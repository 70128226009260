import { ReactElement } from 'react';
import { AwsClientOverrides, VALIDATION_RULES, getSchema, transformMagicFieldName } from '@localstack/services';
import { CreateGlobalRDSCluster } from '@localstack/types';

import { MagicForm } from '../../magic/MagicForm';
import { ControlledTextField } from '../../../form';

export interface RDSGlobalDatabaseFormProps {
  loading?: boolean;
  formId?: string;
  clientOverrides?: AwsClientOverrides;
  onCreate: (data: CreateGlobalRDSCluster) => unknown;
}

export const RDSGlobalDatabaseForm = ({ loading, formId, onCreate }: RDSGlobalDatabaseFormProps): ReactElement => (
  <MagicForm
    schema={getSchema('RDS')}
    loading={loading}
    entry="CreateGlobalClusterMessage"
    formId={formId}
    externalFields={{
      '^GlobalClusterIdentifier$': (control, name) => (
        <ControlledTextField
          control={control}
          name={name}
          label={transformMagicFieldName(name)}
          fullWidth
          required
          rules={VALIDATION_RULES.required}
          variant="outlined"
        />
      ),
    }}
    onSubmit={(data: CreateGlobalRDSCluster) => onCreate({ ...data })}
  />
);
