import { ChangeEvent, ReactElement, useState } from 'react';
import { CreateLambdaLayerRequest } from '@localstack/types';
import { FormControl, FormLabel, RadioGroup, FormControlLabel, Radio, Box } from '@mui/material';
import { DEFAULT_S3_ROUTES, PublicAsset } from '@localstack/constants';
import { AwsClientOverrides, getSchema } from '@localstack/services';

import { MagicForm } from '../../../magic/MagicForm';
import { RelatedResourcePicker } from '../../../../form/RelatedResourcePicker';

const CODE_SOURCE = {
  DEFAULT: 'default',
  S3: 's3',
  UPLOAD: 'upload',
};

export type LambdaLayerCreateFormProps = {
  loading?: boolean;
  formId: string;
  clientOverrides?: AwsClientOverrides;
  onSubmit: (data: CreateLambdaLayerRequest) => unknown;
};

export const LambdaLayerCreateForm = ({
  loading,
  formId,
  clientOverrides,
  onSubmit,
}: LambdaLayerCreateFormProps): ReactElement => {
  const [codeSource, setCodeSource] = useState(CODE_SOURCE.DEFAULT);

  const submitHandler = async (data: CreateLambdaLayerRequest) => {
    if (codeSource === CODE_SOURCE.DEFAULT) {
      const demoResponse = await fetch(PublicAsset.DEMO_LAMBDA);
      const demoBuffer = await demoResponse.arrayBuffer();
      return onSubmit({ ...data, Content: { ...data.Content, ZipFile: demoBuffer } });
    }
    if (codeSource === CODE_SOURCE.UPLOAD) {
      const blobbedZip = data.Content.ZipFile as Blob;
      const buffer = await blobbedZip.arrayBuffer();
      return onSubmit({ ...data, Content: { ...data.Content, ZipFile: buffer } });
    }
    return onSubmit(data);
  };

  return (
    <>
      <Box>
        <FormControl
          variant="standard"
          component="fieldset"
          // eslint-disable-next-line
          onChange={(e: ChangeEvent<HTMLFieldSetElement>) => setCodeSource((e.target as any).value)}
        >
          <FormLabel component="legend">Code Source</FormLabel>
          <RadioGroup row value={codeSource} color="primary">
            <FormControlLabel value={CODE_SOURCE.S3} control={<Radio color="primary" />} label="S3 Bucket" />
            <FormControlLabel value={CODE_SOURCE.UPLOAD} control={<Radio color="primary" />} label="Upload ZIP File" />
            <FormControlLabel value={CODE_SOURCE.DEFAULT} control={<Radio color="primary" />} label="Default" />
          </RadioGroup>
        </FormControl>
      </Box>
      <MagicForm
        schema={getSchema('Lambda')}
        formId={formId}
        entry="PublishLayerVersionRequest"
        loading={loading}
        onSubmit={(data: CreateLambdaLayerRequest) => submitHandler(data)}
        fieldConditions={{
          '^Code\\.ImageUri': false,
          '^Code\\.ZipFile': codeSource === CODE_SOURCE.UPLOAD,
          '^Code\\.S3Bucket': codeSource === CODE_SOURCE.S3,
          '^Code\\.S3Key': codeSource === CODE_SOURCE.S3,
          '^Code\\.S3ObjectVersion': codeSource === CODE_SOURCE.S3,
          '^Code': codeSource !== CODE_SOURCE.DEFAULT,
          '^EphemeralStorage': false,
        }}
        externalFields={{
          '^Content.S3Bucket$': (control, fieldName, required) => (
            <RelatedResourcePicker
              control={control}
              client="S3"
              method="listBuckets"
              arrayKeyName="Buckets"
              property="Name"
              fieldName={fieldName}
              entityName="S3 Bucket"
              creationRoute={DEFAULT_S3_ROUTES.RESOURCES_S3_BUCKET_CREATE}
              required={required}
              clientOverrides={clientOverrides}
            />
          ),
        }}
      />
    </>
  );
};
