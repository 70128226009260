import { ReactElement, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { Card, CardHeader, Typography, Button, Box, Alert } from '@mui/material';
import { LoadingFragment, Breadcrumbs } from '@localstack/ui';
import { useAwsGetter, useRoutes } from '@localstack/services';

import { DEFAULT_ATHENA_ROUTES } from '@localstack/constants';

import { NavTabs, TableSchema } from './components';
import { AthenaProps } from './types';

export const AthenaDatabase = ({
  Layout,
  clientOverrides,
  routes = DEFAULT_ATHENA_ROUTES,
}: AthenaProps): ReactElement => {
  const { goto } = useRoutes();
  const { databaseName } = useParams<'databaseName'>();

  // start query execution
  const { data: tablesQuery, mutate: reloadTables } = useAwsGetter(
    'Athena',
    'startQueryExecution',
    [{ QueryString: `SHOW TABLES IN ${databaseName}` }],
    { clientOverrides },
  );

  // monitor query execution
  const { data: tablesQueryStatus } = useAwsGetter(
    'Athena',
    'getQueryExecution',
    [{ QueryExecutionId: tablesQuery?.QueryExecutionId }],
    { swrOverrides: { refreshInterval: 1000 }, clientOverrides },
  );

  const executionState = tablesQueryStatus?.QueryExecution?.Status?.State;

  const queryResultId = executionState === 'SUCCEEDED' ? tablesQuery?.QueryExecutionId : undefined;

  // load query execution results once it becomes "SUCCEEDED"
  const { data: tablesResult } = useAwsGetter('Athena', 'getQueryResults', [{ QueryExecutionId: queryResultId }], {
    swrOverrides: { refreshInterval: 1000 },
    clientOverrides,
  });

  const tableNames = useMemo(
    () => (tablesResult?.ResultSet?.Rows ?? []).slice(1).map((row) => row?.Data?.[0]?.VarCharValue as string),
    [(tablesResult?.ResultSet?.Rows ?? []).length],
  );

  return (
    <>
      <Layout
        documentTitle="Database Tables"
        tabs={<NavTabs routes={routes} />}
        title={
          <Box>
            <Typography variant="h4">Database Tables</Typography>
            <Breadcrumbs
              mappings={[
                ['Athena', () => goto(routes.RESOURCES_ATHENA_DATABASES)],
                [databaseName, null],
              ]}
            />
          </Box>
        }
      >
        <>
          {executionState === 'FAILED' && (
            <Box mb={2}>
              <Alert severity="error" action={<Button onClick={reloadTables}>Retry</Button>}>
                Failed to load databases
              </Alert>
            </Box>
          )}
          <LoadingFragment loading={executionState === 'RUNNING'} variant="card" height={300}>
            {tableNames.map((tableName) => (
              <Box key={tableName} mb={2}>
                <Card>
                  <CardHeader title={`Table "${tableName}" schema`} />
                  <TableSchema key={tableName} databaseName={databaseName as string} tableName={tableName} />
                </Card>
              </Box>
            ))}
          </LoadingFragment>
        </>
      </Layout>
    </>
  );
};
