import { useState, useCallback, ReactElement } from 'react';
import { Theme, useTheme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import createStyles from '@mui/styles/createStyles';
import { Alert, Box, TextField, MenuItem, Typography, Link, Grid } from '@mui/material';
import { useElements, Elements, CardElement } from '@stripe/react-stripe-js';
import { loadStripe, StripeCardElement } from '@stripe/stripe-js';
import { ProgressButton, Logo } from '@localstack/ui';

import { ExternalLink } from '@localstack/constants';

import { STRIPE_PUBLIC_KEY, captureException } from '~/config';

const stripePromise = loadStripe(STRIPE_PUBLIC_KEY);

type Props = {
  onSaveCard: (cardId: string, currency: Optional<string>) => unknown;
  onCancel?: () => void;
  showCurrencySelection?: boolean;
  loading: boolean;
  defaultCurrency?: string;
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    card: {
      position: 'relative',
      borderRadius: '20px', // static on purpose
      width: '100%',
      paddingTop: '63%', // preserve aspect ratio of a real card
      background: `linear-gradient(45deg, ${theme.palette.primary.dark} 0%, ${theme.palette.primary.light} 100%)`,
    },
    cardMagnetLine: {
      position: 'absolute',
      top: '10%',
      left: 0,
      height: '20%',
      width: '100%',
      background: theme.palette.grey[900],
    },
    cardInput: {
      position: 'absolute',
      top: '50%',
      left: '5%',
      width: '90%',
      padding: theme.spacing(2, 2),
      background: theme.palette.background.default,
      borderRadius: theme.shape.borderRadius,
    },
    currencyHint: {
      position: 'absolute',
      marginTop: theme.spacing(9),
      '& *': {
        color: theme.palette.primary.contrastText,
      },
    },
    cardBrand: {
      position: 'absolute',
      bottom: '5%',
      right: '5%',
      height: '10%',
      '& svg': {
        maxHeight: '100%',
      },
    },
    // make it look like stripe input
    cardCurrency: {
      '& > div:before': {
        display: 'none',
      },
      '& > div > div': {
        padding: '0 !important',
      },
      '& svg': {
        display: 'none',
      },
    },
  }),
);

const UnwrappedStripeCardForm = ({
  onSaveCard,
  onCancel,
  showCurrencySelection = false,
  loading,
  defaultCurrency,
}: Props): ReactElement => {
  const classes = useStyles();
  const elements = useElements();
  const theme = useTheme();

  const [selectedCurrency, setSelectedCurrency] = useState<Optional<string>>(defaultCurrency);
  const [stripeError, setStripeError] = useState<Optional<Error>>(null);

  const cardElementStyle = {
    base: {
      color: theme.palette.text.primary,
      borderRadius: theme.shape.borderRadius,
      background: 'red',
    },
  };

  const handleSubmit = useCallback(
    async (event) => {
      event.preventDefault();

      const stripe = await stripePromise;

      if (!stripe || !elements) return;

      try {
        const element = elements.getElement('card') as StripeCardElement;
        const response = await stripe.createToken(element);

        if (response.error) {
          throw new Error(response.error.message);
        }

        onSaveCard(response.token?.id as string, selectedCurrency);
        setStripeError(null);
      } catch (e) {
        const unwantedErrors = ['incomplete', 'incorrect'];

        if (!unwantedErrors.some((str) => e.message?.includes(str))) {
          captureException(e);
        }

        // here we are setting a new Error since error.message from Stripe is useless
        setStripeError(new Error(e.message ?? 'Provided card details are wrong or incomplete'));
      }
    },
    [elements, selectedCurrency],
  );

  return (
    <form onSubmit={handleSubmit}>
      {stripeError && (
        <Box mb={2}>
          <Alert severity="error">{stripeError.message}</Alert>
        </Box>
      )}
      <Grid container justifyContent="center" xs={12}>
        <Grid item xs={12} sm={6}>
          <Box className={classes.card}>
            <Box className={classes.cardMagnetLine} />
            <Box className={classes.cardInput}>
              <Box display="flex" alignItems="center">
                <Box flexGrow={1}>
                  <CardElement options={{ hidePostalCode: true, style: cardElementStyle }} />
                </Box>
                {showCurrencySelection && (
                  <Box ml={1}>
                    <TextField
                      select
                      size="small"
                      variant="standard"
                      placeholder="Currency"
                      fullWidth
                      value={selectedCurrency ?? ''}
                      className={classes.cardCurrency}
                      onChange={({ target }) => setSelectedCurrency(target.value as string)}
                    >
                      <MenuItem value="eur">EUR</MenuItem>,<MenuItem value="usd">USD</MenuItem>,
                    </TextField>
                  </Box>
                )}
                {showCurrencySelection && (
                  <Box className={classes.currencyHint}>
                    <Typography variant="caption">
                      Please select the currency that will be used for future payments with this card
                    </Typography>
                  </Box>
                )}
              </Box>
            </Box>
            <Box className={classes.cardBrand}>
              <Logo variant="white" />
            </Box>
          </Box>
        </Grid>
      </Grid>
      <Box mt={2} display="flex" alignItems="center" justifyContent="flex-end">
        <Typography variant="caption">
          Secure payments powered by{' '}
          <Link target="_blank" href={ExternalLink.STRIPE_BASE} underline="hover">
            Stripe
          </Link>
        </Typography>
        <Box ml={2}>
          <ProgressButton variant="contained" color="primary" type="submit" loading={loading}>
            Save
          </ProgressButton>
        </Box>
      </Box>

      <Box>
        {onCancel && (
          <ProgressButton variant="contained" onClick={onCancel}>
            Cancel
          </ProgressButton>
        )}
      </Box>
    </form>
  );
};

export const StripeCardForm = (props: Props): ReactElement => (
  <Elements options={{ appearance: { theme: 'stripe' }, locale: 'en' }} stripe={stripePromise}>
    <UnwrappedStripeCardForm {...props} />
  </Elements>
);
