import { ReactElement } from 'react';

import { ContainedCustomerLayout } from '~/layouts';

import { ApiKeys as AccountApiKeys } from './components';

export const ApiKeys = (): ReactElement => (
  <ContainedCustomerLayout title="Legacy API Keys">
    <AccountApiKeys />
  </ContainedCustomerLayout>
);
