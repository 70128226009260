import { ReactElement } from 'react';
import { NavLink } from '@localstack/ui';
import { buildRoute } from '@localstack/services';

import { GlueRoutes } from '../../types';

type SchemaTabsProps = {
  routes: GlueRoutes;
  schema: string;
  registry: string;
};

export const SchemaTabs = ({ routes, schema, registry }: SchemaTabsProps): ReactElement => (
  <>
    <NavLink to={buildRoute(routes.RESOURCES_GLUE_SCHEMA_VERSIONS, { schema, registry })}>Versions</NavLink>
  </>
);
