import { SERVICE_NAME } from '@localstack/types';

import { PATH_PREFIX_RESOURCES } from './base';

const SECRETSMANAGER_BASE_ROUTE = `${PATH_PREFIX_RESOURCES}/${SERVICE_NAME.SECRETSMANAGER}`;

export const DEFAULT_SECRETSMANAGER_ROUTES: Record<string, string> = {
  RESOURCES_SECRETS_MANAGER: `${SECRETSMANAGER_BASE_ROUTE}`,
  RESOURCES_SECRETS_MANAGER_SECRETS: `${SECRETSMANAGER_BASE_ROUTE}/secrets`,
  RESOURCES_SECRETS_MANAGER_SECRET: `${SECRETSMANAGER_BASE_ROUTE}/secrets/:secretId`,
  RESOURCES_SECRETS_MANAGER_SECRET_CREATE: `${SECRETSMANAGER_BASE_ROUTE}/secrets/new`,
  RESOURCES_SECRETS_MANAGER_SECRET_UPDATE: `${SECRETSMANAGER_BASE_ROUTE}/secrets/:secretId/update`,
};
