/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { BrowsableCloudPodInstance } from '@localstack/types';
import type { CloudPod } from '@localstack/types';
import type { CloudPodsStats } from '@localstack/types';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class CloudPodsService {
  /**
   * @param creator the user_id of the pod creator; it accepts special values like "me"
   * @returns CloudPod
   * @throws ApiError
   */
  public static listCloudPods(creator?: any): CancelablePromise<Array<CloudPod>> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/v1/cloudpods',
      query: {
        creator: creator,
      },
    });
  }

  /**
   * @returns CloudPod
   * @throws ApiError
   */
  public static listPublicCloudPods(): CancelablePromise<Array<CloudPod>> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/v1/cloudpods/public',
    });
  }

  /**
   * @returns CloudPodsStats
   * @throws ApiError
   */
  public static getCloudPodsStats(): CancelablePromise<CloudPodsStats> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/v1/cloudpods/stats',
    });
  }

  /**
   * @param podName
   * @returns CloudPod
   * @throws ApiError
   */
  public static getCloudPod(podName: any): CancelablePromise<CloudPod> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/v1/cloudpods/{pod_name}',
      path: {
        pod_name: podName,
      },
    });
  }

  /**
   * @param podName
   * @throws ApiError
   */
  public static deleteCloudPod(podName: any): CancelablePromise<void> {
    return __request(OpenAPI, {
      method: 'DELETE',
      url: '/v1/cloudpods/{pod_name}',
      path: {
        pod_name: podName,
      },
    });
  }

  /**
   * @param podName
   * @param requestBody
   * @returns CloudPod
   * @throws ApiError
   */
  public static patchCloudPod(podName: any, requestBody?: CloudPod): CancelablePromise<CloudPod> {
    return __request(OpenAPI, {
      method: 'PATCH',
      url: '/v1/cloudpods/{pod_name}',
      path: {
        pod_name: podName,
      },
      body: requestBody,
      mediaType: 'application/json',
    });
  }

  /**
   * @param podName
   * @param version
   * @returns BrowsableCloudPodInstance
   * @throws ApiError
   */
  public static deployBrowsableCloudPod(podName: any, version?: any): CancelablePromise<BrowsableCloudPodInstance> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/v1/cloudpods/{pod_name}/browse',
      path: {
        pod_name: podName,
      },
      query: {
        version: version,
      },
    });
  }
}
