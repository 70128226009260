import { ReactElement } from 'react';
import { NavLink } from '@localstack/ui';
import { buildRoute } from '@localstack/services';

import { SESRoutes } from '../../types';

interface Props {
  routes: SESRoutes;
}

export const IndexNavTabs = ({ routes }: Props): ReactElement => (
  <>
    <NavLink to={buildRoute(routes.RESOURCES_SES2)} pro>
      SES V2
    </NavLink>
    <NavLink to={buildRoute(routes.RESOURCES_SES1)}>SES V1</NavLink>
  </>
);
