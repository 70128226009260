import { ReactElement } from 'react';
import { useRoutes, useAwsEffect, useAwsGetter } from '@localstack/services';
import { useParams } from 'react-router-dom';
import { Card, CardContent, CardActions, Box, Typography } from '@mui/material';
import { CreateAgwRestApiRequest, UpdateAgwRestApiRequest } from '@localstack/types';
import { ProgressButton, AgwRestApiForm, Breadcrumbs } from '@localstack/ui';
import { DEFAULT_API_GATEWAY_ROUTES } from '@localstack/constants';

import { ApiGatewayProps } from './types';
import { RestApiNavTabs, IndexNavTabs } from './components';

export const AgwRestApiUpsert = ({
  Layout,
  clientOverrides,
  routes = DEFAULT_API_GATEWAY_ROUTES,
}: ApiGatewayProps): ReactElement => {
  const { goto } = useRoutes();
  const { restApiId } = useParams<'restApiId'>();

  const { createRestApi, updateRestApi, isLoading } = useAwsEffect('APIGateway', ['createRestApi', 'updateRestApi'], {
    revalidate: ['getRestApi', 'getRestApis'],
    clientOverrides,
  });

  const { data: api } = useAwsGetter('APIGateway', 'getRestApi', [{ restApiId }], { clientOverrides });

  const handleCreateApi = async (data: CreateAgwRestApiRequest) => {
    await createRestApi(data);
    goto(routes.RESOURCES_AGW1);
  };

  const handleUpdateApi = async (data: UpdateAgwRestApiRequest) => {
    await updateRestApi(data);
    goto(routes.RESOURCES_AGW1_API, { restApiId });
  };

  return (
    <Layout
      title={
        <Box>
          <Typography variant="h4">API Details</Typography>
          <Breadcrumbs
            mappings={[
              ['API Gateway', () => goto(routes.RESOURCES_AGW1)],
              [api?.name, () => goto(routes.RESOURCES_AGW1_API, { restApiId })],
              [restApiId ? 'Update' : 'Create API', null],
            ]}
          />
        </Box>
      }
      tabs={
        restApiId ? (
          <RestApiNavTabs restApiId={restApiId as string} routes={routes} />
        ) : (
          <IndexNavTabs routes={routes} />
        )
      }
      actions={
        <ProgressButton type="submit" form="update" variant="outlined" color="primary" loading={isLoading}>
          Submit
        </ProgressButton>
      }
    >
      <Card>
        <CardContent>
          <AgwRestApiForm
            clientOverrides={clientOverrides}
            api={api}
            onCreate={handleCreateApi}
            onUpdate={handleUpdateApi}
            loading={isLoading}
            formId="update"
          />
        </CardContent>
        <CardActions>
          <ProgressButton type="submit" form="update" variant="contained" color="primary" loading={isLoading}>
            Submit
          </ProgressButton>
        </CardActions>
      </Card>
    </Layout>
  );
};
