import { IdType, Edge, Node } from 'vis-network';

export type gremlinList = 'g:List';
export type gremlinVertex = 'g:Vertex';
export type gremlinEdge = 'g:Edge';
export type gremlinType = 'g:T';
export type gremlinMap = 'g:Map';

export enum QUERY_TYPES {
  Search,
  NodeInsert,
  NodeEdit,
  ItemDrop,
  NodeNeighbors,
  EdgeInsert,
}

export type GremlinNodeInsertFormProps = {
  label: string;
  attributes: {
    [Key: string]: string;
  };
};

export type GremlinNodeUpdateFormProps = {
  id: string;
  attributes: {
    [Key: string]: string;
  };
};

export type GremlinEdgeInsertFormProps = {
  label: string;
  sourceVertexId: string;
  targetVertexId: string;
  attributes: {
    [Key: string]: string;
  };
};

export interface GremlinVertexData {
  id: string;
  label: string;
}

export interface GremlinEdgeData {
  id: string;
  inV: string;
  inVLabel: string;
  label: string;
  outV: string;
  outVLabel: string;
}

export interface GremlinReturnEdgeData {
  '@type': gremlinList;
  '@value': [
    {
      '@type': gremlinEdge;
      '@value': GremlinEdgeData;
    },
  ];
}

export interface GremlinComplexQuery {
  '@type': gremlinList;
  '@value': [
    {
      '@type': gremlinMap;
      '@value': [
        'id',
        string,
        'label',
        string,
        'properties',
        {
          '@type': gremlinMap;
          '@value': [string];
        },
        'edges',
        {
          '@type': gremlinList;
          '@value': [
            {
              '@type': gremlinMap;
              '@value': [
                'id',
                string,
                'from',
                string,
                'to',
                string,
                'label',
                string,
                'properties',
                {
                  '@type': gremlinMap;
                  '@value': [string];
                },
              ];
            },
          ];
        },
      ];
    },
  ];
}

export interface GraphItemAttribute {
  key: string;
  value: string;
}

export interface GremlinNode extends Node {
  attributes?: GraphItemAttribute[];
}

export interface GremlinEdge extends Edge {
  attributes?: GraphItemAttribute[];
}

export interface GremlinGraphDataMap {
  nodes: Map<IdType, GremlinNode>;
  edges: Map<IdType, GremlinEdge>;
}

export interface GremlinGraphData {
  nodes: GremlinNode[];
  edges: GremlinEdge[];
}

export interface coordinates {
  x: number;
  y: number;
}

export interface GraphEvent {
  edges: string[];
  nodes: string[];
  pointer: {
    DOM: coordinates;
    Canvas: coordinates;
  };
  event: {
    angle: number;
    center: coordinates;
    changedPointers: object[];
    deltaTime: number;
    deltaX: number;
    deltaY: number;
    direction: number;
    distance: number;
    eventType: number;
    isFinal: boolean;
    isFirst: boolean;
    maxPointers: number;
    offsetDirection: number;
    overallVelocity: number;
    overallVelocityX: number;
    overallVelocityY: number;
    pointerType: string;
    pointers: unknown[];
    preventDefault: unknown;
    rotation: number;
    scale: number;
    srcEvent: object;
    tapCount: number;
    target: object;
    timeStamp: number;
    type: string;
    velocity: number;
    velocityX: number;
    velocityY: number;
  };
}
