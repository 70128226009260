import { ReactElement, useCallback, useState } from 'react';
import { Button, Card, CardContent } from '@mui/material';
import { ConfirmableButton, Dropdown, LoadingFragment, PageTitle, SGMEndpointConfigsTable } from '@localstack/ui';
import { useRoutes, useAwsGetter, useAwsEffect } from '@localstack/services';

import { SageMakerProps } from './types';
import { NavTabs } from './components/NavTabs';

export const SGMEndpointConfigs = ({ Layout, clientOverrides, routes }: SageMakerProps): ReactElement => {
  const { goto } = useRoutes();
  const [selected, setSelected] = useState<string[]>([]);

  const {
    data: endpoints,
    isLoading: isEndpointConfigsLoading,
    mutate,
  } = useAwsGetter('SGM', 'listEndpointConfigs', [], { clientOverrides });
  const { deleteEndpointConfig } = useAwsEffect('SGM', ['deleteEndpointConfig'], {
    revalidate: ['listEndpointConfigs'],
    clientOverrides,
  });

  const handleDeleteObjects = useCallback(
    async () => Promise.all(selected.map((EndpointConfigName) => deleteEndpointConfig({ EndpointConfigName }))),
    [selected],
  );

  return (
    <>
      <Layout
        title={<PageTitle title="SageMaker EndpointConfigs" onMutate={mutate} />}
        tabs={<NavTabs routes={routes} />}
        actions={
          <>
            <Button onClick={() => goto(routes.RESOURCES_SGM_ENDPOINT_CONFIG_NEW)}>Create</Button>
            <Dropdown label="Actions">
              <ConfirmableButton
                componentType="MenuItem"
                disabled={selected.length === 0 || isEndpointConfigsLoading}
                title={`Remove ${selected.length} EndpointConfig(s)?`}
                onClick={handleDeleteObjects}
                text="Selected endpoints will be permanently deleted"
              >
                Remove Selected
              </ConfirmableButton>
            </Dropdown>
          </>
        }
      >
        <Card>
          <CardContent>
            <LoadingFragment
              loading={isEndpointConfigsLoading}
              arrayData={endpoints?.EndpointConfigs ?? []}
              variant="list"
              height={50}
              size={10}
            >
              <SGMEndpointConfigsTable
                loading={isEndpointConfigsLoading}
                selectable
                onSelect={setSelected}
                endpoints={endpoints?.EndpointConfigs ?? []}
              />
            </LoadingFragment>
          </CardContent>
        </Card>
      </Layout>
    </>
  );
};
