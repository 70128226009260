import { DEFAULT_ACM_ROUTES } from '@localstack/constants';
import { ReactElement } from 'react';
import { RequestCertificateRequest } from '@localstack/types';
import { Breadcrumbs, ACMCertificateForm, ProgressButton } from '@localstack/ui';
import { useAwsEffect, useRoutes } from '@localstack/services';
import { Box, Card, CardActions, CardContent, Typography } from '@mui/material';

import { ACMProps } from './types';

export const ACMCertificateCreate = ({
  Layout,
  clientOverrides,
  routes = DEFAULT_ACM_ROUTES,
}: ACMProps): ReactElement => {
  const { goto } = useRoutes();

  const { requestCertificate, isLoading } = useAwsEffect('ACM', ['requestCertificate'], {
    clientOverrides,
    revalidate: ['listCertificates'],
  });
  const handleCreate = async (data: RequestCertificateRequest) => {
    if (await requestCertificate(data)) {
      goto(routes.RESOURCES_ACM_CERTIFICATES);
    }
  };

  return (
    <Layout
      title={
        <Box>
          <Typography variant="h4">ACM Certificate</Typography>
          <Breadcrumbs
            mappings={[
              ['ACM', () => goto(routes.RESOURCES_ACM_CERTIFICATES)],
              ['Create Certificate', null],
            ]}
          />
        </Box>
      }
      actions={
        <ProgressButton
          color="primary"
          variant="outlined"
          type="submit"
          form="ACMCertificateCreate"
          loading={isLoading}
        >
          Submit
        </ProgressButton>
      }
    >
      <Card>
        <CardContent>
          <ACMCertificateForm formId="ACMCertificateCreate" onSubmit={handleCreate} loading={isLoading} />
        </CardContent>
        <CardActions>
          <ProgressButton
            color="primary"
            variant="outlined"
            type="submit"
            form="ACMCertificateCreate"
            loading={isLoading}
          >
            Submit
          </ProgressButton>
        </CardActions>
      </Card>
    </Layout>
  );
};
