import { ReactElement } from 'react';
import { useParams } from 'react-router-dom';
import { Card, CardContent, CardActions, Box, Typography } from '@mui/material';
import { ProgressButton, Breadcrumbs, ECSClusterForm } from '@localstack/ui';
import { CreateECSClusterParams, UpdateECSClusterParams } from '@localstack/types';
import { useRoutes, useAwsEffect, useAwsGetter } from '@localstack/services';

import { DEFAULT_ECS_ROUTES } from '@localstack/constants';

import { ECSProps } from './types';

import { ClusterNavTabs } from './components';

export const ECSClusterUpsert = ({ Layout, clientOverrides, routes = DEFAULT_ECS_ROUTES }: ECSProps): ReactElement => {
  const { goto } = useRoutes();
  const { clusterName } = useParams<'clusterName'>();

  const { data: clusters } = useAwsGetter('ECS', 'describeClusters', [{ clusters: [clusterName ?? ''] }], {
    clientOverrides,
  });

  const cluster = clusters?.clusters?.find((clust) => clust.clusterName === clusterName);

  const {
    createCluster,
    updateCluster,
    isLoading: isClusterMutating,
  } = useAwsEffect('ECS', ['createCluster', 'updateCluster'], {
    revalidate: ['listClusters', 'describeClusters'],
    clientOverrides,
  });

  const handleCreate = async (data: CreateECSClusterParams) => {
    if (await createCluster(data)) {
      goto(routes.RESOURCES_ECS_CLUSTERS);
    }
  };

  const handleUpdate = async (data: UpdateECSClusterParams) => {
    if (await updateCluster(data)) {
      goto(routes.RESOURCES_ECS_CLUSTER, { clusterName });
    }
  };

  return (
    <Layout
      title={
        <Box>
          <Typography variant="h4">Item Editor</Typography>
          <Breadcrumbs
            mappings={[
              ['ECS', () => goto(routes.RESOURCES_ECS)],
              ['Clusters', () => goto(routes.RESOURCES_ECS_CLUSTERS)],
              [clusterName, () => goto(routes.RESOURCES_ECS_CLUSTER, { clusterName })],
              [clusterName ? 'Update' : 'Create Cluster', null],
            ]}
          />
        </Box>
      }
      tabs={clusterName && <ClusterNavTabs clusterName={clusterName} routes={routes} />}
      actions={
        <ProgressButton
          type="submit"
          form={clusterName ? 'update' : 'create'}
          variant="outlined"
          color="primary"
          loading={isClusterMutating}
        >
          Submit
        </ProgressButton>
      }
    >
      <Card>
        <CardContent>
          <ECSClusterForm
            cluster={cluster}
            loading={isClusterMutating}
            formId={clusterName ? 'update' : 'create'}
            clientOverrides={clientOverrides}
            onCreate={handleCreate}
            onUpdate={handleUpdate}
          />
        </CardContent>
        <CardActions>
          <ProgressButton
            type="submit"
            form={clusterName ? 'update' : 'create'}
            variant="contained"
            color="primary"
            loading={isClusterMutating}
          >
            Submit
          </ProgressButton>
        </CardActions>
      </Card>
    </Layout>
  );
};
