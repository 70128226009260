/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { AcquisitionChannel } from '@localstack/types';
import type { AdminSearchResult } from '@localstack/types';
import type { ApiKey } from '@localstack/types';
import type { AuditTrailQueryResult } from '@localstack/types';
import type { CreditCard } from '@localstack/types';
import type { Discount } from '@localstack/types';
import type { FeatureControlRecord } from '@localstack/types';
import type { LicenseAssignment } from '@localstack/types';
import type { Organization } from '@localstack/types';
import type { OrganizationMember } from '@localstack/types';
import type { Plan } from '@localstack/types';
import type { Product } from '@localstack/types';
import type { Subscription } from '@localstack/types';
import type { TaxDetails } from '@localstack/types';
import type { UsageSummary } from '@localstack/types';
import type { User } from '@localstack/types';
import type { UserHealthFixReport } from '@localstack/types';
import type { UserHealthReportItem } from '@localstack/types';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class AdminService {
  /**
   * @param userId
   * @param startTimestamp
   * @param queryId
   * @param endTimestamp
   * @returns AuditTrailQueryResult
   * @throws ApiError
   */
  public static getUserAuditTrail(
    userId: any,
    startTimestamp: number,
    queryId?: any,
    endTimestamp?: number,
  ): CancelablePromise<AuditTrailQueryResult> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/v1/admin/users/{user_id}/audit-trail',
      path: {
        user_id: userId,
      },
      query: {
        query_id: queryId,
        start_timestamp: startTimestamp,
        end_timestamp: endTimestamp,
      },
    });
  }

  /**
   * @param orgId
   * @param startTimestamp
   * @param queryId
   * @param endTimestamp
   * @returns AuditTrailQueryResult
   * @throws ApiError
   */
  public static getOrgAuditTrail(
    orgId: any,
    startTimestamp: number,
    queryId?: any,
    endTimestamp?: number,
  ): CancelablePromise<AuditTrailQueryResult> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/v1/admin/organizations/{org_id}/audit-trail',
      path: {
        org_id: orgId,
      },
      query: {
        query_id: queryId,
        start_timestamp: startTimestamp,
        end_timestamp: endTimestamp,
      },
    });
  }

  /**
   * @returns any
   * @throws ApiError
   */
  public static listResources(): CancelablePromise<{
    plans?: Array<Plan>;
    discounts: Array<Discount>;
    products: Array<Product>;
  }> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/v1/admin/resources',
    });
  }

  /**
   * @returns Organization
   * @throws ApiError
   */
  public static getOrganizations(): CancelablePromise<Array<Organization>> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/v1/admin/organizations',
    });
  }

  /**
   * @param requestBody
   * @returns Organization
   * @throws ApiError
   */
  public static createOrganization(requestBody?: Organization): CancelablePromise<Organization> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/v1/admin/organizations',
      body: requestBody,
      mediaType: 'application/json',
    });
  }

  /**
   * @param orgId
   * @returns Organization
   * @throws ApiError
   */
  public static getOrganization(orgId: any): CancelablePromise<Organization> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/v1/admin/organizations/{org_id}',
      path: {
        org_id: orgId,
      },
    });
  }

  /**
   * @param orgId
   * @param requestBody
   * @returns Organization
   * @throws ApiError
   */
  public static updateOrganization(orgId: any, requestBody?: Organization): CancelablePromise<Organization> {
    return __request(OpenAPI, {
      method: 'PATCH',
      url: '/v1/admin/organizations/{org_id}',
      path: {
        org_id: orgId,
      },
      body: requestBody,
      mediaType: 'application/json',
    });
  }

  /**
   * @param orgId
   * @returns Organization
   * @throws ApiError
   */
  public static removeOrganization(orgId: any): CancelablePromise<Organization> {
    return __request(OpenAPI, {
      method: 'DELETE',
      url: '/v1/admin/organizations/{org_id}',
      path: {
        org_id: orgId,
      },
    });
  }

  /**
   * @param orgId
   * @param currency
   * @returns Organization
   * @throws ApiError
   */
  public static syncOrganizationStripeAccount(orgId: string, currency: string): CancelablePromise<Organization> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/v1/admin/organizations/{org_id}/stripe',
      path: {
        org_id: orgId,
      },
      query: {
        currency: currency,
      },
    });
  }

  /**
   * @param orgId
   * @param requestBody
   * @returns Organization
   * @throws ApiError
   */
  public static inviteOrganizationMember(
    orgId: any,
    requestBody?: {
      email?: string;
      name?: string;
      try_assign_license_on_join?: boolean;
    },
  ): CancelablePromise<Organization> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/v1/admin/organizations/{org_id}/invites',
      path: {
        org_id: orgId,
      },
      body: requestBody,
      mediaType: 'application/json',
    });
  }

  /**
   * @param orgId
   * @param requestBody
   * @returns Organization
   * @throws ApiError
   */
  public static createOrganizationMember(
    orgId: any,
    requestBody?: {
      id?: string;
      email?: string;
      name?: string;
      roles?: Array<string>;
    },
  ): CancelablePromise<Organization> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/v1/admin/organizations/{org_id}/members',
      path: {
        org_id: orgId,
      },
      body: requestBody,
      mediaType: 'application/json',
    });
  }

  /**
   * @param orgId
   * @param memberId
   * @param forever
   * @returns Organization
   * @throws ApiError
   */
  public static removeOrganizationMember(orgId: any, memberId: any, forever?: any): CancelablePromise<Organization> {
    return __request(OpenAPI, {
      method: 'DELETE',
      url: '/v1/admin/organizations/{org_id}/members/{member_id}',
      path: {
        org_id: orgId,
        member_id: memberId,
      },
      query: {
        forever: forever,
      },
    });
  }

  /**
   * @param orgId
   * @param memberId
   * @param requestBody
   * @returns Organization
   * @throws ApiError
   */
  public static updateOrganizationMember(
    orgId: any,
    memberId: any,
    requestBody?: OrganizationMember,
  ): CancelablePromise<Organization> {
    return __request(OpenAPI, {
      method: 'PATCH',
      url: '/v1/admin/organizations/{org_id}/members/{member_id}',
      path: {
        org_id: orgId,
        member_id: memberId,
      },
      body: requestBody,
      mediaType: 'application/json',
    });
  }

  /**
   * @returns User
   * @throws ApiError
   */
  public static getUsers(): CancelablePromise<Array<User>> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/v1/admin/users',
    });
  }

  /**
   * @param requestBody
   * @returns User
   * @throws ApiError
   */
  public static createUser(requestBody?: {
    email: string;
    password: string;
    firstname?: string;
    lastname?: string;
    howFound?: string;
    newsletter?: boolean;
  }): CancelablePromise<User> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/v1/admin/users',
      body: requestBody,
      mediaType: 'application/json',
    });
  }

  /**
   * @param userId
   * @returns User
   * @throws ApiError
   */
  public static getUser(userId: any): CancelablePromise<User> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/v1/admin/users/{user_id}',
      path: {
        user_id: userId,
      },
    });
  }

  /**
   * @param userId
   * @param requestBody
   * @returns User
   * @throws ApiError
   */
  public static updateUser(userId: any, requestBody?: User): CancelablePromise<User> {
    return __request(OpenAPI, {
      method: 'PATCH',
      url: '/v1/admin/users/{user_id}',
      path: {
        user_id: userId,
      },
      body: requestBody,
      mediaType: 'application/json',
    });
  }

  /**
   * @param userId
   * @returns User
   * @throws ApiError
   */
  public static removeUser(userId: any): CancelablePromise<User> {
    return __request(OpenAPI, {
      method: 'DELETE',
      url: '/v1/admin/users/{user_id}',
      path: {
        user_id: userId,
      },
    });
  }

  /**
   * @param userId
   * @returns UserHealthReportItem
   * @throws ApiError
   */
  public static getUserHealth(userId: any): CancelablePromise<Array<UserHealthReportItem>> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/v1/admin/users/{user_id}/health',
      path: {
        user_id: userId,
      },
    });
  }

  /**
   * @param userId
   * @param requestBody
   * @returns UserHealthFixReport
   * @throws ApiError
   */
  public static fixUserIssues(
    userId: any,
    requestBody?: {
      issues: Array<UserHealthReportItem>;
    },
  ): CancelablePromise<UserHealthFixReport> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/v1/admin/users/{user_id}/health',
      path: {
        user_id: userId,
      },
      body: requestBody,
      mediaType: 'application/json',
    });
  }

  /**
   * @param userId
   * @param requestBody
   * @returns User
   * @throws ApiError
   */
  public static replaceUserEmail(
    userId: any,
    requestBody?: {
      email: string;
    },
  ): CancelablePromise<User> {
    return __request(OpenAPI, {
      method: 'PUT',
      url: '/v1/admin/users/{user_id}/emails',
      path: {
        user_id: userId,
      },
      body: requestBody,
      mediaType: 'application/json',
    });
  }

  /**
   * @param userId
   * @returns any
   * @throws ApiError
   */
  public static assignUserPassword(userId: any): CancelablePromise<{
    password: string;
  }> {
    return __request(OpenAPI, {
      method: 'PATCH',
      url: '/v1/admin/users/{user_id}/password',
      path: {
        user_id: userId,
      },
    });
  }

  /**
   * @param userId
   * @returns User
   * @throws ApiError
   */
  public static resendUserActivation(userId: any): CancelablePromise<User> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/v1/admin/users/{user_id}/resend',
      path: {
        user_id: userId,
      },
    });
  }

  /**
   * @param userId
   * @returns User
   * @throws ApiError
   */
  public static activateUser(userId: any): CancelablePromise<User> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/v1/admin/users/{user_id}/activate',
      path: {
        user_id: userId,
      },
    });
  }

  /**
   * @param userId
   * @returns User
   * @throws ApiError
   */
  public static resetUserPassword(userId: any): CancelablePromise<User> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/v1/admin/users/{user_id}/reset',
      path: {
        user_id: userId,
      },
    });
  }

  /**
   * @param userId
   * @returns Organization
   * @throws ApiError
   */
  public static listUserOrganizations(userId: any): CancelablePromise<Array<Organization>> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/v1/admin/users/{user_id}/organizations',
      path: {
        user_id: userId,
      },
    });
  }

  /**
   * @param orgId
   * @returns Subscription
   * @throws ApiError
   */
  public static listOrganizationSubscriptions(orgId: any): CancelablePromise<Array<Subscription>> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/v1/admin/organizations/{org_id}/subscriptions',
      path: {
        org_id: orgId,
      },
    });
  }

  /**
   * @param orgId
   * @param requestBody
   * @returns Subscription
   * @throws ApiError
   */
  public static createOrganizationSubscription(
    orgId: any,
    requestBody?: {
      plan: string;
      name?: string;
      discount?: string;
      payment_id?: string;
      seats?: number;
      volumes?: Record<string, any>;
      products?: Array<string>;
      promo_code?: string;
      backdate_start_date?: number;
      billing_cycle_anchor?: any;
      proration_behavior?: string;
      mrr?: number;
      mrr_private?: number;
      acquisition_channel?: AcquisitionChannel;
    },
  ): CancelablePromise<Subscription> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/v1/admin/organizations/{org_id}/subscriptions',
      path: {
        org_id: orgId,
      },
      body: requestBody,
      mediaType: 'application/json',
    });
  }

  /**
   * @param orgId
   * @param subscriptionId
   * @returns Subscription
   * @throws ApiError
   */
  public static getOrganizationSubscription(orgId: string, subscriptionId: string): CancelablePromise<Subscription> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/v1/admin/organizations/{org_id}/subscriptions/{subscription_id}',
      path: {
        org_id: orgId,
        subscription_id: subscriptionId,
      },
    });
  }

  /**
   * @param orgId
   * @param subscriptionId
   * @param requestBody
   * @returns Subscription
   * @throws ApiError
   */
  public static updateOrganizationSubscription(
    orgId: string,
    subscriptionId: string,
    requestBody?: {
      plan: string;
      discount?: string;
      payment_id?: string;
      seats?: number;
      volumes?: Record<string, any>;
      products?: Array<string>;
      promo_code?: string;
      billing_cycle_anchor?: any;
      proration_behavior?: string;
      mrr?: number;
      mrr_private?: number;
      acquisition_channel?: AcquisitionChannel;
    },
  ): CancelablePromise<Subscription> {
    return __request(OpenAPI, {
      method: 'PATCH',
      url: '/v1/admin/organizations/{org_id}/subscriptions/{subscription_id}',
      path: {
        org_id: orgId,
        subscription_id: subscriptionId,
      },
      body: requestBody,
      mediaType: 'application/json',
    });
  }

  /**
   * @param orgId
   * @param subId
   * @returns Subscription
   * @throws ApiError
   */
  public static cancelOrganizationSubscription(orgId: any, subId: any): CancelablePromise<Subscription> {
    return __request(OpenAPI, {
      method: 'DELETE',
      url: '/v1/admin/organizations/{org_id}/subscriptions/{sub_id}',
      path: {
        org_id: orgId,
        sub_id: subId,
      },
    });
  }

  /**
   * @param orgId
   * @param subId
   * @param requestBody
   * @returns Subscription
   * @throws ApiError
   */
  public static rescheduleOrganizationSubscription(
    orgId: any,
    subId: any,
    requestBody?: {
      cancel_at?: number;
      cancel_at_period_end?: boolean;
      reactivate?: boolean;
    },
  ): CancelablePromise<Subscription> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/v1/admin/organizations/{org_id}/subscriptions/{sub_id}/reschedule',
      path: {
        org_id: orgId,
        sub_id: subId,
      },
      body: requestBody,
      mediaType: 'application/json',
    });
  }

  /**
   * @param orgId
   * @param subscriptionId
   * @returns UsageSummary
   * @throws ApiError
   */
  public static getOrganizationSubscriptionUsage(
    orgId: string,
    subscriptionId: string,
  ): CancelablePromise<UsageSummary> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/v1/admin/organizations/{org_id}/subscriptions/{subscription_id}/usage',
      path: {
        org_id: orgId,
        subscription_id: subscriptionId,
      },
    });
  }

  /**
   * @param orgId
   * @param planId
   * @param seats
   * @param promoCode
   * @returns TaxDetails
   * @throws ApiError
   */
  public static getOrganizationPlanTax(
    orgId: string,
    planId: string,
    seats: number,
    promoCode?: string,
  ): CancelablePromise<TaxDetails> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/v1/admin/organizations/{org_id}/plans/{plan_id}/tax',
      path: {
        org_id: orgId,
        plan_id: planId,
      },
      query: {
        seats: seats,
        promo_code: promoCode,
      },
    });
  }

  /**
   * @param orgId
   * @returns ApiKey
   * @throws ApiError
   */
  public static listOrganizationKeys(orgId: any): CancelablePromise<Array<ApiKey>> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/v1/admin/organizations/{org_id}/keys',
      path: {
        org_id: orgId,
      },
    });
  }

  /**
   * @param orgId
   * @param requestBody
   * @returns ApiKey
   * @throws ApiError
   */
  public static createOrganizationKey(
    orgId: any,
    requestBody?: {
      name?: string;
      is_ci?: boolean;
      expires_at?: number;
      member_email?: string;
      subscription_id?: string;
    },
  ): CancelablePromise<ApiKey> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/v1/admin/organizations/{org_id}/keys',
      path: {
        org_id: orgId,
      },
      body: requestBody,
      mediaType: 'application/json',
    });
  }

  /**
   * @param orgId
   * @returns LicenseAssignment
   * @throws ApiError
   */
  public static listOrganizationLicenseAssignments(orgId: any): CancelablePromise<Array<LicenseAssignment>> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/v1/admin/organizations/{org_id}/license-assignments',
      path: {
        org_id: orgId,
      },
    });
  }

  /**
   * @param orgId
   * @param requestBody
   * @returns LicenseAssignment
   * @throws ApiError
   */
  public static assignLicense(
    orgId: any,
    requestBody?: {
      user_id: string;
      subscription_id?: string;
    },
  ): CancelablePromise<LicenseAssignment> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/v1/admin/organizations/{org_id}/license-assignments',
      path: {
        org_id: orgId,
      },
      body: requestBody,
      mediaType: 'application/json',
    });
  }

  /**
   * @param orgId
   * @param keyId
   * @returns ApiKey
   * @throws ApiError
   */
  public static removeOrganizationKey(orgId: any, keyId: any): CancelablePromise<ApiKey> {
    return __request(OpenAPI, {
      method: 'DELETE',
      url: '/v1/admin/organizations/{org_id}/keys/{key_id}',
      path: {
        org_id: orgId,
        key_id: keyId,
      },
    });
  }

  /**
   * @param orgId
   * @param keyId
   * @param requestBody
   * @returns ApiKey
   * @throws ApiError
   */
  public static updateOrganizationKey(
    orgId: any,
    keyId: any,
    requestBody?: {
      name?: string;
      expires_at?: number;
      member_email?: string;
    },
  ): CancelablePromise<ApiKey> {
    return __request(OpenAPI, {
      method: 'PATCH',
      url: '/v1/admin/organizations/{org_id}/keys/{key_id}',
      path: {
        org_id: orgId,
        key_id: keyId,
      },
      body: requestBody,
      mediaType: 'application/json',
    });
  }

  /**
   * @param orgId
   * @param keyId
   * @param requestBody
   * @returns ApiKey
   * @throws ApiError
   */
  public static rotateOrganizationKey(
    orgId: any,
    keyId: any,
    requestBody?: {
      notify?: boolean;
    },
  ): CancelablePromise<ApiKey> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/v1/admin/organizations/{org_id}/keys/{key_id}/rotate',
      path: {
        org_id: orgId,
        key_id: keyId,
      },
      body: requestBody,
      mediaType: 'application/json',
    });
  }

  /**
   * @param orgId
   * @returns CreditCard
   * @throws ApiError
   */
  public static listOrganizationCards(orgId: any): CancelablePromise<Array<CreditCard>> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/v1/admin/organizations/{org_id}/cards',
      path: {
        org_id: orgId,
      },
    });
  }

  /**
   * @param orgId
   * @param requestBody
   * @returns CreditCard
   * @throws ApiError
   */
  public static attachOrganizationCard(
    orgId: any,
    requestBody?: {
      token?: string;
      currency?: string;
    },
  ): CancelablePromise<CreditCard> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/v1/admin/organizations/{org_id}/cards',
      path: {
        org_id: orgId,
      },
      body: requestBody,
      mediaType: 'application/json',
    });
  }

  /**
   * @param orgId
   * @param cardId
   * @param currency
   * @returns CreditCard
   * @throws ApiError
   */
  public static removeOrganizationCard(orgId: any, cardId: any, currency?: any): CancelablePromise<CreditCard> {
    return __request(OpenAPI, {
      method: 'DELETE',
      url: '/v1/admin/organizations/{org_id}/cards/{card_id}',
      path: {
        org_id: orgId,
        card_id: cardId,
      },
      query: {
        currency: currency,
      },
    });
  }

  /**
   * @param orgId
   * @param cardId
   * @param currency
   * @returns CreditCard
   * @throws ApiError
   */
  public static changeOrganizationCard(orgId: any, cardId: any, currency: any): CancelablePromise<CreditCard> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/v1/admin/organizations/{org_id}/cards/{card_id}/change',
      path: {
        org_id: orgId,
        card_id: cardId,
      },
      query: {
        currency: currency,
      },
    });
  }

  /**
   * @param orgId
   * @param oldSub
   * @param newSub
   * @returns any Success
   * @throws ApiError
   */
  public static migrateSubscriptionApiKeys(orgId: any, oldSub: any, newSub: any): CancelablePromise<any> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/v1/admin/organizations/{org_id}/migrate',
      path: {
        org_id: orgId,
      },
      query: {
        old_sub: oldSub,
        new_sub: newSub,
      },
    });
  }

  /**
   * @param orgId
   * @returns FeatureControlRecord
   * @throws ApiError
   */
  public static getFeatureControlRecord(orgId: any): CancelablePromise<FeatureControlRecord> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/v1/admin/organizations/{org_id}/features',
      path: {
        org_id: orgId,
      },
    });
  }

  /**
   * @param orgId
   * @param recordId
   * @param requestBody
   * @returns FeatureControlRecord
   * @throws ApiError
   */
  public static updateFeatureControlRecord(
    orgId: any,
    recordId: any,
    requestBody?: FeatureControlRecord,
  ): CancelablePromise<FeatureControlRecord> {
    return __request(OpenAPI, {
      method: 'PATCH',
      url: '/v1/admin/organizations/{org_id}/features/{record_id}',
      path: {
        org_id: orgId,
        record_id: recordId,
      },
      body: requestBody,
      mediaType: 'application/json',
    });
  }

  /**
   * @param query
   * @returns AdminSearchResult
   * @throws ApiError
   */
  public static getSearchResults(query: any): CancelablePromise<AdminSearchResult> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/v1/admin/search',
      query: {
        query: query,
      },
    });
  }

  /**
   * @param requestBody
   * @returns any OK
   * @throws ApiError
   */
  public static sendAnnouncement(requestBody?: { rich_text: string }): CancelablePromise<any> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/v1/admin/announcement/send',
      body: requestBody,
      mediaType: 'application/json',
    });
  }

  /**
   * @param requestBody
   * @returns any
   * @throws ApiError
   */
  public static generateAnnouncementPreview(requestBody?: { rich_text: string }): CancelablePromise<{
    preview_html: string;
  }> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/v1/admin/announcement/preview',
      body: requestBody,
      mediaType: 'application/json',
    });
  }

  /**
   * @param orgId
   * @param assignmentId
   * @returns LicenseAssignment
   * @throws ApiError
   */
  public static unassignLicense(orgId: any, assignmentId: any): CancelablePromise<LicenseAssignment> {
    return __request(OpenAPI, {
      method: 'DELETE',
      url: '/v1/admin/organizations/{org_id}/license-assignments/{assignment_id}',
      path: {
        org_id: orgId,
        assignment_id: assignmentId,
      },
    });
  }
}
