import { ReactElement } from 'react';
import { getSchema } from '@localstack/services';

import { AppSyncGraphqlApi, CreateAppSyncGraphqlApiRequest, UpdateAppSyncGraphqlApiRequest } from '@localstack/types';

import { MagicForm } from '../../../magic/MagicForm';

export interface AppSyncApiFormProps {
  api?: Optional<Partial<AppSyncGraphqlApi>>;
  loading?: boolean;
  formId?: string;
  onCreate: (data: CreateAppSyncGraphqlApiRequest) => unknown;
  onUpdate: (data: UpdateAppSyncGraphqlApiRequest) => unknown;
}

export const AppSyncApiForm = ({ api, loading, formId, onCreate, onUpdate }: AppSyncApiFormProps): ReactElement => (
  <MagicForm
    data={api}
    schema={getSchema('AppSync')}
    loading={loading}
    entry={api ? 'UpdateGraphqlApiRequest' : 'CreateGraphqlApiRequest'}
    formId={formId}
    fieldConditions={{
      '^apiId$': !api,
      '^userPoolConfig': ['$authenticationType', '===', 'AMAZON_COGNITO_USER_POOLS'],
      '^openIDConnectConfig': ['$authenticationType', '===', 'OPENID_CONNECT'],
      '^lambdaAuthorizerConfig': ['$authenticationType', '===', 'AWS_LAMBDA'],
    }}
    onSubmit={(data: CreateAppSyncGraphqlApiRequest | UpdateAppSyncGraphqlApiRequest) => {
      if (!api) return onCreate(data as CreateAppSyncGraphqlApiRequest);
      return onUpdate({ ...data, apiId: api.apiId as string, name: (data.name || api.name) as string });
    }}
  />
);
