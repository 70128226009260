/* eslint-disable @typescript-eslint/no-explicit-any */

import { useState, useEffect, useMemo, ReactElement } from 'react';
import { Box } from '@mui/material';
import { S3Bucket, S3Object } from '@localstack/types';

import { S3BucketsTable } from '../../resource/s3/S3BucketsTable';
import { S3ObjectsTable } from '../../resource/s3/S3ObjectsTable';
import { S3ObjectBreadcrumbs } from '../../resource/s3/S3ObjectBreadcrumbs';

type Props = {
  delta: unknown;
};

const convertDeltaToBuckets = (delta: any): S3Bucket[] =>
  Object.entries(delta).map(([bucket, config]: [string, any]) => ({
    Name: bucket,
    CreationDate: new Date(config.creation_date),
  }));

const convertDeltaToObjects = (bucket: Optional<string>, delta: any): S3Object[] => {
  if (!bucket) return [];

  const keys = delta?.[bucket]?.keys || {};

  const objects = Object.entries(keys).map(([name, config]: [string, any]) => ({
    Key: name,
    ETag: config[0]._etag,
    Size: config[0].contentsize,
    StorageClass: config[0]._storage_class,
    LastModified: new Date(config[0].last_modified),
  }));

  const folderNames: string[] = objects.reduce((folders, obj) => {
    const uniquePathsSet = new Set([
      ...folders,
      ...obj.Key.split('/')
        .slice(0, -1)
        .reduce((paths, path) => [...paths, paths.length ? `${paths.slice(-1)[0]}/${path}` : path], [] as string[]),
    ]);
    return Array.from(uniquePathsSet.values());
  }, [] as string[]);

  const folders = folderNames.map((name) => ({
    Key: name,
    Size: 0,
  }));

  return [...folders, ...objects];
};

export const S3Preview = ({ delta }: Props): ReactElement => {
  const [selectedBucket, setSelectedBucket] = useState<Optional<string>>(null);
  const [selectedPrefix, setSelectedPrefix] = useState<string>('');

  const buckets = useMemo(() => convertDeltaToBuckets(delta), [delta]);
  const objects = useMemo(() => convertDeltaToObjects(selectedBucket, delta), [selectedBucket, delta]);

  useEffect(() => {
    setSelectedPrefix('');
  }, [selectedBucket]);

  return (
    <>
      {!selectedBucket && <S3BucketsTable selectable={false} buckets={buckets} onViewBucket={setSelectedBucket} />}
      {selectedBucket && (
        <>
          <Box p={2} mb={2}>
            <S3ObjectBreadcrumbs
              bucket={selectedBucket}
              prefix={selectedPrefix}
              onViewRoot={() => setSelectedBucket(null)}
              onViewBucket={(bucket, prefix) => {
                setSelectedBucket(bucket);
                setSelectedPrefix(prefix || '');
              }}
            />
          </Box>
          <S3ObjectsTable
            selectable={false}
            downloadable={false}
            prefix={selectedPrefix}
            objects={objects}
            onViewObject={setSelectedPrefix}
          />
        </>
      )}
    </>
  );
};
