import { useState, useCallback, ReactNode, ReactElement, MouseEvent } from 'react';
import { KeyboardArrowDown } from '@mui/icons-material';
import {
  Button,
  Popper,
  Grow,
  Paper,
  ClickAwayListener,
  MenuList,
  ButtonProps,
  useTheme,
  MenuItemProps,
  MenuListProps,
  PopperPlacementType,
} from '@mui/material';

export type DropdownProps = MenuItemProps &
  ButtonProps & {
    label?: string;
    renderButton?: (props: DropdownProps) => JSX.Element;
    children: ReactNode | ReactNode[];
    stopPropagation?: boolean;
    dropdownClassName?: string;
    menuListProps?: MenuListProps;
    placement?: PopperPlacementType;
  };

export const Dropdown = ({
  label,
  renderButton,
  children,
  stopPropagation,
  dropdownClassName,
  menuListProps,
  placement,
  ...rest
}: DropdownProps): ReactElement => {
  const theme = useTheme();
  const [anchorEl, setAnchorEl] = useState<Optional<Element>>(null);

  const handleClick = useCallback((event: MouseEvent<Element>) => {
    if (stopPropagation) {
      event.stopPropagation();
    }
    setAnchorEl(event.currentTarget);
  }, []);

  return (
    <>
      {renderButton && renderButton({ label, children, onClick: handleClick, ...rest })}
      {!renderButton && (
        <>
          {label && (
            <Button onClick={handleClick} variant="outlined" disableElevation endIcon={<KeyboardArrowDown />} {...rest}>
              {label}
            </Button>
          )}
          {!label && (
            <Button onClick={handleClick} variant="outlined" disableElevation {...rest}>
              <KeyboardArrowDown />
            </Button>
          )}
        </>
      )}
      <Popper
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        transition
        style={{ zIndex: theme.zIndex.tooltip }}
        placement={placement}
      >
        {({ TransitionProps }) => (
          <Grow {...TransitionProps}>
            <Paper
              style={{
                borderRadius: 10,
                maxHeight: 500,
                overflow: 'auto',
              }}
              className={dropdownClassName}
            >
              <ClickAwayListener onClickAway={() => setAnchorEl(null)}>
                <MenuList autoFocusItem={Boolean(anchorEl)} {...menuListProps}>
                  {children}
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </>
  );
};
