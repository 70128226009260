import { ReactElement } from 'react';
import { useAwsEffect, useAwsGetter, useRoutes } from '@localstack/services';
import { Card, CardContent, CardActions, Box, Typography } from '@mui/material';
import { Breadcrumbs, GlueRegistryForm, ProgressButton } from '@localstack/ui';
import { CreateGlueRegistryRequest, UpdateGlueRegistryRequest } from '@localstack/types';
import { DEFAULT_GLUE_ROUTES } from '@localstack/constants/src';
import { useParams } from 'react-router-dom';

import { GlueProps } from './types';
import { MainNavTabs } from './components';

export const GlueRegistryUpsert = ({
  Layout,
  clientOverrides,
  routes = DEFAULT_GLUE_ROUTES,
}: GlueProps): ReactElement => {
  const { goto } = useRoutes();
  const { registry } = useParams<'registry'>();

  const { createRegistry, updateRegistry, isLoading } = useAwsEffect('Glue', ['createRegistry', 'updateRegistry'], {
    revalidate: ['listRegistries', 'getRegistry'],
    clientOverrides,
  });

  const { data: RegistryData } = useAwsGetter(
    'Glue',
    'getRegistry',
    [registry ? { RegistryId: { RegistryName: registry } } : {}],
    { clientOverrides },
  );

  const handleCreate = async (data: CreateGlueRegistryRequest) => {
    await createRegistry(data);
    goto(routes.RESOURCES_GLUE_REGISTRIES);
  };

  const handleUpdate = async (data: UpdateGlueRegistryRequest) => {
    await updateRegistry(data);
    goto(routes.RESOURCES_GLUE_REGISTRY, { registry });
  };

  return (
    <Layout
      documentTitle={`Glue: ${registry ? 'Update' : 'Create'} Schema Registry`}
      title={
        <Box>
          <Typography variant="h4">{registry ? 'Update' : 'Create'} Schema Registry</Typography>
          <Breadcrumbs
            mappings={[
              ['Glue', () => goto(routes.RESOURCES_GLUE_DATABASES)],
              ['Schema Registries', () => goto(routes.RESOURCES_GLUE_REGISTRIES)],
              [registry ?? null, () => goto(routes.RESOURCES_GLUE_REGISTRY, { registry })],
              [`${registry ? 'Update' : 'Create'} Schema Registry`, null],
            ]}
          />
        </Box>
      }
      tabs={<MainNavTabs routes={routes} />}
      actions={
        <ProgressButton type="submit" form="UpsertRegistry" variant="outlined" color="primary" loading={isLoading}>
          Submit
        </ProgressButton>
      }
    >
      <Card>
        <CardContent>
          <GlueRegistryForm
            registry={RegistryData}
            loading={isLoading}
            formId="UpsertRegistry"
            onCreate={handleCreate}
            onUpdate={handleUpdate}
          />
        </CardContent>
        <CardActions>
          <ProgressButton type="submit" form="UpsertRegistry" variant="contained" color="primary" loading={isLoading}>
            Submit
          </ProgressButton>
        </CardActions>
      </Card>
    </Layout>
  );
};
