import { ReactElement } from 'react';
import { NavLink } from '@localstack/ui';
import { buildRoute } from '@localstack/services';

import { CognitoRoutes } from '../../types';

interface Props {
  poolId: string;
  routes: CognitoRoutes;
}

export const PoolNavTabs = ({ poolId, routes }: Props): ReactElement => (
  <>
    <NavLink to={buildRoute(routes.RESOURCES_COGNITO_USER_POOL, { poolId })} end>
      Details
    </NavLink>
    <NavLink to={buildRoute(routes.RESOURCES_COGNITO_POOL_GROUPS, { poolId })}>Groups</NavLink>
    <NavLink to={buildRoute(routes.RESOURCES_COGNITO_POOL_USERS, { poolId })}>Users</NavLink>
  </>
);
