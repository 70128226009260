import { DEFAULT_DMS_ROUTES } from '@localstack/constants';
import { ReactElement } from 'react';
import { CreateEndpointMessage } from '@localstack/types';
import { Breadcrumbs, DMSEndpointForm, ProgressButton } from '@localstack/ui';
import { useAwsEffect, useRoutes } from '@localstack/services';
import { Box, Card, CardActions, CardContent, Typography } from '@mui/material';

import { DMSProps } from './types';

export const DMSEndpointCreate = ({ Layout, clientOverrides, routes = DEFAULT_DMS_ROUTES }: DMSProps): ReactElement => {
  const { goto } = useRoutes();
  const { createEndpoint, isLoading } = useAwsEffect('DMS', ['createEndpoint'], {
    clientOverrides,
    revalidate: ['describeEndpoints'],
  });

  const handleCreate = async (data: CreateEndpointMessage) => {
    if (await createEndpoint(data)) {
      goto(routes.RESOURCES_DMS_ENDPOINTS);
    }
  };

  return (
    <Layout
      title={
        <Box>
          <Typography variant="h4">Endpoint Details</Typography>
          <Breadcrumbs
            mappings={[
              ['DMS', () => goto(routes.RESOURCES_DMS_REPLICATION_INSTANCES)],
              ['Endpoints', () => goto(routes.RESOURCES_DMS_ENDPOINTS)],
              ['Create', null],
            ]}
          />
        </Box>
      }
      actions={
        <ProgressButton color="primary" variant="outlined" type="submit" form="DMSEndpointCreate" loading={isLoading}>
          Submit
        </ProgressButton>
      }
    >
      <Card>
        <CardContent>
          <DMSEndpointForm formId="DMSEndpointCreate" onSubmit={handleCreate} loading={isLoading} />
        </CardContent>
        <CardActions>
          <ProgressButton color="primary" variant="outlined" type="submit" form="DMSEndpointCreate" loading={isLoading}>
            Submit
          </ProgressButton>
        </CardActions>
      </Card>
    </Layout>
  );
};
