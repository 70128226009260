import { useState, useRef, useEffect, ReactElement, MouseEvent } from 'react';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import classnames from 'classnames';
import { ApiKey, OrganizationMember } from '@localstack/types';
import { Theme } from '@mui/material/styles';

import makeStyles from '@mui/styles/makeStyles';
import createStyles from '@mui/styles/createStyles';

import {
  List,
  ListItem,
  ListItemText,
  ListItemAvatar,
  ListItemSecondaryAction,
  Link,
  IconButton,
  Tooltip,
  Radio,
  Box,
  Chip,
  Typography,
  FormControlLabel,
  Checkbox,
} from '@mui/material';

import {
  FileCopyOutlined as CopyIcon,
  EditOutlined as EditIcon,
  CloseOutlined as CancelIcon,
  CheckOutlined as SaveIcon,
  Delete as DeleteIcon,
  Person as PersonIcon,
  Autorenew as AutorenewIcon,
} from '@mui/icons-material';

import { KeyState } from '../../../display/KeyState';

import { AssignKeyForm } from '../AssignKeyForm';

import { ConfirmableButton } from '../../../feedback';

const MAX_NAME_LENGTH = 45;

export interface ApiKeysListProps {
  keys: ApiKey[];
  reveal?: boolean;
  editable?: boolean;
  deletable?: boolean;
  deletableCI?: boolean;
  showState?: boolean;
  selected?: Optional<ApiKey>;
  selectable?: boolean;
  assignable?: boolean;
  members?: OrganizationMember[];
  loading?: boolean;
  onSave?: (id: string, apiKey: ApiKey) => unknown;
  onDelete?: (id: string) => unknown;
  onAssign?: (apiKey: ApiKey, selectedUser: string) => unknown;
  onRotate?: (id: string, notify: boolean) => unknown;
  onSelectKey?: (apiKey: ApiKey) => unknown;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    item: {
      flexWrap: 'wrap',
    },
    editable: {
      ...theme.typography.body1,
      outline: 'none',
      paddingRight: theme.spacing(3),
    },
    itemActive: {
      outline: `1px solid ${theme.palette.primary.main}`,
      borderRadius: '10px',
    },
    itemPassive: {
      filter: 'blur(5px)',
    },
    itemError: {
      outline: `1px solid ${theme.palette.error.main}`,
    },
  }),
);

export const ApiKeysList = ({
  keys,
  editable,
  selected,
  selectable,
  assignable,
  deletable,
  deletableCI,
  reveal,
  showState,
  members,
  loading,
  onSave,
  onDelete,
  onAssign,
  onRotate,
  onSelectKey,
}: ApiKeysListProps): ReactElement => {
  const classes = useStyles();

  const inputRef = useRef<HTMLDivElement>(null);

  const [editing, setEditing] = useState<Optional<string>>(null);
  const [assigning, setAssigning] = useState<Optional<string>>(null);
  const [name, setName] = useState('');
  // Notify user about key rotation checkbox state
  const [notifyUser, setNotifyUser] = useState(false);

  const isNameValid = (name?.length || 0) <= MAX_NAME_LENGTH;

  const isKeyAssignedToMember = (key: ApiKey): boolean =>
    // edge case where key.member_email is set but user not in org anymore
    // most likely happens when hard deleting users from an org in the admin panel
    members?.some((member) => member.email === key.member_email) || false;

  const membersWithoutKeys = members?.filter((member) => !keys.some((key) => key.member_email === member.email));

  useEffect(() => {
    setName(keys.find((key) => key.id === editing)?.name ?? '');
    inputRef?.current?.focus();
  }, [editing]);

  useEffect(() => {
    const handler = (event: KeyboardEvent) => {
      if (event.code === 'Escape' && editing) {
        setEditing(null);
      }
      if (event.code === 'Enter' && editing) {
        if (isNameValid) onSave?.(editing, { name } as ApiKey);
        setEditing(null);
      }
    };

    document.addEventListener('keydown', handler, false);
    return () => document.removeEventListener('keydown', handler, false);
  }, [editing, isNameValid, onSave, name]);

  return (
    <List>
      {keys.map((key) => (
        <ListItem
          key={key.id}
          className={classnames(classes.item, {
            [classes.itemActive]: editing === key.id || (assigning && assigning === key.id),
            [classes.itemPassive]: (editing && editing !== key.id) || (assigning && assigning !== key.id),
            [classes.itemError]: editing === key.id && !isNameValid,
          })}
        >
          {selectable && (
            <ListItemAvatar>
              <Radio
                color="primary"
                value={key.id}
                checked={selected?.id === key.id}
                onChange={() => onSelectKey?.(key)}
              />
            </ListItemAvatar>
          )}
          {showState && (
            <ListItemAvatar>
              <Box mr={2}>
                <Typography component="div">
                  <KeyState apiKey={key} />
                </Typography>
              </Box>
            </ListItemAvatar>
          )}
          <ListItemText
            primaryTypographyProps={{ component: 'div' }}
            secondaryTypographyProps={{ component: 'div' }}
            secondary={(reveal || assigning === key.api_key) && key.api_key}
            primary={
              <>
                {editing !== key.id && (
                  <Box display="flex" alignItems="center">
                    {key.is_ci && <Chip label="CI" size="small" color="primary" />}
                    <span>&nbsp;</span>
                    <Link
                      href="#"
                      onClick={(e: MouseEvent<HTMLAnchorElement>) => {
                        e.preventDefault();
                        if (editable) setEditing(key.id);
                      }}
                      underline="hover"
                    >
                      {key.name || 'Unnamed'}{' '}
                    </Link>
                  </Box>
                )}
                {editing && editing === key.id && (
                  <div
                    className={classes.editable}
                    contentEditable
                    suppressContentEditableWarning
                    spellCheck={false}
                    ref={inputRef}
                    onInput={(e) => setName(e.currentTarget.textContent ?? '')}
                    style={{ flexGrow: 1 }}
                  >
                    {key.name}
                  </div>
                )}
              </>
            }
          />
          <ListItemSecondaryAction>
            {editing && editing === key.id && (
              <>
                <IconButton
                  color="primary"
                  size="small"
                  disabled={loading || !isNameValid}
                  onClick={async () => {
                    if (onSave) await onSave(editing, { name } as ApiKey);
                    setEditing(null);
                  }}
                >
                  <SaveIcon />
                </IconButton>
                <IconButton color="primary" size="small" disabled={loading} onClick={() => setEditing(null)}>
                  <CancelIcon />
                </IconButton>
              </>
            )}
            {!editing && !assigning && (
              <>
                <CopyToClipboard text={key.api_key}>
                  <Tooltip title="Copy API Key">
                    <IconButton color="primary" size="small">
                      <CopyIcon />
                    </IconButton>
                  </Tooltip>
                </CopyToClipboard>
                {editable && (
                  <Tooltip title={isKeyAssignedToMember(key) ? 'Assigned keys can not be renamed' : 'Rename key'}>
                    <span>
                      <IconButton
                        color="primary"
                        size="small"
                        disabled={loading || isKeyAssignedToMember(key)}
                        onClick={() => setEditing(key.id)}
                      >
                        <EditIcon />
                      </IconButton>
                    </span>
                  </Tooltip>
                )}
                {assignable && (
                  <Tooltip title={isKeyAssignedToMember(key) ? 'Key already assigned' : 'Assign to User'}>
                    <span>
                      <IconButton
                        color="primary"
                        size="small"
                        onClick={() => setAssigning(key.id)}
                        disabled={loading || isKeyAssignedToMember(key)}
                      >
                        <PersonIcon />
                      </IconButton>
                    </span>
                  </Tooltip>
                )}
                {(key.is_ci ? deletableCI : deletable) && (
                  <Tooltip title="Delete key">
                    <IconButton
                      color="primary"
                      size="small"
                      disabled={loading || key.deleted}
                      onClick={() => onDelete?.(key.id)}
                    >
                      <DeleteIcon />
                    </IconButton>
                  </Tooltip>
                )}
                {onRotate && (
                  <ConfirmableButton
                    componentType="IconButton"
                    disabled={loading || key.deleted}
                    title={`Rotate ${key.api_key} key?`}
                    onClick={() => onRotate(key.id, notifyUser)}
                    text={
                      <>
                        <Typography variant="body1">Key will be rotated and a new key will be assigned</Typography>
                        <FormControlLabel
                          control={
                            <Checkbox
                              color="primary"
                              checked={notifyUser}
                              onChange={() => setNotifyUser(!notifyUser)}
                            />
                          }
                          label={<Typography variant="body1">Notify the user</Typography>}
                        />
                      </>
                    }
                    style={{ padding: 0 }}
                  >
                    <IconButton color="primary" size="small" disabled={loading || key.deleted}>
                      <AutorenewIcon />
                    </IconButton>
                  </ConfirmableButton>
                )}
              </>
            )}
          </ListItemSecondaryAction>
          {assigning === key.id && onAssign && (
            <AssignKeyForm
              apiKey={key}
              members={membersWithoutKeys || []}
              onAssign={onAssign}
              setAssigning={setAssigning}
            />
          )}
        </ListItem>
      ))}
    </List>
  );
};
