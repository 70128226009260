import { useState, ReactElement } from 'react';
import { Button, Card, CardActions, CardContent, CardHeader, Alert } from '@mui/material';
import { Edit as EditIcon } from '@mui/icons-material';
import { AddressForm, AddressPreview } from '@localstack/ui';
import { Address as AddressType } from '@localstack/types';
import { OrganizationsService, useApiEffect, UserService } from '@localstack/services';

import { useAuthProvider } from '~/hooks/useAuthProvider';

export interface AddressProps {
  className?: string;
}

export const Address = ({ className }: AddressProps): ReactElement => {
  const [showAddressForm, setShowAddressForm] = useState(false);

  const { userInfo } = useAuthProvider();

  const { updateOrganization, isLoading: isOrgMutating } = useApiEffect(OrganizationsService, ['updateOrganization'], {
    revalidate: ['listOrganizations'],
    revalidateOtherClient: { client: UserService, methods: ['getUser'] },
  });

  const organization = userInfo?.org;

  const handleSubmit = async (address: AddressType) => {
    if (!organization) {
      return;
    }

    return updateOrganization(organization.id, { ...organization, ...address });
  };

  return (
    <Card className={className}>
      <CardHeader
        title="Billing Address"
        action={
          <Button
            size="small"
            color="primary"
            startIcon={<EditIcon />}
            onClick={() => setShowAddressForm(!showAddressForm)}
          >
            Edit Address
          </Button>
        }
      />
      {showAddressForm ? (
        <AddressForm
          showName
          showDiscardButton
          address={organization ?? {}}
          loading={isOrgMutating}
          onSubmit={handleSubmit}
          onDiscard={() => setShowAddressForm(false)}
          wrappers={{ ContentWrapper: CardContent, ActionsWrapper: CardActions }}
        />
      ) : (
        <CardContent>
          {!isOrgMutating && !organization?.address && (
            <Alert severity="info" variant="outlined">
              Address is incomplete or not provided
            </Alert>
          )}
          {organization?.address && <AddressPreview address={organization} />}
        </CardContent>
      )}
    </Card>
  );
};
