import { ReactElement } from 'react';
import { Link } from '@mui/material';
import { EC2Instance } from '@localstack/types';
import { getSchema } from '@localstack/services';

import { MagicTable } from '../../magic/MagicTable';

export type EC2InstancesTableProps = {
  selectable?: boolean;
  loading?: boolean;
  instances: EC2Instance[];
  onViewInstance?: (instance: EC2Instance) => unknown;
  onSelect?: (names: string[]) => void;
};

export const EC2InstancesTable = ({
  instances,
  selectable = true,
  loading,
  onViewInstance,
  onSelect,
}: EC2InstancesTableProps): ReactElement => (
  <MagicTable
    schema={getSchema('EC2')}
    entry="Instance"
    idAttribute="InstanceId"
    rows={instances}
    selectable={selectable}
    onSelect={onSelect}
    order={['InstanceId', 'State']}
    loading={loading}
    externalFields={{
      InstanceId: (row) => (
        <Link onClick={() => onViewInstance && onViewInstance(row)} underline="hover">
          {row.InstanceId}
        </Link>
      ),
      State: (row) => (
        <>
          {row.State?.Name} ({row.State?.Code})
        </>
      ),
    }}
  />
);
