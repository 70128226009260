import { StateMachineDefinitionState, StateMachineDefinitionStates, StateMachineStateType } from '@localstack/types';

import { VisitorNode, StateVisitor } from './types';

const extractStateOrFail = (id: string, states: StateMachineDefinitionStates): StateMachineDefinitionState => {
  const state = states[id];

  if (!state) {
    throw new Error(`State ${id} not found in state machine definition.`);
  }

  return state;
};

const taskVisitor: StateVisitor = (
  id: string,
  state: StateMachineDefinitionState,
  states: StateMachineDefinitionStates,
) => {
  return {
    id,
    state,
    next: state.Next ? [{ id: state.Next, state: extractStateOrFail(state.Next, states) }] : [],
  };
};

const parallelVisitor: StateVisitor = (
  id: string,
  state: StateMachineDefinitionState,
  states: StateMachineDefinitionStates,
) => {
  const next: VisitorNode[] = [];

  const children: VisitorNode[] = (state.Branches ?? []).reduce(
    (memo, branch) => [
      ...memo,
      {
        id: branch.StartAt,
        state: extractStateOrFail(branch.StartAt, branch.States),
        stateMachine: branch,
        children: [],
        next: [],
      },
    ],
    [],
  );

  if (state.Next) {
    next.push({ id: state.Next, state: extractStateOrFail(state.Next, states) });
  }

  return { id, state, next, children };
};

const mapVisitor: StateVisitor = (
  id: string,
  state: StateMachineDefinitionState,
  states: StateMachineDefinitionStates,
) => {
  const next: VisitorNode[] = [];
  const children: VisitorNode[] = [];

  const subStateMachine = state.ItemProcessor || state.Iterator;

  if (subStateMachine) {
    children.push({
      id: subStateMachine.StartAt,
      state: extractStateOrFail(subStateMachine.StartAt, subStateMachine.States),
      stateMachine: subStateMachine ?? {},
    });
  }

  if (state.Next) {
    next.push({ id: state.Next, state: extractStateOrFail(state.Next, states) });
  }

  return { id, state, next, children };
};

const choiceVisitor: StateVisitor = (
  id: string,
  state: StateMachineDefinitionState,
  states: StateMachineDefinitionStates,
) => {
  const next: VisitorNode[] = (state.Choices ?? []).map((choice) => ({
    id: choice.Next,
    state: extractStateOrFail(choice.Next, states),
  }));

  if (state.Default) {
    next.push({ id: state.Default, state: extractStateOrFail(state.Default, states) });
  }

  return { id, type: state.Type, state, next };
};

const buildSimpleVisitor =
  (type: string) => (id: string, state: StateMachineDefinitionState, states: StateMachineDefinitionStates) => ({
    id,
    state,
    type,
    next: state.Next ? [{ id: state.Next, state: extractStateOrFail(state.Next, states) }] : [],
  });

export const STATE_VISITORS: Record<StateMachineStateType, StateVisitor> = {
  Task: taskVisitor,
  Parallel: parallelVisitor,
  Map: mapVisitor,
  Choice: choiceVisitor,
  Wait: buildSimpleVisitor('Wait'),
  Succeed: buildSimpleVisitor('Succeed'),
  Fail: buildSimpleVisitor('Fail'),
  Pass: buildSimpleVisitor('Pass'),
  Start: buildSimpleVisitor('Start'),
  End: buildSimpleVisitor('End'),
};
