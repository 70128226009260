import { getSchema } from '@localstack/services';
import { GetTranscriptionJobResponse } from '@localstack/types';
import { ReactElement } from 'react';

import { MagicDetails } from '../../magic/MagicDetails';

export type TranscriptionJobDetailsProps = {
  job?: Optional<GetTranscriptionJobResponse>;
};

export const TranscriptionJobDetails = ({ job }: TranscriptionJobDetailsProps): ReactElement => (
  <MagicDetails
    data={job?.TranscriptionJob}
    schema={getSchema('TranscribeService')}
    entry="TranscriptionJob"
    title="Transcription Job Details"
  />
);
