import { ReactElement } from 'react';
import { useRoutes, useAwsGetter } from '@localstack/services';
import { useParams } from 'react-router-dom';
import { Card, CardContent, Box, Typography, Button } from '@mui/material';
import { Breadcrumbs, EventBridgeTargetDetails } from '@localstack/ui';

import { DEFAULT_EVENTBRIDGE_ROUTES } from '@localstack/constants/src';

import { EventBridgeProps } from './types';

export const EventBridgeTarget = ({
  Layout,
  clientOverrides,
  routes = DEFAULT_EVENTBRIDGE_ROUTES,
}: EventBridgeProps): ReactElement => {
  const { goto } = useRoutes();
  const { busName, ruleName, targetId } = useParams<'busName' | 'ruleName' | 'targetId'>();

  const { data: targets } = useAwsGetter(
    'EventBridge',
    'listTargetsByRule',
    [{ Rule: ruleName, EventBusName: busName }],
    { clientOverrides },
  );
  const target = targets?.Targets?.find((t) => t.Id === targetId);

  return (
    <Layout
      documentTitle="EventsBridge: Target Details"
      title={
        <Box>
          <Typography variant="h4">Target Details</Typography>
          <Breadcrumbs
            mappings={[
              ['EventBridge', () => goto(routes.RESOURCES_EVENT_BRIDGE)],
              ['Buses', () => goto(routes.RESOURCES_EVENT_BRIDGE_BUSES)],
              [busName, () => goto(routes.RESOURCES_EVENT_BRIDGE_BUS, { busName })],
              ['Rules', () => goto(routes.RESOURCES_EVENT_BRIDGE_RULES, { busName })],
              [ruleName, () => goto(routes.RESOURCES_EVENT_BRIDGE_RULE_TARGETS, { ruleName, busName })],
              ['Targets', () => goto(routes.RESOURCES_EVENT_BRIDGE_RULE_TARGETS, { ruleName, busName })],
              [targetId, null],
            ]}
          />
        </Box>
      }
      actions={
        <>
          <Button
            onClick={() => goto(routes.RESOURCES_EVENT_BRIDGE_RULE_TARGET_UPDATE, { busName, ruleName, targetId })}
          >
            Edit Target
          </Button>
        </>
      }
    >
      <Card>
        <CardContent>
          <EventBridgeTargetDetails target={target} />
        </CardContent>
      </Card>
    </Layout>
  );
};
