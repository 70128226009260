import { StateMachineStateType } from '@localstack/types';

/**
 * Approximate node sizes to simplify auto-layouting.
 *
 * Note the grouping nodes are calculated automatically.
 * In the future we may consider some smarter approximation approaches based on the content.
 */
export const NODE_SIZES: Record<StateMachineStateType, { width: number; height: number }> = {
  Start: { width: 50, height: 50 },
  End: { width: 50, height: 50 },
  Task: { width: 180, height: 50 },
  Parallel: { width: 180, height: 50 }, // parallel label, not the group itself
  Map: { width: 180, height: 50 }, // map label, not the group itself
  Choice: { width: 180, height: 50 }, // choice label, not the group itself
  Wait: { width: 180, height: 50 },
  Succeed: { width: 180, height: 50 },
  Fail: { width: 180, height: 50 },
  Pass: { width: 180, height: 50 },
};
