import { Badge, Box, Theme, Typography } from '@mui/material';
import { ReactElement, Fragment } from 'react';
import { makeStyles, createStyles } from '@mui/styles';

type stringNumberMap = {
  [x: string]: number;
};
type ResourceCounterProp = {
  resourceTypeCount: stringNumberMap;
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    darker: {
      textColor: theme.palette.text.secondary,
      color: theme.palette.text.secondary,
    },
  }),
);

export const singleCounter = (resourceTypeCount: stringNumberMap): ReactElement[] =>
  Object.entries(resourceTypeCount).map(([res, count], key) => (
    <Box key={key} display="flex" alignItems="center">
      <Typography variant="body1">{res}</Typography>
      <Badge
        color="secondary"
        badgeContent={count}
        sx={{
          marginInline: 2,
          '& .MuiBadge-badge': {
            fontSize: '0.7rem',
            minWidth: '18px',
            height: '18px',
          },
        }}
      />
    </Box>
  ));

export const ResourceCounter = ({ resourceTypeCount }: ResourceCounterProp): ReactElement => {
  const resourceCount = singleCounter(resourceTypeCount);
  const classes = useStyles();
  return (
    <Typography sx={{ paddingLeft: 1 }} flexDirection="row" display="flex">
      {resourceCount.map((item, index) =>
        index > 0 ? (
          <Fragment key={index}>
            <span className={classes.darker} style={{ paddingRight: 5 }}>
              {' '}
              ·{' '}
            </span>
            {item}
          </Fragment>
        ) : (
          <Fragment key={index}>{item}</Fragment>
        ),
      )}
    </Typography>
  );
};
