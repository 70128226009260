/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CLIMetadata } from '@localstack/types';
import type { DesktopMetadata } from '@localstack/types';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class GithubMetadataService {
  /**
   * @returns CLIMetadata
   * @throws ApiError
   */
  public static getCliMetadata(): CancelablePromise<CLIMetadata> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/v1/github-metadata/cli',
    });
  }

  /**
   * @returns DesktopMetadata
   * @throws ApiError
   */
  public static getDesktopMetadata(): CancelablePromise<DesktopMetadata> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/v1/github-metadata/desktop',
    });
  }
}
