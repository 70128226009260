import { ReactElement } from 'react';

import { Dialog, DialogContent, DialogTitle, DialogContentText, DialogActions, Button } from '@mui/material';

type Props = {
  open: boolean;
  onConfirm: () => void;
  onClose: () => void;
};

export const MissingDetailsModal = ({ open, onClose, onConfirm }: Props): ReactElement => (
  <Dialog open={open} onClose={onClose}>
    <DialogTitle>We need just a little bit more details...</DialogTitle>
    <DialogContent>
      <DialogContentText>
        Please make sure your profile details, payment method and billing address are complete and up to date before
        starting this plan.
      </DialogContentText>
    </DialogContent>
    <DialogActions>
      <Button variant="contained" onClick={onClose}>
        Close
      </Button>
      <Button variant="contained" color="primary" onClick={onConfirm}>
        To Profile
      </Button>
    </DialogActions>
  </Dialog>
);

export default MissingDetailsModal;
