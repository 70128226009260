import { ExternalLink } from '@localstack/constants';
import { NavLink } from '@localstack/ui';
import { Alert, Card, CardContent, CardHeader, Link } from '@mui/material';
import { ReactElement } from 'react';

import { AppRoute } from '~/config';

import { ContainedCustomerLayout } from '~/layouts';

export const LegacyCIKeys = (): ReactElement => (
  <ContainedCustomerLayout title="Legacy CI Keys">
    <Card>
      <CardHeader title="Legacy CI Keys" />
      <CardContent>
        <Alert severity="info">
          Legacy API keys have been migrated to the new CI Auth token system. You can manage all your CI auth tokens on
          the <NavLink to={AppRoute.SETTINGS_AUTH_TOKENS}>Auth Tokens</NavLink> page.
          <br />
          <br />
          All existing legacy CI keys will remain functional until we start phasing them out in early 2025. The new auth
          tokens also work with older version of LocalStack. See our{' '}
          <Link href={ExternalLink.DOCS_AUTH_TOKEN} target="_blank">
            Auth Tokens
          </Link>{' '}
          on how to set the auth token up in these situations
        </Alert>
      </CardContent>
    </Card>
  </ContainedCustomerLayout>
);
