import { Extension, LocalStackEvent, EnrichedExtension } from '@localstack/types';
import { useCallback, useEffect, useMemo, useState } from 'react';

import { useSnackbar } from '../util';

import { AwsClientOverrides, useAwsEffect, useAwsGetter } from '../aws';

import { useLocalStackEvents } from './instances';

type useExtensionHandlerProps = {
  extensions: Optional<EnrichedExtension[]>;
  clientOverrides: AwsClientOverrides;
};

type useExtensionHandlerReturn = {
  install: (name: string) => void;
  uninstall: (name: string) => void;
  isExtensionInstalled: (name: string) => boolean | undefined;
  isManagingExtension: boolean;
  hasManagingExtensionError: boolean;
  displayedExtensions: Optional<Extension[]>;
  lastInteractedExtension: Optional<string>;
  logs: LocalStackEvent[] | undefined;
  mutatingExtension: Optional<string>;
};
export const useExtensionHandler = ({
  extensions,
  clientOverrides,
}: useExtensionHandlerProps): useExtensionHandlerReturn => {
  const { showSnackbar } = useSnackbar();

  const { logs, setLogs } = useLocalStackEvents();

  const [addExtension, setAddExtension] = useState(true);
  const [mutatingExtension, setMutatingExtension] = useState<string>();
  const [lastExtension, setLastExtension] = useState<string>();

  const { data: installedExtensions } = useAwsGetter('LocalStack', 'listExtensions', [], {
    silentErrors: true,
    swrOverrides: {
      refreshInterval: 1000,
      errorRetryInterval: 1000,
      shouldRetryOnError: true,
      onErrorRetry: (_error, _key, _config, revalidate: () => void) => {
        setTimeout(() => revalidate(), 1000);
      },
    },
    clientOverrides,
  });

  const mappedInstalledExtensions: Optional<Extension[]> = useMemo(
    () =>
      installedExtensions?.map((extension: Extension) => ({
        ...extension,
        ...extension.distribution,
        description: extension.distribution?.description?.split('\n')[3],
        display_name: extension.distribution?.summary?.split('LocalStack Extension: ')[1] ?? extension.name,
        plugin_name: extension.name,
      })),
    [installedExtensions],
  );

  const installedExtensionNames = useMemo(
    () => mappedInstalledExtensions?.map((extension: Extension) => extension.name),
    [mappedInstalledExtensions],
  );

  const isExtensionInstalled = useCallback(
    (name: string) => name !== undefined && installedExtensionNames?.includes(name),
    [installedExtensionNames],
  );

  const displayedExtensions: Optional<Extension[]> = useMemo(
    () =>
      [...(extensions || []), ...(mappedInstalledExtensions || [])].filter(
        (item, index, self) => index === self.findIndex((ext) => ext.name === item.name),
      ),
    [installedExtensionNames, extensions],
  );

  const displayedWithDevExtensions: Optional<EnrichedExtension[]> = useMemo(
    () =>
      displayedExtensions?.map((ext) => ({
        ...ext,
        isDev: !extensions?.some((element) => element.plugin_name === ext.plugin_name),
      })),
    [displayedExtensions],
  );

  const {
    installExtension,
    uninstallExtension,
    isLoading: managingExtension,
    hasError: hasManagingExtensionError,
  } = useAwsEffect('LocalStack', ['installExtension', 'uninstallExtension'], { clientOverrides });

  useEffect(() => {
    if (mutatingExtension && addExtension && isExtensionInstalled(mutatingExtension)) {
      showSnackbar({
        message: `Successfully installed ${mutatingExtension}`,
        severity: 'success',
      });
      setMutatingExtension(undefined);
    }
  }, [installedExtensions]);

  useEffect(() => {
    if (mutatingExtension && !addExtension && !isExtensionInstalled(mutatingExtension)) {
      showSnackbar({
        message: `Successfully uninstalled ${mutatingExtension}`,
        severity: 'success',
      });
      setMutatingExtension(undefined);
    }
  }, [installedExtensions]);

  const hasErrorManaging = useMemo(() => {
    if (hasManagingExtensionError) return true;
    const errorInLogs = logs?.some((log) => log.event === 'error' || log.event === 'exception');
    return !!errorInLogs;
  }, [logs, hasManagingExtensionError]);

  useEffect(() => {
    if (hasErrorManaging) {
      setMutatingExtension(undefined);
    }
  }, [hasErrorManaging]);

  const onInstall = (name: string) => {
    setAddExtension(true);
    setMutatingExtension(name);
    setLastExtension(name);
    installExtension(name, setLogs);
  };

  const onUninstall = (name: string) => {
    setAddExtension(false);
    setMutatingExtension(name);
    setLastExtension(name);
    uninstallExtension(name, setLogs);
  };

  return {
    install: onInstall,
    uninstall: onUninstall,
    isExtensionInstalled,
    isManagingExtension: managingExtension,
    hasManagingExtensionError,
    displayedExtensions: displayedWithDevExtensions,
    lastInteractedExtension: lastExtension,
    logs,
    mutatingExtension,
  };
};
