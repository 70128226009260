import { ReactElement } from 'react';
import { EC2Instance } from '@localstack/types';
import { getSchema } from '@localstack/services';

import { MagicDetails } from '../../magic/MagicDetails';

export interface EC2InstanceDetailsProps {
  instance?: Optional<Partial<EC2Instance>>;
}

export const EC2InstanceDetails = ({ instance }: EC2InstanceDetailsProps): ReactElement => (
  <MagicDetails data={instance} schema={getSchema('EC2')} entry="Instance" title="EC2 Instance Details" />
);
