import { useContext } from 'react';
import { useParams } from 'react-router-dom';
import { LocalStackInstance } from '@localstack/types';

import { INSTANCE_DEFAULT_ID } from '@localstack/constants';

import { GlobalStateContext } from '../context/global';

const PRESERVE_PARAMS = ['region'];

export const buildRoute = (
  route?: string,
  params?: Record<string, Optional<string | number | boolean>>,
  query?: Record<string, Optional<string>>,
): string => {
  const url = window.location.href;
  const searchParams = new URLSearchParams(url.substring(url.lastIndexOf('?') + 1));
  const paramEntries = {
    ...Object.fromEntries(searchParams.entries()),
    ...query,
  };
  let queryString = '';
  Object.keys(paramEntries).forEach((key) => {
    if (PRESERVE_PARAMS.includes(key)) {
      queryString += `${key}=${paramEntries[key]}`;
    }
  });

  if (!params) return (route || '/').toString();

  let builtRoute = `${Object.entries(params).reduce(
    (newRoute, [key, value]) => newRoute.replace(`:${key}`, encodeURIComponent((value || '').toString())),
    (route || '/').toString(),
  )}`;

  builtRoute = builtRoute.includes('?') ? builtRoute : `${builtRoute}${queryString ? `?${queryString}` : ''}`;

  return builtRoute;
};

export const buildInstanceRoute = (
  route?: string,
  params?: Record<string, Optional<string | number | boolean>>,
): string => {
  const { iid = INSTANCE_DEFAULT_ID } = useParams<'iid'>() as { iid: string };
  return buildRoute(route, { ...params, iid });
};

export const getSelectedInstance = (): LocalStackInstance | undefined => {
  const { iid = INSTANCE_DEFAULT_ID } = useParams<'iid'>() as { iid: string };
  // look up endpoint from `iid` (instance id) in defined target instances
  const { instances } = useContext(GlobalStateContext);
  const instance = instances.find((inst) => inst.id === iid);
  return instance;
};
