import { ReactElement } from 'react';
import { Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import createStyles from '@mui/styles/createStyles';
import classnames from 'classnames';

import { ThemeType } from '@localstack/constants';

import LogoSvg from './localstack-icon.svg';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      '& .text': {
        fill: theme.palette.mode === ThemeType.DARK ? theme.palette.common.white : undefined,
      },
      marginBottom: theme.spacing(-1),
    },
  }),
);

export const LogoIcon = (): ReactElement => {
  const classes = useStyles();

  return <LogoSvg className={classnames(classes.root)} />;
};
