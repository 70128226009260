import { ReactElement, useState } from 'react';
import { Box, Tooltip } from '@mui/material';
import { Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import createStyles from '@mui/styles/createStyles';
import { Check as OkIcon, Close as ErrorIcon } from '@mui/icons-material';
import { StackAwsApiCall } from '@localstack/types';

import { ClientIcon } from '../../../display/ClientIcon';
import { AwsServiceIcon } from '../../../display/AwsServiceIcon';
import { MagicTable } from '../../magic/MagicTable';
import { formatUserAgent } from '../common/index';

const DEFAULT_PAGE_SIZE = 25;

const SCHEMA = {
  shapes: {
    ListEvents: {
      type: 'structure',
      members: {
        virtual_id: {
          type: 'string',
        },
        session_id: {
          type: 'string',
        },
        service: {
          type: 'string',
        },
        operation: {
          type: 'string',
        },
        status_code: {
          type: 'string',
        },
        server_time: {
          type: 'timestamp',
        },
        user_agent: {
          type: 'string',
        },
      },
    },
  },
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    success: {
      color: theme.palette.success.main,
    },
    error: {
      color: theme.palette.error.main,
    },
    tooltip: {
      fontSize: theme.typography.body2.fontSize,
    },
  }),
);

export interface EventsTableProps {
  rows: StackAwsApiCall[];
  loading?: boolean;
  onViewEvent?: (id: string) => unknown;
}

export const EventsTable = ({ rows, loading }: EventsTableProps): ReactElement => {
  const classes = useStyles();

  const [pageSize, setPageSize] = useState(DEFAULT_PAGE_SIZE);

  const rowsWithId = rows.map((row, idx) => ({
    ...row,
    id: `${row.server_time}:${row.session_id}:${row.service}:${row.operation}:${idx}`,
  }));

  return (
    <MagicTable
      schema={SCHEMA}
      entry="ListEvents"
      idAttribute="id"
      rows={rowsWithId}
      selectable={false}
      order={['service', 'operation', 'status_code', 'server_time', 'user_agent']}
      filterColumns={['service', 'operation', 'status_code', 'server_time', 'user_agent']}
      customWidths={{ user_agent: 150 }}
      loading={loading}
      pageSize={pageSize}
      onPageSizeChange={(size: number) => setPageSize(size)}
      externalFields={{
        service: (row) => (
          <>
            <AwsServiceIcon code={row.service} />
            {row.service}
          </>
        ),
        status_code: (row) => {
          const isSuccess = row.status_code < 400;
          const BoxElem = (
            <Box display="flex" alignItems="center" gap={5}>
              {isSuccess ? <OkIcon className={classes.success} /> : <ErrorIcon className={classes.error} />}
              <span
                style={{
                  lineHeight: 'normal',
                  textDecoration: isSuccess ? 'normal' : 'underline dotted',
                  textUnderlineOffset: '0.25em',
                }}
              >
                {row.status_code}
              </span>
            </Box>
          );

          return isSuccess ? (
            BoxElem
          ) : (
            <Tooltip title={isSuccess ? 'Success' : (row.err_msg ?? '')} classes={{ tooltip: classes.tooltip }}>
              {BoxElem}
            </Tooltip>
          );
        },
        user_agent: (row) => <ClientIcon code={formatUserAgent(row.user_agent)} />,
      }}
    />
  );
};
