/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { IdentityProvider } from '@localstack/types';
import type { LicenseAssignment } from '@localstack/types';
import type { MyLicenseAssignmentInfo } from '@localstack/types';
import type { Organization } from '@localstack/types';
import type { OrganizationMember } from '@localstack/types';
import type { OrganizationSettings } from '@localstack/types';
import type { SelfAssignmentPreflight } from '@localstack/types';
import type { TosAcceptanceContext } from '@localstack/types';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class OrganizationsService {
  /**
   * @returns Organization
   * @throws ApiError
   */
  public static listOrganizations(): CancelablePromise<Array<Organization>> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/v1/organizations',
    });
  }

  /**
   * @returns Organization
   * @throws ApiError
   */
  public static createOrganization(): CancelablePromise<Organization> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/v1/organizations',
    });
  }

  /**
   * @param orgId
   * @returns Organization
   * @throws ApiError
   */
  public static deleteOrganization(orgId: any): CancelablePromise<Organization> {
    return __request(OpenAPI, {
      method: 'DELETE',
      url: '/v1/organizations/{org_id}',
      path: {
        org_id: orgId,
      },
    });
  }

  /**
   * @param orgId
   * @param requestBody
   * @returns Organization
   * @throws ApiError
   */
  public static updateOrganization(orgId: any, requestBody?: Organization): CancelablePromise<Organization> {
    return __request(OpenAPI, {
      method: 'PATCH',
      url: '/v1/organizations/{org_id}',
      path: {
        org_id: orgId,
      },
      body: requestBody,
      mediaType: 'application/json',
    });
  }

  /**
   * @param orgId
   * @param requestBody
   * @returns Organization
   * @throws ApiError
   */
  public static updateOrganizationSettings(
    orgId: any,
    requestBody?: OrganizationSettings,
  ): CancelablePromise<Organization> {
    return __request(OpenAPI, {
      method: 'PATCH',
      url: '/v1/organizations/{org_id}/settings',
      path: {
        org_id: orgId,
      },
      body: requestBody,
      mediaType: 'application/json',
    });
  }

  /**
   * @param orgId
   * @param requestBody
   * @returns OrganizationMember
   * @throws ApiError
   */
  public static inviteMember(
    orgId: any,
    requestBody?: {
      user_email: string;
      user_name: string;
      try_assign_license_on_join?: boolean;
    },
  ): CancelablePromise<OrganizationMember> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/v1/organizations/{org_id}/members',
      path: {
        org_id: orgId,
      },
      body: requestBody,
      mediaType: 'application/json',
    });
  }

  /**
   * @param orgId
   * @param membershipId
   * @param requestBody
   * @returns OrganizationMember
   * @throws ApiError
   */
  public static confirmMembership(
    orgId: any,
    membershipId: any,
    requestBody?: {
      password: string;
      job_title?: string;
      github_username?: string;
      newsletter?: boolean;
      tos_acceptance_context: TosAcceptanceContext;
    },
  ): CancelablePromise<OrganizationMember> {
    return __request(OpenAPI, {
      method: 'PUT',
      url: '/v1/organizations/{org_id}/members/{membership_id}',
      path: {
        org_id: orgId,
        membership_id: membershipId,
      },
      body: requestBody,
      mediaType: 'application/json',
    });
  }

  /**
   * @param orgId
   * @param membershipId
   * @param requestBody
   * @returns Organization
   * @throws ApiError
   */
  public static updateMembership(
    orgId: any,
    membershipId: any,
    requestBody?: OrganizationMember,
  ): CancelablePromise<Organization> {
    return __request(OpenAPI, {
      method: 'PATCH',
      url: '/v1/organizations/{org_id}/members/{membership_id}',
      path: {
        org_id: orgId,
        membership_id: membershipId,
      },
      body: requestBody,
      mediaType: 'application/json',
    });
  }

  /**
   * @param orgId
   * @param userId
   * @returns OrganizationMember
   * @throws ApiError
   */
  public static removeMembership(orgId: any, userId: any): CancelablePromise<OrganizationMember> {
    return __request(OpenAPI, {
      method: 'DELETE',
      url: '/v1/organizations/{org_id}/members/{user_id}',
      path: {
        org_id: orgId,
        user_id: userId,
      },
    });
  }

  /**
   * @param email
   * @returns IdentityProvider
   * @throws ApiError
   */
  public static listIdentityProvidersForEmail(email: any): CancelablePromise<Array<IdentityProvider>> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/v1/organizations/idps/{email}',
      path: {
        email: email,
      },
    });
  }

  /**
   * @param orgId
   * @returns IdentityProvider
   * @throws ApiError
   */
  public static listIdentityProviders(orgId: any): CancelablePromise<Array<IdentityProvider>> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/v1/organizations/{org_id}/idps',
      path: {
        org_id: orgId,
      },
    });
  }

  /**
   * @param orgId
   * @param idpNameOrId
   * @returns IdentityProvider
   * @throws ApiError
   */
  public static getIdentityProvider(orgId: any, idpNameOrId: any): CancelablePromise<IdentityProvider> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/v1/organizations/{org_id}/idps/{idp_name_or_id}',
      path: {
        org_id: orgId,
        idp_name_or_id: idpNameOrId,
      },
    });
  }

  /**
   * @param orgId
   * @param idpName
   * @param requestBody
   * @returns IdentityProvider
   * @throws ApiError
   */
  public static storeIdentityProvider(
    orgId: any,
    idpName: any,
    requestBody?: IdentityProvider,
  ): CancelablePromise<IdentityProvider> {
    return __request(OpenAPI, {
      method: 'PUT',
      url: '/v1/organizations/{org_id}/idps/{idp_name}',
      path: {
        org_id: orgId,
        idp_name: idpName,
      },
      body: requestBody,
      mediaType: 'application/json',
    });
  }

  /**
   * @param orgId
   * @param idpName
   * @returns IdentityProvider
   * @throws ApiError
   */
  public static deleteIdentityProvider(orgId: any, idpName: any): CancelablePromise<IdentityProvider> {
    return __request(OpenAPI, {
      method: 'DELETE',
      url: '/v1/organizations/{org_id}/idps/{idp_name}',
      path: {
        org_id: orgId,
        idp_name: idpName,
      },
    });
  }

  /**
   * @param orgId
   * @returns LicenseAssignment
   * @throws ApiError
   */
  public static listLicenseAssignments(orgId: any): CancelablePromise<Array<LicenseAssignment>> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/v1/organizations/{org_id}/license-assignments',
      path: {
        org_id: orgId,
      },
    });
  }

  /**
   * @param orgId
   * @param requestBody
   * @returns LicenseAssignment
   * @throws ApiError
   */
  public static assignLicense(
    orgId: any,
    requestBody?: {
      user_id: string;
      subscription_id?: string;
    },
  ): CancelablePromise<LicenseAssignment> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/v1/organizations/{org_id}/license-assignments',
      path: {
        org_id: orgId,
      },
      body: requestBody,
      mediaType: 'application/json',
    });
  }

  /**
   * @param orgId
   * @returns MyLicenseAssignmentInfo
   * @throws ApiError
   */
  public static getUserLicenseAssignment(orgId: any): CancelablePromise<MyLicenseAssignmentInfo> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/v1/organizations/{org_id}/my-license-assignment',
      path: {
        org_id: orgId,
      },
    });
  }

  /**
   * @param orgId
   * @returns LicenseAssignment
   * @throws ApiError
   */
  public static selfAssignLicense(orgId: any): CancelablePromise<LicenseAssignment> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/v1/organizations/{org_id}/my-license-assignment',
      path: {
        org_id: orgId,
      },
    });
  }

  /**
   * @param orgId
   * @returns SelfAssignmentPreflight
   * @throws ApiError
   */
  public static preflightSelfAssignLicense(orgId: any): CancelablePromise<SelfAssignmentPreflight> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/v1/organizations/{org_id}/my-license-assignment/preflight',
      path: {
        org_id: orgId,
      },
    });
  }

  /**
   * @param orgId
   * @param assignmentId
   * @returns LicenseAssignment
   * @throws ApiError
   */
  public static unassignLicense(orgId: any, assignmentId: any): CancelablePromise<LicenseAssignment> {
    return __request(OpenAPI, {
      method: 'DELETE',
      url: '/v1/organizations/{org_id}/license-assignments/{assignment_id}',
      path: {
        org_id: orgId,
        assignment_id: assignmentId,
      },
    });
  }
}
