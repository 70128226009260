import { v4 as uuid } from 'uuid';
import { StackInfo, StackAwsApiCall } from '@localstack/types';
import { sample } from 'lodash';

export const STACK_1: StackInfo = {
  api_key: '7KEBCeCiXH',
  user_email: 'alice@example.com',
  user_name: 'Alice Nakamura',
  duration_in_seconds: 1734,
  is_ci: false,
  is_docker: true,
  number_of_api_calls_error: 0,
  number_of_api_calls_success: 19,
  number_of_services: 3,
  server_time_utc: '2022-08-17T04:59:54.936000',
  session_id: 'qweqwe-e9a9-40e3-9b2c-67f1d60b1168',
  system: 'Linux',
  top_user_agent:
    'Mozilla/5.0 (X11; Linux x86_64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/104.0.0.0 Safari/537.36',
  version: '1.0.5.dev:3f7a7fe7',
};

export const STACK_1b: StackInfo = {
  api_key: '7KEBCeCiXH',
  user_email: 'alice@example.com',
  user_name: 'Alice Nakamura',
  duration_in_seconds: 1734,
  is_ci: false,
  is_docker: true,
  number_of_api_calls_error: 0,
  number_of_api_calls_success: 19,
  number_of_services: 3,
  server_time_utc: '2022-08-17T04:59:54.936000',
  session_id: '123456-123456-123456',
  system: 'Linux',
  top_user_agent:
    'Mozilla/5.0 (X11; Linux x86_64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/104.0.0.0 Safari/537.36',
  version: '1.0.5.dev:3f7a7fe7',
};

export const STACK_2: StackInfo = {
  api_key: '7KEBCeCiXH',
  api_key_name: 'some api key',
  duration_in_seconds: 1734,
  is_ci: false,
  is_docker: true,
  number_of_api_calls_error: 0,
  number_of_api_calls_success: 19,
  number_of_services: 3,
  server_time_utc: '2022-08-17T04:59:54.936000',
  session_id: uuid(),
  system: 'Linux',
  top_user_agent:
    'Mozilla/5.0 (X11; Linux x86_64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/104.0.0.0 Safari/537.36',
  version: '1.0.5.dev:3f7a7fe7',
};

export const STACK_3: StackInfo = {
  api_key: '7KEBCeCiXH',
  duration_in_seconds: 1734,
  is_ci: true,
  is_docker: true,
  number_of_api_calls_error: 0,
  number_of_api_calls_success: 19,
  number_of_services: 3,
  server_time_utc: '2022-08-17T04:59:54.936000',
  session_id: uuid(),
  system: 'Linux',
  top_user_agent:
    'Mozilla/5.0 (X11; Linux x86_64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/104.0.0.0 Safari/537.36',
  version: '1.0.5.dev:3f7a7fe7',
};

export const generateEvents = (): StackAwsApiCall[] => {
  const services = ['lambda', 's3', 'dynamodb', 'sqs', 'sns', 'kinesis'];
  const operation = ['create', 'read', 'update', 'delete'];
  const region = ['eu-central-1', 'eu-central-2', 'eu-central-3', 'eu-central-99'];
  const userAgent = ['Boto3/...', 'curl/...'];
  const statusCode = [200, 201, 202, 203, 204, 300, 400, 500];
  const error = ['oh noooooo', null];

  const diffSeconds = new Array(10).fill(0).map((_val, idx) => idx);

  const data = new Array(100).fill(0).map(() => ({
    server_time: new Date(new Date().getTime() - (sample(diffSeconds) as number) * 1000).toISOString(),
    session_id: `example-stack-${uuid().substring(0, 8)}`,
    count: 1,
    api_key: uuid().substring(0, 8),
    client_time: new Date(new Date().getTime() - (sample(diffSeconds) as number) * 1000).toISOString(),
    machine_id: uuid().substring(0, 8),
    service: sample(services),
    operation: sample(operation),
    region: sample(region),
    account_id: uuid().substring(0, 8),
    user_agent: sample(userAgent),
    is_internal: false,
    status_code: sample(statusCode),
    err_msg: sample(error),
  })) as StackAwsApiCall[];

  return data.sort((a, b) => new Date(a.server_time).getTime() - new Date(b.server_time).getTime());
};

type GeneratedStackWithEvents = {
  stack: StackInfo;
  events: StackAwsApiCall[];
};

export const generateStackWithEvents = (): GeneratedStackWithEvents => {
  const events = generateEvents();

  const stack: StackInfo = {
    api_key: events[0]?.api_key || '',
    user_name: 'Example User',
    duration_in_seconds: 0,
    is_ci: false,
    is_docker: true,
    number_of_api_calls_error: events.filter((event) => event.status_code > 299).length,
    number_of_api_calls_success: events.filter((event) => event.status_code < 300).length,
    number_of_services: events.map((event) => event.service).filter((v, i, a) => a.indexOf(v) === i).length,
    server_time_utc: events[0]?.server_time || '',
    session_id: events[0]?.session_id || '',
    system: 'Linux',
    top_user_agent: events[0]?.user_agent,
    version: '2.3',
  };

  return { stack, events };
};
