import { ReactElement } from 'react';
import { Box, IconButton, Typography } from '@mui/material';
import { ZoomOut as ZoomOutIcon } from '@mui/icons-material';
import { red, green } from '@mui/material/colors';
import { StackAwsApiCall } from '@localstack/types';

import {
  BarChart,
  CartesianGrid,
  XAxis,
  YAxis,
  Tooltip,
  Bar,
  Legend,
  ResponsiveContainer,
  ReferenceArea,
} from 'recharts';

import { useChartProps, useZoomableChart } from '../../../chart';

export interface ErrorsChartProps {
  title: string;
  data: StackAwsApiCall[];
  showZoomOut?: boolean;
  onResetInterval?: () => unknown;
  onChangeInterval?: (start: string, end: string) => unknown;
}

export const ErrorsChart = ({
  title,
  data,
  showZoomOut,
  onResetInterval,
  onChangeInterval,
}: ErrorsChartProps): ReactElement => {
  const chartProps = useChartProps();

  const zommableChart = useZoomableChart({ type: 'datestring', onChangeInterval });

  // aggregate number of the Successful/Errored across the same datapoints
  const aggregatedData = data.reduce(
    (memo, item) => ({
      ...memo,
      [item.server_time]: {
        ...memo[item.server_time as keyof typeof memo],
        Successful: (memo[item.server_time as keyof typeof memo]?.Successful ?? 0) + (item.status_code < 400 ? 1 : 0),
        Errored: (memo[item.server_time as keyof typeof memo]?.Errored ?? 0) + (item.status_code >= 400 ? 1 : 0),
      },
    }),
    {} as Record<string, Record<string, number>>,
  );

  const formattedData = Object.entries(aggregatedData).map(([time, stats]) => ({ time, ...stats }));

  return (
    <>
      <Typography align="center">{title}</Typography>
      {showZoomOut && (
        <Box position="relative">
          {/* FIXME: remove hardcoded zIndex and use theme or styles  */}
          <Box position="absolute" right={0} zIndex={123}>
            <IconButton onClick={onResetInterval} size="large">
              <ZoomOutIcon />
            </IconButton>
          </Box>
        </Box>
      )}
      <ResponsiveContainer width="100%" height={250}>
        <BarChart data={formattedData} syncId="stack-charts" {...zommableChart.props}>
          <CartesianGrid strokeDasharray="3 3" {...chartProps.cartesianGridProps} />
          <XAxis dataKey="time" {...chartProps.xAxisProps} />
          <YAxis {...chartProps.yAxisProps} />

          <Legend {...chartProps.legendProps} />
          <Tooltip {...chartProps.tooltipProps} />

          <Bar dataKey="Successful" stackId="stack" fill={green[400]} />
          <Bar dataKey="Errored" stackId="stack" fill={red[400]} />

          {zommableChart.leftSelection && (
            <ReferenceArea x1={zommableChart.leftSelection} x2={zommableChart.rightSelection} />
          )}
        </BarChart>
      </ResponsiveContainer>
    </>
  );
};
