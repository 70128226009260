import { useState, useCallback, ReactElement } from 'react';
import { useRoutes, useAwsEffect, useAwsGetter } from '@localstack/services';
import { useParams } from 'react-router-dom';
import { Box, Button } from '@mui/material';
import { Dropdown, ConfirmableButton, LoadingFragment, PageTitle } from '@localstack/ui';

import { DEFAULT_APPSYNC_ROUTES } from '@localstack/constants';

import { NavTabs, ResolversCard } from './components';
import { AppSyncProps } from './types';

const DEFAULT_FORMAT = 'SDL';

export const AppSyncApiResolvers = ({
  Layout,
  clientOverrides,
  routes = DEFAULT_APPSYNC_ROUTES,
}: AppSyncProps): ReactElement => {
  const [selectedIds, setSelectedIds] = useState<[string, string][]>([]);

  const { goto } = useRoutes();
  const { apiId } = useParams<'apiId'>();

  const {
    data: types,
    isLoading,
    mutate,
  } = useAwsGetter('AppSync', 'listTypes', [{ apiId, format: DEFAULT_FORMAT }], { clientOverrides });

  const { deleteResolver } = useAwsEffect('AppSync', ['deleteResolver'], {
    revalidate: ['listResolvers', 'getResolver'],
    clientOverrides,
  });

  const handleDeleteSelected = useCallback(async () => {
    const promises = selectedIds.map(([typeName, fieldName]) =>
      deleteResolver({ apiId: apiId as string, typeName, fieldName }),
    );
    await Promise.all(promises);
  }, [selectedIds]);

  return (
    <Layout
      documentTitle="API Resolvers"
      tabs={<NavTabs apiId={apiId as string} routes={routes} />}
      title={
        <PageTitle
          title="API Resolvers"
          onMutate={mutate}
          breadcrumbs={[
            ['AppSync', () => goto(routes.RESOURCES_APPSYNC)],
            [apiId, () => goto(routes.RESOURCES_APPSYNC_API, { apiId })],
            ['Resolvers', null],
          ]}
        />
      }
      actions={
        <>
          <Dropdown label="Actions">
            <ConfirmableButton
              componentType="MenuItem"
              disabled={selectedIds.length === 0 || isLoading}
              title={`Delete ${selectedIds.length} Resolver(s)?`}
              onClick={handleDeleteSelected}
              text="Selected Resolvers will be permanently deleted"
            >
              Remove Selected
            </ConfirmableButton>
          </Dropdown>
        </>
      }
    >
      <LoadingFragment
        loading={isLoading}
        variant="card"
        height={300}
        arrayData={types?.types ?? []}
        emptyText="There are no types to attach resolvers to"
        emptyAction={
          <Button
            size="small"
            color="primary"
            onClick={() => goto(routes.RESOURCES_APPSYNC_API_TYPE_CREATE, { apiId })}
          >
            Add new Type
          </Button>
        }
      >
        {(types?.types ?? []).map((appSyncType) => (
          <Box key={appSyncType.name} mt={2}>
            <ResolversCard typeName={appSyncType.name as string} onSelect={setSelectedIds} routes={routes} />
          </Box>
        ))}
      </LoadingFragment>
    </Layout>
  );
};
