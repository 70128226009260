import React, { ReactElement } from 'react';

import { Card, CardHeader, CardContent, Grid, Typography, Link, Button, Paper, Alert } from '@mui/material';

import { Network } from 'react-graph-vis';

import { MagicTable } from '@localstack/ui';

import { IdType } from 'vis-network';
import { GremlinGraphDataMap, GremlinNode } from '@localstack/types/src';
import { GREMLIN_ITEM_SCHEMA, NODE_EDGES_SCHEMA } from '@localstack/constants/src';

const PAGE_SIZE = 10;

export type GraphNodeInfoProps = {
  node: Optional<GremlinNode>;
  graphDataMap: GremlinGraphDataMap;
  network: Optional<Network>;
  onNavigateCallback: (nodeId: IdType) => void;
  onDelete: (nodeId: IdType) => void;
};

export const GraphNodeInfoNonMemo = ({
  node,
  graphDataMap,
  network,
  onNavigateCallback,
  onDelete,
}: GraphNodeInfoProps): ReactElement => {
  const edges = node && network ? network?.getConnectedEdges(node.id as IdType) : [];

  const enrichedEdgeList = edges.map((edgeId) => {
    const [sourceId, destinationId] = network?.getConnectedNodes(edgeId) as [IdType, IdType];
    const source = graphDataMap.nodes.get(sourceId)?.label as string;
    const label = graphDataMap.edges.get(edgeId)?.label as string;
    const destination = graphDataMap.nodes.get(destinationId)?.label as string;
    return { source, label, destination, edgeId, sourceId, destinationId };
  });

  const handleLinkClick = (nodeId: IdType) => {
    network?.setSelection({ nodes: [nodeId] });
    network?.focus(nodeId, { scale: 1, animation: true });
    onNavigateCallback(nodeId);
  };

  return (
    <>
      {node && graphDataMap.nodes.get(node.id ?? '') ? (
        <Card>
          <CardHeader
            title={`Node: ${node?.label ?? ''} [${node?.id ?? ''}]`}
            action={
              <Button onClick={() => onDelete(node.id as IdType)} variant="outlined" color="primary">
                Delete
              </Button>
            }
          />
          <CardContent>
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <Paper>
                  <Typography variant="h5">Edges</Typography>
                  <MagicTable
                    schema={NODE_EDGES_SCHEMA}
                    entry="EdgeList"
                    rows={enrichedEdgeList || []}
                    filterColumns={['source', 'label', 'destination']}
                    order={['source', 'label', 'destination']}
                    idAttribute="edgeId"
                    selectable={false}
                    disableSelectionOnClick
                    pageSize={PAGE_SIZE}
                    externalFields={{
                      source: (row) => <Link onClick={() => handleLinkClick(row.sourceId)}>{row.source}</Link>,
                      destination: (row) => (
                        <Link onClick={() => handleLinkClick(row.destinationId)}>{row.destination}</Link>
                      ),
                    }}
                  />
                </Paper>
              </Grid>
              <Grid item xs={6}>
                <Paper>
                  <Typography variant="h5">Attributes</Typography>
                  <MagicTable
                    schema={GREMLIN_ITEM_SCHEMA}
                    entry="AttributeList"
                    rows={node?.attributes || []}
                    idAttribute="key"
                    selectable={false}
                    pageSize={PAGE_SIZE}
                    disableSelectionOnClick
                    autoPageSize
                  />
                </Paper>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      ) : (
        <Alert severity="info">Select a Node to see the info</Alert>
      )}
    </>
  );
};

export const GraphNodeInfo = React.memo(GraphNodeInfoNonMemo);
