import { capitalise } from '@localstack/services';
import { PlanFamily, FeatureMaturityLevel, PlanChipProps } from '@localstack/types';
import { Chip, Link, Tooltip } from '@mui/material';
import { Theme } from '@mui/material/styles';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import { ReactElement } from 'react';

import { WrapInComponent } from '../../utils';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    planChip: {
      marginLeft: theme.spacing(2),
    },
  }),
);

const tooltipText = {
  [PlanFamily.PRO_PLANS]: 'Available in the localstack-pro image',
  [PlanFamily.TEAM_PLANS]: 'Available in the LocalStack Team plan',
  [PlanFamily.TRIAL_PLANS]: '', // empty string will disable tooltip
  [PlanFamily.COMMUNITY]: '', // empty string will disable tooltip
  [PlanFamily.ENTERPRISE_PLANS]: 'Available in the LocalStack Enterprise plans',
  [FeatureMaturityLevel.PREVIEW]: 'https://docs.localstack.cloud/references/changelog/#features-under-development',
  [FeatureMaturityLevel.EXPERIMENTAL]: 'https://docs.localstack.cloud/references/changelog/#features-under-development',
  [FeatureMaturityLevel.GA]: '', // empty string will disable tooltip
} as const;

export const PlanChip = ({
  planFamily,
  size = 'small',
  showTooltip,
  tooltipTitleOverride,
  withStyles = true,
  ...rest
}: PlanChipProps): ReactElement => {
  const classes = useStyles();
  const finalText = tooltipTitleOverride || tooltipText[planFamily];

  return (
    <WrapInComponent
      wrap={showTooltip}
      wrapperFn={(child) =>
        finalText.startsWith('http') ? (
          <Link target="_blank" href={finalText} underline="hover">
            {child}
          </Link>
        ) : (
          <Tooltip title={finalText}>{child}</Tooltip>
        )
      }
    >
      <Chip
        variant="outlined"
        size={size}
        label={capitalise(planFamily)}
        color="primary"
        className={withStyles ? classes.planChip : undefined}
        {...rest}
      />
    </WrapInComponent>
  );
};

export default PlanChip;
