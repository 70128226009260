import { forwardRef, ReactElement } from 'react';
import { Controller, Control, ControllerProps } from 'react-hook-form';
import { lighten, TextField, TextFieldProps, Theme } from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import { blue } from '@mui/material/colors';
import { ThemeType } from '@localstack/constants';

export type ControlledTextFieldProps = TextFieldProps &
  Omit<ControllerProps, 'control' | 'render'> & {
    control: Control<any>; // eslint-disable-line
    showErrorsOnlyIfTouched?: boolean;
  };

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    input: {
      '& input:-webkit-autofill, & input:-webkit-autofill:hover, & input:-webkit-autofill:focus, & input:-webkit-autofill:active':
        {
          WebkitBoxShadow: `0 0 0 30px ${
            theme.palette.mode === ThemeType.DARK ? lighten(theme.palette.background.default, 0.1) : blue[50]
          } inset !important`,
        },
    },
  }),
);

export const ControlledTextField = forwardRef<HTMLElement, ControlledTextFieldProps>(
  ({ control, showErrorsOnlyIfTouched = true, ...rest }: ControlledTextFieldProps, ref): ReactElement => {
    const classes = useStyles();
    return (
      <Controller
        {...rest}
        control={control}
        render={({ field, fieldState }) => {
          const shouldShowErrors = showErrorsOnlyIfTouched ? fieldState.isTouched : true;
          return (
            <TextField
              variant="standard"
              {...rest}
              {...field}
              inputRef={ref}
              value={field.value || ''}
              error={shouldShowErrors && Boolean(fieldState.error)}
              helperText={shouldShowErrors && fieldState.error?.message}
              className={classes.input}
              InputProps={{
                ...(rest.InputProps || {}),
                inputProps: {
                  ...(rest.InputProps?.inputProps || {}),
                  'aria-label': rest.InputProps?.inputProps?.['aria-label'] || rest.placeholder,
                },
              }}
            />
          );
        }}
      />
    );
  },
);

ControlledTextField.displayName = 'ControlledTextField';

export default ControlledTextField;
