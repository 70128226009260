import { ReactElement } from 'react';
import { SQSQueue } from '@localstack/types';
import { Link } from '@mui/material';

import { MagicTable } from '../../../magic/MagicTable';

export type SQSQueueTableProps = {
  entries: SQSQueue[];
  loading: boolean;
  selectable?: boolean;
  onViewSQSQueue?: (queue: SQSQueue) => void;
  onSelect?: (names: string[]) => void;
};

const SCHEMA = {
  shapes: {
    SQSQueue: {
      type: 'structure',
      members: {
        Name: { type: 'string' },
        QueueUrl: { type: 'string' },
      },
    },
  },
};

export const SQSQueueTable = ({
  entries,
  loading,
  onViewSQSQueue,
  selectable,
  onSelect,
}: SQSQueueTableProps): ReactElement => (
  <MagicTable
    schema={SCHEMA}
    entry="SQSQueue"
    idAttribute="QueueUrl"
    rows={entries}
    selectable={selectable}
    loading={loading}
    onSelect={onSelect}
    externalFields={{
      Name: (row) => (
        <Link onClick={() => onViewSQSQueue && onViewSQSQueue(row)} underline="hover">
          {row.Name}
        </Link>
      ),
    }}
  />
);
