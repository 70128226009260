import { ThemeType } from '@localstack/constants';
import { Box, Container, darken, lighten, Theme } from '@mui/material';
import { createStyles, makeStyles } from '@mui/styles';
import classNames from 'classnames';
import { ReactElement, useEffect } from 'react';

type Props = {
  wideLayout?: boolean;
  documentTitle?: string;
  children?: any; // eslint-disable-line
};

const useStyles = makeStyles<Theme, { wideLayout: boolean }>((theme: Theme) =>
  createStyles({
    root: {
      background:
        theme.palette.mode === ThemeType.DARK
          ? darken(theme.palette.background.default, 0.6)
          : lighten(theme.palette.background.default, 0.03),
    },
    pageContainer: {
      display: 'flex',
      justifyContent: 'center',
      paddingTop: '16vh',
      paddingBottom: '16vh',
      minHeight: '100vh',
    },
    pageContent: {
      flexGrow: 1,
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      maxWidth: '100%',
    },
    mainContent: {
      flexGrow: 1,
      display: 'flex',
      flexDirection: 'column',
      [theme.breakpoints.down('sm')]: {
        width: '100%',
      },
    },
    mainBox: {
      width: ({ wideLayout }) => (wideLayout ? 556 : 340),
      [theme.breakpoints.down('sm')]: {
        width: () => '100%',
      },
    },
  }),
);

export const AuthLayout = ({ documentTitle, wideLayout = false, children }: Props): ReactElement => {
  const classes = useStyles({ wideLayout });

  useEffect(() => {
    if (documentTitle) {
      document.title = `${documentTitle} - LocalStack`;
    } else {
      document.title = 'LocalStack';
    }
  }, [documentTitle]);

  return (
    <div data-name="page container" className={classNames(classes.root, classes.pageContainer)}>
      <div data-name="page content" className={classes.pageContent}>
        <div data-name="main content" className={classes.mainContent}>
          <Container>
            <Box className={classes.mainBox} px={1}>
              {children}
            </Box>
          </Container>
        </div>
      </div>
    </div>
  );
};
