import { ReactElement } from 'react';
import { ECSTask } from '@localstack/types';
import { getSchema } from '@localstack/services';

import { MagicDetails } from '../../magic/MagicDetails';

export interface ECSTaskDetailsProps {
  task?: Optional<Partial<ECSTask>>;
}

export const ECSTaskDetails = ({ task }: ECSTaskDetailsProps): ReactElement => (
  <MagicDetails data={task} schema={getSchema('ECS')} entry="Task" title="Task Details" />
);
