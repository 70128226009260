import { getSchema } from '@localstack/services';
import { GetAppResult } from '@localstack/types';
import { ReactElement } from 'react';

import { MagicDetails } from '../../magic/MagicDetails';

export type AmplifyAppDetailProps = {
  app?: Optional<Partial<GetAppResult>>;
};

export const AmplifyAppDetail = ({ app }: AmplifyAppDetailProps): ReactElement => (
  <MagicDetails data={app?.app} schema={getSchema('Amplify')} entry="App" title="Amplify App Details" />
);
