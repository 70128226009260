import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import { Backspace, ExpandLess, ExpandMore } from '@mui/icons-material';
import React, { ReactElement, useEffect, useState } from 'react';

import {
  Button,
  Card,
  CardContent,
  CardHeader,
  Checkbox,
  Collapse,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemAvatar,
  TextField,
} from '@mui/material';

import { getDisplayName } from '@localstack/services';
import { StackInfo } from '@localstack/types';

interface FilterStackCardProps {
  stacks: StackInfo[];
  setFilteredStacks: React.Dispatch<React.SetStateAction<StackInfo[]>>;
}

const useStyles = makeStyles(() =>
  createStyles({
    keys: {
      maxHeight: '200px',
      overflowY: 'auto',
    },
    listItem: {
      paddingTop: 0,
      paddingBottom: 0,
    },
  }),
);

export const FilterStackCard = ({ stacks, setFilteredStacks }: FilterStackCardProps): ReactElement => {
  const classes = useStyles();
  const [collapseFilter, setCollapseFilter] = useState(false);
  const [filterString, setFilterString] = useState('');

  const names = Array.from(new Set(stacks.map((stack) => getDisplayName(stack)))).sort();
  const [excludedNames, setExcludedNames] = useState<string[]>([]);

  const updateFilteredStacks = () => {
    const filtered = stacks.filter((stack) => !excludedNames.includes(getDisplayName(stack)));
    setFilteredStacks(filtered);
  };

  useEffect(() => {
    updateFilteredStacks();
  }, [stacks]);

  useEffect(() => {
    updateFilteredStacks();
  }, [excludedNames]);

  return (
    <Card>
      <CardHeader
        title="Filter Stacks"
        action={
          <>
            <IconButton onClick={() => setCollapseFilter(!collapseFilter)} aria-label="show more" size="large">
              {collapseFilter ? <ExpandLess /> : <ExpandMore />}
            </IconButton>
          </>
        }
      />
      <Collapse in={collapseFilter}>
        <CardContent>
          <Grid container spacing={2} alignItems="center">
            <Grid item md={12}>
              <TextField
                fullWidth
                variant="outlined"
                value={filterString}
                onChange={(event) => setFilterString(event.target.value.toLowerCase())}
                label="Filter List"
                InputProps={{
                  endAdornment:
                    filterString !== '' ? (
                      <IconButton onClick={() => setFilterString('')} size="large">
                        <Backspace />
                      </IconButton>
                    ) : undefined,
                }}
              />
            </Grid>
            <Grid item md={12}>
              <List className={classes.keys} disablePadding>
                {names
                  .filter((name) => name.toLocaleLowerCase().includes(filterString))
                  .map((name) => (
                    <ListItem key={name} className={classes.listItem}>
                      <ListItemAvatar>
                        <Checkbox
                          color="primary"
                          checked={!excludedNames.includes(name)}
                          onChange={(_e, checked) => {
                            setExcludedNames(
                              checked
                                ? excludedNames.filter((k) => k !== name)
                                : Array.from(new Set([...excludedNames, name])),
                            );
                          }}
                        />
                      </ListItemAvatar>
                      {name}
                    </ListItem>
                  ))}
              </List>
            </Grid>
            <Grid item md={12}>
              <Grid container justifyContent="center" spacing={2}>
                <Grid item>
                  <Button
                    variant="outlined"
                    onClick={() => {
                      setExcludedNames([]);
                    }}
                  >
                    Select All
                  </Button>
                </Grid>
                <Grid item>
                  <Button
                    variant="outlined"
                    onClick={() => {
                      setExcludedNames(names);
                    }}
                  >
                    Select None
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </CardContent>
      </Collapse>
    </Card>
  );
};
