import { ReactElement } from 'react';
import { Card, CardContent, CardActions, Box, Typography } from '@mui/material';
import { useAwsEffect, useRoutes } from '@localstack/services';
import { CreateLoadBalancer } from '@localstack/types';
import { Breadcrumbs, ProgressButton, ELoadBalancerV1Form } from '@localstack/ui';

import { EC2Props } from './types';

export const LoadBalancerV1Upsert = ({ Layout, clientOverrides, routes }: EC2Props): ReactElement => {
  const { goto } = useRoutes();

  const { createLoadBalancer, isLoading } = useAwsEffect('ELB', ['createLoadBalancer'], {
    revalidate: ['describeLoadBalancers'],
    clientOverrides,
  });

  const handleCreate = async (data: CreateLoadBalancer) => {
    if (await createLoadBalancer(data)) {
      goto(routes.RESOURCES_EC2_LBV1, { name: data.LoadBalancerName });
    }
  };

  return (
    <Layout
      documentTitle="Load Balancer V1 Details"
      title={
        <Box>
          <Typography variant="h4">Load Balancer V1 Details</Typography>
          <Breadcrumbs
            mappings={[
              ['ELB', () => goto(routes.RESOURCES_EC2_LBV1)],
              ['Create Load Balancer V1', null],
            ]}
          />
        </Box>
      }
      actions={
        <ProgressButton type="submit" form="CreateLoadBalancer" variant="outlined" color="primary" loading={isLoading}>
          Submit
        </ProgressButton>
      }
    >
      <Card>
        <CardContent>
          <ELoadBalancerV1Form
            onSubmit={handleCreate}
            clientOverrides={clientOverrides}
            formId="CreateLoadBalancer"
            loading={isLoading}
          />
        </CardContent>
        <CardActions>
          <ProgressButton
            type="submit"
            form="CreateLoadBalancer"
            variant="contained"
            color="primary"
            loading={isLoading}
          >
            Submit
          </ProgressButton>
        </CardActions>
      </Card>
    </Layout>
  );
};
