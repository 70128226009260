import { ReactElement } from 'react';
import { Card, CardContent, CardActions, Box, Typography } from '@mui/material';
import { CreateSQSQueueRequest } from '@localstack/types';
import { useRoutes, useAwsEffect } from '@localstack/services';
import { ProgressButton, Breadcrumbs, SQSQueueCreateForm } from '@localstack/ui';

import { DEFAULT_SQS_ROUTES } from '@localstack/constants';

import { SQSProps } from './types';

export const SQSQueueCreate = ({ Layout, clientOverrides, routes = DEFAULT_SQS_ROUTES }: SQSProps): ReactElement => {
  const { goto } = useRoutes();

  const { createQueue, isLoading } = useAwsEffect('SQS', ['createQueue'], {
    revalidate: ['listQueues', 'getQueueAttributes'],
    clientOverrides,
  });

  const handleCreate = async (data: CreateSQSQueueRequest) => {
    await createQueue(data);
    goto(routes.RESOURCES_SQS_QUEUES);
  };

  return (
    <Layout
      documentTitle="SQS: Create Queue"
      title={
        <Box>
          <Typography variant="h4">Create Queue</Typography>
          <Breadcrumbs
            mappings={[
              ['SQS', () => goto(routes.RESOURCES_SQS_QUEUES)],
              ['Create Queue', null],
            ]}
          />
        </Box>
      }
      actions={
        <ProgressButton type="submit" form="CreateQueue" variant="outlined" color="primary" loading={isLoading}>
          Submit
        </ProgressButton>
      }
    >
      <Card>
        <CardContent>
          <SQSQueueCreateForm onCreate={handleCreate} formId="CreateQueue" />
        </CardContent>
        <CardActions>
          <ProgressButton type="submit" form="CreateQueue" variant="contained" color="primary" loading={isLoading}>
            Submit
          </ProgressButton>
        </CardActions>
      </Card>
    </Layout>
  );
};
