import { ReactElement } from 'react';
import { getSchema } from '@localstack/services';
import { LambdaFunctionInvocationResponse } from '@localstack/types';
import { ListItem, ListItemText } from '@mui/material';

import { MagicDetails } from '../../../magic/MagicDetails';
import { CodeSnippetViewer } from '../../../../display/CodeSnippetViewer';

export type LambdaInvocationDetailsProps = {
  details: Optional<Partial<LambdaFunctionInvocationResponse>>;
};

export const LambdaInvocationDetails = ({ details }: LambdaInvocationDetailsProps): ReactElement => (
  <MagicDetails
    data={details}
    schema={getSchema('Lambda')}
    entry="InvocationResponse"
    title=""
    externalFields={{
      '^ExecutedVersion$': () => <></>,
      '^LogResult$': (data: string, fieldName: string) => (
        <ListItem>
          <ListItemText>
            <CodeSnippetViewer data={data} fieldName={fieldName} disableParsing />
          </ListItemText>
        </ListItem>
      ),
      '^FunctionError$': (data: string, fieldName: string) =>
        data ? (
          <ListItem>
            <ListItemText primary={fieldName} secondary={data} />
          </ListItem>
        ) : (
          <></>
        ),
      '^Payload$': (data: string, fieldName: string) => (
        <ListItem>
          <ListItemText>
            <CodeSnippetViewer data={data} fieldName={fieldName} />
          </ListItemText>
        </ListItem>
      ),
    }}
  />
);
