import { ReactElement } from 'react';
import { AgwApiStage } from '@localstack/types';
import { getSchema } from '@localstack/services';

import { MagicDetails } from '../../../magic/MagicDetails';

export interface AgwApiStageDetailsProps {
  stage?: Optional<Partial<AgwApiStage>>;
}

export const AgwApiStageDetails = ({ stage }: AgwApiStageDetailsProps): ReactElement => (
  <MagicDetails data={stage} schema={getSchema('ApiGatewayV2')} entry="Stage" title="Stage Details" />
);
