export const createNewPopup = (
  win: Window,
  url = '',
  options?: { w?: number; h?: number; name: string },
): WindowProxy | null => {
  const w = options?.w || 900;
  const h = options?.h || 600;
  const name = options?.name || 'popup';

  const winOuterWidth = win.top?.outerWidth || win.innerWidth || screen.width;
  const winTopScreenX = win.top?.screenX || win.screenX || 0;
  const winTopScreenY = win.top?.screenY || win.screenY || 0;

  const x = winTopScreenX + (winOuterWidth - w) / 2;

  const y = winTopScreenY + 100;

  return window.open(url, name, `width=${w},height=${h},top=${y},left=${x}`);
};
