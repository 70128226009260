import { ReactElement, MouseEvent } from 'react';
import { Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import createStyles from '@mui/styles/createStyles';
import classNames from 'classnames';
import { SUB_ACTIVE_STATUSES, SUB_CANCELED_STATUSES } from '@localstack/constants';
import {
  MARKER_IDS,
  TestMarkerSpan,
  computeNumLicensesUsed,
  formatDate,
  formatMonetaryAmount,
  numNonCIKeysUsed,
} from '@localstack/services';

import { Subscription, PlanFamily, OrganizedApiKeys, OrganizationMember, LicenseAssignment } from '@localstack/types';

import { Link, Table, TableHead, TableRow, TableCell, TableBody, Box } from '@mui/material';

import { SubscriptionStatus } from '../../../display/SubscriptionStatus';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    table: {
      '& > *': {
        textWrap: 'nowrap',
      },
    },

    trialRow: {
      background: `linear-gradient(-45deg, ${theme.palette.primary.dark} 0%, ${theme.palette.primary.main} 100%)`,
      '& td:first-child': {
        paddingLeft: `${theme.spacing(1)} !important`,
      },
      '& td, & td a': {
        color: theme.palette.primary.contrastText,
      },
    },
  }),
);

export interface SubscriptionsListProps {
  showCancelled?: boolean;
  subscriptions: Subscription[];
  licenseAssignments: LicenseAssignment[];
  keys: OrganizedApiKeys;
  members: OrganizationMember[];
  onViewSubscription?: (subscription: Subscription) => unknown;
  isAdminPanel?: boolean;
}

export const SubscriptionsList = ({
  showCancelled,
  subscriptions,
  licenseAssignments,
  keys,
  members,
  onViewSubscription,
  isAdminPanel,
}: SubscriptionsListProps): ReactElement => {
  const classes = useStyles();

  const activeSubs: Subscription[] = subscriptions.filter(({ status }) => SUB_ACTIVE_STATUSES.includes(status));
  const cancelledSubs = subscriptions.filter(({ status }) => SUB_CANCELED_STATUSES.includes(status));

  const subscriptionsToShow: Subscription[] = [
    ...(showCancelled ? [...activeSubs, ...cancelledSubs] : [...activeSubs]),
  ].sort((subA, subB) => subB.start_date - subA.start_date);

  const licensesUsed = (subscription?: Subscription): number | undefined => {
    if (!subscription || !licenseAssignments || !keys) {
      return undefined;
    }

    return computeNumLicensesUsed(subscription, licenseAssignments, keys.org, members);
  };

  return (
    <Box style={{ overflowX: 'auto' }}>
      <TestMarkerSpan name={MARKER_IDS.SUBSCRIPTIONS_TABLE}>
        <Table size="small" className={classes.table}>
          <TableHead>
            <TableRow>
              <TableCell>Subscription Plan</TableCell>
              {isAdminPanel && <TableCell>ID</TableCell>}
              <TableCell>Renewal Date</TableCell>
              <TableCell>Est. Renewal Cost (Excl. VAT)</TableCell>
              <TableCell>Licenses Used</TableCell>
              <TableCell>Status</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {subscriptionsToShow.map((subscription) => (
              <TableRow
                hover
                key={subscription.id}
                className={classNames(`plan-${subscription.plan.family}`, {
                  [classes.trialRow]: subscription.plan?.family === PlanFamily.TRIAL_PLANS,
                })}
              >
                <TableCell>
                  <Link
                    href="#"
                    onClick={(e: MouseEvent<HTMLAnchorElement>) => {
                      e.preventDefault();
                      onViewSubscription?.(subscription);
                    }}
                    underline="hover"
                  >
                    {subscription.name || subscription.plan.name || ''}
                  </Link>
                </TableCell>

                {isAdminPanel && (
                  <TableCell>
                    <code>{subscription.id}</code>
                  </TableCell>
                )}

                <TableCell>
                  {subscription.cancel_at_period_end || subscription.cancel_at
                    ? '-'
                    : formatDate(subscription.current_period_end as number)}
                </TableCell>
                <TableCell>
                  {formatMonetaryAmount(
                    (subscription.estimated_mrr ?? 0) * subscription.interval_months,
                    subscription.currency ?? 'usd',
                  )}
                  {' / '}
                  {(subscription.interval_months || 1) === 12 ? 'year' : 'month'}
                </TableCell>
                <TableCell>
                  {licensesUsed(subscription)} of {subscription.seats} (including{' '}
                  {numNonCIKeysUsed(subscription, keys.org)} legacy API keys)
                </TableCell>
                <TableCell>
                  <SubscriptionStatus subscription={subscription} />
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TestMarkerSpan>
    </Box>
  );
};
