import { useState, ReactElement, useCallback } from 'react';
import { ProgressButton, Breadcrumbs, S3ObjectsTable } from '@localstack/ui';
import { ExternalLink } from '@localstack/constants';
import { CloudPod } from '@localstack/types';
import { useAwsGetter, useRoutes, useInjectPodState, useAwsEffect, downloadFileContent } from '@localstack/services';
import {
  Alert,
  Box,
  Card,
  Grid,
  Typography,
  Link,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Button,
  CardHeader,
  LinearProgress,
} from '@mui/material';
import { Cloud, Image, Remove as ListIcon, TouchApp } from '@mui/icons-material';

import { ContainedCustomerLayout } from '~/layouts';
import { AppRoute } from '~/config';

import StepperCard, { StepperItem } from '../components/StepperCard';

const demoPod: CloudPod = {
  id: '-',
  is_public: true,
  max_version: 1,
  org_id: '-',
  pod_name: 'thumbnail-qs',
};

const SOURCE_BUCKET_NAME = 'img-bucket';
const TARGET_BUCKET_NAME = 'img-bucket-resized';
const POLLING_INTERVAL = 2000;

export const DemoFour = (): ReactElement => {
  const { goto } = useRoutes();
  const { inject, injected, isLoading: cloudPodInjecting } = useInjectPodState();

  const { data: s3Buckets } = useAwsGetter('S3', 'listBuckets', [], {
    swrOverrides: { refreshInterval: POLLING_INTERVAL },
    silentErrors: true,
  });

  const sourceBucket = s3Buckets?.Buckets?.find((s3Bucket) => s3Bucket.Name === SOURCE_BUCKET_NAME);
  const targetBucket = s3Buckets?.Buckets?.find((s3Bucket) => s3Bucket.Name === TARGET_BUCKET_NAME);

  const isDeployed = injected || (!!sourceBucket && !!targetBucket);

  const [fileInputValue, setFileInputValue] = useState('');

  const { data: sourceObjects } = useAwsGetter(
    'S3',
    'listObjectsV2',
    [{ Bucket: sourceBucket ? sourceBucket.Name : undefined, Prefix: '', MaxKeys: 1000 }],
    { clientOverrides: { s3ForcePathStyle: true }, swrOverrides: { refreshInterval: POLLING_INTERVAL } },
  );

  const { data: targetObjects } = useAwsGetter(
    'S3',
    'listObjectsV2',
    [{ Bucket: targetBucket ? targetBucket.Name : undefined, Prefix: '', MaxKeys: 1000 }],
    { clientOverrides: { s3ForcePathStyle: true }, swrOverrides: { refreshInterval: POLLING_INTERVAL } },
  );

  const { upload, isLoading, getObject } = useAwsEffect('S3', ['upload', 'getObject'], {
    revalidate: ['listObjectsV2'],
    clientOverrides: { s3ForcePathStyle: true },
  });

  const handleDownloadObject = useCallback(
    async (key: string) => {
      if (!targetBucket || !targetBucket.Name) return;
      const content = await getObject({ Bucket: targetBucket.Name || '', Key: key });
      if (content) downloadFileContent(content.Body as Blob, key, content.ContentType as string);
    },
    [targetBucket],
  );

  const stepperItems: StepperItem[] = [
    {
      content: (
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <Typography paragraph>
              This sample illustrates an application to create thumbnails automatically for files uploaded to an S3
              bucket all <strong>locally inside LocalStack</strong>.
            </Typography>
            <Typography paragraph>
              The end-to-end process of the application is illustrated in the figure on the right.
            </Typography>
            <Typography paragraph>Here&apos;s how the app works:</Typography>
            <List>
              <ListItem>
                <ListItemIcon>
                  <ListIcon />
                </ListItemIcon>
                <ListItemText
                  primary={`
                        Files uploaded to the source S3 bucket
                        will be handled by a Lambda function
                        through S3 triggers.
                      `}
                />
              </ListItem>
              <ListItem>
                <ListItemIcon>
                  <ListIcon />
                </ListItemIcon>
                <ListItemText
                  primary={`
                        After the thumbnail is created for the uploaded file by 
                        the Lambda function, it is saved in the target S3 bucket.
                      `}
                />
              </ListItem>
            </List>
            <Typography paragraph>
              (Note that all resources will be deployed to the local &quot;us-east-1&quot; region.)
            </Typography>
            <Typography paragraph>
              The full source code of this sample is available in this Github repo:{' '}
              <Link href={ExternalLink.DOCS_CLOUD_PODS} underline="hover">
                {ExternalLink.DOCS_CLOUD_PODS}
              </Link>
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <img alt="" src="/demo4/thumbnailer.svg" width="100%" />
          </Grid>
        </Grid>
      ),
      title: 'Application Overview',
      icon: <Image />,
    },
    {
      content: (
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <Typography>
              <Typography paragraph>
                Cloud Pods are a mechanism that allows you to take a snapshot of the state in your current LocalStack
                instance, persist it to a storage backend, and easily share it with your team members.
              </Typography>
              <Typography paragraph>
                This sample will restore the previously shown application from a Cloud Pod that we prepared.
                <br />
                First, load the Cloud Pod into your running instance of LocalStack. Afterwards, you will be able to
                interact with the application, and view the data stored within your local S3 bucket.
              </Typography>
              <Typography paragraph>
                To learn more about Cloud Pods, visit our docs{' '}
                <a target="_blank" rel="noreferrer" href={ExternalLink.DOCS_CLOUD_PODS}>
                  here
                </a>
                .
              </Typography>
            </Typography>
            <ProgressButton
              color="primary"
              variant="contained"
              disabled={isDeployed}
              loading={cloudPodInjecting}
              onClick={() => inject(demoPod)}
            >
              Load Cloud Pod
            </ProgressButton>
            {isDeployed && (
              <Alert severity="info" style={{ marginTop: '2rem' }}>
                You successfully loaded the Cloud Pod. Click on &apos;Continue&apos; to interact with the application
              </Alert>
            )}
          </Grid>
          <Grid item xs={6}>
            <img alt="" src="/demo4/thumbnailerPod.svg" width="100%" />
          </Grid>
        </Grid>
      ),
      icon: <Cloud />,
      title: 'Load Cloud Pod',
      disableContinue: !isDeployed,
    },
    {
      content: (
        <Grid container spacing={2}>
          <Grid item xs={4}>
            <Typography>
              <Typography paragraph>
                As the Cloud Pod has been loaded into your LocalStack instance, you can now interact with the locally
                deployed application.
              </Typography>
              <Typography paragraph>
                Use the button &apos;Upload File&apos; to upload a new file to the source bucket, which will then be
                handled by the Lambda function and stored in the target bucket.
              </Typography>
              <Typography paragraph>
                After the thumbnail for your uploaded file appears in the target bucket list, you can download it by
                clicking on it.
              </Typography>
            </Typography>
            <Button variant="contained" color="primary" component="label">
              Upload File
              <input
                type="file"
                hidden
                value={fileInputValue}
                multiple
                onChange={async (event) => {
                  const file = (event.target.files ?? [])[0] as File;
                  await upload({
                    Bucket: sourceBucket?.Name as string,
                    Body: file instanceof Blob ? file : new Blob([file]),
                    Key: file.name,
                  });
                  setFileInputValue('');
                }}
              />
            </Button>
            {isLoading && (
              <Box mt={2}>
                <LinearProgress />
              </Box>
            )}
          </Grid>
          <Grid item xs={8}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Card>
                  <CardHeader title="Source bucket" />
                  <S3ObjectsTable objects={sourceObjects?.Contents ?? []} prefix="" selectable={false} />
                </Card>
              </Grid>
              <Grid item xs={12}>
                <Card>
                  <CardHeader title="Target bucket" />
                  <S3ObjectsTable
                    objects={targetObjects?.Contents ?? []}
                    prefix=""
                    selectable={false}
                    onDownloadObject={handleDownloadObject}
                  />
                </Card>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      ),
      title: 'Interact with Application',
      icon: <TouchApp />,
    },
  ];

  return (
    <ContainedCustomerLayout
      documentTitle="Thumbnail Sample App"
      title={
        <Box>
          <Typography variant="h4">Cloud Pod Thumbnail Sample App</Typography>
          <Breadcrumbs
            mappings={[
              ['Quickstart', () => goto(AppRoute.QUICKSTART)],
              ['Thumbnail Sample App', null],
            ]}
          />
        </Box>
      }
    >
      <StepperCard stepperItems={stepperItems} />
    </ContainedCustomerLayout>
  );
};
