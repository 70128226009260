import { ReactElement } from 'react';
import { Link } from '@mui/material';
import { RDSDatabaseExpandableType } from '@localstack/types';
import { buildRoute, getInstanceRegion } from '@localstack/services';
import { DEFAULT_RDS_ROUTES } from '@localstack/constants';
import { ClassNameMap } from '@mui/styles';

import { MagicTable } from '../../magic/MagicTable';
import { ConfirmableButton } from '../../../feedback';

export interface RDSDatabasesTableProps {
  databases: RDSDatabaseExpandableType[];
  globalRegion?: string;
  databaseTypes: { [x: string]: string };
  selectedItems: string[];
  isCluster: (type: string) => boolean;
  loading?: boolean;
  selectable?: boolean;
  selectedInstanceId?: Optional<string>;
  classes?: ClassNameMap;
  onViewDatabase?: (instance: RDSDatabaseExpandableType) => unknown;
  onSelect?: (ids: string[]) => unknown;
}

const SCHEMA = {
  shapes: {
    RDSDatabases: {
      type: 'structure',
      members: {
        DBidentifier: { type: 'string' },
        Status: { type: 'string' },
        Type: { type: 'string' },
        Engine: { type: 'string' },
        Region: { type: 'string' },
        Size: { type: 'string' },
      },
    },
  },
};

export const RDSDatabasesTable = ({
  databases,
  databaseTypes,
  globalRegion,
  selectedItems,
  isCluster,
  loading,
  selectable,
  selectedInstanceId,
  onViewDatabase,
  onSelect,
}: RDSDatabasesTableProps): ReactElement => (
  <MagicTable
    expandableTable={{
      idColumn: 'DBidentifier',
    }}
    schema={SCHEMA}
    entry="RDSDatabases"
    idAttribute="id"
    rows={databases}
    selectable={selectable}
    onSelect={onSelect}
    order={['DBidentifier']}
    loading={loading}
    hideFooterSelectedRowCount
    selectionModel={selectedItems}
    onSelectionModelChange={(selection) => {
      const newSelection = selection.filter((s) => !selectedItems.includes(s as string));
      const selected = selection.length > 1 ? [(newSelection?.[0] || '') as string] : (selection as string[]);
      onSelect?.(selected);
    }}
    externalFields={{
      DBidentifier: (row) => {
        const rowRegion = (row.Type === databaseTypes.INSTANCE && getInstanceRegion(row.Region || '')) || row.Region;
        const isSameRegion = globalRegion === rowRegion;

        if (row.Type === databaseTypes.GLOBAL || isSameRegion) {
          return (
            <Link onClick={() => onViewDatabase?.(row)} underline="hover">
              {row.DBidentifier}
            </Link>
          );
        }

        const isClusterType = isCluster(row.Type || '');
        const builtRoute = buildRoute(
          isClusterType ? DEFAULT_RDS_ROUTES.RESOURCES_RDS_CLUSTER : DEFAULT_RDS_ROUTES.RESOURCES_RDS_INSTANCE,
          { [isClusterType ? 'clusterId' : 'instanceId']: row.DBidentifier, iid: selectedInstanceId },
          { region: rowRegion },
        );

        return (
          <ConfirmableButton
            componentType="Link"
            title={`Do you want to switch region to ${rowRegion}`}
            text="This resource belongs to a different region than the current selected region.
            Please confirm if you want to switch the region."
            confirmButton={{
              component: 'NewTabLink',
              props: {
                href: builtRoute,
              },
            }}
          >
            {row.DBidentifier}
          </ConfirmableButton>
        );
      },
    }}
    disableSelectionOnClick
    disableSorting
  />
);
