import { ReactElement } from 'react';
import { NavLink } from '@localstack/ui';
import { buildRoute } from '@localstack/services';

import { SQSRoutes } from '../../types';

interface Props {
  name: string;
  routes: SQSRoutes;
}

export const QueueNavTabs = ({ name, routes }: Props): ReactElement => (
  <>
    <NavLink to={buildRoute(routes.RESOURCES_SQS_QUEUE, { name })} end>
      Details
    </NavLink>
    <NavLink to={buildRoute(routes.RESOURCES_SQS_QUEUE_MESSAGES, { name })}>Messages</NavLink>
  </>
);
