import { ReactElement } from 'react';
import { AgwRestApiKey } from '@localstack/types';
import { getSchema } from '@localstack/services';

import { MagicDetails } from '../../../magic/MagicDetails';

export interface AgwRestApiKeyDetailsProps {
  apiKey?: Optional<Partial<AgwRestApiKey>>;
}

export const AgwRestApiKeyDetails = ({ apiKey }: AgwRestApiKeyDetailsProps): ReactElement => (
  <MagicDetails data={apiKey} schema={getSchema('APIGateway')} entry="ApiKey" title="ApiKey Details" />
);
