import { CombinedUserInfo } from '@localstack/types';
import { extractDataFromLocalStorage } from '@localstack/services';

import {
  STORAGE_KEY_RESOURCE_BASE_URL,
  DEFAULT_RESOURCE_BASE_URL,
  STORAGE_KEY_USER_INFO,
  STORAGE_KEY_PRE_SIGNIN_PAGE_URL,
  STORAGE_KEY_IMPERSONATION_MARKER,
  AUTH_RELATED_STORAGE_KEYS,
} from '@localstack/constants';

// *** AUXILIARY FUNCTIONS ***

export const clearAuthStorage = (): void => AUTH_RELATED_STORAGE_KEYS.forEach((key) => localStorage.removeItem(key));

// *** USER INFO ***

export const storeUserInfo = (info: CombinedUserInfo): void => {
  localStorage.setItem(STORAGE_KEY_USER_INFO, JSON.stringify(info));
};

export const getUserInfo = (): Optional<CombinedUserInfo> =>
  extractDataFromLocalStorage<CombinedUserInfo>(STORAGE_KEY_USER_INFO);

// *** IMPERSONATION ***

export const storeImpersonationMarker = (marker: Optional<string>): void =>
  localStorage.setItem(STORAGE_KEY_IMPERSONATION_MARKER, JSON.stringify(marker));

export const retrieveImpersonationMarker = (): Optional<string> =>
  extractDataFromLocalStorage<string>(STORAGE_KEY_IMPERSONATION_MARKER);

export const getResourcesBaseURL = (): string =>
  // backward compatibility
  (getUserInfo()?.user as unknown as { localEndpoint?: string })?.localEndpoint ||
  localStorage.getItem(STORAGE_KEY_RESOURCE_BASE_URL) ||
  DEFAULT_RESOURCE_BASE_URL;

export const updateResourcesBaseURL = (url: Optional<string>): void => {
  localStorage.setItem(STORAGE_KEY_RESOURCE_BASE_URL, url || '');
};

export const getPreSignInPageURL = (): string | null => localStorage.getItem(STORAGE_KEY_PRE_SIGNIN_PAGE_URL);

export const updatePreSignInPageURL = (url: Optional<string>): void => {
  localStorage.setItem(STORAGE_KEY_PRE_SIGNIN_PAGE_URL, url || '');
};
