import { ReactElement } from 'react';
import { List, ListSubheader, ListItem, ListItemText } from '@mui/material';
import { formatDate } from '@localstack/services';
import { BackupVault } from '@localstack/types';

export type BackupVaultDetailsProps = {
  vault: Optional<BackupVault>;
};

export const BackupVaultDetails = ({ vault }: BackupVaultDetailsProps): ReactElement => (
  <List dense subheader={<ListSubheader>Backup Vault Details</ListSubheader>}>
    <ListItem>
      <ListItemText primary={vault?.BackupVaultName} secondary="Vault Name" />
    </ListItem>
    <ListItem>
      <ListItemText primary={vault?.BackupVaultArn} secondary="Backup Vault ARN" />
    </ListItem>
    <ListItem>
      <ListItemText primary={vault?.CreationDate && formatDate(vault?.CreationDate)} secondary="Creation date" />
    </ListItem>
    <ListItem>
      <ListItemText
        primary={vault?.EncryptionKeyArn && vault.EncryptionKeyArn.split(':')[5]?.split('/')?.[1]}
        secondary="KMS encryption key ID"
      />
    </ListItem>
  </List>
);
