import { ReactElement } from 'react';
import { MQCreateBrokerRequest } from '@localstack/types';
import { AwsClientOverrides, getSchema } from '@localstack/services';

import { MagicForm } from '../../magic/MagicForm';

export type CreateMQBrokerProps = {
  loading?: boolean;
  formId: string;
  clientOverrides?: AwsClientOverrides;
  onSubmit: (data: MQCreateBrokerRequest) => unknown;
};

export const MQBrokerForm = ({ loading, formId, onSubmit }: CreateMQBrokerProps): ReactElement => (
  <MagicForm
    schema={getSchema('MQ')}
    formId={formId}
    entry="CreateBrokerRequest"
    loading={loading}
    onSubmit={(data: MQCreateBrokerRequest) => {
      if (data) {
        data.AutoMinorVersionUpgrade = !!data.AutoMinorVersionUpgrade;
        data.PubliclyAccessible = !!data.PubliclyAccessible;
      }
      onSubmit(data);
    }}
  />
);
