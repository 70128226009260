import moment from 'moment/moment';
import { ReactElement, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { ApiKey, PlanFamily } from '@localstack/types';
import { CANCELABLE_PLAN_FAMILIES, SUB_ACTIVE_STATUSES, UPDATEABLE_PLAN_FAMILIES } from '@localstack/constants';
import {
  Alert,
  Box,
  Card,
  CardContent,
  CardHeader,
  FormControlLabel,
  Grid,
  IconButton,
  InputAdornment,
  Switch,
  TextField,
  Typography,
} from '@mui/material';
import { Clear as ClearIcon } from '@mui/icons-material';

import {
  ApiKeysList,
  ConfirmableButton,
  DangerZone,
  DangerZoneAction,
  DangerZoneActions,
  LoadingFragment,
  SubscriptionSummary,
  SubscriptionSummarySkeleton,
  PageTitle,
} from '@localstack/ui';

import { AdminService, ComputeService, formatDate, useApiEffect, useApiGetter, useRoutes } from '@localstack/services';

import { AppRoute } from '~/config';
import { AdminLayout } from '~/layouts';

const ADMIN_UPDATEABLE_PLAN_FAMILIES = [
  ...UPDATEABLE_PLAN_FAMILIES,
  PlanFamily.ENTERPRISE_PLANS,
  PlanFamily.MARKETPLACE_PLANS,
];

const ADMIN_CANCELLABLE_PLAN_FAMILIES = [
  ...CANCELABLE_PLAN_FAMILIES,
  PlanFamily.ENTERPRISE_PLANS,
  PlanFamily.MARKETPLACE_PLANS,
];

const keysSorter = (lk: ApiKey, rk: ApiKey) => {
  const leftName = lk.name || '';
  const rightName = rk.name || '';

  if (leftName < rightName) return -1;
  if (leftName > rightName) return 1;
  return 0;
};

export const Subscription = (): ReactElement => {
  const { goto } = useRoutes();
  const { orgId, subscriptionId } = useParams<'orgId' | 'subscriptionId'>() as {
    orgId: string;
    subscriptionId: string;
  };

  const [cancelAt, setCancelAt] = useState<number | null>(null);
  const [cancelAtPeriodEnd, setCancelAtPeriodEnd] = useState<boolean>(false);
  const [reactivateSubscription, setReactivateSubscription] = useState<boolean>(false);

  const [revealKeys, setRevealKeys] = useState(false);
  const [selectedKey, setSelectedKey] = useState<Optional<ApiKey>>(null);
  const [targetSubscriptionId, setTargetSubscriptionId] = useState('');

  const { data: subscription } = useApiGetter(AdminService, 'getOrganizationSubscription', [orgId, subscriptionId]);
  const { data: usage } = useApiGetter(AdminService, 'getOrganizationSubscriptionUsage', [orgId, subscriptionId]);
  const { data: keys, isLoading: isKeysLoading } = useApiGetter(AdminService, 'listOrganizationKeys', [orgId]);
  const { data: organization } = useApiGetter(AdminService, 'getOrganization', [orgId]);
  const { rescheduleOrganizationSubscription, isLoading: isSubRescheduling } = useApiEffect(
    AdminService,
    ['rescheduleOrganizationSubscription'],
    { revalidate: ['getOrganizationSubscription'] },
  );
  const { data: licenseAssignments, isLoading: isLicensesLoading } = useApiGetter(
    AdminService,
    'listOrganizationLicenseAssignments',
    [organization?.id || ''],
    { enable: !!organization },
  );

  const subscriptionKeys = (keys ?? [])
    .slice()
    .filter((k) => k.subscription_id === subscriptionId)
    .sort(keysSorter);
  const nonCIKeys = subscriptionKeys.filter((k) => !k.is_ci);

  const isCancelling = !!(subscription?.cancel_at || subscription?.cancel_at_period_end);
  const isTrial = subscription?.plan.family === PlanFamily.TRIAL_PLANS;
  const isInternal = subscription?.id.startsWith('lsub_'); // TODO: find a better way
  const isActive = subscription && SUB_ACTIVE_STATUSES.includes(subscription.status);

  const isCancelableFamily = subscription && ADMIN_CANCELLABLE_PLAN_FAMILIES.includes(subscription.plan.family);
  const isUpdatablePlanFamily = subscription && ADMIN_UPDATEABLE_PLAN_FAMILIES.includes(subscription.plan.family);

  // note trials are always in "cancelling" state
  const isCancellable =
    subscription?.plan?.family && isCancelableFamily && isActive && (!isCancelling || (isCancelling && isTrial));
  const isUpdateable =
    subscription?.plan?.family && isUpdatablePlanFamily && isActive && (!isCancelling || (isCancelling && isTrial));
  const isReschedulable = isActive || isInternal;

  const {
    updateOrganizationKey,
    cancelOrganizationSubscription,
    migrateSubscriptionApiKeys,
    isLoading: isSubOrKeyMutating,
  } = useApiEffect(
    AdminService,
    ['updateOrganizationKey', 'cancelOrganizationSubscription', 'migrateSubscriptionApiKeys'],
    { revalidate: ['listOrganizationKeys', 'listOrganizationSubscriptions', 'getOrganizationSubscription'] },
  );

  const { data: computeUsage } = useApiGetter(ComputeService, 'getComputeUsage', []);

  useEffect(() => {
    if (subscriptionKeys && !selectedKey) setSelectedKey(subscriptionKeys[0]);
  }, [keys]);

  useEffect(() => {
    if (!subscription) return;

    // update rescheduling data
    setCancelAt(subscription.cancel_at || null);
    setCancelAtPeriodEnd(subscription.cancel_at_period_end || false);
  }, [subscription]);

  const cancelAtChanged = subscription?.cancel_at !== cancelAt;
  const cancelAtPeriodEndChanged = subscription?.cancel_at_period_end !== cancelAtPeriodEnd;

  return (
    <AdminLayout
      documentTitle="Subscription Details"
      title={
        <PageTitle
          title={`Organization ${orgId}`}
          breadcrumbs={[
            ['Organizations', () => goto(AppRoute.ADMIN_ORGANIZATIONS)],
            [orgId, () => goto(AppRoute.ADMIN_ORGANIZATION, { id: orgId })],
            ['Subscriptions', () => goto(AppRoute.ADMIN_ORGANIZATION, { id: orgId })],
            [subscription?.plan?.name ?? subscriptionId, () => null],
          ]}
        />
      }
    >
      <Grid container spacing={3}>
        <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
          <Card>
            <CardHeader title="Subscription Details" />
            {(!subscription || isSubRescheduling || isLicensesLoading) && (
              <>
                <SubscriptionSummarySkeleton />
                <CardContent>
                  <LoadingFragment loading height={165} variant="card" />
                </CardContent>
              </>
            )}
            {subscription && !isSubRescheduling && !isLicensesLoading && (
              <>
                <SubscriptionSummary
                  subscription={subscription}
                  usage={usage}
                  orgKeys={nonCIKeys ?? []}
                  licenseAssignments={licenseAssignments ?? []}
                  members={organization?.members ?? []}
                  computeUsage={computeUsage}
                />
                <CardContent>
                  <DangerZone>
                    <DangerZoneActions>
                      {isUpdateable && (
                        <DangerZoneAction
                          primaryText="Update Subscription"
                          secondaryText="Upgrade or downgrade Subscription"
                          actionText="Update"
                          onClick={() =>
                            goto(AppRoute.ADMIN_ORGANIZATION_SUBSCRIPTION_UPDATE, { orgId, subscriptionId })
                          }
                        />
                      )}
                      {isCancellable && (
                        <ConfirmableButton
                          componentType="DangerZoneAction"
                          primaryText="Cancel Subscription"
                          secondaryText="Cancel this Subscription by the End of Prepaid Period"
                          actionText="Cancel"
                          onClick={async () => {
                            await cancelOrganizationSubscription(orgId, subscriptionId);
                            goto(AppRoute.ADMIN_ORGANIZATION, { id: orgId });
                          }}
                          title="Cancel Subscription"
                          cancelText="Don't Cancel"
                          okText="Cancel Subscription"
                          text="Are you sure you want to cancel this Subscription?"
                        />
                      )}
                      {/* TODO: move to its own component? */}
                      {isReschedulable && (
                        <ConfirmableButton
                          componentType="DangerZoneAction"
                          primaryText="Reschedule Subscription"
                          secondaryText="Set or update date until when Subscription is active"
                          actionText="Reschedule"
                          onClick={
                            // TODO: generate types/clients with nullable props, as they are
                            //       essentially nullable and not just optional/undefined!
                            () =>
                              rescheduleOrganizationSubscription(orgId, subscriptionId, {
                                cancel_at: (cancelAtChanged ? cancelAt : undefined) as number | undefined,
                                cancel_at_period_end: cancelAtPeriodEndChanged ? cancelAtPeriodEnd : undefined,
                                reactivate: reactivateSubscription,
                              })
                          }
                          title="Reschedule Subscription"
                          cancelText="Cancel"
                          okText="Reschedule Subscription"
                          disableOk={!!(cancelAt && cancelAtPeriodEnd) || (!cancelAt && !cancelAtPeriodEnd && isTrial)}
                          text={
                            <>
                              {!cancelAt && !cancelAtPeriodEnd && (
                                <Box mb={2}>
                                  <Alert severity="warning">
                                    <Typography paragraph>
                                      Any existing cancellation date will be removed. If the subscription is scheduled
                                      for cancellation the subscription will be moved to an active state again, unless
                                      unpaid.
                                    </Typography>
                                    <Typography paragraph>
                                      If you would like the subscription to maintain the same cancellation period, make
                                      sure to re-add it below by either cancelling at the period end or on a specific
                                      date.
                                    </Typography>
                                  </Alert>
                                </Box>
                              )}
                              {!isActive && isInternal && (
                                <FormControlLabel
                                  label="Reactivate Subscription"
                                  control={
                                    <Switch
                                      color="primary"
                                      checked={reactivateSubscription}
                                      onChange={(_e, checked) => setReactivateSubscription(checked)}
                                    />
                                  }
                                />
                              )}
                              <Box display="flex" alignItems="center" justifyContent="space-between">
                                <FormControlLabel
                                  label="Cancel At Period End"
                                  control={
                                    <Switch
                                      color="primary"
                                      checked={cancelAtPeriodEnd}
                                      onChange={(_e, checked) => setCancelAtPeriodEnd(checked)}
                                    />
                                  }
                                />
                                <Box p={2}>
                                  <Typography>OR</Typography>
                                </Box>
                                <TextField
                                  required
                                  variant="outlined"
                                  label="Cancel At a Custom Date"
                                  size="medium"
                                  type="date"
                                  InputLabelProps={{ shrink: true }}
                                  InputProps={{
                                    endAdornment: (
                                      <InputAdornment position="start">
                                        <IconButton onClick={() => setCancelAt(null)} size="large">
                                          <ClearIcon />
                                        </IconButton>
                                      </InputAdornment>
                                    ),
                                  }}
                                  onChange={({ target }) =>
                                    setCancelAt(target.value ? moment(target.value).unix() : null)
                                  }
                                  value={cancelAt ? moment.unix(cancelAt).format(moment.HTML5_FMT.DATE) : ''}
                                />
                              </Box>
                              {(cancelAt || cancelAtPeriodEnd) && (
                                <Typography paragraph variant="caption" color="error" align="right">
                                  Subscription will be cancelled on{' '}
                                  <strong>{formatDate(cancelAt || subscription.current_period_end)}</strong>
                                </Typography>
                              )}
                            </>
                          }
                        />
                      )}
                      <ConfirmableButton
                        componentType="DangerZoneAction"
                        primaryText="Migrate API Keys"
                        secondaryText="Migrate all API Keys to another Subscription"
                        actionText="Migrate"
                        onClick={async () => {
                          await migrateSubscriptionApiKeys(orgId, subscriptionId, targetSubscriptionId);
                        }}
                        title="Migrate API Keys"
                        cancelText="Cancel"
                        okText="Migrate"
                        text={
                          <>
                            <Typography paragraph>
                              Please note we are not doing any automated ownership checks here. Make sure the
                              organization owns both subscriptions before confirming the operation.
                            </Typography>
                            <TextField
                              required
                              fullWidth
                              variant="outlined"
                              label="Target Subscription Id"
                              onChange={(event) => setTargetSubscriptionId(event.target.value)}
                              value={targetSubscriptionId}
                            />
                          </>
                        }
                      />
                    </DangerZoneActions>
                  </DangerZone>
                </CardContent>
              </>
            )}
          </Card>
        </Grid>
        <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
          <Card>
            <CardHeader
              title="API Keys"
              action={
                <FormControlLabel
                  control={
                    <Switch
                      color="primary"
                      checked={revealKeys}
                      onChange={(_, checked: boolean) => setRevealKeys(checked)}
                    />
                  }
                  label="Display Keys"
                />
              }
            />
            <LoadingFragment loading={isKeysLoading} arrayData={subscriptionKeys} size={3} height={55}>
              <ApiKeysList
                selectable
                reveal={revealKeys}
                keys={subscriptionKeys}
                loading={isSubOrKeyMutating}
                selected={selectedKey}
                onSave={(keyId, updates) => updateOrganizationKey(orgId, keyId, updates)}
                onSelectKey={setSelectedKey}
              />
            </LoadingFragment>
          </Card>
        </Grid>
      </Grid>
    </AdminLayout>
  );
};
