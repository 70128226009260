import { StackInfo } from '@localstack/types';

function obfuscateKey(key: string): string {
  if (key.length <= 4) {
    return key;
  }

  const firstThreeChars = key.slice(0, 4);
  const remainingChars = '*'.repeat(key.length - 4);
  return firstThreeChars + remainingChars;
}

export const getDisplayName = (stack: StackInfo): string =>
  stack.user_name ||
  stack.user_email ||
  (stack.api_key_name && `${stack.api_key_name} (${obfuscateKey(stack.api_key)})`) ||
  `API key: ${obfuscateKey(stack.api_key)}` ||
  'unknown';
