import { ReactElement } from 'react';
import { useRoutes, useAwsGetter } from '@localstack/services';
import { useParams } from 'react-router-dom';
import { Card, CardContent, Button, Box, Typography } from '@mui/material';
import { Breadcrumbs, AgwApiIntegrationDetails as Details } from '@localstack/ui';
import { DEFAULT_API_GATEWAY_ROUTES } from '@localstack/constants';

import { ApiGatewayProps } from './types';
import { ApiNavTabs } from './components';

export const AgwIntegrationDetails = ({
  Layout,
  clientOverrides,
  routes = DEFAULT_API_GATEWAY_ROUTES,
}: ApiGatewayProps): ReactElement => {
  const { goto } = useRoutes();
  const { apiId: ApiId, integrationId: IntegrationId } = useParams<'apiId' | 'integrationId'>();

  const { data: api } = useAwsGetter('ApiGatewayV2', 'getApi', [{ ApiId }], { clientOverrides });

  const { data: integration } = useAwsGetter('ApiGatewayV2', 'getIntegration', [{ ApiId, IntegrationId }], {
    clientOverrides,
  });

  return (
    <Layout
      documentTitle="Integration Details"
      tabs={<ApiNavTabs apiId={ApiId as string} routes={routes} />}
      title={
        <Box>
          <Typography variant="h4">Integration Details</Typography>
          <Breadcrumbs
            mappings={[
              ['API Gateway', () => goto(routes.RESOURCES_AGW2)],
              [api?.Name, () => goto(routes.RESOURCES_AGW2_API, { apiId: ApiId })],
              [integration?.IntegrationId, null],
            ]}
          />
        </Box>
      }
      actions={
        <Button
          onClick={() =>
            goto(routes.RESOURCES_AGW2_API_INTEGRATION_UPDATE, { apiId: ApiId, integrationId: IntegrationId })
          }
        >
          Edit Integration
        </Button>
      }
    >
      <Card>
        <CardContent>
          <Details integration={integration} />
        </CardContent>
      </Card>
    </Layout>
  );
};
