import { ReactElement } from 'react';
import { NavLink } from '@localstack/ui';
import { buildRoute } from '@localstack/services';

import { GlueRoutes } from '../../types';

type JobTabsProps = {
  routes: GlueRoutes;
  jobId: string;
};

export const JobTabs = ({ routes, jobId }: JobTabsProps): ReactElement => (
  <>
    <NavLink to={buildRoute(routes.RESOURCES_GLUE_JOB_RUNS, { job: jobId })} end>
      Job Runs
    </NavLink>
  </>
);
