import { ReactElement } from 'react';
import {
  Skeleton,
  Box,
  List,
  ListItem,
  ListItemAvatar,
  ListItemSecondaryAction,
  ListItemText,
  Typography,
} from '@mui/material';
import {
  ApiKey,
  LicenseAssignment,
  OrderContext,
  OrganizationMember,
  Product,
  ProductType,
  Subscription,
  UsageSummary,
  ComputeUsageSummary,
} from '@localstack/types';
import { PRICELESS_PLAN_FAMILIES } from '@localstack/constants';

import {
  formatBytes,
  formatDate,
  formatIntervalMonths,
  formatMonetaryAmount,
  getPlanEstimations,
  numNonCIKeysUsed,
  computeNumLicensesUsed,
  getProductEstimations,
  getProductVolume,
} from '@localstack/services';

import { PlanProductIcon } from '../../../display/PlanProductIcon';
import { SubscriptionStatus } from '../../../display/SubscriptionStatus';
import { UsageLimit } from '../../../feedback/UsageLimit';

const getProductUsage = (product: Product, usage: Optional<UsageSummary>): number => {
  const productUsage = usage?.usage_by_product[product.id];
  if ([null, undefined].includes(productUsage as any)) return 0; // eslint-disable-line
  return productUsage as number;
};

export interface SubscriptionSummaryProps {
  subscription: Subscription;
  usage?: Optional<UsageSummary>;
  orgKeys: ApiKey[];
  licenseAssignments: LicenseAssignment[];
  members: OrganizationMember[];
  computeUsage?: Optional<ComputeUsageSummary>;
}

export const RenderComputeUsage = (
  computeUsageSummary: ComputeUsageSummary,
  subscription: Subscription,
): ReactElement => {
  const context: OrderContext = { seats: subscription.seats, org_id: '', customer_id: '' };
  const product = subscription.products.find((prod) => prod.type === ProductType.COMPUTE_USAGE);
  if (!product) return <></>;

  const usedCredits = computeUsageSummary?.usage.per_sub[subscription.id]?.compute_credits ?? 0;

  return (
    <>
      {getProductVolume(product, context)} Credits
      <UsageLimit
        variant="edged"
        greenRange={[0, 70]}
        orangeRange={[70, 90]}
        redRange={[90, 100]}
        value={Math.min(100, (usedCredits / (getProductVolume(product, context) || Infinity)) * 100)}
        displayValue={`${usedCredits} Credits`}
        tooltip={
          <>
            Consumed {usedCredits} out of {getProductVolume(product, context) ?? '∞'} Ephemeral instance credits this
            month
          </>
        }
      />
    </>
  );
};

export const SubscriptionSummarySkeleton = (): ReactElement => (
  <>
    <Box ml={2} mr={2} display="flex" alignItems="center" justifyContent="space-between" flexWrap="wrap">
      <Skeleton variant="text" width={100} />
      <Skeleton variant="text" width={100} />
      <Skeleton variant="text" width={100} />
    </Box>
    <Box ml={2} mr={2}>
      <Skeleton variant="text" height={65} />
      <Skeleton variant="text" height={65} />
      <Skeleton variant="text" height={65} />
    </Box>
  </>
);

export const SubscriptionSummary = ({
  subscription,
  usage,
  orgKeys,
  licenseAssignments,
  members,
  computeUsage,
}: SubscriptionSummaryProps): ReactElement => {
  const hasDiscount = !!subscription.discount && subscription.discount.duration_months !== -1;
  const context: OrderContext = { seats: subscription.seats, org_id: '', customer_id: '' };

  const { products, discount, currency, plan } = subscription;

  const fullAmount = getPlanEstimations(products, context, discount, false);
  const formattedFullAmount = formatMonetaryAmount(fullAmount, subscription.currency);

  const discountedAmount = (subscription.estimated_mrr ?? 0) * subscription.interval_months;
  const formattedDiscountedAmount = formatMonetaryAmount(discountedAmount, currency);

  const licensesUsed =
    subscription && licenseAssignments && orgKeys
      ? computeNumLicensesUsed(subscription, licenseAssignments, orgKeys, members)
      : undefined;

  const terminationDate = subscription?.cancel_at_period_end ? subscription.current_period_end : subscription.cancel_at;

  return (
    <>
      <Box ml={2} mr={2} display="flex" alignItems="center" justifyContent="space-between" flexWrap="wrap">
        <Typography>
          <strong>Status:&nbsp;</strong>
          {subscription && <SubscriptionStatus subscription={subscription} />}
        </Typography>
        {terminationDate ? (
          <Typography>
            <strong>Ends on: </strong>
            {formatDate(terminationDate)}
          </Typography>
        ) : (
          <Typography>
            <strong>Renews: </strong>
            {formatDate(subscription.current_period_end)}
          </Typography>
        )}
        {!PRICELESS_PLAN_FAMILIES.includes(plan.family) && (
          <Typography>
            <strong>Est. Cost (Excl. VAT){hasDiscount && <sup>*</sup>}:&nbsp;</strong>
            {formattedDiscountedAmount}/{subscription.interval_months === 1 ? 'month' : 'year'}
          </Typography>
        )}
      </Box>
      <List disablePadding>
        {products.map((product) => (
          <ListItem key={product.id}>
            <ListItemAvatar>
              <PlanProductIcon productType={product.type} />
            </ListItemAvatar>
            <ListItemText
              primary={product.name}
              secondary={
                <Box pr={2}>
                  {product.type === ProductType.SEATS && (
                    <>
                      {getProductVolume(product, context)} License(s)
                      <UsageLimit
                        variant="edged"
                        greenRange={[0, 70]}
                        orangeRange={[70, 90]}
                        redRange={[90, 100]}
                        value={Math.min(100, ((licensesUsed || 0) / subscription.seats || Infinity) * 100)}
                        displayValue={
                          <>
                            {licensesUsed} of {subscription.seats} (including {numNonCIKeysUsed(subscription, orgKeys)}{' '}
                            legacy API keys)
                          </>
                        }
                        tooltip={
                          <>
                            {licensesUsed} of {subscription.seats} (including {numNonCIKeysUsed(subscription, orgKeys)}{' '}
                            legacy API keys)
                          </>
                        }
                      />
                    </>
                  )}
                  {product.type === ProductType.CI_USAGE && (
                    <>
                      {getProductVolume(product, context)} Credits
                      <UsageLimit
                        variant="edged"
                        greenRange={[0, 70]}
                        orangeRange={[70, 90]}
                        redRange={[90, 100]}
                        value={Math.min(
                          100,
                          (getProductUsage(product, usage) / (getProductVolume(product, context) || Infinity)) * 100,
                        )}
                        displayValue={`${getProductUsage(product, usage)} Credits`}
                        tooltip={
                          <>
                            Consumed {getProductUsage(product, usage)} out of{' '}
                            {getProductVolume(product, context) ?? '∞'} CI credits this month
                          </>
                        }
                      />
                    </>
                  )}
                  {product.type === ProductType.POD_USAGE && (
                    <>
                      {formatBytes(getProductVolume(product, context)) ?? '∞'}
                      <UsageLimit
                        variant="edged"
                        greenRange={[0, 70]}
                        orangeRange={[70, 90]}
                        redRange={[90, 100]}
                        value={Math.min(
                          100,
                          (getProductUsage(product, usage) / (getProductVolume(product, context) || Infinity)) * 100,
                        )}
                        displayValue={formatBytes(getProductUsage(product, usage))}
                        tooltip={
                          <>
                            Consumed {formatBytes(getProductUsage(product, usage)) ?? 0} out of{' '}
                            {formatBytes(getProductVolume(product, context)) ?? '∞'}
                          </>
                        }
                      />
                    </>
                  )}
                  {product.type === ProductType.COMPUTE_USAGE &&
                    computeUsage &&
                    RenderComputeUsage(computeUsage, subscription)}
                </Box>
              }
            />
            {!PRICELESS_PLAN_FAMILIES.includes(plan.family) && (
              <ListItemSecondaryAction>
                <ListItemText primary={formatMonetaryAmount(getProductEstimations(product, context), currency)} />
              </ListItemSecondaryAction>
            )}
          </ListItem>
        ))}
      </List>
      {hasDiscount && (
        <Box ml={2} mr={2}>
          <Typography variant="caption">
            *Please note the total displayed is subject to an introductory discount. We will start charging the full
            amount of {formattedFullAmount} after the {formatIntervalMonths(discount?.duration_months ?? 0)} of your
            active subscription
          </Typography>
        </Box>
      )}
    </>
  );
};
