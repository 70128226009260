import { ElementType } from 'react';
import { MenuItem, SvgIconProps, Typography } from '@mui/material';
import { NavLink, To } from 'react-router-dom';
import { styled, Theme } from '@mui/material/styles';

export const getSectionItemTextColor = (theme: Theme): string => {
  return theme.palette.mode === 'dark' ? theme.palette.text.primary : theme.palette.grey[800];
};

export const getSectionItemBackground = (theme: Theme): string => {
  return theme.palette.mode === 'dark' ? theme.palette.background.paper : theme.palette.grey[200];
};

export const SectionHeader = styled('span')(({ theme }) => ({
  fontWeight: 600,
  fontSize: '14px',
  lineHeight: '20px',
  color: theme.palette.mode === 'dark' ? theme.palette.text.primary : theme.palette.grey[700],
  padding: '0 5px',
}));

export const NavbarContainer = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'column',
  gap: '1rem',
  flexGrow: 1,
}));

export const NavbarSection = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'column',
  gap: '1px',
}));

type StyledMenuItemProps = React.ComponentProps<typeof MenuItem> & {
  component?: React.ElementType;
  to: To;
  end?: boolean;
};

const StyledMenuItem = styled(MenuItem)<StyledMenuItemProps>(({ theme }) => ({
  padding: '5px',
  '&:hover': {
    background: getSectionItemBackground(theme),
  },
  '&.active': {
    background: getSectionItemBackground(theme),
  },
  borderRadius: '4px',
  color: getSectionItemTextColor(theme),
}));

interface SectionItemProps extends StyledMenuItemProps {
  label: string;
  icon: ElementType<SvgIconProps>;
  isActive?: boolean;
}

/** Component that renders a reusable menu item with an icon */
export const SectionItem = ({
  to,
  label,
  icon: IconComponent,
  isActive,
  ...restProps
}: SectionItemProps): JSX.Element => {
  return (
    <StyledMenuItem className={isActive ? 'active' : undefined} component={NavLink} to={to} {...restProps}>
      <IconComponent
        fontSize="small"
        sx={{
          color: (theme) => getSectionItemTextColor(theme),
        }}
      />
      <Typography variant="inherit" sx={{ marginLeft: '0.5em' }}>
        {label}
      </Typography>
    </StyledMenuItem>
  );
};
