import { getSchema } from '@localstack/services';
import { Link } from '@mui/material';
import { DMSReplicationInstance } from '@localstack/types';
import { ReactElement } from 'react';

import { MagicTable } from '../../magic/MagicTable';

export type DMSReplicationInstancesTableProps = {
  replicationInstances: DMSReplicationInstance[];
  onViewReplicationInstance: (id: string) => void;
  onSelect: (ids: string[]) => void;
  loading?: boolean;
};

export const DMSReplicationInstancesTable = ({
  replicationInstances,
  onViewReplicationInstance,
  onSelect,
  loading,
}: DMSReplicationInstancesTableProps): ReactElement => (
  <MagicTable
    entry="ReplicationInstance"
    idAttribute="ReplicationInstanceArn"
    rows={replicationInstances}
    schema={getSchema('DMS')}
    order={['ReplicationInstanceArn']}
    externalFields={{
      ReplicationInstanceArn: ({ ReplicationInstanceArn }) => (
        <Link onClick={() => onViewReplicationInstance(ReplicationInstanceArn || '')}>{ReplicationInstanceArn}</Link>
      ),
    }}
    loading={loading}
    onSelect={onSelect}
    selectable
    disableSelectionOnClick
  />
);
