import { useState, useEffect, ReactElement } from 'react';
import axios from 'axios';
import { Alert, Card, CardHeader, CardContent, CardMedia, CardActions, Grid, Box, Button } from '@mui/material';
import { AwsServiceIcon, ProgressButton } from '@localstack/ui';
import { Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import createStyles from '@mui/styles/createStyles';
import { useAwsGetter, useAwsEffect, useRoutes, useRegion } from '@localstack/services';

import { CloudFormationStack } from '@localstack/types';

import { ContainedCustomerLayout } from '~/layouts';
import { AppRoute } from '~/config';

import stackTemplate1 from '~/assets/samples/demo1/cloudformation.json';
// import stackTemplate2 from '~/assets/samples/demo2/cloudformation.json';

import { NavTabs } from './components/NavTabs';
import { CloudFormationDemo } from './components/common';

const DEMO_REGION = 'us-east-1';
const POLLING_INTERVAL = 2_000; // 2 seconds

const DEMOS: CloudFormationDemo[] = [
  {
    stackName: 'demo1',
    title: 'Request worker sample app',
    services: ['apigateway', 'cloudformation', 'dynamodb', 'lambda', 's3', 'sqs', 'stepfunctions'],
    zipURL: '/demo1/lambda.zip',
    template: stackTemplate1,
    image: '/demo1/architecture.png',
    route: AppRoute.QUICKSTART_DEMO1,
  },
  // {
  //   stackName: 'demo2',
  //   title: 'Async microservice with tracing/debugging',
  //   services: ['apigateway', 'cloudformation', 'dynamodb', 'lambda', 's3', 'sqs', 'Thundra (3rd party integration)'],
  //   // eslint-disable-next-line
  //   zipURL: `https://rawcdn.githack.com/localstack/localstack-artifacts/e069aee4804d77aff46fca1806b2424dc0d92d7c/demos/thundra-demo-localstack.jar`,
  //   template: stackTemplate2,
  //   image: '/demo2/architecture.png',
  //   route: AppRoute.QUICKSTART_DEMO2,
  // },
];

const getStackStatusLabel = (stack: Optional<CloudFormationStack>) => {
  if (stack?.StackStatus?.includes('PROGRESS')) return 'Deploying...';
  if (stack?.StackStatus?.includes('FAILED')) return 'Retry';
  if (stack?.StackStatus?.includes('COMPLETE')) return 'Complete';

  return 'Deploy';
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    media: {
      height: theme.spacing(24),
      borderRadius: 8,
    },
  }),
);

export const QuickstartCloudFormation = (): ReactElement => {
  const classes = useStyles();
  const { goto } = useRoutes();

  const [uploading, setUploading] = useState(false);
  const { region, setRegion } = useRegion();

  const {
    createStack,
    updateStack,
    isLoading: isStackLoading,
  } = useAwsEffect('CloudFormation', ['createStack', 'updateStack'], { revalidate: ['listStacks', 'describeStacks'] });

  const {
    upload,
    createBucket,
    isLoading: isBucketLoading,
  } = useAwsEffect('S3', ['upload', 'createBucket'], { clientOverrides: { s3ForcePathStyle: true, region } });

  const { data: stacks } = useAwsGetter('CloudFormation', 'listStacks', [], {
    swrOverrides: { refreshInterval: POLLING_INTERVAL },
    silentErrors: true,
  });

  const isLoading = uploading || isStackLoading || isBucketLoading;

  const existingStacks: Record<string, CloudFormationStack> = (stacks?.StackSummaries ?? []).reduce(
    (memo, summary) => ({ ...memo, [summary.StackName]: summary }),
    {},
  );

  const prepareStack = async (stackName: string, zipUrl: string) => {
    setUploading(true);
    const { data } = await axios.get(zipUrl, { responseType: 'arraybuffer' });
    const bucketName = `localstack-${stackName}`;
    await createBucket({ Bucket: bucketName });
    await upload({ Bucket: bucketName, Body: new Blob([data]), Key: 'lambda.zip' });
    setUploading(false);
  };

  const deployApp = (app: (typeof DEMOS)[number]) => async () => {
    await prepareStack(app.stackName, app.zipURL);
    if (existingStacks[app.stackName]) {
      await updateStack({ StackName: app.stackName, TemplateBody: JSON.stringify(app.template) });
    } else {
      await createStack({ StackName: app.stackName, TemplateBody: JSON.stringify(app.template) });
    }
  };

  // enforce `DEMO_REGION` to be currently active region,
  // as our templates are made for it
  useEffect(() => {
    if (region !== DEMO_REGION) setRegion(DEMO_REGION);
  }, []);

  return (
    <ContainedCustomerLayout title="Quickstart" tabs={<NavTabs />}>
      <Grid container spacing={3}>
        {DEMOS.map((demo) => (
          <Grid item sm={4} key={demo.stackName}>
            <Card>
              <CardHeader title={demo.title} />
              <CardMedia className={classes.media} image={demo.image} />
              <CardContent>
                {existingStacks[demo.stackName]?.StackStatus?.includes('FAILED') && (
                  <Alert severity="error">{existingStacks[demo.stackName]?.StackStatusReason}</Alert>
                )}
                <Box display="flex">
                  {demo.services.map((code) => (
                    <AwsServiceIcon code={code} key={code} />
                  ))}
                </Box>
              </CardContent>
              <CardActions>
                {!existingStacks[demo.stackName]?.StackStatus?.includes('COMPLETE') && (
                  <ProgressButton
                    color="primary"
                    // variant="outlined"
                    loading={isLoading || existingStacks[demo.stackName]?.StackStatus?.includes('PROGRESS')}
                    onClick={deployApp(demo)}
                  >
                    {getStackStatusLabel(existingStacks[demo.stackName])}
                  </ProgressButton>
                )}
                {existingStacks[demo.stackName]?.StackStatus?.includes('COMPLETE') && (
                  <Button color="primary" onClick={() => goto(demo.route)}>
                    View Application
                  </Button>
                )}
              </CardActions>
            </Card>
          </Grid>
        ))}
      </Grid>
    </ContainedCustomerLayout>
  );
};
