import { AuditTrailQueryStatus } from '@localstack/types';

export const LOADING_AUDIT_TRAIL_STATES = [AuditTrailQueryStatus.SCHEDULED, AuditTrailQueryStatus.RUNNING];

export const FAILED_AUDIT_TRAIL_STATES = [
  AuditTrailQueryStatus.FAILED,
  AuditTrailQueryStatus.CANCELLED,
  AuditTrailQueryStatus.TIMEOUT,
  AuditTrailQueryStatus.UNKNOWN,
];

export const COMPLETED_AUDIT_TRAIL_STATES = [
  AuditTrailQueryStatus.COMPLETE,
  AuditTrailQueryStatus.FAILED,
  AuditTrailQueryStatus.CANCELLED,
  AuditTrailQueryStatus.TIMEOUT,
  AuditTrailQueryStatus.UNKNOWN,
];
