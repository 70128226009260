import { ReactElement } from 'react';
import { Link } from '@mui/material';
import { KMSKey } from '@localstack/types';
import { getSchema } from '@localstack/services';

import { MagicTable } from '../../magic/MagicTable';

export interface KMSKeysTableProps {
  keys: KMSKey[];
  loading?: boolean;
  selectable?: boolean;
  onViewKey?: (key: KMSKey) => unknown;
  onSelect?: (ids: string[]) => unknown;
}

export const KMSKeysTable = ({ keys, loading, selectable, onViewKey, onSelect }: KMSKeysTableProps): ReactElement => (
  <MagicTable
    schema={getSchema('KMS')}
    entry="KeyListEntry"
    idAttribute="KeyId"
    rows={keys}
    selectable={selectable}
    onSelect={onSelect}
    order={['KeyId']}
    loading={loading}
    externalFields={{
      KeyId: (row) => (
        <Link onClick={() => onViewKey && onViewKey(row)} underline="hover">
          {row.KeyId}
        </Link>
      ),
    }}
  />
);
