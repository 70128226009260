import { ReactElement } from 'react';
import { useParams } from 'react-router-dom';
import { useRoutes, useAwsEffect, useAwsGetter } from '@localstack/services';
import { Card, CardContent, CardActions, Box, Typography } from '@mui/material';
import { CreateAgwApiRequest, UpdateAgwApiRequest } from '@localstack/types';
import { ProgressButton, AgwApiForm, Breadcrumbs } from '@localstack/ui';
import { DEFAULT_API_GATEWAY_ROUTES } from '@localstack/constants';

import { ApiGatewayProps } from './types';
import { IndexNavTabs } from './components';

export const AgwApiUpsert = ({
  Layout,
  clientOverrides,
  routes = DEFAULT_API_GATEWAY_ROUTES,
}: ApiGatewayProps): ReactElement => {
  const { goto } = useRoutes();
  const { apiId: ApiId } = useParams<'apiId'>();

  const { createApi, updateApi, isLoading } = useAwsEffect('ApiGatewayV2', ['createApi', 'updateApi'], {
    revalidate: ['getApi', 'getApis'],
    clientOverrides,
  });

  const { data: api } = useAwsGetter('ApiGatewayV2', 'getApi', [{ ApiId }], { clientOverrides });

  const handleCreateApi = async (data: CreateAgwApiRequest) => {
    await createApi(data);
    goto(routes.RESOURCES_AGW2);
  };

  const handleUpdateApi = async (data: UpdateAgwApiRequest) => {
    await updateApi(data);
    goto(routes.RESOURCES_AGW2_API, { apiId: ApiId });
  };

  return (
    <Layout
      title={
        <Box>
          <Typography variant="h4">API Details</Typography>
          <Breadcrumbs
            mappings={[
              ['API Gateway', () => goto(routes.RESOURCES_AGW2)],
              [api?.Name, () => goto(routes.RESOURCES_AGW2_API, { apiId: ApiId })],
              [ApiId ? 'Update' : 'Create API', null],
            ]}
          />
        </Box>
      }
      tabs={<IndexNavTabs routes={routes} />}
      actions={
        <ProgressButton type="submit" form="update" variant="outlined" color="primary" loading={isLoading}>
          Submit
        </ProgressButton>
      }
    >
      <Card>
        <CardContent>
          <AgwApiForm
            api={api}
            onCreate={handleCreateApi}
            onUpdate={handleUpdateApi}
            loading={isLoading}
            formId="update"
          />
        </CardContent>
        <CardActions>
          <ProgressButton type="submit" form="update" variant="contained" color="primary" loading={isLoading}>
            Submit
          </ProgressButton>
        </CardActions>
      </Card>
    </Layout>
  );
};
