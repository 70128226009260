import { ReactElement, useMemo } from 'react';
import { Link } from '@mui/material';
import { AgwRestApiResource } from '@localstack/types';
import { getSchema } from '@localstack/services';

import { MagicTable } from '../../../magic/MagicTable';

export type AgwRestApiResourcesTableProps = {
  selectable?: boolean;
  loading?: boolean;
  resources: AgwRestApiResource[];
  onViewResource?: (resource: AgwRestApiResource) => unknown;
  onSelect?: (ids: string[]) => unknown;
};

export const AgwRestApiResourcesTable = ({
  resources,
  selectable = true,
  loading,
  onViewResource,
  onSelect,
}: AgwRestApiResourcesTableProps): ReactElement => {
  const sortedResources = useMemo(
    () =>
      [...resources].sort((left, right) => {
        const leftPath = left.path as string;
        const rightPath = right.path as string;

        if (rightPath.startsWith(leftPath)) return -1;
        if (leftPath.startsWith(rightPath)) return 1;
        return 0;
      }),
    [resources],
  );

  return (
    <MagicTable
      schema={getSchema('APIGateway')}
      entry="Resource"
      idAttribute="id"
      rows={sortedResources}
      selectable={selectable}
      onSelect={onSelect}
      order={['id']}
      loading={loading}
      externalFields={{
        id: (row) => (
          <Link onClick={() => onViewResource && onViewResource(row)} underline="hover">
            {row.id}
          </Link>
        ),
      }}
    />
  );
};
