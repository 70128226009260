import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';

import { RELEASE_VERSION } from '~/config/config';
import { getUserInfo } from '~/util/storage';

const SENTRY_URL = process.env.REACT_APP_SENTRY_URL as Optional<string>;

const getEnvironmentName = () => {
  const envName = process.env.NODE_ENV;

  if (envName === 'production') return 'production';
  if (envName === 'production.staging') return 'staging';

  return 'development';
};

/**
 * Encapsulate errors tracker configuration.
 * Could be replace with any other backend we
 * choose to track errors in the future.
 */
export const configureErrorsTracker = (): void => {
  if (!SENTRY_URL) return;

  Sentry.init({
    dsn: SENTRY_URL,
    // https://github.com/getsentry/sentry-javascript/issues/3203
    integrations: [new Integrations.BrowserTracing({ tracingOrigins: ['::1'] })],
    tracesSampleRate: 0.5,
    environment: getEnvironmentName(),
    release: RELEASE_VERSION,
    ignoreErrors: [
      /cannot redefine property: googletag/, // caused by adblock, can be ignored
      /network error/i,
      /request aborted/i,
      /failed to refresh token/i,
    ],
  });

  Sentry.configureScope((scope) => {
    const userInfo = getUserInfo();

    if (!userInfo) return;

    scope.setUser({
      id: userInfo.user.id,
      email: userInfo.user.email,
      ip_address: '{{auto}}',
    });
  });
};

/**
 * Encapsulate manual (handled) exception tracking.
 */
export const captureException = (e: Error | unknown): void => {
  Sentry.captureException(e);
};
