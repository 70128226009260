import { ReactElement } from 'react';
import { Card, Box, Typography, Button } from '@mui/material';
import { useParams } from 'react-router-dom';

import { ConfirmableButton, Dropdown, SNSTopicAttributes, LoadingFragment, Breadcrumbs } from '@localstack/ui';

import { useAwsEffect, useAwsGetter, useRoutes } from '@localstack/services';

import { DEFAULT_SNS_ROUTES } from '@localstack/constants';

import { TopicNavTabs } from './components';
import { SNSProps } from './types';

export const SNSTopic = ({ Layout, clientOverrides, routes = DEFAULT_SNS_ROUTES }: SNSProps): ReactElement => {
  const { goto } = useRoutes();

  const { topicArn } = useParams<'topicArn'>();

  const { data: topicAttributes, isLoading: isLoadingTopic } = useAwsGetter(
    'SNS',
    'getTopicAttributes',
    [{ TopicArn: topicArn }],
    { clientOverrides },
  );

  const topic = {
    ...topicAttributes,
    TopicArn: topicArn as string,
    Name: topicArn?.split(':')?.slice(-1)?.[0] as string,
  };

  const { deleteTopic } = useAwsEffect('SNS', ['deleteTopic'], { revalidate: ['listTopics'], clientOverrides });

  return (
    <Layout
      documentTitle="SNS Topic"
      title={
        <Box>
          <Typography variant="h4">SNS Topic</Typography>
          <Breadcrumbs
            mappings={[
              ['SNS', () => goto(routes.RESOURCES_SNS_TOPICS)],
              [topicArn?.split(':')?.slice(-1)?.[0], null],
            ]}
          />
        </Box>
      }
      tabs={<TopicNavTabs topicArn={topicArn as string} routes={routes} />}
      actions={
        <>
          <Button onClick={() => goto(routes.RESOURCES_SNS_TOPIC_MESSAGE_SEND_PREFILLED, { topicArn })}>
            Publish Message
          </Button>
          <Dropdown label="Actions">
            <ConfirmableButton
              componentType="MenuItem"
              disabled={isLoadingTopic}
              title={`Delete Topic ${topic?.Name}?`}
              text={`Are you sure you would like to delete ${topic?.Name}?`}
              onClick={async () => {
                goto(routes.RESOURCES_SNS_TOPICS);
                await deleteTopic({ TopicArn: topicArn as string });
              }}
            >
              Delete Topic
            </ConfirmableButton>
          </Dropdown>
        </>
      }
    >
      <Card>
        <LoadingFragment loading={isLoadingTopic}>
          <SNSTopicAttributes attributes={topic?.Attributes} />
        </LoadingFragment>
      </Card>
    </Layout>
  );
};
