import React, { ReactElement } from 'react';
import { Add as AddIcon, Remove as RemoveIcon, DesktopWindowsOutlined as BrowserIcon } from '@mui/icons-material';

import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Chip,
  Collapse,
  Divider,
  Grid,
  GridSize,
  Theme,
  Tooltip,
  Typography,
} from '@mui/material';

import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';

import {
  AllowedPlanChipPlans,
  EnrichedExtension,
  LocalStackEvent,
  PlanFamily,
  ProductFeature,
} from '@localstack/types';

import { buildRoute, getSelectedInstance, useRoutes } from '@localstack/services';

import { ConfirmableButton, LogOutput, MarkdownRenderer, PlanChip, StatusIndicator } from '@localstack/ui';

import classNames from 'classnames';

import { useAuthProvider } from '~/hooks/useAuthProvider';

import { AppRoute } from '~/config';

import { ExtensionHeader } from './ExtensionHeader';

type ExtensionProps = {
  extension: EnrichedExtension;
  onInstall?: () => void;
  onUninstall?: () => void;
  installed?: boolean;
  isUpdating?: boolean;
  disableManage?: boolean;
  size?: CardSize;
  logOutput?: LocalStackEvent[];
  showTerminal?: boolean;
  hideActions?: boolean;
  instanceSelectorButton?: ReactElement;
  highlighted?: boolean;
  hasError?: boolean;
  secondaryAction?: ReactElement;
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    card: {
      height: '100%',
      display: 'flex',
      flexDirection: 'column',
    },
    highlighted: {
      border: `2px solid ${theme.palette.primary.main}`,
      animation: '$fadeBorderAnimation 10s forwards',
    },
    '@keyframes fadeBorderAnimation': {
      '0%': {
        borderColor: `2px solid ${theme.palette.primary.main}`,
      },
      '100%': {
        borderColor: 'transparent',
      },
    },
    cardHeader: {
      display: 'flex',
      flexDirection: 'column',
      gap: '0.75rem',
    },
    cardContent: {
      flexGrow: 1,
      display: 'flex',
      flexDirection: 'column',
    },
    cardActions: {
      gap: '1rem',
    },
    installedChip: {
      backgroundColor: theme.palette.info.main,
      color: 'white',
    },
    button: {
      textTransform: 'none',
    },
    statusWrapper: {
      display: 'flex',
      '&:hover': {
        cursor: 'pointer',
      },
    },
  }),
);

const preventDefaultHandler = (event: React.MouseEvent, fn?: () => void) => {
  event.preventDefault();
  event.stopPropagation();
  fn?.();
};

type CardSize = 'small' | 'medium' | 'large';

const gridSizeCardMapping = {
  small: {
    xs: 12 as GridSize,
    md: 6 as GridSize,
    lg: 4 as GridSize,
  },
  medium: {
    xs: 12 as GridSize,
  },
  large: {
    xs: 12 as GridSize,
  },
};

type GridItemBreakpoints = {
  xs?: GridSize;
  sm?: GridSize;
  md?: GridSize;
  lg?: GridSize;
  xl?: GridSize;
};

export const gridItemBreakpoints = (size: CardSize = 'small'): GridItemBreakpoints => gridSizeCardMapping[size];

export const ExtensionCard = ({
  extension,
  onInstall,
  onUninstall,
  installed = false,
  isUpdating,
  hideActions,
  highlighted = false,
  disableManage,
  size = 'small',
  logOutput,
  showTerminal,
  instanceSelectorButton,
  hasError,
  secondaryAction,
}: ExtensionProps): ReactElement => {
  const classes = useStyles();
  const { goto } = useRoutes();
  const cardBreakpoints = gridItemBreakpoints(size);
  const isSmall = size === 'small';
  const isLarge = size === 'large';

  const showUIButton = (extension.has_ui || extension.isDev) && !secondaryAction;

  const instance = getSelectedInstance();

  const url = `https://pypi.org/project/${extension.name}`;

  const hasLogs = !!logOutput && logOutput.length > 0;

  const extensionAuthor = extension.official ? 'LocalStack' : `${extension.author} | Community`;

  const isEnterpriseExtension = extension.tier === PlanFamily.ENTERPRISE_PLANS;
  const readMoreLink = !isEnterpriseExtension && extension.published ? `[Read more](${url})` : '';
  const description = `${extension.description} ${readMoreLink}`;
  const showVersion = !isSmall && !!extension.version;

  const { hasFeature } = useAuthProvider();
  const allowedToInstall = isEnterpriseExtension ? hasFeature(ProductFeature.FEATURE_LOCALSTACK_EXTENSION) : true;

  const ContactButton = (
    <Button
      variant="contained"
      color="primary"
      href={`mailto:info@localstack.cloud?subject=Interest in ${extension.display_name} extension`}
    >
      Contact Us
    </Button>
  );

  const InstallButton = (
    <Tooltip title={allowedToInstall ? '' : 'This extension requires an enterprise subscription'}>
      <div>
        <ConfirmableButton
          title={`${installed ? 'Remove' : 'Install'} Extension: ${extension.display_name}`}
          text="This action will restart your LocalStack instance. Do you want to continue?"
          className={classes.button}
          color="primary"
          size="small"
          onClick={(event: React.MouseEvent) => {
            preventDefaultHandler(event, installed ? onUninstall : onInstall);
          }}
          startIcon={installed ? <RemoveIcon /> : <AddIcon />}
          disabled={disableManage || !allowedToInstall}
          componentType="Button"
          variant="contained"
        >
          {installed ? 'Remove' : 'Install'}
        </ConfirmableButton>
      </div>
    </Tooltip>
  );

  // TODO: clean up later
  const DisplayButton = extension.published ? InstallButton : ContactButton;

  const handleUiClick = () => {
    const route = buildRoute(AppRoute.EXTENSIONS_DETAILS, { iid: instance?.id, plugin_name: extension.plugin_name });
    goto(route, undefined, `isDev=${(extension.isDev ?? false).toString()}`);
  };

  return (
    <Grid item {...cardBreakpoints}>
      <Card className={classNames(classes.card, { [classes.highlighted]: highlighted })}>
        <CardHeader
          title={
            <Box className={classes.cardHeader}>
              <ExtensionHeader extension={extension} typographyVariant={isLarge ? 'h2' : 'h5'} />
              {!isLarge && (
                <Typography variant={isLarge ? 'h3' : 'caption'} style={{ display: 'flex', alignItems: 'center' }}>
                  {extensionAuthor}
                  {extension.tier && <PlanChip planFamily={extension.tier as AllowedPlanChipPlans} showTooltip />}
                </Typography>
              )}
            </Box>
          }
          action={<Box>{installed && <Chip label="Installed" size="small" className={classes.installedChip} />}</Box>}
        />
        <CardContent className={classes.cardContent}>
          {isLarge && <Typography variant="h3">Author: {extension.author}</Typography>}
          {showVersion && <Typography variant={isLarge ? 'h4' : 'body2'}>Version: {extension.version}</Typography>}
          {isLarge ? (
            <>
              <Divider style={{ margin: '2rem 0' }} />
              <MarkdownRenderer>{extension?.description || ''}</MarkdownRenderer>
            </>
          ) : (
            <MarkdownRenderer
              components={{
                p: ({ children }) => <Typography variant="caption">{children}</Typography>,
              }}
            >
              {description}
            </MarkdownRenderer>
          )}
          <Collapse style={{ marginTop: '1rem' }} in={showTerminal && hasLogs} timeout={{ enter: 500 }}>
            <LogOutput isProgressing={isUpdating} logEvents={logOutput} />
          </Collapse>
        </CardContent>
        {!hideActions && (
          <CardActions className={classes.cardActions} style={{ justifyContent: 'space-between' }}>
            {hasLogs && <StatusIndicator hasError={hasError} isProgressing={isUpdating} logEvents={logOutput} />}
            <div />
            <div style={{ display: 'flex', alignItems: 'center', gap: '1rem' }}>
              {showUIButton && (
                <Tooltip title={installed ? 'Open UI' : 'Includes UI'}>
                  <div>
                    <Button
                      onClick={handleUiClick}
                      variant="outlined"
                      disabled={disableManage || !allowedToInstall || !installed}
                    >
                      <BrowserIcon />
                    </Button>
                  </div>
                </Tooltip>
              )}
              {secondaryAction}
              {(onInstall || onUninstall) && DisplayButton}
              {instanceSelectorButton}
            </div>
          </CardActions>
        )}
      </Card>
    </Grid>
  );
};
