import { useState, useCallback, ReactElement } from 'react';
import { useRoutes, useAwsGetter, useAwsEffect } from '@localstack/services';
import { AthenaDataCatalogsTable, Dropdown, ConfirmableButton, PageTitle } from '@localstack/ui';
import { Card, Button } from '@mui/material';

import { DEFAULT_ATHENA_ROUTES } from '@localstack/constants';

import { NavTabs } from './components';
import { AthenaProps } from './types';

export const AthenaCatalogs = ({
  Layout,
  clientOverrides,
  routes = DEFAULT_ATHENA_ROUTES,
}: AthenaProps): ReactElement => {
  const { goto } = useRoutes();

  const [selectedObjects, setSelectedObjects] = useState<string[]>([]);
  const { data: catalogs, isLoading, mutate } = useAwsGetter('Athena', 'listDataCatalogs', [], { clientOverrides });
  const { deleteDataCatalog } = useAwsEffect('Athena', ['deleteDataCatalog'], {
    revalidate: ['listDataCatalogs'],
    clientOverrides,
  });

  const handleDeleteObjects = useCallback(
    async () => Promise.all(selectedObjects.map((Name) => deleteDataCatalog({ Name }))),
    [selectedObjects],
  );

  return (
    <Layout
      title={<PageTitle title="Athena Catalogs" onMutate={mutate} />}
      tabs={<NavTabs routes={routes} />}
      actions={
        <>
          <Button onClick={() => goto(routes.RESOURCES_ATHENA_CATALOG_CREATE)}>Create</Button>
          <Dropdown label="Actions">
            <ConfirmableButton
              componentType="MenuItem"
              disabled={selectedObjects.length === 0 || isLoading}
              title={`Remove ${selectedObjects.length} Catalog(s)?`}
              onClick={handleDeleteObjects}
              text="Selected Catalogs will be permanently deleted"
            >
              Remove Selected
            </ConfirmableButton>
          </Dropdown>
        </>
      }
    >
      <Card>
        <AthenaDataCatalogsTable
          catalogs={catalogs?.DataCatalogsSummary ?? []}
          loading={isLoading}
          selectable
          onSelect={setSelectedObjects}
          onViewCatalog={(catalog) =>
            goto(routes.RESOURCES_ATHENA_CATALOG_UPDATE, { catalogName: catalog.CatalogName })
          }
        />
      </Card>
    </Layout>
  );
};
