import { ReactElement } from 'react';
import { Card, CardContent, CardActions, Box, Typography } from '@mui/material';
import { useAwsEffect, useRoutes } from '@localstack/services';
import { CreateLoadBalancerV2 } from '@localstack/types';
import { Breadcrumbs, ProgressButton, ELoadBalancerV2Form } from '@localstack/ui';

import { EC2Props } from './types';

export const LoadBalancerV2Upsert = ({ Layout, clientOverrides, routes }: EC2Props): ReactElement => {
  const { goto } = useRoutes();

  const { createLoadBalancer, isLoading } = useAwsEffect('ELBv2', ['createLoadBalancer'], {
    revalidate: ['describeLoadBalancers'],
    clientOverrides,
  });

  const handleCreate = async (data: CreateLoadBalancerV2) => {
    if (await createLoadBalancer(data)) {
      goto(routes.RESOURCES_EC2_LBV2);
    }
  };

  return (
    <Layout
      documentTitle="Load Balancer V2 Details"
      title={
        <Box>
          <Typography variant="h4">Load Balancer V2 Details</Typography>
          <Breadcrumbs
            mappings={[
              ['ELB', () => goto(routes.RESOURCES_EC2_LBV2)],
              ['Create Load Balancer V2', null],
            ]}
          />
        </Box>
      }
      actions={
        <ProgressButton
          type="submit"
          form="CreateLoadBalancerV2"
          variant="outlined"
          color="primary"
          loading={isLoading}
        >
          Submit
        </ProgressButton>
      }
    >
      <Card>
        <CardContent>
          <ELoadBalancerV2Form
            onSubmit={handleCreate}
            clientOverrides={clientOverrides}
            formId="CreateLoadBalancerV2"
            loading={isLoading}
          />
        </CardContent>
        <CardActions>
          <ProgressButton
            type="submit"
            form="CreateLoadBalancerV2"
            variant="contained"
            color="primary"
            loading={isLoading}
          >
            Submit
          </ProgressButton>
        </CardActions>
      </Card>
    </Layout>
  );
};
