import { ReactElement } from 'react';
import { Card, CardContent, CardActions, Link } from '@mui/material';
import { Organization } from '@localstack/types';
import { DataGrid, GridColDef, ProgressButton } from '@localstack/ui';

const buildColumns = (onSelectOrg: (org: Organization) => void): GridColDef[] => [
  {
    field: 'id',
    headerName: 'ID',
    renderCell: (params) => (
      <Link onClick={() => onSelectOrg(params.row as Organization)} underline="hover">
        {params.value}
      </Link>
    ),
  },
  {
    field: 'name',
    headerName: 'Company Name',
    width: 200,
  },
  {
    field: 'address',
    headerName: 'Address',
    width: 350,
    renderCell: (params) => {
      const org = params.row as Organization;
      return `${org.address}, ${org.zip} ${org.city}, ${org.country}`;
    },
  },
  {
    field: 'email',
    headerName: 'Contact Email',
    width: 250,
  },
  {
    field: 'phone',
    headerName: 'Contact Phone',
    width: 250,
  },
];

type Props = {
  loading?: boolean;
  organizations: Organization[];
  onLoadMore?: () => void;
  onSelectOrgs: (ids: string[]) => void;
  onSelectOrg: (org: Organization) => void;
};

export const OrganizationsList = ({
  organizations,
  loading,
  onLoadMore,
  onSelectOrgs,
  onSelectOrg,
}: Props): ReactElement => (
  <Card>
    <CardContent>
      <DataGrid
        loading={loading}
        autoHeight
        rows={organizations}
        columns={buildColumns(onSelectOrg)}
        onSelectionModelChange={(ids) => onSelectOrgs(ids as string[])}
        rowsPerPageOptions={[50, 100, 200, 500, 1000]}
        checkboxSelection
        disableSelectionOnClick
      />
    </CardContent>
    {onLoadMore && (
      <CardActions>
        <ProgressButton loading={loading} color="primary" onClick={onLoadMore}>
          Load More
        </ProgressButton>
      </CardActions>
    )}
  </Card>
);
