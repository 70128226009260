import { SERVICE_NAME } from '@localstack/types';

import { PATH_PREFIX_RESOURCES } from './base';

const IAM_BASE_ROUTE = `${PATH_PREFIX_RESOURCES}/${SERVICE_NAME.IAM}`;

export const DEFAULT_IAM_ROUTES: Record<string, string> = {
  RESOURCES_IAM: `${IAM_BASE_ROUTE}`,
  RESOURCES_IAM_GROUPS: `${IAM_BASE_ROUTE}/groups`,
  RESOURCES_IAM_GROUP: `${IAM_BASE_ROUTE}/groups/:groupName`,
  RESOURCES_IAM_GROUP_CREATE: `${IAM_BASE_ROUTE}/groups/new`,
  RESOURCES_IAM_GROUP_UPDATE: `${IAM_BASE_ROUTE}/groups/:groupName/update`,
  RESOURCES_IAM_USER: `${IAM_BASE_ROUTE}/users/:userName`,
  RESOURCES_IAM_USER_CREATE: `${IAM_BASE_ROUTE}/users/new`,
  RESOURCES_IAM_USER_UPDATE: `${IAM_BASE_ROUTE}/users/:userName/update`,
  RESOURCES_IAM_ROLES: `${IAM_BASE_ROUTE}/roles`,
  RESOURCES_IAM_ROLE: `${IAM_BASE_ROUTE}/roles/:roleName`,
  RESOURCES_IAM_ROLE_CREATE: `${IAM_BASE_ROUTE}/roles/new`,
  RESOURCES_IAM_ROLE_UPDATE: `${IAM_BASE_ROUTE}/roles/:roleName/update`,
  RESOURCES_IAM_POLICIES: `${IAM_BASE_ROUTE}/policies`,
  RESOURCES_IAM_POLICY: `${IAM_BASE_ROUTE}/policies/:policyArn`,
  RESOURCES_IAM_POLICY_VERSION: `${IAM_BASE_ROUTE}/policies/:policyArn/:versionId`,
  RESOURCES_IAM_POLICY_VERSION_UPDATE: `${IAM_BASE_ROUTE}/policies/:policyArn/:versionId/update`,
  RESOURCES_IAM_POLICY_CREATE: `${IAM_BASE_ROUTE}/policies/new`,
  RESOURCES_IAM_ROLE_POLICIES: `${IAM_BASE_ROUTE}/roles/:roleName/policies`,
  RESOURCES_IAM_ROLE_POLICIES_ATTACH: `${IAM_BASE_ROUTE}/roles/:roleName/policies/attach`,
  RESOURCES_IAM_ROLE_INLINE_POLICY_CREATE: `${IAM_BASE_ROUTE}/roles/:roleName/policies/create`,
  RESOURCES_IAM_ROLE_INLINE_POLICY_UPDATE: `${IAM_BASE_ROUTE}/roles/:roleName/policies/:policyName/update`,
};
