import { ReactElement } from 'react';
import { useParams } from 'react-router-dom';
import { Card, CardContent, CardActions, Box, Typography } from '@mui/material';
import { CreateAgwRestApiDeploymentRequest } from '@localstack/types';
import { useRoutes, useAwsEffect, useAwsGetter } from '@localstack/services';
import { ProgressButton, AgwRestApiDeploymentForm, Breadcrumbs } from '@localstack/ui';
import { DEFAULT_API_GATEWAY_ROUTES } from '@localstack/constants';

import { ApiGatewayProps } from './types';
import { RestApiNavTabs } from './components';

export const AgwRestApiDeploymentCreate = ({
  Layout,
  clientOverrides,
  routes = DEFAULT_API_GATEWAY_ROUTES,
}: ApiGatewayProps): ReactElement => {
  const { goto } = useRoutes();
  const { restApiId } = useParams<'restApiId'>();

  const { data: api } = useAwsGetter('APIGateway', 'getRestApi', [{ restApiId }], { clientOverrides });

  const { createDeployment, isLoading } = useAwsEffect('APIGateway', ['createDeployment'], {
    revalidate: ['getStage', 'getStages'],
    clientOverrides,
  });

  const handleCreate = async (data: CreateAgwRestApiDeploymentRequest) => {
    await createDeployment(data);
    goto(routes.RESOURCES_AGW1_API_STAGES, { restApiId });
  };

  return (
    <Layout
      title={
        <Box>
          <Typography variant="h4">Deployment Details</Typography>
          <Breadcrumbs
            mappings={[
              ['API Gateway', () => goto(routes.RESOURCES_AGW1)],
              [api?.name, () => goto(routes.RESOURCES_AGW1_API, { restApiId })],
              ['Deploy', null],
            ]}
          />
        </Box>
      }
      tabs={<RestApiNavTabs restApiId={restApiId as string} routes={routes} />}
      actions={
        <ProgressButton type="submit" form="deploy" variant="outlined" color="primary" loading={isLoading}>
          Submit
        </ProgressButton>
      }
    >
      <Card>
        <CardContent>
          <AgwRestApiDeploymentForm
            restApiId={restApiId as string}
            onCreate={handleCreate}
            loading={isLoading}
            formId="deploy"
          />
        </CardContent>
        <CardActions>
          <ProgressButton type="submit" form="deploy" variant="contained" color="primary" loading={isLoading}>
            Submit
          </ProgressButton>
        </CardActions>
      </Card>
    </Layout>
  );
};
