import { RB_ROUTES } from './resources';
import { PATH_PREFIX_INSTANCE, PATH_PREFIX_RESOURCES } from './routes';

export const INSTANCE_ROUTES = {
  INSTANCE_COMMON: `${PATH_PREFIX_INSTANCE}/*`,
  STACK_OVERVIEW: `${PATH_PREFIX_INSTANCE}/overview`,
  STATUS: `${PATH_PREFIX_INSTANCE}/status`,
  INSTANCE_STATE: `${PATH_PREFIX_INSTANCE}/state`,
  INSTANCE_LOGS: `${PATH_PREFIX_INSTANCE}/logs`,

  RESOURCES_COMMON: `${PATH_PREFIX_RESOURCES}/*`,
  RESOURCES_OVERVIEW: `${PATH_PREFIX_RESOURCES}`,

  IAM_POLICY_STREAM: `${PATH_PREFIX_INSTANCE}/policy-stream`,
  CHAOS_ENGINEERING: `${PATH_PREFIX_INSTANCE}/chaos-engineering`,

  EXTENSIONS_MANAGE: `${PATH_PREFIX_INSTANCE}/extensions/manage`,
  EXTENSIONS_DETAILS: `${PATH_PREFIX_INSTANCE}/extensions/details/:plugin_name`,

  ...RB_ROUTES,
};
