import { DEFAULT_DMS_ROUTES } from '@localstack/constants';
import { ReactElement } from 'react';
import { useAwsGetter, useRoutes } from '@localstack/services';
import { useParams } from 'react-router-dom';
import { Box, Card, Typography } from '@mui/material';
import { Breadcrumbs, DMSEndpointDetail } from '@localstack/ui';

import { DMSProps } from './types';
import { EndpointTabs } from './components/EndpointTabs';

export const DMSEndpoint = ({ Layout, clientOverrides, routes = DEFAULT_DMS_ROUTES }: DMSProps): ReactElement => {
  const { goto } = useRoutes();
  const { id } = useParams<'id'>();
  const { data: endpoint } = useAwsGetter(
    'DMS',
    'describeEndpoints',
    [{ Filters: [{ Name: 'endpoint-arn', Values: [id || ''] }] }],
    {
      clientOverrides,
    },
  );
  return (
    <Layout
      title={
        <Box>
          <Typography variant="h4">DMS Endpoint</Typography>
          <Breadcrumbs
            mappings={[
              ['DMS', () => goto(routes.RESOURCES_DMS_ENDPOINTS)],
              [id, null],
            ]}
          />
        </Box>
      }
      tabs={<EndpointTabs routes={routes} endpointId={id || ''} />}
    >
      <Card>
        <DMSEndpointDetail endpoint={endpoint?.Endpoints?.[0]} />
      </Card>
    </Layout>
  );
};
