import { SERVICE_CODES } from '@localstack/constants';
import { pluralizeOrDash } from '@localstack/services';
import { GeneratedPolicy } from '@localstack/types';
import { Box, Chip } from '@mui/material';
import { ReactElement } from 'react';

const collectActions = (policy: GeneratedPolicy): string[] =>
  policy.policy_document.Statement.reduce<string[]>((actions, statement) => {
    if (Array.isArray(statement.Action)) {
      actions.push(...statement.Action); // If action is an array, spread and add each element
    } else {
      actions.push(statement.Action); // If action is a string, add it directly
    }
    return actions;
  }, []);

export const getServicesForPolicy = (policy: GeneratedPolicy): (string | undefined)[] => {
  const allActions = collectActions(policy);
  const services = allActions.map((action) => action.split(':')[0]);
  return Array.from(new Set(services));
};

export const getActionsForPolicy = (policy: GeneratedPolicy): (string | undefined)[] => {
  const allActions = collectActions(policy);
  const actions = allActions.map((action) => action.split(':').at(-1));
  return Array.from(new Set(actions));
};

export const renderServices = (services: (string | undefined)[]): ReactElement => {
  const servicesToDisplay = services.length > 2 ? services.slice(0, 2) : services;
  const remainder = services.length - 2;

  return (
    <Box sx={{ display: 'flex', gap: '0.4rem' }}>
      {servicesToDisplay.map((service: string) => (
        <Chip
          label={SERVICE_CODES[service as keyof typeof SERVICE_CODES]}
          variant="outlined"
          size="small"
          key={service}
        />
      ))}
      {remainder > 0 && (
        <Chip label={`+${pluralizeOrDash(remainder, 'Service', 'Services')}`} variant="outlined" size="small" />
      )}
    </Box>
  );
};

export const renderActions = (actions: (string | undefined)[]): ReactElement => {
  const actionsToDisplay = actions.length > 2 ? actions.slice(0, 2) : actions;
  const remainder = actions.length - 2;

  return (
    <Box sx={{ display: 'flex', gap: '0.4rem' }}>
      {actionsToDisplay.join(', ')}
      {remainder > 0 && `, and ${remainder} more`}
    </Box>
  );
};
