import { ReactElement, useMemo } from 'react';
import { useAwsEffect, useAwsGetter, useRoutes } from '@localstack/services';
import { DEFAULT_ACCOUNT_ROUTES } from '@localstack/constants';
import { PutAlternateContactRequest } from '@localstack/types';
import { useLocation } from 'react-router-dom';
import { Card, CardContent, CardActions } from '@mui/material';

import { AlternateContactForm, PageTitle, ProgressButton } from '@localstack/ui';

import { AccountProps } from './types';
import { NavTabs } from './components/NavTabs';

export const AlternateContactUpsert = ({
  Layout,
  clientOverrides,
  routes = DEFAULT_ACCOUNT_ROUTES,
}: AccountProps): ReactElement => {
  const { search } = useLocation();

  const queryParams = useMemo(() => new URLSearchParams(search), [search]);
  const contactType = queryParams.get('contactType') || 'BILLING';

  const { goto } = useRoutes();

  const { data: info } = useAwsGetter('Account', 'getAlternateContact', [{ AlternateContactType: contactType }], {
    clientOverrides,
    silentErrors: true,
  });

  const { putAlternateContact, isLoading } = useAwsEffect('Account', ['putAlternateContact'], {
    clientOverrides,
    revalidate: ['getAlternateContact', 'putAlternateContact'],
  });

  const handleUpdate = async (data: PutAlternateContactRequest) => {
    if (await putAlternateContact(data)) {
      goto(routes.RESOURCES_ALTERNATE_CONTACT);
    }
  };

  return (
    <Layout
      documentTitle="Account: Alternate Contact"
      tabs={<NavTabs routes={routes} />}
      title={<PageTitle title="Account" />}
    >
      <Card>
        <CardContent>
          <AlternateContactForm
            formId="AlternateContact"
            onSubmit={handleUpdate}
            loading={isLoading}
            info={info?.AlternateContact}
            selectedContactType={contactType}
          />
        </CardContent>
        <CardActions>
          <ProgressButton color="primary" variant="contained" type="submit" form="AlternateContact" loading={isLoading}>
            Submit
          </ProgressButton>
        </CardActions>
      </Card>
    </Layout>
  );
};
