import { ReactElement, useContext } from 'react';

import {
  ComputeService,
  getSelectedInstance,
  GlobalStateContext,
  useApiEffect,
  useRoutes,
  useSnackbar,
} from '@localstack/services';
import { LocalStackInstance } from '@localstack/types';

import { LocalStackInstanceHeader } from '@localstack/ui';

import { AppRoute } from '~/config';

type InstanceHeaderWrapperProps = { children: unknown };

export const InstanceHeaderWrapper = ({ children }: InstanceHeaderWrapperProps): ReactElement => {
  const { goto } = useRoutes();
  const { showSnackbar } = useSnackbar();
  const selectedInstance = getSelectedInstance();

  const { instances, setInstances } = useContext(GlobalStateContext);

  const { deleteEphemeralInstance, isLoading: isDeletingInstance } = useApiEffect(
    ComputeService,
    ['deleteEphemeralInstance'],
    { revalidate: ['listEphemeralInstances'] },
  );

  const onRemove = async (instance: LocalStackInstance) => {
    if (instance.ephemeralInstance === undefined) {
      setInstances(instances.filter((i) => i.id !== instance.id));
      goto(AppRoute.INSTANCES);
      showSnackbar({
        message: 'Successfully removed instance',
        severity: 'success',
      });
    } else {
      const res = await deleteEphemeralInstance(instance.id);
      if (res) {
        showSnackbar({
          message: 'Successfully removed instance',
          severity: 'success',
        });
        goto(AppRoute.INSTANCES_EPHEMERAL);
      }
    }
  };
  return (
    <>
      <LocalStackInstanceHeader
        instance={selectedInstance}
        isRemovingInstance={isDeletingInstance}
        onRemove={onRemove}
      />
      {children}
    </>
  );
};
