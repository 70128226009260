import { ReactElement } from 'react';
import { NavLink } from '@localstack/ui';
import { buildRoute } from '@localstack/services';

import { AppRoute } from '~/config';

type Props = {
  /** pod name */
  name: string;
};

const ENABLE_DIFF_VIEW = false;

export const DetailsNavTabs = ({ name }: Props): ReactElement => (
  <>
    <NavLink to={buildRoute(AppRoute.POD, { name })}>Browser</NavLink>
    {ENABLE_DIFF_VIEW && (
      // TODO: re-enable diff view with new metamodel
      <NavLink to={buildRoute(AppRoute.POD_DIFF, { name })} end>
        Diff View
      </NavLink>
    )}
  </>
);
