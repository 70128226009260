import { ReactElement } from 'react';
import { IAMInlineRolePolicy, PutIAMRolePolicyRequest } from '@localstack/types';
import { VALIDATION_RULES, getSchema } from '@localstack/services';

import { MagicForm } from '../../../magic/MagicForm';
import { ControlledCodeEditor } from '../../../../form';

export interface IAMRolePolicyUpsertFormProps {
  roleName?: string;
  rolePolicy?: IAMInlineRolePolicy | undefined;
  loading?: boolean;
  formId?: string;
  onUpsert: (data: PutIAMRolePolicyRequest) => unknown;
}

export const IAMRolePolicyUpsertForm = ({
  roleName,
  rolePolicy,
  loading,
  formId,
  onUpsert,
}: IAMRolePolicyUpsertFormProps): ReactElement => (
  <MagicForm
    data={{ ...rolePolicy, RoleName: roleName }}
    schema={getSchema('IAM')}
    loading={loading}
    entry="PutRolePolicyRequest"
    fieldConditions={{
      RoleName: false,
      PolicyName: !rolePolicy,
    }}
    externalFields={{
      '^PolicyDocument$': (control, name) => (
        <ControlledCodeEditor
          name={name}
          control={control}
          required
          rules={{ ...VALIDATION_RULES.validJson, ...VALIDATION_RULES.required }}
          language="json"
        />
      ),
    }}
    formId={formId}
    onSubmit={(data: PutIAMRolePolicyRequest) => onUpsert(data)}
  />
);
