import { ReactElement } from 'react';
import { useRoutes, useAwsGetter } from '@localstack/services';
import { useParams } from 'react-router-dom';
import { Card, CardContent, Box, Typography } from '@mui/material';
import { Breadcrumbs, RDSDatabaseDetails } from '@localstack/ui';

import { DEFAULT_RDS_ROUTES } from '@localstack/constants';

import { RDSProps } from './types';

export const RDSDatabase = ({ Layout, clientOverrides, routes = DEFAULT_RDS_ROUTES }: RDSProps): ReactElement => {
  const { goto } = useRoutes();
  const { databaseId } = useParams<'databaseId'>();

  const { data: databases } = useAwsGetter('RDS', 'describeGlobalClusters', [{ GlobalClusterIdentifier: databaseId }], {
    clientOverrides,
  });
  const database = databases?.GlobalClusters?.find((db) => db.GlobalClusterIdentifier === databaseId);

  return (
    <Layout
      documentTitle="RDS: Database Details"
      title={
        <Box>
          <Typography variant="h4">Database Details</Typography>
          <Breadcrumbs
            mappings={[
              ['RDS', () => goto(routes.RESOURCES_RDS_DATABASES)],
              ['Databases', () => goto(routes.RESOURCES_RDS_DATABASES)],
              [database?.GlobalClusterIdentifier, null],
            ]}
          />
        </Box>
      }
    >
      <Card>
        <CardContent>
          <RDSDatabaseDetails database={database} />
        </CardContent>
      </Card>
    </Layout>
  );
};
