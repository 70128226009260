import { ReactElement } from 'react';
import { GlueTable } from '@localstack/types';
import { getSchema } from '@localstack/services';

import { MagicDetails } from '../../../magic/MagicDetails';

export interface GlueTableDetailsProps {
  table?: Optional<Partial<GlueTable>>;
}

export const GlueTableDetails = ({ table }: GlueTableDetailsProps): ReactElement => (
  <MagicDetails data={table} schema={getSchema('Glue')} entry="Table" title="Table Details" />
);
