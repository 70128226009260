import { ReactElement } from 'react';
import classnames from 'classnames';
import { Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import createStyles from '@mui/styles/createStyles';
import { InvoiceStatus as InvoiceStatusType } from '@localstack/types';

const STATUS_TEXT_MAP = {
  [InvoiceStatusType.DRAFT]: 'draft',
  [InvoiceStatusType.OPEN]: 'open',
  [InvoiceStatusType.PAID]: 'paid',
  [InvoiceStatusType.UNCOLLECTIBLE]: 'failed',
  [InvoiceStatusType.VOID]: 'withdrawn',
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    dot: {
      display: 'inline-block',
      width: theme.spacing(1),
      height: theme.spacing(1),
      borderRadius: theme.spacing(0.5),
      marginRight: theme.spacing(1),
    },
    draft: {
      backgroundColor: theme.palette.info.light,
    },
    open: {
      backgroundColor: theme.palette.info.main,
    },
    paid: {
      backgroundColor: theme.palette.success.main,
    },
    uncollectibe: {
      backgroundColor: theme.palette.error.main,
    },
    void: {
      backgroundColor: theme.palette.warning.main,
    },
  }),
);

type Props = {
  status: InvoiceStatusType | string;
};

export const InvoiceStatus = ({ status }: Props): ReactElement => {
  const classes = useStyles();

  return (
    <span>
      <span
        className={classnames(classes.dot, {
          [classes.draft]: status === InvoiceStatusType.DRAFT,
          [classes.open]: status === InvoiceStatusType.OPEN,
          [classes.paid]: status === InvoiceStatusType.PAID,
          [classes.uncollectibe]: status === InvoiceStatusType.UNCOLLECTIBLE,
          [classes.void]: status === InvoiceStatusType.VOID,
        })}
      />
      {STATUS_TEXT_MAP[status as keyof typeof STATUS_TEXT_MAP]}
    </span>
  );
};

export default InvoiceStatus;
