import { ReactElement } from 'react';
import { NavLink } from '@localstack/ui';
import { buildRoute } from '@localstack/services';

import { DynamoDBRoutes } from '../../types';

interface Props {
  routes: DynamoDBRoutes;
}

export const NavTabs = ({ routes }: Props): ReactElement => (
  <>
    <NavLink to={buildRoute(routes.RESOURCES_DYNAMODB)} end>
      Tables
    </NavLink>
    <NavLink to={buildRoute(routes.RESOURCES_DYNAMODB_TABLE_PARTIQL)}>PartiQL</NavLink>
  </>
);
