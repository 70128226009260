import { DEFAULT_MWAA_ROUTES } from '@localstack/constants';
import { ReactElement } from 'react';
import { CreateEnvironmentInput, UpdateEnvironmentInput } from '@localstack/types';
import { Breadcrumbs, MWAAEnvironmentForm, ProgressButton } from '@localstack/ui';
import { useAwsEffect, useAwsGetter, useRoutes } from '@localstack/services';
import { Box, Card, CardActions, CardContent, Typography } from '@mui/material';

import { useParams } from 'react-router-dom';

import { MWAAProps } from './types';

export const MWAAEnvironmentUpsert = ({
  Layout,
  clientOverrides,
  routes = DEFAULT_MWAA_ROUTES,
}: MWAAProps): ReactElement => {
  const { goto } = useRoutes();
  const { id } = useParams<'id'>();
  const { data: environment } = useAwsGetter('MWAA', 'getEnvironment', [{ Name: id }], { clientOverrides });
  const { createEnvironment, updateEnvironment, isLoading } = useAwsEffect(
    'MWAA',
    ['createEnvironment', 'updateEnvironment'],
    {
      clientOverrides,
      revalidate: ['listEnvironments'],
    },
  );
  const handleCreate = async (data: CreateEnvironmentInput) => {
    if (await createEnvironment(data)) {
      goto(routes.RESOURCES_MWAA_ENVIRONMENTS);
    }
  };
  const handleUpdate = async (data: UpdateEnvironmentInput) => {
    if (await updateEnvironment(data)) {
      goto(routes.RESOURCES_MWAA_ENVIRONMENT, { id });
    }
  };
  return (
    <Layout
      title={
        <Box>
          <Typography variant="h4">Environment Details</Typography>
          <Breadcrumbs
            mappings={[
              ['MWAA', () => goto(routes.RESOURCES_MWAA_ENVIRONMENTS)],
              [id, () => goto(routes.RESOURCES_MWAA_ENVIRONMENT, { id })],
              [environment ? 'Update Environment' : 'Create Environment', null],
            ]}
          />
        </Box>
      }
      actions={
        <ProgressButton
          color="primary"
          variant="outlined"
          type="submit"
          form="MWAAEnvironmentUpsert"
          loading={isLoading}
        >
          Submit
        </ProgressButton>
      }
    >
      <Card>
        <CardContent>
          <MWAAEnvironmentForm
            formId="MWAAEnvironmentUpsert"
            onSubmit={environment ? handleUpdate : handleCreate}
            environment={environment}
            loading={isLoading}
            clientOverrides={clientOverrides}
          />
        </CardContent>
        <CardActions>
          <ProgressButton
            color="primary"
            variant="outlined"
            type="submit"
            form="MWAAEnvironmentUpsert"
            loading={isLoading}
          >
            Submit
          </ProgressButton>
        </CardActions>
      </Card>
    </Layout>
  );
};
