import { createTheme, Theme } from '@mui/material/styles';

import { STORAGE_KEY_THEME, ThemeType } from '../constants';

import { darkPalette, lightPalette } from './palette';
import createTypography from './typography';
import createOverrides from './overrides';

const theme = (themeType: string): Theme => createTheme(getThemeOptionsFromType(themeType));

export const userPrefersDarkSchemeMQ = window.matchMedia('(prefers-color-scheme: dark)');

export const getThemeOptionsFromType = (themeType: string): object => {
  const palette = themeType === ThemeType.DARK ? darkPalette : lightPalette;
  return {
    breakpoints: {
      values: {
        xs: 0,
        sm: 600,
        md: 960,
        lg: 1280,
        xl: 1920,
      },
    },
    palette,
    typography: createTypography(palette),
    components: createOverrides(palette),
    zIndex: {
      tooltip: 1300,
      appBar: 1200,
      drawer: 1100,
    },
  };
};

export const getTheme = (themeType?: ThemeType): Theme => {
  const userTheme = themeType || getStoredThemeType();
  const systemTheme = userPrefersDarkSchemeMQ.matches ? ThemeType.DARK : ThemeType.LIGHT;
  return theme(userTheme === ThemeType.SYSTEM ? systemTheme : userTheme);
};

export const getStoredThemeType = (): ThemeType =>
  (localStorage.getItem(STORAGE_KEY_THEME) as ThemeType) ?? ThemeType.SYSTEM;
