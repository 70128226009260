import { ReactElement } from 'react';
import { Controller, Control, ControllerProps } from 'react-hook-form';
import { FormHelperText } from '@mui/material';

import { CodeEditor, CodeEditorProps } from '../CodeEditor';

type Props = Omit<CodeEditorProps, 'value' | 'setValue'> &
  Omit<ControllerProps, 'control' | 'render'> & {
    control: Control<any>; // eslint-disable-line
    required?: boolean;
  };

export const ControlledCodeEditor = ({ control, ...rest }: Props): ReactElement => (
  <Controller
    {...rest}
    control={control}
    render={({ field: { ref: _ref, ...field }, fieldState }) => (
      <>
        <CodeEditor
          {...rest}
          {...field}
          setValue={(value) => field.onChange({ target: { value, name: field.name } })}
          value={field.value || ''}
        />
        {fieldState.isTouched && Boolean(fieldState.error) && (
          <FormHelperText error>{fieldState.error?.message}</FormHelperText>
        )}
      </>
    )}
  />
);

export default ControlledCodeEditor;
