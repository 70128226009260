import { useState, useCallback, ReactElement } from 'react';
import { useRoutes, useAwsGetter, useAwsEffect } from '@localstack/services';
import { Dropdown, ConfirmableButton, CloudFormationStackGrid, PageTitle } from '@localstack/ui';
import { DEFAULT_CLOUDFORMATION_ROUTES } from '@localstack/constants';
import { Button, Card } from '@mui/material';

import { CloudFormationProps } from './types';

export const CloudFormationStacks = ({
  Layout,
  clientOverrides,
  routes = DEFAULT_CLOUDFORMATION_ROUTES,
}: CloudFormationProps): ReactElement => {
  const { goto } = useRoutes();
  const [selected, setSelected] = useState<string[]>([]);

  const {
    data: stacks,
    isLoading: isStacksLoading,
    mutate,
  } = useAwsGetter('CloudFormation', 'listStacks', [], { clientOverrides });

  const { deleteStack, isLoading: isStackMutating } = useAwsEffect('CloudFormation', ['createStack', 'deleteStack'], {
    revalidate: ['listStacks', 'describeStacks'],
    clientOverrides,
  });

  const isLoading = isStacksLoading || isStackMutating;

  const handleDeleteObjects = useCallback(
    async () => Promise.all(selected.map((StackName) => deleteStack({ StackName }))),
    [selected],
  );

  return (
    <Layout
      title={<PageTitle title="CloudFormation Stacks" onMutate={mutate} />}
      actions={
        <>
          <Button onClick={() => goto(routes.RESOURCES_CLOUDFORMATION_STACK_CREATE)}>Create Stack</Button>
          <Dropdown label="Actions">
            <ConfirmableButton
              componentType="MenuItem"
              disabled={selected.length === 0 || isLoading}
              title={`Remove ${selected.length} stack(s)?`}
              onClick={handleDeleteObjects}
              text="Selected stacks will be permanently deleted"
            >
              Remove Selected
            </ConfirmableButton>
          </Dropdown>
        </>
      }
    >
      <Card>
        <CloudFormationStackGrid
          selectable
          loading={isLoading}
          stacks={
            stacks?.StackSummaries?.filter(Boolean).filter((stack) => stack.StackStatus !== 'DELETE_COMPLETE') ?? []
          }
          onViewStack={({ StackName }) => goto(routes.RESOURCES_CLOUDFORMATION_STACK_RESOURCES, { name: StackName })}
          onSelect={setSelected}
        />
      </Card>
    </Layout>
  );
};
