import { ReactElement } from 'react';

import { INSTANCE_ROUTES } from '@localstack/constants';

import { ResourceBrowserProps } from '../layout';

import * as instanceViews from '../instanceviews';

type InstancePathElementMapEntry = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  [componentName: string]: (props: ResourceBrowserProps<any>) => ReactElement;
};

type InstancePathElementMapType = {
  [key: string]: InstancePathElementMapEntry;
};
export const INSTANCE_PATH_ELEMENT_MAP: InstancePathElementMapType = {
  [INSTANCE_ROUTES.STACK_OVERVIEW]: { StackOverview: instanceViews.StackOverview },
  [INSTANCE_ROUTES.IAM_POLICY_STREAM]: { IAMStream: instanceViews.IAMStream },
  [INSTANCE_ROUTES.RESOURCES_OVERVIEW]: { ResourcesOverview: instanceViews.ResourcesOverview },
  [INSTANCE_ROUTES.STATUS]: { SystemStatus: instanceViews.SystemStatus },
  [INSTANCE_ROUTES.INSTANCE_LOGS]: { InstanceLogs: instanceViews.InstanceLogs },
  [INSTANCE_ROUTES.CHAOS_ENGINEERING]: { FISExperiments: instanceViews.FISExperiments },
};
