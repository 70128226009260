import { ReactElement, useMemo } from 'react';
import { AgwApiRoute } from '@localstack/types';
import { getSchema } from '@localstack/services';
import { Link } from '@mui/material';

import { MagicTable } from '../../../magic/MagicTable';

export type AgwApiRoutesTableProps = {
  selectable?: boolean;
  loading?: boolean;
  routes: AgwApiRoute[];
  onViewRoute?: (route: AgwApiRoute) => unknown;
  onSelect?: (ids: string[]) => void;
};

export const AgwApiRoutesTable = ({
  routes,
  selectable = true,
  loading,
  onViewRoute,
  onSelect,
}: AgwApiRoutesTableProps): ReactElement => {
  const sortedRoutes = useMemo(
    () =>
      [...routes].sort((left, right) => {
        const leftPath = left.RouteKey.split(' ')[1] || '';
        const rightPath = right.RouteKey.split(' ')[1] || '';

        if (rightPath.startsWith(leftPath)) return -1;
        if (leftPath.startsWith(rightPath)) return 1;
        return 0;
      }),
    [routes],
  );

  return (
    <MagicTable
      schema={getSchema('ApiGatewayV2')}
      entry="Route"
      idAttribute="RouteId"
      rows={sortedRoutes}
      selectable={selectable}
      onSelect={onSelect}
      order={['RouteId', 'RouteKey']}
      loading={loading}
      externalFields={{
        RouteId: (row) => (
          <Link onClick={() => onViewRoute && onViewRoute(row)} underline="hover">
            {row.RouteId}
          </Link>
        ),
      }}
    />
  );
};
