import { ReactElement, useMemo } from 'react';
import { Button, Card } from '@mui/material';
import { useParams } from 'react-router-dom';
import { SQSMessage } from '@localstack/types';
import { LoadingFragment, SQSMessagesTable, PageTitle } from '@localstack/ui';

import {
  refineSqsUrl,
  useAwsGetter,
  useRoutes,
  useEndpoint,
  getSQSBackdoorMessagesEndpoint,
  useLocalstackStatus,
  checkSQSInternalEndpointSupported,
} from '@localstack/services';

import { DEFAULT_SQS_ROUTES } from '@localstack/constants';

import { QueueNavTabs } from './components';
import { SQSProps } from './types';

export const SQSMessages = ({ Layout, clientOverrides, routes = DEFAULT_SQS_ROUTES }: SQSProps): ReactElement => {
  const { goto } = useRoutes();
  const { name } = useParams<'name'>();
  const { version } = useLocalstackStatus(clientOverrides);
  const internalEndpointSupported = checkSQSInternalEndpointSupported(version || '');

  const { endpoint } = useEndpoint('SQS');
  const sqsEndpoint = clientOverrides?.endpoint ?? endpoint;

  const { data: url } = useAwsGetter('SQS', 'getQueueUrl', [{ QueueName: name }], { clientOverrides });
  const {
    data: messages,
    isLoading,
    mutate,
  } = useAwsGetter(
    'SQS',
    'receiveMessage',
    [
      {
        QueueUrl: refineSqsUrl(url?.QueueUrl, sqsEndpoint),
        AttributeNames: ['All'],
        MaxNumberOfMessages: -1,
        VisibilityTimeout: 1,
        WaitTimeSeconds: 0,
      },
    ],
    {
      defaultValue: { Messages: [] },
      clientOverrides: {
        ...clientOverrides,
        endpoint: internalEndpointSupported ? getSQSBackdoorMessagesEndpoint(sqsEndpoint) : undefined,
      },
    },
  );

  const reversedMessages = useMemo(() => [...(messages?.Messages ?? [])].reverse() as SQSMessage[], [messages]);

  return (
    <>
      <Layout
        documentTitle="SQS: Queue Messages"
        tabs={<QueueNavTabs name={name as string} routes={routes} />}
        title={
          <PageTitle
            title="Queue Messages"
            onMutate={mutate}
            breadcrumbs={[
              ['SQS', () => goto(routes.RESOURCES_SQS_QUEUES)],
              [name, () => goto(routes.RESOURCES_SQS_QUEUE, { name })],
              ['Messages', null],
            ]}
          />
        }
        actions={
          <>
            <Button onClick={() => goto(routes.RESOURCES_SQS_QUEUE_MESSAGE_SEND, { name })}>Send Message</Button>
          </>
        }
      >
        <Card>
          <LoadingFragment loading={isLoading} variant="card" height={300}>
            <SQSMessagesTable
              entries={reversedMessages || []}
              onViewSQSMessage={(mex) => goto(routes.RESOURCES_SQS_QUEUE_MESSAGE, { name, messageId: mex.MessageId })}
            />
          </LoadingFragment>
        </Card>
      </Layout>
    </>
  );
};
