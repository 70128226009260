/**
 * Based on a timestamp calculates how much time the user has left on their trial
 *
 * @param timestamp - timestamp in seconds
 */
export const formatCountdownFromTimestamp = (timestamp: number | undefined): string => {
  if (!timestamp) {
    return 'an unknown amount of time';
  }

  const now = Math.floor(Date.now() / 1000);
  const diffInSeconds = timestamp - now;

  if (diffInSeconds < 3600) return 'less than an hour';

  if (diffInSeconds < 86400) {
    const hours = Math.floor(diffInSeconds / 3600);
    return `${hours} hour${hours !== 1 ? 's' : ''}`;
  }

  const days = Math.ceil(diffInSeconds / 86400);
  return `${days} day${days !== 1 ? 's' : ''}`;
};
