import { ReactElement } from 'react';
import { useRoutes, useAwsEffect } from '@localstack/services';
import { useParams } from 'react-router-dom';
import { Card, CardContent, CardActions, Box, Typography } from '@mui/material';
import { AttachIAMRolePolicyRequest } from '@localstack/types';
import { ProgressButton, IAMRoleAttachPolicyForm, Breadcrumbs } from '@localstack/ui';

import { DEFAULT_IAM_ROUTES } from '@localstack/constants/src';

import { IAMProps } from './types';

export const IAMRolePolicyAttach = ({
  Layout,
  clientOverrides,
  routes = DEFAULT_IAM_ROUTES,
}: IAMProps): ReactElement => {
  const { goto } = useRoutes();
  const { roleName } = useParams<'roleName'>();

  const { attachRolePolicy, isLoading } = useAwsEffect('IAM', ['attachRolePolicy'], {
    revalidate: ['listAttachedRolePolicies'],
    clientOverrides,
  });

  const handleUpdate = async (data: AttachIAMRolePolicyRequest) => {
    await attachRolePolicy(data);
    goto(routes.RESOURCES_IAM_ROLE_POLICIES, { roleName });
  };

  return (
    <Layout
      documentTitle="IAM: Attach Role Policy"
      title={
        <Box>
          <Typography variant="h4">Attach Role Policy</Typography>
          <Breadcrumbs
            mappings={[
              ['IAM', () => goto(routes.RESOURCES_IAM)],
              ['Roles', () => goto(routes.RESOURCES_IAM_ROLES)],
              [roleName, () => goto(routes.RESOURCES_IAM_ROLE, { roleName })],
              ['Policies', () => goto(routes.RESOURCES_IAM_ROLE_POLICIES, { roleName })],
              ['Attach Role Policy', () => null],
            ]}
          />
        </Box>
      }
      actions={
        <ProgressButton type="submit" form="update" variant="outlined" color="primary" loading={isLoading}>
          Submit
        </ProgressButton>
      }
    >
      <Card>
        <CardContent>
          <IAMRoleAttachPolicyForm
            roleName={roleName || ''}
            clientOverrides={clientOverrides}
            onUpdate={handleUpdate}
            loading={isLoading}
            formId="update"
          />
        </CardContent>
        <CardActions>
          <ProgressButton type="submit" form="update" variant="contained" color="primary" loading={isLoading}>
            Submit
          </ProgressButton>
        </CardActions>
      </Card>
    </Layout>
  );
};
