import { getSchema } from '@localstack/services';
import { Link } from '@mui/material';
import { RedshiftClustersMessage } from '@localstack/types';
import { ReactElement } from 'react';

import { MagicTable } from '../../magic/MagicTable';

export type RedshiftClustersTableProps = {
  clusters: RedshiftClustersMessage;
  onViewCluster: (id: string) => void;
  onSelect: (ids: string[]) => void;
  loading?: boolean;
};

export const RedshiftClustersTable = ({
  clusters,
  onViewCluster,
  onSelect,
  loading,
}: RedshiftClustersTableProps): ReactElement => (
  <MagicTable
    entry="Cluster"
    idAttribute="ClusterIdentifier"
    rows={clusters?.Clusters || []}
    schema={getSchema('Redshift')}
    externalFields={{
      ClusterIdentifier: ({ ClusterIdentifier }) => (
        <Link onClick={() => onViewCluster(ClusterIdentifier || '')} underline="hover">
          {ClusterIdentifier}
        </Link>
      ),
    }}
    loading={loading}
    onSelect={onSelect}
    selectable
    disableSelectionOnClick
    hideEmptyColumns
  />
);
