import { ReactElement } from 'react';
import { VerifySESV1EmailIdentityRequest } from '@localstack/types';
import { getSchema } from '@localstack/services';

import { MagicForm } from '../../magic/MagicForm';

export interface SESV1IdentityFormProps {
  loading?: boolean;
  formId?: string;
  onSubmit: (data: VerifySESV1EmailIdentityRequest) => unknown;
}

export const SESV1IdentityForm = ({ loading, formId, onSubmit }: SESV1IdentityFormProps): ReactElement => (
  <MagicForm
    schema={getSchema('SES')}
    loading={loading}
    entry="VerifyEmailIdentityRequest"
    formId={formId}
    onSubmit={(data: VerifySESV1EmailIdentityRequest) => onSubmit(data)}
  />
);
