import { ReactElement } from 'react';
import { Link } from '@mui/material';
import { OpenSearchListDomainNamesRequest } from '@localstack/types';
import { getSchema } from '@localstack/services';

import { MagicTable } from '../../magic/MagicTable';

export type OpenSearchDomainsTableProps = {
  selectable?: boolean;
  loading?: boolean;
  domains: OpenSearchListDomainNamesRequest[];
  onSelect?: (names: string[]) => void;
  onViewDomain?: (domain: OpenSearchListDomainNamesRequest) => unknown;
};

export const OpenSearchDomainsTable = ({
  domains,
  selectable = true,
  loading,
  onSelect,
  onViewDomain,
}: OpenSearchDomainsTableProps): ReactElement => (
  <MagicTable
    schema={getSchema('OpenSearch')}
    entry="DomainInfo"
    idAttribute="DomainName"
    rows={domains}
    selectable={selectable}
    onSelect={onSelect}
    order={['DomainName', 'EngineType']}
    loading={loading}
    externalFields={{
      DomainName: (row) => (
        <Link onClick={() => onViewDomain && onViewDomain(row)} underline="hover">
          {row.DomainName}
        </Link>
      ),
    }}
  />
);
