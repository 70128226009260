import { ReactElement } from 'react';
import { CreateGlueRegistryRequest, GlueRegistryListItem, UpdateGlueRegistryRequest } from '@localstack/types';
import { getSchema } from '@localstack/services';

import { MagicForm } from '../../../magic/MagicForm';

export type GlueRegistryFormProps = {
  registry?: Optional<Partial<GlueRegistryListItem>>;
  loading?: boolean;
  formId: string;
  onCreate: (data: CreateGlueRegistryRequest) => unknown;
  onUpdate: (data: UpdateGlueRegistryRequest) => unknown;
};

export const GlueRegistryForm = ({
  registry,
  loading,
  formId,
  onCreate,
  onUpdate,
}: GlueRegistryFormProps): ReactElement => (
  <MagicForm
    data={registry}
    schema={getSchema('Glue')}
    formId={formId}
    entry={registry ? 'UpdateRegistryInput' : 'CreateRegistryInput'}
    loading={loading}
    fieldConditions={{
      RegistryId: !registry,
    }}
    onSubmit={(data: CreateGlueRegistryRequest) => {
      if (!registry) return onCreate(data);
      return onUpdate({ RegistryId: { RegistryName: registry.RegistryName }, Description: data.Description ?? '' });
    }}
  />
);
