/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { AuthRequest } from '@localstack/types';
import type { Organization } from '@localstack/types';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class AuthService {
  /**
   * @param requestBody
   * @returns Organization
   * @throws ApiError
   */
  public static exchangeMarketplaceToken(requestBody?: { token: string }): CancelablePromise<Organization> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/v1/auth/marketplace',
      body: requestBody,
      mediaType: 'application/json',
    });
  }

  /**
   * @returns AuthRequest
   * @throws ApiError
   */
  public static createUserAuthRequest(): CancelablePromise<AuthRequest> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/v1/auth/request',
    });
  }

  /**
   * @param requestId
   * @param exchangeToken
   * @returns AuthRequest
   * @throws ApiError
   */
  public static getUserAuthRequest(requestId: any, exchangeToken: any): CancelablePromise<AuthRequest> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/v1/auth/request/{request_id}',
      path: {
        request_id: requestId,
      },
      query: {
        exchange_token: exchangeToken,
      },
    });
  }

  /**
   * @param requestId
   * @param requestBody
   * @returns AuthRequest
   * @throws ApiError
   */
  public static confirmUserAuthRequest(
    requestId: any,
    requestBody?: {
      code: string;
    },
  ): CancelablePromise<AuthRequest> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/v1/auth/request/{request_id}',
      path: {
        request_id: requestId,
      },
      body: requestBody,
      mediaType: 'application/json',
    });
  }

  /**
   * @param requestId
   * @param requestBody
   * @returns AuthRequest
   * @throws ApiError
   */
  public static exchangeUserAuthRequest(
    requestId: any,
    requestBody?: {
      exchange_token: string;
    },
  ): CancelablePromise<AuthRequest> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/v1/auth/request/{request_id}/exchange',
      path: {
        request_id: requestId,
      },
      body: requestBody,
      mediaType: 'application/json',
    });
  }
}
