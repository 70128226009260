import { ReactElement } from 'react';
import { AgwRestApiMethod } from '@localstack/types';
import { getSchema } from '@localstack/services';

import { MagicDetails } from '../../../magic/MagicDetails';

export interface AgwRestApiMethodDetailsProps {
  method?: Optional<Partial<AgwRestApiMethod>>;
}

export const AgwRestApiMethodDetails = ({ method }: AgwRestApiMethodDetailsProps): ReactElement => (
  <MagicDetails data={method} schema={getSchema('APIGateway')} entry="Method" title="Method Details" />
);
