import { getSchema } from '@localstack/services';
import { CreateReplicationInstanceMessage } from '@localstack/types';
import { ReactElement } from 'react';

import { MagicForm } from '../../magic/MagicForm';

export type DMSReplicationInstanceFormProps = {
  formId: string;
  onSubmit: (data: CreateReplicationInstanceMessage) => void;
  loading?: boolean;
};

export const DMSReplicationInstanceForm = ({
  formId,
  onSubmit,
  loading,
}: DMSReplicationInstanceFormProps): ReactElement => (
  <MagicForm
    entry="CreateReplicationInstanceMessage"
    schema={getSchema('DMS')}
    loading={loading}
    onSubmit={onSubmit}
    formId={formId}
  />
);
