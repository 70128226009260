import { useState, useEffect, ReactElement } from 'react';
import {
  AthenaDatabaseItemsTable,
  ProgressButton,
  S3PathPicker,
  ControlledSelect,
  ControlledCodeEditor,
} from '@localstack/ui';
import { Card, CardContent, Box, Button, CardActions, Alert, Grid, CircularProgress, MenuItem } from '@mui/material';
import { useAwsGetter, useAwsKeysMemo, useKeyPress } from '@localstack/services';

import { FormProvider, useForm } from 'react-hook-form';

import { DEFAULT_ATHENA_ROUTES } from '@localstack/constants';

import { NavTabs } from './components';
import { AthenaProps } from './types';
import { AthenaDatabasesLayoutLess } from './components/Databases';

const PAGE_SIZE = 50;

type QuerySubmissionFormProps = {
  Query: string;
  Database?: string;
  OutputLocation?: string;
};

export const AthenaSql = ({ Layout, clientOverrides, routes = DEFAULT_ATHENA_ROUTES }: AthenaProps): ReactElement => {
  const [isLoadingDatabases, setIsLoadingDatabases] = useState<boolean>(true);
  const [querySubmission, setQuerySubmission] = useState<QuerySubmissionFormProps>({ Query: 'SHOW DATABASES' });
  const [page, setPage] = useState(0);
  const [paginationKey, setPaginationKey] = useState<any>(null); // eslint-disable-line

  const methods = useForm<QuerySubmissionFormProps>({ mode: 'all', defaultValues: querySubmission });
  const { control, handleSubmit } = methods;

  const {
    data: query,
    isLoading,
    mutate: reloadQuery,
  } = useAwsGetter(
    'Athena',
    'startQueryExecution',
    [
      {
        QueryString: querySubmission.Query,
        QueryExecutionContext: { Database: querySubmission.Database },
        ResultConfiguration: { OutputLocation: querySubmission.OutputLocation },
      },
    ],
    { defaultValue: { QueryExecutionId: undefined }, clientOverrides },
  );
  const { data: status } = useAwsGetter(
    'Athena',
    'getQueryExecution',
    [{ QueryExecutionId: query?.QueryExecutionId }],
    { swrOverrides: { refreshInterval: 1000 }, clientOverrides },
  );

  const executionState = status?.QueryExecution?.Status?.State;

  const queryResultId = executionState === 'SUCCEEDED' ? query?.QueryExecutionId : undefined;

  const { data: result } = useAwsGetter(
    'Athena',
    'getQueryResults',
    [{ QueryExecutionId: queryResultId, NextToken: paginationKey }],
    { defaultValue: { ResultSet: {}, NextToken: undefined }, clientOverrides },
  );

  const queryKeys = useAwsKeysMemo(page, result?.NextToken);

  const onSubmit = (data: QuerySubmissionFormProps) => {
    setPage(0);
    setQuerySubmission({ ...data, Query: data.Query + ' '.repeat(Math.random() * 100) });
  };

  useEffect(() => {
    setPaginationKey(queryKeys[page]);
  }, [page]);

  useKeyPress(() => methods.handleSubmit(onSubmit)(), 'Enter', ['ctrlKey']);

  const { databases, isLoading: isLoadingDatabasesExt } = AthenaDatabasesLayoutLess({ clientOverrides });

  useEffect(() => setIsLoadingDatabases(isLoadingDatabasesExt), [isLoadingDatabasesExt]);

  return (
    <>
      <Layout title="Athena SQL" tabs={<NavTabs routes={routes} />}>
        <>
          {executionState === 'FAILED' && (
            <Box mb={2}>
              <Alert severity="error" action={<Button onClick={reloadQuery}>Retry</Button>}>
                Query Execution Failed
              </Alert>
            </Box>
          )}
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Card variant="outlined">
                <CardContent>
                  <FormProvider {...methods}>
                    <form id="statement" onSubmit={handleSubmit(onSubmit)}>
                      <Grid container spacing={3}>
                        <Grid item xs={12}>
                          <ControlledCodeEditor language="sql" control={control} name="Query" />
                        </Grid>
                        <Grid item md={6} sm={12} xs={12}>
                          {!isLoadingDatabases ? (
                            <ControlledSelect
                              variant="outlined"
                              control={control}
                              required={false}
                              fullWidth
                              label="Database"
                              name="Database"
                              options={(databases ?? []).map((db) => (
                                <MenuItem key={db.Name} value={db.Name}>
                                  {db.Name}
                                </MenuItem>
                              ))}
                            />
                          ) : (
                            <CircularProgress />
                          )}
                        </Grid>
                        <Grid item md={6} sm={12} xs={12}>
                          <S3PathPicker
                            control={control}
                            required={false}
                            fieldName="OutputLocation"
                            clientOverrides={clientOverrides}
                          />
                        </Grid>
                      </Grid>
                    </form>
                  </FormProvider>
                </CardContent>
                <CardActions>
                  <ProgressButton
                    type="submit"
                    form="statement"
                    color="primary"
                    variant="outlined"
                    loading={['QUEUED', 'RUNNING'].includes(executionState ?? '')}
                  >
                    Execute
                  </ProgressButton>
                </CardActions>
              </Card>
            </Grid>
            <Grid item xs={12}>
              <Card>
                <AthenaDatabaseItemsTable
                  page={page}
                  pageSize={PAGE_SIZE}
                  results={result}
                  loading={isLoading || executionState === 'RUNNING'}
                  hasMore={!!result?.NextToken}
                  onPageChange={setPage}
                />
              </Card>
            </Grid>
          </Grid>
        </>
      </Layout>
    </>
  );
};
