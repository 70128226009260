import { ExternalLink } from '@localstack/constants';
import { Link } from '@mui/material';

import { ReactElement } from 'react';

export const TOS_REQUIRED_ERROR = 'Please read and accept our ToS, privacy policy and data processing addendum';

export const TosAgreementLine = (): ReactElement => (
  <>
    I agree to the{' '}
    <Link target="_blank" href={ExternalLink.LEGAL_TOS} underline="hover">
      Terms and Conditions
    </Link>
    ,{' '}
    <Link target="_blank" href={ExternalLink.LEGAL_DPA} underline="hover">
      Data Processing Addendum
    </Link>{' '}
    and{' '}
    <Link target="_blank" href={ExternalLink.LEGAL_PRIVACY_POLICY} underline="hover">
      Privacy Policy
    </Link>
    .
  </>
);
