import { ReactElement } from 'react';
import { useParams } from 'react-router-dom';
import { Card, CardContent, CardActions, Box, Typography } from '@mui/material';
import { CreateElastiCacheClusterParams, ModifyElastiCacheClusterParams } from '@localstack/types';
import { useRoutes, useAwsEffect, useAwsGetter } from '@localstack/services';
import { ProgressButton, ElastiCacheClusterForm, Breadcrumbs } from '@localstack/ui';

import { DEFAULT_ELASTICACHE_ROUTES } from '@localstack/constants/src';

import { ElastiCacheProps } from './types';

export const ElastiCacheClusterUpsert = ({
  Layout,
  clientOverrides,
  routes = DEFAULT_ELASTICACHE_ROUTES,
}: ElastiCacheProps): ReactElement => {
  const { goto } = useRoutes();
  const { clusterId } = useParams<'clusterId'>();

  const { createCacheCluster, modifyCacheCluster, isLoading } = useAwsEffect(
    'ElastiCache',
    ['createCacheCluster', 'modifyCacheCluster'],
    { revalidate: ['describeCacheClusters'], clientOverrides },
  );

  const { data: clusters } = useAwsGetter('ElastiCache', 'describeCacheClusters', [{ CacheClusterId: clusterId }], {
    clientOverrides,
  });
  const cluster = clusters?.CacheClusters?.find((c) => c.CacheClusterId === clusterId);

  const handleCreate = async (data: CreateElastiCacheClusterParams) => {
    await createCacheCluster(data);
    goto(routes.RESOURCES_ELASTICACHE_CLUSTERS);
  };

  const handleUpdate = async (data: ModifyElastiCacheClusterParams) => {
    await modifyCacheCluster(data);
    goto(routes.RESOURCES_ELASTICACHE_CLUSTER, { clusterId });
  };

  return (
    <Layout
      title={
        <Box>
          <Typography variant="h4">Cluster Details</Typography>
          <Breadcrumbs
            mappings={[
              ['ElastiCache', () => goto(routes.RESOURCES_ELASTICACHE)],
              ['Clusters', () => goto(routes.RESOURCES_ELASTICACHE_CLUSTERS)],
              [clusterId, () => goto(routes.RESOURCES_ELASTICACHE_CLUSTER, { clusterId })],
              [cluster ? 'Update' : 'Create Cluster', null],
            ]}
          />
        </Box>
      }
      actions={
        <ProgressButton
          type="submit"
          form={clusterId ? 'update' : 'create'}
          variant="outlined"
          color="primary"
          loading={isLoading}
        >
          Submit
        </ProgressButton>
      }
    >
      <Card>
        <CardContent>
          <ElastiCacheClusterForm
            cluster={cluster}
            clientOverrides={clientOverrides}
            onCreate={handleCreate}
            onUpdate={handleUpdate}
            loading={isLoading}
            formId={clusterId ? 'update' : 'create'}
          />
        </CardContent>
        <CardActions>
          <ProgressButton
            type="submit"
            form={clusterId ? 'update' : 'create'}
            variant="contained"
            color="primary"
            loading={isLoading}
          >
            Submit
          </ProgressButton>
        </CardActions>
      </Card>
    </Layout>
  );
};
