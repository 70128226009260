import { getSchema } from '@localstack/services';
import { GetRepositoryOutput } from '@localstack/types';
import { ReactElement } from 'react';

import { MagicDetails } from '../../magic/MagicDetails';

export type CodeCommitRepositoryDetailProps = {
  repository?: Optional<Partial<GetRepositoryOutput>>;
};

export const CodeCommitRepositoryDetail = ({ repository }: CodeCommitRepositoryDetailProps): ReactElement => (
  <MagicDetails
    data={repository?.repositoryMetadata}
    schema={getSchema('CodeCommit')}
    entry="RepositoryMetadata"
    title="CodeCommit Repository Details"
  />
);
