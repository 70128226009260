import md5 from 'md5';
import { ReactElement, useMemo } from 'react';
import { AthenaQueryResults } from '@localstack/types';

import { MagicTable } from '../../../magic/MagicTable';

const DEFAULT_PAGE_SIZE = 50;

export interface AthenaDatabaseItemsTableProps {
  results: Optional<AthenaQueryResults>;
  loading?: boolean;
  hasMore?: boolean;
  page?: number;
  pageSize?: number;
  onPageChange?: (page: number) => unknown;
}

export const AthenaDatabaseItemsTable = ({
  results,
  loading,
  hasMore,
  page,
  pageSize = DEFAULT_PAGE_SIZE,
  onPageChange,
}: AthenaDatabaseItemsTableProps): ReactElement => {
  // build simple table schema based on results
  const schema = useMemo(
    () => ({
      shapes: {
        AbstractItem: {
          type: 'structure',
          members: Object.fromEntries(
            (results?.ResultSet?.ResultSetMetadata?.ColumnInfo ?? []).map((ci) => [ci.Name, { type: 'string' }]),
          ),
        },
      },
    }),
    [results],
  );

  const tablePageSize = pageSize || DEFAULT_PAGE_SIZE;
  const columnNames = results?.ResultSet?.ResultSetMetadata?.ColumnInfo?.map((ci) => ci.Name) ?? [];

  // first row contains column names
  const rowsWithoutHeaders = (results?.ResultSet?.Rows ?? []).slice(1);
  const rowsWithColumns = rowsWithoutHeaders.map((row) =>
    (row.Data ?? []).reduce((memo, data, idx) => ({ ...memo, [columnNames[idx] as string]: data.VarCharValue }), {}),
  );

  // we need to bring unique row ids
  const hashedRows = rowsWithColumns.map((row) => ({ $rowHash: md5(JSON.stringify(row)), ...row }));

  // first row contains column names
  const currentItemsCount = Math.max((results?.ResultSet?.Rows?.length ?? 0) - 1, 0);

  return (
    <MagicTable
      pagination
      // For things like PartiQL we can not limit results,
      // that means these apis may be loading huge data sets exceeding our `pageSize`,
      // in this case we want to let DataGrid handle pagination
      paginationMode={hasMore ? 'server' : 'client'}
      page={page}
      pageSize={tablePageSize}
      rowCount={hasMore ? ((page ?? 0) + 1) * tablePageSize + 1 : currentItemsCount}
      onPageChange={onPageChange}
      rowsPerPageOptions={[tablePageSize]}
      schema={schema}
      loading={loading}
      entry="AbstractItem"
      rows={hashedRows}
      idAttribute="$rowHash"
      formatNames={false}
      selectable={false}
    />
  );
};
