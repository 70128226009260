import { ReactElement } from 'react';
import { useLocation } from 'react-router-dom';
import { styled } from '@mui/material/styles';

import { Link } from '@mui/material';

import { ExternalLink } from '~/constants';

import { AppRoute } from '~/config';

import { MainNavbar } from './MainNavbar';
import { SettingsNavbar } from './SettingsNavbar';
import { AdminNavbar } from './AdminNavbar';

const NavBarContainer = styled('div')(() => ({
  width: '300px',
  padding: '0.8rem',
  minHeight: '100vh',
  display: 'flex',
  flexDirection: 'column',
}));

const Footer = styled('div')(({ theme }) => ({
  display: 'inline-block',
  color: theme.palette.text.disabled,
  marginTop: '0.5em',
  [theme.breakpoints.down('md')]: {
    '.collapsed &': {
      display: 'none',
    },
  },
}));

const StyledLink = styled(Link)(({ theme }) => ({
  fontSize: '0.8rem',
  padding: '0 0.1em',
  color: theme.palette.text.secondary,
  textDecoration: 'none',
  '&:hover': {
    textDecoration: 'underline',
  },
}));

const Separator = styled('span')(() => ({
  padding: '0 0.3em',
}));

enum NavState {
  COMMON = 'common',
  SETTINGS = 'settings',
  ADMIN = 'admin',
}

/** Component that decides which version of the navbar to show to the user */
export const Navbar = (): ReactElement => {
  const location = useLocation();

  const getNavState = () => {
    if (location.pathname.startsWith('/settings')) return NavState.SETTINGS;
    if (location.pathname.startsWith('/admin')) return NavState.ADMIN;
    return NavState.COMMON;
  };

  const navState = getNavState();

  return (
    <NavBarContainer data-name="navbar">
      {navState === NavState.COMMON && <MainNavbar />}
      {navState === NavState.SETTINGS && <SettingsNavbar />}
      {navState === NavState.ADMIN && <AdminNavbar />}
      <Footer>
        <StyledLink href={ExternalLink.DOCS_BASE} target="_blank" rel="noopener noreferrer">
          Documentation
        </StyledLink>
        <Separator>&middot;</Separator>
        <StyledLink href={AppRoute.DESKTOP_DOWNLOAD}>Download Desktop</StyledLink>
        <br />
        <StyledLink href={ExternalLink.LEGAL_TOS} target="_blank" rel="noopener noreferrer">
          Terms of Service
        </StyledLink>
        <Separator>&middot;</Separator>
        <StyledLink href={ExternalLink.LEGAL_PRIVACY_POLICY} target="_blank" rel="noopener noreferrer">
          Privacy Policy
        </StyledLink>
        <Separator>&middot;</Separator>
        <StyledLink href={ExternalLink.LEGAL_DPA} target="_blank" rel="noopener noreferrer">
          DPA
        </StyledLink>
      </Footer>
    </NavBarContainer>
  );
};
