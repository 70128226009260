import { ReactElement } from 'react';
import classnames from 'classnames';
import { Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import createStyles from '@mui/styles/createStyles';
import { ApiKey } from '@localstack/types';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    dot: {
      display: 'inline-block',
      width: theme.spacing(1),
      height: theme.spacing(1),
      borderRadius: theme.spacing(0.5),
      marginRight: theme.spacing(1),
    },
    active: {
      backgroundColor: theme.palette.success.main,
    },
    deleted: {
      backgroundColor: theme.palette.error.main,
    },
    rotated: {
      backgroundColor: theme.palette.info.main,
    },
  }),
);

export type KeyStateProps = {
  apiKey: ApiKey;
};

export const KeyState = ({ apiKey }: KeyStateProps): ReactElement => {
  const classes = useStyles();

  let state: 'rotated' | 'deleted' | 'active';

  if (apiKey.rotated) {
    state = 'rotated';
  } else if (apiKey.deleted) {
    state = 'deleted';
  } else {
    state = 'active';
  }

  return (
    <span>
      <span
        className={classnames(classes.dot, {
          [classes.active]: state === 'active',
          [classes.deleted]: state === 'deleted',
          [classes.rotated]: state === 'rotated',
        })}
      />
      {state}
    </span>
  );
};

export default KeyState;
