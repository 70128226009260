import { ReactElement } from 'react';
import { SSMParameter, SSMPutRequest } from '@localstack/types';
import { AwsClientOverrides, getSchema } from '@localstack/services';
import { DEFAULT_KMS_ROUTES } from '@localstack/constants';

import { RelatedResourcePicker } from '../../../form/RelatedResourcePicker';
import { MagicForm } from '../../magic/MagicForm';

export interface SystemParameterFormProps {
  parameter?: Optional<Partial<SSMParameter>>;
  loading?: boolean;
  formId?: string;
  clientOverrides?: AwsClientOverrides;
  onCreate: (data: SSMPutRequest) => unknown;
}

export const SystemParameterForm = ({
  parameter,
  loading,
  formId,
  clientOverrides,
  onCreate,
}: SystemParameterFormProps): ReactElement => (
  <MagicForm
    data={parameter}
    schema={getSchema('SSM')}
    loading={loading}
    entry="PutParameterRequest"
    formId={formId}
    externalFields={{
      '^KeyId$': (control, fieldName, required) => (
        <RelatedResourcePicker
          control={control}
          client="KMS"
          method="listKeys"
          arrayKeyName="Keys"
          property="KeyId"
          fieldName={fieldName}
          entityName="KMS Key"
          creationRoute={DEFAULT_KMS_ROUTES.RESOURCES_KMS_KEY_CREATE}
          required={required}
          clientOverrides={clientOverrides}
        />
      ),
    }}
    onSubmit={(data: SSMPutRequest) => onCreate(data)}
  />
);
