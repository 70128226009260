import { ReactElement } from 'react';
import { useRoutes, useAwsEffect } from '@localstack/services';
import { Card, CardContent, CardActions, Box, Typography } from '@mui/material';
import { ProgressButton, S3FolderCreateForm, Breadcrumbs } from '@localstack/ui';
import { useParams } from 'react-router-dom';
import { S3CreateFolderArgs } from '@localstack/types';
import { DEFAULT_S3_ROUTES } from '@localstack/constants';

import { S3Props } from './types';

export const S3FolderCreate = ({ Layout, clientOverrides, routes = DEFAULT_S3_ROUTES }: S3Props): ReactElement => {
  const { goto } = useRoutes();
  const { bucket } = useParams<'bucket'>();
  const { prefix } = useParams<'prefix'>();

  const { upload, isLoading } = useAwsEffect('S3', ['upload'], {
    revalidate: ['listObjectsV2'],
    clientOverrides: { s3ForcePathStyle: true, ...clientOverrides },
  });

  const handleNavigation = () =>
    prefix ? goto(routes.RESOURCES_S3_BUCKET_PREFIX, { bucket, prefix }) : goto(routes.RESOURCES_S3_BUCKET, { bucket });

  const handleCreate = async (data: S3CreateFolderArgs) => {
    const folderName = data.Name.endsWith('/') ? data.Name : `${data.Name}/`;
    await upload({ Bucket: bucket as string, Key: prefix ? `${prefix}/${folderName}` : folderName, Body: '' });
    goto(routes.RESOURCES_S3, { bucket });
    handleNavigation();
  };

  return (
    <Layout
      documentTitle="Folder Details"
      title={
        <Box>
          <Typography variant="h4">Folder Details</Typography>
          <Breadcrumbs
            mappings={[
              ['Buckets', () => goto(routes.RESOURCES_S3)],
              ['Create Folder', null],
            ]}
          />
        </Box>
      }
      actions={
        <ProgressButton type="submit" form="CreateFolder" variant="outlined" color="primary" loading={isLoading}>
          Submit
        </ProgressButton>
      }
    >
      <Card>
        <CardContent>
          <S3FolderCreateForm onCreate={handleCreate} loading={isLoading} formId="CreateFolder" />
        </CardContent>
        <CardActions>
          <ProgressButton type="submit" form="CreateFolder" variant="contained" color="primary" loading={isLoading}>
            Submit
          </ProgressButton>
        </CardActions>
      </Card>
    </Layout>
  );
};
