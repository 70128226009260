import { ReactElement } from 'react';
import { Card, CardContent, CardActions, Box, Typography } from '@mui/material';
import { useAwsEffect, useRoutes } from '@localstack/services';
import { MQCreateBrokerRequest } from '@localstack/types';
import { Breadcrumbs, ProgressButton, MQBrokerForm } from '@localstack/ui';

import { MQProps } from './types';

export const MQBrokerCreate = ({ Layout, clientOverrides, routes }: MQProps): ReactElement => {
  const { goto } = useRoutes();

  const { createBroker, isLoading } = useAwsEffect('MQ', ['createBroker'], {
    revalidate: ['listBrokers'],
    clientOverrides,
  });

  const handleCreate = async (data: MQCreateBrokerRequest) => {
    if (await createBroker(data)) {
      goto(routes.RESOURCES_MQ_BROKERS);
    }
  };

  return (
    <Layout
      documentTitle="QLDB: Create Broker"
      title={
        <Box>
          <Typography variant="h4">Create Broker</Typography>
          <Breadcrumbs
            mappings={[
              ['MQ', () => goto(routes.RESOURCES_MQ_BROKERS)],
              ['Create Broker', null],
            ]}
          />
        </Box>
      }
      actions={
        <ProgressButton type="submit" form="CreateMQBroker" variant="outlined" color="primary" loading={isLoading}>
          Submit
        </ProgressButton>
      }
    >
      <Card>
        <CardContent>
          <MQBrokerForm
            onSubmit={handleCreate}
            clientOverrides={clientOverrides}
            formId="CreateMQBroker"
            loading={isLoading}
          />
        </CardContent>
        <CardActions>
          <ProgressButton type="submit" form="CreateMQBroker" variant="contained" color="primary" loading={isLoading}>
            Submit
          </ProgressButton>
        </CardActions>
      </Card>
    </Layout>
  );
};
