import { ReactElement } from 'react';
import { useRoutes, useAwsEffect } from '@localstack/services';
import { Card, CardContent, CardActions, Box, Typography } from '@mui/material';
import { CreateSESV2EmailIdentityRequest } from '@localstack/types';
import { ProgressButton, SESV2IdentityForm, Breadcrumbs } from '@localstack/ui';
import { DEFAULT_SES_ROUTES } from '@localstack/constants';

import { SESProps } from './types';
import { IndexNavTabs } from './components';

export const SESV2IdentityUpsert = ({
  Layout,
  clientOverrides,
  routes = DEFAULT_SES_ROUTES,
}: SESProps): ReactElement => {
  const { goto } = useRoutes();

  const { createEmailIdentity, isLoading } = useAwsEffect('SESV2', ['createEmailIdentity'], {
    revalidate: ['listEmailIdentities'],
    clientOverrides,
  });

  const handleCreate = async (data: CreateSESV2EmailIdentityRequest) => {
    await createEmailIdentity(data);
    goto(routes.RESOURCES_SES2_IDENTITIES);
  };

  return (
    <Layout
      documentTitle="Identity Details"
      title={
        <Box>
          <Typography variant="h4">Identity Details</Typography>
          <Breadcrumbs
            mappings={[
              ['SES', () => goto(routes.RESOURCES_SES1)],
              ['Identities', () => goto(routes.RESOURCES_SES2_IDENTITIES)],
              ['Create Identity', null],
            ]}
          />
        </Box>
      }
      tabs={<IndexNavTabs routes={routes} />}
      actions={
        <ProgressButton type="submit" form="update" variant="outlined" color="primary" loading={isLoading}>
          Submit
        </ProgressButton>
      }
    >
      <Card>
        <CardContent>
          <SESV2IdentityForm onSubmit={handleCreate} loading={isLoading} formId="update" />
        </CardContent>
        <CardActions>
          <ProgressButton type="submit" form="update" variant="contained" color="primary" loading={isLoading}>
            Submit
          </ProgressButton>
        </CardActions>
      </Card>
    </Layout>
  );
};
