import { ReactElement } from 'react';
import { useParams } from 'react-router-dom';
import { Card, CardContent, CardActions, Box, Typography } from '@mui/material';
import { CreateCloudFormationStackRequest, UpdateCloudFormationStackRequest } from '@localstack/types';
import { useRoutes, useAwsEffect, useAwsGetter, isStringValidJson } from '@localstack/services';
import { ProgressButton, CloudFormationForm, Breadcrumbs } from '@localstack/ui';
import { DEFAULT_CLOUDFORMATION_ROUTES } from '@localstack/constants';

import { CloudFormationProps } from './types';

export const CloudFormationStackUpsert = ({
  Layout,
  clientOverrides,
  routes = DEFAULT_CLOUDFORMATION_ROUTES,
}: CloudFormationProps): ReactElement => {
  const { goto } = useRoutes();
  const { name } = useParams<'name'>();

  const { data: stacks } = useAwsGetter('CloudFormation', 'describeStacks', [{ StackName: name }], { clientOverrides });
  const { data: template } = useAwsGetter('CloudFormation', 'getTemplate', [{ StackName: name }], { clientOverrides });

  const isJsonTemplate = template?.TemplateBody ? isStringValidJson(template?.TemplateBody) : undefined;
  const jsonifiedTemplate = isJsonTemplate
    ? JSON.stringify(JSON.parse(template?.TemplateBody ?? '{}'))
    : template?.TemplateBody;

  const stack = name ? stacks?.Stacks?.[0] : null;

  const { createStack, updateStack, isLoading } = useAwsEffect('CloudFormation', ['createStack', 'updateStack'], {
    revalidate: ['listStacks', 'describeStacks'],
    clientOverrides,
  });

  const handleCreate = async (data: CreateCloudFormationStackRequest) => {
    if (await createStack(data)) {
      goto(routes.RESOURCES_CLOUDFORMATION_STACKS);
    }
  };

  const handleUpdate = async (data: UpdateCloudFormationStackRequest) => {
    if (await updateStack(data)) {
      goto(routes.RESOURCES_CLOUDFORMATION_STACK_RESOURCES, { name });
    }
  };

  return (
    <Layout
      title={
        <Box>
          <Typography variant="h4">Stack Details</Typography>
          <Breadcrumbs
            mappings={[
              ['CloudFormation', () => goto(routes.RESOURCES_CLOUDFORMATION)],
              ['Create Stack', null],
            ]}
          />
        </Box>
      }
      actions={
        <ProgressButton type="submit" form="update" variant="outlined" color="primary" loading={isLoading}>
          Submit
        </ProgressButton>
      }
    >
      <Card>
        <CardContent>
          <CloudFormationForm
            stack={stack}
            template={jsonifiedTemplate}
            onCreate={handleCreate}
            onUpdate={handleUpdate}
            loading={isLoading}
            formId="update"
            clientOverrides={clientOverrides}
          />
        </CardContent>
        <CardActions>
          <ProgressButton type="submit" form="update" variant="contained" color="primary" loading={isLoading}>
            Submit
          </ProgressButton>
        </CardActions>
      </Card>
    </Layout>
  );
};
