import { ReactElement } from 'react';
import { NavLink } from '@localstack/ui';
import { buildRoute } from '@localstack/services';

import { IAMRoutes } from '../../types';

interface IAMRoleTabsProps {
  routes: IAMRoutes;
  roleName: string | undefined;
}

export const RoleTabs = ({ routes, roleName }: IAMRoleTabsProps): ReactElement => (
  <>
    <NavLink to={buildRoute(routes.RESOURCES_IAM_ROLE_POLICIES, { roleName })} end>
      Role Policies
    </NavLink>
  </>
);
