import { ReactElement } from 'react';
import { NavLink } from '@localstack/ui';
import { buildRoute } from '@localstack/services';

import { ApiGatewayRoutes } from '../../types';

interface Props {
  restApiId: string;
  resourceId: string;
  httpMethod: string;
  routes: ApiGatewayRoutes;
}

export const RestApiMethodNavTabs = ({ restApiId, resourceId, httpMethod, routes }: Props): ReactElement => (
  <>
    <NavLink to={buildRoute(routes.RESOURCES_AGW1_API_METHOD, { restApiId, resourceId, httpMethod })} end>
      Details
    </NavLink>
    <NavLink to={buildRoute(routes.RESOURCES_AGW1_API_INTEGRATION, { restApiId, resourceId, httpMethod })} end>
      Integration
    </NavLink>
    <NavLink to={buildRoute(routes.RESOURCES_AGW1_API_INTEGRATION_RESPONSES, { restApiId, resourceId, httpMethod })}>
      Integration Responses
    </NavLink>
    <NavLink to={buildRoute(routes.RESOURCES_AGW1_API_METHOD_RESPONSES, { restApiId, resourceId, httpMethod })}>
      Method Responses
    </NavLink>
  </>
);
