import { useState, useCallback, ReactElement, FormEvent } from 'react';
import { ExecuteDynamoDBStatementRequest } from '@localstack/types';

import { CodeEditor } from '../../../form';

export interface DynamoDBExpressionFormProps {
  formId: string;
  onSubmit: (request: ExecuteDynamoDBStatementRequest) => unknown;
}

export const DynamoDBExpressionForm = ({ formId, onSubmit }: DynamoDBExpressionFormProps): ReactElement => {
  const [statement, setStatement] = useState<Optional<string>>(null);

  const handleSubmit = useCallback(
    (e: FormEvent<HTMLFormElement>) => {
      e.preventDefault();
      onSubmit({ Statement: statement ?? '' });
    },
    [statement],
  );

  return (
    <form onSubmit={handleSubmit} id={formId}>
      <CodeEditor language="sql" setValue={setStatement} value={statement ?? ''} />
    </form>
  );
};
