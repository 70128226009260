import { ReactElement } from 'react';
import { GlueSchemaVersionItem, RegisterGlueSchemaVersionRequest } from '@localstack/types';
import { getSchema, VALIDATION_RULES } from '@localstack/services';
import { Button } from '@mui/material';

import { ControlledCodeEditor } from '../../../../form';
import { MagicForm } from '../../../magic/MagicForm';

export type GlueSchemaVersionFormProps = {
  schema?: Optional<Partial<GlueSchemaVersionItem>>;
  loading?: boolean;
  formId: string;
  onCreate: (data: RegisterGlueSchemaVersionRequest) => unknown;
  setDiffVersions: (versions: GlueSchemaVersionItem[]) => unknown;
};

export const GlueSchemaVersionForm = ({
  schema,
  loading,
  formId,
  onCreate,
  setDiffVersions,
}: GlueSchemaVersionFormProps): ReactElement => (
  <MagicForm
    data={schema}
    schema={getSchema('Glue')}
    formId={formId}
    entry="RegisterSchemaVersionInput"
    loading={loading}
    fieldConditions={{
      SchemaId: false,
    }}
    externalFields={{
      '^SchemaDefinition$': (control, name, _required, formData) => (
        <>
          <ControlledCodeEditor
            control={control}
            name={name}
            language="json"
            rules={{ ...VALIDATION_RULES.validJson, ...VALIDATION_RULES.required }}
          />
          <Button
            variant="outlined"
            color="primary"
            onClick={() =>
              setDiffVersions([
                { SchemaDefinition: schema?.SchemaDefinition, VersionNumber: schema?.VersionNumber },
                { SchemaDefinition: formData?.SchemaDefinition, VersionNumber: Number(schema?.VersionNumber) + 1 },
              ])
            }
            style={{ marginTop: '1rem' }}
          >
            Compare with Latest Version
          </Button>
        </>
      ),
    }}
    onSubmit={(data: RegisterGlueSchemaVersionRequest) => {
      if (!schema) return;
      const { SchemaArn } = schema;
      return onCreate({
        ...data,
        SchemaId: { SchemaArn },
      });
    }}
  />
);
