import { ReactElement } from 'react';
import { RDSDatabase } from '@localstack/types';
import { getSchema } from '@localstack/services';

import { MagicDetails } from '../../magic/MagicDetails';

export interface RDSDatabaseDetailsProps {
  database?: Optional<Partial<RDSDatabase>>;
}

export const RDSDatabaseDetails = ({ database }: RDSDatabaseDetailsProps): ReactElement => (
  <MagicDetails data={database} schema={getSchema('RDS')} entry="GlobalCluster" title="Database Details" />
);
