import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Button from '@mui/material/Button';
import classnames from 'classnames';

import { ReactElement, ReactNode, useMemo, useState } from 'react';

import { Box, Card, CardActions, CardContent, CardHeader, Grid, StepConnector } from '@mui/material';

import makeStyles from '@mui/styles/makeStyles';
import withStyles from '@mui/styles/withStyles';

const useColorStepIconStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: 'grey',
    zIndex: 1,
    color: '#fff',
    width: 50,
    height: 50,
    display: 'flex',
    borderRadius: '50%',
    justifyContent: 'center',
    alignItems: 'center',
  },
  active: {
    backgroundColor: theme.palette.primary.main,
  },
  completed: {
    backgroundColor: theme.palette.primary.main,
  },
}));

type CustomStepIconProps = {
  active: boolean;
  completed: boolean;
  icon: number;
};

const ColorConnector = withStyles((theme) => ({
  alternativeLabel: {
    top: 22,
  },
  active: {
    '& $line': {
      backgroundColor: theme.palette.primary.main,
    },
  },
  completed: {
    '& $line': {
      backgroundColor: theme.palette.primary.main,
    },
  },
  line: {
    height: 3,
    border: 0,
    backgroundColor: 'grey',
    borderRadius: 1,
  },
}))(StepConnector);

export type StepperItem = {
  content: ReactNode;
  actions?: ReactNode;
  icon: ReactNode;
  title: string;
  disableContinue?: boolean;
};

type CustomStepperProps = {
  cardHeaderTitle?: string;
  stepperItems: StepperItem[];
};

const StepperCard = ({ cardHeaderTitle, stepperItems }: CustomStepperProps): ReactElement => {
  const [activeStep, setActiveStep] = useState(0);

  const ColorStepIcon = ({ active, completed, icon }: CustomStepIconProps) =>
    useMemo(() => {
      const classes = useColorStepIconStyles();

      return (
        <div
          className={classnames(classes.root, {
            [classes.active]: active,
            [classes.completed]: completed,
          })}
        >
          {stepperItems.map((step) => step.icon)[icon - 1]}
        </div>
      );
    }, []);

  const stepperItem = stepperItems[activeStep];

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    if (activeStep > 0) {
      setActiveStep((prevActiveStep) => prevActiveStep - 1);
    }
  };

  return (
    <Card>
      <CardHeader title={cardHeaderTitle} />
      <Stepper alternativeLabel activeStep={activeStep} connector={<ColorConnector />}>
        {stepperItems.map((step: StepperItem) => (
          <Step key={step.title}>
            <StepLabel StepIconComponent={ColorStepIcon}>{step.title}</StepLabel>
          </Step>
        ))}
      </Stepper>
      <CardContent>{stepperItem?.content}</CardContent>
      <CardActions>
        <Grid container spacing={3}>
          <Grid item xs={12} key={activeStep}>
            <Box display="flex" justifyContent={activeStep === 0 ? 'flex-end' : 'space-between'}>
              {activeStep !== 0 && <Button onClick={handleBack}>Back</Button>}
              {activeStep !== stepperItems.length - 1 && (
                <Button
                  variant="contained"
                  disabled={stepperItem?.disableContinue}
                  color="primary"
                  onClick={handleNext}
                >
                  Continue
                </Button>
              )}
            </Box>
          </Grid>
        </Grid>
      </CardActions>
    </Card>
  );
};

export default StepperCard;
