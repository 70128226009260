import { EnrichedExtension, Extension, PlanFamily } from '@localstack/types';
import { Grid, Typography } from '@mui/material';
import { ReactElement, useState } from 'react';
import { ProgressButton } from '@localstack/ui';

import { ExtensionService, useApiEffect, useSnackbar } from '@localstack/services';

import { ContainedCustomerLayout } from '~/layouts';

import { ExtensionSubmissionCard } from './components/ExtensionSubmissionCard';
import { ExtensionCard } from './components/ExtensionCard';

export const ExtensionsSubmit = (): ReactElement => {
  const [newExtension, setNewExtension] = useState<Optional<EnrichedExtension>>(undefined);
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const { showSnackbar } = useSnackbar();

  const { submitExtension } = useApiEffect(ExtensionService, ['submitExtension'], {
    revalidate: ['listExtensions', 'listExtensionsMarketplace'],
  });

  const handleSubmit = async () => {
    setIsSubmitting(true);
    const { logoFile, ...extension } = newExtension ?? {};

    const response = await submitExtension(extension as Extension);

    if (response?.pre_signed_url) {
      await fetch(response.pre_signed_url, {
        method: 'PUT',
        headers: {
          'Content-Type': logoFile?.type ?? '',
        },
        body: logoFile,
      });
      showSnackbar({
        message: 'Extension successfully submitted',
        severity: 'success',
      });
    }

    setIsSubmitting(false);
  };

  return (
    <ContainedCustomerLayout title="Extension Submission" planFamily={PlanFamily.PRO_PLANS}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Typography variant="h5">
            Submit your extension to our marketplace with our simple application process below
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <ExtensionSubmissionCard onSubmit={(extension) => setNewExtension(extension)} />
        </Grid>
        {newExtension && (
          <>
            <Grid item xs={12}>
              <Typography variant="h5">
                The extension for the above input would show up as follows in the marketplace:
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <ExtensionCard extension={newExtension} size="small" onInstall={() => undefined} disableManage />
            </Grid>
            <Grid item xs={12}>
              <Typography variant="h5">
                Please confirm whether everything is correct by confirming your submission below
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <ProgressButton variant="contained" color="primary" onClick={handleSubmit} loading={isSubmitting}>
                Confirm Submission
              </ProgressButton>
            </Grid>
          </>
        )}
      </Grid>
    </ContainedCustomerLayout>
  );
};
