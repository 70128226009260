import { ReactElement } from 'react';
import { useRoutes, useAwsGetter } from '@localstack/services';
import { useParams } from 'react-router-dom';
import { Card, CardContent, Button, Box, Typography } from '@mui/material';
import { Breadcrumbs, AgwRestApiMethodDetails as Details } from '@localstack/ui';
import { DEFAULT_API_GATEWAY_ROUTES } from '@localstack/constants';

import { ApiGatewayProps } from './types';
import { RestApiMethodNavTabs } from './components';

export const AgwRestApiMethodDetails = ({
  Layout,
  clientOverrides,
  routes = DEFAULT_API_GATEWAY_ROUTES,
}: ApiGatewayProps): ReactElement => {
  const { goto } = useRoutes();
  const { restApiId, resourceId, httpMethod } = useParams<'restApiId' | 'resourceId' | 'httpMethod'>();

  const { data: api } = useAwsGetter('APIGateway', 'getRestApi', [{ restApiId }], { clientOverrides });

  const { data: resource } = useAwsGetter('APIGateway', 'getResource', [{ restApiId, resourceId }], {
    clientOverrides,
  });

  const { data: method } = useAwsGetter('APIGateway', 'getMethod', [{ restApiId, resourceId, httpMethod }], {
    clientOverrides,
  });

  return (
    <Layout
      documentTitle="Method Details"
      tabs={
        <RestApiMethodNavTabs
          restApiId={restApiId as string}
          resourceId={resourceId as string}
          httpMethod={httpMethod as string}
          routes={routes}
        />
      }
      title={
        <Box>
          <Typography variant="h4">Method Details</Typography>
          <Breadcrumbs
            mappings={[
              ['API Gateway', () => goto(routes.RESOURCES_AGW1)],
              [api?.name, () => goto(routes.RESOURCES_AGW1_API, { restApiId })],
              [resource?.path, () => goto(routes.RESOURCES_AGW1_API, { restApiId })],
              [method?.httpMethod, null],
            ]}
          />
        </Box>
      }
      actions={
        <Button onClick={() => goto(routes.RESOURCES_AGW1_API_METHOD_UPDATE, { restApiId, resourceId, httpMethod })}>
          Edit method
        </Button>
      }
    >
      <Card>
        <CardContent>
          <Details method={method} />
        </CardContent>
      </Card>
    </Layout>
  );
};
