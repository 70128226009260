import { useState, useCallback, ReactElement, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Button, Card } from '@mui/material';
import { processIdAttributes, useAwsEffect, useAwsGetter, useRoutes } from '@localstack/services';

import { Dropdown, ConfirmableButton, LoadingFragment, PageTitle, GlueTablesGrid } from '@localstack/ui';

import { DEFAULT_GLUE_ROUTES } from '@localstack/constants/src';
import { GlueTable } from '@localstack/types';

import { GlueProps } from './types';
import { MainNavTabs } from './components';

export const GlueTables = ({ Layout, clientOverrides, routes = DEFAULT_GLUE_ROUTES }: GlueProps): ReactElement => {
  const { goto } = useRoutes();
  const { database } = useParams<'database'>();
  const [selected, setSelected] = useState<string[]>([]);
  const [tables, setTables] = useState<GlueTable[]>([]);
  const { data: databases, isLoading, mutate } = useAwsGetter('Glue', 'getDatabases', [], { clientOverrides });
  const { deleteTable } = useAwsEffect('Glue', ['deleteTable'], {
    revalidate: ['getTables', 'getDatabases'],
    clientOverrides,
  });
  const { getTables } = useAwsEffect('Glue', ['getTables'], { clientOverrides });

  const handleDeleteObjects = useCallback(
    async () =>
      Promise.all(
        selected.map((selectedItem) => {
          const [Name, DatabaseName] = processIdAttributes(selectedItem);
          return deleteTable({ DatabaseName: DatabaseName as string, Name: Name as string });
        }),
      ),
    [selected],
  );

  useEffect(() => {
    if (databases?.DatabaseList) {
      (async () => {
        const databaseList = database
          ? databases?.DatabaseList.filter((db) => db.Name === database)
          : databases?.DatabaseList;
        const tablesData = await Promise.all(
          databaseList?.map(async (db) => (await getTables({ DatabaseName: db.Name }))?.TableList ?? []),
        );
        setTables(tablesData.flat());
      })();
    }
  }, [databases?.DatabaseList, database]);

  return (
    <>
      <Layout
        documentTitle="Glue Tables"
        title={
          <PageTitle
            title="Glue Tables"
            onMutate={mutate}
            breadcrumbs={[
              ['Glue', () => goto(routes.RESOURCES_GLUE_DATABASES)],
              ['Tables', () => goto(routes.RESOURCES_GLUE_TABLES)],
              [database, () => goto(routes.RESOURCES_GLUE_DATABASE_TABLES, { database })],
            ]}
          />
        }
        tabs={<MainNavTabs routes={routes} />}
        actions={
          <>
            <Button
              onClick={() =>
                goto(database ? routes.RESOURCES_GLUE_DATABASE_TABLE_CREATE : routes.RESOURCES_GLUE_TABLE_CREATE, {
                  database,
                })
              }
            >
              Create Table
            </Button>
            <Dropdown label="Actions">
              <ConfirmableButton
                componentType="MenuItem"
                disabled={selected.length === 0 || isLoading}
                title={`Remove ${selected.length} table(s)?`}
                onClick={handleDeleteObjects}
                text="Selected Table(s) will be permanently deleted"
              >
                Remove Selected
              </ConfirmableButton>
            </Dropdown>
          </>
        }
      >
        <Card>
          <LoadingFragment loading={isLoading} variant="card" height={300}>
            <GlueTablesGrid
              selectable
              entries={tables || []}
              onViewTable={(table) =>
                goto(routes.RESOURCES_GLUE_TABLE, {
                  database: table.DatabaseName,
                  table: table.Name,
                })
              }
              onSelect={setSelected}
            />
          </LoadingFragment>
        </Card>
      </Layout>
    </>
  );
};
