import { ReactElement } from 'react';
import ReactDiffViewer, { DiffMethod } from 'react-diff-viewer';
import { Theme, useTheme } from '@mui/material/styles';

import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles((theme: Theme) => ({
  diffViewerContainer: {
    border: `1px solid ${theme.palette.divider}`,
    '& table tr': {
      fontSize: theme.typography.body2.fontSize,
    },
    '& table tr:first-child': {
      fontSize: theme.typography.subtitle2.fontSize,
    },
  },
}));

export interface DiffViewerProp {
  previousObj: string;
  latestObj: string;
  leftTitle: string;
  rightTitle: string;
}

export const DiffViewer = ({ previousObj, latestObj, leftTitle, rightTitle }: DiffViewerProp): ReactElement => {
  const classes = useStyles();
  const theme = useTheme();

  return (
    <div className={classes.diffViewerContainer}>
      <ReactDiffViewer
        leftTitle={leftTitle}
        rightTitle={rightTitle}
        oldValue={JSON.stringify(JSON.parse(previousObj ?? '{}'), null, 2)}
        newValue={JSON.stringify(JSON.parse(latestObj ?? '{}'), null, 2)}
        compareMethod={DiffMethod.WORDS_WITH_SPACE}
        useDarkTheme={theme.palette.mode === 'dark'}
        splitView
      />
    </div>
  );
};
