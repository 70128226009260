import { ReactElement } from 'react';

import { AuthenticatedLayout, AuthenticatedLayoutProps } from './AuthenticatedLayout';

import { Navbar } from './components';

import { AdminHeader, AdminHeaderProps } from './Headers';

import { LayoutContainer } from './LayoutContainer';

type AdminLayoutProps = AuthenticatedLayoutProps & AdminHeaderProps;

export const AdminLayout = ({
  children,
  title,
  planFamily,
  actions,
  maxWidth,
  fullScreen,
  tabs,
  ...rest
}: AdminLayoutProps): ReactElement => (
  <AuthenticatedLayout {...rest} navigation={<Navbar />}>
    <AdminHeader
      actions={actions}
      title={title}
      planFamily={planFamily}
      maxWidth={maxWidth}
      fullScreen={fullScreen}
      tabs={tabs}
    />
    <LayoutContainer fullScreen={fullScreen} maxWidth={maxWidth}>
      {children}
    </LayoutContainer>
  </AuthenticatedLayout>
);
