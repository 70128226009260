import { ReactElement } from 'react';
import { NavLink } from '@localstack/ui';
import { buildRoute } from '@localstack/services';

import { SNSRoutes } from '../../types';

interface Props {
  topicArn: string;
  routes: SNSRoutes;
}

export const TopicNavTabs = ({ topicArn, routes }: Props): ReactElement => (
  <>
    <NavLink to={buildRoute(routes.RESOURCES_SNS_TOPIC, { topicArn })} end>
      Details
    </NavLink>
    <NavLink to={buildRoute(routes.RESOURCES_SNS_TOPIC_SUBSCRIPTIONS, { topicArn })}>Subscriptions</NavLink>
  </>
);
