import { useMemo, ReactElement } from 'react';
import { Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import createStyles from '@mui/styles/createStyles';
import { useNavigate } from 'react-router-dom';
import { Wizard, WizardStep, WizardStepType } from '@localstack/ui';
import { Box, IconButton } from '@mui/material';

import {
  Settings as SettingsIcon,
  Flight as FallbackIcon,
  Business as OrganizationDetailsIcon,
  Subscriptions as SubscriptionsIcon,
  ViewModule as ContainerIcons,
  DoneAll as TipsIcon,
  RemoveCircle as RemoveIcon,
} from '@mui/icons-material';

import { Organization, OrganizedApiKeys, Health, WidgetOptions, MyLicenseAssignmentInfo } from '@localstack/types';

import { useApiGetter, SubscriptionService, useAwsGetter, OrganizationsService } from '@localstack/services';

import { ExternalLink } from '@localstack/constants';

import { AppRoute } from '~/config';
import { useAuthProvider } from '~/hooks/useAuthProvider';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    card: {
      height: '100%',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
    },
    title: {
      color: theme.palette.primary.contrastText,
    },
    removeBtn: {
      padding: theme.spacing(1),
      position: 'absolute',
      right: theme.spacing(1),
    },
  }),
);

export const OnboardingWidgetOptions: WidgetOptions = {
  i: 'onboarding',
  image: '/widgets/onBoarding.png',
  style: 'mediaLong',
  xl: {
    w: 5,
    h: 7,
  },
  lg: {
    w: 5,
    h: 7,
  },
  md: {
    w: 5,
    h: 7,
  },
  sm: {
    w: 5,
    h: 7,
  },
  xs: {
    w: 12,
    h: 7,
  },
};

const calculateStepStates = (
  org: Optional<Organization>,
  keys: OrganizedApiKeys,
  license: Optional<MyLicenseAssignmentInfo>,
  health: Optional<Health>,
) => ({
  hasSignedUp: true,
  hasOrgDetails: !!org?.address, // billing address
  hasLicense:
    !!(Object.values(keys.org ?? []).flat().length + Object.values(keys.shared ?? []).flat().length) ||
    license?.has_license_assigned,
  hasLocalStack: !!Object.keys(health || {}).length,
});

const calculateProgress = (steps: ReturnType<typeof calculateStepStates>) => {
  const allSteps = Object.keys(steps).length;
  const completedSteps = Object.values(steps).filter(Boolean).length;

  return Math.floor((completedSteps / allSteps) * 100.0);
};

type Props = {
  className?: string;
  style?: Record<string, string>;
  children?: JSX.Element;
  removeCallback: () => void;
  editing: boolean;
};

export const OnboardingWidget = ({
  style,
  className,
  children: _children,
  removeCallback,
  editing,
  ...rest
}: Props): ReactElement => {
  const classes = useStyles();

  const navigate = useNavigate();
  const { userInfo } = useAuthProvider();

  const { data: assignmentInfo, isLoading: isAssignmentInfoLoading } = useApiGetter(
    OrganizationsService,
    'getUserLicenseAssignment',
    [userInfo?.org.id],
    {
      enable: !!userInfo,
      suppressErrors: true,
    },
  );

  const { data: keys, isLoading: isKeysLoading } = useApiGetter(SubscriptionService, 'listKeys', [false]);
  const { data: health, isLoading: isHealthLoading } = useAwsGetter('LocalStack', 'getHealth', [], {
    silentErrors: true,
  });

  const isLoading = isKeysLoading || isHealthLoading || isAssignmentInfoLoading;

  const states = useMemo(
    () => calculateStepStates(userInfo?.org, keys ?? { org: [], shared: [] }, assignmentInfo, health),
    [userInfo?.org?.id, keys],
  );

  const progress = useMemo(() => calculateProgress(states), [states]);

  const steps: WizardStep<{ org: Optional<Organization> }>[] = useMemo(
    () => [
      // loading screen
      {
        title: 'We are loading some tips for you',
        icon: <TipsIcon fontSize="large" />,
        type: WizardStepType.SIMPLE_TEXT,
        loading: isLoading,
        actions: [{ title: 'Continue', callback: () => null }],
        condition: ({ org }) => !org && isLoading,
      },
      // billing details check
      {
        title: 'Complete your Billing Details',
        icon: <OrganizationDetailsIcon fontSize="large" />,
        type: WizardStepType.SIMPLE_TEXT,
        actions: [{ title: 'Continue', callback: () => navigate(AppRoute.SETTINGS_BILLING) }],
        condition: ({ org }) => !org?.address,
      },
      // license check
      {
        title: 'Get your LocalStack license',
        icon: <SubscriptionsIcon fontSize="large" />,
        type: WizardStepType.SIMPLE_TEXT,
        actions: [{ title: 'Continue', callback: () => navigate(AppRoute.PRICING) }],
        condition: () =>
          !(
            Object.values(keys?.org ?? []).flat().length + Object.values(keys?.shared ?? []).flat().length ||
            assignmentInfo?.has_license_assigned
          ),
      },
      // container check
      {
        title: 'Start the LocalStack container',
        icon: <ContainerIcons fontSize="large" />,
        type: WizardStepType.SIMPLE_TEXT,
        actions: [
          {
            title: 'Continue',
            callback: () => {
              window.location.href = ExternalLink.DOCS_GETTING_STARTED;
            },
          },
        ],
        condition: () => !Object.keys(health || {}).length,
      },
      // fallback (pods ad)
      {
        title: 'View our educational quickstart samples utilizing cloud pods or interact with LocalStack via the CLI',
        icon: <FallbackIcon fontSize="large" />,
        type: WizardStepType.SIMPLE_TEXT,
        actions: [{ title: 'Continue', callback: () => navigate(AppRoute.QUICKSTART) }],
        condition: () => true,
      },
    ],
    [isLoading],
  );

  return (
    <Box className={className} style={style} {...rest}>
      <Wizard
        classes={{ card: classes.card }}
        title={
          <Box display="flex" alignItems="center" className={classes.title}>
            <SettingsIcon />
            <Box ml={1}>LocalStack Wizard</Box>
            {editing && (
              <IconButton className={classes.removeBtn} aria-label="remove" onClick={removeCallback} size="large">
                <RemoveIcon />
              </IconButton>
            )}
          </Box>
        }
        args={{ org: userInfo?.org }}
        steps={steps}
        progress={progress}
      />
    </Box>
  );
};
