import { Button, ButtonProps, Card, CardActions, CardContent, CardHeader, CardProps, styled } from '@mui/material';
import { ReactElement, useMemo } from 'react';
import convert from 'xml-js';

import { STORAGE_BLOG_DISMISS, ThemeType } from '@localstack/constants';

import { useLocalStorage } from '@localstack/services';

import useSWR from 'swr';

import { ItemEntity, RSSResponse } from './typing';

const DISPLAYED_BLOGS_IN_ONE_GO = 3;
const CARDS_OFFSET = 6;

const StyledCardActions = styled(CardActions)(() => ({
  justifyContent: 'space-between',
  paddingTop: 8,
  paddingBottom: 10,
  paddingInline: 10,
}));

const StyledImage = styled('img')(({ theme }) => ({
  position: 'relative',
  top: 0,
  left: 0,
  width: '100%',
  aspectRatio: '16 / 9',
  objectFit: 'cover',
  borderRadius: '8px',
  backgroundColor: theme.palette.background.default,
}));

interface BlogCardProps extends CardProps {
  index: number;
}

const BlogCard = styled(Card)<BlogCardProps>(({ theme, index }) => ({
  padding: 0,
  zIndex: theme.zIndex.appBar + DISPLAYED_BLOGS_IN_ONE_GO - index,
  width: '100%',
  position: index == 0 ? 'relative' : 'absolute', // Ensure absolute positioning for stacking
  top: `${index * -CARDS_OFFSET}px`, // Adjust stacking offset
  transform: `scalex(${1 - 0.05 * index})`,
  transition: 'transform 0.15s ease, opacity 0.15s ease',
  border: theme.palette.mode === ThemeType.DARK ? '0.6px solid #374151' : undefined,
  background: theme.palette.mode === ThemeType.DARK ? '#1f2937' : undefined,
}));

const BlogCardHeader = styled(CardHeader)(() => ({
  padding: 10,
}));

const BlogCardContent = styled(CardContent)(() => ({
  paddingInline: 10,
  paddingBlock: 0,
}));

interface BlogCardContainerProps extends React.HTMLAttributes<HTMLDivElement> {
  cardsCount: number;
}
const BlogCardsContainer = styled('div')<BlogCardContainerProps>(({ cardsCount }) => ({
  position: 'relative',
  display: 'flex',
  marginTop: CARDS_OFFSET * Math.max(0, cardsCount - 1),
}));

const BlogActionButton = styled(Button)<ButtonProps & React.ComponentProps<'a'>>(({ theme }) => ({
  padding: '1px 6px',
  color: theme.palette.mode === ThemeType.DARK ? theme.palette.common.white : theme.palette.common.black,
}));

const fetchRSS = async () => {
  const response = await fetch('https://blog.localstack.cloud/rss');
  if (!response.ok) {
    return;
  }
  const xmlTest = await response.text();
  return convert.xml2js(xmlTest, { compact: true }) as RSSResponse;
};

export const BlogCardViewer = (): ReactElement => {
  const { data: dismissedPosts, setDataWithCurrent: setDismissedPosts } = useLocalStorage<string[]>({
    ...STORAGE_BLOG_DISMISS,
    defaultValue: [],
  });

  const { data } = useSWR('rssFeed', fetchRSS);
  const posts = data?.rss.channel.item?.slice(0, DISPLAYED_BLOGS_IN_ONE_GO) ?? [];

  const filteredBlogs = useMemo(
    () => posts.filter((post) => !dismissedPosts?.includes(post.title._text)),
    [posts, dismissedPosts],
  );

  const handleDismiss = (post: ItemEntity) => {
    if (dismissedPosts?.includes(post.title._text)) {
      return;
    }
    setDismissedPosts((current) => [...(current ?? []), post.title._text]);
  };

  return (
    <BlogCardsContainer cardsCount={filteredBlogs.length}>
      {filteredBlogs.map((post, index) => (
        <BlogCard key={post.id} index={index}>
          <BlogCardHeader
            title={post.title._text}
            titleTypographyProps={{
              variant: 'body1',
              fontSize: '13px',
              style: { fontWeight: 'bold' },
              sx: {
                display: '-webkit-box',
                WebkitLineClamp: 1,
                WebkitBoxOrient: 'vertical',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
              },
            }}
            subheader={post.description._text}
            subheaderTypographyProps={{
              variant: 'body1',
              fontSize: '13px',
              sx: {
                display: '-webkit-box',
                WebkitLineClamp: 2,
                WebkitBoxOrient: 'vertical',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
              },
            }}
          />
          <BlogCardContent>
            <StyledImage src={post.enclosure._attributes.url} alt={post.title._text} loading="lazy" />
          </BlogCardContent>
          <StyledCardActions>
            <BlogActionButton
              component="a"
              href={post.link._text}
              target="_blank"
              rel="noopener noreferrer"
              size="small"
              color="primary"
            >
              Read More
            </BlogActionButton>
            <BlogActionButton size="small" color="primary" onClick={() => handleDismiss(post)}>
              Dismiss
            </BlogActionButton>
          </StyledCardActions>
        </BlogCard>
      ))}
    </BlogCardsContainer>
  );
};
