import { ReactElement, useCallback } from 'react';
import { useForm } from 'react-hook-form';
import { VALIDATION_RULES, getSchema } from '@localstack/services';
import { CreateBackupSelectionRequest } from '@localstack/types';
import { Box, Card, CardContent, CardHeader, IconButton, Link, MenuItem, Typography, Alert } from '@mui/material';
import { Add as AddIcon, Delete as DeleteIcon } from '@mui/icons-material';

import { ActionTitle } from '../../../../display';
import { ControlledSelect } from '../../../../form';
import { MagicForm } from '../../../magic/MagicForm';

const DEFAULT_IAM_ROLE_ARN = 'arn:aws:iam::000000000000:role/test';

enum SupportedResource {
  ALL = '_all_',
  DYNAMODB = 'dynamodb',
  RDS = 'rds',
}

const SUPPORTED_RESOURCES = [
  {
    type: SupportedResource.ALL,
    displayName: 'Include All',
  },
  {
    type: SupportedResource.DYNAMODB,
    displayName: 'DynamoDB',
  },
  {
    type: SupportedResource.RDS,
    displayName: 'RDS',
  },
];

type FormData = {
  SelectionName: string;
  IamRoleArn: string;
  Resources: {
    ResourceType: SupportedResource;
    ResourceArn: string;
  }[];
};

export interface BackupSelectionFormProps {
  loading?: boolean;
  formId?: string;
  arns: string[];
  planId: string;
  onCreate: (data: CreateBackupSelectionRequest) => unknown;
}

const getWildcardArn = (resource: SupportedResource) => {
  if (resource === SupportedResource.DYNAMODB) return 'arn:aws:dynamodb:*:*:table/*';
  if (resource === SupportedResource.RDS) return 'arn:aws:rds:*:*:db:*';
  return '*';
};

export const BackupSelectionForm = ({
  loading,
  formId,
  planId,
  arns,
  onCreate,
}: BackupSelectionFormProps): ReactElement => {
  const { control, watch, setValue, getValues } = useForm<FormData>({ mode: 'all' });
  const resources = watch('Resources') || [];

  const getResourceArns = useCallback(
    (resource: SupportedResource) => [
      getWildcardArn(resource),
      ...arns.filter((arn) => arn.split(':')[2] === resource),
    ],
    [arns],
  );

  const handleAddResource = useCallback(
    () => setValue('Resources', [...resources, { ResourceType: SupportedResource.ALL, ResourceArn: '*' }]),
    [resources],
  );

  const handleRemoveResource = useCallback(
    (index: number) =>
      setValue(
        'Resources',
        resources.filter((_, idx) => idx !== index),
      ),
    [resources],
  );

  const handleSubmitForm = useCallback(
    (data: CreateBackupSelectionRequest) => {
      onCreate({
        BackupPlanId: planId,
        BackupSelection: {
          ...data.BackupSelection,
          Resources: getValues('Resources').map(({ ResourceArn }) => ResourceArn),
        },
      });
    },
    [planId],
  );

  return (
    <MagicForm
      formId={formId}
      schema={getSchema('Backup')}
      entry="CreateBackupSelectionInput"
      loading={loading}
      defaultValues={{
        BackupPlanId: planId,
        BackupSelection: {
          IamRoleArn: DEFAULT_IAM_ROLE_ARN,
        },
      }}
      externalFields={{
        '^BackupSelection\\.Resources$': () => (
          <Box mt={3}>
            <ActionTitle
              title={<Typography variant="subtitle1">Assigned Resources</Typography>}
              actions={
                <IconButton onClick={handleAddResource} size="large">
                  <AddIcon />
                </IconButton>
              }
            />
            {resources.length === 0 && (
              <Alert severity="info" variant="outlined">
                There are no resources yet,{' '}
                <Link onClick={handleAddResource} underline="hover">
                  add a new one
                </Link>
              </Alert>
            )}
            {(resources || []).map((_, idx) => (
              // eslint-disable-next-line
              <Box key={idx} mb={3}>
                <Card variant="outlined">
                  <CardHeader
                    title={`Resource #${idx + 1}`}
                    action={
                      <IconButton onClick={() => handleRemoveResource(idx)} size="large">
                        <DeleteIcon />
                      </IconButton>
                    }
                  />
                  <CardContent>
                    <ControlledSelect
                      control={control}
                      name={`Resources.${idx}.ResourceType`}
                      label="Resource Type"
                      fullWidth
                      required
                      rules={VALIDATION_RULES.required}
                      options={SUPPORTED_RESOURCES.map(({ type, displayName }) => (
                        <MenuItem key={type} value={type}>
                          {displayName}
                        </MenuItem>
                      ))}
                    />
                    {resources[idx]?.ResourceType !== SupportedResource.ALL && (
                      <ControlledSelect
                        control={control}
                        name={`Resources.${idx}.ResourceArn`}
                        label="Resource ARN"
                        fullWidth
                        required
                        rules={VALIDATION_RULES.required}
                        options={getResourceArns(resources[idx]?.ResourceType as SupportedResource).map((arn) => (
                          <MenuItem key={arn} value={arn}>
                            {arn}
                          </MenuItem>
                        ))}
                      />
                    )}
                  </CardContent>
                </Card>
              </Box>
            ))}
          </Box>
        ),
      }}
      onSubmit={handleSubmitForm}
    />
  );
};
