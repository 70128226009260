import { v4 as uuid } from 'uuid';
import { ReactElement, useContext, useState } from 'react';
import { Button, Grid, Link, Typography, Alert, AlertTitle } from '@mui/material';
import { DEFAULT_RESOURCE_BASE_URL, ExternalLink } from '@localstack/constants';
import { GridItemBreakpoints, LoadingFragment, LocalStackInstanceHeader } from '@localstack/ui';
import { GlobalStateContext, useSnackbar } from '@localstack/services';
import { LocalStackInstance, LocalStackInstanceType } from '@localstack/types';

import { InstanceCard } from './InstanceCard';

const INSTANCE_ID_PREFIX = 'instance_';
const NEW_REGULAR_INSTANCE: LocalStackInstance = {
  id: INSTANCE_ID_PREFIX,
  name: 'New instance bookmark',
  endpoint: ExternalLink.WEB_APP_DEFAULT_ENDPOINT,
  description: '',
  instanceType: LocalStackInstanceType.REGULAR_INSTANCE,
  startedAt: new Date().getTime(),
};

export const InstanceBookmarks = (): ReactElement => {
  const { instances, setInstances } = useContext(GlobalStateContext);
  const filteredInstances = instances.filter((i) => i.instanceType === LocalStackInstanceType.REGULAR_INSTANCE);

  const [addBookmark, setAddBookmark] = useState(false);

  const onSave = (instance: LocalStackInstance) => {
    instance.id = `${INSTANCE_ID_PREFIX}${uuid()}`;
    setInstances([...instances, instance]);
    setAddBookmark(false);
  };

  const { showSnackbar } = useSnackbar();

  const onRemove = async (instance: LocalStackInstance) => {
    setInstances(instances.filter((i) => i.id !== instance.id));
    showSnackbar({
      message: 'Successfully removed instance',
      severity: 'success',
    });
  };

  const gridItemBreakpoints: GridItemBreakpoints = { xs: 12 };

  return (
    <>
      {addBookmark ? (
        <Grid item xs={12}>
          <InstanceCard instance={NEW_REGULAR_INSTANCE} onSave={onSave} onCancel={() => setAddBookmark(false)} />
        </Grid>
      ) : (
        <LoadingFragment variant="grid" size={4} gridItemBreakpoints={gridItemBreakpoints} height={189}>
          {filteredInstances?.map((instance) => (
            <Grid item {...gridItemBreakpoints} key={instance.id}>
              <LocalStackInstanceHeader isManagementView key={instance.id} instance={instance} onRemove={onRemove} />
            </Grid>
          ))}
        </LoadingFragment>
      )}
      <Grid item xs={12}>
        <Alert severity="info">
          <AlertTitle>
            <Typography variant="h6" style={{ fontWeight: 'bold' }}>
              Default Configuration
            </Typography>
          </AlertTitle>
          <Typography variant="body1">
            The default endpoint for LocalStack is <code>{DEFAULT_RESOURCE_BASE_URL}</code>.
          </Typography>
          <Typography variant="body1">
            This domain is configured to resolve to 127.0.0.1, directing traffic to your local instance.
          </Typography>
          <Typography variant="body1">
            The choice to avoid <code>http://localhost:4566</code> stems from the fact that certain browsers enforce
            security policies that restrict requests from HTTPS sites to HTTP endpoints.
          </Typography>
        </Alert>
      </Grid>
      <Grid item xs={12}>
        <Alert severity="info">
          <AlertTitle>
            <Typography variant="h6" style={{ fontWeight: 'bold' }}>
              Starting LocalStack on a different port
            </Typography>
          </AlertTitle>
          <Typography variant="body1">
            To run LocalStack on a different port configure the <code>GATEWAY_LISTEN</code> option when starting
            LocalStack as outlined in our{' '}
            <Link href={ExternalLink.DOCS_CORE_CONFIG} target="_blank" underline="hover">
              documentation
            </Link>
            .
          </Typography>
          <Typography variant="body1">
            After your new instance is running, add it to your instances by supplying the endpoint and name - or by
            adjusting existing instances on this page.
          </Typography>
        </Alert>
      </Grid>
      <Grid item xs={12} key={NEW_REGULAR_INSTANCE.id}>
        <Button variant="contained" disabled={addBookmark} color="primary" onClick={() => setAddBookmark(true)}>
          Add Bookmark
        </Button>
      </Grid>
    </>
  );
};
