import { ReactElement } from 'react';
import { AppSyncDataSource } from '@localstack/types';
import { getSchema } from '@localstack/services';

import { MagicDetails } from '../../../magic/MagicDetails';

export interface AppSyncDataSourceDetailsProps {
  dataSource?: Optional<Partial<AppSyncDataSource>>;
}

export const AppSyncDataSourceDetails = ({ dataSource }: AppSyncDataSourceDetailsProps): ReactElement => (
  <MagicDetails data={dataSource} schema={getSchema('AppSync')} entry="DataSource" title="DataSource Details" />
);
