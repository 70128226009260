import { useState, ReactElement } from 'react';
import { FormControlLabel, Switch, Typography, Box } from '@mui/material';
import { EventBridgeTarget, PutEventbridgeTargetsRequest } from '@localstack/types';
import { transformMagicFieldName, getSchema, AwsClientOverrides } from '@localstack/services';
import { DEFAULT_IAM_ROUTES, DEFAULT_SECRETSMANAGER_ROUTES } from '@localstack/constants';

import { MagicForm } from '../../magic/MagicForm';
import { ControlledTextField } from '../../../form';
import { RelatedResourcePicker } from '../../../form/RelatedResourcePicker';

// make fields optional, as they are only required in their contexts and not globally
const OPTIONAL_OVERRIDES = [
  '^Targets\\.[0-9]+\\.InputTransformer\\.InputTemplate$',
  '^Targets\\.[0-9]+\\.KinesisParameters\\.PartitionKeyPath$',
  '^Targets\\.[0-9]+\\.EcsParameters\\.TaskDefinitionArn$',
  '^Targets\\.[0-9]+\\.BatchParameters\\.(JobDefinition|JobName)$',
  '^Targets\\.[0-9]+\\.RedshiftDataParameters\\.(Database|Sql)$',
];

export interface EventBridgeTargetFormProps {
  target?: EventBridgeTarget;
  rule: string;
  bus: string;
  loading?: boolean;
  formId?: string;
  clientOverrides?: AwsClientOverrides;
  onCreate: (data: PutEventbridgeTargetsRequest) => unknown;
}

const ExtendedConfigCheckbox = ({
  label,
  enabled,
  onChange,
}: {
  label: string;
  enabled: boolean;
  onChange: (checked: boolean) => unknown;
}) => (
  <FormControlLabel
    label={label}
    control={<Switch color="primary" checked={enabled} onChange={(_e, checked) => onChange(checked)} />}
  />
);

export const EventBridgeTargetForm = ({
  target,
  rule,
  loading,
  formId,
  bus,
  clientOverrides,
  onCreate,
}: EventBridgeTargetFormProps): ReactElement => {
  const [enableEcs, setEnableEcs] = useState(!!target?.EcsParameters);
  const [enableKinesis, setEnableKinesis] = useState(!!target?.KinesisParameters);
  const [enableBatch, setEnableBatch] = useState(!!target?.BatchParameters);
  const [enableRedshift, setEnableRedshift] = useState(!!target?.RedshiftDataParameters);

  return (
    <>
      <Typography variant="subtitle1">Extended Configurations</Typography>
      <Typography variant="caption">Switch to display additional configurations</Typography>
      <Box mb={1}>
        <ExtendedConfigCheckbox label="ECS Configuration" enabled={enableEcs} onChange={setEnableEcs} />
        <ExtendedConfigCheckbox label="Kinesis Configuration" enabled={enableKinesis} onChange={setEnableKinesis} />
        <ExtendedConfigCheckbox label="Batch Configuration" enabled={enableBatch} onChange={setEnableBatch} />
        <ExtendedConfigCheckbox label="Redshift Configuration" enabled={enableRedshift} onChange={setEnableRedshift} />
      </Box>
      <MagicForm
        data={{
          EventBusName: bus,
          Targets: [target || {}],
        }}
        schema={getSchema('EventBridge')}
        loading={loading}
        entry="PutTargetsRequest"
        formId={formId}
        onSubmit={(data: PutEventbridgeTargetsRequest) => onCreate({ ...data, Rule: rule })}
        // TODO: maybe introduce optional(removable) sub-sections?
        fieldConditions={{
          '^Rule': false,
          '^Targets\\.[0-9]+\\.EcsParameters': enableEcs,
          '^Targets\\.[0-9]+\\.KinesisParameters': enableKinesis,
          '^Targets\\.[0-9]+\\.BatchParameters': enableBatch,
          '^Targets\\.[0-9]+\\.RedshiftDataParameters': enableRedshift,
          '^EventBusName$': false,
          '^Targets\\.[0-9]+\\.Id': !target,
        }}
        externalFields={{
          ...OPTIONAL_OVERRIDES.reduce(
            (memo, regex) => ({
              ...memo,
              // eslint-disable-next-line
              [regex]: (control: any, name: string) => (
                <ControlledTextField
                  control={control}
                  name={name}
                  fullWidth
                  variant="outlined"
                  label={transformMagicFieldName(name.split('.').slice(-1)[0])}
                />
              ),
            }),
            {},
          ),
          '^Targets\\.\\d+\\.RoleArn$': (control, fieldName, required) => (
            <RelatedResourcePicker
              control={control}
              client="IAM"
              method="listRoles"
              arrayKeyName="Roles"
              property="Arn"
              fieldName={fieldName}
              entityName="IAM Role"
              creationRoute={DEFAULT_IAM_ROUTES.RESOURCES_IAM_ROLE_CREATE}
              required={required}
              clientOverrides={clientOverrides}
            />
          ),
          '^Targets\\.\\d+\\.RedshiftDataParameters.SecretManagerArn$': (control, fieldName, required) => (
            <RelatedResourcePicker
              control={control}
              client="SecretsManager"
              method="listSecrets"
              arrayKeyName="SecretList"
              property="ARN"
              fieldName={fieldName}
              entityName="Secret"
              creationRoute={DEFAULT_SECRETSMANAGER_ROUTES.RESOURCES_SECRETS_MANAGER_SECRET_CREATE}
              required={required}
              clientOverrides={clientOverrides}
            />
          ),
        }}
      />
    </>
  );
};
