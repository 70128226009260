import { ReactElement } from 'react';
import { GlueRegistryListItem } from '@localstack/types';
import { getSchema } from '@localstack/services';
import { Link } from '@mui/material';

import { MagicTable } from '../../../magic/MagicTable';

export type GlueRegistriesTableProps = {
  registries: GlueRegistryListItem[];
  selectable?: boolean;
  loading?: boolean;
  onSelect?: (names: string[]) => void;
  onViewRegistry?: (name: string) => void;
};

export const GlueRegistriesTable = ({
  registries,
  selectable = true,
  loading,
  onSelect,
  onViewRegistry,
}: GlueRegistriesTableProps): ReactElement => (
  <MagicTable
    schema={getSchema('Glue')}
    entry="RegistryListItem"
    idAttribute="RegistryName"
    rows={registries}
    selectable={selectable}
    onSelect={onSelect}
    loading={loading}
    filterColumns={['RegistryName', 'Description', 'Status', 'CreatedTime', 'UpdatedTime']}
    externalFields={{
      RegistryName: (row) => (
        <Link onClick={() => onViewRegistry && onViewRegistry(row.RegistryName as string)} underline="hover">
          {row.RegistryName}
        </Link>
      ),
    }}
  />
);
