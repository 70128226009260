import { ReactElement } from 'react';
import { Link } from '@mui/material';
import { KinesisStream } from '@localstack/types';
import { getSchema } from '@localstack/services';

import { MagicTable } from '../../magic/MagicTable';

export type KinesisStreamsTableProps = {
  selectable?: boolean;
  loading?: boolean;
  streams: KinesisStream[];
  onViewStream?: (stream: KinesisStream) => unknown;
  onSelect?: (names: string[]) => void;
};

export const KinesisStreamsTable = ({
  streams,
  selectable = true,
  loading,
  onViewStream,
  onSelect,
}: KinesisStreamsTableProps): ReactElement => (
  <MagicTable
    schema={getSchema('Kinesis')}
    entry="StreamDescription"
    idAttribute="StreamName"
    rows={streams}
    selectable={selectable}
    onSelect={onSelect}
    order={['StreamName']}
    loading={loading}
    filterColumns={[
      'StreamName',
      'StreamARN',
      'StreamStatus',
      'HasMoreShards',
      'RetentionPeriodHours',
      'StreamCreationTimestamp',
      'EncryptionType',
      'KeyId',
    ]}
    externalFields={{
      StreamName: (row) => (
        <Link onClick={() => onViewStream && onViewStream(row)} underline="hover">
          {row.StreamName}
        </Link>
      ),
    }}
  />
);
