import { ReactElement, useState, useMemo } from 'react';

import { ConditionalWrapper } from '@localstack/integrations';

import { ResourceBrowserContext } from '@localstack/services';

import { ResourceBrowserHeader } from '@localstack/ui';

import { ComplexLayoutProps } from '@localstack/types';

import { InstanceHeaderWrapper } from '../Headers/InstanceHeaderWrapper';

import { LayoutContainer } from '../LayoutContainer';

import { CustomerLayout, CustomerLayoutProps } from './CustomerLayout';

type ResourceBrowserLayoutProps = CustomerLayoutProps & ComplexLayoutProps;

export const ResourceBrowserLayout = ({
  children,
  title,
  planFamily,
  actions,
  maxWidth,
  fullScreen,
  tabs,
  ...rest
}: ResourceBrowserLayoutProps): ReactElement => {
  const showInstanceHeader = !(fullScreen ?? false);
  const [resetFn, setResetFn] = useState<(() => void) | null>(null);
  const contextValue = useMemo(() => ({ setResetFn }), [setResetFn]);

  return (
    <CustomerLayout {...rest} title={title}>
      <ConditionalWrapper
        condition={showInstanceHeader}
        wrapper={(content) => <InstanceHeaderWrapper>{content}</InstanceHeaderWrapper>}
      >
        <>
          <ResourceBrowserHeader
            actions={actions}
            title={title}
            planFamily={planFamily}
            maxWidth={maxWidth}
            fullScreen={fullScreen}
            tabs={tabs}
            resetFn={resetFn}
          />
          <LayoutContainer fullScreen={fullScreen} maxWidth={maxWidth}>
            <ResourceBrowserContext.Provider value={contextValue}>{children}</ResourceBrowserContext.Provider>
          </LayoutContainer>
        </>
      </ConditionalWrapper>
    </CustomerLayout>
  );
};
