import { ReactElement } from 'react';
import { Box, Typography, Button } from '@mui/material';
import { LogoIcon } from '@localstack/ui';

import { AuthLayout } from '~/views/customer/Account/AuthLayout';

type Props = {
  message: string | JSX.Element;
  isActivated?: boolean;
  isActivationDisabled?: boolean;
  activationText?: string | JSX.Element;
  onActivation?: () => void | Promise<void>;
};

// TODO: Use this new BaseActivation (with new design) in all the activation screens and remove BaseActivationOld
export const BaseActivation = ({
  message,
  isActivated,
  isActivationDisabled,
  activationText,
  onActivation,
}: Props): ReactElement => (
  <AuthLayout wideLayout>
    <Box textAlign="center">
      <Box mb={4}>
        <LogoIcon />
      </Box>
      <Typography component="div">{message}</Typography>
      {!isActivated && activationText && onActivation && (
        <Box mt={3}>
          <Button color="primary" variant="contained" onClick={onActivation} disabled={isActivationDisabled}>
            {activationText}
          </Button>
        </Box>
      )}
    </Box>
  </AuthLayout>
);
