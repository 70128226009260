import { ReactElement, useCallback, useState } from 'react';
import { useRoutes, useAwsGetter, useAwsEffect } from '@localstack/services';
import { useParams } from 'react-router-dom';
import { Card, CardContent, Button } from '@mui/material';
import { AgwRestApiMethodResponsesTable, Dropdown, ConfirmableButton, PageTitle } from '@localstack/ui';
import { DEFAULT_API_GATEWAY_ROUTES } from '@localstack/constants';

import { ApiGatewayProps } from './types';
import { RestApiMethodNavTabs } from './components';

export const AgwRestApiMethodResponses = ({
  Layout,
  clientOverrides,
  routes = DEFAULT_API_GATEWAY_ROUTES,
}: ApiGatewayProps): ReactElement => {
  const { goto } = useRoutes();
  const [selectedIds, setSelectedIds] = useState<string[]>([]);

  const { restApiId, resourceId, httpMethod } = useParams<'restApiId' | 'resourceId' | 'httpMethod'>();

  const { data: api } = useAwsGetter('APIGateway', 'getRestApi', [{ restApiId }], { clientOverrides });

  const { data: resource } = useAwsGetter('APIGateway', 'getResource', [{ restApiId, resourceId }], {
    clientOverrides,
  });

  const {
    data: method,
    isLoading,
    mutate,
  } = useAwsGetter('APIGateway', 'getMethod', [{ restApiId, resourceId, httpMethod }], { clientOverrides });

  const { deleteMethodResponse } = useAwsEffect('APIGateway', ['deleteMethodResponse'], {
    revalidate: ['getMethod', 'getResources', 'getResource'],
    clientOverrides,
  });

  const handleDeleteSelected = useCallback(async () => {
    const promises = selectedIds.map((statusCode) =>
      deleteMethodResponse({
        restApiId: restApiId as string,
        resourceId: resourceId as string,
        httpMethod: httpMethod as string,
        statusCode,
      }),
    );
    await Promise.all(promises);
  }, [selectedIds]);

  return (
    <Layout
      documentTitle="Method Responses"
      tabs={
        <RestApiMethodNavTabs
          restApiId={restApiId as string}
          resourceId={resourceId as string}
          httpMethod={httpMethod as string}
          routes={routes}
        />
      }
      title={
        <PageTitle
          title="Method Response"
          onMutate={mutate}
          breadcrumbs={[
            ['API Gateway', () => goto(routes.RESOURCES_AGW1)],
            [api?.name, () => goto(routes.RESOURCES_AGW1_API, { restApiId })],
            [resource?.path, () => goto(routes.RESOURCES_AGW1_API_RESOURCE, { restApiId, resourceId })],
            [method?.httpMethod, () => goto(routes.RESOURCES_AGW1_API_METHOD, { restApiId, resourceId, httpMethod })],
            ['Method Responses', null],
          ]}
        />
      }
      actions={
        <>
          <Button
            onClick={() =>
              goto(routes.RESOURCES_AGW1_API_METHOD_RESPONSE_CREATE, { restApiId, resourceId, httpMethod })
            }
          >
            Create Response
          </Button>
          <Dropdown label="Actions">
            <ConfirmableButton
              componentType="MenuItem"
              disabled={selectedIds.length === 0 || isLoading}
              title={`Delete ${selectedIds.length} Response(s)?`}
              onClick={handleDeleteSelected}
              text="Selected Responses will be permanently deleted"
            >
              Remove Selected
            </ConfirmableButton>
          </Dropdown>
        </>
      }
    >
      <Card>
        <CardContent>
          <AgwRestApiMethodResponsesTable
            loading={isLoading}
            responses={Object.values(method?.methodResponses || {})}
            onSelect={setSelectedIds}
            onViewResponse={({ statusCode }) =>
              goto(routes.RESOURCES_AGW1_API_METHOD_RESPONSE, { restApiId, resourceId, httpMethod, statusCode })
            }
          />
        </CardContent>
      </Card>
    </Layout>
  );
};
