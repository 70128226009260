import { ChangeEvent, ReactElement, useState } from 'react';
import { EventBridgeCreatePipeRequest } from '@localstack/types';
import { AwsClientOverrides, buildRoute, getSchema } from '@localstack/services';
import {
  Checkbox,
  Divider,
  FormControl,
  FormControlLabel,
  Grid,
  MenuItem,
  RadioGroup,
  TextField,
  Typography,
} from '@mui/material';
import {
  DEFAULT_CLOUDWATCH_ROUTES,
  DEFAULT_EVENTBRIDGE_ROUTES,
  DEFAULT_IAM_ROUTES,
  DEFAULT_KINESIS_ROUTES,
  DEFAULT_LAMBDA_ROUTES,
  DEFAULT_SNS_ROUTES,
  DEFAULT_SQS_ROUTES,
  DEFAULT_STEPFUNCTIONS_ROUTES,
  SERVICE_CODES,
} from '@localstack/constants';

import { MagicForm } from '../../magic/MagicForm';
import { ControlledCodeEditor, RelatedResourcePicker } from '../../../form';

const RESOURCE_PICKER_ARGUMENTS = {
  [SERVICE_CODES.sqs]: {
    client: 'SQS',
    method: 'listQueues',
    arrayKeyName: 'QueueUrls',
    creationRoute: buildRoute(DEFAULT_SQS_ROUTES.RESOURCES_SQS_QUEUE_CREATE),
    entityName: 'SQS Queue',
  },
  [SERVICE_CODES.dynamodbstreams]: {
    client: 'DynamoDBStreams',
    method: 'listStreams',
    arrayKeyName: 'Streams',
    property: 'StreamArn',
  },
  [SERVICE_CODES.kinesis]: {
    client: 'Kinesis',
    method: 'listStreams',
    arrayKeyName: 'StreamNames',
    creationRoute: buildRoute(DEFAULT_KINESIS_ROUTES.RESOURCES_KINESIS_STREAM_CREATE),
    entityName: 'Kinesis Stream',
  },
  [SERVICE_CODES.events]: {
    client: 'EventBridge',
    method: 'listEventBuses',
    arrayKeyName: 'EventBuses',
    property: 'Arn',
    creationRoute: buildRoute(DEFAULT_EVENTBRIDGE_ROUTES.RESOURCES_EVENT_BRIDGE_BUS_CREATE),
    entityName: 'EventBridge Bus',
  },
  [SERVICE_CODES.lambda]: {
    client: 'Lambda',
    method: 'listFunctions',
    arrayKeyName: 'Functions',
    property: 'FunctionArn',
    creationRoute: buildRoute(DEFAULT_LAMBDA_ROUTES.RESOURCES_LAMBDA_FUNCTION_NEW),
    entityName: 'Lambda Function',
  },
  [SERVICE_CODES.sns]: {
    client: 'SNS',
    method: 'listTopics',
    arrayKeyName: 'Topics',
    property: 'TopicArn',
    creationRoute: buildRoute(DEFAULT_SNS_ROUTES.RESOURCES_SNS_TOPIC_CREATE),
    entityName: 'SNS Topic',
  },
  [SERVICE_CODES.stepfunctions]: {
    client: 'StepFunctions',
    method: 'listStateMachines',
    arrayKeyName: 'stateMachines',
    property: 'stateMachineArn',
    creationRoute: buildRoute(DEFAULT_STEPFUNCTIONS_ROUTES.RESOURCES_STEPFUNCTIONS_STATE_MACHINE_CREATE),
    entityName: 'StepFunctions State Machine',
  },
};

const SUPPORTED_SOURCE_SERVICES = [SERVICE_CODES.sqs, SERVICE_CODES.dynamodbstreams, SERVICE_CODES.kinesis];
const SUPPORTED_TARGET_SERVICES = [
  SERVICE_CODES.sqs,
  SERVICE_CODES.events,
  SERVICE_CODES.kinesis,
  SERVICE_CODES.lambda,
  SERVICE_CODES.sns,
  SERVICE_CODES.stepfunctions,
];

export interface EventBridgePipeFormProps {
  loading?: boolean;
  formId?: string;
  onCreate: (data: EventBridgeCreatePipeRequest) => unknown;
  clientOverrides?: AwsClientOverrides;
  awsAccountId: string;
  region: string;
}

const convertValueToArnIfNeeded = (value: string, service: string, awsAccountId: string, region: string) => {
  if (service === SERVICE_CODES.sqs) {
    const name = value.split('/').pop();
    return `arn:aws:sqs:${region}:${awsAccountId}:${name}`;
  }
  if (service === SERVICE_CODES.kinesis) {
    return `arn:aws:kinesis:${region}:${awsAccountId}:stream/${value}`;
  }
  return value;
};

export const EventBridgePipeForm = ({
  loading,
  formId,
  onCreate,
  clientOverrides,
  awsAccountId,
  region,
}: EventBridgePipeFormProps): ReactElement => {
  const [sourceService, setSourceService] = useState<string>(SUPPORTED_SOURCE_SERVICES[0] as string);
  const [targetService, setTargetService] = useState<string>(SUPPORTED_TARGET_SERVICES[0] as string);
  const [enableLogging, setEnableLogging] = useState<boolean>(false);

  return (
    <MagicForm
      schema={getSchema('Pipes')}
      loading={loading}
      entry="CreatePipeRequest"
      formId={formId}
      onSubmit={(data: EventBridgeCreatePipeRequest) =>
        onCreate({
          ...data,
          Source: convertValueToArnIfNeeded(data.Source, sourceService, awsAccountId, region),
          Target: convertValueToArnIfNeeded(data.Target, targetService, awsAccountId, region),
        })
      }
      injectedFields={{
        '^Tags$': {
          position: 'after',
          element: (
            <Grid item>
              <FormControl
                variant="standard"
                component="fieldset"
                // eslint-disable-next-line
                onChange={(e: ChangeEvent<HTMLFieldSetElement>) => setEnableLogging((e.target as any).checked)}
              >
                <RadioGroup row color="primary">
                  <FormControlLabel
                    checked={enableLogging}
                    control={<Checkbox color="primary" />}
                    label="Enable Logging"
                  />
                </RadioGroup>
              </FormControl>
            </Grid>
          ),
        },
        '^Source$': {
          position: 'before',
          element: (
            <>
              <Grid item md={6} sm={12}>
                <Typography variant="subtitle1">Source service</Typography>
              </Grid>
              <Grid item md={6} sm={12}>
                <TextField
                  variant="outlined"
                  fullWidth
                  select
                  label="Source Service"
                  onChange={(e) => setSourceService(e.target.value)}
                  value={sourceService}
                >
                  {SUPPORTED_SOURCE_SERVICES.map((item) => (
                    <MenuItem key={item} value={item}>
                      {item}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>
              <Grid item xs={12}>
                <Divider variant="fullWidth" orientation="horizontal" />
              </Grid>
            </>
          ),
        },
        '^Target$': {
          position: 'before',
          element: (
            <>
              <Grid item md={6} sm={12}>
                <Typography variant="subtitle1">Target service</Typography>
              </Grid>
              <Grid item md={6} sm={12}>
                <TextField
                  variant="outlined"
                  fullWidth
                  select
                  label="Target Service"
                  onChange={(e) => setTargetService(e.target.value)}
                  value={targetService}
                >
                  {SUPPORTED_TARGET_SERVICES.map((item) => (
                    <MenuItem key={item} value={item}>
                      {item}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>
              <Grid item xs={12}>
                <Divider variant="fullWidth" orientation="horizontal" />
              </Grid>
            </>
          ),
        },
      }}
      fieldConditions={{
        // Sources supported
        '^SourceParameters.KinesisStreamParameters$': sourceService === SERVICE_CODES.kinesis,
        '^SourceParameters.DynamoDBStreamParameters$': sourceService === SERVICE_CODES.dynamodbstreams,
        '^SourceParameters.SqsQueueParameters$': sourceService === SERVICE_CODES.sqs,
        // Sources not supported
        '^SourceParameters.ActiveMQBrokerParameters$': false,
        '^SourceParameters.RabbitMQBrokerParameters$': false,
        '^SourceParameters.ManagedStreamingKafkaParameters$': false,
        '^SourceParameters.SelfManagedKafkaParameters$': false,
        // Targets supported
        '^TargetParameters.LambdaFunctionParameters$': targetService === SERVICE_CODES.lambda,
        '^TargetParameters.StepFunctionStateMachineParameters$': targetService === SERVICE_CODES.stepfunctions,
        '^TargetParameters.KinesisStreamParameters$': targetService === SERVICE_CODES.kinesis,
        '^TargetParameters.SqsQueueParameters$': targetService === SERVICE_CODES.sqs,
        '^TargetParameters.EventBridgeEventBusParameters$': targetService === SERVICE_CODES.events,
        // Targets not supported
        '^TargetParameters.EcsTaskParameters$': false,
        '^TargetParameters.BatchJobParameters$': false,
        '^TargetParameters.RedshiftDataParameters$': false,
        '^TargetParameters.SageMakerPipelineParameters$': false,
        '^TargetParameters.CloudWatchLogsParameters$': false,
        '^TargetParameters.TimestreamParameters$': false,
        '^TargetParameters.HttpParameters$': false,
        // Only Cloudwatch logs are supported
        '^LogConfiguration$': enableLogging,
        '^LogConfiguration.S3LogDestination$': false,
        '^LogConfiguration.FirehoseLogDestination$': false,
        '^EnrichmentParameters$': false,
      }}
      externalFields={{
        // Only Lambda functions are supported for enrichment
        '^Enrichment$': (control, fieldName, required) => (
          <RelatedResourcePicker
            control={control}
            client="Lambda"
            method="listFunctions"
            arrayKeyName="Functions"
            property="FunctionArn"
            fieldName={fieldName}
            entityName="Lambda Function"
            creationRoute={DEFAULT_LAMBDA_ROUTES.RESOURCES_LAMBDA_FUNCTION_NEW}
            required={required}
            clientOverrides={clientOverrides}
          />
        ),
        '^RoleArn$': (control, fieldName, required) => (
          <RelatedResourcePicker
            control={control}
            client="IAM"
            method="listRoles"
            arrayKeyName="Roles"
            property="Arn"
            fieldName={fieldName}
            entityName="IAM Role"
            creationRoute={DEFAULT_IAM_ROUTES.RESOURCES_IAM_ROLE_CREATE}
            required={required}
            clientOverrides={clientOverrides}
          />
        ),
        '^LogConfiguration.CloudwatchLogsLogDestination.LogGroupArn$': (control, fieldName, required) => (
          <RelatedResourcePicker
            control={control}
            client="CloudWatchLogs"
            method="describeLogGroups"
            arrayKeyName="logGroups"
            property="arn"
            fieldName={fieldName}
            entityName="CloudWatchLog Group"
            creationRoute={DEFAULT_CLOUDWATCH_ROUTES.RESOURCES_CLOUDWATCH_GROUP_CREATE}
            required={required}
            clientOverrides={clientOverrides}
          />
        ),
        '^Source$': (control, fieldName, required) => {
          const currentService = RESOURCE_PICKER_ARGUMENTS[sourceService];
          if (!currentService) return <></>;
          const { client, method, arrayKeyName, property, creationRoute, entityName } = currentService;
          return (
            <RelatedResourcePicker
              control={control}
              // @ts-expect-error FIXME: cast to proper type
              client={client}
              // @ts-expect-error FIXME: cast to proper type
              method={method}
              arrayKeyName={arrayKeyName}
              property={property}
              creationRoute={creationRoute}
              entityName={entityName ?? ''}
              fieldName={fieldName}
              required={required}
              clientOverrides={clientOverrides}
            />
          );
        },
        '^Target$': (control, fieldName, required) => {
          const currentService = RESOURCE_PICKER_ARGUMENTS[targetService];
          if (!currentService) return <></>;
          const { client, method, arrayKeyName, property, creationRoute, entityName } = currentService;
          return (
            <RelatedResourcePicker
              control={control}
              // @ts-expect-error FIXME: cast to proper type
              client={client}
              // @ts-expect-error FIXME: cast to proper type
              method={method}
              arrayKeyName={arrayKeyName}
              property={property}
              creationRoute={creationRoute}
              entityName={entityName ?? ''}
              fieldName={fieldName}
              required={required}
              clientOverrides={clientOverrides}
            />
          );
        },
        '^EnrichmentParameters.InputTemplate$': (control, name, required) => (
          <ControlledCodeEditor control={control} name={name} language="json" required={required} />
        ),
        '^TargetParameters.InputTemplate$': (control, name, required) => (
          <ControlledCodeEditor control={control} name={name} language="json" required={required} />
        ),
      }}
    />
  );
};
