import { AwsClientOverrides, getSelectedInstance, useExtensionHandler } from '@localstack/services';
import { Extension } from '@localstack/types';
import { InstanceSelector, ProgressButton, StatusIndicator } from '@localstack/ui';
import { Card, CardActions, CardContent, CardHeader, Chip, Grid } from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import { ReactElement, useMemo, useState } from 'react';

const useStyles = makeStyles(() =>
  createStyles({
    cardContent: {
      flexGrow: 1,
      display: 'flex',
      flexDirection: 'column',
    },
  }),
);
type ExtensionInstallCardProps = {
  extension: Optional<Extension>;
};

export const ExtensionInstallCard = ({ extension }: ExtensionInstallCardProps): ReactElement => {
  const defaultInstance = getSelectedInstance();
  const classes = useStyles();

  const [clientOverrides, setClientOverrides] = useState<AwsClientOverrides>(
    defaultInstance ? { endpoint: defaultInstance.endpoint } : {},
  );

  const {
    install,
    uninstall,
    mutatingExtension,
    isExtensionInstalled,
    logs,
    hasManagingExtensionError,
    lastInteractedExtension,
  } = useExtensionHandler({ extensions: extension ? [extension] : [], clientOverrides });

  const hasLogs = lastInteractedExtension === extension?.name && !!logs;

  const isInstalled = useMemo(
    () => isExtensionInstalled(extension?.name ?? ''),
    [mutatingExtension, isExtensionInstalled],
  );

  return (
    <Card>
      <CardHeader title="Extension Manager" action={isInstalled && <Chip color="primary" label="Installed" />} />
      <CardContent className={classes.cardContent}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <InstanceSelector callback={(instance) => setClientOverrides({ endpoint: instance.endpoint })} hideIfOne />
          </Grid>
          {hasLogs && (
            <Grid item>
              <StatusIndicator
                hasError={hasManagingExtensionError}
                isProgressing={!!mutatingExtension}
                logEvents={logs}
              />
            </Grid>
          )}
        </Grid>
      </CardContent>
      <CardActions>
        <ProgressButton
          variant="contained"
          color="primary"
          type="submit"
          disabled={!extension}
          loading={!!mutatingExtension}
          onClick={() => (isInstalled ? uninstall(extension?.name ?? '') : install(extension?.name ?? ''))}
        >
          {isInstalled ? <>Remove</> : <>Install</>}
        </ProgressButton>
      </CardActions>
    </Card>
  );
};
