import { useState, useCallback, ReactElement, useEffect } from 'react';
import { useRoutes, useAwsEffect, useAwsGetter } from '@localstack/services';
import { Card, Button } from '@mui/material';
import { Dropdown, ConfirmableButton, IAMRolePoliciesTable, PageTitle } from '@localstack/ui';
import { useParams } from 'react-router-dom';
import { IAMRolePolicy } from '@localstack/types';

import { DEFAULT_IAM_ROUTES } from '@localstack/constants/src';

import { RoleTabs } from './components';
import { IAMProps } from './types';
import { ROLE_POLICY_TYPE } from './utils';

export const IAMRolePolicies = ({ Layout, clientOverrides, routes = DEFAULT_IAM_ROUTES }: IAMProps): ReactElement => {
  const [selectedIds, setSelectedIds] = useState<string[]>([]);

  const [rolePolicies, setRolePolicies] = useState<IAMRolePolicy[]>([]);
  const { roleName } = useParams<'roleName'>();
  const { goto } = useRoutes();
  const {
    data: attachedRolePolicies,
    isLoading: attachedPoliciesLoading,
    mutate: attachedPoliciesLoadingMutate,
  } = useAwsGetter(
    'IAM',
    'listAttachedRolePolicies',
    [
      {
        RoleName: roleName,
      },
    ],
    { clientOverrides },
  );

  const {
    data: managedRolePolicies,
    isLoading: managedPoliciesLoading,
    mutate: managedPoliciesMutate,
  } = useAwsGetter(
    'IAM',
    'listRolePolicies',
    [
      {
        RoleName: roleName,
      },
    ],
    { clientOverrides },
  );

  const { deleteRolePolicy } = useAwsEffect('IAM', ['deleteRolePolicy'], {
    revalidate: ['listRolePolicies'],
    clientOverrides,
  });

  const { detachRolePolicy } = useAwsEffect('IAM', ['detachRolePolicy'], {
    revalidate: ['listAttachedRolePolicies'],
    clientOverrides,
  });

  const handleDetachSelected = useCallback(async () => {
    const promises = selectedIds.map((policy) => {
      const item = rolePolicies.find((p) => p.PolicyName === policy);
      // For managed policies, detachRolePolicy
      // For inline policies, deleteRolePolicy
      return item?.PolicyType === ROLE_POLICY_TYPE.managed
        ? detachRolePolicy({ PolicyArn: item?.PolicyArn || '', RoleName: roleName || '' })
        : deleteRolePolicy({ PolicyName: item?.PolicyName || '', RoleName: roleName || '' });
    });
    await Promise.all(promises);
  }, [selectedIds]);

  const isLoading = attachedPoliciesLoading && managedPoliciesLoading;

  useEffect(() => {
    if (!isLoading) {
      setRolePolicies([
        ...(attachedRolePolicies?.AttachedPolicies?.map(({ PolicyName, PolicyArn }) => ({
          PolicyName,
          PolicyArn,
          PolicyType: ROLE_POLICY_TYPE.managed,
        })) || []),
        ...(managedRolePolicies?.PolicyNames?.map((policy) => ({
          PolicyName: policy,
          PolicyArn: undefined,
          PolicyType: ROLE_POLICY_TYPE.inline,
        })) || []),
      ]);
    }
  }, [isLoading]);

  return (
    <Layout
      documentTitle="IAM: Role Policies"
      title={
        <PageTitle
          title="IAM Role Policies"
          onMutate={() => {
            attachedPoliciesLoadingMutate();
            managedPoliciesMutate();
          }}
          breadcrumbs={[
            ['IAM', () => goto(routes.RESOURCES_IAM)],
            ['Roles', () => goto(routes.RESOURCES_IAM_ROLES)],
            [roleName, () => goto(routes.RESOURCES_IAM_ROLE, { roleName })],
            ['Policies', () => null],
          ]}
        />
      }
      tabs={<RoleTabs routes={routes} roleName={roleName} />}
      actions={
        <>
          <Button onClick={() => goto(routes.RESOURCES_IAM_ROLE_INLINE_POLICY_CREATE, { roleName })}>
            Create Inline Policy
          </Button>
          <Button onClick={() => goto(routes.RESOURCES_IAM_ROLE_POLICIES_ATTACH, { roleName })}>Attach Policy</Button>
          <Dropdown label="Actions">
            <ConfirmableButton
              componentType="MenuItem"
              disabled={selectedIds.length === 0 || isLoading}
              title={`Detach / Delete ${selectedIds.length} Policies from Role?`}
              onClick={handleDetachSelected}
              text="Selected Policies will be detached / deleted"
            >
              Detach / Delete Selected
            </ConfirmableButton>
          </Dropdown>
        </>
      }
    >
      <Card>
        <IAMRolePoliciesTable
          selectable
          loading={isLoading}
          policies={rolePolicies || []}
          onSelect={setSelectedIds}
          onViewPolicy={({ PolicyArn, PolicyName }) => {
            if (PolicyArn) {
              goto(routes.RESOURCES_IAM_POLICY, { policyArn: encodeURIComponent(PolicyArn || '') });
            } else {
              goto(routes.RESOURCES_IAM_ROLE_INLINE_POLICY_UPDATE, { roleName, policyName: PolicyName });
            }
          }}
        />
      </Card>
    </Layout>
  );
};
