import { ReactElement } from 'react';
import { NavLink } from '@localstack/ui';
import { buildRoute } from '@localstack/services';

import { AppRoute } from '~/config';

export const NavTabs = (): ReactElement => (
  <>
    <NavLink to={buildRoute(AppRoute.PODS)} end>
      Organization Pods
    </NavLink>
    <NavLink to={buildRoute(AppRoute.PODS_PUBLIC)} end>
      Public Pods
    </NavLink>
  </>
);
