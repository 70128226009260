import makeStyles from '@mui/styles/makeStyles';

import createStyles from '@mui/styles/createStyles';
import { Theme } from '@mui/material/styles';
import { Container } from '@mui/material';

import { ReactElement } from 'react';
import { ComplexLayoutProps } from '@localstack/types';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    contentContainer: {
      flexGrow: 1,
      display: 'flex',
      flexDirection: 'column',
      paddingTop: theme.spacing(2),
      paddingBottom: theme.spacing(2),
    },
  }),
);

type LayoutContainerProps = Pick<ComplexLayoutProps, 'children' | 'fullScreen' | 'maxWidth'>;

/**
 * Layout Container is a wrapper with the content to be shown by a given page of the web app. It leverages mui Container component to center our content on larger screen sizes.
 */
export const LayoutContainer = ({ children, fullScreen, maxWidth }: LayoutContainerProps): ReactElement => {
  const classes = useStyles();

  const containerMaxWidth = fullScreen ? false : maxWidth;

  return (
    <Container maxWidth={containerMaxWidth} className={classes.contentContainer}>
      {children}
    </Container>
  );
};
