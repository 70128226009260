import { ReactElement, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { CANCELABLE_PLAN_FAMILIES, UPDATEABLE_PLAN_FAMILIES } from '@localstack/constants';
import { ApiKey, Permission, PlanFamily, AcquisitionChannel, SubscriptionStatus } from '@localstack/types';
import { Alert, Card, CardContent, CardHeader, Grid } from '@mui/material';

import {
  DangerZone,
  DangerZoneAction,
  DangerZoneActions,
  LoadingFragment,
  SubscriptionSummary,
  SubscriptionSummarySkeleton,
} from '@localstack/ui';

import {
  OrganizationsService,
  ComputeService,
  SubscriptionService,
  useApiEffect,
  useApiGetter,
  useRoutes,
} from '@localstack/services';

import { AppRoute } from '~/config';
import { ContainedCustomerLayout } from '~/layouts';
import { useAuthProvider } from '~/hooks/useAuthProvider';

import { SubscriptionCancellationModal } from './components/Subscriptions/SubscriptionCancellationModal';
import { ProStarterRenameInfo } from './components/Subscriptions/ProStarterRenameInfo';

const keysSorter = (lk: ApiKey, rk: ApiKey) => {
  const leftName = lk.name || '';
  const rightName = rk.name || '';

  if (leftName < rightName) return -1;
  if (leftName > rightName) return 1;
  return 0;
};

export const SubscriptionDetails = (): ReactElement => {
  const { can } = useAuthProvider();
  const { goto } = useRoutes();
  const { subscriptionId } = useParams<'subscriptionId'>() as { subscriptionId: string };

  const [showCancelModal, setShowCancelModal] = useState(false);
  const [selectedKey, setSelectedKey] = useState<Optional<ApiKey>>(null);

  const { userInfo } = useAuthProvider();

  const { data: subscription } = useApiGetter(SubscriptionService, 'getSubscription', [subscriptionId]);
  const { data: usage } = useApiGetter(SubscriptionService, 'getUsage', [subscriptionId]);
  const { data: computeUsage } = useApiGetter(ComputeService, 'getComputeUsage', []);
  const { data: keys } = useApiGetter(SubscriptionService, 'listKeys', [false]);
  const { data: licenseAssignments } = useApiGetter(
    OrganizationsService,
    'listLicenseAssignments',
    [userInfo?.org?.id || ''],
    { enable: !!userInfo?.org },
  );

  // TODO: get rid of mutation (sort)
  const subscriptionKeys = (keys?.org ?? []).filter((k) => k.subscription_id === subscriptionId).sort(keysSorter);
  const nonCIKeys = subscriptionKeys.filter((k) => !k.is_ci);

  const isActive = [SubscriptionStatus.ACTIVE, SubscriptionStatus.TRIALING, SubscriptionStatus.PAST_DUE].includes(
    subscription?.status as SubscriptionStatus,
  );

  const isCancelling = subscription?.cancel_at || subscription?.cancel_at_period_end;
  const isTrial = subscription?.plan?.family === PlanFamily.TRIAL_PLANS;

  // note trials are always in "cancelling" state
  const isCancellable =
    subscription?.plan?.family &&
    can(Permission.DELETE_SUBSCRIPTIONS) &&
    subscription.acquisition_channel !== AcquisitionChannel.SALES &&
    CANCELABLE_PLAN_FAMILIES.includes(subscription.plan.family) &&
    isActive &&
    (!isCancelling || (isCancelling && isTrial));
  const isUpdateable =
    subscription?.plan?.family &&
    can(Permission.UPDATE_SUBSCRIPTIONS) &&
    UPDATEABLE_PLAN_FAMILIES.includes(subscription.plan.family) &&
    subscription.acquisition_channel !== AcquisitionChannel.SALES &&
    isActive &&
    (!isCancelling || (isCancelling && isTrial));

  const { cancelSubscription, isLoading: isSubOrKeyMutating } = useApiEffect(
    SubscriptionService,
    ['cancelSubscription'],
    { revalidate: ['listKeys', 'listSubscriptions', 'getSubscription'] },
  );

  useEffect(() => {
    if (subscriptionKeys && !selectedKey) setSelectedKey(subscriptionKeys[0]);
  }, [keys]);

  return (
    <ContainedCustomerLayout title="Subscription Details">
      <SubscriptionCancellationModal
        open={showCancelModal}
        loading={isSubOrKeyMutating}
        subscription={subscription}
        onClose={() => setShowCancelModal(false)}
        onCancel={async (id, details) => {
          await cancelSubscription(id, details);
          setShowCancelModal(false);

          // trial subs are cancelled immediately
          if (subscription?.plan?.family === PlanFamily.TRIAL_PLANS) {
            goto(AppRoute.SETTINGS_BILLING);
          }
        }}
      />
      <Grid container spacing={3}>
        <Grid item md={12}>
          <Card>
            <CardHeader title="Subscription Details" />
            {!subscription && (
              <>
                <SubscriptionSummarySkeleton />
                <CardContent>
                  <LoadingFragment loading height={165} variant="card" />
                </CardContent>
              </>
            )}
            {subscription && (
              <>
                <SubscriptionSummary
                  subscription={subscription}
                  usage={usage}
                  orgKeys={nonCIKeys}
                  licenseAssignments={licenseAssignments ?? []}
                  members={userInfo?.org?.members ?? []}
                  computeUsage={computeUsage}
                />
                <CardContent>
                  {subscription?.plan?.family === PlanFamily.MARKETPLACE_PLANS && (
                    <Alert severity="warning" variant="outlined">
                      This subscription is managed by AWS Marketplace. For more details on pricing and billing
                      estimations, as well as upgrades, downgrades and cancellation please refer to AWS Marketplace.
                    </Alert>
                  )}

                  {subscription.plan.family === PlanFamily.PRO_PLANS && (
                    <ProStarterRenameInfo activeSubscriptions={[subscription]} mb={2} />
                  )}

                  {(isUpdateable || isCancellable) && (
                    <DangerZone>
                      <DangerZoneActions>
                        {isUpdateable && (
                          <DangerZoneAction
                            primaryText="Update Subscription"
                            secondaryText="Upgrade or downgrade Subscription"
                            actionText="Update"
                            onClick={() => goto(AppRoute.SETTINGS_SUBSCRIPTION_UPDATE, { subscriptionId })}
                          />
                        )}
                        {isCancellable && (
                          <DangerZoneAction
                            primaryText="Cancel Subscription"
                            secondaryText="Cancel this Subscription by the End of Prepaid Period"
                            actionText="Cancel"
                            onClick={() => setShowCancelModal(true)}
                          />
                        )}
                      </DangerZoneActions>
                    </DangerZone>
                  )}
                </CardContent>
              </>
            )}
          </Card>
        </Grid>
      </Grid>
    </ContainedCustomerLayout>
  );
};
