import { ReactElement } from 'react';
import { useParams } from 'react-router-dom';

import { Card, CardContent, CardHeader, Grid, Link } from '@mui/material';

import { CiService, formatDateTime, useApiGetter, useRoutes } from '@localstack/services';

import { DataGrid, GridColDef, LoadingFragment, PageTitle } from '@localstack/ui';

import { CIRun, PlanFamily } from '@localstack/types';

import { ContainedCustomerLayout } from '~/layouts';
import { AppRoute } from '~/config/routes';

import { ProjectDetailNavTabs } from './components/ProjectDetailNavTabs';

export const CIProjectDetails = (): ReactElement => {
  const { goto } = useRoutes();

  const { project: projectName } = useParams<'project'>();
  const { data: project, isLoading } = useApiGetter(CiService, 'getCiProject', [projectName]);

  const buildColumns = (): GridColDef[] => [
    {
      field: 'run_name',
      headerName: 'Run ID',
      flex: 0.1,
      renderCell: (props) => (
        <Link
          onClick={() => goto(AppRoute.CI_PROJECT_RUN, { project: projectName, run: props.row.run_name })}
          underline="hover"
        >
          {props.row.run_name}
        </Link>
      ),
    },
    {
      field: 'start_time',
      headerName: 'Time',
      flex: 0.1,
      renderCell: (props) => (props.row.start_time && formatDateTime(props.row.start_time)) || 'n/a',
    },
    {
      field: 'stack_id',
      headerName: 'Stack Session',
      flex: 0.2,
      renderCell: (props) => (
        <Link onClick={() => goto(AppRoute.STACKS_STACK, { stackId: props.row.stack_id })} underline="hover">
          {props.row.stack_id}
        </Link>
      ),
    },
    {
      field: 'pod',
      headerName: 'Cloud Pod',
      flex: 0.2,
      renderCell: (props) => (
        <Link
          onClick={() => {
            const parts = props.row.pod.split(':');
            const version = parts.length > 1 ? parts.slice(-1) : '';
            return goto(AppRoute.POD, { name: parts[0] }, `version=${version}`);
          }}
          underline="hover"
        >
          {props.row.pod}
        </Link>
      ),
    },
  ];
  const runsDescending = (project?.runs ?? []).concat().sort((r1, r2) => r2.start_time - r1.start_time);

  return (
    <ContainedCustomerLayout
      title={
        <PageTitle
          title="CI Project Details"
          breadcrumbs={[
            ['CI Projects', () => goto(AppRoute.CI_PROJECTS)],
            [projectName, () => goto(AppRoute.CI_PROJECT, { project: projectName })],
          ]}
          planFamily={PlanFamily.ENTERPRISE_PLANS}
        />
      }
      tabs={<ProjectDetailNavTabs projectName={projectName ?? ''} />}
      planFamily={PlanFamily.ENTERPRISE_PLANS}
    >
      <Grid container spacing={3}>
        <Grid item md={12} sm={12}>
          <Card>
            <CardHeader title="CI Project Runs" />
            <CardContent>
              <LoadingFragment loading={isLoading} arrayData={runsDescending} variant="card" height={300}>
                <DataGrid
                  autoHeight
                  rows={runsDescending}
                  columns={buildColumns()}
                  getRowId={(row: CIRun) => row.run_name as string}
                  disableSelectionOnClick
                />
              </LoadingFragment>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </ContainedCustomerLayout>
  );
};
