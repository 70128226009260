import dagre from '@dagrejs/dagre';
import { Node, Edge } from '@xyflow/react';

import { getLocalPosition } from './graphUtils';

export const convertGraphToFlowNodesAndEdges = (graph: dagre.graphlib.Graph): [Node[], Edge[]] => {
  const flowNodes: Node[] = [];
  const flowEdges: Edge[] = [];

  graph.nodes().forEach((nodeId) => {
    const node = graph.node(nodeId);

    const parentId = graph.parent(nodeId);
    const localPosition = getLocalPosition(nodeId, graph);

    flowNodes.push({
      id: nodeId,
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-expect-error
      data: { ...node.data, label: nodeId },
      style: { width: node.width, height: node.height },
      position: localPosition,
      parentId,
      type: node.class,
      extent: parentId ? 'parent' : undefined,
    });
  });

  graph.edges().forEach((edge) => {
    flowEdges.push({ id: `${edge.v}-${edge.w}`, source: edge.v, target: edge.w });
  });

  return [flowNodes, flowEdges];
};
