import { Link } from '@mui/material';

import { DataGrid, GridColDef } from '../../../display/DataGrid/index';

export interface SESIdentitiesTableProps {
  selectable?: boolean;
  rows: string[];
  onView?: (name: string) => void;
  onSelect?: (names: string[]) => void;
}

const buildColumns = (onView: SESIdentitiesTableProps['onView']): GridColDef[] => [
  {
    field: 'Email',
    headerName: 'Email',
    width: 300,
    renderCell: (params) => (
      <Link onClick={() => onView && onView(params.value as string)} underline="hover">
        {params.value}
      </Link>
    ),
  },
];
export const SESIdentitiesTable: React.FC<SESIdentitiesTableProps> = ({
  rows,
  onSelect,
  onView,
  selectable = true,
}) => (
  <DataGrid
    autoHeight
    getRowId={(row) => row.Email}
    onSelectionModelChange={(names) => onSelect && onSelect(names as string[])}
    rows={rows.map((row) => ({ Email: row }))}
    columns={buildColumns(onView)}
    checkboxSelection={selectable}
  />
);
