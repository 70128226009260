import { createStyles, makeStyles } from '@mui/styles';
import { ReactElement } from 'react';
import { Box, Theme } from '@mui/material';

import { BaseHeader } from '@localstack/ui';
import { BaseHeaderProps } from '@localstack/types';

import { Searchbar } from '~/components/Searchbar';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    regionDivider: {
      borderLeft: 'solid 1px',
      width: 0,
      height: '2em',
      borderColor: theme.palette.divider,
    },
  }),
);

export type AdminHeaderProps = BaseHeaderProps;

export const AdminHeader = ({ actions, ...rest }: AdminHeaderProps): ReactElement => {
  const classes = useStyles();
  return (
    <BaseHeader
      {...rest}
      actions={
        <>
          {actions}
          <div className={classes.regionDivider} />
          <Box ml={3} flexGrow={1}>
            <Searchbar />
          </Box>
        </>
      }
    />
  );
};
