import { ReactElement } from 'react';
import { CloudTrailEvent } from '@localstack/types';
import { Link } from '@mui/material';
import { getSchema } from '@localstack/services';

import { MagicTable } from '../../../magic/MagicTable';

export type CloudTrailEventsTableProps = {
  loading?: boolean;
  entries: CloudTrailEvent[];
  onViewCloudTrailEvent?: (event: CloudTrailEvent) => void;
};

export const CloudTrailEventsTable = ({
  entries,
  loading,
  onViewCloudTrailEvent,
}: CloudTrailEventsTableProps): ReactElement => (
  <MagicTable
    schema={getSchema('CloudTrail')}
    entry="EventsList"
    idAttribute="EventId"
    rows={entries}
    loading={loading}
    filterColumns={['EventId', 'EventSource', 'EventTime', 'Username']}
    order={['EventId', 'EventTime', 'EventSource', 'Username']}
    externalFields={{
      EventId: (row) => (
        <Link onClick={() => onViewCloudTrailEvent && onViewCloudTrailEvent(row)} underline="hover">
          {row.EventId}
        </Link>
      ),
    }}
  />
);
