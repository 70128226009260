import { DEFAULT_DOCDB_ROUTES } from '@localstack/constants';
import { ReactElement } from 'react';
import { CreateDBClusterMessage, ModifyDBClusterMessage } from '@localstack/types';
import { Breadcrumbs, DocDBClusterForm, ProgressButton } from '@localstack/ui';
import { useAwsEffect, useAwsGetter, useRoutes } from '@localstack/services';
import { Box, Card, CardActions, CardContent, Typography } from '@mui/material';

import { useParams } from 'react-router-dom';

import { DocDBProps } from './types';

export const DocDBClusterUpsert = ({
  Layout,
  clientOverrides,
  routes = DEFAULT_DOCDB_ROUTES,
}: DocDBProps): ReactElement => {
  const { goto } = useRoutes();
  const { id } = useParams<'id'>();
  const { data: clusterData } = useAwsGetter('DocDB', 'describeDBClusters', [{ DBClusterIdentifier: id }], {
    clientOverrides,
  });
  const { createDBCluster, modifyDBCluster, isLoading } = useAwsEffect(
    'DocDB',
    ['createDBCluster', 'modifyDBCluster'],
    {
      clientOverrides,
      revalidate: ['describeDBClusters'],
    },
  );
  const handleCreate = async (data: CreateDBClusterMessage) => {
    if (await createDBCluster(data)) {
      goto(routes.RESOURCES_DOCDB_CLUSTERS);
    }
  };
  const handleUpdate = async (data: ModifyDBClusterMessage) => {
    if (await modifyDBCluster(data)) {
      goto(routes.RESOURCES_DOCDB_CLUSTER, { id });
    }
  };
  const cluster = clusterData?.DBClusters?.filter((c) => c.DBClusterIdentifier === id)[0];
  return (
    <Layout
      title={
        <Box>
          <Typography variant="h4">Cluster Details</Typography>
          <Breadcrumbs
            mappings={[
              ['DocDB', () => goto(routes.RESOURCES_DOCDB_CLUSTERS)],
              [id, () => goto(routes.RESOURCES_DOCDB_CLUSTER, { id })],
              [cluster ? 'Update Cluster' : 'Create Cluster', null],
            ]}
          />
        </Box>
      }
      actions={
        <ProgressButton color="primary" variant="outlined" type="submit" form="DocDBClusterUpsert" loading={isLoading}>
          Submit
        </ProgressButton>
      }
    >
      <Card>
        <CardContent>
          <DocDBClusterForm
            formId="DocDBClusterUpsert"
            onSubmit={cluster ? handleUpdate : handleCreate}
            cluster={cluster}
            loading={isLoading}
          />
        </CardContent>
        <CardActions>
          <ProgressButton
            color="primary"
            variant="outlined"
            type="submit"
            form="DocDBClusterUpsert"
            loading={isLoading}
          >
            Submit
          </ProgressButton>
        </CardActions>
      </Card>
    </Layout>
  );
};
