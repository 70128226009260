import { ReactElement } from 'react';

import { ComplexLayoutProps } from '@localstack/types';
import { BaseHeader } from '@localstack/ui';

import { LayoutContainer } from '../LayoutContainer';

import { CustomerLayout, CustomerLayoutProps } from './CustomerLayout';

type ContainedCustomerLayoutProps = ComplexLayoutProps & CustomerLayoutProps;

export const ContainedCustomerLayout = ({
  children,
  title,
  planFamily,
  actions,
  maxWidth,
  fullScreen,
  tabs,
  ...rest
}: ContainedCustomerLayoutProps): ReactElement => (
  <CustomerLayout {...rest} title={title}>
    <BaseHeader
      actions={actions}
      title={title}
      planFamily={planFamily}
      maxWidth={maxWidth}
      fullScreen={fullScreen}
      tabs={tabs}
    />
    <LayoutContainer fullScreen={fullScreen} maxWidth={maxWidth}>
      {children}
    </LayoutContainer>
  </CustomerLayout>
);
