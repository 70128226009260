import { ReactElement } from 'react';
import { Controller, Control, ControllerProps } from 'react-hook-form';
import { Grid, Switch, Typography } from '@mui/material';
import { FormControlLabelProps } from '@mui/material/FormControlLabel/FormControlLabel';

export type ControlledSwitchProps = Omit<FormControlLabelProps, 'control'> &
  Omit<ControllerProps, 'control' | 'render'> & {
    control: Control<any>; // eslint-disable-line
    leftLabel?: string;
  };

export const ControlledSwitch = ({ control, leftLabel, ...rest }: ControlledSwitchProps): ReactElement => (
  <Controller
    {...rest}
    control={control}
    render={({ field }) => (
      <Grid container alignItems="center">
        {leftLabel && (
          <Grid item>
            <Typography variant="body1">{leftLabel}</Typography>
          </Grid>
        )}
        <Grid item>
          <Switch {...field} checked={field.value || false} color="primary" />
        </Grid>
        <Grid item>
          <Typography variant="body1">{rest.label}</Typography>
        </Grid>
      </Grid>
    )}
  />
);

export default ControlledSwitch;
