import { ReactElement } from 'react';
import { AwsClientOverrides, getSchema } from '@localstack/services';
import { TextField } from '@mui/material';

import { AgwApiStage, CreateAgwApiStageRequest, UpdateAgwApiStageRequest } from '@localstack/types';

import { MagicForm } from '../../../magic/MagicForm';
import { RelatedResourcePicker } from '../../../../form';

export interface AgwApiStageFormProps {
  apiId: string;
  stage?: Optional<Partial<AgwApiStage>>;
  formId?: string;
  loading?: boolean;
  clientOverrides?: AwsClientOverrides;
  onCreate: (data: CreateAgwApiStageRequest) => unknown;
  onUpdate: (data: UpdateAgwApiStageRequest) => unknown;
}

export const AgwApiStageForm = ({
  apiId,
  stage,
  loading,
  formId,
  clientOverrides,
  onCreate,
  onUpdate,
}: AgwApiStageFormProps): ReactElement => (
  <MagicForm
    data={stage}
    schema={getSchema('ApiGatewayV2')}
    loading={loading}
    entry="CreateStageRequest"
    formId={formId}
    externalFields={{
      '^ApiId$': () => <TextField fullWidth disabled value={apiId} variant="outlined" />,
      '^DeploymentId$': (control, name, required) => (
        <RelatedResourcePicker
          required={required}
          control={control}
          fieldName={name}
          entityName="Deployment"
          client="ApiGatewayV2"
          method="getDeployments"
          arrayKeyName="Items"
          property="DeploymentId"
          clientOverrides={clientOverrides}
          args={[{ ApiId: apiId }]}
        />
      ),
    }}
    onSubmit={(data: CreateAgwApiStageRequest) => {
      if (!stage) return onCreate({ ...data, ApiId: apiId });
      return onUpdate({ ...data, ApiId: apiId, StageName: stage.StageName as string });
    }}
  />
);
