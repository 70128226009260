import { INSTANCE_ROUTES } from '@localstack/constants';

export const AppRoute = {
  HOME: '/',
  DASHBOARD: '/dashboard',
  GETTING_STARTED: '/getting-started',
  SIGN_IN: '/sign-in',
  SIGN_OUT: '/sign-out',
  GLOBAL_SIGN_OUT: '/global-sign-out',
  SIGN_UP: '/sign-up',
  ACCEPT_TOS: '/accept-tos',

  SETTINGS_PROFILE: '/settings/profile',
  SETTINGS_AUTHENTICATION: '/settings/authentication',

  SETTINGS_WORKSPACE: '/settings/workspace',
  SETTINGS_MEMBERS: '/settings/members',
  SETTINGS_KEYS: '/settings/api-keys',
  SETTINGS_LEGACY_CI_KEYS: '/settings/legacy-ci-keys',
  SETTINGS_BILLING: '/settings/billing',
  SETTINGS_SUBSCRIPTIONS: '/settings/subscriptions',
  SETTINGS_SUBSCRIPTION_DETAIL: '/settings/subscriptions/:subscriptionId',
  SETTINGS_SUBSCRIPTION_UPDATE: '/settings/subscriptions/:subscriptionId/update',
  SETTINGS_AUTH_TOKEN: '/settings/auth-token',
  SETTINGS_AUTH_TOKENS: '/settings/auth-tokens',
  SETTINGS_MY_LICENSE: '/settings/my-license',
  SETTINGS_SSO: '/settings/sso',

  PRICING: '/pricing',
  PRICING_SUBSCRIBE: '/pricing/subscribe',

  QUICKSTART: '/quickstart',
  QUICKSTARTCLOUDFORMATION: '/quickstart-cloudformation',
  QUICKSTART_DEMO1: '/quickstart/demo1',
  QUICKSTART_DEMO2: '/quickstart/demo2',
  QUICKSTART_DEMO3: '/quickstart/demo3',
  QUICKSTART_DEMO4: '/quickstart/demo4',
  QUICKSTART_DEMO5: '/quickstart/demo5',
  QUICKSTART_SERVERLESS_QUIZ_APP: '/quickstart/serverless-quiz-app',

  RECOVER: '/recover',
  RECOVER_ACTIVATE: '/recover/:userId/:key',
  UNSUBSCRIBE_ACTIVATE: '/unsubscribe/:key',
  MEMBERSHIP_ACTIVATE: '/confirm/:org/:key',
  ACCOUNT_ACTIVATE: '/activate/:key',
  LAUNCHPAD: '/launchpad',
  NOTIFICATIONS: '/notifications',

  MARKETPLACE_ACTIVATE: '/auth/marketplace/:token',
  AUTH_REQUEST_ACTIVATE: '/auth/request/:requestId',
  SSO_CALLBACK: '/auth/sso/callback',
  SSO_START: '/auth/sso/:orgId/:idpName',
  SSO_LOADING: '/auth/sso/loading',

  PODS: '/pods',
  PODS_PUBLIC: '/pods/public',
  POD: '/pod/:name',
  POD_DIFF: '/pod/:name/diff',
  POD_LOAD: '/pod/:name/:version/load',

  EXTENSIONS_LIBRARY: '/extensions/library',
  EXTENSIONS_REMOTE: '/extensions/remote',
  EXTENSIONS_SUBMIT: '/extensions/submit',

  DESKTOP_DOWNLOAD: '/download',

  INSTANCES: '/instances',
  INSTANCES_EPHEMERAL: '/instances/ephemeral',

  CI_PROJECTS: '/ci',
  CI_PROJECT_CREATE: '/ci-create',
  CI_PROJECT: '/ci/:project',
  CI_PROJECT_SETTINGS: '/ci/:project/settings',
  CI_PROJECT_RUN: '/ci/:project/:run',

  ...INSTANCE_ROUTES,

  // admin panel
  ADMIN_HOME: '/admin',
  ADMIN_ACCOUNTS: '/admin/accounts',
  ADMIN_ACCOUNT: '/admin/accounts/:id',
  ADMIN_CREATE_ACCOUNT: '/admin/accounts/new',
  ADMIN_ORGANIZATIONS: '/admin/organizations',
  ADMIN_ORGANIZATION: '/admin/organizations/:id',
  ADMIN_ORGANIZATION_SUBSCRIBE: '/admin/organizations/:orgId/subscribe',
  ADMIN_ORGANIZATION_SUBSCRIPTION: '/admin/organizations/:orgId/subscriptions/:subscriptionId',
  ADMIN_ORGANIZATION_SUBSCRIPTION_UPDATE: '/admin/organizations/:orgId/subscriptions/:subscriptionId/update',
  ADMIN_EXTENSIONS: '/admin/extensions',
  ADMIN_EXTENSION: '/admin/extension/:id',
  ADMIN_COMMON: '/admin/*',
  ADMIN_ANNOUNCEMENT: '/admin/announcement/new',

  // stacks insights
  STACKS_STACK: '/stacks/:stackId',
  STACKS_OVERVIEW: '/stacks',

  NOT_FOUND: '/not-found',
  SESSION_EXPIRED: '/session-expired',
};

export const externalURLs = {
  documentation: {
    root: 'https://docs.localstack.cloud',
    gettingStarted: {
      installation: 'https://docs.localstack.cloud/getting-started/installation/',
      api_key: 'https://docs.localstack.cloud/getting-started/api-key/',
    },
  },
  developerHub: {
    root: 'https://docs.localstack.cloud/developer-hub/',
  },
};
