import { ReactElement } from 'react';
import { useRoutes, useAwsGetter } from '@localstack/services';
import { useParams } from 'react-router-dom';
import { Card, CardContent, Box, Typography } from '@mui/material';
import { Breadcrumbs, ECSTaskDefinitionDetails } from '@localstack/ui';

import { DEFAULT_ECS_ROUTES } from '@localstack/constants';

import { ECSProps } from './types';

import { MainNavTabs } from './components';

export const ECSTaskDefinition = ({ Layout, clientOverrides, routes = DEFAULT_ECS_ROUTES }: ECSProps): ReactElement => {
  const { goto } = useRoutes();
  const { family } = useParams<'family'>();

  const { data: taskDefinition } = useAwsGetter('ECS', 'describeTaskDefinition', [{ taskDefinition: family }], {
    clientOverrides,
  });

  return (
    <Layout
      documentTitle="Task Definition Details"
      tabs={<MainNavTabs routes={routes} />}
      title={
        <Box>
          <Typography variant="h4">Task Definition Details</Typography>
          <Breadcrumbs
            mappings={[
              ['ECS', () => goto(routes.RESOURCES_ECS)],
              ['Task Definition', () => goto(routes.RESOURCES_ECS_TASK_DEFINITIONS)],
              [family, null],
            ]}
          />
        </Box>
      }
    >
      <Card>
        <CardContent>
          <ECSTaskDefinitionDetails definition={taskDefinition?.taskDefinition} />
        </CardContent>
      </Card>
    </Layout>
  );
};
