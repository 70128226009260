import { ReactElement } from 'react';
import { BackupPlan, BackupVault, CreateBackupPlanRequest, UpdateBackupPlanRequest } from '@localstack/types';
import { MenuItem } from '@mui/material';
import { getSchema } from '@localstack/services';

import { ControlledSelect } from '../../../../form';
import { MagicForm } from '../../../magic/MagicForm';

export interface BackupPlanUpsertFormProps {
  loading?: boolean;
  formId?: string;
  vaultList: BackupVault[];
  plan?: BackupPlan;
  onCreate: (data: CreateBackupPlanRequest) => unknown;
  onUpdate: (data: UpdateBackupPlanRequest) => unknown;
}
export const BackupPlanUpsertForm = ({
  loading,
  formId,
  vaultList,
  plan,
  onCreate,
  onUpdate,
}: BackupPlanUpsertFormProps): ReactElement => (
  <MagicForm
    formId={formId}
    schema={getSchema('Backup')}
    entry={plan ? 'UpdateBackupPlanInput' : 'CreateBackupPlanInput'}
    loading={loading}
    data={plan}
    fieldConditions={{
      '^BackupPlanId$': !plan,
    }}
    externalFields={{
      '^BackupPlan\\.Rules\\.\\d+\\.TargetBackupVaultName$': (control, url) => (
        <ControlledSelect
          variant="outlined"
          control={control}
          fullWidth
          required
          label="Target Vault"
          name={url}
          options={[
            <MenuItem key="" value="">
              None
            </MenuItem>,
            ...vaultList.map((v) => (
              <MenuItem key={v.BackupVaultArn} value={v.BackupVaultName}>
                {v.BackupVaultName}
              </MenuItem>
            )),
          ]}
        />
      ),
    }}
    onSubmit={(data: CreateBackupPlanRequest | UpdateBackupPlanRequest) => {
      if (!plan) return onCreate(data as CreateBackupPlanRequest);
      return onUpdate({ ...data, BackupPlanId: plan.BackupPlanId as string } as UpdateBackupPlanRequest);
    }}
  />
);
