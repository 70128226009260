import { uniq } from 'lodash';
import { DynamoDB } from 'aws-sdk';
import { ReactElement, useMemo } from 'react';
import { Link } from '@mui/material';
import { extractDynamoDBPrimaryKey } from '@localstack/services';
import { DynamoDBTable, DynamoDBAttributeMap, DynamoDBAttributeValue } from '@localstack/types';

import { MagicTable } from '../../magic/MagicTable';

const TYPES_MAP: Record<keyof DynamoDBAttributeValue, string> = {
  S: 'string',
  N: 'double',
  B: 'string',
  SS: 'list',
  NS: 'list',
  BS: 'list',
  M: 'structure',
  L: 'list',
  NULL: 'string',
  BOOL: 'boolean',
};

const DEFAULT_SHAPES = {
  GenericString: { type: 'string' },
  GenericStructure: {
    type: 'structure',
    members: [],
  },
};

const DEFAULT_PAGE_SIZE = 50;
export const DYNAMODB_ITEMS_TABLE_RANGE_SEPARATOR = '--LS--';

export interface DynamoDBItemsTableProps {
  table: Optional<DynamoDBTable>;
  rows: DynamoDBAttributeMap[];
  loading?: boolean;
  selectable?: boolean;
  hasMore?: boolean;
  page?: number;
  pageSize?: number;
  // eslint-disable-next-line
  onViewRow?: (row: any, hashKey: string, rangeKey: Optional<string>) => unknown;
  onPageChange?: (page: number) => unknown;
  onSelect?: (ids: string[]) => unknown;
}

export const DynamoDBItemsTable = ({
  table,
  rows,
  loading,
  selectable,
  hasMore,
  page,
  pageSize = DEFAULT_PAGE_SIZE,
  onViewRow,
  onPageChange,
  onSelect,
}: DynamoDBItemsTableProps): ReactElement => {
  const { hashKey, rangeKey } = extractDynamoDBPrimaryKey(table);

  // Usually there should be a hash key that works as item identifier
  const idFieldName = useMemo(() => hashKey?.AttributeName ?? 'id', [table]);
  const rangeFieldName = useMemo(() => rangeKey?.AttributeName ?? 'id', [table]);

  const fieldsAndTypes = useMemo(
    () =>
      uniq(
        rows.reduce(
          (memo, row) => [
            ...memo,
            ...Object.entries(row).map(([name, opt]) => {
              const dynamoDBType = Object.keys(opt)[0] as keyof DynamoDBAttributeValue;
              const resolvedType = TYPES_MAP[dynamoDBType];

              // treat sets as lists of strings
              if (['SS', 'NS', 'BS'].includes(dynamoDBType)) {
                return `${name}:${resolvedType}:GenericString`;
              }

              // handle list as list of structures
              if (dynamoDBType === 'L') {
                return `${name}:${resolvedType}:GenericStructure`;
              }

              return `${name}:${resolvedType}`;
            }),
          ],
          [],
        ),
      ),
    [rows],
  );

  const schema = useMemo(
    () => ({
      shapes: {
        ...DEFAULT_SHAPES,
        AbstractItem: {
          type: 'structure',
          members: Object.fromEntries(
            fieldsAndTypes.map((joinedField) => {
              const [name, type, shape] = joinedField.split(':');
              if (shape) return [name, { type, member: { shape } }];
              return [name, { type, shape }];
            }),
          ),
        },
      },
    }),
    [fieldsAndTypes],
  );

  // remove type information and convert rows to pure json
  const unmarshalledRows = useMemo(
    () =>
      rows.map((row) => {
        const unmarshalledRow = DynamoDB.Converter.unmarshall(row);
        // If a column is an object, stringify it
        Object.entries(unmarshalledRow).forEach(([k, v]) => {
          if (v && typeof v === 'object') {
            try {
              const text = JSON.stringify(v);
              unmarshalledRow[k] = text.length > 45 ? `${text.slice(0, 45)}...` : text;
              // eslint-disable-next-line no-empty
            } catch (_e) {}
          }
        });
        // trick to make rows unique, as there may be multiple rows with the same hash key,
        // it also helps to pop up range key information to parent components
        return {
          ...unmarshalledRow,
          [idFieldName]: `${unmarshalledRow[idFieldName]}${DYNAMODB_ITEMS_TABLE_RANGE_SEPARATOR}${
            unmarshalledRow[rangeFieldName] ?? ''
          }`,
        };
      }),
    [rows, idFieldName, rangeFieldName],
  );

  const tablePageSize = pageSize || DEFAULT_PAGE_SIZE;

  return (
    <MagicTable
      pagination
      // For things like PartiQL we can not limit results,
      // that means these apis may be loading huge data sets exceeding our `pageSize`,
      // in this case we want to let DataGrid handle pagination
      paginationMode={hasMore ? 'server' : 'client'}
      page={page}
      pageSize={tablePageSize}
      rowCount={hasMore ? ((page ?? 0) + 1) * tablePageSize + 1 : rows.length}
      onPageChange={onPageChange}
      rowsPerPageOptions={[tablePageSize]}
      schema={schema}
      loading={loading}
      entry="AbstractItem"
      rows={unmarshalledRows}
      idAttribute={idFieldName}
      order={[idFieldName]}
      formatNames={false}
      selectable={selectable}
      onSelect={onSelect}
      externalFields={{
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        [idFieldName]: (row: any) => {
          const [hashKeyVal, rangeKeyVal] = row[idFieldName].split(DYNAMODB_ITEMS_TABLE_RANGE_SEPARATOR);
          return (
            <Link
              onClick={() => onViewRow && onViewRow({ ...row, [idFieldName]: hashKeyVal }, hashKeyVal, rangeKeyVal)}
              underline="hover"
            >
              {hashKeyVal}
            </Link>
          );
        },
      }}
    />
  );
};
