import { ReactElement, useCallback, useState } from 'react';
import { useRoutes, useAwsGetter, useAwsEffect } from '@localstack/services';
import { Button, Card, MenuItem } from '@mui/material';
import {
  Dropdown,
  ConfirmableButton,
  PageTitle,
  GlueSchemaVersionsTable,
  DiffViewer,
  ContentModal,
} from '@localstack/ui';
import { DEFAULT_GLUE_ROUTES } from '@localstack/constants';
import { GlueSchemaDefinitionItem } from '@localstack/types';
import { useParams } from 'react-router-dom';

import { GlueProps } from './types';
import { SchemaTabs } from './components';

export const GlueSchemaVersions = ({
  Layout,
  clientOverrides,
  routes = DEFAULT_GLUE_ROUTES,
}: GlueProps): ReactElement => {
  const [selected, setSelected] = useState<string[]>([]);
  const [diffVersions, setDiffVersions] = useState<GlueSchemaDefinitionItem[]>([]);

  const { goto } = useRoutes();
  const { schema, registry } = useParams<'schema' | 'registry'>();

  const {
    data: schemaVersions,
    isLoading,
    mutate,
  } = useAwsGetter('Glue', 'listSchemaVersions', [{ SchemaId: { SchemaName: schema, RegistryName: registry } }], {
    clientOverrides,
  });

  const { deleteSchemaVersions, getSchemaVersion } = useAwsEffect(
    'Glue',
    ['deleteSchemaVersions', 'getSchemaVersion'],
    { revalidate: ['listSchemaVersions'], clientOverrides },
  );

  const handleDeleteSelected = useCallback(
    async () =>
      Promise.all(
        selected.map((VersionNumber) =>
          deleteSchemaVersions({
            SchemaId: { SchemaName: schema, RegistryName: registry },
            Versions: VersionNumber.toString(),
          }),
        ),
      ),
    [selected],
  );

  return (
    <Layout
      documentTitle="Glue: Schema Versions"
      title={
        <PageTitle
          title="Glue Schema Versions"
          breadcrumbs={[
            ['Glue', () => goto(routes.RESOURCES_GLUE_DATABASES)],
            ['Schemas', () => goto(routes.RESOURCES_GLUE_SCHEMAS)],
            [schema, () => goto(routes.RESOURCES_GLUE_SCHEMA, { schema, registry })],
            ['Versions', null],
          ]}
          onMutate={mutate}
        />
      }
      tabs={<SchemaTabs routes={routes} schema={schema ?? ''} registry={registry ?? ''} />}
      actions={
        <>
          <Button
            onClick={() =>
              goto(routes.RESOURCES_GLUE_SCHEMA_VERSION_CREATE, {
                schema,
                registry,
                latestVersion: schemaVersions?.Schemas?.slice(-1)[0]?.VersionNumber || 1,
              })
            }
          >
            Register New Version
          </Button>
          <Dropdown label="Actions">
            <ConfirmableButton
              componentType="MenuItem"
              disabled={selected.length === 0 || isLoading}
              title={`Delete ${selected.length} Schema(s)?`}
              onClick={handleDeleteSelected}
              text="Selected Schema(s) will be permanently deleted"
            >
              Remove Selected
            </ConfirmableButton>
            <MenuItem
              disabled={selected.length !== 2 || isLoading}
              onClick={async () => {
                setDiffVersions(
                  await Promise.all(
                    selected.map((version) =>
                      getSchemaVersion({
                        SchemaId: { SchemaName: schema, RegistryName: registry },
                        SchemaVersionNumber: { VersionNumber: Number(version ?? 0) },
                      }),
                    ),
                  ),
                );
              }}
            >
              Compare Versions
            </MenuItem>
          </Dropdown>
        </>
      }
    >
      <Card>
        <GlueSchemaVersionsTable
          schemaVersions={schemaVersions?.Schemas ?? []}
          loading={isLoading}
          onSelect={setSelected}
          onViewSchemaVersion={(version) => goto(routes.RESOURCES_GLUE_SCHEMA_VERSION, { schema, version, registry })}
        />
      </Card>
      {!!diffVersions.length && (
        <ContentModal
          title="Schema Version Comparison"
          fullWidth
          maxWidth="md"
          open={!!diffVersions.length}
          onClose={() => setDiffVersions([])}
        >
          <DiffViewer
            previousObj={diffVersions[0]?.SchemaDefinition ?? ''}
            latestObj={diffVersions[1]?.SchemaDefinition ?? ''}
            leftTitle={`Version ${(diffVersions[0]?.VersionNumber || '').toString()}`}
            rightTitle={`Version ${(diffVersions[1]?.VersionNumber || '').toString()}`}
          />
        </ContentModal>
      )}
    </Layout>
  );
};
