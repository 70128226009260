import { ReactElement } from 'react';
import { NavLink } from '@localstack/ui';
import { buildRoute } from '@localstack/services';

import { DMSRoutes } from '../types';

type ReplicationTaskProps = {
  routes: DMSRoutes;
  replicationTaskId: string;
};

export const ReplicationTaskTabs = ({ routes, replicationTaskId }: ReplicationTaskProps): ReactElement => (
  <>
    <NavLink to={buildRoute(routes.RESOURCES_DMS_REPLICATION_TASK, { id: replicationTaskId })} end>
      Details
    </NavLink>
    <NavLink to={buildRoute(routes.RESOURCES_DMS_REPLICATION_TASK_TABLE_STATS, { id: replicationTaskId })} end>
      Table Statistics
    </NavLink>
  </>
);
