import { ChangeEvent, ReactElement } from 'react';
import useStateRef from 'react-usestateref';
import { LambdaUpdateFunctionCodeRequest } from '@localstack/types';
import {
  FormControl,
  FormLabel,
  RadioGroup,
  FormControlLabel,
  Radio,
  Box,
  Divider,
  Grid,
  TextField,
  Typography,
  Link,
} from '@mui/material';
import { DEFAULT_S3_ROUTES, LAMBDA_HOT_RELOAD_BUCKET_NAME } from '@localstack/constants';
import { AwsClientOverrides, getSchema } from '@localstack/services';

import { MagicForm } from '../../../magic/MagicForm';
import { RelatedResourcePicker, ControlledDropzone } from '../../../../form';

const CODE_SOURCE = {
  HOT_RELOAD: 'hot-reloading',
  S3: 's3',
  UPLOAD: 'upload',
};

const PACKAGE_TYPE = {
  ZIP: 'Zip',
  IMAGE: 'Image',
};

export type LambdaFunctionUpdateCodeFormProps = {
  loading?: boolean;
  formId: string;
  clientOverrides?: AwsClientOverrides;
  onSubmit: (data: LambdaUpdateFunctionCodeRequest) => unknown;
  lambdaFunction?: { name?: string; packageType?: string };
};

export const LambdaFunctionUpdateCodeForm = ({
  loading,
  formId,
  lambdaFunction,
  clientOverrides,
  onSubmit,
}: LambdaFunctionUpdateCodeFormProps): ReactElement => {
  const [codeSource, setCodeSource, codeSourceRef] = useStateRef(CODE_SOURCE.S3);
  const [selectedPackageType, , selectedPackageTypeRef] = useStateRef(
    lambdaFunction?.packageType === 'Zip' ? PACKAGE_TYPE.ZIP : PACKAGE_TYPE.IMAGE,
  );
  const [, setHotReloadPath, hotReloadPathRef] = useStateRef('');

  const submitHandler = async (data: LambdaUpdateFunctionCodeRequest) => {
    if (selectedPackageTypeRef.current === PACKAGE_TYPE.ZIP && codeSourceRef.current === CODE_SOURCE.UPLOAD) {
      const blobbedZip = data.ZipFile as Blob;
      const buffer = await blobbedZip.arrayBuffer();
      return onSubmit({ ...data, ZipFile: buffer });
    }
    if (selectedPackageTypeRef.current === PACKAGE_TYPE.ZIP && codeSourceRef.current === CODE_SOURCE.HOT_RELOAD) {
      return onSubmit({ ...data, S3Bucket: LAMBDA_HOT_RELOAD_BUCKET_NAME, S3Key: hotReloadPathRef.current });
    }
    return onSubmit(data);
  };

  return (
    <>
      <MagicForm
        schema={getSchema('Lambda')}
        formId={formId}
        entry="UpdateFunctionCodeRequest"
        loading={loading}
        onSubmit={(data: LambdaUpdateFunctionCodeRequest) => submitHandler(data)}
        data={{
          FunctionName: lambdaFunction?.name,
          Publish: true,
        }}
        fieldConditions={{
          '^ImageUri': selectedPackageType === PACKAGE_TYPE.IMAGE,
          '^ZipFile': selectedPackageType === PACKAGE_TYPE.ZIP && codeSource === CODE_SOURCE.UPLOAD,
          '^S3Bucket': selectedPackageType === PACKAGE_TYPE.ZIP && codeSource === CODE_SOURCE.S3,
          '^S3Key': selectedPackageType === PACKAGE_TYPE.ZIP && codeSource === CODE_SOURCE.S3,
          '^S3ObjectVersion': selectedPackageType === PACKAGE_TYPE.ZIP && codeSource === CODE_SOURCE.S3,
          '^DryRun$': false,
          '^RevisionId$': false,
          '^Architectures$': false,
        }}
        injectedFields={{
          '^FunctionName$':
            selectedPackageType === PACKAGE_TYPE.ZIP
              ? {
                  position: 'after',
                  element: (
                    <>
                      <Grid item xs={12}>
                        <Box>
                          <FormControl
                            variant="standard"
                            component="fieldset"
                            // eslint-disable-next-line
                            onChange={(e: ChangeEvent<HTMLFieldSetElement>) => setCodeSource((e.target as any).value)}
                          >
                            <FormLabel component="legend">Code Source</FormLabel>
                            <RadioGroup row value={codeSource} color="primary">
                              <FormControlLabel
                                value={CODE_SOURCE.S3}
                                control={<Radio color="primary" />}
                                label="S3 Bucket"
                              />
                              <FormControlLabel
                                value={CODE_SOURCE.UPLOAD}
                                control={<Radio color="primary" />}
                                label="Upload ZIP File"
                              />
                              <FormControlLabel
                                value={CODE_SOURCE.HOT_RELOAD}
                                control={<Radio color="primary" />}
                                label={
                                  <Box display="flex" alignItems="center" columnGap={1}>
                                    <span>Hot Reload</span> <Typography fontSize={20}>✨</Typography>
                                  </Box>
                                }
                              />
                            </RadioGroup>
                          </FormControl>
                        </Box>
                      </Grid>
                      <Grid item xs={12}>
                        <Divider variant="fullWidth" orientation="horizontal" />
                      </Grid>
                    </>
                  ),
                }
              : undefined,
          '^Publish$':
            selectedPackageType === PACKAGE_TYPE.ZIP && codeSource === CODE_SOURCE.HOT_RELOAD
              ? {
                  position: 'before',
                  element: (
                    <>
                      <Grid item md={6} sm={12}>
                        <Typography variant="subtitle1">Lambda function directory</Typography>
                        <Typography variant="caption">
                          Path to the directory with your Lambda functions for hot reloading code changes. <br />
                          <Link
                            href="https://docs.localstack.cloud/user-guide/lambda-tools/hot-reloading/"
                            underline="none"
                            fontWeight="bold"
                            target="_blank"
                          >
                            How does this work?
                          </Link>
                        </Typography>
                      </Grid>
                      <Grid item md={6} sm={12}>
                        <TextField
                          variant="outlined"
                          fullWidth
                          label="/path/to/lambda-functions-folder"
                          required
                          onChange={(e) => setHotReloadPath(e.target.value)}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <Divider variant="fullWidth" orientation="horizontal" />
                      </Grid>
                    </>
                  ),
                }
              : undefined,
        }}
        defaultValues={{
          Handler: 'handler.handler',
        }}
        externalFields={{
          '^FunctionName$': () => <TextField value={lambdaFunction?.name} disabled fullWidth variant="outlined" />,
          '^ZipFile$': (control, fieldName) => (
            <ControlledDropzone name={fieldName} control={control} rules={{ required: 'Zip File is required' }} />
          ),
          '^S3Bucket$': (control, fieldName, required) => (
            <RelatedResourcePicker
              control={control}
              client="S3"
              method="listBuckets"
              arrayKeyName="Buckets"
              property="Name"
              fieldName={fieldName}
              entityName="S3 Bucket"
              creationRoute={DEFAULT_S3_ROUTES.RESOURCES_S3_BUCKET_CREATE}
              required={required}
              clientOverrides={clientOverrides}
            />
          ),
        }}
      />
    </>
  );
};
