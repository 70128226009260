import {
  Box,
  Grid,
  Input,
  InputBaseComponentProps,
  Slider,
  SliderProps,
  SliderValueLabelProps,
  Tooltip,
  TooltipProps,
  Typography,
} from '@mui/material';
import { ReactElement, useMemo } from 'react';
import { Controller, Control, ControllerProps } from 'react-hook-form';

export type ControlledRangeSliderProps = SliderProps &
  Omit<ControllerProps, 'control' | 'render'> & {
    label: string;
    control: Control<any>; // eslint-disable-line
    inputProps?: InputBaseComponentProps | undefined;
    valueLabelTooltipProps?: Partial<TooltipProps>;
    sliderProps?: SliderProps | undefined;
    postfixElement?: JSX.Element;
    hideInputField?: boolean;
    mapValueOnChange?: (value: number | number[]) => void;
  };

type ValueLabelWithToolTipProps = SliderValueLabelProps & { tooltipProps?: Partial<TooltipProps> };

const DEFAULT_SLIDER_PROPS = {
  step: 1,
  min: 0,
  max: 100,
};

const DEFAULT_INPUT_PROPS = {
  type: 'number',
};

const ValueLabelComponent = (props: ValueLabelWithToolTipProps) => {
  const { children, open, value, tooltipProps } = props;

  return (
    <Tooltip open={open} enterTouchDelay={0} placement="top" title={value} {...tooltipProps}>
      {children}
    </Tooltip>
  );
};

export const ControlledRangeSlider = ({
  control,
  label,
  inputProps = DEFAULT_INPUT_PROPS,
  sliderProps = DEFAULT_SLIDER_PROPS,
  hideInputField,
  valueLabelTooltipProps,
  postfixElement,
  mapValueOnChange,
  ...rest
}: ControlledRangeSliderProps): ReactElement => {
  const MemoizedValueLabelComponent = useMemo(
    // eslint-disable-next-line react/display-name
    () => (props: SliderValueLabelProps) => <ValueLabelComponent {...props} tooltipProps={valueLabelTooltipProps} />,
    [],
  );
  return (
    <Controller
      {...rest}
      control={control}
      render={({ field }) => (
        <Box>
          <Typography gutterBottom>{label}</Typography>
          <Grid container spacing={2} alignItems="center">
            <Grid item xs>
              <Slider
                {...sliderProps}
                value={field.value ?? 0}
                onChange={(_, newValue) => {
                  field.onChange(newValue);
                  mapValueOnChange?.(newValue);
                }}
                valueLabelDisplay="auto"
                slots={{
                  valueLabel: MemoizedValueLabelComponent,
                }}
              />
            </Grid>
            {!hideInputField && (
              <Grid item>
                <Grid container alignItems="center" spacing={1} wrap="nowrap">
                  <Grid item>
                    <Input
                      value={field.value}
                      onChange={(event) => {
                        field.onChange(event.target.value === '' ? '' : Number(event.target.value));
                      }}
                      onBlur={() => {
                        const min = sliderProps?.min || DEFAULT_SLIDER_PROPS.min;
                        const max = sliderProps?.max || DEFAULT_SLIDER_PROPS.max;
                        if (field.value < min) {
                          field.onChange(min);
                        } else if (field.value > max) {
                          field.onChange(max);
                        }
                      }}
                      inputProps={inputProps}
                      disabled={inputProps.disabled}
                      margin="dense"
                    />
                  </Grid>
                  {postfixElement && <Grid item>{postfixElement}</Grid>}
                </Grid>
              </Grid>
            )}
          </Grid>
        </Box>
      )}
    />
  );
};

export default ControlledRangeSlider;
