import { ReactElement } from 'react';
import { useRoutes, useAwsGetter } from '@localstack/services';
import { useParams } from 'react-router-dom';
import { Card, CardContent, Button, Box, Typography } from '@mui/material';
import { Breadcrumbs, AgwRestApiStageDetails as Details } from '@localstack/ui';
import { DEFAULT_API_GATEWAY_ROUTES } from '@localstack/constants';

import { ApiGatewayProps } from './types';
import { RestApiNavTabs } from './components';

export const AgwRestApiStageDetails = ({
  Layout,
  clientOverrides,
  routes = DEFAULT_API_GATEWAY_ROUTES,
}: ApiGatewayProps): ReactElement => {
  const { goto } = useRoutes();
  const { restApiId, stageName } = useParams<'restApiId' | 'stageName'>();

  const { data: api } = useAwsGetter('APIGateway', 'getRestApi', [{ restApiId }], { clientOverrides });
  const { data: stage } = useAwsGetter('APIGateway', 'getStage', [{ restApiId, stageName }], { clientOverrides });

  return (
    <Layout
      documentTitle="Stage Details"
      tabs={<RestApiNavTabs restApiId={restApiId as string} routes={routes} />}
      title={
        <Box>
          <Typography variant="h4">Stage Details</Typography>
          <Breadcrumbs
            mappings={[
              ['API Gateway', () => goto(routes.RESOURCES_AGW1)],
              [api?.name, () => goto(routes.RESOURCES_AGW1_API, { restApiId })],
              [stage?.stageName, null],
            ]}
          />
        </Box>
      }
      actions={
        <Button onClick={() => goto(routes.RESOURCES_AGW1_API_STAGE_UPDATE, { restApiId, stageName })}>
          Edit Stage
        </Button>
      }
    >
      <Card>
        <CardContent>
          <Details stage={stage} />
        </CardContent>
      </Card>
    </Layout>
  );
};
