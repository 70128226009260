import { ReactElement } from 'react';
import { GlueSchemaVersionItem } from '@localstack/types';
import { getSchema } from '@localstack/services';

import { ListItem } from '@mui/material';

import { MagicDetails } from '../../../magic/MagicDetails';
import { CodeSnippetViewer } from '../../../../display/CodeSnippetViewer';

export interface GlueSchemaVersionDetailsProps {
  schemaVersion?: Optional<Partial<GlueSchemaVersionItem>>;
}

export const GlueSchemaVersionDetails = ({ schemaVersion }: GlueSchemaVersionDetailsProps): ReactElement => (
  <MagicDetails
    data={schemaVersion}
    schema={getSchema('Glue')}
    entry="GetSchemaVersionResponse"
    title="Schema Version Details"
    externalFields={{
      '^SchemaDefinition$': (data: string, fieldName: string) => (
        <ListItem key={fieldName}>
          <CodeSnippetViewer data={data} fieldName={fieldName} />
        </ListItem>
      ),
    }}
  />
);
