import { ReactElement } from 'react';
import { Link } from '@mui/material';
import { QLDBLedgerSummary } from '@localstack/types';
import { getSchema } from '@localstack/services';

import { MagicTable } from '../../magic/MagicTable';

export type QLDBLedgersTableProps = {
  selectable?: boolean;
  loading?: boolean;
  ledgers: QLDBLedgerSummary[];
  onSelect?: (names: string[]) => void;
  onViewLedger?: (ledger: QLDBLedgerSummary) => unknown;
};

export const QLDBLedgersTable = ({
  ledgers,
  selectable = true,
  loading,
  onSelect,
  onViewLedger,
}: QLDBLedgersTableProps): ReactElement => (
  <MagicTable
    schema={getSchema('QLDB')}
    entry="LedgerSummary"
    idAttribute="Name"
    rows={ledgers}
    selectable={selectable}
    onSelect={onSelect}
    order={['Name', 'CreationDateTime', 'State']}
    loading={loading}
    externalFields={{
      Name: (row) => (
        <Link onClick={() => onViewLedger && onViewLedger(row)} underline="hover">
          {row.Name}
        </Link>
      ),
    }}
  />
);
