import { useState, useCallback, ReactElement, useMemo } from 'react';
import { useRoutes, useAwsEffect, useAwsGetter } from '@localstack/services';
import { Card, Button } from '@mui/material';

import { Dropdown, ConfirmableButton, ElastiCacheClustersTable, PageTitle } from '@localstack/ui';

import { DEFAULT_ELASTICACHE_ROUTES } from '@localstack/constants/src';

import { ElastiCacheProps } from './types';

export const ElastiCacheClusters = ({
  Layout,
  clientOverrides,
  routes = DEFAULT_ELASTICACHE_ROUTES,
}: ElastiCacheProps): ReactElement => {
  const [selectedIds, setSelectedIds] = useState<string[]>([]);

  const { goto } = useRoutes();
  const {
    data: clusters,
    isLoading,
    mutate,
  } = useAwsGetter('ElastiCache', 'describeCacheClusters', [{ ShowCacheNodeInfo: true }], { clientOverrides });

  const { deleteCacheCluster } = useAwsEffect('ElastiCache', ['deleteCacheCluster'], {
    revalidate: ['describeCacheClusters'],
    clientOverrides,
  });

  const handleDeleteSelected = useCallback(async () => {
    const promises = selectedIds.map((CacheClusterId) => deleteCacheCluster({ CacheClusterId }));
    await Promise.all(promises);
  }, [selectedIds]);

  const updatedClusters = useMemo(
    () =>
      clusters?.CacheClusters?.map((cluster) => {
        if (!cluster.ConfigurationEndpoint && cluster.CacheNodes?.length === 1) {
          // Otherwise we have 2 different endpoints (R/W)
          return {
            ...cluster,
            ConfigurationEndpoint: cluster.CacheNodes?.at(0)?.Endpoint,
          };
        }
        return cluster;
      }),
    [clusters],
  );

  return (
    <Layout
      title={<PageTitle title="ElastiCache Clusters" onMutate={mutate} />}
      actions={
        <>
          <Button onClick={() => goto(routes.RESOURCES_ELASTICACHE_CLUSTER_CREATE)}>Create Cluster</Button>
          <Dropdown label="Actions">
            <ConfirmableButton
              componentType="MenuItem"
              disabled={selectedIds.length === 0 || isLoading}
              title={`Delete ${selectedIds.length} Cluster(s)?`}
              onClick={handleDeleteSelected}
              text="Selected Clusters will be permanently deleted"
            >
              Remove Selected
            </ConfirmableButton>
          </Dropdown>
        </>
      }
    >
      <Card>
        <ElastiCacheClustersTable
          selectable
          clusters={updatedClusters ?? []}
          loading={isLoading}
          onSelect={setSelectedIds}
          onViewCluster={({ CacheClusterId }) =>
            goto(routes.RESOURCES_ELASTICACHE_CLUSTER, { clusterId: CacheClusterId })
          }
        />
      </Card>
    </Layout>
  );
};
