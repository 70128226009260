import { ReactElement } from 'react';
import { CreateGlueJobRequest, GlueJob, UpdateGlueJobRequest } from '@localstack/types';
import { AwsClientOverrides, getSchema } from '@localstack/services';
import { DEFAULT_GLUE_ROUTES, DEFAULT_IAM_ROUTES } from '@localstack/constants';

import { MagicForm } from '../../../magic/MagicForm';
import { S3PathPicker } from '../../../../form';
import { RelatedResourcePicker } from '../../../../form/RelatedResourcePicker';

export type GlueJobFormProps = {
  job?: Optional<Partial<GlueJob>>;
  loading?: boolean;
  formId: string;
  clientOverrides?: AwsClientOverrides;
  onCreate: (data: CreateGlueJobRequest) => unknown;
  onUpdate: (data: UpdateGlueJobRequest) => unknown;
};

export const GlueJobForm = ({
  job,
  loading,
  formId,
  clientOverrides,
  onCreate,
  onUpdate,
}: GlueJobFormProps): ReactElement => (
  <MagicForm
    data={{ JobName: job?.Name ?? '', JobUpdate: job }}
    schema={getSchema('Glue')}
    formId={formId}
    entry={job ? 'UpdateJobRequest' : 'CreateJobRequest'}
    loading={loading}
    fieldConditions={{
      JobName: !job,
      CodeGenConfigurationNodes: false,
    }}
    externalFields={{
      '^Role$': (control, fieldName, required) => (
        <RelatedResourcePicker
          control={control}
          client="IAM"
          method="listRoles"
          arrayKeyName="Roles"
          property="Arn"
          fieldName={fieldName}
          entityName="IAM Role"
          creationRoute={DEFAULT_IAM_ROUTES.RESOURCES_IAM_ROLE_CREATE}
          required={required}
          clientOverrides={clientOverrides}
        />
      ),
      '^Connections.Connections\\..+': (control, fieldName, required) => (
        <RelatedResourcePicker
          control={control}
          client="Glue"
          method="getConnections"
          arrayKeyName="ConnectionList"
          property="Name"
          fieldName={fieldName}
          entityName="Glue Connection"
          creationRoute={DEFAULT_GLUE_ROUTES.RESOURCES_GLUE_CONNECTION_CREATE}
          required={required}
          clientOverrides={clientOverrides}
        />
      ),
      '^Command.ScriptLocation$': (control, fieldName, required) => (
        <S3PathPicker control={control} fieldName={fieldName} required={required} clientOverrides={clientOverrides} />
      ),
    }}
    onSubmit={(data: CreateGlueJobRequest | UpdateGlueJobRequest) => {
      if (!job) return onCreate(data as CreateGlueJobRequest);
      return onUpdate(data as UpdateGlueJobRequest);
    }}
  />
);
