import { ReactElement } from 'react';
import { ConfirmableButton, DangerZone, DangerZoneAction, DangerZoneActions, SplitButton } from '@localstack/ui';
import { AdminService, useApiEffect, useRoutes } from '@localstack/services';

import { AppRoute } from '~/config';

const AVAILABLE_CURRENCIES = ['USD', 'EUR'];

export interface ActionsProps {
  orgId: string;
}

export const Actions = ({ orgId }: ActionsProps): ReactElement => {
  const { goto } = useRoutes();

  const handleRemoveOrg = async () => {
    await removeOrganization(orgId);
    goto(AppRoute.ADMIN_ORGANIZATIONS);
  };

  const {
    syncOrganizationStripeAccount,
    removeOrganization,
    isLoading: isOrgMutating,
  } = useApiEffect(AdminService, ['syncOrganizationStripeAccount', 'removeOrganization'], {
    revalidate: ['getOrganization', 'getOrganizations'],
  });

  return (
    <DangerZone>
      <DangerZoneActions>
        <ConfirmableButton
          componentType="DangerZoneAction"
          primaryText="Remove Organization"
          secondaryText="Mark Organization as Deleted"
          actionText="Remove"
          onClick={handleRemoveOrg}
          title="Remove this Organization?"
          text={
            <>
              The user will loose access to account. However, any data associated with this account will remain
              unchanged, active keys and subscriptions will be retained.
            </>
          }
        />
        <DangerZoneAction
          primaryText="Sync to Stripe"
          secondaryText="Create or Sync Stripe Customer"
          actionText="Create or Sync Stripe Customer"
          renderButton={() => (
            <SplitButton
              variant="outlined"
              disabled={isOrgMutating}
              defaultAction={AVAILABLE_CURRENCIES[0] as string}
              actions={AVAILABLE_CURRENCIES.map((currency) => ({
                id: currency,
                title: `Sync ${currency}`,
              }))}
              onClick={({ id }) => syncOrganizationStripeAccount(orgId as string, id)}
            />
          )}
        />
      </DangerZoneActions>
    </DangerZone>
  );
};
