import { GeneratedPolicy } from '@localstack/types';
import { CopyIconButton, MagicTable } from '@localstack/ui';
import { ReactElement, useMemo } from 'react';

import { GridEventListener } from '@mui/x-data-grid';

import { PolicyIcon } from '../PolicyIcon';
import { getServicesForPolicy, getActionsForPolicy, renderActions, renderServices } from '../common/util';
import { useStyles } from '../common/style';

type PoliciesTableProps = {
  policies: GeneratedPolicy[];
  onRowClick: GridEventListener<'rowClick'>;
};

const TABLE_SCHEMA = {
  shapes: {
    Operation: {
      type: 'structure',
      members: {
        avatar: { type: 'string' },
        arn: { type: 'string' },
        services: { type: 'string' },
        actions: { type: 'string' },
        copyValue: { type: 'string' },
      },
    },
  },
};

export const PoliciesTable = ({ policies, onRowClick }: PoliciesTableProps): ReactElement => {
  const classes = useStyles();

  const transformedPolicies = useMemo(
    () =>
      policies.map((policy) => ({
        id: policy.resource,
        avatar: policy.policy_type,
        arn: policy.resource,
        services: getServicesForPolicy(policy),
        actions: getActionsForPolicy(policy),
        copyValue: JSON.stringify(policy.policy_document),
      })),
    [policies],
  );

  return (
    <MagicTable
      schema={TABLE_SCHEMA}
      entry="Operation"
      onRowClick={onRowClick}
      rows={transformedPolicies ?? []}
      pageSize={25}
      density="compact"
      idAttribute="arn"
      customGridColDef={{
        arn: { flex: 1 },
        services: { flex: 1 },
        actions: { flex: 1 },
      }}
      filterColumns={['id', 'avatar', 'arn', 'services', 'actions', 'copyValue']}
      hideColumnHeaders={['avatar', 'copyValue']}
      disableColumnMenu
      customWidths={{
        avatar: 25,
        copyValue: 25,
      }}
      className={classes.tableHover}
      externalFields={{
        avatar: (row) => <PolicyIcon type={row.avatar} />,
        services: (row) => renderServices(row.services),
        actions: (row) => renderActions(row.actions),
        copyValue: (row) => (
          <CopyIconButton
            tooltipTitle="Copy Policy (JSON)"
            textToCopy={row.copyValue}
            size="small"
            iconFontSize="small"
            color="default"
          />
        ),
      }}
    />
  );
};
