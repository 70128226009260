import { ReactElement } from 'react';
import { useRoutes, useAwsEffect } from '@localstack/services';
import { Card, CardContent, CardActions, Box, Typography } from '@mui/material';
import { PutCloudWatchMetricAlarmRequest } from '@localstack/types';
import { ProgressButton, MonitoringAlarmForm, Breadcrumbs } from '@localstack/ui';
import { DEFAULT_METRICS_ROUTES } from '@localstack/constants/src';

import { MetricsProps } from './types';

export const MetricAlarmUpsert = ({
  Layout,
  clientOverrides,
  routes = DEFAULT_METRICS_ROUTES,
}: MetricsProps): ReactElement => {
  const { goto } = useRoutes();

  const { putMetricAlarm, isLoading } = useAwsEffect('CloudWatch', ['putMetricAlarm'], {
    revalidate: ['describeAlarms'],
    clientOverrides,
  });

  const handleCreate = async (data: PutCloudWatchMetricAlarmRequest) => {
    await putMetricAlarm(data);
    goto(routes.RESOURCES_CLOUDWATCH_MONITORING_ALARMS);
  };

  return (
    <Layout
      documentTitle="Alarm Details"
      title={
        <Box>
          <Typography variant="h4">Alarm Details</Typography>
          <Breadcrumbs
            mappings={[
              ['CloudWatch Metrics', () => goto(routes.RESOURCES_CLOUDWATCH_MONITORING)],
              ['Alarms', () => goto(routes.RESOURCES_CLOUDWATCH_MONITORING_ALARMS)],
              ['Put Alarm', null],
            ]}
          />
        </Box>
      }
      actions={
        <ProgressButton type="submit" form="update" variant="outlined" color="primary" loading={isLoading}>
          Submit
        </ProgressButton>
      }
    >
      <Card>
        <CardContent>
          <MonitoringAlarmForm onCreate={handleCreate} loading={isLoading} formId="update" />
        </CardContent>
        <CardActions>
          <ProgressButton type="submit" form="update" variant="contained" color="primary" loading={isLoading}>
            Submit
          </ProgressButton>
        </CardActions>
      </Card>
    </Layout>
  );
};
