import { AllowedPlanChipPlans, EnrichedExtension } from '@localstack/types';
import { Box, Skeleton, Typography } from '@mui/material';
import { ReactElement, ReactEventHandler, useEffect, useState } from 'react';
import { createStyles, makeStyles } from '@mui/styles';
import { Variant } from '@mui/material/styles/createTypography';
import { PlanChip } from '@localstack/ui';

const DEFAULT_LOGO = '/images/extensions-default.png';

const useStyles = makeStyles(() =>
  createStyles({
    titleImage: {
      display: 'flex',
      alignItems: 'center',
      gap: '0.75rem',
      width: '100%',
    },
    titleText: {
      display: 'flex',
      flexDirection: 'column',
    },
  }),
);

type Props = {
  extension: Optional<EnrichedExtension>;
  typographyVariant?: Variant;
  showAdditionalInfo?: boolean;
};

export const ExtensionHeader = ({
  extension,
  typographyVariant = 'h2',
  showAdditionalInfo = false,
}: Props): ReactElement => {
  const [fileUrl, setFileUrl] = useState<string | undefined>(undefined);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const classes = useStyles();

  const setDefaultLogo = (event: ReactEventHandler<HTMLImageElement>) => {
    /* @ts-expect-error setting the default image here */
    event.target.src = '/images/extension-default.png';
  };

  const handleOnLoad = () => {
    setIsLoading(false);
  };

  useEffect(() => {
    if (!extension?.logoFile) {
      return;
    }
    const reader = new FileReader();
    reader.onload = () => {
      setFileUrl(reader.result as string);
    };
    reader.readAsDataURL(extension.logoFile);
  }, [extension]);

  const primaryLogo = fileUrl ?? extension?.logo;
  const logoLocation = extension ? (primaryLogo ?? DEFAULT_LOGO) : primaryLogo;

  return (
    <Box className={classes.titleImage}>
      {isLoading && <Skeleton variant="rectangular" width={40} height={40} />}
      <img
        /* @ts-expect-error no better way to get a default image for now */
        onError={setDefaultLogo}
        alt={`logo for ${extension?.name}`}
        height={30}
        width={30}
        src={logoLocation}
        onLoad={handleOnLoad}
        style={{ display: isLoading ? 'none' : 'block' }}
      />

      <Box className={classes.titleText}>
        <Typography variant={typographyVariant} style={{ fontWeight: 'bold' }}>
          {extension?.display_name ?? extension?.name}
        </Typography>
        {showAdditionalInfo && <Typography variant="h6">{extension?.author}</Typography>}
      </Box>
      {showAdditionalInfo && extension?.tier && (
        <PlanChip planFamily={extension.tier as AllowedPlanChipPlans} showTooltip size="medium" />
      )}
    </Box>
  );
};
