import { ReactElement } from 'react';
import { useRoutes, useAwsGetter } from '@localstack/services';
import { useParams } from 'react-router-dom';
import { Card, CardContent, Button, Box, Typography, MenuItem } from '@mui/material';
import { Breadcrumbs, AgwRestApiDetails as Details, Dropdown } from '@localstack/ui';
import { DEFAULT_API_GATEWAY_ROUTES } from '@localstack/constants';

import { ApiGatewayProps } from './types';
import { RestApiNavTabs } from './components';

export const AgwRestApiDetails = ({
  Layout,
  clientOverrides,
  routes = DEFAULT_API_GATEWAY_ROUTES,
}: ApiGatewayProps): ReactElement => {
  const { goto } = useRoutes();
  const { restApiId } = useParams<'restApiId'>();

  const { data: api } = useAwsGetter('APIGateway', 'getRestApi', [{ restApiId }], { clientOverrides });

  return (
    <Layout
      documentTitle="API Details"
      tabs={<RestApiNavTabs restApiId={restApiId as string} routes={routes} />}
      title={
        <Box>
          <Typography variant="h4">API Details</Typography>
          <Breadcrumbs
            mappings={[
              ['API Gateway', () => goto(routes.RESOURCES_AGW1)],
              [api?.name, null],
            ]}
          />
        </Box>
      }
      actions={
        <>
          <Button onClick={() => goto(routes.RESOURCES_AGW1_API_UPDATE, { restApiId })}>Edit API</Button>
          <Dropdown label="Actions">
            <MenuItem onClick={() => goto(routes.RESOURCES_AGW1_API_DEPLOY, { restApiId })}>Deploy API</MenuItem>
          </Dropdown>
        </>
      }
    >
      <Card>
        <CardContent>
          <Details api={api} />
        </CardContent>
      </Card>
    </Layout>
  );
};
