export const CORSConfigurationPlaceholder = {
  CORSRules: [
    {
      AllowedHeaders: ['*'],
      AllowedMethods: ['GET', 'POST'],
      AllowedOrigins: ['*'],
    },
  ],
};

export const defaultCORSRules = {
  AllowedHeaders: ['*'],
  AllowedMethods: ['GET', 'POST', 'PUT', 'DELETE'],
  AllowedOrigins: [window.location.origin],
  ExposeHeaders: ['ETag'],
};
