import { GeneratedPolicy } from '@localstack/types';
import { Card, Grid, CardContent, Button } from '@mui/material';

import { ReactElement, useEffect, useState } from 'react';
import { AwsClientOverrides, useAwsEffect } from '@localstack/services';

import { DeleteForeverOutlined } from '@mui/icons-material';

import { PolicySummaryDrawer } from '../PolicySummaryDrawer';
import { PoliciesTable } from '../PoliciesTable';

type PolicySummaryProps = {
  clientOverrides: AwsClientOverrides;
  createSnackbarError: (err: string) => void;
  enable: boolean;
};

export const PolicySummary = ({ clientOverrides, createSnackbarError, enable }: PolicySummaryProps): ReactElement => {
  const [policies, setPolicies] = useState<GeneratedPolicy[]>([]);
  const [selectedPolicy, setSelectedPolicy] = useState<Optional<GeneratedPolicy>>();
  const [summaryStreamReader, setSummaryStreamReader] = useState<Optional<ReadableStreamDefaultReader<Uint8Array>>>();

  const { openPolicySummaryStream, resetIAMPolicySummary } = useAwsEffect(
    'LocalStack',
    ['openPolicySummaryStream', 'resetIAMPolicySummary'],
    { silentErrors: true, clientOverrides },
  );

  const openSummaryStreamReader = async () => {
    const readerSummary = await openPolicySummaryStream({
      onChunkReceived: setPolicies,
      onError: createSnackbarError,
    });
    setSummaryStreamReader(readerSummary);
  };

  const closeSummaryStreamReader = () => {
    summaryStreamReader?.cancel();
    setSummaryStreamReader(undefined);
  };

  useEffect(() => {
    if (enable) {
      openSummaryStreamReader();
    }

    return () => closeSummaryStreamReader();
  }, [enable]);

  const selectPolicyWithId = (id: string | number) => {
    const realPolicy = policies.find((policy) => policy.resource === `${id}`);
    setSelectedPolicy(realPolicy);
  };

  const onDelete = () => {
    resetIAMPolicySummary();
    setPolicies([]);
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'end' }}>
        <Button startIcon={<DeleteForeverOutlined />} onClick={onDelete}>
          Clear Policies
        </Button>
      </Grid>
      <Grid item xs={12}>
        <Card>
          <CardContent>
            <Grid container>
              <Grid item xs={12}>
                <PoliciesTable policies={policies} onRowClick={(row) => selectPolicyWithId(`${row.id}`)} />
                <PolicySummaryDrawer policy={selectedPolicy} onClose={() => setSelectedPolicy(undefined)} />
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  );
};
