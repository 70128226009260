/* eslint-disable @typescript-eslint/no-explicit-any */
import { ReactElement, useMemo } from 'react';
import { Routes, Route } from 'react-router-dom';

import {
  ROUTE_VIEW_MAPPING,
  INSTANCE_PATH_ELEMENT_MAP,
  ResourceBrowserProps,
  InjectableViewProps,
} from '@localstack/resources';

import { buildRoute, getSelectedInstance, useEndpoint } from '@localstack/services';

import { PATH_PREFIX_INSTANCE, PATH_PREFIX_RESOURCES } from '@localstack/constants';

import { AppRoute } from '~/config';

import { InstanceLayout } from '~/layouts/Customer/InstanceLayout';

import { useAuthProvider } from '~/hooks/useAuthProvider';

import { ResourceBrowserLayout } from '~/layouts/Customer/ResourceBrowserLayout';

interface InjectableViewsAdapterProps<R> {
  Component: (props: R) => ReactElement;
  routes: Record<string, string>;
  endpoint?: string;
}

const InstanceAdapter = ({
  Component,
  routes,
  endpoint,
}: InjectableViewsAdapterProps<InjectableViewProps<any>>): ReactElement => (
  <Component Layout={InstanceLayout} routes={routes} clientOverrides={{ endpoint }} useAuthProvider={useAuthProvider} />
);

const ResourceBrowserAdapter = ({
  Component,
  routes,
  endpoint,
}: InjectableViewsAdapterProps<ResourceBrowserProps<any>>): ReactElement => (
  <Component
    Layout={ResourceBrowserLayout}
    routes={routes}
    clientOverrides={{ endpoint }}
    useAuthProvider={useAuthProvider}
  />
);

export const DefaultInstanceRoutes = (): ReactElement => {
  const selectedInstance = getSelectedInstance();
  const { endpoint: globalEndpoint } = useEndpoint();
  const instanceEndpoint = selectedInstance?.endpoint || globalEndpoint;

  const routePrefix = `${PATH_PREFIX_INSTANCE}/`;
  const filteredRoutes = useMemo(
    () =>
      Object.fromEntries(
        Object.entries(AppRoute as Record<string, string>).filter((v) => v[1].startsWith(routePrefix)),
      ),
    [],
  );

  // building routes for the resource browser based on instance id
  const adjustedRoutes = useMemo(
    () =>
      Object.fromEntries(
        Object.entries(filteredRoutes).map((v) => [v[0], buildRoute(v[1], { iid: selectedInstance?.id })]),
      ),
    [selectedInstance],
  );

  const mergedRouteViewMapping = useMemo(() => {
    const formattedInstanceMap = new Map(
      Object.entries(INSTANCE_PATH_ELEMENT_MAP).map(([path, mapEntry]) => [path, Object.values(mapEntry).at(0)]),
    );
    return new Map(Array.from(formattedInstanceMap).concat(Array.from(ROUTE_VIEW_MAPPING)));
  }, []);

  return (
    <Routes>
      {Array.from(mergedRouteViewMapping.keys()).map((key) => {
        const Adapter = key.includes(PATH_PREFIX_RESOURCES) ? ResourceBrowserAdapter : InstanceAdapter;
        return (
          <Route
            key={key}
            // since our customer routes specifies a general route for all resources
            // we need to strip away the prefix, since it's already included in the general route
            path={key.replace(routePrefix, '')}
            element={
              <Adapter
                Component={
                  mergedRouteViewMapping.get(key) as InjectableViewsAdapterProps<InjectableViewProps<any>>['Component']
                }
                routes={adjustedRoutes}
                endpoint={instanceEndpoint}
              />
            }
          />
        );
      })}
    </Routes>
  );
};
