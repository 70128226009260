import { ReactElement } from 'react';
import { useParams } from 'react-router-dom';
import { Card, Box, Typography } from '@mui/material';
import { SNSSubscription as SNSSubscriptionType } from '@localstack/types';

import { useAwsEffect, useAwsGetter, useRoutes } from '@localstack/services';

import {
  SNSSubscriptionDetails,
  SNSSubscriptionAttributes,
  ConfirmableButton,
  Dropdown,
  Breadcrumbs,
} from '@localstack/ui';

import { DEFAULT_SNS_ROUTES } from '@localstack/constants';

import { SNSProps } from './types';

export const SNSSubscription = ({ Layout, clientOverrides, routes = DEFAULT_SNS_ROUTES }: SNSProps): ReactElement => {
  const { goto } = useRoutes();

  const { topicArn, subscriptionArn } = useParams<'topicArn' | 'subscriptionArn'>();

  const { data: attributes, isLoading } = useAwsGetter(
    'SNS',
    'getSubscriptionAttributes',
    [{ SubscriptionArn: subscriptionArn }],
    { clientOverrides },
  );

  const subName = subscriptionArn?.split(':')?.slice(-1)?.[0];

  const subscription: SNSSubscriptionType = {
    SubscriptionArn: subscriptionArn,
    Owner: attributes?.Attributes?.Owner,
    TopicArn: topicArn,
    Endpoint: attributes?.Attributes?.Endpoint,
    Protocol: attributes?.Attributes?.Protocol,
  };

  const { unsubscribe } = useAwsEffect('SNS', ['unsubscribe'], {
    revalidate: ['listSubscriptionsByTopic'],
    clientOverrides,
  });

  return (
    <>
      <Layout
        documentTitle="SNS Topic Subscription"
        title={
          <Box>
            <Typography variant="h4">SNS Topic Subscription</Typography>
            <Breadcrumbs
              mappings={[
                ['SNS', () => goto(routes.RESOURCES_SNS_TOPICS)],
                [topicArn?.split(':')?.slice(-1)?.[0], () => goto(routes.RESOURCES_SNS_TOPIC, { topicArn })],
                [subName, null],
              ]}
            />
          </Box>
        }
        actions={
          <>
            <Dropdown label="Actions">
              <ConfirmableButton
                componentType="MenuItem"
                disabled={isLoading}
                title={`Delete Subscription ${subName}?`}
                text={`Are you sure you would like to delete ${subName}?`}
                onClick={async () => {
                  await unsubscribe({ SubscriptionArn: subscriptionArn as string });
                  goto(routes.RESOURCES_SNS_TOPIC_SUBSCRIPTIONS, { topicArn });
                }}
              >
                Unsubscribe
              </ConfirmableButton>
            </Dropdown>
          </>
        }
      >
        <Card>
          <SNSSubscriptionDetails subscription={subscription} />
          <SNSSubscriptionAttributes attributes={attributes?.Attributes} />
        </Card>
      </Layout>
    </>
  );
};
