import { ReactElement } from 'react';
import { ModelAttributeChange } from '@localstack/types';
import { Chip, Typography } from '@mui/material';

import { AttributeMappingCallbacks } from './types';

const ATTRIBUTE_ALIASES: { regex: RegExp; alias: string }[] = [
  { regex: /^free_beta$/, alias: 'beta program' },
  { regex: /^activation$/, alias: 'email confirmation state' },
  { regex: /^members\.\d+\.(.+)$/, alias: "membership's $1" },
  { regex: /^tos_acceptance_log/, alias: 'terms of service acceptance' },
];

export const findAttributeAlias = (path: string): string => {
  const alias = ATTRIBUTE_ALIASES.find((a) => a.regex.test(path));

  if (!alias) {
    return path;
  }

  const match = path.match(alias.regex);

  if (!match) {
    return alias.alias;
  }

  return alias.alias.replace(/\$[0-9]+/g, (m) => {
    const index = Number(m.slice(1));
    return match[index] ?? m;
  });
};

export const formatStandardAttributeChange = (owner: (entity: unknown) => ReactElement): AttributeMappingCallbacks => ({
  add: (entity: unknown, change: ModelAttributeChange): ReactElement => (
    <Typography variant="caption" component="div">
      set <Chip size="small" color="primary" label={findAttributeAlias(change.path)} /> to{' '}
      <Chip size="small" color="secondary" label={String(change.new_value)} /> {owner(entity)}
    </Typography>
  ),
  replace: (entity: unknown, change: ModelAttributeChange): ReactElement => (
    <Typography variant="caption" component="div">
      changed <Chip size="small" color="primary" label={findAttributeAlias(change.path)} /> from{' '}
      <Chip size="small" color="secondary" label={String(change.old_value)} /> to{' '}
      <Chip size="small" color="secondary" label={String(change.new_value)} /> {owner(entity)}
    </Typography>
  ),
  remove: (entity: unknown, change: ModelAttributeChange): ReactElement => (
    <Typography variant="caption" component="div">
      removed <Chip size="small" color="primary" label={findAttributeAlias(change.path)} /> {owner(entity)}
    </Typography>
  ),
});

export const formatFallbackAttributeChange = (owner: (entity: unknown) => ReactElement): AttributeMappingCallbacks => ({
  add: (entity: unknown, _change: ModelAttributeChange): ReactElement => (
    <Typography variant="caption" component="div">
      {owner(entity)}
    </Typography>
  ),
  replace: (entity: unknown, _change: ModelAttributeChange): ReactElement => (
    <Typography variant="caption" component="div">
      {owner(entity)}
    </Typography>
  ),
  remove: (entity: unknown, _change: ModelAttributeChange): ReactElement => (
    <Typography variant="caption" component="div">
      {owner(entity)}
    </Typography>
  ),
});
