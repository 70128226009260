import { SERVICE_NAME } from '@localstack/types';

import { PATH_PREFIX_RESOURCES } from './base';

const SAGEMAKER_BASE_ROUTE = `${PATH_PREFIX_RESOURCES}/${SERVICE_NAME.SAGEMAKER}`;

export const DEFAULT_SGM_ROUTES: Record<string, string> = {
  RESOURCES_SGM_MODELS: `${SAGEMAKER_BASE_ROUTE}/models`,
  RESOURCES_SGM_MODEL_NEW: `${SAGEMAKER_BASE_ROUTE}/model/new`,
  RESOURCES_SGM_ENDPOINT_CONFIGS: `${SAGEMAKER_BASE_ROUTE}/endpointconfigs`,
  RESOURCES_SGM_ENDPOINT_CONFIG_NEW: `${SAGEMAKER_BASE_ROUTE}/endpointconfig/new`,
  RESOURCES_SGM_ENDPOINTS: `${SAGEMAKER_BASE_ROUTE}/endpoints`,
  RESOURCES_SGM_ENDPOINT_NEW: `${SAGEMAKER_BASE_ROUTE}/endpoint/new`,
};
