import { ReactElement } from 'react';
import { NavLink } from '@localstack/ui';
import { buildRoute } from '@localstack/services';

import { RDSRoutes } from '../../types';

interface Props {
  instanceId: string;
  routes: RDSRoutes;
}

export const InstanceNavTabs = ({ instanceId, routes }: Props): ReactElement => (
  <>
    <NavLink to={buildRoute(routes.RESOURCES_RDS_INSTANCE, { instanceId })} end>
      Details
    </NavLink>
    <NavLink to={buildRoute(routes.RESOURCES_RDS_INSTANCE_SQL, { instanceId })}>Query</NavLink>
  </>
);
