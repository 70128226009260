import { ReactElement, useCallback, useState } from 'react';
import { useRoutes, useAwsGetter, useAwsEffect } from '@localstack/services';
import { Card, Button } from '@mui/material';
import { Dropdown, ConfirmableButton, PageTitle, GlueCrawlersTable } from '@localstack/ui';
import { DEFAULT_GLUE_ROUTES } from '@localstack/constants';

import { GlueProps } from './types';
import { MainNavTabs } from './components';

export const GlueCrawlers = ({ Layout, clientOverrides, routes = DEFAULT_GLUE_ROUTES }: GlueProps): ReactElement => {
  const [selected, setSelected] = useState<string[]>([]);

  const { goto } = useRoutes();

  const { data: crawlers, isLoading, mutate } = useAwsGetter('Glue', 'getCrawlers', [], { clientOverrides });

  const { deleteCrawler, startCrawler, stopCrawler } = useAwsEffect(
    'Glue',
    ['deleteCrawler', 'startCrawler', 'stopCrawler'],
    { revalidate: ['getCrawlers'], clientOverrides },
  );

  const handleDeleteSelected = useCallback(
    async () => Promise.all(selected.map((crawler) => deleteCrawler({ Name: crawler }))),
    [selected],
  );

  const handleStartSelected = useCallback(
    async () => Promise.all(selected.map((crawler) => startCrawler({ Name: crawler }))),
    [selected],
  );

  const handleStopSelected = useCallback(
    async () => Promise.all(selected.map((crawler) => stopCrawler({ Name: crawler }))),
    [selected],
  );

  return (
    <Layout
      documentTitle="Glue: Crawlers"
      title={
        <PageTitle
          title="Glue Crawlers"
          breadcrumbs={[
            ['Glue', () => goto(routes.RESOURCES_GLUE_DATABASES)],
            ['Crawlers', () => goto(routes.RESOURCES_GLUE_CRAWLERS)],
          ]}
          onMutate={mutate}
        />
      }
      tabs={<MainNavTabs routes={routes} />}
      actions={
        <>
          <Button onClick={() => goto(routes.RESOURCES_GLUE_CRAWLER_CREATE)}>Create Crawler</Button>
          <Dropdown label="Actions">
            <ConfirmableButton
              componentType="MenuItem"
              disabled={selected.length === 0 || selected.length > 1 || isLoading}
              title="Start Selected Crawler?"
              onClick={handleStartSelected}
              text="Selected Crawler will started"
            >
              Run Crawler
            </ConfirmableButton>
            <ConfirmableButton
              componentType="MenuItem"
              disabled={selected.length === 0 || selected.length > 1 || isLoading}
              title="Start Selected Crawler?"
              onClick={handleStopSelected}
              text="Selected Crawler will started"
            >
              Stop Crawler
            </ConfirmableButton>
            <ConfirmableButton
              componentType="MenuItem"
              disabled={selected.length === 0 || isLoading}
              title={`Delete ${selected.length} Crawler(s)?`}
              onClick={handleDeleteSelected}
              text="Selected Crawler(s) will be permanently deleted"
            >
              Remove Selected
            </ConfirmableButton>
          </Dropdown>
        </>
      }
    >
      <Card>
        <GlueCrawlersTable
          crawlers={crawlers?.Crawlers ?? []}
          loading={isLoading}
          onSelect={setSelected}
          onViewCrawler={(crawler) => goto(routes.RESOURCES_GLUE_CRAWLER, { crawler })}
        />
      </Card>
    </Layout>
  );
};
