import { useState, ReactElement } from 'react';
import { Theme } from '@mui/material/styles';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import { formatDateTime } from '@localstack/services';
import { Link } from '@mui/material';
import classnames from 'classnames';

import { LogsTableEvent } from './types';

interface LogsTableRowProps {
  style: any; // eslint-disable-line
  event: LogsTableEvent;
  onViewGroup?: (groupName: string) => unknown;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      ...theme.typography.body2,
      display: 'flex',
      overflow: 'hidden',
      cursor: 'pointer',
      padding: theme.spacing(0, 2),
      background: theme.palette.background.paper,
      color: theme.palette.text.primary,
      '&:hover': {
        background: theme.palette.action.hover,
      },
    },
    timestamp: {
      whiteSpace: 'nowrap',
      marginRight: theme.spacing(1),
      color: theme.palette.text.secondary,
    },
    group: {
      whiteSpace: 'nowrap',
      marginRight: theme.spacing(1),
    },
    message: {
      whiteSpace: 'nowrap',
      textOverflow: 'ellipsis',
    },
    details: {
      ...theme.typography.body1,
      width: '100%',
      padding: theme.spacing(2),
      position: 'relative',
      background: theme.palette.background.default,
      border: `1px dotted ${theme.palette.divider}`,
    },
    active: {
      background: theme.palette.action.active,
    },
  }),
);

export const LogsTableRow = ({ style, event, onViewGroup }: LogsTableRowProps): ReactElement => {
  const classes = useStyles();

  const [active, setActive] = useState(false);

  return (
    <div style={style}>
      <div
        className={classnames(classes.root, { [classes.active]: active })}
        onClick={() => setActive(!active)}
        role="button"
        tabIndex={-1}
      >
        <div className={classes.timestamp}>{formatDateTime(event.timestamp)}</div>
        <div className={classes.group}>
          {onViewGroup ? (
            <Link onClick={() => onViewGroup(event.groupName ?? '')} underline="hover">
              [{event.groupName}]
            </Link>
          ) : (
            <>[{event.groupName}]</>
          )}
        </div>
        <div className={classes.message}>{event.message}</div>
      </div>
      {active && <div className={classes.details}>{event.message}</div>}
    </div>
  );
};
