import { ReactElement } from 'react';

import { AcceptTosOverlay } from '~/components/AcceptTosOverlay';

import { AuthenticatedLayout, AuthenticatedLayoutProps } from '../AuthenticatedLayout';

import { Navbar } from '../components';

export type CustomerLayoutProps = AuthenticatedLayoutProps;

export const CustomerLayout = ({ children, ...rest }: CustomerLayoutProps): ReactElement => (
  <AuthenticatedLayout {...rest} navigation={<Navbar />}>
    {children}
    <AcceptTosOverlay />
  </AuthenticatedLayout>
);
