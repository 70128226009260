import { ReactElement } from 'react';
import { GluePartition } from '@localstack/types';
import { getSchema } from '@localstack/services';

import { MagicTable } from '../../../magic/MagicTable';

export type GlueTablePartitionsGridProps = {
  partitionColNames: Partial<keyof GluePartition>[];
  loading?: boolean;
  selectable?: boolean;
  partitions: GluePartition[];
  onSelect?: (names: string[]) => void;
};

export const GlueTablePartitionsGrid = ({
  partitionColNames,
  partitions,
  selectable = true,
  loading,
  onSelect,
}: GlueTablePartitionsGridProps): ReactElement => (
  <MagicTable
    schema={getSchema('Glue')}
    entry="PartitionList"
    idAttribute={[...partitionColNames]}
    rows={partitions.map((item) => ({ ...item, Values: item.Values?.join(', ') ?? '' }))}
    order={[...partitionColNames, 'DatabaseName', 'TableName', 'CreationTime', 'CatalogId']}
    filterColumns={[...partitionColNames, 'DatabaseName', 'TableName', 'CreationTime', 'CatalogId']}
    selectable={selectable}
    onSelect={onSelect}
    loading={loading}
    externalFields={{
      ...(partitionColNames.reduce(
        (acc, item) => ({ ...acc, [item]: (row: GluePartition) => <p>{row[item as keyof GluePartition]}</p> }),
        {},
      ) ?? {}),
    }}
  />
);
