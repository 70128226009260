import { ReactElement } from 'react';
import { NavLink } from '@localstack/ui';
import { buildRoute } from '@localstack/services';

import { ECSRoutes } from '../../types';

type MainNavTabsProps = {
  routes: ECSRoutes;
};

export const MainNavTabs = ({ routes }: MainNavTabsProps): ReactElement => (
  <>
    <NavLink to={buildRoute(routes.RESOURCES_ECS_CLUSTERS)} end>
      Clusters
    </NavLink>
    <NavLink to={buildRoute(routes.RESOURCES_ECS_TASK_DEFINITIONS)}>Task Definitions</NavLink>
  </>
);
