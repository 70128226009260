import { useState, useCallback, useEffect, ReactElement } from 'react';
import { useRoutes, useAwsEffect, useAwsGetter } from '@localstack/services';
import { DynamoDBTable } from '@localstack/types';
import { Card, Button } from '@mui/material';
import { DEFAULT_DYNAMODB_ROUTES } from '@localstack/constants';

import { PageTitle, Dropdown, ConfirmableButton, DynamoDBTablesTable } from '@localstack/ui';

import { NavTabs } from './components';
import { DynamoDBProps } from './types';

export const DynamoDBTables = ({
  Layout,
  clientOverrides,
  routes = DEFAULT_DYNAMODB_ROUTES,
}: DynamoDBProps): ReactElement => {
  const [selectedIds, setSelectedIds] = useState<string[]>([]);
  const [tables, setTables] = useState<DynamoDBTable[]>([]);

  const { goto } = useRoutes();
  const { describeTable } = useAwsEffect('DynamoDB', ['describeTable'], { clientOverrides });
  const { data: tableNames, isLoading, mutate } = useAwsGetter('DynamoDB', 'listTables', [], { clientOverrides });

  useEffect(() => {
    (async () => {
      const resolvedTableNames = tableNames?.TableNames ?? [];
      const describedTables = await Promise.all(resolvedTableNames.map((TableName) => describeTable({ TableName })));
      setTables(
        describedTables
          .filter(Boolean)
          .map(({ Table }) => Table)
          .filter(Boolean) as DynamoDBTable[],
      );
    })();
  }, [tableNames]);

  const { deleteTable } = useAwsEffect('DynamoDB', ['deleteTable'], {
    revalidate: ['describeTable', 'listTables'],
    clientOverrides,
  });

  const handleDeleteSelected = useCallback(async () => {
    const promises = selectedIds.map((TableName) => deleteTable({ TableName }));
    await Promise.all(promises);
  }, [selectedIds]);

  return (
    <Layout
      title={<PageTitle title="Tables" onMutate={mutate} />}
      tabs={<NavTabs routes={routes} />}
      actions={
        <>
          <Button onClick={() => goto(routes.RESOURCES_DYNAMODB_TABLE_CREATE)}>Create Table</Button>
          <Dropdown label="Actions">
            <ConfirmableButton
              componentType="MenuItem"
              disabled={selectedIds.length === 0 || isLoading}
              title={`Delete ${selectedIds.length} Table(s)?`}
              onClick={handleDeleteSelected}
              text="Selected Table(s) will be permanently deleted"
            >
              Remove Selected
            </ConfirmableButton>
          </Dropdown>
        </>
      }
    >
      <Card>
        <DynamoDBTablesTable
          tables={tables}
          loading={isLoading}
          onSelect={setSelectedIds}
          onViewTable={({ TableName }) => goto(routes.RESOURCES_DYNAMODB_TABLE, { tableName: TableName })}
        />
      </Card>
    </Layout>
  );
};
