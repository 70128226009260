import { CloudWatchGetMetricStatisticsRequest, CloudWatchMetric } from '@localstack/types';
import { useForm } from 'react-hook-form';
import { parseDate, formatDateTo, VALIDATION_RULES } from '@localstack/services';
import { DATETIME_LOCAL_FORMAT } from '@localstack/constants';
import { Grid, MenuItem } from '@mui/material';

import { buildOptionsFromArray, ControlledSelect, ControlledTextField } from '../../../form';
import { ProgressButton } from '../../../feedback';

export const PERIODS_LIST = [
  { value: '1', label: '1 Second' },
  { value: '5', label: '5 Seconds' },
  { value: '10', label: '10 Seconds' },
  { value: '30', label: ' 30 Seconds' },
  { value: '60', label: '1 Minute' },
  { value: '300', label: '5 Minutes' },
  { value: '900', label: '15 Minutes' },
  { value: '3600', label: '1 Hour' },
  { value: '21600', label: '6 Hours' },
  { value: '86400', label: '1 Day' },
  { value: '604800', label: '7 Days' },
  { value: '2592000', label: '30 Days' },
];

export const STATISTICS_LIST = ['Average', 'Minimum', 'Maximum', 'Sum'];

export interface MonitoringGraphControlsProps {
  loading?: boolean;
  metric: CloudWatchMetric;
  onSubmit: (values: CloudWatchGetMetricStatisticsRequest) => void;
}

/**
 * Controls for filtering and grouping CloudWatch datapoints.
 */
export const MonitoringGraphControls: React.FC<MonitoringGraphControlsProps> = ({ loading, metric, onSubmit }) => {
  const { control, formState, handleSubmit } = useForm<CloudWatchGetMetricStatisticsRequest>({
    mode: 'all',
    defaultValues: {
      Period: 900,
      Statistics: ['Average'],
      StartTime: formatDateTo(new Date(new Date().getTime() - 3_600_000), DATETIME_LOCAL_FORMAT) as unknown as Date, // -1H
      EndTime: formatDateTo(new Date(new Date().getTime() + 3_600_000), DATETIME_LOCAL_FORMAT) as unknown as Date, // +1H
    },
  });

  const submitHandler = (data: CloudWatchGetMetricStatisticsRequest) => {
    onSubmit({
      ...data,
      Namespace: metric.Namespace as string,
      MetricName: metric.MetricName as string,
      Dimensions: metric.Dimensions,
      StartTime: parseDate(data.StartTime),
      EndTime: parseDate(data.EndTime),
    });
  };

  return (
    <form onSubmit={handleSubmit(submitHandler)}>
      <Grid container direction="row" justifyContent="flex-start" spacing={1} alignItems="center">
        <Grid item>
          <ControlledSelect
            fullWidth
            required
            variant="outlined"
            control={control}
            name="Period"
            label="Period"
            size="small"
            rules={VALIDATION_RULES.required}
            options={PERIODS_LIST.map(({ label, value }) => (
              <MenuItem key={value} value={value}>
                {label}
              </MenuItem>
            ))}
          />
        </Grid>
        <Grid item>
          <ControlledSelect
            fullWidth
            required
            variant="outlined"
            control={control}
            name="Statistics.0"
            label="Statistics"
            size="small"
            rules={VALIDATION_RULES.required}
            options={buildOptionsFromArray(STATISTICS_LIST)}
          />
        </Grid>
        <Grid item>
          <ControlledTextField
            fullWidth
            required
            variant="outlined"
            control={control}
            name="StartTime"
            label="From"
            size="small"
            type="datetime-local"
            InputLabelProps={{ shrink: true }}
            inputProps={{ step: '1' }}
            rules={VALIDATION_RULES.required}
          />
        </Grid>
        <Grid item>
          <ControlledTextField
            fullWidth
            required
            variant="outlined"
            control={control}
            name="EndTime"
            label="To"
            size="small"
            type="datetime-local"
            InputLabelProps={{ shrink: true }}
            inputProps={{ step: '1' }}
            rules={VALIDATION_RULES.required}
          />
        </Grid>
        <Grid item>
          <ProgressButton
            type="submit"
            color="primary"
            variant="outlined"
            loading={loading}
            disabled={!formState.isValid}
          >
            Submit
          </ProgressButton>
        </Grid>
      </Grid>
    </form>
  );
};
