import { ReactElement, useState } from 'react';

import { ExternalLink, STORAGE_KEY_NEWS_BANNER } from '@localstack/constants';

import { Message } from '@mui/icons-material';

import { Theme } from '@mui/material';

import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';

import { createProperDate } from '@localstack/services';

import { Banner } from '~/components/Banner';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    link: {
      color: theme.palette.info.main,
      textDecoration: 'underline',
    },
  }),
);

type Message = {
  message: ReactElement;
  validAfter: Date;
  validBefore: Date;
  storageKey: string;
};

export const NewsBanner = (): ReactElement => {
  const classes = useStyles();

  const newsMessages: Array<Message> = [
    {
      message: (
        <span>
          We are introducing a new way to manage licenses for your workspace. Instead of API keys, developers can now
          use auth tokens. Find out more in our{' '}
          <a className={classes.link} href={ExternalLink.DOCS_USER_LICENSE}>
            documentation
          </a>
        </span>
      ),
      validAfter: createProperDate(2023, 1, 1),
      validBefore: createProperDate(2023, 12, 31),
      storageKey: '2023-10-12_licenseManagement',
    },
  ];

  const [dismissedMessages, setDismissedMessages] = useState<Array<string>>(
    JSON.parse(localStorage.getItem(STORAGE_KEY_NEWS_BANNER) || '[]'),
  );

  const now = new Date();
  const filteredMessages = newsMessages.filter(
    (message) =>
      !dismissedMessages.includes(message.storageKey) && message.validAfter < now && now < message.validBefore,
  );

  return (
    <>
      {filteredMessages.map((message) => (
        <Banner
          key={message.storageKey}
          content={message.message}
          onClose={() => {
            const newDismissedMessages = [message.storageKey, ...dismissedMessages];
            localStorage.setItem(STORAGE_KEY_NEWS_BANNER, JSON.stringify(newDismissedMessages));
            setDismissedMessages(newDismissedMessages);
          }}
        />
      ))}
    </>
  );
};
