import { ReactElement } from 'react';
import { Card } from '@mui/material';
import { CloudFormationOutputsGrid, PageTitle } from '@localstack/ui';
import { useParams } from 'react-router-dom';
import { useRoutes, useAwsGetter } from '@localstack/services';
import { DEFAULT_CLOUDFORMATION_ROUTES } from '@localstack/constants';

import { CloudFormationProps } from './types';
import { NavTabs } from './components/NavTabs';

export const CloudFormationOutputs = ({
  Layout,
  clientOverrides,
  routes = DEFAULT_CLOUDFORMATION_ROUTES,
}: CloudFormationProps): ReactElement => {
  const { goto } = useRoutes();
  const { name } = useParams<'name'>();

  const {
    data: stack,
    isLoading,
    mutate,
  } = useAwsGetter('CloudFormation', 'describeStacks', [{ StackName: name }], { clientOverrides });

  return (
    <Layout
      documentTitle="CloudFormation Outputs"
      tabs={<NavTabs name={name as string} routes={routes} />}
      title={
        <PageTitle
          title="CloudFormation Outputs"
          onMutate={mutate}
          breadcrumbs={[
            ['CloudFormation', () => goto(routes.RESOURCES_CLOUDFORMATION)],
            ['Stacks', () => goto(routes.RESOURCES_CLOUDFORMATION_STACKS)],
            [name, () => goto(routes.RESOURCES_CLOUDFORMATION_STACK_OUTPUTS, { name })],
            ['Outputs', null],
          ]}
        />
      }
    >
      <Card>
        <CloudFormationOutputsGrid entries={stack?.Stacks?.[0]?.Outputs ?? []} loading={isLoading} />
      </Card>
    </Layout>
  );
};
