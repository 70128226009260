import { ReactElement } from 'react';
import { Link } from '@mui/material';
import { MQBrokerSummary } from '@localstack/types';
import { getSchema } from '@localstack/services';

import { MagicTable } from '../../magic/MagicTable';

export type MQBrokersTableProps = {
  selectable?: boolean;
  loading?: boolean;
  brokers: MQBrokerSummary[];
  onSelect?: (names: string[]) => void;
  onViewBroker?: (broker: MQBrokerSummary) => unknown;
};

export const MQBrokersTable = ({
  brokers,
  selectable = true,
  loading,
  onSelect,
  onViewBroker,
}: MQBrokersTableProps): ReactElement => (
  <MagicTable
    schema={getSchema('MQ')}
    entry="BrokerSummary"
    idAttribute="BrokerId"
    rows={brokers}
    selectable={selectable}
    onSelect={onSelect}
    order={['BrokerId', 'BrokerName', 'BrokerArn', 'HostInstanceType', 'EngineType']}
    loading={loading}
    externalFields={{
      BrokerId: (row) => (
        <Link onClick={() => onViewBroker && onViewBroker(row)} underline="hover">
          {row.BrokerId}
        </Link>
      ),
    }}
  />
);
