import { ReactElement } from 'react';
import { StartTranscriptionJobRequest } from '@localstack/types';
import { AwsClientOverrides, getSchema } from '@localstack/services';

import { MagicForm } from '../../magic/MagicForm';

export type TranscriptionJobFormProps = {
  loading?: boolean;
  formId: string;
  clientOverrides?: AwsClientOverrides;
  onSubmit: (data: StartTranscriptionJobRequest) => unknown;
};

export const TranscriptionJobForm = ({ loading, formId, onSubmit }: TranscriptionJobFormProps): ReactElement => (
  <MagicForm
    schema={getSchema('TranscribeService')}
    formId={formId}
    entry="StartTranscriptionJobRequest"
    loading={loading}
    onSubmit={(data: StartTranscriptionJobRequest) => {
      onSubmit(data);
    }}
  />
);
