import { Box, Typography, Skeleton } from '@mui/material';
import { ReactElement } from 'react';

import { OrganizationsService, useApiEffect, useApiGetter } from '@localstack/services';

import { ProgressButton } from '@localstack/ui';

import { useAuthProvider } from '~/hooks/useAuthProvider';

import { preflightLicenseFriendlyError } from '~/util/license';

import { STATUS_ICONS } from './icons';

export const LicenseAssigned = (): ReactElement => {
  const { userInfo } = useAuthProvider();

  const { data: assignmentInfo, isLoading: isAssignmentInfoLoading } = useApiGetter(
    OrganizationsService,
    'getUserLicenseAssignment',
    [userInfo?.org.id],
    {
      enable: !!userInfo,
      suppressErrors: true,
    },
  );

  const { data: preflight, isLoading: isPreflightLoading } = useApiGetter(
    OrganizationsService,
    'preflightSelfAssignLicense',
    [userInfo?.org.id],
    {
      enable: !!userInfo && !!assignmentInfo && !assignmentInfo.has_license_assigned,
      suppressErrors: true,
    },
  );

  const { selfAssignLicense, isLoading: isIssuingLicense } = useApiEffect(OrganizationsService, ['selfAssignLicense'], {
    revalidate: ['getUserLicenseAssignment', 'listLicenseAssignments', 'preflightSelfAssignLicense'],
  });

  const loadingLicenseSection = isAssignmentInfoLoading || isPreflightLoading;
  const licenseIcon = assignmentInfo?.has_license_assigned ? STATUS_ICONS.success : STATUS_ICONS.warning;

  return (
    <Box display="flex" flexDirection="row" gap={1}>
      <>{loadingLicenseSection ? <Skeleton variant="circular" width={18} height={18} /> : licenseIcon}</>
      <Box sx={{ width: '100%' }}>
        {loadingLicenseSection ? (
          <Skeleton width="50%" variant="text" />
        ) : (
          <Typography fontWeight="bold">Activate LocalStack Pro</Typography>
        )}
        {!loadingLicenseSection && (
          <>
            <Typography>
              {assignmentInfo?.has_license_assigned ? (
                <>You can activate localstack-pro with your license that is assigned to you in this workspace.</>
              ) : (
                <>A license is required to activate the localstack-pro image.</>
              )}
            </Typography>
            <Box mt={1}>
              {preflight?.success === false && preflight.failure && (
                <Typography>{preflightLicenseFriendlyError(preflight?.failure)}</Typography>
              )}
              {preflight?.success && (
                <ProgressButton
                  variant="outlined"
                  size="small"
                  color="primary"
                  style={{ whiteSpace: 'nowrap' }}
                  onClick={() => selfAssignLicense(userInfo?.org.id)}
                  loading={isIssuingLicense}
                >
                  Assign license now
                </ProgressButton>
              )}
            </Box>
          </>
        )}
        {!loadingLicenseSection && assignmentInfo?.has_license_assigned && (
          <Typography>{assignmentInfo.plan_name}</Typography>
        )}
      </Box>
    </Box>
  );
};
