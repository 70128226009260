import { useEffect, useState } from 'react';
import { FieldError } from 'react-hook-form';

type PasswordFieldVisibilityReturnType = {
  showPasswordField: boolean;
  setShowPasswordField: React.Dispatch<React.SetStateAction<boolean>>;
};

export const usePasswordFieldVisibility = (
  emailFieldErrors: Optional<FieldError>,
  passwordFieldRef: React.RefObject<HTMLInputElement>,
): PasswordFieldVisibilityReturnType => {
  const [showPasswordField, setShowPasswordField] = useState<boolean>(false);

  useEffect(() => {
    if (emailFieldErrors) {
      setShowPasswordField(false);
    }
  }, [emailFieldErrors]);

  useEffect(() => {
    if (showPasswordField) {
      passwordFieldRef.current?.focus();
    }
  }, [showPasswordField]);

  return {
    showPasswordField,
    setShowPasswordField,
  };
};
