import { ReactElement } from 'react';
import { Card, CardContent, CardActions, Box, Typography } from '@mui/material';
import { useAwsEffect, useRoutes } from '@localstack/services';
import { CreateECRImageRequest } from '@localstack/types';
import { Breadcrumbs, ProgressButton, CreateECRImageForm } from '@localstack/ui';

import { ECRProps } from './types';

export const ECRImageCreate = ({ Layout, clientOverrides, routes }: ECRProps): ReactElement => {
  const { goto } = useRoutes();

  const { putImage, isLoading } = useAwsEffect('ECR', ['putImage'], {
    revalidate: ['describeImages'],
    clientOverrides,
  });

  const handleCreate = async (data: CreateECRImageRequest) => {
    await putImage(data);
    goto(routes.RESOURCES_ECR_REPOSITORIES);
  };

  return (
    <Layout
      documentTitle="Create ECR Image"
      title={
        <Box>
          <Typography variant="h4">Create ECR Image</Typography>
          <Breadcrumbs
            mappings={[
              ['ECR', () => goto(routes.RESOURCES_ECR_REPOSITORIES)],
              ['Create ECR image', null],
            ]}
          />
        </Box>
      }
      actions={
        <ProgressButton type="submit" form="CreateECRImage" variant="outlined" color="primary" loading={isLoading}>
          Submit
        </ProgressButton>
      }
    >
      <Card>
        <CardContent>
          <CreateECRImageForm onSubmit={handleCreate} formId="CreateECRImage" loading={isLoading} />
        </CardContent>
        <CardActions>
          <ProgressButton type="submit" form="CreateECRImage" variant="contained" color="primary" loading={isLoading}>
            Submit
          </ProgressButton>
        </CardActions>
      </Card>
    </Layout>
  );
};
