import { Theme } from '@mui/material/styles';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import { ReactElement } from 'react';

import { AnimatedLogo, ProgressButton } from '@localstack/ui';

import { Box, Card, CardActions, CardContent, CardHeader } from '@mui/material';

import { UserService, useApiEffect } from '@localstack/services';

import { useNavigate } from 'react-router-dom';

import { TosAcceptanceContext } from '@localstack/types';

import { ACCEPT_TOS_BUTTON_CAPTION, AcceptTosTextBlock } from '~/components/AcceptTosTextBlock';
import { AppRoute } from '~/config';
import { BaseLayout } from '~/layouts';
import { useSignout } from '~/hooks/useSignout';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    card: {
      marginBottom: theme.spacing(3),
      width: theme.breakpoints.values.sm,
      [theme.breakpoints.down('md')]: {
        width: 'auto',
      },
    },
  }),
);

export const AcceptTos = (): ReactElement => {
  const classes = useStyles();
  const navigate = useNavigate();
  const { handleSignOut, isSigningOut } = useSignout();

  const { acceptTermsOfService, isLoading: isAcceptingTerms } = useApiEffect(UserService, ['acceptTermsOfService'], {
    revalidate: ['getUser'],
  });

  const handleAccept = async () => {
    await acceptTermsOfService({ acceptance_context: TosAcceptanceContext.TOS_PAGE });
    navigate(AppRoute.DASHBOARD);
  };

  return (
    <BaseLayout pageName="AcceptTos" documentTitle="Accept Terms and Conditions" hideNavigation>
      <Box flexGrow={1} display="flex" flexDirection="column" alignItems="center" justifyContent="center" mt={3} p={2}>
        <Box textAlign="center">
          <AnimatedLogo animation={false} size="small" />
        </Box>
        <Card className={classes.card}>
          <CardHeader title="Accept Terms and Conditions" />
          <CardContent>
            <AcceptTosTextBlock />
          </CardContent>
          <CardActions>
            <ProgressButton
              variant="contained"
              loading={isSigningOut}
              onClick={() => handleSignOut()}
              disabled={isAcceptingTerms || isSigningOut}
            >
              Sign Out
            </ProgressButton>
            <ProgressButton
              color="primary"
              variant="contained"
              onClick={() => handleAccept()}
              data-action="signin"
              loading={isAcceptingTerms}
            >
              {ACCEPT_TOS_BUTTON_CAPTION}
            </ProgressButton>
          </CardActions>
        </Card>
      </Box>
    </BaseLayout>
  );
};
