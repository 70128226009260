import { ReactElement, useEffect, useMemo } from 'react';
import { ReactFlow, ReactFlowProvider, useEdgesState, useNodesState, useReactFlow, NodeTypes } from '@xyflow/react';

import { computeDagreGraphLayout, convertGraphToFlowNodesAndEdges } from './utils';
import { Node } from './types';

import '@xyflow/react/dist/style.css';

export interface FlowGraphProps {
  tree: Node;
  nodeTypes?: NodeTypes;
}

// note wrapping is required if we don't want to see a global ReactFlowProvider
const FlowGraphLayout = ({ tree, nodeTypes }: FlowGraphProps) => {
  const { fitView } = useReactFlow();

  const graph = useMemo(() => computeDagreGraphLayout(tree), [tree]);
  const [initialNodes, initialEdges] = useMemo(() => convertGraphToFlowNodesAndEdges(graph), [graph]);

  const [nodes, , onNodesChange] = useNodesState(initialNodes);
  const [edges] = useEdgesState(initialEdges);

  useEffect(() => {
    window.requestAnimationFrame(() => {
      fitView();
    });
  }, []);

  return <ReactFlow nodes={nodes} edges={edges} nodeTypes={nodeTypes} onNodesChange={onNodesChange} fitView />;
};

export const FlowGraph = ({ tree, nodeTypes }: FlowGraphProps): ReactElement => {
  return (
    <ReactFlowProvider>
      <FlowGraphLayout tree={tree} nodeTypes={nodeTypes} />
    </ReactFlowProvider>
  );
};
