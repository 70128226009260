import { DEFAULT_AMPLIFY_ROUTES } from '@localstack/constants';
import { ReactElement } from 'react';
import { CreateAppRequest, UpdateAppRequest } from '@localstack/types';
import { Breadcrumbs, AmplifyAppForm, ProgressButton } from '@localstack/ui';
import { useAwsEffect, useAwsGetter, useRoutes } from '@localstack/services';
import { Box, Card, CardActions, CardContent, Typography } from '@mui/material';

import { useParams } from 'react-router-dom';

import { AmplifyProps } from './types';

export const AmplifyAppUpsert = ({
  Layout,
  clientOverrides,
  routes = DEFAULT_AMPLIFY_ROUTES,
}: AmplifyProps): ReactElement => {
  const { goto } = useRoutes();
  const { appId } = useParams<'appId'>();
  const { data: app } = useAwsGetter('Amplify', 'getApp', [{ appId }], { clientOverrides });
  const { createApp, updateApp, isLoading } = useAwsEffect('Amplify', ['createApp', 'updateApp'], {
    clientOverrides,
    revalidate: ['listApps', 'getApp'],
  });

  const handleCreate = async (data: CreateAppRequest) => {
    if (await createApp(data)) {
      goto(routes.RESOURCES_AMPLIFY_APPS);
    }
  };

  const handleUpdate = async (data: UpdateAppRequest) => {
    if (await updateApp(data)) {
      goto(routes.RESOURCES_AMPLIFY_APP, { appId });
    }
  };

  return (
    <Layout
      title={
        <Box>
          <Typography variant="h4">App Details</Typography>
          <Breadcrumbs
            mappings={[
              ['Amplify', () => goto(routes.RESOURCES_AMPLIFY_APPS)],
              [appId, () => goto(routes.RESOURCES_AMPLIFY_APP, { appId })],
              [app ? 'Update App' : 'Create App', null],
            ]}
          />
        </Box>
      }
      actions={
        <ProgressButton color="primary" variant="outlined" type="submit" form="AmplifyAppUpsert" loading={isLoading}>
          Submit
        </ProgressButton>
      }
    >
      <Card>
        <CardContent>
          <AmplifyAppForm
            formId="AmplifyAppUpsert"
            onSubmit={app ? handleUpdate : handleCreate}
            app={app}
            loading={isLoading}
          />
        </CardContent>
        <CardActions>
          <ProgressButton color="primary" variant="outlined" type="submit" form="AmplifyAppUpsert" loading={isLoading}>
            Submit
          </ProgressButton>
        </CardActions>
      </Card>
    </Layout>
  );
};
