import { getSchema, AwsClientOverrides } from '@localstack/services';
import { CreateResolverEndpointRequest, UpdateResolverEndpointRequest, ResolverEndpoint } from '@localstack/types';
import { ReactElement } from 'react';

import { MagicForm } from '../../magic/MagicForm';

export type Route53ResolverEndpointFormProps = {
  endpoint?: ResolverEndpoint;
  formId: string;
  clientOverrides?: AwsClientOverrides;
  onCreate: (data: CreateResolverEndpointRequest) => unknown;
  onUpdate: (data: UpdateResolverEndpointRequest) => unknown;
  loading?: boolean;
};

export const Route53ResolverEndpointForm = ({
  endpoint,
  formId,
  onCreate,
  onUpdate,
  loading,
}: Route53ResolverEndpointFormProps): ReactElement => (
  <MagicForm
    entry={endpoint ? 'UpdateResolverEndpointRequest' : 'CreateResolverEndpointRequest'}
    schema={getSchema('Route53Resolver')}
    loading={loading}
    formId={formId}
    data={
      endpoint && {
        ResolverEndpointId: endpoint.Id,
        ...endpoint,
      }
    }
    onSubmit={(data: CreateResolverEndpointRequest | UpdateResolverEndpointRequest) =>
      !endpoint
        ? onCreate(data as CreateResolverEndpointRequest)
        : onUpdate({ ...data, ResolverEndpointId: endpoint?.Id || '' })
    }
  />
);
