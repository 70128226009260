import { ReactElement } from 'react';
import { ECRDescribeImages } from '@localstack/types';
import { getSchema } from '@localstack/services';

import { MagicTable } from '../../../magic/MagicTable';

export type ECRImagesTableProps = {
  loading?: boolean;
  selectable?: boolean;
  images: ECRDescribeImages[];
  onSelectImages?: (names: string[]) => void;
};

export const ECRImagesTable = ({
  loading,
  selectable = true,
  images,
  onSelectImages,
}: ECRImagesTableProps): ReactElement => (
  <MagicTable
    schema={getSchema('ECR')}
    entry="ImageDetailList"
    idAttribute="imageDigest"
    order={['imageTags', 'imagePushedAt', 'imageSizeInBytes', 'imageDigest']}
    filterColumns={['imageTags', 'imagePushedAt', 'imageSizeInBytes', 'imageDigest']}
    rows={images}
    selectable={selectable}
    onSelect={onSelectImages}
    loading={loading}
  />
);
