import { useState, useCallback, ReactElement } from 'react';
import { useParams } from 'react-router-dom';
import { Button, Card } from '@mui/material';
import { useRoutes, useAwsEffect, useAwsGetter } from '@localstack/services';
import { CognitoUser } from '@localstack/types';

import { Dropdown, ConfirmableButton, CognitoUsersTable, PageTitle } from '@localstack/ui';

import { DEFAULT_COGNITO_ROUTES } from '@localstack/constants';

import { CognitoProps } from './types';

import { PoolNavTabs } from './components';

export const CognitoPoolUsers = ({
  Layout,
  clientOverrides,
  routes = DEFAULT_COGNITO_ROUTES,
}: CognitoProps): ReactElement => {
  const { goto } = useRoutes();
  const { poolId } = useParams<'poolId'>();

  const [selected, setSelected] = useState<string[]>([]);

  const { data: pool } = useAwsGetter('CognitoIdentityServiceProvider', 'describeUserPool', [{ UserPoolId: poolId }], {
    clientOverrides,
  });

  const {
    data: users,
    isLoading: isUsersLoading,
    mutate,
  } = useAwsGetter('CognitoIdentityServiceProvider', 'listUsers', [{ UserPoolId: poolId }], { clientOverrides });

  const { adminDeleteUser, isLoading: isUserMutating } = useAwsEffect(
    'CognitoIdentityServiceProvider',
    ['adminCreateUser', 'adminDeleteUser'],
    { revalidate: ['listUsers'], clientOverrides },
  );

  const isLoading = isUsersLoading || isUserMutating;

  const handleDeleteObjects = useCallback(
    async () => Promise.all(selected.map((Username) => adminDeleteUser({ UserPoolId: poolId as string, Username }))),
    [selected],
  );

  const adjustedUsers: CognitoUser[] = (users?.Users ?? []).map(
    (u) => ({ ...u, UserAttributes: u.Attributes }) as CognitoUser,
  );

  return (
    <>
      <Layout
        documentTitle="Pool Users"
        tabs={<PoolNavTabs poolId={poolId as string} routes={routes} />}
        title={
          <PageTitle
            title="Pool Users"
            onMutate={mutate}
            breadcrumbs={[
              ['Cognito', () => goto(routes.RESOURCES_COGNITO)],
              [pool?.UserPool?.Name ?? poolId, () => goto(routes.RESOURCES_COGNITO_USER_POOL, { poolId })],
              ['Users', null],
            ]}
          />
        }
        actions={
          <>
            <Button onClick={() => goto(routes.RESOURCES_COGNITO_POOL_USER_CREATE, { poolId })}>Create User</Button>
            <Dropdown label="Actions">
              <ConfirmableButton
                componentType="MenuItem"
                disabled={selected.length === 0 || isLoading}
                title={`Remove ${selected.length} User(s)?`}
                onClick={handleDeleteObjects}
                text="Selected users will be permanently deleted"
              >
                Remove Selected
              </ConfirmableButton>
            </Dropdown>
          </>
        }
      >
        <Card>
          <CognitoUsersTable
            selectable
            loading={isUsersLoading}
            users={adjustedUsers}
            onSelect={setSelected}
            onViewUser={(row) => goto(routes.RESOURCES_COGNITO_POOL_USER, { poolId, username: row.Username })}
          />
        </Card>
      </Layout>
    </>
  );
};
