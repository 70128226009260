import { ReactElement } from 'react';
import { useRoutes, useAwsGetter } from '@localstack/services';
import { useParams } from 'react-router-dom';
import { Card, CardContent, Button, Box, Typography } from '@mui/material';
import { Breadcrumbs, QLDBLedgerDetails } from '@localstack/ui';

import { DEFAULT_QLDB_ROUTES } from '@localstack/constants';

import { QLDBProps } from './types';

export const QLDBLedger = ({ Layout, clientOverrides, routes = DEFAULT_QLDB_ROUTES }: QLDBProps): ReactElement => {
  const { goto } = useRoutes();
  const { name } = useParams<'name'>();

  const { data: ledger } = useAwsGetter('QLDB', 'describeLedger', [{ Name: name }], { clientOverrides });

  return (
    <Layout
      documentTitle="QLDB: Ledger Details"
      title={
        <Box>
          <Typography variant="h4">Ledger Details</Typography>
          <Breadcrumbs mappings={[['Ledgers', () => goto(routes.RESOURCES_QLDB_LEDGERS)]]} />
        </Box>
      }
      actions={<Button onClick={() => goto(routes.RESOURCES_QLDB_LEDGER_UPDATE, { name })}>Edit Ledger</Button>}
    >
      <Card>
        <CardContent>
          <QLDBLedgerDetails ledger={ledger} />
        </CardContent>
      </Card>
    </Layout>
  );
};
