import { ReactElement } from 'react';
import { useParams } from 'react-router-dom';
import { Card, CardContent, CardActions, Box, Typography } from '@mui/material';
import { ProgressButton, Breadcrumbs, ECSTaskForm } from '@localstack/ui';
import { ECSTaskDefinition, RunECSTaskParams } from '@localstack/types';
import { useRoutes, useAwsEffect, useAwsGetter, useAwsBatchIterator } from '@localstack/services';

import { DEFAULT_ECS_ROUTES } from '@localstack/constants';

import { ECSProps } from './types';

export const ECSClusterTaskUpsert = ({
  Layout,
  clientOverrides,
  routes = DEFAULT_ECS_ROUTES,
}: ECSProps): ReactElement => {
  const { goto } = useRoutes();

  const { clusterName } = useParams<'clusterName'>();

  const { describeTaskDefinition } = useAwsEffect('ECS', ['describeTaskDefinition'], { clientOverrides });

  const { data: definitionArns } = useAwsGetter('ECS', 'listTaskDefinitions', [], { clientOverrides });

  const definitions = useAwsBatchIterator(
    definitionArns?.taskDefinitionArns,
    async (taskDefinition) => (await describeTaskDefinition({ taskDefinition })).taskDefinition as ECSTaskDefinition,
  );

  const { runTask, isLoading: isTaskMutating } = useAwsEffect('ECS', ['runTask'], {
    revalidate: ['listTasks', 'describeTasks'],
    clientOverrides,
  });

  const handleCreate = async (data: RunECSTaskParams) => {
    if (await runTask(data)) {
      goto(routes.RESOURCES_ECS_CLUSTER_TASKS, { clusterName });
    }
  };

  return (
    <Layout
      documentTitle="Task Details"
      title={
        <Box>
          <Typography variant="h4">Task Details</Typography>
          <Breadcrumbs
            mappings={[
              ['ECS', () => goto(routes.RESOURCES_ECS)],
              ['Clusters', () => goto(routes.RESOURCES_ECS_CLUSTERS)],
              [clusterName, () => goto(routes.RESOURCES_ECS_CLUSTER, { clusterName })],
              ['Tasks', () => goto(routes.RESOURCES_ECS_CLUSTER_TASKS, { clusterName })],
              ['Deploy Task', null],
            ]}
          />
        </Box>
      }
      actions={
        <ProgressButton type="submit" form="update" variant="outlined" color="primary" loading={isTaskMutating}>
          Submit
        </ProgressButton>
      }
    >
      <Card>
        <CardContent>
          <ECSTaskForm
            cluster={clusterName as string}
            definitions={definitions}
            loading={isTaskMutating}
            formId="update"
            clientOverrides={clientOverrides}
            onCreate={handleCreate}
          />
        </CardContent>
        <CardActions>
          <ProgressButton type="submit" form="update" variant="contained" color="primary" loading={isTaskMutating}>
            Submit
          </ProgressButton>
        </CardActions>
      </Card>
    </Layout>
  );
};
