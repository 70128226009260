import { ReactElement } from 'react';

export const ChartDot = (props: {
  cx: number;
  cy: number;
  index: number;
  total: number;
  value: number;
}): ReactElement => {
  const { cx, cy, index, total, value } = props;

  if (index === total - 1 && value !== 0) {
    const radius = 5;
    const textY = cy + radius + 12;
    return (
      <g>
        <circle cx={cx} cy={cy} r={radius} fill="black" />
        <text x={cx} y={textY} textAnchor="middle" dy="0.3em" fill="black" fontSize="12">
          {value}
        </text>
      </g>
    );
  }

  return <></>;
};
