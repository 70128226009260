import { ReactElement } from 'react';
import { useParams } from 'react-router-dom';
import { Card, CardContent, CardActions, Box, Typography } from '@mui/material';
import { CreateCognitoGroupRequest, UpdateCognitoGroupRequest } from '@localstack/types';
import { useRoutes, useAwsEffect, useAwsGetter } from '@localstack/services';
import { ProgressButton, CognitoGroupForm, Breadcrumbs } from '@localstack/ui';

import { DEFAULT_COGNITO_ROUTES } from '@localstack/constants';

import { PoolNavTabs } from './components';

import { CognitoProps } from './types';

export const CognitoPoolGroupUpsert = ({
  Layout,
  clientOverrides,
  routes = DEFAULT_COGNITO_ROUTES,
}: CognitoProps): ReactElement => {
  const { goto } = useRoutes();
  const { poolId, groupName } = useParams<'poolId' | 'groupName'>();

  const { data: pool } = useAwsGetter('CognitoIdentityServiceProvider', 'describeUserPool', [{ UserPoolId: poolId }], {
    clientOverrides,
  });

  const { data: group } = useAwsGetter(
    'CognitoIdentityServiceProvider',
    'getGroup',
    [{ UserPoolId: poolId, GroupName: groupName }],
    { clientOverrides },
  );

  const { createGroup, updateGroup, isLoading } = useAwsEffect(
    'CognitoIdentityServiceProvider',
    ['createGroup', 'updateGroup'],
    { revalidate: ['listGroups', 'adminListGroupsForUser'], clientOverrides },
  );

  const handleCreate = async (data: CreateCognitoGroupRequest) => {
    await createGroup(data);
    goto(routes.RESOURCES_COGNITO_POOL_GROUPS, { poolId });
  };

  const handleUpdate = async (data: UpdateCognitoGroupRequest) => {
    await updateGroup(data);
    goto(routes.RESOURCES_COGNITO_POOL_GROUP, { poolId, groupName });
  };

  return (
    <Layout
      documentTitle="Group Details"
      title={
        <Box>
          <Typography variant="h4">Group Details</Typography>
          <Breadcrumbs
            mappings={[
              ['Cognito', () => goto(routes.RESOURCES_COGNITO)],
              [pool?.UserPool?.Name ?? poolId, () => goto(routes.RESOURCES_COGNITO_USER_POOL, { poolId })],
              ['Groups', () => goto(routes.RESOURCES_COGNITO_POOL_GROUPS, { poolId })],
              [groupName, () => goto(routes.RESOURCES_COGNITO_POOL_GROUP, { poolId, groupName })],
              [groupName ? 'Update' : 'Create Group', null],
            ]}
          />
        </Box>
      }
      tabs={poolId && <PoolNavTabs poolId={poolId} routes={routes} />}
      actions={
        <ProgressButton type="submit" form="update" variant="outlined" color="primary" loading={isLoading}>
          Submit
        </ProgressButton>
      }
    >
      <Card>
        <CardContent>
          <CognitoGroupForm
            poolId={poolId as string}
            group={group?.Group}
            clientOverrides={clientOverrides}
            onCreate={handleCreate}
            onUpdate={handleUpdate}
            loading={isLoading}
            formId="update"
          />
        </CardContent>
        <CardActions>
          <ProgressButton type="submit" form="update" variant="contained" color="primary" loading={isLoading}>
            Submit
          </ProgressButton>
        </CardActions>
      </Card>
    </Layout>
  );
};
