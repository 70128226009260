import { AwsClientOverrides, getSchema } from '@localstack/services';
import { DescribeClusterResponse, CreateClusterRequest, UpdateClusterConfigRequest } from '@localstack/types';
import { ReactElement } from 'react';
import { DEFAULT_IAM_ROUTES } from '@localstack/constants';

import { MagicForm } from '../../magic/MagicForm';
import { RelatedResourcePicker } from '../../../form/RelatedResourcePicker';

export type EKSClusterFormProps = {
  cluster?: Optional<Partial<DescribeClusterResponse>>;
  formId: string;
  onSubmit: (data: CreateClusterRequest | UpdateClusterConfigRequest) => void;
  loading?: boolean;
  clientOverrides?: AwsClientOverrides;
};

export const EKSClusterForm = ({
  cluster,
  formId,
  onSubmit,
  loading,
  clientOverrides,
}: EKSClusterFormProps): ReactElement => (
  <MagicForm
    entry={cluster ? 'UpdateClusterConfigRequest' : 'CreateClusterRequest'}
    schema={getSchema('EKS')}
    loading={loading}
    externalFields={{
      '^roleArn$': (control, fieldName, required) => (
        <RelatedResourcePicker
          control={control}
          client="IAM"
          method="listRoles"
          arrayKeyName="Roles"
          property="Arn"
          fieldName={fieldName}
          entityName="IAM Role"
          required={required}
          clientOverrides={clientOverrides}
          creationRoute={DEFAULT_IAM_ROUTES.RESOURCES_IAM_ROLE_CREATE}
        />
      ),
    }}
    onSubmit={onSubmit}
    formId={formId}
    data={cluster?.cluster}
  />
);
