import dagre from '@dagrejs/dagre';

import { GraphLayout, Node } from '../types';

const GROUP_NODE_PREFIX = '$__group__';

const createDagreGraph = (): dagre.graphlib.Graph => {
  const dagreGraph = new dagre.graphlib.Graph({ compound: true });

  dagreGraph.setGraph({
    rankdir: 'TB',
    ranksep: 20, // Vertical separation between nodes
    nodesep: 10, // Horizontal separation between nodes
    marginx: 10, // Horizontal margin around the graph
    marginy: 10, // Vertical margin around the graph
  });

  dagreGraph.setDefaultEdgeLabel(() => ({}));

  return dagreGraph;
};

/**
 * Recursively prepare the graph layout nodes for Dagre.
 * This is required to comply with the Dagre node definition order.
 */
const buildGraphLayout = (rootNode: Node): GraphLayout => {
  const layout: GraphLayout = {
    nodes: [],
    edges: [],
    relations: [],
  };

  const isGroupingNode = (rootNode.children || []).length > 0;

  // grouping nodes cannot be directly used for connections, we therefore create a dummy node to represent the group
  if (isGroupingNode) {
    layout.nodes.push({ id: `${GROUP_NODE_PREFIX}${rootNode.id}`, type: 'group' });
    layout.relations.push({ parent: `${GROUP_NODE_PREFIX}${rootNode.id}`, child: rootNode.id });
  }

  // add the node itself
  layout.nodes.push({ id: rootNode.id, width: rootNode.width, height: rootNode.height, type: rootNode.type });

  // collect nodes/edges/relations from nested sub-graphs
  [...(rootNode.children || []), ...(rootNode.next || [])].forEach((node) => {
    const subLayout = buildGraphLayout(node);

    layout.nodes.push(...subLayout.nodes);
    layout.edges.push(...subLayout.edges);
    layout.relations.push(...subLayout.relations);
  });

  // establish relations between the grouping node and its children
  (rootNode.children || []).forEach((node) => {
    layout.relations.push({ parent: `${GROUP_NODE_PREFIX}${rootNode.id}`, child: node.id });

    // add all directly enclosed nodes to the group to properly compute its bounding box
    if (isGroupingNode) {
      (node.next || []).forEach((nextNode) => {
        layout.relations.push({ parent: `${GROUP_NODE_PREFIX}${rootNode.id}`, child: nextNode.id });
      });
    }
  });

  // add edges to next nodes
  (rootNode.next || []).forEach((node) => {
    layout.edges.push({ source: rootNode.id, target: node.id });
  });

  return layout;
};

/**
 * Center parent nodes around their children.
 *
 * Dagre does not re-center grouping node to wrap around its children.
 * This function calculates the right position for parent nodes based on
 * their children and mutates the graph to reflect the changes.
 */
const centerParentNodes = (graph: dagre.graphlib.Graph) => {
  graph.nodes().forEach((nodeId) => {
    const node = graph.node(nodeId);

    const childrenIds = (graph.children(nodeId) || []) as string[]; // wrong type declaration
    const childrenNodes = childrenIds.map((childId) => graph.node(childId));

    if (childrenNodes.length === 0) {
      return;
    }

    const minX = Math.min(...childrenNodes.map((child) => child.x));
    const maxX = Math.max(...childrenNodes.map((child) => child.x + child.width));

    const minY = Math.min(...childrenNodes.map((child) => child.y));
    const maxY = Math.max(...childrenNodes.map((child) => child.y + child.height));

    const childrenWidth = Math.abs(maxX - minX);
    const childrenHeight = Math.abs(maxY - minY);

    node.x = minX - (node.width - childrenWidth) / 2;
    node.y = minY - (node.height - childrenHeight) / 2;
  });
};

const flattenTree = (tree: Node): Record<string, Node> => {
  const nodes: Record<string, Node> = {};

  const visit = (node: Node) => {
    nodes[node.id] = node;

    (node.children || []).forEach(visit);
    (node.next || []).forEach(visit);
  };

  visit(tree);

  return nodes;
};

// const

/**
 * Get the local position of a node within its parent.
 *
 * Dagre computes the layout in global coordinates which might not work with
 * some renderers (like react-flow) that expect local coordinates for child nodes.
 */
export const getLocalPosition = (nodeId: string, graph: dagre.graphlib.Graph): { x: number; y: number } => {
  const thisNode = graph.node(nodeId);

  const parentNodeId = graph.parent(nodeId);
  const parentNode = parentNodeId ? graph.node(parentNodeId) : null;

  if (parentNode) {
    return {
      x: thisNode.x - parentNode.x,
      y: thisNode.y - parentNode.y,
    };
  }

  return { x: thisNode.x, y: thisNode.y };
};

/**
 * Compute an optimized graph layout using Dagre.
 */
export const computeDagreGraphLayout = (tree: Node): dagre.graphlib.Graph => {
  const layout = buildGraphLayout(tree);
  const graph = createDagreGraph();

  const treeNodes = flattenTree(tree);

  for (const node of layout.nodes) {
    graph.setNode(node.id, node.width && node.height ? { width: node.width, height: node.height } : {});

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-expect-error
    graph.node(node.id).data = treeNodes[node.id]; // a way to propagate the data through the graph
    graph.node(node.id).class = node.type; // a way to propagate the type of the node through the graph
  }

  for (const edge of layout.edges) {
    graph.setEdge(edge.source, edge.target);
  }

  for (const parent of layout.relations) {
    graph.setParent(parent.child, parent.parent);
  }

  dagre.layout(graph);

  centerParentNodes(graph);

  return graph;
};
