import { ReactElement } from 'react';
import { Controller, Control, ControllerProps } from 'react-hook-form';
import { TextField, TextFieldProps, MenuItem } from '@mui/material';

/**
 * Creates an array of MenuItem from an object.
 */
export const fromObject = (options: Record<string, string>): JSX.Element[] =>
  Object.entries(options).map(([key, value]) => (
    <MenuItem key={key} value={value}>
      {key}
    </MenuItem>
  ));

/**
 * Creates an array of MenuItem from an array of strings.
 */
export const buildOptionsFromArray = (options: string[]): JSX.Element[] =>
  options.map((value) => (
    <MenuItem key={value} value={value}>
      {value}
    </MenuItem>
  ));

type Props = TextFieldProps &
  Omit<ControllerProps, 'control' | 'render'> & {
    control: Control<any>; // eslint-disable-line
    options: JSX.Element[];
    required?: boolean;
    multiple?: boolean;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    multipleDisplay?: (value: any) => ReactElement;
  };

export const ControlledSelect = ({ control, options, multiple, multipleDisplay, ...rest }: Props): ReactElement => (
  <Controller
    {...rest}
    control={control}
    render={({ field, fieldState }) => (
      <TextField
        variant="standard"
        {...rest}
        {...field}
        select
        SelectProps={{
          ...(multiple && { multiple }),
          renderValue: multiple && multipleDisplay ? (value) => multipleDisplay(value) : undefined,
        }}
        value={field.value || (multiple ? [] : '')}
        error={fieldState.isTouched && Boolean(fieldState.error)}
        helperText={fieldState.isTouched && fieldState.error?.message}
      >
        {options}
      </TextField>
    )}
  />
);

export default ControlledSelect;
