import { ReactElement } from 'react';
import { useRoutes, useAwsEffect, useAwsGetter, useSnackbar } from '@localstack/services';
import { Card, CardContent, CardActions, Box, Typography } from '@mui/material';
import { ProgressButton, S3BucketCorsCreateForm, Breadcrumbs } from '@localstack/ui';
import { useParams } from 'react-router-dom';
import { DEFAULT_S3_ROUTES } from '@localstack/constants';
import { PutS3BucketCorsRequest } from '@localstack/types';

import { S3Props } from './types';
import { PermissionsTabs } from './components';

export const S3BucketPermissionsCorsConfiguration = ({
  Layout,
  clientOverrides,
  routes = DEFAULT_S3_ROUTES,
}: S3Props): ReactElement => {
  const { goto } = useRoutes();
  const { bucket } = useParams<'bucket'>();
  const { showSnackbar } = useSnackbar();

  const {
    putBucketCors,
    deleteBucketCors,
    isLoading: formSubmitting,
  } = useAwsEffect('S3', ['putBucketCors', 'deleteBucketCors'], {
    revalidate: ['getBucketCors'],
    clientOverrides: { s3ForcePathStyle: true, ...clientOverrides },
  });

  const {
    data: corsConfiguration,
    isLoading,
    mutate,
  } = useAwsGetter('S3', 'getBucketCors', [{ Bucket: bucket }], {
    clientOverrides: { s3ForcePathStyle: true, ...clientOverrides },
    silentErrors: true,
    swrOverrides: { shouldRetryOnError: false },
  });

  const showError = (error: string) =>
    showSnackbar({
      message: error,
      severity: 'error',
    });

  const handleSubmit = async (data: PutS3BucketCorsRequest) => {
    let res = null;
    if (!data.CORSConfiguration?.CORSRules.length) {
      res = await deleteBucketCors({ Bucket: bucket ?? '' });
    } else {
      res = await putBucketCors(data);
    }
    if (res) {
      showSnackbar({
        message: 'CORS Configuration updated successfully',
        severity: 'success',
      });
      mutate();
    }
  };

  return (
    <Layout
      documentTitle="S3: Update CORS Configuration"
      title={
        <Box>
          <Typography variant="h4">Update CORS Configuration</Typography>
          <Breadcrumbs
            mappings={[
              ['Buckets', () => goto(routes.RESOURCES_S3)],
              [bucket, () => goto(routes.RESOURCES_S3_BUCKET, { bucket })],
              ['Permissions', () => goto(routes.RESOURCES_S3_BUCKET_PERMISSIONS_CORS_CONFIGURATION, { bucket })],
              ['Update CORS Configuration', null],
            ]}
          />
        </Box>
      }
      tabs={<PermissionsTabs routes={routes} bucket={bucket ?? ''} />}
      actions={
        <ProgressButton type="submit" form="PutBucketCors" variant="outlined" color="primary" loading={isLoading}>
          Submit
        </ProgressButton>
      }
    >
      <Card>
        <CardContent>
          <S3BucketCorsCreateForm
            corsConfiguration={corsConfiguration}
            bucket={bucket ?? ''}
            onSubmit={handleSubmit}
            loading={formSubmitting || isLoading}
            formId="PutBucketCors"
            showError={showError}
          />
        </CardContent>
        <CardActions>
          <ProgressButton
            type="submit"
            form="PutBucketCors"
            variant="contained"
            color="primary"
            loading={formSubmitting || isLoading}
          >
            Submit
          </ProgressButton>
        </CardActions>
      </Card>
    </Layout>
  );
};
