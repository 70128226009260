import { ReactElement } from 'react';
import { useRoutes, useAwsEffect, useAwsGetter } from '@localstack/services';
import { useParams } from 'react-router-dom';
import { Card, CardContent, CardActions, Box, Typography } from '@mui/material';
import { CreateClusterParams, UpdateClusterParams } from '@localstack/types';
import { ProgressButton, RDSClusterForm, Breadcrumbs } from '@localstack/ui';

import { DEFAULT_RDS_ROUTES } from '@localstack/constants';

import { RDSProps } from './types';

export const RDSDBClusterUpsert = ({
  Layout,
  clientOverrides,
  routes = DEFAULT_RDS_ROUTES,
}: RDSProps): ReactElement => {
  const { goto } = useRoutes();
  const { clusterId } = useParams<'clusterId'>();

  const { createDBCluster, modifyDBCluster, isLoading } = useAwsEffect('RDS', ['createDBCluster', 'modifyDBCluster'], {
    revalidate: ['describeGlobalClusters', 'describeDBClusters', 'describeDBInstances'],
    clientOverrides,
  });

  const { data: clusters } = useAwsGetter('RDS', 'describeDBClusters', [{ DBClusterIdentifier: clusterId }], {
    clientOverrides,
  });
  const cluster = clusters?.DBClusters?.find((inst) => inst.DBClusterIdentifier === clusterId);

  const handleCreate = async (data: CreateClusterParams) => {
    await createDBCluster(data);
    goto(routes.RESOURCES_RDS_DATABASES);
  };

  const handleUpdate = async (data: UpdateClusterParams) => {
    await modifyDBCluster(data);
    goto(routes.RESOURCES_RDS_CLUSTER, { clusterId });
  };

  return (
    <Layout
      documentTitle="RDS: Create Cluster"
      title={
        <Box>
          <Typography variant="h4">Create Cluster</Typography>
          <Breadcrumbs
            mappings={[
              ['RDS', () => goto(routes.RESOURCES_RDS_DATABASES)],
              ['Create Cluster', null],
            ]}
          />
        </Box>
      }
      actions={
        <ProgressButton type="submit" form="update" variant="outlined" color="primary" loading={isLoading}>
          Submit
        </ProgressButton>
      }
    >
      <Card>
        <CardContent>
          <RDSClusterForm
            cluster={cluster}
            clientOverrides={clientOverrides}
            onCreate={handleCreate}
            onUpdate={handleUpdate}
            loading={isLoading}
            formId="update"
          />
        </CardContent>
        <CardActions>
          <ProgressButton type="submit" form="update" variant="contained" color="primary" loading={isLoading}>
            Submit
          </ProgressButton>
        </CardActions>
      </Card>
    </Layout>
  );
};
