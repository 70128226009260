import { ReactElement } from 'react';
import { GlueJob } from '@localstack/types';
import { getSchema } from '@localstack/services';
import { Link } from '@mui/material';

import { MagicTable } from '../../../magic/MagicTable';

export type GlueJobsTableProps = {
  jobs: GlueJob[];
  selectable?: boolean;
  loading?: boolean;
  onSelect?: (names: string[]) => void;
  onViewJob?: (name: string) => void;
};

export const GlueJobsTable = ({
  jobs,
  selectable = true,
  loading,
  onSelect,
  onViewJob,
}: GlueJobsTableProps): ReactElement => (
  <MagicTable
    schema={getSchema('Glue')}
    entry="Job"
    idAttribute="Name"
    rows={jobs}
    selectable={selectable}
    onSelect={onSelect}
    loading={loading}
    filterColumns={['Name', 'Description', 'Role', 'CreatedOn', 'LastModifiedOn']}
    externalFields={{
      Name: (row) => (
        <Link onClick={() => onViewJob && onViewJob(row.Name as string)} underline="hover">
          {row.Name}
        </Link>
      ),
    }}
  />
);
