import { ReactElement, useEffect } from 'react';
import { Card, CardContent, CardActions, Box, Typography } from '@mui/material';
import { useRoutes, useAwsEffect, useAwsGetter } from '@localstack/services';
import { ProgressButton, Breadcrumbs, BackupPlanUpsertForm } from '@localstack/ui';
import { useParams } from 'react-router-dom';
import { BackupPlan, CreateBackupPlanRequest, UpdateBackupPlanRequest } from '@localstack/types';

import { DEFAULT_BACKUP_ROUTES } from '@localstack/constants/src';

import { BackupProps } from './types';

export const BackupPlanUpsert = ({
  Layout,
  clientOverrides,
  routes = DEFAULT_BACKUP_ROUTES,
}: BackupProps): ReactElement => {
  const { goto } = useRoutes();
  const { id } = useParams<'id'>();

  const {
    createBackupPlan,
    updateBackupPlan,
    isLoading: isPlansMutating,
  } = useAwsEffect('Backup', ['updateBackupPlan', 'createBackupPlan'], {
    revalidate: ['listBackupPlans', 'getBackupPlan'],
    clientOverrides,
  });

  const { data: backupPlan, isLoading } = useAwsGetter('Backup', 'getBackupPlan', [{ BackupPlanId: id }], {
    clientOverrides,
  });

  const { data: backupVaults, isLoading: isListLoading } = useAwsGetter('Backup', 'listBackupVaults', [], {
    clientOverrides,
  });

  const { createBackupVault } = useAwsEffect('Backup', ['createBackupVault'], {
    revalidate: ['listBackupVaults'],
    clientOverrides,
  });

  useEffect(() => {
    if (!isListLoading && backupVaults?.BackupVaultList?.length === 0) {
      createBackupVault({ BackupVaultName: 'default-backup-vault' });
    }
  }, [isListLoading]);

  const handleCreateBackupPlan = async (data: CreateBackupPlanRequest) => {
    await createBackupPlan(data);
    goto(routes.RESOURCES_BACKUP_PLANS);
  };

  const handleUpdateBackupPlan = async (data: UpdateBackupPlanRequest) => {
    await updateBackupPlan(data);
    goto(routes.RESOURCES_BACKUP_PLANS);
  };

  return (
    <Layout
      title={
        <Box>
          <Typography variant="h4">Backup Plan</Typography>
          <Breadcrumbs
            mappings={[
              ['Plans', () => goto(routes.RESOURCES_BACKUP_PLANS)],
              [id, () => goto(routes.RESOURCES_BACKUP_PLAN, { id })],
              [id ? 'Update Plan' : 'Create Plan', null],
            ]}
          />
        </Box>
      }
      actions={
        <ProgressButton
          type="submit"
          form="UpdateBackupPlan"
          variant="outlined"
          color="primary"
          loading={isPlansMutating}
        >
          Submit
        </ProgressButton>
      }
    >
      <Card>
        <CardContent>
          <BackupPlanUpsertForm
            onCreate={handleCreateBackupPlan}
            onUpdate={handleUpdateBackupPlan}
            formId="UpdateBackupPlan"
            vaultList={backupVaults?.BackupVaultList || []}
            plan={backupPlan as BackupPlan}
            loading={isLoading}
          />
        </CardContent>
        <CardActions>
          <ProgressButton
            type="submit"
            form="UpdateBackupPlan"
            variant="contained"
            color="primary"
            loading={isPlansMutating}
          >
            Submit
          </ProgressButton>
        </CardActions>
      </Card>
    </Layout>
  );
};
