import { ReactElement, ReactNode, MouseEvent } from 'react';
import { Theme } from '@mui/material/styles';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import { ListItem, ListItemSecondaryAction, ListItemText, Button } from '@mui/material';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    action: {
      '& button': {
        borderColor: theme.palette.error.main,
        color: theme.palette.error.main,
        fontWeight: 'bold',
      },
    },
  }),
);

export type DangerZoneActionProps = {
  primaryText: string;
  secondaryText?: string;
  actionText?: string;
  onClick?: (event: MouseEvent<HTMLButtonElement>) => unknown;
  renderButton?: () => ReactNode;
};

export const DangerZoneAction = ({
  primaryText,
  secondaryText,
  actionText,
  onClick,
  renderButton,
}: DangerZoneActionProps): ReactElement => {
  const classes = useStyles();

  return (
    <ListItem>
      <ListItemText primary={primaryText} secondary={secondaryText} />
      {actionText && (onClick || renderButton) && (
        <ListItemSecondaryAction className={classes.action}>
          {renderButton && renderButton()}
          {!renderButton && (
            <Button variant="outlined" onClick={onClick}>
              {actionText}
            </Button>
          )}
        </ListItemSecondaryAction>
      )}
    </ListItem>
  );
};

export default DangerZoneAction;
