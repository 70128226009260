import { useState, ReactElement, ReactNode, FunctionComponent, MouseEvent } from 'react';

import {
  Button,
  IconButton,
  ButtonProps,
  MenuItem,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Link,
  LinkProps,
  Theme,
  MenuItemProps,
} from '@mui/material';

import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';

import { DangerZoneAction, DangerZoneActionProps } from '../../display';
import { ProgressButtonProps, ProgressButton } from '../ProgressButton';
import { NewTabLink, NewTabLinkProps } from '../../navigation';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    linkButton: {
      display: 'inline-block',
      padding: '10px 16px',
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.common.white,
      borderRadius: '8px',
      transition: 'background-color 0.3s ease',
      fontSize: theme.typography.button.fontSize,
      '&:hover': {
        backgroundColor: theme.palette.primary.dark,
        textDecoration: 'none',
      },
    },
  }),
);

const COMPONENTS_MAP = {
  Button,
  IconButton,
  MenuItem,
  DangerZoneAction,
  Link,
  ProgressButton,
  fallback: Button,
};

const CONFIRM_BUTTON_COMPONENTS_MAP = {
  Button,
  NewTabLink,
  fallback: Button,
};

type ConfirmButtonComponentName = keyof typeof CONFIRM_BUTTON_COMPONENTS_MAP;
type ConfirmButtonProps = {
  component: ConfirmButtonComponentName;
  props?: ButtonProps | NewTabLinkProps;
};

export type BaseProps = {
  title: string;
  text?: string | JSX.Element;
  okText?: string;
  cancelText?: string;
  children?: ReactNode;
  disableOk?: boolean;
  disableCancel?: boolean;
  confirmButton?: ConfirmButtonProps;
  onBeforeShowDialog?: () => unknown;
  onAfterHideDialog?: () => unknown;
};

type ComponentButton = (ButtonProps & { componentType: 'Button' }) & BaseProps;
type ComponentIconButton = (ButtonProps & { componentType: 'IconButton' }) & BaseProps;
type ComponentMenuItem = (MenuItemProps & { componentType: 'MenuItem' }) & BaseProps;
type ComponentDangerZoneAction = (DangerZoneActionProps & { componentType: 'DangerZoneAction' }) & BaseProps;
type ComponentLink = (LinkProps & { componentType: 'Link' }) & BaseProps;
type ComponentProgressButton = (ProgressButtonProps & { componentType: 'ProgressButton' }) & BaseProps;

export type ConfirmableButtonProps =
  | ComponentButton
  | ComponentIconButton
  | ComponentMenuItem
  | ComponentDangerZoneAction
  | ComponentLink
  | ComponentProgressButton;

export const ConfirmableButton = ({
  title,
  text,
  componentType = 'Button',
  okText,
  cancelText,
  children,
  disableOk,
  disableCancel,
  confirmButton = { component: 'Button' },
  onBeforeShowDialog,
  onAfterHideDialog,
  ...rest
}: ConfirmableButtonProps): ReactElement => {
  const classes = useStyles();
  const [showConfirmDialog, setShowConfirmDialog] = useState(false);

  // eslint-disable-next-line
  const Component: FunctionComponent<any> = COMPONENTS_MAP[componentType] || COMPONENTS_MAP.fallback;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const ConfirmButtonComponent: FunctionComponent<any> =
    CONFIRM_BUTTON_COMPONENTS_MAP[confirmButton.component] || CONFIRM_BUTTON_COMPONENTS_MAP.fallback;

  const showDialog = () => {
    onBeforeShowDialog?.();
    setShowConfirmDialog(true);
  };

  const hideDialog = () => {
    setShowConfirmDialog(false);
    onAfterHideDialog?.();
  };

  return (
    <>
      <Dialog open={showConfirmDialog} onClose={() => setShowConfirmDialog(false)}>
        <DialogTitle>{title}</DialogTitle>
        {text && (
          <DialogContent>
            {typeof text === 'string' ? <DialogContentText>{text}</DialogContentText> : text}
          </DialogContent>
        )}
        <DialogActions>
          <Button disabled={disableCancel} onClick={hideDialog}>
            {cancelText || 'Cancel'}
          </Button>
          {confirmButton.component === 'NewTabLink' ? (
            <span role="button" tabIndex={0} onClick={hideDialog}>
              <ConfirmButtonComponent
                {...confirmButton.props}
                type="Link"
                color="secondary"
                className={classes.linkButton}
              >
                {okText || 'Continue'}
              </ConfirmButtonComponent>
            </span>
          ) : (
            <ConfirmButtonComponent
              {...confirmButton.props}
              autoFocus
              disabled={disableOk}
              color="primary"
              variant="contained"
              onClick={(event: MouseEvent<HTMLButtonElement>) => {
                if (rest.onClick) rest.onClick(event as any); // eslint-disable-line
                setShowConfirmDialog(false);
              }}
            >
              {okText || 'Continue'}
            </ConfirmButtonComponent>
          )}
        </DialogActions>
      </Dialog>
      <Component {...rest} onClick={showDialog}>
        {children}
      </Component>
    </>
  );
};
