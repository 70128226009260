import { ReactElement, useState, useCallback } from 'react';
import { Button, Card } from '@mui/material';
import { useRoutes, useAwsGetter, useAwsEffect } from '@localstack/services';
import { Dropdown, PageTitle, ConfirmableButton, NeptuneInstancesTable } from '@localstack/ui';

import { DEFAULT_NEPTUNE_ROUTES } from '@localstack/constants';

import { NeptuneProps } from './types';
import { NavTabs } from './components/NavTabs';

export const NeptuneDBInstances = ({
  Layout,
  clientOverrides,
  routes = DEFAULT_NEPTUNE_ROUTES,
}: NeptuneProps): ReactElement => {
  const { goto } = useRoutes();
  const [selected, setSelected] = useState<string[]>([]);

  const {
    data: instances,
    isLoading,
    mutate,
  } = useAwsGetter('Neptune', 'describeDBInstances', [], { clientOverrides });
  const { deleteDBInstance } = useAwsEffect('Neptune', ['deleteDBInstance'], {
    revalidate: ['describeDBInstances'],
    clientOverrides,
  });

  const handleDeleteObjects = useCallback(
    async () => Promise.all(selected.map((DBInstanceIdentifier) => deleteDBInstance({ DBInstanceIdentifier }))),
    [selected],
  );

  return (
    <Layout
      title={<PageTitle title="Neptune Database Instances" onMutate={mutate} />}
      tabs={<NavTabs routes={routes} />}
      actions={
        <>
          <Button onClick={() => goto(routes.RESOURCES_NEPTUNE_INSTANCE_CREATE)}>Create Instance</Button>
          <Dropdown label="Actions">
            <ConfirmableButton
              componentType="MenuItem"
              disabled={selected.length === 0 || isLoading}
              title={`Remove ${selected.length} instance(s)?`}
              onClick={handleDeleteObjects}
              text="Selected instances will be permanently deleted"
            >
              Remove Selected
            </ConfirmableButton>
          </Dropdown>
        </>
      }
    >
      <Card>
        <NeptuneInstancesTable
          selectable
          loading={isLoading}
          instances={instances?.DBInstances ?? []}
          onSelect={setSelected}
          onViewInstance={(instance) =>
            goto(routes.RESOURCES_NEPTUNE_INSTANCE, { instanceId: instance.DBInstanceIdentifier })
          }
        />
      </Card>
    </Layout>
  );
};
