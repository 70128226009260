import { ReactElement } from 'react';
import { useParams } from 'react-router-dom';
import { Card, CardContent, CardActions, Box, Typography } from '@mui/material';
import { CreateAgwRestApiModelRequest, UpdateAgwRestApiModelRequest } from '@localstack/types';
import { useRoutes, useAwsEffect, useAwsGetter } from '@localstack/services';
import { ProgressButton, AgwRestApiModelForm, Breadcrumbs } from '@localstack/ui';
import { DEFAULT_API_GATEWAY_ROUTES } from '@localstack/constants';

import { ApiGatewayProps } from './types';
import { RestApiNavTabs } from './components';

export const AgwRestApiModelUpsert = ({
  Layout,
  clientOverrides,
  routes = DEFAULT_API_GATEWAY_ROUTES,
}: ApiGatewayProps): ReactElement => {
  const { goto } = useRoutes();
  const { restApiId, modelName } = useParams<'restApiId' | 'modelName'>();

  const { data: api } = useAwsGetter('APIGateway', 'getRestApi', [{ restApiId }], { clientOverrides });
  const { data: model } = useAwsGetter('APIGateway', 'getModel', [{ restApiId, modelName }], { clientOverrides });

  const { createModel, updateModel, isLoading } = useAwsEffect('APIGateway', ['createModel', 'updateModel'], {
    revalidate: ['getModel', 'getModels'],
    clientOverrides,
  });

  const handleCreate = async (data: CreateAgwRestApiModelRequest) => {
    await createModel(data);
    goto(routes.RESOURCES_AGW1_API_MODELS, { restApiId });
  };

  const handleUpdate = async (data: UpdateAgwRestApiModelRequest) => {
    await updateModel(data);
    goto(routes.RESOURCES_AGW1_API_MODEL, { restApiId, modelName });
  };

  return (
    <Layout
      title={
        <Box>
          <Typography variant="h4">Model Details</Typography>
          <Breadcrumbs
            mappings={[
              ['API Gateway', () => goto(routes.RESOURCES_AGW1)],
              [api?.name, () => goto(routes.RESOURCES_AGW1_API, { restApiId })],
              [model?.name, () => goto(routes.RESOURCES_AGW1_API_MODEL, { restApiId, modelName })],
              [modelName ? 'Update' : 'Create Model', null],
            ]}
          />
        </Box>
      }
      tabs={<RestApiNavTabs restApiId={restApiId as string} routes={routes} />}
      actions={
        <ProgressButton type="submit" form="update" variant="outlined" color="primary" loading={isLoading}>
          Submit
        </ProgressButton>
      }
    >
      <Card>
        <CardContent>
          <AgwRestApiModelForm
            restApiId={restApiId as string}
            model={model}
            onCreate={handleCreate}
            onUpdate={handleUpdate}
            loading={isLoading}
            formId="update"
          />
        </CardContent>
        <CardActions>
          <ProgressButton type="submit" form="update" variant="contained" color="primary" loading={isLoading}>
            Submit
          </ProgressButton>
        </CardActions>
      </Card>
    </Layout>
  );
};
