import { ReactElement } from 'react';
import { AgwApi } from '@localstack/types';
import { getSchema } from '@localstack/services';
import { Link } from '@mui/material';

import { NewTabLink } from '../../../../navigation';

import { MagicTable } from '../../../magic/MagicTable';

export type AgwApisTableProps = {
  selectable?: boolean;
  loading?: boolean;
  apis: AgwApi[];
  onViewApi?: (api: AgwApi) => unknown;
  onSelect?: (ids: string[]) => void;
};

export const AgwApisTable = ({
  apis,
  selectable = true,
  loading,
  onViewApi,
  onSelect,
}: AgwApisTableProps): ReactElement => (
  <MagicTable
    schema={getSchema('ApiGatewayV2')}
    entry="Api"
    idAttribute="ApiId"
    rows={apis}
    selectable={selectable}
    onSelect={onSelect}
    order={['ApiId', 'Name']}
    loading={loading}
    externalFields={{
      ApiId: (row) => (
        <Link onClick={() => onViewApi && onViewApi(row)} underline="hover">
          {row.ApiId}
        </Link>
      ),
      ApiEndpoint: ({ ApiEndpoint }) => (
        <NewTabLink type="Link" href={`http://${ApiEndpoint}`}>
          {ApiEndpoint}
        </NewTabLink>
      ),
    }}
  />
);
