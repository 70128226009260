import { AuditTrailEvent, AuditTrailInitiatorType } from '@localstack/types';
import { Fragment, ReactElement, useState, useMemo } from 'react';
import { formatDateTime, hashEmail } from '@localstack/services';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';

import {
  KeyboardArrowDown as ArrowDownIcon,
  KeyboardArrowUp as ArrowUpButton,
  ArrowRight as ListMarkerIcon,
} from '@mui/icons-material';

import {
  Theme,
  Table,
  TableBody,
  TableRow,
  TableCell,
  Collapse,
  Typography,
  List,
  ListItem,
  ListItemText,
  IconButton,
  Avatar,
  Box,
  Badge,
  ListItemAvatar,
} from '@mui/material';

import { LogoRocket } from '../../../display/LogoRocket';

import { RECOGNIZED_UPDATE_MAPPINGS } from './mapping';
import { AttributeMappingCallbacks } from './types';

export interface AuditTrailTableProps {
  events: AuditTrailEvent[];
  revealLocalStackStaff?: boolean;
}

const formatAuditEvents = (events: AuditTrailEvent[]): (AuditTrailEvent & { formattedUpdates: ReactElement[] })[] => {
  const formattedTrails: (AuditTrailEvent & { formattedUpdates: ReactElement[] })[] = [];

  events.forEach((event) => {
    const knownUpdates: ReactElement[] = [];

    const recognizedUpdates =
      RECOGNIZED_UPDATE_MAPPINGS[event.modifications.entity as keyof typeof RECOGNIZED_UPDATE_MAPPINGS];

    if (!recognizedUpdates || !event.modifications.changes.length) {
      return;
    }

    event.modifications.changes.forEach((change) => {
      const recognizedUpdate = recognizedUpdates.find((update) => update.regex.test(change.path));

      if (!recognizedUpdate) {
        return;
      }

      const recognizedOperation = recognizedUpdate[change.type as keyof AttributeMappingCallbacks];

      if (!recognizedOperation) {
        return;
      }

      knownUpdates.push(recognizedOperation(event.modifications.entity_data, change, event) ?? <></>);
    });

    if (!knownUpdates.length) {
      return;
    }

    formattedTrails.push({
      ...event,
      formattedUpdates: knownUpdates,
    });
  });

  return formattedTrails;
};

const buildRowId = (event: AuditTrailEvent) => `${event.timestamp}_${event.modifications.entity}`;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    row: {
      '& > *': {
        borderBottom: 'unset',
      },
    },
    denseCell: {
      paddingBottom: 0,
      paddingTop: 0,
    },
    denseRow: {
      height: 'unset',
    },
    avatar: {
      width: theme.spacing(3),
      height: theme.spacing(3),
    },
  }),
);

export const AuditTrailTable = ({ events, revealLocalStackStaff = false }: AuditTrailTableProps): ReactElement => {
  const classes = useStyles();

  const [openRow, setOpenRow] = useState<string | null>(null);
  const formattedAuditEvents = useMemo(() => formatAuditEvents(events), []);

  return (
    <Table size="small">
      <TableBody>
        {formattedAuditEvents.map((item) => (
          <Fragment key={buildRowId(item)}>
            <TableRow className={classes.row}>
              <TableCell>
                {item.formattedUpdates.length > 1 && (
                  <IconButton
                    size="small"
                    onClick={() => setOpenRow(openRow === buildRowId(item) ? null : buildRowId(item))}
                  >
                    {openRow === buildRowId(item) ? <ArrowDownIcon /> : <ArrowUpButton />}
                  </IconButton>
                )}
              </TableCell>
              <TableCell>{formatDateTime(item.timestamp, true)}</TableCell>
              <TableCell>
                <Box display="flex" alignItems="center">
                  {(item.initiator.type === AuditTrailInitiatorType.USER ||
                    (item.initiator.type === AuditTrailInitiatorType.PLATFORM_ADMIN && revealLocalStackStaff)) && (
                    <>
                      <Avatar
                        className={classes.avatar}
                        alt={item.initiator.name}
                        src={`https://www.gravatar.com/avatar/${hashEmail(item.initiator?.email ?? '')}?d=db`}
                      />
                      <Box ml={1}>
                        <Typography variant="caption">{item.initiator.email}</Typography>
                      </Box>
                    </>
                  )}
                  {(item.initiator.type === AuditTrailInitiatorType.SYSTEM ||
                    (item.initiator.type === AuditTrailInitiatorType.PLATFORM_ADMIN && !revealLocalStackStaff)) && (
                    <>
                      <LogoRocket size="small" />
                      <Box ml={1}>
                        {item.initiator.type === AuditTrailInitiatorType.SYSTEM && (
                          <Typography variant="caption">LocalStack System</Typography>
                        )}
                        {item.initiator.type === AuditTrailInitiatorType.PLATFORM_ADMIN && (
                          <Typography variant="caption">LocalStack Staff</Typography>
                        )}
                      </Box>
                    </>
                  )}
                </Box>
              </TableCell>
              <TableCell colSpan={item.formattedUpdates.length > 1 ? 1 : 2}>{item.formattedUpdates[0]}</TableCell>
              {item.formattedUpdates.length > 1 && (
                <TableCell>
                  <Badge badgeContent={item.formattedUpdates.length} color="primary" />
                </TableCell>
              )}
            </TableRow>
            <TableRow className={classes.denseRow}>
              <TableCell colSpan={5} className={classes.denseCell}>
                <Collapse in={openRow === buildRowId(item)} timeout="auto" unmountOnExit>
                  <List dense>
                    {item.formattedUpdates.map((formattedChange, idx) => (
                      <ListItem button key={idx}>
                        <ListItemAvatar>
                          <ListMarkerIcon />
                        </ListItemAvatar>
                        <ListItemText primary={formattedChange} />
                      </ListItem>
                    ))}
                  </List>
                </Collapse>
              </TableCell>
            </TableRow>
          </Fragment>
        ))}
      </TableBody>
    </Table>
  );
};
