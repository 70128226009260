import { ReactElement } from 'react';
import { useRoutes, useAwsGetter } from '@localstack/services';
import { useParams } from 'react-router-dom';
import { Card, CardContent, Button, Box, Typography } from '@mui/material';
import { Breadcrumbs, AgwApiRouteDetails as Details } from '@localstack/ui';
import { DEFAULT_API_GATEWAY_ROUTES } from '@localstack/constants';

import { ApiGatewayProps } from './types';
import { ApiNavTabs } from './components';

export const AgwApiRouteDetails = ({
  Layout,
  clientOverrides,
  routes = DEFAULT_API_GATEWAY_ROUTES,
}: ApiGatewayProps): ReactElement => {
  const { goto } = useRoutes();
  const { apiId: ApiId, routeId: RouteId } = useParams<'apiId' | 'routeId'>();

  const { data: api } = useAwsGetter('ApiGatewayV2', 'getApi', [{ ApiId }], { clientOverrides });

  const { data: route } = useAwsGetter('ApiGatewayV2', 'getRoute', [{ ApiId, RouteId }], { clientOverrides });

  return (
    <Layout
      documentTitle="Route Details"
      tabs={<ApiNavTabs apiId={ApiId as string} routes={routes} />}
      title={
        <Box>
          <Typography variant="h4">Route Details</Typography>
          <Breadcrumbs
            mappings={[
              ['API Gateway', () => goto(routes.RESOURCES_AGW2)],
              [api?.Name, () => goto(routes.RESOURCES_AGW2_API, { apiId: ApiId })],
              [route?.RouteKey, null],
            ]}
          />
        </Box>
      }
      actions={
        <Button onClick={() => goto(routes.RESOURCES_AGW2_API_ROUTE_UPDATE, { apiId: ApiId, routeId: RouteId })}>
          Edit Route
        </Button>
      }
    >
      <Card>
        <CardContent>
          <Details route={route} />
        </CardContent>
      </Card>
    </Layout>
  );
};
