import { ReactElement } from 'react';
import { Card, CardContent, CardActions, Box, Typography } from '@mui/material';
import { RegisterScalableTargetsRequest } from '@localstack/types';
import { useRoutes, useAwsEffect } from '@localstack/services';
import { ProgressButton, ScalableTargetForm, Breadcrumbs } from '@localstack/ui';
import { DEFAULT_APPLICATION_AUTO_SCALING_ROUTES } from '@localstack/constants';

import { ApplicationAutoscalingProps } from './types';

export const ScalableTargetCreate = ({
  Layout,
  clientOverrides,
  routes = DEFAULT_APPLICATION_AUTO_SCALING_ROUTES,
}: ApplicationAutoscalingProps): ReactElement => {
  const { goto } = useRoutes();

  const { registerScalableTarget, isLoading } = useAwsEffect('ApplicationAutoScaling', ['registerScalableTarget'], {
    revalidate: ['describeScalableTargets'],
    clientOverrides,
  });

  const handleSubmit = async (data: RegisterScalableTargetsRequest) => {
    if (await registerScalableTarget(data)) {
      goto(routes.RESOURCES_APPLICATION_AUTO_SCALING);
    }
  };

  return (
    <Layout
      documentTitle="Application Auto Scaling: Register Scalable Target"
      title={
        <Box>
          <Typography variant="h4">Register Scalable Target</Typography>
          <Breadcrumbs mappings={[['Scalable Targets', () => goto(routes.RESOURCES_APPLICATION_AUTO_SCALING)]]} />
        </Box>
      }
      actions={
        <ProgressButton type="submit" form="update" variant="outlined" color="primary" loading={isLoading}>
          Submit
        </ProgressButton>
      }
    >
      <Card>
        <CardContent>
          <ScalableTargetForm onSubmit={handleSubmit} loading={isLoading} formId="update" />
        </CardContent>
        <CardActions>
          <ProgressButton type="submit" form="update" variant="contained" color="primary" loading={isLoading}>
            Submit
          </ProgressButton>
        </CardActions>
      </Card>
    </Layout>
  );
};
