import { ReactElement } from 'react';
import { Control, ControllerProps } from 'react-hook-form';
import { BaseTextFieldProps, TextField, Box } from '@mui/material';
import { COUNTRY_MAP } from '@localstack/constants';

import { ControlledAutocomplete } from '../ControlledAutocomplete';

const getCountryFlag = (isoCode: string) =>
  typeof String.fromCodePoint !== 'undefined'
    ? isoCode.toUpperCase().replace(/./g, (char) => String.fromCodePoint(char.charCodeAt(0) + 127397))
    : isoCode;

const DEFAULT_COUNTRY = 'AT';

type Props = Omit<ControllerProps, 'control' | 'render'> & {
  control: Control<any>; // eslint-disable-line
  inputProps: Omit<BaseTextFieldProps, 'autoComplete'>;
};

const getValueFromKey = (key: string) => COUNTRY_MAP[key as keyof typeof COUNTRY_MAP];

export const ControlledCountryAutocomplete = ({ control, inputProps, ...rest }: Props): ReactElement => (
  <ControlledAutocomplete
    control={control}
    options={Object.entries(COUNTRY_MAP).map(([code, _a]) => code)}
    getOptionLabel={(code) => getValueFromKey(code)}
    getOptionValue={(code) => code}
    getValueOption={(code) => code}
    defaultValue={DEFAULT_COUNTRY}
    renderOption={(props, code) => (
      <li {...props}>
        <Box component="span" mr={1}>
          {getCountryFlag(code)}{' '}
        </Box>{' '}
        {getValueFromKey(code)}
      </li>
    )}
    renderInput={(params) => <TextField variant="standard" {...params} {...inputProps} />}
    {...rest}
  />
);

export default ControlledCountryAutocomplete;
