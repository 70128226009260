import { ReactElement } from 'react';
import { useRoutes, useAwsEffect, useAwsGetter } from '@localstack/services';
import { useParams } from 'react-router-dom';
import { Card, CardContent, CardActions, Box, Typography } from '@mui/material';
import { CreateAppSyncGraphqlApiRequest, UpdateAppSyncGraphqlApiRequest } from '@localstack/types';
import { ProgressButton, AppSyncApiForm, Breadcrumbs } from '@localstack/ui';

import { DEFAULT_APPSYNC_ROUTES } from '@localstack/constants';

import { NavTabs } from './components';
import { AppSyncProps } from './types';

export const AppSyncApiUpsert = ({
  Layout,
  clientOverrides,
  routes = DEFAULT_APPSYNC_ROUTES,
}: AppSyncProps): ReactElement => {
  const { goto } = useRoutes();
  const { apiId } = useParams<'apiId'>();

  const { createGraphqlApi, updateGraphqlApi, isLoading } = useAwsEffect(
    'AppSync',
    ['createGraphqlApi', 'updateGraphqlApi'],
    { revalidate: ['listGraphqlApis', 'getGraphqlApi'], clientOverrides },
  );

  const { data: api } = useAwsGetter('AppSync', 'getGraphqlApi', [{ apiId }], { clientOverrides });

  const handleCreate = async (data: CreateAppSyncGraphqlApiRequest) => {
    await createGraphqlApi(data);
    goto(routes.RESOURCES_APPSYNC);
  };

  const handleUpdate = async (data: UpdateAppSyncGraphqlApiRequest) => {
    await updateGraphqlApi(data);
    goto(routes.RESOURCES_APPSYNC_API, { apiId });
  };

  return (
    <Layout
      title={
        <Box>
          <Typography variant="h4">API Details</Typography>
          <Breadcrumbs
            mappings={[
              ['AppSync', () => goto(routes.RESOURCES_APPSYNC)],
              [api?.graphqlApi?.name, () => goto(routes.RESOURCES_APPSYNC_API, { apiId })],
              [apiId ? 'Update' : 'Create API', null],
            ]}
          />
        </Box>
      }
      tabs={<NavTabs apiId={apiId as string} routes={routes} />}
      actions={
        <ProgressButton type="submit" form="update" variant="outlined" color="primary" loading={isLoading}>
          Submit
        </ProgressButton>
      }
    >
      <Card>
        <CardContent>
          <AppSyncApiForm
            api={api?.graphqlApi}
            onCreate={handleCreate}
            onUpdate={handleUpdate}
            loading={isLoading}
            formId="update"
          />
        </CardContent>
        <CardActions>
          <ProgressButton type="submit" form="update" variant="contained" color="primary" loading={isLoading}>
            Submit
          </ProgressButton>
        </CardActions>
      </Card>
    </Layout>
  );
};
