import { ReactElement } from 'react';
import { useRoutes, useAwsGetter } from '@localstack/services';
import { useParams } from 'react-router-dom';
import { Card, CardContent, Button, Box, Typography } from '@mui/material';
import { Breadcrumbs, GlueJobDetails as Details } from '@localstack/ui';
import { DEFAULT_GLUE_ROUTES } from '@localstack/constants';

import { GlueProps } from './types';
import { JobTabs } from './components';

export const GlueJobDetails = ({ Layout, clientOverrides, routes = DEFAULT_GLUE_ROUTES }: GlueProps): ReactElement => {
  const { goto } = useRoutes();
  const { job } = useParams<'job'>();

  const { data: JobData } = useAwsGetter('Glue', 'getJob', [{ JobName: job }], { clientOverrides });

  return (
    <Layout
      documentTitle="Glue: Job Details"
      tabs={<JobTabs routes={routes} jobId={job ?? ''} />}
      title={
        <Box>
          <Typography variant="h4">Job Details</Typography>
          <Breadcrumbs
            mappings={[
              ['Glue', () => goto(routes.RESOURCES_GLUE_DATABASES)],
              ['Job', () => goto(routes.RESOURCES_GLUE_JOBS)],
              [job, null],
            ]}
          />
        </Box>
      }
      actions={<Button onClick={() => goto(routes.RESOURCES_GLUE_JOB_UPDATE, { job })}>Edit job</Button>}
    >
      <Card>
        <CardContent>
          <Details job={JobData?.Job} />
        </CardContent>
      </Card>
    </Layout>
  );
};
