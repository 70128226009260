import { ReactElement } from 'react';
import { RDSCluster } from '@localstack/types';
import { getSchema } from '@localstack/services';

import { MagicDetails } from '../../magic/MagicDetails';

export interface RDSClusterDetailsProps {
  cluster?: Optional<Partial<RDSCluster>>;
}

export const RDSClusterDetails = ({ cluster }: RDSClusterDetailsProps): ReactElement => (
  <MagicDetails data={cluster} schema={getSchema('RDS')} entry="DBCluster" title="Cluster Details" />
);
