import { useEffect, ReactElement } from 'react';
import { TaxIdType } from '@localstack/types';
import { getTaxIdType } from '@localstack/services';

import { ControlledTextField, ControlledTextFieldProps } from '../../../form/ControlledTextField';

type Props = ControlledTextFieldProps & {
  country: Optional<string>;
  taxId: Optional<string>;
  onChangeTaxType: (taxType: Optional<TaxIdType>) => void;
};

export const TaxIdTextField = ({ country, taxId, onChangeTaxType, ...rest }: Props): ReactElement => {
  // update tax id type whenever tax id changes
  useEffect(() => {
    if (!taxId) return;

    onChangeTaxType(getTaxIdType(country, taxId));
  }, [taxId]);

  return <ControlledTextField {...rest} />;
};
