import { Notification } from '@localstack/types';
import { Dialog, DialogTitle, IconButton } from '@mui/material';
import { ReactElement } from 'react';
import { Close } from '@mui/icons-material';

export type NotificationModalProps = {
  notification: Optional<Notification>;
  onClose: () => void;
};

export const NotificationModal = ({ notification, onClose }: NotificationModalProps): ReactElement => (
  <Dialog maxWidth="md" open={!!notification} onClose={onClose}>
    <DialogTitle style={{ padding: 0, display: 'flex', justifyContent: 'flex-end' }}>
      <IconButton onClick={onClose} size="large">
        <Close />
      </IconButton>
    </DialogTitle>
    <div dangerouslySetInnerHTML={{ __html: notification?.rich_text ?? '' }} />
  </Dialog>
);
