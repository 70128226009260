/* eslint-disable no-console */
import { v4 as uuid } from 'uuid';
import useWebSocket from 'react-use-websocket';
import { useEffect, useState } from 'react';

import _ from 'lodash';

import {
  GraphItemAttribute,
  GremlinComplexQuery,
  GremlinEdge,
  GremlinGraphData,
  GremlinNode,
  QUERY_TYPES,
} from '@localstack/types';

import { DEFAULT_RESOURCE_BASE_URL, GremlinNodeColor } from '@localstack/constants';

import { getSelectedInstance } from '../../util/navigation';

import { useSnackbar } from '../util';

export type useGremlinServerReturn = {
  returnData: GremlinGraphData;
  error: Optional<Error>;
  sendToServer: (gremlinQuery: string, queryType: QUERY_TYPES, vertex?: Optional<string>) => void;
  deleteNode: (id: string) => void;
  clearData: () => void;
  deleteAll: () => void;
};

type GremlinMessage = {
  requestId: string;
  op: string;
  processor: string;
  args: {
    gremlin: string;
    bindings: unknown;
    language: string;
  };
};

const BIG_QUERY_SUFFIX =
  ".dedup().as('node').project('id', 'label', 'properties', 'edges').by(__.id()).by(__.label()).by(__.valueMap().by(__.unfold())).by(__.outE().project('id', 'from', 'to', 'label', 'properties').by(__.id()).by(__.select('node').id()).by(__.inV().id()).by(__.label()).by(__.valueMap().by(__.unfold())).fold())";

const PARTIAL_CONTENT_CODE = 206;

export const useGremlinServer = (socketPort: string): useGremlinServerReturn => {
  const [returnData, setReturnData] = useState<GremlinGraphData>({ nodes: [], edges: [] });
  const [error, setError] = useState<Optional<Error>>();
  const [lastQueryType, setLastQueryType] = useState<QUERY_TYPES>();
  const [temporaryData, setTemporaryData] = useState<GremlinGraphData>({ nodes: [], edges: [] });
  const { showSnackbar } = useSnackbar();

  const endpoint = getSelectedInstance()?.endpoint ?? DEFAULT_RESOURCE_BASE_URL;

  const protocol = window.location.protocol === 'https:' ? 'wss' : 'ws';

  const { sendMessage, lastMessage: lastReceivedMessage } = useWebSocket(
    `${protocol}://${new URL(endpoint).hostname}:${socketPort}/gremlin`,
    {
      shouldReconnect: (_closeEvent) => true,
    },
  );

  const convertStringArrayToMap = (data: string[]): GraphItemAttribute[] =>
    data
      .map((item, index) => {
        if (index % 2 !== 0) {
          return null;
        }
        return {
          key: item as string,
          value: data.at(index + 1) as string,
        };
      })
      .filter(Boolean) as GraphItemAttribute[];

  /**
   * Edges have different id for each time they get retrieved from db so
   * here we simply remove the id, remove all remaining duplicates and
   * re create an id unique for everyone
   */
  const removeEdgeDuplicates = (list: GremlinEdge[]): GremlinEdge[] => {
    const newList = list.map(({ id: _a, ...rest }) => ({ ...rest }));
    return _.uniqWith(newList, _.isEqual).map((obj) => ({ ...obj, id: uuid() }));
  };

  const changeDataFormat = (data: GremlinComplexQuery): GremlinGraphData => {
    const nodes = data['@value'].map((node) => {
      const values = node['@value'];
      return {
        id: values[1],
        label: values[3],
        attributes: convertStringArrayToMap(values?.[5]?.['@value'] || []),
        color: GremlinNodeColor,
        shape: 'dot',
      };
    }) as GremlinNode[];

    const edges = data['@value']
      .filter((node) => (node?.['@value']?.[7]?.['@value'] || []).length > 0)
      .reduce(
        (accumulator, currentValue) =>
          accumulator.concat(
            currentValue['@value'][7]['@value'].map((edge) => {
              const values = edge['@value'];
              return {
                id: values[1],
                from: values[3],
                to: values[5],
                label: values[7],
                attributes: convertStringArrayToMap(values[9]['@value']),
              };
            }),
          ),
        [] as GremlinEdge[],
      );

    return { nodes, edges };
  };

  useEffect(() => {
    if (!lastReceivedMessage) {
      return;
    }
    const response = JSON.parse(lastReceivedMessage.data);
    const code = Number(response.status.code);
    if (Number.isNaN(code) || code < 200 || code > 299) {
      setError(new Error(response.status.message));
      return;
    }

    showSnackbar({ message: 'Query successful', severity: 'success' });

    const { data } = response.result;
    if (data == null) {
      return;
    }

    if ([QUERY_TYPES.Search, QUERY_TYPES.NodeNeighbors, QUERY_TYPES.NodeEdit].includes(lastQueryType as QUERY_TYPES)) {
      const newData = changeDataFormat(data);
      if (code === PARTIAL_CONTENT_CODE) {
        setTemporaryData({
          nodes: [...newData.nodes, ...temporaryData.nodes],
          edges: [...newData.edges, ...temporaryData.edges],
        });
        return;
      }

      /**
       * for large amount of nodes returned removing duplicates takes some time so we do this only once
       * when all data has been returned
       */

      setReturnData({
        nodes: _.uniqBy([...newData.nodes, ...temporaryData.nodes], 'id'),
        edges: removeEdgeDuplicates([...newData.edges, ...temporaryData.edges]),
      } as unknown as GremlinGraphData);
      setTemporaryData({ nodes: [], edges: [] });
    }
  }, [lastReceivedMessage]);

  const runWebSocketRequest = (gremlinQuery: string) => {
    const message = {
      requestId: uuid(),
      op: 'eval',
      args: {
        gremlin: gremlinQuery,
        bindings: {},
        language: 'gremlin-groovy',
      },
    } as GremlinMessage;
    sendMessage(JSON.stringify(message), true);
  };

  const sendToServer = (gremlinQuery: string, queryType: QUERY_TYPES, vertex?: Optional<string>) => {
    setLastQueryType(queryType);
    if (queryType === QUERY_TYPES.Search || queryType === QUERY_TYPES.NodeEdit) {
      console.log(gremlinQuery);
      runWebSocketRequest(`${gremlinQuery}${BIG_QUERY_SUFFIX} `);
    } else if (queryType === QUERY_TYPES.EdgeInsert) {
      // here added ".constant(null)" so there are no problem with setState being executed before server response
      runWebSocketRequest(`${gremlinQuery}.constant(null)`);
      if (vertex) {
        setLastQueryType(QUERY_TYPES.EdgeInsert);
        runWebSocketRequest(`g.V('${vertex}')${BIG_QUERY_SUFFIX} `);
      }
    } else {
      runWebSocketRequest(gremlinQuery);
    }
  };

  const deleteNode = (id: string) => {
    const queryString = `g.V("${id}").drop()`;
    setLastQueryType(QUERY_TYPES.ItemDrop);
    runWebSocketRequest(queryString);
    setReturnData({ ...returnData, nodes: returnData.nodes.filter((node) => node.id !== id) });
  };

  const deleteAll = () => {
    const queryString = 'g.V().drop()';
    setLastQueryType(QUERY_TYPES.ItemDrop);
    runWebSocketRequest(queryString);
  };

  const clearData = () => {
    setReturnData({ nodes: [], edges: [] });
  };

  return {
    returnData,
    error,
    sendToServer,
    deleteNode,
    clearData,
    deleteAll,
  };
};
