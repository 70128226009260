import { ReactElement } from 'react';
import { Paper, Typography } from '@mui/material';
import { Handle, Position } from '@xyflow/react';
import makeStyles from '@mui/styles/makeStyles';
import createStyles from '@mui/styles/createStyles';

import { NODE_SIZES } from '../constants';
import { Node, NodeRendererProps } from '../types';

const useStyles = makeStyles(() =>
  createStyles({
    node: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      borderRadius: '50%',
      width: (node: Node) => `${NODE_SIZES[node.type].width}px`,
      height: (node: Node) => `${NODE_SIZES[node.type].height}px`,
    },
  }),
);

export const StartEndStateNode = ({ data }: NodeRendererProps): ReactElement => {
  const classes = useStyles(data);
  return (
    <Paper className={classes.node}>
      <Typography>{data.id}</Typography>
      {data.type === 'End' && <Handle type="target" position={Position.Top} />}
      {data.type === 'Start' && <Handle type="source" position={Position.Bottom} />}
    </Paper>
  );
};
