import { MembershipRole } from '@localstack/types';

import { getUserInfo } from '../util/storage';

import { useAuthProvider } from './useAuthProvider';

type FeatureFlagsType = {
  showCIProjects: boolean; // TODO: please remove me (currently used for Snowflake/Azure gating)
  showExtensionSubmission: boolean;
};

export const useFeatureFlags: () => FeatureFlagsType = () => {
  const userInfo = getUserInfo();
  const organization = userInfo?.org;
  const { can } = useAuthProvider();

  return {
    showCIProjects: !!organization?.settings?.features?.ci_projects,
    showExtensionSubmission: can(MembershipRole.PLATFORM_ADMIN),
  };
};
