import { useState, useCallback, ReactElement } from 'react';
import { useRoutes, useAwsGetter, useAwsEffect } from '@localstack/services';
import { Card, Button } from '@mui/material';
import { MonitoringAlarmsTable, Dropdown, ConfirmableButton, PageTitle } from '@localstack/ui';

import { DEFAULT_METRICS_ROUTES } from '@localstack/constants/src';

import { MainNavTabs } from './components';
import { MetricsProps } from './types';

export const MetricAlarms = ({
  Layout,
  clientOverrides,
  routes = DEFAULT_METRICS_ROUTES,
}: MetricsProps): ReactElement => {
  const { goto } = useRoutes();

  const [selected, setSelected] = useState<string[]>([]);
  const { data: alarms, isLoading, mutate } = useAwsGetter('CloudWatch', 'describeAlarms');

  const { deleteAlarms } = useAwsEffect('CloudWatch', ['deleteAlarms'], {
    revalidate: ['describeAlarms'],
    clientOverrides,
  });

  const handleDeleteSelected = useCallback(async () => {
    const promises = selected.map((name) => deleteAlarms({ AlarmNames: [name] }));
    await Promise.all(promises);
  }, [selected]);

  const allAlarms = [...(alarms?.MetricAlarms ?? []), ...(alarms?.CompositeAlarms ?? [])];

  return (
    <Layout
      title={<PageTitle title="CloudWatch Alarms" onMutate={mutate} />}
      tabs={<MainNavTabs routes={routes} />}
      actions={
        <>
          <Button onClick={() => goto(routes.RESOURCES_CLOUDWATCH_MONITORING_ALARM_CREATE)}>Put Alarm</Button>
          <Dropdown label="Actions">
            <ConfirmableButton
              componentType="MenuItem"
              disabled={selected.length === 0 || isLoading}
              title={`Delete ${selected.length} Alarm(s)?`}
              onClick={handleDeleteSelected}
              text="Selected Alarms will be permanently deleted"
            >
              Remove Selected
            </ConfirmableButton>
          </Dropdown>
        </>
      }
    >
      <Card>
        <MonitoringAlarmsTable
          selectable
          onSelect={setSelected}
          loading={isLoading}
          alarms={allAlarms}
          onViewAlarm={({ AlarmName }) => goto(routes.RESOURCES_CLOUDWATCH_MONITORING_ALARM, { name: AlarmName })}
        />
      </Card>
    </Layout>
  );
};
