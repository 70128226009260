import { SelfAssignmentPreflightFailure } from '@localstack/types';

export const preflightLicenseFriendlyError = (failure: SelfAssignmentPreflightFailure): string => {
  let failureMessage = '';

  switch (failure) {
    case SelfAssignmentPreflightFailure.ALL_SEATS_ALREADY_ASSIGNED:
      failureMessage = `All seats in your subscription are already taken.
      Review the currently issued legacy API keys and assigned licenses in your workspace.`;
      break;

    case SelfAssignmentPreflightFailure.LICENSE_ALREADY_ASSIGNED:
      failureMessage = 'A license has already been assigned to you.';
      break;

    case SelfAssignmentPreflightFailure.NO_TARGET_SUBSCRIPTION:
      failureMessage = `There is currently no suitable subscription in this workspace.
      Check the subscriptions on the 'Subscriptions' page in your workspace.`;
      break;

    case SelfAssignmentPreflightFailure.NOT_PERMITTED:
      failureMessage = "You don't have the necessary permissions to self assign a license.";
      break;
  }

  return `The workspace admin enabled self-issued licenses but this feature is currently
    not available to you: ${failureMessage}`;
};
