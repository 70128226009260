import { ReactElement } from 'react';
import { useParams } from 'react-router-dom';
import { Card, CardContent, CardActions, Box, Typography } from '@mui/material';
import { CreateAgwRestApiAuthorizerRequest, UpdateAgwRestApiAuthorizerRequest } from '@localstack/types';
import { useRoutes, useAwsEffect, useAwsGetter } from '@localstack/services';
import { ProgressButton, AgwRestApiAuthorizerForm, Breadcrumbs } from '@localstack/ui';
import { DEFAULT_API_GATEWAY_ROUTES } from '@localstack/constants';

import { ApiGatewayProps } from './types';
import { RestApiNavTabs } from './components';

export const AgwRestApiAuthorizerUpsert = ({
  Layout,
  clientOverrides,
  routes = DEFAULT_API_GATEWAY_ROUTES,
}: ApiGatewayProps): ReactElement => {
  const { goto } = useRoutes();
  const { restApiId, authorizerId } = useParams<'restApiId' | 'authorizerId'>();

  const { createAuthorizer, updateAuthorizer, isLoading } = useAwsEffect(
    'APIGateway',
    ['createAuthorizer', 'updateAuthorizer'],
    {
      revalidate: ['getAuthorizer', 'getAuthorizers'],
      clientOverrides,
    },
  );

  const { data: authorizer } = useAwsGetter('APIGateway', 'getAuthorizer', [{ restApiId, authorizerId }], {
    clientOverrides,
  });

  const { data: api } = useAwsGetter('APIGateway', 'getRestApi', [{ restApiId }], { clientOverrides });

  const handleCreate = async (data: CreateAgwRestApiAuthorizerRequest) => {
    await createAuthorizer(data);
    goto(routes.RESOURCES_AGW1_API_AUTHORIZERS, { restApiId });
  };

  const handleUpdate = async (data: UpdateAgwRestApiAuthorizerRequest) => {
    await updateAuthorizer(data);
    goto(routes.RESOURCES_AGW1_API_AUTHORIZER, { restApiId, authorizerId });
  };

  return (
    <Layout
      title={
        <Box>
          <Typography variant="h4">Authorizer Details</Typography>
          <Breadcrumbs
            mappings={[
              ['API Gateway', () => goto(routes.RESOURCES_AGW1)],
              [api?.name, () => goto(routes.RESOURCES_AGW1_API, { restApiId })],
              [authorizer?.name, () => goto(routes.RESOURCES_AGW1_API_AUTHORIZER, { restApiId, authorizerId })],
              [authorizerId ? 'Update' : 'Create Authorizer', null],
            ]}
          />
        </Box>
      }
      tabs={<RestApiNavTabs restApiId={restApiId as string} routes={routes} />}
      actions={
        <ProgressButton type="submit" form="update" variant="outlined" color="primary" loading={isLoading}>
          Submit
        </ProgressButton>
      }
    >
      <Card>
        <CardContent>
          <AgwRestApiAuthorizerForm
            restApiId={restApiId as string}
            authorizer={authorizer}
            onCreate={handleCreate}
            onUpdate={handleUpdate}
            loading={isLoading}
            formId="update"
          />
        </CardContent>
        <CardActions>
          <ProgressButton type="submit" form="update" variant="contained" color="primary" loading={isLoading}>
            Submit
          </ProgressButton>
        </CardActions>
      </Card>
    </Layout>
  );
};
