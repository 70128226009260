import { getSchema } from '@localstack/services';
import { Link } from '@mui/material';
import { IdentityPoolsList } from '@localstack/types';
import { ReactElement } from 'react';

import { MagicTable } from '../../magic/MagicTable';

export type CognitoIdentityIdentityPoolsTableProps = {
  identityPools: IdentityPoolsList;
  onViewIdentityPool: (id: string) => void;
  onSelect: (ids: string[]) => void;
  loading?: boolean;
};

export const CognitoIdentityPoolsTable = ({
  identityPools,
  onViewIdentityPool,
  onSelect,
  loading,
}: CognitoIdentityIdentityPoolsTableProps): ReactElement => (
  <MagicTable
    entry="IdentityPoolShortDescription"
    idAttribute="IdentityPoolId"
    rows={identityPools}
    schema={getSchema('CognitoIdentity')}
    externalFields={{
      IdentityPoolId: ({ IdentityPoolId }) => (
        <Link onClick={() => onViewIdentityPool(IdentityPoolId || '')} underline="hover">
          {IdentityPoolId}
        </Link>
      ),
    }}
    loading={loading}
    onSelect={onSelect}
    selectable
    disableSelectionOnClick
  />
);
