import { useCallback, useEffect, ReactElement } from 'react';
import { Grid, Box, MenuItem, Tooltip } from '@mui/material';
import { useForm } from 'react-hook-form';
import { Organization, OrganizationType } from '@localstack/types';
import { VALIDATION_RULES, getTaxIdExample } from '@localstack/services';

import {
  ProgressButton,
  TaxIdTextField,
  ControlledTextField,
  ControlledPhoneField,
  ControlledCountryAutocomplete,
  ControlledSelect,
  ControlledCheckbox,
} from '@localstack/ui';

const ORG_TYPE_MAP = {
  [OrganizationType.COMPANY]: 'company',
  [OrganizationType.INDIVIDUAL]: 'individual',
};

interface OrganizationFormProps {
  organization: Optional<Organization>;
  loading?: boolean;
  onUpdate: (orgId: string, details: Organization) => unknown;
}

export const OrganizationForm = ({ organization, loading, onUpdate }: OrganizationFormProps): ReactElement => {
  const { control, handleSubmit, setValue, formState, watch } = useForm<Organization>({ mode: 'all' });

  const country = watch('country');
  const taxId = watch('tax_id');

  const onSubmit = useCallback(
    (details: Partial<Organization>) => {
      if (!organization || !formState.isValid) return;

      const updates = Object.keys(formState.dirtyFields).reduce(
        (memo, field) => ({ ...memo, [field]: details[field as keyof typeof details] }),
        {},
      );

      onUpdate(organization.id, updates as Organization);
    },
    [organization, formState],
  );

  useEffect(() => {
    if (!organization) return;
    Object.entries(organization).forEach(([k, v]) => {
      setValue(k as keyof Organization, v, { shouldValidate: true });
    });
  }, [organization]);

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Grid container spacing={3}>
        <Grid item md={12}>
          <ControlledTextField
            control={control}
            name="id"
            variant="outlined"
            label="Organization ID"
            disabled
            fullWidth
          />
        </Grid>
        <Grid item md={12}>
          <ControlledTextField
            control={control}
            name="creator"
            variant="outlined"
            label="Organization Owner ID"
            rules={VALIDATION_RULES.required}
            required
            fullWidth
          />
        </Grid>
        <Grid item md={12}>
          <TaxIdTextField
            control={control}
            name="tax_id"
            label="TAX ID"
            placeholder={getTaxIdExample(country) || ''}
            fullWidth
            variant="outlined"
            rules={VALIDATION_RULES.taxId(country)}
            taxId={taxId}
            country={country}
            onChangeTaxType={(newTaxType) => setValue('tax_id_type', newTaxType || undefined, { shouldDirty: true })}
          />
        </Grid>
        <Grid item md={6} sm={12}>
          <ControlledTextField control={control} name="name" variant="outlined" label="Company Name" fullWidth />
        </Grid>
        <Grid item md={6} sm={12}>
          <ControlledTextField control={control} name="email" variant="outlined" label="Billing Email" fullWidth />
        </Grid>
        <Grid item md={4} sm={12}>
          <ControlledTextField control={control} name="address" variant="outlined" label="Address" fullWidth />
        </Grid>
        <Grid item md={4} sm={12}>
          <ControlledTextField control={control} name="zip" variant="outlined" label="ZIP" fullWidth />
        </Grid>
        <Grid item md={4} sm={12}>
          <ControlledTextField control={control} name="city" variant="outlined" label="City" fullWidth />
        </Grid>
        <Grid item md={6} sm={12}>
          <ControlledTextField control={control} name="state" variant="outlined" label="State" fullWidth />
        </Grid>
        <Grid item md={6} sm={12}>
          <ControlledCountryAutocomplete
            control={control}
            name="country"
            inputProps={{
              variant: 'outlined',
              label: 'Country',
              fullWidth: true,
            }}
          />
        </Grid>
        <Grid item md={12}>
          <ControlledPhoneField control={control} name="phone" variant="outlined" label="Phone Number" fullWidth />
        </Grid>
        <Grid item md={12}>
          <ControlledSelect
            control={control}
            name="orgType"
            variant="outlined"
            label="Organization Type"
            options={Object.entries(ORG_TYPE_MAP).map(([key, val]) => (
              <MenuItem key={key} value={key}>
                {val}
              </MenuItem>
            ))}
            fullWidth
          />
        </Grid>
        <Grid item xs={12}>
          <ControlledTextField
            control={control}
            name="hubspot_company_id"
            variant="outlined"
            label="HubSpot Company ID"
            fullWidth
          />
        </Grid>
        <Grid item xs={12}>
          <ControlledTextField
            control={control}
            name="tenant_id"
            variant="outlined"
            label="Namespace Tenant Id"
            disabled
            fullWidth
          />
        </Grid>
        <Grid item xs={12}>
          <Tooltip title="The ToS confirmation screen is not shown to users if their org has custom terms.">
            <span>
              <ControlledCheckbox control={control} name="has_custom_terms" label="Has custom Terms" />
            </span>
          </Tooltip>
        </Grid>
        <Grid item md={12}>
          <Box textAlign="right">
            <ProgressButton
              color="primary"
              variant="contained"
              type="submit"
              loading={loading}
              disabled={!formState.isValid}
            >
              Update
            </ProgressButton>
          </Box>
        </Grid>
      </Grid>
    </form>
  );
};
