import { ReactElement, useEffect } from 'react';

import { ALLOWED_ORIGINS, LocalStackWebEvent, WINDOW_EVENT } from '../constants';

import { observable } from './Observable';

interface Props {
  children: ReactElement;
}

export const CommunicationProvider = ({ children }: Props): ReactElement => {
  const messageEventHandler = (event: MessageEvent<LocalStackWebEvent>) => {
    if (!ALLOWED_ORIGINS.includes(event.origin)) {
      return;
    }

    observable.notify(event.data);
  };

  useEffect(() => {
    window.addEventListener(WINDOW_EVENT, messageEventHandler);

    return () => {
      window.removeEventListener(WINDOW_EVENT, messageEventHandler);
    };
  }, []);

  return children;
};
