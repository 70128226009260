import { ReactElement } from 'react';
import { GlueCrawler } from '@localstack/types';
import { getSchema } from '@localstack/services';
import { Link } from '@mui/material';

import { MagicTable } from '../../../magic/MagicTable';

export type GlueCrawlersTableProps = {
  crawlers: GlueCrawler[];
  selectable?: boolean;
  loading?: boolean;
  onSelect?: (names: string[]) => void;
  onViewCrawler?: (name: string) => void;
};

export const GlueCrawlersTable = ({
  crawlers,
  selectable = true,
  loading,
  onSelect,
  onViewCrawler,
}: GlueCrawlersTableProps): ReactElement => (
  <MagicTable
    schema={getSchema('Glue')}
    entry="Crawler"
    idAttribute="Name"
    rows={crawlers}
    selectable={selectable}
    onSelect={onSelect}
    loading={loading}
    filterColumns={['Name', 'Role', 'DatabaseName', 'State', 'CreationTime', 'LastUpdated']}
    externalFields={{
      Name: (row) => (
        <Link onClick={() => onViewCrawler && onViewCrawler(row.Name as string)} underline="hover">
          {row.Name}
        </Link>
      ),
    }}
  />
);
