import { LocalStackEventType, observable, STORAGE_KEY_THEME, ThemeType } from '@localstack/integrations';
import { useApiGetter, useRoutes, UserService } from '@localstack/services';
import { ConfirmableButton, Dropdown, DropdownProps, LogoRocket, LoadingFragment } from '@localstack/ui';
import { KeyboardArrowDown, DarkMode, Laptop, LightMode } from '@mui/icons-material';
import {
  Box,
  CircularProgress,
  Divider,
  MenuItem,
  Stack,
  Theme,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
} from '@mui/material';
import { ReactElement, ReactNode } from 'react';

import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';

import { AppRoute } from '~/config';
import { useSignout } from '~/hooks/useSignout';

import { getSectionItemBackground, getSectionItemTextColor } from './shared';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      maxWidth: 'calc(100% - 26px)', // leaving room for the close button that appears in small screens
    },
    skeleton: {
      minWidth: theme.spacing(15),
    },
    dropDown: {
      minWidth: theme.spacing(30),
      marginLeft: theme.spacing(5),
    },
    dropDownArrow: {
      color: getSectionItemTextColor(theme),
      '.collapsed &': {
        display: 'none',
      },
    },
    globalSignOut: {
      color: theme.palette.error.main,
    },
    fakeMenuItem: {
      border: '1px solid transparent',
      borderRadius: 8,
      '&:hover': {
        backgroundColor: theme.palette.background.paper,
      },
    },
    dropdownMenuItem: {
      paddingLeft: theme.spacing(1),
      paddingRight: theme.spacing(1),
    },
    themeToggler: {
      display: 'flex',
      justifyContent: 'space-between',
      gap: '1rem',
      width: '100%',
      '&:hover': {
        backgroundColor: 'unset !important',
        cursor: 'unset !important',
        color: 'unset !important',
        '& svg': {
          color: 'unset !important',
        },
      },
    },
    divider: {
      marginLeft: '-10px',
      marginRight: '-10px',
    },
    menuItem: {
      marginTop: '1px',
      padding: '0.3rem 0.2rem',
      '&:hover': {
        background: getSectionItemBackground(theme),
      },
      '&.active': {
        background: getSectionItemBackground(theme),
      },
      borderRadius: 8,
    },
    label: {
      marginLeft: '0.5em',
      color: getSectionItemTextColor(theme),
    },
  }),
);

const TextWithLoading = ({ loading, children }: { children: ReactNode; loading: boolean }): ReactElement => (
  <Box component="span" display="flex" alignItems="center" gap={1} justifyContent="space-between" width="100%">
    <span>{children}</span>
    {loading && <CircularProgress size={12} />}
  </Box>
);

export const WorkspaceSelector = (): ReactElement => {
  const classes = useStyles();
  const { goto } = useRoutes();
  const currentTheme = localStorage.getItem(STORAGE_KEY_THEME);
  const { handleSignOut, isSigningOut, isGlobalSigningOut } = useSignout();

  const handleChangeTheme = (theme: ThemeType) => {
    observable.notify({ eventType: LocalStackEventType.THEME_UPDATE, data: { theme } });
  };

  const { data: user, isLoading: isUserLoading } = useApiGetter(UserService, 'getUser', []);

  return (
    <div data-name="workspace selector" className={classes.root}>
      <Dropdown
        size="small"
        dropdownClassName={classes.dropDown}
        menuListProps={{
          disablePadding: true,
        }}
        renderButton={({ onClick }: DropdownProps) => (
          <MenuItem
            data-testid="Workspace Selector"
            className={classes.menuItem}
            sx={{ maxWidth: '100%' }}
            onClick={onClick}
          >
            <Stack
              direction="row"
              sx={{
                width: '100%',
                alignItems: 'center',
              }}
            >
              <LogoRocket size="small" />
              <LoadingFragment
                loading={isUserLoading}
                skeletonClassName={`${classes.label} ${classes.skeleton}`}
                size={1}
              >
                <Typography
                  className={classes.label}
                  style={{
                    textOverflow: 'ellipsis',
                    whiteSpace: 'nowrap',
                    overflow: 'hidden',
                    textAlign: 'left',
                    fontWeight: 500,
                  }}
                >
                  {user?.org.name}
                </Typography>
              </LoadingFragment>
              <KeyboardArrowDown className={classes.dropDownArrow} fontSize="small" opacity={0.5} />
            </Stack>
          </MenuItem>
        )}
      >
        <MenuItem
          className={`${classes.menuItem} ${classes.dropdownMenuItem}`}
          onClick={() => goto(AppRoute.SETTINGS_PROFILE)}
        >
          Settings
        </MenuItem>
        <MenuItem className={`${classes.menuItem} ${classes.themeToggler} ${classes.dropdownMenuItem}`} disableRipple>
          Theme
          <ToggleButtonGroup
            exclusive
            onChange={(_e, newValue) => (newValue ? handleChangeTheme(newValue as ThemeType) : undefined)}
            value={currentTheme}
          >
            <ToggleButton value={ThemeType.SYSTEM} size="xsmall">
              <Laptop fontSize="inherit" />
            </ToggleButton>
            <ToggleButton value={ThemeType.LIGHT} size="xsmall">
              <LightMode fontSize="inherit" />
            </ToggleButton>
            <ToggleButton value={ThemeType.DARK} size="xsmall">
              <DarkMode fontSize="inherit" />
            </ToggleButton>
          </ToggleButtonGroup>
        </MenuItem>
        <Divider className={classes.divider} />
        <MenuItem
          className={`${classes.menuItem} ${classes.dropdownMenuItem}`}
          onClick={() => handleSignOut()}
          disabled={isSigningOut}
        >
          <TextWithLoading loading={isSigningOut}>Sign Out</TextWithLoading>
        </MenuItem>
        <ConfirmableButton
          componentType="MenuItem"
          onClick={() => handleSignOut({ global: true })}
          okText="Sign out"
          title="Sign out on all devices?"
          text="By performing this action you will sign out of your account on every device / browser."
          className={`${classes.globalSignOut} ${classes.menuItem} ${classes.dropdownMenuItem}`}
          disabled={isGlobalSigningOut}
        >
          <TextWithLoading loading={isGlobalSigningOut}>Global Sign Out</TextWithLoading>
        </ConfirmableButton>
      </Dropdown>
    </div>
  );
};
