/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-explicit-any */

import { merge, mapValues, isArray, isObject, isNil, cloneDeep, pickBy } from 'lodash';
import { CloudPodMetamodelData } from '@localstack/types';

/**
 * If node has a `_meta_=no-change` marker, that means it hasn't changed
 */
export const nodeHasNoChanges = (obj: any): boolean => obj?._meta_ === 'no-change';

/**
 * Merge two delta trees using replace strategy
 * (by replacing removed nodes instead of preserving them).
 *
 * - In case case node is missing in a newer version it gets removed from the result
 * - In case newer version has a `no-change` marker the previous version of a subnode is taken.
 */
// TODO remove
export const mergeReplaceTrees = (
  leftTree: CloudPodMetamodelData,
  rightTree: CloudPodMetamodelData,
): CloudPodMetamodelData => {
  const mergeTree = merge(cloneDeep(leftTree), rightTree);

  const result = mapValues(mergeTree, (value, key) => {
    const leftValue = leftTree?.[key] as CloudPodMetamodelData;
    const rightValue = rightTree?.[key] as CloudPodMetamodelData;

    if (isNil(rightValue) && !isNil(leftValue)) return '$deleted$';
    if (nodeHasNoChanges(rightValue)) return leftValue;

    if (isArray(value) || isObject(value)) {
      return mergeReplaceTrees(leftValue, rightValue);
    }

    if (isObject(value)) {
      return mergeReplaceTrees(leftValue, rightValue);
    }

    return value;
  });

  // remove attributes marked for deletion
  return pickBy(result, (value) => value !== '$deleted$') as CloudPodMetamodelData;
};

/**
 * Extract resource types from delta and group them by region.
 */
export const extractGroupedResourceTypes = (delta: Optional<CloudPodMetamodelData>): Map<string, string[]> =>
  new Map(Object.entries(delta || {}).map(([region, config]) => [region, Object.keys(config as object)]));
