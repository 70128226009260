import { ReactElement } from 'react';

import { ConditionalWrapper } from '@localstack/integrations';

import { ComplexLayoutProps } from '@localstack/types';

import { BaseHeader } from '@localstack/ui';

import { InstanceHeaderWrapper } from '../Headers/InstanceHeaderWrapper';

import { LayoutContainer } from '../LayoutContainer';

import { CustomerLayout, CustomerLayoutProps } from './CustomerLayout';

type InstanceLayoutProps = CustomerLayoutProps & ComplexLayoutProps;

export const InstanceLayout = ({
  children,
  title,
  planFamily,
  actions,
  maxWidth,
  fullScreen,
  tabs,
  ...rest
}: InstanceLayoutProps): ReactElement => {
  const showInstanceHeader = !(fullScreen ?? false);

  return (
    <CustomerLayout {...rest} title={title}>
      <ConditionalWrapper
        condition={showInstanceHeader}
        wrapper={(content) => <InstanceHeaderWrapper>{content}</InstanceHeaderWrapper>}
      >
        <>
          <BaseHeader
            actions={actions}
            title={title}
            planFamily={planFamily}
            maxWidth={maxWidth}
            fullScreen={fullScreen}
            tabs={tabs}
          />
          <LayoutContainer fullScreen={fullScreen} maxWidth={maxWidth}>
            {children}
          </LayoutContainer>
        </>
      </ConditionalWrapper>
    </CustomerLayout>
  );
};
