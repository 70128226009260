import { MouseEvent, ReactElement, useState } from 'react';
import { Box, IconButton, Theme, Typography, useMediaQuery } from '@mui/material';

import { HealthState, SERVICE_NAME, RESOURCES_SERVICES_TYPE, PlanFamily } from '@localstack/types';

import { SERVICE_CODES, STORAGE_FAVORITES } from '@localstack/constants';
import makeStyles from '@mui/styles/makeStyles';
import createStyles from '@mui/styles/createStyles';

import { StarBorder as StarEmptyIcon, Star as StarFullIcon } from '@mui/icons-material';
import { getSelectedInstance, useLocalstackStatus, useLocalStorage } from '@localstack/services';

import classnames, { Argument } from 'classnames';

import AwsServiceIcon, { AwsServiceIconProps } from '../AwsServiceIcon';
import { PlanChip } from '../../feedback/PlanChip';
import { SystemStatus as SystemStatusIcon } from '../SystemStatus';
import { ProFeatureUnavailableDialog } from '../../feedback/ProFeatureUnavailableDialog';
import { EnterpriseFeatureUnavailableDialog } from '../../feedback/EnterpriseFeatureUnavailableDialog';

type Props = AwsServiceIconProps & {
  showFavoriteIcon?: boolean;
  status?: HealthState;
  clickable?: boolean;
  urlOpenerFuncOverride?: (externalUrl: string) => void;
  navigateTo: () => void;
  className?: Argument;
  isEnterpriseSub?: boolean;
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    starPointer: {
      visibility: 'hidden',
      padding: 0,
      opacity: 0,
      marginLeft: theme.spacing(1),
    },
    starTouchScreen: {
      visibility: 'visible',
      marginLeft: theme.spacing(1),
    },
    starWrapper: {
      '&:hover $starPointer': {
        visibility: 'visible',
        opacity: 1,
        transition: 'opacity .2s linear',
      },
    },
    starWrapperStarred: {
      '& $starPointer': {
        visibility: 'visible',
        opacity: 1,
      },
    },
    treatAsButton: {
      alignItems: 'center',
      '&:hover': {
        backgroundColor: theme.palette.action.hover,
      },
      cursor: 'pointer',
      width: '100%',
      padding: theme.spacing(0, 1),
    },
    fullWidth: {
      width: '100%',
    },

    serviceUnavailable: {
      filter: 'grayscale(1)',
      opacity: '0.7',
    },
  }),
);

export const AwsServiceTile = ({
  code,
  title,
  size,
  hideTooltip,
  showFavoriteIcon,
  status,
  clickable,
  urlOpenerFuncOverride,
  navigateTo,
  className,
  isEnterpriseSub,
}: Props): ReactElement => {
  const isResourceEnterprise = RESOURCES_SERVICES_TYPE.enterprise.includes(code as SERVICE_NAME);
  /*
  Assume a service is pro if not found in static list.
  This way each time a new service is added and web app is not up to date
  it will still show as pro in the status page.
  */
  const isResourcePro = !RESOURCES_SERVICES_TYPE.community.includes(code as SERVICE_NAME) && !isResourceEnterprise;

  const hasTouchScreen = useMediaQuery('(pointer: coarse)');
  const classes = useStyles();
  const { data: favorites, setDataWithCurrent } = useLocalStorage<string[]>({ ...STORAGE_FAVORITES, defaultValue: [] });
  const [openProFeatureUnavailableDialog, setOpenProFeatureUnavailableDialog] = useState<boolean>(false);
  const [openEnterpriseFeatureUnavailableDialog, setOpenEnterpriseFeatureUnavailableDialog] = useState<boolean>(false);
  const instance = getSelectedInstance();
  const clientOverrides = instance ? { endpoint: instance.endpoint } : {};
  const { isPro: isInstancePro } = useLocalstackStatus(clientOverrides);

  const handleStarClick = (event: MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    if (favorites?.includes(code)) {
      setDataWithCurrent((current) => (current ?? []).filter((x) => x !== code));
    } else {
      setDataWithCurrent((current) => [...(current ?? []), code]);
    }
  };
  const isServiceAvailable = isResourceEnterprise
    ? isInstancePro && isEnterpriseSub
    : isInstancePro || RESOURCES_SERVICES_TYPE.community.includes(code as SERVICE_NAME);

  const handleResourceClick = () => {
    if (!isServiceAvailable) {
      return isResourceEnterprise
        ? setOpenEnterpriseFeatureUnavailableDialog(true)
        : setOpenProFeatureUnavailableDialog(true);
    }

    navigateTo();
  };

  return (
    <div role="listitem" className={classes.fullWidth}>
      <ProFeatureUnavailableDialog
        open={openProFeatureUnavailableDialog}
        onClose={() => setOpenProFeatureUnavailableDialog(false)}
        urlOpenerFuncOverride={urlOpenerFuncOverride}
      />
      <EnterpriseFeatureUnavailableDialog
        open={openEnterpriseFeatureUnavailableDialog}
        onClose={() => setOpenEnterpriseFeatureUnavailableDialog(false)}
        urlOpenerFuncOverride={urlOpenerFuncOverride}
      />
      <Box
        display="flex"
        width="100%"
        className={classnames(
          classes.starWrapper,
          {
            [classes.starWrapperStarred]: favorites?.includes(code),
            [classes.serviceUnavailable]: !isServiceAvailable,
          },
          className,
          classes.treatAsButton,
        )}
        role="button"
        id={code}
        onClick={clickable ? handleResourceClick : undefined}
      >
        <Box alignItems="center" display="flex" width="100%">
          <AwsServiceIcon code={code} title={title} size={size} hideTooltip={hideTooltip} />
          <Box>
            <Typography style={{ whiteSpace: 'normal' }} variant="h6">
              {SERVICE_CODES[code as keyof typeof SERVICE_CODES] || code}
            </Typography>

            {status && (
              <Typography variant="body2">
                <SystemStatusIcon state={status} />
              </Typography>
            )}
          </Box>
        </Box>
        <Box display="flex" alignItems="center" justifyContent="flex-end">
          {isResourcePro && <PlanChip planFamily={PlanFamily.PRO_PLANS} showTooltip={!isInstancePro} />}
          {isResourceEnterprise && (
            <PlanChip planFamily={PlanFamily.ENTERPRISE_PLANS} showTooltip={!(isInstancePro && isEnterpriseSub)} />
          )}
          {showFavoriteIcon && (
            <IconButton
              onClick={(event: React.MouseEvent<HTMLButtonElement>) => handleStarClick(event)}
              className={hasTouchScreen ? classes.starTouchScreen : classes.starPointer}
              size="large"
            >
              {favorites?.includes(code) ? <StarFullIcon /> : <StarEmptyIcon />}
            </IconButton>
          )}
        </Box>
      </Box>
    </div>
  );
};
