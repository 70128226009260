import { ReactElement } from 'react';
import { Grid, Card, CardContent, CardActions } from '@mui/material';
import { useForm } from 'react-hook-form';
import { ControlledTextField, PasswordCheck, ProgressButton, PageTitle } from '@localstack/ui';

import { VALIDATION_RULES, AdminService, useApiEffect, useRoutes } from '@localstack/services';

import { AdminLayout } from '~/layouts';
import { AppRoute } from '~/config';

interface FormData {
  firstname: string;
  lastname: string;
  email: string;
  password: string;
}

export const CreateAccount = (): ReactElement => {
  const { goto } = useRoutes();

  const { control, watch, handleSubmit, formState } = useForm<FormData>({ mode: 'all' });

  const password = watch('password');

  const { createUser, isLoading } = useApiEffect(AdminService, ['createUser'], { revalidate: ['getUsers'] });

  const onSubmit = async (data: FormData) => {
    const result = await createUser(data);
    if (result) goto(AppRoute.ADMIN_ACCOUNT, { id: result.id });
  };

  return (
    <AdminLayout
      documentTitle="Create Account"
      title={
        <PageTitle
          title="Create Account"
          breadcrumbs={[
            ['Accounts', () => goto(AppRoute.ADMIN_ACCOUNTS)],
            ['Create Account', () => null],
          ]}
        />
      }
    >
      <form onSubmit={handleSubmit(onSubmit)}>
        <Card>
          <CardContent>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <ControlledTextField
                  control={control}
                  name="firstname"
                  fullWidth
                  required
                  label="First Name"
                  type="text"
                  variant="outlined"
                  rules={VALIDATION_RULES.required}
                />
              </Grid>
              <Grid item xs={12}>
                <ControlledTextField
                  control={control}
                  name="lastname"
                  fullWidth
                  required
                  label="Last Name"
                  type="text"
                  variant="outlined"
                  rules={VALIDATION_RULES.required}
                />
              </Grid>
              <Grid item xs={12}>
                <ControlledTextField
                  control={control}
                  name="email"
                  fullWidth
                  required
                  label="Email address"
                  type="text"
                  variant="outlined"
                  rules={{
                    ...VALIDATION_RULES.email,
                    ...VALIDATION_RULES.required,
                  }}
                />
              </Grid>
              <Grid item xs={12}>
                <ControlledTextField
                  control={control}
                  name="password"
                  fullWidth
                  required
                  label="Password"
                  type="password"
                  variant="outlined"
                  rules={VALIDATION_RULES.password}
                />
                {password && <PasswordCheck password={password ?? ''} />}
              </Grid>
              <Grid item xs={12}>
                <ControlledTextField
                  control={control}
                  name="password_confirmation"
                  fullWidth
                  required
                  label="Confirm Password"
                  type="password"
                  variant="outlined"
                  rules={{
                    validate: (value) => value === password || 'Passwords do not match',
                  }}
                />
              </Grid>
            </Grid>
          </CardContent>
          <CardActions>
            <ProgressButton
              type="submit"
              variant="contained"
              color="primary"
              loading={isLoading}
              disabled={!formState.isValid}
            >
              Create Account
            </ProgressButton>
          </CardActions>
        </Card>
      </form>
    </AdminLayout>
  );
};
