import { ReactElement } from 'react';
import { Link } from '@mui/material';
import { AppSyncDataSource } from '@localstack/types';
import { getSchema } from '@localstack/services';

import { MagicTable } from '../../../magic/MagicTable';

export type AppSyncDataSourcesTableProps = {
  selectable?: boolean;
  loading?: boolean;
  dataSources: AppSyncDataSource[];
  onViewDataSource?: (api: AppSyncDataSource) => unknown;
  onSelect?: (names: string[]) => void;
};

export const AppSyncDataSourcesTable = ({
  dataSources,
  selectable = true,
  loading,
  onViewDataSource,
  onSelect,
}: AppSyncDataSourcesTableProps): ReactElement => (
  <MagicTable
    schema={getSchema('AppSync')}
    entry="DataSource"
    idAttribute="name"
    rows={dataSources}
    selectable={selectable}
    onSelect={onSelect}
    order={['name']}
    loading={loading}
    filterColumns={['name', 'dataSourceArn', 'description', 'type']}
    externalFields={{
      name: (row) => (
        <Link onClick={() => onViewDataSource && onViewDataSource(row)} underline="hover">
          {row.name}
        </Link>
      ),
    }}
  />
);
