// Material helpers
import { StyledEngineProvider, ThemeProvider } from '@mui/material/styles';

import { createGenerateClassName, StylesProvider } from '@mui/styles';

import { ReactElement, useEffect, useState } from 'react';

import { createTheme, CssBaseline } from '@mui/material';

import { EventDataMap, LocalStackEventType, STORAGE_KEY_THEME, ThemeType } from '../constants';

import { useObservableEvent, CommunicationProvider } from '../communication';

import { BaseExtensionLayout } from './BaseExtensionLayout';

import { getStoredThemeType, getTheme, userPrefersDarkSchemeMQ } from './theming';

// since mui creates a global instance as soon as imported, which is a problem when you
// have a subpackage which also needs mui, but doesn't really need it's own instance
// https://github.com/mui/material-ui/issues/11843
const generateClassName = createGenerateClassName({
  productionPrefix: 'c',
  disableGlobal: true,
});

interface ConditionalWrapperProps {
  condition: boolean;
  wrapper: (children: ReactElement) => ReactElement;
  children: ReactElement;
}

export const ConditionalWrapper = ({ condition, wrapper, children }: ConditionalWrapperProps): ReactElement =>
  condition ? wrapper(children) : children;

interface Props {
  children: ReactElement;
  useExtensionLayout?: boolean;
  themeType?: ThemeType;
  disableCommunicationProvider?: boolean;
}

export const LocalStackThemeProvider = ({
  children,
  useExtensionLayout,
  themeType,
  disableCommunicationProvider,
}: Props): ReactElement => {
  const [currentTheme, setCurrentTheme] = useState(() => getTheme(themeType));

  const handleThemeUpdate = (data: EventDataMap[LocalStackEventType.THEME_UPDATE]) => {
    localStorage.setItem(STORAGE_KEY_THEME, data.theme);
    setCurrentTheme(getTheme(data.theme));
  };

  const handleThemeInject = (data: EventDataMap[LocalStackEventType.THEME_INJECT]) =>
    setCurrentTheme(createTheme(JSON.parse(data.theme)));

  useObservableEvent({
    subscribedEvents: {
      [LocalStackEventType.THEME_UPDATE]: handleThemeUpdate,
      [LocalStackEventType.THEME_INJECT]: handleThemeInject,
    },
  });

  useEffect(() => {
    const removeClassEndingWith = (el: HTMLElement, suffix: string) => {
      el.classList.forEach((className) => {
        if (className.endsWith(suffix)) {
          el.classList.remove(className);
        }
      });
    };

    const suffix = '-theme';
    removeClassEndingWith(document.body, suffix);
    // Adding this (light/dark)-theme classes to body based on theme
    // This class is to handle elements whose appearance can't be changed using JS
    // Example: ReCaptcha
    document.body.classList.add(`${currentTheme.palette.mode}${suffix}`);
  }, [currentTheme]);

  useEffect(() => {
    const handleThemeChange = () => {
      // change theme without updating localStorage values as this method is only called if user updates their system theme preference
      // also, only react to system theme change if web app has system theme value
      if (getStoredThemeType() !== ThemeType.SYSTEM) return;

      const theme = userPrefersDarkSchemeMQ.matches ? ThemeType.DARK : ThemeType.LIGHT;
      setCurrentTheme(getTheme(theme));
    };

    userPrefersDarkSchemeMQ.addEventListener('change', handleThemeChange);

    return () => {
      userPrefersDarkSchemeMQ.removeEventListener('change', handleThemeChange);
    };
  }, []);

  return (
    <StylesProvider generateClassName={generateClassName}>
      <StyledEngineProvider injectFirst>
        <ConditionalWrapper
          condition={!disableCommunicationProvider}
          wrapper={(content) => <CommunicationProvider>{content}</CommunicationProvider>}
        >
          <ThemeProvider theme={currentTheme}>
            <CssBaseline>
              <ConditionalWrapper
                condition={!!useExtensionLayout}
                wrapper={(content) => <BaseExtensionLayout>{content}</BaseExtensionLayout>}
              >
                {children}
              </ConditionalWrapper>
            </CssBaseline>
          </ThemeProvider>
        </ConditionalWrapper>
      </StyledEngineProvider>
    </StylesProvider>
  );
};
