import { ReactElement } from 'react';
import { useRoutes, useAwsEffect } from '@localstack/services';
import { Card, CardContent, CardActions, Box, Typography } from '@mui/material';
import { VerifySESV1EmailIdentityRequest } from '@localstack/types';
import { ProgressButton, SESV1IdentityForm, Breadcrumbs } from '@localstack/ui';
import { DEFAULT_SES_ROUTES } from '@localstack/constants';

import { IndexNavTabs } from './components/IndexNavTabs';

import { SESProps } from './types';

export const SESV1IdentityUpsert = ({
  Layout,
  clientOverrides,
  routes = DEFAULT_SES_ROUTES,
}: SESProps): ReactElement => {
  const { goto } = useRoutes();

  const { verifyEmailIdentity, isLoading } = useAwsEffect('SES', ['verifyEmailIdentity'], {
    revalidate: ['listIdentities'],
    clientOverrides,
  });

  const handleCreate = async (data: VerifySESV1EmailIdentityRequest) => {
    await verifyEmailIdentity(data);
    goto(routes.RESOURCES_SES1_IDENTITIES);
  };

  return (
    <Layout
      documentTitle="Identity Details"
      title={
        <Box>
          <Typography variant="h4">Identity Details</Typography>
          <Breadcrumbs
            mappings={[
              ['SES', () => goto(routes.RESOURCES_SES1)],
              ['Identities', () => goto(routes.RESOURCES_SES1_IDENTITIES)],
              ['Create Identity', null],
            ]}
          />
        </Box>
      }
      tabs={<IndexNavTabs routes={routes} />}
      actions={
        <ProgressButton type="submit" form="update" variant="outlined" color="primary" loading={isLoading}>
          Submit
        </ProgressButton>
      }
    >
      <Card>
        <CardContent>
          <SESV1IdentityForm onSubmit={handleCreate} loading={isLoading} formId="update" />
        </CardContent>
        <CardActions>
          <ProgressButton type="submit" form="update" variant="contained" color="primary" loading={isLoading}>
            Submit
          </ProgressButton>
        </CardActions>
      </Card>
    </Layout>
  );
};
