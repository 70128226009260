import { ReactElement, useEffect, useMemo, useState } from 'react';

import {
  Alert,
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Collapse,
  FormHelperText,
  FormLabel,
  Grid,
  IconButton,
  TextField,
  Theme,
} from '@mui/material';
import { Mark } from '@mui/base';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import { CloudPod, Extension, ComputeUsageSummary, LocalStackInstance, SupportedImage } from '@localstack/types';
import {
  ConfirmableButton,
  ControlledAutocomplete,
  ControlledCheckbox,
  ControlledRadioGroup,
  ControlledRangeSlider,
  ControlledTextField,
  OptionStruct,
} from '@localstack/ui';
import { VALIDATION_RULES } from '@localstack/services';
import { useForm } from 'react-hook-form';
import { DEFAULT_INSTANCE_LIFETIME, MAX_INSTANCE_LIFETIME } from '@localstack/constants';

import { KeyboardArrowDown as ArrowDownIcon, KeyboardArrowUp as ArrowUpIcon, RocketLaunch } from '@mui/icons-material';

import { min } from 'lodash';

import { useFeatureFlags } from '~/hooks/useFeatureFlags';

const INSTANCE_FORM_ID = 'instanceFormEphemeral';

type InstanceCardProps = {
  instance: LocalStackInstance;
  onSave?: (instance: LocalStackInstance) => void;
  onCancel?: () => void;
  isSaving?: boolean;
  pods?: CloudPod[];
  disabled?: boolean;
  computeUsage?: ComputeUsageSummary;
  extensions?: Extension[];
  podsEnabled?: boolean;
};

const useStyles = makeStyles<Theme>(() =>
  createStyles({
    card: {
      height: '100%',
      display: 'flex',
      flexDirection: 'column',
    },
    cardHeader: {
      display: 'flex',
      alignItems: 'center',
      gap: '0.75rem',
    },
    cardContent: {
      flexGrow: 1,
      display: 'flex',
      gap: '0.5rem',
      flexDirection: 'column',
    },
  }),
);

export const InstanceEphemeralCard = ({
  instance,
  onSave,
  onCancel,
  isSaving,
  pods,
  disabled = false,
  computeUsage,
  extensions,
  podsEnabled = false,
}: InstanceCardProps): ReactElement => {
  const classes = useStyles();

  const { control, handleSubmit, formState, reset, watch } = useForm<LocalStackInstance>({ mode: 'all' });

  const minuteValue = watch('ephemeralInstanceConfig.lifetime');

  const availableCredits =
    (computeUsage?.policy.quota.compute_credits ?? 0) - (computeUsage?.usage.total.compute_credits ?? 0);

  const submitHandler = (lsInstance: LocalStackInstance) => {
    onSave?.(lsInstance);
  };

  const [isCollapsed, setIsCollapsed] = useState(false);

  const AddButton = (
    <ConfirmableButton
      componentType="ProgressButton"
      variant="contained"
      color="primary"
      startIcon={<RocketLaunch />}
      loading={isSaving}
      disabled={!formState.isValid || disabled}
      title="Confirm instance creation"
      text={
        <Alert severity="info" variant="outlined">
          Launching this instance will consume {minuteValue} credit(s) of your ephemeral instance credit quota. After
          the lifetime has passed, the instance will be shut down, and there will be no way to restart it.
        </Alert>
      }
      confirmButton={{
        component: 'Button',
        props: {
          type: 'submit',
          form: INSTANCE_FORM_ID,
        },
      }}
    >
      Launch
    </ConfirmableButton>
  );

  const CancelButton = (
    <Button className={classes.button} variant="outlined" onClick={onCancel} disabled={disabled}>
      Cancel
    </Button>
  );

  useEffect(() => {
    reset(instance);
  }, [instance]);

  const flags = useFeatureFlags();
  const maxSliderValue = min([MAX_INSTANCE_LIFETIME, availableCredits]) ?? 0;
  const defaultSliderValue = min([DEFAULT_INSTANCE_LIFETIME, availableCredits]);

  const marks: Mark[] = [];
  for (let i = 0; i <= maxSliderValue; i += 10) {
    marks.push({ value: i, label: i });
  }

  const imageOptions: OptionStruct[] = useMemo(
    () => [
      { value: SupportedImage.localstack, label: 'AWS' },
      ...(flags.showCIProjects ? [{ value: SupportedImage.snowflake, label: 'Snowflake (preview)' }] : []),
      ...(flags.showCIProjects ? [{ value: SupportedImage.azure, label: 'Azure (alpha)' }] : []),
    ],
    [flags],
  );

  // const cpuOptions =
  //   [
  //     <MenuItem key={2} value={2}>2</MenuItem>,
  //     <MenuItem key={4} value={4}>4</MenuItem>,
  //     // <MenuItem key="8" value='8'>8</MenuItem>,
  //     // <MenuItem key="16" value='16'>16</MenuItem>,
  //     // <MenuItem key="32" value='32'>32</MenuItem>,
  //   ];

  // const memoryOptions =
  //   [
  //     <MenuItem key={2048} value={2048}>2048</MenuItem>,
  //     <MenuItem key={4096} value={4096}>4096</MenuItem>,
  //     <MenuItem key={8192} value={8192}>8192</MenuItem>,
  //     // <MenuItem key="16384" value='16384'>16384</MenuItem>,
  //     // <MenuItem key="32768" value='32768'>32768</MenuItem>,
  //   ];

  return (
    <Card className={classes.card}>
      <form onSubmit={handleSubmit(submitHandler)} id={INSTANCE_FORM_ID}>
        <CardHeader
          className={classes.cardHeader}
          title={'Launch new ephemeral instance'}
          titleTypographyProps={{ style: { fontWeight: 'bold' } }}
          action={
            <IconButton size="small" onClick={() => setIsCollapsed(!isCollapsed)}>
              {isCollapsed ? <ArrowDownIcon /> : <ArrowUpIcon />}
            </IconButton>
          }
        />
        <Collapse in={!isCollapsed}>
          <CardContent className={classes.cardContent}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <ControlledTextField
                  control={control}
                  fullWidth
                  label={'Instance Name'}
                  name="name"
                  disabled={disabled}
                  variant="outlined"
                  type="text"
                  rules={VALIDATION_RULES.required && VALIDATION_RULES.onlyAlphaNumAndHyphen}
                />
              </Grid>
              <Grid
                item
                xs={12}
                style={{
                  display: imageOptions.length === 1 ? 'none' : 'block',
                }}
              >
                <ControlledRadioGroup
                  control={control}
                  required
                  defaultValue="localstack/localstack-pro"
                  options={imageOptions}
                  disabled={disabled}
                  row
                  name="ephemeralInstanceConfig.image.image_name"
                  rules={VALIDATION_RULES.required}
                />
              </Grid>
              {/* <Grid item xs={12} md={4}>
        <ControlledTextField
          control={control}
          fullWidth
          label="Tag"
          name="ephemeralInstanceConfig.image.tag"
          variant="outlined"
          type="text"
          defaultValue='latest'
        />
      </Grid> */}
              <Grid item xs={12}>
                <ControlledRangeSlider
                  control={control}
                  label="Lifetime in minutes"
                  name="ephemeralInstanceConfig.lifetime"
                  sliderProps={{
                    disabled,
                    max: maxSliderValue,
                    marks,
                    min: 5,
                    step: 5,
                  }}
                  hideInputField
                  defaultValue={defaultSliderValue}
                  rules={{
                    ...VALIDATION_RULES.required,
                    validate: (value) => (value <= availableCredits ? true : 'Exceeds available minutes'),
                  }}
                />
                <FormHelperText>Total available minutes: {availableCredits}</FormHelperText>
              </Grid>
              {/* <Grid item xs={12} md={4}>
        <ControlledSelect
          variant="outlined"
          control={control}
          required
          fullWidth
          label="Number of vCPUs"
          name="ephemeralInstanceConfig.shape.virtual_cpus"
          defaultValue={2}
          options={cpuOptions}
          rules={VALIDATION_RULES.required}
        />
      </Grid>
      <Grid item xs={12} md={4}>
        <ControlledSelect
          variant="outlined"
          control={control}
          required
          fullWidth
          label="RAM (in Megabytes)"
          name="ephemeralInstanceConfig.shape.memory_megabytes"
          defaultValue={2048}
          options={memoryOptions}
          rules={VALIDATION_RULES.required}
        />
      </Grid> */}
              {pods && podsEnabled && (
                <>
                  <Grid item xs={12}>
                    <FormLabel component="legend">Cloud Pod Settings</FormLabel>
                  </Grid>
                  <Grid item xs={12}>
                    <ControlledAutocomplete
                      variant="outlined"
                      control={control}
                      disabled={disabled}
                      fullWidth
                      name="ephemeralInstanceConfig.env_vars.AUTO_LOAD_POD"
                      options={pods.map((pod) => pod.pod_name)}
                      getOptionValue={(item) => item}
                      getValueOption={(item) => item}
                      renderInput={(params) => <TextField {...params} label="Cloud Pod Name" variant="outlined" />}
                      style={{ marginBottom: '0.5rem' }}
                    />
                    <FormHelperText>Automatically load a Cloud Pod when launching your instance</FormHelperText>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <ControlledCheckbox
                      control={control}
                      name="ephemeralInstanceConfig.pod_on_shutdown"
                      label="Create a Cloud Pod automatically when shutting down the instance"
                      defaultChecked
                    />
                  </Grid>
                </>
              )}
              {extensions && (
                <>
                  <Grid item xs={12}>
                    <FormLabel component="legend">Extension Settings</FormLabel>
                  </Grid>
                  <Grid item xs={12}>
                    <ControlledAutocomplete
                      variant="outlined"
                      control={control}
                      multiple
                      disabled={disabled}
                      fullWidth
                      disableCloseOnSelect
                      name="ephemeralInstanceConfig.env_vars.EXTENSION_AUTO_INSTALL"
                      options={extensions}
                      renderOption={(props, option: Extension) => (
                        <li {...props} style={{ display: 'flex', gap: '1rem' }}>
                          <img alt={`logo for ${option.display_name}`} height={30} width={30} src={option.logo} />
                          {option.display_name}
                        </li>
                      )}
                      getOptionLabel={(extension: Extension) => extension.display_name ?? extension.name}
                      getOptionValue={(item: Extension[]) => item.map((it) => it.name).join(',')}
                      getValueOption={(item) => item}
                      renderInput={(params) => <TextField {...params} label="Extensions" variant="outlined" />}
                      style={{ marginBottom: '0.5rem' }}
                    />
                    <FormHelperText>Automatically load extensions when launching your instance</FormHelperText>
                  </Grid>
                </>
              )}
              {/* <Grid item xs={12}>
        <FormLabel component="legend">Visibility Settings</FormLabel>
      </Grid>
      <Grid item xs={12}>
        <ControlledCheckbox
          control={control}
          name="ephemeralInstanceConfig.allow_workspace_access"
          label="Make this instance visible to workspace members"
        />
      </Grid> */}
              {/* <Grid item xs={12}>
        <FormLabel component="legend">Environment Variables</FormLabel>
      </Grid> */}
            </Grid>
          </CardContent>
          <CardActions>
            {onCancel && CancelButton}
            {AddButton}
          </CardActions>
        </Collapse>
      </form>
    </Card>
  );
};
