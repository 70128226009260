import { ChangeEvent, ReactElement, useEffect, useState } from 'react';
import { Route53ResourceRecordSet } from '@localstack/types';
import { getSchema } from '@localstack/services';
import {
  Box,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  MenuItem,
  Radio,
  RadioGroup,
} from '@mui/material';
import InputAdornment from '@mui/material/InputAdornment';

import { ControlledSelect, ControlledTextField } from '../../../../form';

import { MagicForm } from '../../../magic/MagicForm';

export type Route53HostedZoneRecordFormProps = {
  record?: Optional<Route53ResourceRecordSet>;
  hostedZoneName?: Optional<string>;
  recordTypes: string[];
  disabledRecordTypes: string[];
  routingPolicies: Record<string, string>;
  isDefaultRecord: boolean;
  loading?: boolean;
  formId: string;
  onUpsert: (data: Route53ResourceRecordSet) => unknown;
};

export const Route53HostedZoneRecordForm = ({
  record,
  hostedZoneName,
  recordTypes,
  disabledRecordTypes,
  routingPolicies,
  isDefaultRecord,
  loading,
  formId,
  onUpsert,
}: Route53HostedZoneRecordFormProps): ReactElement => {
  const [aliasTargetVisible, setAliasTargetVisible] = useState(false);
  const [routingPolicy, setRoutingPolicy] = useState(routingPolicies.SIMPLE);

  useEffect(() => {
    if (record) {
      if (record.AliasTarget) setAliasTargetVisible(true);
      setRoutingPolicy(record.RoutingPolicy || '');
    }
  }, [record]);

  return (
    <>
      {
        <Box style={{ marginBottom: '20px' }}>
          <FormControl
            variant="standard"
            component="fieldset"
            // eslint-disable-next-line
            onChange={(e: ChangeEvent<HTMLFieldSetElement>) => setRoutingPolicy((e.target as any).value)}
            disabled={!!record}
          >
            <FormLabel component="legend">Routing Policy</FormLabel>
            <RadioGroup row value={routingPolicy} color="primary">
              {Object.entries(routingPolicies).map(([key, value]) => (
                <FormControlLabel
                  key={`${key}-${value}`}
                  value={value}
                  control={<Radio color="primary" />}
                  label={value}
                />
              ))}
            </RadioGroup>
          </FormControl>
        </Box>
      }
      <MagicForm
        data={record}
        schema={getSchema('Route53')}
        formId={formId}
        entry="ResourceRecordSet"
        loading={loading}
        fieldConditions={{
          CidrRoutingConfig: false,
          HealthCheckId: routingPolicy !== routingPolicies.SIMPLE,
          SetIdentifier: routingPolicy !== routingPolicies.SIMPLE,
          Failover: routingPolicy === routingPolicies.FAILOVER,
          Weight: false,
          GeoLocation: false,
          Region: false,
          MultiValueAnswer: false,
          ResourceRecords: !aliasTargetVisible,
          AliasTarget: aliasTargetVisible,
        }}
        externalFields={{
          '^Name$': (control, name, required) => (
            <ControlledTextField
              fullWidth
              variant="outlined"
              label="Name"
              control={control}
              name={name}
              required={required}
              disabled={!!record}
              InputProps={
                !isDefaultRecord
                  ? {
                      endAdornment: <InputAdornment position="end">.{hostedZoneName}</InputAdornment>,
                    }
                  : undefined
              }
            />
          ),
          '^Type$': (control, name, required) => (
            <ControlledSelect
              variant="outlined"
              control={control}
              fullWidth
              label="Type"
              name={name}
              required={required}
              disabled={!!record}
              options={recordTypes.map((t) => (
                <MenuItem key={t} value={t} disabled={!record && disabledRecordTypes.includes(t)}>
                  {t}
                </MenuItem>
              ))}
            />
          ),
        }}
        injectedFields={{
          TTL: {
            position: 'after',
            element: (
              <Grid item xs={12}>
                <FormControl
                  variant="standard"
                  component="fieldset"
                  // eslint-disable-next-line
                  onChange={(e: ChangeEvent<HTMLFieldSetElement>) => setAliasTargetVisible((e.target as any).checked)}
                >
                  <RadioGroup row color="primary">
                    <FormControlLabel
                      checked={aliasTargetVisible}
                      control={<Checkbox color="primary" />}
                      label="Alias"
                    />
                  </RadioGroup>
                </FormControl>
              </Grid>
            ),
          },
        }}
        onSubmit={(data: Route53ResourceRecordSet) => onUpsert(data)}
      />
    </>
  );
};
