import { ServiceResourceAggregationObj } from '@localstack/types';

export const beautifyCloudControlResponse = (
  resources: ServiceResourceAggregationObj,
): ServiceResourceAggregationObj => {
  const parsed_new_values = Object.entries(resources).reduce((acc, [serviceName, resourceList]) => {
    const parts = serviceName.split('::');
    const service = parts.at(1) ?? '';
    const resource = parts.at(2) ?? '';

    acc[service] = resourceList.map((res) => ({ ...res, resource_type: resource }));
    return acc;
  }, {} as ServiceResourceAggregationObj);

  return parsed_new_values;
};
