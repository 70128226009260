import { LISTABLE_PLAN_FAMILIES, ExternalLink } from '@localstack/constants';
import { Plan, PlanFamily } from '@localstack/types';
import { DecorativeDivider } from '@localstack/ui';
import { Box, Button, Card, CardContent, Grid, Link, Typography, useMediaQuery, Skeleton } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import { ReactElement, useCallback, useState } from 'react';

import { Check as CheckIcon, Clear as CrossIcon } from '@mui/icons-material';

import { SubscriptionService, useApiGetter, useRoutes, UserService } from '@localstack/services';

import classNames from 'classnames';

import { useHubspotProvider } from '~/contexts/HubspotContext';
import { AppRoute } from '~/config';
import { ContainedCustomerLayout } from '~/layouts';

import { useAuthProvider } from '~/hooks/useAuthProvider';

import { MissingDetailsModal, PLAN_FAMILY_MAP, PricingPlan, usePricingPlanStyles } from './components';

const useStyles = makeStyles(() =>
  createStyles({
    hobbyFeature: {
      display: 'inline-block',
      marginTop: '-1em',
    },
    hobbyMark: {
      transform: 'translateY(0.3em)',
    },
    hobbySmall: {
      flexDirection: 'column',
      textAlign: 'center',
    },
    buttonSmall: {
      marginLeft: 'unset !important',
      marginTop: '1em;',
    },
  }),
);

export const PricingPlans = (): ReactElement => {
  const theme = useTheme();
  const { goto } = useRoutes();
  const { openHubspotWidget } = useHubspotProvider();

  const [selectedPlan, setSelectedPlan] = useState<Plan | null>(null);

  const { data: plans, isLoading: isPlansLoading } = useApiGetter(SubscriptionService, 'listPlans', []);
  const { userInfo } = useAuthProvider();
  const { data: emailDomain, isLoading: isCheckingEmailDomain } = useApiGetter(
    UserService,
    'checkEmailDomain',
    [{ email: userInfo?.user.email as string }],
    { enable: !!userInfo?.user.email },
  );

  const userCanStartPaidSub = !!userInfo?.org?.address;

  const hobbyPlan = plans?.find((plan) => plan.family === PlanFamily.HOBBY_PLANS);
  const hobbyDescription = hobbyPlan ? PLAN_FAMILY_MAP[hobbyPlan?.family] : undefined;

  const isViewportSmOrBelow = useMediaQuery(theme.breakpoints.down('md'));

  // group plans (we may have same plans with different interval settings)
  const groupedPlans = (plans ?? [])
    .filter((plan) => LISTABLE_PLAN_FAMILIES.includes(plan.family))
    // remove regular monthly plans
    .filter(
      (plan) =>
        !(plan.family === PlanFamily.PRO_PLANS && plan.interval_months === 1) &&
        !(plan.family === PlanFamily.TEAM_PLANS && plan.interval_months === 1),
    )
    .sort((lp, rp) => LISTABLE_PLAN_FAMILIES.indexOf(lp.family) - LISTABLE_PLAN_FAMILIES.indexOf(rp.family))
    .reduce((map, plan) => {
      const family = plan.family as PlanFamily;
      if (!map.has(family)) map.set(family, []);
      map.set(family, map.get(family)?.concat([plan]) ?? []);
      return map; // note mutation of the incoming map here
    }, new Map<PlanFamily, Plan[]>());

  const handlePlanSelection = useCallback(async (plan: Plan | undefined) => {
    if (plan) {
      if (plan.family === PlanFamily.ENTERPRISE_PLANS) {
        openHubspotWidget();
      } else {
        goto(AppRoute.PRICING_SUBSCRIBE, {}, `plan=${plan.id}`);
      }
    }
  }, []);

  const classes = useStyles();
  const pricingPlanSharedClasses = usePricingPlanStyles();

  return (
    <ContainedCustomerLayout title="Pricing Plans">
      <MissingDetailsModal
        open={selectedPlan !== null && !userCanStartPaidSub}
        onClose={() => setSelectedPlan(null)}
        onConfirm={() => goto(AppRoute.SETTINGS_BILLING)}
      />
      <Box p={3}>
        <Typography variant="h2" align="center">
          The right plan for your needs!
        </Typography>
        <Typography align="center">Choose a plan that works best for you and your team.</Typography>
      </Box>
      <Grid container justifyContent="center" spacing={3}>
        {isPlansLoading && (
          <>
            {[...Array(4)].map((_, idx) => (
              // eslint-disable-next-line
              <Grid item xs={6} md={3} key={idx}>
                <Skeleton width="100%" height={theme.spacing(82)} variant="rectangular" />
              </Grid>
            ))}
          </>
        )}
        {!isPlansLoading &&
          Array.from(groupedPlans.keys()).map((key) => (
            <PricingPlan key={key} plansGroup={groupedPlans.get(key) || []} onSelect={handlePlanSelection} />
          ))}
      </Grid>

      {hobbyPlan && hobbyDescription && !isCheckingEmailDomain && emailDomain?.is_public && (
        <Box mt={2}>
          <Card variant="outlined">
            <CardContent style={{ paddingTop: '0px', paddingBottom: '1em' }}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Box
                    mt={2}
                    style={{ display: 'flex', gap: '1em' }}
                    className={classNames({ [classes.hobbySmall]: isViewportSmOrBelow })}
                  >
                    <Box>
                      <Typography variant="caption" color="inherit">
                        {hobbyDescription?.description}
                      </Typography>

                      <Box mt={1}>
                        <Typography variant="caption" color="inherit">
                          {hobbyDescription.yes.map((feature) => (
                            <span key={feature} className={classes.hobbyFeature}>
                              <CheckIcon
                                className={classNames([pricingPlanSharedClasses.supportedFeature, classes.hobbyMark])}
                              />{' '}
                              {feature},
                            </span>
                          ))}
                          {hobbyDescription.no.map((feature) => (
                            <span key={feature} className={classes.hobbyFeature}>
                              <CrossIcon
                                className={classNames([pricingPlanSharedClasses.missingFeature, classes.hobbyMark])}
                              />{' '}
                              {feature}
                            </span>
                          ))}
                        </Typography>
                      </Box>
                    </Box>

                    <Box
                      style={{ marginLeft: 'auto' }}
                      className={classNames({ [classes.buttonSmall]: isViewportSmOrBelow })}
                    >
                      <Button
                        variant="outlined"
                        color="primary"
                        style={{ whiteSpace: 'nowrap', marginLeft: 'auto' }}
                        onClick={() => {
                          if (hobbyPlan) {
                            handlePlanSelection(hobbyPlan);
                          }
                        }}
                      >
                        {hobbyDescription.buttonText}
                      </Button>
                    </Box>
                  </Box>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Box>
      )}

      <Box mt={2}>
        <DecorativeDivider />
        <Typography variant="caption" component="div">
          *Depending on your country of residence, an additional Value Added Tax (VAT) may apply at the checkout
        </Typography>
        <Typography variant="caption" component="div">
          **Check out the full feature coverage{' '}
          <Link target="_blank" rel="noreferrer" href={ExternalLink.DOCS_FEATURE_COVERAGE} underline="hover">
            here
          </Link>
          <br />
          1: 100 base credits for the first 3 users + 20 additional credits per additional user / month
          <br />
          2: 1000 base credits for the first 3 users + 200 additional credits per user / month
          <br />
          3: managed cloud storage, limit of 500 MB per user
          <br />
          4: managed cloud storage, limit of 5 GB per seat
        </Typography>
      </Box>
    </ContainedCustomerLayout>
  );
};
