import { ReactElement, ReactNode } from 'react';
import { v4 as uuid } from 'uuid';
import { Skeleton, Alert, Grid, GridSize } from '@mui/material';

export type GridItemBreakpoints = {
  xs?: GridSize;
  sm?: GridSize;
  md?: GridSize;
  lg?: GridSize;
  xl?: GridSize;
};

type Props = {
  /** hide children elements and show loading skeleton */
  loading?: boolean;
  /** when specified and empty, LoadingFragment will display "no data" alert */
  arrayData?: unknown[];
  /** type of the loading block to show, can be extended with different looks */
  variant?: 'list' | 'card' | 'grid';
  /** height of each skeleton element */
  height?: number;
  /** how many skeleton to display, if applicable */
  size?: number;
  /** text to display in case of empty `arrayData` */
  emptyText?: string;
  /** action to supply to alert in case of empty `arrayData` */
  emptyAction?: ReactNode;
  children?: ReactNode;
  gridItemBreakpoints?: GridItemBreakpoints;
  skeletonClassName?: string;
};

export const LoadingFragment = ({
  loading,
  arrayData,
  height,
  size = 10,
  variant = 'list',
  emptyText,
  emptyAction,
  children,
  gridItemBreakpoints = { sm: 12 },
  skeletonClassName,
}: Props): ReactElement => {
  if (!loading) {
    if (arrayData && arrayData.length === 0) {
      return (
        <Alert variant="outlined" severity="info" action={emptyAction}>
          {emptyText ?? 'No Data to display'}
        </Alert>
      );
    }
    return <>{children}</>;
  }

  if (variant === 'list') {
    return (
      <>
        {new Array(size).fill(0).map(() => (
          <Skeleton
            key={uuid()}
            height={height}
            animation="wave"
            variant="text"
            className={skeletonClassName}
            style={{ flexGrow: 1 }}
          />
        ))}
      </>
    );
  }

  if (variant === 'card') {
    return <Skeleton variant="rectangular" height={height} className={skeletonClassName} />;
  }

  if (variant === 'grid') {
    return (
      <>
        {Array.from({ length: size }).map((_, i) => (
          <Grid item {...gridItemBreakpoints} key={i}>
            <Skeleton key={uuid()} variant="rectangular" height={height} className={skeletonClassName} />
          </Grid>
        ))}
      </>
    );
  }

  throw new Error(`Unknown variant '${variant}'`);
};
