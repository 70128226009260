import {
  Box,
  Collapse,
  IconButton,
  ListItem,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Theme,
  Typography,
} from '@mui/material';
import { ReactElement, useMemo, useState } from 'react';

import { KeyboardArrowDown as ArrowDownIcon, KeyboardArrowRight as ArrowRightIcon } from '@mui/icons-material';

import { AwsServiceIcon } from '@localstack/ui';

import { makeStyles, createStyles } from '@mui/styles';

import classnames from 'classnames';
import { ResourceAggregationType } from '@localstack/types';
import { pluralizeOrDash } from '@localstack/services';

import { ResourceCounter } from './components/ResourcesCounter';

type ServiceResourceAggregationType = {
  service: string;
  resources: ResourceAggregationType[];
};

type ServiceListEntryProps = {
  serviceResAggregation: ServiceResourceAggregationType;
};

/**
 * Generates a 4-letter acronym for a given resource type name.
 * - If the name has multiple parts (e.g., "EventBus" -> "Event-Bus"), it
 *   combines the first letter of the first part with up to 3 letters
 *   from the subsequent parts to create the acronym.
 *
 * @param resourceName The name of the resource (e.g., "EventBus").
 * @returns A 4-letter acronym representing the resource name.
 */
const generateAcronym = (resourceName: string): string => {
  const parts = resourceName.split(/(?=[A-Z])/); // Split by capital letters
  const maxAcronymLength = 4;
  let remainingLength = maxAcronymLength;

  return parts
    .reduce((acronym, part, index) => {
      const isLastPart = index === parts.length - 1;
      const takeLength = isLastPart ? remainingLength : Math.min(1, remainingLength);

      acronym.push(part.substring(0, takeLength).toUpperCase());
      remainingLength -= takeLength;

      return acronym;
    }, [] as string[])
    .join('');
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      padding: 0,
    },
    darker: {
      textColor: theme.palette.text.secondary,
      color: theme.palette.text.secondary,
    },
    lastElement: {
      paddingRight: theme.spacing(1),
    },
    firstRow: {
      color: theme.palette.divider,
      backgroundColor: theme.palette.divider,
    },
    otherRow: {
      color: theme.palette.divider,
      backgroundColor: theme.palette.divider,
      '&:not(:last-child) td': {
        borderBottom: `1px solid ${theme.palette.background.paper}`,
      },
    },
    iconsTableCell: {
      padding: 0,
      width: '6%',
      alignItems: 'center',
    },
    resourceCounterBox: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'left',
      flexDirection: 'row',
      padding: 0,
    },
  }),
);

export const ServiceListEntry = ({ serviceResAggregation: serviceAccRegRes }: ServiceListEntryProps): ReactElement => {
  const classes = useStyles();
  const [open, setOpen] = useState<boolean>(false);

  const regionsNumber = useMemo(
    () => new Set(serviceAccRegRes.resources.map((item) => item.region_name)).size,
    [serviceAccRegRes],
  );

  const accountNumber = useMemo(
    () => new Set(serviceAccRegRes.resources.map((item) => item.account_id)).size,
    [serviceAccRegRes],
  );

  const regionString = pluralizeOrDash(regionsNumber, 'region', 'regions');
  const accountString = pluralizeOrDash(accountNumber, 'account', 'accounts');

  const resourceTypeCount = useMemo(
    () =>
      serviceAccRegRes.resources.reduce(
        (acc, curr) => {
          const { resource_type } = curr;
          acc[resource_type] = (acc[resource_type] || 0) + 1;
          return acc;
        },
        {} as { [key: string]: number },
      ),
    [serviceAccRegRes],
  );

  const shrunkResourceTypeList = useMemo(
    () => serviceAccRegRes.resources.map((item) => ({ ...item, resourceType: generateAcronym(item.resource_type) })),
    [serviceAccRegRes],
  );

  return (
    <ListItem disablePadding key={serviceAccRegRes.service}>
      <TableContainer component={Paper} className={classes.container}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell align="center" className={classes.iconsTableCell}>
                <Box display="flex" flexDirection="row" alignItems="center">
                  <IconButton aria-label="expand row" size="medium" onClick={() => setOpen(!open)}>
                    {open ? <ArrowDownIcon /> : <ArrowRightIcon />}
                  </IconButton>
                  <AwsServiceIcon code={serviceAccRegRes.service.toLocaleLowerCase()} hideTooltip />
                </Box>
              </TableCell>
              <TableCell align="left" style={{ paddingLeft: 0, alignItems: 'center' }} colSpan={2}>
                <Box className={classes.resourceCounterBox}>
                  <Typography fontWeight="600">{serviceAccRegRes.service}</Typography>
                  <br />
                  <ResourceCounter resourceTypeCount={resourceTypeCount} />
                </Box>
              </TableCell>
              <TableCell align="right" sx={{ width: '15%', alignItems: 'center' }} className={classes.darker}>
                {regionString}
              </TableCell>
              <TableCell
                align="right"
                sx={{ width: '15%', alignItems: 'center' }}
                className={classnames(classes.darker, classes.lastElement)}
              >
                {accountString}
              </TableCell>
            </TableRow>
          </TableHead>
        </Table>
        <Collapse in={open} timeout="auto" unmountOnExit>
          <Table>
            <TableBody>
              {shrunkResourceTypeList.map((row) => (
                <TableRow
                  key={`${row.id}${row.account_id}${row.region_name}`}
                  sx={{ paddingLeft: 0 }}
                  className={classes.otherRow}
                >
                  <TableCell sx={{ padding: 0, width: '6%' }} />
                  <TableCell align="left" style={{ paddingLeft: 0 }} className={classes.darker} width={33}>
                    {row.resourceType}
                  </TableCell>
                  <TableCell align="left">
                    <Typography fontWeight="600">{row.id}</Typography>
                  </TableCell>
                  <TableCell align="right" className={classes.darker} sx={{ width: '15%' }}>
                    {row.region_name}
                  </TableCell>
                  <TableCell
                    align="right"
                    className={classnames(classes.darker, classes.lastElement)}
                    sx={{ width: '15%' }}
                  >
                    {row.account_id}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </Collapse>
      </TableContainer>
    </ListItem>
  );
};
