import { ReactElement } from 'react';
import { ButtonProps, Button } from '@mui/material';
import { Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import createStyles from '@mui/styles/createStyles';
import classnames from 'classnames';

import LoadingImage from './loading.gif';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    '@keyframes progress': {
      '0%': { backgroundPosition: '140%' },
      '20%': { backgroundPosition: '100%' },
      '80%': { backgroundPosition: '0%' },
      '100%': { backgroundPosition: '-40%' },
    },
    root: {
      display: 'inline-block',
      position: 'relative',
    },
    loading: {
      '& button': {
        backgroundColor: theme.palette.common.white,
        color: 'transparent !important',
      },
    },
    progress: {
      background: `url(${LoadingImage})`,
      borderRadius: theme.shape.borderRadius,
      backgroundSize: 'contain',
      backgroundRepeat: 'no-repeat',
      mixBlendMode: 'multiply',
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      animation: '$progress 10s linear infinite',
    },
  }),
);

type Props = ButtonProps & {
  loading?: boolean;
};

export const PikaButton = ({ loading, children, disabled, ...rest }: Props): ReactElement => {
  const classes = useStyles();

  return (
    <div className={classnames(classes.root, { [classes.loading]: loading })}>
      <Button {...rest} variant="contained" disabled={loading || disabled}>
        {children}
      </Button>
      {loading && <div className={classes.progress} />}
    </div>
  );
};

export default PikaButton;
