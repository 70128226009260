import { ReactElement } from 'react';
import { processIdAttributes, useAwsEffect, useAwsGetter, useRoutes } from '@localstack/services';
import { Card, CardContent, CardActions, Box, Typography } from '@mui/material';
import { Breadcrumbs, Route53HostedZoneRecordForm, ProgressButton } from '@localstack/ui';
import { Route53ResourceRecordSet } from '@localstack/types';
import { useParams } from 'react-router-dom';

import { DEFAULT_ROUTE53_ROUTES } from '@localstack/constants/src';

import { Route53Props } from './types';
import { isHostedZoneDefaultRecord, processHostedZone, processHostedZoneRecord } from './utils';
import { HOSTED_ZONE_DISABLED_RECORD_TYPES, HOSTED_ZONE_RECORD_TYPES, ROUTING_POLICIES } from './data';

export const Route53HostedZoneRecordUpsert = ({
  Layout,
  clientOverrides,
  routes = DEFAULT_ROUTE53_ROUTES,
}: Route53Props): ReactElement => {
  const { hostedZone, record } = useParams<'hostedZone' | 'record'>();
  const { goto } = useRoutes();

  const { data: records } = useAwsGetter('Route53', 'listResourceRecordSets', [{ HostedZoneId: hostedZone }], {
    clientOverrides,
  });

  const [recordName, recordType] = processIdAttributes(record || '');
  const selectedRecord = records?.ResourceRecordSets.find((r) => r.Name === `${recordName}.` && r.Type === recordType);

  const { data: hostedZoneData } = useAwsGetter('Route53', 'getHostedZone', [{ Id: hostedZone }], { clientOverrides });

  const { changeResourceRecordSets, isLoading } = useAwsEffect('Route53', ['changeResourceRecordSets'], {
    revalidate: ['listResourceRecordSets'],
    clientOverrides,
  });

  const isDefaultRecord = isHostedZoneDefaultRecord(selectedRecord, hostedZoneData?.HostedZone.Name || '');

  const handleUpsert = async (data: Route53ResourceRecordSet) => {
    await changeResourceRecordSets({
      HostedZoneId: hostedZone || '',
      ChangeBatch: {
        Changes: [
          {
            Action: 'UPSERT',
            ResourceRecordSet: {
              ...data,
              Name: `${data.Name}${!isDefaultRecord ? `.${hostedZoneData?.HostedZone.Name}` : ''}`,
            },
          },
        ],
      },
    });
    if (record) return goto(routes.RESOURCES_ROUTE53_HOSTED_ZONE_RECORD, { hostedZone, record });
    goto(routes.RESOURCES_ROUTE53_HOSTED_ZONE_RECORDS, { hostedZone });
  };

  return (
    <Layout
      documentTitle={`Route53: ${record ? 'Update' : 'Create'} Record`}
      title={
        <Box>
          <Typography variant="h4">{record ? 'Update' : 'Create'} Record</Typography>
          <Breadcrumbs
            mappings={[
              ['Route53', () => goto(routes.RESOURCES_ROUTE53_HOSTED_ZONES)],
              ['Hosted Zones', () => goto(routes.RESOURCES_ROUTE53_HOSTED_ZONES)],
              [hostedZone, () => goto(routes.RESOURCES_ROUTE53_HOSTED_ZONE, { hostedZone })],
              ['Records', () => goto(routes.RESOURCES_ROUTE53_HOSTED_ZONE_RECORDS, { hostedZone })],
              [recordName, () => goto(routes.RESOURCES_ROUTE53_HOSTED_ZONE_RECORD, { hostedZone, record })],
              [`${record ? 'Update' : 'Create'} Record`, null],
            ]}
          />
        </Box>
      }
      actions={
        <ProgressButton
          type="submit"
          form="UpsertHostedZoneRecord"
          variant="outlined"
          color="primary"
          loading={isLoading}
        >
          Submit
        </ProgressButton>
      }
    >
      <Card>
        <CardContent>
          <Route53HostedZoneRecordForm
            record={
              selectedRecord &&
              processHostedZoneRecord(
                selectedRecord,
                !isDefaultRecord ? { withoutHostedZone: hostedZoneData?.HostedZone.Name || '' } : undefined,
              )
            }
            routingPolicies={ROUTING_POLICIES}
            hostedZoneName={hostedZoneData && processHostedZone(hostedZoneData?.HostedZone).Name}
            recordTypes={HOSTED_ZONE_RECORD_TYPES}
            disabledRecordTypes={HOSTED_ZONE_DISABLED_RECORD_TYPES}
            isDefaultRecord={isDefaultRecord}
            loading={isLoading}
            formId="UpsertHostedZoneRecord"
            onUpsert={handleUpsert}
          />
        </CardContent>
        <CardActions>
          <ProgressButton
            type="submit"
            form="UpsertHostedZoneRecord"
            variant="contained"
            color="primary"
            loading={isLoading}
          >
            Submit
          </ProgressButton>
        </CardActions>
      </Card>
    </Layout>
  );
};
