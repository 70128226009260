import { ReactElement } from 'react';
import { SWRConfig as CacheConfig } from 'swr';

import { TRUE_VALUES } from '@localstack/constants';

import { localStorageSWRCacheProvider } from './api';
import { VISUAL_INSPECTION_TESTS } from './config';
type SWRConfigProps = {
  children: ReactElement;
};
export const SWRConfig = ({ children }: SWRConfigProps): ReactElement => {
  const configObj = TRUE_VALUES.includes(VISUAL_INSPECTION_TESTS)
    ? { provider: localStorageSWRCacheProvider }
    : undefined;
  return <CacheConfig value={configObj}>{children}</CacheConfig>;
};
