import { CiKey, LicensingTokenType, Permission } from '@localstack/types';
import { ConfirmableButton, ContentModal, Dropdown, DropdownProps } from '@localstack/ui';
import { TableRow, TableCell, Box, Typography, IconButton, MenuItem, Theme, TextField } from '@mui/material';
import { ReactElement, useState } from 'react';

import MoreHorizIcon from '@mui/icons-material/MoreHoriz';

import { formatDateTo, SubscriptionService } from '@localstack/services';

import createStyles from '@mui/styles/createStyles';

import makeStyles from '@mui/styles/makeStyles';

import { useAuthProvider } from '~/hooks/useAuthProvider';

import { STATUS_ICONS } from '../icons';
import { TokenChip } from '../TokenChip';
import { AUTH_TOKENS_DATE_FORMAT } from '../../utils';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    danger: {
      color: theme.palette.error.main,
    },
  }),
);

type CITokenTableRowProps = {
  token: CiKey;
  deleteCiKey: (typeof SubscriptionService)['deleteCiKey'];
  updateCiKey: (typeof SubscriptionService)['updateCiKey'];
  rotateCiKey: (typeof SubscriptionService)['rotateCiKey'];
  isCiKeyUpdating: boolean;
};

export const CITokenTableRow = ({
  token,
  deleteCiKey,
  updateCiKey,
  rotateCiKey,
  isCiKeyUpdating,
}: CITokenTableRowProps): ReactElement => {
  const { can } = useAuthProvider();
  const canWrite = can(Permission.WRITE_CI_KEYS);
  const classes = useStyles();

  const [openModal, setOpenModal] = useState<boolean>(false);
  const [renameKeyFormValue, setRenameKeyFormValue] = useState<string>(token.name ?? '');

  return (
    <TableRow key={token.api_key}>
      <ContentModal
        open={openModal}
        onClose={() => setOpenModal(false)}
        title="Rename CI Auth Token"
        confirmText="Rename CI Auth Token"
        closeText="Cancel"
        fullWidth
        onConfirm={() => updateCiKey(token.id, { name: renameKeyFormValue })}
      >
        <TextField
          placeholder="CI Auth Token Name"
          size="small"
          variant="outlined"
          fullWidth
          value={renameKeyFormValue}
          onChange={({ target }) => setRenameKeyFormValue(target.value)}
          disabled={isCiKeyUpdating}
        />
      </ContentModal>
      <TableCell variant="firstColumnCell">
        <Box>
          <Typography>{token.name}</Typography>
          {token.token_type === LicensingTokenType.LICENSING_CI_KEY && (
            <Box display="flex" flexDirection="row" gap={1}>
              {STATUS_ICONS.error}
              <Typography>Legacy CI key. Regenerate key to update to new CI auth token format.</Typography>
            </Box>
          )}
        </Box>
      </TableCell>
      <TableCell>
        <TokenChip token={token.api_key} />
      </TableCell>
      <TableCell>
        <Typography>{token.usage}</Typography>
      </TableCell>
      <TableCell>
        <Typography>{token.created ? formatDateTo(token.created, AUTH_TOKENS_DATE_FORMAT) : '-'}</Typography>
      </TableCell>
      <TableCell>
        <Dropdown
          size="small"
          placement="bottom-end"
          disabled={!canWrite}
          menuListProps={{
            disablePadding: true,
          }}
          renderButton={({ onClick }: DropdownProps) => (
            <IconButton size="small" onClick={onClick} disabled={!canWrite}>
              <MoreHorizIcon />
            </IconButton>
          )}
        >
          <MenuItem onClick={() => setOpenModal(true)}>Rename</MenuItem>
          <ConfirmableButton
            componentType="MenuItem"
            title="Delete CI Auth Token"
            onClick={() => deleteCiKey(token.id)}
            text={
              `Are you sure you want to delete this CI Auth Token (${token.name})? Any systems using this` +
              ' CI Auth Token will no longer be able to activate LocalStack with this token.'
            }
            className={classes.danger}
            okText="Delete CI Auth Token"
          >
            Delete
          </ConfirmableButton>
          <ConfirmableButton
            componentType="MenuItem"
            title="Regenerate CI Auth Token"
            onClick={() => rotateCiKey(token.id)}
            text={
              `Are you sure you want to regenerate this CI Auth Token (${token.name})? Any systems using this` +
              ' Any systems using the previous token will no longer be able to activate LocalStack.'
            }
            className={classes.danger}
            okText="Regenerate CI Auth Token"
          >
            Regenerate
          </ConfirmableButton>
        </Dropdown>
      </TableCell>
    </TableRow>
  );
};
