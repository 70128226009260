import { ReactElement } from 'react';
import { useRoutes, useAwsGetter } from '@localstack/services';
import { useParams } from 'react-router-dom';
import { Card, CardContent, Box, Typography } from '@mui/material';
import { Breadcrumbs, GlueJobRunDetails as Details } from '@localstack/ui';
import { DEFAULT_GLUE_ROUTES } from '@localstack/constants';

import { GlueProps } from './types';
import { JobTabs } from './components';

export const GlueJobRunDetails = ({
  Layout,
  clientOverrides,
  routes = DEFAULT_GLUE_ROUTES,
}: GlueProps): ReactElement => {
  const { goto } = useRoutes();
  const { job, run: runId } = useParams<'job' | 'run'>();

  const { data: JobRunData } = useAwsGetter('Glue', 'getJobRun', [{ JobName: job, RunId: runId }], { clientOverrides });

  return (
    <Layout
      documentTitle="Glue: Job Run Details"
      tabs={<JobTabs routes={routes} jobId={job ?? ''} />}
      title={
        <Box>
          <Typography variant="h4">Job Run Details</Typography>
          <Breadcrumbs
            mappings={[
              ['Glue', () => goto(routes.RESOURCES_GLUE_DATABASES)],
              ['Jobs', () => goto(routes.RESOURCES_GLUE_JOBS)],
              [job, () => goto(routes.RESOURCES_GLUE_JOB, { job })],
              ['Job Runs', () => goto(routes.RESOURCES_GLUE_JOB_RUNS, { job })],
              [runId, null],
            ]}
          />
        </Box>
      }
    >
      <Card>
        <CardContent>
          <Details jobRun={JobRunData?.JobRun} />
        </CardContent>
      </Card>
    </Layout>
  );
};
