import { ReactElement } from 'react';
import { Box, Alert } from '@mui/material';
import { AxiosError } from 'axios';

type DMSConfigOptionAlertProps = {
  error: Error | AxiosError<unknown, unknown> | undefined;
};

const DMS_NOT_ENABLED_ERROR_MESSAGE = 'localstack.aws.provider:dms:pro is disabled';

export const DMSConfigOptionAlert = ({ error }: DMSConfigOptionAlertProps): ReactElement => {
  if (!error?.message.includes(DMS_NOT_ENABLED_ERROR_MESSAGE)) return <></>;
  return (
    <Box mb={1}>
      <Alert severity="info">
        This resource browser requires starting LocalStack with <b>ENABLE_DMS=1</b> option
      </Alert>
    </Box>
  );
};
