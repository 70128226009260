import { SERVICE_NAME } from '@localstack/types';

import { PATH_PREFIX_RESOURCES } from './base';

const QLDB_BASE_ROUTE = `${PATH_PREFIX_RESOURCES}/${SERVICE_NAME.QLDB}`;

export const DEFAULT_QLDB_ROUTES: Record<string, string> = {
  RESOURCES_QLDB_LEDGERS: `${QLDB_BASE_ROUTE}/ledgers`,
  RESOURCES_QLDB_LEDGER_CREATE: `${QLDB_BASE_ROUTE}/ledger/new`,
  RESOURCES_QLDB_LEDGER: `${QLDB_BASE_ROUTE}/ledger/:name`,
  RESOURCES_QLDB_LEDGER_UPDATE: `${QLDB_BASE_ROUTE}/ledger/:name/update`,
};
