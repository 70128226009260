import { getSchema } from '@localstack/services';
import { DBCluster } from '@localstack/types';
import { ReactElement } from 'react';

import { MagicDetails } from '../../magic/MagicDetails';

export type DocDBClusterDetailProps = {
  cluster?: Optional<Partial<DBCluster>>;
};

export const DocDBClusterDetail = ({ cluster }: DocDBClusterDetailProps): ReactElement => (
  <MagicDetails data={cluster} schema={getSchema('DocDB')} entry="DBCluster" title="DocDB Cluster Details" />
);
