import { ReactElement } from 'react';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { Link, Breadcrumbs, Box, Typography, Button } from '@mui/material';

export type S3ObjectBreadcrumbsProps = {
  bucket: string;
  prefix: string;
  onViewRoot?: () => void;
  onViewBucket?: (bucket: string, prefix?: string) => void;
  showCopyButton?: boolean;
};

export const S3ObjectBreadcrumbs = ({
  bucket,
  prefix,
  onViewRoot,
  onViewBucket,
  showCopyButton = true,
}: S3ObjectBreadcrumbsProps): ReactElement => {
  const parts = (prefix.endsWith('/') ? prefix.slice(0, -1) : prefix).split('/');

  const currentPart = parts.slice(-1)[0];
  const prefixes = parts
    .slice(0, -1)
    .reduce(
      (memo, key, idx) => ({ ...memo, [key]: parts.slice(0, idx + 1).join('/') }),
      {} as { [key: string]: string },
    );

  const bucketLink = `s3://${bucket}${prefix ? `/${prefix}` : ''}`;

  return (
    <Box display="flex" alignItems="center" justifyContent="space-between">
      <Breadcrumbs>
        <Link onClick={onViewRoot} underline="hover">
          S3
        </Link>
        <Link onClick={() => onViewBucket && onViewBucket(bucket)} underline="hover">
          {bucket}
        </Link>
        {Object.entries(prefixes).map(([name, pref]) => (
          <Link key={pref} onClick={() => onViewBucket && onViewBucket(bucket, pref)} underline="hover">
            {name}
          </Link>
        ))}
        <Typography color="textPrimary">{currentPart}</Typography>
      </Breadcrumbs>
      {showCopyButton && (
        <CopyToClipboard text={bucketLink}>
          <Button color="primary" size="small">
            Copy S3 URI
          </Button>
        </CopyToClipboard>
      )}
    </Box>
  );
};
