import {
  useApiGetter,
  ExtensionService,
  useRoutes,
  useApiEffect,
  useSnackbar,
  AdminService,
} from '@localstack/services';
import { EnrichedExtension } from '@localstack/types';
import { PageTitle } from '@localstack/ui';
import { Grid, Skeleton } from '@mui/material';
import { ReactElement } from 'react';
import { useParams } from 'react-router-dom';

import { AppRoute } from '~/config';

import { AdminLayout } from '~/layouts';
import { ExtensionSubmissionCard } from '~/views/customer/Extensions/components/ExtensionSubmissionCard';

import { ExtensionCard } from '~/views/customer/Extensions/components/ExtensionCard';

import { SmallOrgDetails } from './SmallOrgDetails';
import { ExtensionInstallCard } from './ExtensionInstallCard';

export const AdminExtension = (): ReactElement => {
  const { id: extensionId } = useParams<'id'>() as { id: string };
  const { goto } = useRoutes();

  const { showSnackbar } = useSnackbar();

  const { data: extension, isLoading: isExtensionLoading } = useApiGetter(ExtensionService, 'getExtension', [
    extensionId,
  ]);

  const { updateExtensionAdmin } = useApiEffect(ExtensionService, ['updateExtensionAdmin'], {
    revalidate: ['listExtensions', 'listExtensionsMarketplace', 'getExtension'],
  });

  const { data: organization, isLoading: isOrgLoading } = useApiGetter(
    AdminService,
    'getOrganization',
    [extension?.org_id],
    {
      enable: !!extension,
      suppressErrors: true,
      swrOverrides: { shouldRetryOnError: false },
    },
  );

  const handleChangesSubmit = async (data: EnrichedExtension) => {
    const { logoFile, ...modifiedExtension } = data;
    try {
      const response = await updateExtensionAdmin(modifiedExtension);
      if (response?.pre_signed_url && logoFile) {
        await fetch(response.pre_signed_url, {
          method: 'PUT',
          headers: {
            'Content-Type': logoFile?.type ?? '',
          },
          body: logoFile,
        });
      }
      showSnackbar({
        message: 'Extension successfully updated',
        severity: 'success',
      });
    } catch (e) {
      console.error(e);
    }
  };

  return (
    <AdminLayout
      documentTitle="Extension Details"
      title={
        <PageTitle
          title={`Extension ${extension?.display_name ?? extensionId}`}
          breadcrumbs={[
            ['Extensions', () => goto(AppRoute.ADMIN_EXTENSIONS)],
            [extension?.display_name ?? extensionId, () => null],
          ]}
        />
      }
    >
      <Grid container spacing={2}>
        <Grid item xs={12}>
          {isExtensionLoading ? (
            <Skeleton height={645} variant="rectangular" />
          ) : (
            <ExtensionSubmissionCard
              extension={extension ?? undefined}
              onSubmit={handleChangesSubmit}
              submitText="Save changes"
              cardTitle={extension?.display_name}
              editing
              showPlatformAdminFields
            />
          )}
        </Grid>
        <Grid item xs={6} lg={6}>
          {isOrgLoading || isExtensionLoading ? (
            <Skeleton height={210} variant="rectangular" />
          ) : (
            <ExtensionInstallCard extension={extension} />
          )}
        </Grid>
        <Grid item xs={6}>
          {isExtensionLoading ? (
            <Skeleton height={205} variant="rectangular" />
          ) : (
            extension && <ExtensionCard extension={extension} size="medium" isUpdating={isExtensionLoading} />
          )}
        </Grid>
        <Grid item xs={6} lg={6}>
          {isOrgLoading || isExtensionLoading ? (
            <Skeleton height={275} variant="rectangular" />
          ) : (
            <SmallOrgDetails
              organization={organization ?? undefined}
              alertMessage="No organization attached to this extension"
            />
          )}
        </Grid>
      </Grid>
    </AdminLayout>
  );
};
