/* istanbul ignore file */
/* tslint:disable */

import type { LicensingAuthToken } from '@localstack/types';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class LicenseService {
  /**
   * @returns LicensingAuthToken
   * @throws ApiError
   */
  public static getWorkspaceToken(): CancelablePromise<LicensingAuthToken> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/v1/license/credentials',
    });
  }

  /**
   * @returns LicensingAuthToken
   * @throws ApiError
   */
  public static rotateWorkspaceToken(): CancelablePromise<LicensingAuthToken> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/v1/license/credentials/rotate',
    });
  }
}
