import { ReactElement } from 'react';
import { CreateGlueSchemaRequest, GlueSchema, UpdateGlueSchemaRequest } from '@localstack/types';
import { AwsClientOverrides, getSchema, VALIDATION_RULES } from '@localstack/services';
import { DEFAULT_GLUE_ROUTES } from '@localstack/constants';

import { ControlledCodeEditor } from '../../../../form';
import { MagicForm } from '../../../magic/MagicForm';
import { RelatedResourcePicker } from '../../../../form/RelatedResourcePicker';

export type GlueSchemaFormProps = {
  schema?: Optional<Partial<GlueSchema>>;
  loading?: boolean;
  formId: string;
  clientOverrides?: AwsClientOverrides;
  onCreate: (data: CreateGlueSchemaRequest) => unknown;
  onUpdate: (data: UpdateGlueSchemaRequest) => unknown;
};

export const GlueSchemaForm = ({
  schema,
  loading,
  formId,
  clientOverrides,
  onCreate,
  onUpdate,
}: GlueSchemaFormProps): ReactElement => (
  <MagicForm
    data={schema}
    schema={getSchema('Glue')}
    formId={formId}
    entry={schema ? 'UpdateSchemaInput' : 'CreateSchemaInput'}
    loading={loading}
    fieldConditions={{
      SchemaId: !schema,
      SchemaVersionNumber: !schema,
    }}
    externalFields={{
      '^SchemaDefinition$': (control, fieldName, required, formData) => (
        <ControlledCodeEditor
          control={control}
          name={fieldName}
          language="json"
          required={required}
          rules={formData?.DataFormat === 'JSON' && formData?.SchemaDefinition ? VALIDATION_RULES.validJson : undefined}
        />
      ),
      '^RegistryId.RegistryArn': (control, fieldName, required) => (
        <RelatedResourcePicker
          control={control}
          client="Glue"
          method="listRegistries"
          arrayKeyName="Registries"
          property="RegistryArn"
          fieldName={fieldName}
          entityName="Glue Registry"
          creationRoute={DEFAULT_GLUE_ROUTES.RESOURCES_GLUE_DATABASE_CREATE}
          required={required}
          clientOverrides={clientOverrides}
        />
      ),
    }}
    onSubmit={(data: CreateGlueSchemaRequest) => {
      if (!schema) return onCreate(data);
      const { SchemaArn, SchemaName, RegistryName } = schema;
      return onUpdate({
        ...data,
        SchemaId: { SchemaArn, SchemaName, RegistryName },
      });
    }}
  />
);
