import { useState, ReactElement, useMemo } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import { CheckoutForm, CheckoutFormSkeleton } from '@localstack/ui';
import { VAT_COUNTRIES } from '@localstack/constants';

import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from '@mui/material';

import {
  Plan,
  SubscriptionStatus,
  InvoiceStatus,
  Discount,
  Product,
  OrderContext,
  Subscription,
  TosAcceptanceContext,
} from '@localstack/types';

import {
  useRoutes,
  useApiGetter,
  useApiEffect,
  SubscriptionService,
  UserService,
  OrganizationsService,
} from '@localstack/services';

import { ContainedCustomerLayout } from '~/layouts';
import { AppRoute } from '~/config';
import { useAuthProvider } from '~/hooks/useAuthProvider';
import { StripeCardForm } from '~/components';

export const PricingSubscribe = (): ReactElement => {
  const { subscriptionId } = useParams<'subscriptionId'>() as { subscriptionId?: string };

  const { goto } = useRoutes();
  const { reloadUserInfo, userInfo } = useAuthProvider();

  const { search } = useLocation();
  const query = new URLSearchParams(search);
  const planId = query.get('plan');

  const [promotion, setPromotion] = useState<Optional<Discount>>();
  const [seats, setSeats] = useState<number>(1);
  const [plan, setPlan] = useState<Optional<Plan>>(null);
  const [stripeLoading, setStripeLoading] = useState(false);
  const [showCardForm, setShowCardForm] = useState(false);
  const [showCompletePaymentDialog, setShowCompletePaymentDialog] = useState(false);
  const [newSubscriptionId, setNewSubscriptionId] = useState<Optional<string>>(undefined);

  const { data: invoices, isLoading: isInvoicesLoading } = useApiGetter(SubscriptionService, 'listInvoices', []);

  const openInvoice = useMemo(
    () => invoices?.find((i) => i.status === InvoiceStatus.OPEN && i.subscription === newSubscriptionId),
    [invoices, showCompletePaymentDialog, newSubscriptionId],
  );

  const {
    attachCreditCard,
    removeCreditCard,
    isLoading: isCreditCardMutating,
  } = useApiEffect(UserService, ['attachCreditCard', 'removeCreditCard'], { revalidate: ['listCreditCards'] });

  const { getPromotion, isLoading: isPromotionLoading } = useApiEffect(SubscriptionService, ['getPromotion']);
  const { data: plans, isLoading: isPlansLoading } = useApiGetter(SubscriptionService, 'listPlans', []);
  const { data: cards } = useApiGetter(UserService, 'listCreditCards', []);

  const { data: subscription, isLoading: isSubLoading } = useApiGetter(
    SubscriptionService,
    'getSubscription',
    [subscriptionId ?? ''],
    { enable: !!subscriptionId },
  );

  const { data: keys, isLoading: isKeysLoading } = useApiGetter(SubscriptionService, 'listKeys', [false], {
    enable: !!subscriptionId,
  });

  const { data: licenseAssignments, isLoading: isLicensesLoading } = useApiGetter(
    OrganizationsService,
    'listLicenseAssignments',
    [userInfo?.org.id],
    { enable: !!userInfo },
  );

  const subscriptionLicenceAssignments = licenseAssignments?.filter(
    (license) => license.subscription_ids?.[0] === subscriptionId,
  );

  const { data: tax, isLoading: isTaxLoading } = useApiGetter(
    SubscriptionService,
    'calculateTax',
    [plan?.id ?? '', seats, undefined],
    {
      defaultValue: null,
      enable: !!(plan && userInfo?.org?.country && VAT_COUNTRIES.includes(userInfo.org.country)),
    },
  );

  // TODO: we need to add:
  // revalidateOtherClient: { client: UserService, methods: ['getUser'] },
  // but `revalidateOtherClient` does not support multiple clients atm.
  const {
    createSubscription,
    updateSubscription,
    isLoading: isSubMutating,
  } = useApiEffect(SubscriptionService, ['createSubscription', 'updateSubscription'], {
    revalidate: ['listSubscriptions', 'getSubscription', 'listKeys', 'listInvoices', 'listPlans'],
    revalidateOtherClient: {
      client: OrganizationsService,
      methods: ['listLicenseAssignments', 'getUserLicenseAssignment'],
    },
  });

  const { updateOrganization, isLoading: isOrgMutating } = useApiEffect(OrganizationsService, ['updateOrganization'], {
    revalidate: ['listOrganizations'],
    revalidateOtherClient: { client: UserService, methods: ['getUser'] },
  });

  const { deleteKey, isLoading: isKeyMutating } = useApiEffect(SubscriptionService, ['deleteKey'], {
    revalidate: ['listKeys'],
  });

  const { unassignLicense, isLoading: isUnassigningLicense } = useApiEffect(OrganizationsService, ['unassignLicense'], {
    revalidate: ['listLicenseAssignments'],
  });

  const { acceptTermsOfService } = useApiEffect(UserService, ['acceptTermsOfService']);

  const goToSubscriptionDetails = (id: string) => {
    goto(AppRoute.SETTINGS_SUBSCRIPTION_DETAIL, { subscriptionId: id });
    reloadUserInfo();
  };

  const onApplyPromoCode = async (promotionPlanId: string, code: string) => {
    setPromotion(code ? await getPromotion(promotionPlanId, code) : undefined);
  };

  const onAddNewCard = async (cardId: string, currency: string) => {
    // TODO: think how we can pre-select the card without exposing the state
    await attachCreditCard({ token: cardId, currency });
  };

  const onSubscribe = async (
    subscribedPlan: Plan,
    _products: Product[],
    context: OrderContext,
    sub: Optional<Subscription>,
    _discount: Optional<Discount>,
  ) => {
    // fire up the call early, and await it before we move away from the page.
    // to make sure it is not cancelled by the browser.
    const tosAcceptancePromise = acceptTermsOfService({ acceptance_context: TosAcceptanceContext.CHECKOUT });

    let result: Optional<Subscription>;
    if (sub) {
      result = await updateSubscription(sub.id, { plan: subscribedPlan.id, ...context });
    } else {
      result = await createSubscription({ plan: subscribedPlan.id, ...context });
    }

    await tosAcceptancePromise;

    if ([SubscriptionStatus.ACTIVE, SubscriptionStatus.TRIALING].includes(result.status)) {
      goToSubscriptionDetails(result.id);
    } else if (result.status === SubscriptionStatus.INCOMPLETE) {
      setShowCompletePaymentDialog(true);
      setNewSubscriptionId(result.id);
    }
  };

  const subscriptionKeys = (keys?.org ?? []).filter((k) => k.subscription_id === subscriptionId && !k.is_ci);
  const isPageLoading = isPlansLoading || (subscriptionId && isSubLoading);

  const isLoading =
    isPlansLoading ||
    (subscriptionId && isKeysLoading) ||
    isLicensesLoading ||
    isOrgMutating ||
    isKeyMutating ||
    isUnassigningLicense;

  return (
    <ContainedCustomerLayout title={subscriptionId ? 'Update Subscription Terms' : 'Subscribe to a LocalStack Plan'}>
      {isPageLoading && <CheckoutFormSkeleton />}

      {!isPageLoading && (
        <CheckoutForm
          loading={isLoading}
          isSubmitting={isSubMutating}
          taxLoading={isTaxLoading}
          tax={tax}
          defaultPlan={(plans ?? []).find((p) => p.id === planId)}
          plans={plans ?? []}
          cards={cards ?? []}
          subscription={subscription}
          subscriptionKeys={subscriptionKeys}
          subscriptionLicenseAssignments={subscriptionLicenceAssignments}
          organization={userInfo?.org}
          promotion={promotion}
          promotionLoading={isPromotionLoading}
          showCardForm={showCardForm}
          onChangeSeats={setSeats}
          onChangePlan={setPlan}
          onToggleCardForm={() => setShowCardForm(!showCardForm)}
          onApplyPromoCode={onApplyPromoCode}
          onUpdateOrganization={(org) => updateOrganization(userInfo?.org?.id || '', org)}
          onDeleteCard={(card) => removeCreditCard(card.id)}
          onDeleteApiKey={(keyId) => deleteKey(keyId)}
          onDeleteLicense={(licenseId) => unassignLicense(userInfo?.org.id || '', licenseId)}
          onSubscribe={onSubscribe}
          renderCardForm={(currency: string) => (
            <Box p={2}>
              <StripeCardForm
                loading={stripeLoading || isCreditCardMutating}
                onSaveCard={async (cardId) => {
                  try {
                    setStripeLoading(true);
                    await onAddNewCard(cardId, currency);
                    setShowCardForm(false);
                  } finally {
                    setStripeLoading(false);
                  }
                }}
              />
            </Box>
          )}
        />
      )}
      <Dialog open={showCompletePaymentDialog}>
        <DialogTitle color="primary">
          <Typography variant="h4">Complete Payment</Typography>
        </DialogTitle>
        <DialogContent>
          <Typography variant="h5">Your provided credit card requires further verification.</Typography>
          <Typography variant="h5">Please proceed by clicking on the below button.</Typography>
        </DialogContent>
        <DialogActions>
          {isInvoicesLoading && <CircularProgress />}
          {openInvoice && (
            <Box style={{ display: 'flex', alignItems: 'center', gap: '1rem' }}>
              <Typography variant="body1">Invoice: {openInvoice.number}</Typography>
              <Button
                color="primary"
                variant="contained"
                href={openInvoice.hosted_invoice_url || ''}
                target="_blank"
                onClick={() => goToSubscriptionDetails(newSubscriptionId || '')}
              >
                Pay
              </Button>
            </Box>
          )}
        </DialogActions>
      </Dialog>
    </ContainedCustomerLayout>
  );
};
