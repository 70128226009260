import { ReactElement } from 'react';
import { Route53ResourceRecordSet } from '@localstack/types';
import { getSchema } from '@localstack/services';
import { Link } from '@mui/material';

import { MagicTable } from '../../../magic/MagicTable';

export type Route53HostedZonesRecordsTableProps = {
  records: Route53ResourceRecordSet[];
  selectable?: boolean;
  loading?: boolean;
  onSelect?: (names: string[]) => void;
  onViewRecord?: (record: Route53ResourceRecordSet) => void;
};

export const Route53HostedZonesRecordsTable = ({
  records,
  selectable = true,
  loading,
  onSelect,
  onViewRecord,
}: Route53HostedZonesRecordsTableProps): ReactElement => (
  <MagicTable
    schema={getSchema('Route53')}
    entry="ResourceRecordSet"
    idAttribute={['Name', 'Type']}
    order={['Name', 'Type', 'RoutingPolicy']}
    filterColumns={[
      'Name',
      'Type',
      'RoutingPolicy',
      'SetIdentifier',
      'Failover',
      'TTL',
      'HealthCheckId',
      'TrafficPolicyInstanceId',
    ]}
    rows={records}
    selectable={selectable}
    onSelect={onSelect}
    loading={loading}
    externalFields={{
      Name: (row) => (
        <Link onClick={() => onViewRecord && onViewRecord(row)} underline="hover">
          {row.Name}
        </Link>
      ),
      RoutingPolicy: (row) => <p>{row.RoutingPolicy}</p>,
    }}
  />
);
