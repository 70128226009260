import { DEFAULT_EKS_ROUTES } from '@localstack/constants';
import { ReactElement, useState } from 'react';
import { useAwsEffect, useAwsGetter, useRoutes } from '@localstack/services';
import { Button, Card } from '@mui/material';
import { EKSClustersTable, ConfirmableButton, PageTitle, Dropdown } from '@localstack/ui';

import { EKSProps } from './types';

export const EKSClusters = ({ Layout, clientOverrides, routes = DEFAULT_EKS_ROUTES }: EKSProps): ReactElement => {
  const { goto } = useRoutes();
  const [selectedIds, setSelectedIds] = useState<string[]>([]);
  const { data: clusters, isLoading, mutate } = useAwsGetter('EKS', 'listClusters', [], { clientOverrides });

  const { deleteCluster } = useAwsEffect('EKS', ['deleteCluster'], {
    revalidate: ['listClusters'],
    clientOverrides,
  });
  const handleRemove = async () => {
    await Promise.all(selectedIds.map(async (id) => deleteCluster({ name: id })));
  };
  return (
    <Layout
      title={<PageTitle title="EKS Clusters" onMutate={mutate} />}
      actions={
        <>
          <Button onClick={() => goto(routes.RESOURCES_EKS_CLUSTER_CREATE)}>Create Cluster</Button>
          <Dropdown label="Actions">
            <ConfirmableButton
              componentType="MenuItem"
              disabled={selectedIds.length === 0 || isLoading}
              title={`Delete ${selectedIds.length} Cluster(s)?`}
              onClick={handleRemove}
              text="Selected Clusters will be permanently deleted"
            >
              Remove Selected
            </ConfirmableButton>
          </Dropdown>
        </>
      }
    >
      <Card>
        <EKSClustersTable
          clusters={clusters || {}}
          onViewCluster={(id: string) => goto(routes.RESOURCES_EKS_CLUSTER, { id })}
          loading={isLoading}
          onSelect={setSelectedIds}
        />
      </Card>
    </Layout>
  );
};
