import { CodeVisualizer, Confetti, ProgressButton } from '@localstack/ui';
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Grid,
  Link,
  Paper,
  Tab,
  Tabs,
  Theme,
  Typography,
  Alert,
  AlertTitle,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { ReactElement, ReactNode, useState } from 'react';

import {
  CloudDownload as DownloadIcon,
  EmojiEvents as EmojiEventsIcon,
  OpenInNew as OpenInNewIcon,
} from '@mui/icons-material';

import {
  GithubMetadataService,
  LicenseService,
  MARKER_IDS,
  OrganizationsService,
  TestMarkerSpan,
  useApiGetter,
} from '@localstack/services';

import { NavLink } from 'react-router-dom';

import { ExternalLink } from '@localstack/constants';

import { AppRoute, externalURLs } from '~/config';

import { ContainedCustomerLayout } from '~/layouts';
import { useAuthProvider } from '~/hooks/useAuthProvider';

const useStyles = makeStyles((theme: Theme) => ({
  inlineCode: {
    backgroundColor: theme.palette.background.default,
    padding: '0.2em',
    marginTop: '-0.2em',
    marginBottom: '-0.2em',
    borderRadius: '0.3em',
  },
}));

export const GettingStarted = (): ReactElement => {
  const [runConfetti, setRunConfetti] = useState(false);

  const { userInfo } = useAuthProvider();

  const { data: workspaceToken, isLoading: isWorkspaceTokenLoading } = useApiGetter(
    LicenseService,
    'getWorkspaceToken',
    [],
  );

  const { data: assignmentInfo } = useApiGetter(OrganizationsService, 'getUserLicenseAssignment', [userInfo?.org.id], {
    enable: !!userInfo,
  });

  const { data: cliMetadata, isLoading: cliMetadataIsLoading } = useApiGetter(
    GithubMetadataService,
    'getCliMetadata',
    [],
  );

  const [currentTab, setCurrentTab] = useState('linux');

  const [currentOS, setCurrentOS] = useState('linux');

  const classes = useStyles();

  const TabPanel = (props: { children: ReactNode; value: string; tabIndex: string }) => {
    const { children, value, tabIndex } = props;

    return (
      <div
        role="tabpanel"
        hidden={value !== tabIndex}
        id={`wrapped-tabpanel-${tabIndex}`}
        aria-labelledby={`wrapped-tab-${tabIndex}`}
      >
        {value === tabIndex && (
          <Box p={3}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
  };

  return (
    <ContainedCustomerLayout title="Getting Started">
      {runConfetti && <Confetti numberOfPieces={250} run onConfettiComplete={() => setRunConfetti(false)} />}
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Card>
            <CardHeader title="1. Install the Localstack CLI" titleTypographyProps={{ variant: 'h4' }} />
            <CardContent>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Typography variant="body1">
                    The easiest way to install LocalStack is via a package manager or a prebuilt binary.
                  </Typography>
                </Grid>

                <Grid item xs={12}>
                  <Paper variant="outlined">
                    <Tabs
                      value={currentTab}
                      indicatorColor="primary"
                      textColor="primary"
                      onChange={(_: unknown, newValue: string) => {
                        setCurrentTab(newValue);
                      }}
                    >
                      <Tab label="Linux binary" value="linux" />
                      <Tab label="macOS brew" value="mac" />
                      <Tab label="Windows binary" value="windows" />
                      <Tab label="PyPI" value="other" />
                    </Tabs>

                    <TabPanel value={currentTab} tabIndex="mac">
                      <Typography variant="body1">
                        You can install the LocalStack CLI using{' '}
                        <Link href={ExternalLink.EXTERNAL_BREW} target="_blanc" underline="hover">
                          Brew
                        </Link>{' '}
                        directly from our official LocalStack tap:
                      </Typography>
                      <CodeVisualizer commands={['brew install localstack/tap/localstack-cli']} />
                    </TabPanel>

                    <TabPanel value={currentTab} tabIndex="linux">
                      <Grid container spacing={2}>
                        <Grid item xs={12}>
                          <Typography variant="body1">
                            Download the pre-built binary that matches your architecture:
                          </Typography>
                        </Grid>
                        <Grid item xs={12}>
                          <Grid container spacing={2}>
                            <Grid item>
                              <ProgressButton
                                color="primary"
                                variant="contained"
                                startIcon={<DownloadIcon />}
                                onClick={() => window.open(cliMetadata?.binaries.linux_amd64.downloadUrl, '_blank')}
                                loading={cliMetadataIsLoading}
                              >
                                x86-64
                              </ProgressButton>
                            </Grid>
                            <Grid item>
                              <ProgressButton
                                color="primary"
                                variant="contained"
                                startIcon={<DownloadIcon />}
                                onClick={() => window.open(cliMetadata?.binaries.linux_arm64.downloadUrl, '_blank')}
                                loading={cliMetadataIsLoading}
                              >
                                ARM64
                              </ProgressButton>
                            </Grid>
                          </Grid>
                        </Grid>
                        <Grid item xs={12}>
                          <Typography variant="body1">Then extract the LocalStack CLI using the terminal:</Typography>
                        </Grid>
                        <Grid item xs={12}>
                          <CodeVisualizer
                            commands={[
                              `sudo tar xvzf ~/Downloads/${cliMetadata?.binaries.linux_arm64.name.replace(
                                'arm64',
                                '*',
                              )} -C /usr/local/bin`,
                            ]}
                          />
                        </Grid>
                      </Grid>
                    </TabPanel>

                    <TabPanel value={currentTab} tabIndex="windows">
                      <Grid container spacing={2}>
                        <Grid item xs={12}>
                          <Typography variant="body1">Download the pre-built binary:</Typography>
                        </Grid>
                        <Grid item xs={12}>
                          <ProgressButton
                            color="primary"
                            variant="contained"
                            startIcon={<DownloadIcon />}
                            onClick={() => window.open(cliMetadata?.binaries.windows_amd64.downloadUrl, '_blank')}
                            loading={cliMetadataIsLoading}
                          >
                            64-bit
                          </ProgressButton>
                        </Grid>
                        <Grid item xs={12}>
                          <Typography variant="body1">
                            Then extract the archive and execute the binary using PowerShell.
                          </Typography>
                        </Grid>
                      </Grid>
                    </TabPanel>

                    <TabPanel value={currentTab} tabIndex="other">
                      <Grid container spacing={2}>
                        <Grid item xs={12}>
                          <Typography variant="body1">
                            If you cannot use one of our prebuilt binary releases of LocalStack, you can install the
                            LocalStack CLI in a Python environment.
                            <br />
                            <br />
                            Make sure to install the following tools on your machine before moving ahead — Python and
                            pip (Python package manager). Afterwards, you can install the LocalStack CLI in your Python
                            environment with the following command:
                            <br />
                            <br />
                            <CodeVisualizer commands={['python3 -m pip install --upgrade localstack']} />
                          </Typography>
                        </Grid>
                      </Grid>
                    </TabPanel>
                  </Paper>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Grid>

        <Grid item xs={12}>
          <Card>
            <CardHeader title="2. Configure your personal auth token" titleTypographyProps={{ variant: 'h4' }} />
            <CardContent>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Typography variant="body1">
                    LocalStack uses a personal auth token to access your LocalStack license and to authenticate you
                    within a workspace. The auth token is unique to each workspace. You can manage all your auth tokens
                    in the{' '}
                    <NavLink to={AppRoute.SETTINGS_AUTH_TOKEN} end>
                      Auth Tokens
                    </NavLink>{' '}
                    page. See the{' '}
                    <Link
                      href={externalURLs.documentation.gettingStarted.installation}
                      target="_blank"
                      underline="hover"
                    >
                      documentation
                    </Link>{' '}
                    on how to use LocalStack versions &lt; 2.2.
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Paper variant="outlined">
                    <Tabs
                      value={currentOS}
                      indicatorColor="primary"
                      textColor="primary"
                      onChange={(_: unknown, newValue: string) => {
                        setCurrentOS(newValue);
                      }}
                    >
                      <Tab label="Linux" value="linux" />
                      <Tab label="macOS" value="mac" />
                      <Tab label="Windows" value="windows" />
                    </Tabs>
                    <Box mt={2}>
                      <TestMarkerSpan name={MARKER_IDS.AUTH_TOKEN_VIEWER}>
                        <TabPanel value={currentOS} tabIndex="linux">
                          {isWorkspaceTokenLoading || !workspaceToken ? (
                            <CodeVisualizer commands={['loading...']} showCopy={false} />
                          ) : (
                            <CodeVisualizer commands={[`export LOCALSTACK_AUTH_TOKEN="${workspaceToken?.token}"`]} />
                          )}
                        </TabPanel>

                        <TabPanel value={currentOS} tabIndex="mac">
                          {isWorkspaceTokenLoading || !workspaceToken ? (
                            <CodeVisualizer commands={['loading...']} showCopy={false} />
                          ) : (
                            <CodeVisualizer commands={[`export LOCALSTACK_AUTH_TOKEN="${workspaceToken?.token}"`]} />
                          )}
                        </TabPanel>

                        <TabPanel value={currentOS} tabIndex="windows">
                          {isWorkspaceTokenLoading || !workspaceToken ? (
                            <CodeVisualizer commands={['loading...']} showCopy={false} />
                          ) : (
                            <CodeVisualizer commands={[`$env:LOCALSTACK_AUTH_TOKEN="${workspaceToken?.token}"`]} />
                          )}
                        </TabPanel>
                      </TestMarkerSpan>
                    </Box>
                  </Paper>
                </Grid>
                {assignmentInfo && !assignmentInfo.has_license_assigned && (
                  <Grid item xs={12}>
                    <Box mt={2}>
                      <Alert severity="warning">
                        <AlertTitle>No License Assigned</AlertTitle>
                        There is currently no license assigned to your user. In order to activate LocalStack with this
                        auth token you need to have a license assigned. Workspace admins can assign licenses on the{' '}
                        <Link href={AppRoute.SETTINGS_MEMBERS} underline="hover">
                          Users &amp; Licenses
                        </Link>{' '}
                        page.
                      </Alert>
                    </Box>
                  </Grid>
                )}
              </Grid>
            </CardContent>
          </Card>
        </Grid>

        <Grid item xs={12}>
          <Card>
            <CardHeader title="3. Configure test environment variables" titleTypographyProps={{ variant: 'h4' }} />
            <CardContent>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Typography variant="body1">Set test environment variables</Typography>
                </Grid>
                <Grid item xs={12}>
                  <Paper variant="outlined">
                    <Tabs
                      value={currentOS}
                      indicatorColor="primary"
                      textColor="primary"
                      onChange={(_: unknown, newValue: string) => {
                        setCurrentOS(newValue);
                      }}
                    >
                      <Tab label="Linux" value="linux" />
                      <Tab label="macOS" value="mac" />
                      <Tab label="Windows" value="windows" />
                    </Tabs>
                    <Box mt={2}>
                      <TestMarkerSpan name={MARKER_IDS.AUTH_TOKEN_VIEWER}>
                        <TabPanel value={currentOS} tabIndex="linux">
                          <CodeVisualizer
                            commands={[
                              'export AWS_ACCESS_KEY_ID="test"',
                              'export AWS_SECRET_ACCESS_KEY="test"',
                              'export AWS_DEFAULT_REGION="us-east-1"',
                            ]}
                          />
                        </TabPanel>
                        <TabPanel value={currentOS} tabIndex="mac">
                          <CodeVisualizer
                            commands={[
                              'export AWS_ACCESS_KEY_ID="test"',
                              'export AWS_SECRET_ACCESS_KEY="test"',
                              'export AWS_DEFAULT_REGION="us-east-1"',
                            ]}
                          />
                        </TabPanel>

                        <TabPanel value={currentOS} tabIndex="windows">
                          <CodeVisualizer
                            commands={[
                              '$env:AWS_ACCESS_KEY_ID="test"',
                              '$env:AWS_SECRET_ACCESS_KEY="test"',
                              '$env:AWS_DEFAULT_REGION="us-east-1"',
                            ]}
                          />
                        </TabPanel>
                      </TestMarkerSpan>
                    </Box>
                  </Paper>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Grid>

        <Grid item xs={12}>
          <Card>
            <CardHeader title="4. Run LocalStack" titleTypographyProps={{ variant: 'h4' }} />
            <CardContent>
              <Typography variant="body1">
                Begin by running LocalStack and creating your first local S3 bucket. Start the LocalStack instance using
                the following command:
              </Typography>
              <CodeVisualizer commands={['localstack start']} />
              <Typography variant="body1">
                After starting LocalStack, you can create your first S3 bucket using the{' '}
                <Link href={ExternalLink.EXTERNAL_AWS_CLI} target="_blank" underline="hover">
                  AWS CLI
                </Link>
                .
              </Typography>
              <CodeVisualizer commands={['aws s3 mb s3://my-first-bucket --endpoint-url=http://localhost:4566']} />

              <Typography variant="body1">
                Additionally, you can install the <code className={classes.inlineCode}>awslocal</code> cli tool. It acts
                as a thin wrapper around the <code className={classes.inlineCode}>aws</code> command, automatically
                setting the <code className={classes.inlineCode}>--endpoint-url</code> for each call. Find out more in
                our{' '}
                <Link href={ExternalLink.DOCS_AWS_CLI} target="_blank" underline="hover">
                  docs
                </Link>
                .
              </Typography>
            </CardContent>
          </Card>
        </Grid>

        <Grid item xs={12}>
          <Card>
            <CardHeader title="5. Well Done!" titleTypographyProps={{ variant: 'h4' }} />
            <CardContent>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Typography variant="body1">
                    Congratulations! You have set up LocalStack on your local machine and have everything configured and
                    operational! Explore sample architectures and step-by-step tutorials on LocalStack Developer Hub
                    alongside the full documentation.
                  </Typography>
                </Grid>

                <Grid item>
                  <ProgressButton
                    color="primary"
                    startIcon={<EmojiEventsIcon />}
                    variant="contained"
                    onClick={() => setRunConfetti(true)}
                    loading={runConfetti}
                  >
                    Celebrate!
                  </ProgressButton>
                </Grid>

                <Grid item>
                  <Button
                    color="primary"
                    variant="contained"
                    startIcon={<OpenInNewIcon />}
                    onClick={() => window.open(externalURLs.documentation.root, '_blank')}
                  >
                    Documentation
                  </Button>
                </Grid>

                <Grid item>
                  <Button
                    color="primary"
                    variant="contained"
                    startIcon={<OpenInNewIcon />}
                    onClick={() => window.open(externalURLs.developerHub.root, '_blank')}
                  >
                    Developer Hub
                  </Button>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </ContainedCustomerLayout>
  );
};
