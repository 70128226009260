import { ReactElement } from 'react';
import { SendSESEmailRequest } from '@localstack/types';
import { getSchema } from '@localstack/services';

import { MagicForm } from '../../magic/MagicForm';

export interface SESV1MessageFormProps {
  source: string;
  loading?: boolean;
  formId?: string;
  onSubmit: (data: SendSESEmailRequest) => unknown;
}

export const SESV1MessageForm = ({ source, loading, formId, onSubmit }: SESV1MessageFormProps): ReactElement => (
  <MagicForm
    schema={getSchema('SES')}
    loading={loading}
    entry="SendEmailRequest"
    formId={formId}
    fieldConditions={{
      '^Source$': false,
    }}
    onSubmit={(data: SendSESEmailRequest) => onSubmit({ ...data, Source: source })}
  />
);
