import { VALIDATION_RULES } from '@localstack/services';
import { ControlledAutocomplete, ControlledAutocompleteProps } from '@localstack/ui';
import { TextField } from '@mui/material';
import { ReactElement } from 'react';

type Props = Omit<ControlledAutocompleteProps, 'renderInput' | 'getOptionValue' | 'getValueOption'>;

export const VertexIdAutocomplete = ({ placeholder, ...props }: Props): ReactElement => (
  <ControlledAutocomplete
    {...props}
    fullWidth
    freeSolo
    autoSelect
    getOptionLabel={(node) => (typeof node === 'string' ? node : node.id)}
    getOptionValue={(node) => (typeof node === 'string' ? node : node.id)}
    getValueOption={(node) => (typeof node === 'string' ? node : node?.id)}
    isOptionEqualToValue={(key, value) => key === value}
    rules={VALIDATION_RULES.required}
    renderOption={(innerProps, node) => (
      <li {...innerProps}>
        {node.label} [{node.id}]
      </li>
    )}
    renderInput={(params) => (
      <TextField {...params} label={placeholder} placeholder={placeholder} required variant="outlined" />
    )}
  />
);
