import { SERVICE_NAME } from '@localstack/types';

import { PATH_PREFIX_RESOURCES } from './base';

const MONITORING_BASE_ROUTE = `${PATH_PREFIX_RESOURCES}/${SERVICE_NAME.CLOUDWATCH}`;

export const DEFAULT_METRICS_ROUTES: Record<string, string> = {
  RESOURCES_CLOUDWATCH_MONITORING: `${MONITORING_BASE_ROUTE}`,
  RESOURCES_CLOUDWATCH_MONITORING_METRICS: `${MONITORING_BASE_ROUTE}/metrics`,
  RESOURCES_CLOUDWATCH_MONITORING_METRIC: `${MONITORING_BASE_ROUTE}/metrics/:namespace/:name/:dimensions`,
  RESOURCES_CLOUDWATCH_MONITORING_METRIC_CREATE: `${MONITORING_BASE_ROUTE}/metrics/new`,
  RESOURCES_CLOUDWATCH_MONITORING_ALARMS: `${MONITORING_BASE_ROUTE}/alarms`,
  RESOURCES_CLOUDWATCH_MONITORING_ALARM: `${MONITORING_BASE_ROUTE}/alarms/:name`,
  RESOURCES_CLOUDWATCH_MONITORING_ALARM_CREATE: `${MONITORING_BASE_ROUTE}/alarms/new`,
};
