/**
 * TODO: replace JSON.stringify() in aws.ts/api.ts with simpleHash()
 */
export const simpleHash = (input: unknown): string => {
  const inputString = JSON.stringify(input ?? '');
  return Array.from(inputString)
    .reduce((hash, sym) => (Math.imul(31, hash) + sym.charCodeAt(0)) | 0, 0)
    .toString(16);
};

export const obfuscateWithStars = (str: string, numPlacesToShow = 3): string => {
  if (str.length <= numPlacesToShow) {
    return str;
  }

  const visible = str.slice(0, numPlacesToShow);
  const hidden = '\u2217'.repeat(str.length - numPlacesToShow); // low asterisk ∗∗∗∗∗∗∗

  return visible + hidden;
};
