import { ReactElement } from 'react';
import { useParams } from 'react-router-dom';
import { Card, CardContent, CardActions, Box, Typography } from '@mui/material';
import { CreateAgwRestApiUsagePlanRequest, UpdateAgwRestApiUsagePlanRequest } from '@localstack/types';
import { useRoutes, useAwsEffect, useAwsGetter } from '@localstack/services';
import { ProgressButton, AgwRestApiUsagePlanForm, Breadcrumbs } from '@localstack/ui';
import { DEFAULT_API_GATEWAY_ROUTES } from '@localstack/constants';

import { ApiGatewayProps } from './types';
import { RestApiNavTabs } from './components';

export const AgwRestApiUsagePlanUpsert = ({
  Layout,
  clientOverrides,
  routes = DEFAULT_API_GATEWAY_ROUTES,
}: ApiGatewayProps): ReactElement => {
  const { goto } = useRoutes();
  const { restApiId, usagePlanId } = useParams<'restApiId' | 'usagePlanId'>();

  const { createUsagePlan, updateUsagePlan, isLoading } = useAwsEffect(
    'APIGateway',
    ['createUsagePlan', 'updateUsagePlan'],
    {
      revalidate: ['getUsagePlans', 'getUsagePlan'],
      clientOverrides,
    },
  );

  const { data: api } = useAwsGetter('APIGateway', 'getRestApi', [{ restApiId }], { clientOverrides });
  const { data: usagePlan } = useAwsGetter('APIGateway', 'getUsagePlan', [{ usagePlanId }], { clientOverrides });

  const handleCreate = async (data: CreateAgwRestApiUsagePlanRequest) => {
    await createUsagePlan(data);
    goto(routes.RESOURCES_AGW1_API_USAGE_PLANS, { restApiId });
  };

  const handleUpdate = async (data: UpdateAgwRestApiUsagePlanRequest) => {
    await updateUsagePlan(data);
    goto(routes.RESOURCES_AGW1_API_USAGE_PLAN, { restApiId, usagePlanId });
  };

  return (
    <Layout
      title={
        <Box>
          <Typography variant="h4">Usage Plan Details</Typography>
          <Breadcrumbs
            mappings={[
              ['API Gateway', () => goto(routes.RESOURCES_AGW1)],
              [api?.name, () => goto(routes.RESOURCES_AGW1_API, { restApiId })],
              [usagePlan?.name, () => goto(routes.RESOURCES_AGW1_API_USAGE_PLAN, { restApiId, usagePlanId })],
              [usagePlanId ? 'Update' : 'Create Plan', null],
            ]}
          />
        </Box>
      }
      tabs={<RestApiNavTabs restApiId={restApiId as string} routes={routes} />}
      actions={
        <ProgressButton type="submit" form="update" variant="outlined" color="primary" loading={isLoading}>
          Submit
        </ProgressButton>
      }
    >
      <Card>
        <CardContent>
          <AgwRestApiUsagePlanForm
            clientOverrides={clientOverrides}
            usagePlan={usagePlan}
            onCreate={handleCreate}
            onUpdate={handleUpdate}
            loading={isLoading}
            formId="update"
          />
        </CardContent>
        <CardActions>
          <ProgressButton type="submit" form="update" variant="contained" color="primary" loading={isLoading}>
            Submit
          </ProgressButton>
        </CardActions>
      </Card>
    </Layout>
  );
};
