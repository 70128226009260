import { DEFAULT_DOCDB_ROUTES } from '@localstack/constants';
import { ReactElement, useEffect, useState } from 'react';
import { useAwsEffect, useAwsGetter, useRoutes } from '@localstack/services';
import { Card, MenuItem } from '@mui/material';
import { DocDBClustersTable, ConfirmableButton, PageTitle, Dropdown } from '@localstack/ui';

import { DocDBTableRow } from '@localstack/types';

import { DocDBProps } from './types';

export const DocDBClusters = ({ Layout, clientOverrides, routes = DEFAULT_DOCDB_ROUTES }: DocDBProps): ReactElement => {
  const { goto } = useRoutes();
  const [selectedIds, setSelectedIds] = useState<string[]>([]);
  const [gridData, setGridData] = useState<DocDBTableRow[]>([]);
  const {
    data: clustersData,
    isLoading: loadingClusters,
    mutate: mutateClusters,
  } = useAwsGetter('DocDB', 'describeDBClusters', [], { clientOverrides });
  const {
    data: instances,
    isLoading: loadingInstances,
    mutate: mutateInstances,
  } = useAwsGetter('DocDB', 'describeDBInstances', [], {
    clientOverrides,
  });

  const mutate = () => {
    mutateClusters();
    mutateInstances();
  };

  const {
    deleteDBCluster,
    deleteDBInstance,
    isLoading: isDeleting,
  } = useAwsEffect('DocDB', ['deleteDBCluster', 'deleteDBInstance'], {
    clientOverrides,
    revalidate: ['describeDBClusters', 'describeDBInstances'],
  });
  const loading = loadingInstances || loadingClusters || isDeleting;

  useEffect(() => {
    if (loading) return;

    const newClusters: DocDBTableRow[] =
      clustersData?.DBClusters?.map((cluster) => ({
        DBIdentifier: cluster.DBClusterIdentifier || '',
        Engine: cluster.Engine || '',
        Status: cluster.Status || '',
        Type: 'Cluster',
        Size: `${cluster.DBClusterMembers?.length ?? 0} Instance(s)`,
        childrenIds: cluster.DBClusterMembers?.map((instance) => instance.DBInstanceIdentifier || '') || [],
      })) || [];

    const newInstances: DocDBTableRow[] =
      instances?.DBInstances?.map((instance) => ({
        DBIdentifier: instance.DBInstanceIdentifier || '',
        Status: instance.DBInstanceStatus || '',
        Type: 'Instance',
        Engine: instance.Engine || '',
        Size: instance.DBInstanceClass || '',
      })) || [];

    setGridData([...newClusters, ...newInstances]);
  }, [clustersData, instances]);

  const handleRemove = async () =>
    Promise.all(
      selectedIds.map(async (id) => {
        const row = gridData.find((item) => item.DBIdentifier === id);
        if (row?.Type === 'Cluster') {
          await Promise.all(
            row?.childrenIds?.map(async (childId) => deleteDBInstance({ DBInstanceIdentifier: childId })) || [],
          );
          await deleteDBCluster({ DBClusterIdentifier: id });
        } else if (row?.Type === 'Instance') {
          await deleteDBInstance({ DBInstanceIdentifier: id });
        }
      }),
    );

  return (
    <Layout
      title={<PageTitle title="DocDB Clusters" onMutate={mutate} />}
      actions={
        <>
          <Dropdown label="Actions">
            <MenuItem onClick={() => goto(routes.RESOURCES_DOCDB_CLUSTER_CREATE)}>Create Cluster</MenuItem>
            <MenuItem onClick={() => goto(routes.RESOURCES_DOCDB_INSTANCE_CREATE)}>Create Instance</MenuItem>
            <ConfirmableButton
              componentType="MenuItem"
              disabled={selectedIds.length === 0 || loading}
              title={`Delete ${selectedIds.length} item(s)?`}
              onClick={handleRemove}
              text="Selected items will be permanently deleted"
            >
              Remove Selected
            </ConfirmableButton>
          </Dropdown>
        </>
      }
    >
      <Card>
        <DocDBClustersTable
          items={gridData || []}
          onViewCluster={(id: string) => goto(routes.RESOURCES_DOCDB_CLUSTER, { id })}
          onViewInstance={(id: string) => goto(routes.RESOURCES_DOCDB_INSTANCE, { id })}
          loading={loading}
          onSelect={setSelectedIds}
        />
      </Card>
    </Layout>
  );
};
