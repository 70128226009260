import { ReactElement } from 'react';
import { TextField } from '@mui/material';
import { AwsClientOverrides, buildAgwPatches, buildRoute, getSchema } from '@localstack/services';

import {
  AgwRestApiMethodResponse,
  CreateAgwRestApiMethodResponseRequest,
  UpdateAgwRestApiMethodResponseRequest,
} from '@localstack/types';

import { DEFAULT_API_GATEWAY_ROUTES } from '@localstack/constants';

import { MagicForm } from '../../../magic/MagicForm';
import { ControlledCheckbox, RelatedResourcePicker } from '../../../../form';

export interface AgwRestApiMethodResponseFormProps {
  restApiId: string;
  resourceId: string;
  httpMethod: string;
  response?: Optional<Partial<AgwRestApiMethodResponse>>;
  loading?: boolean;
  formId?: string;
  clientOverrides?: AwsClientOverrides;
  onCreate: (data: CreateAgwRestApiMethodResponseRequest) => unknown;
  onUpdate: (data: UpdateAgwRestApiMethodResponseRequest) => unknown;
}

export const AgwRestApiMethodResponseForm = ({
  restApiId,
  resourceId,
  httpMethod,
  response,
  loading,
  formId,
  clientOverrides,
  onCreate,
  onUpdate,
}: AgwRestApiMethodResponseFormProps): ReactElement => (
  <MagicForm
    data={response}
    schema={getSchema('APIGateway')}
    loading={loading}
    entry="PutMethodResponseRequest"
    formId={formId}
    externalFields={{
      '^restApiId$': () => <TextField value={restApiId} disabled fullWidth variant="outlined" />,
      '^resourceId$': () => <TextField value={resourceId} disabled fullWidth variant="outlined" />,
      '^httpMethod$': () => <TextField value={httpMethod} disabled fullWidth variant="outlined" />,
      '^responseModels\\..+': (control, fieldName, required) => (
        <RelatedResourcePicker
          required={required}
          control={control}
          fieldName={fieldName}
          entityName="Model"
          client="APIGateway"
          method="getModels"
          arrayKeyName="items"
          property="name"
          args={[{ restApiId }]}
          clientOverrides={clientOverrides}
          creationRoute={buildRoute(DEFAULT_API_GATEWAY_ROUTES.RESOURCES_AGW1_API_MODEL_CREATE, { restApiId })}
        />
      ),
      '^responseParameters\\..+$': (control, name) => (
        <ControlledCheckbox name={name} label="Required" color="primary" control={control} />
      ),
    }}
    onSubmit={(data: CreateAgwRestApiMethodResponseRequest) => {
      if (!response) return onCreate({ ...data, restApiId, resourceId, httpMethod });

      const patchOperations = buildAgwPatches(response, data);

      return onUpdate({
        restApiId,
        resourceId,
        httpMethod,
        statusCode: response.statusCode as string,
        patchOperations,
      });
    }}
  />
);
