export const removeHubspotCookies = (): void => {
  ['__hstc', 'hubspotutk', 'messagesUtk', '__hssc', 'hs-messages-is-open', '__hssrc'].forEach((cookie) => {
    document.cookie = `${cookie}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;`;
  });
};

export const generateOffsetTimestamp = (hours: number): number => {
  const currentTimeInSeconds = Math.floor(Date.now() / 1000);
  const futureTimeInSeconds = currentTimeInSeconds + hours * 60 * 60;
  const timestamp = Math.floor(new Date(futureTimeInSeconds * 1000).getTime() / 1000);
  return timestamp;
};

export const tokenIsNotExpired = (expiresAt: number): boolean => {
  const currentTimeInSeconds = Math.floor(Date.now() / 1000);
  return expiresAt > currentTimeInSeconds;
};
