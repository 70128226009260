import {
  CloudPodsService,
  ComputeService,
  buildRoute,
  formatBytes,
  formatDateTime,
  getSelectedInstance,
  useApiEffect,
  useApiGetter,
  useAwsEffect,
  useRoutes,
  useSnackbar,
  retrieveAuthToken,
} from '@localstack/services';
import {
  CloudPod,
  CloudPodMergeStrategy,
  CloudPodVersion,
  LocalStackEvent,
  LocalStackInstance,
  ProductFeature,
} from '@localstack/types';
import {
  AwsServiceIcon,
  ConfirmableButton,
  ControlledAutocomplete,
  InstanceSelectorButton,
  LoadingFragment,
  ProgressButton,
  StatusIndicator,
  VersionSelector,
} from '@localstack/ui';
import { Card, CardHeader, CardContent, CardActions, Box, Typography, Theme, Tooltip, TextField } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import createStyles from '@mui/styles/createStyles';
import { CloudDownload as CloudDownloadIcon, Lock } from '@mui/icons-material';
import classNames from 'classnames';
import { ReactElement, useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';

import { AppRoute } from '~/config';
import { useAuthProvider } from '~/hooks/useAuthProvider';
import { getUserInfo } from '~/util/storage';

import { DISABLED_TEAM_TOOLTIP_TEXT } from './common';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    highlighted: {
      border: `2px solid ${theme.palette.primary.main}`,
      animation: '$fadeBorderAnimation 10s forwards',
    },
    '@keyframes fadeBorderAnimation': {
      '0%': {
        borderColor: `2px solid ${theme.palette.primary.main}`,
      },
      '100%': {
        borderColor: 'transparent',
      },
    },
    cardContent: {
      gap: '1rem',
      display: 'flex',
      flexDirection: 'column',
    },
    cardActions: {
      display: 'flex',
      justifyContent: 'space-between',
    },
  }),
);

type LoadFromCloudPodCardProps = {
  podName?: string;
  version?: string;
  size?: 'small' | 'medium';
  highlighted?: boolean;
  enableBrowse?: boolean;
  selector?: boolean;
  mainAction?: 'install' | 'instance';
  disabled?: boolean;
  showSkeletonLoad?: boolean;
  callbackVersionSelection?: (podVersion: CloudPodVersion) => unknown;
};

type PodFormProps = {
  podName: string;
  version: number;
  merge: CloudPodMergeStrategy;
};

export const LoadFromCloudPodCard = ({
  podName,
  version,
  size,
  highlighted,
  selector,
  mainAction = 'install',
  enableBrowse,
  disabled = false,
  showSkeletonLoad = false,
  callbackVersionSelection,
}: LoadFromCloudPodCardProps): ReactElement => {
  const userInfo = getUserInfo();
  const organization = userInfo?.org;
  const { goto } = useRoutes();
  const { showSnackbar } = useSnackbar();
  const classes = useStyles();
  const [hasInjectedPod, setHasInjectedPod] = useState(false);
  const [logOutput, setLogOutput] = useState<LocalStackEvent[]>([]);

  const { hasFeature } = useAuthProvider();
  const hasTeamSubscription = hasFeature(ProductFeature.FEATURE_PODS);

  const current_instance = getSelectedInstance();
  const clientOverrides = current_instance ? { endpoint: current_instance.endpoint } : {};

  const { data: allPods, isLoading: isFetchingPods } = useApiGetter(CloudPodsService, 'listCloudPods', [], {
    defaultValue: [],
  });

  const {
    loadPod,
    isLoading: isLoadingPod,
    hasError: hasPodLoadingError,
  } = useAwsEffect('LocalStack', ['loadPod'], { silentErrors: true, clientOverrides });

  const {
    deployBrowsableCloudPod,
    isLoading: isDeployPodEndpointLoading,
    error: browsablePodDeployError,
  } = useApiEffect(CloudPodsService, ['deployBrowsableCloudPod'], {
    revalidateOtherClient: {
      client: ComputeService,
      methods: ['listEphemeralInstances'],
    },
  });

  const [selectedPod, setSelectedPod] = useState<Optional<CloudPod>>(undefined);
  const [selectedPodVersionNumber, setSelectedPodVersionNumber] = useState<Optional<number>>(undefined);

  const { control, handleSubmit, watch, setValue } = useForm<PodFormProps>();
  const watchPodName = watch('podName');

  useEffect(() => {
    const selectPod = allPods?.find((pod) => pod.pod_name === watchPodName);
    setSelectedPod(selectPod);
  }, [watchPodName]);

  const podVersions = useMemo(
    () => selectedPod?.versions?.filter((v) => !v.deleted).map((v) => v.version),
    [selectedPod?.pod_name],
  );

  useEffect(() => {
    if (!podVersions) return;
    setSelectedPodVersionNumber(podVersions[podVersions.length - 1]);
  }, [podVersions]);

  const podVersion: Optional<CloudPodVersion> = useMemo(
    () => selectedPod?.versions?.find((v) => v.version === selectedPodVersionNumber),
    [selectedPodVersionNumber, podVersions],
  );

  useEffect(() => {
    if (podVersion) callbackVersionSelection?.(podVersion);
  }, [podVersion]);

  const onSubmit = async (data: PodFormProps) => {
    setLogOutput([]);
    setHasInjectedPod(false);
    const authToken = retrieveAuthToken();
    setLogOutput([{ event: 'status', message: 'Loading pod...' }]);
    await loadPod(data.podName, data.version, authToken?.token, data.merge);
    setHasInjectedPod(true);
  };

  useEffect(() => {
    if (!selectedPodVersionNumber) return;
    setValue('version', selectedPodVersionNumber);
  }, [selectedPodVersionNumber]);

  useEffect(() => {
    if (hasInjectedPod && !isLoadingPod && hasPodLoadingError) {
      setLogOutput((prevLogOutput) => [...prevLogOutput, { event: 'error', message: 'Error loading pod' }]);
    }
    if (hasInjectedPod && !isLoadingPod && !hasPodLoadingError) {
      setLogOutput((prevLogOutput) => [...prevLogOutput, { event: 'status', message: 'Pod successfully loaded' }]);
    }
  }, [hasInjectedPod, isLoadingPod, hasPodLoadingError]);

  useEffect(() => {
    if (!podName) return;
    setValue('podName', podName);
    setSelectedPod(allPods?.find((pod) => pod.pod_name === podName));
  }, [podName, allPods]);

  useEffect(() => {
    if (!version) return;
    setSelectedPodVersionNumber(parseInt(version, 10));
  }, [version, selectedPod]);

  const disableButtons = !hasTeamSubscription || !selectedPod || disabled;

  const DefaultLoadButton = (
    <Tooltip title={disableButtons ? DISABLED_TEAM_TOOLTIP_TEXT : ''}>
      <div>
        <ProgressButton
          loading={isLoadingPod}
          variant="outlined"
          disabled={disableButtons}
          color="primary"
          type="submit"
          startIcon={<CloudDownloadIcon />}
        >
          Load state from Pod
        </ProgressButton>
      </div>
    </Tooltip>
  );

  const InstanceSelector = (
    <InstanceSelectorButton
      size="medium"
      callback={(instance: LocalStackInstance) =>
        goto(
          AppRoute.INSTANCE_STATE,
          { iid: instance.id },
          `cloudpod=${selectedPod?.pod_name}&version=${selectedPodVersionNumber}`,
        )
      }
      label="Load into Instance"
    />
  );

  const loadBrowsablePodEndpoint = async () => {
    const result = await deployBrowsableCloudPod(selectedPod?.pod_name, selectedPodVersionNumber);

    if (!result || browsablePodDeployError) {
      return showSnackbar({ severity: 'error', message: 'Unable to browse cloud pod state' });
    }

    goto(buildRoute(AppRoute.RESOURCES_OVERVIEW, { iid: result.instance_name }));
  };

  const BrowsePodButton = (
    <ConfirmableButton
      title="Browse Pod"
      text="
      This will start a new ephemeral LocalStack instance for 30 minutes and load the selected Pod version.
      This will consume 30 credits of your ephemeral instance credit quota.
      "
      componentType="ProgressButton"
      onClick={loadBrowsablePodEndpoint}
      loading={isDeployPodEndpointLoading}
      variant="outlined"
      disabled={isDeployPodEndpointLoading}
    >
      Browse Version
    </ConfirmableButton>
  );

  const getUserName = (userId: string) => {
    const member = organization?.members.find((user) => user.id === userId);
    return member?.name;
  };

  return (
    <Card className={classNames({ [classes.highlighted]: highlighted })}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <CardHeader title={selector ? 'Load State from Cloud Pod' : `Pod: ${podName}`} />
        <CardContent className={classes.cardContent}>
          {allPods && selector && (
            <>
              <ControlledAutocomplete
                control={control}
                name="podName"
                label="Pod"
                defaultValue={podName ?? undefined}
                fullWidth
                options={allPods.map((pod) => pod.pod_name)}
                getOptionValue={(item) => item}
                getValueOption={(item) => item}
                renderInput={(params) => <TextField {...params} label="Cloud Pod Name" variant="outlined" />}
                variant="outlined"
              />
              <ControlledAutocomplete
                control={control}
                name="merge"
                label="Merge Strategy"
                defaultValue={CloudPodMergeStrategy.ACCOUNT_REGION_MERGE}
                fullWidth
                options={[
                  CloudPodMergeStrategy.ACCOUNT_REGION_MERGE,
                  CloudPodMergeStrategy.SERVICE_MERGE,
                  CloudPodMergeStrategy.OVERWRITE,
                ]}
                getOptionValue={(item) => item}
                getValueOption={(item) => item}
                renderInput={(params) => <TextField {...params} label="Merge Strategy" variant="outlined" />}
                variant="outlined"
              />
            </>
          )}
          {(selectedPod || showSkeletonLoad) && (
            <>
              <Typography variant="body1" color="textSecondary">
                Versions:
              </Typography>
              <VersionSelector
                size={size}
                loading={isFetchingPods}
                selected={selectedPodVersionNumber}
                onSelectVersion={(v: number) => setSelectedPodVersionNumber(v)}
                versions={podVersions ?? []}
              />
            </>
          )}
          <LoadingFragment loading={isFetchingPods && showSkeletonLoad} variant="list" size={5} height={24}>
            {!!podVersion?.user_id && (
              <Typography variant="body1" color="textSecondary">
                Created by: {getUserName(podVersion?.user_id)}
              </Typography>
            )}
            {!!podVersion?.description && (
              <Typography variant="body1" color="textSecondary">
                Description: {podVersion?.description}
              </Typography>
            )}
            {!!podVersion?.created_at && (
              <Typography variant="body1" color="textSecondary">
                Created at: {formatDateTime(podVersion?.created_at)}
              </Typography>
            )}
            {!!podVersion?.localstack_version && (
              <Typography variant="body1" color="textSecondary">
                LocalStack version: {podVersion?.localstack_version}
              </Typography>
            )}
            {!!podVersion?.storage_size && (
              <Typography variant="body1" color="textSecondary">
                Size: {formatBytes(parseInt(`${podVersion?.storage_size}` || '0', 10))}
              </Typography>
            )}
            {!!podVersion?.services && (
              <Box display="flex" alignItems="center">
                <Typography variant="body1" color="textSecondary">
                  Services:
                </Typography>
                {podVersion?.services &&
                  podVersion.services.map((code: string) => <AwsServiceIcon code={code} key={code} />)}
              </Box>
            )}
            {!!selectedPod?.encrypted && (
              <Box display="flex" alignItems="center">
                <Typography variant="body1" color="textSecondary">
                  This pod is encrypted
                </Typography>
                <Lock />
              </Box>
            )}
          </LoadingFragment>
        </CardContent>
        <CardActions className={classes.cardActions}>
          {enableBrowse && BrowsePodButton}
          {(isLoadingPod || hasInjectedPod) && (
            <StatusIndicator
              isProgressing={isLoadingPod}
              logEvents={logOutput}
              hasError={hasPodLoadingError && !isLoadingPod}
            />
          )}
          {!isLoadingPod && <div />}
          {mainAction === 'install' && DefaultLoadButton}
          {mainAction === 'instance' && InstanceSelector}
        </CardActions>
      </form>
    </Card>
  );
};
