import md5 from 'md5';
import { ReactElement, useMemo } from 'react';
import { RDSQueryResults, RDSQueryField } from '@localstack/types';

import { MagicTable } from '../../magic/MagicTable';

const DEFAULT_PAGE_SIZE = 50;

export interface RDSResultsTableProps {
  results: Optional<RDSQueryResults>;
  loading?: boolean;
  hasMore?: boolean;
  page?: number;
  pageSize?: number;
  onPageChange?: (page: number) => unknown;
}

const parseField = (field: RDSQueryField) => {
  if (field.isNull) {
    return 'NULL';
  }

  if (field.blobValue) {
    return 'Binary Blob';
  }

  if (field.arrayValue) {
    const array = field.arrayValue;
    return (
      array.booleanValues ||
      array.doubleValues ||
      array.longValues ||
      array.stringValues ||
      array.arrayValues
    )?.join(',');
  }

  if (field.booleanValue !== undefined) {
    return field.booleanValue.toString();
  }

  return (field.stringValue || field.doubleValue || field.longValue)?.toString();
};

export const RDSResultsTable = ({
  results,
  loading,
  hasMore,
  page,
  pageSize = DEFAULT_PAGE_SIZE,
  onPageChange,
}: RDSResultsTableProps): ReactElement => {
  // build simple table schema based on results
  const schema = useMemo(
    () => ({
      shapes: {
        AbstractItem: {
          type: 'structure',
          members: Object.fromEntries((results?.columnMetadata ?? []).map((cm) => [cm.name, { type: 'string' }])),
        },
      },
    }),
    [results],
  );

  const tablePageSize = pageSize || DEFAULT_PAGE_SIZE;
  const columnNames = results?.columnMetadata?.map((cm) => cm.name as string) ?? [];

  // first row contains column names
  const rowsWithColumns = (results?.records ?? []).map((row) =>
    row.reduce((memo, data, idx) => ({ ...memo, [columnNames[idx] as string]: parseField(data) }), {}),
  );

  // we need to bring unique row ids
  const hashedRows = rowsWithColumns.map((row) => ({ $rowHash: md5(JSON.stringify(row)), ...row }));

  return (
    <MagicTable
      pagination
      paginationMode={hasMore ? 'server' : 'client'}
      page={page}
      pageSize={tablePageSize}
      rowCount={hasMore ? ((page ?? 0) + 1) * tablePageSize + 1 : rowsWithColumns.length}
      onPageChange={onPageChange}
      rowsPerPageOptions={[tablePageSize]}
      schema={schema}
      loading={loading}
      entry="AbstractItem"
      rows={hashedRows}
      idAttribute="$rowHash"
      formatNames={false}
      selectable={false}
    />
  );
};
