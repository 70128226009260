import { ReactElement } from 'react';
import { GlueRegistryListItem } from '@localstack/types';
import { getSchema } from '@localstack/services';

import { MagicDetails } from '../../../magic/MagicDetails';

export interface GlueRegistryDetailsProps {
  registry?: Optional<Partial<GlueRegistryListItem>>;
}

export const GlueRegistryDetails = ({ registry }: GlueRegistryDetailsProps): ReactElement => (
  <MagicDetails data={registry} schema={getSchema('Glue')} entry="RegistryListItem" title="Schema Registry Details" />
);
