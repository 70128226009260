import { ReactElement, useCallback, useState } from 'react';
import { useRoutes, useAwsGetter, useAwsEffect } from '@localstack/services';
import { Card, Button } from '@mui/material';
import { Dropdown, ConfirmableButton, PageTitle, GlueRegistriesTable } from '@localstack/ui';
import { DEFAULT_GLUE_ROUTES } from '@localstack/constants';

import { GlueProps } from './types';
import { MainNavTabs } from './components';

export const GlueRegistries = ({ Layout, clientOverrides, routes = DEFAULT_GLUE_ROUTES }: GlueProps): ReactElement => {
  const [selected, setSelected] = useState<string[]>([]);

  const { goto } = useRoutes();

  const { data: registries, isLoading, mutate } = useAwsGetter('Glue', 'listRegistries', [], { clientOverrides });

  const { deleteRegistry } = useAwsEffect('Glue', ['deleteRegistry'], {
    revalidate: ['listRegistries'],
    clientOverrides,
  });

  const handleDeleteSelected = useCallback(
    async () =>
      Promise.all(selected.map((registryName) => deleteRegistry({ RegistryId: { RegistryName: registryName } }))),
    [selected],
  );

  return (
    <Layout
      documentTitle="Glue: Schema Registries"
      title={
        <PageTitle
          title="Glue Schema Registries"
          breadcrumbs={[
            ['Glue', () => goto(routes.RESOURCES_GLUE_DATABASES)],
            ['Schema Registries', () => goto(routes.RESOURCES_GLUE_REGISTRIES)],
          ]}
          onMutate={mutate}
        />
      }
      tabs={<MainNavTabs routes={routes} />}
      actions={
        <>
          <Button onClick={() => goto(routes.RESOURCES_GLUE_REGISTRY_CREATE)}>Create Registry</Button>
          <Dropdown label="Actions">
            <ConfirmableButton
              componentType="MenuItem"
              disabled={selected.length === 0 || isLoading}
              title={`Delete ${selected.length} Registry(s)?`}
              onClick={handleDeleteSelected}
              text="Selected Registry(s) will be permanently deleted"
            >
              Remove Selected
            </ConfirmableButton>
          </Dropdown>
        </>
      }
    >
      <Card>
        <GlueRegistriesTable
          registries={registries?.Registries ?? []}
          loading={isLoading}
          onSelect={setSelected}
          onViewRegistry={(registry) => goto(routes.RESOURCES_GLUE_REGISTRY, { registry })}
        />
      </Card>
    </Layout>
  );
};
