import { useCallback, useEffect, ReactElement } from 'react';
import { Grid, Box, Typography, Divider } from '@mui/material';
import { useForm } from 'react-hook-form';
import { User } from '@localstack/types';
import { ControlledTextField, ControlledPhoneField, ProgressButton, ControlledCheckbox } from '@localstack/ui';

type Props = {
  user: Optional<User>;
  loading: boolean;
  onUpdate: (userId: string, details: User) => unknown;
};

export const AccountForm = ({ user, loading, onUpdate }: Props): ReactElement => {
  const { control, handleSubmit, setValue, formState, watch } = useForm<Partial<User>>({ mode: 'all' });

  const firstName = watch('firstname');
  const lastName = watch('lastname');

  const onSubmit = useCallback(
    (details: Partial<User>) => {
      if (!user || !formState.isValid) return;

      const updates = Object.keys(formState.dirtyFields).reduce(
        (memo, field) => ({ ...memo, [field]: details[field as keyof typeof details] }),
        {},
      );

      onUpdate(user.id, updates as User);
    },
    [user, formState],
  );

  useEffect(() => {
    if (!user) return;
    Object.entries(user).forEach(([k, v]) => {
      setValue(k as keyof User, v, { shouldValidate: true });
    });
  }, [user]);

  // automatically update fullname
  useEffect(() => {
    setValue('name', `${firstName} ${lastName}`, { shouldDirty: true });
  }, [firstName, lastName]);

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Grid container spacing={3}>
        <Grid item md={12}>
          <ControlledTextField control={control} name="id" variant="outlined" label="User ID" disabled fullWidth />
        </Grid>
        <Grid item md={12}>
          <ControlledTextField control={control} name="email" variant="outlined" label="Email" disabled fullWidth />
        </Grid>
        <Grid item md={12}>
          <ControlledTextField
            control={control}
            name="password"
            variant="outlined"
            label="Password"
            type="password"
            fullWidth
          />
          <Typography variant="caption">Use this field to override user password</Typography>
        </Grid>
        <Grid item md={6} sm={12}>
          <ControlledTextField control={control} name="firstname" variant="outlined" label="First Name" fullWidth />
        </Grid>
        <Grid item md={6} sm={12}>
          <ControlledTextField control={control} name="lastname" variant="outlined" label="Last Name" fullWidth />
        </Grid>
        <Grid item md={12}>
          <ControlledTextField control={control} name="name" variant="outlined" label="Full Name" disabled fullWidth />
        </Grid>
        <Grid item md={12}>
          <ControlledPhoneField
            control={control}
            name="phone"
            variant="outlined"
            label="Personal Phone Number"
            fullWidth
          />
        </Grid>

        <Grid item xs={12}>
          <ControlledTextField
            control={control}
            name="hubspot_contact_id"
            variant="outlined"
            label="HubSpot Contact ID"
            fullWidth
          />
        </Grid>

        <Grid item xs={12}>
          <Divider />
        </Grid>

        <Grid item md={12}>
          <Typography variant="h5">Additional fields:</Typography>
          <ControlledCheckbox control={control} name="free_beta" label="Participates in Insiders Program" />
        </Grid>

        <Grid item md={12}>
          <Box textAlign="right">
            <ProgressButton
              color="primary"
              variant="contained"
              type="submit"
              loading={loading}
              disabled={!formState.isValid || loading}
            >
              Update
            </ProgressButton>
          </Box>
        </Grid>
      </Grid>
    </form>
  );
};
