import { SERVICE_NAME } from '@localstack/types';

import { PATH_PREFIX_RESOURCES } from './base';

const SNS_BASE_ROUTE = `${PATH_PREFIX_RESOURCES}/${SERVICE_NAME.SNS}`;

export const DEFAULT_SNS_ROUTES: Record<string, string> = {
  RESOURCES_SNS_TOPICS: `${SNS_BASE_ROUTE}`,
  RESOURCES_SNS_TOPIC: `${SNS_BASE_ROUTE}/:topicArn`,
  RESOURCES_SNS_TOPIC_CREATE: `${SNS_BASE_ROUTE}/new`,
  RESOURCES_SNS_TOPIC_SUBSCRIPTIONS: `${SNS_BASE_ROUTE}/:topicArn/subscriptions`,
  RESOURCES_SNS_TOPIC_SUBSCRIPTION: `${SNS_BASE_ROUTE}/:topicArn/subscriptions/:subscriptionArn`,
  RESOURCES_SNS_TOPIC_SUBSCRIPTION_CREATE: `${SNS_BASE_ROUTE}/:topicArn/subscriptions/new`,
  RESOURCES_SNS_TOPIC_MESSAGE_SEND_PREFILLED: `${SNS_BASE_ROUTE}/:topicArn/messages/new`,
  RESOURCES_SNS_TOPIC_MESSAGE_SEND: `${SNS_BASE_ROUTE}/messages/new`,
};
