import { ReactElement, useEffect, useState } from 'react';

import makeStyles from '@mui/styles/makeStyles';
import createStyles from '@mui/styles/createStyles';
import { MARKER_IDS, TestMarkerSpan } from '@localstack/services';

const DEFAULT_INTERVAL = 3000;

const useStyles = makeStyles(() =>
  createStyles({
    imageSliderContainer: {
      overflow: 'hidden',
      width: '100%',
      height: 'auto',
      position: 'relative',
    },
    imageSlider: {
      display: 'flex',
      transition: 'transform 0.5s ease-in-out',
    },
    sliderImage: {
      flex: 1,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      '& img': {
        maxWidth: '100%',
        maxHeight: '100%',
      },
    },
  }),
);

type Props = {
  images: string[];
};

export const ImageSlider = ({ images }: Props): ReactElement => {
  const classes = useStyles();
  const [currentIndex, setCurrentIndex] = useState<number>(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentIndex((prevIndex) => (prevIndex + 1) % images.length);
    }, DEFAULT_INTERVAL);
    return () => clearInterval(interval);
  }, []);

  return (
    <div className={classes.imageSliderContainer}>
      <TestMarkerSpan name={MARKER_IDS.IMAGE_SLIDE}>
        <div
          className={classes.imageSlider}
          style={{
            transform: `translateX(-${(currentIndex * 100) / images.length}%)`,
            width: `${100 * images.length}%`,
          }}
        >
          {images.map((image) => (
            <div key={image} className={classes.sliderImage}>
              <img src={image} alt={image} />
            </div>
          ))}
        </div>
      </TestMarkerSpan>
    </div>
  );
};
