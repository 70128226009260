import { ReactElement } from 'react';
import { useForm } from 'react-hook-form';

import { VALIDATION_RULES, UserService, useSnackbar, useApiEffect, useRoutes } from '@localstack/services';

import { ControlledTextField, ProgressButton, LogoIcon } from '@localstack/ui';
import { Box, Typography, Link } from '@mui/material';

import { AppRoute } from '~/config';

import { AuthLayout } from './AuthLayout';
import { useStyles } from './common';

interface FormData {
  email: string;
}

export const Recover = (): ReactElement => {
  const classes = useStyles();

  const { showSnackbar } = useSnackbar();
  const { goto } = useRoutes();

  const { control, handleSubmit } = useForm<FormData>({ mode: 'all' });

  const { requestPasswordReset, isLoading } = useApiEffect(UserService, ['requestPasswordReset']);

  const onSubmit = async (data: FormData) => {
    await requestPasswordReset(data);

    showSnackbar({
      message: 'Password recovery email sent - please check your email inbox.',
      severity: 'success',
    });

    goto(AppRoute.SIGN_IN);
  };

  return (
    <AuthLayout documentTitle="Recover Password">
      <Box textAlign="center">
        <Box mb={4}>
          <LogoIcon />
        </Box>
        <Typography variant="h3" component="h2" fontWeight={600}>
          Recover Password
        </Typography>
        <form onSubmit={handleSubmit(onSubmit)} id="recover">
          <Box mt={4}>
            <ControlledTextField
              showErrorsOnlyIfTouched={false}
              autoFocus
              control={control}
              name="email"
              fullWidth
              placeholder="Enter your email address"
              type="text"
              variant="outlined"
              rules={{
                ...VALIDATION_RULES.email,
                ...VALIDATION_RULES.required,
              }}
            />
            <Box mt={2}>
              <ProgressButton
                variant="contained"
                color="primary"
                fullWidth
                size="xlarge"
                type="submit"
                loading={isLoading}
              >
                Reset Password
              </ProgressButton>
            </Box>
          </Box>
        </form>
        <Box mt={4}>
          <Link
            underline="hover"
            fontSize={13}
            fontWeight={500}
            className={classes.grey800Text}
            display="inline-flex"
            alignItems="center"
            columnGap={0.5}
            onClick={() => goto(AppRoute.SIGN_IN)}
          >
            Back to login
          </Link>
        </Box>
      </Box>
    </AuthLayout>
  );
};
