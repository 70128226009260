import { ReactElement } from 'react';
import { useParams } from 'react-router-dom';
import { Card, CardContent, CardActions, Box, Typography } from '@mui/material';
import { CreateKinesisStreamParams, UpdateKinesisStreamModeParams } from '@localstack/types';
import { useRoutes, useAwsEffect, useAwsGetter } from '@localstack/services';
import { ProgressButton, KinesisStreamForm, Breadcrumbs } from '@localstack/ui';

import { DEFAULT_KINESIS_ROUTES } from '@localstack/constants';

import { KinesisProps } from './types';

export const KinesisStreamUpsert = ({
  Layout,
  clientOverrides,
  routes = DEFAULT_KINESIS_ROUTES,
}: KinesisProps): ReactElement => {
  const { goto } = useRoutes();
  const { streamName } = useParams<'streamName'>();

  const { createStream, updateStreamMode, isLoading } = useAwsEffect('Kinesis', ['createStream', 'updateStreamMode'], {
    revalidate: ['listStreams', 'describeStream'],
    clientOverrides,
  });

  const { data: stream } = useAwsGetter('Kinesis', 'describeStream', [{ StreamName: streamName }], { clientOverrides });

  const handleCreate = async (data: CreateKinesisStreamParams) => {
    await createStream(data);
    goto(routes.RESOURCES_KINESIS_STREAMS);
  };

  const handleUpdate = async (data: UpdateKinesisStreamModeParams) => {
    await updateStreamMode(data);
    goto(routes.RESOURCES_KINESIS_STREAM, { streamName });
  };

  return (
    <Layout
      documentTitle="Stream Details"
      title={
        <Box>
          <Typography variant="h4">Stream Details</Typography>
          <Breadcrumbs
            mappings={[
              ['Kinesis', () => goto(routes.RESOURCES_KINESIS)],
              ['Streams', () => goto(routes.RESOURCES_KINESIS_STREAMS)],
              [streamName, () => goto(routes.RESOURCES_KINESIS_STREAM, { streamName })],
              [streamName ? 'Update' : 'Create Stream', null],
            ]}
          />
        </Box>
      }
      actions={
        <ProgressButton type="submit" form="update" variant="outlined" color="primary" loading={isLoading}>
          Submit
        </ProgressButton>
      }
    >
      <Card>
        <CardContent>
          <KinesisStreamForm
            stream={stream?.StreamDescription}
            onCreate={handleCreate}
            onUpdate={handleUpdate}
            loading={isLoading}
            formId="update"
          />
        </CardContent>
        <CardActions>
          <ProgressButton type="submit" form="update" variant="contained" color="primary" loading={isLoading}>
            Submit
          </ProgressButton>
        </CardActions>
      </Card>
    </Layout>
  );
};
