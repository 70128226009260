import { ReactElement } from 'react';
import { Link } from '@mui/material';
import { AgwRestApiIntegration } from '@localstack/types';
import { getSchema } from '@localstack/services';

import { MagicTable } from '../../../magic/MagicTable';

export type AgwRestApiIntegrationsTableProps = {
  selectable?: boolean;
  loading?: boolean;
  integrations: AgwRestApiIntegration[];
  onViewIntegration?: (resource: AgwRestApiIntegration) => unknown;
  onSelect?: (ids: string[]) => unknown;
};

export const AgwRestApiIntegrationsTable = ({
  integrations,
  selectable = true,
  loading,
  onViewIntegration,
  onSelect,
}: AgwRestApiIntegrationsTableProps): ReactElement => (
  <MagicTable
    schema={getSchema('APIGateway')}
    entry="Integration"
    idAttribute="uri"
    rows={integrations}
    selectable={selectable}
    onSelect={onSelect}
    order={['uri']}
    loading={loading}
    externalFields={{
      uri: (row) => (
        <Link onClick={() => onViewIntegration && onViewIntegration(row)} underline="hover">
          {row.uri}
        </Link>
      ),
    }}
  />
);
