import { ReactElement } from 'react';
import { useRoutes, useAwsEffect, useAwsGetter } from '@localstack/services';
import { useParams } from 'react-router-dom';
import { Card, CardContent, CardActions, Box, Typography } from '@mui/material';
import { CreateRDBParams, UpdateRDBParams } from '@localstack/types';
import { ProgressButton, RDSInstanceForm, Breadcrumbs } from '@localstack/ui';

import { DEFAULT_RDS_ROUTES } from '@localstack/constants';

import { RDSProps } from './types';

export const RDSDBInstanceUpsert = ({
  Layout,
  clientOverrides,
  routes = DEFAULT_RDS_ROUTES,
}: RDSProps): ReactElement => {
  const { goto } = useRoutes();
  const { instanceId, clusterId } = useParams<'instanceId' | 'clusterId'>();

  const { createDBInstance, modifyDBInstance, isLoading } = useAwsEffect(
    'RDS',
    ['createDBInstance', 'modifyDBInstance'],
    { revalidate: ['describeDBInstances', 'describeDBClusters'], clientOverrides },
  );

  const { data: instances } = useAwsGetter('RDS', 'describeDBInstances', [{ DBInstanceIdentifier: instanceId }], {
    clientOverrides,
  });
  const instance = instances?.DBInstances?.find((inst) => inst.DBInstanceIdentifier === instanceId);

  const handleCreate = async (data: CreateRDBParams) => {
    await createDBInstance(data);
    goto(routes.RESOURCES_RDS_DATABASES);
  };

  const handleUpdate = async (data: UpdateRDBParams) => {
    await modifyDBInstance(data);
    goto(routes.RESOURCES_RDS_INSTANCE, { instanceId });
  };

  const createInstanceTitle = clusterId ? `Create Instance in Cluster: ${clusterId}` : 'Create Instance';

  return (
    <Layout
      documentTitle={`RDS: ${instanceId ? 'Update' : 'Create'} Instance`}
      title={
        <Box>
          <Typography variant="h4">{instanceId ? 'Update instance' : createInstanceTitle}</Typography>
          <Breadcrumbs
            mappings={[
              ['RDS', () => goto(routes.RESOURCES_RDS_DATABASES)],
              [
                instanceId ? `${instanceId} (${instance?.DBName})` : null,
                () => goto(routes.RESOURCES_RDS_INSTANCE, { instanceId }),
              ],
              [instanceId ? 'Update' : 'Create Instance', null],
            ]}
          />
        </Box>
      }
      actions={
        <ProgressButton type="submit" form="update" variant="outlined" color="primary" loading={isLoading}>
          Submit
        </ProgressButton>
      }
    >
      <Card>
        <CardContent>
          <RDSInstanceForm
            cluster={clusterId}
            instance={instance}
            clientOverrides={clientOverrides}
            onCreate={handleCreate}
            onUpdate={handleUpdate}
            loading={isLoading}
            formId="update"
          />
        </CardContent>
        <CardActions>
          <ProgressButton type="submit" form="update" variant="contained" color="primary" loading={isLoading}>
            Submit
          </ProgressButton>
        </CardActions>
      </Card>
    </Layout>
  );
};
