import { ReactElement } from 'react';
import { MQDescribeBroker } from '@localstack/types';
import { getSchema } from '@localstack/services';

import { MagicDetails } from '../../magic/MagicDetails';

export type MQBrokerDetailsProps = {
  broker: Optional<MQDescribeBroker>;
};

export const MQBrokerDetails = ({ broker }: MQBrokerDetailsProps): ReactElement => (
  <MagicDetails data={broker} schema={getSchema('MQ')} entry="DescribeBrokerResponse" title="Broker Details" />
);
