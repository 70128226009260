import { ReactElement, useState, useCallback } from 'react';
import { useRoutes, useAwsEffect, useAwsGetter } from '@localstack/services';
import { Card, Button } from '@mui/material';

import { Dropdown, ConfirmableButton, PageTitle, MQBrokersTable } from '@localstack/ui';

import { DEFAULT_MQ_ROUTES } from '@localstack/constants';

import { MQProps } from './types';

export const MQBrokers = ({ Layout, clientOverrides, routes = DEFAULT_MQ_ROUTES }: MQProps): ReactElement => {
  const [selectedIds, setSelectedIds] = useState<string[]>([]);
  const { goto } = useRoutes();
  const { data: brokers, isLoading, mutate } = useAwsGetter('MQ', 'listBrokers', [], { clientOverrides });

  const { deleteBroker } = useAwsEffect('MQ', ['deleteBroker'], { revalidate: ['listBrokers'], clientOverrides });

  const handleDeleteSelected = useCallback(async () => {
    const promises = selectedIds.map((BrokerId) => deleteBroker({ BrokerId }));
    await Promise.all(promises);
  }, [selectedIds]);

  return (
    <Layout
      documentTitle="MQ: Brokers"
      title={<PageTitle title="MQ Brokers" onMutate={mutate} />}
      actions={
        <>
          <Button onClick={() => goto(routes.RESOURCES_MQ_BROKER_CREATE)}>Create Broker</Button>
          <Dropdown label="Actions">
            <ConfirmableButton
              componentType="MenuItem"
              disabled={selectedIds.length === 0 || isLoading}
              title={`Delete ${selectedIds.length} Broker(s)?`}
              onClick={handleDeleteSelected}
              text="Selected Brokers will be permanently deleted"
            >
              Remove Selected
            </ConfirmableButton>
          </Dropdown>
        </>
      }
    >
      <Card>
        <MQBrokersTable
          brokers={brokers?.BrokerSummaries ?? []}
          loading={isLoading}
          onSelect={setSelectedIds}
          onViewBroker={(broker) => goto(routes.RESOURCES_MQ_BROKER, { id: broker.BrokerId })}
        />
      </Card>
    </Layout>
  );
};
