import { ReactElement } from 'react';
import { TextField } from '@mui/material';
import { CreateAgwRestApiDeploymentRequest } from '@localstack/types';
import { getSchema } from '@localstack/services';

import { MagicForm } from '../../../magic/MagicForm';

export interface AgwRestApiDeploymentFormProps {
  restApiId: string;
  formId?: string;
  loading?: boolean;
  onCreate: (data: CreateAgwRestApiDeploymentRequest) => unknown;
}

export const AgwRestApiDeploymentForm = ({
  restApiId,
  loading,
  formId,
  onCreate,
}: AgwRestApiDeploymentFormProps): ReactElement => (
  <MagicForm
    schema={getSchema('APIGateway')}
    loading={loading}
    entry="CreateDeploymentRequest"
    formId={formId}
    externalFields={{
      '^restApiId$': () => <TextField fullWidth disabled value={restApiId} variant="outlined" />,
    }}
    onSubmit={(data: CreateAgwRestApiDeploymentRequest) => onCreate({ ...data, restApiId })}
  />
);
