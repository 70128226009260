import { AxiosError } from 'axios';
import { useState } from 'react';
import useSWR, { SWRConfiguration } from 'swr';

import { DEFAULT_SWR_SETTINGS } from '../config';

import { useErrorSnackbarEffect, useGlobalSwr, usePrevious } from './util';

// eslint-disable-next-line
type ClassType = { new (...args: any[]): any; [key: string]: any };

// eslint-disable-next-line
type Client = ClassType | Record<string, (...args: any[]) => unknown>;

type UseApiEffectFunctionsReturn<C extends Client, N extends (keyof C)[]> = { [K in N[number]]: C[K] };

interface UseApiEffectStateReturn {
  isLoading: boolean;
  error?: Optional<Error | AxiosError>;
  hasError: boolean;
}

interface UseApiEffectRevalidateOtherSettings<RC extends Client> {
  client: RC;
  methods: (keyof RC)[];
}

interface UseApiEffectSettings<C, RC extends Client> {
  revalidate?: (keyof C)[];
  revalidateOtherClient?: UseApiEffectRevalidateOtherSettings<RC>;
  suppressErrors?: boolean;
}

/**
 * A lightweight version of `useAwsEffect` for internal API calls.
 *
 * Example:
 * ```ts
 * const client = {
 *   doSomething: (arg: string) => console.log(arg);
 * };
 *
 * const { doSomething } = useApiEffect(client, ['doSomething']);
 * ```
 *
 * @param client - service client to use with this hook
 * @param methods - list of methods/functions to expose to the hook
 */
export const useApiEffect = <C extends Client, RC extends Client, N extends (keyof C)[]>(
  client: C,
  methods: (keyof C)[],
  settings?: UseApiEffectSettings<C, RC>,
): UseApiEffectFunctionsReturn<C, N> & UseApiEffectStateReturn => {
  const [loadingStates, setLoadingStates] = useState({});
  const [error, setError] = useState<Optional<Error | AxiosError>>(null);

  const { mutateRelated } = useGlobalSwr();

  const isLoading = Object.values(loadingStates).some(Boolean);

  const definitions = methods.reduce(
    (memo, method) => ({
      ...memo,
      // eslint-disable-next-line
      [method]: async (...args: any[]) => {
        try {
          setLoadingStates({ ...loadingStates, [method]: true });

          const callable = client[method] as C[string];

          const result = await callable.call(client, ...args);

          mutateRelated(method);

          if (settings?.revalidate) {
            settings.revalidate.forEach((key) => mutateRelated(key));
          }
          if (settings?.revalidateOtherClient) {
            settings.revalidateOtherClient.methods.forEach((key) => mutateRelated(key));
          }

          return result;
        } catch (e) {
          console.error(e);
          setError(e);
        } finally {
          setLoadingStates({
            ...loadingStates,
            [method]: false,
          });
        }
      },
    }),
    {} as UseApiEffectFunctionsReturn<C, N>,
  );

  useErrorSnackbarEffect(settings?.suppressErrors ? undefined : error);

  return {
    ...definitions,
    isLoading,
    error,
    hasError: !!error,
  };
};

interface UseApiGetterSettings<
  C extends Client,
  N extends keyof C,
  R = ReturnType<C[N]> extends Promise<infer X> ? X : ReturnType<C[N]>,
> {
  defaultValue?: Optional<R>;
  enable?: boolean;
  swrOverrides?: SWRConfiguration;
  suppressErrors?: boolean;
}

interface UseApiSetterReturn<
  C extends Client,
  N extends keyof C,
  R = ReturnType<C[N]> extends Promise<infer X> ? X : ReturnType<C[N]>,
> {
  data: Optional<R>;
  error?: Error | AxiosError;
  hasError: boolean;
  isLoading: boolean;
  mutate: () => unknown;
}

/**
 * A lightweight version of `useAwsGetter` for internal services.
 *
 * Example:
 * ```ts
 * const client = {
 *   loadSomething: (arg: number) => Promise.resolve([1, arg, 3]);
 * };
 *
 * const {} = useApiGetter(client, 'loadSomething', [2]);
 * ```
 *
 * @param client - api client
 * @param name - method or function name to use as a getter
 * @param args - arguments that will be passed to the method/function.
 *               Please note the call is skipped in case arity does not match.
 *               This is to simplify state-driven loads.
 * @param settings - getter settings and overrides
 * @returns
 */
export const useApiGetter = <C extends Client, N extends keyof C>(
  client: C,
  name: N,
  args: Parameters<C[N]>,
  settings?: UseApiGetterSettings<C, N>,
): UseApiSetterReturn<C, N> => {
  const callable = client[name];

  const enable = settings?.enable === undefined || !!settings?.enable;
  const cacheKey = JSON.stringify([name, enable, ...args]);

  const { data, error, isValidating, mutate } = useSWR(
    cacheKey,
    () => (enable ? callable(...args) : Promise.resolve(settings?.defaultValue)),
    { ...DEFAULT_SWR_SETTINGS, ...settings?.swrOverrides },
  );

  const oldData = usePrevious(data);

  useErrorSnackbarEffect(settings?.suppressErrors ? undefined : error);

  // if we are still loading do not reset the data to `undefined`, but temporarily used the old data instead
  const resolvedData = isValidating ? (oldData ?? settings?.defaultValue) : data;

  return {
    data: resolvedData,
    error,
    hasError: !!error,
    isLoading: isValidating,
    mutate,
  };
};
