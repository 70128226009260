import { ReactElement } from 'react';
import { CloudPod } from '@localstack/types';
import { Link, Tooltip } from '@mui/material';
import { LockOpen, Lock, Public, PublicOff } from '@mui/icons-material';
import { formatBytes } from '@localstack/services';

import { MagicTable } from '../../magic/MagicTable';

export type PodsTableProps = {
  className?: string;
  pods: CloudPod[];
  loading?: boolean;
  onViewPod?: (name: string) => void;
  onSelect: (pods: string[]) => void;
  onDeletePod?: (name: string) => void;
  onInjectPod?: (pod: CloudPod) => void;
  hideUserId?: boolean;
};

const SCHEMA = (hideUserId: boolean) => ({
  shapes: {
    ListPods: {
      type: 'structure',
      members: {
        id: {
          type: 'string',
        },
        pod_name: {
          type: 'string',
        },
        max_version: {
          type: 'string',
        },
        last_change: {
          type: 'timestamp',
        },
        storage_size: {
          type: 'integer',
        },
        ...(!hideUserId && {
          user_id: {
            type: 'string',
          },
        }),
        is_public: {
          type: 'boolean',
        },
      },
    },
  },
});

export const PodsTable = (props: PodsTableProps): ReactElement => (
  <MagicTable
    schema={SCHEMA(props.hideUserId ?? false)}
    entry="ListPods"
    idAttribute="id"
    onSelect={props.onSelect}
    rows={props.pods}
    selectable
    loading={props.loading}
    externalFields={{
      id: (row) => (
        <Link onClick={() => props.onViewPod?.(row.pod_name)} underline="hover">
          {row.id}
        </Link>
      ),
      storage_size: (row) => <>{formatBytes(row.storage_size ?? 0)}</>,
      is_public: (row) =>
        row.is_public ? (
          <Tooltip title="Pod is public">
            <Public />
          </Tooltip>
        ) : (
          <Tooltip title="Pod is private">
            <PublicOff />
          </Tooltip>
        ),
      encrypted: (row) =>
        row.encrypted ? (
          <Tooltip title="Pod is encrypted">
            <Lock />
          </Tooltip>
        ) : (
          <Tooltip title="Pod is unencrypted">
            <LockOpen />
          </Tooltip>
        ),
    }}
  />
);
