import { OrganizationsService, useApiEffect } from '@localstack/services';
import { useEffect, useState } from 'react';

import { CombinedUserInfo, SSOError, SSOParams, isSSOPostMessagePayload } from '@localstack/types';

import { AppRoute, BASE_URL, COGNITO_BASE_URL, COGNITO_CLIENT_ID } from '~/config';
import { createNewPopup } from '~/util/window';

import { useAuthProvider } from './useAuthProvider';

type SSOStartReturnType = {
  isIdpLoading: boolean;
  initSSOFlow: (ssoParams: Optional<SSOParams>) => Promise<void>;
};

type UseSSOStartParams = {
  onSuccess?: (userInfo: CombinedUserInfo) => void;
  onError?: (error: SSOError) => void;
};

const buildSSOLink = ({ orgId, idpName }: SSOParams) => {
  // Cognito fetches identity providers (IDPs) using the `identity_provider` property, which represents the provider's name.
  // All IDPs in Cognito follow a specific format: `{orgId}-{idpName}`.
  // However, Google IDPs cannot be renamed to match this format (e.g., `public-google`).
  // To work around this, we use the `identifier` property (equivalent to tags) to store our formatted name.
  // Since we cannot rename the Google IDP, we assign it an identifier (`public-google`) instead.
  // The following line ensures that Cognito fetches the Google IDP using the `idp_identifier` property, while other IDPs are fetched by name.
  const isPublicGoogleIdp = orgId === 'public' && idpName === 'google';
  const idpNameOrIdentifier = isPublicGoogleIdp ? 'idp_identifier' : 'identity_provider';

  return (
    `${COGNITO_BASE_URL}/oauth2/authorize?response_type=code` +
    `&client_id=${COGNITO_CLIENT_ID}&redirect_uri=${BASE_URL}${AppRoute.SSO_CALLBACK}` +
    `&${idpNameOrIdentifier}=${orgId}-${idpName}`
  );
};

export const useSSOStart = (params: UseSSOStartParams): SSOStartReturnType => {
  const { onSuccess, onError } = params;
  const [windowObj, setWindowObj] = useState<Window | null>(null);
  const { userInfo } = useAuthProvider();

  const {
    getIdentityProvider,
    isLoading: isIdpLoading,
    error: apiError,
  } = useApiEffect(OrganizationsService, ['getIdentityProvider'], { suppressErrors: true });

  const initSSOFlow = async ({ idpName, orgId }: SSOParams) => {
    if (!idpName || !orgId) {
      onError?.({
        type: 'idp-error',
        message: 'Something went wrong.',
      });
      return;
    }
    const popup = createNewPopup(window, AppRoute.SSO_LOADING);

    if (!popup) {
      onError?.({
        type: 'popup-error',
        message: 'Pop-up is blocked. Please enable pop-ups and try again',
      });
      return;
    }

    const idp = await getIdentityProvider(orgId, idpName);

    if (!idp) {
      popup.close();
      onError?.({
        type: 'idp-error',
        message: 'Something went wrong. Please contact your administrator to verify your IDP settings.',
      });
      return;
    }

    if (apiError) {
      popup.close();
      onError?.({
        type: 'idp-error',
        message: apiError.message,
      });
      return;
    }

    const ssoLink = buildSSOLink({ idpName, orgId });

    popup.location.href = ssoLink;
    popup.focus();
    setWindowObj(popup);
  };

  const closeWindow = () => windowObj?.close();

  useEffect(() => {
    if (!userInfo || !windowObj) {
      return;
    }
    // only redirect if sso window is open and userInfo is available
    onSuccess?.(userInfo);
    closeWindow();
  }, [userInfo]);

  useEffect(() => {
    if (!windowObj) {
      return;
    }

    const messageEventHandler = async (event: MessageEvent) => {
      if (event.origin !== BASE_URL) {
        return;
      }

      if (!isSSOPostMessagePayload(event.data)) {
        return;
      }

      if (event.data.messageType === 'sso-error') {
        const { error } = event.data;
        onError?.(error);
        closeWindow();
      }
      if (event.data.messageType === 'sso-success') {
        if (userInfo) {
          // if userInfo is available, call the success callback here
          // or it'll be handled in the useEffect
          onSuccess?.(userInfo);
          closeWindow();
        }
      }
    };

    window.addEventListener('message', messageEventHandler);
    return () => window.removeEventListener('message', messageEventHandler);
  }, [windowObj]);

  return { isIdpLoading, initSSOFlow };
};
