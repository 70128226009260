import { ReactElement } from 'react';

import { ExternalLink } from '@localstack/constants';
import { Typography } from '@mui/material';

import { ContentModal } from '../ContentModal';

type Props = {
  open: boolean;
  onClose: () => void;
  urlOpenerFuncOverride?: (url: string) => void;
};

export const ProFeatureUnavailableDialog = ({ open, onClose, urlOpenerFuncOverride }: Props): ReactElement => {
  const externalUrl = ExternalLink.DOCS_AUTH_TOKEN;
  return (
    <ContentModal
      title="Pro features unavailable"
      open={open}
      onClose={onClose}
      onConfirm={() => {
        if (urlOpenerFuncOverride) {
          urlOpenerFuncOverride(externalUrl);
        } else {
          window.open(externalUrl, '_blank');
        }
      }}
      confirmText="Learn more"
      maxWidth="sm"
    >
      <Typography variant="body1">
        This instance does not support Pro features. Make sure that you are using the{' '}
        <code>localstack/localstack-pro</code> image and that you have set up your license and auth token correctly.
      </Typography>
    </ContentModal>
  );
};
