import { ReactElement } from 'react';
import { useParams } from 'react-router-dom';
import { Card, CardContent, CardActions, Box, Typography } from '@mui/material';
import { useAwsEffect, useRoutes } from '@localstack/services';
import { CreateSNSSubscriptionRequest } from '@localstack/types';
import { Breadcrumbs, ProgressButton, SNSSubscribeForm } from '@localstack/ui';

import { DEFAULT_SNS_ROUTES } from '@localstack/constants';

import { SNSProps } from './types';

export const SNSSubscriptionCreate = ({
  Layout,
  clientOverrides,
  routes = DEFAULT_SNS_ROUTES,
}: SNSProps): ReactElement => {
  const { goto } = useRoutes();
  const { topicArn } = useParams<'topicArn'>();

  const { subscribe, isLoading } = useAwsEffect('SNS', ['subscribe'], {
    revalidate: ['listSubscriptionsByTopic'],
    clientOverrides,
  });

  const handleCreate = async (data: CreateSNSSubscriptionRequest) => {
    if (await subscribe(data)) {
      goto(routes.RESOURCES_SNS_TOPIC_SUBSCRIPTIONS, { topicArn });
    }
  };

  return (
    <Layout
      documentTitle="Subscription Details"
      title={
        <Box>
          <Typography variant="h4">Subscription Details</Typography>
          <Breadcrumbs
            mappings={[
              ['SNS', () => goto(routes.RESOURCES_SNS_TOPICS)],
              [topicArn?.split(':')?.slice(-1)?.[0], () => goto(routes.RESOURCES_SNS_TOPIC, { topicArn })],
              ['Create Subscription', null],
            ]}
          />
        </Box>
      }
      actions={
        <ProgressButton type="submit" form="CreateSubscription" variant="outlined" color="primary" loading={isLoading}>
          Submit
        </ProgressButton>
      }
    >
      <Card>
        <CardContent>
          <SNSSubscribeForm arn={topicArn as string} onSubmit={handleCreate} formId="CreateSubscription" />
        </CardContent>
        <CardActions>
          <ProgressButton
            type="submit"
            form="CreateSubscription"
            variant="contained"
            color="primary"
            loading={isLoading}
          >
            Submit
          </ProgressButton>
        </CardActions>
      </Card>
    </Layout>
  );
};
