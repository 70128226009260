/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { ComputeUsageSummary } from '@localstack/types';
import type { EphemeralInstance } from '@localstack/types';
import type { EphemeralInstanceConfig } from '@localstack/types';
import type { LogLine } from '@localstack/types';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class ComputeService {
  /**
   * @param requestBody
   * @returns EphemeralInstance
   * @throws ApiError
   */
  public static createEphemeralInstance(requestBody: EphemeralInstanceConfig): CancelablePromise<EphemeralInstance> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/v1/compute/instances',
      body: requestBody,
      mediaType: 'application/json',
    });
  }

  /**
   * @returns EphemeralInstance
   * @throws ApiError
   */
  public static listEphemeralInstances(): CancelablePromise<Array<EphemeralInstance>> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/v1/compute/instances',
    });
  }

  /**
   * @param instanceName
   * @returns EphemeralInstance
   * @throws ApiError
   */
  public static deleteEphemeralInstance(instanceName: any): CancelablePromise<EphemeralInstance> {
    return __request(OpenAPI, {
      method: 'DELETE',
      url: '/v1/compute/instances/{instance_name}',
      path: {
        instance_name: instanceName,
      },
    });
  }

  /**
   * @returns ComputeUsageSummary
   * @throws ApiError
   */
  public static getComputeUsage(): CancelablePromise<ComputeUsageSummary> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/v1/compute/usage',
    });
  }

  /**
   * @param instanceName
   * @returns LogLine
   * @throws ApiError
   */
  public static getEphemeralInstanceLogs(instanceName: any): CancelablePromise<Array<LogLine>> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/v1/compute/instances/{instance_name}/logs',
      path: {
        instance_name: instanceName,
      },
    });
  }
}
