import { ReactElement } from 'react';
import { NavLink } from '@localstack/ui';
import { buildRoute } from '@localstack/services';

import { CloudFormationRoutes } from '../types';

interface Props {
  routes: CloudFormationRoutes;
  name: string;
}

export const NavTabs = ({ routes, name }: Props): ReactElement => (
  <>
    <NavLink to={buildRoute(routes.RESOURCES_CLOUDFORMATION_STACK_RESOURCES, { name })}>Resources</NavLink>
    <NavLink to={buildRoute(routes.RESOURCES_CLOUDFORMATION_STACK_OUTPUTS, { name })}>Outputs</NavLink>
  </>
);
