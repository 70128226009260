import { ReactElement, ReactNode } from 'react';
import { Controller, Control, ControllerProps } from 'react-hook-form';
import {
  FormControlLabel,
  Checkbox as MuiCheckbox,
  CheckboxProps,
  FormControlLabelProps,
  FormHelperText,
  FormControl,
} from '@mui/material';
import { createStyles, makeStyles } from '@mui/styles';

type Props = CheckboxProps &
  Omit<ControllerProps, 'control' | 'render'> & {
    label?: ReactNode;
    formControlLabelProps?: Omit<FormControlLabelProps, 'control' | 'label'>;
    control: Control<any>; // eslint-disable-line
  };

const useStyles = makeStyles(() =>
  createStyles({
    errorText: {
      marginTop: 0,
    },
  }),
);

export const ControlledCheckbox = ({ label, formControlLabelProps, ...props }: Props): ReactElement => {
  const classes = useStyles();
  return (
    <Controller
      render={({ field, fieldState }) => (
        <FormControl error={Boolean(fieldState.error)}>
          <FormControlLabel
            control={
              <MuiCheckbox
                {...props}
                {...field}
                color="primary"
                value={field.value || false}
                checked={field.value || false}
              />
            }
            label={label}
            {...formControlLabelProps}
          />
          {fieldState.error && (
            <FormHelperText className={classes.errorText}>{fieldState.error?.message}</FormHelperText>
          )}
        </FormControl>
      )}
      {...props}
    />
  );
};

export default ControlledCheckbox;
