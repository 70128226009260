import { AwsClientOverrides } from '@localstack/services';
import { ComplexLayoutProps, UseAuthProvider } from '@localstack/types';

import { RESOURCES_TYPES } from './constants';

/**
 * Base interface for components whose layout need to be injected.
 * Routes such as Resource Browser ones and instance ones are shared
 * between desktop app and web app so we need to change the surrounding layout based
 * on where we are using them
 */
export interface InjectableViewProps<R extends Record<string, string>> {
  Layout: (props: ComplexLayoutProps) => JSX.Element;
  routes: R;
  clientOverrides?: AwsClientOverrides;
  useAuthProvider: UseAuthProvider;
}

export type PostOnClickProps = {
  serviceCode: keyof typeof RESOURCES_TYPES;
};

export interface ResourceBrowserProps<R extends Record<string, string>> extends InjectableViewProps<R> {
  postOnClick?: (props: PostOnClickProps) => unknown;
}
