import { ReactElement } from 'react';
import { useLocation } from 'react-router-dom';

import { AdminViews } from './views/admin/AdminViews';
import { CustomerViews } from './views/customer/CustomerViews';

const App = (): ReactElement => {
  const { pathname } = useLocation();

  if (pathname.startsWith('/admin')) return <AdminViews />;
  return <CustomerViews />;
};

export default App;
