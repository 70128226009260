import { ReactElement } from 'react';
import { useParams } from 'react-router-dom';
import { Typography, Box, Card } from '@mui/material';
import { Breadcrumbs, StateMachineFlowchart } from '@localstack/ui';
import { useRoutes, useAwsGetter } from '@localstack/services';

import { DEFAULT_STEPFUNCTIONS_ROUTES } from '@localstack/constants';

import { StepFunctionsProps } from './types';

import { DefinitionTabs } from './components';

export const StateDefinitionFlowchart = ({
  Layout,
  clientOverrides,
  routes = DEFAULT_STEPFUNCTIONS_ROUTES,
}: StepFunctionsProps): ReactElement => {
  const { goto } = useRoutes();
  const { stateMachineArn } = useParams<'stateMachineArn'>();
  const stateMachineName = stateMachineArn?.split(':').pop();

  const { data: stateMachineForExecution } = useAwsGetter(
    'StepFunctions',
    'describeStateMachine',
    [{ stateMachineArn }],
    { clientOverrides },
  );

  const definition = JSON.parse(stateMachineForExecution?.definition ?? '{}');

  return (
    <Layout
      documentTitle="Step Functions: State Machine Flowchart"
      tabs={<DefinitionTabs stateMachineArn={stateMachineArn as string} routes={routes} />}
      title={
        <Box>
          <Typography variant="h4">State Machine Flowchart</Typography>
          <Breadcrumbs
            mappings={[
              ['Step Functions', () => goto(routes.RESOURCES_STEPFUNCTIONS)],
              ['State Machines', () => goto(routes.RESOURCES_STEPFUNCTIONS_STATE_MACHINES)],
              [stateMachineName, () => goto(routes.RESOURCES_STEPFUNCTIONS_STATE_MACHINE, { stateMachineArn })],
              ['Definition', () => goto(routes.RESOURCES_STEPFUNCTIONS_STATE_MACHINE_DEFINITION, { stateMachineArn })],
              ['Flowchart', () => null],
            ]}
          />
        </Box>
      }
    >
      <Card>
        <Box textAlign="center" width="100vw" height="100vh">
          {stateMachineForExecution?.definition && <StateMachineFlowchart definition={definition} />}
        </Box>
      </Card>
    </Layout>
  );
};
