import { ReactElement } from 'react';
import { AwsClientOverrides, getSchema } from '@localstack/services';

import {
  CreateCloudFormationStackRequest,
  UpdateCloudFormationStackRequest,
  CloudFormationStack,
} from '@localstack/types';
import { DEFAULT_IAM_ROUTES, DEFAULT_SNS_ROUTES } from '@localstack/constants';

import { ControlledCodeEditor } from '../../../form';
import { MagicForm } from '../../magic/MagicForm';
import { RelatedResourcePicker } from '../../../form/RelatedResourcePicker';

import { PLACEHOLDER_DATA } from './placeholder';

const SAMPLE_TEMPLATE = JSON.stringify(PLACEHOLDER_DATA, null, 2);

export interface CloudFormationFormProps {
  stack?: Optional<CloudFormationStack>;
  template?: Optional<string>;
  loading?: boolean;
  formId?: string;
  clientOverrides?: AwsClientOverrides;
  onCreate: (data: CreateCloudFormationStackRequest) => unknown;
  onUpdate: (data: UpdateCloudFormationStackRequest) => unknown;
}

export const CloudFormationForm = ({
  stack,
  template,
  loading,
  formId,
  clientOverrides,
  onCreate,
  onUpdate,
}: CloudFormationFormProps): ReactElement => (
  <MagicForm
    schema={getSchema('CloudFormation')}
    loading={loading}
    data={template ? { ...stack, TemplateBody: template } : stack}
    defaultValues={{ TemplateBody: SAMPLE_TEMPLATE }}
    entry={stack ? 'UpdateStackInput' : 'CreateStackInput'}
    formId={formId}
    fieldConditions={{
      '^StackName': !stack,
    }}
    externalFields={{
      '^TemplateBody$': (control, name) => <ControlledCodeEditor control={control} name={name} language="json" />,
      '^RoleARN$': (control, fieldName, required) => (
        <RelatedResourcePicker
          control={control}
          client="IAM"
          method="listRoles"
          arrayKeyName="Roles"
          property="Arn"
          fieldName={fieldName}
          entityName="IAM Role"
          creationRoute={DEFAULT_IAM_ROUTES.RESOURCES_IAM_ROLE_CREATE}
          required={required}
          clientOverrides={clientOverrides}
        />
      ),
      '^NotificationARNs\\..+': (control, fieldName, required) => (
        <RelatedResourcePicker
          control={control}
          client="SNS"
          method="listTopics"
          arrayKeyName="Topics"
          property="TopicArn"
          fieldName={fieldName}
          entityName="SNS Topic"
          creationRoute={DEFAULT_SNS_ROUTES.RESOURCES_SNS_TOPIC_CREATE}
          required={required}
          clientOverrides={clientOverrides}
        />
      ),
    }}
    onSubmit={(data: CreateCloudFormationStackRequest | UpdateCloudFormationStackRequest) => {
      if (!stack) return onCreate(data);
      return onUpdate({ ...data, StackName: stack.StackName });
    }}
  />
);
