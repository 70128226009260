import { ReactElement } from 'react';
import { Typography, Button, Grid } from '@mui/material';

import { useRoutes } from '@localstack/services';

import { AdminLayout } from '~/layouts';
import { useAuthProvider } from '~/hooks/useAuthProvider';
import { Searchbar } from '~/components/Searchbar';
import { AppRoute } from '~/config';

export const Dashboard = (): ReactElement => {
  const { userInfo } = useAuthProvider();
  const { goto } = useRoutes();

  return (
    <AdminLayout title="Admin Dashboard">
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Typography align="center" variant="h2">
            Hej {userInfo?.user?.firstname}, what would you like to do?
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Searchbar size="medium" />
        </Grid>
        <Grid item xs={6}>
          <Button
            variant="contained"
            color="primary"
            onClick={() => goto(AppRoute.ADMIN_ACCOUNT, { id: userInfo?.user.id })}
            disabled={!userInfo}
            fullWidth
          >
            My Account
          </Button>
        </Grid>
        <Grid item xs={6}>
          <Button
            variant="contained"
            color="primary"
            onClick={() => goto(AppRoute.ADMIN_ORGANIZATION, { id: userInfo?.org.id })}
            disabled={!userInfo}
            fullWidth
          >
            My Organization
          </Button>
        </Grid>
      </Grid>
    </AdminLayout>
  );
};
