import { DEFAULT_DMS_ROUTES } from '@localstack/constants';
import { ReactElement, useState } from 'react';
import { useAwsEffect, useAwsGetter, useRoutes } from '@localstack/services';
import { Button, Card } from '@mui/material';
import { DMSReplicationTasksTable, ConfirmableButton, PageTitle, Dropdown } from '@localstack/ui';

import { DMSProps } from './types';
import { MainTabs } from './components/MainTabs';
import { DMSConfigOptionAlert } from './components/DMSConfigOptionAlert';

export const DMSReplicationTasks = ({
  Layout,
  clientOverrides,
  routes = DEFAULT_DMS_ROUTES,
}: DMSProps): ReactElement => {
  const { goto } = useRoutes();
  const [selectedIds, setSelectedIds] = useState<string[]>([]);
  const {
    data: replicationTasks,
    isLoading,
    mutate,
    error,
  } = useAwsGetter('DMS', 'describeReplicationTasks', [], { clientOverrides });
  const { deleteReplicationTask, startReplicationTask, stopReplicationTask } = useAwsEffect(
    'DMS',
    ['deleteReplicationTask', 'startReplicationTask', 'stopReplicationTask'],
    {
      revalidate: ['describeReplicationTasks'],
      clientOverrides,
    },
  );
  const handleRemove = async () => {
    await Promise.all(selectedIds.map(async (id) => deleteReplicationTask({ ReplicationTaskArn: id })));
  };
  const handleStart = async () => {
    await Promise.all(
      selectedIds.map(async (id) =>
        startReplicationTask({ ReplicationTaskArn: id, StartReplicationTaskType: 'start-replication' }),
      ),
    );
  };
  const handleStop = async () => {
    await Promise.all(selectedIds.map(async (id) => stopReplicationTask({ ReplicationTaskArn: id })));
  };
  return (
    <Layout
      title={<PageTitle title="DMS Replication Tasks" onMutate={mutate} />}
      tabs={<MainTabs routes={routes} />}
      actions={
        <>
          <Button onClick={() => goto(routes.RESOURCES_DMS_REPLICATION_TASK_CREATE)}>Create Replication Task</Button>
          <Dropdown label="Actions">
            <ConfirmableButton
              componentType="MenuItem"
              disabled={selectedIds.length === 0 || isLoading}
              title={`Delete ${selectedIds.length} Replication Task(s)?`}
              onClick={handleRemove}
              text="Selected Replication Tasks will be permanently deleted"
            >
              Remove Selected
            </ConfirmableButton>
            <ConfirmableButton
              componentType="MenuItem"
              disabled={selectedIds.length === 0 || isLoading}
              title={`Start ${selectedIds.length} Replication Task(s)?`}
              onClick={handleStart}
            >
              Start Selected
            </ConfirmableButton>
            <ConfirmableButton
              componentType="MenuItem"
              disabled={selectedIds.length === 0 || isLoading}
              title={`Stop ${selectedIds.length} Replication Task(s)?`}
              onClick={handleStop}
            >
              Stop Selected
            </ConfirmableButton>
          </Dropdown>
        </>
      }
    >
      <DMSConfigOptionAlert error={error} />
      <Card>
        <DMSReplicationTasksTable
          replicationTasks={replicationTasks?.ReplicationTasks || []}
          onViewReplicationTask={(id: string) => goto(routes.RESOURCES_DMS_REPLICATION_TASK, { id })}
          loading={isLoading}
          onSelect={setSelectedIds}
        />
      </Card>
    </Layout>
  );
};
