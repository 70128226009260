import { ReactElement, useState } from 'react';
import { Link } from 'react-router-dom';

import { AwsServiceIcon, DataGrid, GridRenderCellParams } from '@localstack/ui';

import { Accordion, AccordionDetails, AccordionSummary, Grid, List, ListItem } from '@mui/material';

import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';

import { ExpandMore as ExpandMoreIcon } from '@mui/icons-material';

import { CodeSnippetViewer } from '@localstack/ui/src/display/CodeSnippetViewer';

type JsonRecord = Record<string, unknown>;

export type TraceRequest = {
  readonly service: string;
  readonly operation: string;
  readonly request: JsonRecord;
  readonly response: JsonRecord;
};

export type TraceSection = {
  readonly name: string;
  readonly traces: Array<TraceRequest>;
  outcome: string;
};

export type RunTracesInput = {
  traceLogs: Array<string>;
};

const JsonView = ({ code }: { code: string }) => (
  <CodeSnippetViewer data={code} fieldName="" expandableContainerHeight={300} />
);

const useStyles = makeStyles(() =>
  createStyles({
    accordion: {
      width: '100%',
      boxShadow: 'none',
      padding: 0,
      '&::before': {
        display: 'none',
      },
    },
    accordionDetails: {
      display: 'block',
      width: '100%',
      height: 'auto',
    },
    muiGrid: {
      '& *': {
        maxHeight: 'none !important',
      },
    },
  }),
);

const ExpandableJsonEditor = ({
  json,
  expanded,
  setExpanded,
}: {
  json: string | object;
  expanded: boolean;
  setExpanded: () => null;
}) => {
  const jsonString = typeof json === 'string' ? json : JSON.stringify(json, null, 2);

  return expanded ? (
    <JsonView code={jsonString} />
  ) : (
    <Link to="" onClick={setExpanded}>
      Click to Expand
    </Link>
  );
};

const TraceSectionTable = ({
  section,
  expanded = false,
}: {
  section: TraceSection;
  expanded: boolean;
}): ReactElement => {
  const classes = useStyles();
  const [isExpanded, setExpanded] = useState<boolean>(expanded);

  const columns = [
    {
      field: 'service',
      headerName: 'Service',
      width: 100,
      renderCell: (params: GridRenderCellParams) => <AwsServiceIcon code={params.row.service} size="medium" />,
    },
    {
      field: 'operation',
      headerName: 'Operation',
      width: 140,
      renderCell: (params: GridRenderCellParams) => (
        <Link to="" onClick={params.row.setExpanded}>
          {params.row.operation}
        </Link>
      ),
    },
    {
      field: 'request',
      headerName: 'Request',
      width: 450,
      renderCell: (params: GridRenderCellParams) => (
        <ExpandableJsonEditor
          json={params.row.request}
          expanded={params.row.expanded}
          setExpanded={params.row.setExpanded}
        />
      ),
    },
    {
      field: 'response',
      headerName: 'Response',
      width: 450,
      renderCell: (params: GridRenderCellParams) => (
        <ExpandableJsonEditor
          json={params.row.response}
          expanded={params.row.expanded}
          setExpanded={params.row.setExpanded}
        />
      ),
    },
  ];

  const rows = section.traces.map((trace, index) => {
    const [rowExpanded, setRowExpanded] = useState<boolean>(false);
    return {
      id: index,
      expanded: rowExpanded,
      setExpanded: setRowExpanded,
      ...trace,
    };
  });

  const { outcome } = section;
  let phaseOutcome = <>{outcome || 'n/a'}</>;
  if (outcome === 'passed') phaseOutcome = <>&#10004; {outcome}</>;
  if (['failed', 'error'].includes(outcome)) phaseOutcome = <>&#10060; {outcome}</>;

  return (
    <ListItem>
      <Accordion expanded={isExpanded} onChange={(_event, state) => setExpanded(state)} className={classes.accordion}>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <div>
            <b>Phase:</b>
            <span style={{ marginLeft: '10px', marginRight: '10px' }}>{section.name}</span>
          </div>
          (result: {phaseOutcome})
        </AccordionSummary>
        <AccordionDetails className={classes.accordionDetails}>
          {isExpanded && (
            <DataGrid
              columns={columns}
              rows={rows}
              autoHeight
              isRowSelectable={() => false}
              className={classes.muiGrid}
            />
          )}
        </AccordionDetails>
      </Accordion>
    </ListItem>
  );
};

export const RunTraces = ({ traceLogs }: RunTracesInput): ReactElement => {
  const sections: Array<TraceSection> = [];

  const traceStartIdx = 0;
  // TODO: enable pagination
  const [traceEndIndex] = useState(1000);
  const tracesToShow = traceLogs.slice(traceStartIdx, traceEndIndex);

  // Parse request/response entries from trace logs.
  // Note: this is a first simplistic approach for now. If and when we find that users
  //  are parsing huge trace logs, we can tackle this in a more performant/scalable way.
  tracesToShow.forEach((line) => {
    if (line.match(/^ci:event:/)) {
      const event = JSON.parse(line.replace('ci:event: ', ''));
      if (event.type === 'test_start') {
        sections.push({ name: event.name, traces: [], outcome: '' });
      } else if (event.type === 'test_end') {
        const currentSection = sections[sections.length - 1] as TraceSection;
        currentSection.outcome = event.outcome;
      }
      return;
    }
    if (!sections.length) {
      sections.push({ name: 'Test Setup', traces: [], outcome: '' });
    }
    if (line.match(/^ci:trace: /)) {
      const currentSection = sections[sections.length - 1] as TraceSection;
      const lineJson = JSON.parse(line.replace('ci:trace: ', ''));
      currentSection.traces.push(lineJson as TraceRequest);
    }
  });

  return (
    <Grid item xs={12}>
      <List>
        {sections.map((section, index) => (
          <TraceSectionTable section={section} key={section.name} expanded={index === 0} />
        ))}
      </List>
    </Grid>
  );
};
