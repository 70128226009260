import { ReactElement, useEffect } from 'react';
import { Grid } from '@mui/material';
import { ControlledRangeSlider } from '@localstack/ui';
import { useForm } from 'react-hook-form';

import { getInternalServerErrorTemplate } from '../../ExperimentTemplates';
import { DEFAULT_PROBABILITY, DEFAULT_REGIONS } from '../../constants';
import { ExperimentFormValues, ExperimentProps } from '../../types';

import { ExperimentCard } from '../ExperimentCard';
import { UpdateExperimentButton } from '../UpdateExperimentButton';
import { RegionInput } from '../RegionInput';

export const InternalServerErrorCard = (props: ExperimentProps): ReactElement => {
  const { experiment: experimentState, alert, onUpsertExperiment } = props;
  const experiment = Array.isArray(experimentState) ? experimentState[0] : undefined;

  const { control, handleSubmit, formState, watch, reset } = useForm<ExperimentFormValues>({
    mode: 'all',
    defaultValues: {
      probability: DEFAULT_PROBABILITY,
      region: DEFAULT_REGIONS[0],
    },
  });
  useEffect(() => {
    if (!experiment?.probability || !experiment?.region) return;
    reset({
      probability: experiment.probability * 100,
      region: experiment.region,
    });
  }, [experiment]);
  const formValues = watch();
  const template = getInternalServerErrorTemplate(formValues);
  return (
    <ExperimentCard
      experimentCard={{
        title: '500 Internal Error',
        description: `This experiment randomly terminates incoming requests,
                      generating an 'internal error' with a response code of 500.`,
        template,
        options: (
          <form onSubmit={handleSubmit((_) => onUpsertExperiment(template, experimentState))}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <ControlledRangeSlider
                  control={control}
                  name="probability"
                  label="Fault Probability"
                  postfixElement={<>%</>}
                  inputProps={{
                    type: 'number',
                    style: { textAlign: 'center', width: 80 },
                  }}
                  sliderProps={{
                    step: 1,
                    min: 1,
                    max: 100,
                  }}
                />
              </Grid>
              <Grid item xs={12}>
                <RegionInput control={control} />
              </Grid>
              <Grid item xs={12}>
                <UpdateExperimentButton formState={formState} experimentState={experimentState} alert={alert} />
              </Grid>
            </Grid>
          </form>
        ),
      }}
      formState={formState}
      {...props}
    />
  );
};
