import { ReactElement } from 'react';
import { useRoutes, useAwsGetter } from '@localstack/services';
import { useParams } from 'react-router-dom';
import { Card, CardContent, Button, Box, Typography } from '@mui/material';
import { Breadcrumbs, NeptuneInstanceDetails } from '@localstack/ui';

import { DEFAULT_NEPTUNE_ROUTES } from '@localstack/constants';

import { NeptuneProps } from './types';

export const NeptuneDBInstance = ({
  Layout,
  clientOverrides,
  routes = DEFAULT_NEPTUNE_ROUTES,
}: NeptuneProps): ReactElement => {
  const { goto } = useRoutes();
  const { instanceId } = useParams<'instanceId'>();

  const { data: instances } = useAwsGetter('Neptune', 'describeDBInstances', [{ DBInstanceIdentifier: instanceId }], {
    clientOverrides,
  });
  const instance = instances?.DBInstances?.find((inst) => inst.DBInstanceIdentifier === instanceId);

  return (
    <Layout
      documentTitle="Instance Details"
      title={
        <Box>
          <Typography variant="h4">Instance Details</Typography>
          <Breadcrumbs
            mappings={[
              ['Neptune', () => goto(routes.RESOURCES_NEPTUNE_INSTANCES)],
              [`${instanceId} (${instance?.DBName})`, null],
            ]}
          />
        </Box>
      }
      actions={
        <Button onClick={() => goto(routes.RESOURCES_NEPTUNE_INSTANCE_UPDATE, { instanceId })}>Edit Instance</Button>
      }
    >
      <Card>
        <CardContent>
          <NeptuneInstanceDetails instance={instance} />
        </CardContent>
      </Card>
    </Layout>
  );
};
