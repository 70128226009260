import { ReactElement, useState } from 'react';
import { Button, CardHeader } from '@mui/material';
import { Add as AddIcon } from '@mui/icons-material';
import { CreditCardsList, LoadingFragment } from '@localstack/ui';
import { AdminService, useApiEffect, useApiGetter } from '@localstack/services';

import { StripeCardForm } from '~/components';

export interface PaymentMethods {
  orgId: string;
}

export const PaymentMethods = ({ orgId }: PaymentMethods): ReactElement => {
  const [showCardForm, setShowCardForm] = useState(false);
  const [stripeLoading, setStripeLoading] = useState(false);

  const { data: cards, isLoading: isCardsLoading } = useApiGetter(AdminService, 'listOrganizationCards', [orgId], {
    defaultValue: [],
  });

  const {
    attachOrganizationCard,
    removeOrganizationCard,
    isLoading: isCardMutating,
  } = useApiEffect(AdminService, ['attachOrganizationCard', 'removeOrganizationCard'], {
    revalidate: ['listOrganizationCards'],
  });

  return (
    <>
      <CardHeader
        action={
          <Button
            color="primary"
            size="small"
            onClick={() => setShowCardForm(!showCardForm)}
            startIcon={showCardForm ? null : <AddIcon />}
          >
            {showCardForm ? 'Cancel' : 'Add Card'}
          </Button>
        }
      />
      <LoadingFragment loading={isCardsLoading} variant="list" height={65} size={3}>
        {!showCardForm && (
          <CreditCardsList
            cards={cards || []}
            onDelete={(card) => {
              removeOrganizationCard(orgId, card.id, card.currency as string);
            }}
          />
        )}
        {showCardForm && (
          <StripeCardForm
            showCurrencySelection
            defaultCurrency="usd"
            loading={stripeLoading || isCardMutating}
            onSaveCard={async (token, currency) => {
              if (!currency) return;
              try {
                setStripeLoading(true);
                await attachOrganizationCard(orgId, { token, currency });
                setShowCardForm(false);
              } finally {
                setStripeLoading(false);
              }
            }}
          />
        )}
      </LoadingFragment>
    </>
  );
};
