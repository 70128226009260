import { ReactElement } from 'react';
import { Card, CardContent, CardActions, Box, Typography } from '@mui/material';
import { useAwsEffect, useRoutes } from '@localstack/services';
import { Breadcrumbs, ProgressButton, SNSTopicCreateForm } from '@localstack/ui';
import { CreateSNSTopicRequest } from '@localstack/types';

import { DEFAULT_SNS_ROUTES } from '@localstack/constants';

import { SNSProps } from './types';

export const SNSTopicCreate = ({ Layout, clientOverrides, routes = DEFAULT_SNS_ROUTES }: SNSProps): ReactElement => {
  const { goto } = useRoutes();

  const { createTopic, isLoading } = useAwsEffect('SNS', ['createTopic'], {
    revalidate: ['listTopics'],
    clientOverrides,
  });

  const handleCreate = async (data: CreateSNSTopicRequest) => {
    await createTopic(data);
    goto(routes.RESOURCES_SNS_TOPICS);
  };

  return (
    <Layout
      documentTitle="Create Topic"
      title={
        <Box>
          <Typography variant="h4">Topic Details</Typography>
          <Breadcrumbs
            mappings={[
              ['SNS', () => goto(routes.RESOURCES_SNS_TOPICS)],
              ['Create Topic', null],
            ]}
          />
        </Box>
      }
      actions={
        <ProgressButton type="submit" form="CreateTopic" variant="outlined" color="primary" loading={isLoading}>
          Submit
        </ProgressButton>
      }
    >
      <Card>
        <CardContent>
          <SNSTopicCreateForm onCreate={handleCreate} formId="CreateTopic" />
        </CardContent>
        <CardActions>
          <ProgressButton type="submit" form="CreateTopic" variant="contained" color="primary" loading={isLoading}>
            Submit
          </ProgressButton>
        </CardActions>
      </Card>
    </Layout>
  );
};
