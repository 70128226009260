import { ReactElement } from 'react';
import { NavLink } from '@localstack/ui';
import { buildRoute } from '@localstack/services';

import { ECSRoutes } from '../../types';

interface ClusterNavTabsProps {
  clusterName: string;
  routes: ECSRoutes;
}

export const ClusterNavTabs = ({ clusterName, routes }: ClusterNavTabsProps): ReactElement => (
  <>
    <NavLink to={buildRoute(routes.RESOURCES_ECS_CLUSTER, { clusterName })} end>
      Details
    </NavLink>
    <NavLink to={buildRoute(routes.RESOURCES_ECS_CLUSTER_SERVICES, { clusterName })}>Services</NavLink>
    <NavLink to={buildRoute(routes.RESOURCES_ECS_CLUSTER_TASKS, { clusterName })}>Tasks</NavLink>
  </>
);
