import { useState, useCallback, ReactElement } from 'react';
import { Button, Card } from '@mui/material';
import { useRoutes, useAwsGetter, useAwsEffect } from '@localstack/services';
import { Dropdown, ConfirmableButton, SESIdentitiesTable, PageTitle } from '@localstack/ui';
import { DEFAULT_SES_ROUTES } from '@localstack/constants';

import { SESV2IdentityInfo } from '@localstack/types';

import { SESProps } from './types';
import { IndexNavTabs } from './components/IndexNavTabs';
/**
 * A list view of all SimpleEmailService V2 identities.
 */
export const SESV2Identities = ({ Layout, clientOverrides, routes = DEFAULT_SES_ROUTES }: SESProps): ReactElement => {
  const { goto } = useRoutes();
  const [selected, setSelected] = useState<string[]>([]);

  const { deleteEmailIdentity } = useAwsEffect('SESV2', ['deleteEmailIdentity'], {
    revalidate: ['listEmailIdentities'],
    clientOverrides,
  });
  const {
    data: identities,
    isLoading,
    mutate,
  } = useAwsGetter('SESV2', 'listEmailIdentities', [], {
    clientOverrides,
  });
  const handleDeleteObjects = useCallback(
    async () => Promise.all(selected.map((Identity) => deleteEmailIdentity({ EmailIdentity: Identity }))),
    [selected],
  );

  return (
    <Layout
      title={<PageTitle title="SES Identities" onMutate={mutate} />}
      tabs={<IndexNavTabs routes={routes} />}
      actions={
        <>
          <Button onClick={() => goto(routes.RESOURCES_SES2_IDENTITY_CREATE)}>Create Identity</Button>
          <Dropdown label="Actions">
            <ConfirmableButton
              componentType="MenuItem"
              disabled={selected.length === 0 || isLoading}
              title={`Remove ${selected.length} identities?`}
              onClick={handleDeleteObjects}
              text="Selected identities will be permanently deleted"
            >
              Remove Selected
            </ConfirmableButton>
          </Dropdown>
        </>
      }
    >
      <Card>
        <SESIdentitiesTable
          rows={identities?.EmailIdentities?.map((identity: SESV2IdentityInfo) => identity.IdentityName || '') ?? []}
          onView={(id) => goto(routes.RESOURCES_SES2_IDENTITY_MESSAGES, { id })}
          onSelect={setSelected}
        />
      </Card>
    </Layout>
  );
};
