import { ReactElement } from 'react';
import { AwsClientOverrides, getSchema } from '@localstack/services';
import { CreateTrailRequest } from '@localstack/types';

import { DEFAULT_S3_ROUTES } from '@localstack/constants';

import { RelatedResourcePicker } from '../../../../form';
import { MagicForm } from '../../../magic/MagicForm';

export interface CloudTrailCreateFormProps {
  loading?: boolean;
  formId?: string;
  clientOverrides?: AwsClientOverrides;
  onCreate: (data: CreateTrailRequest) => unknown;
}

export const CloudTrailCreateForm = ({
  loading,
  formId,
  clientOverrides,
  onCreate,
}: CloudTrailCreateFormProps): ReactElement => (
  <MagicForm
    formId={formId}
    schema={getSchema('CloudTrail')}
    entry="CreateTrailRequest"
    loading={loading}
    externalFields={{
      '^S3BucketName$': (control, fieldName, required) => (
        <RelatedResourcePicker
          required={required}
          control={control}
          fieldName={fieldName}
          entityName="S3 Bucket"
          client="S3"
          method="listBuckets"
          arrayKeyName="Buckets"
          property="Name"
          clientOverrides={{ ...clientOverrides, s3ForcePathStyle: true }}
          creationRoute={DEFAULT_S3_ROUTES.RESOURCES_S3_BUCKET_CREATE}
        />
      ),
    }}
    onSubmit={(data: CreateTrailRequest) => onCreate(data)}
  />
);
