import { DEFAULT_DOCDB_ROUTES } from '@localstack/constants';
import { ReactElement } from 'react';
import { useAwsGetter, useRoutes } from '@localstack/services';
import { useParams } from 'react-router-dom';
import { Box, Button, Card, Typography } from '@mui/material';
import { Breadcrumbs, DocDBClusterDetail } from '@localstack/ui';

import { DocDBProps } from './types';

export const DocDBCluster = ({ Layout, clientOverrides, routes = DEFAULT_DOCDB_ROUTES }: DocDBProps): ReactElement => {
  const { goto } = useRoutes();
  const { id } = useParams<'id'>();
  const { data: clusterData } = useAwsGetter('DocDB', 'describeDBClusters', [{ DBClusterIdentifier: id }], {
    clientOverrides,
    swrOverrides: { revalidateOnMount: true },
  });
  const cluster = clusterData?.DBClusters?.filter((c) => c.DBClusterIdentifier === id)[0];
  return (
    <Layout
      title={
        <Box>
          <Typography variant="h4">Cluster Details</Typography>
          <Breadcrumbs
            mappings={[
              ['DocDB', () => goto(routes.RESOURCES_DOCDB_CLUSTERS)],
              [id, null],
            ]}
          />
        </Box>
      }
      actions={<Button onClick={() => goto(routes.RESOURCES_DOCDB_CLUSTER_UPDATE, { id })}>Edit Cluster</Button>}
    >
      <Card>
        <DocDBClusterDetail cluster={cluster} />
      </Card>
    </Layout>
  );
};
