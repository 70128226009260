import { buildRoute } from '@localstack/services';
import { LocalStackInstance } from '@localstack/types';

import { NavLink as BaseNavLink } from 'react-router-dom';
import { ReactElement } from 'react';
import { INSTANCE_ROUTES } from '@localstack/constants';

import { NavLink as CustomNavLink } from '@localstack/ui';

type InstanceRoutePath = (typeof INSTANCE_ROUTES)[keyof typeof INSTANCE_ROUTES];

type InstanceEntriesDefinition = {
  name: string;
  route: InstanceRoutePath;
  secondaryRoute?: InstanceRoutePath;
};

export type InstanceMenuEntriesProps = {
  instance?: LocalStackInstance;
  wrapper?: (children: ReactElement, entry: InstanceEntriesDefinition) => ReactElement;
  className?: string;
  useLocalStackLinkComponent?: boolean;
  disabledRoutes?: string[];
};

const defaultWrapper = (children: ReactElement, _entry: InstanceEntriesDefinition) => children;

export const InstanceMenuEntries = ({
  instance,
  wrapper = defaultWrapper,
  className,
  useLocalStackLinkComponent = true,
  disabledRoutes,
}: InstanceMenuEntriesProps): ReactElement => {
  const LinkComponent = useLocalStackLinkComponent ? CustomNavLink : BaseNavLink;
  const links: InstanceEntriesDefinition[] = [
    {
      name: 'Overview',
      route: INSTANCE_ROUTES.STACK_OVERVIEW,
    },
    {
      name: 'Status',
      route: INSTANCE_ROUTES.STATUS,
    },
    {
      name: 'Resource Browser',
      route: INSTANCE_ROUTES.RESOURCES_OVERVIEW,
      secondaryRoute: INSTANCE_ROUTES.RESOURCES_COMMON,
    },
    {
      name: 'State',
      route: INSTANCE_ROUTES.INSTANCE_STATE,
    },
    {
      name: 'IAM Policy Stream',
      route: INSTANCE_ROUTES.IAM_POLICY_STREAM,
    },
    {
      name: 'Chaos Engineering',
      route: INSTANCE_ROUTES.CHAOS_ENGINEERING,
    },
    ...(instance?.ephemeralInstance
      ? [
          {
            name: 'Logs',
            route: INSTANCE_ROUTES.INSTANCE_LOGS,
          },
        ]
      : []),
    {
      name: 'Extensions',
      route: INSTANCE_ROUTES.EXTENSIONS_MANAGE,
      secondaryRoute: INSTANCE_ROUTES.EXTENSIONS_DETAILS,
    },
  ];

  return (
    <>
      {links
        .filter(
          (element) =>
            !(disabledRoutes?.includes(element.route) || disabledRoutes?.includes(element.secondaryRoute ?? '')),
        )
        .map((entry) =>
          wrapper(
            <LinkComponent key={entry.name} to={buildRoute(entry.route, { iid: instance?.id })} className={className}>
              {entry.name}
            </LinkComponent>,
            entry,
          ),
        )}
    </>
  );
};
