import { useCallback, ReactElement } from 'react';
import { Card, CardContent, Grid, CardActions } from '@mui/material';
import { useForm } from 'react-hook-form';
import { ControlledTextField, ProgressButton, PasswordCheck } from '@localstack/ui';
import { VALIDATION_RULES, useApiEffect, UserService } from '@localstack/services';

interface PasswordData {
  old_password: string;
  password: string;
}

export const Authentication = (): ReactElement => {
  const { isLoading, updatePassword } = useApiEffect(UserService, ['updatePassword']);

  const { control, handleSubmit, formState, watch, reset } = useForm<PasswordData>({ mode: 'all' });

  const newPassword = watch('password', '');

  const onSubmit = useCallback(async (data: PasswordData) => {
    await updatePassword(data);
    reset();
  }, []);

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Card>
        <CardContent>
          <Grid container spacing={2}>
            <Grid item md={12}>
              <ControlledTextField
                control={control}
                fullWidth
                label="Old Password"
                name="old_password"
                variant="outlined"
                type="password"
                rules={VALIDATION_RULES.required}
              />
            </Grid>
            <Grid item md={12}>
              <ControlledTextField
                control={control}
                fullWidth
                label="New Password"
                name="password"
                variant="outlined"
                type="password"
                rules={VALIDATION_RULES.password}
              />
              <PasswordCheck password={newPassword ?? ''} />
            </Grid>
            <Grid item md={12}>
              <ControlledTextField
                control={control}
                fullWidth
                label="Confirm Password"
                name="confirmation"
                variant="outlined"
                type="password"
                rules={VALIDATION_RULES.passwordConfirmation(newPassword)}
              />
            </Grid>
          </Grid>
        </CardContent>
        <CardActions>
          <ProgressButton
            variant="contained"
            color="primary"
            type="submit"
            disabled={!formState.isValid}
            loading={isLoading}
          >
            Save
          </ProgressButton>
        </CardActions>
      </Card>
    </form>
  );
};
