import { ReactElement } from 'react';
import { Route53HostedZone } from '@localstack/types';
import { getSchema } from '@localstack/services';

import { MagicDetails } from '../../../magic/MagicDetails';

export interface Route53HostedZoneDetailsProps {
  hostedZone?: Optional<Partial<Route53HostedZone>>;
}

export const Route53HostedZoneDetails = ({ hostedZone }: Route53HostedZoneDetailsProps): ReactElement => (
  <MagicDetails data={hostedZone} schema={getSchema('Route53')} entry="HostedZone" title="Hosted Zone Details" />
);
