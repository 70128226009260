import { ReactElement } from 'react';
import { Link } from '@mui/material';
import { VaultRecoveryPoint } from '@localstack/types';
import { getSchema } from '@localstack/services';

import { MagicTable } from '../../../magic/MagicTable';

export type BackupVaultRecoveryPointsTableProps = {
  recoveryPoints: VaultRecoveryPoint[];
  selectable?: boolean;
  isLoading?: boolean;
  onSelect?: (arns: string[]) => unknown;
  onViewRecoveryPoint?: (point: VaultRecoveryPoint) => unknown;
  onRestoreRecoveryPoint?: (point: VaultRecoveryPoint) => unknown;
};

export const BackupVaultRecoveryPointsTable = ({
  recoveryPoints,
  selectable,
  isLoading,
  onSelect,
  onViewRecoveryPoint,
}: BackupVaultRecoveryPointsTableProps): ReactElement => (
  <MagicTable
    selectable={selectable}
    onSelect={onSelect}
    schema={getSchema('Backup')}
    entry="RecoveryPointByBackupVaultList"
    idAttribute="RecoveryPointArn"
    rows={recoveryPoints}
    loading={isLoading}
    filterColumns={['RecoveryPointArn', 'ResourceArn']}
    externalFields={{
      RecoveryPointArn: (row) => (
        <Link onClick={() => onViewRecoveryPoint && onViewRecoveryPoint(row)} underline="hover">
          {row.RecoveryPointArn}
        </Link>
      ),
    }}
  />
);
