export enum SERVICE_NAME {
  ACCESSANALYZER = 'accessanalyzer',
  ACCOUNT = 'account',
  ACMPCA = 'acm-pca',
  ACM = 'acm',
  ALEXAFORBUSINESS = 'alexaforbusiness',
  AMP = 'amp',
  AMPLIFY = 'amplify',
  AMPLIFYBACKEND = 'amplifybackend',
  AMPLIFYUIBUILDER = 'amplifyuibuilder',
  APIGATEWAY = 'apigateway',
  APIGATEWAYMANAGEMENTAPI = 'apigatewaymanagementapi',
  APIGATEWAYV2 = 'apigatewayv2',
  APPCONFIG = 'appconfig',
  APPCONFIGDATA = 'appconfigdata',
  APPFABRIC = 'appfabric',
  APPFLOW = 'appflow',
  APPINTEGRATIONS = 'appintegrations',
  APPLICATIONAUTOSCALING = 'application-autoscaling',
  APPLICATIONINSIGHTS = 'application-insights',
  APPLICATIONCOSTPROFILER = 'applicationcostprofiler',
  APPMESH = 'appmesh',
  APPRUNNER = 'apprunner',
  APPSTREAM = 'appstream',
  APPSYNC = 'appsync',
  ARCZONALSHIFT = 'arc-zonal-shift',
  ATHENA = 'athena',
  AUDITMANAGER = 'auditmanager',
  AUTOSCALINGPLANS = 'autoscaling-plans',
  AUTOSCALING = 'autoscaling',
  BACKUPGATEWAY = 'backup-gateway',
  BACKUP = 'backup',
  BACKUPSTORAGE = 'backupstorage',
  BATCH = 'batch',
  BEDROCKRUNTIME = 'bedrock-runtime',
  BEDROCK = 'bedrock',
  BILLINGCONDUCTOR = 'billingconductor',
  BRAKET = 'braket',
  BUDGETS = 'budgets',
  CE = 'ce',
  CHIMESDKIDENTITY = 'chime-sdk-identity',
  CHIMESDKMEDIAPIPELINES = 'chime-sdk-media-pipelines',
  CHIMESDKMEETINGS = 'chime-sdk-meetings',
  CHIMESDKMESSAGING = 'chime-sdk-messaging',
  CHIMESDKVOICE = 'chime-sdk-voice',
  CHIME = 'chime',
  CLEANROOMS = 'cleanrooms',
  CLOUD9 = 'cloud9',
  CLOUDCONTROL = 'cloudcontrol',
  CLOUDDIRECTORY = 'clouddirectory',
  CLOUDFORMATION = 'cloudformation',
  CLOUDFRONTKEYVALUESTORE = 'cloudfront-keyvaluestore',
  CLOUDFRONT = 'cloudfront',
  CLOUDHSM = 'cloudhsm',
  CLOUDHSMV2 = 'cloudhsmv2',
  CLOUDSEARCH = 'cloudsearch',
  CLOUDSEARCHDOMAIN = 'cloudsearchdomain',
  CLOUDTRAILDATA = 'cloudtrail-data',
  CLOUDTRAIL = 'cloudtrail',
  CLOUDWATCH = 'cloudwatch',
  CODEARTIFACT = 'codeartifact',
  CODEBUILD = 'codebuild',
  CODECATALYST = 'codecatalyst',
  CODECOMMIT = 'codecommit',
  CODEDEPLOY = 'codedeploy',
  CODEGURUREVIEWER = 'codeguru-reviewer',
  CODEGURUSECURITY = 'codeguru-security',
  CODEGURUPROFILER = 'codeguruprofiler',
  CODEPIPELINE = 'codepipeline',
  CODESTARCONNECTIONS = 'codestar-connections',
  CODESTARNOTIFICATIONS = 'codestar-notifications',
  CODESTAR = 'codestar',
  COGNITOIDENTITY = 'cognito-identity',
  COGNITOIDP = 'cognito-idp',
  COGNITOSYNC = 'cognito-sync',
  COMPREHEND = 'comprehend',
  COMPREHENDMEDICAL = 'comprehendmedical',
  COMPUTEOPTIMIZER = 'compute-optimizer',
  CONFIG = 'config',
  CONNECTCONTACTLENS = 'connect-contact-lens',
  CONNECT = 'connect',
  CONNECTCAMPAIGNS = 'connectcampaigns',
  CONNECTCASES = 'connectcases',
  CONNECTPARTICIPANT = 'connectparticipant',
  CONTROLTOWER = 'controltower',
  CUR = 'cur',
  CUSTOMERPROFILES = 'customer-profiles',
  DATABREW = 'databrew',
  DATAEXCHANGE = 'dataexchange',
  DATAPIPELINE = 'datapipeline',
  DATASYNC = 'datasync',
  DATAZONE = 'datazone',
  DAX = 'dax',
  DETECTIVE = 'detective',
  DEVICEFARM = 'devicefarm',
  DEVOPSGURU = 'devops-guru',
  DIRECTCONNECT = 'directconnect',
  DISCOVERY = 'discovery',
  DLM = 'dlm',
  DMS = 'dms',
  DOCDBELASTIC = 'docdb-elastic',
  DOCDB = 'docdb',
  DRS = 'drs',
  DS = 'ds',
  DYNAMODB = 'dynamodb',
  DYNAMODBSTREAMS = 'dynamodbstreams',
  EBS = 'ebs',
  EC2INSTANCECONNECT = 'ec2-instance-connect',
  EC2 = 'ec2',
  ECRPUBLIC = 'ecr-public',
  ECR = 'ecr',
  ECS = 'ecs',
  EFS = 'efs',
  EKS = 'eks',
  ELASTICINFERENCE = 'elastic-inference',
  ELASTICACHE = 'elasticache',
  ELASTICBEANSTALK = 'elasticbeanstalk',
  ELASTICTRANSCODER = 'elastictranscoder',
  ELB = 'elb',
  ELBV2 = 'elbv2',
  EMRCONTAINERS = 'emr-containers',
  EMRSERVERLESS = 'emr-serverless',
  EMR = 'emr',
  ENTITYRESOLUTION = 'entityresolution',
  ES = 'es',
  EVENTS = 'events',
  EVIDENTLY = 'evidently',
  FINSPACEDATA = 'finspace-data',
  FINSPACE = 'finspace',
  FIREHOSE = 'firehose',
  FIS = 'fis',
  FMS = 'fms',
  FORECAST = 'forecast',
  FORECASTQUERY = 'forecastquery',
  FRAUDDETECTOR = 'frauddetector',
  FSX = 'fsx',
  GAMELIFT = 'gamelift',
  GLACIER = 'glacier',
  GLOBALACCELERATOR = 'globalaccelerator',
  GLUE = 'glue',
  GRAFANA = 'grafana',
  GREENGRASS = 'greengrass',
  GREENGRASSV2 = 'greengrassv2',
  GROUNDSTATION = 'groundstation',
  GUARDDUTY = 'guardduty',
  HEALTH = 'health',
  HEALTHLAKE = 'healthlake',
  HONEYCODE = 'honeycode',
  IAM = 'iam',
  IDENTITYSTORE = 'identitystore',
  INFRASTRUCTURE = 'infrastructure',
  IMAGEBUILDER = 'imagebuilder',
  IMPORTEXPORT = 'importexport',
  INSPECTORSCAN = 'inspector-scan',
  INSPECTOR = 'inspector',
  INSPECTOR2 = 'inspector2',
  INTERNETMONITOR = 'internetmonitor',
  IOTDATA = 'iot-data',
  IOTJOBSDATA = 'iot-jobs-data',
  IOTROBORUNNER = 'iot-roborunner',
  IOT = 'iot',
  IOT1CLICKDEVICES = 'iot1click-devices',
  IOT1CLICKPROJECTS = 'iot1click-projects',
  IOTANALYTICS = 'iotanalytics',
  IOTDEVICEADVISOR = 'iotdeviceadvisor',
  IOTEVENTSDATA = 'iotevents-data',
  IOTEVENTS = 'iotevents',
  IOTFLEETHUB = 'iotfleethub',
  IOTFLEETWISE = 'iotfleetwise',
  IOTSECURETUNNELING = 'iotsecuretunneling',
  IOTSITEWISE = 'iotsitewise',
  IOTTHINGSGRAPH = 'iotthingsgraph',
  IOTTWINMAKER = 'iottwinmaker',
  IOTWIRELESS = 'iotwireless',
  IVSREALTIME = 'ivs-realtime',
  IVS = 'ivs',
  IVSCHAT = 'ivschat',
  KAFKA = 'kafka',
  KAFKACONNECT = 'kafkaconnect',
  KENDRARANKING = 'kendra-ranking',
  KENDRA = 'kendra',
  KEYSPACES = 'keyspaces',
  KINESISVIDEOARCHIVEDMEDIA = 'kinesis-video-archived-media',
  KINESISVIDEOMEDIA = 'kinesis-video-media',
  KINESISVIDEOSIGNALING = 'kinesis-video-signaling',
  KINESISVIDEOWEBRTCSTORAGE = 'kinesis-video-webrtc-storage',
  KINESIS = 'kinesis',
  KINESISANALYTICS = 'kinesisanalytics',
  KINESISANALYTICSV2 = 'kinesisanalyticsv2',
  KINESISVIDEO = 'kinesisvideo',
  KMS = 'kms',
  LAKEFORMATION = 'lakeformation',
  LAMBDA = 'lambda',
  LAUNCHWIZARD = 'launch-wizard',
  LEXMODELS = 'lex-models',
  LEXRUNTIME = 'lex-runtime',
  LEXV2MODELS = 'lexv2-models',
  LEXV2RUNTIME = 'lexv2-runtime',
  LICENSEMANAGERLINUXSUBSCRIPTIONS = 'license-manager-linux-subscriptions',
  LICENSEMANAGERUSERSUBSCRIPTIONS = 'license-manager-user-subscriptions',
  LICENSEMANAGER = 'license-manager',
  LIGHTSAIL = 'lightsail',
  LOCATION = 'location',
  LOGS = 'logs',
  LOOKOUTEQUIPMENT = 'lookoutequipment',
  LOOKOUTMETRICS = 'lookoutmetrics',
  LOOKOUTVISION = 'lookoutvision',
  M2 = 'm2',
  MACHINELEARNING = 'machinelearning',
  MACIE2 = 'macie2',
  MANAGEDBLOCKCHAINQUERY = 'managedblockchain-query',
  MANAGEDBLOCKCHAIN = 'managedblockchain',
  MARKETPLACECATALOG = 'marketplace-catalog',
  MARKETPLACEENTITLEMENT = 'marketplace-entitlement',
  MARKETPLACECOMMERCEANALYTICS = 'marketplacecommerceanalytics',
  MEDIACONNECT = 'mediaconnect',
  MEDIACONVERT = 'mediaconvert',
  MEDIALIVE = 'medialive',
  MEDIAPACKAGEVOD = 'mediapackage-vod',
  MEDIAPACKAGE = 'mediapackage',
  MEDIAPACKAGEV2 = 'mediapackagev2',
  MEDIASTOREDATA = 'mediastore-data',
  MEDIASTORE = 'mediastore',
  MEDIATAILOR = 'mediatailor',
  MEDICALIMAGING = 'medical-imaging',
  MEMORYDB = 'memorydb',
  METERINGMARKETPLACE = 'meteringmarketplace',
  MGH = 'mgh',
  MGN = 'mgn',
  MIGRATIONHUBREFACTORSPACES = 'migration-hub-refactor-spaces',
  MIGRATIONHUBCONFIG = 'migrationhub-config',
  MIGRATIONHUBORCHESTRATOR = 'migrationhuborchestrator',
  MIGRATIONHUBSTRATEGY = 'migrationhubstrategy',
  MOBILE = 'mobile',
  MQ = 'mq',
  MTURK = 'mturk',
  MWAA = 'mwaa',
  NEPTUNE = 'neptune',
  NEPTUNEDATA = 'neptunedata',
  NETWORKFIREWALL = 'network-firewall',
  NETWORKMANAGER = 'networkmanager',
  NIMBLE = 'nimble',
  OAM = 'oam',
  OMICS = 'omics',
  OPENSEARCH = 'opensearch',
  OPENSEARCHSERVERLESS = 'opensearchserverless',
  OPSWORKS = 'opsworks',
  OPSWORKSCM = 'opsworkscm',
  ORGANIZATIONS = 'organizations',
  OSIS = 'osis',
  OUTPOSTS = 'outposts',
  PANORAMA = 'panorama',
  PAYMENTCRYPTOGRAPHYDATA = 'payment-cryptography-data',
  PAYMENTCRYPTOGRAPHY = 'payment-cryptography',
  PCACONNECTORAD = 'pca-connector-ad',
  PERSONALIZEEVENTS = 'personalize-events',
  PERSONALIZERUNTIME = 'personalize-runtime',
  PERSONALIZE = 'personalize',
  PI = 'pi',
  PINPOINTEMAIL = 'pinpoint-email',
  PINPOINTSMSVOICEV2 = 'pinpoint-sms-voice-v2',
  PINPOINTSMSVOICE = 'pinpoint-sms-voice',
  PINPOINT = 'pinpoint',
  PIPES = 'pipes',
  POLLY = 'polly',
  PRICING = 'pricing',
  PRIVATENETWORKS = 'privatenetworks',
  PROTON = 'proton',
  QLDBSESSION = 'qldb-session',
  QLDB = 'qldb',
  QUICKSIGHT = 'quicksight',
  RAM = 'ram',
  RBIN = 'rbin',
  RDSDATA = 'rds-data',
  RDS = 'rds',
  REDSHIFTDATA = 'redshift-data',
  REDSHIFTSERVERLESS = 'redshift-serverless',
  REDSHIFT = 'redshift',
  REKOGNITION = 'rekognition',
  RESILIENCEHUB = 'resiliencehub',
  RESOURCEEXPLORER2 = 'resource-explorer-2',
  RESOURCEGROUPS = 'resource-groups',
  RESOURCEGROUPSTAGGINGAPI = 'resourcegroupstaggingapi',
  ROBOMAKER = 'robomaker',
  ROLESANYWHERE = 'rolesanywhere',
  ROUTE53RECOVERYCLUSTER = 'route53-recovery-cluster',
  ROUTE53RECOVERYCONTROLCONFIG = 'route53-recovery-control-config',
  ROUTE53RECOVERYREADINESS = 'route53-recovery-readiness',
  ROUTE53 = 'route53',
  ROUTE53DOMAINS = 'route53domains',
  ROUTE53RESOLVER = 'route53resolver',
  RUM = 'rum',
  S3 = 's3',
  S3CONTROL = 's3control',
  S3OUTPOSTS = 's3outposts',
  SAGEMAKERA2IRUNTIME = 'sagemaker-a2i-runtime',
  SAGEMAKEREDGE = 'sagemaker-edge',
  SAGEMAKERFEATURESTORERUNTIME = 'sagemaker-featurestore-runtime',
  SAGEMAKERGEOSPATIAL = 'sagemaker-geospatial',
  SAGEMAKERMETRICS = 'sagemaker-metrics',
  SAGEMAKERRUNTIME = 'sagemaker-runtime',
  SAGEMAKER = 'sagemaker',
  SAVINGSPLANS = 'savingsplans',
  SCHEDULER = 'scheduler',
  SCHEMAS = 'schemas',
  SDB = 'sdb',
  SECRETSMANAGER = 'secretsmanager',
  SECURITYHUB = 'securityhub',
  SECURITYLAKE = 'securitylake',
  SERVERLESSREPO = 'serverlessrepo',
  SERVICEQUOTAS = 'service-quotas',
  SERVICECATALOGAPPREGISTRY = 'servicecatalog-appregistry',
  SERVICECATALOG = 'servicecatalog',
  SERVICEDISCOVERY = 'servicediscovery',
  SES = 'ses',
  SESV2 = 'sesv2',
  SHIELD = 'shield',
  SIGNER = 'signer',
  SIMSPACEWEAVER = 'simspaceweaver',
  SMSVOICE = 'sms-voice',
  SMS = 'sms',
  SNOWDEVICEMANAGEMENT = 'snow-device-management',
  SNOWBALL = 'snowball',
  SNS = 'sns',
  SQS = 'sqs',
  SSMCONTACTS = 'ssm-contacts',
  SSMINCIDENTS = 'ssm-incidents',
  SSMSAP = 'ssm-sap',
  SSM = 'ssm',
  SSOADMIN = 'sso-admin',
  SSOOIDC = 'sso-oidc',
  SSO = 'sso',
  STEPFUNCTIONS = 'stepfunctions',
  STORAGEGATEWAY = 'storagegateway',
  STS = 'sts',
  SUPPORTAPP = 'support-app',
  SUPPORT = 'support',
  SWF = 'swf',
  SYNTHETICS = 'synthetics',
  TEXTRACT = 'textract',
  TIMESTREAMQUERY = 'timestream-query',
  TIMESTREAMWRITE = 'timestream-write',
  TNB = 'tnb',
  TRANSCRIBE = 'transcribe',
  TRANSFER = 'transfer',
  TRANSLATE = 'translate',
  TRUSTEDADVISOR = 'trustedadvisor',
  VERIFIEDPERMISSIONS = 'verifiedpermissions',
  VOICEID = 'voice-id',
  VPCLATTICE = 'vpc-lattice',
  WAFREGIONAL = 'waf-regional',
  WAF = 'waf',
  WAFV2 = 'wafv2',
  WELLARCHITECTED = 'wellarchitected',
  WISDOM = 'wisdom',
  WORKDOCS = 'workdocs',
  WORKLINK = 'worklink',
  WORKMAIL = 'workmail',
  WORKMAILMESSAGEFLOW = 'workmailmessageflow',
  WORKSPACESWEB = 'workspaces-web',
  WORKSPACES = 'workspaces',
  XRAY = 'xray',
}
