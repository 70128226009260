import { ReactElement } from 'react';
import { Controller, Control, ControllerProps } from 'react-hook-form';
import { RadioGroupProps, RadioGroup, Radio, FormLabel, FormControlLabel } from '@mui/material';

export type OptionStruct = {
  value: string;
  label: string;
};

type Props = RadioGroupProps &
  Omit<ControllerProps, 'control' | 'render'> & {
    control: Control<any>; // eslint-disable-line
    options: OptionStruct[];
    required?: boolean;
    label?: string;
    disabled?: boolean;
  };

export const ControlledRadioGroup = ({ control, options, label, disabled, ...rest }: Props): ReactElement => (
  <Controller
    {...rest}
    control={control}
    render={({ field }) => (
      <>
        {label && <FormLabel>{label}</FormLabel>}
        <RadioGroup {...rest} {...field}>
          {options.map((option) => (
            <FormControlLabel
              key={option.value}
              value={option.value}
              label={option.label}
              disabled={disabled}
              control={<Radio />}
            />
          ))}
        </RadioGroup>
      </>
    )}
  />
);

export default ControlledRadioGroup;
