import { GTMEventTriggers } from '@localstack/constants';
import { PostHog } from 'posthog-js';

declare global {
  interface Window {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    dataLayer: any[];
  }
}

export const pushGTMEvent =
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  (event: GTMEventTriggers, posthog: PostHog, additionalData: Record<string, any> = {}): void => {
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      event,
      ...additionalData,
    });
    posthog?.capture(event, additionalData);
  };
