import { ReactElement } from 'react';
import { useRoutes, useAwsGetter } from '@localstack/services';
import { useParams } from 'react-router-dom';
import { Card, CardHeader, Button } from '@mui/material';
import { Add as AddIcon } from '@mui/icons-material';
import { AppSyncResolversTable } from '@localstack/ui';

import { AppSyncRoutes } from '../../types';

interface Props {
  typeName: string;
  onSelect: (pairs: [string, string][]) => unknown;
  routes: AppSyncRoutes;
}

export const ResolversCard = ({ typeName, onSelect, routes }: Props): ReactElement => {
  const { goto } = useRoutes();
  const { apiId } = useParams<'apiId'>();

  const { data: resolvers, isLoading } = useAwsGetter('AppSync', 'listResolvers', [{ apiId, typeName }]);

  return (
    <Card>
      <CardHeader
        title={typeName}
        action={
          <Button
            size="small"
            startIcon={<AddIcon />}
            onClick={() => goto(routes.RESOURCES_APPSYNC_API_RESOLVER_CREATE, { apiId, typeName })}
          >
            Attach Resolver
          </Button>
        }
      />
      <AppSyncResolversTable
        selectable
        loading={isLoading}
        resolvers={resolvers?.resolvers ?? []}
        onSelect={(fieldNames) => onSelect(fieldNames.map((fieldName) => [typeName, fieldName]))}
        onViewResolver={({ fieldName }) => goto(routes.RESOURCES_APPSYNC_API_RESOLVER, { apiId, typeName, fieldName })}
      />
    </Card>
  );
};
