import { DEFAULT_DOCDB_ROUTES } from '@localstack/constants';
import { ReactElement } from 'react';
import { CreateDBInstanceMessage, ModifyDBInstanceMessage } from '@localstack/types';
import { Breadcrumbs, DocDBInstanceForm, ProgressButton } from '@localstack/ui';
import { useAwsEffect, useAwsGetter, useRoutes } from '@localstack/services';
import { Box, Card, CardActions, CardContent, Typography } from '@mui/material';

import { useParams } from 'react-router-dom';

import { DocDBProps } from './types';

export const DocDBInstanceUpsert = ({
  Layout,
  clientOverrides,
  routes = DEFAULT_DOCDB_ROUTES,
}: DocDBProps): ReactElement => {
  const { goto } = useRoutes();
  const { id } = useParams<'id'>();
  const { data: instanceData } = useAwsGetter('DocDB', 'describeDBInstances', [{ DBInstanceIdentifier: id }], {
    clientOverrides,
  });
  const { createDBInstance, modifyDBInstance, isLoading } = useAwsEffect(
    'DocDB',
    ['createDBInstance', 'modifyDBInstance'],
    {
      clientOverrides,
      revalidate: ['describeDBInstances', 'describeDBClusters'],
    },
  );
  const handleCreate = async (data: CreateDBInstanceMessage) => {
    if (await createDBInstance(data)) {
      goto(routes.RESOURCES_DOCDB_CLUSTERS);
    }
  };
  const handleUpdate = async (data: ModifyDBInstanceMessage) => {
    if (await modifyDBInstance(data)) {
      goto(routes.RESOURCES_DOCDB_INSTANCE, { id });
    }
  };
  const instance = instanceData?.DBInstances?.filter((c) => c.DBInstanceIdentifier === id)[0];
  return (
    <Layout
      title={
        <Box>
          <Typography variant="h4">Instance Details</Typography>
          <Breadcrumbs
            mappings={[
              ['DocDB', () => goto(routes.RESOURCES_DOCDB_CLUSTERS)],
              [id, () => goto(routes.RESOURCES_DOCDB_INSTANCE, { id })],
              [instance ? 'Update Instance' : 'Create Instance', null],
            ]}
          />
        </Box>
      }
      actions={
        <ProgressButton color="primary" variant="outlined" type="submit" form="DocDBInstanceUpsert" loading={isLoading}>
          Submit
        </ProgressButton>
      }
    >
      <Card>
        <CardContent>
          <DocDBInstanceForm
            clientOverrides={clientOverrides}
            formId="DocDBInstanceUpsert"
            onSubmit={instance ? handleUpdate : handleCreate}
            instance={instance}
            loading={isLoading}
          />
        </CardContent>
        <CardActions>
          <ProgressButton
            color="primary"
            variant="outlined"
            type="submit"
            form="DocDBInstanceUpsert"
            loading={isLoading}
          >
            Submit
          </ProgressButton>
        </CardActions>
      </Card>
    </Layout>
  );
};
