import { SERVICE_NAME } from '@localstack/types';

import { PATH_PREFIX_RESOURCES } from './base';

const KINESIS_BASE_ROUTE = `${PATH_PREFIX_RESOURCES}/${SERVICE_NAME.KINESIS}`;

export const DEFAULT_KINESIS_ROUTES: Record<string, string> = {
  RESOURCES_KINESIS: `${KINESIS_BASE_ROUTE}`,
  RESOURCES_KINESIS_STREAMS: `${KINESIS_BASE_ROUTE}/streams`,
  RESOURCES_KINESIS_STREAM: `${KINESIS_BASE_ROUTE}/streams/:streamName`,
  RESOURCES_KINESIS_STREAM_CREATE: `${KINESIS_BASE_ROUTE}/streams/new`,
  RESOURCES_KINESIS_STREAM_UPDATE: `${KINESIS_BASE_ROUTE}/streams/:streamName/update`,
};
