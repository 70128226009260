import { ReactElement, useEffect } from 'react';
import { Grid, TextField } from '@mui/material';
import { ControlledAutocomplete } from '@localstack/ui';
import { useForm } from 'react-hook-form';
import { VALIDATION_RULES } from '@localstack/services';

import { AWS_REGIONS } from '@localstack/constants';

import { getRegionUnavailableError } from '../../ExperimentTemplates';
import { DEFAULT_REGIONS } from '../../constants';
import { ExperimentFormValues, ExperimentProps } from '../../types';

import { ExperimentCard } from '../ExperimentCard';
import { UpdateExperimentButton } from '../UpdateExperimentButton';

export const RegionUnavailableCard = (props: ExperimentProps): ReactElement => {
  const { experiment: experimentState, alert, onUpsertExperiment } = props;
  const experiment = Array.isArray(experimentState) ? experimentState : undefined;
  const { control, handleSubmit, formState, watch, reset } = useForm<ExperimentFormValues>({
    mode: 'all',
    defaultValues: {
      regions: DEFAULT_REGIONS,
    },
  });
  useEffect(() => {
    if (!experiment?.length) return;
    reset({
      regions: experiment.map((exp) => exp.region || ''),
    });
  }, [experiment?.length]);
  const formValues = watch();
  const template = getRegionUnavailableError(formValues);

  return (
    <ExperimentCard
      experimentCard={{
        title: 'AWS Region Unavailable',
        description: 'This experiment involves disabling entire regions to simulate regional outages and failovers.',
        template,
        options: (
          <form onSubmit={handleSubmit((_) => onUpsertExperiment(template, experimentState))}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <ControlledAutocomplete
                  control={control}
                  fullWidth
                  name="regions"
                  options={AWS_REGIONS}
                  rules={VALIDATION_RULES.required}
                  multiple
                  getValueOption={(item) => item}
                  getOptionValue={(item) => item}
                  renderInput={(params) => <TextField variant="standard" {...params} label="Regions" />}
                />
              </Grid>
              <Grid item xs={12}>
                <UpdateExperimentButton formState={formState} experimentState={experimentState} alert={alert} />
              </Grid>
            </Grid>
          </form>
        ),
      }}
      formState={formState}
      {...props}
    />
  );
};
