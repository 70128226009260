import { ThemeType } from '@localstack/constants';
import { Theme } from '@mui/material';
import { blueGrey, grey } from '@mui/material/colors';
import { createStyles, makeStyles } from '@mui/styles';

export const useStyles = makeStyles((theme: Theme) => {
  const isDarkMode = theme.palette.mode === ThemeType.DARK;
  const radioBorderColor = isDarkMode ? grey[800] : grey[300];
  return createStyles({
    baseFontSize: {
      fontSize: 13,
    },
    grey600Text: {
      color: isDarkMode ? grey[500] : grey[600],
    },
    grey700Text: {
      color: isDarkMode ? grey[400] : grey[700],
    },
    grey800Text: {
      color: isDarkMode ? grey[300] : grey[800],
    },
    ellipsisBox: {
      maxWidth: '100%',
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    },
    radioBoxParent: {
      border: `1px solid ${radioBorderColor}`,
      borderRadius: '6px',
      overflow: 'hidden',
    },
    radioBoxItem: {
      borderBottom: `1px solid ${radioBorderColor}`,
      padding: `${theme.spacing(0.5)} ${theme.spacing(1)}`,
      margin: 0,
      transition: 'all .2s ease-in-out',
      '&:last-child': {
        borderBottom: 'none',
      },
      '&:hover': {
        background: isDarkMode ? blueGrey[900] : grey[100],
      },
    },
    radioBoxActiveItem: {
      '& .Mui-checked': {
        color: isDarkMode ? grey[500] : grey[900],
      },
    },
    radioLabel: {
      flex: '1',
    },
    hiddenAsterik: {
      display: 'none',
    },
    formControlLabelRoot: {
      marginLeft: 0,
    },
  });
});
