import { ReactElement } from 'react';
import { TextField } from '@mui/material';
import { buildAgwPatches, getSchema } from '@localstack/services';

import {
  AgwRestApiIntegration,
  CreateAgwRestApiIntegrationRequest,
  UpdateAgwRestApiIntegrationRequest,
} from '@localstack/types';

import { MagicForm } from '../../../magic/MagicForm';
import { ControlledTextarea } from '../../../../form';

export interface AgwRestApiIntegrationFormProps {
  restApiId: string;
  resourceId: string;
  httpMethod: string;
  integration?: Optional<Partial<AgwRestApiIntegration>>;
  loading?: boolean;
  formId?: string;
  onCreate: (data: CreateAgwRestApiIntegrationRequest) => unknown;
  onUpdate: (data: UpdateAgwRestApiIntegrationRequest) => unknown;
}

export const AgwRestApiIntegrationForm = ({
  restApiId,
  resourceId,
  httpMethod,
  integration,
  loading,
  formId,
  onCreate,
  onUpdate,
}: AgwRestApiIntegrationFormProps): ReactElement => (
  <MagicForm
    data={integration}
    schema={getSchema('APIGateway')}
    loading={loading}
    entry="PutIntegrationRequest"
    formId={formId}
    fieldConditions={{
      '^(connectionType|passthroughBehavior|timeoutInMillis)$': ['$type', '!==', 'MOCK'],
      '^(integrationHttpMethod|contentHandling|uri)$': ['$type', '!==', 'MOCK'],
      '^credentials$': ['$type', 'in', ['AWS', 'AWS_PROXY']],
      '^connectionId$': ['$connectionType', '===', 'VPC_LINK'],
    }}
    externalFields={{
      '^restApiId$': () => <TextField value={restApiId} disabled fullWidth variant="outlined" />,
      '^resourceId$': () => <TextField value={resourceId} disabled fullWidth variant="outlined" />,
      '^httpMethod$': () => <TextField value={httpMethod} disabled fullWidth variant="outlined" />,
      '^requestTemplates\\..+': (control, name) => (
        <ControlledTextarea control={control} name={name} placeholder="Request Template" minRows={10} fullWidth />
      ),
    }}
    onSubmit={(data: CreateAgwRestApiIntegrationRequest) => {
      if (!integration?.httpMethod) return onCreate({ ...data, restApiId, resourceId, httpMethod });

      const patchOperations = buildAgwPatches(integration, data);
      return onUpdate({ restApiId, resourceId, httpMethod, patchOperations });
    }}
  />
);
