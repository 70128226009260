import { ReactElement, useMemo, useState } from 'react';

import {
  ToggleButton,
  ToggleButtonGroup,
  Button,
  Card,
  CardHeader,
  Grid,
  Link,
  Typography,
  CardActions,
  CardContent,
  Box,
  Tooltip,
} from '@mui/material';

import { downloadFileContent, getSelectedInstance, useAwsEffect, useLocalstackStatus } from '@localstack/services';
import makeStyles from '@mui/styles/makeStyles';
import { Cloud as CloudIcon, GetApp as GetAppIcon, Laptop as LaptopIcon } from '@mui/icons-material';
import { useLocation } from 'react-router-dom';

import { PlanChip, NotRunningAlert } from '@localstack/ui';

import { PlanFamily, ProductFeature } from '@localstack/types';

import { ExternalLink } from '@localstack/constants';

import classnames from 'classnames';

import { useAuthProvider } from '~/hooks/useAuthProvider';
import { InstanceLayout } from '~/layouts/Customer/InstanceLayout';

import { PodSelectorCard } from '../CloudPods/components';
import { DISABLED_PRO_TOOLTIP_TEXT } from '../CloudPods/components/common';
import { ExportToCloudPodCard } from '../CloudPods/components/ExportToCloudPodCard';
import { LoadFromCloudPodCard } from '../CloudPods/components/LoadFromCloudPodCard';

const PAGE_TITLE = 'Export/Import State';

enum ToggleState {
  LOCAL = 'local',
  CLOUD = 'CLOUD',
}

const useStyles = makeStyles((theme) => ({
  activeChip: {
    border: 'none',
    backgroundColor: theme.palette.primary.contrastText,
    color: theme.palette.primary.main,
  },
  toggleButton: {
    gap: '0.75rem',
  },
}));

export const StateManager = (): ReactElement => {
  const classes = useStyles();
  const { search } = useLocation();
  const queryParams = useMemo(() => new URLSearchParams(search), [search]);
  const queryParamPodName = queryParams.get('cloudpod') ?? undefined;
  const queryParamPodVersion = queryParams.get('version') ?? undefined;

  const instance = getSelectedInstance();
  const clientOverrides = instance ? { endpoint: instance.endpoint } : {};
  const { running, isPro } = useLocalstackStatus(clientOverrides);

  const { hasFeature } = useAuthProvider();
  const hasProSub = hasFeature(ProductFeature.FEATURE_STACKS_PREVIEW);
  const canUseLocalState = hasProSub && isPro && running;

  const [toggleState, setToggleState] = useState<ToggleState>(
    queryParamPodName ? ToggleState.CLOUD : ToggleState.LOCAL,
  );

  const { savePod } = useAwsEffect('LocalStack', ['savePod'], { silentErrors: false, clientOverrides });
  const exportPod = async (): Promise<void> => {
    const blob = await savePod();
    downloadFileContent(blob, `${instance?.name}-state`, 'application/zip');
  };

  return (
    <InstanceLayout title={PAGE_TITLE}>
      <Grid container spacing={2}>
        {!running && (
          <Grid item xs={12}>
            <NotRunningAlert />
          </Grid>
        )}
        <Grid item xs={12}>
          <Grid container spacing={2}>
            <Grid item xs={12} style={{ marginBottom: '1rem' }}>
              <ToggleButtonGroup
                exclusive
                onChange={(_, value) => setToggleState(value || toggleState)}
                value={toggleState}
              >
                <ToggleButton value={ToggleState.LOCAL} color="primary">
                  <Box display="flex" alignItems="center" className={classes.toggleButton}>
                    <LaptopIcon />
                    <span>Local</span>
                    <PlanChip
                      className={classnames({ [classes.activeChip]: toggleState === ToggleState.LOCAL })}
                      planFamily={PlanFamily.PRO_PLANS}
                    />
                  </Box>
                </ToggleButton>
                <ToggleButton value={ToggleState.CLOUD} color="primary">
                  <Box display="flex" alignItems="center" className={classes.toggleButton}>
                    <CloudIcon />
                    <span>Cloud Pod</span>
                    <PlanChip
                      className={classnames({ [classes.activeChip]: toggleState === ToggleState.CLOUD })}
                      planFamily={PlanFamily.TEAM_PLANS}
                    />
                  </Box>
                </ToggleButton>
              </ToggleButtonGroup>
            </Grid>
            {toggleState === ToggleState.LOCAL && (
              <>
                <Grid item xs={12} md={6}>
                  <PodSelectorCard disabled={!canUseLocalState} />
                </Grid>
                <Grid item xs={12} md={6}>
                  <Card>
                    <CardHeader title="Export State" />
                    <CardContent>
                      <Typography variant="body2" color="textSecondary">
                        By clicking the button below, you can export the state of the current container into a file. You
                        can later re-import this file into the running container. You could achieve the same operation
                        via the CLI with the following command <code>localstack state export &lt;file-name&gt;</code>.
                        Read more in our{' '}
                        <Link href={ExternalLink.DOCS_STATE_MGMT} target="_blank" underline="hover">
                          documentation
                        </Link>
                        .
                      </Typography>
                    </CardContent>
                    <CardActions>
                      <Tooltip title={!canUseLocalState ? DISABLED_PRO_TOOLTIP_TEXT : ''}>
                        <div>
                          <Button
                            variant="outlined"
                            color="primary"
                            onClick={exportPod}
                            startIcon={<GetAppIcon />}
                            disabled={!canUseLocalState}
                          >
                            Export State
                          </Button>
                        </div>
                      </Tooltip>
                    </CardActions>
                  </Card>
                </Grid>
              </>
            )}
            {toggleState === ToggleState.CLOUD && (
              <>
                <Grid item xs={12} md={6}>
                  <LoadFromCloudPodCard
                    podName={queryParamPodName}
                    version={queryParamPodVersion}
                    size="small"
                    highlighted={!!(queryParamPodName || queryParamPodVersion)}
                    selector
                    disabled={!running || !isPro}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <ExportToCloudPodCard disabled={!running || !isPro} />
                </Grid>
              </>
            )}
          </Grid>
        </Grid>
      </Grid>
    </InstanceLayout>
  );
};
