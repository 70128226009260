import { ReactElement } from 'react';
import { useParams } from 'react-router-dom';
import { Card, CardContent, CardActions, Box, Typography } from '@mui/material';
import { CognitoCreatePoolParams, CognitoUpdatePoolParams } from '@localstack/types';
import { useRoutes, useAwsEffect, useAwsGetter } from '@localstack/services';
import { ProgressButton, CognitoPoolForm, Breadcrumbs } from '@localstack/ui';

import { DEFAULT_COGNITO_ROUTES } from '@localstack/constants';

import { CognitoProps } from './types';

import { PoolNavTabs } from './components';

export const CognitoPoolUpsert = ({
  Layout,
  clientOverrides,
  routes = DEFAULT_COGNITO_ROUTES,
}: CognitoProps): ReactElement => {
  const { goto } = useRoutes();
  const { poolId } = useParams<'poolId'>();

  const { data: pool } = useAwsGetter('CognitoIdentityServiceProvider', 'describeUserPool', [{ UserPoolId: poolId }], {
    clientOverrides,
  });

  const { createUserPool, updateUserPool, isLoading } = useAwsEffect(
    'CognitoIdentityServiceProvider',
    ['createUserPool', 'updateUserPool'],
    { revalidate: ['describeUserPool', 'listUserPools'], clientOverrides },
  );

  const handleCreate = async (data: CognitoCreatePoolParams) => {
    await createUserPool(data);
    goto(routes.RESOURCES_COGNITO);
  };

  const handleUpdate = async (data: CognitoUpdatePoolParams) => {
    await updateUserPool(data);
    goto(routes.RESOURCES_COGNITO_USER_POOL, { poolId });
  };

  return (
    <Layout
      title={
        <Box>
          <Typography variant="h4">Model Details</Typography>
          <Breadcrumbs
            mappings={[
              ['Cognito', () => goto(routes.RESOURCES_COGNITO)],
              [pool?.UserPool?.Name ?? poolId, () => goto(routes.RESOURCES_COGNITO_USER_POOL, { poolId })],
              [poolId ? 'Update' : 'Create User Pool', null],
            ]}
          />
        </Box>
      }
      tabs={poolId && <PoolNavTabs poolId={poolId} routes={routes} />}
      actions={
        <ProgressButton type="submit" form="update" variant="outlined" color="primary" loading={isLoading}>
          Submit
        </ProgressButton>
      }
    >
      <Card>
        <CardContent>
          <CognitoPoolForm
            pool={pool?.UserPool}
            clientOverrides={clientOverrides}
            onCreate={handleCreate}
            onUpdate={handleUpdate}
            loading={isLoading}
            formId="update"
          />
        </CardContent>
        <CardActions>
          <ProgressButton type="submit" form="update" variant="contained" color="primary" loading={isLoading}>
            Submit
          </ProgressButton>
        </CardActions>
      </Card>
    </Layout>
  );
};
