import { useState, useCallback, ReactElement } from 'react';
import { useRoutes, useAwsEffect, useAwsGetter } from '@localstack/services';
import { Card, Button, MenuItem } from '@mui/material';

import { Dropdown, ConfirmableButton, EventBridgeBusesTable, PageTitle } from '@localstack/ui';

import { DEFAULT_EVENTBRIDGE_ROUTES } from '@localstack/constants/src';

import { MainNavTabs } from './components';
import { EventBridgeProps } from './types';

export const EventBridgeBuses = ({
  Layout,
  clientOverrides,
  routes = DEFAULT_EVENTBRIDGE_ROUTES,
}: EventBridgeProps): ReactElement => {
  const [selectedIds, setSelectedIds] = useState<string[]>([]);

  const { goto } = useRoutes();

  const { data: buses, isLoading, mutate } = useAwsGetter('EventBridge', 'listEventBuses', [], { clientOverrides });

  const { deleteEventBus } = useAwsEffect('EventBridge', ['deleteEventBus'], {
    revalidate: ['listEventBuses', 'describeEventBus'],
    clientOverrides,
  });

  const handleDeleteSelected = useCallback(async () => {
    const promises = selectedIds.map((Name) => deleteEventBus({ Name }));
    await Promise.all(promises);
  }, [selectedIds]);

  return (
    <Layout
      documentTitle="EventBridge: Buses"
      title={
        <PageTitle
          title="EventBridge Buses"
          onMutate={mutate}
          breadcrumbs={[
            ['EventBridge', () => goto(routes.RESOURCES_EVENT_BRIDGE)],
            ['Buses', null],
          ]}
        />
      }
      tabs={<MainNavTabs routes={routes} />}
      actions={
        <>
          <Button onClick={() => goto(routes.RESOURCES_EVENT_BRIDGE_BUS_CREATE)}>Create Bus</Button>
          <Dropdown label="Actions">
            <MenuItem
              disabled={selectedIds.length !== 1}
              onClick={() => goto(routes.RESOURCES_EVENT_BRIDGE_BUS_TRIGGER, { busName: selectedIds[0] })}
            >
              Trigger Event
            </MenuItem>
            <ConfirmableButton
              componentType="MenuItem"
              disabled={selectedIds.length === 0 || isLoading}
              title={`Delete ${selectedIds.length} Event Bus(es)?`}
              onClick={handleDeleteSelected}
              text="Selected Event Buses will be permanently deleted"
            >
              Remove Selected
            </ConfirmableButton>
          </Dropdown>
        </>
      }
    >
      <Card>
        <EventBridgeBusesTable
          buses={buses?.EventBuses ?? []}
          loading={isLoading}
          onSelect={setSelectedIds}
          onViewBus={({ Name }) => goto(routes.RESOURCES_EVENT_BRIDGE_BUS, { busName: Name })}
        />
      </Card>
    </Layout>
  );
};
