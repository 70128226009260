import { ReactElement, useEffect, useState } from 'react';
import { Grid, Button, IconButton } from '@mui/material';
import {
  useAwsGetter,
  VALIDATION_RULES,
  expandS3Folders,
  transformMagicFieldName,
  AwsClientOverrides,
} from '@localstack/services';
import { Control, useFormContext } from 'react-hook-form';
import RefreshIcon from '@mui/icons-material/Refresh';

import ControlledTextField from '../ControlledTextField';
import { ContentModal } from '../../feedback';
import { S3BucketsTable } from '../../core/resource/s3/S3BucketsTable';
import { S3ObjectsTable } from '../../core/resource/s3/S3ObjectsTable';
import { S3ObjectBreadcrumbs } from '../../core/resource/s3/S3ObjectBreadcrumbs';

export const S3PathPicker = ({
  control,
  required,
  fieldName,
  bucketName,
  pathPrefix,
  clientOverrides,
}: {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  control: Control<any>;
  required?: Optional<boolean>;
  fieldName: string;
  bucketName?: string;
  pathPrefix?: string;
  clientOverrides?: AwsClientOverrides;
}): ReactElement => {
  const { setValue } = useFormContext();
  const [modalOpen, setModalOpen] = useState(false);
  const [selectedPath, setSelectedPath] = useState<string[]>([]);
  const [bucket, setBucket] = useState<string>(bucketName || '');
  const [prefix, setPrefix] = useState<string>(pathPrefix || '');
  const { data: buckets, mutate: mutateBuckets } = useAwsGetter('S3', 'listBuckets', [], {
    clientOverrides: { ...clientOverrides, s3ForcePathStyle: true },
  });

  const { data: rawObjects, mutate: mutateObjects } = useAwsGetter(
    'S3',
    'listObjectsV2',
    [bucket ? { Bucket: bucket, Prefix: prefix ? `${prefix}/` : prefix, MaxKeys: 1000 } : {}],
    { clientOverrides: { ...clientOverrides, s3ForcePathStyle: true } },
  );

  const objects = expandS3Folders(rawObjects?.Contents ?? [], prefix);

  const confirmActionHandler = () => {
    if (selectedPath?.[0]) {
      const bucketLink = `s3://${bucket ? `${bucket}/` : ''}${selectedPath}`;
      setValue(fieldName, bucketLink);
    }
    setModalOpen(false);
  };

  useEffect(() => {
    // If modal is closing, reset the modal
    if (!modalOpen) {
      setBucket('');
      setPrefix('');
      setSelectedPath([]);
    }
  }, [modalOpen]);

  const mutateCallback = !bucket ? mutateBuckets : mutateObjects;

  return (
    <Grid container spacing={3}>
      <Grid item style={{ flexGrow: 1 }}>
        <ControlledTextField
          control={control}
          required={!!required}
          fullWidth
          label={transformMagicFieldName(fieldName.split('.').pop())}
          name={fieldName}
          variant="outlined"
          rules={required ? VALIDATION_RULES.required : undefined}
        />
      </Grid>
      <Grid item>
        <Button variant="outlined" color="primary" style={{ marginTop: '8px' }} onClick={() => setModalOpen(true)}>
          Browse S3
        </Button>
      </Grid>
      <ContentModal
        title="Choose S3 Path"
        confirmText="Choose S3 Path"
        titleActions={
          <IconButton onClick={mutateCallback} size="large">
            <RefreshIcon />
          </IconButton>
        }
        maxWidth="md"
        open={modalOpen}
        confirmDisabled={!selectedPath.length || selectedPath.length > 1}
        fullWidth
        onClose={() => setModalOpen(false)}
        onConfirm={confirmActionHandler}
      >
        {!bucket && (
          <S3BucketsTable
            buckets={buckets?.Buckets ?? []}
            onViewBucket={(b) => setBucket(b)}
            onSelect={setSelectedPath}
            selectable
          />
        )}
        {bucket && (
          <>
            <S3ObjectBreadcrumbs
              bucket={bucket as string}
              prefix={prefix}
              onViewRoot={() => {
                setBucket('');
                setPrefix('');
              }}
              onViewBucket={(b, p) => {
                setBucket(b);
                setPrefix(p || '');
              }}
              showCopyButton={false}
            />
            <S3ObjectsTable
              selectable
              objects={objects}
              prefix={prefix}
              onSelect={setSelectedPath}
              onViewObject={(p) => setPrefix(p)}
            />
          </>
        )}
      </ContentModal>
    </Grid>
  );
};
