import { ReactElement, useState, useCallback } from 'react';
import { useRoutes, useAwsEffect, useAwsGetter } from '@localstack/services';
import { Card, Button } from '@mui/material';
import { DEFAULT_API_GATEWAY_ROUTES } from '@localstack/constants';
import { useParams } from 'react-router-dom';

import { PageTitle, Dropdown, ConfirmableButton, AgwRestApiModelsTable } from '@localstack/ui';

import { ApiGatewayProps } from './types';
import { RestApiNavTabs } from './components';

export const AgwRestApiModels = ({
  Layout,
  clientOverrides,
  routes = DEFAULT_API_GATEWAY_ROUTES,
}: ApiGatewayProps): ReactElement => {
  const { restApiId } = useParams<'restApiId'>();
  const [selectedIds, setSelectedIds] = useState<string[]>([]);

  const { goto } = useRoutes();
  const { data: api } = useAwsGetter('APIGateway', 'getRestApi', [{ restApiId }], { clientOverrides });

  const {
    data: models,
    isLoading,
    mutate,
  } = useAwsGetter('APIGateway', 'getModels', [{ restApiId }], { clientOverrides });

  const { deleteModel } = useAwsEffect('APIGateway', ['deleteModel'], {
    revalidate: ['getModel', 'getModels'],
    clientOverrides,
  });

  const handleDeleteSelected = useCallback(async () => {
    const promises = selectedIds.map((modelName) => deleteModel({ restApiId: restApiId as string, modelName }));
    await Promise.all(promises);
  }, [selectedIds]);

  return (
    <Layout
      documentTitle="API Models"
      title={
        <PageTitle
          title="API Models"
          onMutate={mutate}
          breadcrumbs={[
            ['API Gateway', () => goto(routes.RESOURCES_AGW1)],
            [api?.name, () => goto(routes.RESOURCES_AGW1_API, { restApiId })],
            ['Models', null],
          ]}
        />
      }
      tabs={<RestApiNavTabs restApiId={restApiId as string} routes={routes} />}
      actions={
        <>
          <Button onClick={() => goto(routes.RESOURCES_AGW1_API_MODEL_CREATE, { restApiId })}>Create Model</Button>
          <Dropdown label="Actions">
            <ConfirmableButton
              componentType="MenuItem"
              disabled={selectedIds.length === 0 || isLoading}
              title={`Delete ${selectedIds.length} Model(s)?`}
              onClick={handleDeleteSelected}
              text="Selected Models will be permanently deleted"
            >
              Remove Selected
            </ConfirmableButton>
          </Dropdown>
        </>
      }
    >
      <Card>
        <AgwRestApiModelsTable
          models={models?.items || []}
          onSelect={setSelectedIds}
          onViewModel={({ name }) => goto(routes.RESOURCES_AGW1_API_MODEL, { restApiId, modelName: name })}
        />
      </Card>
    </Layout>
  );
};
