import { ReactElement } from 'react';
import { useRoutes, useAwsEffect, useRegion } from '@localstack/services';
import { Card, CardContent, CardActions, Box, Typography } from '@mui/material';
import { CreateS3BucketRequest } from '@localstack/types';
import { ProgressButton, S3BucketCreateForm, Breadcrumbs } from '@localstack/ui';
import { DEFAULT_S3_ROUTES } from '@localstack/constants';

import { S3Props } from './types';

export const S3BucketCreate = ({ Layout, clientOverrides, routes = DEFAULT_S3_ROUTES }: S3Props): ReactElement => {
  const { goto } = useRoutes();
  const { region: globalRegion } = useRegion();

  const { createBucket, isLoading } = useAwsEffect('S3', ['createBucket'], {
    revalidate: ['listBuckets'],
    clientOverrides: { s3ForcePathStyle: true, ...clientOverrides },
  });

  const handleCreate = async (data: CreateS3BucketRequest) => {
    await createBucket(data);
    goto(routes.RESOURCES_S3);
  };

  return (
    <Layout
      documentTitle="Bucket Details"
      title={
        <Box>
          <Typography variant="h4">Bucket Details</Typography>
          <Breadcrumbs
            mappings={[
              ['Buckets', () => goto(routes.RESOURCES_S3)],
              ['Create Bucket', null],
            ]}
          />
        </Box>
      }
      actions={
        <ProgressButton type="submit" form="CreateBucket" variant="outlined" color="primary" loading={isLoading}>
          Submit
        </ProgressButton>
      }
    >
      <Card>
        <CardContent>
          <S3BucketCreateForm onCreate={handleCreate} loading={isLoading} formId="CreateBucket" region={globalRegion} />
        </CardContent>
        <CardActions>
          <ProgressButton type="submit" form="CreateBucket" variant="contained" color="primary" loading={isLoading}>
            Submit
          </ProgressButton>
        </CardActions>
      </Card>
    </Layout>
  );
};
