import { User } from '@localstack/types';
import { ReactElement } from 'react';

import { MARKER_IDS, TestMarkerSpan, formatDateTimeTimezone } from '@localstack/services';
import { Box, Card, CardContent, CardHeader, Typography } from '@mui/material';
import { AssignmentTurnedIn as TosAcceptedIcon, Error as TosNotAcceptanceMissingIcon } from '@mui/icons-material';

export interface TosAcceptanceLogProps {
  user: User;
}

export const TosAcceptanceLog = ({ user }: TosAcceptanceLogProps): ReactElement => (
  <Card>
    <CardHeader title="Terms and Conditions Acceptance Log" />
    <CardContent>
      {user.tos_acceptance_log ? (
        <>
          <Typography variant="body1">
            <Box style={{ display: 'flex', columnGap: '0.5em' }}>
              <TosAcceptedIcon /> Terms and Conditions accepted.
            </Box>
          </Typography>

          <Box mt={2}>
            <Typography variant="body1">
              <b>Time Accepted:</b> {formatDateTimeTimezone(user.tos_acceptance_log.timestamp_utc)}
              <br />
              <b>Context:</b> {user.tos_acceptance_log.acceptance_context}
              <br />
              <b>Client IP Address:</b>{' '}
              <TestMarkerSpan name={MARKER_IDS.ADMIN_TOS_IP_ADDRESS}>
                {user.tos_acceptance_log.client_ip}
              </TestMarkerSpan>
              <br />
              <b>Client User Agent:</b>{' '}
              <span style={{ fontFamily: 'monospace' }}>{user.tos_acceptance_log.user_agent}</span>
            </Typography>
          </Box>
        </>
      ) : (
        <Typography variant="body1">
          <Box style={{ display: 'flex', columnGap: '0.5em' }}>
            <TosNotAcceptanceMissingIcon /> No Terms and Conditions acceptance documented yet.
          </Box>
        </Typography>
      )}
    </CardContent>
  </Card>
);
