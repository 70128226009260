import { useState, ReactElement } from 'react';
import { PikaButton } from '@localstack/ui';
import { UserHealthReportItem, UserHealthFixReport } from '@localstack/types';
import {
  Alert,
  Card,
  CardHeader,
  CardContent,
  CardActions,
  List,
  ListItem,
  ListItemText,
  ListItemAvatar,
  LinearProgress,
  Checkbox,
  Typography,
} from '@mui/material';
import { Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import classnames from 'classnames';

const ISSUE_TO_HUMAN_MAP = {
  [UserHealthReportItem.NOT_FOUND]: 'User does not seem to exist',
  [UserHealthReportItem.MISSING_AUTH_LINK]: 'User has no auth link',
};

const useStyles = makeStyles((theme: Theme) => ({
  error: {
    color: theme.palette.error.main,
  },
  success: {
    color: theme.palette.success.main,
  },
}));

export interface AccountHealthProps {
  issues?: Optional<UserHealthReportItem[]>;
  issuesLoading?: boolean;
  fixReport?: Optional<UserHealthFixReport>;
  reportLoading?: boolean;
  onRunAccountCheck: () => unknown;
  onRunAccountFix: (issues: UserHealthReportItem[]) => unknown;
}

export const AccountHealth = ({
  issues,
  issuesLoading,
  fixReport,
  reportLoading,
  onRunAccountCheck,
  onRunAccountFix,
}: AccountHealthProps): ReactElement => {
  const classes = useStyles();

  const [selectedIssues, setSelectedIssues] = useState<UserHealthReportItem[]>([]);

  const isLoading = issuesLoading || reportLoading;

  return (
    <Card>
      <CardHeader title="Account Health" />
      <CardContent>
        {issuesLoading && <LinearProgress />}
        {issues && issues.length === 0 && <Alert severity="success">No known issues detected</Alert>}
        {!issues && !issuesLoading && (
          <Alert severity="info">Run the health check to see if we could spot any issues</Alert>
        )}
        {issues && issues.length > 0 && (
          <Typography>The following issues have been detected, please check the ones you want to fix:</Typography>
        )}
        {issues && (
          <List>
            {issues.map((issue) => (
              <ListItem key={issue}>
                <ListItemAvatar>
                  <Checkbox
                    color="primary"
                    checked={selectedIssues.includes(issue)}
                    onChange={(_, checked) => {
                      setSelectedIssues(
                        checked ? [...selectedIssues, issue] : selectedIssues.filter((i) => i !== issue),
                      );
                    }}
                  />
                </ListItemAvatar>
                <ListItemText
                  primary={ISSUE_TO_HUMAN_MAP[issue]}
                  primaryTypographyProps={{
                    className: classnames({
                      [classes.error]: fixReport?.failed?.includes(issue),
                      [classes.success]: fixReport?.succeeded?.includes(issue),
                    }),
                  }}
                />
              </ListItem>
            ))}
          </List>
        )}
      </CardContent>
      <CardActions>
        <PikaButton
          loading={issuesLoading}
          disabled={isLoading}
          onClick={onRunAccountCheck}
          color={selectedIssues.length ? 'inherit' : 'primary'}
        >
          Check Account Health
        </PikaButton>
        {selectedIssues.length > 0 && (
          <PikaButton
            loading={reportLoading}
            disabled={isLoading}
            onClick={() => onRunAccountFix(selectedIssues)}
            color={selectedIssues.length ? 'primary' : 'inherit'}
          >
            Fix selected issues
          </PikaButton>
        )}
      </CardActions>
    </Card>
  );
};
