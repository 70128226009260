import { ReactElement } from 'react';
import { Card, CardContent, CardActions, Box, Typography } from '@mui/material';
import { EventBridgeCreatePipeRequest } from '@localstack/types';
import { useRoutes, useAwsEffect, useAwsAccountId, useRegion } from '@localstack/services';
import { ProgressButton, EventBridgePipeForm, Breadcrumbs } from '@localstack/ui';

import { DEFAULT_EVENTBRIDGE_ROUTES } from '@localstack/constants/src';

import { EventBridgeProps } from './types';

export const EventBridgePipeCreate = ({
  Layout,
  clientOverrides,
  routes = DEFAULT_EVENTBRIDGE_ROUTES,
}: EventBridgeProps): ReactElement => {
  const { goto } = useRoutes();
  const { awsAccountId } = useAwsAccountId();
  const { region } = useRegion();

  const { createPipe, isLoading } = useAwsEffect('Pipes', ['createPipe'], {
    revalidate: ['listPipes'],
    clientOverrides,
  });

  const handleCreate = async (data: EventBridgeCreatePipeRequest) => {
    if (await createPipe(data)) {
      goto(routes.RESOURCES_EVENT_BRIDGE_PIPES);
    }
  };

  return (
    <Layout
      documentTitle="EventBridge: Create Pipe"
      title={
        <Box>
          <Typography variant="h4">Create Pipe</Typography>
          <Breadcrumbs
            mappings={[
              ['EventBridge', () => goto(routes.RESOURCES_EVENT_BRIDGE)],
              ['Pipes', () => goto(routes.RESOURCES_EVENT_BRIDGE_PIPES)],
              ['Create Pipe', null],
            ]}
          />
        </Box>
      }
      actions={
        <ProgressButton type="submit" form="update" variant="outlined" color="primary" loading={isLoading}>
          Submit
        </ProgressButton>
      }
    >
      <Card>
        <CardContent>
          <EventBridgePipeForm
            onCreate={handleCreate}
            loading={isLoading}
            formId="update"
            clientOverrides={clientOverrides}
            awsAccountId={awsAccountId}
            region={region}
          />
        </CardContent>
        <CardActions>
          <ProgressButton type="submit" form="update" variant="contained" color="primary" loading={isLoading}>
            Submit
          </ProgressButton>
        </CardActions>
      </Card>
    </Layout>
  );
};
