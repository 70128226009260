import React, { ReactElement } from 'react';
import { StateMachineExecutionEventDetails, StateMachineExecutionGroupedEvent } from '@localstack/types';
import { Box, Tab, Tabs } from '@mui/material';
import capitalize from 'lodash/capitalize';

import { CodeSnippetViewer, TabPanel } from '../../../display';
import { MagicDetails } from '../../magic/MagicDetails';

import { StateMachineExecutionEventsTable } from './StateMachineExecutionEventsTable';

export interface StateExecutionGroupedEventDetailsProps {
  event?: Optional<Partial<StateMachineExecutionGroupedEvent>>;
  activeTab: string;
  setTabs: (activeTab: string) => void;
  setSelectedExecutionEvent: (object: StateMachineExecutionEventDetails | null) => void;
}

const SCHEMA = {
  shapes: {
    GroupedEventDetails: {
      type: 'structure',
      members: {
        Status: {
          type: 'string',
        },
        Type: {
          type: 'string',
        },
        StartedAfter: {
          type: 'string',
        },
        Duration: {
          type: 'string',
        },
        Resource: {
          type: 'string',
        },
      },
    },
  },
};

// eslint-disable-next-line react/display-name
export const StateExecutionGroupedEventDetails = React.memo(
  ({ event, activeTab, setTabs, setSelectedExecutionEvent }: StateExecutionGroupedEventDetailsProps): ReactElement => (
    <>
      <Tabs
        value={activeTab}
        indicatorColor="primary"
        textColor="primary"
        onChange={(_: unknown, newValue: string) => {
          setTabs(newValue);
        }}
      >
        <Tab label="Input" value="input" />
        <Tab label="Output" value="output" />
        <Tab label="Details" value="details" />
        <Tab label="Definition" value="definition" />
        <Tab label="Events" value="events" />
      </Tabs>
      <Box p={1}>
        <TabPanel index="input" tab={activeTab}>
          <CodeSnippetViewer data={event?.Input || ''} />
        </TabPanel>
        <TabPanel index="output" tab={activeTab}>
          <CodeSnippetViewer data={event?.Output || ''} />
        </TabPanel>
        <TabPanel index="details" tab={activeTab}>
          <MagicDetails
            data={{
              Status: event?.Status,
              Type: event?.Type,
              StartedAfter: event?.StartedAfter,
              Duration: event?.Duration,
              Resource: capitalize(event?.Resource),
            }}
            schema={SCHEMA}
            entry="GroupedEventDetails"
            title="Event Details"
          />
        </TabPanel>
        <TabPanel index="definition" tab={activeTab}>
          <CodeSnippetViewer data={JSON.stringify(event?.Definition || {})} />
        </TabPanel>
        <TabPanel index="events" tab={activeTab}>
          <StateMachineExecutionEventsTable
            events={event?.Data ?? []}
            setSelectedExecutionEvent={setSelectedExecutionEvent}
            minimalTable
          />
        </TabPanel>
      </Box>
    </>
  ),
);
