import { IconButton } from '@mui/material';

import { Close } from '@mui/icons-material';

import { useNavigation } from '@localstack/ui/src/provider/NavbarContext';

export const NavbarCloseButton = (): JSX.Element | null => {
  const { isNavOpen, closeNav, isMobile } = useNavigation();

  if (!isMobile || !isNavOpen) {
    return null;
  }

  return (
    <IconButton onClick={closeNav} size="small" sx={{ alignSelf: 'center' }} aria-label="Close navigation">
      <Close fontSize="small" />
    </IconButton>
  );
};
