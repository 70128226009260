import { EXTENSION_ADMIN_ORDER } from '@localstack/constants';
import { useApiGetter, ExtensionService, capitalise, useRoutes } from '@localstack/services';
import { Extension, ExtensionSubmissionState } from '@localstack/types';
import { LoadingFragment } from '@localstack/ui';
import { Box, Button, Grid, Theme, Typography, Alert } from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';

import { Fragment, ReactElement, useMemo } from 'react';

import { AppRoute } from '~/config';

import { AdminLayout } from '~/layouts';
import { gridItemBreakpoints, ExtensionCard } from '~/views/customer/Extensions/components/ExtensionCard';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    fragment: {
      margin: theme.spacing(1, 0),
    },
  }),
);

export const AdminExtensions = (): ReactElement => {
  const {
    data: extensions,
    isLoading: isLoadingExtensions,
    hasError: hasExtensionsError,
  } = useApiGetter(ExtensionService, 'listExtensions', [], { defaultValue: [], suppressErrors: true });

  const classes = useStyles();
  const { goto } = useRoutes();

  const extensionsAdminInstallable = useMemo(
    () => extensions?.map((extension) => ({ ...extension, published: true }) as Extension),
    [extensions],
  );

  const extensionsSubmissionStatusMap = useMemo(
    () =>
      extensionsAdminInstallable?.reduce(
        (memo, extension) => {
          const submissionState = extension.submission_state ?? ExtensionSubmissionState.PUBLISHED;

          return {
            ...memo,
            [submissionState]: [...(memo[submissionState] || []), extension],
          };
        },
        {} as Record<ExtensionSubmissionState, Extension[]>,
      ) ?? ({} as Record<ExtensionSubmissionState, Extension[]>),
    [extensionsAdminInstallable],
  );

  const handleCardNavigation = (id: string) => {
    goto(AppRoute.ADMIN_EXTENSION, { id });
  };

  return (
    <AdminLayout title="Manage Extensions">
      <Grid container spacing={2}>
        {hasExtensionsError && (
          <Grid item xs={12}>
            <Alert severity="warning" style={{ width: '100%' }}>
              An error occurred while fetching extensions.
            </Alert>
          </Grid>
        )}
        <LoadingFragment
          loading={isLoadingExtensions}
          variant="grid"
          size={9}
          gridItemBreakpoints={gridItemBreakpoints()}
          height={170}
        >
          {EXTENSION_ADMIN_ORDER.map((submissionState) => (
            <Fragment key={submissionState}>
              {extensionsSubmissionStatusMap[submissionState] && (
                <Box width="100%">
                  <Typography variant="h4" className={classes.fragment}>
                    {capitalise(submissionState)}
                  </Typography>
                  <Grid container spacing={2}>
                    {extensionsSubmissionStatusMap[submissionState]?.map((extension) => (
                      <ExtensionCard
                        key={extension.id}
                        extension={extension}
                        secondaryAction={
                          <Button onClick={() => handleCardNavigation(extension.id)} variant="outlined">
                            View Details
                          </Button>
                        }
                      />
                    ))}
                  </Grid>
                </Box>
              )}
            </Fragment>
          ))}
        </LoadingFragment>
      </Grid>
    </AdminLayout>
  );
};
