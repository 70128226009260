import { ReactElement } from 'react';
import ReactConfetti, { Props as ReactConfettiProps } from 'react-confetti';
import makeStyles from '@mui/styles/makeStyles';
import { useWindowSize } from 'react-use';

const useStyles = makeStyles(() => ({
  confettiParent: {
    height: '100vh',
    width: '100vw',
    position: 'fixed',
    top: 0,
    left: 0,
    pointerEvents: 'none',
  },
}));

export const Confetti = (props: ReactConfettiProps): ReactElement => {
  const classes = useStyles();
  const { width, height } = useWindowSize();
  return (
    <div className={classes.confettiParent}>
      <ReactConfetti width={width} height={height} recycle={false} {...props} />
    </div>
  );
};
