import { ReactElement } from 'react';
import { Link } from '@mui/material';
import { CloudTrail } from '@localstack/types';

import { MagicTable } from '../../../magic/MagicTable';

export type CloudTrailTableProps = {
  selectable?: boolean;
  loading?: boolean;
  entries: CloudTrail[];
  onViewTrails?: (trail: CloudTrail) => unknown;
  onSelect?: (names: string[]) => void;
};

const SCHEMA = {
  shapes: {
    CloudTrailTableModified: {
      type: 'structure',
      members: {
        Name: { type: 'string' },
        TrailARN: { type: 'string' },
        IsLogging: { type: 'boolean' },
      },
    },
  },
};

export const CloudTrailTable = ({ entries, loading, onViewTrails }: CloudTrailTableProps): ReactElement => (
  <MagicTable
    schema={SCHEMA}
    entry="CloudTrailTableModified"
    idAttribute="TrailARN"
    rows={entries}
    order={['Name', 'IsLogging', 'TrailARN']} // filterColumns={['Name', 'IsLogging']}
    loading={loading}
    externalFields={{
      Name: (row) => (
        <Link onClick={() => onViewTrails && onViewTrails(row)} underline="hover">
          {row.Name}
        </Link>
      ),
    }}
  />
);
