import { ReactElement } from 'react';
import { ElastiCacheCluster } from '@localstack/types';
import { getSchema } from '@localstack/services';

import { MagicDetails } from '../../magic/MagicDetails';

export interface ElastiCacheClusterDetailsProps {
  cluster?: Optional<Partial<ElastiCacheCluster>>;
}

export const ElastiCacheClusterDetails = ({ cluster }: ElastiCacheClusterDetailsProps): ReactElement => (
  <MagicDetails data={cluster} schema={getSchema('ElastiCache')} entry="CacheCluster" title="Cluster Details" />
);
