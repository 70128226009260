import { ReactElement } from 'react';
import { useRoutes, useAwsGetter } from '@localstack/services';
import { useParams } from 'react-router-dom';
import { Card, CardContent, Box, Typography } from '@mui/material';
import { Breadcrumbs, NeptuneClusterDetails } from '@localstack/ui';

import { DEFAULT_NEPTUNE_ROUTES } from '@localstack/constants';

import { NeptuneProps } from './types';
import { NavTabs } from './components/NavTabs';

export const NeptuneDBCluster = ({
  Layout,
  clientOverrides,
  routes = DEFAULT_NEPTUNE_ROUTES,
}: NeptuneProps): ReactElement => {
  const { goto } = useRoutes();
  const { clusterId } = useParams<'clusterId'>();

  const { data: clusters } = useAwsGetter('Neptune', 'describeDBClusters', [{ DBClusterIdentifier: clusterId }], {
    clientOverrides,
  });
  const cluster = clusters?.DBClusters?.find((inst) => inst.DBClusterIdentifier === clusterId);

  return (
    <Layout
      documentTitle="Cluster Details"
      tabs={<NavTabs routes={routes} />}
      title={
        <Box>
          <Typography variant="h4">Cluster Details</Typography>
          <Breadcrumbs
            mappings={[
              ['Neptune', () => goto(routes.RESOURCES_NEPTUNE_CLUSTERS)],
              [`${clusterId} (${cluster?.DBClusterIdentifier})`, null],
            ]}
          />
        </Box>
      }
    >
      <Card>
        <CardContent>
          <NeptuneClusterDetails cluster={cluster} />
        </CardContent>
      </Card>
    </Layout>
  );
};
