import { ReactElement } from 'react';
import { useRoutes, useAwsGetter } from '@localstack/services';
import { useParams } from 'react-router-dom';
import { Card, CardContent, Button, Box, Typography } from '@mui/material';
import { Breadcrumbs, CognitoUserDetails } from '@localstack/ui';

import { DEFAULT_COGNITO_ROUTES } from '@localstack/constants';

import { CognitoProps } from './types';

import { UserNavTabs } from './components';

export const CognitoPoolUser = ({
  Layout,
  clientOverrides,
  routes = DEFAULT_COGNITO_ROUTES,
}: CognitoProps): ReactElement => {
  const { goto } = useRoutes();
  const { poolId, username } = useParams<'poolId' | 'username'>();

  const { data: pool } = useAwsGetter('CognitoIdentityServiceProvider', 'describeUserPool', [{ UserPoolId: poolId }], {
    clientOverrides,
  });

  const { data: user } = useAwsGetter(
    'CognitoIdentityServiceProvider',
    'adminGetUser',
    [{ UserPoolId: poolId, Username: username }],
    { clientOverrides },
  );

  return (
    <Layout
      documentTitle="User Details"
      tabs={<UserNavTabs poolId={poolId as string} username={username as string} routes={routes} />}
      title={
        <Box>
          <Typography variant="h4">User Details</Typography>
          <Breadcrumbs
            mappings={[
              ['Cognito', () => goto(routes.RESOURCES_COGNITO)],
              [pool?.UserPool?.Name ?? poolId, () => goto(routes.RESOURCES_COGNITO_USER_POOL, { poolId })],
              ['Users', () => goto(routes.RESOURCES_COGNITO_POOL_USERS, { poolId })],
              [username, null],
            ]}
          />
        </Box>
      }
      actions={
        <>
          <Button onClick={() => goto(routes.RESOURCES_COGNITO_POOL_USER_UPDATE, { poolId, username })}>
            Edit User
          </Button>
        </>
      }
    >
      <Card>
        <CardContent>
          <CognitoUserDetails user={user} />
        </CardContent>
      </Card>
    </Layout>
  );
};
