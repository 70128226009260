import { useAwsEffect, useRoutes } from '@localstack/services';
import { useParams } from 'react-router-dom';
import { Card, CardContent, CardActions, Box, Typography } from '@mui/material';
import { CreateTimestreamTableRequest } from '@localstack/types';
import { ProgressButton, Breadcrumbs, TimestreamTableForm } from '@localstack/ui';
import { DEFAULT_TIMESTREAMDB_ROUTES } from '@localstack/constants/src';
import { ReactElement } from 'react';

import { TimestreamDBProps } from './types';

export const TimestreamTableCreate = ({
  Layout,
  clientOverrides,
  routes = DEFAULT_TIMESTREAMDB_ROUTES,
}: TimestreamDBProps): ReactElement => {
  const { goto } = useRoutes();
  const { database } = useParams<'database'>();

  const { createTable, isLoading } = useAwsEffect('TimestreamWrite', ['createTable'], {
    revalidate: ['listTables'],
    clientOverrides,
  });

  const handleCreate = async (data: CreateTimestreamTableRequest) => {
    if (await createTable(data)) {
      goto(routes.RESOURCES_TIMESTREAM_TABLE, { database, table: data.TableName });
    }
    goto(routes.RESOURCES_TIMESTREAM_DATABASE, { database });
  };

  return (
    <Layout
      documentTitle="Table Details"
      title={
        <Box>
          <Typography variant="h4">Table Details</Typography>
          <Breadcrumbs
            mappings={[
              ['Timestream', () => goto(routes.RESOURCES_TIMESTREAM)],
              [database, () => goto(routes.RESOURCES_TIMESTREAM_DATABASE, { database })],
              ['Create Table', null],
            ]}
          />
        </Box>
      }
      actions={
        <ProgressButton type="submit" form="CreateTable" variant="outlined" color="primary" loading={isLoading}>
          Submit
        </ProgressButton>
      }
    >
      <Card>
        <CardContent>
          <TimestreamTableForm
            databaseName={database as string}
            loading={isLoading}
            formId="CreateTable"
            clientOverrides={clientOverrides}
            onSubmit={handleCreate}
          />
        </CardContent>
        <CardActions>
          <ProgressButton type="submit" form="CreateTable" variant="contained" color="primary" loading={isLoading}>
            Submit
          </ProgressButton>
        </CardActions>
      </Card>
    </Layout>
  );
};
