import { ReactElement } from 'react';
import { useForm } from 'react-hook-form';
import { Grid, Typography } from '@mui/material';
import { AwsClientOverrides, buildRoute, VALIDATION_RULES } from '@localstack/services';

import { DEFAULT_APPSYNC_ROUTES } from '@localstack/constants';

import { ControlledTextField, ControlledCodeEditor, RelatedResourcePicker } from '../../../../form';

export interface AppSyncQueryFormData {
  query: string;
  variables: string;
  operation: string;
  apiKey: string;
}

export interface AppSyncQueryFormProps {
  apiId?: string;
  formId?: string;
  clientOverrides?: AwsClientOverrides;
  onSubmit: (data: AppSyncQueryFormData) => unknown;
}

export const AppSyncQueryForm = ({ apiId, clientOverrides, formId, onSubmit }: AppSyncQueryFormProps): ReactElement => {
  const { control, handleSubmit } = useForm<AppSyncQueryFormData>({ mode: 'all' });

  return (
    <form id={formId} onSubmit={handleSubmit(onSubmit)}>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <ControlledTextField
            control={control}
            name="operation"
            label="Operation"
            required
            fullWidth
            variant="outlined"
            rules={VALIDATION_RULES.required}
          />
        </Grid>
        <Grid item xs={12}>
          <RelatedResourcePicker
            control={control}
            client="AppSync"
            method="listApiKeys"
            arrayKeyName="apiKeys"
            property="id"
            args={[{ apiId }]}
            clientOverrides={clientOverrides}
            creationRoute={buildRoute(DEFAULT_APPSYNC_ROUTES.RESOURCES_APPSYNC_API_KEY_CREATE, { apiId })}
            fieldName="apiKey"
            entityName="AppSync API Key"
          />
        </Grid>
        <Grid item xs={12}>
          <Typography>Query</Typography>
          <ControlledCodeEditor
            control={control}
            name="query"
            language="graphql"
            required
            rules={VALIDATION_RULES.required}
          />
        </Grid>
        <Grid item xs={12}>
          <Typography>Variables</Typography>
          <ControlledCodeEditor control={control} name="variables" language="graphql" />
        </Grid>
      </Grid>
    </form>
  );
};
