import { AwsClientOverrides, getSchema } from '@localstack/services';
import { GetEnvironmentOutput, CreateEnvironmentInput, UpdateEnvironmentInput } from '@localstack/types';
import { ReactElement } from 'react';
import { DEFAULT_IAM_ROUTES, DEFAULT_S3_ROUTES } from '@localstack/constants';

import { RelatedResourcePicker } from '../../../form/RelatedResourcePicker';

import { MagicForm } from '../../magic/MagicForm';

export type MWAAEnvironmentFormProps = {
  environment?: Optional<Partial<GetEnvironmentOutput>>;
  formId: string;
  onSubmit: (data: CreateEnvironmentInput | UpdateEnvironmentInput) => void;
  loading?: boolean;
  clientOverrides: AwsClientOverrides | undefined;
};

export const MWAAEnvironmentForm = ({
  environment,
  formId,
  onSubmit,
  loading,
  clientOverrides,
}: MWAAEnvironmentFormProps): ReactElement => (
  <MagicForm
    entry={environment ? 'UpdateEnvironmentInput' : 'CreateEnvironmentInput'}
    schema={getSchema('MWAA')}
    loading={loading}
    onSubmit={onSubmit}
    formId={formId}
    data={environment?.Environment}
    fieldConditions={{ Name: !environment, LoggingConfiguration: false }}
    externalFields={{
      SourceBucketArn: (control, fieldName, required) => (
        <RelatedResourcePicker
          control={control}
          fieldName={fieldName}
          client="S3"
          entityName="S3 Bucket"
          arrayKeyName="Buckets"
          method="listBuckets"
          property="Name"
          creationRoute={DEFAULT_S3_ROUTES.RESOURCES_S3_BUCKET_CREATE}
          required={required}
          clientOverrides={clientOverrides}
        />
      ),
      ExecutionRoleArn: (control, fieldName, required) => (
        <RelatedResourcePicker
          control={control}
          client="IAM"
          method="listRoles"
          arrayKeyName="Roles"
          property="Arn"
          fieldName={fieldName}
          entityName="IAM Role"
          creationRoute={DEFAULT_IAM_ROUTES.RESOURCES_IAM_ROLE_CREATE}
          required={required}
          clientOverrides={clientOverrides}
        />
      ),
    }}
  />
);
