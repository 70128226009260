import React, { ReactElement } from 'react';
import { StateMachineExecutionGroupedEvent } from '@localstack/types';

import { MagicTable } from '../../magic/MagicTable';

const SCHEMA = {
  shapes: {
    GroupedEvents: {
      type: 'structure',
      members: {
        Name: {
          type: 'string',
        },
        Type: {
          type: 'string',
        },
        Status: {
          type: 'string',
        },
        Duration: {
          type: 'string',
        },
        StartedAfter: {
          type: 'string',
        },
      },
    },
  },
};

export interface StateMachineExecutionGroupedEventsTableProps {
  events: StateMachineExecutionGroupedEvent[];
  loading: boolean;
  selectedItems: string[];
  selectable?: boolean;
  onSelect: (item: string[]) => void;
}

// eslint-disable-next-line react/display-name
export const StateMachineExecutionGroupedEventsTable = React.memo(
  ({
    events,
    loading,
    selectedItems,
    onSelect,
    selectable = true,
  }: StateMachineExecutionGroupedEventsTableProps): ReactElement => (
    <MagicTable
      schema={SCHEMA}
      entry="GroupedEvents"
      idAttribute="Name"
      rows={events}
      loading={loading}
      selectionModel={selectedItems}
      selectable={selectable}
      onSelectionModelChange={(selection) => {
        const newSelection = selection.filter((s) => !selectedItems.includes(s as string));
        const selected = selection.length > 1 ? [(newSelection?.[0] || '') as string] : (selection as string[]);
        onSelect?.(selected);
      }}
    />
  ),
);
