import { ReactElement } from 'react';
import { NavLink } from '@localstack/ui';
import { buildRoute } from '@localstack/services';

import { ApiGatewayRoutes } from '../../types';

interface Props {
  restApiId: string;
  resourceId: string;
  routes: ApiGatewayRoutes;
}

export const RestApiResourceNavTabs = ({ restApiId, resourceId, routes }: Props): ReactElement => (
  <>
    <NavLink to={buildRoute(routes.RESOURCES_AGW1_API_RESOURCE, { restApiId, resourceId })} end>
      Details
    </NavLink>
    <NavLink to={buildRoute(routes.RESOURCES_AGW1_API_METHODS, { restApiId, resourceId })}>Methods</NavLink>
  </>
);
