import { ReactElement } from 'react';
import { useRoutes, useAwsGetter } from '@localstack/services';
import { useParams } from 'react-router-dom';
import { Card, CardContent, Button, Box, Typography } from '@mui/material';
import { Breadcrumbs, AgwRestApiModelDetails as Details } from '@localstack/ui';
import { DEFAULT_API_GATEWAY_ROUTES } from '@localstack/constants';

import { ApiGatewayProps } from './types';
import { RestApiNavTabs } from './components';

export const AgwRestApiModelDetails = ({
  Layout,
  clientOverrides,
  routes = DEFAULT_API_GATEWAY_ROUTES,
}: ApiGatewayProps): ReactElement => {
  const { goto } = useRoutes();
  const { restApiId, modelName } = useParams<'restApiId' | 'modelName'>();

  const { data: api } = useAwsGetter('APIGateway', 'getRestApi', [{ restApiId }], { clientOverrides });
  const { data: model } = useAwsGetter('APIGateway', 'getModel', [{ restApiId, modelName }], { clientOverrides });

  return (
    <Layout
      documentTitle="Model Details"
      tabs={<RestApiNavTabs restApiId={restApiId as string} routes={routes} />}
      title={
        <Box>
          <Typography variant="h4">Model Details</Typography>
          <Breadcrumbs
            mappings={[
              ['API Gateway', () => goto(routes.RESOURCES_AGW1)],
              [api?.name, () => goto(routes.RESOURCES_AGW1_API, { restApiId })],
              [model?.name, null],
            ]}
          />
        </Box>
      }
      actions={
        <Button onClick={() => goto(routes.RESOURCES_AGW1_API_MODEL_UPDATE, { restApiId, modelName })}>
          Edit Model
        </Button>
      }
    >
      <Card>
        <CardContent>
          <Details model={model} />
        </CardContent>
      </Card>
    </Layout>
  );
};
