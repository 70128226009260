import { ReactElement } from 'react';
import { useRoutes, useAwsEffect, useAwsGetter } from '@localstack/services';
import { useParams } from 'react-router-dom';
import { Card, CardContent, CardActions, Box, Typography } from '@mui/material';
import { CreateAgwRestApiResourceRequest, UpdateAgwRestApiResourceRequest } from '@localstack/types';
import { ProgressButton, AgwRestApiResourceForm, Breadcrumbs } from '@localstack/ui';
import { DEFAULT_API_GATEWAY_ROUTES } from '@localstack/constants';

import { ApiGatewayProps } from './types';
import { RestApiNavTabs } from './components';

export const AgwRestApiResourceUpsert = ({
  Layout,
  clientOverrides,
  routes = DEFAULT_API_GATEWAY_ROUTES,
}: ApiGatewayProps): ReactElement => {
  const { goto } = useRoutes();
  const { restApiId, resourceId } = useParams<'restApiId' | 'resourceId'>();

  const { createResource, updateResource, isLoading } = useAwsEffect(
    'APIGateway',
    ['createResource', 'updateResource'],
    {
      revalidate: ['getResources', 'getResource'],
      clientOverrides,
    },
  );

  const { data: api } = useAwsGetter('APIGateway', 'getRestApi', [{ restApiId }], { clientOverrides });
  const { data: resources } = useAwsGetter('APIGateway', 'getResources', [{ restApiId }], { clientOverrides });

  const { data: resource } = useAwsGetter('APIGateway', 'getResource', [{ restApiId, resourceId }], {
    clientOverrides,
  });

  const handleCreate = async (data: CreateAgwRestApiResourceRequest) => {
    await createResource(data);
    goto(routes.RESOURCES_AGW1_API_RESOURCES, { restApiId });
  };

  const handleUpdate = async (data: UpdateAgwRestApiResourceRequest) => {
    await updateResource(data);
    goto(routes.RESOURCES_AGW1_API_RESOURCE, { restApiId, resourceId });
  };

  return (
    <Layout
      title={
        <Box>
          <Typography variant="h4">Resource Details</Typography>
          <Breadcrumbs
            mappings={[
              ['API Gateway', () => goto(routes.RESOURCES_AGW1)],
              [api?.name, () => goto(routes.RESOURCES_AGW1_API, { restApiId })],
              [resource?.path, () => goto(routes.RESOURCES_AGW1_API_RESOURCE, { restApiId, resourceId })],
              [resourceId ? 'Update' : 'Create Resource', null],
            ]}
          />
        </Box>
      }
      tabs={<RestApiNavTabs restApiId={restApiId as string} routes={routes} />}
      actions={
        <ProgressButton type="submit" form="update" variant="outlined" color="primary" loading={isLoading}>
          Submit
        </ProgressButton>
      }
    >
      <Card>
        <CardContent>
          <AgwRestApiResourceForm
            restApiId={restApiId as string}
            resources={resources?.items || []}
            resource={resource}
            onCreate={handleCreate}
            onUpdate={handleUpdate}
            loading={isLoading}
            formId="update"
          />
        </CardContent>
        <CardActions>
          <ProgressButton type="submit" form="update" variant="contained" color="primary" loading={isLoading}>
            Submit
          </ProgressButton>
        </CardActions>
      </Card>
    </Layout>
  );
};
