import { ReactElement, ChangeEvent } from 'react';
import { Controller, Control, ControllerProps, ControllerRenderProps } from 'react-hook-form';
import { Button, ButtonProps } from '@mui/material';

export type ControlledUploadButtonProps = ButtonProps &
  Omit<ControllerProps, 'control' | 'render'> & {
    control: Control<any>; // eslint-disable-line
    multiple?: boolean;
  };

export const ControlledUploadButton = ({
  control,
  children,
  multiple,
  ...rest
}: ControlledUploadButtonProps): ReactElement => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleChange = (field: ControllerRenderProps<any, string>) => (event: ChangeEvent<HTMLInputElement>) => {
    const files = event.target.files || [];
    const value = multiple ? Array.from(files) : files[0];
    field.onChange({ type: 'change', target: { name: field.name, value } });
  };

  return (
    <Controller
      {...rest}
      control={control}
      render={({ field }) => (
        // eslint-disable-next-line
        // @ts-ignore
        <Button {...rest} component="label">
          {children}
          <input {...field} type="file" value={undefined} hidden multiple={multiple} onChange={handleChange(field)} />
        </Button>
      )}
    />
  );
};

export default ControlledUploadButton;
