import { Link } from '@mui/material';
import { DocDBTableRow } from '@localstack/types';
import { ReactElement } from 'react';

import { MagicTable } from '../../magic/MagicTable';

export type DocDBClustersTableProps = {
  items: DocDBTableRow[];
  onViewCluster: (id: string) => void;
  onViewInstance: (id: string) => void;
  onSelect: (ids: string[]) => void;
  loading?: boolean;
};

const SCHEMA = {
  shapes: {
    DocDBDatabases: {
      type: 'structure',
      members: {
        DBIdentifier: { type: 'string' },
        Status: { type: 'string' },
        Engine: { type: 'string' },
        Type: { type: 'string' },
        Size: { type: 'string' },
      },
    },
  },
};

export const DocDBClustersTable = ({
  items,
  onViewCluster,
  onViewInstance,
  onSelect,
  loading,
}: DocDBClustersTableProps): ReactElement => (
  <MagicTable
    schema={SCHEMA}
    entry="DocDBDatabases"
    idAttribute="DBIdentifier"
    order={['DBIdentifier']}
    rows={items}
    externalFields={{
      DBIdentifier: ({ DBIdentifier, Type }) => (
        <Link
          onClick={() => (Type === 'Cluster' ? onViewCluster(DBIdentifier || '') : onViewInstance(DBIdentifier || ''))}
          underline="hover"
        >
          {DBIdentifier}
        </Link>
      ),
    }}
    expandableTable={{ idColumn: 'DBIdentifier' }}
    loading={loading}
    onSelect={onSelect}
    selectable
    disableSelectionOnClick
  />
);
