import { ReactElement } from 'react';

import { ContainedCustomerLayout } from '~/layouts';

import { SSOSettings } from './components';

export const SingleSignOnSettings = (): ReactElement => (
  <ContainedCustomerLayout title="Single Sign-on Settings">
    <SSOSettings />
  </ContainedCustomerLayout>
);
