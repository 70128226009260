export type getAWSRowCountParams = {
  pageSize?: number;
  DEFAULT_PAGE_SIZE: number;
  page: number;
  hasMore: boolean;
  array: unknown[];
};

/**
 * Basically the DataGrid relies on the rowCount to know how many
 * rows to show and it needs a bit of further processing to tell
 * how many rows there actually are and display them correctly
 */

export const getAWSRowCount = ({ pageSize, DEFAULT_PAGE_SIZE, page, hasMore, array }: getAWSRowCountParams): number => {
  const actualPageSize = pageSize ?? DEFAULT_PAGE_SIZE;
  const actualPageNumber = page + 1;
  if (hasMore) {
    if (array.length > 0) {
      return array.length * actualPageNumber + 1;
    }
    return actualPageSize * actualPageNumber + 1;
  }
  if (page > 0) {
    return page * actualPageSize + array.length;
  }
  return array.length;
};
