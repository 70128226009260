import { ReactElement, useCallback, useEffect, useState } from 'react';
import { useRoutes, useAwsGetter, useAwsEffect } from '@localstack/services';
import { Card, Button } from '@mui/material';
import { Dropdown, ConfirmableButton, PageTitle, Route53HostedZonesTable } from '@localstack/ui';
import { DEFAULT_ROUTE53_ROUTES } from '@localstack/constants';
import { Route53HostedZone } from '@localstack/types';

import { Route53Props } from './types';
import { MainNavTabs } from './components';
import { processHostedZone } from './utils';

export const Route53HostedZones = ({
  Layout,
  clientOverrides,
  routes = DEFAULT_ROUTE53_ROUTES,
}: Route53Props): ReactElement => {
  const [selected, setSelected] = useState<string[]>([]);
  const [hostedZones, setHostingZones] = useState<Optional<Route53HostedZone[]>>();

  const { goto } = useRoutes();

  const { data, isLoading, mutate } = useAwsGetter('Route53', 'listHostedZones', [], { clientOverrides });

  const { deleteHostedZone } = useAwsEffect('Route53', ['deleteHostedZone'], {
    revalidate: ['listHostedZones'],
    clientOverrides,
  });

  const handleDeleteSelected = useCallback(
    async () =>
      Promise.all(
        selected.map((Name) =>
          deleteHostedZone({
            Id: hostedZones?.find((hostedZone) => hostedZone.Name === Name)?.Id || '',
          }),
        ),
      ),
    [selected],
  );

  useEffect(() => {
    setHostingZones(data?.HostedZones?.map(processHostedZone));
  }, [data?.HostedZones]);

  return (
    <Layout
      documentTitle="Route53: Hosted Zones"
      title={
        <PageTitle
          title="Route 53"
          breadcrumbs={[
            ['Route53', () => goto(routes.RESOURCES_ROUTE53_HOSTED_ZONES)],
            ['Hosted Zones', () => goto(routes.RESOURCES_ROUTE53_HOSTED_ZONES)],
          ]}
          onMutate={mutate}
        />
      }
      tabs={<MainNavTabs routes={routes} />}
      actions={
        <>
          <Button onClick={() => goto(routes.RESOURCES_ROUTE53_HOSTED_ZONE_CREATE)}>Create Hosted Zone</Button>
          <Dropdown label="Actions">
            <ConfirmableButton
              componentType="MenuItem"
              disabled={selected.length === 0 || isLoading}
              title={`Delete ${selected.length} Hosted Zone(s)?`}
              onClick={handleDeleteSelected}
              text="Selected Hosted Zone(s) will be permanently deleted"
            >
              Remove Selected
            </ConfirmableButton>
          </Dropdown>
        </>
      }
    >
      <Card>
        <Route53HostedZonesTable
          hostedzones={hostedZones ?? []}
          loading={isLoading}
          onSelect={setSelected}
          onViewHostedZone={(hostedZone) => goto(routes.RESOURCES_ROUTE53_HOSTED_ZONE, { hostedZone })}
        />
      </Card>
    </Layout>
  );
};
