import { ReactElement } from 'react';
import { AAScalableTarget } from '@localstack/types';
import { getSchema } from '@localstack/services';

import { MagicTable } from '../../magic/MagicTable';

export type ScalableTargetsTableProps = {
  selectable?: boolean;
  loading?: boolean;
  scalableTargets: AAScalableTarget[];
  onViewScalableTarget?: (scalableTargets: AAScalableTarget) => unknown;
  onSelect?: (scalableTargets: string[]) => void;
};

export const ScalableTargetsTable = ({
  scalableTargets,
  selectable = true,
  loading,
  onSelect,
}: ScalableTargetsTableProps): ReactElement => (
  <MagicTable
    schema={getSchema('ApplicationAutoScaling')}
    entry="ScalableTargets"
    idAttribute="ResourceId"
    filterColumns={[
      'ServiceNamespace',
      'ResourceId',
      'ScalableDimension',
      'CreationTime',
      'MaxCapacity',
      'MinCapacity',
    ]}
    rows={scalableTargets}
    selectable={selectable}
    onSelect={onSelect}
    loading={loading}
  />
);
