import { INSTANCE_DEFAULT_ID } from '@localstack/constants';
import {
  getSelectedInstance,
  GlobalStateContext,
  useAwsEffect,
  useEndpoint,
  useGlobalSwr,
  useLocalstackStatus,
} from '@localstack/services';
import { EphemeralInstanceStatus, LocalStackInstance, LocalStackInstanceType, PlanFamily } from '@localstack/types';

import { Box, Button, Theme, Typography, Chip, Container, Tooltip, DialogContentText } from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import {
  AccessTime,
  Close as CloseIcon,
  Delete as DeleteIcon,
  CallMade as CallMadeIcon,
  Edit as EditIcon,
  RefreshOutlined as RefreshOutlinedIcon,
} from '@mui/icons-material';
import classNames from 'classnames';
import { ReactElement, useContext, useState, useMemo } from 'react';

import { CopyIconButton } from '@localstack/ui/src/feedback/CopyIconButton';

import { LogoRocket } from '../../display/LogoRocket';
import { ConfirmableButton, PlanChip } from '../../feedback';
import { TopMenuSwitcher, TopMenuSwitcherProps } from '../TopMenuSwitcher';
import { InstanceBookmarkCard } from '../../core/instance/InstanceBookmarkCard';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      backgroundColor: theme.palette.divider,
      color: theme.palette.text.primary,
      display: 'flex',
      flexDirection: 'column',
    },
    header: {
      display: 'flex',
      margin: '3em',
      marginLeft: 0,
    },
    logo: {
      '& svg': {
        height: '3rem',
      },
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
    },
    instanceInfo: {
      display: 'flex',
      flexDirection: 'column',
      width: '100%',
      gap: '1rem',
    },
    statusInfo: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      gap: '1rem',
    },
    endpoint: {
      fontSize: '1rem',
      display: 'flex',
      flexDirection: 'column',
    },
    url: {
      display: 'flex',
      alignItems: 'center',
      fontFamily: 'monospace',
      fontWeight: 900,
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    },
    rounded: {
      borderRadius: '0.5em',
    },
    onlineIndicatorOffline: {
      backgroundColor: theme.palette.error.main,
      color: theme.palette.error.contrastText,
    },
    onlineIndicatorOnline: {
      backgroundColor: theme.palette.success.main,
      color: theme.palette.success.contrastText,
    },
    onlineIndicatorCreating: {
      backgroundColor: theme.palette.warning.main,
      color: theme.palette.warning.contrastText,
    },
    tabs: {
      marginLeft: '0.8em',

      '& ul': {
        display: 'flex',
        columnGap: '0.5em',
      },

      '& li': {
        display: 'inline-block',
        backgroundColor: theme.palette.background.default,
        padding: '0.8em',
        paddingBottom: '0.6em',
        color: theme.palette.text.primary,
        borderRadius: '0.4em 0.4em 0px 0px',
        borderBottom: '1px solid',
        borderColor: `${theme.palette.divider} !important`,
      },

      '& .active': {
        border: 'none',
      },
    },
  }),
);

type LocalStackInstanceHeaderProps = TopMenuSwitcherProps & {
  onSave?: (instance: LocalStackInstance) => void;
  onRemove?: (instance: LocalStackInstance) => void;
  isManagementView?: boolean;
  isRemovingInstance?: boolean;
};

export const LocalStackInstanceHeader = ({
  instance,
  disabledRoutes,
  onSave,
  onRemove,
  isManagementView,
  isRemovingInstance,
}: LocalStackInstanceHeaderProps): ReactElement => {
  const selectedInstance = getSelectedInstance();
  const { endpoint: globalEndpoint } = useEndpoint();
  const instanceEndpoint = selectedInstance?.endpoint || globalEndpoint;
  const classes = useStyles();
  const { clearAll: clearAllState } = useGlobalSwr();

  const { restartInstance, isLoading: restartingInstance } = useAwsEffect('LocalStack', ['restartInstance'], {
    clientOverrides: { endpoint: instanceEndpoint },
  });

  const isEditableInstance = instance?.instanceType === LocalStackInstanceType.REGULAR_INSTANCE;
  const isDefaultInstance = instance?.id === INSTANCE_DEFAULT_ID;
  const isRemovableInstance = !isDefaultInstance && onRemove;

  const enableHealthChecks = instance?.endpoint !== '';

  const { running, isPro } = useLocalstackStatus({ endpoint: instance?.endpoint }, enableHealthChecks);

  const localstackStatus = running ? 'running' : 'offline';

  const ephemeralInstanceStatus = instance?.ephemeralInstance?.status;
  const statusText = ephemeralInstanceStatus ?? localstackStatus;
  const creating = statusText === EphemeralInstanceStatus.CREATING;

  const { updateInstance } = useContext(GlobalStateContext);

  const [isEditMode, setEditMode] = useState<boolean>(false);

  const isEphemeralInstance = instance?.instanceType === LocalStackInstanceType.EPHEMERAL_INSTANCE;
  const isPodInstance = instance?.name?.startsWith('pod-');

  const Wrapper = isManagementView ? Box : (Container as React.ElementType);

  const deadline = useMemo(
    () => Math.trunc(((instance?.ephemeralInstance?.expiry_time ?? 0) - Date.now() / 1000) / 60),
    [instance],
  );

  return (
    <div
      data-name="resource browser preface"
      className={classNames(classes.root, { [classes.rounded]: isManagementView })}
    >
      <Wrapper>
        <Box className={classes.header}>
          <Box className={classes.logo}>
            <LogoRocket />
          </Box>
          <Box className={classes.instanceInfo}>
            {/* status line */}
            <Box className={classes.statusInfo}>
              {isEphemeralInstance && (
                <Tooltip title="Time until your instance is shut down">
                  <Chip
                    color="primary"
                    variant="outlined"
                    label={
                      <div style={{ display: 'flex', alignItems: 'center', gap: 4 }}>
                        <AccessTime /> {deadline} minutes
                      </div>
                    }
                  />
                </Tooltip>
              )}
              {isPro ? (
                <PlanChip
                  size="medium"
                  planFamily={PlanFamily.PRO_PLANS}
                  showTooltip
                  tooltipTitleOverride="Running the localstack-pro image"
                  style={{ marginLeft: 0 }}
                />
              ) : (
                <PlanChip size="medium" planFamily={PlanFamily.COMMUNITY} style={{ marginLeft: 0 }} />
              )}
              {isEphemeralInstance && (
                <Chip color="primary" variant="outlined" label={isPodInstance ? 'Pod' : 'Ephemeral'} />
              )}
              <Chip
                className={classNames(classes.onlineIndicatorOffline, {
                  [classes.onlineIndicatorOnline]: running,
                  [classes.onlineIndicatorCreating]: creating,
                })}
                label={statusText}
              />
              {!isEphemeralInstance && (
                <Button
                  variant="outlined"
                  color="primary"
                  disabled={!isEditableInstance}
                  onClick={() => setEditMode(!isEditMode)}
                  startIcon={isEditMode ? <CloseIcon /> : <EditIcon />}
                >
                  {isEditMode ? 'Cancel' : 'Edit'}
                </Button>
              )}
              <Tooltip title={!running ? 'The instance is not running' : ''}>
                <div>
                  <ConfirmableButton
                    componentType="Button"
                    title="Restart instance"
                    text={
                      <DialogContentText>
                        Are you sure you want to restart this instance? <br />
                        Restarting will reset the instance state unless persistence is enabled.
                      </DialogContentText>
                    }
                    variant="outlined"
                    color="primary"
                    onClick={() => {
                      restartInstance();
                      clearAllState();
                    }}
                    startIcon={<RefreshOutlinedIcon />}
                    disabled={!running || restartingInstance}
                    okText="Restart Instance"
                  >
                    Restart
                  </ConfirmableButton>
                </div>
              </Tooltip>
              <Tooltip
                title={isDefaultInstance ? 'Removing the default instance is disabled to keep the app functional' : ''}
              >
                <div>
                  <ConfirmableButton
                    componentType="ProgressButton"
                    title={`Remove instance ${instance?.name}`}
                    text={`Are you sure you want to remove the instance called ${instance?.name}?`}
                    variant="outlined"
                    color="primary"
                    onClick={() => instance && onRemove?.(instance)}
                    startIcon={<DeleteIcon />}
                    disabled={!isRemovableInstance}
                    loading={isRemovingInstance}
                  >
                    {isEphemeralInstance ? 'Shut down' : 'Remove'}
                  </ConfirmableButton>
                </div>
              </Tooltip>
              <Tooltip title={!running ? 'The instance is not running' : ''}>
                <div>
                  {!isEphemeralInstance && (
                    <Button
                      component="a"
                      href={`${instance?.endpoint}/_localstack/swagger`}
                      target="_blank"
                      rel="noopener noreferrer"
                      variant="text"
                      color="primary"
                      disabled={!running || restartingInstance}
                      endIcon={<CallMadeIcon />}
                    >
                      API Docs
                    </Button>
                  )}
                </div>
              </Tooltip>
            </Box>
            <Box className={classes.endpoint}>
              <Typography variant="h5">{instance?.name}</Typography>
              {instance?.endpoint && (
                <Typography variant="h5" className={classes.url}>
                  {instance?.endpoint}
                  <CopyIconButton textToCopy={instance?.endpoint ?? ''} tooltipTitle="Copy endpoint URL" />
                </Typography>
              )}
              {instance?.description && !isEphemeralInstance && (
                <>
                  <br />
                  <Typography variant="h5">{instance?.description}</Typography>
                </>
              )}
            </Box>
            {isEditableInstance && isEditMode && instance && (
              <Box mt={2}>
                <InstanceBookmarkCard
                  instance={instance}
                  onSave={(updatedInstance: LocalStackInstance) => {
                    if (onSave) onSave(updatedInstance);
                    else updateInstance(updatedInstance);
                    setEditMode(false);
                  }}
                />
              </Box>
            )}
          </Box>
        </Box>
        <TopMenuSwitcher instance={instance} disabledRoutes={disabledRoutes} />
      </Wrapper>
    </div>
  );
};
