import { getSchema } from '@localstack/services';
import { DBInstance } from '@localstack/types';
import { ReactElement } from 'react';

import { MagicDetails } from '../../magic/MagicDetails';

export type DocDBInstanceDetailProps = {
  instance?: Optional<Partial<DBInstance>>;
};

export const DocDBInstanceDetail = ({ instance }: DocDBInstanceDetailProps): ReactElement => (
  <MagicDetails data={instance} schema={getSchema('DocDB')} entry="DBInstance" title="DocDB Instance Details" />
);
