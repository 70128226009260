/* eslint-disable @typescript-eslint/no-explicit-any */
import { useEffect, useState } from 'react';
import { useTheme, Typography } from '@mui/material';

// eslint-disable-next-line
import { CategoricalChartState } from 'recharts/types/chart/generateCategoricalChart';

interface UseChartPropsReturn {
  barProps: Record<string, any>;
  cartesianGridProps: Record<string, any>;
  legendProps: Record<string, any>;
  tooltipProps: Record<string, any>;
  xAxisProps: Record<string, any>;
  yAxisProps: Record<string, any>;
}

/**
 * A temporary solution to create reusable theming for chart components,
 * since wrapping is currently [alost] not supported:
 *
 * https://github.com/recharts/recharts/issues/412
 * https://github.com/recharts/recharts/issues/2788
 */
export const useChartProps = (): UseChartPropsReturn => {
  const theme = useTheme();

  /**
   * A solution to fix tooltip autofocus issue. The tooltip in chart gets focused on chart hover
   * which is not the desired behaviour. To get the element non-focusable, tabindex is being removed.
   * The issue is open in recharts as well and in progress.
   *
   * https://github.com/recharts/recharts/issues/3063
   */
  useEffect(() => {
    document.querySelectorAll('.recharts-responsive-container').forEach((elem) => {
      (elem as HTMLElement).onmouseover = () =>
        document.querySelectorAll('.recharts-tooltip-wrapper[tabindex]').forEach((e) => e.removeAttribute('tabindex'));
    });
  }, []);

  return {
    barProps: {
      fill: theme.palette.primary.main,
    },
    cartesianGridProps: {
      fill: theme.palette.background.default,
      stroke: theme.palette.divider,
    },
    legendProps: {
      formatter: (label: string) => (
        <Typography component="span" variant="caption" color="textPrimary" style={{ lineHeight: '1rem' }}>
          {label}
        </Typography>
      ),
    },
    tooltipProps: {
      cursor: {
        fill: theme.palette.action.focus,
      },
      contentStyle: {
        background: theme.palette.background.paper,
        borderRadius: 10,
      },
      wrapperStyle: {
        zIndex: 2,
      },
      itemStyle: { color: theme.palette.text.disabled },
    },
    xAxisProps: {
      stroke: theme.palette.text.disabled,
    },
    yAxisProps: {
      stroke: theme.palette.text.disabled,
    },
  };
};
type UseZoomableChartProps =
  | {
      type: 'datestring';
      onChangeInterval?: (start: string | Date, end: string | Date) => unknown;
    }
  | {
      type: 'number';
      onChangeInterval?: (start: number, end: number) => unknown;
    }
  | {
      type: 'string';
      onChangeInterval?: (start: string, end: string) => unknown;
    };

export const useZoomableChart = ({ type, onChangeInterval }: UseZoomableChartProps): Record<string, any> => {
  const [leftSelection, setLeftSelection] = useState<Optional<string | number>>('');
  const [rightSelection, setRightSelection] = useState<Optional<string | number>>('');
  const [isReferencing, setIsReferencing] = useState(false);

  return {
    props: {
      onMouseDown: (e: CategoricalChartState) => {
        if (!e) return; // ¯\_(ツ)_/¯
        setIsReferencing(true);
        setLeftSelection(e.activeLabel);
      },
      onMouseMove: (e: CategoricalChartState) => isReferencing && setRightSelection(e.activeLabel),
      onMouseUp: () => {
        setIsReferencing(false);

        if (leftSelection && rightSelection && leftSelection !== rightSelection) {
          let ordered =
            leftSelection > rightSelection ? [rightSelection, leftSelection] : [leftSelection, rightSelection];

          if (type === 'datestring') {
            ordered =
              new Date(leftSelection) > new Date(rightSelection)
                ? [rightSelection, leftSelection]
                : [leftSelection, rightSelection];
          }

          // eslint-disable-next-line
          // @ts-ignore
          onChangeInterval?.(ordered[0], ordered[1]);

          setLeftSelection('');
          setRightSelection('');
        }

        setLeftSelection('');
      },
    },
    leftSelection,
    rightSelection,
  };
};
