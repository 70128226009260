import { ReactElement } from 'react';
import { AwsClientOverrides, buildRoute, getSchema } from '@localstack/services';
import { TextField } from '@mui/material';

import { AgwApiRoute, CreateAgwApiRouteRequest, UpdateAgwApiRouteRequest } from '@localstack/types';

import { DEFAULT_API_GATEWAY_ROUTES } from '@localstack/constants';

import { MagicForm } from '../../../magic/MagicForm';
import { RelatedResourcePicker } from '../../../../form';

export interface AgwApiRouteFormProps {
  apiId: string;
  isWebsocket?: boolean;
  route?: Optional<Partial<AgwApiRoute>>;
  loading?: boolean;
  formId?: string;
  clientOverrides?: AwsClientOverrides;
  onCreate: (data: CreateAgwApiRouteRequest) => unknown;
  onUpdate: (data: UpdateAgwApiRouteRequest) => unknown;
}

export const AgwApiRouteForm = ({
  apiId,
  isWebsocket,
  route,
  loading,
  formId,
  clientOverrides,
  onCreate,
  onUpdate,
}: AgwApiRouteFormProps): ReactElement => (
  <MagicForm
    data={route}
    schema={getSchema('ApiGatewayV2')}
    loading={loading}
    entry="CreateRouteRequest"
    formId={formId}
    fieldConditions={{
      '^AuthorizerId$': ['$AuthorizationType', '!==', 'NONE'],
      '^(RequestModels|RequestParameters)': !!isWebsocket,
    }}
    externalFields={{
      '^ApiId$': () => <TextField value={apiId} fullWidth disabled variant="outlined" />,
      '^AuthorizerId$': (control, name, required) => (
        <RelatedResourcePicker
          required={required}
          control={control}
          fieldName={name}
          entityName="Authorizer"
          creationRoute={buildRoute(DEFAULT_API_GATEWAY_ROUTES.RESOURCES_AGW2_API_AUTHORIZER_CREATE, { apiId })}
          client="ApiGatewayV2"
          method="getAuthorizers"
          arrayKeyName="Items"
          property="AuthorizerId"
          args={[{ ApiId: apiId }]}
          clientOverrides={clientOverrides}
        />
      ),
      '^Target$': (control, name, required) => (
        <RelatedResourcePicker
          required={required}
          control={control}
          fieldName={name}
          entityName="Integration"
          client="ApiGatewayV2"
          method="getIntegrations"
          arrayKeyName="Items"
          property="IntegrationId"
          args={[{ ApiId: apiId }]}
          creationRoute={buildRoute(DEFAULT_API_GATEWAY_ROUTES.RESOURCES_AGW2_API_INTEGRATION_CREATE, { apiId })}
          clientOverrides={clientOverrides}
        />
      ),
      '^RequestModels\\..+': (control, name, required) => (
        <RelatedResourcePicker
          required={required}
          control={control}
          fieldName={name}
          entityName="Model"
          client="ApiGatewayV2"
          method="getModels"
          arrayKeyName="Items"
          property="Name"
          clientOverrides={clientOverrides}
          args={[{ ApiId: apiId }]}
          creationRoute={buildRoute(DEFAULT_API_GATEWAY_ROUTES.RESOURCES_AGW2_API_MODEL_CREATE, { apiId })}
        />
      ),
    }}
    onSubmit={(data: CreateAgwApiRouteRequest) => {
      if (!route) return onCreate({ ...data, ApiId: apiId });
      return onUpdate({ ...data, ApiId: apiId, RouteId: route.RouteId as string });
    }}
  />
);
