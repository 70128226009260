import { SERVICE_NAME } from '@localstack/types';

import { PATH_PREFIX_RESOURCES } from './base';

const SSM_BASE_ROUTE = `${PATH_PREFIX_RESOURCES}/${SERVICE_NAME.SSM}`;

export const DEFAULT_SYSTEMPARAMETERS_ROUTES: Record<string, string> = {
  RESOURCES_SYSTEM_PARAMETERS: `${SSM_BASE_ROUTE}`,
  RESOURCES_SYSTEM_PARAMETER: `${SSM_BASE_ROUTE}/:name`,
  RESOURCES_SYSTEM_PARAMETER_CREATE: `${SSM_BASE_ROUTE}/new`,
};
