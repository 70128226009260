import { useState, useEffect, useCallback, ReactElement } from 'react';
import { useSearchParams } from 'react-router-dom';
import { LogoIcon } from '@localstack/ui';
import { useApiEffect, UserService, storeAuthToken } from '@localstack/services';
import { Box, Link, Typography } from '@mui/material';

import { SSOPostMessagePayload, SSOPostMessageSuccessPayload } from '@localstack/types';

import { useAuthProvider } from '~/hooks/useAuthProvider';
import { BASE_URL, AppRoute } from '~/config';

import { AuthLayout } from '../Account/AuthLayout';
import { useStyles } from '../Account/common';

const generateIdpErrorPayload = (error: string): SSOPostMessagePayload => ({
  messageType: 'sso-error',
  error: {
    type: 'idp-error',
    message: `Something went wrong during the sign-in process. Reason: ${error}`,
  },
});

type CognitoError = {
  error: string;
  errorDescription?: string;
};

export const SSOCallback = (): ReactElement => {
  const classes = useStyles();
  const [searchParams] = useSearchParams();
  const error = searchParams.get('error');
  const errorDescription = searchParams.get('error_description') || undefined;
  const cognitoError: Optional<CognitoError> = error ? { error, errorDescription } : undefined;

  const [loading, setLoading] = useState(true);
  const [errorFromCognito, setErrorFromCognito] = useState<Optional<CognitoError>>(cognitoError);
  const [goodToGo, setGoodToGo] = useState(false);

  const { reloadUserInfo } = useAuthProvider();

  const { finalizeSso } = useApiEffect(UserService, ['finalizeSso'], { suppressErrors: true });

  const code = searchParams.get('code');

  const authenticate = useCallback(async () => {
    setErrorFromCognito(null);
    setLoading(true);

    const redirect_uri = `${BASE_URL}${AppRoute.SSO_CALLBACK}`;

    try {
      const response = await finalizeSso({
        code: code || '',
        redirect_uri,
      });

      storeAuthToken(response);
      await reloadUserInfo();

      setGoodToGo(true);

      const payload: SSOPostMessageSuccessPayload = { messageType: 'sso-success' };
      window.opener.postMessage(payload, BASE_URL);
    } catch (e) {
      const payload = generateIdpErrorPayload(e.message ?? 'Unknown error');
      window.opener.postMessage(payload, BASE_URL);
    } finally {
      setLoading(false);
    }
  }, [code]);

  useEffect(() => {
    if (errorFromCognito) {
      const payload = generateIdpErrorPayload(`
        ${errorFromCognito.error}
        ${errorFromCognito.errorDescription ? `(${errorFromCognito.errorDescription.trim()})` : ''}`);
      window.opener.postMessage(payload, BASE_URL);
      return;
    }
    authenticate();
  }, []);

  return (
    <AuthLayout documentTitle="SSO Login">
      <Box textAlign="center">
        <Box mb={4}>
          <LogoIcon />
        </Box>
        <Typography variant="h3" component="h2" fontWeight={600}>
          Finalizing your Login
        </Typography>

        <Box mt={4}>
          {!errorFromCognito && code && (
            <Typography fontSize={13} className={classes.grey600Text} fontWeight={500}>
              {loading && code && 'Please wait while we are finalizing your login...'}
              {goodToGo && (
                <>
                  We are all set! You will be redirected shortly... If this does not happen, please{' '}
                  <Link underline="hover" className={classes.grey800Text} onClick={() => self.close()}>
                    click here
                  </Link>
                  .
                </>
              )}
            </Typography>
          )}
        </Box>
      </Box>
    </AuthLayout>
  );
};
