import { ReactElement, useState, useCallback } from 'react';
import { useRoutes, useAwsEffect, useAwsGetter } from '@localstack/services';
import { DEFAULT_API_GATEWAY_ROUTES } from '@localstack/constants';
import { Card, Button } from '@mui/material';
import { useParams } from 'react-router-dom';

import { PageTitle, Dropdown, ConfirmableButton, AgwRestApiMethodsTable } from '@localstack/ui';

import { ApiGatewayProps } from './types';
import { RestApiResourceNavTabs } from './components';

export const AgwRestApiMethods = ({
  Layout,
  clientOverrides,
  routes = DEFAULT_API_GATEWAY_ROUTES,
}: ApiGatewayProps): ReactElement => {
  const { restApiId, resourceId } = useParams<'restApiId' | 'resourceId'>();
  const [selectedIds, setSelectedIds] = useState<string[]>([]);

  const { goto } = useRoutes();
  const { data: api } = useAwsGetter('APIGateway', 'getRestApi', [{ restApiId }], { clientOverrides });

  const {
    data: resource,
    isLoading,
    mutate,
  } = useAwsGetter('APIGateway', 'getResource', [{ restApiId, resourceId }], { clientOverrides });

  const { deleteMethod } = useAwsEffect('APIGateway', ['deleteMethod'], {
    revalidate: ['getResources', 'getResource'],
  });

  const handleDeleteSelected = useCallback(async () => {
    const promises = selectedIds.map((httpMethod) =>
      deleteMethod({
        restApiId: restApiId as string,
        resourceId: resourceId as string,
        httpMethod,
      }),
    );
    await Promise.all(promises);
  }, [selectedIds]);

  return (
    <Layout
      documentTitle="Resource Methods"
      title={
        <PageTitle
          title="Resource Methods"
          onMutate={mutate}
          breadcrumbs={[
            ['API Gateway', () => goto(routes.RESOURCES_AGW1)],
            [api?.name, () => goto(routes.RESOURCES_AGW1_API, { restApiId })],
            [resource?.path, () => goto(routes.RESOURCES_AGW1_API_RESOURCE, { restApiId, resourceId })],
            ['Resource Methods', null],
          ]}
        />
      }
      tabs={
        <RestApiResourceNavTabs restApiId={restApiId as string} resourceId={resourceId as string} routes={routes} />
      }
      actions={
        <>
          <Button onClick={() => goto(routes.RESOURCES_AGW1_API_METHOD_CREATE, { restApiId, resourceId })}>
            Create Method
          </Button>
          <Dropdown label="Actions">
            <ConfirmableButton
              componentType="MenuItem"
              disabled={selectedIds.length === 0 || isLoading}
              title={`Delete ${selectedIds.length} Method(s)?`}
              onClick={handleDeleteSelected}
              text="Selected Methods will be permanently deleted"
            >
              Remove Selected
            </ConfirmableButton>
          </Dropdown>
        </>
      }
    >
      <Card>
        <AgwRestApiMethodsTable
          methods={Object.values(resource?.resourceMethods || {})}
          onSelect={setSelectedIds}
          onViewMethod={({ httpMethod }) =>
            goto(routes.RESOURCES_AGW1_API_METHOD, { restApiId, resourceId, httpMethod })
          }
        />
      </Card>
    </Layout>
  );
};
