import { useState, useCallback, ReactElement, useEffect } from 'react';
import { Button, Card, CardContent } from '@mui/material';
import { Dropdown, ConfirmableButton, LoadingFragment, LambdaFunctionsTable, PageTitle } from '@localstack/ui';
import { useRoutes, useAwsEffect, useAwsGetter, useAwsKeysMemo } from '@localstack/services';

import { LAMBDA_DEFAULT_PAGE_SIZE } from '@localstack/constants';

import { LambdaProps } from './types';
import { NavTabs } from './components/NavTabs';

export const LambdaFunctions = ({ Layout, clientOverrides, routes }: LambdaProps): ReactElement => {
  const { goto } = useRoutes();
  const [page, setPage] = useState<number>(0);
  const [paginationKey, setPaginationKey] = useState<string | undefined>(undefined);

  const [selectedFunctions, setSelectedFunctions] = useState<string[]>([]);

  const {
    data: functions,
    isLoading: isLambdasLoading,
    mutate,
  } = useAwsGetter('Lambda', 'listFunctions', [{ Marker: paginationKey }], { clientOverrides });

  const lambdaMarkers = useAwsKeysMemo(page, functions?.NextMarker);

  useEffect(() => {
    setPaginationKey(lambdaMarkers[page]);
  }, [page]);

  const { deleteFunction, isLoading: isFunctionMutating } = useAwsEffect('Lambda', ['deleteFunction'], {
    revalidate: ['listFunctions'],
    clientOverrides,
  });

  const handleDeleteFunctions = useCallback(
    async () => Promise.all(selectedFunctions.map((FunctionName) => deleteFunction({ FunctionName }))),
    [selectedFunctions],
  );

  return (
    <>
      <Layout
        title={<PageTitle title="Lambda Functions" onMutate={mutate} />}
        tabs={<NavTabs routes={routes} />}
        actions={
          <>
            <Button onClick={() => goto(routes.RESOURCES_LAMBDA_FUNCTION_NEW)}>Create</Button>
            <Dropdown label="Actions">
              <ConfirmableButton
                componentType="MenuItem"
                disabled={selectedFunctions.length === 0 || isFunctionMutating}
                title={`Remove ${selectedFunctions.length} functions(s)?`}
                onClick={handleDeleteFunctions}
                text="Selected Functions will be permanently deleted"
              >
                Remove Selected
              </ConfirmableButton>
            </Dropdown>
          </>
        }
      >
        <Card>
          <CardContent>
            <LoadingFragment
              loading={isLambdasLoading}
              arrayData={functions?.Functions ?? []}
              variant="list"
              height={50}
              size={10}
            >
              <LambdaFunctionsTable
                loading={isLambdasLoading}
                functions={functions?.Functions ?? []}
                onSelectFunctions={setSelectedFunctions}
                onViewFunction={(name) => goto(routes.RESOURCES_LAMBDA_FUNCTION, { name })}
                page={page}
                onPageChange={setPage}
                pageSize={LAMBDA_DEFAULT_PAGE_SIZE}
                hasMore={!!functions?.NextMarker}
              />
            </LoadingFragment>
          </CardContent>
        </Card>
      </Layout>
    </>
  );
};
