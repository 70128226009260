import { DEFAULT_COGNITO_ROUTES } from '@localstack/constants';
import { ReactElement } from 'react';
import { useAwsGetter, useRoutes } from '@localstack/services';
import { useParams } from 'react-router-dom';
import { Box, Button, Card, Typography } from '@mui/material';
import { Breadcrumbs, CognitoIdentityPoolDetail } from '@localstack/ui';

import { CognitoProps } from './types';

export const CognitoIdentityPool = ({
  Layout,
  clientOverrides,
  routes = DEFAULT_COGNITO_ROUTES,
}: CognitoProps): ReactElement => {
  const { goto } = useRoutes();
  const { id } = useParams<'id'>();
  const { data: identityPool } = useAwsGetter('CognitoIdentity', 'describeIdentityPool', [{ IdentityPoolId: id }], {
    clientOverrides,
    swrOverrides: { revalidateOnMount: true },
  });
  return (
    <Layout
      title={
        <Box>
          <Typography variant="h4">Identity Pool Details</Typography>
          <Breadcrumbs
            mappings={[
              ['Cognito Identity', () => goto(routes.RESOURCES_COGNITO_IDENTITY_POOLS)],
              [identityPool?.IdentityPoolName, null],
            ]}
          />
        </Box>
      }
      actions={<Button onClick={() => goto(routes.RESOURCES_COGNITO_IDENTITY_POOL_UPDATE, { id })}>Edit Pool</Button>}
    >
      <Card>
        <CognitoIdentityPoolDetail identityPool={identityPool} />
      </Card>
    </Layout>
  );
};
