import {
  CloudPodsService,
  getSelectedInstance,
  useApiGetter,
  useAwsEffect,
  retrieveAuthToken,
} from '@localstack/services';
import {
  AwsServiceIcon,
  ControlledAutocomplete,
  ControlledTextField,
  ProgressButton,
  StatusIndicator,
} from '@localstack/ui';
import {
  Box,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  MenuItem,
  Switch,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import createStyles from '@mui/styles/createStyles';
import { CloudUpload as CloudUploadIcon } from '@mui/icons-material';
import { ReactElement, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';

import { LocalStackEvent, ProductFeature, RESOURCES_SERVICES_TYPE } from '@localstack/types';
import { SERVICE_CODES } from '@localstack/constants';

import { useAuthProvider } from '~/hooks/useAuthProvider';

import { DISABLED_TEAM_TOOLTIP_TEXT } from './common';

const SUPPORTED_SERVICE_NAME = Object.values(RESOURCES_SERVICES_TYPE).flat();

const DEFAULT_SAVED_SERVICES = 'All Services';

type PodFormProps = {
  podName: string;
  services?: string[];
};

type ExistingPodFormProps = {
  existingPodName: string;
};

type ExportToCloudPodCardProps = {
  disabled: boolean;
};

const useStyles = makeStyles(() =>
  createStyles({
    cardContent: {
      gap: '1rem',
      display: 'flex',
      flexDirection: 'column',
    },
    cardBox: {
      display: 'flex',
      alignItems: 'center',
    },
    cardActions: {
      display: 'flex',
      justifyContent: 'space-between',
    },
  }),
);

export const ExportToCloudPodCard = ({ disabled }: ExportToCloudPodCardProps): ReactElement => {
  const classes = useStyles();

  const [useExistingPod, setUseExistingPod] = useState(false);
  const [logOutput, setLogOutput] = useState<LocalStackEvent[]>([]);
  const [hasSavedPod, setHasSavedPod] = useState(false);

  const { hasFeature } = useAuthProvider();
  const hasTeamSubscription = hasFeature(ProductFeature.FEATURE_PODS);

  const {
    data: allPods,
    isLoading: isPodLoading,
    mutate,
  } = useApiGetter(CloudPodsService, 'listCloudPods', [], { defaultValue: [] });

  const instance = getSelectedInstance();
  const clientOverrides = instance ? { endpoint: instance.endpoint } : {};

  const { savePodToRemote, isLoading, hasError } = useAwsEffect('LocalStack', ['savePodToRemote'], {
    silentErrors: false,
    clientOverrides,
  });

  const { control, handleSubmit, watch, setValue } = useForm<PodFormProps | ExistingPodFormProps>();
  const podName = watch(useExistingPod ? 'existingPodName' : 'podName');
  const savedServices = watch('services');

  const disableButtons = !hasTeamSubscription || !podName || disabled;

  const onSubmit = async (data: PodFormProps) => {
    setLogOutput([]);
    const authToken = retrieveAuthToken();
    setHasSavedPod(false);
    setLogOutput([{ event: 'status', message: 'Saving pod...' }]);
    if (data.services?.length === 1 && data.services.includes(DEFAULT_SAVED_SERVICES)) {
      data.services = undefined;
    }

    await savePodToRemote(data.podName, authToken?.token, {
      is_public: false,
      services: data.services ? data.services : undefined,
    });
    setHasSavedPod(true);
    mutate();
  };

  useEffect(() => {
    if (hasSavedPod && !isPodLoading && hasError) {
      setLogOutput((prevLogOutput) => [...prevLogOutput, { event: 'error', message: 'Error saving pod' }]);
    }
    if (hasSavedPod && !isPodLoading && !hasError) {
      setLogOutput((prevLogOutput) => [...prevLogOutput, { event: 'status', message: 'Pod successfully saved' }]);
    }
  }, [hasSavedPod, isLoading, hasError]);

  useEffect(() => {
    if (savedServices?.length === 0) {
      setValue('services', [DEFAULT_SAVED_SERVICES]);
    }
    if (savedServices && savedServices?.length > 1 && savedServices?.includes(DEFAULT_SAVED_SERVICES)) {
      setValue(
        'services',
        savedServices.filter((service) => service !== DEFAULT_SAVED_SERVICES),
      );
    }
  }, [savedServices]);

  return (
    <Card>
      <form onSubmit={handleSubmit(onSubmit)}>
        <CardHeader title="Save State to Cloud Pod" />
        <CardContent className={classes.cardContent}>
          <Box className={classes.cardBox}>
            <Typography>New Pod</Typography>
            <Switch
              color="primary"
              checked={useExistingPod}
              onChange={(_, checked: boolean) => setUseExistingPod(checked)}
            />
            <Typography>Existing Pod</Typography>
          </Box>
          {useExistingPod && !isPodLoading && allPods ? (
            <ControlledAutocomplete
              control={control}
              name="existingPodName"
              label="Existing Pod"
              fullWidth
              options={allPods.map((pod) => pod.pod_name)}
              getOptionValue={(item) => item}
              getValueOption={(item) => item}
              renderInput={(params) => <TextField {...params} label="Existing Cloud Pod Name" variant="outlined" />}
              variant="outlined"
            />
          ) : (
            <ControlledTextField
              fullWidth
              label="Cloud Pod Name"
              variant="outlined"
              name="podName"
              control={control}
              rules={
                !useExistingPod
                  ? {
                      validate: (name: string): string | boolean =>
                        !allPods?.some((pod) => pod.pod_name === name) ||
                        'Name already taken by another pod - you can create a new version for it by switching the toggle above',
                    }
                  : undefined
              }
            />
          )}
          <ControlledAutocomplete
            variant="outlined"
            control={control}
            multiple
            name="services"
            disableCloseOnSelect
            fullWidth
            renderInput={(params) => <TextField {...params} label="Saved Services" variant="outlined" />}
            getOptionLabel={(option: string) => option}
            getOptionValue={(items: string[]) => items}
            getValueOption={(item) => item}
            defaultValue={[DEFAULT_SAVED_SERVICES]}
            renderOption={(props, option: string) =>
              option === DEFAULT_SAVED_SERVICES ? (
                <MenuItem {...props} key={option} value={option}>
                  {DEFAULT_SAVED_SERVICES}
                </MenuItem>
              ) : (
                <MenuItem {...props} key={option} value={option}>
                  <AwsServiceIcon code={option} title={option} size="medium" hideTooltip />
                  {SERVICE_CODES[option as keyof typeof SERVICE_CODES] || option}
                </MenuItem>
              )
            }
            options={[DEFAULT_SAVED_SERVICES, ...Object.values(SUPPORTED_SERVICE_NAME)]}
          />
        </CardContent>
        <CardActions className={classes.cardActions}>
          {(isLoading || hasSavedPod) && (
            <StatusIndicator isProgressing={isLoading} logEvents={logOutput} hasError={hasError && !isLoading} />
          )}
          {!isLoading && <div />}
          <Tooltip title={disableButtons ? DISABLED_TEAM_TOOLTIP_TEXT : ''}>
            <div>
              <ProgressButton
                disabled={disableButtons}
                loading={isLoading}
                variant="outlined"
                color="primary"
                type="submit"
                startIcon={<CloudUploadIcon />}
              >
                Create new Pod {useExistingPod ? 'version' : ''}
              </ProgressButton>
            </div>
          </Tooltip>
        </CardActions>
      </form>
    </Card>
  );
};
