import { DEFAULT_AMPLIFY_ROUTES } from '@localstack/constants';
import { ReactElement, useState } from 'react';
import { useAwsEffect, useAwsGetter, useRoutes } from '@localstack/services';
import { Button, Card } from '@mui/material';
import { ConfirmableButton, PageTitle, Dropdown, AmplifyBEEnvironmentsTable } from '@localstack/ui';

import { useParams } from 'react-router-dom';

import { AmplifyProps } from './types';
import { NavTabs } from './components/NavTabs';

export const AmplifyBEEnvironments = ({
  Layout,
  clientOverrides,
  routes = DEFAULT_AMPLIFY_ROUTES,
}: AmplifyProps): ReactElement => {
  const { goto } = useRoutes();
  const [selectedIds, setSelectedIds] = useState<string[]>([]);
  const { appId } = useParams<'appId'>();
  const {
    data: environments,
    isLoading,
    mutate,
  } = useAwsGetter('Amplify', 'listBackendEnvironments', [{ appId }], { clientOverrides });
  const { deleteBackendEnvironment } = useAwsEffect('Amplify', ['deleteBackendEnvironment'], {
    revalidate: ['listBackendEnvironments'],
    clientOverrides,
  });
  const handleRemove = async () => {
    await Promise.all(
      selectedIds.map(async (id) => deleteBackendEnvironment({ appId: appId ?? '', environmentName: id })),
    );
  };
  return (
    <Layout
      title={<PageTitle title="Amplify Backend Environments" onMutate={mutate} />}
      tabs={<NavTabs routes={routes} appId={appId ?? ''} />}
      actions={
        <>
          <Button onClick={() => goto(routes.RESOURCES_AMPLIFY_BE_ENVIRONMENT_CREATE, { appId })}>
            Create Environment
          </Button>
          <Dropdown label="Actions">
            <ConfirmableButton
              componentType="MenuItem"
              disabled={selectedIds.length === 0 || isLoading}
              title={`Delete ${selectedIds.length} Environment(s)?`}
              onClick={handleRemove}
              text="Selected Environments will be permanently deleted"
            >
              Remove Selected
            </ConfirmableButton>
          </Dropdown>
        </>
      }
    >
      <Card>
        <AmplifyBEEnvironmentsTable
          environments={environments?.backendEnvironments ?? []}
          onViewEnvironment={(id: string) =>
            goto(routes.RESOURCES_AMPLIFY_BE_ENVIRONMENT, { appId, environmentName: id })
          }
          loading={isLoading}
          onSelect={setSelectedIds}
        />
      </Card>
    </Layout>
  );
};
