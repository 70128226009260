import { ReactElement, useEffect, useState } from 'react';

import {
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Collapse,
  FormHelperText,
  Grid,
  IconButton,
  styled,
} from '@mui/material';
import { VALIDATION_RULES } from '@localstack/services';
import { useForm } from 'react-hook-form';
import { DEFAULT_RESOURCE_BASE_URL } from '@localstack/constants';

import { KeyboardArrowDown as ArrowDownIcon, KeyboardArrowUp as ArrowUpIcon, Add } from '@mui/icons-material';

import { LocalStackInstance } from '@localstack/types';

import { ControlledTextField } from '../../form';
import { ProgressButton } from '../../feedback';

const INSTANCE_FORM_ID = 'instanceFormBookmark';

type InstanceBookmarkCardProps = {
  instance: LocalStackInstance;
  onSave?: (instance: LocalStackInstance) => void;
  onCancel?: () => void;
  isSaving?: boolean;
  disabled?: boolean;
};

const StyledCard = styled(Card)(
  () =>
    `
      height: 100%,
      display: flex,
      flex-direction: column,
`,
);
const StyledCardHeader = styled(CardHeader)(
  () =>
    `
        display: flex,
      align-items: center,
      gap: 0.75rem,
  `,
);
const StyledCardContent = styled(CardContent)(
  () =>
    `
      flex-grow: 1,
      display: flex,
      gap: 0.5rem,
      flex-direction: column,
  `,
);

export const InstanceBookmarkCard = ({
  instance,
  onSave,
  onCancel,
  isSaving,
  disabled = false,
}: InstanceBookmarkCardProps): ReactElement => {
  const { control, handleSubmit, formState, reset } = useForm<LocalStackInstance>({ mode: 'all' });

  const submitHandler = (lsInstance: LocalStackInstance) => {
    onSave?.(lsInstance);
  };

  const [isCollapsed, setIsCollapsed] = useState(false);

  const isNewBookmark = !!onCancel;

  useEffect(() => {
    reset(instance);
  }, [instance]);

  const bookmarkTitle = isNewBookmark ? 'Add new instance bookmark' : 'Edit instance bookmark';

  return (
    <StyledCard>
      <form onSubmit={handleSubmit(submitHandler)} id={INSTANCE_FORM_ID}>
        <StyledCardHeader
          title={bookmarkTitle}
          titleTypographyProps={{ style: { fontWeight: 'bold' } }}
          action={
            <IconButton size="small" onClick={() => setIsCollapsed(!isCollapsed)}>
              {isCollapsed ? <ArrowDownIcon /> : <ArrowUpIcon />}
            </IconButton>
          }
        />
        <Collapse in={!isCollapsed}>
          <StyledCardContent>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <ControlledTextField
                  control={control}
                  fullWidth
                  label="Name"
                  name="name"
                  disabled={disabled}
                  variant="outlined"
                  type="text"
                  rules={VALIDATION_RULES.required}
                />
              </Grid>
              <Grid item xs={12}>
                <ControlledTextField
                  control={control}
                  fullWidth
                  label="Endpoint"
                  name="endpoint"
                  variant="outlined"
                  type="text"
                  rules={VALIDATION_RULES.required}
                  aria-describedby="textfield-helper-text"
                />
                <FormHelperText id="textfield-helper-text" style={{ marginTop: 0, paddingBottom: '0.5rem' }}>
                  Endpoint URL under which LocalStack is accessible. <br />
                  (default is <code>{DEFAULT_RESOURCE_BASE_URL}</code>)
                </FormHelperText>
              </Grid>
              <Grid item xs={12}>
                <ControlledTextField
                  control={control}
                  fullWidth
                  label="Description"
                  name="description"
                  variant="outlined"
                  type="text"
                />
              </Grid>
            </Grid>
          </StyledCardContent>
          <CardActions>
            {onCancel && (
              <Button variant="outlined" onClick={onCancel} disabled={disabled}>
                Cancel
              </Button>
            )}
            <ProgressButton
              color="primary"
              loading={isSaving}
              startIcon={<Add />}
              variant="contained"
              type="submit"
              disabled={!formState.isValid}
            >
              Save Bookmark
            </ProgressButton>
          </CardActions>
        </Collapse>
      </form>
    </StyledCard>
  );
};
