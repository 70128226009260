import { ReactElement, useState, useCallback } from 'react';
import { useRoutes, useAwsEffect, useAwsGetter } from '@localstack/services';
import { Card, Button } from '@mui/material';

import { Dropdown, ConfirmableButton, PageTitle, OpenSearchDomainsTable } from '@localstack/ui';

import { DEFAULT_OPENSEARCH_ROUTES } from '@localstack/constants';

import { OpenSearchProps } from './types';

export const OpenSearchDomains = ({
  Layout,
  clientOverrides,
  routes = DEFAULT_OPENSEARCH_ROUTES,
}: OpenSearchProps): ReactElement => {
  const [selectedIds, setSelectedIds] = useState<string[]>([]);
  const { goto } = useRoutes();
  const { data: domains, isLoading, mutate } = useAwsGetter('OpenSearch', 'listDomainNames', [], { clientOverrides });

  const { deleteDomain } = useAwsEffect('OpenSearch', ['deleteDomain'], {
    revalidate: ['listDomainNames'],
    clientOverrides,
  });

  const handleDeleteSelected = useCallback(async () => {
    const promises = selectedIds.map((DomainName) => deleteDomain({ DomainName }));
    await Promise.all(promises);
  }, [selectedIds]);

  return (
    <Layout
      title={<PageTitle title="OpenSearch Domains" onMutate={mutate} />}
      actions={
        <>
          <Button onClick={() => goto(routes.RESOURCES_OPENSEARCH_DOMAIN_CREATE)}>Create Domain</Button>
          <Dropdown label="Actions">
            <ConfirmableButton
              componentType="MenuItem"
              disabled={selectedIds.length === 0 || isLoading}
              title={`Delete ${selectedIds.length} Domain(s)?`}
              onClick={handleDeleteSelected}
              text="Selected Domains will be permanently deleted"
            >
              Remove Selected
            </ConfirmableButton>
          </Dropdown>
        </>
      }
    >
      <Card>
        <OpenSearchDomainsTable
          domains={domains?.DomainNames ?? []}
          loading={isLoading}
          onSelect={setSelectedIds}
          onViewDomain={({ DomainName }) => goto(routes.RESOURCES_OPENSEARCH_DOMAIN, { domainName: DomainName })}
        />
      </Card>
    </Layout>
  );
};
