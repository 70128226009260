import { Breakpoint } from '@mui/material/styles';

import { AllowedPlanChipPlans } from './localstack';

export interface Layout {
  /** Attribute set in the DOM to base element of the current page */
  pageName?: string;
  /** Any components to be rendered within the layout e.g. header and page content */
  children?: React.ReactNode;
  /** The name displayed in the layout page */
  title?: JSX.Element | string;
  /** The name displayed in the browser tab */
  documentTitle?: string;
  /** The navbar component that page expects */
  navigation?: React.ReactNode;
  /** Whether the navbar component should be hidden */
  hideNavigation?: boolean;
}

export type BaseHeaderProps = Pick<Layout, 'title' | 'children'> & {
  planFamily?: AllowedPlanChipPlans;
  /** Any actions that should appear in the header, such as buttons etc */
  actions?: React.ReactNode;
  maxWidth?: Breakpoint;
  fullScreen?: boolean;
  tabs?: React.ReactNode;
};

export type ComplexLayoutProps = Layout & BaseHeaderProps;
