import { getSchema } from '@localstack/services';
import { IdentityPool } from '@localstack/types';
import { ReactElement } from 'react';

import { MagicDetails } from '../../magic/MagicDetails';

export type CognitoIdentityIdentityPoolDetailProps = {
  identityPool?: Optional<Partial<IdentityPool>>;
};

export const CognitoIdentityPoolDetail = ({ identityPool }: CognitoIdentityIdentityPoolDetailProps): ReactElement => (
  <MagicDetails
    data={identityPool}
    schema={getSchema('CognitoIdentity')}
    entry="IdentityPool"
    title="Cognito Identity Pool Details"
  />
);
