import { ReactElement } from 'react';
import { Route53ResourceRecordSet } from '@localstack/types';
import { getSchema } from '@localstack/services';

import { MagicDetails } from '../../../magic/MagicDetails';

export interface Route53HostedZoneRecordDetailsProps {
  record?: Optional<Partial<Route53ResourceRecordSet>>;
}

export const Route53HostedZoneRecordDetails = ({ record }: Route53HostedZoneRecordDetailsProps): ReactElement => (
  <MagicDetails data={record} schema={getSchema('Route53')} entry="ResourceRecordSet" title="Record Details" />
);
