import { ReactElement } from 'react';
import { PlanFamily } from '@localstack/types';
import { PageTitle } from '@localstack/ui';
import { useRoutes } from '@localstack/services';

import { ContainedCustomerLayout } from '~/layouts';
import { AppRoute } from '~/config';

import { CIProjectCard } from './components/CIProjectCard';

export const CIProjectsCreate = (): ReactElement => {
  const { goto } = useRoutes();

  return (
    <ContainedCustomerLayout
      title={
        <PageTitle
          title="CI Project Details"
          breadcrumbs={[
            ['CI Projects', () => goto(AppRoute.CI_PROJECTS)],
            ['Create new Project', undefined],
          ]}
          planFamily={PlanFamily.ENTERPRISE_PLANS}
        />
      }
    >
      <CIProjectCard />
    </ContainedCustomerLayout>
  );
};
