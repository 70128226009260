import { ReactElement } from 'react';
import { TextField } from '@mui/material';
import { buildAgwPatches, getSchema } from '@localstack/services';

import { AgwRestApiModel, CreateAgwRestApiModelRequest, UpdateAgwRestApiModelRequest } from '@localstack/types';

import { MagicForm } from '../../../magic/MagicForm';
import { ControlledTextarea } from '../../../../form';

export interface AgwRestApiModelFormProps {
  restApiId: string;
  model?: Optional<Partial<AgwRestApiModel>>;
  formId?: string;
  loading?: boolean;
  onCreate: (data: CreateAgwRestApiModelRequest) => unknown;
  onUpdate: (data: UpdateAgwRestApiModelRequest) => unknown;
}

export const AgwRestApiModelForm = ({
  restApiId,
  model,
  loading,
  formId,
  onCreate,
  onUpdate,
}: AgwRestApiModelFormProps): ReactElement => (
  <MagicForm
    data={model}
    schema={getSchema('APIGateway')}
    loading={loading}
    entry="CreateModelRequest"
    formId={formId}
    externalFields={{
      '^restApiId$': () => <TextField fullWidth disabled value={restApiId} variant="outlined" />,
      '^schema$': (control, name) => (
        <ControlledTextarea control={control} name={name} placeholder="Schema" minRows={10} fullWidth />
      ),
    }}
    onSubmit={(data: CreateAgwRestApiModelRequest) => {
      if (!model) return onCreate({ ...data, restApiId });
      const patchOperations = buildAgwPatches(model, data);
      return onUpdate({ restApiId, modelName: model.name as string, patchOperations });
    }}
  />
);
