import { ReactElement, useEffect, useState } from 'react';
import { Breadcrumbs, CodeVisualizer, ProgressButton } from '@localstack/ui';
import { CloudPod } from '@localstack/types';
import { ExternalLink } from '@localstack/constants';
import { useAwsGetter, useInjectPodState, useRoutes, useSnackbar } from '@localstack/services';
import { Alert, Box, Grid, Link, Typography } from '@mui/material';
import { Cloud, Image } from '@mui/icons-material';

import { ContainedCustomerLayout } from '~/layouts';
import { AppRoute } from '~/config';

import StepperCard, { StepperItem } from '../components/StepperCard';

const infraPod: CloudPod = {
  id: '-',
  is_public: false,
  max_version: 1,
  org_id: '-',
  pod_name: 'ecs-apigateway-infra',
};

const fePod: CloudPod = {
  id: '-',
  is_public: true,
  max_version: 1,
  org_id: '-',
  pod_name: 'ecs-apigateway-fe',
};

const testPod: CloudPod = {
  id: '-',
  is_public: true,
  max_version: 1,
  org_id: '-',
  pod_name: 'ecs-apigateway-db',
};

const MonospacedText: React.FC<{ text: string }> = ({ text }) => (
  <span style={{ fontFamily: 'monospace' }}>{text}</span>
);

export const DemoFive = (): ReactElement => {
  const { goto } = useRoutes();
  const { showSnackbar } = useSnackbar();

  const [sampleAppURL, setSampleAppURL] = useState('');

  const {
    inject: injectInfra,
    injected: injectedInfra,
    isLoading: loadingInfra,
    isError: hasErrorInfra,
  } = useInjectPodState();
  const { inject: injectFe, injected: injectedFe, isLoading: loadingFe, isError: hasErrorFe } = useInjectPodState();
  const { inject: injectTest, isLoading: loadingTest, isError: hasErrorTest } = useInjectPodState();

  useEffect(() => {
    if (hasErrorInfra || hasErrorFe || hasErrorTest) {
      return showSnackbar({ severity: 'error', message: 'Unable to load the Cloud Pod' });
    }
  }, [hasErrorInfra, hasErrorFe, hasErrorTest]);

  useEffect(() => {
    if (injectedInfra || injectedFe) {
      showSnackbar({ severity: 'success', message: 'Cloud Pod loaded successfully' });
    }
  }, [injectedFe, injectedInfra]);

  // query app details from LocalStack APIs
  const { data: apis, mutate: mutateApis } = useAwsGetter('ApiGatewayV2', 'getApis');
  const { data: userPools, mutate: mutatePools } = useAwsGetter('CognitoIdentityServiceProvider', 'listUserPools', [
    { MaxResults: 10 },
  ]);
  const api = apis?.Items?.find((_api) => _api.Name === 'ecsapi-demo');
  const userPool = userPools?.UserPools?.find((pool) => pool.Name?.startsWith('UserPool-'));
  const { data: userPoolClients, mutate: mutatePoolClients } = useAwsGetter(
    'CognitoIdentityServiceProvider',
    'listUserPoolClients',
    [{ UserPoolId: userPool?.Id }],
  );
  const userPoolClient = userPoolClients?.UserPoolClients?.find((client) =>
    client.ClientName?.startsWith('UserPoolClient-'),
  );

  useEffect(() => {
    mutateApis();
    mutatePools();
  }, [injectedInfra, injectedFe]);

  useEffect(() => {
    mutatePoolClients();
  }, [userPool?.Id]);

  useEffect(() => {
    if (!injectedFe) return;
    if (!api || !userPool || !userPoolClient) return;
    const domain = 'sample-app.s3.localhost.localstack.cloud:4566';
    const url =
      `http://${domain}/index.html?stackregion=us-east-1&stackhttpapi=${api.ApiId}&` +
      `stackuserpool=${userPool.Id}&stackuserpoolclient=${userPoolClient.ClientId}`;
    setSampleAppURL(url);
  }, [injectedFe, api?.ApiId, userPool?.Id, userPoolClient?.ClientId]);

  const stepperItems: StepperItem[] = [
    {
      content: (
        <Grid container spacing={2}>
          <Typography paragraph>
            This sample demonstrates the deployment of a serverless application in LocalStack from multiple{' '}
            <b>Cloud Pods</b>. It also shows how organizations can leverage Cloud Pods for cross-team collaboration. The
            full source code of this sample is available in this Github repo:{' '}
            <Link href={ExternalLink.SAMPLE_TF_ECS_AGW} underline="hover">
              {ExternalLink.SAMPLE_TF_ECS_AGW}.
            </Link>
          </Typography>
          <Typography paragraph>The following diagram shows the architecture of the application:</Typography>
          <Grid item xs={12}>
            <img alt="" src="/demo5/serverless-container-api.png" width="70%" />
          </Grid>
          <Typography paragraph>
            ECS (Elastic Container Service) is used to deploy two containerized applications, consisting of a{' '}
            <Link href={ExternalLink.DEMO_PET_STORE} underline="hover">
              pet store
            </Link>{' '}
            and a{' '}
            <Link href={ExternalLink.DEMO_FOOD_STORE} underline="hover">
              food store
            </Link>
            , respectively. For convenience, the two images are already hosted on Docker Hub.
          </Typography>
          <Typography paragraph>
            API Gateway is used to expose the two applications to the outside world. It exposes two endpoints for each
            containerized application, a <MonospacedText text="GET" /> and <MonospacedText text="PUT" /> endpoint. The{' '}
            <MonospacedText text="GET" /> endpoint can be used by unauthenticated users, while the{' '}
            <MonospacedText text="PUT" /> endpoint needs authentication, which is handled by Cognito.
          </Typography>
          <Typography paragraph>
            S3 is used to deploy a React application with Amplify, allowing users to interact with the application.
            Finally, the two services are connected to a DynamoDB table, which is used to store and retrieve the data.
          </Typography>

          {/* Example team division */}
          <Typography paragraph>
            Let us imagine a real world scenario, where different teams work together to implement this application. A{' '}
            <strong>platform</strong> team would be responsible to deploy the infrastructure, while a{' '}
            <strong>frontend</strong> team would be responsible to develop the frontend application that will be hosted
            on S3.
          </Typography>
          <Typography paragraph>
            In this scenario, the platform team would prepare a Cloud Pod with the infrastructure for the frontend team,
            which would then only need to take care of the frontend application deployed via S3. Moreover, a{' '}
            <strong>QA</strong> team would be responsible to prepare some test data. Therefore, it could prepare a Cloud
            Pod containing some test data for the DynamoDB tables used by the containerized services.
          </Typography>
        </Grid>
      ),
      title: 'Application Overview',
      icon: <Image />,
    },
    {
      content: (
        <Grid container spacing={2}>
          <Box mb={2} width="100%">
            <Typography paragraph>
              As mentioned, the platform team is responsible for providing a Cloud Pod with the overall infrastructure.
              After deploying the infrastructure onto LocalStack, they can create a Cloud Pod and make it available to
              the entire organization with the following command:
            </Typography>
            <CodeVisualizer commands={['localstack pod save ecs-apigateway-infra']} />
            <Typography paragraph>
              Every other co-worker can now load this state with the command below. Clicking on the{' '}
              <i>&apos;Load Infrastructure Cloud Pod&apos;</i> button below will achieve the same result.
            </Typography>
            <CodeVisualizer commands={['localstack pod load ecs-apigateway-infra']} />
            <ProgressButton
              color="primary"
              variant="contained"
              loading={loadingInfra}
              onClick={() => injectInfra(infraPod)}
            >
              Load Infrastructure Cloud Pod
            </ProgressButton>
          </Box>
          {/* Inject now the FE Cloud Pod */}
          <Box mb={2} width="100%">
            <Typography paragraph>
              In the meantime, the front end team is working independently on the Web UI for the application. They can
              simply load the platform pod and then deploy their website to an S3 bucket. Similarly, they can create
              their own Cloud Pod to make the full application easily deployable by loading the two Cloud Pods.
            </Typography>
            <Typography paragraph>
              Therefore, now loading the two Cloud Pods would result in deploying the full application. Click on the
              button below to load also the second Pod containing the frontend.
            </Typography>
            <ProgressButton color="primary" variant="contained" loading={loadingFe} onClick={() => injectFe(fePod)}>
              Load Front End Cloud Pod
            </ProgressButton>
          </Box>
          <Box mb={2} width="100%">
            <Typography paragraph>The alternative CLI command would be the following:</Typography>
            <CodeVisualizer commands={['localstack pod load ecs-apigateway-fe']} />
            {injectedFe && (
              <Alert severity="info" style={{ marginTop: '2rem' }}>
                You can now browse the application deployed at the following:{' '}
                <Link href={sampleAppURL} underline="hover">
                  URL.
                </Link>
                The same URL can be obtained with the following commands:
                <CodeVisualizer
                  commands={[
                    'export API_ID=$(awslocal apigatewayv2 get-apis | jq -r \'.Items[] | select(.Name=="ecsapi-demo") | .ApiId\')',
                    "export POOL_ID=$(awslocal cognito-idp list-user-pools --max-results 1 | jq -r '.UserPools[0].Id')",
                    "export CLIENT_ID=$(awslocal cognito-idp list-user-pool-clients --user-pool-id $POOL_ID | jq -r '.UserPoolClients[0].ClientId')",
                    'export URL="http://sample-app.s3.localhost.localstack.cloud:4566/index.html?stackregion=us-east-1&stackhttpapi=$API_ID&stackuserpool=$POOL_ID&stackuserpoolclient=$CLIENT_ID"',
                    'echo $URL',
                  ]}
                />
              </Alert>
            )}
          </Box>
        </Grid>
      ),
      icon: <Cloud />,
      title: 'Load Infrastructure & Front End Cloud Pods',
      disableContinue: !injectedInfra && !injectedFe,
    },
    {
      content: (
        <Grid container spacing={2}>
          <Box mb={4}>
            <Typography>
              <Typography paragraph>
                We now have the full application deployed with Cloud Pods, and we can issue{' '}
                <MonospacedText text="GET" /> and <MonospacedText text="PUT" /> calls to our API endpoints and interact
                with them. However, right after the deployment, the DynamoDB tables in use by the APIs are empty. In a
                testing scenario (e.g., executing some tests in CI), it might be handy to have some easily loadable seed
                data available, which we can load into the LocalStack instance.
              </Typography>
              <Typography paragraph>
                The QA team, which is devoted to testing, can prepare a Cloud Pod with some seed data and make it
                available to the entire organization. To do that, they can populate the database (making{' '}
                <MonospacedText text="PUT" /> calls or programmatically), and then save only the state of the DynamoDB
                tables with the following command:
              </Typography>
              <CodeVisualizer commands={['localstack pod save --services dynamodb ecs-apigateway-db']} />
              <Typography paragraph>
                The command above will save a Cloud Pod containing only the state of the DynamoDB tables. As mentioned,
                it can be loaded <strong>on top</strong> of the other Cloud Pods to deploy the application and seed it
                with some data. In a similar fashion, we could create a Cloud Pod with a Cognito user, so we do not have
                to go through the sign-up process after the deployment.
              </Typography>
              <Typography paragraph>
                Let us now load the Cloud Pod containing the seed data by clicking the &apos;Load Seed Data&apos;
                button, and try to to interact with the application by making a <MonospacedText text="GET" /> call to
                the <em>petstore</em> service (using IDs <MonospacedText text="1" />, <MonospacedText text="2" />, or{' '}
                <MonospacedText text="3" />
                ).
              </Typography>
            </Typography>
            <ProgressButton
              color="primary"
              variant="contained"
              loading={loadingTest}
              onClick={() => injectTest(testPod)}
            >
              Load Seed Data
            </ProgressButton>
          </Box>
        </Grid>
      ),
      icon: <Cloud />,
      title: 'Load Seed Data',
    },
  ];

  return (
    <ContainedCustomerLayout
      documentTitle="Serverless Container-Based API Application deployed with Cloud Pods layers"
      title={
        <Box>
          <Typography variant="h4">
            Serverless Container-Based API Application deployed with Cloud Pods layers
          </Typography>
          <Breadcrumbs
            mappings={[
              ['Quickstart', () => goto(AppRoute.QUICKSTART)],
              ['Serverless Container-Based API Application deployed with CLoud Pods layers', null],
            ]}
          />
        </Box>
      }
    >
      <StepperCard stepperItems={stepperItems} />
    </ContainedCustomerLayout>
  );
};
