import { AwsClientOverrides } from '@localstack/services';
import { TextField, Button, RadioGroup, FormControlLabel, Radio, Stack, Box } from '@mui/material';
import { useForm, Controller } from 'react-hook-form';

import { RelatedResourcePicker } from '@localstack/ui';

export enum CredentialType {
  DatabaseCredentials = 'databaseCredentials',
  SecretsARN = 'secretsARN',
}

export interface SecretFormData {
  credentialType: CredentialType;
  username?: string;
  password?: string;
  secretsManagerArn?: string;
}

type SecretFormProps = {
  onSubmit: (data: SecretFormData) => Promise<void>;
  clientOverrides?: AwsClientOverrides;
};

/**
 * Form used for the user to either add credentials to SecretManager or pick from an existing secret ARN.
 */
export const RDSSecretForm = (props: SecretFormProps): JSX.Element => {
  const { register, handleSubmit, watch, control } = useForm<SecretFormData>({
    defaultValues: {
      credentialType: CredentialType.DatabaseCredentials,
      username: '',
      password: '',
      secretsManagerArn: '',
    },
  });

  const watchedCredentialType = watch('credentialType');

  const onSubmit = async (data: SecretFormData) => {
    await props.onSubmit(data);
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Stack spacing={3}>
        <Controller
          name="credentialType"
          control={control}
          render={({ field }) => (
            <RadioGroup {...field}>
              <FormControlLabel
                value={CredentialType.DatabaseCredentials}
                control={<Radio />}
                label="Add new database credentials"
              />
              <FormControlLabel
                value={CredentialType.SecretsARN}
                control={<Radio />}
                label="Choose from an existing Secrets Manager ARN"
              />
            </RadioGroup>
          )}
        />
        {watchedCredentialType === CredentialType.DatabaseCredentials ? (
          <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
            <TextField {...register('username')} label="Enter database username" fullWidth required />
            <TextField label="Enter database password" {...register('password')} type="password" fullWidth required />
          </Box>
        ) : (
          <div style={{ width: '100%' }}>
            <RelatedResourcePicker
              control={control}
              client="SecretsManager"
              method="listSecrets"
              arrayKeyName="SecretList"
              property="ARN"
              fieldName={'secretsManagerArn'}
              entityName="secretsManagerArn"
              required
              clientOverrides={props.clientOverrides}
            />
          </div>
        )}
        <Button type="submit" variant="outlined" color="primary" sx={{ alignSelf: 'flex-end' }}>
          Save & Continue
        </Button>
      </Stack>
    </form>
  );
};
