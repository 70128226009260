import { useState, useCallback, ReactElement, FormEvent, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Card, CardContent, Box, CardActions, Typography, Stack, Alert, AlertTitle } from '@mui/material';
import {
  RDSResultsTable,
  CodeEditor,
  ProgressButton,
  Breadcrumbs,
  RDSSecretForm,
  CredentialType,
  SecretFormData,
} from '@localstack/ui';
import { useAwsEffect, useAwsGetter, useRoutes, useSnackbar } from '@localstack/services';

import { DEFAULT_RDS_ROUTES } from '@localstack/constants';

import { RDSQueryParams, RDSQueryResults, SMPutSecretValueRequest } from '@localstack/types';

import { InstanceNavTabs } from './components';

import { RDSProps } from './types';

const PAGE_SIZE = 50;

export const RDSDBInstanceQuery = ({
  Layout,
  clientOverrides,
  routes = DEFAULT_RDS_ROUTES,
}: RDSProps): ReactElement => {
  const { goto } = useRoutes();
  const { instanceId } = useParams<'instanceId'>();
  const { showSnackbar } = useSnackbar();

  const [statement, setStatement] = useState('SHOW TABLES');
  const [defaultSecretArn, setDefaultSecretArn] = useState<string | undefined>(undefined); // state containing the arn based on the instance being used
  const [secretArn, setSecretArn] = useState<string | undefined>(undefined); // state containing the secret Arn to be used by the query
  const [result, setResult] = useState<RDSQueryResults>();

  const { data: instances } = useAwsGetter('RDS', 'describeDBInstances', [{ DBInstanceIdentifier: instanceId }], {
    clientOverrides,
  });

  const instance = instances?.DBInstances?.find((inst) => inst.DBInstanceIdentifier === instanceId);

  const { data: clusters } = useAwsGetter(
    'RDS',
    'describeDBClusters',
    [{ DBClusterIdentifier: instance?.DBClusterIdentifier }],
    { clientOverrides },
  );

  const { putSecretValue, createSecret, describeSecret } = useAwsEffect(
    'SecretsManager',
    ['putSecretValue', 'createSecret', 'describeSecret'],
    {
      silentErrors: true,
      clientOverrides,
    },
  );

  useEffect(() => {
    async function handleSecret() {
      if (instance === undefined) {
        return;
      }
      const secretName = `rds-db-credentials/${instance.DBClusterIdentifier}/${instance.DBInstanceIdentifier}`;

      const existingSecret = await describeSecret({ SecretId: secretName });

      if (existingSecret) {
        setDefaultSecretArn(existingSecret.ARN);
      } else {
        const newSecret = await createSecret({ Name: secretName });
        setDefaultSecretArn(newSecret.ARN);
      }
    }

    handleSecret();
  }, [instance]);

  const { executeStatement, isLoading } = useAwsEffect('RDSDataService', ['executeStatement'], {
    clientOverrides,
  });

  const handleExecuteStatement = useCallback(
    async (e: FormEvent<HTMLFormElement>) => {
      e.preventDefault();

      const clusterArn = clusters?.DBClusters?.[0]?.DBClusterArn;
      const secretArnToUse = secretArn ?? defaultSecretArn;

      if (!clusterArn || !secretArnToUse) {
        return;
      }

      try {
        const params: RDSQueryParams = {
          resourceArn: clusterArn,
          secretArn: secretArnToUse,
          sql: statement,
          database: instance?.DBName,
          includeResultMetadata: true,
        };

        const queryResult = await executeStatement(params);

        setResult(queryResult);
      } catch (error) {
        const errorMessage = error?.message ?? String(error);
        showSnackbar({
          message: `Error executing statement: ${errorMessage}`,
          severity: 'error',
        });
      }
    },
    [statement, clusters, defaultSecretArn],
  );

  const handleSaveCredentials = async (data: SecretFormData): Promise<void> => {
    if (!defaultSecretArn) {
      return;
    }

    let newSecretArn = data.secretsManagerArn;

    try {
      if (data.credentialType == CredentialType.DatabaseCredentials) {
        newSecretArn = defaultSecretArn;

        const params: SMPutSecretValueRequest = {
          SecretId: newSecretArn,
          SecretString: JSON.stringify({
            username: data.username,
            password: data.password,
          }),
        };

        await putSecretValue(params);
      }
      setSecretArn(newSecretArn);

      showSnackbar({
        message: 'Credentials successfully stored',
        severity: 'success',
      });
    } catch (error) {
      const errorMessage = error?.message ?? String(error);
      showSnackbar({
        message: `Error occurred whilst trying to save credentials: ${errorMessage}`,
        severity: 'error',
      });
    }
  };

  return (
    <Layout
      documentTitle="Instance Query"
      tabs={<InstanceNavTabs instanceId={instanceId as string} routes={routes} />}
      title={
        <Box>
          <Typography variant="h4">Instance Query</Typography>
          <Breadcrumbs
            mappings={[
              ['RDS', () => goto(routes.RESOURCES_RDS_DATABASES)],
              ['Databases', () => goto(routes.RESOURCES_RDS_DATABASES)],
              [`${instanceId} (${instance?.DBName})`, () => goto(routes.RESOURCES_RDS_INSTANCE, { instanceId })],
              ['Query', null],
            ]}
          />
        </Box>
      }
    >
      <Stack spacing={3}>
        {clusters && !clusters?.DBClusters?.[0]?.Engine?.includes('aurora') && (
          <Alert severity="warning">
            <AlertTitle>
              <Typography variant="h6" style={{ fontWeight: 'bold' }}>
                Current cluster is not an Aurora DB cluster
              </Typography>
            </AlertTitle>
            <Typography variant="body1">
              RDS Data API used to send queries can only function on Aurora DB clusters.
            </Typography>
          </Alert>
        )}
        <Card sx={{ gap: 3, display: 'flex', flexDirection: 'column', padding: '26px', fontSize: '14px' }}>
          You need to enter the database credentials to execute a query. We will be storing your credentials in the AWS
          Secret Manager service.
          <RDSSecretForm onSubmit={handleSaveCredentials} />
        </Card>
        <Card variant="outlined">
          <form id="statement" onSubmit={handleExecuteStatement}>
            <CardContent>
              <CodeEditor language="sql" setValue={setStatement} value={statement ?? ''} />
            </CardContent>
            <CardActions>
              <ProgressButton type="submit" form="statement" color="primary" variant="outlined" loading={isLoading}>
                Execute
              </ProgressButton>
            </CardActions>
          </form>
        </Card>
        <Card>
          <RDSResultsTable pageSize={PAGE_SIZE} results={result} loading={isLoading} />
        </Card>
      </Stack>
    </Layout>
  );
};
