import { useState, useEffect, ReactElement } from 'react';
import { useParams, useSearchParams } from 'react-router-dom';
import { AdminService, useApiGetter, useRoutes } from '@localstack/services';

import { Box, Tabs, Tab } from '@mui/material';
import { DecorativeDivider, PageTitle } from '@localstack/ui';

import { AppRoute } from '~/config';
import { AdminLayout } from '~/layouts';

import { BasicDetails } from './BasicDetails';
import { Subscriptions } from './Subscriptions';
import { PaymentMethods } from './PaymentMethods';
import { Members } from './Members';
import { KeysAndLicenses } from './KeysAndLicenses';
import { AuditTrail } from './AuditTrail';
import { Actions } from './Actions';
import { Features } from './Features';

enum OrganizationTab {
  BASIC_DETAILS = 'basic-details',
  SUBSCRIPTIONS = 'subscriptions',
  MEMBERS = 'members',
  KEYS_AND_LICENSES = 'keys-and-licenses',
  AUDIT_TRAIL = 'audit-trail',
  FEATURES = 'features',
  DANGER_ZONE = 'danger-zone',
}

export const Organization = (): ReactElement => {
  const [queryParams, setQueryParams] = useSearchParams();

  const { id: orgId } = useParams<'id'>() as { id: string };
  const { goto } = useRoutes();

  const [currentTab, setCurrentTab] = useState<OrganizationTab>(OrganizationTab.BASIC_DETAILS);
  const { data: organization } = useApiGetter(AdminService, 'getOrganization', [orgId]);

  useEffect(() => {
    const tab = queryParams.get('tab');

    if (tab) {
      setCurrentTab(tab as OrganizationTab);
    }
  }, []);

  useEffect(() => {
    queryParams.set('tab', currentTab);
    setQueryParams(queryParams);
  }, [currentTab]);

  return (
    <AdminLayout
      documentTitle="Organization Details"
      title={
        <PageTitle
          title={`Organization ${organization?.name ?? orgId}`}
          breadcrumbs={[
            ['Organizations', () => goto(AppRoute.ADMIN_ORGANIZATIONS)],
            [organization?.name ?? orgId, () => null],
          ]}
        />
      }
    >
      <Tabs
        value={currentTab}
        onChange={(_, tab: OrganizationTab) => setCurrentTab(tab)}
        indicatorColor="primary"
        textColor="primary"
      >
        <Tab value={OrganizationTab.BASIC_DETAILS} label="Basic Details" />
        <Tab value={OrganizationTab.SUBSCRIPTIONS} label="Subscriptions" />
        <Tab value={OrganizationTab.MEMBERS} label="Members" />
        <Tab value={OrganizationTab.KEYS_AND_LICENSES} label="Keys and Licenses" />
        <Tab value={OrganizationTab.AUDIT_TRAIL} label="Audit Trail" />
        <Tab value={OrganizationTab.FEATURES} label="Features" />
        <Tab value={OrganizationTab.DANGER_ZONE} label="Danger Zone" />
      </Tabs>
      {currentTab === OrganizationTab.BASIC_DETAILS && (
        <Box mt={2}>
          <BasicDetails orgId={orgId} />
        </Box>
      )}
      {currentTab === OrganizationTab.SUBSCRIPTIONS && (
        <>
          <Box mt={2}>
            <Subscriptions orgId={orgId} />
          </Box>
          <Box mt={2} mb={2}>
            <DecorativeDivider />
          </Box>
          <Box mt={2}>
            <PaymentMethods orgId={orgId} />
          </Box>
        </>
      )}
      {currentTab === OrganizationTab.MEMBERS && (
        <Box mt={2}>
          <Members orgId={orgId} />
        </Box>
      )}
      {currentTab === OrganizationTab.KEYS_AND_LICENSES && (
        <>
          <Box mt={2}>
            <KeysAndLicenses orgId={orgId} />
          </Box>
        </>
      )}
      {currentTab === OrganizationTab.AUDIT_TRAIL && (
        <Box mt={2}>
          <AuditTrail orgId={orgId} />
        </Box>
      )}
      {currentTab === OrganizationTab.FEATURES && (
        <Box mt={2}>
          <Features orgId={orgId} />
        </Box>
      )}
      {currentTab === OrganizationTab.DANGER_ZONE && (
        <Box mt={2}>
          <Actions orgId={orgId} />
        </Box>
      )}
    </AdminLayout>
  );
};
