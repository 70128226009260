import { ReactElement } from 'react';
import { NavLink } from '@localstack/ui';
import { buildRoute } from '@localstack/services';

import { ApiGatewayRoutes } from '../../types';

interface Props {
  routes: ApiGatewayRoutes;
}

export const IndexNavTabs = ({ routes }: Props): ReactElement => (
  <>
    <NavLink to={buildRoute(routes.RESOURCES_AGW2)} pro>
      Gateway V2
    </NavLink>
    <NavLink to={buildRoute(routes.RESOURCES_AGW1)}>Gateway V1</NavLink>
  </>
);
