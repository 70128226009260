import { ReactElement } from 'react';
import { GlueJobRun } from '@localstack/types';
import { getSchema } from '@localstack/services';
import { Link } from '@mui/material';

import { MagicTable } from '../../../magic/MagicTable';

export type GlueJobRunsTableProps = {
  jobRuns: GlueJobRun[];
  selectable?: boolean;
  loading?: boolean;
  onSelect?: (names: string[]) => void;
  onViewJobRun?: (name: string) => void;
};

export const GlueJobRunsTable = ({
  jobRuns,
  selectable = true,
  loading,
  onViewJobRun,
}: GlueJobRunsTableProps): ReactElement => (
  <MagicTable
    schema={getSchema('Glue')}
    entry="JobRunList"
    idAttribute="Id"
    rows={jobRuns}
    selectable={selectable}
    loading={loading}
    filterColumns={[
      'Id',
      'Attempt',
      'JobRunState',
      'ExecutionTime',
      'PreviousRunId',
      'JobName',
      'TriggerName',
      'StartedOn',
    ]}
    order={['Id', 'Attempt', 'JobRunState', 'ExecutionTime', 'PreviousRunId', 'JobName', 'TriggerName', 'StartedOn']}
    externalFields={{
      Id: (row) => (
        <Link onClick={() => onViewJobRun && onViewJobRun(row.Id as string)} underline="hover">
          {row.Id}
        </Link>
      ),
    }}
  />
);
