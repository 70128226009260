import { ReactElement } from 'react';
import { NavLink } from '@localstack/ui';
import { buildRoute } from '@localstack/services';

import { AmplifyRoutes } from '../types';

interface AmplifyNavTabsProps {
  routes: AmplifyRoutes;
  appId: string;
}

export const NavTabs = ({ routes, appId }: AmplifyNavTabsProps): ReactElement => (
  <>
    <NavLink to={buildRoute(routes.RESOURCES_AMPLIFY_APP, { appId })} end>
      App
    </NavLink>
    <NavLink to={buildRoute(routes.RESOURCES_AMPLIFY_BE_ENVIRONMENTS, { appId })}>Environments</NavLink>
  </>
);
