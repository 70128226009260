import { ReactElement } from 'react';
import { List, ListItem, ListItemAvatar, ListItemSecondaryAction, ListItemText, styled } from '@mui/material';
import { ProductType, Product, OrderContext, PlanFamily } from '@localstack/types';
import { formatBytes, formatMonetaryAmount, getProductEstimations, getProductVolume } from '@localstack/services';
import { MAX_NUMBER_OF_SEATS_STARTER, MAX_NUMBER_OF_SEATS_TEAMS } from '@localstack/constants';

import { PlanProductIcon } from '../../../display/PlanProductIcon';

export interface ProductsListProps {
  currency: string;
  products: Product[];
  context: OrderContext;
  planFamily: PlanFamily;
}

const CustomAnchor = styled('a')(({ theme }) => ({
  color: theme.palette.primary.main,
}));

const getSeatsLimit = (planFamily: PlanFamily): JSX.Element | undefined => {
  // Contact link copy differs to track easier
  if (planFamily === PlanFamily.PRO_PLANS) {
    return (
      <span>
        Up to {MAX_NUMBER_OF_SEATS_STARTER} seats – Need more?{' '}
        <CustomAnchor href="https://www.localstack.cloud/contact">Contact our team</CustomAnchor>
      </span>
    );
  }
  if (planFamily === PlanFamily.TEAM_PLANS) {
    return (
      <span>
        Up to {MAX_NUMBER_OF_SEATS_TEAMS} seats – Need more?{' '}
        <CustomAnchor href="https://www.localstack.cloud/contact">Contact sales</CustomAnchor>
      </span>
    );
  }
  return;
};

export const ProductsList = ({ currency, products, context, planFamily }: ProductsListProps): ReactElement => {
  return (
    <List dense disablePadding>
      {products?.map((product) => (
        <ListItem key={product.id}>
          <ListItemAvatar>
            <PlanProductIcon productType={product.type} />
          </ListItemAvatar>
          <ListItemText
            primary={product.name}
            secondary={
              <>
                {product.type === ProductType.SEATS && getSeatsLimit(planFamily)}
                {product.type === ProductType.CI_USAGE &&
                  `${getProductVolume(product, context) ?? '∞'} CI Credits / month`}
                {product.type === ProductType.POD_USAGE && `${formatBytes(getProductVolume(product, context)) ?? '∞'}`}
                {product.type === ProductType.COMPUTE_USAGE &&
                  `${getProductVolume(product, context) ?? '∞'} Credits / month`}
              </>
            }
          />
          <ListItemSecondaryAction>
            <ListItemText primary={formatMonetaryAmount(getProductEstimations(product, context), currency)} />
          </ListItemSecondaryAction>
        </ListItem>
      ))}
    </List>
  );
};
