import { ReactElement } from 'react';
import { Link } from '@mui/material';
import { LambdaFunctionConfig } from '@localstack/types';
import { getAWSRowCount, getSchema } from '@localstack/services';

import { LAMBDA_DEFAULT_PAGE_SIZE as DEFAULT_PAGE_SIZE } from '@localstack/constants';

import { MagicTable } from '../../../magic/MagicTable';

export type LambdaFunctionsTableProps = {
  loading?: boolean;
  selectable?: boolean;
  functions: LambdaFunctionConfig[];
  page: number;
  pageSize?: number;
  onPageChange?: (page: number) => unknown;
  hasMore: boolean;
  onSelectFunctions?: (names: string[]) => void;
  onViewFunction?: (name: string) => void;
};

export const LambdaFunctionsTable = ({
  loading,
  selectable = true,
  functions,
  page,
  pageSize,
  hasMore,
  onPageChange,
  onSelectFunctions,
  onViewFunction,
}: LambdaFunctionsTableProps): ReactElement => (
  <MagicTable
    schema={getSchema('Lambda')}
    entry="FunctionList"
    idAttribute="FunctionArn"
    order={['FunctionName']}
    filterColumns={['FunctionName', 'Runtime', 'Handler', 'CodeSize', 'LastModified']}
    rows={functions}
    selectable={selectable}
    onSelect={onSelectFunctions}
    loading={loading}
    page={page}
    paginationMode="server"
    pageSize={pageSize ?? DEFAULT_PAGE_SIZE}
    rowsPerPageOptions={[DEFAULT_PAGE_SIZE]}
    rowCount={getAWSRowCount({ page, pageSize, DEFAULT_PAGE_SIZE, hasMore, array: functions })}
    onPageChange={onPageChange}
    externalFields={{
      FunctionName: (row) => (
        <Link onClick={() => onViewFunction && onViewFunction(row.FunctionName as string)} underline="hover">
          {row.FunctionName}
        </Link>
      ),
    }}
  />
);
