import { ReactElement, useCallback, useState } from 'react';
import { Button, Card } from '@mui/material';
import { useRoutes, useAwsGetter, useAwsEffect } from '@localstack/services';
import { Dropdown, ConfirmableButton, PageTitle, GlueSchemaVersionMetadataTable } from '@localstack/ui';
import { DEFAULT_GLUE_ROUTES } from '@localstack/constants';
import { useParams } from 'react-router-dom';

import { GlueProps } from './types';
import { SchemaVersionTabs } from './components';

export const GlueSchemaVersionMetadata = ({
  Layout,
  clientOverrides,
  routes = DEFAULT_GLUE_ROUTES,
}: GlueProps): ReactElement => {
  const [selected, setSelected] = useState<string[]>([]);

  const { goto } = useRoutes();
  const { schema, version, registry } = useParams<'schema' | 'version' | 'registry'>();

  const {
    data: schemaVersionMetadata,
    isLoading,
    mutate,
  } = useAwsGetter(
    'Glue',
    'querySchemaVersionMetadata',
    [
      {
        SchemaId: { SchemaName: schema, RegistryName: registry },
        SchemaVersionNumber: { VersionNumber: Number(version ?? 0) },
      },
    ],
    { clientOverrides },
  );

  const { removeSchemaVersionMetadata } = useAwsEffect('Glue', ['removeSchemaVersionMetadata'], {
    revalidate: ['querySchemaVersionMetadata'],
    clientOverrides,
  });

  const removeSchemaMetadata = (key: string, value: string | undefined) =>
    removeSchemaVersionMetadata({
      SchemaId: { SchemaName: schema, RegistryName: registry },
      SchemaVersionNumber: { VersionNumber: Number(version ?? 0) },
      MetadataKeyValue: { MetadataKey: key, MetadataValue: value },
    });

  const handleDeleteSelected = useCallback(
    async () =>
      Promise.all(
        selected.map((key) => {
          const metadata = Object.entries(schemaVersionMetadata?.MetadataInfoMap ?? {}).find((item) => item[0] === key);

          if (metadata?.[1].OtherMetadataValueList?.length) {
            metadata?.[1].OtherMetadataValueList?.forEach((item) => removeSchemaMetadata(key, item.MetadataValue));
          }
          removeSchemaMetadata(key, metadata?.[1].MetadataValue);
          return [];
        }),
      ),
    [selected],
  );

  return (
    <Layout
      documentTitle="Glue: Schema Version Metadata"
      title={
        <PageTitle
          title="Glue Schema Version Metadata"
          breadcrumbs={[
            ['Glue', () => goto(routes.RESOURCES_GLUE_DATABASES)],
            ['Schemas', () => goto(routes.RESOURCES_GLUE_SCHEMAS)],
            [schema, () => goto(routes.RESOURCES_GLUE_SCHEMA, { schema, registry })],
            ['Versions', () => goto(routes.RESOURCES_GLUE_SCHEMA_VERSIONS, { schema, registry })],
            [version, () => goto(routes.RESOURCES_GLUE_SCHEMA_VERSION, { schema, version, registry })],
            ['Metadata', null],
          ]}
          onMutate={mutate}
        />
      }
      tabs={
        <SchemaVersionTabs routes={routes} schema={schema ?? ''} version={version ?? ''} registry={registry ?? ''} />
      }
      actions={
        <>
          <Button
            onClick={() => goto(routes.RESOURCES_GLUE_SCHEMA_VERSION_METADATA_CREATE, { schema, version, registry })}
          >
            Add Metadata
          </Button>
          <Dropdown label="Actions">
            <ConfirmableButton
              componentType="MenuItem"
              disabled={selected.length === 0 || isLoading}
              title={`Delete ${selected.length} Metadata(s)?`}
              onClick={handleDeleteSelected}
              text="Selected Metadata(s) will be permanently deleted"
            >
              Remove Selected
            </ConfirmableButton>
          </Dropdown>
        </>
      }
    >
      <Card>
        <GlueSchemaVersionMetadataTable
          schemaVersionMetadata={schemaVersionMetadata?.MetadataInfoMap ?? {}}
          loading={isLoading}
          onSelect={setSelected}
          onViewMetadata={(key, value) =>
            goto(routes.RESOURCES_GLUE_SCHEMA_VERSION_METADATA_UPDATE, {
              schema,
              version,
              metadataKey: key,
              metadataValue: value,
              registry,
            })
          }
        />
      </Card>
    </Layout>
  );
};
