import { ReactElement } from 'react';

import { Instances } from '~/components/Instances';

import { AppRoute } from '../../config';

import { NotFound, SessionExpired } from '../common';

import { Dashboard } from './Dashboard';
import {
  Account,
  Billing,
  ApiKeys,
  TeamMembers,
  Authentication,
  SingleSignOnSettings,
  Subscriptions,
  SubscriptionDetails,
  LegacyCIKeys,
} from './Profile';
import { SignIn, SignUp, Recover } from './Account';
import {
  AccountActivation,
  MembershipActivation,
  UnsubscribeActivation,
  PasswordResetActivation,
  AuthRequestActivation,
  MarketplaceActivation,
} from './Activation';
import { PricingPlans, PricingSubscribe } from './PricingPlans';
import { Launchpad } from './Launchpad';

// Pods
import { CloudPods, CloudPodsPublic, PodDetails, CloudPodsLoad } from './CloudPods';

// CI Analytics
import { CIProjects, CIProjectRunDetails, CIProjectsCreate, CIProjectSettings } from './CIAnalytics';

import {
  Quickstart,
  DemoOne,
  DemoTwo,
  DemoThree,
  DemoFour,
  DemoFive,
  QuickstartCloudFormation,
  ServerlessQuizApp,
} from './Quickstart';
import { Stack } from './Stacks';
import { SSOCallback, SSOStart, SSOLoading } from './SSO';
import { GettingStarted } from './GettingStarted';
import { DesktopDownload } from './DesktopDownload/DesktopDownload';
import { AuthToken } from './AuthToken';
import { AuthTokens } from './AuthTokens/AuthTokens';
import { ExtensionsLibrary, ExtensionsRemote, ExtensionsSubmit } from './Extensions';
import { CIProjectDetails } from './CIAnalytics/ProjectDetails';

import { MyLicense } from './MyLicense';
import { WorkspaceSettings } from './Workspace';

// Chaos Engineering
import { AcceptTos } from './Account/AcceptTos';
import { StateManager, ExtensionsManage, ExtensionsDetails } from './Instances';

type PathElementMapEntry = {
  [componentName: string]: () => ReactElement;
};

// Define the PathElementMapType type
type PathElementMapType = {
  [key: string]: PathElementMapEntry;
};

export const PATH_ELEMENT_MAP: PathElementMapType = {
  [AppRoute.SIGN_UP]: { SignUp: SignUp },
  [AppRoute.SIGN_IN]: { SignIn: SignIn },
  [AppRoute.DASHBOARD]: { Dashboard: Dashboard },
  [AppRoute.ACCEPT_TOS]: { AcceptTos: AcceptTos },
  [AppRoute.STACKS_STACK]: { Stack: Stack },
  [AppRoute.QUICKSTART]: { Quickstart: Quickstart },
  [AppRoute.QUICKSTARTCLOUDFORMATION]: { QuickstartCloudFormation: QuickstartCloudFormation },
  [AppRoute.QUICKSTART_DEMO1]: { DemoOne: DemoOne },
  [AppRoute.QUICKSTART_DEMO2]: { DemoTwo: DemoTwo },
  [AppRoute.QUICKSTART_DEMO3]: { DemoThree: DemoThree },
  [AppRoute.QUICKSTART_DEMO4]: { DemoFour: DemoFour },
  [AppRoute.QUICKSTART_DEMO5]: { DemoFive: DemoFive },
  [AppRoute.QUICKSTART_SERVERLESS_QUIZ_APP]: { ServerlessQuizApp: ServerlessQuizApp },
  [AppRoute.GETTING_STARTED]: { GettingStarted: GettingStarted },
  [AppRoute.SETTINGS_PROFILE]: { Account: Account },
  [AppRoute.SETTINGS_AUTHENTICATION]: { Authentication: Authentication },
  [AppRoute.SETTINGS_WORKSPACE]: { WorkspaceSettings: WorkspaceSettings },
  [AppRoute.SETTINGS_BILLING]: { Billing: Billing },
  [AppRoute.SETTINGS_KEYS]: { ApiKeys: ApiKeys },
  [AppRoute.SETTINGS_LEGACY_CI_KEYS]: { LegacyCIKeys: LegacyCIKeys },
  [AppRoute.SETTINGS_MEMBERS]: { TeamMembers: TeamMembers },
  [AppRoute.SETTINGS_SUBSCRIPTIONS]: { Subscriptions: Subscriptions },
  [AppRoute.SETTINGS_SUBSCRIPTION_DETAIL]: { SubscriptionDetails: SubscriptionDetails },
  [AppRoute.SETTINGS_SUBSCRIPTION_UPDATE]: { PricingSubscribe: PricingSubscribe },
  [AppRoute.SETTINGS_AUTH_TOKEN]: { AuthToken: AuthToken },
  [AppRoute.SETTINGS_AUTH_TOKENS]: { AuthTokens: AuthTokens },
  [AppRoute.SETTINGS_MY_LICENSE]: { MyLicense: MyLicense },
  [AppRoute.SETTINGS_SSO]: { SingleSignOnSettings: SingleSignOnSettings },
  [AppRoute.PRICING]: { PricingPlans: PricingPlans },
  [AppRoute.PRICING_SUBSCRIBE]: { PricingSubscribe: PricingSubscribe },
  [AppRoute.LAUNCHPAD]: { Launchpad: Launchpad },
  [AppRoute.DESKTOP_DOWNLOAD]: { DesktopDownload: DesktopDownload },
  [AppRoute.SSO_CALLBACK]: { SSOCallback: SSOCallback },
  [AppRoute.SSO_LOADING]: { SSOLoading: SSOLoading },
  [AppRoute.SSO_START]: { SSOStart: SSOStart },
  [AppRoute.EXTENSIONS_LIBRARY]: { ExtensionsLibrary: ExtensionsLibrary },
  [AppRoute.EXTENSIONS_MANAGE]: { ExtensionsManage: ExtensionsManage },
  [AppRoute.EXTENSIONS_DETAILS]: { ExtensionsDetails: ExtensionsDetails },
  [AppRoute.EXTENSIONS_REMOTE]: { ExtensionsRemote: ExtensionsRemote },
  [AppRoute.EXTENSIONS_SUBMIT]: { ExtensionsSubmit: ExtensionsSubmit },
  [AppRoute.INSTANCES]: { Instances: Instances },
  [AppRoute.INSTANCES_EPHEMERAL]: { Instances: Instances },
  [AppRoute.CI_PROJECTS]: { CIProjects: CIProjects },
  [AppRoute.CI_PROJECT_CREATE]: { CIProjectsCreate: CIProjectsCreate },
  [AppRoute.CI_PROJECT_SETTINGS]: { CIProjectSettings: CIProjectSettings },
  [AppRoute.CI_PROJECT]: { CIProjectDetails: CIProjectDetails },
  [AppRoute.CI_PROJECT_RUN]: { CIProjectRunDetails: CIProjectRunDetails },
  [AppRoute.PODS]: { CloudPods: CloudPods },
  [AppRoute.PODS_PUBLIC]: { CloudPodsPublic: CloudPodsPublic },
  [AppRoute.POD]: { PodDetails: PodDetails },
  [AppRoute.POD_DIFF]: { PodDetails: PodDetails },
  [AppRoute.POD_LOAD]: { CloudPodsLoad: CloudPodsLoad },
  [AppRoute.ACCOUNT_ACTIVATE]: { AccountActivation: AccountActivation },
  [AppRoute.MEMBERSHIP_ACTIVATE]: { MembershipActivation: MembershipActivation },
  [AppRoute.UNSUBSCRIBE_ACTIVATE]: { UnsubscribeActivation: UnsubscribeActivation },
  [AppRoute.RECOVER]: { Recover: Recover },
  [AppRoute.RECOVER_ACTIVATE]: { PasswordResetActivation: PasswordResetActivation },
  [AppRoute.AUTH_REQUEST_ACTIVATE]: { AuthRequestActivation: AuthRequestActivation },
  [AppRoute.MARKETPLACE_ACTIVATE]: { MarketplaceActivation: MarketplaceActivation },
  [AppRoute.SESSION_EXPIRED]: { SessionExpired: SessionExpired },
  [AppRoute.NOT_FOUND]: { NotFound: NotFound },
  [AppRoute.STACKS_OVERVIEW]: { Stack: Stack },
  [AppRoute.INSTANCE_STATE]: { StateManager: StateManager },
};
