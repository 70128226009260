import React, { useState, ReactElement } from 'react';
import { Check as CheckIcon, Clear as CrossIcon } from '@mui/icons-material';
import { Theme, useTheme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import createStyles from '@mui/styles/createStyles';
import cn from 'classnames';
import { OrderContext, Plan, PlanFamily } from '@localstack/types';
import { PlanIcon } from '@localstack/ui';

import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  Divider,
  Typography,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  FormControlLabel,
  Grid,
  Switch,
} from '@mui/material';
import { formatMonetaryAmount, getPlanEstimations } from '@localstack/services';

const useSharedStyles = makeStyles((theme: Theme) =>
  createStyles({
    supportedFeature: {
      color: theme.palette.success.main,
    },
    missingFeature: {
      color: theme.palette.error.main,
    },
  }),
);

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    pricingCard: {
      position: 'relative',
    },
    trial: {
      background: `linear-gradient(-45deg, ${theme.palette.primary.dark} 0%, ${theme.palette.primary.main} 100%);`,
      color: theme.palette.primary.contrastText,
      '& .MuiTypography-root': {
        color: theme.palette.primary.contrastText,
      },
      '& p': {
        color: theme.palette.primary.contrastText,
      },
    },
  }),
);

type PlanFamilyDescription = {
  name: string;
  description: string;
  lead?: string;
  banner?: string;
  yes: string[];
  no: string[];
  buttonText: string;
};

const NUMBER_OF_SERVICES_IN_PRO = '100+';

export const PLAN_FAMILY_MAP: Record<PlanFamily, PlanFamilyDescription> = {
  // Note hobby plan is not yet exposed via API
  [PlanFamily.HOBBY_PLANS]: {
    name: 'Hobby',
    description: `We are offering a Hobby plan for individuals
      who are not using LocalStack commercially. See T&C and FAQ for details. `,
    lead: '',
    yes: [
      `${NUMBER_OF_SERVICES_IN_PRO} AWS services (localstack-pro image)`,
      'CI starter tier¹',
      'IAM security enforcement',
      'State persistence for individual debugging',
    ],
    no: ['Best-effort support (Slack)'],
    buttonText: 'Get Started',
  },
  [PlanFamily.TRIAL_PLANS]: {
    name: 'Trial',
    description: 'Trial access to all LocalStack Starter and Team features',
    lead: 'Full access for 14 days:',
    yes: [
      `${NUMBER_OF_SERVICES_IN_PRO} AWS services (localstack-pro image)`,
      'CI starter tier¹',
      'IAM security enforcement',
      'Cloud pods storage³',
      'Shared cloud pods for collaborative debugging',
      'Best-effort support (Slack)',
      'All LocalStack Starter and Team features',
    ],
    no: [],
    buttonText: 'Try now',
  },
  [PlanFamily.PRO_PLANS]: {
    name: 'Starter',
    description: "Use of LocalStack on individual developers' machines with limited use in CI/CD",
    yes: [
      `${NUMBER_OF_SERVICES_IN_PRO} AWS services (localstack-pro image)`,
      'CI starter tier¹',
      'IAM security enforcement',
      'State persistence for individual debugging',
      'Ephemeral instances (preview)',
      'Best-effort support (Slack)',
    ],
    no: [],
    buttonText: 'Get started',
  },
  [PlanFamily.TEAM_PLANS]: {
    name: 'Team',
    description: 'Use of LocalStack across teams and extensive use in CI/CD pipelines',
    lead: 'Everything in Starter, plus:',
    yes: [
      'CI performance tier²',
      'Team usage analytics',
      'Shared cloud pods for collaborative debugging',
      'Cloud pods storage³',
      'Ephemeral instances (preview)',
      'Prioritized best effort-support (Slack)',
    ],
    buttonText: 'Get started',
    no: [],
  },
  [PlanFamily.ENTERPRISE_PLANS]: {
    name: 'Enterprise',
    description: 'Use of LocalStack in large organizational settings',
    lead: 'Everything in Team, plus:',
    yes: [
      'Unlimited CI usage',
      'CI analytics',
      'Advanced cloud pods storage⁴',
      'SSO Support',
      'Dedicated tech support via ticketing system with SLAs',
      'Shared instances',
      'Offline image',
      'Ephemeral instances (preview)',
      'Custom or on-premise installations',
    ],
    no: [],
    buttonText: 'Contact us',
  },
  [PlanFamily.LEGACY_PLANS]: {
    name: 'Legacy',
    description: 'Legacy plan',
    yes: [],
    no: [],
    buttonText: '',
  },
  [PlanFamily.MARKETPLACE_PLANS]: {
    name: 'Marketplace',
    description: 'Marketplace plan',
    yes: [],
    no: [],
    buttonText: '',
  },
  [PlanFamily.COMMUNITY]: {
    name: 'Community',
    description: 'Community plan',
    yes: [],
    no: [],
    buttonText: '',
  },
};

type Props = {
  className?: string;
  plansGroup: Plan[];
  onSelect: (plan: Plan) => void | Promise<void>;
};

export const PricingPlan = ({ className, plansGroup, onSelect }: Props): ReactElement => {
  const classes = useStyles();
  const sharedClasses = useSharedStyles();
  const theme = useTheme();

  const monthlyPlans = plansGroup.filter((plan) => plan.interval_months === 1);
  const yearlyPlans = plansGroup.filter((plan) => plan.interval_months === 12);

  if (!monthlyPlans.length && !yearlyPlans.length) {
    throw new Error('Neither monthly nor yearly plans available');
  }

  const [activeInterval, setActiveInterval] = useState(yearlyPlans.length ? 'year' : 'month');

  const selectedPlan = (activeInterval === 'month' ? monthlyPlans[0] : yearlyPlans[0]) as Plan;

  const discount = selectedPlan?.discount;
  const context: OrderContext = { seats: 1, org_id: '', customer_id: '' };
  const planFamily: PlanFamily = (selectedPlan?.family || PlanFamily.PRO_PLANS) as PlanFamily;

  const planName = PLAN_FAMILY_MAP[planFamily].name;
  const planDescription = PLAN_FAMILY_MAP[planFamily].description;
  const includedFeatures = PLAN_FAMILY_MAP[planFamily].yes;
  const excludedFeatures = PLAN_FAMILY_MAP[planFamily].no;
  const { buttonText, lead, banner } = PLAN_FAMILY_MAP[planFamily];

  const isTrial = planFamily === PlanFamily.TRIAL_PLANS;

  const planFullAmount = getPlanEstimations(selectedPlan.products, context, discount, false);
  const planFullMonthlyAmount = planFullAmount / selectedPlan.interval_months;
  const planFormattedFullAmount = formatMonetaryAmount(planFullMonthlyAmount, selectedPlan.currency);

  const planDiscountedAmount = getPlanEstimations(selectedPlan.products, context, discount, true);
  const planDiscountedMonthlyAmount = planDiscountedAmount / selectedPlan.interval_months;
  const planFormattedDiscountedAmount = formatMonetaryAmount(planDiscountedMonthlyAmount, selectedPlan.currency);

  return (
    <Grid item xl={3} lg={3} md={6} sm={6} xs={12}>
      <Card
        className={cn(classes.pricingCard, className, {
          [classes.trial]: isTrial,
        })}
      >
        <CardContent style={{ position: 'relative' }}>
          <Box display="flex" flexDirection="row" alignItems="center" style={{ flexGrow: 1 }}>
            {selectedPlan && (
              <React.Fragment key={selectedPlan.id}>
                <Box flexGrow={1} flexBasis={1} width={theme.spacing(30)}>
                  <Box textAlign="center" m={2}>
                    <PlanIcon planFamily={planFamily} size="large" color={isTrial ? 'contrastText' : 'primary'} />
                  </Box>
                  <Box height={theme.spacing(7)}>
                    <Typography variant="h4" color="inherit" align="center">
                      {planName}
                    </Typography>
                  </Box>
                  <Box height={theme.spacing(9)}>
                    <Typography variant="caption" color="inherit" align="center" component="div">
                      {planDescription}
                    </Typography>
                  </Box>
                  <Divider />
                  <Box height={theme.spacing(32)}>
                    <List dense>
                      {banner && (
                        <ListItem key={banner}>
                          <ListItemText>
                            <div
                              className={classes.trial}
                              style={{ textAlign: 'center', borderRadius: '5px', padding: '5px' }}
                            >
                              {banner}
                            </div>
                          </ListItemText>
                        </ListItem>
                      )}
                      {lead && (
                        <ListItem key={lead}>
                          <ListItemText>
                            <p>{lead}</p>
                          </ListItemText>
                        </ListItem>
                      )}
                      {includedFeatures.map((feature) => (
                        <ListItem key={feature}>
                          <ListItemIcon>
                            <CheckIcon className={sharedClasses.supportedFeature} />
                          </ListItemIcon>
                          <ListItemText>
                            <p>{feature}</p>
                          </ListItemText>
                        </ListItem>
                      ))}
                      {excludedFeatures.map((feature) => (
                        <ListItem key={feature}>
                          <ListItemIcon>
                            <CrossIcon className={sharedClasses.missingFeature} />
                          </ListItemIcon>
                          <ListItemText>
                            <p>{feature}</p>
                          </ListItemText>
                        </ListItem>
                      ))}
                    </List>
                  </Box>
                  <Box textAlign="center" height={theme.spacing(5)} style={{ marginBottom: 10 }}>
                    <>
                      {/* Discounted price with duration */}
                      {discount && planDiscountedAmount > 0 && (
                        <>
                          <Typography variant="subtitle1" color="primary" component="span">
                            {planFormattedDiscountedAmount}{' '}
                          </Typography>
                          {discount.duration_months !== -1 && (
                            <>
                              <Typography variant="subtitle2" component="span">
                                {activeInterval === 'year' && 'first year'}
                                {activeInterval === 'month' &&
                                  `first ${discount.duration_months}
                                  ${discount?.duration_months === 1 ? 'month' : 'months'}`}
                                <br />
                                then{' '}
                              </Typography>
                              <Typography variant="subtitle1" color="primary" component="span">
                                {planFormattedFullAmount}
                                <sup>*</sup>
                              </Typography>
                            </>
                          )}
                          <Typography variant="subtitle2" component="span">
                            /month per seat
                          </Typography>
                          {activeInterval === 'year' && (
                            <Typography variant="subtitle2" component="div">
                              (billed annually)
                            </Typography>
                          )}
                        </>
                      )}
                      {/* Price without any discounts */}
                      {!discount && planDiscountedAmount > 0 && (
                        <>
                          <Typography variant="subtitle1" color="primary" component="span">
                            {planFormattedFullAmount}
                            <sup>*</sup>{' '}
                          </Typography>
                          <Typography variant="subtitle2" component="span">
                            per seat per month
                          </Typography>
                          {activeInterval === 'year' && (
                            <Typography variant="subtitle2" component="div">
                              (billed annually)
                            </Typography>
                          )}
                        </>
                      )}
                    </>
                  </Box>
                </Box>
              </React.Fragment>
            )}
          </Box>
        </CardContent>
        <>
          <Box display="flex" justifyContent="center" height={theme.spacing(2)} style={{ marginBottom: 10 }}>
            {monthlyPlans.length > 0 && yearlyPlans.length > 0 && (
              <FormControlLabel
                label="Yearly"
                control={
                  <Switch
                    color="primary"
                    checked={activeInterval === 'year'}
                    onChange={(_, checked: boolean) => setActiveInterval(checked ? 'year' : 'month')}
                  />
                }
              />
            )}
          </Box>
          <CardActions>
            <Box textAlign="center" width="100%">
              <Button
                variant="contained"
                color={isTrial ? undefined : 'primary'}
                onClick={() => {
                  if (selectedPlan) {
                    onSelect(selectedPlan);
                  }
                }}
              >
                {buttonText}
              </Button>
            </Box>
          </CardActions>
        </>
      </Card>
    </Grid>
  );
};

export { useSharedStyles as usePricingPlanStyles };
