import { ReactElement, useCallback, useState } from 'react';
import { useForm } from 'react-hook-form';
import { AuthLinkType } from '@localstack/types';
import { PUBLIC_IDENTITY_PROVIDERS } from '@localstack/constants';

import {
  ControlledTextField,
  LoadingFragment,
  PasswordCheck,
  ProgressButton,
  SSOIcon,
  LogoRocket,
} from '@localstack/ui';

import { useApiEffect, useApiGetter, UserService, useSnackbar, VALIDATION_RULES } from '@localstack/services';

import {
  Typography,
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Grid,
  List,
  ListItem,
  ListItemAvatar,
  ListItemSecondaryAction,
  ListItemText,
  Box,
} from '@mui/material';
import upperFirst from 'lodash/upperFirst';

import { ContainedCustomerLayout } from '~/layouts';
import { getUserInfo } from '~/util/storage';
import { useSSOStart } from '~/hooks/useSSOStart';

interface PasswordData {
  old_password: string;
  password: string;
}

export const Authentication = (): ReactElement => {
  const { showSnackbar } = useSnackbar();
  const userInfo = getUserInfo();
  const [ssoError, setSsoError] = useState<Optional<string>>(undefined);

  const { isLoading: isPasswordUpdating, updatePassword } = useApiEffect(UserService, ['updatePassword']);

  const { isIdpLoading, initSSOFlow } = useSSOStart({
    onSuccess: () => mutateAuthLinks(),
    onError: (err) => setSsoError(err.message),
  });

  const { control, handleSubmit, formState, watch, reset } = useForm<PasswordData>({ mode: 'all' });

  const newPassword = watch('password', '');

  const onSubmit = useCallback(async (data: PasswordData) => {
    await updatePassword(data);
    showSnackbar({ message: 'Password has been successfully changed', severity: 'success' });
    reset();
  }, []);

  const {
    data: authLinks,
    isLoading: isAuthLinksLoading,
    mutate: mutateAuthLinks,
  } = useApiGetter(UserService, 'listAuthLinks', []);

  const { deleteAuthLink, isLoading: isAuthLinksDeleting } = useApiEffect(UserService, ['deleteAuthLink'], {
    revalidate: ['listAuthLinks'],
  });

  const primaryAuthLink = (authLinks ?? []).find((link) => link.link_type === AuthLinkType.SIGNUP);
  const publicAuthLinks = (authLinks ?? []).filter((link) => link.link_type === AuthLinkType.PUBLIC_SSO);
  const privateAuthLinks = (authLinks ?? []).filter((link) => link.link_type === AuthLinkType.CUSTOM_SSO);
  const isAloneInTheDarkLink = (authLinks ?? []).length <= 1;

  const isLoading = isPasswordUpdating || isAuthLinksLoading || isAuthLinksDeleting;

  const authLinksProviders = [...publicAuthLinks, ...privateAuthLinks].map(
    (provider) => provider.user_name.split('_')[0]?.toLowerCase() ?? '',
  );

  const organization = userInfo?.org;
  const isStrictSSOModeEnabled = Object.values(organization?.settings?.sso_settings ?? {}).some(
    (sso_settings) => sso_settings.strict_sso_mode,
  );

  return (
    <ContainedCustomerLayout title="Authentication">
      <Grid container spacing={2}>
        <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Card>
              <CardHeader title="Change your Password" />
              <CardContent>
                <Grid container spacing={2}>
                  <Grid item md={12}>
                    <ControlledTextField
                      control={control}
                      fullWidth
                      label="Old Password"
                      name="old_password"
                      variant="outlined"
                      type="password"
                      rules={VALIDATION_RULES.required}
                    />
                  </Grid>
                  <Grid item md={12}>
                    <ControlledTextField
                      control={control}
                      fullWidth
                      label="New Password"
                      name="password"
                      variant="outlined"
                      type="password"
                      rules={VALIDATION_RULES.password}
                    />
                    <PasswordCheck password={newPassword ?? ''} />
                  </Grid>
                  <Grid item md={12}>
                    <ControlledTextField
                      control={control}
                      fullWidth
                      label="Confirm Password"
                      name="confirmation"
                      variant="outlined"
                      type="password"
                      rules={VALIDATION_RULES.passwordConfirmation(newPassword)}
                    />
                  </Grid>
                </Grid>
              </CardContent>
              <CardActions>
                <ProgressButton
                  variant="contained"
                  color="primary"
                  type="submit"
                  disabled={!formState.isValid}
                  loading={isLoading}
                >
                  Save
                </ProgressButton>
              </CardActions>
            </Card>
          </form>
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
          <Card>
            <CardHeader title="Identity Providers" />
            <LoadingFragment variant="list" size={2} height={76} loading={isLoading} />
            {!isLoading && (
              <List>
                {primaryAuthLink && (
                  <ListItem>
                    <ListItemAvatar>
                      <LogoRocket size="medium" />
                    </ListItemAvatar>
                    <ListItemText primary={primaryAuthLink.user_email} />
                    <ListItemSecondaryAction>Connected</ListItemSecondaryAction>
                  </ListItem>
                )}
                {[...publicAuthLinks, ...privateAuthLinks].map((link) => (
                  <ListItem key={link.id}>
                    <ListItemAvatar>
                      <SSOIcon provider={link.user_name.split('_')[0] ?? ''} />
                    </ListItemAvatar>
                    <ListItemText primary={link.user_email} />
                    {isAloneInTheDarkLink ? (
                      'Connected'
                    ) : (
                      <ListItemSecondaryAction>
                        <Button color="primary" variant="outlined" onClick={() => deleteAuthLink(link.id)}>
                          Disconnect
                        </Button>
                      </ListItemSecondaryAction>
                    )}
                  </ListItem>
                ))}
                {publicAuthLinks.length === 0 &&
                  !isStrictSSOModeEnabled &&
                  PUBLIC_IDENTITY_PROVIDERS.filter((provider) => !authLinksProviders.includes(provider.idpName)).map(
                    ({ orgId, idpName }) => (
                      <ListItem key={`${orgId}-${idpName}`}>
                        <ListItemAvatar>
                          <SSOIcon provider={idpName} />
                        </ListItemAvatar>
                        <ListItemText primary={upperFirst(idpName)} />
                        <ListItemSecondaryAction>
                          <ProgressButton
                            color="primary"
                            variant="outlined"
                            loading={isIdpLoading}
                            disabled={isIdpLoading}
                            onClick={() => {
                              setSsoError(undefined);
                              initSSOFlow({
                                orgId: orgId,
                                idpName: idpName,
                              });
                            }}
                          >
                            Connect
                          </ProgressButton>
                        </ListItemSecondaryAction>
                      </ListItem>
                    ),
                  )}
              </List>
            )}
            {ssoError && (
              <Box px={2.5}>
                <Typography fontSize={13} fontWeight={500} color="error">
                  {ssoError}
                </Typography>
              </Box>
            )}
          </Card>
        </Grid>
      </Grid>
    </ContainedCustomerLayout>
  );
};
