import { useEffect } from 'react';

import { RECAPTCHA_API_KEY } from '~/config/config';

declare global {
  interface Window {
    grecaptcha: {
      ready: (callback: () => void) => void;
      execute: (recaptchaKey: string) => string;
    };
  }
}

type RecaptchaV3ReturnType = {
  initToken: () => Promise<string>;
};

export const useRecaptchaV3 = (): RecaptchaV3ReturnType => {
  useEffect(() => {
    // Check if script is not loaded then proceed
    if (document.getElementById('google-recaptcha-v3')) return;

    const script = document.createElement('script');
    script.src = `https://www.google.com/recaptcha/api.js?render=${RECAPTCHA_API_KEY}`;
    script.id = 'google-recaptcha-v3';
    document.body.appendChild(script);
  }, []);

  const initToken = () =>
    new Promise<string>((resolve) => {
      window.grecaptcha.ready(async () => {
        const token = await window.grecaptcha.execute(RECAPTCHA_API_KEY);
        resolve(token);
      });
    });

  return {
    initToken,
  };
};
