import { DynamoDBTable, DynamoDBKeySchemaElement, DynamoDBAttributeDefinition } from '@localstack/types';

/**
 * When working with items by index we need to "restore" original type of the
 * value, otherwise request may fail due to key schema mismatch
 * (i.e. when passing string range key instead of a number etc.)
 */
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types, @typescript-eslint/no-explicit-any
export const restoreDynamoDBType = <T>(type: Optional<string>, value: any): T => {
  if (type === 'S') return value?.toString();
  if (value && type === 'N') return Number.parseFloat(value.toString()) as unknown as T;
  if (type === 'BOOL') return Boolean(Number.parseInt(value?.toString(), 10)) as unknown as T;

  return value;
};

interface ExtractDynamoDBPrimaryKeyReturn {
  hashKey: Optional<DynamoDBKeySchemaElement>;
  rangeKey: Optional<DynamoDBKeySchemaElement>;
  hashKeyDef: Optional<DynamoDBAttributeDefinition>;
  rangeKeyDef: Optional<DynamoDBAttributeDefinition>;
}

export const extractDynamoDBPrimaryKey = (table: Optional<DynamoDBTable>): ExtractDynamoDBPrimaryKeyReturn => {
  const hashKey = table?.KeySchema?.find((s) => s.KeyType === 'HASH');
  const rangeKey = table?.KeySchema?.find((s) => s.KeyType === 'RANGE');

  const hashKeyDef = table?.AttributeDefinitions?.find((a) => a.AttributeName === hashKey?.AttributeName);
  const rangeKeyDef = table?.AttributeDefinitions?.find((a) => a.AttributeName === rangeKey?.AttributeName);

  return { hashKey, hashKeyDef, rangeKey, rangeKeyDef };
};

export const buildDynamoDBPrimaryKey = (
  table: Optional<DynamoDBTable>,
  hashKeyValue: Optional<string | boolean | number>,
  sortKeyValue?: Optional<string | boolean | number>,
): Record<string, string> => {
  const { hashKey, hashKeyDef, rangeKey, rangeKeyDef } = extractDynamoDBPrimaryKey(table);

  if (!hashKey || !hashKeyDef) throw new Error('Missing hash key or hash key definition');

  return {
    [hashKey.AttributeName]: restoreDynamoDBType(hashKeyDef.AttributeType, hashKeyValue),
    ...(rangeKey &&
      rangeKeyDef && {
        [rangeKey.AttributeName]: restoreDynamoDBType(rangeKeyDef.AttributeType, sortKeyValue),
      }),
  };
};
