import { ReactElement } from 'react';
import { NeptuneCluster } from '@localstack/types';
import { getSchema } from '@localstack/services';

import { MagicDetails } from '../../magic/MagicDetails';

export interface NeptuneClusterDetailsProps {
  cluster?: Optional<Partial<NeptuneCluster>>;
}

export const NeptuneClusterDetails = ({ cluster }: NeptuneClusterDetailsProps): ReactElement => (
  <MagicDetails data={cluster} schema={getSchema('Neptune')} entry="DBCluster" title="Cluster Details" />
);
