import { ReactElement, useEffect, useState } from 'react';
import {
  retrieveFileContentAsBlob,
  useAwsEffect,
  useSnackbar,
  VALIDATION_RULES,
  getSelectedInstance,
} from '@localstack/services';
import { CloudPodMergeStrategy } from '@localstack/types';
import { Button, Card, CardActions, CardContent, CardHeader, Link, Tooltip, Typography } from '@mui/material';
import { useForm } from 'react-hook-form';
import { ControlledDropzone } from '@localstack/ui';
import { Publish as PublishIcon } from '@mui/icons-material';
import { ExternalLink } from '@localstack/constants';

import { DISABLED_PRO_TOOLTIP_TEXT } from './common';

type FormProps = {
  podFile: File;
};

type PodSelectorCardProps = {
  disabled: boolean;
};

export const PodSelectorCard = ({ disabled }: PodSelectorCardProps): ReactElement => {
  const { showSnackbar } = useSnackbar();

  const [hasBeenInjected, setHasBeenInjected] = useState(false);
  const { control, handleSubmit } = useForm<FormProps>({ mode: 'all' });

  const instance = getSelectedInstance();
  const clientOverrides = instance ? { endpoint: instance.endpoint } : {};

  const {
    injectPod,
    isLoading,
    hasError: hasPodInjectError,
    error: podInjectError,
  } = useAwsEffect('LocalStack', ['injectPod'], { silentErrors: true, clientOverrides });

  const uploadPod = async (props: FormProps) => {
    const fileContent = await retrieveFileContentAsBlob(props.podFile);
    const requestBody = {
      data: fileContent,
      params: {
        pod_name: 'imported-pod',
        pod_version: 1,
        merge_strategy: CloudPodMergeStrategy.ACCOUNT_REGION_MERGE,
      },
    };
    setHasBeenInjected(true);
    await injectPod(requestBody);
  };

  useEffect(() => {
    if (!isLoading && hasBeenInjected) {
      if (!hasPodInjectError && !podInjectError) {
        showSnackbar({ severity: 'success', message: 'Successfully loaded cloud pod state into local instance' });
      } else {
        showSnackbar({
          severity: 'error',
          message: `Unable to load cloud pod state into local instance: ${podInjectError}. 
          Check the LocalStack logs for more info.`,
        });
      }
      setHasBeenInjected(false);
    }
  }, [isLoading, hasPodInjectError, podInjectError]);

  return (
    <Card>
      <CardHeader title="Import State" />
      <CardContent>
        <Typography variant="body2" color="textSecondary">
          Please select the file containing the state that you want to import and load into this running instance. Such
          a file can be either generated by this same page or via the CLI (read more about in in our{' '}
          <Link href={ExternalLink.DOCS_PODS_CLI} target="_blank" underline="hover">
            documentation
          </Link>
          ).
        </Typography>
      </CardContent>

      <form onSubmit={handleSubmit(uploadPod)}>
        <CardContent>
          <ControlledDropzone control={control} name="podFile" rules={VALIDATION_RULES.required} />
        </CardContent>
        <CardActions>
          <Tooltip title={disabled ? DISABLED_PRO_TOOLTIP_TEXT : ''}>
            <div>
              <Button variant="outlined" color="primary" type="submit" startIcon={<PublishIcon />} disabled={disabled}>
                Import State
              </Button>
            </div>
          </Tooltip>
        </CardActions>
      </form>
    </Card>
  );
};
