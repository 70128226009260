import { DEFAULT_CE_ROUTES } from '@localstack/constants';
import { ReactElement } from 'react';
import { useAwsGetter, useRoutes } from '@localstack/services';
import { useParams } from 'react-router-dom';
import { Box, Button, Card, Typography } from '@mui/material';
import { Breadcrumbs, CECostCategoryDefinitionDetail } from '@localstack/ui';

import { CEProps } from './types';

export const CECostCategoryDefinition = ({
  Layout,
  clientOverrides,
  routes = DEFAULT_CE_ROUTES,
}: CEProps): ReactElement => {
  const { goto } = useRoutes();
  const { id } = useParams<'id'>();
  const { data: costCategoryDefinition } = useAwsGetter(
    'CostExplorer',
    'describeCostCategoryDefinition',
    [{ CostCategoryArn: id }],
    {
      clientOverrides,
    },
  );
  return (
    <Layout
      title={
        <Box>
          <Typography variant="h4">Cost Explorer</Typography>
          <Breadcrumbs
            mappings={[
              ['Cost Explorer', () => goto(routes.RESOURCES_CE_COST_CATEGORY_DEFINITIONS)],
              [id, null],
            ]}
          />
        </Box>
      }
      actions={
        <Button onClick={() => goto(routes.RESOURCES_CE_COST_CATEGORY_DEFINITION_UPDATE, { id })}>
          Edit Cost Category Definition
        </Button>
      }
    >
      <Card>
        <CECostCategoryDefinitionDetail costCategoryDefinition={costCategoryDefinition} />
      </Card>
    </Layout>
  );
};
