import { ReactElement, useState, useCallback } from 'react';
import { useRoutes, useAwsEffect, useAwsGetter } from '@localstack/services';
import { Card, Button } from '@mui/material';

import { Dropdown, ConfirmableButton, PageTitle, CloudFrontDistributionsTable } from '@localstack/ui';

import { DEFAULT_CLOUDFRONT_ROUTES } from '@localstack/constants';

import { CloudFrontProps } from './types';

export const CloudFrontDistributions = ({
  Layout,
  clientOverrides,
  routes = DEFAULT_CLOUDFRONT_ROUTES,
}: CloudFrontProps): ReactElement => {
  const [selectedIds, setSelectedIds] = useState<string[]>([]);
  const { goto } = useRoutes();
  const {
    data: distributions,
    isLoading,
    mutate,
  } = useAwsGetter('CloudFront', 'listDistributions', [], { clientOverrides });

  const { deleteDistribution } = useAwsEffect('CloudFront', ['deleteDistribution'], {
    revalidate: ['listDistributions', 'getDistribution'],
    clientOverrides,
  });

  const handleDeleteSelected = useCallback(async () => {
    const promises = selectedIds.map((Id) => deleteDistribution({ Id }));
    await Promise.all(promises);
  }, [selectedIds]);

  return (
    <Layout
      title={<PageTitle title="CloudFront Distributions" onMutate={mutate} />}
      actions={
        <>
          <Button onClick={() => goto(routes.RESOURCES_CLOUDFRONT_DISTRIBUTIONS_CREATE)}>Create Distribution</Button>
          <Dropdown label="Actions">
            <ConfirmableButton
              componentType="MenuItem"
              disabled={selectedIds.length === 0 || isLoading}
              title={`Delete ${selectedIds.length} Distribution(s)?`}
              onClick={handleDeleteSelected}
              text="Selected Distributions will be permanently deleted"
            >
              Remove Selected
            </ConfirmableButton>
          </Dropdown>
        </>
      }
    >
      <Card>
        <CloudFrontDistributionsTable
          distributions={distributions?.DistributionList?.Items ?? []}
          loading={isLoading}
          onSelect={setSelectedIds}
          onViewDistribution={({ Id }) => goto(routes.RESOURCES_CLOUDFRONT_DISTRIBUTION, { id: Id })}
        />
      </Card>
    </Layout>
  );
};
