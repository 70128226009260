import { ReactElement } from 'react';
import { Link } from '@mui/material';
import { IAMPolicy } from '@localstack/types';
import { getSchema } from '@localstack/services';

import { MagicTable } from '../../../magic/MagicTable';

const DEFAULT_PAGE_SIZE = 100;

export interface IAMPoliciesTableProps {
  policies: IAMPolicy[];
  loading?: boolean;
  page: number;
  pageSize?: number;
  onPageChange: (page: number) => unknown;
  selectable?: boolean;
  onViewPolicy?: (policy: IAMPolicy) => unknown;
  onSelect?: (ids: string[]) => unknown;
}

export const IAMPoliciesTable = ({
  policies,
  loading,
  page,
  pageSize,
  onPageChange,
  selectable,
  onViewPolicy,
  onSelect,
}: IAMPoliciesTableProps): ReactElement => (
  <MagicTable
    schema={getSchema('IAM')}
    autoHeight={false}
    entry="Policy"
    idAttribute="PolicyId"
    rows={policies}
    selectable={selectable}
    onSelect={onSelect}
    order={['PolicyId', 'PolicyName']}
    loading={loading}
    page={page}
    paginationMode="client"
    pageSize={pageSize ?? DEFAULT_PAGE_SIZE}
    rowsPerPageOptions={[DEFAULT_PAGE_SIZE]}
    rowCount={policies.length}
    onPageChange={onPageChange}
    externalFields={{
      PolicyId: (row) => (
        <Link onClick={() => onViewPolicy && onViewPolicy(row)} underline="hover">
          {row.PolicyId}
        </Link>
      ),
    }}
  />
);
