import { Button, ButtonProps, styled } from '@mui/material';
import { ReactElement } from 'react';
import { useMatch } from 'react-router-dom';

export type TabButtonProps = ButtonProps & {
  /** The routes in which the button should be active */
  activeRoutes?: string[];
};

interface StyledTabButtonProps extends ButtonProps {
  isActive?: boolean;
}

const StyledTabButton = styled(Button)<StyledTabButtonProps>(({ theme, isActive }) => ({
  background: isActive ? theme.palette.navigation.activeBackground : theme.palette.navigation.background,
  border: `0.5px solid ${theme.palette.divider}`,
  color: isActive ? theme.palette.navigation.activeContrastText : theme.palette.navigation.contrastText,
  fontWeight: 600, // semi bold
}));

export const TabButton = ({ activeRoutes, children, ...rest }: TabButtonProps): ReactElement => {
  const isActive = activeRoutes?.map((route) => useMatch(route)).some((value) => value);
  return (
    <StyledTabButton isActive={isActive} {...rest}>
      {children}
    </StyledTabButton>
  );
};
