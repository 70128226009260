import { ReactElement } from 'react';
import { Link } from '@mui/material';
import { SMSecret } from '@localstack/types';
import { getSchema } from '@localstack/services';

import { MagicTable } from '../../magic/MagicTable';

export interface SecretsManagerSecretsTableProps {
  secrets: SMSecret[];
  loading?: boolean;
  selectable?: boolean;
  onViewSecret?: (key: SMSecret) => unknown;
  onSelect?: (ids: string[]) => unknown;
}

export const SecretsManagerSecretsTable = ({
  secrets,
  loading,
  selectable,
  onViewSecret,
  onSelect,
}: SecretsManagerSecretsTableProps): ReactElement => (
  <MagicTable
    schema={getSchema('SecretsManager')}
    entry="SecretListEntry"
    idAttribute="Name"
    rows={secrets}
    selectable={selectable}
    onSelect={onSelect}
    order={['Name']}
    loading={loading}
    externalFields={{
      Name: (row) => (
        <Link onClick={() => onViewSecret && onViewSecret(row)} underline="hover">
          {row.Name}
          {row.DeletedDate ? ' (deleted)' : ''}
        </Link>
      ),
    }}
  />
);
