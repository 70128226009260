import { lighten, styled } from '@mui/material/styles';
import { ReactElement, useEffect } from 'react';

import { Layout } from '@localstack/types';

import { NavContextProvider, useNavigation } from '@localstack/ui/src/provider/NavbarContext';

import { AppRoute } from '~/config';

import { UpgradeFromTrialBanner, NewsBanner } from './components';

const LayoutContainer = styled('div')(({ theme }) => ({
  background: lighten(theme.palette.background.default, 0.03),
  display: 'flex',
  height: '100vh',
}));

const PageContainer = styled('div')(() => ({
  flexGrow: 1,
  display: 'flex',
  flexDirection: 'column',
  overflow: 'auto',
}));

const PageContent = styled('div')(() => ({
  flexGrow: 1,
  display: 'flex',
  flexDirection: 'column',
}));

interface NavigationProps {
  isExpanded?: boolean;
}

const Navigation = styled('div')<NavigationProps>(({ theme, isExpanded }) => ({
  flexGrow: 0,
  flexShrink: 0,
  borderColor: `${theme.palette.divider} !important`,
  borderRight: '1px solid',
  backgroundColor: theme.palette.background.default,
  overflowY: 'auto',

  ...(isExpanded && {
    position: 'absolute',
    top: 0,
    left: 0,
    zIndex: 1000,
  }),
}));

export const INSTANCE_ROUTES = [
  AppRoute.RESOURCES_COMMON,
  AppRoute.STATUS,
  AppRoute.INSTANCE_STATE,
  AppRoute.EXTENSIONS_MANAGE,
  AppRoute.INSTANCE_LOGS,
  AppRoute.IAM_POLICY_STREAM,
  AppRoute.STACK_OVERVIEW,
  AppRoute.CHAOS_ENGINEERING,
];

interface NavigationWrapperProps {
  navigation: React.ReactNode;
}

const NavigationWrapper = ({ navigation }: NavigationWrapperProps): JSX.Element => {
  const { isNavOpen, isMobile } = useNavigation();
  const isExpanded = isMobile && isNavOpen;

  return (
    <Navigation data-name="navigation" isExpanded={isExpanded}>
      {(!isMobile || isNavOpen) && navigation}
    </Navigation>
  );
};

export const BaseLayout = ({
  pageName,
  children,
  title,
  documentTitle,
  navigation,
  hideNavigation,
}: Layout): ReactElement => {
  useEffect(() => {
    if (documentTitle) {
      document.title = `${documentTitle} - LocalStack`;
    } else if (title && typeof title === 'string') {
      document.title = `${title} - LocalStack`;
    } else {
      document.title = 'LocalStack';
    }
  }, [title, documentTitle]);

  return (
    <NavContextProvider>
      <LayoutContainer data-page-name={pageName} data-name="page container">
        {!hideNavigation && <NavigationWrapper navigation={navigation} />}

        <PageContainer data-name="page content">
          {!hideNavigation && (
            <>
              <UpgradeFromTrialBanner />
              <NewsBanner />
            </>
          )}
          <PageContent data-name="main content">{children}</PageContent>
        </PageContainer>
      </LayoutContainer>
    </NavContextProvider>
  );
};
