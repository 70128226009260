import { ReactElement } from 'react';
import { AgwRestApiIntegration } from '@localstack/types';
import { getSchema } from '@localstack/services';

import { MagicDetails } from '../../../magic/MagicDetails';

export interface AgwRestApiIntegrationDetailsProps {
  integration?: Optional<Partial<AgwRestApiIntegration>>;
}

export const AgwRestApiIntegrationDetails = ({ integration }: AgwRestApiIntegrationDetailsProps): ReactElement => (
  <MagicDetails data={integration} schema={getSchema('APIGateway')} entry="Integration" title="Integration Details" />
);
