import { GlobalStateContext, getSelectedInstance } from '@localstack/services';
import { LocalStackInstance } from '@localstack/types';
import { TextField, Autocomplete } from '@mui/material';

import { useContext, useState } from 'react';
import { ReactElement } from 'react-markdown/lib/react-markdown';

type InstanceSelectorProps = {
  callback: (instance: LocalStackInstance) => void;
  hideIfOne?: boolean;
};

export const InstanceSelector = ({ callback, hideIfOne }: InstanceSelectorProps): ReactElement => {
  const { instances } = useContext(GlobalStateContext);
  const defaultInstance = getSelectedInstance();
  const [selectedInstance, setSelectedInstance] = useState<LocalStackInstance>(defaultInstance as LocalStackInstance);

  const handleInstanceChange = (instance: LocalStackInstance) => {
    setSelectedInstance(instance);
    callback(instance);
  };

  return (
    <>
      {hideIfOne && instances.length === 1 ? null : (
        <Autocomplete
          disablePortal
          options={instances}
          getOptionLabel={(r) => r.name}
          onChange={(_, instance) => instance && handleInstanceChange(instance)}
          renderInput={(options) => <TextField variant="outlined" label="Select Instance" {...options} />}
          value={selectedInstance}
        />
      )}
    </>
  );
};
