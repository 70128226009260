import { useCallback, useEffect, useMemo, useState, ReactElement } from 'react';
import { useParams } from 'react-router-dom';
import { Card, CardContent, CardActions, Box, Typography } from '@mui/material';
import { ProgressButton, Breadcrumbs, ControlledCodeEditor } from '@localstack/ui';
import { useForm } from 'react-hook-form';
import { DEFAULT_DYNAMODB_ROUTES } from '@localstack/constants';

import {
  useRoutes,
  useAwsEffect,
  useAwsGetter,
  extractDynamoDBPrimaryKey,
  buildDynamoDBPrimaryKey,
  VALIDATION_RULES,
} from '@localstack/services';

import { TableNavTabs } from './components';
import { DynamoDBProps } from './types';

const getFieldSampleValue = (type: Optional<string>) => {
  if (type === 'N') return 123;
  if (type === 'BOOL') return true;

  return 'hello-world';
};

interface JsonItemInput {
  json: string;
}

export const DynamoDBTableItemUpsert = ({
  Layout,
  clientOverrides,
  routes = DEFAULT_DYNAMODB_ROUTES,
}: DynamoDBProps): ReactElement => {
  const { goto } = useRoutes();
  const { tableName, hashKey, rangeKey } = useParams<'tableName' | 'hashKey' | 'rangeKey'>();

  const [placeholder, setPlaceholder] = useState('');

  const { data: table } = useAwsGetter('DynamoDB', 'describeTable', [{ TableName: tableName }], { clientOverrides });

  const itemPrimaryKey = useMemo(() => {
    if (!hashKey || !table) return undefined;
    return buildDynamoDBPrimaryKey(table?.Table, hashKey, rangeKey);
  }, [hashKey, rangeKey, table]);

  const { data: item } = useAwsGetter('DynamoDBDocument', 'get', [{ TableName: tableName, Key: itemPrimaryKey }], {
    clientOverrides,
  });

  const { put, isLoading } = useAwsEffect('DynamoDBDocument', ['put'], {
    revalidate: ['scan', 'query', 'get'],
    clientOverrides,
  });

  const { control, handleSubmit, setValue } = useForm<JsonItemInput>({ mode: 'all' });

  const handleUpsert = useCallback(async (jsonItem: JsonItemInput) => {
    await put({ TableName: tableName as string, Item: JSON.parse(jsonItem.json) });
    goto(routes.RESOURCES_DYNAMODB_TABLE_ITEMS, { tableName });
  }, []);

  // set a sample json value
  useEffect(() => {
    if (!table) return;

    const primaryKey = extractDynamoDBPrimaryKey(table?.Table);
    const primaryKeyExample = buildDynamoDBPrimaryKey(
      table?.Table,
      getFieldSampleValue(primaryKey.hashKeyDef?.AttributeType),
      getFieldSampleValue(primaryKey.rangeKeyDef?.AttributeType),
    );

    setPlaceholder(JSON.stringify(primaryKeyExample, null, 2));
  }, [table]);

  // update json based on loaded item
  useEffect(() => {
    if (!item) return;
    setValue('json', JSON.stringify(item?.Item ?? '{}', null, 2));
  }, [item]);

  return (
    <Layout
      title={
        <Box>
          <Typography variant="h4">Item Editor</Typography>
          <Breadcrumbs
            mappings={[
              ['DynamoDB', () => goto(routes.RESOURCES_DYNAMODB)],
              [tableName, () => goto(routes.RESOURCES_DYNAMODB_TABLE, { tableName })],
              [
                hashKey,
                () => goto(routes.RESOURCES_DYNAMODB_TABLE_ITEM_RANGE_UPDATE, { tableName, hashKey, rangeKey }),
              ],
              [hashKey ? 'Update' : 'Create Item', null],
            ]}
          />
        </Box>
      }
      tabs={tableName && <TableNavTabs tableName={tableName} routes={routes} />}
      actions={
        <ProgressButton type="submit" form="update" variant="outlined" color="primary" loading={isLoading}>
          Submit
        </ProgressButton>
      }
    >
      <Card>
        <CardContent>
          <form id="update" onSubmit={handleSubmit(handleUpsert)}>
            <ControlledCodeEditor
              language="json"
              control={control}
              name="json"
              rules={VALIDATION_RULES.validJson}
              placeholder={placeholder}
            />
          </form>
        </CardContent>
        <CardActions>
          <ProgressButton type="submit" form="update" variant="contained" color="primary" loading={isLoading}>
            Submit
          </ProgressButton>
        </CardActions>
      </Card>
    </Layout>
  );
};
