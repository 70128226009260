import { AwsClientOverrides, getSchema } from '@localstack/services';
import { DBInstance, CreateDBInstanceMessage, ModifyDBInstanceMessage } from '@localstack/types';
import { ReactElement } from 'react';

import { DEFAULT_DOCDB_ROUTES } from '@localstack/constants';

import { RelatedResourcePicker } from '../../../form/RelatedResourcePicker';

import { MagicForm } from '../../magic/MagicForm';

export type DocDBInstanceFormProps = {
  instance?: Optional<Partial<DBInstance>>;
  formId: string;
  onSubmit: (data: CreateDBInstanceMessage | ModifyDBInstanceMessage) => void;
  loading?: boolean;
  clientOverrides: AwsClientOverrides | undefined;
};

export const DocDBInstanceForm = ({
  instance,
  formId,
  onSubmit,
  loading,
  clientOverrides,
}: DocDBInstanceFormProps): ReactElement => (
  <MagicForm
    entry={instance ? 'ModifyDBInstanceMessage' : 'CreateDBInstanceMessage'}
    schema={getSchema('DocDB')}
    loading={loading}
    onSubmit={onSubmit}
    formId={formId}
    data={instance}
    externalFields={{
      DBClusterIdentifier: (control, fieldName, required) => (
        <RelatedResourcePicker
          fieldName={fieldName}
          control={control}
          property="DBClusterIdentifier"
          arrayKeyName="DBClusters"
          entityName="DBCluster"
          client="DocDB"
          method="describeDBClusters"
          required={required}
          clientOverrides={clientOverrides}
          creationRoute={DEFAULT_DOCDB_ROUTES.RESOURCES_DOCDB_CLUSTER_CREATE}
        />
      ),
    }}
    fieldConditions={{ DBInstanceIdentifier: !instance }}
  />
);
