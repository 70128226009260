import { ReactElement } from 'react';
import { useRoutes, useAwsGetter, processIdAttributes } from '@localstack/services';
import { useParams } from 'react-router-dom';
import { Card, CardContent, Box, Typography, Button } from '@mui/material';
import { Breadcrumbs, Route53HostedZoneRecordDetails as Details } from '@localstack/ui';
import { DEFAULT_ROUTE53_ROUTES } from '@localstack/constants';

import { Route53Props } from './types';
import { processHostedZoneRecord } from './utils';

export const Route53HostedZoneRecordDetails = ({
  Layout,
  clientOverrides,
  routes = DEFAULT_ROUTE53_ROUTES,
}: Route53Props): ReactElement => {
  const { goto } = useRoutes();
  const { hostedZone, record } = useParams<'hostedZone' | 'record'>();

  const { data: records } = useAwsGetter('Route53', 'listResourceRecordSets', [{ HostedZoneId: hostedZone }], {
    clientOverrides,
  });

  const [recordName, recordType] = processIdAttributes(record || '');
  const selectedRecord = records?.ResourceRecordSets.find((r) => r.Name === `${recordName}.` && r.Type === recordType);

  return (
    <Layout
      documentTitle="Route53: Record Details"
      title={
        <Box>
          <Typography variant="h4">Record Details</Typography>
          <Breadcrumbs
            mappings={[
              ['Route53', () => goto(routes.RESOURCES_ROUTE53_HOSTED_ZONES)],
              ['Hosted Zones', () => goto(routes.RESOURCES_ROUTE53_HOSTED_ZONES)],
              [hostedZone, () => goto(routes.RESOURCES_ROUTE53_HOSTED_ZONE, { hostedZone })],
              ['Records', () => goto(routes.RESOURCES_ROUTE53_HOSTED_ZONE_RECORDS, { hostedZone })],
              [recordName, () => null],
            ]}
          />
        </Box>
      }
      actions={
        <Button onClick={() => goto(routes.RESOURCES_ROUTE53_HOSTED_ZONE_RECORD_UPDATE, { hostedZone, record })}>
          Edit Record
        </Button>
      }
    >
      <Card>
        <CardContent>
          <Details record={selectedRecord && processHostedZoneRecord(selectedRecord)} />
        </CardContent>
      </Card>
    </Layout>
  );
};
