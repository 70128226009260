import { ReactElement } from 'react';
import { Link } from '@mui/material';
import { ElastiCacheCluster } from '@localstack/types';
import { getSchema } from '@localstack/services';

import { MagicTable } from '../../magic/MagicTable';

export type ElastiCacheClustersTableProps = {
  loading?: boolean;
  selectable?: boolean;
  clusters: ElastiCacheCluster[];
  onViewCluster?: (cluster: ElastiCacheCluster) => void;
  onSelect?: (names: string[]) => void;
};

export const ElastiCacheClustersTable = ({
  clusters,
  loading,
  selectable,
  onViewCluster,
  onSelect,
}: ElastiCacheClustersTableProps): ReactElement => (
  <MagicTable
    schema={getSchema('ElastiCache')}
    entry="CacheCluster"
    idAttribute="CacheClusterId"
    rows={clusters}
    loading={loading}
    onSelect={onSelect}
    selectable={selectable}
    externalFields={{
      ConfigurationEndpoint: ({ ConfigurationEndpoint: CE }) => <>{CE ? `${CE.Address}:${CE.Port}` : ''}</>,
      CacheClusterId: (row) => (
        <Link onClick={() => onViewCluster && onViewCluster(row)} underline="hover">
          {row.CacheClusterId}
        </Link>
      ),
    }}
    filterColumns={[
      'CacheClusterId',
      'ConfigurationEndpoint',
      'ClientDownloadLandingPage',
      'CacheNodeType',
      'Engine',
      'EngineVersion',
      'CacheClusterStatus',
      'NumCacheNodes',
      'PreferredAvailabilityZone',
      'CacheClusterCreateTime',
      'CacheSubnetGroupName',
      'AuthTokenEnabled',
      'TransitEncryptionEnabled',
      'AtRestEncryptionEnabled',
    ]}
  />
);
