import { DEFAULT_DMS_ROUTES } from '@localstack/constants';
import { ReactElement } from 'react';
import { useAwsGetter, useRoutes } from '@localstack/services';
import { Button, Card } from '@mui/material';
import { DMSEndpointConnectionsTable, PageTitle } from '@localstack/ui';
import { useParams } from 'react-router-dom';

import { DMSProps } from './types';
import { EndpointTabs } from './components/EndpointTabs';

export const DMSEndpointConnections = ({
  Layout,
  clientOverrides,
  routes = DEFAULT_DMS_ROUTES,
}: DMSProps): ReactElement => {
  const { goto } = useRoutes();
  const { id } = useParams<'id'>();
  const {
    data: connections,
    isLoading,
    mutate,
  } = useAwsGetter('DMS', 'describeConnections', [{ Filters: [{ Name: 'endpoint-arn', Values: [id || ''] }] }], {
    clientOverrides,
  });

  return (
    <Layout
      title={
        <PageTitle
          title="DMS Connections"
          onMutate={mutate}
          breadcrumbs={[
            ['DMS', () => goto(routes.RESOURCES_DMS_REPLICATION_INSTANCES)],
            [id, () => goto(routes.RESOURCES_DMS_ENDPOINT, { id })],
            ['Connections', null],
          ]}
        />
      }
      tabs={<EndpointTabs routes={routes} endpointId={id || ''} />}
      actions={
        <>
          <Button onClick={() => goto(routes.RESOURCES_DMS_ENDPOINT_CONNECTIONS_TEST, { id })}>Test Connection</Button>
        </>
      }
    >
      <Card>
        <DMSEndpointConnectionsTable connections={connections?.Connections || []} loading={isLoading} />
      </Card>
    </Layout>
  );
};
