import { ReactElement } from 'react';
import { GlueConnection } from '@localstack/types';
import { getSchema } from '@localstack/services';

import { MagicDetails } from '../../../magic/MagicDetails';

export interface GlueConnectionDetailsProps {
  connection?: Optional<Partial<GlueConnection>>;
}

export const GlueConnectionDetails = ({ connection }: GlueConnectionDetailsProps): ReactElement => (
  <MagicDetails data={connection} schema={getSchema('Glue')} entry="Connection" title="Connection Details" />
);
