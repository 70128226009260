import { EXTENSION_ADMIN_ORDER, LISTABLE_PLAN_FAMILIES } from '@localstack/constants';
import { VALIDATION_RULES } from '@localstack/services';
import { Extension, EnrichedExtension } from '@localstack/types';
import {
  ControlledCheckbox,
  ControlledDropzone,
  ControlledSelect,
  ControlledTextField,
  ProgressButton,
} from '@localstack/ui';
import { Card, CardActions, CardContent, CardHeader, FormGroup, Grid, MenuItem } from '@mui/material';
import { ReactElement, useEffect } from 'react';
import { useForm } from 'react-hook-form';

type ExtensionsSubmissionsCardProps = {
  onSubmit: (extension: EnrichedExtension) => void;
  extension?: Extension;
  submitText?: string;
  editing?: boolean;
  cardTitle?: string;
  showPlatformAdminFields?: boolean;
};

export const ExtensionSubmissionCard = ({
  onSubmit,
  extension,
  submitText = 'Preview',
  editing = false,
  cardTitle = 'New extension',
  showPlatformAdminFields = false,
}: ExtensionsSubmissionsCardProps): ReactElement => {
  const { control, handleSubmit, setValue } = useForm<EnrichedExtension>();

  useEffect(() => {
    if (!extension) return;
    Object.entries(extension).forEach(([k, v]) => {
      setValue(k as keyof Extension, v, { shouldValidate: true });
    });
  }, [extension]);

  return (
    <Card>
      <form onSubmit={handleSubmit(onSubmit)}>
        <CardHeader title={cardTitle} />
        <CardContent>
          <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
              <ControlledTextField
                control={control}
                name="name"
                label="Name of your distribution on pypi"
                fullWidth
                variant="outlined"
                required
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <ControlledTextField
                control={control}
                name="plugin_name"
                label="Plugin name"
                fullWidth
                variant="outlined"
                required
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <ControlledTextField
                control={control}
                name="display_name"
                fullWidth
                variant="outlined"
                label="Name that will be displayed as the extension title"
                required
              />
            </Grid>

            <Grid item xs={12} md={6}>
              <ControlledTextField
                control={control}
                name="version"
                label="Version"
                fullWidth
                variant="outlined"
                required
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <ControlledTextField
                control={control}
                name="author"
                label="Author"
                fullWidth
                variant="outlined"
                required
              />
            </Grid>
            {showPlatformAdminFields && (
              <>
                <Grid item xs={6} sm={3}>
                  <ControlledSelect
                    variant="outlined"
                    control={control}
                    fullWidth
                    label="Tier"
                    name="tier"
                    options={Object.values(LISTABLE_PLAN_FAMILIES).map((plan) => (
                      <MenuItem key={plan} value={plan}>
                        {plan}
                      </MenuItem>
                    ))}
                  />
                </Grid>
                <Grid item xs={6} sm={3}>
                  <ControlledSelect
                    variant="outlined"
                    control={control}
                    fullWidth
                    label="Submission State"
                    name="submission_state"
                    options={Object.values(EXTENSION_ADMIN_ORDER).map((state) => (
                      <MenuItem key={state} value={state}>
                        {state}
                      </MenuItem>
                    ))}
                  />
                </Grid>
              </>
            )}
            <Grid item xs={12}>
              <ControlledTextField
                control={control}
                name="description"
                label="Description"
                fullWidth
                variant="outlined"
                required
              />
            </Grid>
            <Grid item xs={12}>
              <ControlledTextField
                control={control}
                name="repository_url"
                label="Public git repository URL"
                fullWidth
                variant="outlined"
              />
            </Grid>
            <Grid item xs={12}>
              <ControlledDropzone
                control={control}
                name="logoFile"
                label="Upload extension logo (only *.png images accepted)"
                accept=".png"
                rules={VALIDATION_RULES.fileSize(2, !editing)}
              />
            </Grid>
            {showPlatformAdminFields && (
              <>
                <Grid item xs={6}>
                  <ControlledTextField
                    control={control}
                    name="org_id"
                    label="Organization Id"
                    fullWidth
                    variant="outlined"
                  />
                </Grid>
                <Grid item xs={6}>
                  <FormGroup row>
                    <ControlledCheckbox control={control} name="official" label="Official" />
                    <ControlledCheckbox control={control} name="published" label="Installable" />
                    <ControlledCheckbox control={control} name="has_ui" label="Has UI" />
                  </FormGroup>
                </Grid>
              </>
            )}
          </Grid>
        </CardContent>
        <CardActions>
          <ProgressButton variant="contained" color="primary" type="submit">
            {submitText}
          </ProgressButton>
        </CardActions>
      </form>
    </Card>
  );
};
