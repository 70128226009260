import { AuthToken } from '@localstack/types';

import { STORAGE_KEY_AUTH_TOKEN } from '@localstack/constants';

export const extractDataFromLocalStorage = <T>(key: string): Optional<T> => {
  const data = localStorage.getItem(key);

  if (data) {
    try {
      return JSON.parse(data);
    } catch (_e) {
      console.error('ERROR: Unable to parse the data');
    }
  }

  return undefined;
};

export const storeAuthToken = (token: AuthToken): void =>
  localStorage.setItem(STORAGE_KEY_AUTH_TOKEN, JSON.stringify(token));

export const retrieveAuthToken = (): Optional<AuthToken> =>
  extractDataFromLocalStorage<AuthToken>(STORAGE_KEY_AUTH_TOKEN);
