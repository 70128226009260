import { ReactElement, useCallback, useState } from 'react';
import { ConfirmableButton, Dropdown, SQSQueueTable, PageTitle } from '@localstack/ui';
import { Button, Card } from '@mui/material';
import { SQSQueue } from '@localstack/types';

import {
  refineSqsUrl,
  useAwsBatchIterator,
  useAwsEffect,
  useAwsGetter,
  useRoutes,
  useEndpoint,
} from '@localstack/services';
import { DEFAULT_SQS_ROUTES } from '@localstack/constants';

import { SQSProps } from './types';

export const SQSQueues = ({ Layout, clientOverrides, routes = DEFAULT_SQS_ROUTES }: SQSProps): ReactElement => {
  const { goto } = useRoutes();

  const { endpoint } = useEndpoint('SQS');

  const [selectedQueues, setSelectedQueues] = useState<string[]>([]);
  const {
    data: initialQueues,
    isLoading: isQueueLoading,
    mutate,
  } = useAwsGetter('SQS', 'listQueues', [], { clientOverrides });

  const { deleteQueue } = useAwsEffect('SQS', ['deleteQueue'], {
    revalidate: ['listQueues', 'receiveMessage'],
    clientOverrides,
  });
  const { getQueueAttributes } = useAwsEffect('SQS', ['getQueueAttributes'], { clientOverrides });

  const buildQueue = async (url: string): Promise<SQSQueue> => {
    const refinedUrl = refineSqsUrl(url, clientOverrides?.endpoint ?? endpoint);

    const urlParts = refinedUrl.split('/');
    const queueName = urlParts[urlParts.length - 1];

    try {
      return {
        QueueUrl: refinedUrl,
        Name: queueName,
        attributes: (
          await getQueueAttributes({
            QueueUrl: refinedUrl,
            AttributeNames: ['All'],
          })
        ).Attributes,
      };
    } catch (e) {
      console.error(`Unable to load attributes for SQS queue ${url}: ${e}`);
      return {
        QueueUrl: refinedUrl,
        Name: queueName,
      };
    }
  };

  const queues = useAwsBatchIterator(initialQueues?.QueueUrls, (queueUrl: string) => buildQueue(queueUrl));

  const handleDeleteQueues = useCallback(
    async () => Promise.all(selectedQueues.map((QueueUrl) => deleteQueue({ QueueUrl }))),
    [selectedQueues],
  );

  return (
    <>
      <Layout
        documentTitle="SQS: Queues"
        title={
          <PageTitle
            title="SQS Queues"
            onMutate={mutate}
            breadcrumbs={[
              ['SQS', () => goto(routes.RESOURCES_SQS_QUEUES)],
              ['Queues', null],
            ]}
          />
        }
        actions={
          <>
            <Button onClick={() => goto(routes.RESOURCES_SQS_QUEUE_CREATE)}>Create Queue</Button>
            <Dropdown label="Actions">
              <ConfirmableButton
                componentType="MenuItem"
                disabled={selectedQueues.length === 0 || isQueueLoading}
                title={`Delete ${selectedQueues.length} queue(s)?`}
                onClick={handleDeleteQueues}
                text="Selected Queue will be permanently deleted"
              >
                Delete Selected
              </ConfirmableButton>
            </Dropdown>
          </>
        }
      >
        <Card>
          <SQSQueueTable
            selectable
            entries={queues || []}
            onSelect={setSelectedQueues}
            onViewSQSQueue={(queue) => goto(routes.RESOURCES_SQS_QUEUE, { name: queue.Name as string })}
            loading={isQueueLoading}
          />
        </Card>
      </Layout>
    </>
  );
};
