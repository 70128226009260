import { ReactElement, useContext } from 'react';
import { NavLink, useMatch, useParams } from 'react-router-dom';
import { Theme } from '@mui/material/styles';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import {
  LayersOutlined as ResourcesIcon,
  CloudOutlined as CloudIcon,
  RocketLaunch as FlightTakeoffIcon,
  ExtensionOutlined as ExtensionIcon,
  Lock as AdminIcon,
  DonutLarge as LayersIcon,
  CheckCircleOutline as LoopIcon,
} from '@mui/icons-material';

import { TeamDashboard as DashboardIcon } from '@localstack/ui/src/icons';
import { MembershipRole, ProductFeature } from '@localstack/types';
import { buildRoute, GlobalStateContext } from '@localstack/services';

import { AppRoute } from '~/config';
import { useAuthProvider } from '~/hooks/useAuthProvider';
import { useFeatureFlags } from '~/hooks/useFeatureFlags';
import { INSTANCE_ROUTES } from '~/layouts/BaseLayout';

import { BlogCardViewer } from '~/layouts/components/Blog/BlogCardViewer';

import { WorkspaceSelector } from './WorkspaceSelector';
import { InstanceSubMenu } from './InstanceSubMenu';
import { NavbarContainer, NavbarSection, SectionHeader, SectionItem } from './shared';
import { NavbarCloseButton } from './NavbarCloseButton';

// TODO: update to use styled when with redesign of instance part of navbar
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    subMenu: {
      paddingLeft: '1.4rem',
      display: 'flex',
      flexDirection: 'column',
      overflow: 'hidden',
    },

    sidebarSubMenuEntry: {
      textDecoration: 'none',
      marginRight: theme.spacing(2),
      fontSize: '14px',
      color: theme.palette.mode === 'dark' ? theme.palette.text.primary : theme.palette.grey[800],
      padding: '0.5em',
      borderRadius: '0.5em',
      marginBottom: '0.1em',
      display: 'flex',
      flex: 4,
      overflow: 'hidden',

      '&:hover': {
        background: theme.palette.mode === 'dark' ? theme.palette.background.paper : theme.palette.grey[200],
      },
      '&.active': {
        background: theme.palette.mode === 'dark' ? theme.palette.background.paper : theme.palette.grey[200],
      },
    },
  }),
);
/** Contains menu items to be shown with the main actions of the app */
export const MainNavbar = (): ReactElement => {
  const { instances } = useContext(GlobalStateContext);
  const { iid } = useParams<'iid'>() as { iid: string };
  const classes = useStyles();

  const { can, hasFeature } = useAuthProvider();

  const isPerInstancePage = !!INSTANCE_ROUTES.find((route) => useMatch(route));
  const isCloudPodsPage = !!useMatch(AppRoute.PODS) || !!useMatch(AppRoute.POD);

  const flags = useFeatureFlags();

  return (
    <NavbarContainer>
      <NavbarContainer>
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <WorkspaceSelector />
          <NavbarCloseButton />
        </div>
        {can(MembershipRole.PLATFORM_ADMIN) && (
          <SectionItem label="Admin Panel" icon={AdminIcon} to={AppRoute.ADMIN_HOME} />
        )}
        <NavbarSection>
          <SectionItem label="Dashboard" icon={DashboardIcon} to={AppRoute.DASHBOARD} />
          <SectionItem label="Getting Started" icon={FlightTakeoffIcon} to={AppRoute.GETTING_STARTED} />
          <SectionItem
            label="LocalStack Instances"
            icon={ResourcesIcon}
            to={AppRoute.INSTANCES}
            isActive={isPerInstancePage}
            end
          />

          <div
            className={classes.subMenu}
            data-name="instances sub navigation"
            style={{ paddingTop: '0.5em', paddingBottom: '0.5em' }}
          >
            <NavLink
              to={buildRoute(AppRoute.INSTANCES_EPHEMERAL)}
              className={classes.sidebarSubMenuEntry}
              style={{ marginBottom: '0.5em' }}
              end
            >
              Ephemeral Instances
            </NavLink>

            {instances.map((instance, index) => (
              <InstanceSubMenu
                instance={instance}
                activeInstanceId={iid}
                expanded={instance.id === iid || (iid === undefined && index === 0)}
                key={instance.id}
              />
            ))}
          </div>
        </NavbarSection>
        <NavbarSection data-name="workspace sub navigation">
          <SectionHeader>Workspace</SectionHeader>

          <SectionItem label="Cloud Pods" icon={CloudIcon} to={AppRoute.PODS} isActive={isCloudPodsPage} />

          {/* CI PROJECTS */}
          {hasFeature(ProductFeature.FEATURE_CI_PROJECTS) && flags.showCIProjects && (
            <SectionItem label="CI Projects" icon={LoopIcon} to={AppRoute.CI_PROJECTS} />
          )}

          <SectionItem label="Stack Insights" icon={LayersIcon} to={AppRoute.STACKS_OVERVIEW} />
          <SectionItem label="Extensions" icon={ExtensionIcon} to={AppRoute.EXTENSIONS_LIBRARY} />
        </NavbarSection>
      </NavbarContainer>
      <BlogCardViewer />
    </NavbarContainer>
  );
};
