import { useState, useCallback, ReactElement, useEffect, useRef } from 'react';
import { Snackbar, Alert, AlertProps } from '@mui/material';

import { ComputeService, GlobalStateContext, SnackbarMessage, useApiGetter } from '@localstack/services';
import { LocalStackInstance, LocalStackInstanceType } from '@localstack/types';

import {
  STORAGE_KEY_REGION,
  STORAGE_KEY_RESOURCE_BASE_URL,
  DEFAULT_REGION,
  DEFAULT_RESOURCE_BASE_URL,
  STORAGE_KEY_INSTANCES,
  INSTANCE_DEFAULT_ID,
  STORAGE_KEY_AWS_ACCOUNT_ID,
  DEFAULT_AWS_ACCOUNT_ID,
  STORAGE_KEY_AUTH_TOKEN,
} from '@localstack/constants';

import { useSearchParams } from 'react-router-dom';

const DEFAULT_HIDE_DURATION = 5000;
const DEFAULT_SEVERITY: AlertProps['severity'] = 'info';

type Props = {
  children: JSX.Element;
  defaultEndpoint?: string;
};

/**
 * Use this Provider to replace Redux.
 * Store only data that really needs to be stored globally and shared among multiple components at once.
 * In any other cases consider using local state.
 */
export const GlobalStateProvider = ({ children, defaultEndpoint = DEFAULT_RESOURCE_BASE_URL }: Props): ReactElement => {
  const [searchParams] = useSearchParams();
  const regionOverride = searchParams.get('region');
  const isInitialRender = useRef(true);
  const [region, setRegion] = useState(regionOverride || localStorage.getItem(STORAGE_KEY_REGION) || DEFAULT_REGION);
  const [awsAccountId, setAwsAccountId] = useState(
    localStorage.getItem(STORAGE_KEY_AWS_ACCOUNT_ID) || DEFAULT_AWS_ACCOUNT_ID,
  );
  const [endpoint, setEndpoint] = useState(localStorage.getItem(STORAGE_KEY_RESOURCE_BASE_URL) || defaultEndpoint);

  // Separate states to keep the message till snackbar is fully removed from the screen
  const [message, setMessage] = useState<Optional<SnackbarMessage>>(null);
  const [show, setShow] = useState(false);

  const showSnackbar = useCallback(
    (msg: SnackbarMessage) => {
      setMessage(msg);
      setShow(true);
    },
    [setMessage],
  );

  useEffect(() => {
    if (!!regionOverride && isInitialRender.current) isInitialRender.current = false;
    else localStorage.setItem(STORAGE_KEY_REGION, region);
  }, [region]);

  useEffect(() => {
    localStorage.setItem(STORAGE_KEY_AWS_ACCOUNT_ID, awsAccountId);
  }, [awsAccountId]);

  useEffect(() => {
    localStorage.setItem(STORAGE_KEY_RESOURCE_BASE_URL, endpoint);
  }, [endpoint]);

  const storedInstances: LocalStackInstance[] = JSON.parse(localStorage.getItem(STORAGE_KEY_INSTANCES) || '[]');

  if (storedInstances.length === 0) {
    storedInstances.push({
      id: INSTANCE_DEFAULT_ID,
      name: 'Default Instance',
      endpoint,
      description: 'Default LocalStack instance bookmark',
      instanceType: LocalStackInstanceType.REGULAR_INSTANCE,
      startedAt: new Date().getTime(),
    });
  }

  // initial state for ephemeral instances (stale instances removed automatically once ttl is reached)
  // keeping all regular instances
  const [instances, setInstances] = useState<LocalStackInstance[]>(storedInstances);
  const [eInstances, setEInstances] = useState<LocalStackInstance[]>([]);

  const updateInstance = (instance: LocalStackInstance) => {
    const updatedInstances = instances.map((inst) => (inst.id === instance.id ? instance : inst));
    setInstances(updatedInstances);
  };

  const { data: ephemeralInstances } = useApiGetter(ComputeService, 'listEphemeralInstances', [], {
    enable: !!localStorage.getItem(STORAGE_KEY_AUTH_TOKEN),
  });

  useEffect(() => {
    const instanceEntries = (ephemeralInstances || []).map(
      (inst) =>
        ({
          id: inst.instance_name,
          name: inst.instance_name,
          endpoint: inst.endpoint_url,
          instanceType: LocalStackInstanceType.EPHEMERAL_INSTANCE,
          startedAt: inst.creation_time,
          ephemeralInstance: inst,
        }) as LocalStackInstance,
    );
    setEInstances(instanceEntries);
  }, [(ephemeralInstances || []).length]);

  useEffect(() => {
    setInstances([
      ...instances.filter((inst) => inst.instanceType === LocalStackInstanceType.REGULAR_INSTANCE),
      ...eInstances,
    ]);
  }, [(eInstances ?? []).length]);

  useEffect(() => {
    const filteredInstances = instances.filter(
      (instance) => instance.instanceType === LocalStackInstanceType.REGULAR_INSTANCE,
    );
    localStorage.setItem(STORAGE_KEY_INSTANCES, JSON.stringify(filteredInstances));
  }, [instances]);

  const exposedContextApi = {
    showSnackbar,
    region,
    setRegion,
    endpoint,
    setEndpoint,
    instances,
    setInstances,
    updateInstance,
    awsAccountId,
    setAwsAccountId,
  };

  return (
    <GlobalStateContext.Provider value={exposedContextApi}>
      <Snackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        open={show}
        onClose={() => setShow(false)}
        autoHideDuration={message?.autoHideDuration || DEFAULT_HIDE_DURATION}
      >
        <div>
          <Alert onClose={() => setShow(false)} severity={message?.severity || DEFAULT_SEVERITY}>
            {message?.message}
          </Alert>
          <span data-name={`snackbar-severity-${message?.severity}`} />
        </div>
      </Snackbar>
      {children}
    </GlobalStateContext.Provider>
  );
};
