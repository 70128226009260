import { ReactElement, useEffect, ChangeEvent } from 'react';
import { Plan, Product, Discount, PlanFamily, ProductType, OrderContext, AcquisitionChannel } from '@localstack/types';
import { curry, omit } from 'lodash';
import moment from 'moment/moment';
import {
  Alert,
  Button,
  Checkbox,
  Grid,
  Link,
  List,
  ListItem,
  ListItemAvatar,
  ListItemSecondaryAction,
  ListItemText,
  MenuItem,
  Typography,
  TextField,
  FormControl,
  Select,
  InputLabel,
  FormHelperText,
} from '@mui/material';
import { PRICELESS_PLAN_FAMILIES, ExternalLink } from '@localstack/constants';

import { formatBytes, formatMonetaryAmount, getProductEstimations, getProductVolume } from '@localstack/services';

import { PlanProductIcon, DecorativeDivider } from '../../../display';

export interface ConstructorFormProps {
  edit?: boolean;
  plan: Plan;
  products: Product[];
  selectedProducts: Product[];
  discounts: Discount[];
  selectedDiscount?: Optional<Discount>;
  context: OrderContext;
  onUpdateContext?: (context: Partial<OrderContext>) => unknown;
  onUpdateDiscount?: (discount: Optional<Discount>) => unknown;
  onUpdateProducts?: (products: Product[]) => unknown;
}

export const ConstructorForm = ({
  edit,
  plan,
  products,
  selectedProducts,
  discounts,
  selectedDiscount,
  context,
  onUpdateContext,
  onUpdateDiscount,
  onUpdateProducts,
}: ConstructorFormProps): ReactElement => {
  const handleContextChange = curry(
    (attribute: keyof OrderContext, type: 'number' | 'string' | 'timestamp', e: ChangeEvent<HTMLInputElement>) => {
      const { value } = e.target;

      if (type === 'number') {
        if (/[0-9]+/.test(value ?? '')) {
          onUpdateContext?.({ [attribute]: Number.parseInt(value, 10) });
        } else {
          onUpdateContext?.({ [attribute]: undefined });
        }
        return;
      }

      if (type === 'string') {
        return onUpdateContext?.({ [attribute]: value ?? '' });
      }

      if (type === 'timestamp') {
        return onUpdateContext?.({ [attribute]: value ? moment(value).unix() : undefined });
      }

      throw new Error(`unknown type ${type}`);
    },
  );

  useEffect(() => {
    // reset custom volumes selection on
    onUpdateContext?.({ volumes: {} });
    // reset custom interval
    onUpdateContext?.({ interval: plan.interval_months });
  }, [plan]);

  // whenever we are in edit mode - adjust things
  useEffect(() => {
    if (!edit) {
      return;
    }

    onUpdateContext?.({ billing_cycle_anchor: 'unchanged' });
  }, [edit]);

  /**
   * Check whether the product of the same type has been selected already.
   * @param product
   */
  const checkProductSameProductPicked = (product: Product): boolean => {
    const productTypes = selectedProducts.map((p) => p.type);
    return productTypes.includes(product.type);
  };

  /**
   * Check whether the product should be disabled in the list.
   * The product should be disabled if a product of the same type is already selected.
   * @param product
   */
  const checkProductDisabled = (product: Product): boolean => {
    const selectedProductIds = selectedProducts.map((p) => p.id);
    if (selectedProductIds.includes(product.id)) return false;
    return checkProductSameProductPicked(product);
  };

  /**
   * Check whether the product should be marked as checked.
   * @param product
   */
  const checkProductChecked = (product: Product): boolean => {
    const selectedProductIds = selectedProducts.map((p) => p.id);
    return selectedProductIds.includes(product.id);
  };

  /**
   * Toggle product selection and update the custom volumes state.
   * When the product is newly selected we add to the list.
   * When the product is deselected we remove it from the list and reset its volumes.
   * @param product
   */
  const handleToggleProduct = (product: Product) => {
    if (checkProductChecked(product)) {
      onUpdateProducts?.(selectedProducts.filter((p) => p.id !== product.id));
      onUpdateContext?.({ volumes: omit(context.volumes ?? {}, product.id) });
    }

    if (!checkProductSameProductPicked(product)) {
      onUpdateProducts?.([...selectedProducts, product]);
    }
  };

  const addInitialVolume = (product: Product) => {
    onUpdateContext?.({
      volumes: { ...context.volumes, [product.id]: getProductVolume(product, context) ?? 1 },
    });
  };

  return (
    <Grid container spacing={3}>
      <Grid item md={6} sm={12} xs={12}>
        <TextField
          variant="outlined"
          fullWidth
          label="Subscription Name"
          value={context.name ?? ''}
          onChange={handleContextChange('name', 'string')}
        />
        <FormHelperText>Falls back to a plan name if not specified</FormHelperText>
      </Grid>
      <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
        <FormControl variant="outlined" fullWidth required>
          <InputLabel>Acquisition Channel</InputLabel>
          <Select
            required
            fullWidth
            variant="outlined"
            defaultValue={AcquisitionChannel.SALES}
            value={context.acquisition_channel}
            onChange={({ target }) => {
              const { value } = target;
              onUpdateContext?.({
                ['acquisition_channel']: (value as AcquisitionChannel) ?? undefined,
              });
            }}
          >
            <MenuItem value={AcquisitionChannel.SALES}>Sales led</MenuItem>
            <MenuItem value={AcquisitionChannel.SELF_SERVE}>Self serve</MenuItem>
          </Select>
        </FormControl>
      </Grid>
      <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
        <FormControl variant="outlined" fullWidth required>
          <InputLabel>Payments Interval</InputLabel>
          <Select
            required
            fullWidth
            variant="outlined"
            value={(context?.interval ?? '').toString()}
            onChange={handleContextChange('interval', 'number')}
          >
            <MenuItem value="1">1 month</MenuItem>
            <MenuItem value="2">2 months</MenuItem>
            <MenuItem value="3">3 months</MenuItem>
            <MenuItem value="6">6 months</MenuItem>
            <MenuItem value="9">9 months</MenuItem>
            <MenuItem value="12">1 year</MenuItem>
          </Select>
        </FormControl>
      </Grid>
      <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
        <TextField
          variant="outlined"
          fullWidth
          label="MRR (Public)"
          value={context?.mrr ?? ''}
          onChange={handleContextChange('mrr', 'number')}
        />
        <FormHelperText>Custom subscription amount displayed to customers</FormHelperText>
      </Grid>
      <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
        <TextField
          variant="outlined"
          required
          fullWidth
          label="MRR (Private)"
          value={context?.mrr_private ?? ''}
          onChange={handleContextChange('mrr_private', 'number')}
        />
        <FormHelperText>Custom subscription amount used internally</FormHelperText>
      </Grid>
      <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
        <FormControl variant="outlined" fullWidth required>
          <InputLabel>Discount</InputLabel>
          <Select
            required
            fullWidth
            variant="outlined"
            value={selectedDiscount?.id || ''}
            onChange={({ target }) => onUpdateDiscount?.(discounts.find((d) => d.id === target.value))}
          >
            <MenuItem key="none" value="">
              None
            </MenuItem>
            {discounts.map((disc) => (
              <MenuItem key={disc.id} value={disc.id}>
                {disc.name} [{disc.duration_months === -1 ? 'forever' : `${disc.duration_months} months`}{' '}
                {disc.discount_percentage ? `${disc.discount_percentage}%` : disc.discount_flat}]
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Grid>
      <DecorativeDivider />
      <Grid item xs={12}>
        <Typography>Backdating Subscriptions</Typography>
      </Grid>
      {!edit && (
        <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
          <TextField
            variant="outlined"
            fullWidth
            InputLabelProps={{ shrink: true }}
            type="date"
            label="Backdate Start Date"
            value={
              context.backdate_start_date ? moment.unix(context.backdate_start_date).format(moment.HTML5_FMT.DATE) : ''
            }
            onChange={handleContextChange('backdate_start_date', 'timestamp')}
          />
          <FormHelperText>Backdate the subscription start date, defaults to current date</FormHelperText>
        </Grid>
      )}
      <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
        {edit ? (
          <FormControl variant="outlined" fullWidth required>
            <InputLabel>Billing Cycle Anchor</InputLabel>
            <Select
              required
              fullWidth
              variant="outlined"
              value={(context.billing_cycle_anchor ?? 'unchanged').toString()}
              onChange={handleContextChange('billing_cycle_anchor', 'string')}
            >
              {['unchanged', 'now'].map((e) => (
                <MenuItem key={e} value={e}>
                  {e}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        ) : (
          <TextField
            type="date"
            variant="outlined"
            InputLabelProps={{ shrink: true }}
            fullWidth
            label="Billing Cycle Anchor"
            value={
              context.billing_cycle_anchor
                ? moment.unix(context.billing_cycle_anchor as number).format(moment.HTML5_FMT.DATE)
                : ''
            }
            onChange={handleContextChange('billing_cycle_anchor', 'timestamp')}
          />
        )}
        <FormHelperText>When the paid period of subscription should start, defaults to creation date</FormHelperText>
      </Grid>
      <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
        <FormControl variant="outlined" fullWidth required>
          <InputLabel>Proration Behavior</InputLabel>
          <Select
            required
            fullWidth
            variant="outlined"
            value={context.proration_behavior ?? 'none'}
            onChange={handleContextChange('proration_behavior', 'string')}
          >
            {['none', 'always_invoice', 'create_prorations'].map((e) => (
              <MenuItem key={e} value={e}>
                {e}
              </MenuItem>
            ))}
          </Select>
          <FormHelperText>--None-- corresponds to default behaviour configured in Stripe</FormHelperText>
        </FormControl>
      </Grid>
      <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
        <Alert severity="info">
          <strong>Some hints:</strong>
          <ul>
            <li>Select at least one [Seats] product;</li>
            <li>
              From a technical point of view it does not really matter which product variant (monthly/yearly) you
              choose;
            </li>
            <li>
              If you want that a price is displayed to the user, add a monthly representation of the price into the MRR
              field;
            </li>
            <li>
              Select the [forever 100%] discount if the payment is not handled on Stripe. This helps to create
              subscriptions for customers without any payment sources.
            </li>
            <li>
              For backdating a subscription please refer to the stripe docs{' '}
              <Link href={ExternalLink.STRIPE_BILLING} underline="hover">
                here
              </Link>
              . Proration behavior should probably always be set to &apos;none&apos; as otherwise the customer will be
              billed for the prorated period.
            </li>
          </ul>
        </Alert>
      </Grid>
      <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
        <Grid container spacing={3}>
          <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
            <Typography variant="subtitle1" paragraph>
              Products to include
            </Typography>
            <List>
              {products?.map((product) => (
                <ListItem
                  button
                  key={product.id}
                  disabled={checkProductDisabled(product)}
                  onClick={() => handleToggleProduct(product)}
                >
                  <ListItemAvatar>
                    <PlanProductIcon productType={product.type} />
                  </ListItemAvatar>
                  <ListItemText primary={product.name} secondary={product.id} />
                  <ListItemSecondaryAction>
                    <Checkbox
                      color="primary"
                      onChange={() => handleToggleProduct(product)}
                      checked={checkProductChecked(product)}
                      disabled={checkProductDisabled(product)}
                    />
                  </ListItemSecondaryAction>
                </ListItem>
              ))}
            </List>
          </Grid>
          <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
            <Typography variant="subtitle1" paragraph>
              Selected Products
            </Typography>
            <List>
              {selectedProducts.map((product) => (
                <ListItem key={product.id}>
                  <ListItemAvatar>
                    <PlanProductIcon productType={product.type} />
                  </ListItemAvatar>
                  <ListItemText
                    secondaryTypographyProps={{ component: 'div' }}
                    primary={product.name}
                    secondary={
                      <>
                        {!PRICELESS_PLAN_FAMILIES.includes(plan.family as PlanFamily) &&
                          formatMonetaryAmount(getProductEstimations(product, context), plan.currency)}{' '}
                        | Volume: {product.type === ProductType.SEATS && <>{context.seats} License(s)</>}
                        {product.type === ProductType.CI_USAGE && (
                          <>{getProductVolume(product, context) ?? '∞'} Credits/Mo.</>
                        )}
                        {product.type === ProductType.POD_USAGE && (
                          <>{formatBytes(getProductVolume(product, context)) ?? '∞'}</>
                        )}
                      </>
                    }
                  />
                  <ListItemSecondaryAction>
                    {!!context.volumes?.[product.id] && (
                      <TextField
                        type="number"
                        variant="outlined"
                        fullWidth
                        value={context.volumes[product.id] ?? ''}
                        onChange={(e) =>
                          onUpdateContext?.({
                            volumes: { ...context.volumes, [product.id]: Number.parseInt(e.target.value, 10) },
                          })
                        }
                      />
                    )}
                    {!context.volumes?.[product.id] && product.type !== ProductType.SEATS && (
                      <Button color="primary" size="small" variant="outlined" onClick={() => addInitialVolume(product)}>
                        Custom Vol.
                      </Button>
                    )}
                  </ListItemSecondaryAction>
                </ListItem>
              ))}
            </List>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};
