import { useEffect, useCallback, ReactElement, FunctionComponent, Fragment } from 'react';
import { useForm } from 'react-hook-form';
import { VALIDATION_RULES } from '@localstack/services';
import { Organization, Address } from '@localstack/types';
import { Grid } from '@mui/material';

import { pick } from 'lodash';

import { ControlledCountryAutocomplete, ControlledPhoneField, ControlledTextField } from '../../form';
import { ProgressButton } from '../../feedback';

export interface AddressFormWrappers {
  ContentWrapper?: FunctionComponent;
  ActionsWrapper?: FunctionComponent;
}

interface AddressFormProps {
  address: Address;
  disabled?: boolean;
  showDiscardButton?: boolean;
  showName?: boolean;
  loading?: boolean;
  wrappers?: AddressFormWrappers;
  onDirty?: (isDirty: boolean) => void;
  onSubmit: (address: Address) => unknown;
  onDiscard?: () => unknown;
}

export const AddressForm = ({
  address,
  showDiscardButton,
  showName,
  loading,
  wrappers,
  disabled,
  onDirty,
  onSubmit,
  onDiscard,
}: AddressFormProps): ReactElement => {
  const ContentWrapper = wrappers?.ContentWrapper || Fragment;
  const ActionsWrapper = wrappers?.ActionsWrapper || Fragment;

  const { control, handleSubmit, formState, setValue, reset } = useForm<Address>({ mode: 'all' });

  const onSubmitHandler = useCallback(
    async (data: Partial<Organization>) => {
      const updates = Object.keys(formState.dirtyFields).reduce(
        (memo, field) => ({ ...memo, [field]: data[field as keyof typeof data] }),
        {},
      );

      await onSubmit({ ...address, ...updates });
    },
    [formState, address],
  );

  const onResetHandler = useCallback(() => {
    reset();
    onDirty?.(false); // for some reason is not always fired by the form in the same render
    onDiscard?.();
  }, []);

  useEffect(() => {
    reset(); // reset the form to clear the isDirty flag.

    const cleanedAddress = pick(address, ['name', 'country', 'address', 'phone', 'zip', 'city', 'state', 'email']);

    Object.entries(cleanedAddress).forEach(([k, v]) => {
      setValue(k as keyof Address, v, { shouldValidate: true, shouldDirty: false });
    });
  }, [address]);

  useEffect(() => {
    onDirty?.(formState.isDirty);
  }, [formState.isDirty]);

  return (
    <form onSubmit={handleSubmit(onSubmitHandler)} onReset={onResetHandler}>
      <ContentWrapper>
        <Grid container spacing={2}>
          {showName && (
            <Grid item xs={12}>
              <ControlledTextField
                control={control}
                required
                name="name"
                label="Company Name"
                placeholder="Company Name"
                fullWidth
                variant="outlined"
                rules={VALIDATION_RULES.required}
                disabled={disabled}
              />
            </Grid>
          )}
          <Grid item xs={12}>
            <ControlledPhoneField
              control={control}
              required
              name="phone"
              label="Primary Phone"
              placeholder="Primary Phone"
              fullWidth
              variant="outlined"
              rules={VALIDATION_RULES.phoneRequired}
              disabled={disabled}
            />
          </Grid>
          <Grid item xs={12}>
            <ControlledTextField
              control={control}
              required
              name="email"
              label="Billing Email"
              placeholder="Billing Email"
              fullWidth
              variant="outlined"
              rules={VALIDATION_RULES.email}
              disabled={disabled}
            />
          </Grid>
          <Grid item xs={12}>
            <ControlledTextField
              control={control}
              required
              name="address"
              label="Address"
              placeholder="Address"
              fullWidth
              variant="outlined"
              rules={VALIDATION_RULES.required}
              disabled={disabled}
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <ControlledTextField
              control={control}
              required
              name="city"
              label="City"
              placeholder="City"
              fullWidth
              variant="outlined"
              rules={VALIDATION_RULES.required}
              disabled={disabled}
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <ControlledTextField
              control={control}
              name="state"
              label="State"
              placeholder="State"
              fullWidth
              variant="outlined"
              disabled={disabled}
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <ControlledTextField
              control={control}
              required
              name="zip"
              label="Zip"
              placeholder="Zip"
              fullWidth
              variant="outlined"
              rules={VALIDATION_RULES.required}
              disabled={disabled}
            />
          </Grid>
          <Grid item xs={12}>
            <ControlledCountryAutocomplete
              control={control}
              name="country"
              rules={VALIDATION_RULES.required}
              inputProps={{
                label: 'Country',
                placeholder: 'Country',
                fullWidth: true,
                variant: 'outlined',
                disabled,
              }}
            />
          </Grid>
        </Grid>
      </ContentWrapper>
      <ActionsWrapper>
        {showDiscardButton && (
          <ProgressButton
            color="primary"
            variant="outlined"
            disabled={loading || disabled}
            type="reset"
            onClick={onResetHandler}
          >
            Discard Changes
          </ProgressButton>
        )}
        <ProgressButton
          color="primary"
          variant="contained"
          loading={loading}
          disabled={!formState.isValid || disabled}
          type="submit"
        >
          Save
        </ProgressButton>
      </ActionsWrapper>
    </form>
  );
};
