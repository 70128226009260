import axios, { Axios } from 'axios';

import {
  CloudPodMetadata,
  CloudPodInjectPodBody,
  Health,
  StackInfo,
  Extension,
  IAMPolicyStreamConfig,
  PolicyStreamResponse,
  GeneratedPolicy,
  LocalStackConfigUpdateParams,
  RawPolicyStreamResponse,
  LocalStackChaosFaults,
  LocalStackChaosNetworkEffect,
  CloudPodRemoteAttributes,
  IAMPolicyStreamConfigPayload,
  ServiceResourceAggregationObj,
} from '@localstack/types';

interface LocalStackSettings {
  endpoint: string;
}

type ChunkedResponseEvent = ProgressEvent & {
  currentTarget: {
    response: string;
  };
};

type StreamConfig<T> = {
  onChunkReceived: (data: T) => void;
  onError: (err: string) => void;
  onStreamClose?: () => void;
};

type EnrichedStreamConfig<T> = StreamConfig<T> & {
  processRawData?: (data: string) => T;
};

const PATH_LOCALSTACK_INTERNAL = '/_localstack';
const PATH_PODS = `${PATH_LOCALSTACK_INTERNAL}/pods`;
const PATH_PODS_LAUNCHPAD = `${PATH_PODS}/launchpad`;
const PATH_CONFIG = `${PATH_LOCALSTACK_INTERNAL}/config`;
const PATH_IAM_INTERNAL = '/_aws/iam';
const PATH_IAM_POLICIES = `${PATH_IAM_INTERNAL}/policies`;
const PATH_IAM_STREAM_URL = `${PATH_IAM_POLICIES}/stream`;
const PATH_IAM_STREAM_SUMMARY_URL = `${PATH_IAM_POLICIES}/summary\\?stream=1`;
const PATH_RESOURCES_STREAM_URL = `${PATH_LOCALSTACK_INTERNAL}/resources`;
const PATH_CHAOS = `${PATH_LOCALSTACK_INTERNAL}/chaos`;
const PATH_CHAOS_FAULTS = `${PATH_CHAOS}/faults`;
const PATH_CHAOS_EFFECTS = `${PATH_CHAOS}/effects`;
const STREAM_DELIMITER = '\n';

export class LocalStack {
  private client: Axios;

  // keep compatible with aws services
  constructor(settings: LocalStackSettings) {
    this.client = axios.create({ baseURL: settings.endpoint });
  }

  public async fetchPod(url: string, onProgress?: (progress: number) => void): Promise<number> {
    const response = await this.client.post(
      `${PATH_PODS}/launchpad/fetch`,
      {},
      {
        params: { url },
        // Transfer-Encoding: chunked can't be set on the user end, at least with POSTs
        // headers: { 'Transfer-Encoding': 'chunked' },
        onDownloadProgress: (e: ProgressEvent) => {
          onProgress?.((e.loaded / e.total) * 100);
        },
      },
    );

    return response.data;
  }

  // LocalStack internal endpoint to load a specific pod version from the default platform remote
  public async loadPod(
    podName: string,
    version: Optional<number>,
    token: Optional<string>,
    merge: Optional<string> = null,
  ): Promise<void> {
    const searchParams = new URLSearchParams();
    if (version) {
      searchParams.append('version', version.toString());
    }
    if (merge) {
      searchParams.append('merge', merge);
    }
    const params = searchParams.toString();
    const baseUrl = `${PATH_PODS}/${podName}${params ? `?${params}` : ''}`;
    const { data } = await this.client.put(
      baseUrl,
      {},
      {
        headers: {
          Authorization: `${token}`,
        },
      },
    );
    return data;
  }

  public async savePodToRemote(
    podName: string,
    token: Optional<string>,
    attributes: CloudPodRemoteAttributes,
  ): Promise<void> {
    const url = `${PATH_PODS}/${podName}`;

    const { data } = await this.client.post(
      url,
      { attributes: attributes },
      {
        headers: {
          Authorization: `${token}`,
        },
      },
    );
    return data;
  }

  // Internal endpoint (from the plugin at the moment) to save the current pod state into a zip file
  public async savePod(): Promise<Blob> {
    const { data } = await this.client.get(`${PATH_PODS}/state`, {
      responseType: 'blob',
    });
    return data;
  }

  public async getPodMetadata(url: Optional<string>): Promise<CloudPodMetadata> {
    const { data } = await this.client.get(`${PATH_PODS_LAUNCHPAD}/metadata`, { params: { url } });
    return data;
  }

  public async injectPodFromUrl(url: string): Promise<CloudPodMetadata> {
    const { data } = await this.client.post(`${PATH_PODS_LAUNCHPAD}/inject`, {}, { params: { url } });
    return data;
  }

  public async injectPod(pod: CloudPodInjectPodBody): Promise<void> {
    const { data } = await this.client.post(PATH_PODS, pod.data, {
      headers: {
        'Content-Type': 'text/octet-stream',
      },
      params: {
        pod_name: pod.params.pod_name,
        pod_version: pod.params.pod_version,
        merge_strategy: pod.params.merge_strategy,
      },
    });

    return data;
  }

  public async getHealth(): Promise<Health> {
    const { data } = await this.client.get(`${PATH_LOCALSTACK_INTERNAL}/health`);
    return data;
  }

  public async getStackInfo(): Promise<StackInfo> {
    const { data } = await this.client.get(`${PATH_LOCALSTACK_INTERNAL}/stackinfo`);
    return data;
  }

  public async installExtension(url: string, setChunkedResponse?: (res: string) => void): Promise<void> {
    const { data } = await this.client.post(
      `${PATH_LOCALSTACK_INTERNAL}/extensions/install`,
      {
        URL: url,
        restart: true,
      },
      {
        onDownloadProgress: (e: ChunkedResponseEvent) => setChunkedResponse?.(e.currentTarget?.response),
      },
    );
    return data;
  }

  public async listExtensions(): Promise<Array<Extension>> {
    const { data } = await this.client.get(`${PATH_LOCALSTACK_INTERNAL}/extensions/list`);
    return data;
  }

  public async uninstallExtension(distribution: string, setChunkedResponse?: (res: string) => void): Promise<void> {
    const { data } = await this.client.post(
      `${PATH_LOCALSTACK_INTERNAL}/extensions/uninstall`,
      {
        distribution: distribution,
        restart: true,
      },
      {
        onDownloadProgress: (e: ChunkedResponseEvent) => setChunkedResponse?.(e.currentTarget?.response),
      },
    );
    return data;
  }

  public async getConfig(): Promise<Record<string, unknown>> {
    const { data } = await this.client.get(PATH_CONFIG);
    return data;
  }

  public async setConfig(params: LocalStackConfigUpdateParams): Promise<LocalStackConfigUpdateParams> {
    const { data } = await this.client.post(PATH_CONFIG, params, {
      headers: {
        'Content-Type': 'application/json',
      },
    });

    return data;
  }

  public async resetIAMPolicySummary(): Promise<void> {
    const { data } = await this.client.delete(`${PATH_IAM_POLICIES}/summary`);
    return data;
  }

  public async getIAMPolicyStreamConfig(): Promise<IAMPolicyStreamConfig> {
    const { data } = await this.client.get(`${PATH_IAM_INTERNAL}/config`);
    return data;
  }

  public async setIAMPolicyStreamConfig(config: IAMPolicyStreamConfigPayload): Promise<IAMPolicyStreamConfig> {
    const resp = await this.client.post(`${PATH_IAM_INTERNAL}/config`, config);
    return resp.data;
  }

  public async addChaosFaults(params: LocalStackChaosFaults): Promise<LocalStackChaosFaults> {
    const { data } = await this.client.patch(PATH_CHAOS_FAULTS, params, {
      headers: {
        'Content-Type': 'application/json',
      },
    });

    return data;
  }

  public async getChaosFaults(): Promise<LocalStackChaosFaults> {
    return (await this.client.get(PATH_CHAOS_FAULTS)).data;
  }

  public async deleteChaosFaults(params: LocalStackChaosFaults): Promise<LocalStackChaosFaults> {
    const { data } = await this.client.delete(PATH_CHAOS_FAULTS, {
      headers: {
        'Content-Type': 'application/json',
      },
      data: params,
    });
    return data;
  }

  public async getChaosNetworkEffects(): Promise<LocalStackChaosNetworkEffect> {
    return (await this.client.get(PATH_CHAOS_EFFECTS)).data;
  }

  public async addChaosNetworkEffect(params: LocalStackChaosNetworkEffect): Promise<LocalStackChaosNetworkEffect> {
    const { data } = await this.client.post(PATH_CHAOS_EFFECTS, params, {
      headers: {
        'Content-Type': 'application/json',
      },
    });

    return data;
  }

  private async streamDataFetcher<T>(
    reader: ReadableStreamDefaultReader<Uint8Array>,
    decoder: TextDecoder,
    config: EnrichedStreamConfig<T>,
  ): Promise<void> {
    let buffer = '';

    while (true) {
      const { value, done } = await reader.read();
      if (done) {
        config.onStreamClose?.();
        break;
      }
      const decodedMessage = decoder.decode(value, { stream: true });
      buffer += decodedMessage;
      if (decodedMessage.endsWith(STREAM_DELIMITER)) {
        const toParse = buffer;
        buffer = '';

        const consecutiveChunks = toParse
          .split(STREAM_DELIMITER)
          .filter(Boolean)
          .map((str) => str.trim());
        try {
          consecutiveChunks.forEach((singleChunk) => {
            const parsedData = config.processRawData ? config.processRawData(singleChunk) : JSON.parse(singleChunk);
            config.onChunkReceived(parsedData);
          });
        } catch {
          config.onError('Failed to parse data');
        }
      }
    }
  }

  private async openSteam<T>(
    config: EnrichedStreamConfig<T>,
    endpoint: string,
  ): Promise<Optional<ReadableStreamDefaultReader<Uint8Array>>> {
    try {
      const response = await fetch(endpoint);

      if (!response.ok || !response.body) {
        config.onError(response.statusText);
        return Promise.reject();
      }

      const reader = response.body.getReader();
      const decoder = new TextDecoder();

      this.streamDataFetcher(reader, decoder, config);

      return reader;
    } catch (e) {
      const error = (e as Error).message || 'Failed to connect to stream';
      config.onError(error);
      return Promise.reject();
    }
  }

  public async openResourcesStream(
    config: StreamConfig<ServiceResourceAggregationObj>,
  ): Promise<Optional<ReadableStreamDefaultReader<Uint8Array>>> {
    const endpoint = `${this.client.defaults?.baseURL}${PATH_RESOURCES_STREAM_URL}`;
    return this.openSteam(config, endpoint);
  }

  public async openPolicyStream(
    config: StreamConfig<PolicyStreamResponse>,
  ): Promise<Optional<ReadableStreamDefaultReader<Uint8Array>>> {
    const endpoint = `${this.client.defaults?.baseURL}${PATH_IAM_STREAM_URL}`;

    const processRawData = (data: string) => {
      const rawParsedChunk = JSON.parse(data) as RawPolicyStreamResponse;
      const { request, ...policy } = rawParsedChunk;
      return { request, policy };
    };

    return this.openSteam({ ...config, processRawData }, endpoint);
  }

  public async openPolicySummaryStream(
    config: StreamConfig<GeneratedPolicy[]>,
  ): Promise<Optional<ReadableStreamDefaultReader<Uint8Array>>> {
    const endpoint = `${this.client.defaults?.baseURL}${PATH_IAM_STREAM_SUMMARY_URL}`;

    return this.openSteam(config, endpoint);
  }

  public async restartInstance(): Promise<Health> {
    const { data } = await this.client.post(
      `${PATH_LOCALSTACK_INTERNAL}/health`,
      { action: 'restart' },
      {
        headers: {
          'Content-Type': 'application/json',
        },
      },
    );
    return data;
  }
}
