import { ReactElement } from 'react';
import { Typography } from '@mui/material';
import { Handle, Position } from '@xyflow/react';
import makeStyles from '@mui/styles/makeStyles';
import createStyles from '@mui/styles/createStyles';

import { NODE_SIZES } from '../constants';
import { Node, NodeRendererProps } from '../types';

const useStyles = makeStyles(() =>
  createStyles({
    node: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      textDecoration: 'underline',
      borderRadius: '50%',
      width: (node: Node) => `${NODE_SIZES[node.type].width}px`,
      height: (node: Node) => `${NODE_SIZES[node.type].height}px`,
    },
  }),
);

export const GroupLabelNode = ({ data }: NodeRendererProps): ReactElement => {
  const classes = useStyles(data);
  return (
    <div className={classes.node}>
      <Typography>{data.id}</Typography>
      <Handle type="target" position={Position.Top} />
      <Handle type="source" position={Position.Bottom} />
    </div>
  );
};
