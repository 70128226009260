import { useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { createContext, useContext, useState, ReactNode, useMemo } from 'react';

interface NavContextType {
  /** The latest state of the NavBar if it should be open or closed */
  isNavOpen: boolean;
  /** Whether the viewport is currently below md breakpoint */
  isMobile: boolean;
  /** Sets isNavOpen to true */
  openNav: () => void;
  /** Sets isNavOpen to false */
  closeNav: () => void;
}

const NavContext = createContext<NavContextType>({
  isNavOpen: false,
  isMobile: false,
  openNav: () => {},
  closeNav: () => {},
});

export const useNavigation = (): NavContextType => useContext(NavContext);

interface NavContextProviderProps {
  children: ReactNode;
}

export const NavContextProvider = ({ children }: NavContextProviderProps): JSX.Element => {
  const [isNavOpen, setIsNavOpen] = useState(false);
  const theme = useTheme();

  const openNav = () => setIsNavOpen(true);
  const closeNav = () => setIsNavOpen(false);

  const mdAndAbove = useMediaQuery(theme.breakpoints.up('md'));
  const isMobile = !mdAndAbove;

  const value = useMemo(
    () => ({
      isNavOpen,
      isMobile,
      openNav,
      closeNav,
    }),
    [isNavOpen, openNav, closeNav],
  );

  return <NavContext.Provider value={value}>{children}</NavContext.Provider>;
};
