import { ReactElement } from 'react';
import { NavLink } from '@localstack/ui';
import { buildRoute } from '@localstack/services';
import { useMatch } from 'react-router-dom';
import { Theme } from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import classNames from 'classnames';

import { SageMakerRoutes } from '../../types';

interface Props {
  routes: SageMakerRoutes;
}

export const NavTabs = ({ routes }: Props): ReactElement => {
  const useStyles = makeStyles((theme: Theme) =>
    createStyles({
      tabs: {
        '& ul': {
          display: 'flex',
          columnGap: '0.5em',
        },
        '& li': {
          display: 'inline-block',
          color: theme.palette.text.primary,
        },
      },
    }),
  );
  const classes = useStyles();
  const isSGMModels = !!useMatch(routes.RESOURCES_SGM_MODELS as string);
  const isSGMEndpoints = !!useMatch(routes.RESOURCES_SGM_ENDPOINT_CONFIGS as string);
  const isSGMEndpointConfigs = !!useMatch(routes.RESOURCES_SGM_ENDPOINTS as string);
  return (
    <div className={classes.tabs}>
      <ul>
        <li className={classNames({ active: isSGMModels })}>
          <NavLink to={buildRoute(routes.RESOURCES_SGM_MODELS)} end>
            Models
          </NavLink>
        </li>
        <li className={classNames({ active: isSGMEndpointConfigs })}>
          <NavLink to={buildRoute(routes.RESOURCES_SGM_ENDPOINT_CONFIGS)} end>
            Endpoint Configurations
          </NavLink>
        </li>
        <li className={classNames({ active: isSGMEndpoints })}>
          <NavLink to={buildRoute(routes.RESOURCES_SGM_ENDPOINTS)} end>
            Endpoints
          </NavLink>
        </li>
      </ul>
    </div>
  );
};
