import { ReactElement, useCallback, useState } from 'react';
import { Button, Card, CardContent } from '@mui/material';
import { ConfirmableButton, Dropdown, LoadingFragment, PageTitle, SGMModelsTable } from '@localstack/ui';
import { useRoutes, useAwsGetter, useAwsEffect } from '@localstack/services';

import { SageMakerProps } from './types';
import { NavTabs } from './components/NavTabs';

export const SGMModels = ({ Layout, clientOverrides, routes }: SageMakerProps): ReactElement => {
  const { goto } = useRoutes();
  const [selected, setSelected] = useState<string[]>([]);

  const {
    data: models,
    isLoading: isModelsLoading,
    mutate,
  } = useAwsGetter('SGM', 'listModels', [], { clientOverrides });
  const { deleteModel } = useAwsEffect('SGM', ['deleteModel'], { revalidate: ['listModels'], clientOverrides });

  const handleDeleteObjects = useCallback(
    async () => Promise.all(selected.map((ModelName) => deleteModel({ ModelName }))),
    [selected],
  );

  return (
    <>
      <Layout
        title={<PageTitle title="SageMaker Models" onMutate={mutate} />}
        tabs={<NavTabs routes={routes} />}
        actions={
          <>
            <Button onClick={() => goto(routes.RESOURCES_SGM_MODEL_NEW)}>Create</Button>
            <Dropdown label="Actions">
              <ConfirmableButton
                componentType="MenuItem"
                disabled={selected.length === 0 || isModelsLoading}
                title={`Remove ${selected.length} model(s)?`}
                onClick={handleDeleteObjects}
                text="Selected models will be permanently deleted"
              >
                Remove Selected
              </ConfirmableButton>
            </Dropdown>
          </>
        }
      >
        <Card>
          <CardContent>
            <LoadingFragment
              loading={isModelsLoading}
              arrayData={models?.Models ?? []}
              variant="list"
              height={50}
              size={10}
            >
              <SGMModelsTable
                loading={isModelsLoading}
                selectable
                onSelect={setSelected}
                models={models?.Models ?? []}
              />
            </LoadingFragment>
          </CardContent>
        </Card>
      </Layout>
    </>
  );
};
