import { ReactElement } from 'react';
import { useRoutes, useAwsGetter } from '@localstack/services';
import { useParams } from 'react-router-dom';
import { Card, CardContent, Button, Box, Typography } from '@mui/material';
import { Breadcrumbs, KMSKeyDetails } from '@localstack/ui';
import { DEFAULT_KMS_ROUTES } from '@localstack/constants';

import { KMSProps } from './types';

export const KMSKey = ({ Layout, clientOverrides, routes = DEFAULT_KMS_ROUTES }: KMSProps): ReactElement => {
  const { goto } = useRoutes();
  const { keyId } = useParams<'keyId'>();

  const { data: key } = useAwsGetter('KMS', 'describeKey', [{ KeyId: keyId }], { clientOverrides });

  return (
    <Layout
      documentTitle="KMS Key Details"
      title={
        <Box>
          <Typography variant="h4">KMS Key Details</Typography>
          <Breadcrumbs
            mappings={[
              ['KMS', () => goto(routes.RESOURCES_KMS)],
              ['Keys', () => goto(routes.RESOURCES_KMS_KEYS)],
              [keyId, null],
            ]}
          />
        </Box>
      }
      actions={<Button onClick={() => goto(routes.RESOURCES_KMS_KEY_UPDATE, { keyId })}>Edit Key</Button>}
    >
      <Card>
        <CardContent>
          <KMSKeyDetails metadata={key?.KeyMetadata} />
        </CardContent>
      </Card>
    </Layout>
  );
};
