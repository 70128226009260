import { BackdoorSESMessage } from '@localstack/types';
import { Link } from '@mui/material';

import { DataGrid, GridColDef } from '../../../display/DataGrid/index';

export interface SESMessagesTableProps {
  selectable?: boolean;
  rows: BackdoorSESMessage[];
  onView?: (id: string) => void;
}

const buildColumns = (onView: SESMessagesTableProps['onView']): GridColDef[] => [
  {
    field: 'Timestamp',
    headerName: 'Sent',
    width: 160,
    renderCell: (props) => new Date(props.value as string).toLocaleString(),
  },
  {
    field: 'Destination.ToAddresses',
    headerName: 'To',
    width: 160,
    renderCell: (prop) => prop.row.Destination.ToAddresses.join(', '),
  },
  {
    field: 'Subject',
    headerName: 'Subject',
    width: 250,
    renderCell: (params) => (
      <Link onClick={() => onView && onView(params.id as string)} underline="hover">
        {params.value || 'No subject'}
      </Link>
    ),
  },
];
export const SESMessagesTable: React.FC<SESMessagesTableProps> = ({ rows, onView }) => (
  <DataGrid
    autoHeight
    checkboxSelection={false}
    getRowId={(row) => JSON.stringify(row)}
    rows={rows}
    columns={buildColumns(onView)}
  />
);
