import { ReactElement } from 'react';
import { NavLink } from '@localstack/ui';
import { buildRoute } from '@localstack/services';

import { ApplicationAutoscalingRoutes } from '../../types';

interface Props {
  routes: ApplicationAutoscalingRoutes;
}

export const NavTabs = ({ routes }: Props): ReactElement => (
  <>
    <NavLink to={buildRoute(routes.RESOURCES_APPLICATION_AUTO_SCALING)}>Scalable Targets</NavLink>
  </>
);
