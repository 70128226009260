import { ReactElement, useState } from 'react';

import { FileCopyOutlined as CopyIcon } from '@mui/icons-material';

import {
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Grid,
  Link,
  List,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material';

import CopyToClipboard from 'react-copy-to-clipboard';
import { ExternalLink } from '@localstack/constants';

const BASE_EXTENSION_INSTALLER_LINK = `${document.URL}?url=git+`;

export const ExtensionsBadgeGenerator = (): ReactElement => {
  const [url, setUrl] = useState('');
  const [egg, setEgg] = useState('');
  const [link, setLink] = useState('');

  const generateLink = () => setLink(`${BASE_EXTENSION_INSTALLER_LINK}${url}/#egg=${egg}`);

  return (
    <>
      <Typography variant="h5" color="textSecondary">
        The LocalStack Extensions Installer allows you to easily create sharable links to install extensions that are
        hosted on GitHub. With it, you can also add a badge to your repo and let anyone quickly add your extension to
        their running LocalStack instance.
      </Typography>

      <Card>
        <CardHeader title="Generate your link" titleTypographyProps={{ variant: 'h4' }} />
        <CardContent style={{ display: 'flex', gap: '1rem', flexDirection: 'column' }}>
          <TextField
            fullWidth
            variant="outlined"
            value={url}
            label="GitHub URL to your extension"
            onChange={(event) => setUrl(event.target.value)}
          />
          <TextField
            fullWidth
            variant="outlined"
            value={egg}
            label="Name of your extension (in setup.cfg)"
            onChange={(event) => setEgg(event.target.value)}
          />
        </CardContent>
        <CardActions style={{ flexDirection: 'column', alignItems: 'flex-start' }}>
          <Button disabled={url === ''} variant="contained" color="primary" onClick={generateLink}>
            Generate Link
          </Button>
          {link && (
            <List style={{ width: '100%', marginTop: '1rem' }}>
              <ListItem disableGutters>
                <Grid container>
                  <Grid item xs={9}>
                    <Link variant="body1" href={link} target="_blank" underline="hover">
                      {link}
                    </Link>
                  </Grid>
                  <Grid item xs={3}>
                    <CopyToClipboard text={link}>
                      <Tooltip title="Copy Link">
                        <Button startIcon={<CopyIcon />} color="primary" size="small">
                          Copy Link
                        </Button>
                      </Tooltip>
                    </CopyToClipboard>
                  </Grid>
                </Grid>
              </ListItem>
              <ListItem disableGutters>
                <ListItemText primary={<img src={ExternalLink.BADGE_EXTENSIONS} alt="" />} />
                <ListItemSecondaryAction>
                  <CopyToClipboard
                    text={`[![Install LocalStack Extension](${ExternalLink.BADGE_EXTENSIONS})](${link})`}
                  >
                    <Tooltip title="Copy Code">
                      <Button startIcon={<CopyIcon />} color="primary" size="small">
                        Copy Markdown
                      </Button>
                    </Tooltip>
                  </CopyToClipboard>
                </ListItemSecondaryAction>
              </ListItem>
            </List>
          )}
        </CardActions>
      </Card>
    </>
  );
};
