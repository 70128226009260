import { ReactElement } from 'react';
import { NavLink } from '@localstack/ui';
import { buildRoute } from '@localstack/services';

import { CloudTrailRoutes } from '../types';

interface Props {
  routes: CloudTrailRoutes;
}

export const TrailsNavTabs = ({ routes }: Props): ReactElement => (
  <>
    <NavLink to={buildRoute(routes.RESOURCES_CLOUDTRAIL_EVENTS)}>Event History</NavLink>
    <NavLink to={buildRoute(routes.RESOURCES_CLOUDTRAIL_TRAILS)}>Trails</NavLink>
  </>
);
