import { useState, useEffect, useMemo, ReactElement } from 'react';
import { Alert, Button, CardContent, Grid } from '@mui/material';

import { useApiGetter, StacksService, useRoutes } from '@localstack/services';

import { ServicesChart, ClientsChart, ErrorsChart, RangeSelector, EventsTable } from '@localstack/ui';

import { ProductFeature, StackAwsApiCall } from '@localstack/types';

import { useAuthProvider } from '~/hooks/useAuthProvider';
import { AppRoute } from '~/config';

import { StackTabSkeleton } from './StackTabsSkeleton';

type StackTabProps = {
  stackId: string;
  sampleEvents?: StackAwsApiCall[];
};

export const StackTab = ({ stackId, sampleEvents }: StackTabProps): ReactElement => {
  const [interval, setInterval] = useState<[string, string]>(['', '']);
  const [intervalStartDate, intervalEndDate] = interval;

  const { hasFeature } = useAuthProvider();
  const { goto } = useRoutes();

  const useSampleEvents = !hasFeature(ProductFeature.FEATURE_STACKS_PREVIEW);

  const showRealData = hasFeature(ProductFeature.FEATURE_STACKS_PREMIUM);
  const showTeamPreview =
    hasFeature(ProductFeature.FEATURE_STACKS_PREVIEW) && !hasFeature(ProductFeature.FEATURE_STACKS_PREMIUM);

  const fetchEvents = showTeamPreview || showRealData;

  const { data: stackEvents, isLoading } = useApiGetter(StacksService, 'listStackEvents', [stackId], {
    enable: fetchEvents,
    suppressErrors: true,
  });

  const events = useMemo(() => {
    if (useSampleEvents) return sampleEvents;
    if (showTeamPreview) {
      return stackEvents?.slice(0, 24);
    }
    return stackEvents;
  }, [useSampleEvents, showTeamPreview, stackEvents, sampleEvents]);

  const sortedEvents: Optional<StackAwsApiCall[]> = useMemo(
    () => events?.sort((a, b) => new Date(a.server_time).getTime() - new Date(b.server_time).getTime()),
    [events],
  );

  const expandedEvents = useMemo(
    () => sortedEvents?.flatMap((event) => Array.from({ length: event.count }, () => event)),
    [sortedEvents],
  );

  const initialStart = expandedEvents?.[0]?.server_time ?? new Date().toISOString();
  const initialEnd = expandedEvents?.[expandedEvents.length - 1]?.server_time ?? new Date().toISOString();

  useEffect(() => {
    if (!expandedEvents?.length) return;
    setInterval([initialStart, initialEnd]);
  }, [expandedEvents]);

  const data =
    intervalStartDate && intervalEndDate
      ? (expandedEvents ?? []).filter(
          (e) =>
            new Date(e.server_time) >= new Date(intervalStartDate) &&
            new Date(e.server_time) <= new Date(intervalEndDate),
        )
      : (expandedEvents ?? []);

  const timestamps = expandedEvents?.map((event) => event.server_time) ?? [];

  if (isLoading && !useSampleEvents) return <StackTabSkeleton />;

  return (
    <CardContent>
      <Grid container spacing={2}>
        {showTeamPreview && (
          <Grid item xs={12}>
            <Alert
              severity="info"
              variant="outlined"
              action={
                <Button
                  size="small"
                  variant="outlined"
                  color="primary"
                  onClick={() => goto(AppRoute.SETTINGS_SUBSCRIPTIONS)}
                  style={{ whiteSpace: 'nowrap' }}
                >
                  Get Subscription
                </Button>
              }
            >
              Only the first 25 events of your stack are shown. To see all {stackEvents?.length} events, please upgrade
              to a team plan.
            </Alert>
          </Grid>
        )}
        {data ? (
          <>
            <Grid item xs={12}>
              <ErrorsChart
                title="API Calls"
                data={data}
                showZoomOut={intervalStartDate !== initialStart || intervalEndDate !== initialEnd}
                onResetInterval={() => setInterval([initialStart, initialEnd])}
                onChangeInterval={(start, end) => setInterval([start, end])}
              />
            </Grid>
            <Grid item xs={12} xl={6}>
              <ServicesChart
                title="Service invocations"
                data={data}
                showZoomOut={intervalStartDate !== initialStart || intervalEndDate !== initialEnd}
                onResetInterval={() => setInterval([initialStart, initialEnd])}
                onChangeInterval={(start, end) => setInterval([start, end])}
              />
            </Grid>
            <Grid item xs={12} xl={6}>
              <ClientsChart
                title="Clients"
                data={data}
                showZoomOut={intervalStartDate !== initialStart || intervalEndDate !== initialEnd}
                onResetInterval={() => setInterval([initialStart, initialEnd])}
                onChangeInterval={(start, end) => setInterval([start, end])}
              />
            </Grid>
            <Grid item xs={12}>
              <RangeSelector
                dates={timestamps}
                intervalStartDate={intervalStartDate}
                intervalEndDate={intervalEndDate}
                onChangeInterval={(start, end) => setInterval([start, end])}
              />
            </Grid>
            <Grid item xs={12}>
              <EventsTable rows={data} />
            </Grid>
          </>
        ) : (
          <Grid item xs={12}>
            <Alert severity="info">Data about this stack is not available yet</Alert>
          </Grid>
        )}
      </Grid>
    </CardContent>
  );
};
