import { ReactElement } from 'react';
import { useParams } from 'react-router-dom';
import { useRoutes, useAwsEffect } from '@localstack/services';
import { Card, CardContent, CardActions, Box, Typography } from '@mui/material';
import { SendSESEmailRequest } from '@localstack/types';
import { ProgressButton, SESV1MessageForm, Breadcrumbs } from '@localstack/ui';
import { DEFAULT_SES_ROUTES } from '@localstack/constants';

import { SESProps } from './types';
import { IndexNavTabs } from './components';

export const SESV1MessageUpsert = ({
  Layout,
  clientOverrides,
  routes = DEFAULT_SES_ROUTES,
}: SESProps): ReactElement => {
  const { goto } = useRoutes();

  const { id } = useParams<'id'>();

  const { sendEmail, isLoading } = useAwsEffect('SES', ['sendEmail'], { clientOverrides });

  const handleCreate = async (data: SendSESEmailRequest) => {
    await sendEmail(data);
    goto(routes.RESOURCES_SES1_IDENTITY_MESSAGES, { id });
  };

  return (
    <Layout
      documentTitle="Message Details"
      title={
        <Box>
          <Typography variant="h4">Message Details</Typography>
          <Breadcrumbs
            mappings={[
              ['SES', () => goto(routes.RESOURCES_SES1)],
              ['Identities', () => goto(routes.RESOURCES_SES1_IDENTITIES)],
              [id, () => goto(routes.RESOURCES_SES1_IDENTITY_MESSAGES, { id })],
              ['Send Message', null],
            ]}
          />
        </Box>
      }
      tabs={<IndexNavTabs routes={routes} />}
      actions={
        <ProgressButton type="submit" form="update" variant="outlined" color="primary" loading={isLoading}>
          Submit
        </ProgressButton>
      }
    >
      <Card>
        <CardContent>
          <SESV1MessageForm source={id as string} onSubmit={handleCreate} loading={isLoading} formId="update" />
        </CardContent>
        <CardActions>
          <ProgressButton type="submit" form="update" variant="contained" color="primary" loading={isLoading}>
            Submit
          </ProgressButton>
        </CardActions>
      </Card>
    </Layout>
  );
};
