import { ReactElement, useState } from 'react';
import axios from 'axios';
import ReactJsonViewCompare from 'react-json-view-compare';
import { useRoutes, useEndpoint } from '@localstack/services';
import { useParams } from 'react-router-dom';
import { Card, CardContent, Box, Typography, CardActions, Grid } from '@mui/material';
import { Breadcrumbs, ProgressButton, AppSyncQueryForm, AppSyncQueryFormData, NewTabLink } from '@localstack/ui';

import { DEFAULT_APPSYNC_ROUTES } from '@localstack/constants';

import { NavTabs } from './components';
import { AppSyncProps } from './types';

export const AppSyncApiQuery = ({
  Layout,
  clientOverrides,
  routes = DEFAULT_APPSYNC_ROUTES,
}: AppSyncProps): ReactElement => {
  const { goto } = useRoutes();
  const { apiId } = useParams<'apiId'>();

  const { endpoint } = useEndpoint();

  const [result, setResult] = useState({});

  const graphQlEndpoint = `${clientOverrides?.endpoint ?? endpoint}/graphql/${apiId}`;

  const execute = async (formData: AppSyncQueryFormData) => {
    const headers = { 'x-api-key': formData.apiKey };
    const { data } = await axios.post(graphQlEndpoint, formData, { headers });
    setResult(data);
  };

  return (
    <Layout
      documentTitle="Query"
      tabs={<NavTabs apiId={apiId as string} routes={routes} />}
      title={
        <Box>
          <Typography variant="h4">Query</Typography>
          <Breadcrumbs
            mappings={[
              ['AppSync', () => goto(routes.RESOURCES_APPSYNC)],
              [apiId, () => goto(routes.RESOURCES_APPSYNC_API, { apiId })],
              ['Query', null],
            ]}
          />
        </Box>
      }
      actions={
        <NewTabLink type="Link" href={graphQlEndpoint}>
          {graphQlEndpoint}
        </NewTabLink>
      }
    >
      <Card>
        <CardContent>
          <Grid container spacing={3}>
            <Grid item md={6}>
              <AppSyncQueryForm apiId={apiId} clientOverrides={clientOverrides} formId="query" onSubmit={execute} />
            </Grid>
            <Grid item md={6}>
              <ReactJsonViewCompare oldData={result} newData={result} />
            </Grid>
          </Grid>
        </CardContent>
        <CardActions>
          <ProgressButton variant="contained" color="primary" type="submit" form="query">
            Execute
          </ProgressButton>
        </CardActions>
      </Card>
    </Layout>
  );
};
