import { ReactElement } from 'react';
import { CloudFormationResourceSummary } from '@localstack/types';

import { getSchema } from '@localstack/services';

import { MagicTable } from '../../magic/MagicTable';

export type CloudFormationResourceGridProps = {
  entries: CloudFormationResourceSummary[];
  loading?: boolean;
};

export const CloudFormationResourceGrid = ({ entries, loading }: CloudFormationResourceGridProps): ReactElement => (
  <MagicTable
    schema={getSchema('CloudFormation')}
    entry="StackResourceSummaries"
    idAttribute="LogicalResourceId"
    filterColumns={[
      'LogicalResourceId',
      'PhysicalResourceId',
      'ResourceType',
      'LastUpdatedTimestamp',
      'ResourceStatus',
    ]}
    rows={entries}
    loading={loading}
  />
);
