import { Container, Theme, lighten } from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import { ReactElement } from 'react';

type Props = {
  children: ReactElement;
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      background: lighten(theme.palette.background.default, 0.03),
      display: 'flex',
      minHeight: '100vh',
    },
    pageContainer: {
      paddingLeft: '0 !important',
      paddingRight: '0 !important',
    },
  }),
);

export const BaseExtensionLayout = ({ children }: Props): ReactElement => {
  const classes = useStyles();

  return (
    <div data-name="page container" className={classes.root}>
      <Container maxWidth="lg" className={classes.pageContainer}>
        {children}
      </Container>
    </div>
  );
};
