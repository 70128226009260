import { useState, ReactElement, ChangeEvent } from 'react';
import { ProgressButton } from '@localstack/ui';

import { Box, TextField, Card, CardHeader, CardContent, CardActions, FormControlLabel, Checkbox } from '@mui/material';

type Props = {
  className?: string;
  loading: boolean;
  onInviteMember: (name: string, email: string, tryAssignLicenseOnJoin: boolean) => unknown;
};

export const InvitationForm = ({ className, loading, onInviteMember }: Props): ReactElement => {
  const [name, setName] = useState<string>('');
  const [email, setEmail] = useState<string>('');
  const [assignLicenseOnJoin, setAssignLicenseOnJoin] = useState<boolean>(true);

  return (
    <Card className={className} variant="outlined">
      <CardHeader title="Invite new Member" />
      <CardContent>
        <Box>
          <TextField
            fullWidth
            variant="outlined"
            label="Invitee Name"
            value={name}
            onChange={(event: ChangeEvent<HTMLInputElement>) => setName(event?.target.value)}
          />
        </Box>
        <Box mt={3}>
          <TextField
            fullWidth
            variant="outlined"
            label="Invitee Email"
            value={email}
            onChange={(event: ChangeEvent<HTMLInputElement>) => setEmail(event?.target.value)}
          />
        </Box>
        <Box mt={3}>
          <FormControlLabel
            control={
              <Checkbox
                color="primary"
                onChange={(_, value) => setAssignLicenseOnJoin(value)}
                checked={assignLicenseOnJoin}
              />
            }
            label={
              <>
                <Box>Automatically assign a license when the user joins (subject to availability)</Box>
              </>
            }
          />
        </Box>
      </CardContent>
      <CardActions>
        <ProgressButton
          color="primary"
          variant="contained"
          size="small"
          loading={loading}
          disabled={!name || !email || loading}
          onClick={() => onInviteMember(name, email, assignLicenseOnJoin)}
        >
          Invite
        </ProgressButton>
      </CardActions>
    </Card>
  );
};
