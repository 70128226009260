import { Fragment, FunctionComponent, ReactElement, useCallback, useEffect } from 'react';
import { Grid } from '@mui/material';
import { pick } from 'lodash';

import { getTaxIdExample, VALIDATION_RULES } from '@localstack/services';
import { useForm } from 'react-hook-form';

import { ControlledCountryAutocomplete, ControlledTextField } from '../../form';
import { TaxIdTextField } from '../account/TaxIdTextField';
import { ProgressButton } from '../../feedback';

export interface TaxFormWrappers {
  ContentWrapper?: FunctionComponent;
  ActionsWrapper?: FunctionComponent;
}

export interface TaxFormProps {
  wrappers?: TaxFormWrappers;
  loading?: boolean;
  taxData: TaxFormData;
  onSubmit: (data: TaxFormData) => unknown;
  onDirty?: (dirty: boolean) => unknown;
}

interface TaxFormData {
  // TODO: maybe it's enough to have the name on the address level?
  name: string;
  country?: string;
  tax_id?: Optional<string>;
  tax_id_type?: Optional<string>;
}

export const TaxForm = ({ wrappers, loading, taxData, onSubmit, onDirty }: TaxFormProps): ReactElement => {
  const { control, handleSubmit, formState, setValue, reset, watch } = useForm<TaxFormData>({ mode: 'all' });

  useEffect(() => {
    const cleanedTaxData = pick(taxData, ['name', 'country', 'tax_id', 'tax_id_type']);
    reset(cleanedTaxData);
  }, [taxData]);

  const onResetHandler = useCallback(() => {
    reset();
    onDirty?.(false);
  }, []);

  const taxId = watch('tax_id');
  const country = watch('country');

  const ContentWrapper = wrappers?.ContentWrapper || Fragment;
  const ActionsWrapper = wrappers?.ActionsWrapper || Fragment;

  return (
    <form onSubmit={handleSubmit(onSubmit)} onReset={onResetHandler}>
      <ContentWrapper>
        <Grid container spacing={2} alignItems="center">
          <Grid item xs={12}>
            <ControlledTextField
              control={control}
              name="name"
              label="Company Name"
              placeholder="Company Name"
              fullWidth
              required
              variant="outlined"
              rules={VALIDATION_RULES.required}
            />
          </Grid>
          <Grid item md={6} xs={12}>
            <ControlledCountryAutocomplete
              control={control}
              name="country"
              rules={VALIDATION_RULES.required}
              inputProps={{
                label: 'Country',
                placeholder: 'Country',
                fullWidth: true,
                required: true,
                variant: 'outlined',
              }}
            />
          </Grid>
          <Grid item md={6} xs={12}>
            <TaxIdTextField
              control={control}
              name="tax_id"
              label="TAX ID"
              placeholder={getTaxIdExample(country) || ''}
              fullWidth
              variant="outlined"
              required
              rules={VALIDATION_RULES.taxId(country)}
              taxId={taxId}
              country={country}
              onChangeTaxType={(newTaxType) => setValue('tax_id_type', newTaxType || undefined, { shouldDirty: true })}
            />
          </Grid>
        </Grid>
      </ContentWrapper>
      <ActionsWrapper>
        <ProgressButton
          color="primary"
          variant="contained"
          loading={loading}
          disabled={!formState.isValid || loading}
          type="submit"
        >
          Save
        </ProgressButton>
      </ActionsWrapper>
    </form>
  );
};
