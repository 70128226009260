// WARNING: this file has been autogenerated, do not modify it
import { SERVICE_NAME } from './serviceName';

export const RESOURCES_SERVICES_TYPE = {
  community: [
    'acm',
    'apigateway',
    'cloudformation',
    'cloudwatch',
    'config',
    'dynamodb',
    'dynamodbstreams',
    'ec2',
    'es',
    'events',
    'firehose',
    'iam',
    'kinesis',
    'kms',
    'lambda',
    'logs',
    'opensearch',
    'redshift',
    'resource-groups',
    'resourcegroupstaggingapi',
    'route53',
    'route53resolver',
    's3',
    's3control',
    'scheduler',
    'secretsmanager',
    'ses',
    'sns',
    'sqs',
    'ssm',
    'stepfunctions',
    'sts',
    'support',
    'swf',
    'transcribe',
  ] as Partial<SERVICE_NAME>[],
  pro: [
    'account',
    'acm-pca',
    'amplify',
    'apigatewaymanagementapi',
    'apigatewayv2',
    'appconfig',
    'appconfigdata',
    'application-autoscaling',
    'appsync',
    'athena',
    'autoscaling',
    'backup',
    'batch',
    'ce',
    'cloudcontrol',
    'cloudfront',
    'cloudtrail',
    'codebuild',
    'codecommit',
    'codedeploy',
    'codepipeline',
    'codestar-connections',
    'cognito-identity',
    'cognito-idp',
    'docdb',
    'ecr',
    'ecs',
    'efs',
    'eks',
    'elasticache',
    'elasticbeanstalk',
    'elastictranscoder',
    'elb',
    'elbv2',
    'emr',
    'emr-serverless',
    'fis',
    'glacier',
    'glue',
    'identitystore',
    'iot',
    'iot-data',
    'iotanalytics',
    'iotwireless',
    'kafka',
    'kinesisanalytics',
    'kinesisanalyticsv2',
    'lakeformation',
    'managedblockchain',
    'mediaconvert',
    'mediastore',
    'mediastore-data',
    'memorydb',
    'mq',
    'mwaa',
    'neptune',
    'organizations',
    'pinpoint',
    'pipes',
    'qldb',
    'qldb-session',
    'ram',
    'rds',
    'rds-data',
    'redshift-data',
    'sagemaker',
    'sagemaker-runtime',
    'serverlessrepo',
    'servicediscovery',
    'sesv2',
    'shield',
    'sso-admin',
    'textract',
    'timestream-query',
    'timestream-write',
    'transfer',
    'wafv2',
    'xray',
  ] as Partial<SERVICE_NAME>[],
  enterprise: ['dms', 'bedrock', 'bedrock-runtime'] as Partial<SERVICE_NAME>[],
};
