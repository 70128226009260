import { ProductFeature, WidgetOptions } from '@localstack/types';
import { StackList, StackListSkeleton } from '@localstack/ui';
import { Alert, Box, Button, CardHeader, IconButton, Link } from '@mui/material';
import { ReactElement } from 'react';

import { useAwsGetter, useRoutes } from '@localstack/services';

import { RemoveCircle as RemoveIcon } from '@mui/icons-material';

import { ExternalLink } from '@localstack/constants';

import { AppRoute } from '~/config';
import { useAuthProvider } from '~/hooks/useAuthProvider';

export const StacksWidgetOptions: WidgetOptions = {
  i: 'stacks_list',
  image: '/widgets/stacks.png',
  style: 'mediaLong',
  xl: {
    w: 12,
    h: 20,
  },
  lg: {
    w: 12,
    h: 20,
  },
  md: {
    w: 10,
    h: 20,
  },
  sm: {
    w: 12,
    h: 14,
  },
  xs: {
    w: 12,
    h: 14,
  },
};

type Props = {
  className?: string;
  style?: Record<string, string>;
  children?: JSX.Element;
  removeCallback?: () => void;
  editing?: boolean;
};

export const StacksWidget = ({ className, style, children, removeCallback, editing, ...rest }: Props): ReactElement => {
  const { goto } = useRoutes();
  const { hasFeature } = useAuthProvider();

  const { data: stackInfo, isLoading: isStackInfoLoading } = useAwsGetter('LocalStack', 'getStackInfo', [], {
    silentErrors: true,
  });
  const { hasError: hasHealthError } = useAwsGetter('LocalStack', 'getHealth', [], { silentErrors: true });

  const isLoading = isStackInfoLoading;
  const hasSubscription = hasFeature(ProductFeature.FEATURE_STACKS_PREVIEW);

  const AlertComponent = !hasHealthError ? (
    <Alert
      severity="info"
      action={
        !hasSubscription && (
          <Button
            size="small"
            color="primary"
            variant="outlined"
            style={{ whiteSpace: 'nowrap' }}
            href={AppRoute.PRICING}
            onClick={() => goto(AppRoute.PRICING)}
          >
            Upgrade Now
          </Button>
        )
      }
    >
      You are running the LocalStack community version.
      {hasSubscription && <> To use Stack Insights, please start the LocalStack Pro Edition.</>}
      {!hasSubscription && (
        <>
          {' '}
          Would you like to upgrade to use{' '}
          <Link href={ExternalLink.DOCS_STACK_INSIGHTS} target="_blank" underline="hover">
            Stack Insights
          </Link>
          ?
        </>
      )}
    </Alert>
  ) : null;

  const handleStackClick = hasFeature(ProductFeature.FEATURE_STACKS_PREVIEW)
    ? (stackId: string) => goto(AppRoute.STACKS_STACK, { stackId })
    : undefined;

  return (
    <Box className={className} style={style} {...rest}>
      <Box style={{ height: '100%' }} display="flex" flexDirection="column">
        <CardHeader
          title="Stacks"
          titleTypographyProps={{ variant: 'subtitle1' }}
          action={
            <Box display="flex" style={{ gap: 20, paddingLeft: 20 }} alignItems="center">
              {editing && (
                <IconButton aria-label="remove" onClick={removeCallback} size="large">
                  <RemoveIcon />
                </IconButton>
              )}
            </Box>
          }
        />
        <Box flexGrow={1} style={{ overflowY: 'auto', padding: '0 10px' }}>
          {isLoading ? (
            <StackListSkeleton size="lg" />
          ) : (
            <StackList
              activeStack={stackInfo ?? undefined}
              historyStacks={[]}
              totalNumberOfStacks={0}
              size="lg"
              onClick={handleStackClick}
              noActiveStackError={AlertComponent}
              disableHistory
              stacksRoute={AppRoute.STACKS_OVERVIEW}
            />
          )}
        </Box>
        {children}
      </Box>
    </Box>
  );
};
