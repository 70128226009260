import { ReactElement, useCallback } from 'react';
import { useRoutes, useAwsGetter } from '@localstack/services';
import { useParams } from 'react-router-dom';
import { Card, CardContent, Box, Typography, Button, Grid, CardActions } from '@mui/material';
import { Breadcrumbs, IAMPolicyDetails as Details, IAMPolicyVersion } from '@localstack/ui';

import { DEFAULT_IAM_ROUTES } from '@localstack/constants/src';

import { NavTabs } from './components';
import { IAMProps } from './types';

export const IAMPolicyDetails = ({ Layout, clientOverrides, routes = DEFAULT_IAM_ROUTES }: IAMProps): ReactElement => {
  const { goto } = useRoutes();
  const { policyArn } = useParams<'policyArn'>();
  const decodedPolicyArn = decodeURIComponent(policyArn || '');
  const { data: policy } = useAwsGetter(
    'IAM',
    'getPolicy',
    [
      {
        PolicyArn: decodedPolicyArn,
      },
    ],
    { clientOverrides },
  );

  const { data: policyVersion } = useAwsGetter(
    'IAM',
    'getPolicyVersion',
    [
      {
        PolicyArn: decodedPolicyArn,
        VersionId: policy?.Policy?.DefaultVersionId || '',
      },
    ],
    { clientOverrides },
  );

  const gotoUpdatePolicyVersion = useCallback(
    () =>
      goto(routes.RESOURCES_IAM_POLICY_VERSION_UPDATE, {
        policyArn: decodedPolicyArn,
        versionId: policy?.Policy?.DefaultVersionId || '',
      }),
    [policy],
  );

  return (
    <Layout
      documentTitle="IAM: Policy Details"
      tabs={<NavTabs routes={routes} />}
      title={
        <Box>
          <Typography variant="h4">IAM Policy Details</Typography>
          <Breadcrumbs
            mappings={[
              ['IAM', () => goto(routes.RESOURCES_IAM)],
              ['Policies', () => goto(routes.RESOURCES_IAM_POLICIES)],
              [policy?.Policy?.PolicyName, null],
            ]}
          />
        </Box>
      }
    >
      <Grid container spacing={3}>
        <Grid item xs={12} md={6} lg={5}>
          <Card>
            <CardContent>
              <Details policy={policy?.Policy} />
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} md={6} lg={7}>
          <Card>
            <CardActions>
              <Button onClick={gotoUpdatePolicyVersion}>Update Policy Version</Button>
            </CardActions>
            <CardContent>
              <IAMPolicyVersion policyVersion={policyVersion?.PolicyVersion} />
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </Layout>
  );
};
