import { ReactNode, ReactElement } from 'react';
import { Box } from '@mui/material';
import { Theme } from '@mui/material/styles';

import makeStyles from '@mui/styles/makeStyles';

import { AllowedPlanChipPlans } from '@localstack/types';

import { PlanChip } from '../../feedback/PlanChip';

const useStyles = makeStyles((theme: Theme) => ({
  actions: {
    display: 'flex',
    alignItems: 'center',
    '& > *': {
      marginRight: theme.spacing(1),
    },
    '& > *:last-child': {
      marginRight: theme.spacing(0),
    },
  },
}));

export interface ActionTitleProp {
  title: ReactNode;
  variant?: 'default' | 'dense';
  actions?: ReactNode;
  planFamily?: AllowedPlanChipPlans;
}

export const ActionTitle = ({ title, variant = 'default', actions, planFamily }: ActionTitleProp): ReactElement => {
  const classes = useStyles();
  return (
    <Box display="flex" alignItems="center" justifyContent={variant === 'default' ? 'space-between' : undefined}>
      <Box flexGrow={variant === 'default' ? 1 : undefined}>{title}</Box>
      {planFamily && <PlanChip planFamily={planFamily} />}
      <div className={classes.actions}>{actions}</div>
    </Box>
  );
};
