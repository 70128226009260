import { ReactElement } from 'react';
import { useParams } from 'react-router-dom';
import { Card, CardContent, CardActions, Box, Typography } from '@mui/material';
import { CreateCloudWatchLogStreamRequest } from '@localstack/types';
import { useRoutes, useAwsEffect } from '@localstack/services';
import { ProgressButton, CloudWatchLogStreamForm, Breadcrumbs } from '@localstack/ui';
import { DEFAULT_CLOUDWATCH_ROUTES } from '@localstack/constants';

import { CloudWatchProps } from './types';

export const CloudWatchLogStreamUpsert = ({
  Layout,
  clientOverrides,
  routes = DEFAULT_CLOUDWATCH_ROUTES,
}: CloudWatchProps): ReactElement => {
  const { goto } = useRoutes();
  const { logGroupName } = useParams<'logGroupName'>();

  const { createLogStream, isLoading } = useAwsEffect('CloudWatchLogs', ['createLogStream'], {
    revalidate: ['describeLogStreams'],
    clientOverrides,
  });

  const handleCreate = async (data: CreateCloudWatchLogStreamRequest) => {
    await createLogStream(data);
    goto(routes.RESOURCES_CLOUDWATCH_GROUP_STREAMS, { logGroupName });
  };

  return (
    <Layout
      documentTitle="Log Stream Details"
      title={
        <Box>
          <Typography variant="h4">Log Stream Details</Typography>
          <Breadcrumbs
            mappings={[
              ['CloudWatch Logs', () => goto(routes.RESOURCES_CLOUDWATCH)],
              ['Log Groups', () => goto(routes.RESOURCES_CLOUDWATCH_GROUPS)],
              [logGroupName, () => goto(routes.RESOURCES_CLOUDWATCH_GROUP_EVENTS, { logGroupName })],
              ['Create Log Stream', null],
            ]}
          />
        </Box>
      }
      actions={
        <ProgressButton type="submit" form="update" variant="outlined" color="primary" loading={isLoading}>
          Submit
        </ProgressButton>
      }
    >
      <Card>
        <CardContent>
          <CloudWatchLogStreamForm
            logGroupName={logGroupName as string}
            onCreate={handleCreate}
            loading={isLoading}
            formId="update"
          />
        </CardContent>
        <CardActions>
          <ProgressButton type="submit" form="update" variant="contained" color="primary" loading={isLoading}>
            Submit
          </ProgressButton>
        </CardActions>
      </Card>
    </Layout>
  );
};
