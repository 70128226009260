import { ReactElement, useCallback, useState } from 'react';
import { Button, Card } from '@mui/material';
import { useParams } from 'react-router-dom';
import { ConfirmableButton, Dropdown, LoadingFragment, SNSSubscriptionTable, PageTitle } from '@localstack/ui';

import { useAwsEffect, useAwsGetter, useRoutes } from '@localstack/services';

import { DEFAULT_SNS_ROUTES } from '@localstack/constants';

import { TopicNavTabs } from './components';
import { SNSProps } from './types';

export const SNSSubscriptions = ({ Layout, clientOverrides, routes = DEFAULT_SNS_ROUTES }: SNSProps): ReactElement => {
  const { goto } = useRoutes();
  const { topicArn } = useParams<'topicArn'>();

  const [selectedSubs, setSelectedSubs] = useState<string[]>([]);

  const {
    data: subscriptions,
    isLoading,
    mutate,
  } = useAwsGetter('SNS', 'listSubscriptionsByTopic', [{ TopicArn: topicArn }], { clientOverrides });
  const { unsubscribe } = useAwsEffect('SNS', ['unsubscribe'], {
    revalidate: ['listSubscriptionsByTopic'],
    clientOverrides,
  });

  const handleDeleteSubs = useCallback(
    async () => Promise.all(selectedSubs.map((SubscriptionArn) => unsubscribe({ SubscriptionArn }))),
    [selectedSubs],
  );

  return (
    <>
      <Layout
        documentTitle="SNS Topic Subscriptions"
        tabs={<TopicNavTabs topicArn={topicArn as string} routes={routes} />}
        title={
          <PageTitle
            title="SNS Topic Subscriptions"
            onMutate={mutate}
            breadcrumbs={[
              ['SNS', () => goto(routes.RESOURCES_SNS_TOPICS)],
              [topicArn?.split(':')?.slice(-1)?.[0], () => goto(routes.RESOURCES_SNS_TOPIC, { topicArn })],
              ['Subscriptions', null],
            ]}
          />
        }
        actions={
          <>
            <Button onClick={() => goto(routes.RESOURCES_SNS_TOPIC_SUBSCRIPTION_CREATE, { topicArn })}>
              Create Subscription
            </Button>
            <Dropdown label="Actions">
              <ConfirmableButton
                componentType="MenuItem"
                disabled={selectedSubs.length === 0 || isLoading}
                title={`Delete ${selectedSubs.length} Subscription(s)?`}
                onClick={handleDeleteSubs}
                text="Selected Subscription(s) will be permanently deleted"
              >
                Unsubscribe Selected
              </ConfirmableButton>
            </Dropdown>
          </>
        }
      >
        <Card>
          <LoadingFragment loading={isLoading} variant="card" height={300}>
            <SNSSubscriptionTable
              entries={subscriptions?.Subscriptions ?? []}
              onSelect={setSelectedSubs}
              onViewSNSSubscription={(sub) =>
                goto(routes.RESOURCES_SNS_TOPIC_SUBSCRIPTION, { topicArn, subscriptionArn: sub.SubscriptionArn })
              }
            />
          </LoadingFragment>
        </Card>
      </Layout>
    </>
  );
};
