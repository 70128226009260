import { useState, ReactElement, useEffect } from 'react';
import {
  satisfiesVersionConstraint,
  useAwsEffect,
  useAwsGetter,
  useLocalstackStatus,
  useSnackbar,
} from '@localstack/services';

import {
  Box,
  Grid,
  Typography,
  ToggleButtonGroup,
  ToggleButton,
  Alert,
  Button,
  Card,
  CardContent,
  FormControlLabel,
  Switch,
} from '@mui/material';
import { PlanChip } from '@localstack/ui';

import { FeatureMaturityLevel, IAMPolicyEngineState } from '@localstack/types';

import { ExternalLink } from '@localstack/constants';

import { InstanceViewProps } from '../props';

import { PolicyStream } from './components/PolicyStream';
import { PolicySummary } from './components/PolicySummary';

const MIN_REQUIRED_VERSION = '4.0.0';

enum ToggleState {
  OPERATIONS = 'operations',
  POLICIES = 'policies',
}

export const IAMStream = ({ Layout, clientOverrides = {} }: InstanceViewProps): ReactElement => {
  const { showSnackbar } = useSnackbar();
  const createSnackbarError = (error: string) => showSnackbar({ message: error, severity: 'error' });

  const { running: isLocalStackRunning, version } = useLocalstackStatus(clientOverrides);
  const hasMinVersion = satisfiesVersionConstraint(version || '0', MIN_REQUIRED_VERSION);

  const [toggleState, setToggleState] = useState(ToggleState.OPERATIONS);

  const { data: policyConfig, mutate: refetchPolicyConfig } = useAwsGetter(
    'LocalStack',
    'getIAMPolicyStreamConfig',
    [],
    { clientOverrides, silentErrors: true },
  );

  const { setIAMPolicyStreamConfig } = useAwsEffect('LocalStack', ['setIAMPolicyStreamConfig'], {
    silentErrors: true,
    clientOverrides,
  });

  const [offPolicyEngineState, setOffPolicyEngineState] = useState<Optional<IAMPolicyEngineState>>();

  const [policyEngineStatus, setPolicyEngineStatus] = useState<Optional<IAMPolicyEngineState>>(policyConfig?.state);

  useEffect(() => {
    if (policyConfig?.state !== policyEngineStatus) {
      setPolicyEngineStatus(policyConfig?.state);
    }
  }, [policyConfig]);

  useEffect(() => {
    if (offPolicyEngineState === undefined && policyConfig) {
      if (policyConfig.state === IAMPolicyEngineState.SoftMode) {
        setOffPolicyEngineState(IAMPolicyEngineState.SoftMode);
      } else {
        setOffPolicyEngineState(IAMPolicyEngineState.EngineOnly);
      }
    }
  }, [policyConfig]);

  useEffect(() => {
    const changeEngineState = async () => {
      await setIAMPolicyStreamConfig({ state: policyEngineStatus as IAMPolicyEngineState });
      refetchPolicyConfig();
    };

    if (policyEngineStatus !== policyConfig?.state) {
      changeEngineState();
    }
  }, [policyEngineStatus]);

  useEffect(() => {
    refetchPolicyConfig();
  }, [isLocalStackRunning]);

  const isInsightsEnabled = policyEngineStatus && policyEngineStatus !== IAMPolicyEngineState.Disabled;

  return (
    <Layout
      title={
        <Box
          sx={{
            width: '100%',
            display: 'flex',
            alignItems: 'center',
            justifyItems: 'right',
            gap: '1rem',
          }}
        >
          <Typography variant="h4">IAM Policy Stream</Typography>
          <PlanChip withStyles={false} planFamily={FeatureMaturityLevel.PREVIEW} showTooltip />
          {isInsightsEnabled && (
            <ToggleButtonGroup
              value={toggleState}
              exclusive
              onChange={(_, value) => setToggleState(value || toggleState)}
              size="small"
            >
              <ToggleButton value={ToggleState.OPERATIONS}>
                <span>Operations</span>
              </ToggleButton>
              <ToggleButton value={ToggleState.POLICIES}>
                <span>Policies</span>
              </ToggleButton>
            </ToggleButtonGroup>
          )}
          {isInsightsEnabled && (
            <FormControlLabel
              control={
                <Switch
                  color="primary"
                  onChange={() =>
                    policyEngineStatus === IAMPolicyEngineState.Enforced
                      ? setPolicyEngineStatus(offPolicyEngineState)
                      : setPolicyEngineStatus(IAMPolicyEngineState.Enforced)
                  }
                  checked={policyEngineStatus === IAMPolicyEngineState.Enforced}
                />
              }
              label="Enforce IAM Policies"
            />
          )}
        </Box>
      }
    >
      <Grid container spacing={2}>
        {isLocalStackRunning && !hasMinVersion && (
          <Grid item xs={12}>
            <Alert severity="warning">This feature requires LocalStack v4.0 and above.</Alert>
          </Grid>
        )}
        <Grid item xs={12}>
          {!isInsightsEnabled && (
            <Card>
              <CardContent sx={{ padding: '50px' }}>
                <Box sx={{ display: 'flex', justifyContent: 'center', gap: '1rem', flexDirection: 'column' }}>
                  <Typography textAlign="center" variant="subtitle1">
                    Implement least-privilege permissions for AWS resources
                    <br />
                    with identity and resource based policies in IAM.
                  </Typography>
                  <Box sx={{ display: 'flex', gap: '0.5rem', justifyContent: 'center' }}>
                    <Button
                      disabled={!isLocalStackRunning || !hasMinVersion}
                      variant="contained"
                      color="primary"
                      onClick={() => setPolicyEngineStatus(IAMPolicyEngineState.EngineOnly)}
                    >
                      Enable Stream
                    </Button>
                    <Button variant="contained" href={ExternalLink.DOCS_SECURITY_TESTING} target="_blank">
                      Documentation
                    </Button>
                  </Box>
                </Box>
              </CardContent>
            </Card>
          )}
          {isInsightsEnabled && (
            <>
              <Box sx={{ display: toggleState === ToggleState.POLICIES ? 'block' : 'none' }}>
                <PolicySummary
                  clientOverrides={clientOverrides}
                  createSnackbarError={createSnackbarError}
                  enable={isLocalStackRunning && hasMinVersion}
                />
              </Box>
              <Box sx={{ display: toggleState === ToggleState.OPERATIONS ? 'block' : 'none' }}>
                <PolicyStream
                  clientOverrides={clientOverrides}
                  createSnackbarError={createSnackbarError}
                  enable={isLocalStackRunning && hasMinVersion}
                  policyEngineState={policyEngineStatus}
                />
              </Box>
            </>
          )}
        </Grid>
      </Grid>
    </Layout>
  );
};
