import { ReactElement } from 'react';
import { CreateSGMEndpointRequest } from '@localstack/types';
import { AwsClientOverrides, getSchema } from '@localstack/services';
import { DEFAULT_SGM_ROUTES } from '@localstack/constants';

import { RelatedResourcePicker } from '../../../form';

import { MagicForm } from '../../magic/MagicForm';

export type CreateSGMEndpointProps = {
  loading?: boolean;
  formId: string;
  clientOverrides?: AwsClientOverrides;
  onSubmit: (data: CreateSGMEndpointRequest) => unknown;
};

export const SGMEndpointCreate = ({
  loading,
  formId,
  clientOverrides,
  onSubmit,
}: CreateSGMEndpointProps): ReactElement => {
  const submitHandler = async (data: CreateSGMEndpointRequest) => {
    return onSubmit(data);
  };

  return (
    <>
      <MagicForm
        schema={getSchema('SGM')}
        formId={formId}
        entry="CreateEndpointInput"
        loading={loading}
        onSubmit={(data: CreateSGMEndpointRequest) => submitHandler(data)}
        externalFields={{
          '^EndpointConfigName$': (control, fieldName, required) => (
            <RelatedResourcePicker
              control={control}
              client="SGM"
              method="listEndpointConfigs"
              arrayKeyName="EndpointConfigs"
              property="EndpointConfigName"
              fieldName={fieldName}
              entityName="Endpoint Config"
              creationRoute={DEFAULT_SGM_ROUTES.RESOURCES_SGM_ENDPOINT_CONFIG_NEW}
              required={required}
              clientOverrides={clientOverrides}
            />
          ),
        }}
      />
    </>
  );
};
