import { ReactElement } from 'react';
import {
  AccountCircle as AccountIcon,
  ChevronLeft,
  Apartment,
  Groups,
  LocalPolice,
  CreditCard,
  Key,
  ViewWeek,
} from '@mui/icons-material';

import { PasskeyIcon } from '@localstack/ui/src/icons';

import { Permission, ProductFeature } from '@localstack/types';

import { buildRoute } from '@localstack/services';

import { AppRoute } from '~/config';
import { useAuthProvider } from '~/hooks/useAuthProvider';

import { NavbarContainer, NavbarSection, SectionItem, SectionHeader } from './shared';
import { NavbarCloseButton } from './NavbarCloseButton';

/** Contains menu items to be shown with the main actions of the app */
export const SettingsNavbar = (): ReactElement => {
  const { can, hasFeature } = useAuthProvider();

  // show billing if user is permitted for any of those actions
  const billingPermissions = [Permission.READ_CREDIT_CARDS, Permission.READ_INVOICES, Permission.UPDATE_ORGANIZATION];
  return (
    <NavbarContainer>
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <SectionItem label="Back to app" icon={ChevronLeft} to={AppRoute.DASHBOARD} />
        <NavbarCloseButton />
      </div>
      <NavbarSection data-name="account sub navigation">
        <SectionHeader>Account</SectionHeader>
        <SectionItem label="Profile" to={buildRoute(AppRoute.SETTINGS_PROFILE)} end icon={AccountIcon}></SectionItem>
        <SectionItem
          label="Authentication"
          to={buildRoute(AppRoute.SETTINGS_AUTHENTICATION)}
          icon={PasskeyIcon}
        ></SectionItem>
      </NavbarSection>

      <NavbarSection data-name="administration sub navigation">
        <SectionHeader>Administration</SectionHeader>

        {can(Permission.UPDATE_ORGANIZATION) && (
          <SectionItem
            label="Workspace"
            to={buildRoute(AppRoute.SETTINGS_WORKSPACE)}
            end
            icon={Apartment}
          ></SectionItem>
        )}

        {can(Permission.READ_MEMBERS) && can(Permission.READ_SUBSCRIPTIONS) && (
          <SectionItem label="Users & Licenses" to={buildRoute(AppRoute.SETTINGS_MEMBERS)} icon={Groups}></SectionItem>
        )}

        {hasFeature(ProductFeature.FEATURE_SSO) && can(Permission.UPDATE_ORGANIZATION) && (
          <SectionItem label="Single Sign-on" to={buildRoute(AppRoute.SETTINGS_SSO)} icon={LocalPolice}></SectionItem>
        )}

        <SectionItem label="Auth Tokens" to={buildRoute(AppRoute.SETTINGS_AUTH_TOKENS)} end icon={Key}></SectionItem>

        {can(Permission.READ_CI_KEYS) && (
          <SectionItem
            label="Legacy CI Keys"
            to={buildRoute(AppRoute.SETTINGS_LEGACY_CI_KEYS)}
            icon={Key}
          ></SectionItem>
        )}

        <SectionItem label="Legacy API Keys" to={buildRoute(AppRoute.SETTINGS_KEYS)} icon={Key}></SectionItem>

        {can(Permission.READ_SUBSCRIPTIONS) && (
          <SectionItem
            label="Subscriptions"
            to={buildRoute(AppRoute.SETTINGS_SUBSCRIPTIONS)}
            end
            icon={ViewWeek}
          ></SectionItem>
        )}

        {billingPermissions.some(can) && (
          <SectionItem label="Billing" to={buildRoute(AppRoute.SETTINGS_BILLING)} icon={CreditCard}></SectionItem>
        )}
      </NavbarSection>
    </NavbarContainer>
  );
};
