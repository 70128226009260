import React, { ReactElement } from 'react';
import { LamdaFunctionInvocationRequest } from '@localstack/types';
import { getSchema } from '@localstack/services';

import { MagicForm } from '../../../magic/MagicForm';
import { ControlledCodeEditor } from '../../../../form';

export interface LambdaFunctionInvokeFormProps {
  functionName: Optional<string>;
  loading?: boolean;
  formId?: string;
  onCreate: (data: LamdaFunctionInvocationRequest) => void;
}

// eslint-disable-next-line react/display-name
export const LambdaFunctionInvokeForm = React.memo(
  ({ functionName, loading, formId, onCreate }: LambdaFunctionInvokeFormProps): ReactElement => (
    <MagicForm
      data={{
        FunctionName: functionName,
        LogType: 'Tail',
      }}
      fieldConditions={{
        FunctionName: false,
        LogType: false,
        ClientContext: false,
        Qualifier: false,
        InvocationType: false,
      }}
      externalFields={{
        Payload: (control, name) => <ControlledCodeEditor control={control} name={name} language="json" />,
      }}
      schema={getSchema('Lambda')}
      loading={loading}
      entry="InvocationRequest"
      formId={formId}
      onSubmit={(data: LamdaFunctionInvocationRequest) => onCreate(data)}
    />
  ),
);
