import { ReactElement } from 'react';

import { ContainedCustomerLayout } from '~/layouts';

import { Profile } from './components';

export const Account = (): ReactElement => (
  <ContainedCustomerLayout title="Profile">
    <Profile />
  </ContainedCustomerLayout>
);
