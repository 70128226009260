import { ReactNode, ReactElement, createContext, useContext, useEffect, useState } from 'react';
import { HubspotWindowInterface, User } from '@localstack/types';
import {
  UserService,
  generateOffsetTimestamp,
  removeHubspotCookies,
  tokenIsNotExpired,
  useApiEffect,
  useSnackbar,
} from '@localstack/services';
import { STORAGE_KEY_HUBSPOT_TOKEN } from '@localstack/constants';
import { useLocation } from 'react-router-dom';

import { HUBSPOT_TRACKING_CODE } from '~/config/config';
import { useAuthProvider } from '~/hooks/useAuthProvider';

interface Props {
  children: ReactNode;
}

declare const window: HubspotWindowInterface;

type HubspotContextInterface = {
  cleanupHubspotUser: () => void;
  openHubspotWidget: () => void;
};

const HubspotContext = createContext<HubspotContextInterface>({
  cleanupHubspotUser: () => undefined,
  openHubspotWidget: () => undefined,
});

const initialTokenDataState = {
  token: undefined,
  email: undefined,
  expiresAt: undefined,
};

const defaultHsConversationsSettings = {
  loadImmediately: false,
};

export const HubspotProvider = ({ children }: Props): ReactElement => {
  const location = useLocation();

  useEffect(() => {
    // refresh widget on page change
    // this is needed on SPAs so HubSpot can hide/show widget based on the route
    refreshWidget();
  }, [location]);

  const { userInfo } = useAuthProvider();
  const [conversationsAPIReady, setConversationsAPIReady] = useState(false);
  const [tokenData, setTokenData] = useState(initialTokenDataState);
  const { getHubspotToken } = useApiEffect(UserService, ['getHubspotToken']);
  const { showSnackbar } = useSnackbar();

  const onConversationsAPIReady = () => {
    setConversationsAPIReady(true);
    // For logged in users, we've to wait till the visitor is identified through token
    // Loading for logged out users.
    // if (!userInfo) window.HubSpotConversations.widget.load();
    // Uncomment above line if we want to load widget for logged out users
  };

  const isWidgetLoaded = () => window.HubSpotConversations?.widget.status().loaded;

  const openWidget = () => {
    if (!isWidgetLoaded()) {
      return showSnackbar({
        message: "Support widget hasn't been initialized. Please try refreshing the page.",
        severity: 'error',
      });
    }
    return window.HubSpotConversations.widget.open();
  };

  const refreshWidget = () => window.HubSpotConversations?.widget?.refresh();

  const cleanupHubspotUser = () => {
    if (!isWidgetLoaded()) return;
    localStorage.removeItem(STORAGE_KEY_HUBSPOT_TOKEN);
    window.HubSpotConversations?.clear({ resetWidget: true });
    window.HubSpotConversations?.widget.remove();
    window.hsConversationsSettings = defaultHsConversationsSettings;

    window.__hsUserToken = '';
    removeHubspotCookies();
  };

  const authenticateUser = async (user: User) => {
    let hubspotTokenData = JSON.parse(localStorage.getItem(STORAGE_KEY_HUBSPOT_TOKEN) || '{}');
    const tokenIsValid = hubspotTokenData.token && tokenIsNotExpired(hubspotTokenData.expiresAt);

    if (!tokenIsValid) {
      // Direct `token` destructing may fail for some reason. Presumably in case of authentication or the underlying
      // server error. We should actually have axios interceptors to refresh auth details and retry calls in such a
      // case, but :shrug:
      // TODO: we can move this logic to the server side on getUser?
      const result = await getHubspotToken();

      if (!result?.token) {
        return;
      }

      hubspotTokenData = {
        token: result.token,
        expiresAt: generateOffsetTimestamp(12),
      };
      localStorage.setItem(STORAGE_KEY_HUBSPOT_TOKEN, JSON.stringify(hubspotTokenData));
    }

    setTokenData({
      ...hubspotTokenData,
      email: user.email,
    });

    if (window.HubSpotConversations) onConversationsAPIReady();
  };
  useEffect(() => {
    if (document.getElementById('hubspot-script')) return;
    window.hsConversationsSettings = defaultHsConversationsSettings;
    const script = document.createElement('script');
    script.id = 'hubspot-script';
    script.async = true;
    script.defer = true;
    script.src = `https://js-eu1.hs-scripts.com/${HUBSPOT_TRACKING_CODE}.js`;
    window.hsConversationsOnReady = [onConversationsAPIReady];

    document.head.appendChild(script);
  }, []);

  useEffect(() => {
    if (userInfo) authenticateUser(userInfo.user);
  }, [userInfo]);

  useEffect(() => {
    if (conversationsAPIReady && tokenData.token) {
      window.hsConversationsSettings = {
        loadImmediately: false,
        identificationEmail: tokenData.email,
        identificationToken: tokenData.token,
      };
      window.HubSpotConversations.widget.load();
    }
  }, [tokenData, conversationsAPIReady]);

  return (
    <HubspotContext.Provider
      value={{
        cleanupHubspotUser,
        openHubspotWidget: openWidget,
      }}
    >
      {children}
    </HubspotContext.Provider>
  );
};

export const useHubspotProvider = (): HubspotContextInterface => useContext(HubspotContext);
