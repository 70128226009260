import { useMemo, ReactElement } from 'react';
import { Link } from '@mui/material';
import { CIRun } from '@localstack/types';
import { formatDate } from '@localstack/services';

import { DataGrid, GridColDef } from '../../../display/DataGrid';

export type RunsTableProps = {
  /** project name */
  projectName: string;
  /** project run details */
  runs: CIRun[];
  /** fired whenever user clicks on run link */
  onViewRun?: (projectName: string, runName: string) => void;
};

const buildColumns = (projectName: string, onViewRun: RunsTableProps['onViewRun']): GridColDef[] => [
  {
    field: 'sid',
    headerName: 'ID',
    width: 300,
    renderCell: (params) => (
      <Link onClick={() => onViewRun && onViewRun(projectName, params.row.run_name)} underline="hover">
        {params.value}
      </Link>
    ),
  },
  {
    field: 'run_name',
    headerName: 'Name',
    width: 300,
  },
  {
    field: 'last_updated',
    headerName: 'Last updated',
    width: 300,
    renderCell: (props) => formatDate(props.value || props.row.start_time),
  },
];

export const RunsTable = ({ projectName, runs, onViewRun }: RunsTableProps): ReactElement => {
  // `order by run.start_time desc` by default (keep runs immutable)
  const presortedRuns = useMemo(() => [...runs].sort((r1, r2) => r2.start_time - r1.start_time), [runs]);

  return (
    <DataGrid
      autoHeight
      rows={presortedRuns}
      columns={buildColumns(projectName, onViewRun)}
      getRowId={(row) => ((row as CIRun).stack_id ?? (row as CIRun).start_time) as string}
      disableSelectionOnClick
    />
  );
};
