import { ReactElement, ReactNode } from 'react';
import { Alert, Box, Button } from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';

interface ImpersonationWrapperProps {
  children: ReactNode;
  onStop: () => unknown;
}

const useStyles = makeStyles((theme) =>
  createStyles({
    wrapper: {
      backgroundColor: theme.palette.error.light,
      borderWidth: theme.spacing(2),
      borderColor: theme.palette.error.main,
      borderStyle: 'solid',
    },
    alert: {
      borderRadius: 0,
    },
  }),
);

export const ImpersonationWrapper = ({ onStop, children }: ImpersonationWrapperProps): ReactElement => {
  const classes = useStyles();

  return (
    <Box width="100%" height="100%" className={classes.wrapper}>
      <Alert
        severity="error"
        variant="filled"
        className={classes.alert}
        action={
          <Button onClick={onStop} variant="outlined" size="small" color="inherit">
            Stop
          </Button>
        }
      >
        You are currently in impersonation mode!
      </Alert>
      {children}
    </Box>
  );
};
