import { useState, ReactElement } from 'react';
import { Alert, AlertTitle, Button, Card, CardHeader, CardContent, Box } from '@mui/material';
import { useApiEffect, useApiGetter, UserService } from '@localstack/services';
import { CreditCardsList, LoadingFragment } from '@localstack/ui';
import { Add as AddIcon } from '@mui/icons-material';

import { Link } from 'react-router-dom';

import { StripeCardForm } from '~/components';
import { AppRoute } from '~/config';

type Props = {
  className?: string;
  hideAddButton?: boolean;
};

const PaymentMethod = ({ className, hideAddButton }: Props): ReactElement => {
  const [stripeLoading, setStripeLoading] = useState(false);
  const [showCardForm, setShowCardForm] = useState(false);

  const { data: cards, isLoading: areCardsRetrieving } = useApiGetter(UserService, 'listCreditCards', [], {
    defaultValue: [],
  });

  const {
    attachCreditCard,
    removeCreditCard,
    isLoading: isCardMutating,
  } = useApiEffect(UserService, ['attachCreditCard', 'removeCreditCard'], { revalidate: ['listCreditCards'] });

  const areCardsLoading = areCardsRetrieving || isCardMutating;

  return (
    <Card className={className || ''}>
      <CardHeader
        title="Payment Method"
        action={
          !hideAddButton && (
            <Button
              color="primary"
              size="small"
              onClick={() => setShowCardForm(!showCardForm)}
              startIcon={showCardForm ? null : <AddIcon />}
            >
              {showCardForm ? 'Cancel' : 'Add Card'}
            </Button>
          )
        }
      />

      <CardContent>
        {!areCardsLoading && cards?.length === 0 && !showCardForm && (
          <Alert severity="info" variant="outlined">
            There are no stored payment methods
          </Alert>
        )}
        <LoadingFragment loading={areCardsLoading} variant="list" height={65} size={3}>
          {!showCardForm && (
            <CreditCardsList
              cards={cards || []}
              onDelete={(card) => {
                removeCreditCard(card.id, card.currency as string);
              }}
            />
          )}
          {showCardForm && (
            <StripeCardForm
              showCurrencySelection
              defaultCurrency="usd"
              loading={stripeLoading || isCardMutating}
              onSaveCard={async (token, currency) => {
                if (!currency) {
                  return;
                }

                try {
                  setStripeLoading(true);
                  await attachCreditCard({ token, currency });
                  setShowCardForm(false);
                } finally {
                  setStripeLoading(false);
                }
              }}
            />
          )}
        </LoadingFragment>

        {hideAddButton && (
          <Box mt={2}>
            <Alert severity="info">
              <AlertTitle>Adding or updating a payment method</AlertTitle>
              To add a or update a payment method for an existing subscription select a subscription from the{' '}
              <Link to={AppRoute.SETTINGS_SUBSCRIPTIONS}>
                <b>Subscriptions</b>
              </Link>{' '}
              overview and click <b>update</b>.
            </Alert>
          </Box>
        )}
      </CardContent>
    </Card>
  );
};

export default PaymentMethod;
