import { ReactElement, useMemo } from 'react';
import { renderToString } from 'react-dom/server';
import { SERVICE_CODES } from '@localstack/constants';
import { Box, IconButton, Typography, useTheme } from '@mui/material';
import { ZoomOut as ZoomOutIcon } from '@mui/icons-material';
import { StackAwsApiCall } from '@localstack/types';

import {
  BarChart,
  CartesianGrid,
  XAxis,
  YAxis,
  Tooltip,
  Bar,
  Legend,
  ResponsiveContainer,
  ReferenceArea,
} from 'recharts';

import { AwsServiceIcon } from '../../../display/AwsServiceIcon';

import { useChartProps, useZoomableChart } from '../../../chart';

export interface ServicesChartProps {
  title: string;
  data: StackAwsApiCall[];
  showZoomOut?: boolean;
  onResetInterval?: () => unknown;
  onChangeInterval?: (start: string, end: string) => unknown;
}

export const ServicesChart = ({
  title,
  data,
  showZoomOut,
  onResetInterval,
  onChangeInterval,
}: ServicesChartProps): ReactElement => {
  const theme = useTheme();
  const chartProps = useChartProps();

  const zommableChart = useZoomableChart({ type: 'datestring', onChangeInterval });

  const serviceTypes = Array.from(new Set(data.map((e) => e.service)));

  // aggregate number of the same services across the same datapoints
  const aggregatedData = data.reduce(
    (memo, item) => ({
      ...memo,
      [item.server_time]: {
        ...memo[item.server_time as keyof typeof memo],
        [item.service]: (memo[item.server_time as keyof typeof memo]?.[item.service] ?? 0) + 1,
      },
    }),
    {} as Record<string, Record<string, number>>,
  );

  const formattedData = Object.entries(aggregatedData).map(([time, services]) => ({ time, ...services }));

  // fill missing entries with 0s
  const completeData = formattedData.map((rec) => ({
    ...serviceTypes.reduce((memo, serviceType) => ({ ...memo, [serviceType]: 0 }), {}),
    ...rec,
  }));

  /**
   * Do not repeat this at home! Here we are extracting aws icon color.
   * This leads to some warnings, but at least it looks noice!
   */
  const extractAwsIconColor = (code: string) =>
    renderToString(<AwsServiceIcon code={code} />).match(/.*stop-color="([^"]+)"/)?.[1] ?? theme.palette.primary.main;

  const serviceColors: Record<string, string> = useMemo(
    () => serviceTypes.reduce((memo, service) => ({ ...memo, [service]: extractAwsIconColor(service) }), {}),
    [data?.length],
  );

  return (
    <>
      <Typography align="center">{title}</Typography>
      {showZoomOut && (
        <Box position="relative">
          {/* FIXME: remove hardcoded zIndex and use theme or styles  */}
          <Box position="absolute" right={0} zIndex={123}>
            <IconButton onClick={onResetInterval} size="large">
              <ZoomOutIcon />
            </IconButton>
          </Box>
        </Box>
      )}
      <ResponsiveContainer width="100%" height={250}>
        <BarChart data={completeData} syncId="stack-charts" {...zommableChart.props}>
          <CartesianGrid strokeDasharray="3 3" {...chartProps.cartesianGridProps} />
          <XAxis dataKey="time" minTickGap={20} {...chartProps.xAxisProps} />
          <YAxis {...chartProps.yAxisProps} />
          <Legend
            content={() => (
              <Box display="flex" justifyContent="center">
                {serviceTypes.map((serviceType) => (
                  <AwsServiceIcon
                    key={serviceType}
                    code={serviceType}
                    title={SERVICE_CODES[serviceType as keyof typeof SERVICE_CODES]}
                    size="medium"
                  />
                ))}
              </Box>
            )}
          />
          <Tooltip {...chartProps.tooltipProps} />
          {serviceTypes.map((serviceType) => (
            <Bar
              key={serviceType}
              dataKey={serviceType}
              stroke={serviceColors[serviceType]}
              fill={serviceColors[serviceType]}
              name={SERVICE_CODES[serviceType as keyof typeof SERVICE_CODES]}
            />
          ))}
          {zommableChart.leftSelection && (
            <ReferenceArea x1={zommableChart.leftSelection} x2={zommableChart.rightSelection} />
          )}
        </BarChart>
      </ResponsiveContainer>
    </>
  );
};
