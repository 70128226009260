/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Feedback } from '@localstack/types';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class MarketingService {
  /**
   * @param requestBody
   * @returns any Unsubscribed
   * @throws ApiError
   */
  public static unsubscribeNewsletter(requestBody?: { key: string }): CancelablePromise<any> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/v1/user/unsubscribe',
      body: requestBody,
      mediaType: 'application/json',
    });
  }

  /**
   * @returns Feedback
   * @throws ApiError
   */
  public static getFeedbacks(): CancelablePromise<Array<Feedback>> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/v1/feedbacks',
    });
  }

  /**
   * @param requestBody
   * @returns any Success
   * @throws ApiError
   */
  public static storeFeedback(requestBody?: { survey_id: string; responses: Feedback }): CancelablePromise<any> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/v1/feedbacks',
      body: requestBody,
      mediaType: 'application/json',
    });
  }
}
