import { ReactElement, useState, useCallback } from 'react';
import { useRoutes, useAwsGetter, useAwsEffect } from '@localstack/services';
import { Card, Button, Theme, TextField, Autocomplete } from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import { DEFAULT_APPLICATION_AUTO_SCALING_ROUTES } from '@localstack/constants';

import { PageTitle, Dropdown, ConfirmableButton, ScalableTargetsTable } from '@localstack/ui';

import { SERVICES } from './constants';
import { ApplicationAutoscalingProps } from './types';
import { NavTabs } from './components/NavTabs';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    servicesDropdown: {
      width: '10em',
      '& div': {
        '& div': {
          paddingRight: '0 !important',
        },
      },
      '& input': {
        textTransform: 'capitalize',
      },
    },
    dropdown: {
      flexGrow: 1,
      display: 'flex',
      zIndex: 1,
      '& > *': {
        marginLeft: theme.spacing(2),
        marginBottom: theme.spacing(1),
      },
      justifyContent: 'right',
    },
  }),
);

export const ScalableTargets = ({
  Layout,
  clientOverrides,
  routes = DEFAULT_APPLICATION_AUTO_SCALING_ROUTES,
}: ApplicationAutoscalingProps): ReactElement => {
  const classes = useStyles();
  const [selectedIds, setSelectedIds] = useState<string[]>([]);
  const [selectedService, setSelectedService] = useState<string>('lambda');
  const { goto } = useRoutes();
  const {
    data: scalableTargets,
    isLoading,
    mutate,
  } = useAwsGetter('ApplicationAutoScaling', 'describeScalableTargets', [{ ServiceNamespace: selectedService }], {
    clientOverrides,
  });

  const { deregisterScalableTarget } = useAwsEffect('ApplicationAutoScaling', ['deregisterScalableTarget'], {
    revalidate: ['describeScalableTargets'],
    clientOverrides,
  });

  const handleDeleteSelected = useCallback(async () => {
    const promises = selectedIds.map((ResourceId) => {
      const target = scalableTargets?.ScalableTargets?.find((list) => list.ResourceId === ResourceId);
      return deregisterScalableTarget({
        ServiceNamespace: target?.ServiceNamespace || '',
        ResourceId,
        ScalableDimension: target?.ScalableDimension || '',
      });
    });
    await Promise.all(promises);
  }, [selectedIds]);

  return (
    <Layout
      title={<PageTitle title="Application Autoscaling" onMutate={mutate} />}
      tabs={<NavTabs routes={routes} />}
      actions={
        <>
          <Button onClick={() => goto(routes.RESOURCES_APPLICATION_AUTO_SCALING_NEW)}>Create Scalable Target</Button>
          <Dropdown label="Actions">
            <ConfirmableButton
              componentType="MenuItem"
              disabled={selectedIds.length === 0 || isLoading}
              title={`Delete ${selectedIds.length} Scalable Target(s)?`}
              onClick={handleDeleteSelected}
              text="Selected Scalable Targets will be permanently deleted"
            >
              Remove Selected
            </ConfirmableButton>
          </Dropdown>
        </>
      }
    >
      <div className={classes.dropdown}>
        <Autocomplete
          size="small"
          clearIcon
          options={SERVICES}
          getOptionLabel={(r) => r}
          className={classes.servicesDropdown}
          renderInput={(params) => <TextField {...params} label="Service Namespace" variant="outlined" />}
          value={selectedService}
          onChange={(_e, r) => r && setSelectedService(r)}
        />
      </div>
      <Card>
        <ScalableTargetsTable
          scalableTargets={scalableTargets?.ScalableTargets || []}
          loading={isLoading}
          onSelect={setSelectedIds}
        />
      </Card>
    </Layout>
  );
};
