import { ReactElement, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { CircularProgress } from '@mui/material';
import { MarketingService, useSnackbar, useApiEffect } from '@localstack/services';

import { AppRoute } from '~/config';

import { BaseActivationOld } from './components/BaseActivationOld';

const CONFIRMED_MESSAGE = 'You have been successfully unsubscribed from the LocalStack newsletter';
const ERROR_MESSAGE = 'Unable to unsubscribe from the newsletter, Please make sure that the link is correct';

interface Params {
  key: string;
}

export const UnsubscribeActivation = (): ReactElement => {
  const navigate = useNavigate();
  const { showSnackbar } = useSnackbar();
  const { key } = useParams<'key'>() as Params;

  const { unsubscribeNewsletter } = useApiEffect(MarketingService, ['unsubscribeNewsletter']);

  useEffect(() => {
    const unsubscribe = async () => {
      try {
        await unsubscribeNewsletter({ key });
        showSnackbar({ severity: 'success', message: CONFIRMED_MESSAGE });
        navigate(AppRoute.SIGN_IN);
      } catch (_e) {
        showSnackbar({ severity: 'error', message: ERROR_MESSAGE });
      }
    };
    unsubscribe();
  }, [key]);

  return <BaseActivationOld message={<CircularProgress />} activated />;
};

export default UnsubscribeActivation;
