import { ReactElement, useEffect, useRef, useState } from 'react';
import { ControlledTextField, LogoIcon, ProgressButton } from '@localstack/ui';
import { Alert, Box, Button, IconButton, InputAdornment, Link, Tooltip, Typography } from '@mui/material';
import {
  BASE_REQUIRED_ERROR,
  formatError,
  formatErrorCode,
  OrganizationsService,
  useApiEffect,
  useRoutes,
  UserService,
  useSnackbar,
  VALIDATION_RULES,
  storeAuthToken,
} from '@localstack/services';
import { useForm } from 'react-hook-form';
import {
  Visibility as VisibilityIcon,
  VisibilityOff as VisibilityOffIcon,
  Cancel as CancelIcon,
} from '@mui/icons-material';
import { IdentityProvider } from '@localstack/types';
import { ExternalLink, PUBLIC_IDENTITY_PROVIDERS } from '@localstack/constants';

import { AppRoute } from '~/config';
import { getPreSignInPageURL, updatePreSignInPageURL } from '~/util/storage';
import { useHubspotProvider } from '~/contexts/HubspotContext';
import { useAuthProvider } from '~/hooks/useAuthProvider';
import { useSSOStart } from '~/hooks/useSSOStart';

import { AuthLayout } from './AuthLayout';
import { useStyles, usePasswordFieldVisibility } from './common';

const LoginType = {
  email: 'email',
  sso: 'sso',
} as const;

type LoginTypeValues = (typeof LoginType)[keyof typeof LoginType];
interface FormData {
  username: string;
  password: string;
}

const SSOErrorMessage = ({ error }: { error: string }) => (
  <Typography fontSize={13} fontWeight={500} color="error">
    {error}
  </Typography>
);

export const SignIn = (): ReactElement => {
  const classes = useStyles();
  const { goto } = useRoutes();
  const { userInfo, reloadUserInfo } = useAuthProvider();
  const { cleanupHubspotUser } = useHubspotProvider();
  const { showSnackbar } = useSnackbar();
  const [ssoError, setSsoError] = useState<Optional<string>>(undefined);
  const { can } = useAuthProvider();

  const { isIdpLoading, initSSOFlow } = useSSOStart({
    onError: (err) => setSsoError(err.message),
  });

  const {
    control,
    watch,
    handleSubmit,
    formState: { errors: formErrors },
    reset,
  } = useForm<FormData>({ mode: 'all' });

  const { listIdentityProvidersForEmail, isLoading: isLoadingIdps } = useApiEffect(OrganizationsService, [
    'listIdentityProvidersForEmail',
  ]);
  const {
    signinUser,
    isLoading: isSigningInUser,
    error: signInError,
  } = useApiEffect(UserService, ['signinUser'], { revalidate: ['getUser'], suppressErrors: true });
  const { resendActivation, isLoading: isResendingActivation } = useApiEffect(UserService, ['resendActivation'], {
    revalidate: ['getUser'],
  });

  const [maskPassword, setMaskPassword] = useState<boolean>(false);
  const [providers, setProviders] = useState<IdentityProvider[]>([]);
  const [loginType, setLoginType] = useState<LoginTypeValues>('email');
  const passwordFieldRef = useRef<HTMLInputElement>(null);
  const isEmailFlow = loginType === LoginType.email;
  const isSSOFlow = loginType === LoginType.sso;
  const email = watch('username');
  const usernameFieldErrors = formErrors.username;
  const { showPasswordField, setShowPasswordField } = usePasswordFieldVisibility(usernameFieldErrors, passwordFieldRef);
  const isAuthenticated = can('authenticated');
  const requireAccountCreationFinish = userInfo?.user.signup_survey?.require_finish_account_creation;

  const handleClickMaskPassword = () => setMaskPassword((show) => !show);

  const handleResendActivationEmail = async () => {
    await resendActivation({ email });

    showSnackbar({
      message:
        'The activation email is being resent if an account with the email ' +
        `address "${email}" exists. Please check your inbox and spam folder.`,
      severity: 'success',
    });
  };

  useEffect(() => {
    if (!isAuthenticated) {
      return;
    }
    const preSignInUrl = getPreSignInPageURL();
    if (requireAccountCreationFinish) {
      goto(AppRoute.SIGN_UP, { refresh: true }, 'isFinishAccountCreation=true');
      return;
    } else if (preSignInUrl) {
      updatePreSignInPageURL(null);
      goto(preSignInUrl);
      return;
    }
    goto(AppRoute.DASHBOARD);
  }, [isAuthenticated, goto]);

  const onSubmit = async (data: FormData) => {
    if (isSSOFlow) {
      setSsoError(undefined);
      const provider = await listIdentityProvidersForEmail(data.username);
      if (!provider.length) {
        return showSnackbar({
          message: 'No providers found with this email address',
          severity: 'error',
        });
      }
      return setProviders(provider);
    }
    if (!showPasswordField) return setShowPasswordField(true);
    const result = await signinUser(data);

    // FIXME: error from signIn gets somehow suppressed and we get result=undefined
    if (result?.token) {
      cleanupHubspotUser();
      storeAuthToken(result.token);

      await reloadUserInfo();
    }
  };

  const handleEmailCancelHandler = () => {
    reset();
    setShowPasswordField(false);
  };

  useEffect(() => {
    if (loginType === LoginType.sso) {
      setShowPasswordField(false);
    }
    reset();
    setSsoError(undefined);
  }, [loginType]);

  return (
    <AuthLayout documentTitle="Login">
      <Box textAlign="center">
        <Box mb={4}>
          <LogoIcon />
        </Box>
        <Typography variant="h3" component="h2" fontWeight={600}>
          Login to LocalStack
        </Typography>
        <Typography fontSize={18} className={classes.grey600Text} fontWeight={500} mt={0.5}>
          Run locally. Deploy globally.
        </Typography>
        {signInError && (
          <Box mt={2}>
            <Alert
              sx={{ textAlign: 'left' }}
              severity="error"
              variant="outlined"
              action={
                formatErrorCode(signInError) === 'auth.not_activated' && (
                  <ProgressButton size="small" onClick={handleResendActivationEmail} loading={isResendingActivation}>
                    Resend
                  </ProgressButton>
                )
              }
            >
              {formatError(signInError)}
            </Alert>
          </Box>
        )}
        <form onSubmit={handleSubmit(onSubmit)} id="signin">
          {isEmailFlow && (
            <Box display="flex" flexDirection="column" rowGap={1} mt={3.5}>
              {PUBLIC_IDENTITY_PROVIDERS.map(({ orgId, idpName, label }) => (
                <ProgressButton
                  key={idpName}
                  variant="outlined"
                  color="tertiary"
                  fullWidth
                  size="xlarge"
                  data-sso-button={`${orgId}/${idpName}`}
                  sx={{ textTransform: 'none' }}
                  disabled={isIdpLoading}
                  loading={isIdpLoading}
                  onClick={() => {
                    setSsoError(undefined);
                    initSSOFlow({
                      orgId: orgId,
                      idpName: idpName,
                    });
                  }}
                >
                  Continue with {label}
                </ProgressButton>
              ))}
              <Button
                type="button"
                variant="outlined"
                color="tertiary"
                fullWidth
                size="xlarge"
                sx={{ textTransform: 'none' }}
                onClick={() => setLoginType(LoginType.sso)}
              >
                Continue with SSO
              </Button>
            </Box>
          )}
          {ssoError && loginType === LoginType.email && (
            <Box mt={1}>
              <SSOErrorMessage error={ssoError} />
            </Box>
          )}
          <Box mt={4} display="flex" flexDirection="column" rowGap={1}>
            <ControlledTextField
              control={control}
              showErrorsOnlyIfTouched={false}
              name="username"
              fullWidth
              placeholder="Enter your email address"
              type="text"
              variant="outlined"
              rules={{
                ...VALIDATION_RULES.email,
                ...VALIDATION_RULES.required,
              }}
              InputProps={{
                endAdornment: email ? (
                  <InputAdornment position="end">
                    <IconButton onClick={handleEmailCancelHandler} size="small" aria-label="Clear email address">
                      <CancelIcon fontSize="small" />
                    </IconButton>
                  </InputAdornment>
                ) : undefined,
              }}
            />
            {/* hack to avoid the prefill issues with react-hook-form */}
            <Box display={showPasswordField ? 'flex' : 'none'} flexDirection="column" rowGap={1} alignItems="center">
              <ControlledTextField
                control={control}
                showErrorsOnlyIfTouched={false}
                name="password"
                ref={passwordFieldRef}
                fullWidth
                placeholder="Enter your password"
                type={maskPassword ? 'text' : 'password'}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <Tooltip title={`${maskPassword ? 'Hide' : 'Show'} Password`}>
                        <IconButton onClick={handleClickMaskPassword} size="small">
                          {maskPassword ? <VisibilityOffIcon fontSize="small" /> : <VisibilityIcon fontSize="small" />}
                        </IconButton>
                      </Tooltip>
                    </InputAdornment>
                  ),
                }}
                variant="outlined"
                rules={{
                  validate: (value) => {
                    if (showPasswordField && !value) return BASE_REQUIRED_ERROR;
                    return true;
                  },
                }}
              />
              <Link
                underline="hover"
                fontSize={13}
                fontWeight={500}
                className={classes.grey800Text}
                display="inline-flex"
                alignItems="center"
                columnGap={0.5}
                onClick={() => goto(AppRoute.RECOVER)}
                mb={1}
              >
                <span>Forgot Password?</span>
              </Link>
            </Box>
            <ProgressButton
              variant="contained"
              color="primary"
              fullWidth
              size="xlarge"
              type="submit"
              loading={isLoadingIdps || isSigningInUser}
            >
              Continue
            </ProgressButton>
          </Box>
          {providers.length ? (
            <Box display="flex" flexDirection="column" rowGap={1} mt={3.5}>
              {providers.map(({ org_id, idp_name }) => {
                const providerName = `${org_id}/${idp_name}`;
                return (
                  <ProgressButton
                    key={providerName}
                    data-sso-button={providerName}
                    variant="outlined"
                    fullWidth
                    size="xlarge"
                    disabled={isIdpLoading}
                    loading={isIdpLoading}
                    onClick={() =>
                      initSSOFlow({
                        orgId: org_id,
                        idpName: idp_name,
                      })
                    }
                  >
                    Continue with {idp_name}
                  </ProgressButton>
                );
              })}
            </Box>
          ) : null}
          {ssoError && loginType === LoginType.sso && (
            <Box mt={1}>
              <SSOErrorMessage error={ssoError} />
            </Box>
          )}
        </form>
        <Box mt={4} px={2.5}>
          <Typography fontSize={13} className={classes.grey600Text} fontWeight={500}>
            By logging in, you agree to our{' '}
            <Link underline="hover" target="_blank" className={classes.grey800Text} href={ExternalLink.LEGAL_TOS}>
              Terms
            </Link>
            ,{' '}
            <Link
              underline="hover"
              target="_blank"
              className={classes.grey800Text}
              href={ExternalLink.LEGAL_PRIVACY_POLICY}
            >
              Privacy Policy
            </Link>
            , and{' '}
            <Link underline="hover" target="_blank" className={classes.grey800Text} href={ExternalLink.LEGAL_DPA}>
              DPA
            </Link>
            .
          </Typography>
        </Box>
        <Box mt={4}>
          <Typography className={classes.grey600Text}>—</Typography>
        </Box>
        <Box mt={4}>
          {isEmailFlow && (
            <Typography fontSize={13} className={classes.grey600Text} fontWeight={500}>
              New to LocalStack?{' '}
              <Link
                underline="hover"
                className={classes.grey800Text}
                display="inline-flex"
                alignItems="center"
                columnGap={0.5}
                onClick={() => goto(AppRoute.SIGN_UP)}
              >
                Sign up
              </Link>
            </Typography>
          )}
          {isSSOFlow && (
            <Typography fontSize={13} fontWeight={500}>
              <Link
                underline="hover"
                className={classes.grey800Text}
                display="inline-flex"
                alignItems="center"
                columnGap={0.5}
                onClick={() => {
                  setProviders([]);
                  setLoginType(LoginType.email);
                }}
              >
                Back to login
              </Link>
            </Typography>
          )}
        </Box>
      </Box>
    </AuthLayout>
  );
};
