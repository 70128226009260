import { ReactElement, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { LogoIcon } from '@localstack/ui';
import { Box, Link, Typography } from '@mui/material';
import { PUBLIC_IDENTITY_PROVIDERS } from '@localstack/constants';
import { useRoutes } from '@localstack/services';
import { SSOParams, SSOError } from '@localstack/types';

import { useSSOStart } from '~/hooks/useSSOStart';
import { AppRoute } from '~/config';

import { AuthLayout } from '../Account/AuthLayout';
import { useStyles } from '../Account/common';

export const SSOStart = (): ReactElement => {
  const classes = useStyles();
  const { goto } = useRoutes();
  const [error, setError] = useState<Optional<SSOError>>(undefined);
  const { orgId, idpName } = useParams<SSOParams>() as SSOParams;

  const publicIdpProviderName = PUBLIC_IDENTITY_PROVIDERS.find((p) => p.idpName === idpName)?.label;
  const idpNameCaptilised = publicIdpProviderName ?? idpName;

  const ssoParams = { orgId, idpName };

  const { isIdpLoading: isLoading, initSSOFlow } = useSSOStart({
    onSuccess: (userInfo): void => {
      const requireAccountCreationFinish = userInfo?.user.signup_survey?.require_finish_account_creation;
      if (requireAccountCreationFinish) {
        goto(AppRoute.SIGN_UP, undefined, 'isFinishAccountCreation=true');
      } else {
        goto(AppRoute.DASHBOARD);
      }
    },
    onError: (err) => setError(err),
  });

  useEffect(() => {
    initSSOFlow(ssoParams);
  }, []);

  const isIdpError = error?.type === 'idp-error';

  return (
    <AuthLayout documentTitle="SSO Login">
      <Box textAlign="center">
        <Box mb={4}>
          <LogoIcon />
        </Box>
        <Typography variant="h3" component="h2" fontWeight={600}>
          Continue with {idpNameCaptilised}
        </Typography>
        <Box mt={4}>
          {!isIdpError ? (
            <Typography fontSize={13} fontWeight={500} className={classes.grey600Text}>
              {isLoading ? (
                'Loading Identity provider...'
              ) : (
                <>
                  Redirecting to the identity provider&apos;s login page... <br />
                  If not redirected, make sure pop-ups are not blocked and{' '}
                  <Link underline="hover" className={classes.grey800Text} onClick={() => window.location.reload()}>
                    refresh the page
                  </Link>
                  .
                  <Box component="span" mt={3} display="block">
                    Still not working?{' '}
                    <Link underline="hover" className={classes.grey800Text} onClick={() => initSSOFlow(ssoParams)}>
                      Open window manually
                    </Link>
                    .
                  </Box>
                </>
              )}
            </Typography>
          ) : (
            <>
              <Typography fontSize={13} fontWeight={500} className={classes.grey600Text}>
                There was an error loading the identity provider.
              </Typography>
              <Typography fontSize={13} fontWeight={500} color="error">
                {error.message}
              </Typography>
            </>
          )}
        </Box>
        {isIdpError && (
          <Typography fontSize={13} fontWeight={500} mt={4}>
            <Link
              underline="hover"
              className={classes.grey800Text}
              display="inline-flex"
              alignItems="center"
              columnGap={0.5}
              onClick={() => {
                goto(AppRoute.SIGN_IN);
              }}
            >
              Back to login
            </Link>
          </Typography>
        )}
      </Box>
    </AuthLayout>
  );
};
