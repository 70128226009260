import { useState, useCallback, FormEvent, ReactElement } from 'react';
import { useParams } from 'react-router-dom';
import { CodeEditor, LoadingFragment, ProgressButton, TimestreamQueryGrid } from '@localstack/ui';
import { QueryResponse, TSRecords, WriteRecordsResponse } from '@localstack/types';

import { Switch, Breadcrumbs, Link, Typography, Card } from '@mui/material';

import { useSnackbar, useRoutes, useAwsEffect } from '@localstack/services';

import { DEFAULT_TIMESTREAMDB_ROUTES } from '@localstack/constants/src';

import placeholderData from './data';
import { TimestreamDBProps } from './types';

/**
 * UI for querying data belonging to a Timestream database.
 */

export const TimestreamQuery = ({
  Layout,
  clientOverrides,
  routes = DEFAULT_TIMESTREAMDB_ROUTES,
}: TimestreamDBProps): ReactElement => {
  const { showSnackbar } = useSnackbar();
  const { goto } = useRoutes();
  const { database, table } = useParams();
  const [isQueryMode, setQueryMode] = useState(true);
  const [data, setData] = useState<QueryResponse | undefined>(undefined);
  const placeholderQuery = `SELECT * FROM "${database}"."${table}" LIMIT 10`;
  const switchMode = () => {
    setQueryMode(!isQueryMode);
    setQueryOrDataString(!isQueryMode ? placeholderQuery : placeholderData);
  };
  const { writeRecords, isLoading: insertLoading } = useAwsEffect('TimestreamWrite', ['writeRecords'], {
    clientOverrides,
  });

  const { query: runQuery, isLoading: isQueryLoading } = useAwsEffect('TimestreamQuery', ['query'], {
    clientOverrides,
  });

  const [queryOrDataString, setQueryOrDataString] = useState(isQueryMode ? placeholderQuery : placeholderData);
  const isLoading = insertLoading || isQueryLoading;

  const populateTimestreamTable = (records: TSRecords): Promise<WriteRecordsResponse> => {
    const params = {
      DatabaseName: database as string,
      TableName: table as string,
      CommonAttributes: {
        MeasureValueType: 'DOUBLE',
        TimeUnit: 'SECONDS',
      },
      Records: records,
    };
    return writeRecords(params);
  };

  const updateValue = (query: string) => setQueryOrDataString(query);

  const handleQueryButtonClick = async () => {
    const data_returned = await runQuery({ QueryString: queryOrDataString || placeholderQuery });
    setData(data_returned);
  };

  const handleInsertButtonClick = async () => {
    try {
      await populateTimestreamTable(JSON.parse(queryOrDataString));
      showSnackbar({
        message: 'Data inserted',
        severity: 'success',
      });
    } catch (e) {
      showSnackbar({
        message: e.message,
        severity: 'error',
      });
    }
  };

  const handleButtonClick = useCallback(
    (e: FormEvent<HTMLFormElement>) => {
      e.preventDefault();
      if (isQueryMode) {
        handleQueryButtonClick();
      } else {
        handleInsertButtonClick();
      }
    },
    [queryOrDataString],
  );

  return (
    <Layout
      documentTitle={`Timestream: ${database}/${table}`}
      title={
        <Breadcrumbs aria-label="breadcrumb">
          <Link onClick={() => goto(routes.RESOURCES_TIMESTREAM)} underline="hover">
            Timestream
          </Link>
          <Link
            onClick={() =>
              goto(routes.RESOURCES_TIMESTREAM_DATABASE, {
                database: database as string,
              })
            }
            underline="hover"
          >
            {database}
          </Link>
          <Typography>{table}</Typography>
        </Breadcrumbs>
      }
      actions={
        <>
          <ProgressButton type="submit" form="statement" color="primary" variant="outlined" loading={isLoading}>
            {isQueryMode ? 'Run Query' : 'Insert Data'}
          </ProgressButton>
          <Switch
            color="primary"
            checked={isQueryMode}
            onChange={switchMode}
            inputProps={{ 'aria-label': 'controlled' }}
          />
        </>
      }
    >
      <form id="statement" onSubmit={handleButtonClick}>
        <CodeEditor language="sql" setValue={updateValue} value={queryOrDataString} />
      </form>
      <br />
      {isQueryMode && (
        <Card>
          <LoadingFragment loading={isLoading} variant="card" height={300}>
            <TimestreamQueryGrid data={data as QueryResponse} loading={isLoading} />
          </LoadingFragment>
        </Card>
      )}
    </Layout>
  );
};
