import { ReactElement } from 'react';
import { useAwsEffect, useRoutes } from '@localstack/services';
import { Card, CardContent, CardActions, Box, Typography } from '@mui/material';
import { Breadcrumbs, GlueDatabaseForm, ProgressButton } from '@localstack/ui';
import { CreateGlueDatabaseRequest } from '@localstack/types';

import { DEFAULT_GLUE_ROUTES } from '@localstack/constants/src';

import { GlueProps } from './types';
import { MainNavTabs } from './components';

export const GlueDatabaseCreate = ({
  Layout,
  clientOverrides,
  routes = DEFAULT_GLUE_ROUTES,
}: GlueProps): ReactElement => {
  const { goto } = useRoutes();

  const { createDatabase, isLoading } = useAwsEffect('Glue', ['createDatabase'], {
    revalidate: ['getDatabases'],
    clientOverrides,
  });

  const handleCreate = async (data: CreateGlueDatabaseRequest) => {
    await createDatabase(data);
    goto(routes.RESOURCES_GLUE_DATABASES);
  };

  return (
    <Layout
      documentTitle="Glue: Create Database"
      title={
        <Box>
          <Typography variant="h4">Create Database</Typography>
          <Breadcrumbs
            mappings={[
              ['Glue', () => goto(routes.RESOURCES_GLUE_DATABASES)],
              ['Create Database', null],
            ]}
          />
        </Box>
      }
      tabs={<MainNavTabs routes={routes} />}
      actions={
        <ProgressButton type="submit" form="CreateDB" variant="outlined" color="primary" loading={isLoading}>
          Submit
        </ProgressButton>
      }
    >
      <Card>
        <CardContent>
          <GlueDatabaseForm loading={isLoading} formId="CreateDB" onSubmit={handleCreate} />
        </CardContent>
        <CardActions>
          <ProgressButton type="submit" form="CreateDB" variant="contained" color="primary" loading={isLoading}>
            Submit
          </ProgressButton>
        </CardActions>
      </Card>
    </Layout>
  );
};
