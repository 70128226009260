import { ReactElement } from 'react';
import { QLDBCreateLedgerRequest, QLDBUpdateLedgerRequest } from '@localstack/types';
import { AwsClientOverrides, getSchema } from '@localstack/services';
import { DEFAULT_KMS_ROUTES } from '@localstack/constants';

import { MagicForm } from '../../magic/MagicForm';
import { RelatedResourcePicker } from '../../../form/RelatedResourcePicker';

export type CreateQLDBLedgerProps = {
  ledger?: Optional<Partial<QLDBUpdateLedgerRequest>>;
  loading?: boolean;
  formId: string;
  clientOverrides?: AwsClientOverrides;
  onCreate: (data: QLDBCreateLedgerRequest) => unknown;
  onUpdate: (data: QLDBUpdateLedgerRequest) => unknown;
};

export const QLDBLedgerForm = ({
  ledger,
  loading,
  formId,
  clientOverrides,
  onCreate,
  onUpdate,
}: CreateQLDBLedgerProps): ReactElement => (
  <>
    <MagicForm
      data={ledger || { DeletionProtection: true }}
      schema={getSchema('QLDB')}
      formId={formId}
      entry={ledger ? 'UpdateLedgerRequest' : 'CreateLedgerRequest'}
      loading={loading}
      defaultValues={{
        DeletionProtection: true,
      }}
      fieldConditions={{
        '^Name': !ledger,
      }}
      externalFields={{
        '^KmsKey': (control, fieldName, required) => (
          <RelatedResourcePicker
            control={control}
            client="KMS"
            method="listKeys"
            arrayKeyName="Keys"
            property="KeyArn"
            fieldName={fieldName}
            entityName="KMS Key"
            creationRoute={DEFAULT_KMS_ROUTES.RESOURCES_KMS_KEY_CREATE}
            required={required}
            clientOverrides={clientOverrides}
          />
        ),
      }}
      onSubmit={(data: QLDBCreateLedgerRequest | QLDBUpdateLedgerRequest) =>
        !ledger ? onCreate(data as QLDBCreateLedgerRequest) : onUpdate({ ...data, Name: ledger?.Name || '' })
      }
    />
  </>
);
