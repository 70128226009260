import { useState, useCallback, ReactElement } from 'react';
import { useRoutes, useAwsEffect, useAwsGetter } from '@localstack/services';
import { Card, Button } from '@mui/material';

import { Dropdown, ConfirmableButton, KafkaClustersTable } from '@localstack/ui';

import { DEFAULT_KAFKA_ROUTES } from '@localstack/constants';

import { KafkaProps } from './types';

const POLLING_INTERVAL = 5000;

export const KafkaClusters = ({ Layout, clientOverrides, routes = DEFAULT_KAFKA_ROUTES }: KafkaProps): ReactElement => {
  const [selectedIds, setSelectedIds] = useState<string[]>([]);

  const { goto } = useRoutes();

  const { data: clusters, isLoading } = useAwsGetter('Kafka', 'listClusters', [], {
    swrOverrides: { refreshInterval: POLLING_INTERVAL },
    clientOverrides,
  });

  const { deleteCluster } = useAwsEffect('Kafka', ['deleteCluster'], { revalidate: ['listClusters'] });

  const handleDeleteSelected = useCallback(async () => {
    const promises = selectedIds.map((ClusterArn) => deleteCluster({ ClusterArn }));
    await Promise.all(promises);
  }, [selectedIds]);

  return (
    <Layout
      title="Kafka Clusters"
      actions={
        <>
          <Button onClick={() => goto(routes.RESOURCES_KAFKA_CLUSTER_CREATE)}>Create Cluster</Button>
          <Dropdown label="Actions">
            <ConfirmableButton
              componentType="MenuItem"
              disabled={selectedIds.length === 0 || isLoading}
              title={`Delete ${selectedIds.length} Cluster(s)?`}
              onClick={handleDeleteSelected}
              text="Selected Clusters will be permanently deleted"
            >
              Remove Selected
            </ConfirmableButton>
          </Dropdown>
        </>
      }
    >
      <Card>
        <KafkaClustersTable
          selectable
          clusters={clusters?.ClusterInfoList ?? []}
          loading={isLoading}
          onSelect={setSelectedIds}
          onViewCluster={({ ClusterArn }) => goto(routes.RESOURCES_KAFKA_CLUSTER, { clusterArn: ClusterArn })}
        />
      </Card>
    </Layout>
  );
};
