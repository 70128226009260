import { ReactElement } from 'react';
import { useRoutes, useAwsGetter } from '@localstack/services';
import { useParams } from 'react-router-dom';
import { Card, CardContent, Box, Typography } from '@mui/material';
import { Breadcrumbs, EC2InstanceDetails } from '@localstack/ui';

import { DEFAULT_EC2_ROUTES } from '@localstack/constants';

import { EC2Props } from './types';

export const EC2Instance = ({ Layout, clientOverrides, routes = DEFAULT_EC2_ROUTES }: EC2Props): ReactElement => {
  const { goto } = useRoutes();
  const { id } = useParams<'id'>();

  const { data: instances } = useAwsGetter('EC2', 'describeInstances', [{ InstanceIds: [id as string] }], {
    clientOverrides,
  });
  const instance = instances?.Reservations?.map((res) => res.Instances)?.flat()?.[0];

  return (
    <Layout
      documentTitle="EC2: Instance Details"
      title={
        <Box>
          <Typography variant="h4">Instance Details</Typography>
          <Breadcrumbs
            mappings={[
              ['EC2', () => goto(routes.RESOURCES_EC2)],
              ['Instances', () => goto(routes.RESOURCES_EC2_INSTANCES)],
              [id, null],
            ]}
          />
        </Box>
      }
    >
      <Card>
        <CardContent>
          <EC2InstanceDetails instance={instance} />
        </CardContent>
      </Card>
    </Layout>
  );
};
