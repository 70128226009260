import { ReactElement } from 'react';
import { PutGlueSchemaVersionMetadataRequest, GlueMetadataKeyValuePair } from '@localstack/types';
import { getSchema } from '@localstack/services';

import { MagicForm } from '../../../magic/MagicForm';

export type GlueSchemaVersionMetadataFormProps = {
  metadata?: Optional<Partial<{ MetadataKeyValue: GlueMetadataKeyValuePair }>>;
  loading?: boolean;
  formId: string;
  onUpsert: (data: PutGlueSchemaVersionMetadataRequest) => unknown;
};

export const GlueSchemaVersionMetadataForm = ({
  metadata,
  loading,
  formId,
  onUpsert,
}: GlueSchemaVersionMetadataFormProps): ReactElement => (
  <MagicForm
    data={metadata}
    schema={getSchema('Glue')}
    formId={formId}
    entry="PutSchemaVersionMetadataInput"
    loading={loading}
    fieldConditions={{
      SchemaId: false,
      SchemaVersionNumber: false,
      SchemaVersionId: false,
      'MetadataKeyValue.MetadataKey$': !metadata,
    }}
    onSubmit={(data: PutGlueSchemaVersionMetadataRequest) => onUpsert(data)}
  />
);
