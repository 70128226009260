import { ReactElement } from 'react';
import { useRoutes, useAwsGetter } from '@localstack/services';
import { useParams } from 'react-router-dom';
import { Card, CardContent, Button, Box, Typography } from '@mui/material';
import { Breadcrumbs, GlueSchemaDetails as Details } from '@localstack/ui';
import { DEFAULT_GLUE_ROUTES } from '@localstack/constants';

import { GlueProps } from './types';
import { SchemaTabs } from './components';

export const GlueSchemaDetails = ({
  Layout,
  clientOverrides,
  routes = DEFAULT_GLUE_ROUTES,
}: GlueProps): ReactElement => {
  const { goto } = useRoutes();
  const { schema, registry } = useParams<'schema' | 'registry'>();

  const { data: SchemaData } = useAwsGetter(
    'Glue',
    'getSchema',
    [{ SchemaId: { SchemaName: schema, RegistryName: registry } }],
    { clientOverrides },
  );

  return (
    <Layout
      documentTitle="Glue: Schema Details"
      tabs={<SchemaTabs routes={routes} schema={schema ?? ''} registry={registry ?? ''} />}
      title={
        <Box>
          <Typography variant="h4">Schema Details</Typography>
          <Breadcrumbs
            mappings={[
              ['Glue', () => goto(routes.RESOURCES_GLUE_DATABASES)],
              ['Schemas', () => goto(routes.RESOURCES_GLUE_SCHEMAS)],
              [schema, null],
            ]}
          />
        </Box>
      }
      actions={
        <Button onClick={() => goto(routes.RESOURCES_GLUE_SCHEMA_UPDATE, { schema, registry })}>Edit Schema</Button>
      }
    >
      <Card>
        <CardContent>
          <Details schema={SchemaData} />
        </CardContent>
      </Card>
    </Layout>
  );
};
