import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import { PostHogConfig } from 'posthog-js';
import { PostHogProvider } from 'posthog-js/react';

// Material helpers
import { Theme } from '@mui/material/styles';

import { GlobalStateProvider } from '@localstack/ui';

import { LocalStackThemeProvider } from '@localstack/integrations';

import { configureErrorsTracker, configureApiClient } from '~/config';

// Service worker
import * as serviceWorker from '~/config/serviceWorker';

import App from './App';

import { AuthGuardProvider } from './contexts/AuthGuardContext';

// grid-layout and resizable
import 'react-resizable/css/styles.css';
import 'react-grid-layout/css/styles.css';

// our own
import './assets/fonts.css';
import './assets/index.css';
import { HubspotProvider } from './contexts/HubspotContext';
import { PostHogWrapper } from './components/PostHogWrapper';
import { SWRConfig } from './config/swr';

declare module '@mui/styles/defaultTheme' {
  // eslint-disable-next-line @typescript-eslint/no-empty-object-type
  interface DefaultTheme extends Theme {}
}

configureErrorsTracker();
configureApiClient();

const postHogOptions: Partial<PostHogConfig> = {
  api_host: process.env.REACT_APP_PUBLIC_POSTHOG_HOST,
  capture_pageview: false,
  capture_pageleave: true,
};

ReactDOM.render(
  <SWRConfig>
    <LocalStackThemeProvider disableCommunicationProvider>
      <BrowserRouter>
        <PostHogProvider apiKey={process.env.REACT_APP_PUBLIC_POSTHOG_KEY as string} options={postHogOptions}>
          <GlobalStateProvider>
            <AuthGuardProvider>
              <HubspotProvider>
                <PostHogWrapper>
                  <App />
                </PostHogWrapper>
              </HubspotProvider>
            </AuthGuardProvider>
          </GlobalStateProvider>
        </PostHogProvider>
      </BrowserRouter>
    </LocalStackThemeProvider>
  </SWRConfig>,
  document.getElementById('root'),
);

serviceWorker.unregister();
