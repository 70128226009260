import { ReactElement, useState } from 'react';
import { Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import createStyles from '@mui/styles/createStyles';
import { LocalStackEvent } from '@localstack/types';
import { Box, Button, LinearProgress, Typography } from '@mui/material';
import { ArrowDropDown, ArrowDropUp } from '@mui/icons-material';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    terminal: {
      display: 'grid',
    },
    terminalProgress: {
      borderBottomLeftRadius: 0,
      borderBottomRightRadius: 0,
    },
    terminalOutput: {
      display: 'block',
      backgroundColor: theme.palette.grey[900],
      color: theme.palette.common.white,
      fontFamily: 'monospace',
      fontSize: '0.8rem',
      padding: theme.spacing(1),
      whiteSpace: 'pre-line',
      borderBottomLeftRadius: theme.shape.borderRadius,
      borderBottomRightRadius: theme.shape.borderRadius,
      maxHeight: 400,
      overflowY: 'auto',
      overflowX: 'hidden',
    },
    seeMoreButton: {
      textTransform: 'lowercase',
      justifySelf: 'flex-end',
      border: `1px solid ${theme.palette.primary.main}`,
      borderTop: 'none',
      fontSize: '0.8rem',
      borderTopLeftRadius: 0,
      borderTopRightRadius: 0,
      width: 'fit-content',
      marginRight: '0.5rem',
    },
  }),
);

type Props = {
  logEvents?: LocalStackEvent[];
  isProgressing?: boolean;
  level?: 'status' | 'pip' | 'all' | 'log';
  hideMoreButton?: boolean;
};

export const LogOutput = ({ logEvents, isProgressing, level = 'log', hideMoreButton = false }: Props): ReactElement => {
  const classes = useStyles();

  const [filter, setFilter] = useState(level);

  const nonStatusLogs = logEvents?.filter((log) => log.event !== 'status');

  const isAll = filter === 'all';

  const filteredEvents = filter === 'all' ? nonStatusLogs : nonStatusLogs?.filter((log) => log.event === filter);

  return (
    <Box className={classes.terminal}>
      {isProgressing ? (
        <LinearProgress className={classes.terminalProgress} />
      ) : (
        <LinearProgress className={classes.terminalProgress} variant="determinate" value={100} />
      )}
      <Typography className={classes.terminalOutput}>
        {'Log Output:\n'}
        {filteredEvents?.map((event) => `${event.message}\n`)}
      </Typography>
      {!isProgressing && !hideMoreButton && (
        <Button
          className={classes.seeMoreButton}
          onClick={() => (isAll ? setFilter(level) : setFilter('all'))}
          size="small"
          color="primary"
        >
          {isAll ? (
            <>
              <ArrowDropUp />
              see less
            </>
          ) : (
            <>
              <ArrowDropDown />
              see more
            </>
          )}
        </Button>
      )}
    </Box>
  );
};
