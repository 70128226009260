import { useState, ReactElement } from 'react';
import { useParams } from 'react-router-dom';
import { Grid, Card, ListSubheader, MenuItem } from '@mui/material';

import {
  Dropdown,
  ConfirmableButton,
  BackupVaultDetails,
  BackupVaultRecoveryPointsTable,
  PageTitle,
} from '@localstack/ui';

import { useRoutes, useAwsGetter, useAwsEffect } from '@localstack/services';

import { DEFAULT_BACKUP_ROUTES } from '@localstack/constants/src';

import { DetailsNavTabs } from './components';
import { BackupProps } from './types';

const DEFAULT_IAM_ROLE_ARN = 'arn:aws:iam::000000000000:role/test';

export const BackupVault = ({ Layout, clientOverrides, routes = DEFAULT_BACKUP_ROUTES }: BackupProps): ReactElement => {
  const { name } = useParams<'name'>();
  const { goto } = useRoutes();

  const [selectedRecoveryPoints, setSelectedRecoveryPoints] = useState<string[]>([]);

  const { data: backupVault, isLoading: isVaultLoading } = useAwsGetter(
    'Backup',
    'describeBackupVault',
    [{ BackupVaultName: name }],
    { clientOverrides },
  );

  const {
    data: recoveryPoints,
    isLoading: isRecoveryPointsLoading,
    mutate,
  } = useAwsGetter('Backup', 'listRecoveryPointsByBackupVault', [{ BackupVaultName: name }], { clientOverrides });

  const { deleteBackupVault, isLoading: isVaultMutating } = useAwsEffect('Backup', ['deleteBackupVault'], {
    revalidate: ['describeBackupVault', 'listBackupVaults'],
    clientOverrides,
  });

  const { startRestoreJob } = useAwsEffect('Backup', ['startRestoreJob'], {
    revalidate: ['listRestoreJobs'],
    clientOverrides,
  });

  const isLoading = isVaultLoading || isVaultMutating || isRecoveryPointsLoading;

  const handleStartRestoreBackupJob = async () => {
    if (!selectedRecoveryPoints.length) return;

    await startRestoreJob({
      IamRoleArn: DEFAULT_IAM_ROLE_ARN,
      Metadata: {},
      RecoveryPointArn: selectedRecoveryPoints[0] as string,
    });
  };

  return (
    <>
      <Layout
        title={
          <PageTitle
            title={`Backup Vault ${name}`}
            onMutate={mutate}
            breadcrumbs={[
              ['Vaults', () => goto(routes.RESOURCES_BACKUP_VAULTS)],
              [name, () => null],
            ]}
          />
        }
        tabs={<DetailsNavTabs routes={routes} />}
        actions={
          <>
            <Dropdown label="Actions">
              <MenuItem onClick={() => goto(routes.RESOURCES_BACKUP_JOB_CREATE)}>Create Backup</MenuItem>
              <ConfirmableButton
                componentType="MenuItem"
                disabled={isLoading}
                title={`Delete Backup Vault ${name}?`}
                text={`Are you sure you would like to delete Backup Vault ${name}?`}
                onClick={async () => {
                  goto(routes.RESOURCES_BACKUP_VAULTS);
                  await deleteBackupVault({ BackupVaultName: name as string });
                }}
              >
                Delete Vault
              </ConfirmableButton>
              <MenuItem onClick={handleStartRestoreBackupJob} disabled={selectedRecoveryPoints.length !== 1}>
                Restore Recovery Point
              </MenuItem>
            </Dropdown>
          </>
        }
      >
        <Grid container spacing={3}>
          <Grid item md={4} sm={12}>
            <Card>
              <BackupVaultDetails vault={backupVault} />
            </Card>
          </Grid>
          <Grid item md={8} sm={12}>
            <Card>
              <ListSubheader component="div">Recovery Points</ListSubheader>
              <BackupVaultRecoveryPointsTable
                selectable
                onSelect={setSelectedRecoveryPoints}
                recoveryPoints={recoveryPoints?.RecoveryPoints ?? []}
                onRestoreRecoveryPoint={handleStartRestoreBackupJob}
              />
            </Card>
          </Grid>
        </Grid>
      </Layout>
    </>
  );
};
