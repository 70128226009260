import { ReactElement } from 'react';
import { AthenaDatabaseItemsTable } from '@localstack/ui';
import { useAwsGetter } from '@localstack/services';

interface Props {
  databaseName: string;
  tableName: string;
}

export const TableSchema = ({ databaseName, tableName }: Props): ReactElement => {
  // start query execution
  const { data: tablesQuery, isLoading } = useAwsGetter('Athena', 'startQueryExecution', [
    { QueryString: `DESCRIBE ${databaseName}.${tableName}` },
  ]);

  // monitor query execution
  const { data: tableQueryStatus } = useAwsGetter(
    'Athena',
    'getQueryExecution',
    [{ QueryExecutionId: tablesQuery?.QueryExecutionId }],
    { swrOverrides: { refreshInterval: 1000 } },
  );

  const executionState = tableQueryStatus?.QueryExecution?.Status?.State;

  const queryResultId = executionState === 'SUCCEEDED' ? tablesQuery?.QueryExecutionId : undefined;

  // load query execution results once it becomes "SUCCEEDED"
  const { data: result } = useAwsGetter('Athena', 'getQueryResults', [{ QueryExecutionId: queryResultId }], {
    swrOverrides: { refreshInterval: 1000 },
  });

  return <AthenaDatabaseItemsTable results={result} loading={isLoading || executionState === 'RUNNING'} />;
};
