import { ReactElement } from 'react';
import makeStyles from '@mui/styles/makeStyles';
import createStyles from '@mui/styles/createStyles';
import ReactMarkdown, { Options } from 'react-markdown';

import { NewTabLink } from '../../navigation/NewTabLink';

// we are overriding margin / padding to 0 in index.css for all elements
// since rendering markdown relies on the default margin / padding this class reverts these changes
const useStyles = makeStyles(() =>
  createStyles({
    markdown: {
      '& > *': {
        margin: 'revert',
        padding: 'revert',
      },
      '& > pre': {
        textWrap: 'wrap',
      },
    },
  }),
);

export const MarkdownRenderer = ({ children, components, ...rest }: Options): ReactElement => {
  const classes = useStyles();
  return (
    <ReactMarkdown
      components={{
        a: ({ children: linkChildren, href }) => (
          <NewTabLink type="Link" rel="noopener noreferrer" href={href}>
            {linkChildren}
          </NewTabLink>
        ),
        ...components,
      }}
      {...rest}
      className={classes.markdown}
    >
      {children}
    </ReactMarkdown>
  );
};
