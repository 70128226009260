import { ReactElement, useState, useCallback } from 'react';
import { Button, Card } from '@mui/material';
import { useRoutes, useAwsGetter, useAwsEffect } from '@localstack/services';
import { Dropdown, ConfirmableButton, NeptuneClustersTable, PageTitle } from '@localstack/ui';

import { DEFAULT_NEPTUNE_ROUTES } from '@localstack/constants';

import { NeptuneProps } from './types';
import { NavTabs } from './components/NavTabs';

export const NeptuneDBClusters = ({
  Layout,
  clientOverrides,
  routes = DEFAULT_NEPTUNE_ROUTES,
}: NeptuneProps): ReactElement => {
  const { goto } = useRoutes();
  const [selected, setSelected] = useState<string[]>([]);

  const {
    data: clusters,
    isLoading,
    mutate,
  } = useAwsGetter('Neptune', 'describeDBClusters', [{ Filters: [{ Name: 'engine', Values: ['neptune'] }] }], {
    clientOverrides,
  });
  const { deleteDBCluster } = useAwsEffect('Neptune', ['deleteDBCluster'], {
    revalidate: ['describeDBClusters'],
    clientOverrides,
  });

  const handleDeleteObjects = useCallback(
    async () => Promise.all(selected.map((DBClusterIdentifier) => deleteDBCluster({ DBClusterIdentifier }))),
    [selected],
  );

  return (
    <Layout
      title={<PageTitle title="Neptune Database Clusters" onMutate={mutate} />}
      tabs={<NavTabs routes={routes} />}
      actions={
        <>
          <Button onClick={() => goto(routes.RESOURCES_NEPTUNE_CLUSTER_CREATE)}>Create Cluster</Button>
          <Dropdown label="Actions">
            <ConfirmableButton
              componentType="MenuItem"
              disabled={selected.length === 0 || isLoading}
              title={`Remove ${selected.length} cluster(s)?`}
              onClick={handleDeleteObjects}
              text="Selected clusters will be permanently deleted"
            >
              Remove Selected
            </ConfirmableButton>
          </Dropdown>
        </>
      }
    >
      <Card>
        <NeptuneClustersTable
          selectable
          loading={isLoading}
          clusters={clusters?.DBClusters ?? []}
          onSelect={setSelected}
          onViewCluster={(cluster) =>
            goto(routes.RESOURCES_NEPTUNE_CLUSTER, { clusterId: cluster.DBClusterIdentifier })
          }
        />
      </Card>
    </Layout>
  );
};
