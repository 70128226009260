import { ReactElement } from 'react';
import { AwsClientOverrides, VALIDATION_RULES, getSchema } from '@localstack/services';
import { StartBackupJobInput } from '@localstack/types';
import { MenuItem } from '@mui/material';

import { DEFAULT_BACKUP_ROUTES, DEFAULT_IAM_ROUTES } from '@localstack/constants';

import { ControlledSelect, RelatedResourcePicker } from '../../../../form';
import { MagicForm } from '../../../magic/MagicForm';

export interface BackupJobFormProps {
  loading?: boolean;
  formId?: string;
  /** resource arns that support backups */
  arns: string[];
  clientOverrides?: AwsClientOverrides;
  onCreate: (data: StartBackupJobInput) => unknown;
}
export const BackupJobForm = ({
  loading,
  formId,
  arns,
  clientOverrides,
  onCreate,
}: BackupJobFormProps): ReactElement => (
  <MagicForm
    formId={formId}
    schema={getSchema('Backup')}
    entry="StartBackupJobInput"
    loading={loading}
    externalFields={{
      '^BackupVaultName$': (control, fieldName, required) => (
        <RelatedResourcePicker
          required={required}
          control={control}
          fieldName={fieldName}
          entityName="Backup Vault"
          client="Backup"
          method="listBackupVaults"
          arrayKeyName="BackupVaultList"
          property="BackupVaultName"
          clientOverrides={clientOverrides}
          creationRoute={DEFAULT_BACKUP_ROUTES.RESOURCES_BACKUP_VAULT_CREATE}
        />
      ),
      '^IamRoleArn$': (control, fieldName, required) => (
        <RelatedResourcePicker
          required={required}
          control={control}
          fieldName={fieldName}
          entityName="IAM Role"
          client="IAM"
          method="listRoles"
          arrayKeyName="Roles"
          property="Arn"
          clientOverrides={clientOverrides}
          creationRoute={DEFAULT_IAM_ROUTES.RESOURCES_IAM_ROLE_CREATE}
        />
      ),
      '^ResourceArn$': (control) => (
        <ControlledSelect
          control={control}
          required
          fullWidth
          label="Resource ARN"
          name="ResourceArn"
          variant="outlined"
          options={arns.map((arn) => (
            <MenuItem key={arn} value={arn}>
              {arn}
            </MenuItem>
          ))}
          rules={VALIDATION_RULES.required}
        />
      ),
    }}
    onSubmit={(data: StartBackupJobInput) => onCreate(data)}
  />
);
