import { ReactElement } from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';

import { AppRoute } from '../../config';

import { DefaultInstanceRoutes } from './Instances/Adapters';

import { PATH_ELEMENT_MAP } from './mapping';

export const CustomerViews = (): ReactElement => (
  <Routes>
    <Route path="/" element={<Navigate replace to={AppRoute.DASHBOARD} />} />
    {Object.entries(PATH_ELEMENT_MAP).map(([path, mapEntry]) => {
      const Element = Object.values(mapEntry)[0] ?? (() => <div />);
      return <Route element={<Element />} path={path} key={path} />;
    })}
    <Route element={<DefaultInstanceRoutes />} path={AppRoute.INSTANCE_COMMON} />
    <Route element={<Navigate replace to={AppRoute.NOT_FOUND} />} path="*" />

    {/*
     * Legacy routes
     * unfortunately there is no way in react to respond with a '301 moved permanently'
     * so simply navigating to the new path is all we can do. sorry search engines...
     */}
    <Route path="/account/members" element={<Navigate replace to={AppRoute.SETTINGS_MEMBERS} />} />
    <Route path="/account/apikeys" element={<Navigate replace to={AppRoute.SETTINGS_KEYS} />} />
    <Route path="/account/billing" element={<Navigate replace to={AppRoute.SETTINGS_BILLING} />} />
    <Route path="/account/subscriptions" element={<Navigate replace to={AppRoute.SETTINGS_SUBSCRIPTIONS} />} />
    <Route path="/account/auth-token" element={<Navigate replace to={AppRoute.SETTINGS_AUTH_TOKEN} />} />
    <Route path="/account/sso" element={<Navigate replace to={AppRoute.SETTINGS_SSO} />} />
    <Route path="/account/authentication" element={<Navigate replace to={AppRoute.SETTINGS_AUTHENTICATION} />} />
    <Route path="/account" element={<Navigate replace to={AppRoute.SETTINGS_PROFILE} />} />
    <Route path="/workspace" element={<Navigate replace to={AppRoute.SETTINGS_WORKSPACE} />} />
    <Route path="/workspace/members" element={<Navigate replace to={AppRoute.SETTINGS_MEMBERS} />} />
    <Route path="/workspace/api-keys" element={<Navigate replace to={AppRoute.SETTINGS_KEYS} />} />
    <Route path="/workspace/legacy-ci-keys" element={<Navigate replace to={AppRoute.SETTINGS_LEGACY_CI_KEYS} />} />
    <Route path="/workspace/billing" element={<Navigate replace to={AppRoute.SETTINGS_BILLING} />} />
    <Route path="/workspace/subscriptions" element={<Navigate replace to={AppRoute.SETTINGS_SUBSCRIPTIONS} />} />
    <Route
      path="/workspace/subscriptions/:subscriptionId"
      element={<Navigate replace to={AppRoute.SETTINGS_SUBSCRIPTION_DETAIL} />}
    />
    <Route
      path="/workspace/subscriptions/:subscriptionId/update"
      element={<Navigate replace to={AppRoute.SETTINGS_SUBSCRIPTION_UPDATE} />}
    />
    <Route path="/workspace/auth-token" element={<Navigate replace to={AppRoute.SETTINGS_AUTH_TOKEN} />} />
    <Route path="/workspace/auth-tokens" element={<Navigate replace to={AppRoute.SETTINGS_AUTH_TOKENS} />} />
    <Route path="/workspace/my-license" element={<Navigate replace to={AppRoute.SETTINGS_MY_LICENSE} />} />
    <Route path="/workspace/sso" element={<Navigate replace to={AppRoute.SETTINGS_SSO} />} />

    {/*
     * not forwarded legacy routes:
     * - '/account/subscriptions/:subscriptionId'
     * - '/account/subscriptions/:subscriptionId/update'
     */}
  </Routes>
);
