import { SERVICE_NAME } from '@localstack/types';

import { PATH_PREFIX_RESOURCES } from './base';

const CLOUDFORMATION_BASE_ROUTE = `${PATH_PREFIX_RESOURCES}/${SERVICE_NAME.CLOUDFORMATION}`;

export const DEFAULT_CLOUDFORMATION_ROUTES: Record<string, string> = {
  RESOURCES_CLOUDFORMATION: `${CLOUDFORMATION_BASE_ROUTE}`,
  RESOURCES_CLOUDFORMATION_STACKS: `${CLOUDFORMATION_BASE_ROUTE}/stacks`,
  RESOURCES_CLOUDFORMATION_STACK_CREATE: `${CLOUDFORMATION_BASE_ROUTE}/stacks/new`,
  RESOURCES_CLOUDFORMATION_STACK_UPDATE: `${CLOUDFORMATION_BASE_ROUTE}/stacks/:name/update`,
  RESOURCES_CLOUDFORMATION_STACK_RESOURCES: `${CLOUDFORMATION_BASE_ROUTE}/stacks/:name/resources`,
  RESOURCES_CLOUDFORMATION_STACK_OUTPUTS: `${CLOUDFORMATION_BASE_ROUTE}/stacks/:name/outputs`,
};
