import { ReactElement } from 'react';

import { Grid, Box, Button, Typography } from '@mui/material';

import makeStyles from '@mui/styles/makeStyles';

import { useSearchParams } from 'react-router-dom';

import { ExternalLink } from '@localstack/constants';

import { BaseLayout } from '~/layouts';

import { LinkGenerator } from './components/LinkGenerator';
import { PodInjector } from './components/PodInjector';

const useStyles = makeStyles(() => ({
  gridItem: {
    gap: '2rem',
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
  },
}));

export const Launchpad = (): ReactElement => {
  const [searchParams] = useSearchParams();
  const url = searchParams.get('url') || '';
  const classes = useStyles();

  return (
    <BaseLayout documentTitle="Cloud Pods Launchpad" hideNavigation>
      <Grid container alignItems="center" direction="column" style={{ margin: '3rem 0', flexWrap: 'nowrap' }}>
        <Grid xs={11} sm={9} md={7} lg={5} xl={4} className={classes.gridItem}>
          <Typography variant="h2">Cloud Pods Launchpad</Typography>

          {!url && <LinkGenerator />}
          {url && <PodInjector url={url} />}
        </Grid>

        <Grid xs={11} sm={9} md={7} lg={5} xl={4} className={classes.gridItem}>
          <Typography style={{ marginTop: '5rem' }} variant="h4">
            What are LocalStack Cloud Pods?
          </Typography>
          <Typography color="textSecondary">
            LocalStack Cloud Pods are a mechanism that allows you to take a snapshot of the state in your current
            LocalStack instance, persist it to a storage backend, and easily share it with your team members. With the
            LocalStack Cloud Pods Launchpad, we further allow you to easily load your cloud pods into your LocalStack
            instance.
          </Typography>
          <Box>
            <Button variant="contained" color="primary" href={ExternalLink.DOCS_CLOUD_PODS} target="_blank">
              Learn More
            </Button>
          </Box>
        </Grid>
      </Grid>
    </BaseLayout>
  );
};
