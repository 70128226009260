import { ReactElement } from 'react';
import { useParams } from 'react-router-dom';
import { Card, CardContent, CardActions, Box, Typography } from '@mui/material';
import { useAwsEffect, useRoutes, useSnackbar } from '@localstack/services';
import { SNSMessagePublishRequest } from '@localstack/types';
import { Breadcrumbs, ProgressButton, SNSMessagePublishForm } from '@localstack/ui';

import { DEFAULT_SNS_ROUTES } from '@localstack/constants';

import { SNSProps } from './types';

export const SNSMessagePublish = ({ Layout, clientOverrides, routes = DEFAULT_SNS_ROUTES }: SNSProps): ReactElement => {
  const { goto } = useRoutes();
  const { topicArn } = useParams<'topicArn'>();
  const { showSnackbar } = useSnackbar();

  const { publish, isLoading } = useAwsEffect('SNS', ['publish'], { clientOverrides });

  const handleSendMessage = async (data: SNSMessagePublishRequest) => {
    if (await publish(data)) {
      showSnackbar({ severity: 'success', message: 'Your message has been successfully published' });
      if (topicArn) {
        goto(routes.RESOURCES_SNS_TOPIC, { topicArn });
      } else {
        goto(routes.RESOURCES_SNS_TOPICS);
      }
    }
  };

  return (
    <Layout
      documentTitle="Publish Message"
      title={
        <Box>
          <Typography variant="h4">Publish Message</Typography>
          <Breadcrumbs
            mappings={[
              ['SNS', () => goto(routes.RESOURCES_SNS_TOPICS)],
              [topicArn?.split(':')?.slice(-1)?.[0] as string, () => goto(routes.RESOURCES_SNS_TOPIC, { topicArn })],
              ['Publish Message', null],
            ]}
          />
        </Box>
      }
      actions={
        <ProgressButton type="submit" form="SendMessage" variant="outlined" color="primary" loading={isLoading}>
          Publish
        </ProgressButton>
      }
    >
      <Card>
        <CardContent>
          <SNSMessagePublishForm
            arn={topicArn}
            onSubmit={handleSendMessage}
            formId="SendMessage"
            clientOverrides={clientOverrides}
          />
        </CardContent>
        <CardActions>
          <ProgressButton type="submit" form="SendMessage" variant="contained" color="primary" loading={isLoading}>
            Publish
          </ProgressButton>
        </CardActions>
      </Card>
    </Layout>
  );
};
