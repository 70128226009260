import { useApiGetter, CiService, useApiEffect, useSnackbar, useRoutes } from '@localstack/services';
import { CIProject, PlanFamily } from '@localstack/types';
import {
  ConfirmableButton,
  DangerZone,
  DangerZoneActions,
  LoadingFragment,
  PageTitle,
  ProgressButton,
} from '@localstack/ui';
import { Grid, Card, CardHeader, CardContent, CardActions } from '@mui/material';
import { useParams } from 'react-router-dom';
import { ReactElement } from 'react';

import { ContainedCustomerLayout } from '~/layouts';
import { AppRoute } from '~/config';

import { ProjectDetailNavTabs } from './components/ProjectDetailNavTabs';
import { PROJECT_FORM_ID, ProjectForm } from './components/ProjectForm';

export const CIProjectSettings = (): ReactElement => {
  const {
    storeCiProject,
    deleteCiProject,
    isLoading: isMutatingCiProject,
  } = useApiEffect(CiService, ['storeCiProject', 'deleteCiProject'], { revalidate: ['listCiProjects'] });

  const { goto } = useRoutes();

  const { showSnackbar } = useSnackbar();

  const { project: projectName } = useParams<'project'>();
  const { data: project, isLoading } = useApiGetter(CiService, 'getCiProject', [projectName]);

  const onSubmit = async (data: CIProject) => {
    const res = await storeCiProject(data.project_name, { ...data });
    if (!res) return;
    showSnackbar({
      message: 'Project updated successfully',
      severity: 'success',
    });
  };

  const onDelete = async () => {
    const res = await deleteCiProject(projectName);
    if (!res) return;
    showSnackbar({
      message: 'Project deleted successfully',
      severity: 'success',
    });
    goto(AppRoute.CI_PROJECTS);
  };

  return (
    <ContainedCustomerLayout
      title={
        <PageTitle
          title="CI Project Details"
          breadcrumbs={[
            ['CI Projects', () => goto(AppRoute.CI_PROJECTS)],
            [projectName, () => goto(AppRoute.CI_PROJECT, { project: projectName })],
          ]}
          planFamily={PlanFamily.ENTERPRISE_PLANS}
        />
      }
      tabs={<ProjectDetailNavTabs projectName={projectName ?? ''} />}
    >
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Card>
            <CardHeader title="CI Project Settings" />
            <CardContent>
              <LoadingFragment loading={isLoading} variant="card" height={464}>
                <ProjectForm project={project} onSubmit={onSubmit} />
              </LoadingFragment>
            </CardContent>
            <CardActions>
              <ProgressButton
                form={PROJECT_FORM_ID}
                loading={isMutatingCiProject}
                type="submit"
                variant="contained"
                color="primary"
              >
                Update
              </ProgressButton>
            </CardActions>
          </Card>
        </Grid>
        <Grid item xs={12}>
          <DangerZone>
            <DangerZoneActions>
              <ConfirmableButton
                componentType="DangerZoneAction"
                primaryText="Delete CI Project"
                secondaryText="This will delete the CI Project and all associated data."
                actionText="Delete"
                onClick={onDelete}
                title="Delete this CI project?"
                text={
                  <>
                    Are you sure you want to delete this CI project? This action cannot be undone. This will delete the
                    CI project and all associated data, such as CI runs.
                  </>
                }
              />
            </DangerZoneActions>
          </DangerZone>
        </Grid>
      </Grid>
    </ContainedCustomerLayout>
  );
};
