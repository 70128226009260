import { SERVICE_NAME } from '@localstack/types';

import { PATH_PREFIX_RESOURCES } from './base';

const RDS_BASE_ROUTE = `${PATH_PREFIX_RESOURCES}/${SERVICE_NAME.RDS}`;

export const DEFAULT_RDS_ROUTES: Record<string, string> = {
  RESOURCES_RDS: `${RDS_BASE_ROUTE}`,
  RESOURCES_RDS_DATABASES: `${RDS_BASE_ROUTE}/databases`,
  RESOURCES_RDS_DATABASE: `${RDS_BASE_ROUTE}/database/:databaseId`,
  RESOURCES_RDS_DATABASE_CREATE: `${RDS_BASE_ROUTE}/database/new`,
  RESOURCES_RDS_INSTANCE: `${RDS_BASE_ROUTE}/instances/:instanceId`,
  RESOURCES_RDS_INSTANCE_CREATE: `${RDS_BASE_ROUTE}/instances/:clusterId/new`,
  RESOURCES_RDS_WITHOUT_CLUSTER_INSTANCE_CREATE: `${RDS_BASE_ROUTE}/instances/new`,
  RESOURCES_RDS_INSTANCE_UPDATE: `${RDS_BASE_ROUTE}/instances/:instanceId/update`,
  RESOURCES_RDS_INSTANCE_SQL: `${RDS_BASE_ROUTE}/instances/:instanceId/sql`,
  RESOURCES_RDS_CLUSTER: `${RDS_BASE_ROUTE}/clusters/:clusterId`,
  RESOURCES_RDS_CLUSTER_CREATE: `${RDS_BASE_ROUTE}/clusters/new`,
};
