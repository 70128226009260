export const getDateDifferenceInMsFormat = (date1: Date | undefined, date2: Date | undefined): string => {
  if (!date1 || !date2) return '';
  // TS doesn't like adding dates so .valueOf() is a fix.
  const diff = Math.abs(date1.valueOf() - date2.valueOf());
  const hours = Math.floor(diff / 3600000);
  const minutes = Math.floor((diff % 3600000) / 60000);
  const seconds = Math.floor((diff % 60000) / 1000);
  const milliseconds = diff % 1000;

  const formattedTime = `${String(hours).padStart(2, '0')}:${String(minutes).padStart(2, '0')}:${String(seconds).padStart(2, '0')}.${String(milliseconds).padStart(3, '0')}`;
  return formattedTime;
};
