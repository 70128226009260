import { ReactElement } from 'react';
import { GlueCrawler } from '@localstack/types';
import { getSchema } from '@localstack/services';

import { MagicDetails } from '../../../magic/MagicDetails';

export interface GlueCrawlerDetailsProps {
  crawler?: Optional<Partial<GlueCrawler>>;
}

export const GlueCrawlerDetails = ({ crawler }: GlueCrawlerDetailsProps): ReactElement => (
  <MagicDetails data={crawler} schema={getSchema('Glue')} entry="Crawler" title="Crawler Details" />
);
