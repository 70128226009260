import { Fragment, ChangeEvent, ReactElement } from 'react';
import { Plan, PlanFamily, OrderContext, TaxDetails } from '@localstack/types';
import { Theme } from '@mui/material/styles';

import makeStyles from '@mui/styles/makeStyles';
import createStyles from '@mui/styles/createStyles';

import {
  getPlanEstimations,
  isDiscountApplicable,
  formatMonetaryAmount,
  formatTaxableText,
} from '@localstack/services';

import {
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  RadioGroup,
  Radio,
  Divider,
  Box,
  Typography,
} from '@mui/material';

import { ProductsList } from '../ProductsList';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    trial: {
      position: 'relative',
      '& div': {
        color: theme.palette.success.main,
      },
      '&::before': {
        content: '" "',
        position: 'absolute',
        left: 0,
        height: '100%',
        width: '10px',
        background: theme.palette.success.main,
      },
    },
  }),
);

const purpose = (family: string): string => {
  if (family === PlanFamily.TRIAL_PLANS) return 'for evaluation';
  if (family === PlanFamily.HOBBY_PLANS) return 'for non-commercial use';
  if (family === PlanFamily.PRO_PLANS) return 'for individuals';
  if (family === PlanFamily.TEAM_PLANS) return 'for teams';
  return '';
};

export interface PlansList {
  plans: Plan[];
  context: OrderContext;
  selected?: Optional<Plan>;
  tax?: Optional<TaxDetails>;
  onSelect?: (plan: Plan) => unknown;
}

export const PlansList = ({ plans, context, selected, tax, onSelect }: PlansList): ReactElement => {
  const classes = useStyles();

  const handleRadioGroupChange = (e: ChangeEvent<HTMLInputElement>) => {
    const value = e?.currentTarget?.value;
    onSelect?.(plans.find((p) => p.id === value) as Plan);
  };

  return (
    <RadioGroup value={selected?.id ?? ''} onChange={handleRadioGroupChange}>
      <List disablePadding>
        {plans.map((plan, i) => (
          <Fragment key={plan.id}>
            <ListItem
              button
              onClick={() => onSelect?.(plan)}
              className={plan.family === PlanFamily.TRIAL_PLANS ? classes.trial : ''}
            >
              <ListItemAvatar>
                <Radio color="primary" value={plan.id} checked={selected?.id === plan.id} />
              </ListItemAvatar>
              <ListItemText
                primaryTypographyProps={{ component: 'div' }}
                secondaryTypographyProps={{ component: 'div' }}
                primary={
                  <Box display="flex" justifyContent="space-between" flexWrap="wrap">
                    <Box>{plan.name}</Box>
                    <Box>
                      {plan.discount && isDiscountApplicable(plan.discount) && (
                        <>
                          <s>
                            {formatMonetaryAmount(
                              getPlanEstimations(plan.products, context, plan.discount, false) / plan.interval_months,
                              plan.currency,
                            )}
                          </s>{' '}
                        </>
                      )}
                      {formatMonetaryAmount(
                        getPlanEstimations(plan.products, context, plan.discount) / plan.interval_months,
                        plan.currency,
                      )}
                      <Typography variant="caption"> {formatTaxableText('', tax, false)}</Typography>
                    </Box>
                  </Box>
                }
                secondary={
                  <Box display="flex" justifyContent="space-between" flexWrap="wrap">
                    <Box>{purpose(plan.family)}</Box>
                    <Box>per user / month {plan.interval_months === 12 && <span>(billed annually)</span>}</Box>
                  </Box>
                }
              />
            </ListItem>
            {selected?.id === plan.id && (
              <>
                <ListItem>
                  <ListItemAvatar>
                    <div />
                  </ListItemAvatar>
                  <ListItemText disableTypography>
                    <ProductsList
                      currency={plan.currency}
                      products={plan.products}
                      context={context}
                      planFamily={plan.family}
                    />
                  </ListItemText>
                </ListItem>
              </>
            )}
            {i < plans.length - 1 && <Divider variant="fullWidth" component="li" />}
          </Fragment>
        ))}
      </List>
    </RadioGroup>
  );
};
