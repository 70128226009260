/* eslint-disable */
/**
 * This file was automatically generated by json-schema-to-typescript.
 * DO NOT MODIFY IT BY HAND. Instead, modify Platform models and run
 * the export script to regenerate this file.
 */

export interface Models {
  [k: string]: unknown;
}
/**
 * This interface was referenced by `Models`'s JSON-Schema
 * via the `definition` "AdminSearchResult".
 */
export interface AdminSearchResult {
  extensions: [string, string][];
  keys: [string, string][];
  organizations: [string, string][];
  users: [string, string][];
}
/**
 * This interface was referenced by `Models`'s JSON-Schema
 * via the `definition` "ApiKey".
 */
export interface ApiKey {
  api_key: string;
  created?: number;
  deleted?: boolean;
  expires_at?: number;
  id: string;
  is_ci?: boolean;
  is_trial?: boolean;
  member_email?: string;
  name?: string;
  org_id: string;
  rotated?: string;
  subscription_id: string;
  token_type?: LicensingTokenType & string;
}
/**
 * This interface was referenced by `Models`'s JSON-Schema
 * via the `definition` "ArtifactDownload".
 */
export interface ArtifactDownload {
  artifact: string;
  email: string;
  filename: string;
  id: string;
  newsletter?: boolean;
  token: string;
}
/**
 * This interface was referenced by `Models`'s JSON-Schema
 * via the `definition` "ArtifactDownloadRequest".
 */
export interface ArtifactDownloadRequest {
  artifact: string;
  token: string;
}
/**
 * This interface was referenced by `Models`'s JSON-Schema
 * via the `definition` "ArtifactDownloadResponse".
 */
export interface ArtifactDownloadResponse {
  artifact: string;
  download_url: string;
  email: string;
  filename: string;
  id: string;
  newsletter?: boolean;
  token: string;
}
/**
 * This interface was referenced by `Models`'s JSON-Schema
 * via the `definition` "ArtifactDownloadSignup".
 */
export interface ArtifactDownloadSignup {
  artifact: string;
  email: string;
  newsletter?: boolean;
  timestamp?: number;
}
/**
 * This interface was referenced by `Models`'s JSON-Schema
 * via the `definition` "AuditTrailEvent".
 */
export interface AuditTrailEvent {
  initiator: AuditTrailInitiator;
  modifications: AuditTrailModifications;
  subject: AuditTrailParty;
  timestamp: number;
}
/**
 * This interface was referenced by `Models`'s JSON-Schema
 * via the `definition` "AuditTrailInitiator".
 */
export interface AuditTrailInitiator {
  email?: string;
  id?: string;
  name?: string;
  org_id?: string;
  org_name?: string;
  type: AuditTrailInitiatorType;
}
/**
 * This interface was referenced by `Models`'s JSON-Schema
 * via the `definition` "AuditTrailModifications".
 */
export interface AuditTrailModifications {
  changes: ModelAttributeChange[];
  entity: string;
  entity_data: {
    [k: string]: unknown;
  };
}
/**
 * This interface was referenced by `Models`'s JSON-Schema
 * via the `definition` "ModelAttributeChange".
 */
export interface ModelAttributeChange {
  new_value: unknown;
  old_value: unknown;
  path: string;
  type: string;
}
/**
 * Entity representing a basic party involved in an audit trail event (usually initiator and subject).
 * Note empty strings instead of None(s) here, this is to fix the issue with CW filtering on moto side.
 *
 * This interface was referenced by `Models`'s JSON-Schema
 * via the `definition` "AuditTrailParty".
 */
export interface AuditTrailParty {
  email?: string;
  id?: string;
  name?: string;
  org_id?: string;
  org_name?: string;
}
/**
 * This interface was referenced by `Models`'s JSON-Schema
 * via the `definition` "AuditTrailQueryResult".
 */
export interface AuditTrailQueryResult {
  events: AuditTrailEvent[];
  query_id?: string;
  status: AuditTrailQueryStatus;
}
/**
 * This interface was referenced by `Models`'s JSON-Schema
 * via the `definition` "AuthClaims".
 */
export interface AuthClaims {
  org_id: string;
  user_id: string;
  username: string;
}
/**
 * This interface was referenced by `Models`'s JSON-Schema
 * via the `definition` "AuthCredentialModel".
 */
export interface AuthCredentialModel {
  created?: number;
  id: string;
  name?: string;
  org_id: string;
  token_type?: LicensingTokenType & string;
}
/**
 * Establishes a link between an auth account(s) (Cognito) and a user account
 *
 * This interface was referenced by `Models`'s JSON-Schema
 * via the `definition` "AuthLink".
 */
export interface AuthLink {
  id: string;
  link_type: AuthLinkType;
  user_email: string;
  user_id: string;
  user_name: string;
}
/**
 * This interface was referenced by `Models`'s JSON-Schema
 * via the `definition` "AuthRequest".
 */
export interface AuthRequest {
  auth_token?: string;
  code: string;
  confirmed?: boolean;
  exchange_token?: string;
  exchanged?: boolean;
  expires_at?: boolean & number;
  id: string;
  user_id?: string;
}
/**
 * This interface was referenced by `Models`'s JSON-Schema
 * via the `definition` "AuthToken".
 */
export interface AuthToken {
  metadata?: {
    [k: string]: unknown;
  };
  token: string;
}
/**
 * Represents a browsable cloud pod instance, which is a LocalStack instance accessible
 * under a specific endpoint URL, allowing the user to browse the state of a pod.
 *
 * This interface was referenced by `Models`'s JSON-Schema
 * via the `definition` "BrowsableCloudPodInstance".
 */
export interface BrowsableCloudPodInstance {
  creation_time: number;
  endpoint_url: string;
  expiry_time: number;
  id: string;
  image: ComputeInstanceImage;
  instance_name: string;
  labels?: {
    [k: string]: string;
  };
  pod_name: string;
  requestor: string;
  shape: ComputeInstanceShape;
  status: EphemeralInstanceStatus;
  version?: number;
}
/**
 * This interface was referenced by `Models`'s JSON-Schema
 * via the `definition` "ComputeInstanceImage".
 */
export interface ComputeInstanceImage {
  image_name: SupportedImage;
  tag?: string;
}
/**
 * This interface was referenced by `Models`'s JSON-Schema
 * via the `definition` "ComputeInstanceShape".
 */
export interface ComputeInstanceShape {
  memory_megabytes: 2048 | 4096 | 8192 | 16384 | 32768;
  virtual_cpus: 1 | 2 | 4 | 8 | 16 | 32;
}
/**
 * This interface was referenced by `Models`'s JSON-Schema
 * via the `definition` "CIProject".
 */
export interface CIProject {
  id: string;
  latest_run_time?: number;
  org_id: string;
  project_name: string;
  runs?: CIRun[];
  runs_count?: number;
  settings?: CIProjectSettings;
  user_id?: string;
}
/**
 * This interface was referenced by `Models`'s JSON-Schema
 * via the `definition` "CIRun".
 */
export interface CIRun {
  end_time?: number;
  logs_url?: string;
  pod?: string;
  run_name: string;
  run_number: number;
  stack_id?: string;
  start_time: number;
}
/**
 * This interface was referenced by `Models`'s JSON-Schema
 * via the `definition` "CIProjectSettings".
 */
export interface CIProjectSettings {
  ci_runs_history?: number;
  cloud_pod_name?: string;
  cloud_pod_services?: string[];
  store_logs?: boolean;
  store_traces?: boolean;
}
/**
 * This interface was referenced by `Models`'s JSON-Schema
 * via the `definition` "CLIMetadata".
 */
export interface CLIMetadata {
  binaries: PlatformBinaries;
}
/**
 * This interface was referenced by `Models`'s JSON-Schema
 * via the `definition` "PlatformBinaries".
 */
export interface PlatformBinaries {
  linux_amd64: PrebuiltBinaryMetadata;
  linux_arm64: PrebuiltBinaryMetadata;
  windows_amd64: PrebuiltBinaryMetadata;
}
/**
 * This interface was referenced by `Models`'s JSON-Schema
 * via the `definition` "PrebuiltBinaryMetadata".
 */
export interface PrebuiltBinaryMetadata {
  downloadUrl: string;
  name: string;
}
/**
 * This interface was referenced by `Models`'s JSON-Schema
 * via the `definition` "CachedEntity".
 */
export interface CachedEntity {
  created: number;
  entity: {
    [k: string]: unknown;
  };
  entity_type: string;
  expire_at?: number;
  id: string;
  org_id?: string;
  user_id?: string;
}
/**
 * This interface was referenced by `Models`'s JSON-Schema
 * via the `definition` "CancellationDetails".
 */
export interface CancellationDetails {
  comment?: string;
  feedback?: CancellationFeedback;
  reason?: string;
}
/**
 * This interface was referenced by `Models`'s JSON-Schema
 * via the `definition` "CiKey".
 */
export interface CiKey {
  api_key: string;
  created?: number;
  deleted?: boolean;
  expires_at?: number;
  id: string;
  is_ci?: boolean;
  is_trial?: boolean;
  member_email?: string;
  name?: string;
  org_id: string;
  rotated?: string;
  rotated_from: string[];
  subscription_id: string;
  token_type?: LicensingTokenType & string;
  usage: number;
}
/**
 * This interface was referenced by `Models`'s JSON-Schema
 * via the `definition` "CiKeysUsageSummary".
 */
export interface CiKeysUsageSummary {
  ci_keys_usage_by_date?: {
    [k: string]: UsageRecordDetails;
  };
}
/**
 * This interface was referenced by `Models`'s JSON-Schema
 * via the `definition` "UsageRecordDetails".
 */
export interface UsageRecordDetails {
  key_usage?: {
    [k: string]: number;
  };
  subscription_usage?: {
    [k: string]: number;
  };
  usage_total?: number;
  user_usage?: {
    [k: string]: number;
  };
}
/**
 * The entity representing a Cloud Pod. It is part of the storage layer.
 *
 * This interface was referenced by `Models`'s JSON-Schema
 * via the `definition` "CloudPod".
 */
export interface CloudPod {
  branch?: string;
  encrypted?: boolean;
  id: string;
  is_public?: boolean;
  last_change?: number;
  max_version?: number;
  org_id: string;
  pod_name: string;
  presigned_urls?: CloudPodPresignedUrls;
  storage_size?: number;
  url?: string;
  user_id?: string;
  versions?: CloudPodVersion[];
}
/**
 * This interface was referenced by `Models`'s JSON-Schema
 * via the `definition` "CloudPodPresignedUrls".
 */
export interface CloudPodPresignedUrls {
  state_archive_url?: string;
  versions_archive_url?: string;
}
/**
 * Stores a number of details about a pod version. Part of the storage layer.
 *
 * This interface was referenced by `Models`'s JSON-Schema
 * via the `definition` "CloudPodVersion".
 */
export interface CloudPodVersion {
  cloud_control_resources?: string;
  created_at?: number;
  deleted?: boolean;
  description?: string;
  localstack_version?: string;
  services?: string[];
  storage_size?: number;
  user?: string;
  user_id?: string;
  version: number;
}
/**
 * Payload for the pod requests/responses.
 * Note: `versions` and `presigned_urls` meant only for the responses.
 *
 * This interface was referenced by `Models`'s JSON-Schema
 * via the `definition` "CloudPodDetails".
 */
export interface CloudPodDetails {
  ci_pod?: {
    [k: string]: unknown;
  };
  cloud_control_resources?: string;
  description?: string;
  encrypted?: boolean;
  localstack_version?: string;
  max_version?: number;
  pod_name?: string;
  presigned_urls?: CloudPodPresignedUrls;
  services?: string[];
  size?: number;
  user_id?: string;
  version?: number;
  versions?: CloudPodVersion[];
}
/**
 * This interface was referenced by `Models`'s JSON-Schema
 * via the `definition` "CloudPodsAutoCleanup".
 */
export interface CloudPodsAutoCleanup {
  max_pod_versions?: number;
}
/**
 * This interface was referenced by `Models`'s JSON-Schema
 * via the `definition` "CloudPodsSettings".
 */
export interface CloudPodsSettings {
  auto_cleanup?: CloudPodsAutoCleanup;
}
/**
 * This interface was referenced by `Models`'s JSON-Schema
 * via the `definition` "CloudPodsStats".
 */
export interface CloudPodsStats {
  org_limit?: CloudPodsSummary;
  org_pods?: CloudPodsSummary;
  user_pods?: {
    [k: string]: CloudPodsSummary;
  };
}
/**
 * This interface was referenced by `Models`'s JSON-Schema
 * via the `definition` "CloudPodsSummary".
 */
export interface CloudPodsSummary {
  pods_count?: number;
  storage_size?: number;
}
/**
 * This interface was referenced by `Models`'s JSON-Schema
 * via the `definition` "CombinedUserInfo".
 */
export interface CombinedUserInfo {
  features: string[];
  org: Organization;
  permissions: string[];
  roles: string[];
  user: User;
}
/**
 * This interface was referenced by `Models`'s JSON-Schema
 * via the `definition` "Organization".
 */
export interface Organization {
  address?: string;
  aws_customer?: string;
  city?: string;
  cloud_pods_stats?: CloudPodsStats;
  country?: string;
  creator: string;
  deleted?: boolean | number;
  email?: string;
  has_custom_terms?: boolean;
  hubspot_company_id?: string;
  id: string;
  members: OrganizationMember[];
  name: string;
  orgType?: OrganizationType;
  phone?: string;
  settings?: OrganizationSettings;
  state?: string;
  stripe_customer?: {
    [k: string]: string;
  };
  stripe_tax?:
    | string
    | {
        [k: string]: unknown;
      };
  subscriptions?: string[];
  tax_id?: string;
  tax_id_type?: TaxIdType;
  tenant_id?: string;
  zip?: string;
}
/**
 * This interface was referenced by `Models`'s JSON-Schema
 * via the `definition` "OrganizationMember".
 */
export interface OrganizationMember {
  email: string;
  id: string;
  name: string;
  permissions?: Permission[];
  roles: MembershipRole[];
  state: MembershipState;
  try_assign_license_on_join?: boolean;
}
/**
 * This interface was referenced by `Models`'s JSON-Schema
 * via the `definition` "OrganizationSettings".
 */
export interface OrganizationSettings {
  cloud_pods?: CloudPodsSettings;
  features?: FeatureSettings;
  sso_settings?: {
    [k: string]: OrganizationSSOSettings;
  };
}
/**
 * This interface was referenced by `Models`'s JSON-Schema
 * via the `definition` "FeatureSettings".
 */
export interface FeatureSettings {
  ci_projects?: boolean;
}
/**
 * This interface was referenced by `Models`'s JSON-Schema
 * via the `definition` "OrganizationSSOSettings".
 */
export interface OrganizationSSOSettings {
  assign_license_on_join?: boolean;
  default_membership_permissions?: string[];
  default_membership_role?: string;
  strict_sso_mode?: boolean;
}
/**
 * This interface was referenced by `Models`'s JSON-Schema
 * via the `definition` "User".
 */
export interface User {
  activated?: number;
  activation_key?: string;
  chatToken?: string;
  company?: string;
  created?: number;
  deleted?: boolean | number;
  email: string;
  email_prev?: string;
  firstname?: string;
  free_beta?: boolean;
  github_username?: string;
  how_found?: UserSource;
  hubspot_contact_id?: string;
  id: string;
  job_title?: string;
  last_hst_ts?: number;
  lastname?: string;
  login?: string;
  name: string;
  newsletter?: boolean;
  newsletter_disabled?: boolean;
  organizations?: string[];
  origin?: UserOrigin;
  origin_details?: string;
  phone?: string;
  recovery_info?: RecoveryInfo;
  recovery_key?: string;
  settings?: UserSettings;
  signup_survey?: SignupSurvey;
  token?: AuthToken;
  tos_acceptance_log?: TosAcceptanceLog;
}
/**
 * This interface was referenced by `Models`'s JSON-Schema
 * via the `definition` "RecoveryInfo".
 */
export interface RecoveryInfo {
  recovery_key: string;
  valid_through_utc_timestamp: number;
}
/**
 * This interface was referenced by `Models`'s JSON-Schema
 * via the `definition` "UserSettings".
 */
export interface UserSettings {
  latest_workspace_id?: string;
}
/**
 * This interface was referenced by `Models`'s JSON-Schema
 * via the `definition` "SignupSurvey".
 */
export interface SignupSurvey {
  create_sub?: boolean;
  planned_usage_type?: PlannedUsageType;
  promo?: string;
  require_finish_account_creation?: boolean;
}
/**
 * This interface was referenced by `Models`'s JSON-Schema
 * via the `definition` "TosAcceptanceLog".
 */
export interface TosAcceptanceLog {
  acceptance_context: string;
  client_ip: string;
  timestamp_utc: number;
  user_agent: string;
}
/**
 * This interface was referenced by `Models`'s JSON-Schema
 * via the `definition` "ComputeTenant".
 */
export interface ComputeTenant {
  id: string;
  org_id: string;
  tenant_name: string;
}
/**
 * This interface was referenced by `Models`'s JSON-Schema
 * via the `definition` "ComputeUsageInformation".
 */
export interface ComputeUsageInformation {
  per_day: {
    [k: string]: ComputeUsageRecord;
  };
  per_sub: {
    [k: string]: ComputeUsageRecord;
  };
  total: ComputeUsageRecord;
}
/**
 * This interface was referenced by `Models`'s JSON-Schema
 * via the `definition` "ComputeUsageRecord".
 */
export interface ComputeUsageRecord {
  compute_credits?: number;
}
/**
 * This interface was referenced by `Models`'s JSON-Schema
 * via the `definition` "ComputeUsagePolicy".
 */
export interface ComputeUsagePolicy {
  quota: ComputeUsageQuota;
}
/**
 * This interface was referenced by `Models`'s JSON-Schema
 * via the `definition` "ComputeUsageQuota".
 */
export interface ComputeUsageQuota {
  compute_credits?: number;
}
/**
 * This interface was referenced by `Models`'s JSON-Schema
 * via the `definition` "ComputeUsageSummary".
 */
export interface ComputeUsageSummary {
  policy: ComputeUsagePolicy;
  usage: ComputeUsageInformation;
}
/**
 * This interface was referenced by `Models`'s JSON-Schema
 * via the `definition` "CreditCard".
 */
export interface CreditCard {
  brand: CreditCardBrand;
  currency?: string;
  exp_month: number;
  exp_year: number;
  id: string;
  is_default?: boolean;
  last4: string;
}
/**
 * This interface was referenced by `Models`'s JSON-Schema
 * via the `definition` "DesktopMetadata".
 */
export interface DesktopMetadata {
  executables: PlatformExecutables;
}
/**
 * This interface was referenced by `Models`'s JSON-Schema
 * via the `definition` "PlatformExecutables".
 */
export interface PlatformExecutables {
  linux: PrebuiltBinaryMetadata;
  mac: PrebuiltBinaryMetadata;
  windows: PrebuiltBinaryMetadata;
}
/**
 * This interface was referenced by `Models`'s JSON-Schema
 * via the `definition` "Discount".
 */
export interface Discount {
  discount_flat?: number;
  discount_percentage?: number;
  discount_products?: string[];
  duration_months?: number;
  id: string;
  name?: string;
  valid_until?: number;
}
/**
 * This interface was referenced by `Models`'s JSON-Schema
 * via the `definition` "Distribution".
 */
export interface Distribution {
  description?: string;
  name: string;
  summary?: string;
  version?: string;
}
/**
 * This interface was referenced by `Models`'s JSON-Schema
 * via the `definition` "DownloadArtifact".
 */
export interface DownloadArtifact {
  artifact: string;
  location: string;
}
/**
 * This interface was referenced by `Models`'s JSON-Schema
 * via the `definition` "EmailSession".
 */
export interface EmailSession {
  id: string;
  name: string;
  sent?: string[];
}
/**
 * Represents an ephemeral instance, which is a LocalStack instance accessible under a specific
 * endpoint URL.
 * There are several use cases for eph. instances, including:
 *   (1) quickly spinning up a LS instance if it cannot be run on the local machine
 *   (2) allow the user to browse the state of a cloud pod from the UI
 *   (3) creating preview environments for application builds
 *
 * This interface was referenced by `Models`'s JSON-Schema
 * via the `definition` "EphemeralInstance".
 */
export interface EphemeralInstance {
  creation_time: number;
  endpoint_url: string;
  expiry_time: number;
  id: string;
  image: ComputeInstanceImage;
  instance_name: string;
  labels?: {
    [k: string]: string;
  };
  requestor: string;
  shape: ComputeInstanceShape;
  status: EphemeralInstanceStatus;
}
/**
 * This interface was referenced by `Models`'s JSON-Schema
 * via the `definition` "EphemeralInstanceConfig".
 */
export interface EphemeralInstanceConfig {
  allow_workspace_access?: boolean;
  env_vars?: {
    [k: string]: string;
  };
  image?: ComputeInstanceImage;
  instance_name: string;
  labels?: {
    [k: string]: string;
  };
  lifetime?: number;
  pod_on_shutdown?: boolean;
  requestor: string;
  shape?: ComputeInstanceShape;
}
/**
 * This interface was referenced by `Models`'s JSON-Schema
 * via the `definition` "Extension".
 */
export interface Extension {
  author?: string;
  created?: number;
  description?: string;
  display_name?: string;
  distribution?: Distribution;
  has_ui?: boolean;
  id: string;
  logo?: string;
  name: string;
  official?: boolean;
  org_id?: string;
  plugin_name: string;
  published?: boolean;
  submission_state?: ExtensionSubmissionState & string;
  tier?: PlanFamily;
  version?: string;
}
/**
 * This interface was referenced by `Models`'s JSON-Schema
 * via the `definition` "FeatureControlRecord".
 */
export interface FeatureControlRecord {
  default_features?: {
    [k: string]: [ProductFeature, string, string][];
  };
  feature_overrides?: FeatureOverrides;
  id: string;
  org_id: string;
}
/**
 * This interface was referenced by `Models`'s JSON-Schema
 * via the `definition` "FeatureOverrides".
 */
export interface FeatureOverrides {
  add?: {
    [k: string]: [ProductFeature, string, string][];
  };
  remove?: {
    [k: string]: [ProductFeature, string, string][];
  };
}
/**
 * This interface was referenced by `Models`'s JSON-Schema
 * via the `definition` "Feedback".
 */
export interface Feedback {
  additionalText?: string;
  feat_chaos?: number;
  feat_collab?: number;
  feat_insights?: number;
  id: string;
  motive_costs?: boolean;
  motive_local?: boolean;
  motive_regulated?: boolean;
  seniority?: string;
  showSurvey?: boolean;
  subscription_id?: string;
  survey_id?: string;
  time: number;
  user_id: string;
}
/**
 * This interface was referenced by `Models`'s JSON-Schema
 * via the `definition` "HubspotToken".
 */
export interface HubspotToken {
  token: string;
}
/**
 * This interface was referenced by `Models`'s JSON-Schema
 * via the `definition` "IdentityProvider".
 */
export interface IdentityProvider {
  IDPSignout?: boolean;
  MetadataFile?: string;
  MetadataURL?: string;
  attribute_mapping?: {
    [k: string]: string;
  };
  attributes_request_method?: string;
  attributes_url?: string;
  authorize_scopes?: string;
  authorize_url?: string;
  client_id?: string;
  client_secret?: string;
  idp_name: string;
  jwks_uri?: string;
  oidc_issuer?: string;
  org_id: string;
  provider_type: string;
  token_url?: string;
}
/**
 * Discount that can be only applied once per organization
 *
 * This interface was referenced by `Models`'s JSON-Schema
 * via the `definition` "IntroductoryDiscount".
 */
export interface IntroductoryDiscount {
  discount_flat?: number;
  discount_percentage?: number;
  discount_products?: string[];
  duration_months?: number;
  id: string;
  name?: string;
  valid_until?: number;
}
/**
 * This interface was referenced by `Models`'s JSON-Schema
 * via the `definition` "InvitationTokenPayload".
 */
export interface InvitationTokenPayload {
  action: InvitationTokenActionType;
  email: string;
  invitation_token: string;
  workspace_name: string;
}
/**
 * This interface was referenced by `Models`'s JSON-Schema
 * via the `definition` "Invoice".
 */
export interface Invoice {
  created: number;
  currency: string;
  hosted_invoice_url?: string;
  invoice_pdf?: string;
  number?: string;
  quantity?: number;
  status: InvoiceStatus;
  status_transitions?: InvoiceStatusTransitions;
  subscription?: string;
  total: number;
}
/**
 * This interface was referenced by `Models`'s JSON-Schema
 * via the `definition` "InvoiceStatusTransitions".
 */
export interface InvoiceStatusTransitions {
  finalized_at?: number;
  marked_uncollectible_at?: number;
  paid_at?: number;
  voided_at?: number;
}
/**
 * Common license attributes
 *
 * This interface was referenced by `Models`'s JSON-Schema
 * via the `definition` "License".
 */
export interface License {
  id: string;
  license_format: string;
  signature?: string;
}
/**
 * This interface was referenced by `Models`'s JSON-Schema
 * via the `definition` "LicenseAssignment".
 */
export interface LicenseAssignment {
  excluded_licensing_products?: ProductInfo[];
  id?: string;
  included_licensing_products?: ProductInfo[];
  org_id: string;
  subscription_ids?: string[];
  user_id?: string;
}
/**
 * A description of the product being licensed. Does not necessarily map to the stripe products.
 *
 * This interface was referenced by `Models`'s JSON-Schema
 * via the `definition` "ProductInfo".
 */
export interface ProductInfo {
  name: string;
  version: string;
}
/**
 * This interface was referenced by `Models`'s JSON-Schema
 * via the `definition` "LicenseV1".
 */
export interface LicenseV1 {
  credentials: string;
  expiry_date: string;
  id: string;
  issue_date: string;
  last_activated?: string;
  license_format?: string;
  license_secret?: string;
  license_status?: LicenseStatus & string;
  license_type: string;
  org_id: string;
  products?: ProductInfo[];
  reactivate_after?: string;
  signature?: string;
  user_id: string;
}
/**
 * This is the auth token we give the user or machine to authenticate themselves. Auth tokens are a form of
 * licensing credentials that allow requesting and activating of licenses. Each set of credentials are valid
 * for one entity (e.g., user) in one organization. An entity can only ever have one active set of
 * credentials.
 *
 * This interface was referenced by `Models`'s JSON-Schema
 * via the `definition` "LicensingAuthToken".
 */
export interface LicensingAuthToken {
  active: boolean;
  created?: number;
  created_at: number;
  entity_id: string;
  entity_type: string;
  id: string;
  name?: string;
  org_id: string;
  rotated_from?: string;
  token: string;
  token_type?: LicensingTokenType & string;
  user_id: string;
}
/**
 * This is the object we give back to legacy LS versions
 *
 * This interface was referenced by `Models`'s JSON-Schema
 * via the `definition` "LicensingDecryptionKey".
 */
export interface LicensingDecryptionKey {
  key: string;
}
/**
 * This interface was referenced by `Models`'s JSON-Schema
 * via the `definition` "LicensingToken".
 */
export interface LicensingToken {
  token: string;
  type: LicensingTokenType;
}
/**
 * This interface was referenced by `Models`'s JSON-Schema
 * via the `definition` "LogLine".
 */
export interface LogLine {
  content: string;
}
/**
 * This interface was referenced by `Models`'s JSON-Schema
 * via the `definition` "MyLicenseAssignmentInfo".
 */
export interface MyLicenseAssignmentInfo {
  has_license_assigned: boolean;
  plan_name?: string;
}
/**
 * This interface was referenced by `Models`'s JSON-Schema
 * via the `definition` "Notification".
 */
export interface Notification {
  created?: number;
  email: string;
  id: string;
  plan_text?: string;
  read?: boolean;
  reference_id?: string;
  rich_text?: string;
  subject: string;
}
/**
 * This interface was referenced by `Models`'s JSON-Schema
 * via the `definition` "OnceOrderablePlan".
 */
export interface OnceOrderablePlan {
  currency: string;
  discount?: Discount;
  family: PlanFamily;
  id: string;
  interval_months: number;
  max_duration?: {
    [k: string]: number;
  };
  name: string;
  prepaid?: boolean;
  products: Product[];
  provider: SubscriptionProvider;
  public?: boolean;
  subscribable?: boolean;
  supersedes_plan_ids?: string[];
  taxable?: string[];
}
/**
 * This interface was referenced by `Models`'s JSON-Schema
 * via the `definition` "Product".
 */
export interface Product {
  description?: string;
  features?: [ProductFeature, string, string][];
  id: string;
  metered?: boolean;
  name: string;
  price?: number;
  providers: SubscriptionProvider[];
  tax_code?: ProductTaxCode;
  tiers?: Tier[];
  type: ProductType;
  usage_limit?: UsageLimit;
}
/**
 * This interface was referenced by `Models`'s JSON-Schema
 * via the `definition` "Tier".
 */
export interface Tier {
  end?: number;
  flat_value?: number;
  item_value?: number;
  start: number;
}
/**
 * This interface was referenced by `Models`'s JSON-Schema
 * via the `definition` "UsageLimit".
 */
export interface UsageLimit {
  grace_period_months?: number;
  seat_tiers?: Tier[];
  soft?: boolean;
  static_limit?: number;
}
/**
 * This interface was referenced by `Models`'s JSON-Schema
 * via the `definition` "OrderContext".
 */
export interface OrderContext {
  acquisition_channel?: AcquisitionChannel & string;
  backdate_start_date?: number;
  billing_cycle_anchor?: number | string;
  country?: string;
  custom_renewal_date?: number;
  customer_id?: string;
  interval?: number;
  mrr?: number;
  mrr_private?: number;
  name?: string;
  new_customer?: boolean;
  org_id: string;
  origin?: string;
  payment_id?: string;
  promo_code?: string;
  proration_behavior?: string;
  seats: number;
  volumes?: {
    [k: string]: number;
  };
}
/**
 * This interface was referenced by `Models`'s JSON-Schema
 * via the `definition` "OrganizedApiKeys".
 */
export interface OrganizedApiKeys {
  org: ApiKey[];
  shared: ApiKey[];
}
/**
 * This interface was referenced by `Models`'s JSON-Schema
 * via the `definition` "Plan".
 */
export interface Plan {
  currency: string;
  discount?: Discount;
  family: PlanFamily;
  id: string;
  interval_months: number;
  max_duration?: {
    [k: string]: number;
  };
  name: string;
  prepaid?: boolean;
  products: Product[];
  provider: SubscriptionProvider;
  public?: boolean;
  subscribable?: boolean;
  supersedes_plan_ids?: string[];
  taxable?: string[];
}
/**
 * This interface was referenced by `Models`'s JSON-Schema
 * via the `definition` "PodsStorageSettings".
 */
export interface PodsStorageSettings {
  allowed_origins?: string[];
  hostname_external?: string;
  s3_notification_handler?: string;
}
/**
 * This interface was referenced by `Models`'s JSON-Schema
 * via the `definition` "RbacAccessControlStatement".
 */
export interface RbacAccessControlStatement {
  endpoint: string;
  features?: RbacGroupingStatement;
  methods?: string[];
  roles: RbacGroupingStatement;
}
/**
 * This interface was referenced by `Models`'s JSON-Schema
 * via the `definition` "RbacGroupingStatement".
 */
export interface RbacGroupingStatement {
  children: (unknown | RbacGroupingStatement)[];
  variant?: string;
}
/**
 * This interface was referenced by `Models`'s JSON-Schema
 * via the `definition` "RecaptchaResponse".
 */
export interface RecaptchaResponse {
  score?: number;
  success: boolean;
}
/**
 * This interface was referenced by `Models`'s JSON-Schema
 * via the `definition` "SelfAssignmentPreflight".
 */
export interface SelfAssignmentPreflight {
  failure?: SelfAssignmentPreflightFailure;
  success?: boolean;
}
/**
 * This interface was referenced by `Models`'s JSON-Schema
 * via the `definition` "Session".
 */
export interface Session {
  token: string;
  user_id: string;
}
/**
 * An object describing an AWS API call made against localstack. Objects coming from
 * api.tinybird.co/v0/pipes/dashboard_stack_aws_requests__v0.json.
 *
 * This interface was referenced by `Models`'s JSON-Schema
 * via the `definition` "StackAwsApiCall".
 */
export interface StackAwsApiCall {
  account_id: string;
  api_key: string;
  client_time: string;
  count: number;
  err_msg?: string;
  err_type?: string;
  is_internal: boolean;
  machine_id: string;
  operation: string;
  region: string;
  server_time: string;
  service: string;
  session_id: string;
  status_code: number;
  user_agent: string;
}
/**
 * An object describing properties and statistics of a stack (a localstack run). Objects coming from
 * api.tinybird.co/v0/pipes/dashboard_stacks_overview__v0.json.
 *
 * This interface was referenced by `Models`'s JSON-Schema
 * via the `definition` "StackInfo".
 */
export interface StackInfo {
  api_key: string;
  api_key_name?: string;
  duration_in_seconds?: number;
  is_ci: boolean;
  is_docker: boolean;
  number_of_api_calls_error?: number;
  number_of_api_calls_success?: number;
  number_of_services?: number;
  server_time_utc: string;
  session_id: string;
  system: string;
  top_user_agent?: string;
  user_email?: string;
  user_id?: string;
  user_name?: string;
  version: string;
}
/**
 * An object describing how often a particular service operation was called. Objects coming from
 * api.tinybird.co/v0/pipes/stack_insights_service_usage__v0.json.
 *
 * This interface was referenced by `Models`'s JSON-Schema
 * via the `definition` "StackServiceUsage".
 */
export interface StackServiceUsage {
  number_of_api_calls: number;
  operation: string;
  service: string;
  session_id: string;
}
/**
 * This interface was referenced by `Models`'s JSON-Schema
 * via the `definition` "Subscription".
 */
export interface Subscription {
  acquisition_channel?: AcquisitionChannel & string;
  cancel_at?: number;
  cancel_at_period_end?: boolean;
  cancellation_details?: CancellationDetails;
  created: number;
  currency: string;
  current_period_end: number;
  current_period_start: number;
  custom_renewal_date?: number;
  customer_id: string;
  discount?: Discount;
  estimated_mrr?: number;
  estimated_private_mrr?: number;
  id: string;
  interval_months: number;
  name: string;
  org_id: string;
  origin?: AcquisitionChannel & string;
  payment_id?: string;
  plan: Plan;
  products: Product[];
  seats: number;
  start_date: number;
  status: SubscriptionStatus;
}
/**
 * This interface was referenced by `Models`'s JSON-Schema
 * via the `definition` "SubscriptionCiKeys".
 */
export interface SubscriptionCiKeys {
  deleted_keys_usage: number;
  keys: CiKey[];
  quota: number;
  subscription_display_name: string;
  subscription_id: string;
}
/**
 * This interface was referenced by `Models`'s JSON-Schema
 * via the `definition` "TaxDetails".
 */
export interface TaxDetails {
  tax_amount: number;
  tax_rate: number;
}
/**
 * This interface was referenced by `Models`'s JSON-Schema
 * via the `definition` "UsageRecord".
 */
export interface UsageRecord {
  id: string;
  org_id: string;
  usage_details?: {
    [k: string]: UsageRecordDetails;
  };
  usage_refills?: UsageRecordRefillPackage[];
  usage_total?: number;
}
/**
 * Note refill packages are currently global!
 *
 * Which means we cannot refill a particular subscription - the customer receives a "global" refill
 * applied to all available usage records within the specified period.
 *
 * This interface was referenced by `Models`'s JSON-Schema
 * via the `definition` "UsageRecordRefillPackage".
 */
export interface UsageRecordRefillPackage {
  refill_end?: number;
  refill_start: number;
  refill_usage: number;
}
/**
 * This interface was referenced by `Models`'s JSON-Schema
 * via the `definition` "UsageSummary".
 */
export interface UsageSummary {
  ci_keys_usage_by_date?: {
    [k: string]: UsageRecordDetails;
  };
  usage_by_product: {
    [k: string]: number;
  };
  usage_by_type: {
    [k: string]: number;
  };
  usage_period_end: number;
  usage_period_start: number;
}
/**
 * This interface was referenced by `Models`'s JSON-Schema
 * via the `definition` "UserHealthFixReport".
 */
export interface UserHealthFixReport {
  failed?: UserHealthReportItem[];
  succeeded?: UserHealthReportItem[];
}

/**
 * This interface was referenced by `Models`'s JSON-Schema
 * via the `definition` "AcquisitionChannel".
 */
export const enum AcquisitionChannel {
  SELF_SERVE = 'Self-serve',
  SALES = 'Sales',
}
/**
 * This interface was referenced by `Models`'s JSON-Schema
 * via the `definition` "Action".
 */
export const enum Action {
  AFTER_GET = 'after_get',
  BEFORE_GET = 'before_get',
}
/**
 * This interface was referenced by `Models`'s JSON-Schema
 * via the `definition` "LicensingTokenType".
 */
export const enum LicensingTokenType {
  LICENSING_AUTH_TOKEN = 'licensing-auth-token',
  LICENSING_CI_AUTH_TOKEN = 'licensing-ci-auth-token',
  LICENSING_API_KEY = 'licensing-api-key',
  LICENSING_CI_KEY = 'licensing-ci-key',
}
/**
 * This interface was referenced by `Models`'s JSON-Schema
 * via the `definition` "AsyncIOEngine".
 */
export const enum AsyncIOEngine {
  CUSTOM_IO_MANAGER = 'custom_io_manager',
  POLLER = 'poller',
}
/**
 * This interface was referenced by `Models`'s JSON-Schema
 * via the `definition` "AuditTrailInitiatorType".
 */
export const enum AuditTrailInitiatorType {
  USER = 'user',
  SYSTEM = 'system',
  PLATFORM_ADMIN = 'platform_admin',
}
/**
 * This interface was referenced by `Models`'s JSON-Schema
 * via the `definition` "AuditTrailQueryStatus".
 */
export const enum AuditTrailQueryStatus {
  SCHEDULED = 'Scheduled',
  RUNNING = 'Running',
  COMPLETE = 'Complete',
  FAILED = 'Failed',
  CANCELLED = 'Cancelled',
  TIMEOUT = 'Timeout',
  UNKNOWN = 'Unknown',
}
/**
 * This interface was referenced by `Models`'s JSON-Schema
 * via the `definition` "AuthLinkType".
 */
export const enum AuthLinkType {
  PUBLIC_SSO = 'public_sso',
  CUSTOM_SSO = 'custom_sso',
  SIGNUP = 'signup',
}
/**
 * This interface was referenced by `Models`'s JSON-Schema
 * via the `definition` "AuthenticationTriggerSource".
 */
export const enum AuthenticationTriggerSource {
  PRE_SIGNUP = 'PreSignUp_SignUp',
  PRE_SIGNUP_ADMIN = 'PreSignUp_AdminCreateUser',
  PRE_SIGNUP_EXTERNAL_PROVIDER = 'PreSignUp_ExternalProvider',
  POST_CONFIRMATION = 'PostConfirmation_ConfirmSignUp',
  POST_CONFIRMATION_FORGOT_PASSWORD = 'PostConfirmation_ConfirmForgotPassword',
  PRE_AUTHENTICATION = 'PreAuthentication_Authentication',
  POST_AUTHENTICATION = 'PostAuthentication_Authentication',
  AUTH_CHALLENGE_DEFINE_AUTHENTICATION = 'DefineAuthChallenge_Authentication',
  AUTH_CHALLENGE_CREATE_AUTHENTICATION = 'CreateAuthChallenge_Authentication',
  AUTH_CHALLENGE_VERIFY_AUTHENTICATION = 'VerifyAuthChallengeResponse_Authentication',
  PRE_TOKEN_GENERATION = 'TokenGeneration_Authentication',
  PRE_TOKEN_GENERATION_HOSTED_AUTH = 'TokenGeneration_HostedAuth',
  PRE_TOKEN_GENERATION_NEW_PASSWORD_CHALLENGE = 'TokenGeneration_NewPasswordChallenge',
  PRE_TOKEN_GENERATION_AUTH_DEVICE = 'TokenGeneration_AuthenticateDevice',
  PRE_TOKEN_GENERATION_REFRESH_TOKENS = 'TokenGeneration_RefreshTokens',
  USER_MIGRATION_AUTHENTICATION = 'UserMigration_Authentication',
  USER_MIGRATION_FORGOT_PASSWORD = 'UserMigration_ForgotPassword',
}
/**
 * This interface was referenced by `Models`'s JSON-Schema
 * via the `definition` "AuthenticationUserStatus".
 */
export const enum AuthenticationUserStatus {
  UNCONFIRMED = 'UNCONFIRMED',
  CONFIRMED = 'CONFIRMED',
  EXTERNAL_PROVIDER = 'EXTERNAL_PROVIDER',
  UNKNOWN = 'UNKNOWN',
  RESET_REQUIRED = 'RESET_REQUIRED',
  FORCE_CHANGE_PASSWORD = 'FORCE_CHANGE_PASSWORD',
}
/**
 * This interface was referenced by `Models`'s JSON-Schema
 * via the `definition` "BoolObj".
 */
export const enum BoolObj {
  TRUE = 1,
  FALSE = 0,
}
/**
 * This interface was referenced by `Models`'s JSON-Schema
 * via the `definition` "SupportedImage".
 */
export const enum SupportedImage {
  localstack = 'localstack/localstack-pro',
  snowflake = 'localstack/snowflake',
  azure = 'localstack/localstack-azure-alpha',
}
/**
 * This interface was referenced by `Models`'s JSON-Schema
 * via the `definition` "EphemeralInstanceStatus".
 */
export const enum EphemeralInstanceStatus {
  CREATING = 'creating',
  RUNNING = 'running',
}
/**
 * This interface was referenced by `Models`'s JSON-Schema
 * via the `definition` "COEP".
 */
export const enum COEP {
  UNSAFE_NONE = 'unsafe-none',
  REQUIRE_CORP = 'require-corp',
}
/**
 * This interface was referenced by `Models`'s JSON-Schema
 * via the `definition` "COOP".
 */
export const enum COOP {
  UNSAFE_NONE = 'unsafe-none',
  SAME_ORIGIN_ALLOW_POPUPS = 'same-origin-allow-popups',
  SAME_ORIGIN = 'same-origin',
}
/**
 * This interface was referenced by `Models`'s JSON-Schema
 * via the `definition` "CancellationFeedback".
 */
export const enum CancellationFeedback {
  TOO_EXPENSIVE = 'too_expensive',
  MISSING_FEATURES = 'missing_features',
  SWITCHED_SERVICE = 'switched_service',
  UNUSED = 'unused',
  CUSTOMER_SERVICE = 'customer_service',
  TOO_COMPLEX = 'too_complex',
  LOW_QUALITY = 'low_quality',
  OTHER = 'other',
}
/**
 * This interface was referenced by `Models`'s JSON-Schema
 * via the `definition` "CloudPodContentMode".
 */
export const enum CloudPodContentMode {
  PUSH = 'push',
  PULL = 'pull',
}
/**
 * This interface was referenced by `Models`'s JSON-Schema
 * via the `definition` "Permission".
 */
export const enum Permission {
  READ_CREDIT_CARDS = 'read.credit_cards',
  CREATE_CREDIT_CARDS = 'create.credit_cards',
  READ_SUBSCRIPTIONS = 'read.subscriptions',
  CREATE_SUBSCRIPTIONS = 'create.subscriptions',
  UPDATE_SUBSCRIPTIONS = 'update.subscriptions',
  DELETE_SUBSCRIPTIONS = 'delete.subscriptions',
  READ_ORG_KEYS = 'read.org_keys',
  UPDATE_ORG_KEYS = 'update.org_keys',
  CREATE_ORG_KEYS = 'keys:create',
  CREATE_SELF_KEYS = 'keys:create_self',
  DELETE_ORG_KEYS = 'keys:delete',
  READ_MEMBERS = 'read.members',
  CREATE_MEMBERS = 'create.members',
  UPDATE_MEMBERS = 'update.members',
  DELETE_MEMBERS = 'delete.members',
  READ_INVOICES = 'read.invoices',
  UPDATE_ORGANIZATION = 'update.organization',
  DELETE_ORGANIZATION = 'organization:delete',
  LIST_LICENSES = 'licenses:list',
  ASSIGN_LICENSES = 'licenses:assign',
  UNASSIGN_LICENSES = 'licenses:unassign',
  WRITE_CI_KEYS = 'ci-keys:write',
  READ_CI_KEYS = 'ci-keys:read',
}
/**
 * This interface was referenced by `Models`'s JSON-Schema
 * via the `definition` "MembershipRole".
 */
export const enum MembershipRole {
  PLATFORM_ADMIN = 'platform_admin',
  ADMIN = 'admin',
  MEMBER = 'member',
}
/**
 * This interface was referenced by `Models`'s JSON-Schema
 * via the `definition` "MembershipState".
 */
export const enum MembershipState {
  INVITED = 'invited',
  ACTIVE = 'active',
  REMOVED = 'removed',
}
/**
 * This interface was referenced by `Models`'s JSON-Schema
 * via the `definition` "OrganizationType".
 */
export const enum OrganizationType {
  COMPANY = 'comp',
  INDIVIDUAL = 'ind',
}
/**
 * This interface was referenced by `Models`'s JSON-Schema
 * via the `definition` "TaxIdType".
 */
export const enum TaxIdType {
  AU_ABN = 'au_abn',
  AU_ARN = 'au_arn',
  EU_VAT = 'eu_vat',
  BR_CNPJ = 'br_cnpj',
  BR_CPF = 'br_cpf',
  CA_BN = 'ca_bn',
  CA_GST_HST = 'ca_gst_hst',
  CA_PST_BC = 'ca_pst_bc',
  CA_PST_MB = 'ca_pst_mb',
  CA_PST_SK = 'ca_pst_sk',
  CA_QST = 'ca_qst',
  CL_TIN = 'cl_tin',
  HK_BR = 'hk_br',
  IN_GST = 'in_gst',
  ID_NPWP = 'id_npwp',
  IL_VAT = 'il_vat',
  JP_CN = 'jp_cn',
  JP_RN = 'jp_rn',
  KR_BRN = 'kr_brn',
  LI_UID = 'li_uid',
  MY_FRP = 'my_frp',
  MY_ITN = 'my_itn',
  MY_SST = 'my_sst',
  MX_RFC = 'mx_rfc',
  NZ_GST = 'nz_gst',
  NO_VAT = 'no_vat',
  RU_INN = 'ru_inn',
  RU_KPP = 'ru_kpp',
  SA_VAT = 'sa_vat',
  SG_GST = 'sg_gst',
  SG_UEN = 'sg_uen',
  ZA_VAT = 'za_vat',
  ES_CIF = 'es_cif',
  CH_VAT = 'ch_vat',
  TW_VAT = 'tw_vat',
  TH_VAT = 'th_vat',
  AE_TRN = 'ae_trn',
  GB_VAT = 'gb_vat',
  US_EIN = 'us_ein',
}
/**
 * This interface was referenced by `Models`'s JSON-Schema
 * via the `definition` "UserSource".
 */
export const enum UserSource {
  GOOGLE = 'google',
  GITHUB = 'github',
  FRIEND = 'friend',
  TWITTER = 'twitter',
  ADS = 'ads',
  FORUM = 'forum',
  BLOG = 'blog',
  OTHER = 'other',
  NOT_SET = 'not_set',
}
/**
 * This interface was referenced by `Models`'s JSON-Schema
 * via the `definition` "UserOrigin".
 */
export const enum UserOrigin {
  SIGNUP = 'signup',
  INVITE = 'invite',
  PUBLIC_SSO = 'public_sso',
  CUSTOM_SSO = 'custom_sso',
  ADMIN_PANEL = 'admin_panel',
}
/**
 * This interface was referenced by `Models`'s JSON-Schema
 * via the `definition` "PlannedUsageType".
 */
export const enum PlannedUsageType {
  PROFESSIONAL = 'professional',
  HOBBYIST = 'hobbyist',
}
/**
 * This interface was referenced by `Models`'s JSON-Schema
 * via the `definition` "CreditCardBrand".
 */
export const enum CreditCardBrand {
  AMERICAN_EXPRESS = 'American Express',
  DINERS_CLUB = 'Diners Club',
  DISCOVER = 'Discover',
  JSB = 'JCB',
  MASTERCARD = 'MasterCard',
  UNION_PAY = 'UnionPay',
  VISA = 'Visa',
  UNKNOWN = 'Unknown',
}
/**
 * This interface was referenced by `Models`'s JSON-Schema
 * via the `definition` "DataStorageTable".
 */
export const enum DataStorageTable {
  ENTITIES = 'entities_table',
  CACHE = 'cache_table',
}
/**
 * This interface was referenced by `Models`'s JSON-Schema
 * via the `definition` "Domain".
 */
export const enum Domain {
  GOOGLE_INTERNAL = 1,
  PUBLIC = 2,
}
/**
 * This interface was referenced by `Models`'s JSON-Schema
 * via the `definition` "EndpointResolverBuiltins".
 */
export const enum EndpointResolverBuiltins {
  AWS_REGION = 'AWS::Region',
  AWS_USE_FIPS = 'AWS::UseFIPS',
  AWS_USE_DUALSTACK = 'AWS::UseDualStack',
  AWS_STS_USE_GLOBAL_ENDPOINT = 'AWS::STS::UseGlobalEndpoint',
  AWS_S3_USE_GLOBAL_ENDPOINT = 'AWS::S3::UseGlobalEndpoint',
  AWS_S3_ACCELERATE = 'AWS::S3::Accelerate',
  AWS_S3_FORCE_PATH_STYLE = 'AWS::S3::ForcePathStyle',
  AWS_S3_USE_ARN_REGION = 'AWS::S3::UseArnRegion',
  AWS_S3CONTROL_USE_ARN_REGION = 'AWS::S3Control::UseArnRegion',
  AWS_S3_DISABLE_MRAP = 'AWS::S3::DisableMultiRegionAccessPoints',
  SDK_ENDPOINT = 'SDK::Endpoint',
}
/**
 * This interface was referenced by `Models`'s JSON-Schema
 * via the `definition` "EventType".
 */
export const enum EventType {
  POST_AUTHENTICATION = 'auth/PostAuthentication',
  POST_CONFIRMATION = 'auth/PostConfirmation',
  PRE_TOKEN_GENERATION = 'auth/PreTokenGeneration',
  POST_USER_MIGRATION = 'auth/PostUserMigration',
}
/**
 * This interface was referenced by `Models`'s JSON-Schema
 * via the `definition` "ExtensionSubmissionState".
 */
export const enum ExtensionSubmissionState {
  SUBMITTED = 'submitted',
  APPROVED = 'approved',
  REJECTED = 'rejected',
  PUBLISHED = 'published',
}
/**
 * This interface was referenced by `Models`'s JSON-Schema
 * via the `definition` "PlanFamily".
 */
export const enum PlanFamily {
  HOBBY_PLANS = 'hobby',
  PRO_PLANS = 'pro',
  TEAM_PLANS = 'team',
  TRIAL_PLANS = 'trial',
  LEGACY_PLANS = 'legacy',
  ENTERPRISE_PLANS = 'enterprise',
  MARKETPLACE_PLANS = 'marketplace',
  COMMUNITY = 'community',
}
/**
 * This interface was referenced by `Models`'s JSON-Schema
 * via the `definition` "ProductFeature".
 */
export const enum ProductFeature {
  FEATURE_SSO = 'localstack.sso',
  FEATURE_PODS = 'localstack.pods',
  FEATURE_PODS_PUBLIC = 'localstack.pods.public',
  FEATURE_STACKS_PREVIEW = 'localstack.stacks.preview',
  FEATURE_STACKS_PREMIUM = 'localstack.stacks.premium',
  FEATURE_CI_PROJECTS = 'localstack.ci_projects',
  FEATURE_LOCALSTACK_PRO = 'localstack-pro',
  FEATURE_LOCALSTACK_EXTENSION = 'localstack.extensions',
  FEATURE_LOCALSTACK_PLUGIN = 'localstack.platform.plugin',
  FEATURE_LOCALSTACK_AWS_PROVIDER = 'localstack.aws.provider',
  FEATURE_COMPUTE = 'localstack.compute',
  FEATURE_LOCALSTACK_CONTAINER_RUNTIME = 'localstack.container.runtime',
  FEATURE_LOCALSTACK_LAMBDA_RUNTIME_EXECUTOR = 'localstack.lambda.runtime_executor',
  FEATURE_LOCALSTACK_K8S_OPERATOR = 'localstack.k8s.operator',
  FEATURE_SNOWFLAKE = 'localstack.snowflake',
}
/**
 * This interface was referenced by `Models`'s JSON-Schema
 * via the `definition` "InvitationTokenActionType".
 */
export const enum InvitationTokenActionType {
  ACCEPT = 'accept',
  CREATE = 'create',
}
/**
 * This interface was referenced by `Models`'s JSON-Schema
 * via the `definition` "InvoiceStatus".
 */
export const enum InvoiceStatus {
  DRAFT = 'draft',
  OPEN = 'open',
  PAID = 'paid',
  UNCOLLECTIBLE = 'uncollectible',
  VOID = 'void',
}
/**
 * This interface was referenced by `Models`'s JSON-Schema
 * via the `definition` "LicenseStatus".
 */
export const enum LicenseStatus {
  UNKNOWN = 'UNKNOWN',
  ACTIVE = 'ACTIVE',
  INACTIVE = 'INACTIVE',
  EXPIRED = 'EXPIRED',
  SUSPENDED = 'SUSPENDED',
}
/**
 * This interface was referenced by `Models`'s JSON-Schema
 * via the `definition` "LocalConnectionType".
 */
export const enum LocalConnectionType {
  UDS = 0,
  LOCAL_TCP = 1,
}
/**
 * This interface was referenced by `Models`'s JSON-Schema
 * via the `definition` "MetricUnit".
 */
export const enum MetricUnit {
  Seconds = 'Seconds',
  Microseconds = 'Microseconds',
  Milliseconds = 'Milliseconds',
  Bytes = 'Bytes',
  Kilobytes = 'Kilobytes',
  Megabytes = 'Megabytes',
  Gigabytes = 'Gigabytes',
  Terabytes = 'Terabytes',
  Bits = 'Bits',
  Kilobits = 'Kilobits',
  Megabits = 'Megabits',
  Gigabits = 'Gigabits',
  Terabits = 'Terabits',
  Percent = 'Percent',
  Count = 'Count',
  BytesPerSecond = 'Bytes/Second',
  KilobytesPerSecond = 'Kilobytes/Second',
  MegabytesPerSecond = 'Megabytes/Second',
  GigabytesPerSecond = 'Gigabytes/Second',
  TerabytesPerSecond = 'Terabytes/Second',
  BitsPerSecond = 'Bits/Second',
  KilobitsPerSecond = 'Kilobits/Second',
  MegabitsPerSecond = 'Megabits/Second',
  GigabitsPerSecond = 'Gigabits/Second',
  TerabitsPerSecond = 'Terabits/Second',
  CountPerSecond = 'Count/Second',
}
/**
 * This interface was referenced by `Models`'s JSON-Schema
 * via the `definition` "ModelAttributeChangeType".
 */
export const enum ModelAttributeChangeType {
  ADD = 'add',
  REMOVE = 'remove',
  REPLACE = 'replace',
  COPY = 'copy',
  MOVE = 'move',
}
/**
 * This interface was referenced by `Models`'s JSON-Schema
 * via the `definition` "MrrCategory".
 */
export const enum MrrCategory {
  NEW = 'new',
  CHURN = 'churn',
  EXPANSION = 'expansion',
  REDUCTION = 'reduction',
  NO_CHANGE = 'no_change',
}
/**
 * This interface was referenced by `Models`'s JSON-Schema
 * via the `definition` "NewsletterSignupOrigin".
 */
export const enum NewsletterSignupOrigin {
  ACCOUNT_CREATION = 'account_creation',
  COCKPIT_DOWNLOAD = 'cockpit_download',
}
/**
 * This interface was referenced by `Models`'s JSON-Schema
 * via the `definition` "SubscriptionProvider".
 */
export const enum SubscriptionProvider {
  STRIPE = 'stripe',
  PLATFORM = 'platform',
  UNKNOWN = 'unknown',
}
/**
 * This interface was referenced by `Models`'s JSON-Schema
 * via the `definition` "ProductTaxCode".
 */
export const enum ProductTaxCode {
  SAAS_DOWNLOAD_B2B = 'txcd_10103101',
}
/**
 * This interface was referenced by `Models`'s JSON-Schema
 * via the `definition` "ProductType".
 */
export const enum ProductType {
  SEATS = 'seats',
  CI_USAGE = 'ci_usage',
  POD_USAGE = 'pod_usage',
  COMPUTE_USAGE = 'compute_usage',
}
/**
 * This interface was referenced by `Models`'s JSON-Schema
 * via the `definition` "OutputFormat".
 */
export const enum OutputFormat {
  CSV = 'CSV',
  CSVWithNames = 'CSVWithNames',
  JSON = 'JSON',
  TSV = 'TSV',
  TSVWithNames = 'TSVWithNames',
  PrettyCompact = 'PrettyCompact',
  JSONEachRow = 'JSONEachRow',
}
/**
 * This interface was referenced by `Models`'s JSON-Schema
 * via the `definition` "ParameterSource".
 */
export const enum ParameterSource {
  COMMANDLINE = 1,
  ENVIRONMENT = 2,
  DEFAULT = 3,
  DEFAULT_MAP = 4,
  PROMPT = 5,
}
/**
 * This interface was referenced by `Models`'s JSON-Schema
 * via the `definition` "PaymentCardBrand".
 */
export const enum PaymentCardBrand {
  amex = 'American Express',
  mastercard = 'Mastercard',
  visa = 'Visa',
  other = 'other',
}
/**
 * This interface was referenced by `Models`'s JSON-Schema
 * via the `definition` "PlanInterval".
 */
export const enum PlanInterval {
  YEARS = 'years',
  MONTHS = 'months',
  WEEKS = 'weeks',
  DAYS = 'days',
}
/**
 * This interface was referenced by `Models`'s JSON-Schema
 * via the `definition` "PodStorageEventTrigger".
 */
export const enum PodStorageEventTrigger {
  PUT = 'PUT',
  DELETE = 'DELETE',
}
/**
 * This interface was referenced by `Models`'s JSON-Schema
 * via the `definition` "ReservoirDecision".
 */
export const enum ReservoirDecision {
  TAKE = 'take',
  BORROW = 'borrow',
  NO = 'no',
}
/**
 * This interface was referenced by `Models`'s JSON-Schema
 * via the `definition` "SelfAssignmentPreflightFailure".
 */
export const enum SelfAssignmentPreflightFailure {
  LICENSE_ALREADY_ASSIGNED = 'license-already-assigned',
  NO_TARGET_SUBSCRIPTION = 'no-target-subscription',
  ALL_SEATS_ALREADY_ASSIGNED = 'all-seats-already-assigned',
  NOT_PERMITTED = 'not-permitted',
}
/**
 * This interface was referenced by `Models`'s JSON-Schema
 * via the `definition` "Service".
 */
export const enum Service {
  LOCALSTACK = 'localstack',
  FLASK = 'flask',
  SMTP = 'smtp',
  STRIPE = 'stripe',
}
/**
 * This interface was referenced by `Models`'s JSON-Schema
 * via the `definition` "State".
 */
export const enum State {
  PREAMBLE = 1,
  PART = 2,
  DATA = 3,
  DATA_START = 4,
  EPILOGUE = 5,
  COMPLETE = 6,
}
/**
 * This interface was referenced by `Models`'s JSON-Schema
 * via the `definition` "SubscriptionStatus".
 */
export const enum SubscriptionStatus {
  INCOMPLETE = 'incomplete',
  INCOMPLETE_EXPIRED = 'incomplete_expired',
  TRIALING = 'trialing',
  ACTIVE = 'active',
  PAST_DUE = 'past_due',
  CANCELED = 'canceled',
  UNPAID = 'unpaid',
}
/**
 * This interface was referenced by `Models`'s JSON-Schema
 * via the `definition` "SubscriptionType".
 */
export const enum SubscriptionType {
  MARKETING_INFORMATION = 159704750,
  ONE_TO_ONE = 160786218,
}
/**
 * This interface was referenced by `Models`'s JSON-Schema
 * via the `definition` "TosAcceptanceContext".
 */
export const enum TosAcceptanceContext {
  SIGN_UP = 'accepted during sign-up',
  MEMBERSHIP_CONFIRMATION = 'Accepted ToS during membership confirmation (following org invite)',
  OVERLAY_PROMPT = 'accepted ToS via overlay prompt',
  CHECKOUT = 'accepted during checkout',
  TOS_PAGE = 'accepted ToS via /accept-tos page',
}
/**
 * This interface was referenced by `Models`'s JSON-Schema
 * via the `definition` "UserHealthReportItem".
 */
export const enum UserHealthReportItem {
  NOT_FOUND = 'not_found',
  MISSING_AUTH_LINK = 'missing_auth_link',
}
/**
 * This interface was referenced by `Models`'s JSON-Schema
 * via the `definition` "_Nothing".
 */
export const enum _Nothing {
  NOTHING = 1,
}
/**
 * This interface was referenced by `Models`'s JSON-Schema
 * via the `definition` "_PassArg".
 */
export const enum _PassArg {
  context = 1,
  eval_context = 2,
  environment = 3,
}
/**
 * This interface was referenced by `Models`'s JSON-Schema
 * via the `definition` "_SendfileMode".
 */
export const enum _SendfileMode {
  UNSUPPORTED = 1,
  TRY_NATIVE = 2,
  FALLBACK = 3,
}
