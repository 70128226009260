import { ReactElement } from 'react';
import { useParams } from 'react-router-dom';
import { Grid, Card, Button, Box, ListSubheader, MenuItem } from '@mui/material';

import {
  Dropdown,
  ConfirmableButton,
  BackupSelectionsTable,
  BackupPlanDetails,
  BackupRulesTable,
  PageTitle,
} from '@localstack/ui';

import { useRoutes, useAwsGetter, useAwsEffect, useAwsBatchIterator } from '@localstack/services';

import { DEFAULT_BACKUP_ROUTES } from '@localstack/constants';

import { DetailsNavTabs } from './components';
import { BackupProps } from './types';

export const BackupPlan = ({ Layout, clientOverrides, routes = DEFAULT_BACKUP_ROUTES }: BackupProps): ReactElement => {
  const { id } = useParams<'id'>();
  const { goto } = useRoutes();

  const { getBackupSelection } = useAwsEffect('Backup', ['getBackupSelection'], { clientOverrides });
  const { data: selectionSummaries, mutate } = useAwsGetter('Backup', 'listBackupSelections', [{ BackupPlanId: id }], {
    clientOverrides,
  });

  const backupSelections = useAwsBatchIterator(selectionSummaries?.BackupSelectionsList, (selection) =>
    getBackupSelection({
      BackupPlanId: selection.BackupPlanId as string,
      SelectionId: selection.SelectionId as string,
    }),
  );

  const fixedBackupSelections = backupSelections.map((data, idx) => ({
    ...data,
    ...selectionSummaries?.BackupSelectionsList?.[idx],
  }));

  const { data: backupPlan, isLoading: isPlanLoading } = useAwsGetter(
    'Backup',
    'getBackupPlan',
    [{ BackupPlanId: id }],
    { clientOverrides },
  );

  const { deleteBackupPlan, isLoading: isPlansMutating } = useAwsEffect('Backup', ['deleteBackupPlan'], {
    revalidate: ['listBackupPlans', 'getBackupPlan'],
    clientOverrides,
  });

  const isLoading = isPlanLoading || isPlansMutating;

  return (
    <>
      <Layout
        title={
          <PageTitle
            title={`Backup Plan ${backupPlan?.BackupPlan?.BackupPlanName}`}
            onMutate={mutate}
            breadcrumbs={[
              ['Plans', () => goto(routes.RESOURCES_BACKUP_PLANS)],
              [id, () => null],
            ]}
          />
        }
        tabs={<DetailsNavTabs routes={routes} />}
        actions={
          <>
            <Button disabled={isLoading} onClick={() => goto(routes.RESOURCES_BACKUP_PLAN_UPDATE, { id })}>
              Edit Plan
            </Button>
            <Dropdown label="Actions">
              <MenuItem disabled={isLoading} onClick={() => goto(routes.RESOURCES_BACKUP_PLAN_ASSIGNMENT, { id })}>
                Assign Resources
              </MenuItem>
              <ConfirmableButton
                componentType="MenuItem"
                disabled={isLoading}
                title={`Delete Backup Plan ${id}?`}
                text={`Are you sure you would like to delete Backup Plan ${id}?`}
                onClick={async () => {
                  goto(routes.RESOURCES_BACKUP_PLANS);
                  await deleteBackupPlan({ BackupPlanId: id as string });
                }}
              >
                Delete Backup Plan
              </ConfirmableButton>
            </Dropdown>
          </>
        }
      >
        <Grid container spacing={3}>
          <Grid item md={4} sm={12}>
            <Card>
              <BackupPlanDetails plan={backupPlan} />
            </Card>
          </Grid>
          <Grid item md={8} sm={12}>
            <Card>
              <ListSubheader component="div">Backup Rules</ListSubheader>
              <BackupRulesTable
                rules={backupPlan?.BackupPlan?.Rules || []}
                onViewRule={() => goto(routes.RESOURCES_BACKUP_PLAN_UPDATE, { id })}
              />
            </Card>
            <Box mt={3}>
              <Card>
                <ListSubheader component="div">Resource Assignments</ListSubheader>
                <BackupSelectionsTable selections={fixedBackupSelections} />
              </Card>
            </Box>
          </Grid>
        </Grid>
      </Layout>
    </>
  );
};
