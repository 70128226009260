import { ReactElement } from 'react';
import { Avatar } from '@mui/material';
import { makeStyles, createStyles } from '@mui/styles';

import MasterCardIcon from './icons/mastercard.svg';
import VisaIcon from './icons/visa.svg';
import AmericanExpressIcon from './icons/americanexpress.svg';
import DinersClubIcon from './icons/dinersclub.svg';
import DiscoverIcon from './icons/discover.svg';
import JcbIcon from './icons/jcb.svg';
import UnionPayIcon from './icons/unionpay.svg';

type Props = {
  brand: string;
};

const useStyles = makeStyles(() =>
  createStyles({
    root: {},
    avatar: {
      background: 'transparent',
    },
  }),
);

export const CreditCardBrandIcon = ({ brand }: Props): ReactElement => {
  const classes = useStyles();

  return (
    <span className={classes.root}>
      {brand === 'American Express' && (
        <Avatar variant="square" className={classes.avatar}>
          <AmericanExpressIcon />
        </Avatar>
      )}
      {brand === 'Diners Club' && (
        <Avatar variant="square" className={classes.avatar}>
          <DinersClubIcon />
        </Avatar>
      )}
      {brand === 'Discover' && (
        <Avatar variant="square" className={classes.avatar}>
          <DiscoverIcon />
        </Avatar>
      )}
      {brand === 'JCB' && (
        <Avatar variant="square" className={classes.avatar}>
          <JcbIcon />
        </Avatar>
      )}
      {brand === 'UnionPay' && (
        <Avatar variant="square" className={classes.avatar}>
          <UnionPayIcon />
        </Avatar>
      )}
      {brand === 'Visa' && (
        <Avatar variant="square" className={classes.avatar}>
          <VisaIcon />
        </Avatar>
      )}
      {brand === 'MasterCard' && (
        <Avatar variant="square" className={classes.avatar}>
          <MasterCardIcon />
        </Avatar>
      )}
    </span>
  );
};
