import { ReactElement } from 'react';
import { useParams } from 'react-router-dom';
import { Card, CardContent, CardActions, Box, Typography } from '@mui/material';
import { PutEventBridgeEventsRequest } from '@localstack/types';
import { useRoutes, useAwsEffect } from '@localstack/services';
import { ProgressButton, EventBridgeEventForm, Breadcrumbs } from '@localstack/ui';

import { DEFAULT_EVENTBRIDGE_ROUTES } from '@localstack/constants/src';

import { EventBridgeProps } from './types';

export const EventBridgeEventUpsert = ({
  Layout,
  clientOverrides,
  routes = DEFAULT_EVENTBRIDGE_ROUTES,
}: EventBridgeProps): ReactElement => {
  const { busName } = useParams<'busName'>();
  const { goto } = useRoutes();

  const { putEvents, isLoading } = useAwsEffect('EventBridge', ['putEvents'], { clientOverrides });

  const handleCreate = async (data: PutEventBridgeEventsRequest) => {
    if (await putEvents(data)) {
      goto(routes.RESOURCES_EVENT_BRIDGE_BUSES);
    }
  };

  return (
    <Layout
      documentTitle="EventBridge: Trigger Event"
      title={
        <Box>
          <Typography variant="h4">Trigger Event</Typography>
          <Breadcrumbs
            mappings={[
              ['EventBridge', () => goto(routes.RESOURCES_EVENT_BRIDGE)],
              ['Buses', () => goto(routes.RESOURCES_EVENT_BRIDGE_BUSES)],
              [busName, () => goto(routes.RESOURCES_EVENT_BRIDGE_BUS, { busName })],
              ['Trigger Event', null],
            ]}
          />
        </Box>
      }
      actions={
        <ProgressButton type="submit" form="update" variant="outlined" color="primary" loading={isLoading}>
          Submit
        </ProgressButton>
      }
    >
      <Card>
        <CardContent>
          <EventBridgeEventForm
            clientOverrides={clientOverrides}
            onCreate={handleCreate}
            loading={isLoading}
            formId="update"
          />
        </CardContent>
        <CardActions>
          <ProgressButton type="submit" form="update" variant="contained" color="primary" loading={isLoading}>
            Submit
          </ProgressButton>
        </CardActions>
      </Card>
    </Layout>
  );
};
