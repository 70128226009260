import { PERMISSION_GROUPS, PERMISSION_TYPES, ROLE_MAPPINGS, SUB_ACTIVE_STATUSES } from '@localstack/constants';
import { ConfirmableButton, NavLink, ProgressButton } from '@localstack/ui';
import { blue, red } from '@mui/material/colors';
import { Theme } from '@mui/material/styles';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import { FormEvent, ReactElement, Fragment, useCallback, useEffect, useMemo, useState } from 'react';

import { MembershipRole, MembershipState, OrganizationMember, Permission, Subscription } from '@localstack/types';

import {
  OrganizationsService,
  SubscriptionService,
  UserService,
  VALIDATION_RULES,
  buildRoute,
  hashEmail,
  useApiEffect,
  useApiGetter,
  computeNumLicensesUsed,
  numNonCIKeysUsed,
  useRoutes,
  TestMarkerSpan,
} from '@localstack/services';

import {
  DeleteOutlined as DeleteIcon,
  ArrowDropDown as DropDownIcon,
  ArrowDropUp as DropDownUpIcon,
  Email as EmailIcon,
  ExpandMore as ExpandMoreIcon,
  Email as MessageIcon,
  PersonOutline as UsernameIcon,
} from '@mui/icons-material';

import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Avatar,
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Checkbox,
  Divider,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormLabel,
  Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  Link,
  MenuItem,
  Select,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  Typography,
  Alert,
  Skeleton,
} from '@mui/material';

import classNames from 'classnames';

import { AppRoute } from '~/config';
import { useAuthProvider } from '~/hooks/useAuthProvider';

import { ContainedCustomerLayout } from '~/layouts';

import { AssignLicenseButton } from './components/TeamMembers/AssignLicenseButton';
import { ApiKeyTag } from './components/TeamMembers/ApiKeyTag';
import { LicenseTag } from './components/TeamMembers/LicenseTag';
import { ProStarterRenameInfo } from './components/Subscriptions/ProStarterRenameInfo';

const chipColors = {
  invited: blue[100],
  removed: red[100],
};

const PAGE_PROPS = {
  title: 'Users & Licenses',
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    accordion: {
      border: 'none',
      boxShadow: 'none',
      paddingLeft: 0,
      paddingRight: 0,
      '&::before': {
        display: 'none',
      },
    },
    accordionSummary: {
      padding: 0,
    },

    table: {
      '& > *': {
        textWrap: 'nowrap',
      },

      '& tbody tr:hover': {
        backgroundColor: theme.palette.action.hover,
      },
    },

    memberTable: {
      '& tr td': {
        padding: theme.spacing(1),
        paddingLeft: '0px',
        paddingRight: '0px',
      },
    },

    withRowPointer: {
      '& tbody tr:hover': {
        cursor: 'pointer',
      },
    },

    noHoverEffect: {
      '&:hover': {
        backgroundColor: 'unset !important',
        cursor: 'unset !important',
      },
    },

    avatar: {
      width: '2em',
      height: '2em',
      display: 'inline-flex',
      marginRight: '0.5em',
      border: `1px solid ${theme.palette.divider}`,
    },

    dropdownCell: {
      width: '2em',
    },

    inlineChip: {
      display: 'inline-block',
      borderRadius: '777px',
      padding: '0.2em',
      marginTop: '-0.2em',
      paddingLeft: '0.8em',
      paddingRight: '0.8em',
      marginBottom: '-0.2em',
      marginLeft: '0.5em',
      textWrap: 'nowrap',
      color: 'black',
      fontSize: '0.9em',
    },
    adminChip: {
      backgroundColor: theme.palette.info.main,
      color: theme.palette.common.white,
    },

    youChip: {
      backgroundColor: theme.palette.info.main,
      color: theme.palette.common.white,
    },

    nowrap: {
      textWrap: 'nowrap',
    },

    dangerButton: {
      color: theme.palette.error.contrastText,
      backgroundColor: theme.palette.error.main,
      '&:hover': {
        backgroundColor: theme.palette.error.dark,
      },
    },

    skeletonWord: {
      width: '10em',
      height: '1em',
      transform: 'unset',
    },

    skeletonUser: {
      width: '20em',
      height: '2em',
      transform: 'unset',
    },

    unassignedKeysSummary: {
      textAlign: 'center',
      color: theme.palette.text.disabled,
    },
  }),
);

export const TeamMembers = (): ReactElement => {
  const classes = useStyles();
  const { goto } = useRoutes();
  const { userInfo } = useAuthProvider();
  const user = userInfo?.user;
  const { can } = useAuthProvider();

  const { data: keys } = useApiGetter(SubscriptionService, 'listKeys', [false]);
  const { data: subscriptions, isLoading: isSubsLoading } = useApiGetter(SubscriptionService, 'listSubscriptions', []);
  const {
    updateMembership,
    removeMembership,
    isLoading: isUpdatingMembership,
  } = useApiEffect(OrganizationsService, ['updateMembership', 'removeMembership'], {
    revalidate: ['listOrganizations', 'listLicenseAssignments', 'preflightSelfAssignLicense'],
    revalidateOtherClient: { client: UserService, methods: ['getUser'] },
  });
  const { inviteMember, isLoading: isInvitingMember } = useApiEffect(OrganizationsService, ['inviteMember'], {
    revalidate: ['listOrganizations'],
    revalidateOtherClient: { client: UserService, methods: ['getUser'] },
  });

  const { data: licenseAssignments } = useApiGetter(
    OrganizationsService,
    'listLicenseAssignments',
    [userInfo?.org?.id || ''],
    { enable: !!userInfo?.org },
  );
  const { assignLicense, unassignLicense } = useApiEffect(OrganizationsService, ['assignLicense', 'unassignLicense'], {
    revalidate: ['listLicenseAssignments', 'preflightSelfAssignLicense'],
  });

  const [activeMember, setActiveMember] = useState<Optional<OrganizationMember>>(null);
  const [roles, setRoles] = useState<MembershipRole[]>([]);
  const [permissions, setPermissions] = useState<Permission[]>([]);
  const [inviteeName, setInviteeName] = useState<string>('');
  const [inviteeEmail, setInviteeEmail] = useState<string>('');
  const [showDeleted, setShowDeleted] = useState(false);

  const isOrgLoading = isUpdatingMembership;

  const isFormValid = inviteeName && inviteeEmail && VALIDATION_RULES.email.validate(inviteeEmail) === true;

  const editingSelf = user?.id === activeMember?.id;
  const editingInvited = activeMember?.state === MembershipState.INVITED;
  const editingRemoved = activeMember?.state === MembershipState.REMOVED;

  const isMemberRemovable = !editingSelf && !editingRemoved && can(Permission.DELETE_MEMBERS);
  const isMemberEditable = !editingRemoved && can(Permission.UPDATE_MEMBERS);

  const membersToShow = useMemo(
    () =>
      showDeleted
        ? userInfo?.org?.members || []
        : (userInfo?.org?.members || []).filter((m) => m.state !== MembershipState.REMOVED),
    [showDeleted, userInfo?.org?.members],
  );

  const defaultPermissions = useMemo(
    () =>
      Array.from(
        new Set(
          roles.reduce(
            (memo, role) => [...memo, ...ROLE_MAPPINGS[role as keyof typeof ROLE_MAPPINGS]],
            [] as Permission[],
          ),
        ),
      ),
    [roles],
  );

  const togglePermission = useCallback(
    (permission: Permission) => {
      if (permissions.includes(permission)) {
        setPermissions(permissions.filter((p) => p !== permission));
      } else {
        setPermissions([...permissions, permission]);
      }
    },
    [permissions],
  );

  const saveMember = useCallback(async () => {
    if (!activeMember || !userInfo?.org) return;

    await updateMembership(userInfo.org.id, activeMember.id, { roles, permissions } as OrganizationMember);
  }, [activeMember, roles, permissions]);

  useEffect(() => {
    if (!activeMember) {
      return;
    }

    setRoles(activeMember.roles);
    setPermissions((activeMember.permissions || []).map((p) => p as Permission));
  }, [activeMember]);

  const activeSubs: Subscription[] = (subscriptions ?? []).filter(({ status }) => SUB_ACTIVE_STATUSES.includes(status));

  const subscriptionKeys = (subscription: Subscription) =>
    keys?.org.filter((key) => key.subscription_id === subscription.id);

  const subIds = subscriptions ? subscriptions.map((sub) => sub.id) : [];

  const hasLicenseAssigned = (member: OrganizationMember): boolean =>
    licenseAssignments?.some((license) => license.user_id === member.id) || false;

  const toggleActiveMember = (member: OrganizationMember) => {
    if (activeMember && activeMember.id === member.id) {
      setActiveMember(null);
      return;
    }

    setActiveMember(member);
  };

  const licensesUsed = (subscription?: Subscription): number | undefined => {
    if (!subscription || !licenseAssignments || !keys) {
      return undefined;
    }

    return computeNumLicensesUsed(subscription, licenseAssignments, keys.org, membersToShow);
  };

  const licensesLeft = (subscription: Subscription): number | undefined => {
    const numLicensesUsed = licensesUsed(subscription);

    if (numLicensesUsed === undefined) {
      return undefined;
    }

    return subscription.seats - numLicensesUsed;
  };

  const canAssignLicense = (subscription: Subscription | undefined, member: OrganizationMember): boolean => {
    if (!subscription) {
      return false;
    }

    const left = licensesLeft(subscription);

    if (!!left && left > 0) {
      return true;
    }

    const subKeys = subscriptionKeys(subscription) || [];
    const nonCIKeys = subKeys.filter((k) => !k.is_ci);

    return nonCIKeys.some((key) => key.member_email === member.email);
  };

  const activeSubsIds = activeSubs?.map((sub) => sub.id) ?? [];
  const unassignedActiveSubNonCiKeys = (keys?.org || []).filter(
    (key) => !key.deleted && !key.is_ci && !key.member_email && activeSubsIds.includes(key.subscription_id ?? ''),
  );

  const [inviteeAutoAssignLicense, setInviteeAutoAssignLicense] = useState<boolean>(true);

  const handleInviteFormSubmit = useCallback(
    async (event: FormEvent) => {
      event.preventDefault();
      if (!userInfo?.org.id) {
        return;
      }

      await inviteMember(userInfo.org.id, {
        user_email: inviteeEmail,
        user_name: inviteeName,
        try_assign_license_on_join:
          activeSubs.length > 0 && inviteeAutoAssignLicense && can(Permission.ASSIGN_LICENSES),
      });

      setInviteeEmail('');
      setInviteeName('');
    },
    [inviteeEmail, inviteeName, inviteeAutoAssignLicense, activeSubs],
  );

  if (!can(Permission.READ_MEMBERS) || !can(Permission.READ_SUBSCRIPTIONS)) {
    return (
      <ContainedCustomerLayout {...PAGE_PROPS}>
        <Alert severity="error">
          You do not have the necessary permissions to view or manage users in this workspace. Reach out to your
          workspace admin to review permissions for your account.
        </Alert>
      </ContainedCustomerLayout>
    );
  }

  return (
    <ContainedCustomerLayout {...PAGE_PROPS}>
      <Grid container spacing={2}>
        {/* Licenses */}
        <Grid item xs={12}>
          <Card>
            <CardHeader title="Licenses" />
            <CardContent>
              <Grid item xs={12}>
                <Box mb={2}>
                  <Typography variant="body1">
                    Licenses are contained in plans. A license is required to use the LocalStack cloud emulator as well
                    as features on this website. Admin Tasks do not require a license. Go to the{' '}
                    <NavLink to={AppRoute.SETTINGS_SUBSCRIPTIONS}>Subscriptions</NavLink> page to manage your current
                    plans.
                  </Typography>
                </Box>
                <Box mb={2}>
                  <Typography variant="body1">
                    We are deprecating legacy developer API keys and are transitioning to auth tokens and licenses. If a
                    user has both a legacy API key and a license assigned, then this counts as only 1 license consumed.
                  </Typography>
                </Box>
                <Box mb={2}>
                  <Typography variant="body1">
                    Tokens for use in CI environments or other machine contexts are managed separately on the{' '}
                    <NavLink to={AppRoute.SETTINGS_AUTH_TOKENS}>Auth Tokens</NavLink> page.
                  </Typography>
                </Box>

                <Box style={{ overflowX: 'auto' }}>
                  <Table className={classes.table}>
                    <TableHead>
                      <TableRow>
                        <TableCell>Plan</TableCell>
                        <TableCell>Licenses Used</TableCell>
                      </TableRow>
                    </TableHead>

                    <TableBody>
                      {isSubsLoading ? (
                        <TableRow>
                          <TableCell>
                            <Skeleton className={classes.skeletonWord} />
                          </TableCell>
                          <TableCell>
                            <Skeleton className={classes.skeletonWord} />
                          </TableCell>
                        </TableRow>
                      ) : (
                        <>
                          {activeSubs.map((sub) => (
                            <TableRow key={sub.id}>
                              <TableCell>
                                <Link
                                  href={buildRoute(AppRoute.SETTINGS_SUBSCRIPTION_DETAIL, { subscriptionId: sub.id })}
                                  underline="hover"
                                >
                                  {sub.name}
                                </Link>
                              </TableCell>
                              <TableCell>
                                <TestMarkerSpan name="licenses_used">{licensesUsed(sub)}</TestMarkerSpan> of {sub.seats}{' '}
                                (including {numNonCIKeysUsed(sub, keys?.org ?? [])} legacy API keys)
                              </TableCell>
                            </TableRow>
                          ))}
                        </>
                      )}
                    </TableBody>
                  </Table>
                </Box>

                <ProStarterRenameInfo activeSubscriptions={activeSubs} mt={2} />

                {!isSubsLoading && activeSubs.length === 0 && (
                  <Box mt={2}>
                    <Alert severity="info" variant="outlined">
                      There are currently no active subscriptions in your workspace.{' '}
                      {
                        <Link onClick={() => goto(AppRoute.PRICING)} underline="hover">
                          Start a new plan.
                        </Link>
                      }
                    </Alert>
                  </Box>
                )}
              </Grid>
            </CardContent>
          </Card>
        </Grid>

        {/* User List */}
        <Grid item xs={12}>
          <Card>
            <CardHeader
              title="Workspace Members"
              action={
                <Box display="flex" alignItems="center">
                  <FormControlLabel
                    control={
                      <Switch
                        checked={showDeleted}
                        color="primary"
                        onChange={(_, checked) => setShowDeleted(checked)}
                      />
                    }
                    label="Show Removed"
                  />
                </Box>
              }
            />
            <CardContent>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Box style={{ overflowX: 'auto' }}>
                    <Table className={classNames(classes.table, classes.memberTable, classes.withRowPointer)}>
                      <TableHead>
                        <TableRow>
                          <TableCell>Name</TableCell>
                          <TableCell>Legacy API Key</TableCell>
                          <TableCell>License</TableCell>
                          <TableCell>{/* empty */}</TableCell>
                        </TableRow>
                      </TableHead>

                      <TableBody>
                        {isOrgLoading ? (
                          <>
                            <TableRow>
                              <TableCell colSpan={3}>
                                <Skeleton className={classes.skeletonUser} />
                              </TableCell>
                            </TableRow>

                            <TableRow>
                              <TableCell colSpan={3}>
                                <Skeleton className={classes.skeletonUser} />
                              </TableCell>
                            </TableRow>

                            <TableRow>
                              <TableCell colSpan={3}>
                                <Skeleton className={classes.skeletonUser} />
                              </TableCell>
                            </TableRow>
                          </>
                        ) : (
                          <>
                            {membersToShow.map((member) => (
                              <Fragment key={member.id}>
                                <TableRow key={member.id} onClick={() => toggleActiveMember(member)}>
                                  <TableCell>
                                    <Box display="flex" flexWrap="nowrap" alignItems="center">
                                      <Avatar
                                        className={classes.avatar}
                                        alt={member.name || ''}
                                        src={`https://www.gravatar.com/avatar/${hashEmail(member.email)}?d=db`}
                                      />

                                      <Box display="flex" flexDirection="column">
                                        <Box display="flex">
                                          <span>
                                            <b>{member.name}</b>
                                          </span>

                                          {member.id === user?.id && (
                                            <Box className={classNames(classes.inlineChip, classes.youChip)}>You</Box>
                                          )}

                                          {member.roles.includes(MembershipRole.ADMIN) && (
                                            <Box className={classNames(classes.inlineChip, classes.adminChip)}>
                                              Workspace Admin
                                            </Box>
                                          )}

                                          {member.state === MembershipState.REMOVED && (
                                            <Box
                                              className={classes.inlineChip}
                                              style={{ background: chipColors.removed }}
                                            >
                                              removed
                                            </Box>
                                          )}

                                          {member.state === MembershipState.INVITED && (
                                            <Box
                                              className={classes.inlineChip}
                                              style={{ background: chipColors.invited }}
                                            >
                                              {member.try_assign_license_on_join
                                                ? 'invited (automatically assign license when joining)'
                                                : 'invited'}
                                            </Box>
                                          )}
                                        </Box>
                                        <Box className={classes.nowrap}>{member.email}</Box>
                                      </Box>
                                    </Box>
                                  </TableCell>

                                  <TableCell>
                                    {keys && <ApiKeyTag keys={keys?.org} member={member} subscriptionIds={subIds} />}
                                  </TableCell>

                                  <TableCell>
                                    {licenseAssignments && subscriptions && (
                                      <LicenseTag
                                        licenseAssignments={licenseAssignments}
                                        member={member}
                                        subscriptions={subscriptions}
                                        unassignLicense={(assignmentId) =>
                                          unassignLicense(userInfo?.org.id, assignmentId)
                                        }
                                      />
                                    )}
                                    {!hasLicenseAssigned(member) && member.state === MembershipState.ACTIVE && (
                                      <AssignLicenseButton
                                        licenseInfo={
                                          activeSubs
                                            ? activeSubs.map((sub) => ({
                                                displayName: sub.name,
                                                subscription: sub,
                                                disabled: !canAssignLicense(sub, member),
                                              }))
                                            : []
                                        }
                                        onSelect={async (subscription) => {
                                          await assignLicense(userInfo?.org?.id, {
                                            user_id: member.id,
                                            subscription_id: subscription.id,
                                          });
                                        }}
                                      />
                                    )}
                                  </TableCell>
                                  <TableCell className={classes.dropdownCell}>
                                    <IconButton onClick={() => toggleActiveMember(member)} size="large">
                                      {activeMember && activeMember.id === member.id ? (
                                        <DropDownUpIcon />
                                      ) : (
                                        <DropDownIcon />
                                      )}
                                    </IconButton>
                                  </TableCell>
                                </TableRow>

                                {/* Edit User */}
                                {activeMember && activeMember.id === member.id && (
                                  <TableRow key={`${member.id}-edit`} className={classes.noHoverEffect}>
                                    <TableCell colSpan={4}>
                                      <Box
                                        mt={2}
                                        mb={2}
                                        display="flex"
                                        style={{ rowGap: '1em', flexDirection: 'column' }}
                                      >
                                        <Grid item xs={12}>
                                          <Card variant="outlined">
                                            <CardHeader title="Role and Permission" />
                                            <CardContent>
                                              {/* Membership Role */}
                                              <Box>
                                                <FormControl variant="outlined" fullWidth required>
                                                  <InputLabel>Member Roles</InputLabel>
                                                  <Select
                                                    variant="standard"
                                                    multiple
                                                    value={roles}
                                                    label="Member Roles"
                                                    disabled={!isMemberEditable || editingSelf}
                                                    onChange={({ target }) =>
                                                      setRoles(target.value as MembershipRole[])
                                                    }
                                                  >
                                                    <MenuItem value={MembershipRole.ADMIN}>Admin</MenuItem>,
                                                    <MenuItem value={MembershipRole.MEMBER}>Member</MenuItem>,
                                                  </Select>
                                                </FormControl>
                                              </Box>

                                              {/* Advanced Permissions */}
                                              <Accordion className={classes.accordion}>
                                                <AccordionSummary
                                                  className={classes.accordionSummary}
                                                  expandIcon={<ExpandMoreIcon />}
                                                >
                                                  <Typography>Advanced Permissions</Typography>
                                                </AccordionSummary>
                                                <Divider />
                                                <AccordionDetails classes={{ root: classes.accordionSummary }}>
                                                  <Box display="flex" flexWrap="wrap" mt={2} style={{ rowGap: '1em' }}>
                                                    {Object.entries(PERMISSION_GROUPS).map(([title, perms]) => (
                                                      <Grid xs={12} sm={6} key={title}>
                                                        <FormGroup>
                                                          <FormLabel component="legend">{title}</FormLabel>
                                                          {perms.map((permission) => (
                                                            <div key={permission}>
                                                              <FormControlLabel
                                                                label={
                                                                  PERMISSION_TYPES[
                                                                    permission as keyof typeof PERMISSION_TYPES
                                                                  ]
                                                                }
                                                                disabled={
                                                                  defaultPermissions.includes(permission) ||
                                                                  !isMemberEditable
                                                                }
                                                                checked={
                                                                  defaultPermissions.includes(permission) ||
                                                                  permissions.includes(permission)
                                                                }
                                                                onChange={() => togglePermission(permission)}
                                                                control={<Checkbox />}
                                                              />
                                                            </div>
                                                          ))}
                                                        </FormGroup>
                                                      </Grid>
                                                    ))}
                                                  </Box>
                                                </AccordionDetails>
                                              </Accordion>
                                              <Box mt={1} textAlign="right" width="100%">
                                                <ProgressButton
                                                  color="primary"
                                                  variant="contained"
                                                  loading={isOrgLoading}
                                                  disabled={roles.length === 0 || !isMemberEditable}
                                                  type="submit"
                                                  onClick={saveMember}
                                                >
                                                  Save
                                                </ProgressButton>
                                              </Box>
                                            </CardContent>
                                          </Card>
                                        </Grid>

                                        {/* Membership Actions */}
                                        {!editingSelf && (
                                          <Grid item xs={12}>
                                            <Card variant="outlined">
                                              <CardHeader title="Membership Actions" />
                                              <CardContent>
                                                <Box display="flex" style={{ columnGap: '1em' }}>
                                                  {(editingInvited || editingRemoved) && (
                                                    <Button
                                                      size="small"
                                                      color="primary"
                                                      variant="contained"
                                                      startIcon={<MessageIcon />}
                                                      disabled={!can(Permission.CREATE_MEMBERS) || isOrgLoading}
                                                      onClick={() =>
                                                        inviteMember(userInfo?.org?.id || '', {
                                                          user_email: activeMember.email,
                                                          user_name: activeMember.name,
                                                        })
                                                      }
                                                    >
                                                      Resend Invitation
                                                    </Button>
                                                  )}
                                                  {isMemberRemovable && (
                                                    <ConfirmableButton
                                                      componentType="Button"
                                                      okText="Remove"
                                                      cancelText="Cancel"
                                                      title="Remove User"
                                                      text={`Do you want to remove the
                                                        user ${member.name} (${member.email}) from this workspace?`}
                                                      size="small"
                                                      variant="contained"
                                                      className={classes.dangerButton}
                                                      startIcon={<DeleteIcon />}
                                                      disabled={!can(Permission.DELETE_MEMBERS) || isOrgLoading}
                                                      onClick={async () => {
                                                        if (userInfo?.org) {
                                                          await removeMembership(userInfo?.org?.id, activeMember.id);
                                                          setActiveMember(null);
                                                        }
                                                      }}
                                                    >
                                                      Remove from Workspace
                                                    </ConfirmableButton>
                                                  )}
                                                </Box>
                                              </CardContent>
                                            </Card>
                                          </Grid>
                                        )}
                                      </Box>
                                    </TableCell>
                                  </TableRow>
                                )}
                              </Fragment>
                            ))}

                            {unassignedActiveSubNonCiKeys.length > 0 && (
                              <TableRow>
                                <TableCell colSpan={4} className={classes.unassignedKeysSummary}>
                                  {unassignedActiveSubNonCiKeys.length} unassigned legacy API key(s) not shown in list
                                </TableCell>
                              </TableRow>
                            )}
                          </>
                        )}
                      </TableBody>
                    </Table>
                  </Box>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Grid>

        {/* Invite user to Workspace */}
        {can(Permission.CREATE_MEMBERS) && (
          <Grid item xs={12}>
            <Card>
              <CardHeader title="Invite User" />
              <CardContent>
                <Grid item xs={12}>
                  <Box mt={2} mb={2}>
                    <Typography variant="body1">
                      Invite a user into your workspace. If the user does not have a LocalStack account yet, we will
                      invite them to create one first.
                    </Typography>
                  </Box>
                  <form onSubmit={handleInviteFormSubmit}>
                    <Grid item container spacing={2} xs={12} direction="column">
                      <Grid item xs={12} sm={6}>
                        <TextField
                          placeholder="Name"
                          size="small"
                          variant="outlined"
                          fullWidth
                          value={inviteeName}
                          onChange={({ target }) => setInviteeName(target.value)}
                          InputProps={{
                            startAdornment: (
                              <InputAdornment position="start">
                                <UsernameIcon />
                              </InputAdornment>
                            ),
                          }}
                        />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <TextField
                          placeholder="Email"
                          size="small"
                          variant="outlined"
                          fullWidth
                          value={inviteeEmail}
                          onChange={({ target }) => setInviteeEmail(target.value)}
                          error={!!inviteeEmail && VALIDATION_RULES.email.validate(inviteeEmail) !== true}
                          InputProps={{
                            startAdornment: (
                              <InputAdornment position="start">
                                <EmailIcon />
                              </InputAdornment>
                            ),
                          }}
                        />
                      </Grid>

                      {activeSubs.length > 0 && (
                        <>
                          <Grid item xs={12} sm={6}>
                            <FormControlLabel
                              control={
                                <Checkbox
                                  color="primary"
                                  onChange={(_, value) => setInviteeAutoAssignLicense(value)}
                                  checked={inviteeAutoAssignLicense}
                                />
                              }
                              label={
                                <>
                                  <Box>
                                    Automatically assign a license when the user joins (subject to availability)
                                  </Box>
                                </>
                              }
                            />
                          </Grid>
                        </>
                      )}

                      <Grid item>
                        <ProgressButton
                          variant="contained"
                          color="primary"
                          size="small"
                          type="submit"
                          disabled={!isFormValid || isOrgLoading}
                          loading={isInvitingMember}
                        >
                          Invite to Workspace
                        </ProgressButton>
                      </Grid>
                    </Grid>
                  </form>
                </Grid>
              </CardContent>
            </Card>
          </Grid>
        )}
      </Grid>
    </ContainedCustomerLayout>
  );
};
