import { ReactElement } from 'react';
import { useRoutes, useAwsGetter } from '@localstack/services';
import { useParams } from 'react-router-dom';
import { Card, CardContent, Button, Box, Typography } from '@mui/material';
import { Breadcrumbs, IAMUserDetails as Details } from '@localstack/ui';

import { DEFAULT_IAM_ROUTES } from '@localstack/constants/src';

import { NavTabs } from './components';
import { IAMProps } from './types';

export const IAMUserDetails = ({ Layout, clientOverrides, routes = DEFAULT_IAM_ROUTES }: IAMProps): ReactElement => {
  const { goto } = useRoutes();
  const { userName } = useParams<'userName'>();

  const { data: user } = useAwsGetter('IAM', 'getUser', [{ UserName: userName }], { clientOverrides });

  return (
    <Layout
      documentTitle="IAM: User Details"
      tabs={<NavTabs routes={routes} />}
      title={
        <Box>
          <Typography variant="h4">User Details</Typography>
          <Breadcrumbs
            mappings={[
              ['IAM', () => goto(routes.RESOURCES_IAM)],
              ['Users', () => goto(routes.RESOURCES_IAM)],
              [user?.User?.UserName, null],
            ]}
          />
        </Box>
      }
      actions={<Button onClick={() => goto(routes.RESOURCES_IAM_USER_UPDATE, { userName })}>Edit User</Button>}
    >
      <Card>
        <CardContent>
          <Details user={user?.User} />
        </CardContent>
      </Card>
    </Layout>
  );
};
