import { ReactElement } from 'react';
import { Controller, Control, ControllerProps } from 'react-hook-form';
import { Autocomplete, AutocompleteProps, BaseTextFieldProps } from '@mui/material';

// eslint-disable-next-line
export type ControlledAutocompleteProps = AutocompleteProps<any, any, any, any> &
  Omit<BaseTextFieldProps, 'autoComplete'> &
  Omit<ControllerProps, 'control' | 'render'> & {
    control: Control<any>; // eslint-disable-line
    defaultValue?: any; // eslint-disable-line
    getOptionValue: (option: any) => any; // eslint-disable-line
    getValueOption: (value: any) => any; // eslint-disable-line
  };

export const ControlledAutocomplete = ({
  control,
  getOptionValue,
  getValueOption,
  defaultValue,
  ...rest
}: ControlledAutocompleteProps): ReactElement => (
  <Controller
    {...rest}
    defaultValue={defaultValue}
    control={control}
    render={({ field }) => (
      <Autocomplete
        {...rest}
        {...field}
        value={getValueOption(field.value)}
        ChipProps={{
          color: 'primary',
        }}
        onChange={(_, value) => {
          const event = {
            target: {
              name: field.name,
              value: value ? getOptionValue(value) : undefined,
            },
          };
          field.onChange(event);
        }}
      />
    )}
  />
);

export default ControlledAutocomplete;
