import { ReactElement, useMemo } from 'react';
import { S3Object } from '@localstack/types';
import { Link, Box } from '@mui/material';
import { Folder as FolderIcon } from '@mui/icons-material';
import { getSchema } from '@localstack/services';

import { MagicTable } from '../../../magic/MagicTable';

export type S3ObjectsTableProps = {
  prefix: string;
  selectable?: boolean;
  downloadable?: boolean;
  objects: S3Object[];
  onViewObject?: (name: string) => void;
  onDownloadObject?: (key: string) => void;
  onSelect?: (keys: string[]) => void;
};

export const S3ObjectsTable = ({
  prefix,
  objects,
  selectable = true,
  downloadable = true,
  onViewObject,
  onDownloadObject,
  onSelect,
}: S3ObjectsTableProps): ReactElement => {
  const currentLevelObjects = useMemo(
    () =>
      objects.map((obj) => {
        // we are in a sub-folder
        if (prefix && obj.Key?.startsWith(prefix)) {
          const prefixToUse = prefix.startsWith('/') ? prefix : `/${prefix}`;
          const remainingPart = obj.Key.substring(prefixToUse.length) ?? '';
          const isFolder = remainingPart.split('/').length > 1;

          if (isFolder) return { Key: `${prefix}/${remainingPart.split('/')[0]}`, Size: 0 };
          return obj;
        }

        // we are under root
        const isFolder = (obj.Key?.split('/')?.length ?? 0) > 1;
        if (isFolder) return { Key: `${obj.Key?.split('/')?.[0]}`, Size: 0 };

        return obj;
      }),
    [objects, prefix],
  );

  const sortedObjects = useMemo(
    () =>
      [...currentLevelObjects]
        // sort objects by name
        .sort((lo, ro) => {
          if ((lo.Key || '') < (ro.Key || '')) return -1;
          if ((ro.Key || '') < (lo.Key || '')) return 1;
          return 0;
        })
        // keep folders on top
        .sort((lo, ro) => {
          if (lo.Size === 0 && ro.Size !== 0) return -1;
          if (ro.Size === 0 && lo.Size !== 0) return 1;
          return 0;
        }),
    [currentLevelObjects, prefix],
  );

  return (
    <MagicTable
      schema={getSchema('S3')}
      entry="ObjectList"
      idAttribute="Key"
      rows={sortedObjects.filter((obj) => obj.LastModified)}
      selectable={selectable}
      filterColumns={['Key', 'Size', 'LastModified']}
      onSelect={onSelect}
      externalFields={{
        Key: (row) => (
          <>
            {row.Size === 0 && (
              <Link
                onClick={(e: React.MouseEvent<HTMLElement>) => {
                  e.stopPropagation();
                  return onViewObject && onViewObject(row.Key as string);
                }}
                underline="hover"
              >
                <Box alignItems="center" display="flex">
                  <FolderIcon />
                  <Box mr={1} />
                  {(row.Key as string).split('/').slice(-1)[0]}
                </Box>
              </Link>
            )}
            {(row.Size as number) > 0 && downloadable && (
              <Link onClick={() => onDownloadObject && onDownloadObject(row.Key as string)} underline="hover">
                {(row.Key as string).split('/').slice(-1)[0]}
              </Link>
            )}
            {(row.Size as number) > 0 && !downloadable && (row.Key as string).split('/').slice(-1)[0]}
          </>
        ),
      }}
    />
  );
};
