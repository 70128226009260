import { ReactElement } from 'react';
import { AttachIAMRolePolicyRequest } from '@localstack/types';
import { AwsClientOverrides, buildRoute, getSchema } from '@localstack/services';
import { DEFAULT_IAM_ROUTES } from '@localstack/constants';

import { MagicForm } from '../../../magic/MagicForm';
import { RelatedResourcePicker } from '../../../../form';

export interface IAMRoleAttachPolicyFormProps {
  roleName?: string;
  loading?: boolean;
  formId?: string;
  clientOverrides?: AwsClientOverrides;
  onUpdate: (data: AttachIAMRolePolicyRequest) => unknown;
}

export const IAMRoleAttachPolicyForm = ({
  roleName,
  loading,
  formId,
  clientOverrides,
  onUpdate,
}: IAMRoleAttachPolicyFormProps): ReactElement => (
  <MagicForm
    data={{
      RoleName: roleName,
    }}
    schema={getSchema('IAM')}
    loading={loading}
    entry="AttachRolePolicyRequest"
    formId={formId}
    fieldConditions={{
      RoleName: false,
    }}
    externalFields={{
      '^PolicyArn$': (control, name, required) => (
        <RelatedResourcePicker
          required={required}
          control={control}
          fieldName={name}
          entityName="IAM Policy"
          creationRoute={buildRoute(DEFAULT_IAM_ROUTES.RESOURCES_IAM_POLICY_CREATE)}
          client="IAM"
          method="listPolicies"
          arrayKeyName="Policies"
          property="Arn"
          clientOverrides={clientOverrides}
        />
      ),
    }}
    onSubmit={(data: AttachIAMRolePolicyRequest) => onUpdate(data)}
  />
);
