import { useState, ReactElement } from 'react';
import { FormControlLabel, Switch, Typography, Box } from '@mui/material';
import { AwsClientOverrides, getSchema } from '@localstack/services';

import { KafkaCluster, CreateKafkaClusterParams, UpdateKafkaClusterConfigurationParams } from '@localstack/types';
import { DEFAULT_KMS_ROUTES, DEFAULT_S3_ROUTES } from '@localstack/constants';

import { MagicForm } from '../../magic/MagicForm';
import { RelatedResourcePicker } from '../../../form/RelatedResourcePicker';

export interface KafkaClusterFormProps {
  cluster?: Optional<Partial<KafkaCluster>>;
  loading?: boolean;
  formId?: string;
  clientOverrides?: AwsClientOverrides;
  onCreate: (data: CreateKafkaClusterParams) => unknown;
  onUpdate: (data: UpdateKafkaClusterConfigurationParams) => unknown;
}

const ExtendedConfigCheckbox = ({
  label,
  enabled,
  onChange,
}: {
  label: string;
  enabled: boolean;
  onChange: (checked: boolean) => unknown;
}) => (
  <FormControlLabel
    label={label}
    control={<Switch color="primary" checked={enabled} onChange={(_e, checked) => onChange(checked)} />}
  />
);

export const KafkaClusterForm = ({
  cluster,
  loading,
  formId,
  clientOverrides,
  onCreate,
  onUpdate,
}: KafkaClusterFormProps): ReactElement => {
  const [enableConfiguration, setEnableConfiguration] = useState(false);
  const [enableEncryption, setEnableEncryption] = useState(false);
  const [enableStorage, setEnableStorage] = useState(false);
  const [enableAuth, setEnableAuth] = useState(false);
  const [enableMonitoring, setEnableMonitoring] = useState(false);
  const [enableLogging, setEnableLogging] = useState(false);

  return (
    <>
      <Typography variant="subtitle1">Extended Configurations</Typography>
      <Typography variant="caption">Switch to display additional configurations</Typography>
      <Box mb={1}>
        <ExtendedConfigCheckbox label="Configuration" enabled={enableConfiguration} onChange={setEnableConfiguration} />
        <ExtendedConfigCheckbox label="Encryption" enabled={enableEncryption} onChange={setEnableEncryption} />
        <ExtendedConfigCheckbox label="Storage" enabled={enableStorage} onChange={setEnableStorage} />
        <ExtendedConfigCheckbox label="Client Authentication" enabled={enableAuth} onChange={setEnableAuth} />
        <ExtendedConfigCheckbox label="Monitoring" enabled={enableMonitoring} onChange={setEnableMonitoring} />
        <ExtendedConfigCheckbox label="Logging" enabled={enableLogging} onChange={setEnableLogging} />
      </Box>
      <MagicForm
        data={cluster}
        schema={getSchema('Kafka')}
        loading={loading}
        entry={cluster ? 'UpdateClusterConfigurationRequest' : 'CreateClusterRequest'}
        formId={formId}
        fieldConditions={{
          '^ClusterArn$': !cluster,
          '^ConfigurationInfo': enableConfiguration,
          '^EncryptionInfo': enableEncryption,
          '^BrokerNodeGroupInfo\\.StorageInfo': enableStorage,
          '^ClientAuthentication': enableAuth,
          '^OpenMonitoring': enableMonitoring,
          '^LoggingInfo': enableLogging,
        }}
        externalFields={{
          'S3.Bucket$': (control, fieldName, required) => (
            <RelatedResourcePicker
              control={control}
              client="S3"
              method="listBuckets"
              arrayKeyName="Buckets"
              property="Name"
              fieldName={fieldName}
              entityName="S3 Bucket"
              creationRoute={DEFAULT_S3_ROUTES.RESOURCES_S3_BUCKET_CREATE}
              required={required}
              clientOverrides={clientOverrides}
            />
          ),

          KMSKeyId$: (control, fieldName, required) => (
            <RelatedResourcePicker
              control={control}
              client="KMS"
              method="listKeys"
              arrayKeyName="Keys"
              property="KeyArn"
              fieldName={fieldName}
              entityName="KMS Key"
              creationRoute={DEFAULT_KMS_ROUTES.RESOURCES_KMS_KEY_CREATE}
              required={required}
              clientOverrides={clientOverrides}
            />
          ),
        }}
        onSubmit={(data: CreateKafkaClusterParams | UpdateKafkaClusterConfigurationParams) => {
          if (!cluster) return onCreate(data as CreateKafkaClusterParams);
          return onUpdate({
            ...data,
            ClusterArn: cluster.ClusterArn as string,
          } as UpdateKafkaClusterConfigurationParams);
        }}
      />
    </>
  );
};
