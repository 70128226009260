import { DictWithList } from '@localstack/types';

export const mergeDictWithListValues = <T>(obj1: DictWithList<T>, obj2: DictWithList<T>): DictWithList<T> =>
  Object.entries({ ...obj1, ...obj2 }).reduce(
    (acc, [key, _value]) => {
      acc[key] = (obj1[key] || []).concat(obj2[key] || []);
      return acc;
    },
    {} as { [key: string]: T[] },
  );

export const removeEmptyProperties = (obj: object): object => {
  const isFileOrBlob = (value: unknown): value is File | Blob => value instanceof File || value instanceof Blob;

  Object.keys(obj).forEach((key: keyof typeof obj) => {
    if (typeof obj[key] === 'undefined') {
      delete obj[key];
    } else if (typeof obj[key] === 'object' && obj[key] !== null && !isFileOrBlob(obj[key])) {
      removeEmptyProperties(obj[key]);
      if (!Object.keys(obj[key]).length) {
        delete obj[key];
      }
    }
  });
  return obj;
};
