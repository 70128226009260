import { ReactElement } from 'react';
import { Card, CardContent, CardActions, Box, Typography } from '@mui/material';
import { ProgressButton, Breadcrumbs, ECSTaskDefinitionForm } from '@localstack/ui';
import { RegisterECSTaskDefinitionParams } from '@localstack/types';
import { useRoutes, useAwsEffect } from '@localstack/services';

import { DEFAULT_ECS_ROUTES } from '@localstack/constants';

import { ECSProps } from './types';

export const ECSTaskDefinitionUpsert = ({
  Layout,
  clientOverrides,
  routes = DEFAULT_ECS_ROUTES,
}: ECSProps): ReactElement => {
  const { goto } = useRoutes();

  const { registerTaskDefinition, isLoading: isTaskdefMutating } = useAwsEffect('ECS', ['registerTaskDefinition'], {
    revalidate: ['listTaskDefinitions', 'describeTaskDefinition'],
    clientOverrides,
  });

  const handleCreate = async (data: RegisterECSTaskDefinitionParams) => {
    if (await registerTaskDefinition(data)) {
      goto(routes.RESOURCES_ECS_TASK_DEFINITIONS);
    }
  };

  return (
    <Layout
      documentTitle="Task Definition Details"
      title={
        <Box>
          <Typography variant="h4">Task Definition Details</Typography>
          <Breadcrumbs
            mappings={[
              ['ECS', () => goto(routes.RESOURCES_ECS)],
              ['Task Definitions', () => goto(routes.RESOURCES_ECS_TASK_DEFINITIONS)],
              ['Register Task Definition', null],
            ]}
          />
        </Box>
      }
      actions={
        <ProgressButton type="submit" form="create" variant="outlined" color="primary" loading={isTaskdefMutating}>
          Submit
        </ProgressButton>
      }
    >
      <Card>
        <CardContent>
          <ECSTaskDefinitionForm
            loading={isTaskdefMutating}
            formId="create"
            clientOverrides={clientOverrides}
            onCreate={handleCreate}
          />
        </CardContent>
        <CardActions>
          <ProgressButton type="submit" form="create" variant="contained" color="primary" loading={isTaskdefMutating}>
            Submit
          </ProgressButton>
        </CardActions>
      </Card>
    </Layout>
  );
};
