import { getSchema } from '@localstack/services';
import { GetEnvironmentOutput } from '@localstack/types';
import { ReactElement } from 'react';

import { MagicDetails } from '../../magic/MagicDetails';

export type MWAAEnvironmentDetailProps = {
  environment?: Optional<Partial<GetEnvironmentOutput>>;
};

export const MWAAEnvironmentDetail = ({ environment }: MWAAEnvironmentDetailProps): ReactElement => (
  <MagicDetails
    data={environment?.Environment}
    schema={getSchema('MWAA')}
    entry="Environment"
    title="MWAA Environment Details"
  />
);
