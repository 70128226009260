import { ReactElement, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { CircularProgress } from '@mui/material';
import { UserService, useApiEffect, useSnackbar } from '@localstack/services';

import { AppRoute } from '~/config';

import { BaseActivationOld } from './components/BaseActivationOld';

const SUCCESS_MESSAGE = 'We have sent you an email with temporary credentials';
const ERROR_MESSAGE = 'There was an error resetting the password';

interface Params {
  userId: string;
  key: string;
}

export const PasswordResetActivation = (): ReactElement => {
  const navigate = useNavigate();
  const { showSnackbar } = useSnackbar();
  const { userId: user_id, key: recovery_key } = useParams<'userId' | 'key'>() as Params;
  const { confirmPasswordReset } = useApiEffect(UserService, ['confirmPasswordReset']);

  useEffect(() => {
    const resetPassword = async () => {
      try {
        await confirmPasswordReset({ user_id, recovery_key });
        showSnackbar({ severity: 'success', message: SUCCESS_MESSAGE });
        navigate(AppRoute.SIGN_IN);
      } catch (_e) {
        showSnackbar({ severity: 'error', message: ERROR_MESSAGE });
      }
    };
    resetPassword();
  }, [user_id, recovery_key]);

  return <BaseActivationOld message={<CircularProgress />} activated />;
};

export default PasswordResetActivation;
