import { ReactElement } from 'react';
import { Box, Card, Link, CardContent, Grid, Typography, Alert } from '@mui/material';
import {
  LeadsService,
  satisfiesVersionConstraint,
  useApiEffect,
  useAwsEffect,
  useAwsGetter,
  useLocalstackStatus,
  useSnackbar,
} from '@localstack/services';
import { FeatureMaturityLevel, LocalStackChaosFaults, LocalStackChaosNetworkEffect } from '@localstack/types';

import { InstanceViewProps } from '../props';

import {
  InternalServerErrorCard,
  LatencyCard,
  RegionUnavailableCard,
  ServiceUnavailableCard,
  DynamodbErrorCard,
  KinesisErrorCard,
} from './components/experiments';
import { EXPERIMENT_NAMES } from './constants';

const MIN_REQUIRED_VERSION = '3.6.0';

export const FISExperiments = ({ Layout, clientOverrides = {} }: InstanceViewProps): ReactElement => {
  const { showSnackbar } = useSnackbar();

  const { running: isLocalStackRunning, version } = useLocalstackStatus(clientOverrides);

  const { data: chaosFaults } = useAwsGetter('LocalStack', 'getChaosFaults', undefined, { clientOverrides });

  const { data: chaosNetworkEffects, hasError } = useAwsGetter(
    'LocalStack',
    'getChaosNetworkEffects',
    undefined,
    { silentErrors: true, swrOverrides: { shouldRetryOnError: false }, clientOverrides },
    // when chaos-basic is loaded, getChaosNetworkEffects returns 404 as its not available
    // swrOverrides are set to prevent retries
  );
  const isNotEnterpriseSub = hasError;

  const { createChaosEngineeringLead } = useApiEffect(LeadsService, ['createChaosEngineeringLead'], {
    suppressErrors: true,
  });

  const { addChaosNetworkEffect } = useAwsEffect('LocalStack', ['addChaosNetworkEffect'], {
    revalidate: ['getChaosNetworkEffects'],
    clientOverrides,
  });

  const { addChaosFaults, deleteChaosFaults } = useAwsEffect('LocalStack', ['addChaosFaults', 'deleteChaosFaults'], {
    revalidate: ['getChaosFaults'],
    clientOverrides,
  });

  const createLead = async (faultName: string) => {
    const res = await createChaosEngineeringLead({ chaos_fault_name: faultName });
    if (res) {
      showSnackbar({
        message: "We'll reach out to you soon",
        severity: 'success',
      });
    } else {
      showSnackbar({
        message: 'Something went wrong. Please try again later',
        severity: 'error',
      });
    }
  };

  const onUpsertFault = async (template: LocalStackChaosFaults, existingExperiment: LocalStackChaosFaults) => {
    await deleteChaosFaults(existingExperiment);
    await addChaosFaults(template);
  };

  const onUpsertNetworkEffect = async (template: LocalStackChaosNetworkEffect) => {
    await addChaosNetworkEffect(template);
  };

  const onStopFault = async (existingExperiment: LocalStackChaosFaults) => {
    await deleteChaosFaults(existingExperiment);
  };

  const onStopNetworkEffect = async (existingExperiment: LocalStackChaosNetworkEffect) => {
    // for now, there's only latency which has to be set to 0 for the experiment to be stopped
    await addChaosNetworkEffect({ ...existingExperiment, latency: 0 });
  };

  const LocalStackNotRunningAlert = !isLocalStackRunning
    ? 'LocalStack is not running. Start LocalStack to use this feature.'
    : undefined;
  const hasVersionMismatch = !satisfiesVersionConstraint(version || '', MIN_REQUIRED_VERSION);
  const VersionMismatchAlert = hasVersionMismatch
    ? `This feature requires version ${MIN_REQUIRED_VERSION} or higher.`
    : undefined;
  const alertMessage = LocalStackNotRunningAlert || VersionMismatchAlert;

  const experiments = [
    {
      id: EXPERIMENT_NAMES.dynamodbError,
      content: (
        <DynamodbErrorCard
          onUpsertExperiment={onUpsertFault}
          onStopExperiment={onStopFault}
          alert={alertMessage}
          createLead={createLead}
          experiment={chaosFaults?.filter((fault) => fault.description === EXPERIMENT_NAMES.dynamodbError) || []}
        />
      ),
    },
    {
      id: EXPERIMENT_NAMES.kinesisError,
      content: (
        <KinesisErrorCard
          onUpsertExperiment={onUpsertFault}
          onStopExperiment={onStopFault}
          alert={alertMessage}
          createLead={createLead}
          experiment={chaosFaults?.filter((fault) => fault.description === EXPERIMENT_NAMES.kinesisError) || []}
        />
      ),
    },
    {
      id: EXPERIMENT_NAMES.internalServerError,
      content: (
        <InternalServerErrorCard
          onUpsertExperiment={onUpsertFault}
          onStopExperiment={onStopFault}
          alert={alertMessage}
          createLead={createLead}
          experiment={chaosFaults?.filter((fault) => fault.description === EXPERIMENT_NAMES.internalServerError) || []}
          disableAndShowCta={isNotEnterpriseSub}
        />
      ),
    },
    {
      id: EXPERIMENT_NAMES.serviceUnavailableError,
      content: (
        <ServiceUnavailableCard
          onUpsertExperiment={onUpsertFault}
          onStopExperiment={onStopFault}
          alert={alertMessage}
          createLead={createLead}
          experiment={
            chaosFaults?.filter((fault) => fault.description === EXPERIMENT_NAMES.serviceUnavailableError) || []
          }
          disableAndShowCta={isNotEnterpriseSub}
        />
      ),
    },
    {
      id: EXPERIMENT_NAMES.regionUnavailableError,
      content: (
        <RegionUnavailableCard
          onUpsertExperiment={onUpsertFault}
          onStopExperiment={onStopFault}
          alert={alertMessage}
          createLead={createLead}
          experiment={
            chaosFaults?.filter((fault) => fault.description === EXPERIMENT_NAMES.regionUnavailableError) || []
          }
          disableAndShowCta={isNotEnterpriseSub}
        />
      ),
    },
    {
      id: EXPERIMENT_NAMES.latencyError,
      content: (
        <LatencyCard
          onUpsertExperiment={onUpsertNetworkEffect}
          onStopExperiment={onStopNetworkEffect}
          alert={alertMessage}
          createLead={createLead}
          experiment={chaosNetworkEffects || {}}
          disableAndShowCta={isNotEnterpriseSub}
        />
      ),
    },
  ];

  return (
    <Layout title="Chaos Engineering" planFamily={FeatureMaturityLevel.PREVIEW}>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Card>
            <CardContent>
              <Typography>
                Run controlled fault injection experiments to test resilience and performance.{' '}
                <Link
                  href="https://docs.localstack.cloud/user-guide/chaos-engineering/"
                  underline="always"
                  target="_blank"
                >
                  Learn more
                </Link>
              </Typography>
              {alertMessage && (
                <Box mt={2}>
                  <Box>
                    <Alert severity="error" style={{ width: '100%' }}>
                      {alertMessage}
                    </Alert>
                  </Box>
                </Box>
              )}
              {experiments.map((experiment) => (
                <Box mt={3} key={experiment.id}>
                  {experiment.content}
                </Box>
              ))}
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </Layout>
  );
};
