import { Palette } from '@mui/material/styles';

import { MuiThemeComponentsType } from '../types';

export default (palette: Palette): MuiThemeComponentsType['MuiIconButton'] => ({
  variants: [
    {
      props: { size: 'xsmall' },
      style: {
        padding: '1.5px',
        fontSize: '1rem',
      },
    },
    {
      props: { size: 'small' },
      style: {
        padding: '3px',
      },
    },
    {
      props: { size: 'medium' },
      style: {
        padding: '10px',
      },
    },
    {
      props: { size: 'large' },
      style: {
        padding: '10px',
      },
    },
    {
      props: { color: 'default' },
      style: {
        color: palette.text.secondary,
        '&:hover': {
          backgroundColor: 'rgba(0, 0, 0, 0.03)',
        },
      },
    },
  ],
});
