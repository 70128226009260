import { ReactElement } from 'react';
import { AgwApi } from '@localstack/types';
import { getSchema } from '@localstack/services';

import { MagicDetails } from '../../../magic/MagicDetails';

export interface AgwApiDetailsProps {
  api?: Optional<Partial<AgwApi>>;
}

export const AgwApiDetails = ({ api }: AgwApiDetailsProps): ReactElement => (
  <MagicDetails data={api} schema={getSchema('ApiGatewayV2')} entry="Api" title="API Details" />
);
