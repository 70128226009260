import { ReactElement } from 'react';
import { RDSInstance, CreateRDBParams, UpdateRDBParams } from '@localstack/types';
import { AwsClientOverrides, getSchema } from '@localstack/services';
import { DEFAULT_IAM_ROUTES, DEFAULT_KMS_ROUTES } from '@localstack/constants';

import { RelatedResourcePicker } from '../../../form/RelatedResourcePicker';
import { MagicForm } from '../../magic/MagicForm';

export interface RDSInstanceFormProps {
  cluster?: Optional<string>;
  instance?: Optional<Partial<RDSInstance>>;
  loading?: boolean;
  formId?: string;
  clientOverrides?: AwsClientOverrides;
  onCreate: (data: CreateRDBParams) => unknown;
  onUpdate: (data: UpdateRDBParams) => unknown;
}

export const RDSInstanceForm = ({
  cluster,
  instance,
  loading,
  formId,
  clientOverrides,
  onCreate,
  onUpdate,
}: RDSInstanceFormProps): ReactElement => (
  <MagicForm
    data={{ ...instance, DBClusterIdentifier: cluster }}
    schema={getSchema('RDS')}
    loading={loading}
    entry={instance ? 'ModifyDBInstanceMessage' : 'CreateDBInstanceMessage'}
    formId={formId}
    fieldConditions={{
      '^DBInstanceIdentifier': !instance,
      '^MasterUserPassword': !instance, // not supported in editing mode by LS atm
      '^DBClusterIdentifier': !cluster,
    }}
    externalFields={{
      '^(KmsKeyId|PerformanceInsightsKMSKeyId)$': (control, fieldName, required) => (
        <RelatedResourcePicker
          control={control}
          client="KMS"
          method="listKeys"
          arrayKeyName="Keys"
          property="KeyArn"
          fieldName={fieldName}
          entityName="KMS Key"
          creationRoute={DEFAULT_KMS_ROUTES.RESOURCES_KMS_KEY_CREATE}
          required={required}
          clientOverrides={clientOverrides}
        />
      ),
      '^(MonitoringRoleArn|DomainIAMRoleName)$': (control, fieldName, required) => (
        <RelatedResourcePicker
          control={control}
          client="IAM"
          method="listRoles"
          arrayKeyName="Roles"
          property="Arn"
          fieldName={fieldName}
          entityName="IAM Role"
          creationRoute={DEFAULT_IAM_ROUTES.RESOURCES_IAM_ROLE_CREATE}
          required={required}
          clientOverrides={clientOverrides}
        />
      ),
      '^DBClusterIdentifier$': (control, fieldName, required) => (
        <RelatedResourcePicker
          control={control}
          client="RDS"
          method="describeDBClusters"
          arrayKeyName="DBClusters"
          property="DBClusterIdentifier"
          fieldName={fieldName}
          entityName="IAM Role"
          creationRoute={DEFAULT_IAM_ROUTES.RESOURCES_IAM_ROLE_CREATE}
          required={required}
          clientOverrides={clientOverrides}
        />
      ),
    }}
    onSubmit={(data: CreateRDBParams) => {
      if (!instance) return onCreate(data);
      return onUpdate({ ...data, DBInstanceIdentifier: instance.DBInstanceIdentifier as string });
    }}
  />
);
