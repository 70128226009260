import { Box, Typography, TypographyProps } from '@mui/material';
import { ReactElement, Fragment } from 'react';
import { Theme } from '@mui/material/styles';

import makeStyles from '@mui/styles/makeStyles';

import classnames, { Argument } from 'classnames';

import { CopyIconButton } from '../../feedback/CopyIconButton';

const useStyles = makeStyles((theme: Theme) => ({
  wrappingBox: {
    display: 'flex',
    background: theme.palette.background.default,
    border: '1px solid ',
    borderColor: theme.palette.info.main,
    borderRadius: theme.shape.borderRadius,
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    alignItems: 'center',
    flexDirection: 'row',
  },
  shrinkedWrappingBox: {
    display: 'inline-flex',
  },
  centerWrapping: {
    justifyContent: 'center',
  },
  commandBox: {
    display: 'flex',
    alignSelf: 'center',
    padding: '0.5rem',
  },
  copyBox: {
    alignSelf: 'center',
  },
}));

export type CodeVisualizerProps = {
  commands: string[];
  showCopy?: boolean;
  shrink?: boolean;
  center?: boolean;
  typographyProps?: TypographyProps;
  boxStyling?: Argument;
};

export const CodeVisualizer = ({
  commands,
  showCopy = true,
  center,
  shrink,
  typographyProps,
  boxStyling,
}: CodeVisualizerProps): ReactElement => {
  const classes = useStyles();

  return (
    <Box
      className={classnames(
        classes.wrappingBox,
        {
          [classes.centerWrapping]: center,
          [classes.shrinkedWrappingBox]: shrink,
        },
        boxStyling,
      )}
    >
      <Box className={classes.copyBox}>
        {showCopy && <CopyIconButton textToCopy={commands.join('\n')} tooltipTitle="Copy to clipboard" />}
      </Box>
      <Box className={classes.commandBox} overflow="auto">
        <Typography style={{ fontSize: '12px', fontFamily: 'monospace', overflow: 'unset' }} {...typographyProps}>
          {commands.map((log) => (
            <Fragment key={log}>
              {log}
              <br />
            </Fragment>
          ))}
        </Typography>
      </Box>
    </Box>
  );
};
