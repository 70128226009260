import { ReactElement } from 'react';
import { Chip, Box, styled } from '@mui/material';
import { NavLink } from 'react-router-dom';
import { Theme } from '@mui/material/styles';

import { EphemeralInstanceStatus, LocalStackInstance, LocalStackInstanceType } from '@localstack/types';

import { buildRoute, useLocalstackStatus } from '@localstack/services';

import { InstanceMenuEntries } from '@localstack/ui';

import { AppRoute } from '~/config';

import { getSectionItemBackground, getSectionItemTextColor } from './shared';

const InstanceContainer = styled(Box)(({ theme }) => ({
  width: '100%',
  border: `1px solid ${theme.palette.divider}`,
  borderRadius: '0.8em',
  padding: '0.5em',
  marginLeft: '-0.5em',
  marginBottom: '0.1em',
}));

/** base style used across the links within an instance shown and its name */
const baseLinkItem = (theme: Theme) => ({
  textDecoration: 'none',
  marginRight: theme.spacing(2),
  fontSize: '14px',
  color: getSectionItemTextColor(theme),
  padding: '0.5em',
  borderRadius: '0.5em',
  display: 'flex',
  alignItems: 'center',
  flexGrow: 1,
  overflow: 'hidden',
  gap: '0.5em',

  '&:hover': {
    background: getSectionItemBackground(theme),
  },
});

interface InstanceContainerProps {
  isInstanceActive: boolean;
}

const InstanceNameContainer = styled(NavLink, {
  shouldForwardProp: (prop) => prop !== 'isActive',
})<InstanceContainerProps>(({ theme, isInstanceActive }) => ({
  ...baseLinkItem(theme),
  ...(isInstanceActive && {
    background: getSectionItemBackground(theme),
  }),
}));

type StatusType = EphemeralInstanceStatus | 'offline' | 'running';

interface StatusChipProps {
  status: StatusType;
}

const StatusChip = styled(Chip, {
  shouldForwardProp: (prop) => prop !== 'status',
})<StatusChipProps>(({ theme, status }) => {
  let bg = theme.palette.error.main;
  let textColor = theme.palette.error.contrastText;

  if (status === EphemeralInstanceStatus.CREATING) {
    bg = theme.palette.warning.main;
    textColor = theme.palette.warning.contrastText;
  }

  if (status === EphemeralInstanceStatus.RUNNING) {
    bg = theme.palette.success.main;
    textColor = theme.palette.success.contrastText;
  }

  return {
    display: 'flex',
    backgroundColor: bg,
    color: textColor,
  };
});

const StyledHorizontalLine = styled('hr')(({ theme }) => ({
  margin: '0.5em -0.5em',
  borderTop: '1px',
  borderColor: theme.palette.divider,
}));

const StyledInstanceMenuEntries = styled(InstanceMenuEntries)(({ theme }) => ({
  ...baseLinkItem(theme),
}));

type InstanceSubMenuProps = {
  instance: LocalStackInstance;
  activeInstanceId: string;
  expanded: boolean;
};

export const InstanceSubMenu = ({ activeInstanceId, expanded, instance }: InstanceSubMenuProps): ReactElement => {
  const { running } = useLocalstackStatus({ endpoint: instance.endpoint });

  const localstackStatus = running ? 'running' : 'offline';
  const ephemeralInstanceStatus = instance.ephemeralInstance?.status;
  const statusText = ephemeralInstanceStatus ?? localstackStatus;

  return (
    <InstanceContainer>
      <Box display="flex" overflow="hidden" alignItems="center" justifyContent="space-between">
        <InstanceNameContainer
          to={buildRoute(AppRoute.RESOURCES_OVERVIEW, { iid: instance.id })}
          key={instance.id}
          end
          isInstanceActive={activeInstanceId === instance.id}
        >
          {instance.instanceType === LocalStackInstanceType.EPHEMERAL_INSTANCE && (
            <Chip size="small" color="primary" label={instance.name?.startsWith('pod-') ? 'Pod' : 'Eph'} />
          )}
          {instance.name}
          {!!instance.podVersion && `:${instance.podVersion}`}
        </InstanceNameContainer>
        <StatusChip status={statusText} label={statusText} size="small" />
      </Box>
      {expanded && (
        <>
          <StyledHorizontalLine />
          <Box display="flex" flexDirection="column" gap="0.1em">
            <StyledInstanceMenuEntries instance={instance} useLocalStackLinkComponent={false} />
          </Box>
        </>
      )}
    </InstanceContainer>
  );
};
