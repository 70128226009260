import { ReactElement } from 'react';
import { LogoIcon } from '@localstack/ui';
import { Box, Typography } from '@mui/material';

import { AuthLayout } from '../Account/AuthLayout';
import { useStyles } from '../Account/common';

export const SSOLoading = (): ReactElement => {
  const classes = useStyles();
  return (
    <AuthLayout documentTitle="SSO Loading">
      <Box textAlign="center">
        <Box mb={4}>
          <LogoIcon />
        </Box>
        <Typography variant="h3" component="h2" fontWeight={600}>
          Loading Identity Provider
        </Typography>

        <Box mt={4}>
          <Typography fontSize={13} className={classes.grey600Text} fontWeight={500}>
            Please wait while we load your Identity Provider.
          </Typography>
        </Box>
      </Box>
    </AuthLayout>
  );
};
