import { ReactElement } from 'react';
import { AppSyncGraphqlApi } from '@localstack/types';
import { getSchema } from '@localstack/services';

import { MagicDetails } from '../../../magic/MagicDetails';

export interface AppSyncApiDetailsProps {
  api?: Optional<Partial<AppSyncGraphqlApi>>;
}

export const AppSyncApiDetails = ({ api }: AppSyncApiDetailsProps): ReactElement => (
  <MagicDetails data={api} schema={getSchema('AppSync')} entry="GraphqlApi" title="API Details" />
);
