import { Theme } from '@mui/material/styles';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import { Close as CloseIcon } from '@mui/icons-material';
import { Alert, Box, Button, Card, Grid, IconButton, Tab } from '@mui/material';
import { ReactElement, useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';

import { StacksService, useApiGetter, useAwsGetter, useRoutes } from '@localstack/services';

import {
  ControlledTabPanels,
  ProgressButton,
  StackList,
  StackListSkeleton,
  generateStackWithEvents,
} from '@localstack/ui';

import { ProductFeature, PlanFamily, StackInfo } from '@localstack/types';

import { AppRoute } from '~/config';
import { ContainedCustomerLayout } from '~/layouts';

import { useAuthProvider } from '~/hooks/useAuthProvider';

import { FilterStackCard } from './components/FilterStackCard';
import { StackTab } from './components/StackTab';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    stackCard: {
      padding: 0,
    },
    tabs: {
      borderBottom: `1px solid ${theme.palette.divider}`,
      minHeight: 'auto',
    },
    tab: {
      display: 'flex',
      flexDirection: 'row-reverse',
      alignItems: 'center',
      minHeight: 'auto',
      height: 'auto',
      padding: theme.spacing(1),
      borderRight: `1px solid ${theme.palette.divider}`,
      '&:last-child': {
        borderRight: 'none',
      },
      '&:hover': {
        background: theme.palette.action.hover,
      },
    },
    tabSelected: {
      background: theme.palette.action.active,
    },
    tabButton: {
      margin: `0 0 0 ${theme.spacing(1)} !important`,
      padding: 0,
    },
    listSummary: {
      fontSize: '14px',
      textAlign: 'center',
      color: theme.palette.text.disabled,
    },
  }),
);

export const Stack = (): ReactElement => {
  const { hasFeature } = useAuthProvider();

  const showSampleData = !hasFeature(ProductFeature.FEATURE_STACKS_PREVIEW);
  const sampleData = useMemo(
    () =>
      showSampleData ? [generateStackWithEvents(), generateStackWithEvents(), generateStackWithEvents()] : undefined,
    [showSampleData],
  );
  const sampleStacks = sampleData?.map((stack) => stack.stack);

  const classes = useStyles();

  const { stackId } = useParams<'stackId'>() as { stackId: string };
  const stackIds = stackId ? stackId.split(',').map((id) => id.trim()) : [];

  const noStacksSelected = stackIds.length === 0;

  const [selectedStackIndex, setSelectedStackIndex] = useState(0);

  const { goto } = useRoutes();

  const { data: stackInfo } = useAwsGetter('LocalStack', 'getStackInfo', [], { silentErrors: true });

  const [requestPage, setRequestPage] = useState(0);
  const { data: stacksPageData } = useApiGetter(StacksService, 'listStacks', [
    undefined, // since
    undefined, // until
    10, // limit
    requestPage * 10, // offset
  ]);
  const [stacks, setStacks] = useState<StackInfo[]>([]);

  const [filteredStacks, setFilteredStacks] = useState<StackInfo[]>([]);
  const historyStacks = useMemo(
    () => (showSampleData ? sampleStacks : filteredStacks),
    [showSampleData, filteredStacks],
  );
  const stacksOrEmpty = useMemo(() => stacks ?? [], [stacks]);

  const [showLoadMoreButton, setShowLoadMoreButton] = useState(true);
  const [isInitialLoading, setIsInitialLoading] = useState(true);
  const [isLoadingMore, setIsLoadingMore] = useState(false);

  useEffect(() => {
    if (stacksPageData) {
      setIsInitialLoading(false);
      setIsLoadingMore(false);

      if (stacksPageData.length > 0) {
        setStacks([...stacks, ...stacksPageData]);
      } else {
        setShowLoadMoreButton(false);
      }
    }
  }, [stacksPageData]);

  return (
    <ContainedCustomerLayout maxWidth="xl" title="Stack Insights" planFamily={PlanFamily.TEAM_PLANS}>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          {showSampleData && (
            <Alert severity="info" variant="outlined">
              Stack Insights are only available in the Team/Enterprise plan, see what is possible below with some sample
              data.
              <Button
                style={{ marginLeft: '1rem' }}
                size="small"
                variant="outlined"
                color="primary"
                onClick={() => goto(AppRoute.PRICING)}
              >
                Get Subscription
              </Button>
            </Alert>
          )}
        </Grid>

        <Grid item xs={12} md={5} lg={5} xl={4}>
          <Grid container spacing={3}>
            {!showSampleData && (
              <Grid item xs={12}>
                <FilterStackCard setFilteredStacks={setFilteredStacks} stacks={stacksOrEmpty} />
              </Grid>
            )}

            <Grid item xs={12}>
              <Box
                flexGrow={1}
                style={{
                  overflowY: 'scroll',
                  maxHeight: '1000px',
                  paddingRight: '10px',
                  paddingBottom: '1em',
                }}
              >
                {isInitialLoading && !showSampleData && <StackListSkeleton />}
                {(!isInitialLoading || showSampleData) && (
                  <StackList
                    size="md"
                    activeStack={stackInfo ?? undefined}
                    historyStacks={historyStacks ?? []}
                    totalNumberOfStacks={!showSampleData ? stacks.length : undefined}
                    selectedStacks={stackIds}
                    onClick={(id) => {
                      const stackIndex = stackIds.indexOf(id);
                      if (stackIndex !== -1) {
                        setSelectedStackIndex(stackIndex);
                      } else {
                        goto(AppRoute.STACKS_STACK, { stackId: Array.from(new Set([...stackIds, id])).join(',') });
                        setSelectedStackIndex(stackIds.length);
                      }
                    }}
                  />
                )}
                {!isInitialLoading && historyStacks?.length === 0 && stacks.length !== 0 && (
                  <Box mt={2}>
                    <Alert severity="info">No Stacks found matching the selected filter</Alert>
                  </Box>
                )}
                {showLoadMoreButton && !showSampleData && (
                  <Box mt={2}>
                    <ProgressButton
                      fullWidth
                      size="small"
                      variant="outlined"
                      loading={isLoadingMore}
                      onClick={() => {
                        setIsLoadingMore(true);
                        setRequestPage(requestPage + 1);
                      }}
                    >
                      Load More
                    </ProgressButton>
                  </Box>
                )}
              </Box>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} md={7} lg={7} xl={8}>
          <Card className={classes.stackCard}>
            <ControlledTabPanels
              className={classes.tabs}
              selected={selectedStackIndex}
              renderTab={(tabStackId, index) => (
                <Tab
                  key={tabStackId}
                  classes={{ root: classes.tab, selected: classes.tabSelected }}
                  label={<div>Stack {tabStackId.substring(0, 8)}</div>}
                  icon={
                    stackIds.length !== 1 ? (
                      <IconButton
                        className={classes.tabButton}
                        onClick={(e) => {
                          e.stopPropagation();
                          if (selectedStackIndex >= index) {
                            setSelectedStackIndex(Math.max(0, selectedStackIndex - 1));
                          }
                          goto(AppRoute.STACKS_STACK, {
                            stackId: stackIds.filter((id) => id !== tabStackId).join(','),
                          });
                        }}
                        size="large"
                      >
                        <CloseIcon />
                      </IconButton>
                    ) : (
                      <span />
                    )
                  }
                />
              )}
              options={stackIds.map((id) => ({
                label: id,
                panel: (
                  <StackTab
                    stackId={id}
                    sampleEvents={sampleData?.find((data) => data.stack.session_id === id)?.events}
                  />
                ),
              }))}
              onTabChange={(label: string) => setSelectedStackIndex(stackIds.indexOf(label))}
            />
            {noStacksSelected && (
              <Alert severity="info">
                No Stack selected, please select one from the stack list to get more details
              </Alert>
            )}
          </Card>
        </Grid>
      </Grid>
    </ContainedCustomerLayout>
  );
};
