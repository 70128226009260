import { ReactElement } from 'react';
import {
  Card,
  CardContent,
  CardHeader,
  Grid,
  Theme,
  ThemeProvider,
  Typography,
  useTheme,
  List,
  ListItem,
  Link,
  Fade,
  IconButton,
} from '@mui/material';
import { StyledEngineProvider } from '@mui/material/styles';

import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';

import {
  FlightTakeoff as FlightTakeoffIcon,
  FlightLand as FlightLandIcon,
  Launch as IconLaunch,
  RemoveCircle as RemoveIcon,
} from '@mui/icons-material';
import { WidgetOptions } from '@localstack/types';
import { ExternalLink } from '@localstack/constants';

export const WelcomeWidgetOptions: WidgetOptions = {
  i: 'welcome',
  image: '/widgets/welcome.png',
  style: 'media',
  xl: {
    w: 10,
    h: 8,
  },
  lg: {
    w: 10,
    h: 8,
  },
  md: {
    w: 10,
    h: 8,
  },
  sm: {
    w: 12,
    h: 8,
  },
  xs: {
    w: 12,
    h: 14,
  },
};

type Props = {
  className?: string;
  style?: Record<string, string>;
  children?: JSX.Element;
  removeCallback?: () => void;
  editing?: boolean;
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    card: {
      color: theme.palette.primary.contrastText,
      marginBottom: theme.spacing(2),
      '& *': {
        color: theme.palette.primary.contrastText,
      },
      background: `linear-gradient(45deg,
      #4D29B4 0%,
      #6B7CF5 50%,
      #70adfe 100%);`,
      '& a svg': {
        height: '0.8em',
        transform: 'translate(0, 0.1em)',
      },
      '& h2': {
        marginTop: '0.5em',
      },
      '& .inlineIcon': {
        marginRight: '0.3em',
        transform: 'translate(0, 0.15em)',
      },

      '& .nowrap': {
        whiteSpace: 'nowrap',
      },
      '& ul.bullets': {
        listStyleType: 'disc',
      },
      '& ul.bullets li': {
        display: 'list-item',
        listStylePosition: 'inside',
        marginLeft: '1.4em',
        textIndent: '-1.4em',
      },
    },
  }),
);

export const WelcomeWidget = ({
  style,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  className,
  children: _children,
  removeCallback,
  editing,
  ...rest
}: Props): ReactElement => {
  const theme = useTheme();
  const classes = useStyles();

  return (
    <Fade in>
      <span>
        <StyledEngineProvider injectFirst>
          <ThemeProvider theme={theme}>
            <Card className={classes.card} style={style} {...rest}>
              <CardHeader
                title="Welcome to LocalStack"
                titleTypographyProps={{ variant: 'h2', style: { fontWeight: 'bold' } }}
                subheader="Here are a few resources to get started with LocalStack!"
                subheaderTypographyProps={{ variant: 'body1' }}
                action={
                  editing && (
                    <IconButton aria-label="remove" onClick={removeCallback} size="large">
                      <RemoveIcon />
                    </IconButton>
                  )
                }
              />
              <CardContent>
                <Grid container spacing={0}>
                  <Grid xs={12} sm={6} item>
                    <Typography variant="h4" component="h2" style={{ marginBottom: '0.5em', fontWeight: 'bold' }}>
                      <FlightTakeoffIcon className="inlineIcon" />
                      Getting Started
                    </Typography>
                    <Typography variant="body1" component="span">
                      <List disablePadding className="bullets">
                        <ListItem dense>
                          <Link target="_blank" href={ExternalLink.DOCS_GETTING_STARTED} underline="hover">
                            Install LocalStack on your{' '}
                            <span className="nowrap">
                              machine.
                              <IconLaunch />
                            </span>
                          </Link>
                        </ListItem>

                        <ListItem dense>
                          <Link target="_blank" href={ExternalLink.DOCS_QUICK_START} underline="hover">
                            Deploy our quick start sample to your LocalStack{' '}
                            <span className="nowrap">
                              Instance.
                              <IconLaunch />
                            </span>
                          </Link>
                        </ListItem>

                        <ListItem dense>
                          <Link target="_blank" href={ExternalLink.DOCS_TOOLS} underline="hover">
                            Learn about additional tools that come with
                            <span className="nowrap">
                              {' '}
                              LocalStack.
                              <IconLaunch />
                            </span>
                          </Link>
                        </ListItem>
                      </List>
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Typography variant="h4" component="h2" style={{ marginBottom: '0.5em', fontWeight: 'bold' }}>
                      <FlightLandIcon className="inlineIcon" />
                      Diving Deeper
                    </Typography>
                    <Typography variant="body1" component="span">
                      <List disablePadding className="bullets">
                        <ListItem dense>
                          <Link target="_blank" href={ExternalLink.DOCS_CI} underline="hover">
                            Integrate LocalStack into your CI{' '}
                            <span className="nowrap">
                              pipeline.
                              <IconLaunch />
                            </span>
                          </Link>
                        </ListItem>
                        <ListItem dense>
                          <Link target="_blank" href={ExternalLink.DOCS_FEATURE_COVERAGE} underline="hover">
                            Browse through the services emulated by{' '}
                            <span className="nowrap">
                              LocalStack.
                              <IconLaunch />
                            </span>
                          </Link>
                        </ListItem>
                      </List>
                    </Typography>
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </ThemeProvider>
        </StyledEngineProvider>
      </span>
    </Fade>
  );
};
