import { ReactElement } from 'react';
import { NavLink } from '@localstack/ui';
import { Permission, ProductFeature } from '@localstack/types';
import { buildRoute } from '@localstack/services';

import { useAuthProvider } from '~/hooks/useAuthProvider';
import { AppRoute } from '~/config';

export const NavTabs = (): ReactElement => {
  const { can, hasFeature } = useAuthProvider();

  // show billing if user is permitted for any of those actions
  const billingPermissions = [Permission.READ_CREDIT_CARDS, Permission.READ_INVOICES, Permission.UPDATE_ORGANIZATION];

  return (
    <>
      <NavLink to={buildRoute(AppRoute.SETTINGS_PROFILE)} end>
        Account Info
      </NavLink>

      {can(Permission.READ_SUBSCRIPTIONS) && (
        <NavLink to={buildRoute(AppRoute.SETTINGS_SUBSCRIPTIONS)} end>
          Subscriptions
        </NavLink>
      )}

      {billingPermissions.some(can) && <NavLink to={buildRoute(AppRoute.SETTINGS_BILLING)}>Billing</NavLink>}

      <NavLink to={buildRoute(AppRoute.SETTINGS_KEYS)}>API Keys</NavLink>

      {can(Permission.READ_MEMBERS) && <NavLink to={buildRoute(AppRoute.SETTINGS_MEMBERS)}>Team Members</NavLink>}

      {hasFeature(ProductFeature.FEATURE_SSO) && (
        <NavLink to={buildRoute(AppRoute.SETTINGS_SSO)}>Single Sign-on</NavLink>
      )}

      <NavLink to={buildRoute(AppRoute.SETTINGS_AUTHENTICATION)}>Password and Authentication</NavLink>
    </>
  );
};
