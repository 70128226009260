import { ReactElement } from 'react';
import { useParams } from 'react-router-dom';
import { Card, CardContent, CardActions, Box, Typography } from '@mui/material';
import { CreateAgwApiModelRequest, UpdateAgwApiModelRequest } from '@localstack/types';
import { useRoutes, useAwsEffect, useAwsGetter } from '@localstack/services';
import { ProgressButton, AgwApiModelForm, Breadcrumbs } from '@localstack/ui';
import { DEFAULT_API_GATEWAY_ROUTES } from '@localstack/constants';

import { ApiGatewayProps } from './types';
import { ApiNavTabs } from './components';

export const AgwApiModelUpsert = ({
  Layout,
  clientOverrides,
  routes = DEFAULT_API_GATEWAY_ROUTES,
}: ApiGatewayProps): ReactElement => {
  const { goto } = useRoutes();
  const { apiId: ApiId, modelId: ModelId } = useParams<'apiId' | 'modelId'>();

  const { data: api } = useAwsGetter('ApiGatewayV2', 'getApi', [{ ApiId }], { clientOverrides });

  const { data: model } = useAwsGetter('ApiGatewayV2', 'getModel', [{ ApiId, ModelId }], { clientOverrides });

  const { createModel, updateModel, isLoading } = useAwsEffect('ApiGatewayV2', ['createModel', 'updateModel'], {
    revalidate: ['getModel', 'getModels'],
    clientOverrides,
  });

  const handleCreate = async (data: CreateAgwApiModelRequest) => {
    await createModel(data);
    goto(routes.RESOURCES_AGW2_API_MODELS, { apiId: ApiId });
  };

  const handleUpdate = async (data: UpdateAgwApiModelRequest) => {
    await updateModel(data);
    goto(routes.RESOURCES_AGW2_API_MODEL, { apiId: ApiId, modelId: ModelId });
  };

  return (
    <Layout
      title={
        <Box>
          <Typography variant="h4">Model Details</Typography>
          <Breadcrumbs
            mappings={[
              ['API Gateway', () => goto(routes.RESOURCES_AGW2)],
              [api?.Name, () => goto(routes.RESOURCES_AGW2_API, { apiId: ApiId })],
              [model?.Name, () => goto(routes.RESOURCES_AGW2_API_MODEL, { apiId: ApiId, modelId: ModelId })],
              [ModelId ? 'Update' : 'Create Model', null],
            ]}
          />
        </Box>
      }
      tabs={<ApiNavTabs apiId={ApiId as string} routes={routes} />}
      actions={
        <ProgressButton type="submit" form="update" variant="outlined" color="primary" loading={isLoading}>
          Submit
        </ProgressButton>
      }
    >
      <Card>
        <CardContent>
          <AgwApiModelForm
            apiId={ApiId as string}
            model={model}
            onCreate={handleCreate}
            onUpdate={handleUpdate}
            loading={isLoading}
            formId="update"
          />
        </CardContent>
        <CardActions>
          <ProgressButton type="submit" form="update" variant="contained" color="primary" loading={isLoading}>
            Submit
          </ProgressButton>
        </CardActions>
      </Card>
    </Layout>
  );
};
