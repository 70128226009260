/* eslint-disable @typescript-eslint/no-explicit-any */

import { useCallback, useMemo, useState, useEffect } from 'react';
import { AxiosError } from 'axios';
import { Request } from 'aws-sdk';
import useSWR, { SWRConfiguration } from 'swr';

import { ExtractOverloadedParams, FilterUnknowns, TupleArrayUnion } from '@localstack/types';

import { AWS_CLIENTS, AWS_SERVICE_PORTS, DEFAULT_SWR_SETTINGS } from '../config';

import { useRegion, useEndpoint, useErrorSnackbarEffect, useGlobalSwr, useAwsAccountId, usePrevious } from './util';

// ==================================================
// UTIL TYPES
// ==================================================

/** Extract response type from AWS SDK method */
type ExtractResponse<T> = T extends Request<infer R, any> ? R : T extends Promise<infer R> ? R : never;

/** Extract Name=Method mapping from object/instance */
type ExtractMethodsRecord<T> = Pick<T, { [K in keyof T]: T[K] extends (...args: any) => any ? K : never }[keyof T]>;

/** How method invocation will look like after wrapping it into our hook */
type BuildAwsmethodCall<Method extends (...args: any) => unknown> = (
  ...args: TupleArrayUnion<FilterUnknowns<ExtractOverloadedParams<Method>>>
) => Promise<ExtractResponse<ReturnType<Method>>>;

export interface AwsClientOverrides {
  region?: string;
  s3ForcePathStyle?: boolean;
  endpoint?: string;
}

// ==================================================
// useAwsEffect
// ==================================================

interface UseAwsEffectMethodsSettings<MethodNames extends string[], AllMethodNames extends string[]> {
  revalidate?: AllMethodNames;
  callbacks?: Partial<Record<MethodNames[number], () => unknown>>;
  clientOverrides?: AwsClientOverrides;
  silentErrors?: boolean;
}

type UseAwsEffectMethodsReturn<
  MethodNames extends string[],
  Methods extends Record<MethodNames[number], (...args: any[]) => any>,
> = { [K in MethodNames[number]]: BuildAwsmethodCall<Methods[K]> };

interface UseAwsEffectStateReturn {
  isLoading: boolean;
  error?: Optional<Error | AxiosError>;
  hasError: boolean;
  awsClient: InstanceType<(typeof AWS_CLIENTS)[keyof typeof AWS_CLIENTS]>;
}

/**
 * Unified way of wrapping AWS SDK Calls into controllable functions.
 *
 * Example:
 * ```ts
 *   const { isLoading, error, createApi } = useAwsEffect('ApiGatewayV2', ['createApi', 'updateApi']);
 *   const result = await createApi({ Name: '', ProtocolType: '' });
 * ```
 *
 * HINT: if you are getting TS errors for input arguments (no overload...)
 *       this is most likely due to missing required attributes.
 *
 * @param client
 * @param methods
 * @param options
 * @returns
 */
export const useAwsEffect = <
  // Client name is a key of AWS_CLIENTS
  Client extends keyof typeof AWS_CLIENTS,
  // Extract all methods from the matched AWS Client Instance
  Methods extends ExtractMethodsRecord<InstanceType<(typeof AWS_CLIENTS)[Client]>>,
  // Supplied method names should exist in AWS Client Instance
  MethodNames extends (keyof Methods)[],
  // Make sure names is a list of strings to suppress TS error
  MethodNamesArray extends MethodNames extends string[] ? MethodNames : never,
  // Make sure names is a list of strings to suppress TS error
  AllMethodNamesArray extends (keyof Methods)[] extends string[] ? (keyof Methods)[] : never,
  // Make sure methods is a mapping of methods to suppress TS error
  MethodsArray extends Methods extends Record<MethodNames[number], (...args: any[]) => any> ? Methods : never,
>(
  client: Client,
  methods: MethodNames,
  settings?: UseAwsEffectMethodsSettings<MethodNamesArray, AllMethodNamesArray>,
): UseAwsEffectMethodsReturn<MethodNamesArray, MethodsArray> & UseAwsEffectStateReturn => {
  const [loadingStates, setLoadingStates] = useState({});
  const [error, setError] = useState<Optional<Error | AxiosError>>(null);

  const { mutateRelated } = useGlobalSwr();

  const isLoading = Object.values(loadingStates).some(Boolean);

  const { region } = useRegion();
  const { awsAccountId } = useAwsAccountId();
  // TODO: get from clientOverrides
  const { endpoint: endpointUrl } = useEndpoint();
  // add multiaccount support here once it's implemented

  const endpoint =
    (settings?.clientOverrides?.endpoint ?? endpointUrl.includes(':'))
      ? endpointUrl
      : `${endpointUrl}:${AWS_SERVICE_PORTS[client]}`;

  const awsClient = useMemo(
    () =>
      new AWS_CLIENTS[client]({
        accessKeyId: awsAccountId,
        secretAccessKey: 'test',
        endpoint,
        region,
        ...settings?.clientOverrides,
      }),
    [endpoint, region, awsAccountId, JSON.stringify(settings?.clientOverrides)],
  );

  // Patch required to connect S3 client to custom endpoint for certain regions, see:
  // https://docs.localstack.cloud/user-guide/integrations/sdks/javascript/#examples
  if (client === 'S3') {
    const s3Client = awsClient as any;
    s3Client.api.globalEndpoint = s3Client.endpoint?.hostname;
  }

  const definitions = methods.reduce(
    (memo, method) => ({
      ...memo,
      [method]: async (...args: any[]) => {
        try {
          setLoadingStates({
            ...loadingStates,
            [method]: true,
          });

          const callable = awsClient[method as unknown as keyof typeof awsClient] as (...a: any[]) => {
            promise: () => any;
          };

          const promise = await callable.call(awsClient, ...(args || []));
          const result = promise.promise ? await promise.promise() : promise;

          mutateRelated(method);

          if (settings?.revalidate) {
            settings.revalidate.forEach((key) => mutateRelated(key));
          }

          // TS doesn't like this part
          if (settings?.callbacks?.[method as unknown as keyof typeof settings.callbacks]) {
            settings.callbacks[method as unknown as keyof typeof settings.callbacks]?.();
          }

          return result;
        } catch (e) {
          console.error(e);
          setError(e);
        } finally {
          setLoadingStates({
            ...loadingStates,
            [method]: false,
          });
        }
      },
    }),
    {} as UseAwsEffectMethodsReturn<MethodNamesArray, MethodsArray>,
  );

  useErrorSnackbarEffect(settings?.silentErrors ? undefined : error);

  return {
    ...definitions,
    isLoading,
    error,
    hasError: !!error,
    awsClient,
  };
};

// ==================================================
// useAwsGetter
// ==================================================

interface UseAgwGetterSettings<R> {
  defaultValue?: R;
  clientOverrides?: AwsClientOverrides;
  swrOverrides?: SWRConfiguration;
  silentErrors?: boolean;
  enable?: boolean;
}

/**
 * Flexible hook to wrap any getter- AWS call.
 *
 * Example:
 * ```ts
 *   const { data } = useAgwGetter('ApiGatewayV2', 'getApi', [{ ApiId: '123' }]);
 *   const apiId = data?.ApIId
 * ```
 *
 * @param client - AWS Client name (has to be added to AWS_CLIENTS)
 * @param method - Client method name to invoke
 * @param args - Array of arguments to pass to invocation method
 * @returns
 */
export const useAwsGetter = <
  // Client name is a key of AWS_CLIENTS
  Client extends keyof typeof AWS_CLIENTS,
  // Extract all methods from the matched AWS Client Instance
  Methods extends ExtractMethodsRecord<InstanceType<(typeof AWS_CLIENTS)[Client]>>,
  // Selected method name should be part of our matched AWS Client Instance
  MethodName extends keyof Methods,
  // Extract matching method from AWS Client Instance
  Method extends Methods[MethodName] extends (...a: any[]) => any ? Methods[MethodName] : never,
  // Extract method arguments, the magic here is transforming of overloaded arguments into tuple
  MethodArgs extends TupleArrayUnion<FilterUnknowns<ExtractOverloadedParams<Method>>>,
  // Here we are mapping through arguments and making them optional
  MethodArgsTuple extends {
    [K in keyof MethodArgs]: MethodArgs[K] extends Record<any, any> ? Partial<MethodArgs[K]> : Optional<MethodArgs[K]>;
  },
  // TS argues "MethodArgsTuple" is not an array, thus here we are making sure the final args list is an array
  MethodArgsArray extends MethodArgsTuple extends any[] ? MethodArgsTuple : never,
  // Extract return type from our AWS SDK METHOD
  Data extends ExtractResponse<ReturnType<Method>>,
>(
  client: Client,
  method: MethodName,
  args?: MethodArgsArray,
  settings?: UseAgwGetterSettings<Data>,
): {
  data: Optional<Data>;
  error?: Error | AxiosError;
  hasError: boolean;
  isLoading: boolean;
  mutate: () => void;
} => {
  const { region } = useRegion();
  const { endpoint: endpointUrl } = useEndpoint();
  const { awsAccountId } = useAwsAccountId();

  const endpoint =
    settings?.clientOverrides?.endpoint ??
    (endpointUrl.includes(':') ? endpointUrl : `${endpointUrl}:${AWS_SERVICE_PORTS[client]}`);

  const awsClient = useMemo(
    () =>
      new AWS_CLIENTS[client]({
        accessKeyId: awsAccountId,
        secretAccessKey: 'test',
        endpoint,
        region,
        ...settings?.clientOverrides,
      }),
    [endpoint, region, awsAccountId, client, JSON.stringify(settings?.clientOverrides)],
  );

  // swr cache key already includes all params it needs
  const cacheKey = JSON.stringify([client, method, region, awsAccountId, endpoint, args]);
  const enable = settings?.enable === undefined || !!settings?.enable;

  /**
   * Sometimes we want to skip backend calls in case not all of
   * the arguments have been provided. For example, when one of params is
   * coming from state and is not yet available.
   *
   * This little wrapper tries to make invocation, and if it sees there
   * are no supplied arguments and the call has failed - return default value.
   */
  const fetch = useCallback(async () => {
    try {
      if (!enable) return Promise.resolve(settings?.defaultValue);
      const callable = awsClient[method as unknown as keyof typeof awsClient] as (
        ...a: any[]
      ) => { promise: () => Promise<Data> } | Promise<Data>;
      const result = await callable.call(awsClient, ...(args || []));
      return result.promise ? await result.promise() : result;
    } catch (e) {
      const hasUndefined = (args || [])
        .map((arg: any) => Object.values(arg))
        .flat()
        .some((arg) => arg === undefined);
      const hasMissing = (e as Error).message?.toLowerCase().includes('missing');
      if (!hasUndefined && !hasMissing) throw e;
      return Promise.resolve(settings?.defaultValue);
    }
  }, [cacheKey, awsClient]);

  const { data, error, isValidating, mutate } = useSWR(cacheKey, fetch, {
    ...DEFAULT_SWR_SETTINGS,
    ...settings?.swrOverrides,
  });

  const oldData = usePrevious(data);

  // if we are still loading do not reset the data to `undefined`, but temporarily used the old data instead
  const resolvedData = isValidating ? (oldData ?? settings?.defaultValue) : data;

  useErrorSnackbarEffect(!settings?.silentErrors ? error : undefined);

  return { data: resolvedData, error, hasError: !!error, isLoading: isValidating, mutate };
};

/**
 * Cursor pagination can be done with useSWRInfinite, but it wraps everything to arrays
 * and there's no a beautiful way to extract items out of responses to merge them.
 *
 * So this function helps to keep track of cursor keys to then use them as page tokens.
 */
export const useAwsKeysMemo = <T>(page: number, lastEvaluatedKey: T): Record<number, T> => {
  const [keys, setKeys] = useState<Record<number, T>>({});

  useEffect(() => {
    if (page === 0) setKeys({});
    setKeys({ ...keys, [page + 1]: lastEvaluatedKey });
  }, [page, JSON.stringify(lastEvaluatedKey)]);

  return keys;
};

interface UseAwsBatchIteratorSettings {
  refreshInterval?: number;
}

/**
 * Helper hook to batch load data.
 * Note that the function has to be wrapped by `useAwsEffect`.
 *
 * Example:
 * ```ts
 *   const { data: tableNames } = useAwsGetter('DynamoDB', 'listTables');
 *   const { describeTable } = useAwsEffect('DynamoDB', ['describeTable']);
 *
 *   const tables = useAwsBatchIterator(tableNames, (TableName) => describeTable({ TableName }));
 * ```
 */
export const useAwsBatchIterator = <
  I extends any[],
  F extends (arg: I[number]) => any,
  R extends Awaited<ReturnType<F>>,
>(
  items: Optional<I>,
  func: F,
  defaultValue?: R[],
  settings?: UseAwsBatchIteratorSettings,
): R extends any[] ? R : R[] => {
  const [results, setResults] = useState<R[]>(defaultValue ?? []);

  useEffect(() => {
    async function load() {
      const promises = (items ?? []).map((item: I[number]) => func(item));
      setResults((await Promise.all(promises)).flat());
    }

    load();

    if (settings?.refreshInterval && settings.refreshInterval !== 0) {
      const int = setInterval(load, settings.refreshInterval);
      return () => clearInterval(int);
    }

    return () => undefined;
  }, [items]);

  return results as R extends any[] ? R : R[];
};
