import { ReactElement } from 'react';
import { CreateGlueCrawlerRequest, GlueCrawler, UpdateGlueCrawlerRequest } from '@localstack/types';
import { AwsClientOverrides, getSchema } from '@localstack/services';
import { DEFAULT_GLUE_ROUTES, DEFAULT_IAM_ROUTES } from '@localstack/constants';

import { MagicForm } from '../../../magic/MagicForm';
import { RelatedResourcePicker } from '../../../../form/RelatedResourcePicker';
import { S3PathPicker } from '../../../../form';

export type GlueCrawlerFormProps = {
  crawler?: Optional<Partial<GlueCrawler>>;
  loading?: boolean;
  formId: string;
  clientOverrides?: AwsClientOverrides;
  onCreate: (data: CreateGlueCrawlerRequest) => unknown;
  onUpdate: (data: UpdateGlueCrawlerRequest) => unknown;
};

export const GlueCrawlerForm = ({
  crawler,
  loading,
  formId,
  clientOverrides,
  onCreate,
  onUpdate,
}: GlueCrawlerFormProps): ReactElement => (
  <MagicForm
    data={crawler}
    schema={getSchema('Glue')}
    formId={formId}
    entry={crawler ? 'UpdateCrawlerRequest' : 'CreateCrawlerRequest'}
    loading={loading}
    fieldConditions={{
      Name: !crawler,
    }}
    externalFields={{
      '^Role$': (control, fieldName, required) => (
        <RelatedResourcePicker
          control={control}
          client="IAM"
          method="listRoles"
          arrayKeyName="Roles"
          property="Arn"
          fieldName={fieldName}
          entityName="IAM Role"
          creationRoute={DEFAULT_IAM_ROUTES.RESOURCES_IAM_ROLE_CREATE}
          required={required}
          clientOverrides={clientOverrides}
        />
      ),
      DatabaseName: (control, fieldName, required) => (
        <RelatedResourcePicker
          control={control}
          client="Glue"
          method="getDatabases"
          arrayKeyName="DatabaseList"
          property="Name"
          fieldName={fieldName}
          entityName="Glue Database"
          creationRoute={DEFAULT_GLUE_ROUTES.RESOURCES_GLUE_DATABASE_CREATE}
          required={required}
          clientOverrides={clientOverrides}
        />
      ),
      ConnectionName: (control, fieldName, required) => (
        <RelatedResourcePicker
          control={control}
          client="Glue"
          method="getConnections"
          arrayKeyName="ConnectionList"
          property="Name"
          fieldName={fieldName}
          entityName="Glue Connection"
          creationRoute={DEFAULT_GLUE_ROUTES.RESOURCES_GLUE_DATABASE_CREATE}
          required={required}
          clientOverrides={clientOverrides}
        />
      ),
      '^Targets.S3Targets\\.\\d+\\.Path$': (control, fieldName, required) => (
        <S3PathPicker control={control} fieldName={fieldName} required={required} clientOverrides={clientOverrides} />
      ),
    }}
    onSubmit={(data: CreateGlueCrawlerRequest) => {
      if (!crawler) return onCreate(data);
      return onUpdate({ ...data, Name: crawler.Name as string });
    }}
  />
);
