import { ReactElement } from 'react';
import { AgwRestApi } from '@localstack/types';
import { getSchema } from '@localstack/services';

import { MagicDetails } from '../../../magic/MagicDetails';

export interface AgwRestApiDetailsProps {
  api?: Optional<Partial<AgwRestApi>>;
}

export const AgwRestApiDetails = ({ api }: AgwRestApiDetailsProps): ReactElement => (
  <MagicDetails data={api} schema={getSchema('APIGateway')} entry="RestApi" title="API Details" />
);
