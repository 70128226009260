import {
  DEFAULT_API_GATEWAY_ROUTES as AGW,
  DEFAULT_KMS_ROUTES as KMS,
  DEFAULT_APPSYNC_ROUTES as APPSYNC,
  DEFAULT_SNS_ROUTES as SNS,
  DEFAULT_BACKUP_ROUTES as BACKUP,
  DEFAULT_ATHENA_ROUTES as ATHENA,
  DEFAULT_SQS_ROUTES as SQS,
  DEFAULT_CLOUDFORMATION_ROUTES as CLOUDFORMATION,
  DEFAULT_S3_ROUTES as S3,
  DEFAULT_RDS_ROUTES as RDS,
  DEFAULT_LAMBDA_ROUTES as LAMBDA,
  DEFAULT_CLOUDWATCH_ROUTES as CLOUDWATCH,
  DEFAULT_EC2_ROUTES as EC2,
  DEFAULT_ECS_ROUTES as ECS,
  DEFAULT_ECR_ROUTES as ECR,
  DEFAULT_COGNITO_ROUTES as COGNITO,
  DEFAULT_STEPFUNCTIONS_ROUTES as STEPFUNCTIONS,
  DEFAULT_SECRETSMANAGER_ROUTES as SECRETSMANAGER,
  DEFAULT_SES_ROUTES as SES,
  DEFAULT_DYNAMODB_ROUTES as DDB,
  DEFAULT_CLOUDTRAIL_ROUTES as CLOUDTRAIL,
  DEFAULT_ELASTICACHE_ROUTES as ELASTICACHE,
  DEFAULT_IAM_ROUTES as IAM,
  DEFAULT_KINESIS_ROUTES as KINESIS,
  DEFAULT_KAFKA_ROUTES as KAFKA,
  DEFAULT_EVENTBRIDGE_ROUTES as EVENTBRIDGE,
  DEFAULT_METRICS_ROUTES as METRICS,
  DEFAULT_MQ_ROUTES as MQ,
  DEFAULT_SYSTEMPARAMETERS_ROUTES as SP,
  DEFAULT_TIMESTREAMDB_ROUTES as TIMESTREAM,
  DEFAULT_GLUE_ROUTES as GLUE,
  DEFAULT_NEPTUNE_ROUTES as NEPTUNE,
  DEFAULT_SGM_ROUTES as SAGEMAKER,
  DEFAULT_CLOUDFRONT_ROUTES as CLOUDFRONT,
  DEFAULT_ROUTE53_ROUTES as ROUTE53,
  DEFAULT_APPLICATION_AUTO_SCALING_ROUTES as APPLICATION_AUTO_SCALING,
  DEFAULT_OPENSEARCH_ROUTES as OPENSEARCH,
  DEFAULT_QLDB_ROUTES as QLDB,
  DEFAULT_DOCDB_ROUTES as DOCDB,
  DEFAULT_APPCONFIG_ROUTES as APPCONFIG,
  DEFAULT_ACM_ROUTES as ACM,
  DEFAULT_MWAA_ROUTES as MWAA,
  DEFAULT_AMPLIFY_ROUTES as AMPLIFY,
  DEFAULT_EKS_ROUTES as EKS,
  DEFAULT_TRANSCRIBE_ROUTES as TRANSCRIBE,
  DEFAULT_CODECOMMIT_ROUTES as CODECOMMIT,
  DEFAULT_CE_ROUTES as CE,
  DEFAULT_ACCOUNT_ROUTES as ACCOUNT,
  DEFAULT_DMS_ROUTES as DMS,
  DEFAULT_REDSHIFT_ROUTES as REDSHIFT,
} from '@localstack/constants';

import * as views from '../views';

export const APIGatewayViewMappings = new Map([
  // API Gateway V2
  [AGW.RESOURCES_AGW2, { AgwV2Index: views.AgwV2Index }],
  [AGW.RESOURCES_AGW2_API, { AgwApiDetails: views.AgwApiDetails }],
  [AGW.RESOURCES_AGW2_API_CREATE, { AgwApiUpsert: views.AgwApiUpsert }],
  [AGW.RESOURCES_AGW2_API_UPDATE, { AgwApiUpsert: views.AgwApiUpsert }],
  [AGW.RESOURCES_AGW2_API_DEPLOY, { AgwApiDeploymentCreate: views.AgwApiDeploymentCreate }],
  [AGW.RESOURCES_AGW2_API_ROUTES, { AgwApiRoutes: views.AgwApiRoutes }],
  [AGW.RESOURCES_AGW2_API_ROUTE, { AgwApiRouteDetails: views.AgwApiRouteDetails }],
  [AGW.RESOURCES_AGW2_API_ROUTE_CREATE, { AgwApiRouteUpsert: views.AgwApiRouteUpsert }],
  [AGW.RESOURCES_AGW2_API_ROUTE_UPDATE, { AgwApiRouteUpsert: views.AgwApiRouteUpsert }],
  [AGW.RESOURCES_AGW2_API_INTEGRATIONS, { AgwApiIntegrations: views.AgwApiIntegrations }],
  [AGW.RESOURCES_AGW2_API_INTEGRATION, { AgwIntegrationDetails: views.AgwIntegrationDetails }],
  [AGW.RESOURCES_AGW2_API_INTEGRATION_CREATE, { AgwApiIntegrationUpsert: views.AgwApiIntegrationUpsert }],
  [AGW.RESOURCES_AGW2_API_INTEGRATION_UPDATE, { AgwApiIntegrationUpsert: views.AgwApiIntegrationUpsert }],
  [AGW.RESOURCES_AGW2_API_STAGES, { AgwApiStages: views.AgwApiStages }],
  [AGW.RESOURCES_AGW2_API_STAGE, { AgwApiStageDetails: views.AgwApiStageDetails }],
  [AGW.RESOURCES_AGW2_API_STAGE_CREATE, { AgwApiStageUpsert: views.AgwApiStageUpsert }],
  [AGW.RESOURCES_AGW2_API_STAGE_UPDATE, { AgwApiStageUpsert: views.AgwApiStageUpsert }],
  [AGW.RESOURCES_AGW2_API_MODELS, { AgwApiModels: views.AgwApiModels }],
  [AGW.RESOURCES_AGW2_API_MODEL, { AgwApiModelDetails: views.AgwApiModelDetails }],
  [AGW.RESOURCES_AGW2_API_MODEL_CREATE, { AgwApiModelUpsert: views.AgwApiModelUpsert }],
  [AGW.RESOURCES_AGW2_API_MODEL_UPDATE, { AgwApiModelUpsert: views.AgwApiModelUpsert }],
  [AGW.RESOURCES_AGW2_API_AUTHORIZERS, { AgwApiAuthorizers: views.AgwApiAuthorizers }],
  [AGW.RESOURCES_AGW2_API_AUTHORIZER, { AgwApiAuthorizerDetails: views.AgwApiAuthorizerDetails }],
  [AGW.RESOURCES_AGW2_API_AUTHORIZER_CREATE, { AgwApiAuthorizerUpsert: views.AgwApiAuthorizerUpsert }],
  [AGW.RESOURCES_AGW2_API_AUTHORIZER_UPDATE, { AgwApiAuthorizerUpsert: views.AgwApiAuthorizerUpsert }],
  // API Gateway V1
  [AGW.RESOURCES_AGW1, { AgwV1Index: views.AgwV1Index }],
  [AGW.RESOURCES_AGW1_API, { AgwRestApiDetails: views.AgwRestApiDetails }],
  [AGW.RESOURCES_AGW1_API_CREATE, { AgwRestApiUpsert: views.AgwRestApiUpsert }],
  [AGW.RESOURCES_AGW1_API_UPDATE, { AgwRestApiUpsert: views.AgwRestApiUpsert }],
  [AGW.RESOURCES_AGW1_API_DEPLOY, { AgwRestApiDeploymentCreate: views.AgwRestApiDeploymentCreate }],
  [AGW.RESOURCES_AGW1_API_RESOURCES, { AgwRestApiResources: views.AgwRestApiResources }],
  [AGW.RESOURCES_AGW1_API_RESOURCE, { AgwRestApiResourceDetails: views.AgwRestApiResourceDetails }],
  [AGW.RESOURCES_AGW1_API_RESOURCE_CREATE, { AgwRestApiResourceUpsert: views.AgwRestApiResourceUpsert }],
  [AGW.RESOURCES_AGW1_API_RESOURCE_UPDATE, { AgwRestApiResourceUpsert: views.AgwRestApiResourceUpsert }],
  [AGW.RESOURCES_AGW1_API_STAGES, { AgwRestApiStages: views.AgwRestApiStages }],
  [AGW.RESOURCES_AGW1_API_STAGE, { AgwRestApiStageDetails: views.AgwRestApiStageDetails }],
  [AGW.RESOURCES_AGW1_API_STAGE_CREATE, { AgwRestApiStageUpsert: views.AgwRestApiStageUpsert }],
  [AGW.RESOURCES_AGW1_API_STAGE_UPDATE, { AgwRestApiStageUpsert: views.AgwRestApiStageUpsert }],
  [AGW.RESOURCES_AGW1_API_AUTHORIZERS, { AgwRestApiAuthorizers: views.AgwRestApiAuthorizers }],
  [AGW.RESOURCES_AGW1_API_AUTHORIZER, { AgwRestApiAuthorizerDetails: views.AgwRestApiAuthorizerDetails }],
  [AGW.RESOURCES_AGW1_API_AUTHORIZER_CREATE, { AgwRestApiAuthorizerUpsert: views.AgwRestApiAuthorizerUpsert }],
  [AGW.RESOURCES_AGW1_API_AUTHORIZER_UPDATE, { AgwRestApiAuthorizerUpsert: views.AgwRestApiAuthorizerUpsert }],
  [AGW.RESOURCES_AGW1_API_MODELS, { AgwRestApiModels: views.AgwRestApiModels }],
  [AGW.RESOURCES_AGW1_API_MODEL, { AgwRestApiModelDetails: views.AgwRestApiModelDetails }],
  [AGW.RESOURCES_AGW1_API_MODEL_CREATE, { AgwRestApiModelUpsert: views.AgwRestApiModelUpsert }],
  [AGW.RESOURCES_AGW1_API_MODEL_UPDATE, { AgwRestApiModelUpsert: views.AgwRestApiModelUpsert }],
  [AGW.RESOURCES_AGW1_API_VALIDATORS, { AgwRestApiValidators: views.AgwRestApiValidators }],
  [AGW.RESOURCES_AGW1_API_VALIDATOR, { AgwRestApiValidatorDetails: views.AgwRestApiValidatorDetails }],
  [AGW.RESOURCES_AGW1_API_VALIDATOR_CREATE, { AgwRestApiValidatorUpsert: views.AgwRestApiValidatorUpsert }],
  [AGW.RESOURCES_AGW1_API_VALIDATOR_UPDATE, { AgwRestApiValidatorUpsert: views.AgwRestApiValidatorUpsert }],
  [AGW.RESOURCES_AGW1_API_USAGE_PLANS, { AgwRestApiUsagePlans: views.AgwRestApiUsagePlans }],
  [AGW.RESOURCES_AGW1_API_USAGE_PLAN, { AgwRestApiUsagePlanDetails: views.AgwRestApiUsagePlanDetails }],
  [AGW.RESOURCES_AGW1_API_USAGE_PLAN_CREATE, { AgwRestApiUsagePlanUpsert: views.AgwRestApiUsagePlanUpsert }],
  [AGW.RESOURCES_AGW1_API_USAGE_PLAN_UPDATE, { AgwRestApiUsagePlanUpsert: views.AgwRestApiUsagePlanUpsert }],
  [AGW.RESOURCES_AGW1_API_KEYS, { AgwRestApiKeys: views.AgwRestApiKeys }],
  [AGW.RESOURCES_AGW1_API_KEY_CREATE, { AgwRestApiKeyUpsert: views.AgwRestApiKeyUpsert }],
  [AGW.RESOURCES_AGW1_API_METHODS, { AgwRestApiMethods: views.AgwRestApiMethods }],
  [AGW.RESOURCES_AGW1_API_METHOD, { AgwRestApiMethodDetails: views.AgwRestApiMethodDetails }],
  [AGW.RESOURCES_AGW1_API_METHOD_CREATE, { AgwRestApiMethodUpsert: views.AgwRestApiMethodUpsert }],
  [AGW.RESOURCES_AGW1_API_METHOD_UPDATE, { AgwRestApiMethodUpsert: views.AgwRestApiMethodUpsert }],
  [AGW.RESOURCES_AGW1_API_INTEGRATION, { AgwRestApiIntegrationDetails: views.AgwRestApiIntegrationDetails }],
  [AGW.RESOURCES_AGW1_API_INTEGRATION_CREATE, { AgwRestApiIntegrationUpsert: views.AgwRestApiIntegrationUpsert }],
  [AGW.RESOURCES_AGW1_API_INTEGRATION_UPDATE, { AgwRestApiIntegrationUpsert: views.AgwRestApiIntegrationUpsert }],
  [
    AGW.RESOURCES_AGW1_API_INTEGRATION_RESPONSES,
    { AgwRestApiIntegrationResponses: views.AgwRestApiIntegrationResponses },
  ],
  [
    AGW.RESOURCES_AGW1_API_INTEGRATION_RESPONSE,
    { AgwRestApiIntegrationResponseDetails: views.AgwRestApiIntegrationResponseDetails },
  ],
  [
    AGW.RESOURCES_AGW1_API_INTEGRATION_RESPONSE_CREATE,
    { AgwRestApiIntegrationResponseUpsert: views.AgwRestApiIntegrationResponseUpsert },
  ],
  [
    AGW.RESOURCES_AGW1_API_INTEGRATION_RESPONSE_UPDATE,
    { AgwRestApiIntegrationResponseUpsert: views.AgwRestApiIntegrationResponseUpsert },
  ],
  [AGW.RESOURCES_AGW1_API_METHOD_RESPONSES, { AgwRestApiMethodResponses: views.AgwRestApiMethodResponses }],
  [AGW.RESOURCES_AGW1_API_METHOD_RESPONSE, { AgwRestApiMethodResponseDetails: views.AgwRestApiMethodResponseDetails }],
  [
    AGW.RESOURCES_AGW1_API_METHOD_RESPONSE_CREATE,
    { AgwRestApiMethodResponseUpsert: views.AgwRestApiMethodResponseUpsert },
  ],
  [
    AGW.RESOURCES_AGW1_API_METHOD_RESPONSE_UPDATE,
    { AgwRestApiMethodResponseUpsert: views.AgwRestApiMethodResponseUpsert },
  ],
]);

export const KMSViewMappings = new Map([
  [KMS.RESOURCES_KMS, { KMSKeys: views.KMSKeys }],
  [KMS.RESOURCES_KMS_KEY, { KMSKey: views.KMSKey }],
  [KMS.RESOURCES_KMS_KEYS, { KMSKeys: views.KMSKeys }],
  [KMS.RESOURCES_KMS_KEY_CREATE, { KMSKeyUpsert: views.KMSKeyUpsert }],
  [KMS.RESOURCES_KMS_KEY_UPDATE, { KMSKeyUpsert: views.KMSKeyUpsert }],
]);

export const S3ViewMappings = new Map([
  [S3.RESOURCES_S3, { S3Buckets: views.S3Buckets }],
  [S3.RESOURCES_S3_BUCKET, { S3Objects: views.S3Objects }],
  [S3.RESOURCES_S3_BUCKET_PREFIX, { S3Objects: views.S3Objects }],
  [S3.RESOURCES_S3_BUCKET_CREATE, { S3BucketCreate: views.S3BucketCreate }],
  [S3.RESOURCES_S3_FOLDER_CREATE, { S3FolderCreate: views.S3FolderCreate }],
  [S3.RESOURCES_S3_FOLDER_CREATE_PREFIX, { S3FolderCreate: views.S3FolderCreate }],
  [
    S3.RESOURCES_S3_BUCKET_PERMISSIONS,
    { S3BucketPermissionsCorsConfiguration: views.S3BucketPermissionsCorsConfiguration },
  ],
  [
    S3.RESOURCES_S3_BUCKET_PERMISSIONS_CORS_CONFIGURATION,
    { S3BucketPermissionsCorsConfiguration: views.S3BucketPermissionsCorsConfiguration },
  ],
]);

export const AppSyncViewMappings = new Map([
  [APPSYNC.RESOURCES_APPSYNC, { AppSyncApis: views.AppSyncApis }],
  [APPSYNC.RESOURCES_APPSYNC_API, { AppSyncApi: views.AppSyncApi }],
  [APPSYNC.RESOURCES_APPSYNC_API_CREATE, { AppSyncApiUpsert: views.AppSyncApiUpsert }],
  [APPSYNC.RESOURCES_APPSYNC_API_UPDATE, { AppSyncApiUpsert: views.AppSyncApiUpsert }],
  [APPSYNC.RESOURCES_APPSYNC_API_SCHEMA, { AppSyncApiSchema: views.AppSyncApiSchema }],
  [APPSYNC.RESOURCES_APPSYNC_API_QUERY, { AppSyncApiQuery: views.AppSyncApiQuery }],
  [APPSYNC.RESOURCES_APPSYNC_API_TYPES, { AppSyncApiTypes: views.AppSyncApiTypes }],
  [APPSYNC.RESOURCES_APPSYNC_API_TYPE_CREATE, { AppSyncApiTypeUpsert: views.AppSyncApiTypeUpsert }],
  [APPSYNC.RESOURCES_APPSYNC_API_TYPE_UPDATE, { AppSyncApiTypeUpsert: views.AppSyncApiTypeUpsert }],
  [APPSYNC.RESOURCES_APPSYNC_API_RESOLVERS, { AppSyncApiResolvers: views.AppSyncApiResolvers }],
  [APPSYNC.RESOURCES_APPSYNC_API_RESOLVER, { AppSyncApiResolver: views.AppSyncApiResolver }],
  [APPSYNC.RESOURCES_APPSYNC_API_RESOLVER_CREATE, { AppSyncApiResolverUpsert: views.AppSyncApiResolverUpsert }],
  [APPSYNC.RESOURCES_APPSYNC_API_RESOLVER_UPDATE, { AppSyncApiResolverUpsert: views.AppSyncApiResolverUpsert }],
  [APPSYNC.RESOURCES_APPSYNC_API_DATA_SOURCES, { AppSyncApiDataSources: views.AppSyncApiDataSources }],
  [APPSYNC.RESOURCES_APPSYNC_API_DATA_SOURCE, { AppSyncApiDataSource: views.AppSyncApiDataSource }],
  [APPSYNC.RESOURCES_APPSYNC_API_DATA_SOURCE_CREATE, { AppSyncApiDataSourceUpsert: views.AppSyncApiDataSourceUpsert }],
  [APPSYNC.RESOURCES_APPSYNC_API_DATA_SOURCE_UPDATE, { AppSyncApiDataSourceUpsert: views.AppSyncApiDataSourceUpsert }],
  [APPSYNC.RESOURCES_APPSYNC_API_FUNCTIONS, { AppSyncApiFunctions: views.AppSyncApiFunctions }],
  [APPSYNC.RESOURCES_APPSYNC_API_FUNCTION, { AppSyncApiFunction: views.AppSyncApiFunction }],
  [APPSYNC.RESOURCES_APPSYNC_API_FUNCTION_CREATE, { AppSyncApiFunctionUpsert: views.AppSyncApiFunctionUpsert }],
  [APPSYNC.RESOURCES_APPSYNC_API_FUNCTION_UPDATE, { AppSyncApiFunctionUpsert: views.AppSyncApiFunctionUpsert }],
  [APPSYNC.RESOURCES_APPSYNC_API_KEYS, { AppSyncApiKeys: views.AppSyncApiKeys }],
  [APPSYNC.RESOURCES_APPSYNC_API_KEY_CREATE, { AppSyncApiKeyUpsert: views.AppSyncApiKeyUpsert }],
]);

export const LambdaViewMappings = new Map([
  [LAMBDA.RESOURCES_LAMBDA_FUNCTION, { LambdaFunction: views.LambdaFunction }],
  [LAMBDA.RESOURCES_LAMBDA_FUNCTION_INVOKE, { LambdaFunctionInvoke: views.LambdaFunctionInvoke }],
  [LAMBDA.RESOURCES_LAMBDA_FUNCTIONS, { LambdaFunctions: views.LambdaFunctions }],
  [LAMBDA.RESOURCES_LAMBDA_FUNCTION_NEW, { LambdaFunctionCreate: views.LambdaFunctionCreate }],
  [LAMBDA.RESOURCES_LAMBDA_FUNCTION_UPDATE_CODE, { LambdaFunctionUpdateCode: views.LambdaFunctionUpdateCode }],
  [LAMBDA.RESOURCES_LAMBDA_LAYERS, { LambdaLayers: views.LambdaLayers }],
  [LAMBDA.RESOURCES_LAMBDA_LAYER_NEW, { LambdaLayerCreate: views.LambdaLayerCreate }],
  [LAMBDA.RESOURCES_LAMBDA_LAYER_VERSIONS, { LambdaLayerVersions: views.LambdaLayerVersions }],
]);

export const SNSViewMappings = new Map([
  [SNS.RESOURCES_SNS_TOPIC, { SNSTopic: views.SNSTopic }],
  [SNS.RESOURCES_SNS_TOPICS, { SNSTopics: views.SNSTopics }],
  [SNS.RESOURCES_SNS_TOPIC_CREATE, { SNSTopicCreate: views.SNSTopicCreate }],
  [SNS.RESOURCES_SNS_TOPIC_SUBSCRIPTION, { SNSSubscription: views.SNSSubscription }],
  [SNS.RESOURCES_SNS_TOPIC_SUBSCRIPTIONS, { SNSSubscriptions: views.SNSSubscriptions }],
  [SNS.RESOURCES_SNS_TOPIC_SUBSCRIPTION_CREATE, { SNSSubscriptionCreate: views.SNSSubscriptionCreate }],
  [SNS.RESOURCES_SNS_TOPIC_MESSAGE_SEND, { SNSMessagePublish: views.SNSMessagePublish }],
  [SNS.RESOURCES_SNS_TOPIC_MESSAGE_SEND_PREFILLED, { SNSMessagePublish: views.SNSMessagePublish }],
]);

export const BackupViewMappings = new Map([
  [BACKUP.RESOURCES_BACKUP_PLANS, { BackupPlans: views.BackupPlans }],
  [BACKUP.RESOURCES_BACKUP_PLAN, { BackupPlan: views.BackupPlan }],
  [BACKUP.RESOURCES_BACKUP_VAULTS, { BackupVaults: views.BackupVaults }],
  [BACKUP.RESOURCES_BACKUP_VAULT, { BackupVault: views.BackupVault }],
  [BACKUP.RESOURCES_BACKUP_PLAN_CREATE, { BackupPlanUpsert: views.BackupPlanUpsert }],
  [BACKUP.RESOURCES_BACKUP_PLAN_UPDATE, { BackupPlanUpsert: views.BackupPlanUpsert }],
  [BACKUP.RESOURCES_BACKUP_VAULT_CREATE, { BackupVaultCreate: views.BackupVaultCreate }],
  [BACKUP.RESOURCES_BACKUP_PLAN_ASSIGNMENT, { BackupPlanAssignment: views.BackupPlanAssignment }],
  [BACKUP.RESOURCES_BACKUP_JOB_CREATE, { BackupJobCreate: views.BackupJobCreate }],
]);

export const AthenaViewMappings = new Map([
  [ATHENA.RESOURCES_ATHENA_CATALOGS, { AthenaCatalogs: views.AthenaCatalogs }],
  [ATHENA.RESOURCES_ATHENA_DATABASES, { AthenaDatabases: views.AthenaDatabases }],
  [ATHENA.RESOURCES_ATHENA_DATABASE, { AthenaDatabase: views.AthenaDatabase }],
  [ATHENA.RESOURCES_ATHENA_CATALOG_CREATE, { AthenaCatalogUpsert: views.AthenaCatalogUpsert }],
  [ATHENA.RESOURCES_ATHENA_CATALOG_UPDATE, { AthenaCatalogUpsert: views.AthenaCatalogUpsert }],
  [ATHENA.RESOURCES_ATHENA_SQL, { AthenaSql: views.AthenaSql }],
]);

export const SQSViewMappings = new Map([
  [SQS.RESOURCES_SQS_QUEUE_MESSAGE, { SQSMessage: views.SQSMessage }],
  [SQS.RESOURCES_SQS_QUEUE_MESSAGES, { SQSMessages: views.SQSMessages }],
  [SQS.RESOURCES_SQS_QUEUE_MESSAGE_SEND, { SQSMessageSend: views.SQSMessageSend }],
  [SQS.RESOURCES_SQS_QUEUE_MESSAGE_SEND_1, { SQSMessageSend: views.SQSMessageSend }],
  [SQS.RESOURCES_SQS_QUEUE, { SQSQueue: views.SQSQueue }],
  [SQS.RESOURCES_SQS_QUEUE_CREATE, { SQSQueueCreate: views.SQSQueueCreate }],
  [SQS.RESOURCES_SQS_QUEUES, { SQSQueues: views.SQSQueues }],
]);

export const CloudFormationViewMappings = new Map([
  [CLOUDFORMATION.RESOURCES_CLOUDFORMATION, { CloudFormationStacks: views.CloudFormationStacks }],
  [CLOUDFORMATION.RESOURCES_CLOUDFORMATION_STACKS, { CloudFormationStacks: views.CloudFormationStacks }],
  [
    CLOUDFORMATION.RESOURCES_CLOUDFORMATION_STACK_CREATE,
    { CloudFormationStackUpsert: views.CloudFormationStackUpsert },
  ],
  [
    CLOUDFORMATION.RESOURCES_CLOUDFORMATION_STACK_UPDATE,
    { CloudFormationStackUpsert: views.CloudFormationStackUpsert },
  ],
  [CLOUDFORMATION.RESOURCES_CLOUDFORMATION_STACK_RESOURCES, { CloudFormationResources: views.CloudFormationResources }],
  [CLOUDFORMATION.RESOURCES_CLOUDFORMATION_STACK_OUTPUTS, { CloudFormationOutputs: views.CloudFormationOutputs }],
]);

export const RDSViewMappings = new Map([
  [RDS.RESOURCES_RDS, { RDSDatabases: views.RDSDatabases }],
  [RDS.RESOURCES_RDS_DATABASES, { RDSDatabases: views.RDSDatabases }],
  [RDS.RESOURCES_RDS_DATABASE, { RDSDatabase: views.RDSDatabase }],
  [RDS.RESOURCES_RDS_DATABASE_CREATE, { RDSDatabaseCreate: views.RDSDatabaseCreate }],
  [RDS.RESOURCES_RDS_CLUSTER, { RDSDBCluster: views.RDSDBCluster }],
  [RDS.RESOURCES_RDS_CLUSTER_CREATE, { RDSDBClusterUpsert: views.RDSDBClusterUpsert }],
  [RDS.RESOURCES_RDS_INSTANCE, { RDSDBInstance: views.RDSDBInstance }],
  [RDS.RESOURCES_RDS_INSTANCE_SQL, { RDSDBInstanceQuery: views.RDSDBInstanceQuery }],
  [RDS.RESOURCES_RDS_INSTANCE_CREATE, { RDSDBInstanceUpsert: views.RDSDBInstanceUpsert }],
  [RDS.RESOURCES_RDS_WITHOUT_CLUSTER_INSTANCE_CREATE, { RDSDBInstanceUpsert: views.RDSDBInstanceUpsert }],
  [RDS.RESOURCES_RDS_INSTANCE_UPDATE, { RDSDBInstanceUpsert: views.RDSDBInstanceUpsert }],
]);

export const CloudWatchViewMappings = new Map([
  [CLOUDWATCH.RESOURCES_CLOUDWATCH, { CloudWatchLogGroups: views.CloudWatchLogGroups }],
  [CLOUDWATCH.RESOURCES_CLOUDWATCH_GROUPS, { CloudWatchLogGroups: views.CloudWatchLogGroups }],
  [CLOUDWATCH.RESOURCES_CLOUDWATCH_GROUP_CREATE, { CloudWatchLogGroupUpsert: views.CloudWatchLogGroupUpsert }],
  [CLOUDWATCH.RESOURCES_CLOUDWATCH_GROUP_STREAMS, { CloudWatchLogStreams: views.CloudWatchLogStreams }],
  [CLOUDWATCH.RESOURCES_CLOUDWATCH_GROUP_STREAM_CREATE, { CloudWatchLogStreamUpsert: views.CloudWatchLogStreamUpsert }],
  [CLOUDWATCH.RESOURCES_CLOUDWATCH_GROUP_EVENTS, { CloudWatchLogGroupEvents: views.CloudWatchLogGroupEvents }],
  [CLOUDWATCH.RESOURCES_CLOUDWATCH_GROUP_STREAM_EVENTS, { CloudWatchLogGroupEvents: views.CloudWatchLogGroupEvents }],
]);

export const EC2ViewMappings = new Map([
  [EC2.RESOURCES_EC2, { EC2Instances: views.EC2Instances }],
  [EC2.RESOURCES_EC2_INSTANCES, { EC2Instances: views.EC2Instances }],
  [EC2.RESOURCES_EC2_INSTANCE, { EC2Instance: views.EC2Instance }],
  [EC2.RESOURCES_EC2_INSTANCE_NEW, { EC2InstanceUpsert: views.EC2InstanceUpsert }],
  [EC2.RESOURCES_EC2_LBV1, { LoadBalancersV1: views.LoadBalancersV1 }],
  [EC2.RESOURCES_EC2_LBV1_NEW, { LoadBalancerV1Upsert: views.LoadBalancerV1Upsert }],
  [EC2.RESOURCES_EC2_LBV2, { LoadBalancersV2: views.LoadBalancersV2 }],
  [EC2.RESOURCES_EC2_LBV2_NEW, { LoadBalancerV2Upsert: views.LoadBalancerV2Upsert }],
]);

export const ECSViewMappings = new Map([
  [ECS.RESOURCES_ECS, { ECSClusters: views.ECSClusters }],
  [ECS.RESOURCES_ECS_CLUSTERS, { ECSClusters: views.ECSClusters }],
  [ECS.RESOURCES_ECS_CLUSTER, { ECSCluster: views.ECSCluster }],
  [ECS.RESOURCES_ECS_CLUSTER_CREATE, { ECSClusterUpsert: views.ECSClusterUpsert }],
  [ECS.RESOURCES_ECS_CLUSTER_UPDATE, { ECSClusterUpsert: views.ECSClusterUpsert }],
  [ECS.RESOURCES_ECS_TASK_DEFINITIONS, { ECSTaskDefinitions: views.ECSTaskDefinitions }],
  [ECS.RESOURCES_ECS_TASK_DEFINITION, { ECSTaskDefinition: views.ECSTaskDefinition }],
  [ECS.RESOURCES_ECS_TASK_DEFINITION_CREATE, { ECSTaskDefinitionUpsert: views.ECSTaskDefinitionUpsert }],
  [ECS.RESOURCES_ECS_CLUSTER_SERVICES, { ECSClusterServices: views.ECSClusterServices }],
  [ECS.RESOURCES_ECS_CLUSTER_SERVICE, { ECSClusterService: views.ECSClusterService }],
  [ECS.RESOURCES_ECS_CLUSTER_SERVICE_CREATE, { ECSClusterServiceUpsert: views.ECSClusterServiceUpsert }],
  [ECS.RESOURCES_ECS_CLUSTER_SERVICE_UPDATE, { ECSClusterServiceUpsert: views.ECSClusterServiceUpsert }],
  [ECS.RESOURCES_ECS_CLUSTER_TASKS, { ECSClusterTasks: views.ECSClusterTasks }],
  [ECS.RESOURCES_ECS_CLUSTER_TASK, { ECSClusterTask: views.ECSClusterTask }],
  [ECS.RESOURCES_ECS_CLUSTER_TASK_DEPLOY, { ECSClusterTaskUpsert: views.ECSClusterTaskUpsert }],
]);

export const CognitoViewMappings = new Map([
  // Cognito
  [COGNITO.RESOURCES_COGNITO, { CognitoPools: views.CognitoPools }],
  [COGNITO.RESOURCES_COGNITO_USER_POOL, { CognitoPool: views.CognitoPool }],
  [COGNITO.RESOURCES_COGNITO_USER_POOL_CREATE, { CognitoPoolUpsert: views.CognitoPoolUpsert }],
  [COGNITO.RESOURCES_COGNITO_USER_POOL_UPDATE, { CognitoPoolUpsert: views.CognitoPoolUpsert }],
  [COGNITO.RESOURCES_COGNITO_POOL_USERS, { CognitoPoolUsers: views.CognitoPoolUsers }],
  [COGNITO.RESOURCES_COGNITO_POOL_USER, { CognitoPoolUser: views.CognitoPoolUser }],
  [COGNITO.RESOURCES_COGNITO_POOL_USER_CREATE, { CognitoPoolUserUpsert: views.CognitoPoolUserUpsert }],
  [COGNITO.RESOURCES_COGNITO_POOL_USER_UPDATE, { CognitoPoolUserUpsert: views.CognitoPoolUserUpsert }],
  [COGNITO.RESOURCES_COGNITO_POOL_USER_GROUPS, { CognitoPoolUserGroups: views.CognitoPoolUserGroups }],
  [COGNITO.RESOURCES_COGNITO_POOL_GROUPS, { CognitoPoolGroups: views.CognitoPoolGroups }],
  [COGNITO.RESOURCES_COGNITO_POOL_GROUP, { CognitoPoolGroup: views.CognitoPoolGroup }],
  [COGNITO.RESOURCES_COGNITO_POOL_GROUP_CREATE, { CognitoPoolGroupUpsert: views.CognitoPoolGroupUpsert }],
  [COGNITO.RESOURCES_COGNITO_POOL_GROUP_UPDATE, { CognitoPoolGroupUpsert: views.CognitoPoolGroupUpsert }],
  // Cognito Identity
  [COGNITO.RESOURCES_COGNITO_IDENTITY_POOLS, { CognitoIdentityPools: views.CognitoIdentityPools }],
  [COGNITO.RESOURCES_COGNITO_IDENTITY_POOL, { CognitoIdentityPool: views.CognitoIdentityPool }],
  [COGNITO.RESOURCES_COGNITO_IDENTITY_POOL_CREATE, { CognitoIdentityPoolUpsert: views.CognitoIdentityPoolUpsert }],
  [COGNITO.RESOURCES_COGNITO_IDENTITY_POOL_UPDATE, { CognitoIdentityPoolUpsert: views.CognitoIdentityPoolUpsert }],
]);

export const StepFunctionsViewMappings = new Map([
  [STEPFUNCTIONS.RESOURCES_STEPFUNCTIONS, { StateMachines: views.StateMachines }],
  [STEPFUNCTIONS.RESOURCES_STEPFUNCTIONS_STATE_MACHINE, { StateExecutions: views.StateExecutions }],
  [STEPFUNCTIONS.RESOURCES_STEPFUNCTIONS_STATE_MACHINES, { StateMachines: views.StateMachines }],
  [STEPFUNCTIONS.RESOURCES_STEPFUNCTIONS_STATE_MACHINE_CREATE, { StateMachineUpsert: views.StateMachineUpsert }],
  [STEPFUNCTIONS.RESOURCES_STEPFUNCTIONS_STATE_MACHINE_UPDATE, { StateMachineUpsert: views.StateMachineUpsert }],
  [STEPFUNCTIONS.RESOURCES_STEPFUNCTIONS_STATE_MACHINE_EXECUTIONS, { StateExecutions: views.StateExecutions }],
  [
    STEPFUNCTIONS.RESOURCES_STEPFUNCTIONS_STATE_MACHINE_EXECUTION_START,
    { StateExecutionStart: views.StateExecutionStart },
  ],
  [
    STEPFUNCTIONS.RESOURCES_STEPFUNCTIONS_STATE_MACHINE_DEFINITION,
    { StateDefinitionSchema: views.StateDefinitionSchema },
  ],
  [
    STEPFUNCTIONS.RESOURCES_STEPFUNCTIONS_STATE_MACHINE_DEFINITION_SCHEMA,
    { StateDefinitionSchema: views.StateDefinitionSchema },
  ],
  [
    STEPFUNCTIONS.RESOURCES_STEPFUNCTIONS_STATE_MACHINE_DEFINITION_FLOWCHART,
    { StateDefinitionFlowchart: views.StateDefinitionFlowchart },
  ],
  [
    STEPFUNCTIONS.RESOURCES_STEPFUNCTIONS_STATE_MACHINE_EXECUTION,
    { StateExecutionDetails: views.StateExecutionDetails },
  ],
  [
    STEPFUNCTIONS.RESOURCES_STEPFUNCTIONS_STATE_MACHINE_EXECUTION_EVENTS,
    { StateExecutionEvents: views.StateExecutionEvents },
  ],
]);

export const SecretsManagerViewMappings = new Map([
  [SECRETSMANAGER.RESOURCES_SECRETS_MANAGER, { SecretsManagerSecrets: views.SecretsManagerSecrets }],
  [SECRETSMANAGER.RESOURCES_SECRETS_MANAGER_SECRETS, { SecretsManagerSecrets: views.SecretsManagerSecrets }],
  [SECRETSMANAGER.RESOURCES_SECRETS_MANAGER_SECRET, { SecretsManagerSecret: views.SecretsManagerSecret }],
  [
    SECRETSMANAGER.RESOURCES_SECRETS_MANAGER_SECRET_CREATE,
    { SecretsManagerSecretUpsert: views.SecretsManagerSecretUpsert },
  ],
  [
    SECRETSMANAGER.RESOURCES_SECRETS_MANAGER_SECRET_UPDATE,
    { SecretsManagerSecretUpsert: views.SecretsManagerSecretUpsert },
  ],
]);

export const SESViewMappings = new Map([
  // SES V2
  [SES.RESOURCES_SES2, { SESV2Identities: views.SESV2Identities }],
  [SES.RESOURCES_SES2_IDENTITIES, { SESV2Identities: views.SESV2Identities }],
  [SES.RESOURCES_SES2_IDENTITY_CREATE, { SESV2IdentityUpsert: views.SESV2IdentityUpsert }],
  [SES.RESOURCES_SES2_IDENTITY_MESSAGES, { SESV2Messages: views.SESV2Messages }],
  [SES.RESOURCES_SES2_IDENTITY_MESSAGE_CREATE, { SESV2MessageUpsert: views.SESV2MessageUpsert }],

  // SES V1
  [SES.RESOURCES_SES1, { SESV1Identities: views.SESV1Identities }],
  [SES.RESOURCES_SES1_IDENTITIES, { SESV1Identities: views.SESV1Identities }],
  [SES.RESOURCES_SES1_IDENTITY_CREATE, { SESV1IdentityUpsert: views.SESV1IdentityUpsert }],
  [SES.RESOURCES_SES1_IDENTITY_MESSAGES, { SESV1Messages: views.SESV1Messages }],
  [SES.RESOURCES_SES1_IDENTITY_MESSAGE_CREATE, { SESV1MessageUpsert: views.SESV1MessageUpsert }],
]);

export const DynamoDBViewMappings = new Map([
  [DDB.RESOURCES_DYNAMODB, { DynamoDBTables: views.DynamoDBTables }],
  [DDB.RESOURCES_DYNAMODB_TABLE, { DynamoDBTable: views.DynamoDBTable }],
  [DDB.RESOURCES_DYNAMODB_TABLE_CREATE, { DynamoDBTableUpsert: views.DynamoDBTableUpsert }],
  [DDB.RESOURCES_DYNAMODB_TABLE_UPDATE, { DynamoDBTableUpsert: views.DynamoDBTableUpsert }],
  [DDB.RESOURCES_DYNAMODB_TABLE_ITEMS, { DynamoDBTableItems: views.DynamoDBTableItems }],
  [DDB.RESOURCES_DYNAMODB_TABLE_ITEM_CREATE, { DynamoDBTableItemUpsert: views.DynamoDBTableItemUpsert }],
  [DDB.RESOURCES_DYNAMODB_TABLE_ITEM_UPDATE, { DynamoDBTableItemUpsert: views.DynamoDBTableItemUpsert }],
  [DDB.RESOURCES_DYNAMODB_TABLE_ITEM_RANGE_UPDATE, { DynamoDBTableItemUpsert: views.DynamoDBTableItemUpsert }],
  [DDB.RESOURCES_DYNAMODB_TABLE_PARTIQL, { DynamoDBPartiQL: views.DynamoDBPartiQL }],
]);

export const CloudTrailViewMappings = new Map([
  [CLOUDTRAIL.RESOURCES_CLOUDTRAIL_EVENTS, { CloudTrailEvents: views.CloudTrailEvents }],
  [CLOUDTRAIL.RESOURCES_CLOUDTRAIL_TRAILS, { CloudTrailTrails: views.CloudTrailTrails }],
  [CLOUDTRAIL.RESOURCES_CLOUDTRAIL_TRAIL, { CloudTrailTrail: views.CloudTrailTrail }],
  [CLOUDTRAIL.RESOURCES_CLOUDTRAIL_TRAIL_CREATE, { CloudTrailCreate: views.CloudTrailCreate }],
  [CLOUDTRAIL.RESOURCES_CLOUDTRAIL_EVENT, { CloudTrailEvent: views.CloudTrailEvent }],
]);

export const ElastiCacheViewMappings = new Map([
  [ELASTICACHE.RESOURCES_ELASTICACHE, { ElastiCacheClusters: views.ElastiCacheClusters }],
  [ELASTICACHE.RESOURCES_ELASTICACHE_CLUSTERS, { ElastiCacheClusters: views.ElastiCacheClusters }],
  [ELASTICACHE.RESOURCES_ELASTICACHE_CLUSTER, { ElastiCacheCluster: views.ElastiCacheCluster }],
  [ELASTICACHE.RESOURCES_ELASTICACHE_CLUSTER_CREATE, { ElastiCacheClusterUpsert: views.ElastiCacheClusterUpsert }],
  [ELASTICACHE.RESOURCES_ELASTICACHE_CLUSTER_UPDATE, { ElastiCacheClusterUpsert: views.ElastiCacheClusterUpsert }],
]);

export const IAMViewMappings = new Map([
  [IAM.RESOURCES_IAM, { IAMUsers: views.IAMUsers }],
  [IAM.RESOURCES_IAM_USER, { IAMUserDetails: views.IAMUserDetails }],
  [IAM.RESOURCES_IAM_USER_CREATE, { IAMUserUpsert: views.IAMUserUpsert }],
  [IAM.RESOURCES_IAM_USER_UPDATE, { IAMUserUpsert: views.IAMUserUpsert }],
  [IAM.RESOURCES_IAM_GROUPS, { IAMGroups: views.IAMGroups }],
  [IAM.RESOURCES_IAM_GROUP, { IAMGroupDetails: views.IAMGroupDetails }],
  [IAM.RESOURCES_IAM_GROUP_CREATE, { IAMGroupUpsert: views.IAMGroupUpsert }],
  [IAM.RESOURCES_IAM_GROUP_UPDATE, { IAMGroupUpsert: views.IAMGroupUpsert }],
  [IAM.RESOURCES_IAM_ROLES, { IAMRoles: views.IAMRoles }],
  [IAM.RESOURCES_IAM_ROLE, { IAMRoleDetails: views.IAMRoleDetails }],
  [IAM.RESOURCES_IAM_ROLE_POLICIES, { IAMRolePolicies: views.IAMRolePolicies }],
  [IAM.RESOURCES_IAM_ROLE_POLICIES_ATTACH, { IAMRolePolicyAttach: views.IAMRolePolicyAttach }],
  [IAM.RESOURCES_IAM_ROLE_INLINE_POLICY_CREATE, { IAMRolePolicyUpsert: views.IAMRolePolicyUpsert }],
  [IAM.RESOURCES_IAM_ROLE_INLINE_POLICY_UPDATE, { IAMRolePolicyUpsert: views.IAMRolePolicyUpsert }],
  [IAM.RESOURCES_IAM_ROLE_CREATE, { IAMRoleUpsert: views.IAMRoleUpsert }],
  [IAM.RESOURCES_IAM_ROLE_UPDATE, { IAMRoleUpsert: views.IAMRoleUpsert }],
  [IAM.RESOURCES_IAM_POLICIES, { IAMPolicies: views.IAMPolicies }],
  [IAM.RESOURCES_IAM_POLICY, { IAMPolicyDetails: views.IAMPolicyDetails }],
  [IAM.RESOURCES_IAM_POLICY_VERSION_UPDATE, { IAMPolicyVersionUpdate: views.IAMPolicyVersionUpdate }],
  [IAM.RESOURCES_IAM_POLICY_CREATE, { IAMPolicyCreate: views.IAMPolicyCreate }],
]);

export const KinesisViewMappings = new Map([
  [KINESIS.RESOURCES_KINESIS, { KinesisStreams: views.KinesisStreams }],
  [KINESIS.RESOURCES_KINESIS_STREAMS, { KinesisStreams: views.KinesisStreams }],
  [KINESIS.RESOURCES_KINESIS_STREAM, { KinesisStream: views.KinesisStream }],
  [KINESIS.RESOURCES_KINESIS_STREAM_CREATE, { KinesisStreamUpsert: views.KinesisStreamUpsert }],
  [KINESIS.RESOURCES_KINESIS_STREAM_UPDATE, { KinesisStreamUpsert: views.KinesisStreamUpsert }],
]);

export const KafkaViewMappings = new Map([
  [KAFKA.RESOURCES_KAFKA, { KafkaClusters: views.KafkaClusters }],
  [KAFKA.RESOURCES_KAFKA_CLUSTERS, { KafkaClusters: views.KafkaClusters }],
  [KAFKA.RESOURCES_KAFKA_CLUSTER, { KafkaCluster: views.KafkaCluster }],
  [KAFKA.RESOURCES_KAFKA_CLUSTER_CREATE, { KafkaClusterUpsert: views.KafkaClusterUpsert }],
  [KAFKA.RESOURCES_KAFKA_CLUSTER_UPDATE, { KafkaClusterUpsert: views.KafkaClusterUpsert }],
]);

export const EventBridgeViewMappings = new Map([
  [EVENTBRIDGE.RESOURCES_EVENT_BRIDGE, { EventBridgeBuses: views.EventBridgeBuses }],
  [EVENTBRIDGE.RESOURCES_EVENT_BRIDGE_BUSES, { EventBridgeBuses: views.EventBridgeBuses }],
  [EVENTBRIDGE.RESOURCES_EVENT_BRIDGE_BUS, { EventBridgeBus: views.EventBridgeBus }],
  [EVENTBRIDGE.RESOURCES_EVENT_BRIDGE_BUS_CREATE, { EventBridgeBusUpsert: views.EventBridgeBusUpsert }],
  [EVENTBRIDGE.RESOURCES_EVENT_BRIDGE_RULES, { EventBridgeRules: views.EventBridgeRules }],
  [EVENTBRIDGE.RESOURCES_EVENT_BRIDGE_RULE_CREATE, { EventBridgeRuleUpsert: views.EventBridgeRuleUpsert }],
  [EVENTBRIDGE.RESOURCES_EVENT_BRIDGE_RULE_UPDATE, { EventBridgeRuleUpsert: views.EventBridgeRuleUpsert }],
  [EVENTBRIDGE.RESOURCES_EVENT_BRIDGE_RULE_TARGET, { EventBridgeTarget: views.EventBridgeTarget }],
  [EVENTBRIDGE.RESOURCES_EVENT_BRIDGE_RULE_TARGETS, { EventBridgeTargets: views.EventBridgeTargets }],
  [EVENTBRIDGE.RESOURCES_EVENT_BRIDGE_RULE_TARGET_CREATE, { EventBridgeTargetUpsert: views.EventBridgeTargetUpsert }],
  [EVENTBRIDGE.RESOURCES_EVENT_BRIDGE_RULE_TARGET_UPDATE, { EventBridgeTargetUpsert: views.EventBridgeTargetUpsert }],
  [EVENTBRIDGE.RESOURCES_EVENT_BRIDGE_BUS_TRIGGER, { EventBridgeEventUpsert: views.EventBridgeEventUpsert }],
  [EVENTBRIDGE.RESOURCES_EVENT_BRIDGE_PIPES, { EventBridgePipes: views.EventBridgePipes }],
  [EVENTBRIDGE.RESOURCES_EVENT_BRIDGE_PIPE, { EventBridgePipe: views.EventBridgePipe }],
  [EVENTBRIDGE.RESOURCES_EVENT_BRIDGE_PIPES_CREATE, { EventBridgePipe: views.EventBridgePipeCreate }],
]);

export const MetricsViewMappings = new Map([
  [METRICS.RESOURCES_CLOUDWATCH_MONITORING, { Metrics: views.Metrics }],
  [METRICS.RESOURCES_CLOUDWATCH_MONITORING_METRICS, { Metrics: views.Metrics }],
  [METRICS.RESOURCES_CLOUDWATCH_MONITORING_METRIC, { Metric: views.Metric }],
  [METRICS.RESOURCES_CLOUDWATCH_MONITORING_METRIC_CREATE, { MetricUpsert: views.MetricUpsert }],
  [METRICS.RESOURCES_CLOUDWATCH_MONITORING_ALARMS, { MetricAlarms: views.MetricAlarms }],
  [METRICS.RESOURCES_CLOUDWATCH_MONITORING_ALARM, { MetricAlarm: views.MetricAlarm }],
  [METRICS.RESOURCES_CLOUDWATCH_MONITORING_ALARM_CREATE, { MetricAlarmUpsert: views.MetricAlarmUpsert }],
]);

export const MQViewMappings = new Map([
  [MQ.RESOURCES_MQ_BROKERS, { MQBrokers: views.MQBrokers }],
  [MQ.RESOURCES_MQ_BROKER_CREATE, { MQBrokerCreate: views.MQBrokerCreate }],
  [MQ.RESOURCES_MQ_BROKER, { MQBroker: views.MQBroker }],
]);

export const SystemParametersViewMappings = new Map([
  [SP.RESOURCES_SYSTEM_PARAMETERS, { SystemParameters: views.SystemParameters }],
  [SP.RESOURCES_SYSTEM_PARAMETER, { SystemParameter: views.SystemParameter }],
  [SP.RESOURCES_SYSTEM_PARAMETER_CREATE, { SystemParameterUpsert: views.SystemParameterUpsert }],
]);

export const TimestreamDBViewMappings = new Map([
  [TIMESTREAM.RESOURCES_TIMESTREAM, { TimestreamDatabases: views.TimestreamDatabases }],
  [TIMESTREAM.RESOURCES_TIMESTREAM_DATABASE, { TimestreamTables: views.TimestreamTables }],
  [TIMESTREAM.RESOURCES_TIMESTREAM_TABLE, { TimestreamQuery: views.TimestreamQuery }],
  [TIMESTREAM.RESOURCES_TIMESTREAM_TABLE_CREATE, { TimestreamTableCreate: views.TimestreamTableCreate }],
  [TIMESTREAM.RESOURCES_TIMESTREAM_DATABASE_CREATE, { TimestreamDatabaseCreate: views.TimestreamDatabaseCreate }],
]);

export const GlueMappings = new Map([
  [GLUE.RESOURCES_GLUE, { GlueDatabases: views.GlueDatabases }],
  [GLUE.RESOURCES_GLUE_DATABASES, { GlueDatabases: views.GlueDatabases }],
  [GLUE.RESOURCES_GLUE_DATABASE_CREATE, { GlueDatabaseCreate: views.GlueDatabaseCreate }],
  [GLUE.RESOURCES_GLUE_DATABASE_TABLES, { GlueTables: views.GlueTables }],
  [GLUE.RESOURCES_GLUE_TABLES, { GlueTables: views.GlueTables }],
  [GLUE.RESOURCES_GLUE_DATABASE_TABLE_CREATE, { GlueTableUpsert: views.GlueTableUpsert }],
  [GLUE.RESOURCES_GLUE_TABLE_CREATE, { GlueTableUpsert: views.GlueTableUpsert }],
  [GLUE.RESOURCES_GLUE_TABLE_UPDATE, { GlueTableUpsert: views.GlueTableUpsert }],
  [GLUE.RESOURCES_GLUE_TABLE, { GlueTableDetails: views.GlueTableDetails }],
  [GLUE.RESOURCES_GLUE_CONNECTIONS, { GlueConnections: views.GlueConnections }],
  [GLUE.RESOURCES_GLUE_CONNECTION, { GlueConnectionDetails: views.GlueConnectionDetails }],
  [GLUE.RESOURCES_GLUE_CONNECTION_CREATE, { GlueConnectionUpsert: views.GlueConnectionUpsert }],
  [GLUE.RESOURCES_GLUE_CONNECTION_UPDATE, { GlueConnectionUpsert: views.GlueConnectionUpsert }],
  [GLUE.RESOURCES_GLUE_CRAWLERS, { GlueCrawlers: views.GlueCrawlers }],
  [GLUE.RESOURCES_GLUE_CRAWLER_CREATE, { GlueCrawlerUpsert: views.GlueCrawlerUpsert }],
  [GLUE.RESOURCES_GLUE_CRAWLER_UPDATE, { GlueCrawlerUpsert: views.GlueCrawlerUpsert }],
  [GLUE.RESOURCES_GLUE_CRAWLER, { GlueCrawlerDetails: views.GlueCrawlerDetails }],
  [GLUE.RESOURCES_GLUE_JOBS, { GlueJobs: views.GlueJobs }],
  [GLUE.RESOURCES_GLUE_JOB_CREATE, { GlueJobUpsert: views.GlueJobUpsert }],
  [GLUE.RESOURCES_GLUE_JOB_UPDATE, { GlueJobUpsert: views.GlueJobUpsert }],
  [GLUE.RESOURCES_GLUE_JOB, { GlueJobDetails: views.GlueJobDetails }],
  [GLUE.RESOURCES_GLUE_JOB_RUNS, { GlueJobRuns: views.GlueJobRuns }],
  [GLUE.RESOURCES_GLUE_JOB_RUN, { GlueJobRunDetails: views.GlueJobRunDetails }],
  [GLUE.RESOURCES_GLUE_REGISTRIES, { GlueRegistries: views.GlueRegistries }],
  [GLUE.RESOURCES_GLUE_REGISTRY, { GlueRegistryDetails: views.GlueRegistryDetails }],
  [GLUE.RESOURCES_GLUE_REGISTRY_CREATE, { GlueRegistryUpsert: views.GlueRegistryUpsert }],
  [GLUE.RESOURCES_GLUE_REGISTRY_UPDATE, { GlueRegistryUpsert: views.GlueRegistryUpsert }],
  [GLUE.RESOURCES_GLUE_SCHEMAS, { GlueSchemas: views.GlueSchemas }],
  [GLUE.RESOURCES_GLUE_SCHEMA_CREATE, { GlueSchemaUpsert: views.GlueSchemaUpsert }],
  [GLUE.RESOURCES_GLUE_SCHEMA_UPDATE, { GlueSchemaUpsert: views.GlueSchemaUpsert }],
  [GLUE.RESOURCES_GLUE_SCHEMA, { GlueSchemaDetails: views.GlueSchemaDetails }],
  [GLUE.RESOURCES_GLUE_SCHEMA_VERSIONS, { GlueSchemaVersions: views.GlueSchemaVersions }],
  [GLUE.RESOURCES_GLUE_SCHEMA_VERSION, { GlueSchemaVersionDetails: views.GlueSchemaVersionDetails }],
  [GLUE.RESOURCES_GLUE_SCHEMA_VERSION_CREATE, { GlueSchemaVersionCreate: views.GlueSchemaVersionCreate }],
  [GLUE.RESOURCES_GLUE_TABLE_PARTITIONS, { GlueTablePartitions: views.GlueTablePartitions }],
  [GLUE.RESOURCES_GLUE_SCHEMA_VERSION_METADATA, { GlueSchemaVersionMetadata: views.GlueSchemaVersionMetadata }],
  [
    GLUE.RESOURCES_GLUE_SCHEMA_VERSION_METADATA_CREATE,
    { GlueSchemaVersionMetadataUpsert: views.GlueSchemaVersionMetadataUpsert },
  ],
  [
    GLUE.RESOURCES_GLUE_SCHEMA_VERSION_METADATA_UPDATE,
    { GlueSchemaVersionMetadataUpsert: views.GlueSchemaVersionMetadataUpsert },
  ],
]);

export const ECRViewMappings = new Map([
  [ECR.RESOURCES_ECR_REPOSITORIES, { ECRRepositories: views.ECRRepositories }],
  [ECR.RESOURCES_ECR_REPOSITORY_NEW, { ECRRepositoryCreate: views.ECRRepositoryCreate }],
  [ECR.RESOURCES_ECR_IMAGES, { ECRImages: views.ECRImages }],
  [ECR.RESOURCES_ECR_IMAGE_NEW, { ECRImageCreate: views.ECRImageCreate }],
]);

export const NeptuneViewMappings = new Map([
  [NEPTUNE.RESOURCES_NEPTUNE_INSTANCES, { NeptuneDBInstances: views.NeptuneDBInstances }],
  [NEPTUNE.RESOURCES_NEPTUNE_INSTANCE_CREATE, { NeptuneDBInstanceUpsert: views.NeptuneDBInstanceUpsert }],
  [NEPTUNE.RESOURCES_NEPTUNE_INSTANCE_UPDATE, { NeptuneDBInstanceUpsert: views.NeptuneDBInstanceUpsert }],
  [NEPTUNE.RESOURCES_NEPTUNE_CLUSTER_CREATE, { NeptuneDBClusterUpsert: views.NeptuneDBClusterUpsert }],
  [NEPTUNE.RESOURCES_NEPTUNE_CLUSTER_UPDATE, { NeptuneDBClusterUpsert: views.NeptuneDBClusterUpsert }],
  [NEPTUNE.RESOURCES_NEPTUNE_CLUSTERS, { NeptuneDBClusters: views.NeptuneDBClusters }],
  [NEPTUNE.RESOURCES_NEPTUNE_CLUSTER, { NeptuneDBCluster: views.NeptuneDBCluster }],
  [NEPTUNE.RESOURCES_NEPTUNE_INSTANCE, { NeptuneDBInstance: views.NeptuneDBInstance }],
  [NEPTUNE.RESOURCES_NEPTUNE_CLUSTER_GRAPH_BROWSER, { NeptuneDBGraphBrowser: views.NeptuneDBGraphBrowser }],
  [NEPTUNE.RESOURCES_NEPTUNE_CLUSTER_QUICK_ACTIONS, { NeptuneDBClusterQuickAction: views.NeptuneDBClusterQuickAction }],
]);

export const SageMakerViewMappings = new Map([
  [SAGEMAKER.RESOURCES_SGM_MODELS, { SGMModels: views.SGMModels }],
  [SAGEMAKER.RESOURCES_SGM_MODEL_NEW, { SageMakerModelCreate: views.SageMakerModelCreate }],
  [SAGEMAKER.RESOURCES_SGM_ENDPOINTS, { SGMEndpoints: views.SGMEndpoints }],
  [SAGEMAKER.RESOURCES_SGM_ENDPOINT_NEW, { SageMakerEndpointCreate: views.SageMakerEndpointCreate }],
  [SAGEMAKER.RESOURCES_SGM_ENDPOINT_CONFIGS, { SGMEndpointConfigs: views.SGMEndpointConfigs }],
  [SAGEMAKER.RESOURCES_SGM_ENDPOINT_CONFIG_NEW, { SageMakerEndpointConfigCreate: views.SageMakerEndpointConfigCreate }],
]);

export const ApplicationAutoscalingViewMappings = new Map([
  [APPLICATION_AUTO_SCALING.RESOURCES_APPLICATION_AUTO_SCALING, { ScalableTargets: views.ScalableTargets }],
  [
    APPLICATION_AUTO_SCALING.RESOURCES_APPLICATION_AUTO_SCALING_NEW,
    { ScalableTargetCreate: views.ScalableTargetCreate },
  ],
]);

export const CloudFrontViewMappings = new Map([
  [CLOUDFRONT.RESOURCES_CLOUDFRONT_DISTRIBUTIONS, { CloudFrontDistributions: views.CloudFrontDistributions }],
  [CLOUDFRONT.RESOURCES_CLOUDFRONT_DISTRIBUTION, { CloudFrontDistribution: views.CloudFrontDistribution }],
  [
    CLOUDFRONT.RESOURCES_CLOUDFRONT_DISTRIBUTIONS_CREATE,
    { CloudFrontDistributionCreate: views.CloudFrontDistributionCreate },
  ],
  [
    CLOUDFRONT.RESOURCES_CLOUDFRONT_DISTRIBUTION_UPDATE,
    { CloudFrontDistributionCreate: views.CloudFrontDistributionCreate },
  ],
]);

export const Route53ViewMappings = new Map([
  [ROUTE53.RESOURCES_ROUTE53, { Route53HostedZones: views.Route53HostedZones }],
  [ROUTE53.RESOURCES_ROUTE53_HOSTED_ZONES, { Route53HostedZones: views.Route53HostedZones }],
  [ROUTE53.RESOURCES_ROUTE53_HOSTED_ZONE_CREATE, { Route53HostedZoneCreate: views.Route53HostedZoneCreate }],
  [ROUTE53.RESOURCES_ROUTE53_HOSTED_ZONE, { Route53HostedZoneDetails: views.Route53HostedZoneDetails }],
  [ROUTE53.RESOURCES_ROUTE53_HOSTED_ZONE_RECORDS, { Route53HostedZoneRecords: views.Route53HostedZoneRecords }],
  [
    ROUTE53.RESOURCES_ROUTE53_HOSTED_ZONE_RECORD,
    { Route53HostedZoneRecordDetails: views.Route53HostedZoneRecordDetails },
  ],
  [
    ROUTE53.RESOURCES_ROUTE53_HOSTED_ZONE_RECORD_CREATE,
    { Route53HostedZoneRecordUpsert: views.Route53HostedZoneRecordUpsert },
  ],
  [
    ROUTE53.RESOURCES_ROUTE53_HOSTED_ZONE_RECORD_UPDATE,
    { Route53HostedZoneRecordUpsert: views.Route53HostedZoneRecordUpsert },
  ],
  [ROUTE53.RESOURCES_ROUTE53RESOLVER, { Route53ResolverEndpoints: views.Route53ResolverEndpoints }],
  [ROUTE53.RESOURCES_ROUTE53RESOLVER_ENDPOINTS, { Route53ResolverEndpoints: views.Route53ResolverEndpoints }],
  [
    ROUTE53.RESOURCES_ROUTE53RESOLVER_ENDPOINT_CREATE,
    { Route53ResolverEndpointUpsert: views.Route53ResolverEndpointUpsert },
  ],
  [
    ROUTE53.RESOURCES_ROUTE53RESOLVER_ENDPOINT_UPDATE,
    { Route53ResolverEndpointUpsert: views.Route53ResolverEndpointUpsert },
  ],
  [ROUTE53.RESOURCES_ROUTE53RESOLVER_ENDPOINT, { Route53ResolverEndpoint: views.Route53ResolverEndpoint }],
]);

export const OpenSearchViewMappings = new Map([
  [OPENSEARCH.RESOURCES_OPENSEARCH_DOMAINS, { OpenSearchDomains: views.OpenSearchDomains }],
  [OPENSEARCH.RESOURCES_OPENSEARCH_DOMAIN_CREATE, { OpenSearchDomainUpsert: views.OpenSearchDomainUpsert }],
  [OPENSEARCH.RESOURCES_OPENSEARCH_DOMAIN, { OpenSearchDomain: views.OpenSearchDomain }],
  [OPENSEARCH.RESOURCES_OPENSEARCH_DOMAIN_UPDATE, { OpenSearchDomainUpsert: views.OpenSearchDomainUpsert }],
]);

export const QLDBViewMappings = new Map([
  [QLDB.RESOURCES_QLDB_LEDGERS, { QLDBLedgers: views.QLDBLedgers }],
  [QLDB.RESOURCES_QLDB_LEDGER_CREATE, { QLDBLedgerUpsert: views.QLDBLedgerUpsert }],
  [QLDB.RESOURCES_QLDB_LEDGER, { QLDBLedger: views.QLDBLedger }],
  [QLDB.RESOURCES_QLDB_LEDGER_UPDATE, { QLDBLedgerUpsert: views.QLDBLedgerUpsert }],
]);

export const DocDBViewMappings = new Map([
  [DOCDB.RESOURCES_DOCDB_INSTANCE, { DocDBInstance: views.DocDBInstance }],
  [DOCDB.RESOURCES_DOCDB_INSTANCE_CREATE, { DocDBInstanceUpsert: views.DocDBInstanceUpsert }],
  [DOCDB.RESOURCES_DOCDB_INSTANCE_UPDATE, { DocDBInstanceUpsert: views.DocDBInstanceUpsert }],
  [DOCDB.RESOURCES_DOCDB_CLUSTERS, { DocDBClusters: views.DocDBClusters }],
  [DOCDB.RESOURCES_DOCDB_CLUSTER, { DocDBCluster: views.DocDBCluster }],
  [DOCDB.RESOURCES_DOCDB_CLUSTER_CREATE, { DocDBClusterUpsert: views.DocDBClusterUpsert }],
  [DOCDB.RESOURCES_DOCDB_CLUSTER_UPDATE, { DocDBClusterUpsert: views.DocDBClusterUpsert }],
]);

export const AppConfigViewMappings = new Map([
  [APPCONFIG.RESOURCES_APPCONFIG_APPLICATIONS, { AppConfigApplications: views.AppConfigApplications }],
  [APPCONFIG.RESOURCES_APPCONFIG_APPLICATION, { AppConfigApplication: views.AppConfigApplication }],
  [APPCONFIG.RESOURCES_APPCONFIG_APPLICATION_CREATE, { AppConfigApplicationUpsert: views.AppConfigApplicationUpsert }],
  [APPCONFIG.RESOURCES_APPCONFIG_APPLICATION_UPDATE, { AppConfigApplicationUpsert: views.AppConfigApplicationUpsert }],
]);

export const ACMViewMappings = new Map([
  [ACM.RESOURCES_ACM_CERTIFICATES, { ACMCertificates: views.ACMCertificates }],
  [ACM.RESOURCES_ACM_CERTIFICATE, { ACMCertificate: views.ACMCertificate }],
  [ACM.RESOURCES_ACM_CERTIFICATE_CREATE, { ACMCertificateCreate: views.ACMCertificateCreate }],
]);

export const MWAAViewMappings = new Map([
  [MWAA.RESOURCES_MWAA_ENVIRONMENTS, { MWAAEnvironments: views.MWAAEnvironments }],
  [MWAA.RESOURCES_MWAA_ENVIRONMENT, { MWAAEnvironment: views.MWAAEnvironment }],
  [MWAA.RESOURCES_MWAA_ENVIRONMENT_CREATE, { MWAAEnvironmentUpsert: views.MWAAEnvironmentUpsert }],
  [MWAA.RESOURCES_MWAA_ENVIRONMENT_UPDATE, { MWAAEnvironmentUpsert: views.MWAAEnvironmentUpsert }],
]);

export const AmplifyViewMappings = new Map([
  [AMPLIFY.RESOURCES_AMPLIFY_APPS, { AmplifyApps: views.AmplifyApps }],
  [AMPLIFY.RESOURCES_AMPLIFY_APP, { AmplifyApp: views.AmplifyApp }],
  [AMPLIFY.RESOURCES_AMPLIFY_APP_CREATE, { AmplifyAppUpsert: views.AmplifyAppUpsert }],
  [AMPLIFY.RESOURCES_AMPLIFY_APP_UPDATE, { AmplifyAppUpsert: views.AmplifyAppUpsert }],
  // [AMPLIFY.RESOURCES_AMPLIFY_BE_ENVIRONMENT, { 'AmplifyBEEnvironment': views.AmplifyBEEnvironment }],
  // [AMPLIFY.RESOURCES_AMPLIFY_BE_ENVIRONMENTS, { 'AmplifyBEEnvironments': views.AmplifyBEEnvironments }],
  // [AMPLIFY.RESOURCES_AMPLIFY_BE_ENVIRONMENT_CREATE, { 'AmplifyBEEnvironmentCreate': views.AmplifyBEEnvironmentCreate }],
]);

export const EKSViewMappings = new Map([
  [EKS.RESOURCES_EKS_CLUSTERS, { EKSClusters: views.EKSClusters }],
  [EKS.RESOURCES_EKS_CLUSTER, { EKSCluster: views.EKSCluster }],
  [EKS.RESOURCES_EKS_CLUSTER_CREATE, { EKSClusterUpsert: views.EKSClusterUpsert }],
  [EKS.RESOURCES_EKS_CLUSTER_UPDATE, { EKSClusterUpsert: views.EKSClusterUpsert }],
]);

export const TranscribeViewMappings = new Map([
  [TRANSCRIBE.RESOURCES_TRANSCRIBE_TRANSCRIPTION_JOBS, { TranscriptionJobs: views.TranscriptionJobs }],
  [TRANSCRIBE.RESOURCES_TRANSCRIBE_TRANSCRIPTION_JOB, { TranscriptionJob: views.TranscriptionJob }],
  [TRANSCRIBE.RESOURCES_TRANSCRIBE_TRANSCRIPTION_JOB_CREATE, { TranscriptionJobCreate: views.TranscriptionJobCreate }],
]);

export const CodeCommitViewMappings = new Map([
  [CODECOMMIT.RESOURCES_CODECOMMIT_REPOSITORIES, { CodeCommitRepositories: views.CodeCommitRepositories }],
  [CODECOMMIT.RESOURCES_CODECOMMIT_REPOSITORY, { CodeCommitRepository: views.CodeCommitRepository }],
  [CODECOMMIT.RESOURCES_CODECOMMIT_REPOSITORY_CREATE, { CodeCommitRepositoryUpsert: views.CodeCommitRepositoryUpsert }],
  [CODECOMMIT.RESOURCES_CODECOMMIT_REPOSITORY_UPDATE, { CodeCommitRepositoryUpsert: views.CodeCommitRepositoryUpsert }],
]);

export const CEViewMappings = new Map([
  [CE.RESOURCES_CE_COST_CATEGORY_DEFINITIONS, { CECostCategoryDefinitions: views.CECostCategoryDefinitions }],
  [CE.RESOURCES_CE_COST_CATEGORY_DEFINITION, { CECostCategoryDefinition: views.CECostCategoryDefinition }],
  [
    CE.RESOURCES_CE_COST_CATEGORY_DEFINITION_CREATE,
    { CECostCategoryDefinitionUpsert: views.CECostCategoryDefinitionUpsert },
  ],
  [
    CE.RESOURCES_CE_COST_CATEGORY_DEFINITION_UPDATE,
    { CECostCategoryDefinitionUpsert: views.CECostCategoryDefinitionUpsert },
  ],
]);

export const AccountViewMappings = new Map([
  [ACCOUNT.RESOURCES_ACCOUNT_CONTACTINFO, { ContactInformation: views.ContactInformation }],
  [ACCOUNT.RESOURCES_ACCOUNT_CONTACTINFO_FORM, { ContactInformationUpsert: views.ContactInformationUpsert }],
  [ACCOUNT.RESOURCES_ALTERNATE_CONTACT, { AlternateContact: views.AlternateContact }],
  [ACCOUNT.RESOURCES_ALTERNATE_CONTACT_FORM, { AlternateContactUpsert: views.AlternateContactUpsert }],
]);

export const DMSViewMappings = new Map([
  [DMS.RESOURCES_DMS_REPLICATION_INSTANCES, { DMSReplicationInstances: views.DMSReplicationInstances }],
  [DMS.RESOURCES_DMS_REPLICATION_INSTANCE, { DMSReplicationInstance: views.DMSReplicationInstance }],
  [DMS.RESOURCES_DMS_REPLICATION_INSTANCE_CREATE, { DMSReplicationInstanceCreate: views.DMSReplicationInstanceCreate }],
  [DMS.RESOURCES_DMS_ENDPOINTS, { DMSEndpoints: views.DMSEndpoints }],
  [DMS.RESOURCES_DMS_ENDPOINT, { DMSEndpoint: views.DMSEndpoint }],
  [DMS.RESOURCES_DMS_ENDPOINT_CREATE, { DMSEndpointCreate: views.DMSEndpointCreate }],
  [DMS.RESOURCES_DMS_REPLICATION_TASKS, { DMSReplicationTasks: views.DMSReplicationTasks }],
  [DMS.RESOURCES_DMS_REPLICATION_TASK, { DMSReplicationTask: views.DMSReplicationTask }],
  [DMS.RESOURCES_DMS_REPLICATION_TASK_CREATE, { DMSReplicationTaskCreate: views.DMSReplicationTaskCreate }],
  [
    DMS.RESOURCES_DMS_REPLICATION_TASK_TABLE_STATS,
    { DMSReplicationTaskTableStats: views.DMSReplicationTaskTableStats },
  ],
  [DMS.RESOURCES_DMS_ENDPOINT_CONNECTIONS, { DMSEndpointConnections: views.DMSEndpointConnections }],
  [DMS.RESOURCES_DMS_ENDPOINT_CONNECTIONS_TEST, { DMSEndpointConnectionTest: views.DMSEndpointConnectionTest }],
]);

export const RedshiftViewMappings = new Map([
  [REDSHIFT.RESOURCES_REDSHIFT_CLUSTERS, { RedshiftClusters: views.RedshiftClusters }],
  [REDSHIFT.RESOURCES_REDSHIFT_CLUSTER, { RedshiftCluster: views.RedshiftCluster }],
  [REDSHIFT.RESOURCES_REDSHIFT_CLUSTER_CREATE, { RedshiftClusterUpsert: views.RedshiftClusterUpsert }],
  [REDSHIFT.RESOURCES_REDSHIFT_CLUSTER_UPDATE, { RedshiftClusterUpsert: views.RedshiftClusterUpsert }],
]);
