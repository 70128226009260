import { ReactElement } from 'react';
import classnames from 'classnames';
import { Theme } from '@mui/material/styles';

import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';

import {
  Card,
  CardHeader,
  Button,
  CardContent,
  CardActions,
  Box,
  Typography,
  Avatar,
  CircularProgress,
  LinearProgress,
} from '@mui/material';
import { ThemeType } from '@localstack/constants';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    '@keyframes fadeIn': {
      '0%': {
        transform: 'translate(0, -10px)',
        visibility: 'hidden',
        opacity: 0,
      },
      '100%': {
        transform: 'translate(0)',
        visibility: 'visible',
        opacity: 1,
      },
    },
    card: {
      animation: '$fadeIn 0.5s linear',
      color: theme.palette.primary.contrastText,
      marginBottom: theme.spacing(2),
      '& *': {
        color: theme.palette.primary.contrastText,
      },
      background: `linear-gradient(45deg,
      #4D29B4 0%,
      #6B7CF5 50%,
      #70adfe 100%);`,
    },
    cardHeader: {
      color: theme.palette.primary.contrastText,
    },
    cardContent: {
      paddingBottom: `${theme.spacing(2)} !important`,
      color: theme.palette.primary.contrastText,
    },
    button: {
      borderColor: theme.palette.primary.contrastText,
      color: theme.palette.primary.contrastText,
      marginLeft: theme.spacing(1),
    },
    avatar: {
      background: theme.palette.mode === ThemeType.DARK ? '#6B7CF5' : theme.palette.primary.contrastText,
      '& > svg': {
        color: theme.palette.mode === ThemeType.DARK ? theme.palette.primary.contrastText : theme.palette.primary.dark,
        fill: theme.palette.mode === ThemeType.DARK ? theme.palette.primary.contrastText : theme.palette.primary.dark,
      },
    },
    progress: {
      background: theme.palette.primary.main,
      '& span': {
        background: theme.palette.primary.contrastText,
      },
    },
  }),
);

export interface WizardStep<T> {
  title: string;
  text?: string | ReactElement | ((args: T) => ReactElement);
  condition: (args: T) => boolean;
  type: WizardStepType;
  actions?: { title: string; callback?: (args: T) => unknown }[];
  icon?: ReactElement;
  loading?: boolean;
}

export interface WizardProps<T> {
  title?: string | ReactElement;
  steps: WizardStep<T>[];
  classes?: Partial<ReturnType<typeof useStyles>>;
  progress?: number;
}

export enum WizardStepType {
  SIMPLE_TEXT = 'simple_text',
}

function SimpleTextRenderer<T>({ icon, title, actions, loading, args, text }: WizardStep<unknown> & { args: T }) {
  const classes = useStyles();
  return (
    <Box display="flex" alignItems="center" flexGrow={1}>
      {icon && (
        <Box mr={2}>
          <Avatar className={classes.avatar} variant="rounded">
            {icon}
          </Avatar>
        </Box>
      )}
      <Box flexGrow={1}>
        <Typography variant="subtitle1">{title}</Typography>
        {text && (
          <>
            {typeof text === 'string' && <Typography>{text}</Typography>}
            {typeof text === 'function' && text(args)}
            {typeof text === 'object' && text}
          </>
        )}
      </Box>
      {(actions ?? []).length > 0 && (
        <Box>
          {loading ? (
            <CircularProgress size={32} />
          ) : (
            <>
              {(actions ?? []).map((action) => (
                <Button
                  key={action.title}
                  size="large"
                  variant="outlined"
                  className={classes.button}
                  onClick={() => action?.callback?.(args)}
                >
                  {action.title}
                </Button>
              ))}
            </>
          )}
        </Box>
      )}
    </Box>
  );
}

export function Wizard<T>({
  steps,
  args,
  title,
  progress,
  classes: customClasses,
}: WizardProps<T> & { args: T }): ReactElement | null {
  const classes = useStyles();

  const step = steps.find((s) => s.condition(args));

  if (!step) return null;

  return (
    <Card className={classnames(classes.card, customClasses?.card)}>
      {title && <CardHeader className={classes.cardHeader} title={title} />}
      <CardContent className={classnames(classes.cardContent, customClasses?.cardContent)}>
        {step?.type === WizardStepType.SIMPLE_TEXT && <SimpleTextRenderer {...step} args={args} />}
      </CardContent>
      {progress !== undefined && (
        <CardActions>
          <Box display="flex" alignItems="center" width="100%">
            <Box width="100%" mr={1}>
              <LinearProgress variant="determinate" value={progress} className={classes.progress} />
            </Box>
            <Box minWidth={50}>
              <Typography variant="h5">{progress}%</Typography>
            </Box>
          </Box>
        </CardActions>
      )}
    </Card>
  );
}
