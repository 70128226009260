import axios, { AxiosError, AxiosRequestConfig, AxiosResponse } from 'axios';
import { clearSwrCache, configureClient, retrieveAuthToken } from '@localstack/services';
import { Cache } from 'swr';

import { clearAuthStorage, retrieveImpersonationMarker } from '~/util/storage';
import { refreshExpiringToken, RefreshTokenStatus } from '~/util/tokens';
import { AppRoute } from '~/config/routes';

import { SERVICE_BASE_URL } from './config';
import { captureException } from './errors';

const RETRIES_HEADER = 'x-retries-count';
const MAX_RETRIES = 3;

const getAuthHeaders = (): AxiosRequestConfig['headers'] => {
  const authToken = retrieveAuthToken();
  const impersonationMarker = retrieveImpersonationMarker();

  if (!authToken) return {};

  if (impersonationMarker) {
    return {
      Authorization: authToken.token,
      'x-impersonate': impersonationMarker,
    };
  }

  return { Authorization: authToken.token };
};

const redirectToExpiredSession = (): void => {
  // TODO: use browser history from react-router-dom
  const isSignInPage = window.location.href.includes(AppRoute.SIGN_IN);
  const isSessionExpiredPage = window.location.href.includes(AppRoute.SESSION_EXPIRED);

  if (!isSignInPage && !isSessionExpiredPage) {
    window.location.href = AppRoute.SESSION_EXPIRED;
  }
};

/**
 * Axios request interceptor to automatically refresh tokens when needed.
 * This implementation does not require a constantly running timer or interval.
 *
 * In case of expiring tokens, the requests are suspended until the refresh is complete.
 * If the refresh fails, the storage is cleaned up and the user is redirected to the login page.
 */
const configureAxiosTokenInterceptor = (): void => {
  axios.interceptors.request.use(async (config) => {
    // this is a token refresh request itself, nothing to do here
    if (config.url?.includes('/v1/user/signin')) {
      return config;
    }

    const tokenStatus = await refreshExpiringToken();

    // there is no authentication token, but it could still be a legitimate request
    if (tokenStatus === RefreshTokenStatus.TOKEN_MISSING) {
      return config;
    }

    if (tokenStatus === RefreshTokenStatus.TOKEN_FAILED) {
      // clear local storage and cache
      clearAuthStorage();
      await clearSwrCache();

      redirectToExpiredSession();

      // cancel this request
      throw new axios.Cancel('Failed to refresh token');
    }

    // token refreshed or was still valid - pick it up (or re-use)
    config.headers = { ...config.headers, ...getAuthHeaders() };

    return config;
  });
};

/**
 * Axios response interceptor to handle errors and conditionally report them to Sentry.
 * In some cases response interceptor will try refreshing the token, if the response
 * would look like it's time for it.
 *
 * As in some cases we fire requests even before we know user permissions, some of
 * 403 errors are "expected". These errors are not reported to Sentry. This, however,
 * should change in the future to improve token refresh flow.
 *
 * This interceptor would additionally handle 401 errors that may result from
 * an invalid or expired tokens. In this case it would try to refresh the token
 * and retry the request for up to `MAX_RETRIES` times.
 */
const configureAxiosErrorInterceptor = (): void => {
  axios.interceptors.response.use(
    (response: AxiosResponse) => response,
    async (error: AxiosError) => {
      // skip capturing of network errors due to not running LocalStack

      if (error.request?.__sentry_xhr__?.url?.includes('/health')) {
        return Promise.reject(error);
      }

      const errorCode = error.code ?? error.response?.status ?? '';
      const errorMessage = error.response?.data.message ?? '';

      const unreportedErrors = ['auth.auth_request'];

      if (['401', 401].includes(errorCode)) {
        const retriesCount = error.config.headers?.[RETRIES_HEADER]
          ? Number.parseInt(error.config.headers[RETRIES_HEADER] as string, 10)
          : 0;

        // this is a retry-request, but we already tried N times - cleanup and go to sign in
        if (retriesCount >= MAX_RETRIES) {
          clearAuthStorage();
          await clearSwrCache();

          redirectToExpiredSession();

          return Promise.reject(error);
        }

        // force-refresh the token
        const tokenStatus = await refreshExpiringToken(true);

        const isTokenMissing = tokenStatus === RefreshTokenStatus.TOKEN_MISSING;
        const isTokenFailedRefresh = tokenStatus === RefreshTokenStatus.TOKEN_FAILED;

        // token missing or failed to refresh, nothing to do here other than re-authenticate
        if (isTokenMissing || isTokenFailedRefresh) {
          // clear local storage and cache
          clearAuthStorage();
          await clearSwrCache();

          redirectToExpiredSession();

          return Promise.reject(error);
        }

        // attempt to retry the request with the new token (if any obtained)
        error.config.headers = {
          ...error.config.headers,
          ...getAuthHeaders(),
          [RETRIES_HEADER]: (retriesCount + 1).toString(),
        };

        // retry the request
        return axios.request(error.config);
      }

      // Skip tracking of 403/400 errors or known / expected errors
      if (['403', 403, '400', 400].includes(errorCode) || unreportedErrors.includes(errorMessage)) {
        return Promise.reject(error);
      }

      // attempt to facilitate "Non-Error exception captured" error,
      // when for some reason `error` is not actually an instance of `Error`
      const capturedException =
        (error as unknown) instanceof Error ? error : new Error((error as { message: string }).message);

      captureException(capturedException);

      return Promise.reject(error);
    },
  );
};

export const configureApiClient = (): void => {
  configureClient({
    baseUrl: SERVICE_BASE_URL,
    getAuthHeaders,
  });

  configureAxiosTokenInterceptor();
  configureAxiosErrorInterceptor();
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const localStorageSWRCacheProvider = (_cache: Readonly<Cache<any>>): Cache<any> => {
  // When initializing, we restore the data from `localStorage` into a map.
  const map = new Map(JSON.parse(localStorage.getItem('app-cache') || '[]'));

  // Before unloading the app, we write back all the data into `localStorage`.
  window.addEventListener('beforeunload', () => {
    const appCache = JSON.stringify(Array.from(map.entries()));
    localStorage.setItem('app-cache', appCache);
  });

  // We still use the map for write & read for performance.
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  return map as Cache<any>;
};
