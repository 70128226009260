import { Palette } from '@mui/material/styles';

import { MuiThemeComponentsType } from '../types';

export default (palette: Palette): MuiThemeComponentsType['MuiInputLabel'] => ({
  styleOverrides: {
    root: {
      color: palette.text.secondary,
    },
  },
});
