import { ReactElement } from 'react';
import { useRoutes, useAwsGetter } from '@localstack/services';
import { Card, Button } from '@mui/material';
import { MonitoringMetricsTable, PageTitle } from '@localstack/ui';

import { DEFAULT_METRICS_ROUTES } from '@localstack/constants/src';

import { MainNavTabs } from './components';
import { MetricsProps } from './types';

export const Metrics = ({ Layout, clientOverrides, routes = DEFAULT_METRICS_ROUTES }: MetricsProps): ReactElement => {
  const { goto } = useRoutes();
  const { data: metrics, isLoading, mutate } = useAwsGetter('CloudWatch', 'listMetrics', [], { clientOverrides });

  return (
    <Layout
      title={<PageTitle title="CloudWatch Metrics" onMutate={mutate} />}
      tabs={<MainNavTabs routes={routes} />}
      actions={<Button onClick={() => goto(routes.RESOURCES_CLOUDWATCH_MONITORING_METRIC_CREATE)}>Put Metric</Button>}
    >
      <Card>
        <MonitoringMetricsTable
          loading={isLoading}
          metrics={metrics?.Metrics || []}
          onViewMetric={({ Namespace, MetricName, Dimensions }) =>
            goto(routes.RESOURCES_CLOUDWATCH_MONITORING_METRIC, {
              namespace: Namespace,
              name: MetricName,
              dimensions: JSON.stringify(Dimensions ?? []),
            })
          }
        />
      </Card>
    </Layout>
  );
};
