import { AccountCircle, Layers } from '@mui/icons-material';
import { Box } from '@mui/material';
import { ReactElement } from 'react';

type PolicyIconProps = {
  type: 'identity' | 'resource';
};

export const PolicyIcon = ({ type }: PolicyIconProps): ReactElement => (
  <Box sx={{ width: 24, height: 24 }}>
    {type === 'identity' && <AccountCircle color="disabled" />}
    {type === 'resource' && <Layers color="disabled" />}
  </Box>
);
