import { ReactElement, useCallback, useState } from 'react';
import { useRoutes, useAwsGetter, useAwsEffect } from '@localstack/services';
import { Card, Button } from '@mui/material';
import { Dropdown, ConfirmableButton, PageTitle, GlueConnectionsTable } from '@localstack/ui';
import { DEFAULT_GLUE_ROUTES } from '@localstack/constants';

import { GlueProps } from './types';
import { MainNavTabs } from './components';

export const GlueConnections = ({ Layout, clientOverrides, routes = DEFAULT_GLUE_ROUTES }: GlueProps): ReactElement => {
  const [selected, setSelected] = useState<string[]>([]);

  const { goto } = useRoutes();

  const { data: connections, isLoading, mutate } = useAwsGetter('Glue', 'getConnections', [], { clientOverrides });

  const { deleteConnection } = useAwsEffect('Glue', ['deleteConnection'], {
    revalidate: ['getConnections'],
    clientOverrides,
  });

  const handleDeleteSelected = useCallback(
    async () => Promise.all(selected.map((connection) => deleteConnection({ ConnectionName: connection }))),
    [selected],
  );

  return (
    <Layout
      documentTitle="Glue: Connections"
      title={
        <PageTitle
          title="Glue Connections"
          breadcrumbs={[
            ['Glue', () => goto(routes.RESOURCES_GLUE_DATABASES)],
            ['Connections', () => goto(routes.RESOURCES_GLUE_CONNECTIONS)],
          ]}
          onMutate={mutate}
        />
      }
      tabs={<MainNavTabs routes={routes} />}
      actions={
        <>
          <Button onClick={() => goto(routes.RESOURCES_GLUE_CONNECTION_CREATE)}>Create Connection</Button>
          <Dropdown label="Actions">
            <ConfirmableButton
              componentType="MenuItem"
              disabled={selected.length === 0 || isLoading}
              title={`Delete ${selected.length} Connection(s)?`}
              onClick={handleDeleteSelected}
              text="Selected Connection(s) will be permanently deleted"
            >
              Remove Selected
            </ConfirmableButton>
          </Dropdown>
        </>
      }
    >
      <Card>
        <GlueConnectionsTable
          connections={connections?.ConnectionList ?? []}
          loading={isLoading}
          onSelect={setSelected}
          onViewConnection={(connection) => goto(routes.RESOURCES_GLUE_CONNECTION, { connection })}
        />
      </Card>
    </Layout>
  );
};
