import { ReactElement } from 'react';
import { Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import createStyles from '@mui/styles/createStyles';
import classnames from 'classnames';
import { v4 as uuid } from 'uuid';
import { Skeleton } from '@mui/material';

const VERSIONS_PER_ROW = 24;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'grid',
      gridTemplateColumns: `repeat(${VERSIONS_PER_ROW}, 1fr)`,
      gridTemplateRows: `repeat(${VERSIONS_PER_ROW}, 1fr)`,
      gridGap: '15px',
      padding: theme.spacing(2),
      '&::before': {
        content: '""',
        width: 0,
        paddingBottom: '100%',
        gridRow: '1 / 1',
        gridColumn: '1 / 1',
      },
    },
    item: {
      position: 'relative',
      '&:first-child': {
        gridRow: '1 / 1',
        gridColumn: '1 / 1',
      },
    },
    version: {
      ...theme.typography.body1,
      color: theme.palette.primary.contrastText,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      background: `linear-gradient(45deg, ${theme.palette.success.dark} 0%, ${theme.palette.success.main} 100%)`,
      transition: 'box-shadow 0.1s ease-in-out',
      cursor: 'pointer',
      '&:hover': {
        background: `linear-gradient(45deg, ${theme.palette.primary.dark} 0%, ${theme.palette.primary.main} 100%)`,
        boxShadow: theme.shadows[5],
      },
    },
    diff: {
      background: `linear-gradient(45deg, ${theme.palette.warning.dark} 0%, ${theme.palette.warning.main} 100%)`,
    },
    filler: {
      background: theme.palette.action.disabledBackground,
    },
    skeleton: {
      height: '100%',
    },
    active: {
      background: `linear-gradient(45deg, ${theme.palette.primary.dark} 0%, ${theme.palette.primary.main} 100%)`,
    },
  }),
);

// TODO remove - still needed?
export type CommitsSelectorProps = {
  /** show loading state */
  loading?: boolean;
  /** selected versions */
  versions: number[];
  /** all available commits */
  // commits: CommitMetamodel[];
  /** callback fired when new version is selected */
  onSelectVersion: (version: number) => void;
};

// TODO remove - still needed?
export const CommitsSelector = ({
  loading,
  // versions,
  // commits,
  // onSelectVersion,
}: CommitsSelectorProps): ReactElement => {
  const classes = useStyles();

  // const minVersion = useMemo(() => versions.length && Math.min(...versions), [versions]);
  // const maxVersion = useMemo(() => versions.length && Math.max(...versions), [versions]);

  // const diffVersions = useMemo(
  //   () => new Array(maxVersion - minVersion).fill(0).map((_, idx) => idx + minVersion),
  //   [minVersion, maxVersion],
  // );

  return (
    <div
      className={classes.root}
      // style={{
      //   gridTemplateRows: `repeat(${Math.max(1, Math.ceil(commits.length / VERSIONS_PER_ROW))}, 1fr)`,
      // }}
    >
      {loading &&
        new Array(VERSIONS_PER_ROW)
          .fill(0)
          .map(() => (
            <Skeleton variant="rectangular" className={classnames(classes.item, classes.skeleton)} key={uuid()} />
          ))}
      {/* {!loading && commits.map((commit) => (
        <div
          key={commit.commit}
          role="button"
          tabIndex={0}
          onClick={() => onSelectVersion(commit.commit)}
          className={classnames(classes.item, classes.version, {
            [classes.active]: versions.includes(commit.commit),
            [classes.diff]: diffVersions.includes(commit.commit),
          })}
        >
          {commit.commit}
        </div>
      ))}
      {!loading && new Array(VERSIONS_PER_ROW - (commits.length % VERSIONS_PER_ROW)).fill(0).map(() => (
        <div className={classnames(classes.item, classes.filler)} key={uuid()} />
      ))} */}
    </div>
  );
};
