import { createStyles, makeStyles } from '@mui/styles';
import { ReactElement } from 'react';

import { Button, Theme } from '@mui/material';

import { MARKER_IDS, TestMarkerSpan } from '@localstack/services';

import { BaseHeaderProps } from '@localstack/types';

import { AwsAccountField, AwsRegionDropdown } from '@localstack/ui';

import { BaseHeader } from './BaseHeader';

interface ResourceBrowserHeaderProps extends BaseHeaderProps {
  /** reset function to call on Reset Form Button */
  resetFn: (() => void) | null;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    regionDivider: {
      borderLeft: 'solid 1px',
      width: 0,
      height: '2em',
      borderColor: theme.palette.divider,
    },
  }),
);

export const ResourceBrowserHeader = ({ actions, resetFn, ...rest }: ResourceBrowserHeaderProps): ReactElement => {
  const classes = useStyles();
  return (
    <BaseHeader
      actions={
        <>
          {resetFn && <Button onClick={() => resetFn?.()}>Reset Form</Button>}
          {actions}
          <div className={classes.regionDivider} />
          <TestMarkerSpan name={MARKER_IDS.REGION_DROPDOWN}>
            <AwsRegionDropdown />
          </TestMarkerSpan>
          <AwsAccountField />
        </>
      }
      {...rest}
    />
  );
};
