import { ReactElement, useMemo } from 'react';
import { ApiKey, LicenseAssignment, OrganizationMember } from '@localstack/types';
import { Theme } from '@mui/material/styles';

import makeStyles from '@mui/styles/makeStyles';
import createStyles from '@mui/styles/createStyles';

import { List, ListItem, ListItemText, ListItemSecondaryAction, IconButton } from '@mui/material';

import { Delete as DeleteIcon } from '@mui/icons-material';

export interface ApiKeysListProps {
  keys: ApiKey[];
  members?: OrganizationMember[];
  licenseAssignments?: LicenseAssignment[];
  loading?: boolean;
  onDeleteApiKey: (id: string) => unknown;
  onDeleteLicense: (id: string) => unknown;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    listItem: {
      '&:hover': {
        backgroundColor: theme.palette.action.hover,
      },
    },
  }),
);

export const DowngradeLicensesList = ({
  keys,
  members,
  licenseAssignments,
  loading,
  onDeleteApiKey,
  onDeleteLicense,
}: ApiKeysListProps): ReactElement => {
  const classes = useStyles();

  type KeysAndLicense = {
    apiKey?: string;
    email?: string;
    userName?: string;
    licenseId?: string;
    userId?: string;
  };

  const data = useMemo<KeysAndLicense[]>(() => {
    const outputData: KeysAndLicense[] = keys.map((key) => {
      const user = members?.find((member) => member.email === key.member_email);
      return {
        apiKey: key.api_key,
        email: key.member_email,
        userName: user?.name,
        userId: user?.id,
      };
    });

    licenseAssignments?.forEach((license) => {
      const keyEntry = outputData.find((element) => element.userId === license.user_id);
      if (keyEntry) {
        keyEntry.licenseId = license.id;
      } else {
        const foundMember = members?.find((member) => member.id === license.user_id);
        outputData.push({
          userId: license.user_id,
          userName: foundMember?.name,
          licenseId: license.id,
        });
      }
    });

    return outputData;
  }, [members]);

  const makeSecondary = (entry: KeysAndLicense) => {
    const parts: string[] = [];
    if (entry.apiKey) {
      parts.push(`API Key: ${entry.apiKey}`);
    }

    if (entry.licenseId) {
      parts.push('License assigned');
    }

    return parts.join(', ');
  };

  return (
    <List>
      {data.map((entry) => (
        <ListItem key={entry.userId || entry.apiKey} className={classes.listItem}>
          <ListItemText
            primary={entry.userName || entry.email || 'Unassigned API Key'}
            secondary={makeSecondary(entry)}
          />
          <ListItemSecondaryAction>
            <IconButton
              color="primary"
              size="small"
              disabled={loading}
              onClick={() => {
                if (entry.apiKey) {
                  onDeleteApiKey?.(entry.apiKey);
                }

                if (entry.licenseId) {
                  onDeleteLicense(entry.licenseId);
                }
              }}
            >
              <DeleteIcon />
            </IconButton>
          </ListItemSecondaryAction>
        </ListItem>
      ))}
    </List>
  );
};
