import { SERVICE_NAME } from '@localstack/types';

import { PATH_PREFIX_RESOURCES } from './base';

const CLOUDWATCH_BASE_ROUTE = `${PATH_PREFIX_RESOURCES}/${SERVICE_NAME.CLOUDWATCH}`;

export const DEFAULT_CLOUDWATCH_ROUTES: Record<string, string> = {
  RESOURCES_CLOUDWATCH: `${CLOUDWATCH_BASE_ROUTE}`,
  RESOURCES_CLOUDWATCH_GROUPS: `${CLOUDWATCH_BASE_ROUTE}/groups`,
  RESOURCES_CLOUDWATCH_GROUP_CREATE: `${CLOUDWATCH_BASE_ROUTE}/groups/new`,
  RESOURCES_CLOUDWATCH_GROUP_EVENTS: `${CLOUDWATCH_BASE_ROUTE}/groups/:logGroupName/events`,
  RESOURCES_CLOUDWATCH_GROUP_STREAMS: `${CLOUDWATCH_BASE_ROUTE}/groups/:logGroupName/streams`,
  RESOURCES_CLOUDWATCH_GROUP_STREAM_CREATE: `${CLOUDWATCH_BASE_ROUTE}/groups/:logGroupName/streams/new`,
  RESOURCES_CLOUDWATCH_GROUP_STREAM_EVENTS: `${CLOUDWATCH_BASE_ROUTE}/groups/:logGroupName/streams/:logStreamName/events`,
};
