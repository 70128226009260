import { ReactElement, useState, useCallback, useEffect } from 'react';
import { useAwsGetter, useAwsEffect } from '@localstack/services';
import { ExternalLink } from '@localstack/constants';
import { LoadingFragment } from '@localstack/ui';
import { Alert, AlertTitle, Collapse, LinearProgress, Link, Typography } from '@mui/material';

import { PodCard } from './PodCard';

export const AlertLocalStackRunning = (version?: string): ReactElement => {
  const satisfiesVersionConstraint = parseFloat(version || '0') >= 1.4;
  return (
    <Alert severity={satisfiesVersionConstraint ? 'success' : 'warning'}>
      <AlertTitle>LocalStack is up and running</AlertTitle>
      Version: {version ?? 'unknown'}
      {!satisfiesVersionConstraint && ' - Please update LocalStack to v1.4 to use all features of the Launchpad'}
    </Alert>
  );
};

export const AlertLocalStackNotRunning = (
  <Alert severity="warning">
    <AlertTitle>Could not connect to running LocalStack instance</AlertTitle>
    Start a new LocalStack instance to load the pod. Find out more in our{' '}
    <Link href={ExternalLink.DOCS_GETTING_STARTED} target="_blank" underline="hover">
      documentation
    </Link>
    .
  </Alert>
);

const AlertMetadataNotFound = (
  <Alert severity="warning">
    <AlertTitle>Failed to load metadata</AlertTitle>
    There seems to be something wrong with the provided url. Please ensure that it is correct and try again.
  </Alert>
);

type PodInjectorProps = {
  url: string;
};

export const PodInjector = ({ url }: PodInjectorProps): ReactElement => {
  const [metadataUrl, setMetadataUrl] = useState<Optional<string>>(undefined);
  const [isInjected, setIsInjected] = useState(false);
  const [logOutput, setLogOutput] = useState('');

  const {
    data: health,
    isLoading: isHealthLoading,
    hasError: hasHealthError,
  } = useAwsGetter('LocalStack', 'getHealth', [], { silentErrors: true });

  const { fetchPod, isLoading: isPodFetching } = useAwsEffect('LocalStack', ['fetchPod'], { silentErrors: true });

  const {
    data: podMetadata,
    isLoading: isLoadingMetadata,
    hasError: hasMetadataError,
  } = useAwsGetter('LocalStack', 'getPodMetadata', [metadataUrl], {
    silentErrors: true,
    swrOverrides: { shouldRetryOnError: false },
  });

  const {
    injectPodFromUrl,
    isLoading: isInjectingPod,
    hasError: hasInjectError,
  } = useAwsEffect('LocalStack', ['injectPodFromUrl'], { silentErrors: true });

  const onInject = useCallback(async () => {
    setLogOutput('Loading pod...');
    await injectPodFromUrl(url);
    setIsInjected(true);
  }, []);

  useEffect(() => {
    if (!isPodFetching) setMetadataUrl(url);
  }, [isPodFetching]);

  useEffect(() => {
    const fetch = async () => {
      await fetchPod(url, undefined);
    };
    fetch();
  }, []);

  useEffect(() => {
    if (isInjected && !isInjectingPod && !hasInjectError) {
      setLogOutput(`${logOutput} \nSuccessfully loaded pod`);
    } else if (isInjected && !isInjectingPod && hasInjectError) {
      setLogOutput(`${logOutput} \nPod load failed. Please check your LocalStack logs for more information.`);
    }
  }, [isInjected, isInjectingPod, hasInjectError]);

  return (
    <>
      <Typography variant="h5" color="textSecondary">
        Download and load this pod into a running LocalStack instance
      </Typography>
      <LoadingFragment loading={isHealthLoading} variant="card" size={1} height={65}>
        {hasHealthError ? AlertLocalStackNotRunning : AlertLocalStackRunning(health?.version)}
      </LoadingFragment>
      <LoadingFragment loading={isHealthLoading} variant="card" size={1} height={65}>
        <Collapse in={isPodFetching && !hasHealthError} timeout={{ exit: 1000 }} unmountOnExit>
          <Alert severity="info">
            <AlertTitle>Fetching information about your pod</AlertTitle>
            <LinearProgress />
          </Alert>
        </Collapse>
      </LoadingFragment>
      <LoadingFragment
        variant="card"
        size={1}
        height={315}
        loading={isHealthLoading || isPodFetching || isLoadingMetadata}
      >
        {podMetadata && (
          <PodCard
            podsMetadata={{ ...podMetadata, url }}
            onInject={onInject}
            isInjecting={isInjectingPod}
            isInjected={isInjected && !isPodFetching && !isInjectingPod}
            injectError={hasInjectError}
            logOutput={logOutput}
          />
        )}
        {!hasHealthError && hasMetadataError && AlertMetadataNotFound}
      </LoadingFragment>
    </>
  );
};
