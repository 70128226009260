import { ReactElement } from 'react';
import { Link } from '@mui/material';
import { BackupRule } from '@localstack/types';
import { getSchema } from '@localstack/services';

import { MagicTable } from '../../../magic/MagicTable';

export type BackupRulesTableProps = {
  rules: BackupRule[];
  loading?: boolean;
  onViewRule?: (rule: BackupRule) => void;
};

export const BackupRulesTable = ({ rules, loading, onViewRule }: BackupRulesTableProps): ReactElement => (
  <MagicTable
    schema={getSchema('Backup')}
    entry="BackupRule"
    idAttribute="RuleName"
    filterColumns={['RuleName', 'TargetBackupVaultName', 'ScheduleExpression']}
    rows={rules}
    loading={loading}
    externalFields={{
      RuleName: (row) => (
        <Link onClick={() => onViewRule && onViewRule(row)} underline="hover">
          {row.RuleName}
        </Link>
      ),
    }}
  />
);
