import { ReactElement } from 'react';
import { SNSSubscription } from '@localstack/types';
import { getSchema } from '@localstack/services';

import { MagicDetails } from '../../../magic/MagicDetails';

export type SNSSubscriptionDetailsProps = {
  subscription: Optional<SNSSubscription>;
};

export const SNSSubscriptionDetails = ({ subscription }: SNSSubscriptionDetailsProps): ReactElement => (
  <MagicDetails data={subscription} schema={getSchema('SNS')} entry="Subscription" title="Subscription Details" />
);
