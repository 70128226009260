import { ReactElement } from 'react';
import { useParams } from 'react-router-dom';
import { Card, CardContent, CardActions, Box, Typography } from '@mui/material';
import { useRoutes, useAwsEffect, useAwsGetter } from '@localstack/services';
import { ProgressButton, AgwRestApiMethodResponseForm, Breadcrumbs } from '@localstack/ui';
import { DEFAULT_API_GATEWAY_ROUTES } from '@localstack/constants';

import { CreateAgwRestApiMethodResponseRequest, UpdateAgwRestApiMethodResponseRequest } from '@localstack/types';

import { ApiGatewayProps } from './types';
import { RestApiMethodNavTabs } from './components';

export const AgwRestApiMethodResponseUpsert = ({
  Layout,
  clientOverrides,
  routes = DEFAULT_API_GATEWAY_ROUTES,
}: ApiGatewayProps): ReactElement => {
  const { goto } = useRoutes();
  const { restApiId, resourceId, httpMethod, statusCode } = useParams<
    'restApiId' | 'resourceId' | 'httpMethod' | 'statusCode'
  >();

  const { putMethodResponse, updateMethodResponse, isLoading } = useAwsEffect(
    'APIGateway',
    ['putMethodResponse', 'updateMethodResponse'],
    {
      revalidate: ['getMethod', 'getResources', 'getResource'],
      clientOverrides,
    },
  );

  const { data: resource } = useAwsGetter('APIGateway', 'getResource', [{ restApiId, resourceId }], {
    clientOverrides,
  });

  const { data: method } = useAwsGetter('APIGateway', 'getMethod', [{ restApiId, resourceId, httpMethod }], {
    clientOverrides,
  });

  const { data: api } = useAwsGetter('APIGateway', 'getRestApi', [{ restApiId }], { clientOverrides });

  const { data: response } = useAwsGetter('APIGateway', 'getMethodResponse', [
    { restApiId, resourceId, httpMethod, statusCode },
  ]);

  const handleCreate = async (data: CreateAgwRestApiMethodResponseRequest) => {
    await putMethodResponse(data);
    goto(routes.RESOURCES_AGW1_API_METHOD_RESPONSES, { restApiId, resourceId, httpMethod });
  };

  const handleUpdate = async (data: UpdateAgwRestApiMethodResponseRequest) => {
    await updateMethodResponse(data);
    goto(routes.RESOURCES_AGW1_API_METHOD_RESPONSE_UPDATE, { restApiId, resourceId, httpMethod, statusCode });
  };

  return (
    <Layout
      title={
        <Box>
          <Typography variant="h4">Integration Response Details</Typography>
          <Breadcrumbs
            mappings={[
              ['API Gateway', () => goto(routes.RESOURCES_AGW1)],
              [api?.name, () => goto(routes.RESOURCES_AGW1_API, { restApiId })],
              [resource?.path, () => goto(routes.RESOURCES_AGW1_API_RESOURCE, { restApiId, resourceId })],
              [method?.httpMethod, () => goto(routes.RESOURCES_AGW1_API_METHOD, { restApiId, resourceId, httpMethod })],
              [httpMethod ? 'Update Integration Response' : 'Create Integration Response', null],
            ]}
          />
        </Box>
      }
      tabs={
        <RestApiMethodNavTabs
          restApiId={restApiId as string}
          resourceId={resourceId as string}
          httpMethod={httpMethod as string}
          routes={routes}
        />
      }
      actions={
        <ProgressButton type="submit" form="update" variant="outlined" color="primary" loading={isLoading}>
          Submit
        </ProgressButton>
      }
    >
      <Card>
        <CardContent>
          <AgwRestApiMethodResponseForm
            clientOverrides={clientOverrides}
            restApiId={restApiId as string}
            resourceId={resourceId as string}
            httpMethod={httpMethod as string}
            response={response}
            onCreate={handleCreate}
            onUpdate={handleUpdate}
            loading={isLoading}
            formId="update"
          />
        </CardContent>
        <CardActions>
          <ProgressButton type="submit" form="update" variant="contained" color="primary" loading={isLoading}>
            Submit
          </ProgressButton>
        </CardActions>
      </Card>
    </Layout>
  );
};
