import { ReactElement } from 'react';
import { useRoutes, useAwsGetter } from '@localstack/services';
import { useParams } from 'react-router-dom';
import { Card, CardContent, Box, Typography } from '@mui/material';
import { Breadcrumbs, EventBridgePipeDetails } from '@localstack/ui';
import { DEFAULT_EVENTBRIDGE_ROUTES } from '@localstack/constants/src';

import { EventBridgeProps } from './types';

export const EventBridgePipe = ({
  Layout,
  clientOverrides,
  routes = DEFAULT_EVENTBRIDGE_ROUTES,
}: EventBridgeProps): ReactElement => {
  const { goto } = useRoutes();
  const { pipeName } = useParams<'pipeName'>();

  const { data: pipe } = useAwsGetter('Pipes', 'describePipe', [{ Name: pipeName }], { clientOverrides });

  return (
    <Layout
      documentTitle="EventBridge: Pipe Details"
      title={
        <Box>
          <Typography variant="h4">Pipe Details</Typography>
          <Breadcrumbs
            mappings={[
              ['EventBridge', () => goto(routes.RESOURCES_EVENT_BRIDGE)],
              ['Pipes', () => goto(routes.RESOURCES_EVENT_BRIDGE_PIPES)],
              [pipeName, null],
            ]}
          />
        </Box>
      }
    >
      <Card>
        <CardContent>{pipe && <EventBridgePipeDetails pipe={pipe} />}</CardContent>
      </Card>
    </Layout>
  );
};
