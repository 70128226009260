import { S3CreateFolderArgs } from '@localstack/types';
import { ReactElement } from 'react';

import { MagicForm } from '../../../magic/MagicForm';

const SCHEMA = {
  shapes: {
    S3Folder: {
      type: 'structure',
      required: ['Name'],
      members: {
        Name: {
          type: 'string',
          documentation: '<p>The name of the folder to create.</p>',
        },
      },
    },
  },
};

export interface S3FolderCreateFormProps {
  loading?: boolean;
  formId?: string;
  onCreate: (data: S3CreateFolderArgs) => void;
}

export const S3FolderCreateForm = ({ loading, formId, onCreate }: S3FolderCreateFormProps): ReactElement => (
  <MagicForm
    schema={SCHEMA}
    loading={loading}
    entry="S3Folder"
    formId={formId}
    onSubmit={(data: S3CreateFolderArgs) => onCreate(data)}
  />
);
