import { ReactElement } from 'react';
import { AwsClientOverrides, getSchema } from '@localstack/services';

import { ECSCluster, CreateECSClusterParams, UpdateECSClusterParams } from '@localstack/types';
import { DEFAULT_CLOUDWATCH_ROUTES, DEFAULT_KMS_ROUTES, DEFAULT_S3_ROUTES } from '@localstack/constants';

import { MagicForm } from '../../magic/MagicForm';
import { RelatedResourcePicker } from '../../../form/RelatedResourcePicker';

export interface ECSClusterFormProps {
  cluster?: Optional<Partial<ECSCluster>>;
  loading?: boolean;
  formId?: string;
  clientOverrides?: AwsClientOverrides;
  onCreate: (data: CreateECSClusterParams) => unknown;
  onUpdate: (data: UpdateECSClusterParams) => unknown;
}

export const ECSClusterForm = ({
  cluster,
  loading,
  formId,
  clientOverrides,
  onCreate,
  onUpdate,
}: ECSClusterFormProps): ReactElement => (
  <MagicForm
    data={cluster}
    schema={getSchema('ECS')}
    loading={loading}
    entry={cluster ? 'UpdateClusterRequest' : 'CreateClusterRequest'}
    formId={formId}
    fieldConditions={{
      '^cluster$': !cluster,
    }}
    externalFields={{
      kmsKeyId$: (control, fieldName, required) => (
        <RelatedResourcePicker
          control={control}
          client="KMS"
          method="listKeys"
          arrayKeyName="Keys"
          property="KeyId"
          fieldName={fieldName}
          entityName="KMS Key"
          creationRoute={DEFAULT_KMS_ROUTES.RESOURCES_KMS_KEY_CREATE}
          required={required}
          clientOverrides={clientOverrides}
        />
      ),
      cloudWatchLogGroupName$: (control, fieldName, required) => (
        <RelatedResourcePicker
          control={control}
          client="CloudWatchLogs"
          method="describeLogGroups"
          arrayKeyName="logGroups"
          property="logGroupName"
          fieldName={fieldName}
          entityName="Cloudwatch Log Group"
          creationRoute={DEFAULT_CLOUDWATCH_ROUTES.RESOURCES_CLOUDWATCH_GROUP_CREATE}
          required={required}
          clientOverrides={clientOverrides}
        />
      ),
      s3BucketName$: (control, fieldName, required) => (
        <RelatedResourcePicker
          control={control}
          client="S3"
          method="listBuckets"
          arrayKeyName="Buckets"
          property="Name"
          fieldName={fieldName}
          entityName="S3 Bucket"
          creationRoute={DEFAULT_S3_ROUTES.RESOURCES_S3_BUCKET_CREATE}
          required={required}
          clientOverrides={clientOverrides}
        />
      ),
    }}
    onSubmit={(data: CreateECSClusterParams) => {
      if (!cluster) return onCreate(data);
      return onUpdate({ ...data, cluster: cluster.clusterName as string });
    }}
  />
);
