import { ReactElement, ReactNode } from 'react';
import { List } from '@mui/material';

type Props = {
  children: ReactNode;
};

export const DangerZoneActions = ({ children }: Props): ReactElement => <List>{children}</List>;

export default DangerZoneActions;
