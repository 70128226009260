import { ReactElement } from 'react';
import { CreateStateMachineRequest, DescribeStateMachine, UpdateStateMachineRequest } from '@localstack/types';
import { VALIDATION_RULES, getSchema, AwsClientOverrides } from '@localstack/services';
import { DEFAULT_IAM_ROUTES } from '@localstack/constants';

import { ControlledCodeEditor, RelatedResourcePicker } from '../../../form';
import { MagicForm } from '../../magic/MagicForm';

export type StateMachineUpsertFormProps = {
  stateMachine?: Optional<Partial<DescribeStateMachine>>;
  loading?: boolean;
  formId: string;
  clientOverrides?: AwsClientOverrides;
  onCreate: (data: CreateStateMachineRequest) => unknown;
  onUpdate: (data: UpdateStateMachineRequest) => unknown;
};

export const StateMachineUpsertForm = ({
  stateMachine,
  loading,
  formId,
  onCreate,
  onUpdate,
  clientOverrides,
}: StateMachineUpsertFormProps): ReactElement => (
  <MagicForm
    data={stateMachine}
    schema={getSchema('StepFunctions')}
    formId={formId}
    entry={stateMachine ? 'UpdateStateMachineInput' : 'CreateStateMachineInput'}
    loading={loading}
    fieldConditions={{
      '^stateMachineArn$': !stateMachine,
    }}
    externalFields={{
      '^definition$': (control, fieldName, required) => (
        <ControlledCodeEditor
          control={control}
          name={fieldName}
          language="json"
          rules={{ ...VALIDATION_RULES.required, ...VALIDATION_RULES.validJson }}
          required={required}
        />
      ),
      roleArn: (control, fieldName, required) => (
        <RelatedResourcePicker
          control={control}
          client="IAM"
          method="listRoles"
          arrayKeyName="Roles"
          property="Arn"
          fieldName={fieldName}
          entityName="IAM Role"
          creationRoute={DEFAULT_IAM_ROUTES.RESOURCES_IAM_ROLE_CREATE}
          required={required}
          clientOverrides={clientOverrides}
        />
      ),
    }}
    onSubmit={(data: CreateStateMachineRequest) => {
      if (!stateMachine) return onCreate({ ...data });
      return onUpdate({ ...data, stateMachineArn: stateMachine.stateMachineArn ?? '' });
    }}
  />
);
