import { ReactElement, useEffect, useState } from 'react';
import { Breadcrumbs, LoadingFragment, ProgressButton, StatusIndicator } from '@localstack/ui';
import {
  useAwsEffect,
  useAwsGetter,
  useLocalstackStatus,
  useRegion,
  useRoutes,
  retrieveAuthToken,
} from '@localstack/services';
import { Box, Button, Chip, Grid, Step, StepContent, StepLabel, Stepper, Theme, Typography } from '@mui/material';
import { GitHub, Info, OpenInNew } from '@mui/icons-material';
import makeStyles from '@mui/styles/makeStyles';

import { ExternalLink } from '@localstack/constants';
import { CloudPodMergeStrategy, LocalStackEvent } from '@localstack/types';

import { ContainedCustomerLayout } from '~/layouts';
import { AppRoute } from '~/config';

import { AlertLocalStackNotRunning, AlertLocalStackRunning } from '../../Launchpad/components';

const DEMO_SERVICES = ['Lambda', 'DynamoDB', 'ApiGateway', 'CloudFront', 'S3', 'SQS', 'SES', 'StepFunctions', 'SNS'];
const DEMO_REGION = 'us-east-1';
const DEMO_POD_APP = 'serverless-quiz-app';
const DEMO_POD_APP_DATA = 'serverless-quiz-app-data';

const useStyles = makeStyles((theme: Theme) => ({
  stepperContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: '1rem',
  },
  stepperMidsection: {
    display: 'flex',
    flexWrap: 'wrap',
    gap: '0.4rem',
    fontWeight: 500,
  },
  stepperActions: {
    display: 'flex',
    gap: '1rem',
  },
  gridItem: {
    display: 'flex',
    flexDirection: 'column',
    gap: '0.5rem',
  },
  archSection: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    gap: '0.5rem',
  },
  stepInfo: {
    fontSize: '0.9rem',
    verticalAlign: 'middle',
    color: theme.palette.text.secondary,
    '& svg': {
      height: '16px',
      verticalAlign: 'middle',
    },
  },
}));

export const ServerlessQuizApp = (): ReactElement => {
  const classes = useStyles();
  const { goto } = useRoutes();

  const { running, version, isFirstLoad } = useLocalstackStatus();

  const [showLeftColumn, setShowLeftColumn] = useState(true);
  const [hasLoadedPod, setHasLoadedPod] = useState(false);
  const [logOutput, setLogOutput] = useState<LocalStackEvent[]>([]);
  const [loadingPodName, setLoadingPodName] = useState('');
  const [activeStep, setActiveStep] = useState(0);

  const { region, setRegion } = useRegion();

  useEffect(() => {
    if (region !== DEMO_REGION) setRegion(DEMO_REGION);
  }, []);

  const { data: distros, mutate: reloadDistros } = useAwsGetter('CloudFront', 'listDistributions', undefined, {
    silentErrors: true,
  });

  const {
    loadPod,
    isLoading: isLoadingPod,
    hasError: hasPodLoadingError,
  } = useAwsEffect('LocalStack', ['loadPod'], { silentErrors: true });

  const appCloudFrontDistroId = distros?.DistributionList?.Items?.[0]?.Id;
  const appUrl = `https://localhost.localstack.cloud/cloudfront/${appCloudFrontDistroId}/`;

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const onLoadPod = async (podName: string) => {
    setLogOutput([]);
    setHasLoadedPod(false);
    setLoadingPodName(podName);
    setLogOutput([{ event: 'status', message: 'Loading pod...' }]);
    const authToken = retrieveAuthToken();
    await loadPod(podName, undefined, authToken?.token, CloudPodMergeStrategy.ACCOUNT_REGION_MERGE);
    setHasLoadedPod(true);
    setLoadingPodName('');
    reloadDistros();
    handleNext();
  };

  const steps = [
    {
      label: 'Load infrastructure configuration',
      description: (
        <Box className={classes.stepperContainer}>
          <Typography>Deploy AWS services required to set up the application infrastructure.</Typography>
          <Box className={classes.stepperMidsection}>
            {DEMO_SERVICES.map((service) => (
              <Chip key={service} label={service} variant="outlined" size="small" />
            ))}
          </Box>
          <Box className={classes.stepperActions}>
            <ProgressButton
              variant="contained"
              color="primary"
              disabled={loadingPodName === DEMO_POD_APP || !running}
              onClick={() => {
                onLoadPod(DEMO_POD_APP);
              }}
              loading={loadingPodName === DEMO_POD_APP}
            >
              Load Cloud Pod
            </ProgressButton>
            {(isLoadingPod || hasLoadedPod) && (
              <StatusIndicator
                isProgressing={isLoadingPod}
                logEvents={logOutput}
                hasError={hasPodLoadingError && !isLoadingPod}
              />
            )}
          </Box>
          {!running && (
            <span className={classes.stepInfo}>
              <Info /> You need a running LocalStack instance to load a cloud pod.
            </span>
          )}
        </Box>
      ),
    },
    {
      label: 'View deployed application',
      description: (
        <Box className={classes.stepperContainer}>
          <Typography>View the deployed application on your LocalStack instance.</Typography>
          <Box className={classes.stepperMidsection}>
            <Button
              variant="contained"
              color="primary"
              onClick={() => {
                setHasLoadedPod(false);
                handleNext();
              }}
            >
              Continue
            </Button>
            <Button variant="text" target="_blank" href={appUrl}>
              View application&nbsp;
              <OpenInNew />
            </Button>
          </Box>
        </Box>
      ),
    },
    {
      label: 'Load application data',
      description: (
        <Box className={classes.stepperContainer}>
          <Typography>Insert quiz test data into the DynamoDB service.</Typography>
          <Box className={classes.stepperMidsection}>
            <Chip label="DynamoDB" variant="outlined" size="small" />
          </Box>
          <Box className={classes.stepperActions}>
            <ProgressButton
              variant="contained"
              color="primary"
              disabled={loadingPodName === DEMO_POD_APP_DATA || !running}
              onClick={() => {
                onLoadPod(DEMO_POD_APP_DATA);
              }}
              loading={loadingPodName === DEMO_POD_APP_DATA}
            >
              Load Cloud Pod
            </ProgressButton>
            {(isLoadingPod || hasLoadedPod) && (
              <StatusIndicator
                isProgressing={isLoadingPod}
                logEvents={logOutput}
                hasError={hasPodLoadingError && !isLoadingPod}
              />
            )}
          </Box>
          {!running && (
            <span className={classes.stepInfo}>
              <Info /> You need a running LocalStack instance to load a cloud pod.
            </span>
          )}
        </Box>
      ),
    },
  ];

  return (
    <ContainedCustomerLayout
      documentTitle="Serverless Container-Based API Application deployed with Cloud Pods layers"
      title={
        <Box>
          <Typography variant="h4">Serverless Quiz App</Typography>
          <Breadcrumbs
            mappings={[
              ['Quickstart', () => goto(AppRoute.QUICKSTART)],
              ['Serverless Quiz App', null],
            ]}
          />
        </Box>
      }
    >
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <LoadingFragment loading={isFirstLoad} variant="card" size={1} height={65}>
            {!running ? AlertLocalStackNotRunning : AlertLocalStackRunning(version)}
          </LoadingFragment>
        </Grid>

        <Grid item xs={12}>
          <Grid container spacing={2}>
            <Grid
              item
              xs={12}
              md={5}
              className={classes.gridItem}
              sx={{ display: showLeftColumn ? 'flex' : 'none !important', borderRadius: '8px' }}
            >
              <Typography variant="h3">Serverless Quiz App</Typography>
              <div>
                <Button
                  variant="text"
                  startIcon={<GitHub />}
                  href={ExternalLink.DEMO_QUIZ_APP}
                  target="_blank"
                  size="small"
                >
                  localstack-samples/serverless-quiz-app
                </Button>
              </div>
              <Typography variant="body1">
                A serverless app where users can create quizzes, submit answers, and see leaderboards. The quickstart
                was designed to demonstrate how cloud development, debugging, and testing works with LocalStack.
              </Typography>
              <Stepper activeStep={activeStep} orientation="vertical">
                {steps.map((step) => (
                  <Step key={step.label}>
                    <StepLabel>
                      <Typography variant="h5">{step.label}</Typography>
                    </StepLabel>
                    <StepContent>{step.description}</StepContent>
                  </Step>
                ))}
                {activeStep === 3 && (
                  <Typography variant="body1" style={{ marginLeft: '32px' }}>
                    <span>Application infrastructure and data loaded.</span>
                    <Button variant="text" target="_blank" href={appUrl}>
                      View application&nbsp;
                      <OpenInNew />
                    </Button>
                  </Typography>
                )}
              </Stepper>
            </Grid>
            <Grid item xs={12} md={showLeftColumn ? 7 : 12} className={classes.archSection}>
              <Box
                onClick={() => {
                  setShowLeftColumn(!showLeftColumn);
                }}
                style={{ cursor: showLeftColumn ? 'zoom-in' : 'zoom-out' }}
              >
                <img
                  alt=""
                  src="/serverless-quiz-app/architecture-diagram.png"
                  width="100%"
                  style={{ borderRadius: '16px' }}
                />
              </Box>
              <Typography variant="subtitle2">Application Architecture</Typography>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </ContainedCustomerLayout>
  );
};
