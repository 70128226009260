import { AWS_REGIONS } from '@localstack/constants';
import { ControlledAutocomplete } from '@localstack/ui';
import { TextField } from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import { ReactElement } from 'react';
import { Control } from 'react-hook-form';

import { DEFAULT_REGIONS } from '../constants';

const useStyles = makeStyles(() =>
  createStyles({
    regionDropdown: {
      width: '12em',
    },
  }),
);

type Props = {
  control: Control<any>; // eslint-disable-line
};

export const RegionInput = ({ control }: Props): ReactElement => {
  const classes = useStyles();

  return (
    <>
      <ControlledAutocomplete
        name="region"
        control={control}
        options={AWS_REGIONS}
        getOptionValue={(name) => name}
        getValueOption={(name) => name}
        renderInput={(params) => <TextField {...params} label="Region" variant="outlined" />}
        size="small"
        className={classes.regionDropdown}
        fullWidth
        // This is here as defaultValue is a required field in ControlledAutocomplete
        // otherwise this is handled automatically by control={control}
        defaultValue={DEFAULT_REGIONS[0]}
      />
    </>
  );
};
