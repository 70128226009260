import { useContext } from 'react';

import { ProductFeature, UseAuthProviderReturnType } from '@localstack/types';

import { extractFeatureTuple } from '@localstack/services/src/util';

import { AuthGuardContext } from '~/contexts/AuthGuardContext';

export const useAuthProvider = (): UseAuthProviderReturnType => {
  const context = useContext(AuthGuardContext);

  const { roles, permissions, features } = context.userInfo ?? {};

  const featureTuples = (features ?? []).map(extractFeatureTuple);
  const featureNames = featureTuples.map((f) => f[0]);
  const featureVariants = featureTuples.map((f) => f[1]);

  const can = (permission: string) => (permissions ?? []).includes(permission) || (roles ?? []).includes(permission);
  const hasFeature = (feature: ProductFeature, variant?: string) => {
    const conditions: boolean[] = [];

    conditions.push(featureNames.includes(feature));

    if (variant !== undefined) {
      conditions.push(featureVariants.includes(variant));
    }

    return conditions.every(Boolean);
  };

  return { ...context, can, hasFeature };
};
