import { ReactElement } from 'react';
import { PutCloudWatchMetricRequest } from '@localstack/types';
import { getSchema } from '@localstack/services';

import { MagicForm } from '../../magic/MagicForm';

export interface MonitoringMetricFormProps {
  loading?: boolean;
  formId?: string;
  onCreate: (data: PutCloudWatchMetricRequest) => unknown;
}

export const MonitoringMetricForm = ({ loading, formId, onCreate }: MonitoringMetricFormProps): ReactElement => (
  <MagicForm
    schema={getSchema('CloudWatch')}
    loading={loading}
    entry="PutMetricDataInput"
    formId={formId}
    fieldConditions={{
      // leave as unsupported for now, add a switch at some point
      'MetricData\\.[0-9]+\\.StatisticValues': false,
    }}
    onSubmit={(data: PutCloudWatchMetricRequest) => onCreate(data)}
  />
);
