import { ReactElement } from 'react';
import { CreateCognitoGroupRequest, UpdateCognitoGroupRequest, CognitoGroup } from '@localstack/types';
import { AwsClientOverrides, getSchema } from '@localstack/services';
import { DEFAULT_IAM_ROUTES } from '@localstack/constants';

import { MagicForm } from '../../magic/MagicForm';
import { RelatedResourcePicker } from '../../../form/RelatedResourcePicker';

export interface CognitoGroupFormProps {
  poolId: string;
  group?: Optional<Partial<CognitoGroup>>;
  loading?: boolean;
  formId?: string;
  clientOverrides?: AwsClientOverrides;
  onCreate: (data: CreateCognitoGroupRequest) => unknown;
  onUpdate: (data: UpdateCognitoGroupRequest) => unknown;
}

export const CognitoGroupForm = ({
  poolId,
  group,
  loading,
  formId,
  clientOverrides,
  onCreate,
  onUpdate,
}: CognitoGroupFormProps): ReactElement => (
  <MagicForm
    data={group}
    schema={getSchema('CognitoIdentityServiceProvider')}
    loading={loading}
    entry={group ? 'UpdateGroupRequest' : 'CreateGroupRequest'}
    formId={formId}
    fieldConditions={{
      '^UserPoolId': false,
      '^GroupName': !group,
    }}
    externalFields={{
      '^RoleArn$': (control, fieldName, required) => (
        <RelatedResourcePicker
          control={control}
          client="IAM"
          method="listRoles"
          arrayKeyName="Roles"
          property="Arn"
          fieldName={fieldName}
          entityName="IAM Role"
          creationRoute={DEFAULT_IAM_ROUTES.RESOURCES_IAM_ROLE_CREATE}
          required={required}
          clientOverrides={clientOverrides}
        />
      ),
    }}
    onSubmit={(data: CreateCognitoGroupRequest) => {
      if (!group) return onCreate({ ...data, UserPoolId: poolId });
      return onUpdate({ ...data, UserPoolId: poolId, GroupName: group.GroupName as string });
    }}
  />
);
