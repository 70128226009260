import { Validate } from 'react-hook-form';
import { TaxIdType } from '@localstack/types';
import { EU_COUNTRIES } from '@localstack/constants';

import { getTaxIdType, TAX_ID_TYPE_REGEX_MAP } from './tax';

interface PasswordValidatorReturn {
  lowercase: boolean;
  uppercase: boolean;
  digits: boolean;
  specials: boolean;
  length: boolean;
}

const MB_IN_B = 1000000;

const EMAIL_REGEX = /[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}/;
const PHONE_REGEX = /^[+0-9()\-\s]+$/;
const ALPHA_NUM_HYPHEN_REGEX = /^[a-zA-Z0-9-]+$/;
const BASE_MAX_FIELD_LENGTH = 255;
const BASE_MIN_FIELD_LENGTH = 1;
const BASE_MAX_LENGTH_MESSAGE = 'Input is too long';
const BASE_MIN_LENGTH_MESSAGE = 'Input is too short';
export const BASE_REQUIRED_ERROR = 'Is required';

export const isStringValidJson = (input: string): boolean => {
  try {
    JSON.parse(input);
    return true;
  } catch (_e) {
    return false;
  }
};

export const passwordValidator = (password: string): PasswordValidatorReturn => {
  const lowercase = new Array(26).fill(null).map((_, i) => String.fromCharCode(97 + i));
  const uppercase = new Array(26).fill(null).map((_, i) => String.fromCharCode(65 + i));
  const digits = new Array(10).fill(null).map((_, i) => String.fromCharCode(48 + i));

  const specials = [
    ...new Array(15).fill(null).map((_, i) => String.fromCharCode(33 + i)),
    ...new Array(7).fill(null).map((_, i) => String.fromCharCode(58 + i)),
    ...new Array(6).fill(null).map((_, i) => String.fromCharCode(91 + i)),
    ...new Array(4).fill(null).map((_, i) => String.fromCharCode(123 + i)),
  ];

  return {
    lowercase: lowercase.some((c) => password.includes(c)),
    uppercase: uppercase.some((c) => password.includes(c)),
    digits: digits.some((c) => password.includes(c)),
    specials: specials.some((c) => password.includes(c)),
    length: password.length >= 8,
  };
};

const validatePhoneNumber = (phone: Optional<string>, isRequired: boolean): string | boolean => {
  if (!isRequired && (!phone || phone.trim() === '')) {
    return true;
  }

  const phoneStr = phone || '';

  if (!PHONE_REGEX.test(phoneStr)) {
    return 'Invalid Phone number';
  }

  let localNumber = '';

  // Case 1: Has area code - extract everything after closing parenthesis
  if (phoneStr.includes(')')) {
    const afterParenMatch = phoneStr.match(/\)(.+)$/);
    localNumber = afterParenMatch == null || afterParenMatch[1] == null ? '' : afterParenMatch[1];
  }
  // Case 2: Starts with + but no parentheses or plain number
  else {
    localNumber = phoneStr;
  }

  // Remove all non-digit characters from the local number, this includes + and - that could be present
  const cleanLocalNumber = localNumber.replace(/\D/g, '');

  const digitCount = cleanLocalNumber.length;

  if (digitCount < 7) {
    return 'Phone number is too short';
  }

  if (digitCount > 15) {
    return 'Phone number is too long';
  }

  return true;
};

export const VALIDATION_RULES = {
  required: {
    required: BASE_REQUIRED_ERROR,
  },
  zipCode: {
    required: BASE_REQUIRED_ERROR,
    maxLength: {
      value: 12,
      message: 'Zip code is too long',
    },
  },
  integerOnly: {
    validate: (value: Optional<string>): string | boolean => !value || /^\d+$/i.test(value) || 'Must be an integer',
  },
  default: {
    maxLength: {
      value: BASE_MAX_FIELD_LENGTH,
      message: BASE_MAX_LENGTH_MESSAGE,
    },
    minLength: {
      value: BASE_MIN_FIELD_LENGTH,
      message: BASE_MIN_LENGTH_MESSAGE,
    },
  },
  defaultRequired: {
    required: BASE_REQUIRED_ERROR,
    maxLength: {
      value: BASE_MAX_FIELD_LENGTH,
      message: BASE_MAX_LENGTH_MESSAGE,
    },
    minLength: {
      value: BASE_MIN_FIELD_LENGTH,
      message: BASE_MIN_LENGTH_MESSAGE,
    },
  },
  phone: {
    validate: (phone: Optional<string>): string | boolean => validatePhoneNumber(phone, false),
  },
  phoneRequired: {
    validate: (phone: Optional<string>): string | boolean => validatePhoneNumber(phone, true),
  },
  password: {
    validate: (password: Optional<string>): string | boolean => {
      const result = passwordValidator(password ?? '');

      if (!result.lowercase) {
        return 'Password must contain lowercase letters';
      }

      if (!result.uppercase) {
        return 'Password must contain uppercase letters';
      }

      if (!result.digits) {
        return 'Password must contain digits';
      }

      if (!result.specials) {
        return 'Password must contain special characters';
      }

      if (!result.length) {
        return 'Password must be at least 8 characters long';
      }

      return true;
    },
  },
  noSpaces: {
    validate: (name: string | undefined): string | boolean => !name?.includes(' ') || 'May not contain spaces',
  },
  onlyAlphaNumAndHyphen: {
    validate: (name: Optional<string>): string | boolean =>
      ALPHA_NUM_HYPHEN_REGEX.test(name || '') || 'Only alphanumeric values and hyphen are allowed',
  },
  email: {
    validate: (email: Optional<string>): string | boolean => EMAIL_REGEX.test(email || '') || 'Invalid Email',
    maxLength: {
      value: BASE_MAX_FIELD_LENGTH,
      message: 'Email is too long',
    },
  },
  passwordConfirmation: (password: Optional<string>): { validate: Validate<string> } => ({
    validate: (value: Optional<string>) => value === password || 'Passwords do not match',
  }),
  validJson: {
    validate: (input: string): string | boolean => {
      try {
        JSON.parse(input);
        return true;
      } catch (e) {
        return e.message;
      }
    },
  },
  taxId: (country: Optional<string>): { validate: Validate<string> } => ({
    validate: (value: Optional<string>) => {
      if (!country || !value) return true;

      const countryCode = country.toUpperCase();
      const isEuCountry = EU_COUNTRIES.includes(countryCode);
      const taxIdCode = isEuCountry ? TaxIdType.EU_VAT.toUpperCase() : countryCode;

      // disable validation for unsupported countries
      if (!Object.keys(TAX_ID_TYPE_REGEX_MAP).some((key) => key.toUpperCase().startsWith(taxIdCode))) {
        return true;
      }

      if (!getTaxIdType(country, value)) return 'Invalid TAX ID';

      return true;
    },
  }),
  greaterThanZero: {
    validate: (value: string): string | boolean =>
      parseInt(value, 10) > 0 ? true : 'Number of shards must be greater than zero',
  },
  https: {
    validate: (value: Optional<string>): string | boolean =>
      !value || value.startsWith('https://') || 'URL has to start with https://',
  },
  fileSize: (fileSizeMB: number, required = true): { validate: Validate<File> } => ({
    validate: (value?: File): string | boolean =>
      (!required && !value) ||
      (value && value?.size < fileSizeMB * MB_IN_B) ||
      `File need to be smaller than ${fileSizeMB}MB`,
  }),
  notEqualsTo: (forbiddenValues: string | string[]): { validate: (value: string) => string | boolean } => ({
    validate: (value: string): string | boolean =>
      forbiddenValues.includes(value) ? `"${value}" is not permitted for use in this field.` : true,
  }),
};
