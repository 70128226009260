import { ReactElement } from 'react';
import { useRoutes, useAwsGetter } from '@localstack/services';
import { useParams } from 'react-router-dom';
import { Card, CardContent, Button } from '@mui/material';
import { AppSyncApiDetails, PageTitle } from '@localstack/ui';

import { DEFAULT_APPSYNC_ROUTES } from '@localstack/constants';

import { NavTabs } from './components';
import { AppSyncProps } from './types';

export const AppSyncApi = ({
  Layout,
  clientOverrides,
  routes = DEFAULT_APPSYNC_ROUTES,
}: AppSyncProps): ReactElement => {
  const { goto } = useRoutes();
  const { apiId } = useParams<'apiId'>();

  const { data: api, mutate } = useAwsGetter('AppSync', 'getGraphqlApi', [{ apiId }], { clientOverrides });

  return (
    <Layout
      documentTitle="API Details"
      tabs={<NavTabs apiId={apiId as string} routes={routes} />}
      title={
        <PageTitle
          title="API Details"
          onMutate={mutate}
          breadcrumbs={[
            ['AppSync', () => goto(routes.RESOURCES_APPSYNC)],
            [apiId, null],
          ]}
        />
      }
      actions={<Button onClick={() => goto(routes.RESOURCES_APPSYNC_API_UPDATE, { apiId })}>Edit API</Button>}
    >
      <Card>
        <CardContent>
          <AppSyncApiDetails api={api?.graphqlApi} />
        </CardContent>
      </Card>
    </Layout>
  );
};
