import { ReactElement } from 'react';
import { CreateLoadBalancerV2 } from '@localstack/types';
import { AwsClientOverrides, getSchema } from '@localstack/services';

import { MagicForm } from '../../magic/MagicForm';

export type ELoadBalancerV2Props = {
  loading?: boolean;
  formId: string;
  clientOverrides?: AwsClientOverrides;
  onSubmit: (data: CreateLoadBalancerV2) => unknown;
};

export const ELoadBalancerV2Form = ({ loading, formId, onSubmit }: ELoadBalancerV2Props): ReactElement => {
  const submitHandler = async (data: CreateLoadBalancerV2) => {
    return onSubmit(data);
  };

  return (
    <>
      <MagicForm
        schema={getSchema('ELBv2')}
        formId={formId}
        entry="CreateLoadBalancerInput"
        loading={loading}
        onSubmit={(data: CreateLoadBalancerV2) => submitHandler(data)}
      />
    </>
  );
};
