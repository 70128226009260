import { SERVICE_CODES } from '@localstack/constants';
import { AggregatedPolicies, IAMPolicyEngineState, PolicyStreamResponse, RequestStatus } from '@localstack/types';
import { AwsServiceIcon } from '@localstack/ui';
import {
  Card,
  Grid,
  Autocomplete,
  TextField,
  Box,
  Typography,
  CardContent,
  Button,
  Checkbox,
  FormControlLabel,
  MenuItem,
} from '@mui/material';

import { useState, useRef, useMemo, useCallback, useEffect, ReactElement } from 'react';

import { AwsClientOverrides, getCurrentTimestamp, useAwsEffect } from '@localstack/services';

import { DeleteForeverOutlined, Search } from '@mui/icons-material';

import { useStyles } from '../common/style';
import { SUPPORTED_RESOURCES } from '../common/const';

import { PolicyStreamDrawer } from '../PolicyStreamDrawer';
import { OperationsTable } from '../OperationsTable';

type PolicyStreamProps = {
  clientOverrides: AwsClientOverrides;
  createSnackbarError: (err: string) => void;
  enable: boolean;
  policyEngineState: Optional<IAMPolicyEngineState>;
};

const SELECT_ALL_SERVICES = 'Select all Services';
const UNSELECT_ALL_SERVICES = 'Unselect all Services';

export const PolicyStream = ({
  clientOverrides,
  createSnackbarError,
  enable,
  policyEngineState,
}: PolicyStreamProps): ReactElement => {
  const classes = useStyles();
  const [policies, setPolicies] = useState<AggregatedPolicies[]>([]);
  const [outputStreamReader, setOutputStreamReader] = useState<Optional<ReadableStreamDefaultReader<Uint8Array>>>();
  const engineStateRef = useRef(policyEngineState);

  useEffect(() => {
    engineStateRef.current = policyEngineState;
  }, [policyEngineState]);

  const [operationsFilter, setOperationsFilter] = useState<string>('');
  const [includeServices, setIncludeServices] = useState<string[]>(SUPPORTED_RESOURCES);
  const [showInternalCalls, setShowInternalCalls] = useState(true);

  const [selectedPolicy, setSelectedPolicy] = useState<Optional<AggregatedPolicies>>();

  const selectPolicyWithId = (id: string | number) => {
    const realPolicy = policies.find((policy) => policy.request.id === `${id}`);
    setSelectedPolicy(realPolicy);
  };

  const resetPolicies = () => setPolicies([]);

  const { openPolicyStream } = useAwsEffect('LocalStack', ['openPolicyStream'], {
    silentErrors: true,
    clientOverrides,
  });

  const filterOperations = useCallback(
    (policyToCheck: AggregatedPolicies) =>
      operationsFilter?.length > 0
        ? policyToCheck.request.operation.toLowerCase().startsWith(operationsFilter?.toLowerCase())
        : true,
    [operationsFilter],
  );

  const filterIncludeServices = useCallback(
    (policyToCheck: AggregatedPolicies) => includeServices.includes(policyToCheck.request.service),
    [includeServices],
  );

  const filterInternalCalls = useCallback(
    (policyToCheck: AggregatedPolicies) => (!showInternalCalls ? !policyToCheck.request.internal : true),
    [showInternalCalls],
  );

  const filtersToApply = useMemo(
    () => [filterOperations, filterIncludeServices, filterInternalCalls],
    [filterOperations, filterIncludeServices, filterInternalCalls],
  );

  const filteredPolicies = useMemo(
    () => policies.filter((policy) => filtersToApply.every((filter) => filter(policy))),
    [filtersToApply, policies],
  );

  const handleIncomingPolicy = (chunk: PolicyStreamResponse) => {
    setPolicies((currentPolicies) => {
      const aggregationLimit = -20;
      const recentPolicies = currentPolicies.slice(aggregationLimit);
      const aggregatedPolicies: AggregatedPolicies[] = [...recentPolicies];

      const foundRequest = aggregatedPolicies.find((item) => item.request.id === chunk.request.id);

      if (foundRequest) {
        foundRequest.policies.push(chunk.policy);
      } else {
        const iamEnforced = engineStateRef.current === IAMPolicyEngineState.Enforced;
        const denied = !(chunk.request.allowed ?? true);

        let status = RequestStatus.SUCCESS;
        if (iamEnforced && denied) {
          status = RequestStatus.DENIED;
        } else if (denied) {
          status = RequestStatus.WARNING;
        }

        aggregatedPolicies.push({
          request: chunk.request,
          policies: [chunk.policy],
          timestamp: getCurrentTimestamp(),
          status,
        });
      }

      return [...currentPolicies.slice(0, aggregationLimit), ...aggregatedPolicies];
    });
  };

  const openOutputStreamReader = async () => {
    const readerStream = await openPolicyStream({
      onChunkReceived: handleIncomingPolicy,
      onError: createSnackbarError,
    });
    setOutputStreamReader(readerStream);
  };

  const closeOutputStreamReader = () => {
    outputStreamReader?.cancel();
    setOutputStreamReader(undefined);
  };

  useEffect(() => {
    if (enable) {
      openOutputStreamReader();
    }

    return closeOutputStreamReader();
  }, [enable]);

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Box sx={{ display: 'flex', alignItems: 'center', gap: '0.5rem' }}>
          <TextField
            value={operationsFilter}
            label={
              <Box sx={{ display: 'flex', gap: '0.25rem' }}>
                <Search fontSize="small" />
                <Typography>Search operations</Typography>
              </Box>
            }
            onChange={(event) => setOperationsFilter(event.target.value)}
            size="small"
          />
          <Autocomplete
            className={classes.autocomplete}
            multiple
            size="small"
            disableCloseOnSelect
            disablePortal
            options={[SELECT_ALL_SERVICES, UNSELECT_ALL_SERVICES, ...SUPPORTED_RESOURCES]}
            getOptionLabel={(r) => r}
            onChange={(_, e) => setIncludeServices(e)}
            renderInput={(options) => (
              <TextField variant="outlined" label={<Typography>Filter Services</Typography>} {...options} />
            )}
            value={includeServices}
            renderTags={() =>
              includeServices.length === SUPPORTED_RESOURCES.length ? (
                <Typography sx={{ paddingLeft: '0.5rem !important' }}>All services</Typography>
              ) : (
                <Typography sx={{ paddingLeft: '0.5rem !important' }}>
                  Service {includeServices.length > 1 ? 'is any of ' : 'is '}
                  {includeServices.length > 1 ? `${includeServices.length} Services` : `${includeServices[0]}`}
                </Typography>
              )
            }
            renderOption={(props, service) =>
              [SELECT_ALL_SERVICES, UNSELECT_ALL_SERVICES].includes(service) ? (
                <MenuItem
                  onClick={() => setIncludeServices(service === SELECT_ALL_SERVICES ? SUPPORTED_RESOURCES : [])}
                >
                  {service}
                </MenuItem>
              ) : (
                <li {...props}>
                  <Box display="flex" className={classes.centerActions}>
                    <AwsServiceIcon
                      code={service}
                      title={SERVICE_CODES[service as keyof typeof SERVICE_CODES]}
                      size="medium"
                    />
                    <Typography>{SERVICE_CODES[service as keyof typeof SERVICE_CODES]}</Typography>
                  </Box>
                </li>
              )
            }
          />
          <FormControlLabel
            control={
              <Checkbox
                color="primary"
                onChange={() => setShowInternalCalls(!showInternalCalls)}
                checked={showInternalCalls}
              />
            }
            label="Show internal calls"
          />
          <Box sx={{ flexGrow: 1 }} />
          <Button startIcon={<DeleteForeverOutlined />} onClick={resetPolicies}>
            Clear Operations
          </Button>
        </Box>
      </Grid>
      <Grid item xs={12}>
        <Card>
          <CardContent>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <OperationsTable policies={filteredPolicies} onRowClick={(row) => selectPolicyWithId(row.id)} />
                <PolicyStreamDrawer policy={selectedPolicy} onClose={() => setSelectedPolicy(undefined)} />
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  );
};
