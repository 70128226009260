import { ReactElement, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { ControlledCheckbox, ControlledTextField, LogoIcon, ProgressButton } from '@localstack/ui';
import {
  Box,
  FormControlLabel,
  IconButton,
  InputAdornment,
  Link,
  Radio,
  RadioGroup,
  Skeleton,
  Tooltip,
  Typography,
} from '@mui/material';
import {
  formatErrorCode,
  OrganizationsService,
  pushGTMEvent,
  SubscriptionService,
  useApiEffect,
  useRoutes,
  UserService,
  VALIDATION_RULES,
} from '@localstack/services';
import { Controller, useForm } from 'react-hook-form';
import { useLocation } from 'react-router-dom';
import {
  Visibility as VisibilityIcon,
  VisibilityOff as VisibilityOffIcon,
  Cancel as CancelIcon,
} from '@mui/icons-material';
import { ExternalLink, GTMSignupTriggers, PUBLIC_IDENTITY_PROVIDERS } from '@localstack/constants';
import { PlanFamily, PlannedUsageType, TosAcceptanceContext, User } from '@localstack/types';
import classnames from 'classnames';
import { usePostHog } from 'posthog-js/react';

import { AppRoute } from '~/config';
import { useRecaptchaV3 } from '~/hooks/useRecaptchaV3';
import { useAuthProvider } from '~/hooks/useAuthProvider';
import { useSSOStart } from '~/hooks/useSSOStart';

import { AuthLayout } from './AuthLayout';
import { useStyles, usePasswordFieldVisibility } from './common';

enum SIGNUP_FLOW_TYPE {
  EMAIL = 'email',
  PUBLIC_SSO = 'sso',
  ENTERPRISE_SSO = 'enterprise-sso',
}

interface FormFields {
  firstname: string;
  lastname: string;
  email: string;
  password: string;
  github_username: string;
  job_title: string;
  company: string;
  tos: boolean;
  newsletter: boolean;
}

interface FormFlowControlData {
  plannedUsageType: PlannedUsageType;
}

type FormData = FormFields & FormFlowControlData;

enum STEPS_ENUM {
  LOADING = 0,
  LOGIN_DETAILS_STEP = 1,
  LS_USAGE_DETAILS_STEP = 2,
  LS_CHECK_EMAIL_STEP = 3,
}

const ERROR_STEPS_MAP: Record<string, STEPS_ENUM> = {
  'auth.create_identity': STEPS_ENUM.LS_USAGE_DETAILS_STEP,
  'auth.identity_exists': STEPS_ENUM.LOGIN_DETAILS_STEP,
};

const initialStepsMap: Record<SIGNUP_FLOW_TYPE, STEPS_ENUM> = {
  [SIGNUP_FLOW_TYPE.EMAIL]: STEPS_ENUM.LOGIN_DETAILS_STEP,
  [SIGNUP_FLOW_TYPE.PUBLIC_SSO]: STEPS_ENUM.LS_USAGE_DETAILS_STEP,
  [SIGNUP_FLOW_TYPE.ENTERPRISE_SSO]: STEPS_ENUM.LS_USAGE_DETAILS_STEP,
};

const GA4_STEPS_EVENTS: Record<STEPS_ENUM, GTMSignupTriggers | undefined> = {
  [STEPS_ENUM.LOADING]: undefined,
  [STEPS_ENUM.LOGIN_DETAILS_STEP]: GTMSignupTriggers.SIGNUP_START_VIEWED,
  [STEPS_ENUM.LS_USAGE_DETAILS_STEP]: undefined,
  [STEPS_ENUM.LS_CHECK_EMAIL_STEP]: GTMSignupTriggers.SIGNUP_SUCCESS,
};

const FOURTEEN_DAY_TRIAL_DURATION = 14;
const THIRTY_DAY_TRIAL_DURATION = 30;
const THIRTY_DAY_TRIAL_PROMO_CODE = '30daytrial';

export const SignUp = (): ReactElement => {
  const posthog = usePostHog();
  const { search } = useLocation();
  const queryParams = useMemo(() => new URLSearchParams(search), [search]);
  const classes = useStyles();
  const { initToken } = useRecaptchaV3();
  const { userInfo } = useAuthProvider();
  const { goto } = useRoutes();
  const [ssoError, setSsoError] = useState<Optional<string>>(undefined);

  const { isIdpLoading, initSSOFlow } = useSSOStart({
    onError: (err) => setSsoError(err.message),
  });

  const [step, setStep] = useState(STEPS_ENUM.LOADING);
  const [isPublicDomainEmail, setIsPublicDomainEmail] = useState(true);
  const [maskPassword, setMaskPassword] = useState<boolean>(false);
  const passwordFieldRef = useRef<HTMLInputElement>(null);
  const { can } = useAuthProvider();

  const promoCode = queryParams.get('promo');
  const is30DayTrialPromo = promoCode === THIRTY_DAY_TRIAL_PROMO_CODE;

  const isFinishAccountCreation = queryParams.get('isFinishAccountCreation');
  const isEnterpriseSSOSignup = !!Object.keys(userInfo?.org.settings?.sso_settings || {}).length;
  const ssoFlow = isEnterpriseSSOSignup ? SIGNUP_FLOW_TYPE.ENTERPRISE_SSO : SIGNUP_FLOW_TYPE.PUBLIC_SSO;
  const flowType = isFinishAccountCreation ? ssoFlow : SIGNUP_FLOW_TYPE.EMAIL;
  const isSSOFlow = [SIGNUP_FLOW_TYPE.PUBLIC_SSO, SIGNUP_FLOW_TYPE.ENTERPRISE_SSO].includes(flowType);

  const showLSUsageTypeStep = isPublicDomainEmail && flowType !== SIGNUP_FLOW_TYPE.ENTERPRISE_SSO;

  const defaultValues = {
    tos: false,
    newsletter: false,
    plannedUsageType: flowType === SIGNUP_FLOW_TYPE.ENTERPRISE_SSO ? PlannedUsageType.PROFESSIONAL : undefined,
  };
  const partialUser: Partial<FormData> = useMemo(
    () => ({
      firstname: userInfo?.user.firstname,
      lastname: userInfo?.user.lastname,
      email: userInfo?.user.email,
      ...defaultValues,
    }),
    [userInfo],
  );

  const {
    control,
    watch,
    handleSubmit,
    formState: { isValid: formIsValid, errors: formErrors },
    resetField,
    setValue,
    trigger,
    reset,
  } = useForm<FormData>({
    mode: 'all',
    defaultValues,
  });

  const { signupUser, error, isLoading: isSigningUpWithEmail } = useApiEffect(UserService, ['signupUser']);
  const {
    updateAccount,
    acceptTermsOfService,
    isLoading: isSigningUpWithSSO,
  } = useApiEffect(UserService, ['updateAccount', 'acceptTermsOfService'], { revalidate: ['getUser'] });
  const { createSubscription, isLoading: isCreatingSubscription } = useApiEffect(
    SubscriptionService,
    ['createSubscription'],
    {
      revalidate: ['listSubscriptions', 'getSubscription', 'listKeys', 'listInvoices', 'listPlans'],
      revalidateOtherClient: {
        client: OrganizationsService,
        methods: ['listLicenseAssignments', 'getUserLicenseAssignment'],
      },
    },
  );
  const { checkEmailDomain, isLoading: isCheckingEmailDomain } = useApiEffect(UserService, ['checkEmailDomain']);

  const email = watch('email');
  const plannedUsageType = watch('plannedUsageType');
  const isSigningUp = isSigningUpWithEmail || isSigningUpWithSSO || isCreatingSubscription;
  const errorCode = error && formatErrorCode(error);
  const isAuthenticated = can('authenticated');
  const requireAccountCreationFinish = userInfo?.user.signup_survey?.require_finish_account_creation;

  const { showPasswordField, setShowPasswordField } = usePasswordFieldVisibility(formErrors.email, passwordFieldRef);

  const onSSOUserSubmit = async (data: FormData) => {
    const signupSurveyData = {
      create_sub: true,
      planned_usage_type: data.plannedUsageType,
      require_finish_account_creation: false,
    };
    const { firstname, lastname, job_title, company, github_username, newsletter } = data;
    const userData: Partial<User> = {
      firstname,
      lastname,
      job_title,
      company,
      github_username,
      signup_survey: signupSurveyData,
      newsletter,
    };
    await updateAccount(userData as User);
    await acceptTermsOfService({ acceptance_context: TosAcceptanceContext.SIGN_UP });
    await createSubscription({
      plan: `plan.${
        signupSurveyData.planned_usage_type === PlannedUsageType.HOBBYIST
          ? PlanFamily.HOBBY_PLANS
          : PlanFamily.TRIAL_PLANS
      }`,
    });
    pushGTMEvent(GTMSignupTriggers.SIGNUP_SUCCESS, posthog);
    goto(AppRoute.DASHBOARD);
  };

  const onEmailUserSubmit = async (data: FormData) => {
    const recaptchaToken = await initToken();
    const { plannedUsageType: p } = data;
    const signupSurveyData = {
      create_sub: true,
      planned_usage_type: p,
      promo: promoCode ?? undefined,
    };
    const { firstname, lastname, email: e, password, job_title, company, github_username, newsletter } = data;
    const user = await signupUser({
      firstname,
      lastname,
      email: e,
      password,
      job_title,
      company,
      github_username,
      recaptcha_token: recaptchaToken,
      tos_acceptance_context: TosAcceptanceContext.SIGN_UP,
      signup_survey: signupSurveyData,
      newsletter,
    });
    if (user) {
      setStep(STEPS_ENUM.LS_CHECK_EMAIL_STEP);
    }
  };

  const loginDetailsNextHandler = async (): Promise<void> => {
    if (!showPasswordField) return setShowPasswordField(true);
    trigger(['email', 'password']);
    if (!formIsValid) return;
    const isPublicDomain = await checkIfEmailIsPublicDomain(email);
    setIsPublicDomainEmail(isPublicDomain);
    setStep(STEPS_ENUM.LS_USAGE_DETAILS_STEP);
    if (!isPublicDomain) {
      setValue('plannedUsageType', PlannedUsageType.PROFESSIONAL);
    }
  };

  const handleClickShowPassword = () => setMaskPassword((show) => !show);

  const checkIfEmailIsPublicDomain = useCallback(async (val: string) => {
    const emailDomainInfo = await checkEmailDomain({ email: val });
    return !!emailDomainInfo.is_public;
  }, []);

  const handleEmailCancelHandler = () => {
    resetField('email', { defaultValue: null });
    setShowPasswordField(false);
  };

  const onSubmitHandler = handleSubmit((data) => {
    if (step === STEPS_ENUM.LOGIN_DETAILS_STEP) {
      return loginDetailsNextHandler();
    }
    return isFinishAccountCreation ? onSSOUserSubmit(data) : onEmailUserSubmit(data);
  });

  useEffect(() => {
    if (!isSSOFlow) return;

    const defaultValuesLoaded = !!email;
    if (defaultValuesLoaded) return;

    const isUserLoaded = !!partialUser?.email;
    if (isUserLoaded) {
      reset(partialUser);
    }
  }, [partialUser]);

  useEffect(() => {
    // Set initial step
    // For SSO flows, where email will be set by default, set initial step to an actual step instead of "Loading"
    // For email flow, directly set the login details step

    (async () => {
      if (step !== STEPS_ENUM.LOADING) return; // Initial step already set, no need to proceed
      if (isSSOFlow && !email) return; //  Prevent setting initial step for sso signups until auth user's email is available
      if (isSSOFlow) {
        const isPublicDomain = await checkIfEmailIsPublicDomain(email);
        setIsPublicDomainEmail(isPublicDomain);
        if (!isPublicDomain) {
          initialStepsMap[SIGNUP_FLOW_TYPE.PUBLIC_SSO] = STEPS_ENUM.LS_USAGE_DETAILS_STEP;
          setValue('plannedUsageType', PlannedUsageType.PROFESSIONAL);
        }
      }
      setStep(initialStepsMap[flowType]);
    })();
  }, [email]);

  useEffect(() => {
    if (!error) return;
    const redirectToStep = ERROR_STEPS_MAP[errorCode || ''];
    if (redirectToStep) setStep(redirectToStep);
  }, [error]);

  useEffect(() => {
    let event = GA4_STEPS_EVENTS[step];
    if (step === STEPS_ENUM.LOADING) return;
    if (step === STEPS_ENUM.LS_USAGE_DETAILS_STEP) {
      event = showLSUsageTypeStep
        ? GTMSignupTriggers.SIGNUP_USAGE_SELECTION_VIEWED
        : GTMSignupTriggers.SIGNUP_USAGE_SELECTION_SKIPPED;
    }
    if (!event) return;
    pushGTMEvent(event, posthog);
  }, [step]);

  useEffect(() => {
    if (!isAuthenticated) {
      return;
    }
    if (requireAccountCreationFinish && !isFinishAccountCreation) {
      // On signup, we redirect users from /sign-up to /sign-up?isFinishAccountCreation=true
      // doing goto('/same-page',queryParams) is changing the URL but not reloading the page's content for some reason
      // we need to call goto with refresh to actually replace the content
      goto(AppRoute.SIGN_UP, { refresh: true }, 'isFinishAccountCreation=true');
    }
    if (!requireAccountCreationFinish) {
      goto(AppRoute.DASHBOARD);
    }
  }, [isAuthenticated, goto]);

  const finalDetailsRef = useRef();
  useEffect(() => {
    if (!finalDetailsRef.current) return;
    pushGTMEvent(GTMSignupTriggers.SIGNUP_FINAL_DETAILS_VIEWED, posthog);
  }, [finalDetailsRef.current]);

  return (
    <AuthLayout wideLayout={step === STEPS_ENUM.LS_CHECK_EMAIL_STEP} documentTitle="Sign Up">
      <Box textAlign="center">
        <Box mb={4}>
          <LogoIcon />
        </Box>
        <Typography variant="h3" component="h2" fontWeight={600}>
          {step === STEPS_ENUM.LS_CHECK_EMAIL_STEP ? 'Check your email' : 'Start LocalStack'}
        </Typography>
        {step !== STEPS_ENUM.LS_CHECK_EMAIL_STEP && (
          <Typography fontSize={18} className={classes.grey600Text} fontWeight={500} mt={0.5}>
            Create your free account.
          </Typography>
        )}
        {step === STEPS_ENUM.LOADING && (
          <>
            <Box mt={4}>
              <Skeleton variant="rectangular" height={370} width="100%" />
            </Box>
          </>
        )}

        {step !== STEPS_ENUM.LOADING && (
          <>
            <form onSubmit={onSubmitHandler} id="signup">
              {step === STEPS_ENUM.LOGIN_DETAILS_STEP && (
                <>
                  <Box display="flex" flexDirection="column" rowGap={1} mt={3.5}>
                    {PUBLIC_IDENTITY_PROVIDERS.map(({ orgId, idpName, label }) => (
                      <ProgressButton
                        key={idpName}
                        loading={isIdpLoading}
                        variant="outlined"
                        color="tertiary"
                        fullWidth
                        size="xlarge"
                        data-sso-button={`${orgId}/${idpName}`}
                        sx={{ textTransform: 'none' }}
                        onClick={() => {
                          setSsoError(undefined);
                          initSSOFlow({
                            orgId: orgId,
                            idpName: idpName,
                          });
                        }}
                      >
                        Continue with {label}
                      </ProgressButton>
                    ))}
                  </Box>
                  {ssoError && (
                    <Box mt={1}>
                      <Typography fontSize={13} fontWeight={500} color="error">
                        {ssoError}
                      </Typography>
                    </Box>
                  )}
                  <Box mt={4} display="flex" flexDirection="column" rowGap={1}>
                    <ControlledTextField
                      control={control}
                      showErrorsOnlyIfTouched={false}
                      name="email"
                      fullWidth
                      placeholder="Enter your email address"
                      type="text"
                      variant="outlined"
                      rules={{
                        ...VALIDATION_RULES.email,
                        ...VALIDATION_RULES.required,
                      }}
                      InputProps={{
                        endAdornment: email ? (
                          <InputAdornment position="end">
                            <IconButton
                              onClick={handleEmailCancelHandler}
                              size="small"
                              aria-label="Clear email address"
                            >
                              <CancelIcon fontSize="small" />
                            </IconButton>
                          </InputAdornment>
                        ) : undefined,
                      }}
                    />
                    {/* hack to avoid the prefill issues with react-hook-form */}
                    <Box display={showPasswordField ? 'block' : 'none'}>
                      <ControlledTextField
                        control={control}
                        showErrorsOnlyIfTouched={false}
                        name="password"
                        ref={passwordFieldRef}
                        fullWidth
                        placeholder="Enter your password"
                        type={maskPassword ? 'text' : 'password'}
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              <Tooltip title={`${maskPassword ? 'Hide' : 'Show'} Password`}>
                                <IconButton onClick={handleClickShowPassword} size="small">
                                  {maskPassword ? (
                                    <VisibilityOffIcon fontSize="small" />
                                  ) : (
                                    <VisibilityIcon fontSize="small" />
                                  )}
                                </IconButton>
                              </Tooltip>
                            </InputAdornment>
                          ),
                        }}
                        variant="outlined"
                        rules={{
                          validate: (password) => {
                            if (!showPasswordField) return true;
                            return VALIDATION_RULES.password.validate(password);
                          },
                        }}
                      />
                    </Box>
                    <ProgressButton
                      variant="contained"
                      color="primary"
                      fullWidth
                      size="xlarge"
                      type="submit"
                      loading={isCheckingEmailDomain}
                      disabled={isCheckingEmailDomain}
                    >
                      Continue
                    </ProgressButton>
                  </Box>
                </>
              )}
              {step === STEPS_ENUM.LS_USAGE_DETAILS_STEP && (
                <>
                  {showLSUsageTypeStep && (
                    <Box display="flex" flexDirection="column" rowGap={1} mt={3.5}>
                      <Controller
                        rules={VALIDATION_RULES.required}
                        control={control}
                        name="plannedUsageType"
                        render={({ field }) => (
                          <RadioGroup {...field}>
                            <Box display="flex" flexDirection="column" className={classes.radioBoxParent}>
                              <FormControlLabel
                                value={PlannedUsageType.PROFESSIONAL}
                                control={<Radio size="small" />}
                                className={classnames(classes.radioBoxItem, {
                                  [classes.radioBoxActiveItem]: field.value === PlannedUsageType.PROFESSIONAL,
                                })}
                                classes={{
                                  label: classes.radioLabel,
                                }}
                                label={
                                  <Box display="flex" alignItems="center" justifyContent="space-between">
                                    <Typography className={classes.grey700Text} fontWeight={500}>
                                      I&apos;m working on commercial projects
                                    </Typography>
                                  </Box>
                                }
                              />
                              <FormControlLabel
                                value={PlannedUsageType.HOBBYIST}
                                control={<Radio size="small" />}
                                className={classnames(classes.radioBoxItem, {
                                  [classes.radioBoxActiveItem]: field.value === PlannedUsageType.HOBBYIST,
                                })}
                                classes={{
                                  label: classes.radioLabel,
                                }}
                                label={
                                  <Box display="flex" alignItems="center" justifyContent="space-between">
                                    <Typography className={classes.grey700Text} fontWeight={500}>
                                      I&apos;m working on personal projects
                                    </Typography>
                                  </Box>
                                }
                              />
                            </Box>
                          </RadioGroup>
                        )}
                      />
                    </Box>
                  )}
                  {plannedUsageType && (
                    <Box mt={4} display="flex" flexDirection="column" rowGap={1} ref={finalDetailsRef}>
                      <ControlledTextField
                        size="small"
                        control={control}
                        showErrorsOnlyIfTouched={false}
                        name="firstname"
                        fullWidth
                        placeholder="First Name"
                        type="text"
                        variant="outlined"
                        rules={{
                          ...VALIDATION_RULES.required,
                        }}
                      />
                      <ControlledTextField
                        size="small"
                        control={control}
                        showErrorsOnlyIfTouched={false}
                        name="lastname"
                        fullWidth
                        placeholder="Last Name"
                        type="text"
                        variant="outlined"
                        rules={{
                          ...VALIDATION_RULES.required,
                        }}
                      />
                      {plannedUsageType === PlannedUsageType.PROFESSIONAL && (
                        <>
                          <ControlledTextField
                            size="small"
                            control={control}
                            showErrorsOnlyIfTouched={false}
                            name="company"
                            fullWidth
                            placeholder="Company (Optional)"
                            type="text"
                            variant="outlined"
                          />
                          <ControlledTextField
                            size="small"
                            control={control}
                            showErrorsOnlyIfTouched={false}
                            name="job_title"
                            fullWidth
                            placeholder="Job Title (Optional)"
                            type="text"
                            variant="outlined"
                          />
                        </>
                      )}
                      <ControlledTextField
                        size="small"
                        control={control}
                        showErrorsOnlyIfTouched={false}
                        name="github_username"
                        fullWidth
                        placeholder="GitHub Username (Optional)"
                        type="text"
                        variant="outlined"
                      />
                      {plannedUsageType && (
                        <Box textAlign="left">
                          <ControlledCheckbox
                            control={control}
                            rules={VALIDATION_RULES.required}
                            name="tos"
                            size="small"
                            formControlLabelProps={{
                              classes: {
                                root: classes.formControlLabelRoot,
                                asterisk: classes.hiddenAsterik,
                              },
                            }}
                            label={
                              <Typography fontSize={13} className={classes.grey600Text} fontWeight={500}>
                                I agree to the{' '}
                                <Link
                                  underline="hover"
                                  target="_blank"
                                  className={classes.grey800Text}
                                  href={ExternalLink.LEGAL_TOS}
                                >
                                  Terms
                                </Link>
                                ,{' '}
                                <Link
                                  underline="hover"
                                  target="_blank"
                                  className={classes.grey800Text}
                                  href={ExternalLink.LEGAL_PRIVACY_POLICY}
                                >
                                  Privacy Policy
                                </Link>
                                , and{' '}
                                <Link
                                  underline="hover"
                                  target="_blank"
                                  className={classes.grey800Text}
                                  href={ExternalLink.LEGAL_DPA}
                                >
                                  DPA
                                </Link>
                                .
                              </Typography>
                            }
                          />
                          <ControlledCheckbox
                            control={control}
                            name="newsletter"
                            size="small"
                            formControlLabelProps={{
                              classes: {
                                root: classes.formControlLabelRoot,
                                asterisk: classes.hiddenAsterik,
                              },
                            }}
                            label={
                              <Typography fontSize={13} className={classes.grey600Text} fontWeight={500}>
                                Receive occasional product updates.
                              </Typography>
                            }
                          />
                        </Box>
                      )}

                      <ProgressButton
                        variant="contained"
                        loading={isSigningUp}
                        fullWidth
                        size="xlarge"
                        type="submit"
                        color="primary"
                      >
                        Continue
                      </ProgressButton>
                      {plannedUsageType === PlannedUsageType.PROFESSIONAL && (
                        <Typography fontSize={13} className={classes.grey600Text} fontWeight={500}>
                          Continuing will start a{' '}
                          {is30DayTrialPromo ? THIRTY_DAY_TRIAL_DURATION : FOURTEEN_DAY_TRIAL_DURATION}-day free trial.
                          {/* Uncomment once we have a link to a dedicated section in our docs or pricing that describes the trial */}
                          {/* <Link underline='always' fontWeight={600} color='primary'>Learn more</Link> */}
                        </Typography>
                      )}
                    </Box>
                  )}
                </>
              )}
            </form>
            {step === STEPS_ENUM.LOGIN_DETAILS_STEP && (
              <>
                <Box mt={4} px={2.5}>
                  <Typography fontSize={13} className={classes.grey600Text} fontWeight={500}>
                    By signing up, you agree to our{' '}
                    <Link
                      underline="hover"
                      target="_blank"
                      className={classes.grey800Text}
                      href={ExternalLink.LEGAL_TOS}
                    >
                      Terms
                    </Link>
                    ,{' '}
                    <Link
                      underline="hover"
                      target="_blank"
                      className={classes.grey800Text}
                      href={ExternalLink.LEGAL_PRIVACY_POLICY}
                    >
                      Privacy Policy
                    </Link>
                    , and{' '}
                    <Link
                      underline="hover"
                      target="_blank"
                      className={classes.grey800Text}
                      href={ExternalLink.LEGAL_DPA}
                    >
                      DPA
                    </Link>
                    .
                  </Typography>
                </Box>
                <Box mt={4}>
                  <Typography className={classes.grey600Text}>—</Typography>
                </Box>
              </>
            )}
            {step === STEPS_ENUM.LS_CHECK_EMAIL_STEP && (
              <Box mt={4}>
                <Typography
                  fontSize={13}
                  className={classnames(classes.grey600Text, classes.ellipsisBox)}
                  fontWeight={500}
                >
                  We’ve sent an account activation link.
                  <br />
                  Please check your inbox at <span className={classes.grey800Text}>{email}</span>.
                </Typography>
                <Typography fontSize={13} fontWeight={500} mt={4}>
                  <Link
                    underline="hover"
                    display="inline-flex"
                    alignItems="center"
                    columnGap={0.5}
                    onClick={() => goto(AppRoute.SIGN_IN)}
                    className={classes.grey800Text}
                  >
                    Back to login
                  </Link>
                </Typography>
              </Box>
            )}

            {step !== STEPS_ENUM.LS_CHECK_EMAIL_STEP && (
              <Box mt={4}>
                <Typography fontSize={13} className={classes.grey600Text} fontWeight={500}>
                  Already have an account?{' '}
                  <Link
                    underline="hover"
                    className={classes.grey800Text}
                    display="inline-flex"
                    alignItems="center"
                    columnGap={0.5}
                    onClick={() => goto(AppRoute.SIGN_IN)}
                  >
                    Log in
                  </Link>
                </Typography>
              </Box>
            )}
          </>
        )}
      </Box>
    </AuthLayout>
  );
};
