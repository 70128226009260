import type { Optional } from './global';

import { CombinedUserInfo, ProductFeature } from './generated';

export type AuthGuardContextType = {
  userInfo: Optional<CombinedUserInfo>;
  reloadUserInfo: () => Promise<CombinedUserInfo | void>;
};

export type UseAuthProviderReturnType = AuthGuardContextType & {
  can: (permission: string) => boolean;
  hasFeature: (feature: ProductFeature, variant?: string) => boolean;
};

export type UseAuthProvider = () => UseAuthProviderReturnType;
