import { SERVICE_NAME } from '@localstack/types';

import { PATH_PREFIX_RESOURCES } from './base';

const EVENTBRIDGE_BASE_ROUTE = `${PATH_PREFIX_RESOURCES}/${SERVICE_NAME.EVENTS}`;

export const DEFAULT_EVENTBRIDGE_ROUTES: Record<string, string> = {
  RESOURCES_EVENT_BRIDGE: `${EVENTBRIDGE_BASE_ROUTE}`,
  RESOURCES_EVENT_BRIDGE_BUSES: `${EVENTBRIDGE_BASE_ROUTE}/buses`,
  RESOURCES_EVENT_BRIDGE_BUS: `${EVENTBRIDGE_BASE_ROUTE}/buses/:busName`,
  RESOURCES_EVENT_BRIDGE_BUS_CREATE: `${EVENTBRIDGE_BASE_ROUTE}/buses/new`,
  RESOURCES_EVENT_BRIDGE_BUS_TRIGGER: `${EVENTBRIDGE_BASE_ROUTE}/buses/:busName/trigger`,
  RESOURCES_EVENT_BRIDGE_RULES: `${EVENTBRIDGE_BASE_ROUTE}/buses/:busName/rules`,
  RESOURCES_EVENT_BRIDGE_RULE_CREATE: `${EVENTBRIDGE_BASE_ROUTE}/buses/:busName/rules/new`,
  RESOURCES_EVENT_BRIDGE_RULE_UPDATE: `${EVENTBRIDGE_BASE_ROUTE}/buses/:busName/rules/:ruleName/update`,
  RESOURCES_EVENT_BRIDGE_RULE_TARGETS: `${EVENTBRIDGE_BASE_ROUTE}/buses/:busName/rules/:ruleName/targets`,
  RESOURCES_EVENT_BRIDGE_RULE_TARGET: `${EVENTBRIDGE_BASE_ROUTE}/buses/:busName/rules/:ruleName/targets/:targetId`,
  RESOURCES_EVENT_BRIDGE_RULE_TARGET_CREATE: `${EVENTBRIDGE_BASE_ROUTE}/buses/:busName/rules/:ruleName/targets/new`,
  RESOURCES_EVENT_BRIDGE_RULE_TARGET_UPDATE: `${EVENTBRIDGE_BASE_ROUTE}/buses/:busName/rules/:ruleName/targets/:targetId/update`,
  RESOURCES_EVENT_BRIDGE_PIPES: `${EVENTBRIDGE_BASE_ROUTE}/pipes`,
  RESOURCES_EVENT_BRIDGE_PIPE: `${EVENTBRIDGE_BASE_ROUTE}/pipe/:pipeName`,
  RESOURCES_EVENT_BRIDGE_PIPES_CREATE: `${EVENTBRIDGE_BASE_ROUTE}/pipes/new`,
};
