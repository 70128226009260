import { ReactElement, useMemo } from 'react';
import { StateMachineDefinition, StateMachineStateType } from '@localstack/types';

import { FlowGraph } from '../../../../display';

import { StartEndStateNode } from './states/StartEndStateNode';
import { BaseStateNode } from './states/BaseStateNode';
import { GroupLabelNode } from './states/GroupLabelNode';
import { NodeRendererProps } from './types';
import { buildNodesTree } from './utils/sfnUtils';
import { STATE_VISITORS } from './visitors';

type NodeRenderer = (props: NodeRendererProps) => ReactElement;
const NODE_RENDERERS: Record<StateMachineStateType, NodeRenderer> = {
  Task: BaseStateNode,
  Parallel: GroupLabelNode,
  Map: GroupLabelNode,
  Choice: BaseStateNode,
  Wait: BaseStateNode,
  Succeed: BaseStateNode,
  Fail: BaseStateNode,
  Pass: BaseStateNode,
  Start: StartEndStateNode,
  End: StartEndStateNode,
};

export interface StateMachineFlowchartProps {
  definition: StateMachineDefinition;
}

export const StateMachineFlowchart = ({ definition }: StateMachineFlowchartProps): ReactElement => {
  const tree = useMemo(() => buildNodesTree(definition, STATE_VISITORS), [definition]);

  return <FlowGraph tree={tree} nodeTypes={NODE_RENDERERS} />;
};
