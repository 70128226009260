import { ReactElement } from 'react';
import { List, ListSubheader, ListItem, ListItemText } from '@mui/material';
import { formatDate } from '@localstack/services';
import { BackupPlan } from '@localstack/types';

export type BackupPlanDetailsProps = {
  plan: Optional<BackupPlan>;
};

export const BackupPlanDetails = ({ plan }: BackupPlanDetailsProps): ReactElement => (
  <List dense subheader={<ListSubheader>Backup Plan Details</ListSubheader>}>
    <ListItem>
      <ListItemText primary={plan?.BackupPlan?.BackupPlanName} secondary="Plan Name" />
    </ListItem>
    <ListItem>
      <ListItemText primary={plan?.BackupPlanId} secondary="Backup plan ID" />
    </ListItem>
    <ListItem>
      <ListItemText primary={plan?.VersionId} secondary="Version ID" />
    </ListItem>
    <ListItem>
      <ListItemText primary={plan?.CreationDate && formatDate(plan?.CreationDate)} secondary="Created" />
    </ListItem>
    <ListItem>
      <ListItemText primary={plan?.LastExecutionDate && formatDate(plan?.LastExecutionDate)} secondary="Last runtime" />
    </ListItem>
  </List>
);
