import { LocalStackInstance } from '@localstack/types';
import { Theme } from '@mui/material';
import { createStyles, makeStyles } from '@mui/styles';
import classNames from 'classnames';
import { ReactElement } from 'react';
import { useMatch } from 'react-router-dom';

import { InstanceMenuEntries } from './InstanceMenuEntries';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    tabs: {
      marginLeft: '0.8em',

      '& ul': {
        display: 'flex',
        columnGap: '0.5em',
      },

      '& li': {
        display: 'inline-block',
        backgroundColor: theme.palette.background.default,
        padding: '0.8em',
        paddingBottom: '0.6em',
        color: theme.palette.text.primary,
        borderRadius: '0.4em 0.4em 0px 0px',
        borderBottom: '1px solid',
        borderColor: `${theme.palette.divider} !important`,
      },

      '& .active': {
        border: 'none',
      },
    },
  }),
);

export type TopMenuSwitcherProps = {
  instance?: LocalStackInstance;
  disabledRoutes?: string[];
};
export const TopMenuSwitcher = ({ instance, disabledRoutes }: TopMenuSwitcherProps): ReactElement => {
  const classes = useStyles();

  return (
    <div className={classes.tabs}>
      <ul style={{ margin: 0 }}>
        <InstanceMenuEntries
          instance={instance}
          disabledRoutes={disabledRoutes}
          wrapper={(children, entry) => (
            <li
              className={classNames({
                active: !!useMatch(entry.route) || (entry.secondaryRoute && !!useMatch(entry.secondaryRoute)),
              })}
              key={entry.name}
            >
              {children}
            </li>
          )}
        />
      </ul>
    </div>
  );
};
