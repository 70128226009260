import { ReactElement } from 'react';
import { CreateTimestreamDatabaseRequest } from '@localstack/types';
import { AwsClientOverrides, getSchema } from '@localstack/services';
import { DEFAULT_KMS_ROUTES, TIMESTREAM_FORBIDDEN_VALUES } from '@localstack/constants';

import { MagicForm } from '../../../magic/MagicForm';
import { RelatedResourcePicker } from '../../../../form/RelatedResourcePicker';

export type TimestreamDatabaseFormProps = {
  loading?: boolean;
  formId: string;
  clientOverrides?: AwsClientOverrides;
  onSubmit: (data: CreateTimestreamDatabaseRequest) => unknown;
};

export const TimestreamDatabaseForm = ({
  loading,
  formId,
  clientOverrides,
  onSubmit,
}: TimestreamDatabaseFormProps): ReactElement => (
  <MagicForm
    schema={getSchema('TimestreamWrite')}
    formId={formId}
    entry="CreateDatabaseRequest"
    loading={loading}
    forbiddenValues={TIMESTREAM_FORBIDDEN_VALUES?.CreateDatabaseRequest}
    externalFields={{
      '^KmsKeyId$': (control, fieldName, required) => (
        <RelatedResourcePicker
          control={control}
          client="KMS"
          method="listKeys"
          arrayKeyName="Keys"
          property="KeyId"
          fieldName={fieldName}
          entityName="KMS Key"
          creationRoute={DEFAULT_KMS_ROUTES.RESOURCES_KMS_KEY_CREATE}
          required={required}
          clientOverrides={clientOverrides}
        />
      ),
    }}
    onSubmit={(data: CreateTimestreamDatabaseRequest) => onSubmit(data)}
  />
);
