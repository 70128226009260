import { UserService, useApiGetter } from '@localstack/services';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import { ReactElement, useMemo } from 'react';

import { Layout } from '@localstack/types';

import { AnnouncementModal } from '~/components';

import { BaseLayout } from './BaseLayout';

const useStyles = makeStyles(() =>
  createStyles({
    content: {
      flex: 1,
      maxWidth: '100%',
      display: 'flex',
      flexDirection: 'column',
    },
  }),
);

export type AuthenticatedLayoutProps = Layout;

export const AuthenticatedLayout = ({ children, ...rest }: AuthenticatedLayoutProps): ReactElement => {
  const classes = useStyles();
  const { data: notifications, mutate } = useApiGetter(UserService, 'listNotifications', []);

  const unreadNotifications = useMemo(() => notifications?.filter((n) => !n.read), [notifications]);

  const announcements = useMemo(
    () => unreadNotifications?.filter((item) => item.reference_id) ?? [],
    [unreadNotifications],
  );

  return (
    <BaseLayout {...rest}>
      <AnnouncementModal announcements={announcements} revalidate={mutate} />
      <main className={classes.content}>{children}</main>
    </BaseLayout>
  );
};
