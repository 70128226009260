import { ReactElement, useState, useCallback } from 'react';
import { useParams } from 'react-router-dom';
import { useRoutes, useAwsEffect, useAwsGetter, useAwsBatchIterator } from '@localstack/services';
import { Card, Button } from '@mui/material';

import { Dropdown, ConfirmableButton, ECSServicesTable, PageTitle } from '@localstack/ui';

import { DEFAULT_ECS_ROUTES } from '@localstack/constants';

import { ECSProps } from './types';

import { ClusterNavTabs } from './components';

const POLLING_INTERVAL = 5000;

export const ECSClusterServices = ({
  Layout,
  clientOverrides,
  routes = DEFAULT_ECS_ROUTES,
}: ECSProps): ReactElement => {
  const [selectedIds, setSelectedIds] = useState<string[]>([]);

  const { clusterName } = useParams<'clusterName'>();

  const { goto } = useRoutes();
  const { describeServices } = useAwsEffect('ECS', ['describeServices'], { clientOverrides });

  const {
    data: serviceArns,
    isLoading,
    mutate,
  } = useAwsGetter('ECS', 'listServices', [{ cluster: clusterName }], {
    swrOverrides: { refreshInterval: POLLING_INTERVAL },
    clientOverrides,
  });

  const services = useAwsBatchIterator(
    serviceArns?.serviceArns,
    async (arn) => (await describeServices({ cluster: clusterName, services: [arn] }))?.services ?? [],
  );

  const { deleteService } = useAwsEffect('ECS', ['deleteService'], {
    revalidate: ['listServices', 'describeServices'],
    clientOverrides,
  });

  const handleDeleteSelected = useCallback(async () => {
    const promises = selectedIds.map((service) => deleteService({ cluster: clusterName, service }));
    await Promise.all(promises);
  }, [selectedIds]);

  return (
    <Layout
      documentTitle="Cluster Services"
      tabs={<ClusterNavTabs clusterName={clusterName as string} routes={routes} />}
      title={
        <PageTitle
          title="Cluster Services"
          onMutate={mutate}
          breadcrumbs={[
            ['ECS', () => goto(routes.RESOURCES_ECS)],
            ['Clusters', () => goto(routes.RESOURCES_ECS_CLUSTERS)],
            [clusterName, () => goto(routes.RESOURCES_ECS_CLUSTER, { clusterName })],
            ['Services', null],
          ]}
        />
      }
      actions={
        <>
          <Button onClick={() => goto(routes.RESOURCES_ECS_CLUSTER_SERVICE_CREATE, { clusterName })}>
            Create Service
          </Button>
          <Dropdown label="Actions">
            <ConfirmableButton
              componentType="MenuItem"
              disabled={selectedIds.length === 0 || isLoading}
              title={`Delete ${selectedIds.length} Service(s)?`}
              onClick={handleDeleteSelected}
              text="Selected Services will be permanently deleted"
            >
              Remove Selected
            </ConfirmableButton>
          </Dropdown>
        </>
      }
    >
      <Card>
        <ECSServicesTable
          services={services}
          loading={isLoading}
          onSelect={setSelectedIds}
          onViewService={({ serviceArn }) =>
            goto(routes.RESOURCES_ECS_CLUSTER_SERVICE, { clusterName, arn: serviceArn })
          }
        />
      </Card>
    </Layout>
  );
};
