import { ReactElement } from 'react';
import { useParams } from 'react-router-dom';
import { Card, CardContent, CardActions, Box, Typography } from '@mui/material';
import { CreateAgwApiAuthorizerRequest, UpdateAgwApiAuthorizerRequest } from '@localstack/types';
import { useRoutes, useAwsEffect, useAwsGetter } from '@localstack/services';
import { ProgressButton, AgwApiAuthorizerForm, Breadcrumbs } from '@localstack/ui';
import { DEFAULT_API_GATEWAY_ROUTES } from '@localstack/constants';

import { ApiGatewayProps } from './types';
import { ApiNavTabs } from './components';

export const AgwApiAuthorizerUpsert = ({
  Layout,
  clientOverrides,
  routes = DEFAULT_API_GATEWAY_ROUTES,
}: ApiGatewayProps): ReactElement => {
  const { goto } = useRoutes();
  const { apiId: ApiId, authorizerId: AuthorizerId } = useParams<'apiId' | 'authorizerId'>();

  const { createAuthorizer, updateAuthorizer, isLoading } = useAwsEffect(
    'ApiGatewayV2',
    ['createAuthorizer', 'updateAuthorizer'],
    {
      revalidate: ['getAuthorizer', 'getAuthorizers'],
      clientOverrides,
    },
  );

  const { data: api } = useAwsGetter('ApiGatewayV2', 'getApi', [{ ApiId }], { clientOverrides });

  const { data: authorizer } = useAwsGetter('ApiGatewayV2', 'getAuthorizer', [{ ApiId, AuthorizerId }], {
    clientOverrides,
  });

  const handleCreate = async (data: CreateAgwApiAuthorizerRequest) => {
    await createAuthorizer(data);
    goto(routes.RESOURCES_AGW2_API_AUTHORIZERS, { apiId: ApiId });
  };

  const handleUpdate = async (data: UpdateAgwApiAuthorizerRequest) => {
    await updateAuthorizer(data);
    goto(routes.RESOURCES_AGW2_API_AUTHORIZER, { apiId: ApiId, authorizerId: AuthorizerId });
  };

  return (
    <Layout
      title={
        <Box>
          <Typography variant="h4">Authorizer Details</Typography>
          <Breadcrumbs
            mappings={[
              ['API Gateway', () => goto(routes.RESOURCES_AGW2)],
              [api?.Name, () => goto(routes.RESOURCES_AGW2_API, { apiId: ApiId })],
              [
                authorizer?.Name,
                () => goto(routes.RESOURCES_AGW2_API_AUTHORIZER, { apiId: ApiId, authorizerId: AuthorizerId }),
              ],
              [AuthorizerId ? 'Update' : 'Create Authorizer', null],
            ]}
          />
        </Box>
      }
      tabs={<ApiNavTabs apiId={ApiId as string} routes={routes} />}
      actions={
        <ProgressButton type="submit" form="update" variant="outlined" color="primary" loading={isLoading}>
          Submit
        </ProgressButton>
      }
    >
      <Card>
        <CardContent>
          <AgwApiAuthorizerForm
            apiId={ApiId as string}
            authorizer={authorizer}
            onCreate={handleCreate}
            onUpdate={handleUpdate}
            loading={isLoading}
            formId="update"
          />
        </CardContent>
        <CardActions>
          <ProgressButton type="submit" form="update" variant="contained" color="primary" loading={isLoading}>
            Submit
          </ProgressButton>
        </CardActions>
      </Card>
    </Layout>
  );
};
