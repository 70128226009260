import { CodeVisualizer, ConfirmableButton } from '@localstack/ui';
import { Box, Card, CardContent, CardHeader, Grid, Link, Typography, Alert } from '@mui/material';
import { ReactElement } from 'react';

import { RotateLeft as RotateLeftIcon } from '@mui/icons-material';

import { LicenseService, MARKER_IDS, TestMarkerSpan, useApiEffect, useApiGetter } from '@localstack/services';

import { ContainedCustomerLayout } from '~/layouts';
import { AppRoute } from '~/config';

export const AuthToken = (): ReactElement => {
  const { data: authToken, isLoading: isAuthTokenLoading } = useApiGetter(LicenseService, 'getWorkspaceToken', []);
  const { rotateWorkspaceToken, isLoading: isResetAuthTokenIsLoading } = useApiEffect(
    LicenseService,
    ['rotateWorkspaceToken'],
    { revalidate: ['getWorkspaceToken'] },
  );

  return (
    <ContainedCustomerLayout title="Personal Auth Token">
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Card>
            <CardHeader title="Your Auth Token" />
            <CardContent>
              <Box mb={2}>
                <Typography variant="body1">
                  LocalStack is activated using an auth token. The auth token is used for authentication and to retrieve
                  your{' '}
                  <Link href={AppRoute.SETTINGS_MY_LICENSE} underline="hover">
                    {' '}
                    assigned license
                  </Link>
                  . To activate older versions of LocalStack (before v3.0) use a{' '}
                  <Link href={AppRoute.SETTINGS_KEYS} underline="hover">
                    legacy API Key
                  </Link>{' '}
                  instead.
                </Typography>
              </Box>

              <Box>
                <Typography variant="body1">
                  The auth token is set as environment variable in your dev environment. To set the auth token for the
                  current session use the following command:
                </Typography>
              </Box>

              <TestMarkerSpan name={MARKER_IDS.AUTH_TOKEN_VIEWER}>
                {isAuthTokenLoading || isResetAuthTokenIsLoading || !authToken ? (
                  <CodeVisualizer commands={['loading...']} showCopy={false} />
                ) : (
                  <CodeVisualizer commands={[`LOCALSTACK_AUTH_TOKEN="${authToken?.token}"`]} />
                )}
              </TestMarkerSpan>
              <Box mt={2}>
                <Alert severity="error">
                  Your personal auth token gives you full access to the associated workspace and LocalStack license.
                  Auth tokens should be treated as secrets and never be shared or checked into source control management
                  systems (SCMs) like for example git.
                </Alert>
              </Box>
            </CardContent>
          </Card>
        </Grid>

        <Grid item xs={12}>
          <Card>
            <CardHeader title="Manage your Auth Token" />
            <CardContent>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Typography variant="body1">
                    If you suspect your personal auth token to be compromised, or if it becomes known to a third party,
                    you should reset it. By resetting a token, all previous tokens will stop working immediately and can
                    no longer be used to access a license or workspace. Previous tokens cannot be restored.
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <ConfirmableButton
                    title="Are you sure you want to reset your personal auth token?"
                    text=" Resetting will generate a new personal auth token and render the
                      current token unusable."
                    okText="Reset Token"
                    cancelText="Cancel"
                    componentType="Button"
                    onClick={() => rotateWorkspaceToken()}
                    variant="outlined"
                    color="secondary"
                    startIcon={<RotateLeftIcon />}
                    disabled={isResetAuthTokenIsLoading}
                  >
                    Reset Auth Token
                  </ConfirmableButton>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </ContainedCustomerLayout>
  );
};
