import { DEFAULT_DMS_ROUTES } from '@localstack/constants';
import { ReactElement, useState } from 'react';
import { useAwsEffect, useAwsGetter, useRoutes } from '@localstack/services';
import { Button, Card } from '@mui/material';
import { DMSEndpointsTable, ConfirmableButton, PageTitle, Dropdown } from '@localstack/ui';

import { DMSProps } from './types';
import { MainTabs } from './components/MainTabs';
import { DMSConfigOptionAlert } from './components/DMSConfigOptionAlert';

export const DMSEndpoints = ({ Layout, clientOverrides, routes = DEFAULT_DMS_ROUTES }: DMSProps): ReactElement => {
  const { goto } = useRoutes();
  const [selectedIds, setSelectedIds] = useState<string[]>([]);
  const {
    data: endpoints,
    isLoading,
    mutate,
    error,
  } = useAwsGetter('DMS', 'describeEndpoints', [], { clientOverrides });
  const { deleteEndpoint } = useAwsEffect('DMS', ['deleteEndpoint'], {
    revalidate: ['describeEndpoints'],
    clientOverrides,
  });
  const handleRemove = async () => {
    await Promise.all(selectedIds.map(async (id) => deleteEndpoint({ EndpointArn: id })));
  };
  return (
    <Layout
      title={<PageTitle title="DMS Endpoints" onMutate={mutate} />}
      tabs={<MainTabs routes={routes} />}
      actions={
        <>
          <Button onClick={() => goto(routes.RESOURCES_DMS_ENDPOINT_CREATE)}>Create Endpoint</Button>
          <Dropdown label="Actions">
            <ConfirmableButton
              componentType="MenuItem"
              disabled={selectedIds.length === 0 || isLoading}
              title={`Delete ${selectedIds.length} Endpoint(s)?`}
              onClick={handleRemove}
              text="Selected Endpoints will be permanently deleted"
            >
              Remove Selected
            </ConfirmableButton>
          </Dropdown>
        </>
      }
    >
      <DMSConfigOptionAlert error={error} />
      <Card>
        <DMSEndpointsTable
          endpoints={endpoints?.Endpoints || []}
          onViewEndpoint={(id: string) => goto(routes.RESOURCES_DMS_ENDPOINT, { id })}
          loading={isLoading}
          onSelect={setSelectedIds}
        />
      </Card>
    </Layout>
  );
};
