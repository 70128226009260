import { ReactElement, useState, useCallback } from 'react';
import { useRoutes, useAwsEffect, useAwsGetter } from '@localstack/services';
import { DEFAULT_API_GATEWAY_ROUTES } from '@localstack/constants';
import { Card, Button } from '@mui/material';
import { useParams } from 'react-router-dom';

import { PageTitle, Dropdown, ConfirmableButton, AgwApiModelsTable } from '@localstack/ui';

import { ApiGatewayProps } from './types';
import { ApiNavTabs } from './components';

export const AgwApiModels = ({
  Layout,
  clientOverrides,
  routes = DEFAULT_API_GATEWAY_ROUTES,
}: ApiGatewayProps): ReactElement => {
  const { apiId: ApiId } = useParams<'apiId'>();
  const [selectedIds, setSelectedIds] = useState<string[]>([]);

  const { goto } = useRoutes();
  const { data: api } = useAwsGetter('ApiGatewayV2', 'getApi', [{ ApiId }], { clientOverrides });

  const {
    data: models,
    isLoading,
    mutate,
  } = useAwsGetter('ApiGatewayV2', 'getModels', [{ ApiId }], { clientOverrides });

  const { deleteModel } = useAwsEffect('ApiGatewayV2', ['deleteModel'], {
    revalidate: ['getModel', 'getModels'],
    clientOverrides,
  });

  const handleDeleteSelected = useCallback(async () => {
    const promises = selectedIds.map((ModelId) => deleteModel({ ApiId: ApiId as string, ModelId }));
    await Promise.all(promises);
  }, [selectedIds]);

  return (
    <Layout
      documentTitle="API Models"
      title={
        <PageTitle
          title="API Models"
          onMutate={mutate}
          breadcrumbs={[
            ['API Gateway', () => goto(routes.RESOURCES_AGW2)],
            [api?.Name, () => goto(routes.RESOURCES_AGW2_API, { apiId: ApiId })],
            ['Models', null],
          ]}
        />
      }
      tabs={<ApiNavTabs apiId={ApiId as string} routes={routes} />}
      actions={
        <>
          <Button onClick={() => goto(routes.RESOURCES_AGW2_API_MODEL_CREATE, { apiId: ApiId })}>Create Model</Button>
          <Dropdown label="Actions">
            <ConfirmableButton
              componentType="MenuItem"
              disabled={selectedIds.length === 0 || isLoading}
              title={`Delete ${selectedIds.length} Model(s)?`}
              onClick={handleDeleteSelected}
              text="Selected Models will be permanently deleted"
            >
              Remove Selected
            </ConfirmableButton>
          </Dropdown>
        </>
      }
    >
      <Card>
        <AgwApiModelsTable
          models={models?.Items || []}
          onSelect={setSelectedIds}
          onViewModel={({ ModelId }) => goto(routes.RESOURCES_AGW2_API_MODEL, { apiId: ApiId, modelId: ModelId })}
        />
      </Card>
    </Layout>
  );
};
