import { Dispatch, SetStateAction, createContext, useContext } from 'react';

type ResourceBrowserInterface = {
  setResetFn: Dispatch<SetStateAction<(() => void) | null>>;
};

export const ResourceBrowserContext = createContext<ResourceBrowserInterface>({
  setResetFn: () => undefined,
});

export const useResourceBrowserProvider = (): ResourceBrowserInterface => useContext(ResourceBrowserContext);
