import { ReactElement } from 'react';
import { useRoutes, useAwsGetter, useAwsEffect } from '@localstack/services';
import { CreateIAMPolicyVersionRequest } from '@localstack/types';
import { useParams } from 'react-router-dom';
import { Card, CardContent, Box, Typography, CardActions } from '@mui/material';
import { Breadcrumbs, IAMPolicyVersionForm, ProgressButton } from '@localstack/ui';

import { DEFAULT_IAM_ROUTES } from '@localstack/constants/src';

import { IAMProps } from './types';

export const IAMPolicyVersionUpdate = ({
  Layout,
  clientOverrides,
  routes = DEFAULT_IAM_ROUTES,
}: IAMProps): ReactElement => {
  const { goto } = useRoutes();
  const { policyArn } = useParams<'policyArn'>();
  const { versionId } = useParams<'versionId'>();

  const decodedPolicyArn = decodeURIComponent(policyArn || '');

  const { data: policy } = useAwsGetter(
    'IAM',
    'getPolicy',
    [
      {
        PolicyArn: decodedPolicyArn,
      },
    ],
    { clientOverrides },
  );

  const { data: policyVersion, isLoading: isPolicyVersionLoading } = useAwsGetter(
    'IAM',
    'getPolicyVersion',
    [
      {
        PolicyArn: decodedPolicyArn,
        VersionId: versionId,
      },
    ],
    { clientOverrides },
  );

  const { createPolicyVersion, isLoading } = useAwsEffect('IAM', ['createPolicyVersion'], {
    revalidate: ['getPolicy', 'listPolicies', 'getPolicyVersion'],
    clientOverrides,
  });

  const handleUpdate = async (data: CreateIAMPolicyVersionRequest) => {
    const response = await createPolicyVersion(data);
    if (response) {
      goto(routes.RESOURCES_IAM_POLICY, {
        policyArn: decodedPolicyArn,
      });
    }
  };

  return (
    <Layout
      documentTitle="IAM: Update Policy Version"
      title={
        <Box>
          <Typography variant="h4">Update Policy Version</Typography>
          <Breadcrumbs
            mappings={[
              ['IAM', () => goto(routes.RESOURCES_IAM)],
              ['Policies', () => goto(routes.RESOURCES_IAM_POLICIES)],
              [
                policy?.Policy?.PolicyName,
                () =>
                  goto(routes.RESOURCES_IAM_POLICY, {
                    policyArn: decodeURIComponent(policyArn || ''),
                  }),
              ],
              [versionId, null],
            ]}
          />
        </Box>
      }
      actions={
        <ProgressButton type="submit" form="update" variant="outlined" color="primary" loading={isLoading}>
          Submit
        </ProgressButton>
      }
    >
      <Card>
        <CardContent>
          <IAMPolicyVersionForm
            policyVersion={policyVersion?.PolicyVersion}
            policyArn={decodedPolicyArn}
            onCreate={handleUpdate}
            loading={isPolicyVersionLoading}
            formId="update"
          />
        </CardContent>
        <CardActions>
          <ProgressButton type="submit" form="update" variant="contained" color="primary" loading={isLoading}>
            Submit
          </ProgressButton>
        </CardActions>
      </Card>
    </Layout>
  );
};
