import { SERVICE_NAME } from '@localstack/types';

import { PATH_PREFIX_RESOURCES } from './base';

const CLOUDFRONT_BASE_ROUTE = `${PATH_PREFIX_RESOURCES}/${SERVICE_NAME.CLOUDFRONT}`;

export const DEFAULT_CLOUDFRONT_ROUTES: Record<string, string> = {
  RESOURCES_CLOUDFRONT_DISTRIBUTIONS: `${CLOUDFRONT_BASE_ROUTE}/distributions`,
  RESOURCES_CLOUDFRONT_DISTRIBUTION: `${CLOUDFRONT_BASE_ROUTE}/distribution/:id`,
  RESOURCES_CLOUDFRONT_DISTRIBUTIONS_CREATE: `${CLOUDFRONT_BASE_ROUTE}/distributions/new`,
  RESOURCES_CLOUDFRONT_DISTRIBUTION_UPDATE: `${CLOUDFRONT_BASE_ROUTE}/distributions/:id/update`,
};
