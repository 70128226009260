import { ReactElement } from 'react';
import {
  OpenSearchCreateDomainRequest,
  OpenSearchDomainStatus,
  OpenSearchUpdateDomainConfigRequest,
} from '@localstack/types';
import { AwsClientOverrides, getSchema } from '@localstack/services';

import { DEFAULT_KMS_ROUTES } from '@localstack/constants';

import { RelatedResourcePicker } from '../../../form/RelatedResourcePicker';

import { MagicForm } from '../../magic/MagicForm';

export type CreateOpenSearchDomainProps = {
  domain?: Optional<Partial<OpenSearchDomainStatus>>;
  loading?: boolean;
  formId: string;
  clientOverrides?: AwsClientOverrides;
  onCreate: (data: OpenSearchCreateDomainRequest) => unknown;
  onUpdate: (data: OpenSearchUpdateDomainConfigRequest) => unknown;
};

export const OpenSearchDomainForm = ({
  domain,
  loading,
  formId,
  clientOverrides,
  onCreate,
  onUpdate,
}: CreateOpenSearchDomainProps): ReactElement => (
  <>
    <MagicForm
      data={domain}
      schema={getSchema('OpenSearch')}
      formId={formId}
      entry={domain ? 'UpdateDomainConfigRequest' : 'CreateDomainRequest'}
      loading={loading}
      fieldConditions={{
        '^domainName': !domain,
      }}
      externalFields={{
        'EncryptionAtRestOptions.KmsKeyId$': (control, fieldName, required) => (
          <RelatedResourcePicker
            control={control}
            client="KMS"
            method="listKeys"
            arrayKeyName="Keys"
            property="KeyArn"
            fieldName={fieldName}
            entityName="KMS Key"
            creationRoute={DEFAULT_KMS_ROUTES.RESOURCES_KMS_KEY_CREATE}
            required={required}
            clientOverrides={clientOverrides}
          />
        ),
      }}
      onSubmit={(data: OpenSearchCreateDomainRequest | OpenSearchUpdateDomainConfigRequest) => {
        if (!domain) return onCreate(data as unknown as OpenSearchCreateDomainRequest);
        return onUpdate({ ...data, DomainName: domain?.DomainName || '' });
      }}
    />
  </>
);
