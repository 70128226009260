import { ReactElement } from 'react';
import { NavLink } from '@localstack/ui';
import { buildRoute } from '@localstack/services';

import { ApiGatewayRoutes } from '../../types';

interface Props {
  apiId: string;
  routes: ApiGatewayRoutes;
}

export const ApiNavTabs = ({ apiId, routes }: Props): ReactElement => (
  <>
    <NavLink to={buildRoute(routes.RESOURCES_AGW2_API, { apiId })} end>
      Details
    </NavLink>
    <NavLink to={buildRoute(routes.RESOURCES_AGW2_API_ROUTES, { apiId })}>Routes</NavLink>
    <NavLink to={buildRoute(routes.RESOURCES_AGW2_API_INTEGRATIONS, { apiId })}>Integrations</NavLink>
    <NavLink to={buildRoute(routes.RESOURCES_AGW2_API_STAGES, { apiId })}>Stages</NavLink>
    <NavLink to={buildRoute(routes.RESOURCES_AGW2_API_MODELS, { apiId })}>Models</NavLink>
    <NavLink to={buildRoute(routes.RESOURCES_AGW2_API_AUTHORIZERS, { apiId })}>Authorizers</NavLink>
  </>
);
