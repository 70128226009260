import { useMemo, ReactElement } from 'react';
import { renderToString } from 'react-dom/server';
import { Box, IconButton, Typography } from '@mui/material';
import { ZoomOut as ZoomOutIcon } from '@mui/icons-material';
import { useTheme } from '@mui/material/styles';
import { StackAwsApiCall } from '@localstack/types';

import {
  ResponsiveContainer,
  AreaChart,
  CartesianGrid,
  XAxis,
  YAxis,
  Tooltip,
  Area,
  Legend,
  ReferenceArea,
} from 'recharts';

import { ClientIcon } from '../../../display/ClientIcon';

import { formatUserAgent } from '../common/index';

import { useChartProps, useZoomableChart } from '../../../chart';

export interface ClientsChartProps {
  title: string;
  data: StackAwsApiCall[];
  showZoomOut?: boolean;
  onResetInterval?: () => unknown;
  onChangeInterval?: (start: string, end: string) => unknown;
}

export const ClientsChart = ({
  title,
  data,
  showZoomOut,
  onResetInterval,
  onChangeInterval,
}: ClientsChartProps): ReactElement => {
  const theme = useTheme();
  const chartProps = useChartProps();

  const zommableChart = useZoomableChart({ type: 'datestring', onChangeInterval });

  const clientTypes = Array.from(new Set(data.map((e) => formatUserAgent(e.user_agent))));

  // aggregate number of the same clients across same datapoints
  const aggregatedData = data
    .map((e) => ({ ...e, user_agent: formatUserAgent(e.user_agent) }))
    .reduce(
      (memo, item) => ({
        ...memo,
        [item.server_time]: {
          ...memo[item.server_time as keyof typeof memo],
          [item.user_agent]: (memo[item.server_time as keyof typeof memo]?.[item.user_agent] ?? 0) + 1,
        },
      }),
      {} as Record<string, Record<string, number>>,
    );

  const formattedData = Object.entries(aggregatedData).map(([time, clients]) => ({ time, ...clients }));

  // fill missing entries with 0s
  const completeData = formattedData.map((rec) => ({
    ...clientTypes.reduce((memo, agentType) => ({ ...memo, [agentType]: 0 }), {}),
    ...rec,
  }));

  const extractClientIconColor = (code: string) =>
    renderToString(<ClientIcon code={code} />).match(/.*localstack-color="([^"]+)"/)?.[1] ?? theme.palette.primary.main;

  const clientColors: Record<string, string> = useMemo(
    () => clientTypes.reduce((memo, client) => ({ ...memo, [client]: extractClientIconColor(client) }), {}),
    [data?.length],
  );

  return (
    <>
      <Typography align="center">{title}</Typography>
      {showZoomOut && (
        <Box position="relative">
          {/* FIXME: remove hardcoded zIndex and use theme or styles  */}
          <Box position="absolute" right={0} zIndex={123}>
            <IconButton onClick={onResetInterval} size="large">
              <ZoomOutIcon />
            </IconButton>
          </Box>
        </Box>
      )}
      <ResponsiveContainer width="100%" height={250}>
        <AreaChart data={completeData} syncId="stack-charts" {...zommableChart.props}>
          <CartesianGrid strokeDasharray="3 3" {...chartProps.cartesianGridProps} />
          <XAxis dataKey="time" {...chartProps.xAxisProps} />
          <YAxis {...chartProps.yAxisProps} />
          <Tooltip {...chartProps.tooltipProps} />
          {clientTypes.map((clientType) => (
            <Area
              key={clientType}
              type="monotone"
              dataKey={clientType}
              stackId="1"
              stroke={clientColors[clientType]}
              fill={clientColors[clientType]}
            />
          ))}
          <Legend
            {...chartProps.legendProps}
            content={() => (
              <Box display="flex" justifyContent="center">
                {clientTypes.map((clientType) => (
                  <Box key={clientType} p={1}>
                    <ClientIcon code={clientType} size="medium" />
                  </Box>
                ))}
              </Box>
            )}
          />
          {zommableChart.leftSelection && (
            <ReferenceArea x1={zommableChart.leftSelection} x2={zommableChart.rightSelection} />
          )}
        </AreaChart>
      </ResponsiveContainer>
    </>
  );
};
