import { getSchema } from '@localstack/services';
import { DescribeCertificateResponse } from '@localstack/types';
import { ReactElement } from 'react';

import { MagicDetails } from '../../magic/MagicDetails';

export type ACMCertificateDetailProps = {
  certificate?: Optional<Partial<DescribeCertificateResponse>>;
};

export const ACMCertificateDetail = ({ certificate }: ACMCertificateDetailProps): ReactElement => (
  <MagicDetails
    data={certificate?.Certificate}
    schema={getSchema('ACM')}
    entry="CertificateDetail"
    title="Certificate Details"
  />
);
