import { useState, useEffect, ReactElement } from 'react';
import { useParams } from 'react-router-dom';
import { CloudWatchLogEvent } from '@localstack/types';
import { Typography, Box, Card } from '@mui/material';
import { useRoutes, useAwsEffect } from '@localstack/services';
import { Breadcrumbs, LogsTable, LogsTableToolbar, LogsTableEvent } from '@localstack/ui';
import { DEFAULT_CLOUDWATCH_ROUTES } from '@localstack/constants';

import { LogGroupNavTabs } from './components';
import { CloudWatchProps } from './types';

// inefficient
const sortEvents = (events: LogsTableEvent[]): LogsTableEvent[] =>
  [...events].sort((le, re) => new Date(le.timestamp).getTime() - new Date(re.timestamp).getTime());

// inefficient
const filterEvents = (eventsIndex: { [key: string]: boolean }, events: CloudWatchLogEvent[]) =>
  events.filter(({ eventId }) => !eventsIndex[eventId as string]);

const transformEvents = (events: CloudWatchLogEvent[]): LogsTableEvent[] =>
  events.map((event) => ({
    id: event.eventId as string,
    groupName: event.logStreamName,
    timestamp: event.ingestionTime as number,
    message: event.message as string,
  }));

const DAY_MS = 24 * 60 * 60 * 1000; // 24 hours

export const CloudWatchLogGroupEvents = ({
  Layout,
  clientOverrides,
  routes = DEFAULT_CLOUDWATCH_ROUTES,
}: CloudWatchProps): ReactElement => {
  const { goto } = useRoutes();
  const { logGroupName, logStreamName } = useParams<'logGroupName' | 'logStreamName'>();

  const [liveMode, setLiveMode] = useState(true);
  const [pattern, setPattern] = useState<string | undefined>(undefined);
  const [events, setEvents] = useState<LogsTableEvent[]>([]);
  const [eventsIndex, setEventsIndex] = useState<{ [key: string]: boolean }>({});

  const { filterLogEvents } = useAwsEffect('CloudWatchLogs', ['filterLogEvents'], {
    revalidate: ['filterLogEvents'],
    clientOverrides,
  });

  const loadForward = async () => {
    // next portion of events starts from the latest available timestamp + 1 day
    const latestTimestamp = events.length
      ? new Date(events[events.length - 1]?.timestamp as Date | number)
      : new Date(new Date().getTime() - DAY_MS);

    const loadedEvents = await filterLogEvents({
      logGroupName: logGroupName as string,
      filterPattern: pattern,
      startTime: latestTimestamp.getTime(),
      endTime: new Date().getTime(),
      logStreamNames: logStreamName ? [logStreamName] : undefined,
    });

    const newEvents = filterEvents(eventsIndex, loadedEvents?.events ?? []);

    const transformedNewEvents = transformEvents(newEvents);

    const allEvents = sortEvents([...events, ...transformedNewEvents]);
    const newIndex = allEvents.reduce((memo, event) => ({ ...memo, [event.id]: true }), {});

    setEventsIndex(newIndex);
    setEvents(allEvents);
  };

  const loadBackward = async () => {
    if (!events.length) return;

    // previous portion of events ends with the earliest available timestamp
    const earliestTimestamp = new Date(events[0]?.timestamp as Date | number);
    const startDate = new Date(earliestTimestamp.getTime() - DAY_MS);

    const loadedEvents = await filterLogEvents({
      logGroupName: logGroupName as string,
      filterPattern: pattern,
      startTime: startDate.getTime(),
      endTime: earliestTimestamp.getTime(),
      logStreamNames: logStreamName ? [logStreamName] : undefined,
    });

    const newEvents = filterEvents(eventsIndex, loadedEvents?.events ?? []);

    const transformedNewEvents = transformEvents(newEvents);

    const allEvents = sortEvents([...events, ...transformedNewEvents]);
    const newIndex = allEvents.reduce((memo, log) => ({ ...memo, [log.id]: true }), {});

    setEventsIndex(newIndex);
    setEvents(allEvents);
  };

  useEffect(() => {
    if (!liveMode) return;

    const interval = setInterval(loadForward, 1000);
    return () => clearInterval(interval);
  }, [liveMode, loadForward]);

  useEffect(() => {
    setEvents([]);
    setEventsIndex({});
    setPattern(undefined);
  }, [logStreamName]);

  return (
    <Layout
      documentTitle="CloudWatch Log Events"
      tabs={!logStreamName && <LogGroupNavTabs logGroupName={logGroupName as string} routes={routes} />}
      title={
        <Box>
          <Typography variant="h4">CloudWatch Log Events</Typography>
          <Breadcrumbs
            mappings={[
              ['CloudWatch Logs', () => goto(routes.RESOURCES_CLOUDWATCH)],
              ['Log Groups', () => goto(routes.RESOURCES_CLOUDWATCH_GROUPS)],
              [logGroupName, () => goto(routes.RESOURCES_CLOUDWATCH_GROUPS)],
              [
                logStreamName ? 'Log Streams' : null,
                () => goto(routes.RESOURCES_CLOUDWATCH_GROUP_STREAMS, { logGroupName }),
              ],
              [logStreamName, () => goto(routes.RESOURCES_CLOUDWATCH_GROUP_STREAMS, { logGroupName })],
              ['Log Events', null],
            ]}
          />
        </Box>
      }
    >
      <Card style={{ flex: 1, height: '100%' }}>
        <Box height="100%" display="flex" flexDirection="column">
          <LogsTableToolbar
            liveUpdate={liveMode}
            onToggleLiveMode={() => setLiveMode(!liveMode)}
            onApply={(filterPattern) => {
              setEvents([]);
              setEventsIndex({});
              setPattern(filterPattern);
            }}
          />
          <Box flexGrow={1} mt={2}>
            <LogsTable
              events={events}
              onLoadForward={loadForward}
              onLoadBackward={loadBackward}
              onViewGroup={(streamName) =>
                goto(routes.RESOURCES_CLOUDWATCH_GROUP_STREAM_EVENTS, { logGroupName, logStreamName: streamName })
              }
            />
          </Box>
        </Box>
      </Card>
    </Layout>
  );
};
