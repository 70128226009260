import { SERVICE_NAME } from '@localstack/types';

import { PATH_PREFIX_RESOURCES } from './base';

const BACKUP_BASE_ROUTE = `${PATH_PREFIX_RESOURCES}/${SERVICE_NAME.BACKUP}`;

export const DEFAULT_BACKUP_ROUTES: Record<string, string> = {
  RESOURCES_BACKUP_PLANS: `${BACKUP_BASE_ROUTE}/plans`,
  RESOURCES_BACKUP_PLAN: `${BACKUP_BASE_ROUTE}/plans/:id`,
  RESOURCES_BACKUP_PLAN_CREATE: `${BACKUP_BASE_ROUTE}/plans/new`,
  RESOURCES_BACKUP_PLAN_UPDATE: `${BACKUP_BASE_ROUTE}/plans/:id/update`,
  RESOURCES_BACKUP_PLAN_ASSIGNMENT: `${BACKUP_BASE_ROUTE}/plans/:id/assign`,
  RESOURCES_BACKUP_VAULT_CREATE: `${BACKUP_BASE_ROUTE}/vaults/new`,
  RESOURCES_BACKUP_VAULTS: `${BACKUP_BASE_ROUTE}/vaults`,
  RESOURCES_BACKUP_VAULT: `${BACKUP_BASE_ROUTE}/vaults/:name`,
  RESOURCES_BACKUP_JOB_CREATE: `${BACKUP_BASE_ROUTE}/job/new`,
};
