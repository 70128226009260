import { ReactElement } from 'react';
import { useParams } from 'react-router-dom';
import { Card, CardContent, CardActions, Box, Typography } from '@mui/material';
import { PutEventbridgeTargetsRequest } from '@localstack/types';
import { useRoutes, useAwsEffect, useAwsGetter } from '@localstack/services';
import { ProgressButton, EventBridgeTargetForm, Breadcrumbs } from '@localstack/ui';
import { DEFAULT_EVENTBRIDGE_ROUTES } from '@localstack/constants/src';

import { EventBridgeProps } from './types';

export const EventBridgeTargetUpsert = ({
  Layout,
  clientOverrides,
  routes = DEFAULT_EVENTBRIDGE_ROUTES,
}: EventBridgeProps): ReactElement => {
  const { busName, ruleName, targetId } = useParams<'busName' | 'ruleName' | 'targetId'>();
  const { goto } = useRoutes();

  const { data: targets } = useAwsGetter(
    'EventBridge',
    'listTargetsByRule',
    [{ Rule: ruleName, EventBusName: busName }],
    { clientOverrides },
  );

  const updateTarget = targets?.Targets?.filter((target) => targetId === target.Id)[0];

  const { putTargets, isLoading } = useAwsEffect('EventBridge', ['putTargets'], {
    revalidate: ['listTargetsByRule'],
    clientOverrides,
  });

  const handleCreate = async (data: PutEventbridgeTargetsRequest) => {
    if (await putTargets(data)) {
      goto(routes.RESOURCES_EVENT_BRIDGE_RULE_TARGETS, { ruleName, busName });
    }
  };

  const isUpdate = !!updateTarget;
  const action = isUpdate ? 'Update' : 'Create';

  return (
    <Layout
      documentTitle={`EventBridge: ${action} Target`}
      title={
        <Box>
          <Typography variant="h4">{action} Target</Typography>
          <Breadcrumbs
            mappings={[
              ['EventBridge', () => goto(routes.RESOURCES_EVENT_BRIDGE)],
              ['Buses', () => goto(routes.RESOURCES_EVENT_BRIDGE_BUSES)],
              [busName, () => goto(routes.RESOURCES_EVENT_BRIDGE_BUS, { busName })],
              ['Rules', () => goto(routes.RESOURCES_EVENT_BRIDGE_RULES, { busName })],
              [ruleName, () => goto(routes.RESOURCES_EVENT_BRIDGE_RULE_TARGETS, { ruleName, busName })],
              ['Targets', () => goto(routes.RESOURCES_EVENT_BRIDGE_RULE_TARGETS, { ruleName, busName })],
              [targetId, () => goto(routes.RESOURCES_EVENT_BRIDGE_RULE_TARGET, { ruleName, busName, targetId })],
              [`${action} Target`, null],
            ]}
          />
        </Box>
      }
      actions={
        <ProgressButton type="submit" form="update" variant="outlined" color="primary" loading={isLoading}>
          Submit
        </ProgressButton>
      }
    >
      <Card>
        <CardContent>
          <EventBridgeTargetForm
            target={updateTarget}
            rule={ruleName as string}
            bus={busName || ''}
            clientOverrides={clientOverrides}
            onCreate={handleCreate}
            loading={isLoading}
            formId="update"
          />
        </CardContent>
        <CardActions>
          <ProgressButton type="submit" form="update" variant="contained" color="primary" loading={isLoading}>
            Submit
          </ProgressButton>
        </CardActions>
      </Card>
    </Layout>
  );
};
