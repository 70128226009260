import { ReactElement } from 'react';
import { useRoutes, useAwsEffect, useAwsGetter } from '@localstack/services';
import { useParams } from 'react-router-dom';
import { Card, CardContent, CardActions, Box, Typography } from '@mui/material';
import { CreateIAMUserRequest, UpdateIAMUserRequest } from '@localstack/types';
import { ProgressButton, IAMUserForm, Breadcrumbs } from '@localstack/ui';

import { DEFAULT_IAM_ROUTES } from '@localstack/constants/src';

import { NavTabs } from './components';
import { IAMProps } from './types';

export const IAMUserUpsert = ({ Layout, clientOverrides, routes = DEFAULT_IAM_ROUTES }: IAMProps): ReactElement => {
  const { goto } = useRoutes();
  const { userName } = useParams<'userName'>();

  const { createUser, updateUser, isLoading } = useAwsEffect('IAM', ['createUser', 'updateUser'], {
    revalidate: ['getUser', 'listUsers'],
  });

  const { data: user } = useAwsGetter('IAM', 'getUser', [{ UserName: userName }], { clientOverrides });
  const resolvedUser = userName ? user?.User : null;

  const handleCreate = async (data: CreateIAMUserRequest) => {
    await createUser(data);
    goto(routes.RESOURCES_IAM);
  };

  const handleUpdate = async (data: UpdateIAMUserRequest) => {
    await updateUser(data);
    goto(routes.RESOURCES_IAM_USER, { userName: data.NewUserName || userName });
  };

  return (
    <Layout
      documentTitle={`IAM: ${userName ? 'Update' : 'Create'} User`}
      title={
        <Box>
          <Typography variant="h4">{userName ? 'Update' : 'Create'} User</Typography>
          <Breadcrumbs
            mappings={[
              ['IAM', () => goto(routes.RESOURCES_IAM)],
              ['Users', () => goto(routes.RESOURCES_IAM)],
              [resolvedUser?.UserName, () => goto(routes.RESOURCES_IAM_USER, { userName })],
              [userName ? 'Update' : 'Create', null],
            ]}
          />
        </Box>
      }
      tabs={<NavTabs routes={routes} />}
      actions={
        <ProgressButton type="submit" form="update" variant="outlined" color="primary" loading={isLoading}>
          Submit
        </ProgressButton>
      }
    >
      <Card>
        <CardContent>
          <IAMUserForm
            user={resolvedUser}
            onCreate={handleCreate}
            onUpdate={handleUpdate}
            loading={isLoading}
            formId="update"
          />
        </CardContent>
        <CardActions>
          <ProgressButton type="submit" form="update" variant="contained" color="primary" loading={isLoading}>
            Submit
          </ProgressButton>
        </CardActions>
      </Card>
    </Layout>
  );
};
