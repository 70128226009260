import { ReactElement, ChangeEvent } from 'react';
import { ButtonGroup, Button, styled } from '@mui/material';

export interface CounterInputProps {
  /**the current value of the component */
  value: number;
  /** handler for when the increment button is clicked */
  onIncrement?: () => void;
  /** handler for when the decrement button is clicked */
  onDecrement?: () => void;
  /** handler called by the onChange of the input field  */
  onSetValue?: (value: number) => void;
  /** whether all buttons and input should appear disabled */
  disabled?: boolean;
  /** minimum value input can take, below it increments are disabled, defaults to 1 */
  min?: number;
  /** defines the maximum value the input should take, above it increments are disabled */
  max?: number;
  /** the id of the label this component refers to */
  ariaLabelledBy?: string;
}

const StyledInput = styled('input')(({ theme }) => ({
  border: `1px solid ${theme.palette.primary.main}66`,
  width: '3em',
  textAlign: 'center',
  backgroundColor: 'transparent',
  color: theme.palette.text.primary,
}));

/**
 * Simple component containing an input field for numbers and button steppers to increase/decrease its value by 1
 */
export const CounterInput = ({
  value,
  onIncrement,
  onDecrement,
  onSetValue,
  disabled = false,
  min = 1,
  max,
  ariaLabelledBy,
}: CounterInputProps): ReactElement => {
  const handleInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    if (!onSetValue) return;

    if (event.target.value === '') {
      onSetValue(min ?? 1);
      return;
    }

    const numValue = parseInt(event.target.value, 10);
    if (!isNaN(numValue)) {
      if (max !== undefined && numValue > max) {
        onSetValue(max);
      } else if (min !== undefined && numValue < min) {
        onSetValue(min);
      } else {
        onSetValue(numValue);
      }
    }
  };

  return (
    <ButtonGroup>
      <Button onClick={onDecrement} disabled={disabled || (min !== undefined && value <= min)} aria-label={'Decrease'}>
        -
      </Button>
      <StyledInput
        inputMode="numeric"
        value={value}
        disabled={disabled}
        onChange={handleInputChange}
        min={min}
        max={max}
        aria-labelledby={ariaLabelledBy}
      />
      <Button onClick={onIncrement} disabled={disabled || (max !== undefined && value >= max)} aria-label={'Increase'}>
        +
      </Button>
    </ButtonGroup>
  );
};
