import { ReactElement } from 'react';
import { AgwRestApiStage } from '@localstack/types';
import { getSchema } from '@localstack/services';

import { MagicDetails } from '../../../magic/MagicDetails';

export interface AgwRestApiStageDetailsProps {
  stage?: Optional<Partial<AgwRestApiStage>>;
}

export const AgwRestApiStageDetails = ({ stage }: AgwRestApiStageDetailsProps): ReactElement => (
  <MagicDetails data={stage} schema={getSchema('APIGateway')} entry="Stage" title="Stage Details" />
);
