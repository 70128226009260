import { useState, useCallback, ReactElement } from 'react';
import { useRoutes, useAwsEffect, useAwsGetter } from '@localstack/services';
import { useParams } from 'react-router-dom';
import { Card, Button } from '@mui/material';
import { Dropdown, ConfirmableButton, AppSyncFunctionsTable, PageTitle } from '@localstack/ui';

import { DEFAULT_APPSYNC_ROUTES } from '@localstack/constants';

import { NavTabs } from './components';
import { AppSyncProps } from './types';

export const AppSyncApiFunctions = ({
  Layout,
  clientOverrides,
  routes = DEFAULT_APPSYNC_ROUTES,
}: AppSyncProps): ReactElement => {
  const [selectedIds, setSelectedIds] = useState<string[]>([]);

  const { goto } = useRoutes();
  const { apiId } = useParams<'apiId'>();

  const {
    data: functions,
    isLoading,
    mutate,
  } = useAwsGetter('AppSync', 'listFunctions', [{ apiId }], { clientOverrides });

  const { deleteFunction } = useAwsEffect('AppSync', ['deleteFunction'], {
    revalidate: ['listFunctions', 'getFunction'],
    clientOverrides,
  });

  const handleDeleteSelected = useCallback(async () => {
    const promises = selectedIds.map((functionId) => deleteFunction({ apiId: apiId as string, functionId }));
    await Promise.all(promises);
  }, [selectedIds]);

  return (
    <Layout
      documentTitle="API Functions"
      tabs={<NavTabs apiId={apiId as string} routes={routes} />}
      title={
        <PageTitle
          title="API Functions"
          onMutate={mutate}
          breadcrumbs={[
            ['AppSync', () => goto(routes.RESOURCES_APPSYNC)],
            [apiId, () => goto(routes.RESOURCES_APPSYNC_API, { apiId })],
            ['Functions', null],
          ]}
        />
      }
      actions={
        <>
          <Button onClick={() => goto(routes.RESOURCES_APPSYNC_API_FUNCTION_CREATE, { apiId })}>Create Function</Button>
          <Dropdown label="Actions">
            <ConfirmableButton
              componentType="MenuItem"
              disabled={selectedIds.length === 0 || isLoading}
              title={`Delete ${selectedIds.length} Data Source(s)?`}
              onClick={handleDeleteSelected}
              text="Selected Functions will be permanently deleted"
            >
              Remove Selected
            </ConfirmableButton>
          </Dropdown>
        </>
      }
    >
      <Card>
        <AppSyncFunctionsTable
          selectable
          loading={isLoading}
          functions={functions?.functions ?? []}
          onSelect={setSelectedIds}
          onViewFunction={({ functionId }) => goto(routes.RESOURCES_APPSYNC_API_FUNCTION, { apiId, functionId })}
        />
      </Card>
    </Layout>
  );
};
