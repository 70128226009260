import { ReactElement, ReactNode } from 'react';

import { Button, Dialog, DialogTitle, DialogContent, DialogActions, DialogProps, IconButton } from '@mui/material';

import { Close as CloseIcon } from '@mui/icons-material';

type BaseProps = {
  title: string;
  titleActions?: ReactNode;
  open: boolean;
  closeText?: string;
  confirmText?: string;
  confirmDisabled?: boolean;
  onClose: () => void;
  onConfirm?: () => void;
  children: ReactNode;
};

type ContentModalProps = DialogProps & BaseProps;

export const ContentModal = ({
  title,
  titleActions,
  closeText,
  confirmText,
  children,
  open,
  confirmDisabled,
  onClose,
  onConfirm,
  ...rest
}: ContentModalProps): ReactElement => (
  <>
    <Dialog open={open} onClose={onClose} {...rest}>
      <DialogTitle
        variant="h4"
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: titleActions ? undefined : 'space-between',
          gap: '10px',
        }}
      >
        {title}
        {titleActions || (
          <IconButton onClick={onClose} size="large">
            <CloseIcon />
          </IconButton>
        )}
      </DialogTitle>
      <DialogContent>{children}</DialogContent>
      <DialogActions>
        <Button onClick={onClose}>{closeText || 'Close'}</Button>
        {onConfirm && (
          <Button color="primary" variant="contained" onClick={onConfirm} disabled={confirmDisabled}>
            {confirmText || 'Confirm'}
          </Button>
        )}
      </DialogActions>
    </Dialog>
  </>
);
