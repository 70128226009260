import { ReactElement } from 'react';
import { NavLink } from '@localstack/ui';
import { buildRoute } from '@localstack/services';

import { NeptuneRoutes } from '../../types';

interface Props {
  routes: NeptuneRoutes;
}

export const NavTabs = ({ routes }: Props): ReactElement => (
  <>
    <NavLink to={buildRoute(routes.RESOURCES_NEPTUNE_INSTANCES)} end>
      Instances
    </NavLink>
    <NavLink to={buildRoute(routes.RESOURCES_NEPTUNE_CLUSTERS)}>Clusters</NavLink>
    <NavLink to={buildRoute(routes.RESOURCES_NEPTUNE_CLUSTER_GRAPH_BROWSER)}>Graph Browser</NavLink>
    <NavLink to={buildRoute(routes.RESOURCES_NEPTUNE_CLUSTER_QUICK_ACTIONS)}>Quick Actions</NavLink>
  </>
);
