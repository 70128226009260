import React, { useState, useCallback, useEffect, FormEvent, ReactElement } from 'react';
import md5 from 'md5';
import { MenuItem, Grid, TextField, Typography, FormControlLabel, Checkbox, IconButton } from '@mui/material';
import { Add as AddIcon, Delete as DeleteIcon } from '@mui/icons-material';
import { QueryDynamoDBRequest, ScanDynamoDBRequest, DynamoDBTable } from '@localstack/types';

import { ActionTitle } from '../../../display';

const OPERATOR_MAPPINGS = {
  '': '',
  '=': '{field} = {value1}',
  '<>': '{field} <> {value1}',
  '<=': '{field} <= {value1}',
  '<': '{field} < {value1}',
  '>': '{field} > {value1}',
  '>=': '{field} >= {value1}',
  BETWEEN: '{field} BETWEEN {value1} AND {value2}',
  attribute_exists: 'attribute_exists({field})',
  'NOT attribute_exists': 'NOT attribute_exists({field})',
  contains: 'contains({field}, {value1})',
  'NOT contains': 'NOT contains({field}, {value1})',
  begins_with: 'begins_with({field}, {value1})',
};

const OPERATOR_NAMES: Record<keyof typeof OPERATOR_MAPPINGS, string> = {
  '': 'None',
  '=': 'Equal to',
  '<>': 'Not equal to',
  '<=': 'Less than or equal to',
  '<': 'Less than',
  '>=': 'Greater than or equal to',
  '>': 'Greater than',
  BETWEEN: 'Between',
  attribute_exists: 'Exists',
  'NOT attribute_exists': 'Not exists',
  contains: 'Contains',
  'NOT contains': 'Not contains',
  begins_with: 'Begins with',
};

const LONELESS_OPERATORS: (keyof typeof OPERATOR_MAPPINGS)[] = ['attribute_exists', 'NOT attribute_exists'];

export type DynamoDBExpressionBuilderProps = {
  formId: string;
  table: Optional<DynamoDBTable>;
} & (
  | {
      mode: 'query';
      onSubmit: (request: QueryDynamoDBRequest) => unknown;
    }
  | {
      mode: 'scan';
      onSubmit: (request: ScanDynamoDBRequest) => unknown;
    }
);

interface Filter {
  attributeName?: string;
  attributeType?: string;
  attributeValue?: [string] | [string, string];
  condition?: keyof typeof OPERATOR_MAPPINGS;
}

const buildNameAlias = (name: string) => `#${md5(name).substring(0, 4)}`;
const buildValueAlias = (name: string) => `:${md5(name).substring(0, 4)}`;

const buildFilterExpression = (filters: Filter[]) => {
  const attributeNames: Record<string, string> = {};
  const attributeValues: Record<string, Record<string, string>> = {};

  const filterExpression = filters.reduce((memo, filter) => {
    const prefix = memo ? `${memo} AND ` : memo;

    const attrAliasName = buildNameAlias(filter.attributeName || '');
    const attrAliasValue1 = `${buildValueAlias(filter.attributeName || '')}_1`;
    const attrAliasValue2 = `${buildValueAlias(filter.attributeName || '')}_2`;

    const value1 = filter.attributeValue?.[0] ?? '';
    const value2 = filter.attributeValue?.[1] ?? '';

    attributeNames[attrAliasName] = filter.attributeName as string;

    if (filter.condition === 'BETWEEN') {
      attributeValues[attrAliasValue1] = { [filter.attributeType as string]: value1 };
      attributeValues[attrAliasValue2] = { [filter.attributeType as string]: value2 };
    } else {
      attributeValues[attrAliasValue1] = { [filter.attributeType as string]: value1 };
    }

    if (!filter.condition) return prefix;

    const expression = OPERATOR_MAPPINGS[filter.condition as keyof typeof OPERATOR_MAPPINGS]
      .replace('{field}', attrAliasName)
      .replace('{value1}', attrAliasValue1)
      .replace('{value2}', attrAliasValue2);

    return `${prefix}${expression}`;
  }, '');

  return {
    filterExpression,
    attributeNames,
    attributeValues,
  };
};

export const DynamoDBExpressionBuilder = ({
  formId,
  table,
  mode,
  onSubmit,
}: DynamoDBExpressionBuilderProps): ReactElement => {
  const [index, setIndex] = useState<Optional<string>>(null);
  const [partitionKeyValue, setPartitionKeyValue] = useState<Optional<string>>(null);
  const [descending, setDescending] = useState(false);

  const [sortKeyValue, setSortKeyValue] = useState<Optional<[string] | [string, string]>>(null);
  const [sortKeyOperator, setSortKeyOperator] = useState<Optional<keyof typeof OPERATOR_MAPPINGS>>(null);

  const [filters, setFilters] = useState<Filter[]>([]);

  const indexObject = table?.GlobalSecondaryIndexes?.find((idx) => idx.IndexName === index);

  const partitionKey = (indexObject?.KeySchema ?? table?.KeySchema)?.find(({ KeyType }) => KeyType === 'HASH');
  const sortKey = (indexObject?.KeySchema ?? table?.KeySchema)?.find(({ KeyType }) => KeyType === 'RANGE');

  useEffect(() => {
    // reset sort and partition key values to prevent leaking when switching to scan
    if (mode !== 'query') {
      setSortKeyValue(null);
      setSortKeyOperator(null);
      setPartitionKeyValue(null);
    }
  }, [mode]);

  const updateFilter = useCallback(
    (idx: number, attribute: keyof Filter, value: string | [string] | [string, string]) => {
      const updatedFilters = [...filters];
      updatedFilters[idx] = { ...filters[idx], [attribute]: value };
      setFilters(updatedFilters);
    },
    [filters],
  );

  const handleSubmit = useCallback(
    (e: FormEvent<HTMLFormElement>) => {
      e.preventDefault();

      const primaryKeyFilters: Filter[] = [];

      // add partiton key condition to filters
      if (partitionKeyValue) {
        const partitionKeyType =
          table?.AttributeDefinitions?.find((d) => d.AttributeName === partitionKey?.AttributeName)?.AttributeType ??
          'S';

        primaryKeyFilters.push({
          attributeName: partitionKey?.AttributeName,
          attributeType: partitionKeyType,
          attributeValue: [partitionKeyValue],
          condition: '=',
        });
      }

      // add sort key condition to filters
      if (sortKeyValue && sortKeyOperator) {
        const sortKeyType =
          table?.AttributeDefinitions?.find((d) => d.AttributeName === sortKey?.AttributeName)?.AttributeType ?? 'S';

        primaryKeyFilters.push({
          attributeName: sortKey?.AttributeName,
          attributeType: sortKeyType,
          attributeValue: sortKeyValue,
          condition: sortKeyOperator,
        });
      }

      const primaryExpression = buildFilterExpression(primaryKeyFilters);
      const attrsExpression = buildFilterExpression(filters);

      const mergedAttributeNames = {
        ...primaryExpression.attributeNames,
        ...attrsExpression.attributeNames,
      };

      const mergedAttributeValues = {
        ...primaryExpression.attributeValues,
        ...attrsExpression.attributeValues,
      };

      const request: QueryDynamoDBRequest | ScanDynamoDBRequest = {
        TableName: table?.TableName as string,
        ...(index && { IndexName: index }),
        ...(primaryExpression.filterExpression && {
          KeyConditionExpression: primaryExpression.filterExpression,
        }),
        ...(attrsExpression.filterExpression && {
          FilterExpression: attrsExpression.filterExpression,
        }),
        ...(Object.keys(mergedAttributeNames).length && {
          ExpressionAttributeNames: mergedAttributeNames,
        }),
        ...(Object.keys(mergedAttributeValues).length && {
          ExpressionAttributeValues: mergedAttributeValues,
        }),
      };

      onSubmit({ ...request, TableName: table?.TableName as string });
    },
    [table, index, filters, sortKey, sortKeyValue, sortKeyOperator, partitionKey, partitionKeyValue, onSubmit],
  );

  return (
    <form onSubmit={handleSubmit} id={formId}>
      <Grid container spacing={1} alignItems="center">
        <Grid item md={12}>
          <TextField
            select
            fullWidth
            variant="outlined"
            label="Index"
            size="small"
            value={index ?? ''}
            onChange={({ target: { value } }) => setIndex(value as string)}
          >
            <MenuItem key="" value="">
              Whole Table
            </MenuItem>
            {(table?.GlobalSecondaryIndexes || []).map(({ IndexName }) => (
              <MenuItem key={IndexName} value={IndexName}>
                {IndexName}
              </MenuItem>
            ))}
          </TextField>
        </Grid>
        {mode === 'query' && (
          <>
            <Grid item md={12}>
              <TextField
                fullWidth
                label={`${partitionKey?.AttributeName} (Partition Key)`}
                variant="outlined"
                size="small"
                value={partitionKeyValue ?? ''}
                onChange={({ target: { value } }) => setPartitionKeyValue(value)}
              />
            </Grid>
            <Grid item sm={4}>
              <TextField
                select
                fullWidth
                variant="outlined"
                label="Operator"
                size="small"
                value={sortKeyOperator ?? ''}
                onChange={({ target: { value } }) => setSortKeyOperator(value as keyof typeof OPERATOR_MAPPINGS)}
              >
                {Object.entries(OPERATOR_NAMES).map(([op, desc]) => (
                  <MenuItem key={op} value={op}>
                    {desc}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
            <Grid item sm={sortKeyOperator === 'BETWEEN' ? 3 : 6}>
              <TextField
                fullWidth
                label={`${sortKey?.AttributeName} (Sort Key)`}
                variant="outlined"
                size="small"
                value={(sortKeyOperator === 'BETWEEN' ? sortKeyValue?.[0] : sortKeyValue) ?? ''}
                onChange={({ target: { value } }) =>
                  sortKeyOperator === 'BETWEEN'
                    ? setSortKeyValue([value, sortKeyValue?.[1] || ''])
                    : setSortKeyValue([value])
                }
              />
            </Grid>
            {sortKeyOperator === 'BETWEEN' && (
              <Grid item sm={3}>
                <TextField
                  fullWidth
                  label="And"
                  variant="outlined"
                  size="small"
                  value={sortKeyValue?.[1] ?? ''}
                  onChange={({ target: { value } }) => setSortKeyValue([sortKeyValue?.[0] || '', value])}
                />
              </Grid>
            )}
            <Grid item sm={2}>
              <FormControlLabel
                control={
                  <Checkbox
                    color="primary"
                    size="small"
                    checked={descending}
                    onChange={(_, checked) => setDescending(checked)}
                  />
                }
                label="Sort descending"
              />
            </Grid>
          </>
        )}
      </Grid>
      <ActionTitle
        title={<Typography variant="subtitle1">Filters</Typography>}
        actions={
          <IconButton onClick={() => setFilters([...filters, {}])} size="large">
            <AddIcon />
          </IconButton>
        }
      />
      <Grid container spacing={1}>
        {filters.map((filter, idx) => (
          // eslint-disable-next-line
          <React.Fragment key={idx}>
            <Grid item sm={3}>
              <TextField
                fullWidth
                label="Attribute"
                variant="outlined"
                size="small"
                value={filter.attributeName ?? ''}
                onChange={({ target: { value } }) => updateFilter(idx, 'attributeName', value)}
              />
            </Grid>
            <Grid item sm={2}>
              <TextField
                select
                fullWidth
                variant="outlined"
                label="Type"
                size="small"
                value={filter.attributeType ?? ''}
                onChange={({ target: { value } }) => updateFilter(idx, 'attributeType', value)}
              >
                <MenuItem key="S" value="S">
                  String
                </MenuItem>
                <MenuItem key="N" value="N">
                  Number
                </MenuItem>
                <MenuItem key="B" value="B">
                  Binary
                </MenuItem>
                <MenuItem key="BOOL" value="BOOL">
                  Boolean
                </MenuItem>
                <MenuItem key="BETWEEN" value="BETWEEN">
                  Null
                </MenuItem>
              </TextField>
            </Grid>
            <Grid item sm={2}>
              <TextField
                select
                fullWidth
                variant="outlined"
                label="Operator"
                size="small"
                value={filter.condition ?? ''}
                onChange={({ target: { value } }) => updateFilter(idx, 'condition', value)}
              >
                {Object.entries(OPERATOR_NAMES).map(([op, desc]) => (
                  <MenuItem key={op} value={op}>
                    {desc}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
            <Grid item sm={filter.condition === 'BETWEEN' ? 2 : 4}>
              {!LONELESS_OPERATORS.includes(filter.condition as '=') && (
                <TextField
                  fullWidth
                  label="Value"
                  variant="outlined"
                  size="small"
                  value={filter.attributeValue?.[0]}
                  onChange={({ target: { value } }) =>
                    updateFilter(idx, 'attributeValue', [value, filter.attributeValue?.[1] || ''])
                  }
                />
              )}
            </Grid>
            {filter.condition === 'BETWEEN' && (
              <Grid item sm={2}>
                <TextField
                  fullWidth
                  label="And"
                  variant="outlined"
                  size="small"
                  value={filter.attributeValue?.[1] ?? ''}
                  onChange={({ target: { value } }) =>
                    updateFilter(idx, 'attributeValue', [filter.attributeValue?.[0] || '', value])
                  }
                />
              </Grid>
            )}
            <Grid item sm={1}>
              <IconButton onClick={() => setFilters(filters.filter((_, i) => i !== idx))} size="large">
                <DeleteIcon />
              </IconButton>
            </Grid>
          </React.Fragment>
        ))}
      </Grid>
    </form>
  );
};
