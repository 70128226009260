import { SERVICE_CODES, STORAGE_FAVORITES } from '@localstack/constants';
import { useRoutes } from '@localstack/services';
import { RemoveCircle as RemoveIcon, Star as StarIcon } from '@mui/icons-material';
import { AwsServiceTile } from '@localstack/ui';
import { ReactElement } from 'react';

import { Box, Card, CardContent, CardHeader, IconButton, Grid, List, ListItem, Typography } from '@mui/material';

import { ProductFeature, WidgetOptions } from '@localstack/types';
import { RESOURCES_TYPES } from '@localstack/resources';

import { useLocalStorage } from '@localstack/services/src/hook/util';

import { useAuthProvider } from '~/hooks/useAuthProvider';

export const FavoritesWidgetOptions: WidgetOptions = {
  i: 'resource_favorites',
  image: '/widgets/favorites.png',
  style: 'media',
  xl: {
    w: 7,
    h: 7,
  },
  lg: {
    w: 7,
    h: 7,
  },
  md: {
    w: 5,
    h: 7,
  },
  sm: {
    w: 7,
    h: 7,
  },
  xs: {
    w: 12,
    h: 7,
  },
};

type Props = {
  className?: string;
  style?: Record<string, string>;
  children?: JSX.Element;
  removeCallback?: () => void;
  editing?: boolean;
};

export const FavoritesWidget = ({
  style,
  className,
  children,
  removeCallback,
  editing,
  ...rest
}: Props): ReactElement => {
  const { goto } = useRoutes();
  const { data: favorites } = useLocalStorage<string[]>({ ...STORAGE_FAVORITES, defaultValue: [] });
  const { hasFeature } = useAuthProvider();
  const isEnterpriseSub = hasFeature(ProductFeature.FEATURE_CI_PROJECTS); // FIXME: not great, find a better way

  return (
    <Card className={className} style={style} {...rest}>
      <Box style={{ maxHeight: '100%' }} display="flex" flexDirection="column">
        <CardHeader
          title="Resource Browser Favorites"
          titleTypographyProps={{ variant: 'subtitle1' }}
          action={
            editing && (
              <IconButton aria-label="remove" onClick={removeCallback} size="large">
                <RemoveIcon />
              </IconButton>
            )
          }
        />
        <Grid container component={List} style={{ overflowY: 'auto' }} disablePadding>
          {favorites?.map((code: keyof typeof RESOURCES_TYPES) => (
            <Grid item xs={12} sm={6} key={code} component={ListItem}>
              <AwsServiceTile
                size="large"
                code={code}
                title={SERVICE_CODES[code as keyof typeof SERVICE_CODES]}
                hideTooltip
                clickable
                navigateTo={() => goto(RESOURCES_TYPES[code])}
                isEnterpriseSub={isEnterpriseSub}
              />
            </Grid>
          ))}

          <CardContent>
            <Typography variant="body2">
              {favorites && favorites.length > 0 ? (
                <>
                  To add or remove favorites from this list click the
                  <StarIcon style={{ transform: 'translate(0, 0.3em)' }} /> icon next to a resource in the{' '}
                  <strong>Resources</strong> tab.
                </>
              ) : (
                <>
                  No favorite resources selected yet. Click the
                  <StarIcon style={{ transform: 'translate(0, 0.3em)' }} /> icon next to a resource in the{' '}
                  <strong>Resources</strong> tab to add it here.
                </>
              )}
            </Typography>
          </CardContent>
        </Grid>
      </Box>
      {children}
    </Card>
  );
};
