import { ReactElement } from 'react';
import { useRoutes, useAwsGetter } from '@localstack/services';
import { useParams } from 'react-router-dom';
import { Card, CardContent, Button, Box, Typography } from '@mui/material';
import { Breadcrumbs, AppSyncResolverDetails } from '@localstack/ui';

import { DEFAULT_APPSYNC_ROUTES } from '@localstack/constants';

import { NavTabs } from './components';
import { AppSyncProps } from './types';

export const AppSyncApiResolver = ({
  Layout,
  clientOverrides,
  routes = DEFAULT_APPSYNC_ROUTES,
}: AppSyncProps): ReactElement => {
  const { goto } = useRoutes();
  const { apiId, typeName, fieldName } = useParams<'apiId' | 'typeName' | 'fieldName'>();

  const { data: resolver } = useAwsGetter('AppSync', 'getResolver', [{ apiId, typeName, fieldName }], {
    clientOverrides,
  });

  return (
    <Layout
      documentTitle="API Details"
      tabs={<NavTabs apiId={apiId as string} routes={routes} />}
      title={
        <Box>
          <Typography variant="h4">Resolver Details</Typography>
          <Breadcrumbs
            mappings={[
              ['AppSync', () => goto(routes.RESOURCES_APPSYNC)],
              [apiId, () => goto(routes.RESOURCES_APPSYNC_API, { apiId })],
              [fieldName && `${typeName}#${fieldName}`, null],
            ]}
          />
        </Box>
      }
      actions={
        <Button onClick={() => goto(routes.RESOURCES_APPSYNC_API_RESOLVER_UPDATE, { apiId, typeName, fieldName })}>
          Edit Resolver
        </Button>
      }
    >
      <Card>
        <CardContent>
          <AppSyncResolverDetails resolver={resolver?.resolver} />
        </CardContent>
      </Card>
    </Layout>
  );
};
