import { ReactElement, useState } from 'react';
import { CognitoCreatePoolParams, CognitoUpdatePoolParams, CognitoPool } from '@localstack/types';
import { FormControlLabel, Switch, Typography, Box } from '@mui/material';
import { AwsClientOverrides, getSchema } from '@localstack/services';
import { DEFAULT_IAM_ROUTES, DEFAULT_KMS_ROUTES, DEFAULT_LAMBDA_ROUTES } from '@localstack/constants';

import { MagicForm } from '../../magic/MagicForm';
import { RelatedResourcePicker } from '../../../form/RelatedResourcePicker';

export interface CognitoPoolFormProps {
  pool?: Optional<Partial<CognitoPool>>;
  loading?: boolean;
  formId?: string;
  clientOverrides?: AwsClientOverrides;
  onCreate: (data: CognitoCreatePoolParams) => unknown;
  onUpdate: (data: CognitoUpdatePoolParams) => unknown;
}

export const CognitoPoolForm = ({
  pool,
  loading,
  formId,
  clientOverrides,
  onCreate,
  onUpdate,
}: CognitoPoolFormProps): ReactElement => {
  const [enableSms, setEnableSms] = useState(!!pool?.SmsConfiguration?.SnsCallerArn);
  const [enableLambda, setEnableLambda] = useState(!!Object.keys(pool?.LambdaConfig ?? {}).length);
  const [enableDevice, setEnableDevice] = useState(!!Object.keys(pool?.DeviceConfiguration ?? {}).length);

  return (
    <>
      <Typography variant="subtitle1">Extended Configurations</Typography>
      <Typography variant="caption">Switch to display additional configurations</Typography>
      <Box>
        <FormControlLabel
          label="Lambda Configuration"
          control={
            <Switch color="primary" checked={enableLambda} onChange={(_e, checked) => setEnableLambda(checked)} />
          }
        />
        <FormControlLabel
          label="SMS Configuration"
          control={<Switch color="primary" checked={enableSms} onChange={(_e, checked) => setEnableSms(checked)} />}
        />
        <FormControlLabel
          label="Device Configuration"
          control={
            <Switch color="primary" checked={enableDevice} onChange={(_e, checked) => setEnableDevice(checked)} />
          }
        />
      </Box>
      <MagicForm
        data={pool}
        schema={getSchema('CognitoIdentityServiceProvider')}
        loading={loading}
        entry={pool ? 'UpdateUserPoolRequest' : 'CreateUserPoolRequest'}
        formId={formId}
        defaultValues={{
          UserPoolAddOns: {
            AdvancedSecurityMode: 'OFF',
          },
        }}
        fieldConditions={{
          '^UserPoolId': false,
          '^LambdaConfig': enableLambda,
          '^SmsConfiguration': enableSms,
          '^DeviceConfiguration': enableDevice,
          '^CorsConfiguration': ['$ProtocolType', '===', 'HTTP'],
        }}
        externalFields={{
          '^SmsConfiguration.SnsCallerArn$': (control, fieldName, required) => (
            <RelatedResourcePicker
              control={control}
              client="IAM"
              method="listRoles"
              arrayKeyName="Roles"
              property="Arn"
              fieldName={fieldName}
              entityName="IAM Role"
              creationRoute={DEFAULT_IAM_ROUTES.RESOURCES_IAM_ROLE_CREATE}
              required={required}
              clientOverrides={clientOverrides}
            />
          ),
          LambdaArn$: (control, fieldName, required) => (
            <RelatedResourcePicker
              control={control}
              client="Lambda"
              method="listFunctions"
              arrayKeyName="Functions"
              property="FunctionArn"
              fieldName={fieldName}
              entityName="Lambda Function"
              creationRoute={DEFAULT_LAMBDA_ROUTES.RESOURCES_LAMBDA_FUNCTION_NEW}
              required={required}
              clientOverrides={clientOverrides}
            />
          ),
          '^LambdaConfig.KMSKeyID$': (control, fieldName, required) => (
            <RelatedResourcePicker
              control={control}
              client="KMS"
              method="listKeys"
              arrayKeyName="Keys"
              property="KeyArn"
              fieldName={fieldName}
              entityName="KMS Key"
              creationRoute={DEFAULT_KMS_ROUTES.RESOURCES_KMS_KEY_CREATE}
              required={required}
              clientOverrides={clientOverrides}
            />
          ),
          '^EmailConfiguration.SourceArn$': (control, fieldName, required) => (
            <RelatedResourcePicker
              control={control}
              client="SESV2"
              method="listEmailIdentities"
              arrayKeyName="EmailIdentities"
              property="IdentityName"
              fieldName={fieldName}
              entityName="KMS Key"
              creationRoute={DEFAULT_KMS_ROUTES.RESOURCES_KMS_KEY_CREATE}
              required={required}
              clientOverrides={clientOverrides}
            />
          ),
        }}
        onSubmit={(data: CognitoCreatePoolParams) => {
          if (!pool) return onCreate(data);
          return onUpdate({ ...data, UserPoolId: pool.Id as string });
        }}
      />
    </>
  );
};
