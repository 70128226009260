import { DEFAULT_ACM_ROUTES } from '@localstack/constants';
import { ReactElement } from 'react';
import { useAwsGetter, useRoutes } from '@localstack/services';
import { useParams } from 'react-router-dom';
import { Box, Card, Typography } from '@mui/material';
import { Breadcrumbs, ACMCertificateDetail } from '@localstack/ui';

import { ACMProps } from './types';

export const ACMCertificate = ({ Layout, clientOverrides, routes = DEFAULT_ACM_ROUTES }: ACMProps): ReactElement => {
  const { goto } = useRoutes();
  const { id } = useParams<'id'>();
  const { data: certificate } = useAwsGetter('ACM', 'describeCertificate', [{ CertificateArn: id }], {
    clientOverrides,
    swrOverrides: { revalidateOnMount: true },
  });
  return (
    <Layout
      title={
        <Box>
          <Typography variant="h4">ACM Certificate</Typography>
          <Breadcrumbs
            mappings={[
              ['ACM', () => goto(routes.RESOURCES_ACM_CERTIFICATES)],
              [certificate?.Certificate?.DomainName, null],
            ]}
          />
        </Box>
      }
    >
      <Card>
        <ACMCertificateDetail certificate={certificate} />
      </Card>
    </Layout>
  );
};
