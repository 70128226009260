import { SERVICE_NAME } from '@localstack/types';

import { PATH_PREFIX_RESOURCES } from './base';

const API_GATEWAY_V1_BASE_ROUTE = `${PATH_PREFIX_RESOURCES}/${SERVICE_NAME.APIGATEWAY}`;
const API_GATEWAY_V2_BASE_ROUTE = `${PATH_PREFIX_RESOURCES}/${SERVICE_NAME.APIGATEWAYV2}`;

export const DEFAULT_API_GATEWAY_ROUTES: Record<string, string> = {
  // AGW V2
  RESOURCES_AGW2: `${API_GATEWAY_V2_BASE_ROUTE}`,
  RESOURCES_AGW2_API: `${API_GATEWAY_V2_BASE_ROUTE}/:apiId`,
  RESOURCES_AGW2_API_CREATE: `${API_GATEWAY_V2_BASE_ROUTE}/new`,
  RESOURCES_AGW2_API_UPDATE: `${API_GATEWAY_V2_BASE_ROUTE}/:apiId/update`,
  RESOURCES_AGW2_API_DEPLOY: `${API_GATEWAY_V2_BASE_ROUTE}/:apiId/deploy`,
  RESOURCES_AGW2_API_ROUTES: `${API_GATEWAY_V2_BASE_ROUTE}/:apiId/routes`,
  RESOURCES_AGW2_API_ROUTE: `${API_GATEWAY_V2_BASE_ROUTE}/:apiId/routes/:routeId`,
  RESOURCES_AGW2_API_ROUTE_CREATE: `${API_GATEWAY_V2_BASE_ROUTE}/:apiId/routes/new`,
  RESOURCES_AGW2_API_ROUTE_UPDATE: `${API_GATEWAY_V2_BASE_ROUTE}/:apiId/routes/:routeId/update`,
  RESOURCES_AGW2_API_INTEGRATIONS: `${API_GATEWAY_V2_BASE_ROUTE}/:apiId/integrations`,
  RESOURCES_AGW2_API_INTEGRATION: `${API_GATEWAY_V2_BASE_ROUTE}/:apiId/integrations/:integrationId`,
  RESOURCES_AGW2_API_INTEGRATION_CREATE: `${API_GATEWAY_V2_BASE_ROUTE}/:apiId/integrations/new`,
  RESOURCES_AGW2_API_INTEGRATION_UPDATE: `${API_GATEWAY_V2_BASE_ROUTE}/:apiId/integrations/:integrationId/update`,
  RESOURCES_AGW2_API_STAGES: `${API_GATEWAY_V2_BASE_ROUTE}/:apiId/stages`,
  RESOURCES_AGW2_API_STAGE: `${API_GATEWAY_V2_BASE_ROUTE}/:apiId/stages/:stageName`,
  RESOURCES_AGW2_API_STAGE_CREATE: `${API_GATEWAY_V2_BASE_ROUTE}/:apiId/stages/new`,
  RESOURCES_AGW2_API_STAGE_UPDATE: `${API_GATEWAY_V2_BASE_ROUTE}/:apiId/stages/:stageName/update`,
  RESOURCES_AGW2_API_MODELS: `${API_GATEWAY_V2_BASE_ROUTE}/:apiId/models`,
  RESOURCES_AGW2_API_MODEL: `${API_GATEWAY_V2_BASE_ROUTE}/:apiId/models/:modelId`,
  RESOURCES_AGW2_API_MODEL_CREATE: `${API_GATEWAY_V2_BASE_ROUTE}/:apiId/models/new`,
  RESOURCES_AGW2_API_MODEL_UPDATE: `${API_GATEWAY_V2_BASE_ROUTE}/:apiId/models/:modelId/update`,
  RESOURCES_AGW2_API_AUTHORIZERS: `${API_GATEWAY_V2_BASE_ROUTE}/:apiId/authorizers`,
  RESOURCES_AGW2_API_AUTHORIZER: `${API_GATEWAY_V2_BASE_ROUTE}/:apiId/authorizers/:authorizerId`,
  RESOURCES_AGW2_API_AUTHORIZER_CREATE: `${API_GATEWAY_V2_BASE_ROUTE}/:apiId/authorizers/new`,
  RESOURCES_AGW2_API_AUTHORIZER_UPDATE: `${API_GATEWAY_V2_BASE_ROUTE}/:apiId/authorizers/:authorizerId/update`,

  // AGW v1
  RESOURCES_AGW1: `${API_GATEWAY_V1_BASE_ROUTE}`,
  RESOURCES_AGW1_API: `${API_GATEWAY_V1_BASE_ROUTE}/:restApiId`,
  RESOURCES_AGW1_API_CREATE: `${API_GATEWAY_V1_BASE_ROUTE}/new`,
  RESOURCES_AGW1_API_UPDATE: `${API_GATEWAY_V1_BASE_ROUTE}/:restApiId/update`,
  RESOURCES_AGW1_API_DEPLOY: `${API_GATEWAY_V1_BASE_ROUTE}/:restApiId/deploy`,
  RESOURCES_AGW1_API_RESOURCES: `${API_GATEWAY_V1_BASE_ROUTE}/:restApiId/resources`,
  RESOURCES_AGW1_API_RESOURCE: `${API_GATEWAY_V1_BASE_ROUTE}/:restApiId/resources/:resourceId`,
  RESOURCES_AGW1_API_RESOURCE_CREATE: `${API_GATEWAY_V1_BASE_ROUTE}/:restApiId/resources/new`,
  RESOURCES_AGW1_API_RESOURCE_UPDATE: `${API_GATEWAY_V1_BASE_ROUTE}/:restApiId/resources/:resourceId/update`,
  RESOURCES_AGW1_API_STAGES: `${API_GATEWAY_V1_BASE_ROUTE}/:restApiId/stages`,
  RESOURCES_AGW1_API_STAGE: `${API_GATEWAY_V1_BASE_ROUTE}/:restApiId/stages/:stageName`,
  RESOURCES_AGW1_API_STAGE_CREATE: `${API_GATEWAY_V1_BASE_ROUTE}/:restApiId/stages/new`,
  RESOURCES_AGW1_API_STAGE_UPDATE: `${API_GATEWAY_V1_BASE_ROUTE}/:restApiId/stages/:stageName/update`,
  RESOURCES_AGW1_API_AUTHORIZERS: `${API_GATEWAY_V1_BASE_ROUTE}/:restApiId/authorizers`,
  RESOURCES_AGW1_API_AUTHORIZER: `${API_GATEWAY_V1_BASE_ROUTE}/:restApiId/authorizers/:authorizerId`,
  RESOURCES_AGW1_API_AUTHORIZER_CREATE: `${API_GATEWAY_V1_BASE_ROUTE}/:restApiId/authorizers/new`,
  RESOURCES_AGW1_API_AUTHORIZER_UPDATE: `${API_GATEWAY_V1_BASE_ROUTE}/:restApiId/authorizers/:authorizerId/update`,
  RESOURCES_AGW1_API_MODELS: `${API_GATEWAY_V1_BASE_ROUTE}/:restApiId/models`,
  RESOURCES_AGW1_API_MODEL: `${API_GATEWAY_V1_BASE_ROUTE}/:restApiId/models/:modelName`,
  RESOURCES_AGW1_API_MODEL_CREATE: `${API_GATEWAY_V1_BASE_ROUTE}/:restApiId/models/new`,
  RESOURCES_AGW1_API_MODEL_UPDATE: `${API_GATEWAY_V1_BASE_ROUTE}/:restApiId/models/:modelName/update`,
  RESOURCES_AGW1_API_VALIDATORS: `${API_GATEWAY_V1_BASE_ROUTE}/:restApiId/validators`,
  RESOURCES_AGW1_API_VALIDATOR: `${API_GATEWAY_V1_BASE_ROUTE}/:restApiId/validators/:requestValidatorId`,
  RESOURCES_AGW1_API_VALIDATOR_CREATE: `${API_GATEWAY_V1_BASE_ROUTE}/:restApiId/validators/new`,
  RESOURCES_AGW1_API_VALIDATOR_UPDATE: `${API_GATEWAY_V1_BASE_ROUTE}/:restApiId/validators/:requestValidatorId/update`,
  RESOURCES_AGW1_API_USAGE_PLANS: `${API_GATEWAY_V1_BASE_ROUTE}/:restApiId/plans`,
  RESOURCES_AGW1_API_USAGE_PLAN: `${API_GATEWAY_V1_BASE_ROUTE}/:restApiId/plans/:usagePlanId`,
  RESOURCES_AGW1_API_USAGE_PLAN_CREATE: `${API_GATEWAY_V1_BASE_ROUTE}/:restApiId/plans/new`,
  RESOURCES_AGW1_API_USAGE_PLAN_UPDATE: `${API_GATEWAY_V1_BASE_ROUTE}/:restApiId/plans/:usagePlanId/update`,
  RESOURCES_AGW1_API_KEYS: `${API_GATEWAY_V1_BASE_ROUTE}/:restApiId/keys`,
  RESOURCES_AGW1_API_KEY_CREATE: `${API_GATEWAY_V1_BASE_ROUTE}/:restApiId/keys/new`,
  RESOURCES_AGW1_API_METHODS: `${API_GATEWAY_V1_BASE_ROUTE}/:restApiId/resources/:resourceId/methods`,
  RESOURCES_AGW1_API_METHOD: `${API_GATEWAY_V1_BASE_ROUTE}/:restApiId/resources/:resourceId/methods/:httpMethod`,
  RESOURCES_AGW1_API_METHOD_CREATE: `${API_GATEWAY_V1_BASE_ROUTE}/:restApiId/resources/:resourceId/methods/new`,
  RESOURCES_AGW1_API_METHOD_UPDATE: `${API_GATEWAY_V1_BASE_ROUTE}/:restApiId/resources/:resourceId/methods/:httpMethod/update`,
  RESOURCES_AGW1_API_INTEGRATION: `${API_GATEWAY_V1_BASE_ROUTE}/:restApiId/resources/:resourceId/methods/:httpMethod/integration`,
  RESOURCES_AGW1_API_INTEGRATION_CREATE: `${API_GATEWAY_V1_BASE_ROUTE}/:restApiId/resources/:resourceId/methods/:httpMethod/integration/new`,
  RESOURCES_AGW1_API_INTEGRATION_UPDATE: `${API_GATEWAY_V1_BASE_ROUTE}/:restApiId/resources/:resourceId/methods/:httpMethod/integration/update`,
  RESOURCES_AGW1_API_INTEGRATION_RESPONSES: `${API_GATEWAY_V1_BASE_ROUTE}/:restApiId/resources/:resourceId/methods/:httpMethod/integration/responses`,
  RESOURCES_AGW1_API_INTEGRATION_RESPONSE: `${API_GATEWAY_V1_BASE_ROUTE}/:restApiId/resources/:resourceId/methods/:httpMethod/integration/responses/:statusCode`,
  RESOURCES_AGW1_API_INTEGRATION_RESPONSE_CREATE: `${API_GATEWAY_V1_BASE_ROUTE}/:restApiId/resources/:resourceId/methods/:httpMethod/integration/responses/new`,
  RESOURCES_AGW1_API_INTEGRATION_RESPONSE_UPDATE: `${API_GATEWAY_V1_BASE_ROUTE}/:restApiId/resources/:resourceId/methods/:httpMethod/integration/responses/:statusCode/update`,
  RESOURCES_AGW1_API_METHOD_RESPONSES: `${API_GATEWAY_V1_BASE_ROUTE}/:restApiId/resources/:resourceId/methods/:httpMethod/responses`,
  RESOURCES_AGW1_API_METHOD_RESPONSE: `${API_GATEWAY_V1_BASE_ROUTE}/:restApiId/resources/:resourceId/methods/:httpMethod/responses/:statusCode`,
  RESOURCES_AGW1_API_METHOD_RESPONSE_CREATE: `${API_GATEWAY_V1_BASE_ROUTE}/:restApiId/resources/:resourceId/methods/:httpMethod/responses/new`,
  RESOURCES_AGW1_API_METHOD_RESPONSE_UPDATE: `${API_GATEWAY_V1_BASE_ROUTE}/:restApiId/resources/:resourceId/methods/:httpMethod/responses/:statusCode/update`,
};
