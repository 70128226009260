import {
  Box,
  Card,
  CardContent,
  CardHeader,
  Grid,
  Link,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Theme,
  Typography,
  Alert,
  AlertTitle,
  Skeleton,
} from '@mui/material';

import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';

import { ReactElement } from 'react';

import { OrganizationsService, useApiEffect, useApiGetter } from '@localstack/services';

import { Permission } from '@localstack/types';

import { ProgressButton } from '@localstack/ui';

import { AppRoute } from '~/config';
import { useAuthProvider } from '~/hooks/useAuthProvider';
import { ContainedCustomerLayout } from '~/layouts';
import { preflightLicenseFriendlyError } from '~/util/license';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    table: {
      '& > *': {
        textWrap: 'nowrap',
      },
      '& tbody tr:hover': {
        backgroundColor: theme.palette.action.hover,
      },
    },
  }),
);

export const MyLicense = (): ReactElement => {
  const classes = useStyles();
  const { can } = useAuthProvider();
  const { userInfo } = useAuthProvider();

  const { data: assignmentInfo, isLoading: isAssignmentInfoLoading } = useApiGetter(
    OrganizationsService,
    'getUserLicenseAssignment',
    [userInfo?.org.id],
    {
      enable: !!userInfo,
      suppressErrors: true,
    },
  );

  const { data: preflight, isLoading: isPreflightLoading } = useApiGetter(
    OrganizationsService,
    'preflightSelfAssignLicense',
    [userInfo?.org.id],
    {
      enable: !!userInfo && !!assignmentInfo && !assignmentInfo.has_license_assigned,
      suppressErrors: true,
    },
  );

  const { selfAssignLicense, isLoading: isIssuingLicense } = useApiEffect(OrganizationsService, ['selfAssignLicense'], {
    revalidate: ['getUserLicenseAssignment', 'listLicenseAssignments', 'preflightSelfAssignLicense'],
  });

  const showSelfAssign = !isAssignmentInfoLoading && !assignmentInfo?.has_license_assigned;

  return (
    <ContainedCustomerLayout title="My License">
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Card>
            <CardHeader title="My License" />
            <CardContent>
              <Box mb={1}>
                <Typography variant="body1">
                  A license is required to activate LocalStack (version 3.0 and up) using an auth token.
                  {can(Permission.UPDATE_ORG_KEYS) && (
                    <>
                      {' '}
                      Licenses can be assigned on the{' '}
                      <Link href={AppRoute.SETTINGS_MEMBERS} underline="hover">
                        Users & Licenses
                      </Link>{' '}
                      page.
                    </>
                  )}{' '}
                  Reach out to your admin to review your license.
                </Typography>
              </Box>

              <Box mb={2}>
                <Typography variant="body1">
                  To use a license with LocalStack set up the auth token in your dev environment. It can be found on the{' '}
                  <Link href={AppRoute.SETTINGS_AUTH_TOKEN} underline="hover">
                    Auth Token
                  </Link>{' '}
                  or{' '}
                  <Link href={AppRoute.GETTING_STARTED} underline="hover">
                    Getting Started
                  </Link>{' '}
                  page.
                </Typography>
              </Box>

              <Box mb={2}>
                <Alert severity="info">
                  <AlertTitle>Using LocalStack versions before v3.0</AlertTitle>
                  To activate and use LocalStack versions prior to the 3.0 release, a legacy API key is used. Legacy API
                  keys can be managed on the{' '}
                  <Link href={AppRoute.SETTINGS_KEYS} underline="hover">
                    Legacy API Keys
                  </Link>{' '}
                  page.
                </Alert>
              </Box>

              <Table className={classes.table}>
                <TableHead>
                  <TableRow>
                    <TableCell>License</TableCell>
                  </TableRow>
                </TableHead>

                <TableBody>
                  {isAssignmentInfoLoading ? (
                    <TableRow>
                      <TableCell>
                        <Skeleton variant="text" />
                      </TableCell>
                    </TableRow>
                  ) : (
                    <>
                      {!assignmentInfo?.has_license_assigned ? (
                        <TableRow>
                          <TableCell>(there is currently no license assigned to your user)</TableCell>
                        </TableRow>
                      ) : (
                        <TableRow>
                          <TableCell style={{ textTransform: 'capitalize' }}>{assignmentInfo.plan_name}</TableCell>
                        </TableRow>
                      )}
                    </>
                  )}
                </TableBody>
              </Table>

              {/* Self assigning license section */}
              {showSelfAssign && (
                <Box mt={2}>
                  {(isPreflightLoading || !preflight) && (
                    <Alert severity="info" variant="outlined">
                      Checking if license can be self-issued...
                    </Alert>
                  )}

                  {preflight?.success === false && preflight.failure && (
                    <Alert severity="info" variant="outlined">
                      {preflightLicenseFriendlyError(preflight?.failure)}
                    </Alert>
                  )}

                  {preflight?.success && (
                    <Alert
                      severity="info"
                      variant="outlined"
                      action={
                        <ProgressButton
                          variant="outlined"
                          size="small"
                          color="primary"
                          style={{ whiteSpace: 'nowrap' }}
                          onClick={() => selfAssignLicense(userInfo?.org.id)}
                          loading={isIssuingLicense}
                        >
                          Issue License
                        </ProgressButton>
                      }
                    >
                      Your workspace admin enabled self-issued licenses. You can issue a license for yourself by
                      clicking the button.
                    </Alert>
                  )}
                </Box>
              )}
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </ContainedCustomerLayout>
  );
};
