import { ReactElement } from 'react';
import { AwsClientOverrides, buildAgwPatches, getSchema } from '@localstack/services';

import {
  AgwRestApiUsagePlan,
  CreateAgwRestApiUsagePlanRequest,
  UpdateAgwRestApiUsagePlanRequest,
} from '@localstack/types';

import { MagicForm } from '../../../magic/MagicForm';
import { RelatedResourcePicker } from '../../../../form';

export interface AgwRestApiUsagePlanFormProps {
  usagePlan?: Optional<Partial<AgwRestApiUsagePlan>>;
  loading?: boolean;
  formId?: string;
  clientOverrides?: AwsClientOverrides;
  onCreate: (data: CreateAgwRestApiUsagePlanRequest) => unknown;
  onUpdate: (data: UpdateAgwRestApiUsagePlanRequest) => unknown;
}

export const AgwRestApiUsagePlanForm = ({
  usagePlan,
  loading,
  formId,
  clientOverrides,
  onCreate,
  onUpdate,
}: AgwRestApiUsagePlanFormProps): ReactElement => (
  <MagicForm
    data={usagePlan}
    schema={getSchema('APIGateway')}
    loading={loading}
    entry="CreateUsagePlanRequest"
    formId={formId}
    externalFields={{
      '^apiStages\\.\\d+\\.apiId$': (control, fieldName, required) => (
        <RelatedResourcePicker
          required={required}
          control={control}
          fieldName={fieldName}
          entityName="API"
          client="APIGateway"
          method="getUsagePlans"
          arrayKeyName="items"
          property="id"
          clientOverrides={clientOverrides}
        />
      ),
    }}
    onSubmit={(data: CreateAgwRestApiUsagePlanRequest) => {
      if (!usagePlan) return onCreate(data);

      const patchOperations = buildAgwPatches(usagePlan, data);
      return onUpdate({ usagePlanId: usagePlan.id as string, patchOperations });
    }}
  />
);
