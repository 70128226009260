import { ReactElement } from 'react';
import { Button, Alert } from '@mui/material';
import { useRoutes } from '@localstack/services';

import { AppRoute } from '~/config';
import { AdminLayout } from '~/layouts';

export const Accounts = (): ReactElement => {
  const { goto } = useRoutes();

  return (
    <AdminLayout
      title="Manage User Accounts"
      actions={<Button onClick={() => goto(AppRoute.ADMIN_CREATE_ACCOUNT)}>Create</Button>}
    >
      <Alert severity="info">
        This page no longer shows a list of users. You can only create new users or search for existing ones.
      </Alert>
    </AdminLayout>
  );
};
