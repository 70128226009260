import { useApiEffect, useGlobalSwr, useRoutes, UserService, retrieveAuthToken } from '@localstack/services';

import { useHubspotProvider } from '~/contexts/HubspotContext';
import { AppRoute } from '~/config';
import { clearAuthStorage } from '~/util/storage';

type OptionsType = { global?: boolean };
type SignoutReturnType = {
  handleSignOut: (options?: OptionsType) => Promise<void>;
  isSigningOut: boolean;
  isGlobalSigningOut: boolean;
};

export const useSignout = (): SignoutReturnType => {
  const { signoutUser, isLoading: isSigningOut } = useApiEffect(UserService, ['signoutUser'], {
    suppressErrors: true,
  });

  const { globalSignoutUser, isLoading: isGlobalSigningOut } = useApiEffect(UserService, ['globalSignoutUser'], {
    suppressErrors: true,
  });

  const { cleanupHubspotUser } = useHubspotProvider();
  const { clearAll } = useGlobalSwr();
  const { goto } = useRoutes();

  const handleSignOut = async (options: OptionsType) => {
    const { global = false } = options || {};
    const authToken = retrieveAuthToken();

    if (!authToken) return;

    const signoutFn = global ? globalSignoutUser : signoutUser;
    await signoutFn(authToken);

    // clean local storage and swr cache
    clearAuthStorage();
    clearAll();
    cleanupHubspotUser();
    goto(AppRoute.SIGN_IN);
  };

  return {
    handleSignOut,
    isSigningOut,
    isGlobalSigningOut,
  };
};
