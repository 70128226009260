import { formatDate } from '@localstack/services';
import { CICreditRangesType, ProductType, UsageSummary } from '@localstack/types';
import { PlanProductIcon, UsageLimit } from '@localstack/ui';
import { Typography, Skeleton, List, ListItem, ListItemAvatar, ListItemText, Box } from '@mui/material';
import { ReactElement } from 'react';

const CI_CREDIT_RANGES: CICreditRangesType = {
  green: [0, 70],
  orange: [70, 90],
  red: [90, 100],
  extra: [100, 200],
};

type UsageOverviewTypes = {
  isLoading: boolean;
  usageSummary: Optional<UsageSummary>;
  creditsUsed: number;
  quota: string | number;
};

export const UsageOverview = ({ isLoading, usageSummary, creditsUsed, quota }: UsageOverviewTypes): ReactElement => (
  <>
    <Typography variant="body1">
      The use of LocalStack in an CI environment requires a CI Auth Token. Each startup of LocalStack is tracked and
      consumes 1 CI credit. The amount of CI credits available to your workspace depends on your current plan and the
      count will reset at the end of the monthly billing period.
    </Typography>
    <Typography variant="body1">
      To keep track of the CI credit consumption in different projects and pipelines it is recommended to create a
      separate CI Auth Token for each and give it a descriptive name. You can create as many CI Auth Tokens as you need,
      but all keys count towards the same sum.
    </Typography>

    {isLoading ? (
      <Skeleton variant="rectangular" height="150px" />
    ) : (
      <List disablePadding>
        <ListItem key="credits">
          <ListItemAvatar>
            <PlanProductIcon productType={ProductType.CI_USAGE} />
          </ListItemAvatar>
          <ListItemText
            primary={
              <>
                Usage ({formatDate(usageSummary?.usage_period_start ?? 0)} &ndash;{' '}
                {formatDate(usageSummary?.usage_period_end ?? 0)})
              </>
            }
            secondary={
              <Box pr={2}>
                {creditsUsed} Credits
                <UsageLimit
                  variant="edged"
                  greenRange={CI_CREDIT_RANGES.green}
                  orangeRange={CI_CREDIT_RANGES.orange}
                  redRange={CI_CREDIT_RANGES.red}
                  value={typeof quota === 'number' ? Math.min(100, creditsUsed / quota) * 100 : 0}
                  displayValue={`${creditsUsed} Credits`}
                  tooltip={
                    <>
                      Consumed {creditsUsed} out of {quota || '∞'} CI credits in this billing period
                    </>
                  }
                />
              </Box>
            }
          />
        </ListItem>
      </List>
    )}
  </>
);
