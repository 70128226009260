import axios from 'axios';
import { get } from 'lodash';
import { useState, useEffect, useCallback, ReactElement, Fragment, useMemo } from 'react';
import {
  formatDate,
  formatDateTime,
  getExternalMagicFieldRenderer,
  getMagicFieldVisibility,
  resolveMagicType,
  transformMagicFieldName,
} from '@localstack/services';
import { MagicDetailsExternalFields, MagicFieldConditions, ResolvedMagicSchema } from '@localstack/types';
import { List, ListSubheader, ListItem, ListItemText, Card } from '@mui/material';

import { orderBy } from '../MagicTable/utils';

interface StructureRendererProps<T> {
  tokens: (string | number)[];
  member: ResolvedMagicSchema;
  data: T;
  title?: Optional<string>;
  externalFields?: MagicDetailsExternalFields;
  fieldConditions?: MagicFieldConditions;
}

const StructureRenderer = <T,>({
  member,
  tokens,
  data,
  title,
  externalFields,
  fieldConditions,
}: StructureRendererProps<T>) => {
  const getFieldName: (name: Optional<string>) => string = useCallback(
    (name: Optional<string>) => [...tokens, name].join('.'),
    [tokens, member.name],
  );

  return (
    <List dense subheader={<ListSubheader disableSticky>{title}</ListSubheader>}>
      {member.members?.map((m) => {
        const fieldName = getFieldName(m.name);
        const fieldValue = get(data, fieldName);

        const visibility = useMemo(
          () => getMagicFieldVisibility(fieldName, fieldConditions, data),
          [fieldName, fieldConditions, data],
        );

        if (!visibility) return <Fragment key={m.name}></Fragment>;

        const customRoot = useMemo(
          () => getExternalMagicFieldRenderer(fieldName, externalFields),
          [m.name, externalFields],
        ) as MagicDetailsExternalFields['key'];

        if (customRoot) return customRoot(fieldValue, transformMagicFieldName(m.name));

        return (
          <Fragment key={m.name}>
            {
              <>
                {['string', 'double', 'long', 'integer'].includes(m.type) &&
                  !(m.name?.toLowerCase() as string).includes('timestamp') && (
                    <ListItem>
                      <ListItemText primary={fieldValue} secondary={transformMagicFieldName(m.name)} />
                    </ListItem>
                  )}
                {['boolean'].includes(m.type) && (
                  <ListItem>
                    <ListItemText primary={`${fieldValue ?? ''}`} secondary={transformMagicFieldName(m.name)} />
                  </ListItem>
                )}
                {(m.name?.toLowerCase() as string).includes('timestamp') && (
                  <ListItem>
                    <ListItemText
                      primary={fieldValue ? formatDateTime(fieldValue) : '-'}
                      secondary={transformMagicFieldName(m.name)}
                    />
                  </ListItem>
                )}
                {['datetime'].includes(m.type) && (
                  <ListItem>
                    <ListItemText
                      primary={fieldValue ? formatDate(fieldValue) : '-'}
                      secondary={transformMagicFieldName(m.name)}
                    />
                  </ListItem>
                )}
                {m.type === 'mapOfString' && fieldValue && (
                  <ListItem style={{ display: 'block' }}>
                    <Card variant="outlined">
                      <List dense subheader={<ListSubheader disableSticky>{m.name}</ListSubheader>}>
                        {Object.keys(fieldValue).map((prop: string) => (
                          <ListItem key={`${m.name}-${prop}`}>
                            <ListItemText primary={fieldValue[prop]} secondary={prop} />
                          </ListItem>
                        ))}
                      </List>
                    </Card>
                  </ListItem>
                )}
                {/* TODO: mapOfObject */}
                {m.type === 'listOfString' && (
                  <ListItem>
                    <ListItemText
                      primary={((fieldValue || []) as string[]).join(', ')}
                      secondary={transformMagicFieldName(m.name)}
                    />
                  </ListItem>
                )}
                {m.type === 'listOfStructure' && (
                  <ListItem style={{ display: 'block' }}>
                    <Card variant="outlined">
                      <List subheader={<ListSubheader disableSticky>{transformMagicFieldName(m.name)}</ListSubheader>}>
                        {/* eslint-disable-next-line */}
                        {((fieldValue || []) as unknown[]).map((_v: any, k: number) => (
                          // eslint-disable-next-line
                          <ListItem style={{ display: 'block' }} key={k}>
                            <Card variant="outlined">
                              <StructureRenderer
                                member={m}
                                tokens={[...tokens, `${m.name}.[${k}]`]}
                                data={data}
                                title={`${transformMagicFieldName(m.name)} (${k + 1})`}
                                externalFields={externalFields}
                                fieldConditions={fieldConditions}
                              />
                            </Card>
                          </ListItem>
                        ))}
                      </List>
                    </Card>
                  </ListItem>
                )}
                {['structure'].includes(m.type) && (
                  <ListItem style={{ display: 'block' }}>
                    <Card variant="outlined">
                      <StructureRenderer
                        member={m}
                        tokens={[...tokens, m.name || '']}
                        data={data}
                        title={transformMagicFieldName(m.name)}
                        externalFields={externalFields}
                        fieldConditions={fieldConditions}
                      />
                    </Card>
                  </ListItem>
                )}
              </>
            }
          </Fragment>
        );
      })}
    </List>
  );
};

type Flatten<Type> = Type extends Array<infer Item> ? Item : Type;

export type MagicDetailsProps<T, D = Flatten<T>> = {
  schema: any; // eslint-disable-line
  entry: string;
  data: Optional<T>;
  title: string;
  externalFields?: MagicDetailsExternalFields;
  fieldConditions?: MagicFieldConditions;
  order?: Extract<keyof D, string>[];
};

export const MagicDetails = <T extends { [Key in keyof D]?: unknown }, D>({
  schema,
  entry,
  data,
  title,
  externalFields,
  order,
  fieldConditions,
}: MagicDetailsProps<T>): ReactElement => {
  const [processedSchema, setProcessedSchema] = useState<Optional<ResolvedMagicSchema>>(null);

  useEffect(() => {
    if (!schema || !entry) return;

    (async () => {
      let resolvedSchema;
      if (typeof schema === 'string') {
        const { data: downloadedSchema } = await axios.get(schema);
        resolvedSchema = resolveMagicType(downloadedSchema.shapes[entry], downloadedSchema);
      } else {
        resolvedSchema = resolveMagicType(schema.shapes[entry], schema);
      }
      const reorderedSchema = order
        ? { ...resolvedSchema, members: resolvedSchema?.members?.sort(orderBy(order)) }
        : resolvedSchema;
      setProcessedSchema(reorderedSchema);
    })();
  }, [schema, entry]);

  return (
    <>
      {processedSchema && (
        <StructureRenderer
          member={processedSchema}
          tokens={[]}
          title={title}
          data={data}
          externalFields={externalFields}
          fieldConditions={fieldConditions}
        />
      )}
    </>
  );
};
