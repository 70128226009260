import { ReactElement } from 'react';
import { useParams } from 'react-router-dom';
import { Card, CardContent, CardActions, Box, Typography } from '@mui/material';
import { CreateAgwRestApiRequestValidatorRequest, UpdateAgwRestApiRequestValidatorRequest } from '@localstack/types';
import { useRoutes, useAwsEffect, useAwsGetter } from '@localstack/services';
import { ProgressButton, AgwRestApiValidatorForm, Breadcrumbs } from '@localstack/ui';
import { DEFAULT_API_GATEWAY_ROUTES } from '@localstack/constants';

import { ApiGatewayProps } from './types';
import { RestApiNavTabs } from './components';

export const AgwRestApiValidatorUpsert = ({
  Layout,
  clientOverrides,
  routes = DEFAULT_API_GATEWAY_ROUTES,
}: ApiGatewayProps): ReactElement => {
  const { goto } = useRoutes();
  const { restApiId, requestValidatorId } = useParams<'restApiId' | 'requestValidatorId'>();

  const { createRequestValidator, updateRequestValidator, isLoading } = useAwsEffect(
    'APIGateway',
    ['createRequestValidator', 'updateRequestValidator'],
    {
      revalidate: ['getRequestValidator', 'getRequestValidators'],
      clientOverrides,
    },
  );

  const { data: validator } = useAwsGetter('APIGateway', 'getRequestValidator', [{ restApiId, requestValidatorId }], {
    clientOverrides,
  });

  const { data: api } = useAwsGetter('APIGateway', 'getRestApi', [{ restApiId }], { clientOverrides });

  const handleCreate = async (data: CreateAgwRestApiRequestValidatorRequest) => {
    await createRequestValidator(data);
    goto(routes.RESOURCES_AGW1_API_VALIDATORS, { restApiId });
  };

  const handleUpdate = async (data: UpdateAgwRestApiRequestValidatorRequest) => {
    await updateRequestValidator(data);
    goto(routes.RESOURCES_AGW1_API_VALIDATOR, { restApiId, requestValidatorId });
  };

  return (
    <Layout
      title={
        <Box>
          <Typography variant="h4">Stage Details</Typography>
          <Breadcrumbs
            mappings={[
              ['API Gateway', () => goto(routes.RESOURCES_AGW1)],
              [api?.name, () => goto(routes.RESOURCES_AGW1_API, { restApiId })],
              [validator?.name, () => goto(routes.RESOURCES_AGW1_API_VALIDATOR, { restApiId, requestValidatorId })],
              [requestValidatorId ? 'Update' : 'Create Validator', null],
            ]}
          />
        </Box>
      }
      tabs={<RestApiNavTabs restApiId={restApiId as string} routes={routes} />}
      actions={
        <ProgressButton type="submit" form="update" variant="outlined" color="primary" loading={isLoading}>
          Submit
        </ProgressButton>
      }
    >
      <Card>
        <CardContent>
          <AgwRestApiValidatorForm
            restApiId={restApiId as string}
            validator={validator}
            onCreate={handleCreate}
            onUpdate={handleUpdate}
            loading={isLoading}
            formId="update"
          />
        </CardContent>
        <CardActions>
          <ProgressButton type="submit" form="update" variant="contained" color="primary" loading={isLoading}>
            Submit
          </ProgressButton>
        </CardActions>
      </Card>
    </Layout>
  );
};
