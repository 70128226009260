import { getSchema } from '@localstack/services';
import { DMSReplicationTask } from '@localstack/types';
import { ReactElement } from 'react';

import { ListItem } from '@mui/material';

import { MagicDetails } from '../../magic/MagicDetails';
import { CodeSnippetViewer } from '../../../display/CodeSnippetViewer';

export type DMSReplicationTaskDetailProps = {
  replicationTask?: Optional<Partial<DMSReplicationTask>>;
};

export const DMSReplicationTaskDetail = ({ replicationTask }: DMSReplicationTaskDetailProps): ReactElement => (
  <MagicDetails
    data={replicationTask}
    schema={getSchema('DMS')}
    entry="ReplicationTask"
    title="DMS Replication Task Details"
    order={['Status', 'StopReason', 'LastFailureMessage']}
    externalFields={{
      '^ReplicationTaskSettings$': (data: string, fieldName: string) => (
        <ListItem key={fieldName}>
          <CodeSnippetViewer data={data} fieldName={fieldName} />
        </ListItem>
      ),
      '^TableMappings$': (data: string, fieldName: string) => (
        <ListItem key={fieldName}>
          <CodeSnippetViewer data={data} fieldName={fieldName} />
        </ListItem>
      ),
    }}
  />
);
