import { ReactElement } from 'react';
import { useRoutes, useAwsEffect, useAwsGetter } from '@localstack/services';
import { useParams } from 'react-router-dom';
import { Card, CardContent, CardActions, Box, Typography } from '@mui/material';
import { CreateAppSyncFunctionRequest, UpdateAppSyncFunctionRequest } from '@localstack/types';
import { ProgressButton, AppSyncFunctionForm, Breadcrumbs } from '@localstack/ui';

import { DEFAULT_APPSYNC_ROUTES } from '@localstack/constants';

import { NavTabs } from './components';
import { AppSyncProps } from './types';

export const AppSyncApiFunctionUpsert = ({
  Layout,
  clientOverrides,
  routes = DEFAULT_APPSYNC_ROUTES,
}: AppSyncProps): ReactElement => {
  const { goto } = useRoutes();
  const { apiId, functionId } = useParams<'apiId' | 'functionId'>();

  const { createFunction, updateFunction, isLoading } = useAwsEffect('AppSync', ['createFunction', 'updateFunction'], {
    revalidate: ['listFunctions', 'getFunction'],
  });

  const { data: func } = useAwsGetter('AppSync', 'getFunction', [{ apiId, functionId }], { clientOverrides });
  const { data: dataSources } = useAwsGetter('AppSync', 'listDataSources', [{ apiId }], { clientOverrides });

  const handleCreate = async (data: CreateAppSyncFunctionRequest) => {
    await createFunction(data);
    goto(routes.RESOURCES_APPSYNC_API_FUNCTIONS, { apiId });
  };

  const handleUpdate = async (data: UpdateAppSyncFunctionRequest) => {
    await updateFunction(data);
    goto(routes.RESOURCES_APPSYNC_API_FUNCTION, { apiId, functionId });
  };

  return (
    <Layout
      documentTitle="Function Details"
      title={
        <Box>
          <Typography variant="h4">Function Details</Typography>
          <Breadcrumbs
            mappings={[
              ['AppSync', () => goto(routes.RESOURCES_APPSYNC)],
              [apiId, () => goto(routes.RESOURCES_APPSYNC_API, { apiId })],
              [
                func?.functionConfiguration?.name,
                () => goto(routes.RESOURCES_APPSYNC_API_FUNCTION, { apiId, functionId }),
              ],
              [functionId ? 'Update' : 'Create Function', null],
            ]}
          />
        </Box>
      }
      tabs={<NavTabs apiId={apiId as string} routes={routes} />}
      actions={
        <ProgressButton type="submit" form="update" variant="outlined" color="primary" loading={isLoading}>
          Submit
        </ProgressButton>
      }
    >
      <Card>
        <CardContent>
          <AppSyncFunctionForm
            apiId={apiId as string}
            dataSources={dataSources?.dataSources ?? []}
            func={func?.functionConfiguration}
            onCreate={handleCreate}
            onUpdate={handleUpdate}
            clientOverrides={clientOverrides}
            loading={isLoading}
            formId="update"
          />
        </CardContent>
        <CardActions>
          <ProgressButton type="submit" form="update" variant="contained" color="primary" loading={isLoading}>
            Submit
          </ProgressButton>
        </CardActions>
      </Card>
    </Layout>
  );
};
