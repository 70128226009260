import { ConfirmableButton } from '@localstack/ui';
import { Box, Card, CardContent, CardHeader, Grid, Typography, Theme, Skeleton } from '@mui/material';
import { ReactElement } from 'react';

import { LicenseService, useApiEffect, useApiGetter } from '@localstack/services';

import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';

import { ContainedCustomerLayout } from '~/layouts';

import { LicenseAssigned } from './components/LicenseAssigned';
import { CITokenList } from './components/CIAuthToken/CITokenList';
import { TokenChip } from './components/TokenChip';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    hypertext: {
      color: theme.palette.primary.main,
    },
    faded: {
      textColor: theme.palette.text.secondary,
      color: theme.palette.text.secondary,
    },
  }),
);

export const AuthTokens = (): ReactElement => {
  const classes = useStyles();

  const { data: authToken, isLoading: isAuthTokenLoading } = useApiGetter(LicenseService, 'getWorkspaceToken', []);
  const { rotateWorkspaceToken, isLoading: isResetAuthTokenIsLoading } = useApiEffect(
    LicenseService,
    ['rotateWorkspaceToken'],
    { revalidate: ['getWorkspaceToken'] },
  );

  const token = authToken?.token ?? '';
  const isLoading = isAuthTokenLoading || isResetAuthTokenIsLoading;

  return (
    <ContainedCustomerLayout title="Auth Tokens">
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Card>
            <CardHeader title={<Typography variant="h4">Personal Auth Token</Typography>} />
            <CardContent>
              <Box mb={2}>
                <Typography variant="body1">
                  Your personal auth token can be used to access your workspace data and activate LocalStack.
                </Typography>
              </Box>
              <Box>{isLoading ? <Skeleton /> : <TokenChip token={token} />}</Box>
              <Box mt={1} display="flex" flexDirection="row" flexWrap="wrap">
                <Typography variant="body1" className={classes.faded}>
                  Anyone who has it can authenticate with your account and access your workspace data as if they were
                  you. If that happens, <span> </span>
                  <ConfirmableButton
                    title="Are you sure you want to regenerate your personal token?"
                    text=" Regenerating will generate a new personal auth token and render the
                      current token unusable."
                    okText="Regenerate Personal Token"
                    cancelText="Cancel"
                    componentType="Link"
                    onClick={() => rotateWorkspaceToken()}
                  >
                    <Typography variant="body1" className={classes.hypertext} display="inline-flex">
                      regenerate this token
                    </Typography>
                  </ConfirmableButton>
                </Typography>
              </Box>
              <Box mt={2}>
                <LicenseAssigned />
              </Box>
            </CardContent>
          </Card>
        </Grid>
        <CITokenList />
      </Grid>
    </ContainedCustomerLayout>
  );
};
