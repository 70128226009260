import { Box, Container, styled, Typography, useTheme, IconButton } from '@mui/material';
import { ReactElement } from 'react';

import { BaseHeaderProps } from '@localstack/types';

import { DockToRightIcon as MenuIcon } from '../../icons';

import { useNavigation } from '../../provider/NavbarContext';

import { PlanChip } from '../../feedback/PlanChip';

const HeaderWrapper = styled('div')(({ theme }) => ({
  paddingTop: theme.spacing(2.5),
  paddingBottom: theme.spacing(2.5),
  borderColor: `${theme.palette.divider} !important`,
  borderBottom: '1px solid',
  backgroundColor: theme.palette.background.default,
}));

const Actions = styled('div')(({ theme }) => ({
  flexGrow: 1,
  display: 'flex',
  zIndex: 1,
  alignItems: 'center',
  gap: theme.spacing(1),
  justifyContent: 'right',
}));

const HeaderTabs = styled('div')(({ theme }) => ({
  borderBottom: '1px solid',
  color: theme.palette.divider,
  borderColor: theme.palette.divider,
  paddingTop: theme.spacing(2),
  paddingBottom: theme.spacing(2),
}));

export const BaseHeader = ({
  title,
  planFamily,
  actions,
  children,
  maxWidth,
  fullScreen,
  tabs,
}: BaseHeaderProps): ReactElement => {
  const containerMaxWidth = fullScreen ? false : maxWidth;
  const theme = useTheme();

  const { openNav, isMobile } = useNavigation();

  return (
    <>
      <HeaderWrapper data-name="in-page navigation and actions">
        <Container maxWidth={containerMaxWidth}>
          <Box display="flex" justifyContent="space-between" alignItems="center" justifyItems="right">
            {isMobile && (
              <IconButton onClick={openNav} size="large" aria-label="Open navigation">
                <MenuIcon sx={{ color: theme.palette.text.primary }} />
              </IconButton>
            )}
            {typeof title === 'string' ? (
              <Typography variant="h4">
                {title}
                {planFamily && <PlanChip planFamily={planFamily} showTooltip />}
              </Typography>
            ) : (
              title
            )}
            <Actions>{actions}</Actions>
          </Box>
        </Container>
      </HeaderWrapper>
      {tabs && (
        <HeaderTabs id="tabs">
          <Container
            maxWidth={containerMaxWidth}
            sx={{
              display: 'flex',
              alignItems: 'center',
              gap: 1,
            }}
          >
            {tabs}
          </Container>
        </HeaderTabs>
      )}
      {children}
    </>
  );
};
