import { ReactElement } from 'react';
import { NavLink } from '@localstack/ui';
import { buildRoute } from '@localstack/services';

import { AppRoute } from '~/config';

export const NavTabs = (): ReactElement => (
  <>
    <NavLink to={buildRoute(AppRoute.QUICKSTART)} end>
      Cloud Pods
    </NavLink>
    <NavLink to={buildRoute(AppRoute.QUICKSTARTCLOUDFORMATION)} end>
      Cloud Formation
    </NavLink>
  </>
);
