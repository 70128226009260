import { ReactElement } from 'react';
import { ChevronLeft, Apartment, PieChart, Group, IntegrationInstructions, Podcasts } from '@mui/icons-material';
import { buildRoute } from '@localstack/services';

import { AppRoute } from '~/config';

import { NavbarContainer, NavbarSection, SectionItem, SectionHeader } from './shared';
import { NavbarCloseButton } from './NavbarCloseButton';

export const AdminNavbar = (): ReactElement => (
  <NavbarContainer>
    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
      <SectionItem label="Back to app" icon={ChevronLeft} to={AppRoute.DASHBOARD} />
      <NavbarCloseButton />
    </div>

    <NavbarSection data-name="administration sub navigation">
      <SectionHeader>Administration</SectionHeader>
      <SectionItem label="Overview" to={buildRoute(AppRoute.ADMIN_HOME)} end icon={PieChart}></SectionItem>
      <SectionItem label="Users" to={buildRoute(AppRoute.ADMIN_ACCOUNTS)} icon={Group}></SectionItem>
      <SectionItem label="Workspaces" to={buildRoute(AppRoute.ADMIN_ORGANIZATIONS)} icon={Apartment}></SectionItem>
      <SectionItem
        label="Extensions"
        to={buildRoute(AppRoute.ADMIN_EXTENSIONS)}
        icon={IntegrationInstructions}
      ></SectionItem>
      <SectionItem label="Announcements" to={buildRoute(AppRoute.ADMIN_ANNOUNCEMENT)} icon={Podcasts}></SectionItem>
    </NavbarSection>
  </NavbarContainer>
);
