import { ReactElement } from 'react';
import { useRoutes, useAwsGetter } from '@localstack/services';
import { useParams } from 'react-router-dom';
import { Card, CardContent, Button, Box, Typography } from '@mui/material';
import { Breadcrumbs, GlueRegistryDetails as Details } from '@localstack/ui';
import { DEFAULT_GLUE_ROUTES } from '@localstack/constants';

import { GlueProps } from './types';
import { MainNavTabs } from './components';

export const GlueRegistryDetails = ({
  Layout,
  clientOverrides,
  routes = DEFAULT_GLUE_ROUTES,
}: GlueProps): ReactElement => {
  const { goto } = useRoutes();
  const { registry } = useParams<'registry'>();

  const { data: RegistryData } = useAwsGetter('Glue', 'getRegistry', [{ RegistryId: { RegistryName: registry } }], {
    clientOverrides,
  });

  return (
    <Layout
      documentTitle="Glue: Schema Registry Details"
      tabs={<MainNavTabs routes={routes} />}
      title={
        <Box>
          <Typography variant="h4">Schema Registry Details</Typography>
          <Breadcrumbs
            mappings={[
              ['Glue', () => goto(routes.RESOURCES_GLUE_DATABASES)],
              ['Schema Registries', () => goto(routes.RESOURCES_GLUE_REGISTRIES)],
              [registry, null],
            ]}
          />
        </Box>
      }
      actions={<Button onClick={() => goto(routes.RESOURCES_GLUE_REGISTRY_UPDATE, { registry })}>Edit Registry</Button>}
    >
      <Card>
        <CardContent>
          <Details registry={RegistryData} />
        </CardContent>
      </Card>
    </Layout>
  );
};
