import { ReactElement } from 'react';
import { CloudTrail } from '@localstack/types';
import { getSchema } from '@localstack/services';

import { MagicDetails } from '../../../magic/MagicDetails';

export interface CloudTrailDetailsProps {
  trail: Optional<Partial<CloudTrail>>;
}

export const CloudTrailDetails = ({ trail }: CloudTrailDetailsProps): ReactElement => (
  <MagicDetails data={trail} schema={getSchema('CloudTrail')} entry="Trail" title="Trail Details" />
);
