import { Components, Theme } from '@mui/material';

// Hubspot Widget

export interface HubspotWindowInterface {
  hsConversationsOnReady: [() => void];
  hsConversationsSettings: {
    loadImmediately?: boolean;
    identificationEmail?: string;
    identificationToken?: string;
  };
  HubSpotConversations: {
    clear: (params: { resetWidget: boolean }) => void;
    widget: {
      remove: () => void;
      load: () => void;
      open: () => void;
      refresh: () => void;
      status: () => { loaded: boolean };
    };
  };
  __hsUserToken: string;
  _hsq: {
    push: (params: string[]) => void;
  };
}

export type MuiThemeComponentsType = Components<Theme>;
