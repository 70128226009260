import { ReactElement } from 'react';
import { useRoutes, useAwsEffect, useAwsGetter } from '@localstack/services';
import { useParams } from 'react-router-dom';
import { Card, CardContent, CardActions, Box, Typography } from '@mui/material';
import { CreateClusterParams, UpdateClusterParams } from '@localstack/types';
import { ProgressButton, NeptuneClusterForm, Breadcrumbs } from '@localstack/ui';

import { DEFAULT_NEPTUNE_ROUTES } from '@localstack/constants';

import { NeptuneProps } from './types';

export const NeptuneDBClusterUpsert = ({
  Layout,
  clientOverrides,
  routes = DEFAULT_NEPTUNE_ROUTES,
}: NeptuneProps): ReactElement => {
  const { goto } = useRoutes();
  const { clusterId } = useParams<'clusterId'>();

  const { createDBCluster, modifyDBCluster, isLoading } = useAwsEffect(
    'Neptune',
    ['createDBCluster', 'modifyDBCluster'],
    { revalidate: ['describeDBClusters'], clientOverrides },
  );

  const { data: clusters } = useAwsGetter('Neptune', 'describeDBClusters', [{ DBClusterIdentifier: clusterId }], {
    clientOverrides,
  });
  const cluster = clusters?.DBClusters?.find((inst) => inst.DBClusterIdentifier === clusterId);

  const handleCreate = async (data: CreateClusterParams) => {
    if (await createDBCluster(data)) {
      goto(routes.RESOURCES_NEPTUNE_CLUSTERS);
    }
  };

  const handleUpdate = async (data: UpdateClusterParams) => {
    await modifyDBCluster(data);
    goto(routes.RESOURCES_NEPTUNE_CLUSTER, { clusterId });
  };

  return (
    <Layout
      documentTitle="cluster Details"
      title={
        <Box>
          <Typography variant="h4">Cluster Details</Typography>
          <Breadcrumbs
            mappings={[
              ['Neptune', () => goto(routes.RESOURCES_NEPTUNE_CLUSTERS)],
              [
                clusterId ? `${clusterId} (${cluster?.DBClusterIdentifier})` : null,
                () => goto(routes.RESOURCES_NEPTUNE_CLUSTER, { clusterId }),
              ],
              [clusterId ? 'Update' : 'Create Cluster', null],
            ]}
          />
        </Box>
      }
      actions={
        <ProgressButton type="submit" form="update" variant="outlined" color="primary" loading={isLoading}>
          Submit
        </ProgressButton>
      }
    >
      <Card>
        <CardContent>
          <NeptuneClusterForm
            cluster={cluster}
            onCreate={handleCreate}
            onUpdate={handleUpdate}
            loading={isLoading}
            formId="update"
          />
        </CardContent>
        <CardActions>
          <ProgressButton type="submit" form="update" variant="contained" color="primary" loading={isLoading}>
            Submit
          </ProgressButton>
        </CardActions>
      </Card>
    </Layout>
  );
};
