import { ReactElement } from 'react';
import { useParams } from 'react-router-dom';
import { Card, CardContent, CardActions, Box, Typography } from '@mui/material';
import { CreateKafkaClusterParams, UpdateKafkaClusterConfigurationParams } from '@localstack/types';
import { useRoutes, useAwsEffect, useAwsGetter } from '@localstack/services';
import { ProgressButton, KafkaClusterForm, Breadcrumbs } from '@localstack/ui';

import { DEFAULT_KAFKA_ROUTES } from '@localstack/constants';

import { KafkaProps } from './types';

export const KafkaClusterUpsert = ({
  Layout,
  clientOverrides,
  routes = DEFAULT_KAFKA_ROUTES,
}: KafkaProps): ReactElement => {
  const { goto } = useRoutes();
  const { clusterArn } = useParams<'clusterArn'>();

  const { createCluster, updateClusterConfiguration, isLoading } = useAwsEffect(
    'Kafka',
    ['createCluster', 'updateClusterConfiguration'],
    { revalidate: ['listClusters', 'describeCluster'], clientOverrides },
  );

  const { data: cluster } = useAwsGetter('Kafka', 'describeCluster', [{ ClusterArn: clusterArn }], { clientOverrides });

  const handleCreate = async (data: CreateKafkaClusterParams) => {
    await createCluster(data);
    goto(routes.RESOURCES_KAFKA_CLUSTERS);
  };

  const handleUpdate = async (data: UpdateKafkaClusterConfigurationParams) => {
    await updateClusterConfiguration(data);
    goto(routes.RESOURCES_KAFKA_CLUSTER, { clusterArn });
  };

  return (
    <Layout
      documentTitle="Cluster Details"
      title={
        <Box>
          <Typography variant="h4">Cluster Details</Typography>
          <Breadcrumbs
            mappings={[
              ['Kafka', () => goto(routes.RESOURCES_KAFKA)],
              ['Clusters', () => goto(routes.RESOURCES_KAFKA_CLUSTERS)],
              [
                cluster?.ClusterInfo?.ClusterName ?? clusterArn,
                () => goto(routes.RESOURCES_KAFKA_CLUSTER, { clusterArn }),
              ],
              [clusterArn ? 'Update' : 'Create Cluster', null],
            ]}
          />
        </Box>
      }
      actions={
        <ProgressButton type="submit" form="update" variant="outlined" color="primary" loading={isLoading}>
          Submit
        </ProgressButton>
      }
    >
      <Card>
        <CardContent>
          <KafkaClusterForm
            cluster={cluster?.ClusterInfo}
            clientOverrides={clientOverrides}
            onCreate={handleCreate}
            onUpdate={handleUpdate}
            loading={isLoading}
            formId="update"
          />
        </CardContent>
        <CardActions>
          <ProgressButton type="submit" form="update" variant="contained" color="primary" loading={isLoading}>
            Submit
          </ProgressButton>
        </CardActions>
      </Card>
    </Layout>
  );
};
