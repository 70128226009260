import { Theme } from '@mui/material';

import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';

interface StyleProps {
  disableAndShowCta?: boolean;
}

export const useExperimentCardStyles = makeStyles<Theme, StyleProps>((theme: Theme) =>
  createStyles({
    card: {
      marginTop: theme.spacing(1.5),
      padding: theme.spacing(3),
    },
    heading: {
      fontWeight: 'bold',
      opacity: ({ disableAndShowCta }) => (disableAndShowCta ? 0.5 : 1),
    },
    description: {
      opacity: ({ disableAndShowCta }) => (disableAndShowCta ? 0.75 : 1),
    },
    controlsAndTemplate: {
      opacity: ({ disableAndShowCta }) => (disableAndShowCta ? 0.25 : 1),
      pointerEvents: ({ disableAndShowCta }) => (disableAndShowCta ? 'none' : 'all'),
      userSelect: ({ disableAndShowCta }) => (disableAndShowCta ? 'none' : 'all'),
    },
    secondaryBtn: {
      borderColor: theme.palette.error.main,
      color: theme.palette.error.main,
      fontWeight: 'bold',
    },
    running: {
      backgroundColor: theme.palette.success.main,
      color: theme.palette.success.contrastText,
    },
  }),
);
