import { SERVICE_NAME } from '@localstack/types';

import { PATH_PREFIX_RESOURCES } from './base';

const GLUE_BASE_ROUTE = `${PATH_PREFIX_RESOURCES}/${SERVICE_NAME.GLUE}`;

export const DEFAULT_GLUE_ROUTES: Record<string, string> = {
  RESOURCES_GLUE: `${GLUE_BASE_ROUTE}`,
  RESOURCES_GLUE_DATABASES: `${GLUE_BASE_ROUTE}/databases`,
  RESOURCES_GLUE_DATABASE_CREATE: `${GLUE_BASE_ROUTE}/databases/new`,
  RESOURCES_GLUE_TABLES: `${GLUE_BASE_ROUTE}/tables`,
  RESOURCES_GLUE_DATABASE_TABLES: `${GLUE_BASE_ROUTE}/tables/database/:database`,
  RESOURCES_GLUE_TABLE: `${GLUE_BASE_ROUTE}/tables/:table/database/:database`,
  RESOURCES_GLUE_DATABASE_TABLE_CREATE: `${GLUE_BASE_ROUTE}/tables/database/:database/new`,
  RESOURCES_GLUE_TABLE_CREATE: `${GLUE_BASE_ROUTE}/tables/new`,
  RESOURCES_GLUE_TABLE_UPDATE: `${GLUE_BASE_ROUTE}/tables/:table/database/:database/update`,
  RESOURCES_GLUE_TABLE_PARTITIONS: `${GLUE_BASE_ROUTE}/tables/:table/database/:database/partitions/:partitions`,
  RESOURCES_GLUE_CONNECTIONS: `${GLUE_BASE_ROUTE}/connections`,
  RESOURCES_GLUE_CONNECTION_CREATE: `${GLUE_BASE_ROUTE}/connections/new`,
  RESOURCES_GLUE_CONNECTION_UPDATE: `${GLUE_BASE_ROUTE}/connections/:connection/update`,
  RESOURCES_GLUE_CONNECTION: `${GLUE_BASE_ROUTE}/connections/:connection`,
  RESOURCES_GLUE_CRAWLERS: `${GLUE_BASE_ROUTE}/crawlers`,
  RESOURCES_GLUE_CRAWLER_CREATE: `${GLUE_BASE_ROUTE}/crawlers/new`,
  RESOURCES_GLUE_CRAWLER_UPDATE: `${GLUE_BASE_ROUTE}/crawlers/:crawler/update`,
  RESOURCES_GLUE_CRAWLER: `${GLUE_BASE_ROUTE}/crawlers/:crawler`,
  RESOURCES_GLUE_JOBS: `${GLUE_BASE_ROUTE}/jobs`,
  RESOURCES_GLUE_JOB_CREATE: `${GLUE_BASE_ROUTE}/jobs/new`,
  RESOURCES_GLUE_JOB_UPDATE: `${GLUE_BASE_ROUTE}/jobs/:job/update`,
  RESOURCES_GLUE_JOB: `${GLUE_BASE_ROUTE}/jobs/:job`,
  RESOURCES_GLUE_JOB_RUNS: `${GLUE_BASE_ROUTE}/jobs/:job/runs`,
  RESOURCES_GLUE_JOB_RUN: `${GLUE_BASE_ROUTE}/jobs/:job/runs/:run`,
  RESOURCES_GLUE_REGISTRIES: `${GLUE_BASE_ROUTE}/registries`,
  RESOURCES_GLUE_REGISTRY_CREATE: `${GLUE_BASE_ROUTE}/registries/new`,
  RESOURCES_GLUE_REGISTRY_UPDATE: `${GLUE_BASE_ROUTE}/registries/:registry/update`,
  RESOURCES_GLUE_REGISTRY: `${GLUE_BASE_ROUTE}/registries/:registry`,
  RESOURCES_GLUE_SCHEMAS: `${GLUE_BASE_ROUTE}/schemas`,
  RESOURCES_GLUE_SCHEMA_CREATE: `${GLUE_BASE_ROUTE}/schemas/new`,
  RESOURCES_GLUE_SCHEMA_UPDATE: `${GLUE_BASE_ROUTE}/registry/:registry/schemas/:schema/update`,
  RESOURCES_GLUE_SCHEMA: `${GLUE_BASE_ROUTE}/registry/:registry/schemas/:schema`,
  RESOURCES_GLUE_SCHEMA_VERSIONS: `${GLUE_BASE_ROUTE}/registry/:registry/schemas/:schema/versions`,
  RESOURCES_GLUE_SCHEMA_VERSION_CREATE: `${GLUE_BASE_ROUTE}/registry/:registry/schemas/:schema/versions/:latestVersion/new`,
  RESOURCES_GLUE_SCHEMA_VERSION: `${GLUE_BASE_ROUTE}/registry/:registry/schemas/:schema/versions/:version`,
  RESOURCES_GLUE_SCHEMA_VERSION_METADATA: `${GLUE_BASE_ROUTE}/registry/:registry/schemas/:schema/versions/:version/metadata`,
  RESOURCES_GLUE_SCHEMA_VERSION_METADATA_CREATE: `${GLUE_BASE_ROUTE}/registry/:registry/schemas/:schema/versions/:version/metadata/create`,
  RESOURCES_GLUE_SCHEMA_VERSION_METADATA_UPDATE: `${GLUE_BASE_ROUTE}/registry/:registry/schemas/:schema/versions/:version/metadata/:metadataKey/:metadataValue/update`,
};
