import { useState, useEffect, ReactElement } from 'react';
import { useRoutes, useAwsGetter, useAwsEffect } from '@localstack/services';
import { useParams } from 'react-router-dom';
import { Card, CardContent, Box, Typography, CardActions, Alert } from '@mui/material';
import { Breadcrumbs, CodeEditor, ProgressButton } from '@localstack/ui';

import { DEFAULT_APPSYNC_ROUTES } from '@localstack/constants';

import { NavTabs } from './components';
import { AppSyncProps } from './types';

const SCHEMA_FORMAT = 'SDL';
const POLLING_INTERVAL = 2000;

export const AppSyncApiSchema = ({
  Layout,
  clientOverrides,
  routes = DEFAULT_APPSYNC_ROUTES,
}: AppSyncProps): ReactElement => {
  const { goto } = useRoutes();
  const { apiId } = useParams<'apiId'>();

  const [definition, setDefinition] = useState('');

  const { data: schema } = useAwsGetter('AppSync', 'getIntrospectionSchema', [{ apiId, format: SCHEMA_FORMAT }], {
    clientOverrides,
  });
  const { startSchemaCreation } = useAwsEffect('AppSync', ['startSchemaCreation'], { clientOverrides });

  const { data: creationStatus } = useAwsGetter('AppSync', 'getSchemaCreationStatus', [{ apiId }], {
    swrOverrides: { refreshInterval: POLLING_INTERVAL },
    clientOverrides,
  });

  const isUpdating = creationStatus?.status === 'PROCESSING';
  const isError = creationStatus?.status === 'FAILED';

  useEffect(() => {
    if (!schema?.schema) return;
    setDefinition(schema.schema.toString('utf-8'));
  }, [schema]);

  return (
    <Layout
      documentTitle="API Schema"
      tabs={<NavTabs apiId={apiId as string} routes={routes} />}
      title={
        <Box>
          <Typography variant="h4">API Schema</Typography>
          <Breadcrumbs
            mappings={[
              ['AppSync', () => goto(routes.RESOURCES_APPSYNC)],
              [apiId, () => goto(routes.RESOURCES_APPSYNC_API, { apiId })],
              ['Schema', null],
            ]}
          />
        </Box>
      }
      actions={
        <ProgressButton
          variant="outlined"
          color="primary"
          onClick={() => startSchemaCreation({ apiId: apiId as string, definition })}
          loading={isUpdating}
        >
          Update
        </ProgressButton>
      }
    >
      <Card>
        {isError && <Alert severity="error">There was an error while updating the schema</Alert>}
        <CardContent>
          <CodeEditor value={definition} setValue={setDefinition} language="graphql" />
        </CardContent>
        <CardActions>
          <ProgressButton
            variant="contained"
            color="primary"
            onClick={() => startSchemaCreation({ apiId: apiId as string, definition })}
            loading={isUpdating}
          >
            Update
          </ProgressButton>
        </CardActions>
      </Card>
    </Layout>
  );
};
