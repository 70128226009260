import { ReactElement } from 'react';
import { Link } from '@mui/material';
import { AgwApiAuthorizer } from '@localstack/types';
import { getSchema } from '@localstack/services';

import { MagicTable } from '../../../magic/MagicTable';

export type AgwApiAuthorizersTableProps = {
  selectable?: boolean;
  loading?: boolean;
  authorizers: AgwApiAuthorizer[];
  onViewAuthorizer?: (authorizer: AgwApiAuthorizer) => unknown;
  onSelect?: (names: string[]) => void;
};

export const AgwApiAuthorizersTable = ({
  authorizers,
  selectable = true,
  loading,
  onViewAuthorizer,
  onSelect,
}: AgwApiAuthorizersTableProps): ReactElement => (
  <MagicTable
    schema={getSchema('ApiGatewayV2')}
    entry="Authorizer"
    idAttribute="AuthorizerId"
    rows={authorizers}
    selectable={selectable}
    onSelect={onSelect}
    order={['AuthorizerId', 'Name']}
    loading={loading}
    externalFields={{
      AuthorizerId: (row) => (
        <Link onClick={() => onViewAuthorizer && onViewAuthorizer(row)} underline="hover">
          {row.AuthorizerId}
        </Link>
      ),
    }}
  />
);
