import { ReactElement, useCallback, useState } from 'react';
import { useRoutes, useAwsGetter, useAwsEffect } from '@localstack/services';
import { Card, Button } from '@mui/material';
import { Dropdown, ConfirmableButton, PageTitle, GlueSchemasTable } from '@localstack/ui';
import { DEFAULT_GLUE_ROUTES } from '@localstack/constants';

import { GlueProps } from './types';
import { MainNavTabs } from './components';

export const GlueSchemas = ({ Layout, clientOverrides, routes = DEFAULT_GLUE_ROUTES }: GlueProps): ReactElement => {
  const [selected, setSelected] = useState<string[]>([]);

  const { goto } = useRoutes();

  const { data: schemas, isLoading, mutate } = useAwsGetter('Glue', 'listSchemas', [], { clientOverrides });

  const { deleteSchema } = useAwsEffect('Glue', ['deleteSchema'], { revalidate: ['listSchemas'], clientOverrides });

  const handleDeleteSelected = useCallback(
    async () =>
      Promise.all(
        selected.map((schema) =>
          deleteSchema({
            SchemaId: {
              SchemaName: schema,
              RegistryName: schemas?.Schemas?.find((item) => item.SchemaName === schema)?.RegistryName,
            },
          }),
        ),
      ),
    [selected],
  );

  return (
    <Layout
      documentTitle="Glue: Schemas"
      title={
        <PageTitle
          title="Glue Schemas"
          breadcrumbs={[
            ['Glue', () => goto(routes.RESOURCES_GLUE_DATABASES)],
            ['Schemas', () => goto(routes.RESOURCES_GLUE_SCHEMAS)],
          ]}
          onMutate={mutate}
        />
      }
      tabs={<MainNavTabs routes={routes} />}
      actions={
        <>
          <Button onClick={() => goto(routes.RESOURCES_GLUE_SCHEMA_CREATE)}>Create Schema</Button>
          <Dropdown label="Actions">
            <ConfirmableButton
              componentType="MenuItem"
              disabled={selected.length === 0 || isLoading}
              title={`Delete ${selected.length} Schema(s)?`}
              onClick={handleDeleteSelected}
              text="Selected Schema(s) will be permanently deleted"
            >
              Remove Selected
            </ConfirmableButton>
          </Dropdown>
        </>
      }
    >
      <Card>
        <GlueSchemasTable
          schemas={schemas?.Schemas ?? []}
          loading={isLoading}
          onSelect={setSelected}
          onViewSchema={(schema, registry) => goto(routes.RESOURCES_GLUE_SCHEMA, { schema, registry })}
        />
      </Card>
    </Layout>
  );
};
