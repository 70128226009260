import { ReactElement } from 'react';
import { useRoutes, useAwsGetter } from '@localstack/services';
import { useParams } from 'react-router-dom';
import { Card, CardContent, Button, Box, Typography, MenuItem } from '@mui/material';
import { Breadcrumbs, AgwApiDetails as Details, Dropdown } from '@localstack/ui';
import { DEFAULT_API_GATEWAY_ROUTES } from '@localstack/constants';

import { ApiGatewayProps } from './types';
import { ApiNavTabs } from './components';

export const AgwApiDetails = ({
  Layout,
  clientOverrides,
  routes = DEFAULT_API_GATEWAY_ROUTES,
}: ApiGatewayProps): ReactElement => {
  const { goto } = useRoutes();
  const { apiId: ApiId } = useParams<'apiId'>();

  const { data: api } = useAwsGetter('ApiGatewayV2', 'getApi', [{ ApiId }], { clientOverrides });

  return (
    <Layout
      documentTitle="API Details"
      tabs={<ApiNavTabs apiId={ApiId as string} routes={routes} />}
      title={
        <Box>
          <Typography variant="h4">API Details</Typography>
          <Breadcrumbs
            mappings={[
              ['API Gateway', () => goto(routes.RESOURCES_AGW2)],
              [api?.Name, null],
            ]}
          />
        </Box>
      }
      actions={
        <>
          <Button onClick={() => goto(routes.RESOURCES_AGW2_API_UPDATE, { apiId: ApiId })}>Edit API</Button>
          <Dropdown label="Actions">
            <MenuItem onClick={() => goto(routes.RESOURCES_AGW2_API_DEPLOY, { apiId: ApiId })}>Deploy API</MenuItem>
          </Dropdown>
        </>
      }
    >
      <Card>
        <CardContent>
          <Details api={api} />
        </CardContent>
      </Card>
    </Layout>
  );
};
