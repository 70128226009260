import { ReactElement } from 'react';
import { IAMRole } from '@localstack/types';
import { getSchema } from '@localstack/services';

import { MagicDetails } from '../../../magic/MagicDetails';

export interface IAMRoleDetailsProps {
  iamRole?: Optional<Partial<IAMRole>>;
}

export const IAMRoleDetails = ({ iamRole }: IAMRoleDetailsProps): ReactElement => (
  <MagicDetails data={iamRole} schema={getSchema('IAM')} entry="Role" title="Role Details" />
);
