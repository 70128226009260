import { ReactElement, useState, useCallback } from 'react';
import { useRoutes, useAwsEffect, useAwsGetter } from '@localstack/services';
import { DEFAULT_API_GATEWAY_ROUTES } from '@localstack/constants';
import { Card, Button } from '@mui/material';
import { useParams } from 'react-router-dom';

import { PageTitle, Dropdown, ConfirmableButton, AgwRestApiValidatorsTable } from '@localstack/ui';

import { ApiGatewayProps } from './types';
import { RestApiNavTabs } from './components';

export const AgwRestApiValidators = ({
  Layout,
  clientOverrides,
  routes = DEFAULT_API_GATEWAY_ROUTES,
}: ApiGatewayProps): ReactElement => {
  const { restApiId } = useParams<'restApiId'>();
  const [selectedIds, setSelectedIds] = useState<string[]>([]);

  const { goto } = useRoutes();
  const { data: api } = useAwsGetter('APIGateway', 'getRestApi', [{ restApiId }], { clientOverrides });

  const {
    data: validators,
    isLoading,
    mutate,
  } = useAwsGetter('APIGateway', 'getRequestValidators', [{ restApiId }], { clientOverrides });

  const { deleteRequestValidator } = useAwsEffect('APIGateway', ['deleteRequestValidator'], {
    revalidate: ['getRequestValidators', 'getRequestValidator'],
    clientOverrides,
  });

  const handleDeleteSelected = useCallback(async () => {
    const promises = selectedIds.map((requestValidatorId) =>
      deleteRequestValidator({ restApiId: restApiId as string, requestValidatorId }),
    );
    await Promise.all(promises);
  }, [selectedIds]);

  return (
    <Layout
      documentTitle="Request Validators"
      title={
        <PageTitle
          title="Request Validators"
          onMutate={mutate}
          breadcrumbs={[
            ['API Gateway', () => goto(routes.RESOURCES_AGW1)],
            [api?.name, () => goto(routes.RESOURCES_AGW1_API, { restApiId })],
            ['Request Validators', null],
          ]}
        />
      }
      tabs={<RestApiNavTabs restApiId={restApiId as string} routes={routes} />}
      actions={
        <>
          <Button onClick={() => goto(routes.RESOURCES_AGW1_API_VALIDATOR_CREATE, { restApiId })}>
            Create Validator
          </Button>
          <Dropdown label="Actions">
            <ConfirmableButton
              componentType="MenuItem"
              disabled={selectedIds.length === 0 || isLoading}
              title={`Delete ${selectedIds.length} Validator(s)?`}
              onClick={handleDeleteSelected}
              text="Selected Validators will be permanently deleted"
            >
              Remove Selected
            </ConfirmableButton>
          </Dropdown>
        </>
      }
    >
      <Card>
        <AgwRestApiValidatorsTable
          validators={validators?.items || []}
          onSelect={setSelectedIds}
          onViewValidator={({ id }) => goto(routes.RESOURCES_AGW1_API_VALIDATOR, { restApiId, requestValidatorId: id })}
        />
      </Card>
    </Layout>
  );
};
