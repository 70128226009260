import React from 'react';
import { AlertProps } from '@mui/material';
import { DEFAULT_AWS_ACCOUNT_ID, DEFAULT_REGION, DEFAULT_RESOURCE_BASE_URL } from '@localstack/constants';
import { LocalStackInstance } from '@localstack/types';

export interface SnackbarMessage {
  message: string;
  severity?: Optional<AlertProps['severity']>;
  autoHideDuration?: Optional<number>;
}

export type GlobalStateContextInterface = {
  region: string;
  setRegion: (region: string) => void;
  awsAccountId: string;
  setAwsAccountId: (id: string) => void;
  // TODO unify `endpoint` and `instances`
  endpoint: string;
  setEndpoint: (endpoint: string) => void;
  instances: LocalStackInstance[];
  setInstances: (instances: LocalStackInstance[]) => void;
  showSnackbar: (msg: SnackbarMessage) => void;
  updateInstance: (instance: LocalStackInstance) => void;
};

export const GlobalStateContext = React.createContext<GlobalStateContextInterface>({
  region: DEFAULT_REGION,
  setRegion: () => undefined,
  awsAccountId: DEFAULT_AWS_ACCOUNT_ID,
  setAwsAccountId: () => undefined,
  endpoint: DEFAULT_RESOURCE_BASE_URL,
  setEndpoint: () => undefined,
  instances: [],
  setInstances: () => undefined,
  showSnackbar: () => undefined,
  updateInstance: () => undefined,
});
