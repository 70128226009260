import { ReactElement } from 'react';
import { getSchema } from '@localstack/services';

import { AthenaDataCatalog, CreateAthenaDataCatalogRequest, UpdateAthenaDataCatalogRequest } from '@localstack/types';

import { MagicForm } from '../../../magic/MagicForm';

export interface AthenaDataCatalogFormProps {
  catalog?: Optional<Partial<AthenaDataCatalog>>;
  loading?: boolean;
  formId?: string;
  onCreate: (data: CreateAthenaDataCatalogRequest) => unknown;
  onUpdate: (data: UpdateAthenaDataCatalogRequest) => unknown;
}

export const AthenaDataCatalogForm = ({
  catalog,
  loading,
  formId,
  onCreate,
  onUpdate,
}: AthenaDataCatalogFormProps): ReactElement => (
  <MagicForm
    data={catalog}
    schema={getSchema('Athena')}
    loading={loading}
    entry="CreateDataCatalogInput"
    formId={formId}
    fieldConditions={{
      '^Name': !catalog,
    }}
    onSubmit={(data: CreateAthenaDataCatalogRequest) => {
      if (!catalog) return onCreate(data);
      return onUpdate({ ...data, Name: catalog.Name as string });
    }}
  />
);
