import { ReactElement } from 'react';
import { Link } from '@mui/material';
import { CloudFrontDistributionList } from '@localstack/types';
import { getSchema } from '@localstack/services';

import { MagicTable } from '../../magic/MagicTable';

export type CloudFrontDistributionsTableProps = {
  selectable?: boolean;
  loading?: boolean;
  distributions: CloudFrontDistributionList[];
  onSelect?: (names: string[]) => void;
  onViewDistribution?: (distribution: CloudFrontDistributionList) => unknown;
};

export const CloudFrontDistributionsTable = ({
  distributions,
  selectable = true,
  loading,
  onSelect,
  onViewDistribution,
}: CloudFrontDistributionsTableProps): ReactElement => (
  <MagicTable
    schema={getSchema('CloudFront')}
    entry="DistributionSummary"
    idAttribute="Id"
    rows={distributions}
    selectable={selectable}
    onSelect={onSelect}
    order={['Id', 'ARN', 'Status']}
    loading={loading}
    externalFields={{
      Id: (row) => (
        <Link onClick={() => onViewDistribution && onViewDistribution(row)} underline="hover">
          {row.Id}
        </Link>
      ),
    }}
  />
);
