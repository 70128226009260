import { ReactElement } from 'react';

import { ContainedCustomerLayout } from '~/layouts';

import { Billing as AccountBilling } from './components';

export const Billing = (): ReactElement => (
  <ContainedCustomerLayout title="Billing">
    <AccountBilling />
  </ContainedCustomerLayout>
);
