import { ReactElement } from 'react';
import { QLDBDescribeLedgerResponse } from '@localstack/types';
import { getSchema } from '@localstack/services';

import { MagicDetails } from '../../magic/MagicDetails';

export interface QLDBLedgerDetailProps {
  ledger?: Optional<QLDBDescribeLedgerResponse>;
}

export const QLDBLedgerDetails = ({ ledger }: QLDBLedgerDetailProps): ReactElement => (
  <MagicDetails data={ledger} schema={getSchema('QLDB')} entry="DescribeLedgerResponse" title="Ledger Details" />
);
