import { getSchema } from '@localstack/services';
import { Link } from '@mui/material';
import { AmplifyApp } from '@localstack/types';
import { ReactElement } from 'react';

import { MagicTable } from '../../magic/MagicTable';

export type AmplifyAppsTableProps = {
  apps: AmplifyApp[];
  onViewApp: (id: string) => void;
  onSelect: (ids: string[]) => void;
  loading?: boolean;
};

export const AmplifyAppsTable = ({ apps, onViewApp, onSelect, loading }: AmplifyAppsTableProps): ReactElement => (
  <MagicTable
    entry="App"
    idAttribute="appId"
    rows={apps}
    schema={getSchema('Amplify')}
    externalFields={{
      appId: ({ appId }) => (
        <Link onClick={() => onViewApp(appId)} underline="hover">
          {appId}
        </Link>
      ),
    }}
    loading={loading}
    order={['appId']}
    onSelect={onSelect}
    selectable
    disableSelectionOnClick
  />
);
