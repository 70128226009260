import { ReactElement } from 'react';
import { CreateGlueTableRequest, GlueTable, UpdateGlueTableRequest } from '@localstack/types';
import { AwsClientOverrides, getSchema } from '@localstack/services';
import { DEFAULT_GLUE_ROUTES } from '@localstack/constants';

import { MagicForm } from '../../../magic/MagicForm';
import { RelatedResourcePicker } from '../../../../form/RelatedResourcePicker';

export type GlueTableFormProps = {
  table?: Optional<Partial<GlueTable>>;
  databaseName: Optional<string>;
  loading?: boolean;
  formId: string;
  clientOverrides?: AwsClientOverrides;
  onCreate: (data: CreateGlueTableRequest) => unknown;
  onUpdate: (data: UpdateGlueTableRequest) => unknown;
};

export const GlueTableForm = ({
  databaseName,
  table,
  loading,
  formId,
  clientOverrides,
  onCreate,
  onUpdate,
}: GlueTableFormProps): ReactElement => (
  <MagicForm
    data={{ ...table, TableInput: table, DatabaseName: databaseName }}
    schema={getSchema('Glue')}
    formId={formId}
    entry={table ? 'UpdateTableRequest' : 'CreateTableRequest'}
    loading={loading}
    fieldConditions={{
      '^DatabaseName': !databaseName,
      '^TableInput.Name': !table,
    }}
    externalFields={{
      '^DatabaseName$': (control, fieldName, required) => (
        <RelatedResourcePicker
          control={control}
          client="Glue"
          method="getDatabases"
          arrayKeyName="DatabaseList"
          property="Name"
          fieldName={fieldName}
          entityName="Glue Database"
          creationRoute={DEFAULT_GLUE_ROUTES.RESOURCES_GLUE_DATABASE_CREATE}
          required={required}
          clientOverrides={clientOverrides}
        />
      ),
      '^TableInput.StorageDescriptor.SchemaReference.SchemaId.SchemaArn$': (control, fieldName, required) => (
        <RelatedResourcePicker
          control={control}
          client="Glue"
          method="listSchemas"
          arrayKeyName="Schemas"
          property="SchemaArn"
          fieldName={fieldName}
          entityName="Glue Schema"
          creationRoute={DEFAULT_GLUE_ROUTES.RESOURCES_GLUE_SCHEMA_CREATE}
          required={required}
          clientOverrides={clientOverrides}
        />
      ),
      '^TableInput.StorageDescriptor.SchemaReference.SchemaId.RegistryName$': (control, fieldName, required) => (
        <RelatedResourcePicker
          control={control}
          client="Glue"
          method="listRegistries"
          arrayKeyName="Registries"
          property="RegistryName"
          fieldName={fieldName}
          entityName="Glue Schema"
          creationRoute={DEFAULT_GLUE_ROUTES.RESOURCES_GLUE_REGISTRY_CREATE}
          required={required}
          clientOverrides={clientOverrides}
        />
      ),
    }}
    onSubmit={(data: CreateGlueTableRequest) => {
      if (!table) return onCreate({ ...data });
      return onUpdate({
        ...data,
        TableInput: { ...data.TableInput, Name: table.Name as string },
      });
    }}
  />
);
