import { ReactElement } from 'react';

import { CloudFormationOutput } from '@localstack/types/src';
import { getSchema } from '@localstack/services';

import { MagicTable } from '../../magic/MagicTable';

export type CloudFormationOutputsGridProps = {
  entries: CloudFormationOutput[];
  loading?: boolean;
};

export const CloudFormationOutputsGrid = ({ entries, loading }: CloudFormationOutputsGridProps): ReactElement => (
  <MagicTable
    schema={getSchema('CloudFormation')}
    entry="Output"
    order={['OutputKey', 'OutputValue', 'ExportName', 'Description']}
    idAttribute="OutputKey"
    rows={entries}
    loading={loading}
  />
);
