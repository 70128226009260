import { CloudPod, OrganizationSettings, ProductFeature, PlanFamily, Permission } from '@localstack/types';
import {
  ConfirmableButton,
  ControlledTextField,
  PageTitle,
  Dropdown,
  LoadingFragment,
  PodsTable,
  ProgressButton,
  UsageLimit,
} from '@localstack/ui';
import {
  Box,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Checkbox,
  FormControlLabel,
  Grid,
  Typography,
  Alert,
} from '@mui/material';
import { ReactElement, useCallback, useEffect, useMemo, useState } from 'react';

import {
  CloudPodsService,
  OrganizationsService,
  formatBytes,
  useApiEffect,
  useApiGetter,
  useRoutes,
  useSnackbar,
  VALIDATION_RULES,
} from '@localstack/services';

import { useForm } from 'react-hook-form';

import ControlledSwitch from '@localstack/ui/src/form/ControlledSwitch';

import { AppRoute } from '~/config';
import { ContainedCustomerLayout } from '~/layouts';

import { useAuthProvider } from '~/hooks/useAuthProvider';
import { getUserInfo } from '~/util/storage';

const PAGE_TITLE = 'Cloud Pods';

type FormProps = {
  autoCleanup: boolean;
  maxVersions: number;
};

export const CloudPods = (): ReactElement => {
  const userInfo = getUserInfo();
  const { goto } = useRoutes();
  const { can, hasFeature } = useAuthProvider();
  const { showSnackbar } = useSnackbar();
  const [selectedPodIds, setSelectedPodIds] = useState<string[]>([]);
  const [onlyUserPods, setOnlyUserPods] = useState<boolean>(false);

  const {
    data: allPods,
    isLoading: isPodLoading,
    mutate,
  } = useApiGetter(CloudPodsService, 'listCloudPods', [], { defaultValue: [] });

  const { deleteCloudPod, isLoading: isPodDeleting } = useApiEffect(CloudPodsService, ['deleteCloudPod'], {
    revalidate: ['listCloudPods'],
  });

  const { patchCloudPod } = useApiEffect(CloudPodsService, ['patchCloudPod'], { revalidate: ['listCloudPods'] });

  const { data: cloudPodsStats, isLoading: isStatsLoading } = useApiGetter(CloudPodsService, 'getCloudPodsStats', []);

  const selectedPods = useMemo(
    () => allPods?.filter((pod) => selectedPodIds.includes(pod.id)) ?? [],
    [selectedPodIds, allPods],
  );

  const onDeletePods = async () => {
    selectedPods.map(async (pod) => {
      await deleteCloudPod(pod.pod_name);
    });
    showSnackbar({ severity: 'success', message: 'Successfully deleted selected cloud pods' });
  };

  const onMakePodsPublic = async () => {
    selectedPods.map(async (pod) => {
      await patchCloudPod(pod.pod_name, { ...pod, is_public: true });
    });
    showSnackbar({ severity: 'success', message: 'Successfully made selected cloud pods public' });
  };

  const onMakePodsPrivate = async () => {
    selectedPods.map(async (pod) => {
      await patchCloudPod(pod.pod_name, { ...pod, is_public: false });
    });
    showSnackbar({ severity: 'success', message: 'Successfully made selected cloud pods private' });
  };

  const organization = userInfo?.org;
  const { control, handleSubmit, setValue, watch } = useForm<FormProps>({ mode: 'all' });
  const isAutoCleanupEnabled = watch('autoCleanup');

  useEffect(() => {
    if (!isAutoCleanupEnabled) {
      setValue('maxVersions', 0, { shouldValidate: true });
    }
  }, [isAutoCleanupEnabled]);

  const { updateOrganizationSettings, isLoading: isOrgUpdating } = useApiEffect(
    OrganizationsService,
    ['updateOrganizationSettings'],
    { revalidate: ['listOrganizations'] },
  );

  useEffect(() => {
    if (!organization) return;
    const podsSettings = organization.settings?.cloud_pods;
    if (podsSettings?.auto_cleanup) {
      const isEnabled = !!podsSettings.auto_cleanup.max_pod_versions;
      setValue('maxVersions', podsSettings.auto_cleanup.max_pod_versions || 0, { shouldValidate: true });
      setValue('autoCleanup', isEnabled, { shouldValidate: true });
    }
  }, [organization?.id]);

  const updatePodsSettings = useCallback(
    async ({ autoCleanup, maxVersions }: FormProps) => {
      const maxVersion = autoCleanup ? maxVersions : 0;
      const requestBody: OrganizationSettings = {
        cloud_pods: {
          auto_cleanup: {
            max_pod_versions: maxVersion,
          },
        },
      };
      await updateOrganizationSettings(organization?.id, requestBody as OrganizationSettings);
      showSnackbar({ severity: 'success', message: 'Successfully updated cloud pods settings' });
    },
    [organization],
  );

  const pods = useMemo(
    () =>
      (allPods ?? [])
        .filter((pod) => !pod.pod_name.match(/^ci[:_].+/))
        .filter((pod) => !onlyUserPods || pod.user_id === userInfo?.user.id),
    [allPods, onlyUserPods],
  );
  const isLoading = isPodLoading || isPodDeleting;

  const getUsername = (user_id: string): string | undefined => {
    const member = organization?.members.find((user) => user.id === user_id);
    return member?.name;
  };

  const podsWithUsername = useMemo(
    () => pods.map((pod) => ({ ...pod, user_id: getUsername(pod.user_id ?? '') })),
    [pods],
  );

  const orgStorageSize = cloudPodsStats?.org_pods?.storage_size ?? 0;
  const userPodsDetails = (cloudPodsStats?.user_pods || {})[userInfo?.user.id || ''];
  const userStorageSize = userPodsDetails?.storage_size ?? 0;
  const maxStorage = cloudPodsStats?.org_limit?.storage_size || 500 * 1024 * 1024;

  if (!hasFeature(ProductFeature.FEATURE_PODS)) {
    return (
      <ContainedCustomerLayout title={PAGE_TITLE} planFamily={PlanFamily.TEAM_PLANS}>
        <Alert severity="info">Cloud Pods are only available in the Team/Enterprise plan.</Alert>
      </ContainedCustomerLayout>
    );
  }

  return (
    <ContainedCustomerLayout title="Cloud Pods" planFamily={PlanFamily.TEAM_PLANS}>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <LoadingFragment loading={isStatsLoading} variant="card" height={184}>
            <Card>
              <CardHeader title="Cloud Pods Storage" />
              <CardContent>
                <Grid container spacing={3}>
                  <Grid item xs={12} md={3}>
                    Organization Storage ({formatBytes(maxStorage)}):
                  </Grid>
                  <Grid item xs={12} md={9}>
                    <UsageLimit
                      variant="edged"
                      greenRange={[0, 75]}
                      orangeRange={[75, 90]}
                      redRange={[90, 100]}
                      value={Math.min((orgStorageSize / maxStorage) * 100, 100)}
                      displayValue={formatBytes(orgStorageSize)}
                      tooltip={`Used ${formatBytes(orgStorageSize)} of organization cloud pods storage`}
                    />
                  </Grid>
                  <Grid item xs={12} md={3}>
                    User Storage ({formatBytes(maxStorage)}):
                  </Grid>
                  <Grid item xs={12} md={9}>
                    <UsageLimit
                      variant="edged"
                      greenRange={[0, 75]}
                      orangeRange={[75, 90]}
                      redRange={[90, 100]}
                      value={Math.min((userStorageSize / maxStorage) * 100, 100)}
                      displayValue={formatBytes(userStorageSize)}
                      tooltip={`Used ${formatBytes(userStorageSize)} of user cloud pods storage`}
                    />
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </LoadingFragment>
        </Grid>
        <Grid item xs={12}>
          <Card>
            <CardHeader
              title={<PageTitle title="Cloud Pods" onMutate={mutate} typographyVariant="h5" />}
              action={
                <>
                  <FormControlLabel
                    control={
                      <Checkbox
                        color="primary"
                        size="small"
                        checked={onlyUserPods}
                        onChange={(_, checked) => setOnlyUserPods(checked)}
                      />
                    }
                    label="Show my pods only"
                  />
                  <Dropdown label="Actions">
                    <ConfirmableButton
                      componentType="MenuItem"
                      color="primary"
                      disabled={selectedPodIds.length === 0}
                      onClick={() => onDeletePods()}
                      title="Do you want to delete the following cloud pods? This action cannot be undone!"
                      text={
                        <>
                          {selectedPods.map((pod) => (
                            <Typography key={pod.id}>{pod.pod_name}</Typography>
                          ))}
                        </>
                      }
                    >
                      Delete
                    </ConfirmableButton>
                    <ConfirmableButton
                      componentType="MenuItem"
                      color="primary"
                      disabled={selectedPodIds.length === 0}
                      onClick={() => onMakePodsPublic()}
                      title="Make following cloud pods public?"
                      text={
                        <>
                          {selectedPods.map((pod) => (
                            <Typography key={pod.id}>{pod.pod_name}</Typography>
                          ))}
                        </>
                      }
                    >
                      Make public
                    </ConfirmableButton>
                    <ConfirmableButton
                      componentType="MenuItem"
                      color="primary"
                      disabled={selectedPodIds.length === 0}
                      onClick={() => onMakePodsPrivate()}
                      title="Make following cloud pods private?"
                      text={
                        <>
                          {selectedPods.map((pod) => (
                            <Typography key={pod.id}>{pod.pod_name}</Typography>
                          ))}
                        </>
                      }
                    >
                      Make private
                    </ConfirmableButton>
                  </Dropdown>
                </>
              }
            />
            <PodsTable
              pods={(podsWithUsername as CloudPod[]) || []}
              loading={isLoading}
              onViewPod={(name) => goto(AppRoute.POD, { name })}
              onSelect={setSelectedPodIds}
            />
          </Card>
        </Grid>
        {can(Permission.UPDATE_ORGANIZATION) && (
          <Grid item xs={12}>
            <form onSubmit={handleSubmit(updatePodsSettings)}>
              <Card>
                <CardHeader title="Cloud Pods Settings" />
                <Card variant="outlined">
                  <CardHeader title="Auto-cleanup" />
                  <CardContent>
                    <Typography variant="body2" paragraph>
                      When enabled, old versions of Cloud Pods will be cleaned up automatically. All versions of a Cloud
                      Pod that exceed the threshold set below will be deleted permanently. Note that changing this
                      setting here will not automatically trigger a cleanup: eventual deletions will happen only when
                      creating a new Cloud Pod version.
                    </Typography>
                    <ControlledSwitch control={control} name="autoCleanup" label="Auto-cleanup" labelPlacement="end" />
                    <Box mb={3}>
                      <ControlledTextField
                        control={control}
                        name="maxVersions"
                        label="No. versions to retain (per pod)"
                        placeholder="no. versions"
                        required={isAutoCleanupEnabled}
                        variant="outlined"
                        rules={isAutoCleanupEnabled ? VALIDATION_RULES.integerOnly : undefined}
                        disabled={!isAutoCleanupEnabled}
                        style={{ width: 220 }}
                      />
                    </Box>
                  </CardContent>
                  <CardActions>
                    <ProgressButton loading={isOrgUpdating} type="submit" color="primary" variant="contained">
                      Save
                    </ProgressButton>
                  </CardActions>
                </Card>
              </Card>
            </form>
          </Grid>
        )}
      </Grid>
    </ContainedCustomerLayout>
  );
};
