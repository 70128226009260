import { ReactElement } from 'react';
import { useAwsEffect, useAwsGetter, useRoutes } from '@localstack/services';
import { Card, CardContent, CardActions, Box, Typography } from '@mui/material';
import { Breadcrumbs, GlueJobForm, ProgressButton } from '@localstack/ui';
import { CreateGlueJobRequest, UpdateGlueJobRequest } from '@localstack/types';
import { DEFAULT_GLUE_ROUTES } from '@localstack/constants/src';
import { useParams } from 'react-router-dom';

import { GlueProps } from './types';
import { MainNavTabs } from './components';

export const GlueJobUpsert = ({ Layout, clientOverrides, routes = DEFAULT_GLUE_ROUTES }: GlueProps): ReactElement => {
  const { goto } = useRoutes();
  const { job } = useParams<'job'>();

  const { createJob, updateJob, isLoading } = useAwsEffect('Glue', ['createJob', 'updateJob'], {
    revalidate: ['getJobs', 'getJob'],
    clientOverrides,
  });

  const { data: JobData } = useAwsGetter('Glue', 'getJob', [{ JobName: job }], { clientOverrides });

  const handleCreate = async (data: CreateGlueJobRequest) => {
    await createJob(data);
    goto(routes.RESOURCES_GLUE_JOBS);
  };

  const handleUpdate = async (data: UpdateGlueJobRequest) => {
    await updateJob(data);
    goto(routes.RESOURCES_GLUE_JOB, { job });
  };

  return (
    <Layout
      documentTitle={`Glue: ${job ? 'Update' : 'Create'} Job`}
      title={
        <Box>
          <Typography variant="h4">{job ? 'Update' : 'Create'} Job</Typography>
          <Breadcrumbs
            mappings={[
              ['Glue', () => goto(routes.RESOURCES_GLUE_DATABASES)],
              ['Jobs', () => goto(routes.RESOURCES_GLUE_JOBS)],
              [`${job ? 'Update' : 'Create'} Job`, null],
            ]}
          />
        </Box>
      }
      tabs={<MainNavTabs routes={routes} />}
      actions={
        <ProgressButton type="submit" form="UpsertJob" variant="outlined" color="primary" loading={isLoading}>
          Submit
        </ProgressButton>
      }
    >
      <Card>
        <CardContent>
          <GlueJobForm
            job={JobData?.Job}
            loading={isLoading}
            formId="UpsertJob"
            clientOverrides={clientOverrides}
            onCreate={handleCreate}
            onUpdate={handleUpdate}
          />
        </CardContent>
        <CardActions>
          <ProgressButton type="submit" form="UpsertJob" variant="contained" color="primary" loading={isLoading}>
            Submit
          </ProgressButton>
        </CardActions>
      </Card>
    </Layout>
  );
};
