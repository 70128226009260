import { ReactElement } from 'react';
import { CheckCircle, Cancel, Error } from '@mui/icons-material';
import { Box } from '@mui/material';

import { RequestStatus } from '@localstack/types';

type StatusIconProps = {
  status: RequestStatus;
};

export const StatusIcon = ({ status }: StatusIconProps): ReactElement => (
  <Box sx={{ width: 24, height: 24 }}>
    {status === RequestStatus.DENIED && <Cancel fontSize="small" color="error" />}
    {status === RequestStatus.SUCCESS && <CheckCircle fontSize="small" color="disabled" />}
    {status === RequestStatus.WARNING && <Error fontSize="small" color="warning" />}
  </Box>
);
