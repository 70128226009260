import { ReactElement } from 'react';
import { useAwsEffect, useRoutes } from '@localstack/services';
import { Card, CardContent, CardActions, Box, Typography } from '@mui/material';
import { Breadcrumbs, Route53HostedZoneCreateForm, ProgressButton } from '@localstack/ui';
import { CreateRoute53HostedZoneRequest } from '@localstack/types';

import { DEFAULT_ROUTE53_ROUTES } from '@localstack/constants/src';

import { Route53Props } from './types';
import { MainNavTabs } from './components';

export const Route53HostedZoneCreate = ({
  Layout,
  clientOverrides,
  routes = DEFAULT_ROUTE53_ROUTES,
}: Route53Props): ReactElement => {
  const { goto } = useRoutes();

  const { createHostedZone, isLoading } = useAwsEffect('Route53', ['createHostedZone'], {
    revalidate: ['listHostedZones'],
    clientOverrides,
  });

  const handleCreate = async (data: CreateRoute53HostedZoneRequest) => {
    await createHostedZone({
      ...data,
      CallerReference: (+new Date()).toString(),
    });
    goto(routes.RESOURCES_ROUTE53_HOSTED_ZONES);
  };

  return (
    <Layout
      documentTitle="Route53: Create Hosted Zone"
      title={
        <Box>
          <Typography variant="h4">Create Hosted Zone</Typography>
          <Breadcrumbs
            mappings={[
              ['Route53', () => goto(routes.RESOURCES_ROUTE53_HOSTED_ZONES)],
              ['Create Hosted Zone', null],
            ]}
          />
        </Box>
      }
      tabs={<MainNavTabs routes={routes} />}
      actions={
        <ProgressButton type="submit" form="CreateHostedZone" variant="outlined" color="primary" loading={isLoading}>
          Submit
        </ProgressButton>
      }
    >
      <Card>
        <CardContent>
          <Route53HostedZoneCreateForm loading={isLoading} formId="CreateHostedZone" onSubmit={handleCreate} />
        </CardContent>
        <CardActions>
          <ProgressButton type="submit" form="CreateHostedZone" variant="contained" color="primary" loading={isLoading}>
            Submit
          </ProgressButton>
        </CardActions>
      </Card>
    </Layout>
  );
};
