import { ReactElement } from 'react';
import { useRoutes, useAwsGetter } from '@localstack/services';
import { useParams } from 'react-router-dom';
import { Card, CardContent, Button, Box, Typography } from '@mui/material';
import { Breadcrumbs, CloudFrontDistributionDetails } from '@localstack/ui';

import { DEFAULT_CLOUDFRONT_ROUTES } from '@localstack/constants';

import { CloudFrontProps } from './types';

export const CloudFrontDistribution = ({
  Layout,
  clientOverrides,
  routes = DEFAULT_CLOUDFRONT_ROUTES,
}: CloudFrontProps): ReactElement => {
  const { goto } = useRoutes();
  const { id } = useParams<'id'>();

  const { data: distribution } = useAwsGetter('CloudFront', 'getDistribution', [{ Id: id }], { clientOverrides });

  return (
    <Layout
      documentTitle="Distribution Details"
      title={
        <Box>
          <Typography variant="h4">Distribution Details</Typography>
          <Breadcrumbs
            mappings={[
              ['Distributions', () => goto(routes.RESOURCES_CLOUDFRONT_DISTRIBUTIONS)],
              [id, null],
            ]}
          />
        </Box>
      }
      actions={
        <Button onClick={() => goto(routes.RESOURCES_CLOUDFRONT_DISTRIBUTION_UPDATE, { id })}>Edit Distribution</Button>
      }
    >
      <Card>
        <CardContent>
          <CloudFrontDistributionDetails distribution={distribution?.Distribution} />
        </CardContent>
      </Card>
    </Layout>
  );
};
