import { isUndefined } from 'lodash';

import { satisfiesVersionConstraint } from '../localstack';

export const refineSqsUrl = (url: Optional<string>, baseUrl: string): string => {
  // regex which finds the text till third slash(/)
  if (isUndefined(url)) {
    return url as unknown as string;
  }
  const regex = new RegExp(/(?:[^/]*\/){3}/g);
  const results = regex.exec(url as string) || [];
  const domain = results[0]?.slice(0, -1);

  // replaces the found text with user endpoint
  return (url ?? '').replace(domain || '', baseUrl);
};

type SQSBackdoorMessagesEndpointOptions = { showInvisible?: boolean; showDelayed?: boolean };
export const getSQSBackdoorMessagesEndpoint = (
  endpoint: string,
  options: SQSBackdoorMessagesEndpointOptions = { showInvisible: true, showDelayed: true },
): string => `${endpoint}/_aws/sqs/messages?ShowInvisible=${options.showInvisible}&ShowDelayed=${options.showDelayed}`;

const MIN_REQUIRED_VERSION = '4.0.0';
export const checkSQSInternalEndpointSupported = (version: string): boolean =>
  satisfiesVersionConstraint(version || '', MIN_REQUIRED_VERSION);
