import { getSchema } from '@localstack/services';
import { RedshiftCluster } from '@localstack/types';
import { ReactElement } from 'react';

import { MagicDetails } from '../../magic/MagicDetails';

export type RedshiftClusterDetailProps = {
  cluster?: Optional<Partial<RedshiftCluster>>;
};

export const RedshiftClusterDetail = ({ cluster }: RedshiftClusterDetailProps): ReactElement => (
  <MagicDetails data={cluster} schema={getSchema('Redshift')} entry="Cluster" title="Redshift Cluster Details" />
);
