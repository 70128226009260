import { ReactElement } from 'react';
import { CloudWatchMetricAlarm } from '@localstack/types';
import { getSchema } from '@localstack/services';

import { MagicDetails } from '../../magic/MagicDetails';

export interface MonitoringAlarmDetailsProps {
  alarm?: Optional<Partial<CloudWatchMetricAlarm>>;
}

export const MonitoringAlarmDetails = ({ alarm }: MonitoringAlarmDetailsProps): ReactElement => (
  <MagicDetails data={alarm} schema={getSchema('CloudWatch')} entry="MetricAlarm" title="Alarm Details" />
);
