import { ReactElement } from 'react';
import { Link } from '@mui/material';
import { TimestreamDatabase } from '@localstack/types';
import { getSchema } from '@localstack/services';

import { MagicTable } from '../../../magic/MagicTable';

export type TimestreamDatabaseGridProps = {
  selectable?: boolean;
  loading?: boolean;
  entries: TimestreamDatabase[];
  onSelect?: (databases: string[]) => void;
  onViewDatabase?: (queue: TimestreamDatabase) => void;
};

export const TimestreamDatabaseGrid = ({
  entries,
  selectable = true,
  loading,
  onSelect,
  onViewDatabase,
}: TimestreamDatabaseGridProps): ReactElement => (
  <MagicTable
    schema={getSchema('TimestreamWrite')}
    entry="DatabaseList"
    idAttribute="Arn"
    rows={entries}
    selectable={selectable}
    order={['DatabaseName', 'CreationTime', 'LastUpdatedTime', 'Arn']}
    filterColumns={['DatabaseName', 'CreationTime', 'LastUpdatedTime', 'Arn']}
    onSelect={onSelect}
    loading={loading}
    externalFields={{
      DatabaseName: (row) => (
        <Link onClick={() => onViewDatabase && onViewDatabase(row)} underline="hover">
          {row.DatabaseName}
        </Link>
      ),
    }}
  />
);
