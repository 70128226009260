import { ReactElement } from 'react';
import { SNSTopicAttributes as SNSTopicAttributesType } from '@localstack/types';

import { MagicDetails } from '../../../magic/MagicDetails';

export type SNSTopicAttributesProps = {
  attributes: Optional<SNSTopicAttributesType>;
};

const SCHEMA = {
  shapes: {
    TopicAttributesMap: {
      type: 'structure',
      members: {
        TopicArn: { type: 'string' },
        DeliveryPolicy: { type: 'string' },
        DisplayName: { type: 'string' },
        EffectiveDeliveryPolicy: { type: 'string' },
        Owner: { type: 'string' },
        Policy: { type: 'string' },
        SubscriptionsConfirmed: { type: 'integer' },
        SubscriptionsDeleted: { type: 'integer' },
        SubscriptionsPending: { type: 'integer' },
        KmsMasterKeyId: { type: 'string' },
        FifoTopic: { type: 'boolean' },
        ContentBasedDeduplication: { type: 'boolean' },
      },
    },
  },
};

export const SNSTopicAttributes = ({ attributes }: SNSTopicAttributesProps): ReactElement => (
  <MagicDetails data={attributes} schema={SCHEMA} entry="TopicAttributesMap" title="Topic Attributes" />
);
