import { Palette, alpha, darken } from '@mui/material/styles';

import { MuiThemeComponentsType } from '../types';
import { ThemeType } from '../../constants';

export default (palette: Palette): MuiThemeComponentsType['MuiButton'] => ({
  styleOverrides: {
    root: {
      borderRadius: 8,
    },
  },
  variants: [
    {
      props: { variant: 'outlined', color: 'tertiary' },
      style: () => ({
        backgroundColor: palette.mode === ThemeType.LIGHT ? 'white' : darken(palette.background.default, 0.05),
        color: palette.text.primary,
        borderColor: alpha(palette.common.black, 0.23),
        '&:hover': {
          backgroundColor: alpha(palette.text.primary, 0.08),
          borderColor: alpha(palette.common.black, 0.23),
        },
      }),
    },
    {
      props: { size: 'xlarge' },
      style: {
        padding: '11px 24px', // Larger padding
      },
    },
    {
      props: { color: 'inherit', variant: 'contained' },
      style: {
        backgroundColor: palette.grey[100],
        '&:hover': {
          backgroundColor: palette.grey[400],
        },
        color: 'rgba(0, 0, 0, 0.87)',
      },
    },
    {
      props: { color: 'inherit', variant: 'outlined' },
      style: {
        color: palette.text.primary,
        borderColor: palette.mode === 'light' ? 'rgba(0, 0, 0, 0.23)' : 'rgba(255, 255, 255, 0.23)',
        '&.Mui-disabled': {
          border: `1px solid ${palette.action.disabledBackground}`,
        },
        '&:hover': {
          borderColor: palette.mode === 'light' ? 'rgba(0, 0, 0, 0.23)' : 'rgba(255, 255, 255, 0.23)',
          backgroundColor: alpha(palette.text.primary, palette.action.hoverOpacity),
        },
      },
    },
  ],
  defaultProps: {
    color: 'inherit',
  },
});
