import { useEffect } from 'react';

import { LocalStackWebEvent, LocalStackEventType, EventDataMap } from '../constants';

import { observable } from './Observable';

type EventCallback<T extends LocalStackEventType> = (data: ExtractEventData<T>) => void;

export interface useObservableProps<T extends LocalStackEventType> {
  subscribedEvents?: {
    [K in T]: EventCallback<K>;
  };
  genericUpdate?: (event: LocalStackWebEvent) => void;
}

type ExtractEventData<T extends LocalStackEventType> = T extends keyof EventDataMap ? EventDataMap[T] : never;

/**
 * Hook to react to events happening in the web app
 *
 * Example:
 * ```ts
 *
 *  const handleThemeUpdate = (data: EventDataMap[LocalStackEventType.THEME_UPDATE]) => {
 *    console.log('Updated theme');
 *  }
 *  const handleGenericUpdate = (data: LocalStackWebEvent) => {
 *    console.log('New event in the system');
 *    console.log(data);
 *  }
 *
 *   useObservableEvent({
 *    subscribedEvents: {
 *     [LocalStackEventType.THEME_UPDATE]: handleThemeUpdate,
 *    },
 *    genericUpdate: handleGenericUpdate,
 *    })
 * ```
 *
 * WARNING: to be able to react to events, your component needs to be loaded which means that you can either
 *          put the listener inside a Provider or have it react to events just when it is loaded
 *
 */

export const useObservableEvent = <T extends LocalStackEventType>({
  subscribedEvents,
  genericUpdate,
}: useObservableProps<T>): void => {
  useEffect(() => {
    const observer = (event: LocalStackWebEvent) => {
      genericUpdate?.(event);

      if (subscribedEvents && subscribedEvents[event.eventType as T]) {
        const callback = subscribedEvents[event.eventType as T];
        callback?.(event.data as ExtractEventData<T>);
      }
    };

    observable.subscribe(observer);

    return () => {
      observable.unsubscribe(observer);
    };
  }, []);
};
