import { ReactElement } from 'react';
import { useAwsEffect, useAwsGetter, useRoutes } from '@localstack/services';
import { Card, CardContent, CardActions, Box, Typography } from '@mui/material';
import { Breadcrumbs, GlueSchemaVersionMetadataForm, ProgressButton } from '@localstack/ui';
import { PutGlueSchemaVersionMetadataRequest } from '@localstack/types';
import { DEFAULT_GLUE_ROUTES } from '@localstack/constants/src';
import { useParams } from 'react-router-dom';

import { GlueProps } from './types';
import { SchemaVersionTabs } from './components';

export const GlueSchemaVersionMetadataUpsert = ({
  Layout,
  clientOverrides,
  routes = DEFAULT_GLUE_ROUTES,
}: GlueProps): ReactElement => {
  const { goto } = useRoutes();
  const { schema, version, metadataKey, metadataValue, registry } = useParams<
    'schema' | 'version' | 'metadataKey' | 'metadataValue' | 'registry'
  >();
  const { putSchemaVersionMetadata, isLoading } = useAwsEffect('Glue', ['putSchemaVersionMetadata'], {
    revalidate: ['querySchemaVersionMetadata'],
    clientOverrides,
  });
  const { data: Metadata } = useAwsGetter(
    'Glue',
    'querySchemaVersionMetadata',
    [
      {
        SchemaId: { SchemaName: schema, RegistryName: registry },
        SchemaVersionNumber: { VersionNumber: Number(version ?? 0) },
        MetadataList: [{ MetadataKey: metadataKey ?? '', MetadataValue: metadataValue ?? '' }],
      },
    ],
    { clientOverrides },
  );

  const handleUpsert = async (data: PutGlueSchemaVersionMetadataRequest) => {
    await putSchemaVersionMetadata({
      ...data,
      SchemaId: { SchemaName: schema, RegistryName: registry },
      SchemaVersionNumber: { VersionNumber: Number(version ?? 0) },
    });
    goto(routes.RESOURCES_GLUE_SCHEMA_VERSION_METADATA, { version, schema, registry });
  };

  return (
    <Layout
      documentTitle={`Glue: ${metadataKey ? 'Update' : 'Create'} Metadata`}
      title={
        <Box>
          <Typography variant="h4">{metadataKey ? 'Update' : 'Create'} Metadata</Typography>
          <Breadcrumbs
            mappings={[
              ['Glue', () => goto(routes.RESOURCES_GLUE_DATABASES)],
              ['Schemas', () => goto(routes.RESOURCES_GLUE_SCHEMAS)],
              [schema, () => goto(routes.RESOURCES_GLUE_SCHEMA, { schema, registry })],
              ['Versions', () => goto(routes.RESOURCES_GLUE_SCHEMA_VERSIONS, { schema, registry })],
              [version, () => goto(routes.RESOURCES_GLUE_SCHEMA_VERSION, { schema, version, registry })],
              ['Metadata', () => goto(routes.RESOURCES_GLUE_SCHEMA_VERSION_METADATA, { schema, version, registry })],
              [metadataKey, () => goto(routes.RESOURCES_GLUE_SCHEMA_VERSION_METADATA, { schema, version, registry })],
              [metadataKey ? 'Update' : 'Create', null],
            ]}
          />
        </Box>
      }
      tabs={
        <SchemaVersionTabs routes={routes} schema={schema ?? ''} version={version ?? ''} registry={registry ?? ''} />
      }
      actions={
        <ProgressButton type="submit" form="UpsertMetadata" variant="outlined" color="primary" loading={isLoading}>
          Submit
        </ProgressButton>
      }
    >
      <Card>
        <CardContent>
          <GlueSchemaVersionMetadataForm
            metadata={
              metadataKey
                ? {
                    MetadataKeyValue: {
                      MetadataKey: metadataKey,
                      MetadataValue: Object.values(Metadata?.MetadataInfoMap ?? {})[0]?.MetadataValue,
                    },
                  }
                : null
            }
            loading={isLoading}
            formId="UpsertMetadata"
            onUpsert={handleUpsert}
          />
        </CardContent>
        <CardActions>
          <ProgressButton type="submit" form="UpsertMetadata" variant="contained" color="primary" loading={isLoading}>
            Submit
          </ProgressButton>
        </CardActions>
      </Card>
    </Layout>
  );
};
