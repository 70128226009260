import { SERVICE_NAME } from '@localstack/types';

import { PATH_PREFIX_RESOURCES } from './base';

const SQS_BASE_ROUTE = `${PATH_PREFIX_RESOURCES}/${SERVICE_NAME.SQS}`;

export const DEFAULT_SQS_ROUTES: Record<string, string> = {
  RESOURCES_SQS_QUEUES: `${SQS_BASE_ROUTE}`,
  RESOURCES_SQS_QUEUE: `${SQS_BASE_ROUTE}/:name`,
  RESOURCES_SQS_QUEUE_CREATE: `${SQS_BASE_ROUTE}/new`,
  RESOURCES_SQS_QUEUE_MESSAGES: `${SQS_BASE_ROUTE}/:name/messages`,
  RESOURCES_SQS_QUEUE_MESSAGE_SEND: `${SQS_BASE_ROUTE}/:name/messages/new`,
  RESOURCES_SQS_QUEUE_MESSAGE_SEND_1: `${SQS_BASE_ROUTE}/messages/new`,
  RESOURCES_SQS_QUEUE_MESSAGE: `${SQS_BASE_ROUTE}/:name/messages/:messageId`,
};
