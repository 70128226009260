import { ReactElement, useEffect, useState } from 'react';
import { debounce } from 'lodash';
import { Box, CardContent, IconButton } from '@mui/material';
import { Add as AddIcon, Mail as MailIcon } from '@mui/icons-material';
import { AdminSearchResult, OrganizationMember } from '@localstack/types';
import { AdminService, useApiEffect, useApiGetter, useRoutes } from '@localstack/services';
import { LoadingFragment } from '@localstack/ui';

import { AppRoute } from '~/config';

import { InvitationForm, MemberForm, MembersList } from '../components';

const SEARCH_DEBOUNCE_RATE = 1000;

export interface MembersProps {
  orgId: string;
}

export const Members = ({ orgId }: MembersProps): ReactElement => {
  const { goto } = useRoutes();

  const [showMembershipForm, setShowMembershipForm] = useState(false);
  const [showInvitationForm, setShowInvitationForm] = useState(false);

  const [usersQuery, setUsersQuery] = useState<Optional<string>>(null);
  const [activeMember, setActiveMember] = useState<Optional<OrganizationMember>>(null);

  const { getUser } = useApiEffect(AdminService, ['getUser']);
  const { data: organization, isLoading: isOrgLoading } = useApiGetter(AdminService, 'getOrganization', [orgId]);

  const {
    createOrganizationMember,
    inviteOrganizationMember,
    updateOrganizationMember,
    removeOrganizationMember,
    isLoading: isOrgMutating,
  } = useApiEffect(
    AdminService,
    ['createOrganizationMember', 'inviteOrganizationMember', 'updateOrganizationMember', 'removeOrganizationMember'],
    { revalidate: ['getOrganization', 'getOrganizations'] },
  );

  const { data: searchResults, isLoading: isSearching } = useApiGetter(AdminService, 'getSearchResults', [usersQuery], {
    enable: !!usersQuery,
    defaultValue: {} as AdminSearchResult,
  });

  useEffect(() => {
    if (!showMembershipForm) setActiveMember(null);
  }, [showMembershipForm]);

  return (
    <>
      <Box display="flex" alignItems="center" justifyContent="space-between">
        <div />
        <Box display="flex" alignItems="center">
          <IconButton color="primary" onClick={() => setShowInvitationForm(!showInvitationForm)}>
            <MailIcon />
          </IconButton>
          <IconButton color="primary" onClick={() => setShowMembershipForm(!showMembershipForm)}>
            <AddIcon />
          </IconButton>
        </Box>
      </Box>
      {showInvitationForm && (
        <CardContent>
          <InvitationForm
            loading={isOrgMutating}
            onInviteMember={(name, email, assignOnJoin) =>
              inviteOrganizationMember(orgId, {
                name,
                email,
                try_assign_license_on_join: assignOnJoin,
              })
            }
          />
        </CardContent>
      )}
      {showMembershipForm && (
        <CardContent>
          <MemberForm
            loading={isOrgMutating}
            member={activeMember}
            searching={isSearching}
            searchedUsers={searchResults?.users ?? []}
            onGetUser={getUser}
            onSearchUsers={debounce(setUsersQuery, SEARCH_DEBOUNCE_RATE)}
            onCreateMember={async (member: OrganizationMember) => {
              await createOrganizationMember(orgId, member);
              setShowMembershipForm(false);
            }}
            onUpdateMember={async (member: OrganizationMember) => {
              await updateOrganizationMember(orgId, member.id, member);
              setShowMembershipForm(false);
            }}
          />
        </CardContent>
      )}
      <LoadingFragment loading={isOrgLoading} arrayData={organization?.members ?? []} size={3} height={80}>
        <MembersList
          members={organization?.members ?? []}
          onRemoveMember={(memberId, forever) => removeOrganizationMember(orgId, memberId, forever)}
          onShowMember={(id) => goto(AppRoute.ADMIN_ACCOUNT, { id })}
          onEditMember={(id) => {
            setActiveMember(organization?.members.find((m) => m.id === id));
            setShowMembershipForm(true);
          }}
        />
      </LoadingFragment>
    </>
  );
};
