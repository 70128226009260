import { ReactElement, useEffect, useRef, useState } from 'react';
import ReactDiffViewer, { DiffMethod } from 'react-diff-viewer';
import { alpha, Theme, useTheme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import { Box, Button, Alert } from '@mui/material';
import classNames from 'classnames';

const DEFAULT_CONTAINER_HEIGHT = 500;

type Props = {
  containerHeight: number;
};

const parseNestedJSONString = (jsonString: string) => {
  try {
    const parsedObj = JSON.parse(jsonString);
    // Recursive function to check and parse nested JSON strings
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const nestedJSONParserFn = (obj: { [key: string]: any }) => {
      Object.entries(obj).forEach(([key, value]) => {
        if (typeof value === 'string') {
          try {
            // if value is equals to a string representing a number f.e. "503", "400" etc we simply ignore it
            // as instead of throwing an error, JSON.parse('400') => 400 (converting the string to integer)
            if (!/^\d+$/.test(value)) {
              obj[key] = JSON.parse(value);
            }
          } catch (_error) {
            // Ignore parsing errors, leave the property unchanged
          }
        } else if (typeof value === 'object' && value) {
          nestedJSONParserFn(value);
        }
      });
    };

    nestedJSONParserFn(parsedObj);

    return parsedObj;
  } catch (error) {
    console.error('Error parsing JSON:', error);
    return null;
  }
};

const parseJSONWithErrorHandler = (jsonString: string) => {
  let data: Optional<Record<string, unknown>> = {};
  try {
    data = parseNestedJSONString(jsonString ?? '{}');
  } catch (error) {
    data = null;
    console.error('Error parsing JSON:', error);
  }
  return data;
};

const useStyles = makeStyles((theme: Theme) => ({
  noPaddingX: {
    paddingLeft: 0,
    paddingRight: 0,
  },
  wrapper: {
    flexWrap: 'wrap',
    width: '100%',
  },
  shadowDiv: {
    position: 'relative',
    overflow: 'hidden',
    width: '100%',
  },
  showShadow: {
    '&:after': {
      content: '""',
      position: 'absolute',
      bottom: '-10px',
      height: '10px',
      margin: 'auto',
      width: 'calc(100% - 25px)',
      zIndex: 0,
      boxShadow: `0px -10px 30px 15px ${
        theme.palette.mode === 'dark' ? alpha(theme.palette.common.black, 0.9) : theme.palette.common.white
      }`,
    },
  },
  fieldName: {
    color: theme.palette.text.secondary,
    fontSize: theme.typography.body2.fontSize,
    width: '100%',
    marginTop: '5px',
  },
  diffViewerContainer: {
    width: '100%',
    border: `1px solid ${theme.palette.divider}`,
    borderRadius: '8px',
    maxHeight: (props: Props) => props.containerHeight,
    position: 'relative',
    overflowY: 'auto',
    '& table tr': {
      fontSize: theme.typography.body2.fontSize,
      '& td:first-child': {
        display: 'none',
      },
    },
  },
  showMoreButton: {
    position: 'absolute',
    bottom: 10,
    left: '50%',
    transform: 'translateX(-50%)',
    zIndex: 1,
  },
}));

export interface CodeSnippetViewerProp {
  data: string;
  fieldName?: string;
  expandableContainerHeight?: number;
  disableParsing?: boolean;
  hideLineNumbers?: boolean;
}

export const CodeSnippetViewer = ({
  data,
  fieldName,
  expandableContainerHeight,
  disableParsing = false,
  hideLineNumbers = false,
}: CodeSnippetViewerProp): ReactElement => {
  const [isExpandable, setIsExpandable] = useState(false);
  const [showFullContent, setShowFullContent] = useState(false);

  const classes = useStyles({
    containerHeight: (!showFullContent && expandableContainerHeight) || DEFAULT_CONTAINER_HEIGHT,
  });
  const theme = useTheme();
  const diffViewContainerRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    if (!diffViewContainerRef.current || !expandableContainerHeight) return;
    if (diffViewContainerRef.current.scrollHeight < expandableContainerHeight) setIsExpandable(false);
    else setIsExpandable(true);
  }, [diffViewContainerRef, data]);

  const json = disableParsing ? data : parseJSONWithErrorHandler(data);
  const diffViewerValue = JSON.stringify(json, null, 2);

  /**
   * For showing code snippets, we could've introduced a new component but that'd be a 3rd new component to show code
   * after ControlledCodeEditor, DiffViewer. To avoid adding a new component and keep it consistent, ReactDiffViewer
   * is used to show code with line numbers in a formatted manner.
   */

  return (
    <Box className={classNames(classes.wrapper)}>
      {json || disableParsing ? (
        <div
          className={classNames(classes.shadowDiv, {
            [classes.showShadow as string]: isExpandable && !showFullContent,
          })}
        >
          <div className={classes.diffViewerContainer} ref={diffViewContainerRef}>
            <ReactDiffViewer
              oldValue={disableParsing ? data : diffViewerValue}
              newValue={disableParsing ? data : diffViewerValue}
              useDarkTheme={theme.palette.mode === 'dark'}
              compareMethod={DiffMethod.WORDS_WITH_SPACE}
              hideLineNumbers={hideLineNumbers}
              splitView={false}
              showDiffOnly={false}
            />
          </div>
          {isExpandable && (
            <>
              <Button
                variant="contained"
                className={classes.showMoreButton}
                onClick={() => setShowFullContent(!showFullContent)}
              >
                {showFullContent ? 'Collapse' : 'Expand'}
              </Button>
            </>
          )}
        </div>
      ) : (
        <Box width="100%">
          <Alert severity="error">An unknown error occurred while parsing this code snippet</Alert>
        </Box>
      )}
      {fieldName && <p className={classes.fieldName}>{fieldName}</p>}
    </Box>
  );
};
