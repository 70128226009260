import { ReactElement } from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import { MembershipRole } from '@localstack/types';

import { AppRoute } from '~/config';
import { useAuthProvider } from '~/hooks/useAuthProvider';

import { NotFound } from '../common';

import { Accounts, Account, CreateAccount } from './Accounts';

import { Organizations, Organization, Subscribe, Subscription } from './Organizations';
import { AdminExtensions, AdminExtension } from './Extensions';
import { AdminAnnouncement } from './AdminAnnouncement';
import { Dashboard } from './Dashboard';

export const AdminViews = (): ReactElement => {
  const { can } = useAuthProvider();

  if (!can(MembershipRole.PLATFORM_ADMIN)) {
    return <Navigate replace to={AppRoute.NOT_FOUND} />;
  }

  return (
    <Routes>
      <Route element={<Dashboard />} path={AppRoute.ADMIN_HOME} />
      <Route element={<Accounts />} path={AppRoute.ADMIN_ACCOUNTS} />
      <Route element={<Account />} path={AppRoute.ADMIN_ACCOUNT} />
      <Route element={<CreateAccount />} path={AppRoute.ADMIN_CREATE_ACCOUNT} />
      <Route element={<Organizations />} path={AppRoute.ADMIN_ORGANIZATIONS} />
      <Route element={<Organization />} path={AppRoute.ADMIN_ORGANIZATION} />
      <Route element={<Subscribe />} path={AppRoute.ADMIN_ORGANIZATION_SUBSCRIBE} />
      <Route element={<Subscription />} path={AppRoute.ADMIN_ORGANIZATION_SUBSCRIPTION} />
      <Route element={<Subscribe />} path={AppRoute.ADMIN_ORGANIZATION_SUBSCRIPTION_UPDATE} />
      <Route element={<NotFound />} path={AppRoute.NOT_FOUND} />
      <Route element={<AdminExtensions />} path={AppRoute.ADMIN_EXTENSIONS} />
      <Route element={<AdminExtension />} path={AppRoute.ADMIN_EXTENSION} />
      <Route element={<AdminAnnouncement />} path={AppRoute.ADMIN_ANNOUNCEMENT} />
      <Route element={<Navigate replace to={AppRoute.NOT_FOUND} />} path="*" />
    </Routes>
  );
};
